import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateItemQuestionArgs } from '@/schemaTypes'

const CREATE_ITEM_QUESTION = gql`
  mutation createItemQuestion(
    $validTo: DateTime!
    $validFrom: DateTime!
    $_oldItemQuestionIds: [String!]
    $order: Float!
    $questionType: EQuestionType!
    $valueMode: EQuestionValueMode!
    $titleKey: String!
    $infoKey: String
    $isInternal: Boolean
    $predictionTag: EQuestionPredictionTag
    $itemCategoryIds: [ObjectId!]!
    $singleChoiceOptions: [ItemQuestionOptionArgs!]
    $rangeData: ItemQuestionRangeDataArgs
  ) {
    createItemQuestion(
      validTo: $validTo
      validFrom: $validFrom
      _oldItemQuestionIds: $_oldItemQuestionIds
      order: $order
      questionType: $questionType
      valueMode: $valueMode
      titleKey: $titleKey
      infoKey: $infoKey
      isInternal: $isInternal
      predictionTag: $predictionTag
      itemCategoryIds: $itemCategoryIds
      singleChoiceOptions: $singleChoiceOptions
      rangeData: $rangeData
    ) {
      _id
      validFrom
      validTo
      _oldItemQuestionIds
      order
      questionType
      valueMode
      titleKey
      infoKey
      itemCategoryIds
      predictionTag
      isDeactivatedForNewProducts
      singleChoiceOptions {
        labelKey
        infoKey
        categoryValues
        conditionValue
        isDefaultValue
      }
      rangeData {
        minValue
        maxValue
        defaultValue
        unitPrice
        unitKey
      }
    }
  }
`

type TData = { createItemQuestion: Mutation['createItemQuestion'] }
type TVariables = MutationCreateItemQuestionArgs

export function useCreateItemQuestion(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createItemQuestion] = useMutation<TData, TVariables>(
    CREATE_ITEM_QUESTION,
    opts,
  )

  return createItemQuestion
}
