import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from '@/domains/cashbooks/styles/CashBook.module.scss'

export function CashBooks() {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <h1>{t('cash_book.label')}</h1>
      <Link to="/inApp/cashBook/cashFlows">{t('revenues_and_expenses')}</Link>
      <br />
      <Link to="/inApp/cashBook/counts">{t('bills_and_coins_count')}</Link>
    </div>
  )
}
