import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateMaterialPriceArgs } from '@/schemaTypes'

const CREATE_MATERIAL_PRICE = gql`
  mutation createMaterialPrice(
    $date: DateTime!
    $material: String!
    $price: Float!
    $mu: Float!
    $sigma: Float!
  ) {
    createMaterialPrice(
      date: $date
      material: $material
      price: $price
      mu: $mu
      sigma: $sigma
    ) {
      _id
      date
      material
      price
      mu
      sigma
    }
  }
`

type TData = { createMaterialPrice: Mutation['createMaterialPrice'] }
type TVariables = MutationCreateMaterialPriceArgs

export function useCreateMaterialPrice(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createMaterialPrice] = useMutation<TData, TVariables>(
    CREATE_MATERIAL_PRICE,
    opts,
  )

  return createMaterialPrice
}
