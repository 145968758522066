import * as at from '../actionTypes'

export const setLanguage = (data: string) => {
  if (data) {
    window.localStorage.setItem('language', data || 'de')
  }

  return {
    type: at.CHANGE_LANGUAGE,
    payload: data,
  }
}
