import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Context } from '@/context'
import { useCloseAuctionById } from '@/domains/auctions/hooks/auctionDetails/closeAuctionById'
import { useDeleteAuction } from '@/domains/auctions/hooks/auctionDetails/deleteAuction'
import { useGenerateAuctionAnnouncementPdf } from '@/domains/auctions/hooks/auctionDetails/generateAuctionAnnouncementPdf'
import { useRefreshAuction } from '@/domains/auctions/hooks/auctionDetails/refreshAuction'
import { useUpdateAuction } from '@/domains/auctions/hooks/auctionDetails/updateAuction'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import { Auction } from '@/schemaTypes'
import AuctionBlock, { AuctionBlockProps } from './AuctionBlock'

export type AuctionContainerProps = Pick<AuctionBlockProps, 'auctionFromDB'>

export default function AuctionContainer(props: AuctionContainerProps) {
  const [closingAuctionInProgress, setClosingAuctionInProgress] =
    useState<boolean>(false)
  const [addOrUpdateIsInProgress, setAddOrUpdateIsInProgress] =
    useState<boolean>(false)
  const [
    auctionAnnouncementGeneratingIsRunning,
    setAuctionAnnouncementGeneratingIsRunning,
  ] = useState<boolean>(false)
  const { auctionFromDB } = props
  const context = useContext(Context)
  const { closeConfirmModal } = context
  const history = useHistory()
  const { t } = useTranslation()
  const generateAuctionAnnouncementPdf = useMutationShowingErrors({
    mutation: useGenerateAuctionAnnouncementPdf(),
  })

  const onGenerateAuctionAnnouncementPdf = () => {
    setAuctionAnnouncementGeneratingIsRunning(true)
    generateAuctionAnnouncementPdf({
      variables: { auctionId: auctionFromDB._id },
    }).then(() => {
      setAuctionAnnouncementGeneratingIsRunning(false)
    })
  }

  const updateAuctionData = useMutationShowingErrors({
    mutation: useUpdateAuction(),
    successMessage: `${t('auction.auction_updated_successfully')}!`,
  })

  const deleteAuctionData = useMutationShowingErrors({
    mutation: useDeleteAuction(),
    successMessage: `${t('auction.auction_deleted_successfully')}!`,
  })

  const closeAuction = useMutationShowingErrors({
    mutation: useCloseAuctionById(),
    successMessage: `${t('auction.started_closing_auction')}!`,
  })

  const refreshAuction = useMutationShowingErrors({
    mutation: useRefreshAuction(),
  })

  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })

  const onUpdateAuction = async (
    auction: Auction | null,
    shouldNotifyCustomer: boolean,
  ) => {
    setAddOrUpdateIsInProgress(true)
    const res = await updateAuctionData({
      variables: {
        args: {
          _id: auctionFromDB._id,
          auctionDate: auction?.auctionDate,
          shopId: auction?.shopId,
          companyId: auction?.companyId,
          auctionElementCreateArgs: auction?.auctionElements.map((element) => ({
            addedToAuctionAt: element.addedToAuctionAt,
            bookingNumber: element.bookingNumber,
            addedToAuctionType: element.addedToAuctionType,
            dealId: element.dealId,
          })),
        },
        shouldNotifyCustomers: shouldNotifyCustomer,
      },
    })
    setAddOrUpdateIsInProgress(false)
    return res
  }

  const onDeleteAuction = async (auctionId) => {
    const res = await deleteAuctionData({
      variables: {
        args: {
          auctionId: auctionId,
        },
      },
    })

    if (res.data && res.data?.deleteAuction) history.push('/inApp/auctions')
  }

  const onCloseAuctionManually = (auctionId) => {
    showConfirmPopup({
      title: t('auction.close_auction_manually'),
      action: async () => {
        closeConfirmModal()
        setClosingAuctionInProgress(true)
        await closeAuction({
          variables: {
            openAuctionId: auctionId,
          },
        })
        setClosingAuctionInProgress(false)
        refreshAuction({
          variables: { auctionId: auctionId },
        })
      },
    })
  }

  return (
    <AuctionBlock
      {...props}
      onUpdateAuction={onUpdateAuction}
      onDeleteAuction={onDeleteAuction}
      onCloseAuctionManually={onCloseAuctionManually}
      closingAuctionInProgress={closingAuctionInProgress}
      addOrUpdateIsInProgress={addOrUpdateIsInProgress}
      generateAuctionAnnouncement={onGenerateAuctionAnnouncementPdf}
      generatingAuctionAnnouncement={auctionAnnouncementGeneratingIsRunning}
    />
  )
}
