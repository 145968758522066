import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

export interface LabelValueTableRowProps {
  label: string
  value?: any
  alignLabel?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  alignValue?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  renderLabel?: (label: string) => JSX.Element
  renderValue?: (value: any) => JSX.Element
}

export interface LabelValueTableProps {
  className?: string
  rows: LabelValueTableRowProps[]
  /**
   * Default: true
   */
  displayHeader?: boolean
  alignLabel?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  alignValue?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  renderLabelHeader?: () => JSX.Element
  renderValueHeader?: () => JSX.Element
  renderLabelColumn?: (label: string) => JSX.Element
  renderValueColumn?: (value: any) => JSX.Element
}

function LabelValueTableRow(props: LabelValueTableRowProps) {
  const { label, value, alignLabel, alignValue, renderLabel, renderValue } =
    props

  return (
    <TableRow>
      <TableCell align={alignLabel}>
        {renderLabel ? renderLabel(label) : label}
      </TableCell>
      <TableCell align={alignValue}>
        {renderValue ? renderValue(value) : value}
      </TableCell>
    </TableRow>
  )
}

export function LabelValueTable(props: LabelValueTableProps) {
  const {
    className,
    rows,
    alignLabel,
    alignValue,
    renderLabelHeader,
    renderValueHeader,
    renderLabelColumn,
    renderValueColumn,
    displayHeader = true,
  } = props
  const { t } = useTranslation()

  return (
    <Table className={`u-mb-sm ${className}`} size="small">
      {displayHeader && (
        <TableHead>
          <TableRow>
            <TableCell align={alignLabel}>
              {renderLabelHeader ? renderLabelHeader() : t('description')}
            </TableCell>
            <TableCell align={alignValue}>
              {renderValueHeader ? renderValueHeader() : t('value')}
            </TableCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {rows.map((row) => (
          <LabelValueTableRow
            key={row.label}
            label={row.label}
            value={row.value}
            renderLabel={row.renderLabel ?? renderLabelColumn}
            renderValue={row.renderValue ?? renderValueColumn}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default LabelValueTable
