import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeleteItemQuestionArgs } from '@/schemaTypes'

const DELETE_ITEM_QUESTION = gql`
  mutation deleteItemQuestion($itemQuestionId: ObjectId!) {
    deleteItemQuestion(itemQuestionId: $itemQuestionId)
  }
`

type TData = { deleteItemQuestion: Mutation['deleteItemQuestion'] }
type TVariables = MutationDeleteItemQuestionArgs

export function useDeleteItemQuestion(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deleteItemQuestion] = useMutation<TData, TVariables>(
    DELETE_ITEM_QUESTION,
    opts,
  )

  return deleteItemQuestion
}
