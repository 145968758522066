import dayjs from 'dayjs'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { ALL_COMPANIES } from '@/components/CompanySwitch/CompanySwitch'
import Loading from '@/components/Loading'
import { Context } from '@/context'
import { useSearchAuctions } from '@/domains/auctions/hooks'
import InfiniteScrollTable from '@/redesign/components/InfiniteScrollTable/InfiniteScrollTable'
import SearchInput from '@/redesign/components/SearchInput/SearchInput'
import TableFilterHeader from '@/redesign/components/TableFilterHeade/TableFilterHeader'
import Taskbar from '@/redesign/components/Taskbar/Taskbar'
import { Auction, AuctionsFiltersArgs, EAuctionStatus } from '@/schemaTypes'
import { useAppSelector } from '@/store'

const LIMIT = 10
const defaultTableFilterArgs = { paginationArgs: { skip: 0, limit: LIMIT } }

const AuctionsList = () => {
  const { t } = useTranslation()
  const companyId = useAppSelector((state) => state.ui.companyId)
  const history = useHistory()
  const { language } = useContext(Context)

  const [auctionsFilter, setAuctionsFilter] = useState<AuctionsFiltersArgs>(
    defaultTableFilterArgs,
  )

  const auctionsFilterArgs = useMemo(() => {
    return {
      ...auctionsFilter,
      companyId: companyId === ALL_COMPANIES ? null : companyId,
      paginationArgs: { skip: 0, limit: LIMIT },
    }
  }, [auctionsFilter, companyId])

  const { queryResult } = useSearchAuctions({
    variables: {
      args: auctionsFilterArgs,
      languageCode: language,
    },
    nextFetchPolicy: 'cache-first',
  })

  const auctions = useMemo(
    () =>
      queryResult?.data?.searchAuctions?.nodes.map((node) => ({
        ...node,
        auctionDate: dayjs(node.auctionDate).format('DD.MM.YYYY'),
      })),
    [queryResult?.data],
  )

  const handleSearch = useCallback((event) => {
    const value = event?.target?.value
    setAuctionsFilter((prev) => ({
      ...prev,
      search: value,
      ...defaultTableFilterArgs,
    }))
  }, [])

  const tableActionsTemplate = (rowData: Auction) => {
    return (
      <Link
        to={`/inApp/auctions/${rowData._id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button icon="pi pi-external-link" severity="secondary" text />
      </Link>
    )
  }

  const handleClickSale = useCallback(
    ({ value }) => {
      value?._id && history.push(`/inApp/auctions/${value._id}`)
    },
    [history],
  )

  const loadNextPage = useCallback(async () => {
    const pageInfo = queryResult?.data?.searchAuctions?.pageInfo
    const paginationLimit = pageInfo?.limit || LIMIT
    const paginationSkip = (pageInfo?.skip || 0) + paginationLimit

    await queryResult.fetchMore({
      variables: {
        args: {
          ...auctionsFilterArgs,
          paginationArgs: {
            skip: paginationSkip,
            limit: paginationLimit,
          },
        },
      },
    })
  }, [queryResult, auctionsFilterArgs])

  return (
    <div className="h-full flex flex-col">
      <TableFilterHeader
        title={t('auctions')}
        resultsCount={queryResult?.data?.searchAuctions.pageInfo.total || 0}
        filterOptions={
          <SearchInput
            placeholder={t('search')}
            value={`${auctionsFilter?.search || ''}`}
            onChange={handleSearch}
            onClear={() => handleSearch(null)}
          />
        }
      />
      {queryResult.loading ? (
        <Loading />
      ) : (
        <>
          <InfiniteScrollTable
            handleLoadNextPage={loadNextPage}
            limit={queryResult?.data?.searchAuctions?.pageInfo.limit ?? LIMIT}
            hasNextPage={
              queryResult?.data?.searchAuctions?.pageInfo.hasNextPage ?? true
            }
            dataKey="_id"
            value={auctions ?? []}
            onClickRow={handleClickSale}
          >
            <Column
              field="auctionDate"
              style={{ width: '50px' }}
              header={t('auction.auction_date')}
            />
            <Column
              field="company.name"
              style={{ width: '50px' }}
              header={t('company.singular')}
            />
            <Column
              style={{ width: '50px' }}
              header={t('status')}
              body={auctionStatusTemplate}
            />
            <Column
              field="auctionElements.length"
              style={{ width: '50px' }}
              header={t('auction.auction_element_length')}
            />
            <Column
              style={{ width: '50px', textAlign: 'right' }}
              body={tableActionsTemplate}
            />
          </InfiniteScrollTable>

          <Taskbar>
            <Link to={`/inApp/auctions/create`}>
              <Button label={t('auction.add_auction')} icon="pi pi-plus" />
            </Link>
          </Taskbar>
        </>
      )}
    </div>
  )
}

const auctionStatusTemplate = (rowData: Auction) => {
  const tagStyle =
    rowData.status === EAuctionStatus.Open
      ? { background: '#22C55E' }
      : rowData.status === EAuctionStatus.Started
        ? { background: '#3B82F6' }
        : { background: '#EF4444' }

  return <Tag style={tagStyle} value={rowData.status} />
}

export default AuctionsList
