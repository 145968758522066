import styled from '@emotion/styled'
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import TableValue from '@/components/TableValue'
import ClearIcon from '@/images/clear.svg'
import DecreaseIcon from '@/images/decrese-price.svg'
import SaveIcon from '@/images/save.svg'
import {
  BuyerDataArgs,
  Deal,
  DealItem,
  EBusinessUnit,
  InternalSaleDataArgs,
  Maybe,
  Partner,
} from '@/schemaTypes'
import { getLatestEventForStatus } from '@/utils/deal'
import { formatFuncForPrices } from '@/utils/misc'
import { Timezone, getStartOfDayForTimezone } from '@/utils/time'
import styles from './ItemValorizationSalesTable.module.scss'

export interface ItemValorizationSalesTableProps {
  deal: Deal
  directPurchaseFactor: number
  timezone: Timezone
  item: DealItem
  onUpdateInternalSaleData: (internalSaleData: InternalSaleDataArgs) => void
  partners: Partner[]
  businessUnit: EBusinessUnit
}

type BuyerDataDisplayData = Maybe<
  Pick<BuyerDataArgs, 'name' | 'partnerId'> | undefined
>

export function ItemValorizationSalesTable({
  deal,
  directPurchaseFactor,
  item,
  onUpdateInternalSaleData,
  timezone,
  partners,
  businessUnit,
}: ItemValorizationSalesTableProps) {
  const { t } = useTranslation()

  const { internalSaleData, itemValorizationCalculation } = item
  const valorizationHistory =
    itemValorizationCalculation?.valorizationHistory ?? []

  const latestSale =
    valorizationHistory && valorizationHistory.length > 0
      ? valorizationHistory.find((entry) => entry.cashflow >= 0)
      : null

  const _purchasedDirectly = internalSaleData
    ? internalSaleData.purchasedDirectly
    : false
  const _externalSalePrice = latestSale?.cashflow ?? null
  const _externalSaleDate = latestSale?.date ?? null
  const _isCashyPrice = internalSaleData?.isCashyPrice ?? false
  const verifiedEvent = getLatestEventForStatus(deal, 'DealVerifiedEvent')
  const closedEvent = getLatestEventForStatus(deal, 'DealClosedEvent')
  const soldInternEvent = getLatestEventForStatus(deal, 'DealSoldInternEvent')
  const soldExternEvent = getLatestEventForStatus(deal, 'DealSoldExternEvent')
  const paybackConfirmedEvent = getLatestEventForStatus(
    deal,
    'DealPaybackConfirmedEvent',
  )
  const extensionConfirmedEvent = getLatestEventForStatus(
    deal,
    'DealExtensionConfirmedEvent',
  )
  let _internalSalePrice = internalSaleData ? internalSaleData.price : null
  let _internalSaleDate = internalSaleData
    ? closedEvent
      ? closedEvent.timestamp
      : internalSaleData.date
    : null

  if (
    _purchasedDirectly &&
    closedEvent &&
    (!_internalSalePrice || !_internalSaleDate) &&
    item.values.closed
  ) {
    _internalSalePrice =
      directPurchaseFactor * (item.values.closed?.loanToValue ?? 0)
    _internalSaleDate = closedEvent.timestamp
  }

  const [internalSalePrice, setInternalSalePrice] = useState(_internalSalePrice)
  const [externalSalePrice, setExternalSalePrice] = useState(_externalSalePrice)
  const [internalSaleDate, setInternalSaleDate] = useState<Date | null>(
    _internalSaleDate,
  )
  const [externalSaleDate, setExternalSaleDate] = useState<Date | null>(
    _externalSaleDate,
  )
  const [purchasedDirectly, setPurchasedDirectly] = useState(_purchasedDirectly)
  const [isCashyPrice, setIsCashyPrice] = useState(_isCashyPrice)
  const [buyerData, setBuyerData] = useState<BuyerDataDisplayData>(
    item.internalSaleData?.buyerData ?? null,
  )

  const internalDisabled =
    !!soldInternEvent ||
    !!closedEvent?.isClosedByAuctionId ||
    !!soldExternEvent ||
    !!paybackConfirmedEvent ||
    !!extensionConfirmedEvent ||
    businessUnit === EBusinessUnit.Car

  useEffect(() => {
    setInternalSaleDate(_internalSaleDate)
    setInternalSalePrice(_internalSalePrice)
    setExternalSaleDate(_externalSaleDate)
    setExternalSalePrice(_externalSalePrice)
    setPurchasedDirectly(_purchasedDirectly)
    setIsCashyPrice(_isCashyPrice)
  }, [
    _internalSaleDate,
    _internalSalePrice,
    _externalSaleDate,
    _externalSalePrice,
    _purchasedDirectly,
    _isCashyPrice,
  ])

  const valorizationRows = [
    {
      label: t('internal_valorization'),
      salePrice: internalSalePrice,
      originPrice:
        internalSaleData?.priceHistories?.length &&
        internalSaleData.priceHistories[0].price,
      date: internalSaleDate,
      onChangePrice: (value) => setInternalSalePrice(value),
      onChangeDate: (date) => {
        const startOfDay = getStartOfDayForTimezone(date, timezone)

        setInternalSaleDate(startOfDay)
      },
      onReset: () => {
        setInternalSalePrice(null)
        setInternalSaleDate(null)
        setPurchasedDirectly(null)
        setIsCashyPrice(false)

        onUpdateInternalSaleData({
          date: null,
          price: null,
          purchasedDirectly: null,
          isCashyPrice: false,
        })
      },
      onSave: () => {
        const internalSaleDataArgs: InternalSaleDataArgs = {
          date: purchasedDirectly && !closedEvent ? null : internalSaleDate,
          price: purchasedDirectly && !closedEvent ? null : internalSalePrice,
          purchasedDirectly: purchasedDirectly || null,
          isCashyPrice,
        }

        if (buyerData?.partnerId || buyerData?.name) {
          internalSaleDataArgs.buyerData = {}

          if (buyerData.partnerId) {
            internalSaleDataArgs.buyerData.partnerId = buyerData.partnerId
          } else if (buyerData.name) {
            internalSaleDataArgs.buyerData.name = buyerData.name
          }
        }

        onUpdateInternalSaleData(internalSaleDataArgs)
      },
      disabled: internalDisabled,
      showPurchasedDirectlyBox: true,
      showBuyerData: true,
      onChangedPurchasedDirectly: (e) => {
        const checked = e.target.checked

        setPurchasedDirectly(checked)

        if (checked) {
          setInternalSalePrice(
            !closedEvent
              ? null
              : directPurchaseFactor * (item.values.closed?.loanToValue ?? 0),
          ) // TODO
          setInternalSaleDate(!closedEvent ? null : closedEvent.timestamp)
          setIsCashyPrice(false)
        } else {
          if (!closedEvent) {
            setInternalSalePrice(null)
            setInternalSaleDate(null)
          }
        }
      },
      purchasedDirectly,
    },
    {
      label: t('external_valorization'),
      salePrice: externalSalePrice,
      date: externalSaleDate,
      disabled: true,
    },
  ] as any[]

  const onBuyerDataChange = useCallback(
    (value?: string | null) => {
      const buyerData =
        typeof value === 'string' && value.length > 0
          ? {
              name: value,
              partnerId: partners.find((partner) => partner.name === value)
                ?._id,
            }
          : null

      setBuyerData(buyerData)
    },
    [partners],
  )

  const onChangeCashyPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'true') {
      setIsCashyPrice(true)
      setBuyerData(null)
    } else setIsCashyPrice(false)
  }

  const partnerOptions: BuyerDataDisplayData[] = partners.map((partner) => ({
    name: partner.name,
    partnerId: partner._id,
  }))

  return (
    <Table className={`u-mb-sm ${styles.valorizationTable}`} size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('description')}</TableCell>
          <TableCell align="right">{t('sales_price')}</TableCell>
          <TableCell align="right">{t('date')}</TableCell>
          <TableCell align="right">{t('direct_purchase')}</TableCell>
          <TableCell align="left">{t('buyer')}</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {valorizationRows.map((row, index) => (
          <TableRow key={row.label}>
            <TableCell component="th" scope="row">
              {row.label}
            </TableCell>
            <TableCell align="right">
              {row.purchasedDirectly && !closedEvent ? (
                '-'
              ) : (
                <SalePriceWrapper>
                  <TableValue
                    value={row.salePrice}
                    type="float"
                    onChange={
                      !row.disabled && !row.purchasedDirectly
                        ? row.onChangePrice
                        : undefined
                    }
                    formatFunc={formatFuncForPrices}
                    minValue={0}
                    addPaddingRight={row.disabled}
                  />
                  {index === 0 && row.originPrice && (
                    <Tooltip
                      title={`${t('origin_price')}: ${formatFuncForPrices(row.originPrice)}$`}
                      placement="top"
                    >
                      <ReactSVG
                        className={styles.decreaseIcon}
                        src={DecreaseIcon}
                      />
                    </Tooltip>
                  )}
                </SalePriceWrapper>
              )}
            </TableCell>
            <TableCell align="right">
              {row.purchasedDirectly && !closedEvent ? (
                '-'
              ) : (
                <TableValue
                  value={row.date}
                  type="date"
                  onChange={
                    !row.disabled && !row.purchasedDirectly
                      ? row.onChangeDate
                      : undefined
                  }
                  minDate={verifiedEvent?.timestamp}
                  addPaddingRight={row.disabled}
                />
              )}
            </TableCell>
            <TableCell align="right">
              {row.showPurchasedDirectlyBox && (
                <Checkbox
                  checked={row.purchasedDirectly || false}
                  disabled={row.disabled}
                  onChange={row.onChangedPurchasedDirectly}
                />
              )}
            </TableCell>
            <TableCell align="right">
              {row.showBuyerData && (
                <div className={styles.flexWrapper}>
                  <RadioGroup
                    aria-label="itemsList"
                    name="list"
                    row
                    onChange={onChangeCashyPrice}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Cashy"
                      checked={isCashyPrice}
                      disabled={!!purchasedDirectly || row.disabled}
                    />
                    <Radio
                      disabled={row.disabled}
                      value={false}
                      checked={!isCashyPrice}
                    />
                  </RadioGroup>
                  <Autocomplete
                    id="buyerName"
                    freeSolo={true}
                    options={partnerOptions}
                    getOptionLabel={(option) => option?.name ?? ''}
                    style={{ width: 200 }}
                    value={
                      buyerData
                        ? {
                            name: buyerData.partnerId
                              ? partnerOptions.find(
                                  (partner) =>
                                    partner?.partnerId === buyerData.partnerId,
                                )?.name ?? ''
                              : buyerData.name,
                            partnerId: buyerData.partnerId,
                          }
                        : null
                    }
                    onChange={(event, value) => {
                      onBuyerDataChange(
                        typeof value === 'string' ? value : value?.name,
                      )
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={''}
                        onChange={(event) => {
                          onBuyerDataChange(event.target.value)
                        }}
                        placeholder="Other..."
                      />
                    )}
                    disabled={isCashyPrice}
                  />
                </div>
              )}
            </TableCell>
            <TableCell>
              {!row.disabled && (
                <div>
                  <div
                    onClick={
                      row.disabled || !row.onReset ? () => {} : row.onReset
                    }
                    className={`o-media__fixed u-inline-block ${row.onReset ? '' : styles.hidden}`}
                  >
                    <ReactSVG
                      className={`${styles.infoIcon} u-mr-8`}
                      src={ClearIcon}
                    />
                  </div>
                  <div
                    onClick={
                      row.disabled || !row.onSave ? () => {} : row.onSave
                    }
                    className={`o-media__fixed u-inline-block ${row.onSave ? '' : styles.hidden}`}
                  >
                    <ReactSVG
                      className={`${styles.infoIcon} u-ml-8`}
                      src={SaveIcon}
                    />
                  </div>
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const SalePriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`

export default ItemValorizationSalesTable
