import { ApolloQueryResult } from '@apollo/client'
import { MenuItem, Paper, Select } from '@material-ui/core'
import React, { Fragment, FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Deal,
  DealTransportUpdateArgs,
  ETransportMode,
  ETransportType,
  Query,
  TransportData,
} from '@/schemaTypes'
import ShipmentInfo from './ShipmentInfo'
import ShopInfo from './ShopInfo'

export interface TransportInfoProps {
  deal: Deal
  transportType: ETransportType
  transportData: TransportData | undefined | null
  isEditable: boolean
  refetchDeal: () => Promise<
    ApolloQueryResult<{ getDealById: Query['getDealById'] }>
  >
}

const TransportInfo: FunctionComponent<TransportInfoProps> = (props) => {
  const { deal, transportType, isEditable, refetchDeal } = props
  const { t } = useTranslation()

  const validTransportTypes = isEditable
    ? [
        ETransportMode.Shop,
        ETransportMode.StandardShipment,
        ETransportMode.Storebox,
      ]
    : [
        ETransportMode.Shop,
        ETransportMode.StandardShipment,
        ETransportMode.Auction,
        ETransportMode.Extension,
        ETransportMode.ExtensionAuction,
        ETransportMode.PrivateSale,
        ETransportMode.Storebox,
      ]
  const [newTransportArgs, setNewTransportArgs] =
    useState<DealTransportUpdateArgs>({
      dealId: deal._id,
      notifyCustomer: true,
      transportMode:
        transportType === ETransportType.Pickup
          ? deal.pickupData
            ? deal.pickupData.transportMode
            : ETransportMode.Shop
          : deal.dropoffData
            ? deal.dropoffData.transportMode
            : ETransportMode.Shop,
      transportType: transportType,
    })

  const handleChangeTransportMode = (newMode) => {
    let transportTemp = { ...newTransportArgs }

    if (newMode === ETransportMode.Shop) {
      transportTemp = { ...newTransportArgs, shippingLabelCreateArgs: null }
    } else if (newMode === ETransportMode.StandardShipment) {
      transportTemp = { ...newTransportArgs, shippingLabelCreateArgs: null }
    }

    setNewTransportArgs({ ...transportTemp, transportMode: newMode })
  }

  return (
    <Fragment>
      <Paper className="u-p-sm u-12/12 u-mb-sm">
        <div>
          {t('transport_type')}:
          <Select
            disabled={!isEditable}
            style={{ marginLeft: '2.3rem' }}
            // When we do not have a transportData object on deal level,
            // it means that transport is SHOP
            // We try to handle that by a migration on old deals, but I prefer to keep this code here
            value={newTransportArgs.transportMode}
            inputProps={{
              id: 'TRANSPORT_TYPE_SELECT',
            }}
            onChange={(e) =>
              handleChangeTransportMode(
                e.target.value as ETransportMode | string,
              )
            }
            variant="standard"
          >
            {validTransportTypes.map((name) => (
              <MenuItem id={name} key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>

        {newTransportArgs?.transportMode ===
          ETransportMode.StandardShipment && (
          <ShipmentInfo
            deal={deal}
            isEditable={isEditable}
            newTransportArgs={newTransportArgs}
            setNewTransportArgs={setNewTransportArgs}
            refetchDeal={refetchDeal}
          />
        )}

        {newTransportArgs?.transportMode === ETransportMode.Shop && (
          <ShopInfo
            isEditable={isEditable}
            newTransportArgs={newTransportArgs}
            refetchDeal={refetchDeal}
          />
        )}

        {/* {newTransportArgs?.transportMode === ETransportMode.Storebox && deal.pickupData?.storeboxData && (
          <StoreboxInfo pickupData={deal.pickupData.storeboxData} />
        )} */
        /** TEMPORARY COMMENTED, KEEP ABOVE CODES FOR FURTHER USE */}
      </Paper>
    </Fragment>
  )
}

export default TransportInfo
