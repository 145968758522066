import { SubscriptionHookOptions, useSubscription } from '@apollo/client'
import gql from 'graphql-tag'
import { Subscription } from '@/schemaTypes'
import { customDeal, customDealItem } from './customDealFragments'

export const CUSTOM_DEAL_ADDED = gql`
  subscription customDealAdded {
    customDealAdded {
      ...CustomDealFragment
      items {
        ...CustomDealItemFragment
      }
    }
  }
  ${customDeal}
  ${customDealItem}
`

export type TCustomDealAddedData = {
  customDealAdded: Subscription['customDealAdded']
}
type TVariables = {}

export function useCustomDealAdded(
  opts: SubscriptionHookOptions<TCustomDealAddedData, TVariables> = {},
) {
  const subscriptionResult = useSubscription<TCustomDealAddedData, TVariables>(
    CUSTOM_DEAL_ADDED,
    {
      variables: {},
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  return {
    ...subscriptionResult,
    customDealAdded: subscriptionResult?.data?.customDealAdded,
  }
}
