import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from '@/domains/importExport/components/ImportExport.module.scss'

function ImportExport() {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <h1>{t('import_export')}</h1>
      <br />
      <div className="u-mb-sm">
        <Link to="/inApp/importExport/products">
          {t('imports_and_exports')}
        </Link>
      </div>
    </div>
  )
}

export default ImportExport
