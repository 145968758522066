import { auctionFragments } from '../auctionDetails/auctionFragments'
import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { ELanguageCode, Query, QueryGetAuctionByIdArgs } from '@/schemaTypes'

const GET_AUCTION_BY_ID = gql`
  query getAuctionById($auctionId: ObjectId!, $languageCode: ELanguageCode!) {
    getAuctionById(auctionId: $auctionId) {
      ...AuctionDetailsFragment
    }
  }
  ${fragments.customDealFormQuestion}
  ${fragments.formAnswers}
  ${auctionFragments.auctionDetails}
  ${fragments.address}
`

type TData = { getAuctionById: Query['getAuctionById'] }
type TVariables = { languageCode?: ELanguageCode } & QueryGetAuctionByIdArgs

export function useGetAuctionById(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_AUCTION_BY_ID, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, auction: queryResult.data?.getAuctionById }
}
