import { ItemCategory } from '@/schemaTypes'

export function GetParentCategories(
  categoryId: string,
  categories: ItemCategory[],
) {
  let _categoryId = categoryId
  const parentCategories: ItemCategory[] = []

  const shouldContinue = true
  do {
    const itemCategory = categories.find(
      (category) => category._id === _categoryId,
    )

    if (!itemCategory) {
      return parentCategories
    }

    if (!parentCategories.includes(itemCategory)) {
      parentCategories.push(itemCategory)
      const parent = categories.find(
        (category) => category._id === itemCategory.parentId,
      )

      _categoryId = parent?._id
    }
  } while (shouldContinue)

  return parentCategories
}
