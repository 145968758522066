import { Paper, Typography } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import ModelsPicker from '@/components/ModelsPicker'
import { useGetItemCategories } from '@/domains/itemCategories/hooks'
import ShopsDetails from '@/domains/shops/components/ShopsDetails'
import {
  useAddItemCategoryId,
  useGetShop,
  useRemoveItemCategoryId,
  useUpdateShop,
} from '@/domains/shops/hooks'
import {
  useConfirmDeleteMutationShowingErrors,
  useMutationShowingErrors,
} from '@/hooks'
import { useGetActivePartners } from '@/hooks/getActivePartners'
import { MutationCreateShopArgs } from '@/schemaTypes'

export interface ShopsEditProps {
  shopId: string
}

export function ShopsEdit(props: ShopsEditProps) {
  const { shopId } = props
  const { t } = useTranslation()
  const history = useHistory()

  const [updateIsInProgress, setUpdateIsInProgress] = useState(false)

  const { shop, queryResult: getShopQueryResult } = useGetShop({
    variables: {
      shopId,
    },
  })

  const { itemCategories } = useGetItemCategories()
  const topLevelCategories = useMemo(
    () => itemCategories.filter((category) => !category.parentId),
    [itemCategories],
  )

  const partnersResult = useGetActivePartners()
  const partners = partnersResult.partners ?? []

  const updateShop = useMutationShowingErrors({
    mutation: useUpdateShop(),
    successMessage: t('shop.shop_updated'),
  })

  const removeItemCategoryId = useConfirmDeleteMutationShowingErrors({
    mutation: useRemoveItemCategoryId(),
  })

  const addItemCategoryId = useMutationShowingErrors({
    mutation: useAddItemCategoryId(),
  })

  const onAddItemCategory = async (itemCategoryId: string) => {
    addItemCategoryId({
      variables: {
        shopId,
        itemCategoryId,
      },
      onCompleted: () => getShopQueryResult.refetch(),
    })
  }

  const onRemoveItemCategory = async (itemCategoryId: string) => {
    removeItemCategoryId({
      variables: {
        shopId,
        itemCategoryId,
      },
      onCompleted: () => getShopQueryResult.refetch(),
    })
  }

  const onClickItemCategory = (itemCategoryId: string) => {
    history.push(`/inApp/configurations/itemCategories/edit/${itemCategoryId}`)
  }

  const onSave = (shop: MutationCreateShopArgs) => {
    setUpdateIsInProgress(true)
    updateShop({
      variables: {
        _id: shopId,
        ...shop,
      },
      onCompleted: () => {
        setUpdateIsInProgress(false)
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={shopId}
        modelName={'shop.plural'}
        mode={'edit'}
      />
      <Typography variant="h5" gutterBottom>
        {t('shop.shop_edit')}
      </Typography>
      {getShopQueryResult.loading ? (
        <Loading />
      ) : (
        shop && (
          <ShopsDetails
            shopRefetch={getShopQueryResult.refetch}
            shop={shop}
            onSave={onSave}
            partners={partners}
            addOrUpdateIsInProgress={updateIsInProgress}
          />
        )
      )}
      <div className="u-pv">
        <ModelsPicker
          label={t('restrict_to_categories')}
          options={topLevelCategories.map((ic) => ({
            id: ic._id,
            label: ic.name,
          }))}
          values={(shop?.itemCategories ?? []).map((ic) => ({
            id: ic._id,
            label: ic.name,
          }))}
          onAdd={(id) => onAddItemCategory(id)}
          onDelete={(id) => onRemoveItemCategory(id)}
          onClick={(id) => onClickItemCategory(id)}
        />
      </div>
    </Paper>
  )
}

export default ShopsEdit
