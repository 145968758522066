import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import LogoImg from '@/images/monogram_01_purple.png'

const ExpiredLink = () => {
  const { t } = useTranslation()

  return (
    <Main className="flex justify-center items-center w-full">
      <div className="flex flex-col items-center w-full">
        <ImageStyled src={LogoImg} alt="Cashy" className="relative mb-6" />
        <Card className="mx-auto">
          <h4 className="text-xl font-bold text-center mb-1">
            {t('expired_link_title')}
          </h4>
          <h5 className="text-md text-center">{t('expired_link_desc')}</h5>
        </Card>
      </div>
    </Main>
  )
}

const Main = styled.div`
  height: 100vh;
  background: white;
`

const ImageStyled = styled.img`
  width: 130px;
`

const Card = styled.div`
  width: 48rem;
  @media (max-width: 28rem) {
    width: 100%;
  }
`

export default ExpiredLink
