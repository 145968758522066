import { itemFragments } from '../itemDetails/itemFragments'
import { QueryHookOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import useDebouncedQuery from '@/hooks/useDebouncedQuery'
import { Query, QuerySearchItemsArgs } from '@/schemaTypes'

const SEARCH_ITEMS = gql`
  query searchItems($args: ItemsFiltersArgs!) {
    searchItems(args: $args) {
      nodes {
        _id
        createdAt
        policeNumber
        isDamageCase
        isForPersonalUse
        isInvestmentGold
        companyId
        media {
          ...FileMetadataFragment
        }
        note
        serialNumber
        storageFacilityId
        updatedAt
        vehicleData {
          vehicleProperties {
            make
            model
            regdate
            body
            facelift
            seats
            engine
            transmission
            odometer
            otherVehicleProperties {
              value
              name
            }
          }
          vin
          indicataId
          carPriceLastFetched
          vinLastFetched
          hasTypeCertificate
          hasApprovalCertificate
          has57aGutachten
          has57aGutachtenDue
          hasSecondKey
          hasSaleContract
        }
        dealsHistory {
          dealId
          algoliaReference
          title
          material
          answers {
            questionId
            selectedOptionIndex
            rangeValue
          }
          questions {
            ...ItemQuestionFragment
          }
          questionIds
          itemCategoryId
          values {
            verified {
              currentMarketValue
              predictedMarketValue
              adjustedMarketValue
              loanToValue
              maxMarketValue
            }
          }
        }
        isInStore
        itemNotifications
        lastBookingNumber
        bookingNumbers
        storageHistory {
          storageUnits {
            lvl1
            lvl2
            lvl3
            lvl4
          }
          createdAt
          timestamp
          storageStatus
          employeeId
          note
        }
        storageLabel
        events {
          ...ItemEventFragment
        }
        internalSaleData {
          date
          price
          purchasedDirectly
        }
      }
      pageInfo {
        skip
        limit
        hasPrevPage
        hasNextPage
        total
      }
    }
  }
  ${fragments.fileMetadata}
  ${itemFragments.itemQuestionFragment}
  ${itemFragments.itemEvent}
`

type TData = { searchItems: Query['searchItems'] }
type TVariables = QuerySearchItemsArgs

export function useSearchItems(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useDebouncedQuery<TData, TVariables>(
    SEARCH_ITEMS,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
    200,
  )

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
