import { useQuery } from '@apollo/client'
import Loading from '@/components/Loading'
import CustomerBlacklistDetailPage from '@/redesign/domains/customerBlacklists/components/CustomerBlacklistDetail'
import { GetCustomerBlacklistById_Query } from '@/redesign/domains/customerBlacklists/helpers/customerBlacklists.graphql'

const CustomerBlacklistEditPage = ({
  customerBlacklistId,
}: {
  customerBlacklistId: string
}) => {
  const { data } = useQuery(GetCustomerBlacklistById_Query, {
    variables: {
      id: customerBlacklistId,
    },
    skip: !customerBlacklistId,
  })

  return data?.getCustomerBlacklistById ? (
    <CustomerBlacklistDetailPage
      customerBlacklist={data.getCustomerBlacklistById}
    />
  ) : (
    <Loading />
  )
}

export default CustomerBlacklistEditPage
