import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { EOrderBy, Query, QueryGetItemQuestionsArgs } from '@/schemaTypes'

const GET_ITEM_QUESTIONS = gql`
  query getItemQuestions($opts: ItemQuestionOpts) {
    getItemQuestions(opts: $opts) {
      _id
      order
      questionType
      valueMode
      titleKey
      infoKey
      isInternal
      singleChoiceOptions {
        labelKey
        infoKey
        categoryValues
        conditionValue
        isDefaultValue
      }
      rangeData {
        minValue
        maxValue
        defaultValue
        unitPrice
        unitKey
      }
      predictionTag
      itemCategoryIds
    }
  }
`

type TData = { getItemQuestions: Query['getItemQuestions'] }
type TVariables = QueryGetItemQuestionsArgs

export function useGetItemQuestions(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResultItemQuestion = useQuery<TData, TVariables>(
    GET_ITEM_QUESTIONS,
    {
      variables: {
        opts: {
          order: {
            order: EOrderBy.Asc,
          },
        },
      },
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResultItemQuestion.error)

  return {
    queryResultItemQuestion,
    itemQuestions: queryResultItemQuestion.data?.getItemQuestions || [],
  }
}
