import React, { Fragment, FunctionComponent, useMemo } from 'react'
import { useEffect } from 'react'
import { useGetCompany } from '@/domains/companies/hooks'
import { useGetCustomer } from '@/domains/customers/hooks'
import { useGetShops } from '@/domains/shops/hooks'
import {
  Deal,
  ECountry,
  EOrderBy,
  EShippingLabelDirection,
  EShippingLabelEntity,
  EShippingLabelFileSource,
  EStandardShipmentType,
  IAddress,
  ICustomerAddress,
  Item,
  ShippingLabelCreateArgs,
} from '@/schemaTypes'
import { createIAddress } from '@/utils/address'
import { assertDealConnectedToRegisteredCustomer } from '@/utils/deal'
import PostAddress from './PostAddress'
import styles from './ShippingLabel.module.scss'

interface SenderReceiverProps {
  deal: Deal
  item?: Item
  directionType: EShippingLabelDirection
  entityType: EShippingLabelEntity
  fileSource: EShippingLabelFileSource
  shippingPartner: EStandardShipmentType

  createSenderArgs: IAddress
  setCreateSenderArgs: (args: IAddress) => void

  createReceiverArgs: IAddress
  setCreateReceiverArgs: (args: IAddress) => void

  createArgs: ShippingLabelCreateArgs | undefined
  setCreateArgs: (args: ShippingLabelCreateArgs) => void

  senderAddressIsValid: boolean
  setSenderAddressIsValid: (value: boolean) => void
  receiverAddressIsValid: boolean
  setReceiverAddressIsValid: (value: boolean) => void
  betweenShops?: boolean
  onChangeDestinationShopId?: (shopId: string | undefined) => void
}

const SenderReceiver: FunctionComponent<SenderReceiverProps> = (props) => {
  const {
    deal,
    item,
    directionType,
    entityType,
    fileSource,
    shippingPartner,
    createSenderArgs,
    setCreateSenderArgs,
    createReceiverArgs,
    setCreateReceiverArgs,
    createArgs,
    setCreateArgs,
    senderAddressIsValid,
    setSenderAddressIsValid,
    receiverAddressIsValid,
    setReceiverAddressIsValid,
    betweenShops,
    onChangeDestinationShopId,
  } = props

  assertDealConnectedToRegisteredCustomer(deal)

  const { customer } = useGetCustomer({
    variables: {
      customerId: deal.customer._id,
    },
  })

  const companyId = item ? item?.companyId : deal?.company?._id

  const { company } = useGetCompany({
    variables: {
      companyId,
    },
    skip: !deal,
  })

  const { shops } = useGetShops({
    variables: {
      opts: {
        filter: {
          includeDisabled: false,
        },
        order: {
          _id: EOrderBy.Desc,
        },
      },
    },
    skip: !deal,
  })

  const primaryShop = useMemo(() => {
    if (item?.storageFacilityId) {
      return shops.find(
        (c) => item.storageFacilityId === c.mainStorageFacilityId,
      )
    }

    return shops.find((c) => c._id === company?.primaryShopLocationId)
  }, [item, shops, company])

  useEffect(() => {
    if (directionType === EShippingLabelDirection.Receive) {
      const customerNameAndAddress = getProperCustomerAddress(
        entityType === EShippingLabelEntity.Deal,
      )
      const { customerAddress, customerName } = customerNameAndAddress
      setCreateReceiverArgs(createIAddress(primaryShop?.address))
      setCreateSenderArgs(createIAddress(customerAddress))
      setCreateArgs({
        ...createArgs,
        dealId: deal._id,
        itemId: item ? item._id : undefined,
        entity: entityType,
        shippingLabelFileSource: fileSource,
        shippingPartner: shippingPartner,
        direction: directionType,
        receiverName: primaryShop?.name,
        // receiverCompanyName: company?.companyName, // This does not make sense. But maybe we can use it in future.
        receiverAddress: createIAddress(primaryShop?.address),
        senderName: customerName,
        senderAddress: createIAddress(customerAddress),
        senderCompanyName: customerAddress?.companyName,
        receiverShopId: primaryShop?._id,
      })
    } else if (directionType === EShippingLabelDirection.Send) {
      const customerNameAndAddress = getProperCustomerAddress(false)
      const { customerAddress, customerName } = customerNameAndAddress
      setCreateSenderArgs(createIAddress(primaryShop?.address))
      setCreateReceiverArgs(
        createIAddress(betweenShops ? primaryShop?.address : customerAddress),
      )
      const receiverName = !betweenShops
        ? createReceiverArgs?.name ?? customerName
        : primaryShop?.name ?? ''

      if (onChangeDestinationShopId) {
        onChangeDestinationShopId(primaryShop?._id)
      }

      setCreateArgs({
        ...createArgs,
        dealId: deal._id,
        itemId: item ? item._id : undefined,
        entity: entityType,
        shippingLabelFileSource: fileSource,
        direction: directionType,
        shippingPartner: shippingPartner,
        senderAddress: createIAddress(primaryShop?.address),
        senderName: primaryShop?.name,
        // senderCompanyName:company?.companyName // This does not make sense. But maybe we can use it in future.
        receiverAddress: createReceiverArgs,
        receiverName: receiverName,
        receiverCompanyName: createReceiverArgs?.companyName,
        senderShopId: primaryShop?._id,
      })
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, customer, primaryShop])

  useEffect(() => {
    setCreateArgs({
      ...createArgs,
      senderAddress: createIAddress(createSenderArgs),
      receiverAddress: createIAddress(createReceiverArgs),
    })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSenderArgs, createReceiverArgs])

  const getProperCustomerAddress = (useAddressSavedOnDeal: boolean) => {
    let customerAddress: ICustomerAddress | IAddress | undefined = undefined
    let customerName: string = ''

    // If the entity is deal, address should be fetched from customer level
    if (entityType === EShippingLabelEntity.Deal) {
      if (useAddressSavedOnDeal) {
        customerAddress = deal.pickupData?.standardShippingData?.address
        customerName = `${deal.customer.firstname} ${deal.customer.lastname}`
      } else {
        if (customer) {
          if (customer.addresses && customer.addresses.length > 0) {
            const deliveryAddresses = customer.addresses.filter(
              (c) => c.isDeliveryAddress,
            )
            if (deliveryAddresses && deliveryAddresses.length > 0) {
              customerAddress = deliveryAddresses[0]
              customerName = deliveryAddresses[0].name ?? ''
            } else {
              customerAddress = customer.addresses[0]
              customerName = customer.addresses[0].name ?? ''
            }
          }

          // If we could not find the name on Address Object, we use the customer name!
          if (!customerName || customerName.length === 0) {
            customerName =
              (customer?.firstname ?? '') + ' ' + (customer?.lastname ?? '')
          }
        }
      }
    }
    // If entity is item, we should find the address from latest ValorizationEntry
    else {
      if (item && deal.items) {
        const dealItems = deal.items.filter(
          (c) => c._id.toString() === item?._id.toString(),
        )

        if (dealItems && dealItems.length > 0 && dealItems[0]) {
          if (
            dealItems[0].itemValorizationCalculation &&
            dealItems[0].itemValorizationCalculation.valorizationHistory
          ) {
            const latestValorizationsWithAddress =
              dealItems[0].itemValorizationCalculation.valorizationHistory.filter(
                (c) => c.cashflow > 0,
              )

            if (
              latestValorizationsWithAddress &&
              latestValorizationsWithAddress.length > 0
            ) {
              const finalCustomerAddress =
                latestValorizationsWithAddress[0].buyerShippingAddress ??
                latestValorizationsWithAddress[0].buyerBillingAddress
              customerAddress = {
                city: finalCustomerAddress?.city,
                countryCode:
                  finalCustomerAddress?.countryCode ??
                  (finalCustomerAddress?.country?.toUpperCase() as ECountry), // Sometimes we get countryCode in the country field from refurbed/ebay
                door: finalCustomerAddress?.door,
                houseNumber: finalCustomerAddress?.houseNumber,
                stairway: finalCustomerAddress?.stairway,
                state: finalCustomerAddress?.state,
                street: finalCustomerAddress?.street,
                zipCode: finalCustomerAddress?.zipCode,
                name: finalCustomerAddress?.name,
                companyName: finalCustomerAddress?.companyName,
              }
              customerName =
                latestValorizationsWithAddress[0].buyerShippingAddress &&
                latestValorizationsWithAddress[0].buyerShippingAddress.name &&
                latestValorizationsWithAddress[0].buyerShippingAddress.name
                  .length > 0
                  ? latestValorizationsWithAddress[0].buyerShippingAddress.name
                  : latestValorizationsWithAddress[0].buyerBillingAddress &&
                      latestValorizationsWithAddress[0].buyerBillingAddress
                        .name &&
                      latestValorizationsWithAddress[0].buyerBillingAddress.name
                        .length > 0
                    ? latestValorizationsWithAddress[0].buyerBillingAddress.name
                    : latestValorizationsWithAddress[0].buyerName ?? ''
            }
          }
        }
      }
    }

    return { customerAddress, customerName }
  }

  if (!customer || !company) return null

  return (
    <Fragment>
      <div className={styles.address}>
        {createArgs && (
          <PostAddress
            label="SENDER"
            createAddressArgs={createSenderArgs}
            setCreateAddressArgs={setCreateSenderArgs}
            createArgs={createArgs}
            setCreateArgs={setCreateArgs}
            addressIsValid={senderAddressIsValid}
            setAddressIsValid={setSenderAddressIsValid}
            shops={
              directionType === EShippingLabelDirection.Send ? shops : null
            }
            primaryShop={
              directionType === EShippingLabelDirection.Send
                ? primaryShop
                : null
            }
            betweenShops={betweenShops}
          />
        )}
      </div>
      <div className={styles.space} />
      <div className={styles.address}>
        {createArgs && (
          <PostAddress
            label="RECEIVER"
            createAddressArgs={createReceiverArgs}
            setCreateAddressArgs={setCreateReceiverArgs}
            createArgs={createArgs}
            setCreateArgs={setCreateArgs}
            addressIsValid={receiverAddressIsValid}
            setAddressIsValid={setReceiverAddressIsValid}
            shops={
              betweenShops
                ? shops
                : directionType === EShippingLabelDirection.Receive
                  ? shops.filter(
                      (shop) =>
                        shop.companyId === companyId ||
                        shop.address.countryCode ===
                          createSenderArgs.countryCode,
                    )
                  : null
            }
            primaryShop={
              directionType === EShippingLabelDirection.Receive || betweenShops
                ? primaryShop
                : null
            }
            betweenShops={betweenShops}
            onChangeDestinationShopId={onChangeDestinationShopId}
          />
        )}
      </div>
    </Fragment>
  )
}

export default SenderReceiver
