import { dealFragments } from '../dealFragments'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { useMutationShowingErrors } from '@/hooks'
import { Deal, Mutation, MutationSetDealReviewingArgs } from '@/schemaTypes'

const SET_DEAL_REVIEWING = gql`
  mutation setDealReviewing($dealId: ObjectId!) {
    setDealReviewing(dealId: $dealId) {
      _id
      events {
        ...DealEventFragment
      }
    }
  }
  ${dealFragments.event}
`

type TData = { setDealReviewing: Mutation['setDealReviewing'] }
type TVariables = MutationSetDealReviewingArgs

export function useOnDealStatusReviewing(deal: Deal) {
  const { t } = useTranslation()

  const [setDealReviewing] = useMutation<TData, TVariables>(
    SET_DEAL_REVIEWING,
    {
      variables: { dealId: deal._id },
    },
  )

  return useMutationShowingErrors({
    mutation: setDealReviewing,
    successMessage: t('update_successful'),
  })
}
