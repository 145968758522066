import { ASSESSMENT_57_TOLERANCE_DURATION_MONTH } from '../VerificationChecklist/VerificationChecklist'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import {
  Field,
  FieldProps,
  Form,
  FormikErrors,
  FormikProvider,
  useFormik,
} from 'formik'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'
import { FunctionComponent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateCarInspection } from '@/domains/deals/hooks/updateCarInspection'
import { useMutationShowingErrors } from '@/hooks/useMutationShowingErrors'
import FormikDatePicker from '@/redesign/components/FormikFields/FormikDatePicker/FormikDatePicker'
import FormikDropdown from '@/redesign/components/FormikFields/FormikDropdown/FormikDropdown'
import FormikInputNum from '@/redesign/components/FormikFields/FormikInputNum/FormikInputNum'
import InputNum from '@/redesign/components/InputNumber/InputNumber'
import InputSlider from '@/redesign/components/InputSlider/InputSlider'
import { CarInspectionArgs, Deal } from '@/schemaTypes'

interface CarInspectionProps {
  deal: Deal
  setOpen: (value: boolean) => void
}
const DEFAULT_PARTIAL_REDEMPTION_INTERVALS_COUNT = 1
const MAXIMUM_CAR_AGE = 11
const MAXIMUM_CAR_MILEAGE = 300000

export const CarInspection: FunctionComponent<CarInspectionProps> = ({
  deal,
  setOpen,
}) => {
  const { t } = useTranslation()
  const [maxPawnDuration, setMaxPawnDuration] = useState<number>(180)
  // formik doesnt support warnings natively https://github.com/jaredpalmer/formik/issues/389
  const [warnings, setWarnings] = useState<{
    [key in keyof CarInspectionArgs]?: string
  }>({})

  const updateCarInspection = useMutationShowingErrors({
    mutation: useUpdateCarInspection(),
    successMessage: `${t('update_successful')}!`,
  })

  const maxPartialRedemptionAmount = useMemo(
    () => Math.max(deal.dealFinalValues.payoutAmount - 50, 0),
    [deal],
  )

  const minimumPossibleDateForExtension = dayjs()
    .add(deal.company.configuration.minimumPawnDuration, 'days')
    .toDate()

  const isOldCarWarningDisplayed =
    deal.items[0]?.vehicleData?.vehicleProperties.regdate &&
    dayjs().year() -
      dayjs(deal.items[0].vehicleData.vehicleProperties.regdate).year() >
      MAXIMUM_CAR_AGE

  const validate = (values: CarInspectionArgs) => {
    const errors: FormikErrors<CarInspectionArgs> = {}
    const newWarnings: { [key in keyof CarInspectionArgs]?: string } = {}

    const oldMileage =
      deal.items[0].vehicleData?.vehicleProperties.odometer ?? 0
    if (values.currentMileage < oldMileage) {
      errors.currentMileage = t('car_inspection.new_mileage_is_less_than', {
        oldMileage,
      })
    }

    if (values.currentMileage > MAXIMUM_CAR_MILEAGE) {
      newWarnings.currentMileage = t('car_inspection.mileage_warning')
    }

    if (
      dayjs(values.has57aGutachtenDue)
        .add(ASSESSMENT_57_TOLERANCE_DURATION_MONTH, 'month')
        .isBefore(minimumPossibleDateForExtension, 'date')
    ) {
      errors.has57aGutachtenDue = t('car_inspection.new_57a_validity_error', {
        minPawnDuration: deal.company.configuration.minimumPawnDuration,
      })
    }

    if (values.remainingPartialRedemptionAmount > maxPartialRedemptionAmount) {
      errors.remainingPartialRedemptionAmount = t(
        'car_inspection.invalid_remaining_partial_redemption_amount',
      )
    }

    setWarnings(newWarnings)
    return errors
  }

  const onSubmit = (values: CarInspectionArgs) => {
    updateCarInspection({ variables: { args: values } })
    setOpen(false)
  }

  const formik = useFormik<CarInspectionArgs>({
    initialValues: {
      dealId: deal._id,
      itemId: deal.itemsIds[0],
      currentMileage: deal.items[0]?.vehicleData?.vehicleProperties.odometer,
      has57aGutachtenDue: deal.items[0]?.vehicleData?.has57aGutachtenDue,
      finalMaxPawnDate: dayjs().add(maxPawnDuration, 'days'),
      remainingPartialRedemptionAmount:
        deal.dealFinalValues.remainingPartialRedemptionAmount,
      partialRedemptionIntervalsCount:
        deal.dealFinalValues?.partialRedemptionIntervalsCount ||
        DEFAULT_PARTIAL_REDEMPTION_INTERVALS_COUNT,
    },
    validate,
    onSubmit,
  })

  const { setFieldValue, isValid, values, touched } = formik

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <div className="flex flex-col mb-10">
            <p>{t('car_inspection.description_1')}</p>
            <p style={{ marginTop: '1rem' }}>
              {t('car_inspection.description_2')}
            </p>
          </div>

          <FormikInputNum
            name="currentMileage"
            label={t('car_inspection.current_mileage')}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            warningText={touched?.currentMileage && warnings?.currentMileage}
            inputContainerClassName="w-52"
            suffix=" km"
            mode="decimal"
          />

          <Field name="remainingPartialRedemptionAmount">
            {({ field, meta }: FieldProps<number>) => (
              <InputNum
                label={t('car_inspection.min_partial_redemption')}
                className="flex flex-col md:flex-row items-start md:items-center mb-2"
                labelClassName="text-sm font-semibold w-12.5"
                inputContainerClassName="w-52"
                minFractionDigits={2}
                maxFractionDigits={2}
                min={0}
                isInvalid={meta.touched && typeof meta.error === 'string'}
                warningText={
                  meta.touched && warnings?.remainingPartialRedemptionAmount
                }
                errorText={meta.touched && meta.error}
                {...field}
                onChange={(event) => {
                  setFieldValue('remainingPartialRedemptionAmount', event.value)
                  if (!event.value) {
                    setFieldValue('partialRedemptionIntervalsCount', null)
                  }
                  if (event.value && !values.partialRedemptionIntervalsCount) {
                    setFieldValue(
                      'partialRedemptionIntervalsCount',
                      DEFAULT_PARTIAL_REDEMPTION_INTERVALS_COUNT,
                    )
                  }
                }}
              />
            )}
          </Field>

          <FormikDropdown
            name="partialRedemptionIntervalsCount"
            label={t(
              'car_inspection.partial_redemption_interval.dropdown_title',
            )}
            options={[1, 2, 3].map((value) => ({
              value,
              label: t(`car_inspection.partial_redemption_interval.amount`, {
                count: value,
              }),
            }))}
            className="flex flex-col md:flex-row items-start md:items-center mb-6"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
            disabled={!values.remainingPartialRedemptionAmount}
          />

          <FormikDatePicker
            name="has57aGutachtenDue"
            label={t('car_inspection.has_57a_gutachtendue')}
            dateFormat="dd.mm.yy"
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
            minDate={deal.items[0].vehicleData?.has57aGutachtenDue}
          />

          <Field name="finalMaxPawnDate">
            {({ meta }: FieldProps<Number>) => (
              <InputSlider
                label={t('car_inspection.max_pawn_duration')}
                className="flex flex-col md:flex-row items-start md:items-center"
                labelClassName="text-sm font-semibold w-12.5"
                inputContainerClassName="w-52"
                sliderMinValue={60}
                sliderMaxValue={360}
                sliderStep={30}
                value={maxPawnDuration}
                suffix={` ${t('days')}`}
                warningText={meta.touched && warnings?.finalMaxPawnDate}
                isInvalid={meta.touched && typeof meta.error === 'string'}
                errorText={meta.touched && meta.error}
                onChangeSlider={(value: number) => {
                  setFieldValue('finalMaxPawnDate', dayjs().add(value, 'days'))
                  setMaxPawnDuration(value)
                }}
              />
            )}
          </Field>

          {isOldCarWarningDisplayed && (
            <div className="mt-4">
              <Message
                severity={'warn'}
                text={t('car_inspection.production_year_warning')}
              />
            </div>
          )}

          <div className="flex flex-row-reverse mt-10">
            <StyledButton
              label={t('car_inspection.allow_extension')}
              icon="pi pi-check"
              type="submit"
              disabled={!isValid}
            />
            <StyledButton
              label={t('cancel')}
              icon="pi pi-times"
              onClick={() => setOpen(false)}
              className="p-button-text"
              type="reset"
            />
          </div>
        </Form>
      </FormikProvider>
    </>
  )
}

const StyledButton = styled(Button)`
  color: var(--button-secondary-color, #fff);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.05rem;
`
