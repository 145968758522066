import ShippingLabelComponent from '../ShippingLabelComponent'
import { IShipBetweenShopsByCashyData } from '../ShippingLabelComponent/ShippingLabelComponent'
import { TextareaAutosize } from '@material-ui/core'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import dayjs from 'dayjs'
import { last } from 'lodash'
import React, { Fragment, useContext, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { Context } from '@/context'
import { useGetActiveEmployees } from '@/domains/items/hooks'
import { useGetShops } from '@/domains/shops/hooks'
import { useShowConfirmPopup } from '@/hooks'
import SaveIcon from '@/images/save.svg'
import {
  EIdFilterConditions,
  EItemStatusType,
  EOrderBy,
  EShippingLabelDirection,
  EShippingLabelEntity,
  EStandardShipmentType,
  Item,
  ItemEvent,
  ShippingLabelCreateArgs,
} from '@/schemaTypes'
import { EHintType, findRelatedEmailForItemEvent } from './ItemStatus'
import styles from './ItemStatus.module.scss'

export interface ItemShippedBetweenShopsProps {
  item: Item
  onChangeItemStatus: (
    newEvent: ItemEvent,
    hint: EHintType,
    shippingLabelCreateArgs?: ShippingLabelCreateArgs | undefined,
    relocateItem?: boolean,
    relocateNote?: string,
  ) => void
}

const ItemShipBetweenShopsStatus: FunctionComponent<
  ItemShippedBetweenShopsProps
> = (props) => {
  const { onChangeItemStatus, item } = props
  const { showInfo, closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()
  const showConfirmPopupForDeletingStorageLabel = useShowConfirmPopup({
    actionText: t('save'),
  })

  const [description, setDescription] = useState('')
  const [email] = useState(findRelatedEmailForItemEvent(item))
  const [shippingLabelCreateArgs, setShippingLabelCreateArgs] =
    useState<ShippingLabelCreateArgs>()
  const [shippingLabelCreateArgsIsValid, setShippingLabelCreateArgsIsValid] =
    useState(false)

  const [destinationShopId, setDestinationShopId] = useState<string>()

  const [shipBetweenShopsByCashyData, setShipBetweenShopsByCashyData] =
    useState<IShipBetweenShopsByCashyData>({
      cashyEmployeeId: undefined,
      destinationShopId: undefined,
    })

  const shipBetweenShopsByCashyDataValid =
    shipBetweenShopsByCashyData.destinationShopId &&
    shipBetweenShopsByCashyData.cashyEmployeeId

  const { employees } = useGetActiveEmployees({
    variables: {
      opts: {
        filter: {
          companyId: {
            value: item?.companyId,
            condition: EIdFilterConditions.Equals,
          },
        },
      },
    },
    skip: !item,
  })

  const successionCompanyId = item?.company.successionCompanyId

  const { shops } = useGetShops({
    variables: {
      opts: {
        filter: {
          includeDisabled: false,
        },
        order: {
          _id: EOrderBy.Desc,
        },
      },
    },
    skip: !item?.companyId && !successionCompanyId,
  })

  const onChangeDestinationShopId = (shopId: string | undefined) => {
    // should never happen
    if (!shopId) {
      return
    }

    setDestinationShopId(shopId)
  }

  const saveNewStatus = async () => {
    if (
      !shippingLabelCreateArgsIsValid &&
      shippingLabelCreateArgs?.shippingPartner !== EStandardShipmentType.Cashy
    ) {
      return showInfo(t('shipping_label_is_not_provided_correctly'))
    }

    if (
      shippingLabelCreateArgs?.shippingPartner ===
        EStandardShipmentType.Cashy &&
      !shipBetweenShopsByCashyDataValid
    ) {
      return showInfo(t('ship_between_shops_by_cashy_data_is_not_valid'))
    }

    showConfirmPopupForDeletingStorageLabel({
      state: {
        confirmRelocateItem: true,
      },
      title: t('remove_storage_label_after_shipped_between_shops'),
      component: (state, setState) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={state.confirmRelocateItem}
                onChange={(e) => {
                  setState({ ...state, confirmRelocateItem: e.target.checked })
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={t('relocate_item_confirmation')}
            style={{ marginTop: '5px' }}
          />
        )
      },
      action: async (state) => {
        closeConfirmModal()
        const now = dayjs().toDate()
        onChangeItemStatus(
          {
            createdAt: now,
            timestamp: now,
            event: EItemStatusType.ShipBetweenShops,
            meta: {
              logisticPartnerName: shippingLabelCreateArgs?.shippingPartner,
              logisticPartnerTrackingCode:
                shippingLabelCreateArgs?.trackingCode,
              logisticPartnerTrackingLink:
                shippingLabelCreateArgs?.trackingLink,
              logisticPartnerLabelFile: shippingLabelCreateArgs?.labelFile,
              sendNotification: false,
              emailAddress: email,
              description: description,
              destinationShopId,
              ...(shippingLabelCreateArgs?.shippingPartner ===
              EStandardShipmentType.Cashy
                ? shipBetweenShopsByCashyData
                : {}),
            },
          },
          EHintType.NONE,
          shippingLabelCreateArgs?.shippingPartner ===
            EStandardShipmentType.Cashy
            ? undefined
            : shippingLabelCreateArgs,
          state.confirmRelocateItem,
          `${t('storage_entry_note_for_shipped_between_shops')} ${
            shippingLabelCreateArgs?.receiverName ??
            shops?.find((shop) => shop._id === destinationShopId)?.name
          }`,
        )
      },
    })
  }

  return (
    <Fragment>
      <div
        onClick={saveNewStatus}
        className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
      >
        <ReactSVG className={`${styles.saveIcon} u-pl-15`} src={SaveIcon} />
      </div>
      <ShippingLabelComponent
        dealId={last(item.dealsHistory)?.dealId}
        item={item}
        entityType={EShippingLabelEntity.Item}
        directionType={EShippingLabelDirection.Send}
        shippingLabelCreateArgs={shippingLabelCreateArgs}
        setShippingLabelCreateArgs={setShippingLabelCreateArgs}
        logisticServiceProviders={[
          EStandardShipmentType.DpdStandard,
          EStandardShipmentType.Post,
          EStandardShipmentType.Cashy,
        ]}
        shippingLabelCreateArgsIsValid={shippingLabelCreateArgsIsValid}
        setShippingLabelCreateArgsIsValid={setShippingLabelCreateArgsIsValid}
        customerEmailAddress={email}
        betweenShops={true}
        onChangeDestinationShopId={onChangeDestinationShopId}
        shipBetweenShopsByCashyOptions={{
          shipBetweenShopsByCashyData,
          setShipBetweenShopsByCashyData,
          employees,
          shops,
        }}
      />
      <div style={{ marginTop: '10px' }}>
        {t('description')}:
        <TextareaAutosize
          rowsMin={4}
          placeholder={t('any_description_want_to_add')}
          style={{ width: '100%' }}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
    </Fragment>
  )
}

export default ItemShipBetweenShopsStatus
