import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useContext } from 'react'
import { Context } from '@/context'
import { fragments } from '@/hooks/fragments'
import {
  ELanguageCode,
  Mutation,
  MutationStartAuctionArgs,
} from '@/schemaTypes'
import { auctionFragments } from './auctionFragments'

const START_AUCTION = gql`
  mutation startAuction($auctionId: ObjectId!, $languageCode: ELanguageCode!) {
    startAuction(auctionId: $auctionId) {
      ...AuctionDetailsFragment
    }
  }
  ${fragments.customDealFormQuestion}
  ${fragments.formAnswers}
  ${auctionFragments.auctionDetails}
  ${fragments.address}
`

type TData = { startAuction: Mutation['startAuction'] }
type TVariables = { languageCode?: ELanguageCode } & MutationStartAuctionArgs

export function useStartAuction(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const { language } = useContext(Context)

  const [startAuction] = useMutation<TData, TVariables>(START_AUCTION, {
    ...opts,
    variables: { ...(opts.variables as TVariables), languageCode: language },
  })

  return startAuction
}
