import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetMaterialPricesArgs } from '@/schemaTypes'

const GET_MATERIAL_PRICES = gql`
  query getMaterialPrices($opts: MaterialPriceOpts) {
    getMaterialPrices(opts: $opts) {
      _id
      date
      material
      price
      mu
      sigma
    }
  }
`

type TData = { getMaterialPrices: Query['getMaterialPrices'] }
type TVariables = QueryGetMaterialPricesArgs

export function useGetMaterialPrices(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_MATERIAL_PRICES, {
    variables: {},
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    materialPrices: queryResult.data?.getMaterialPrices || [],
  }
}
