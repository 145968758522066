import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { ALLOWED_FILE_TYPES } from '@/constants'
import { useUpdateDealUploadedFiles } from '@/domains/deals/hooks'
import { useMutationShowingErrors } from '@/hooks'
import SectionTitle from '@/redesign/components/SectionTitle/SectionTitle'
import { UppyUploader } from '@/redesign/components/UppyUploader/UppyUploader'
import { Deal } from '@/schemaTypes'

export interface UploadDealOverviewProps {
  deal: Deal
}

const UploadDealOverview = (props: UploadDealOverviewProps) => {
  const { deal } = props
  const { t } = useTranslation()

  const updateDealUploadedFiles = useMutationShowingErrors({
    mutation: useUpdateDealUploadedFiles(),
  })

  const handleUploadOverview = async (files) => {
    await updateDealUploadedFiles({
      variables: {
        dealId: deal._id,
        uploadedFilesArgs: extractPropertiesFromArray(files || []),
      },
    })
  }

  return (
    <Formik
      initialValues={{ ...deal }}
      onSubmit={() => {
        //
      }}
    >
      {() => {
        return (
          <Form>
            <div>
              <SectionTitle title={t('uploads_overview')} />
              <UppyUploader
                onFileChanged={handleUploadOverview}
                confirmPopupBeforeRemove
                allowedFileTypes={[...ALLOWED_FILE_TYPES, '.pdf']}
                multiple
                name="uploadedFiles"
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

const extractPropertiesFromArray = (arr: any[]) => {
  return arr.map(({ id, ...rest }) => rest)
}

export default UploadDealOverview
