import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationPayDealSurplusAmountArgs } from '@/schemaTypes'
import { dealCalculationFragments } from './dealFragments'

const PAY_DEAL_SURPLUS_AMOUNT = gql`
  mutation payDealSurplusAmount(
    $dealSurplusPaymentArgs: DealSurplusPaymentArgs!
  ) {
    payDealSurplusAmount(dealSurplusPaymentArgs: $dealSurplusPaymentArgs) {
      _id
      surplusPaymentsData {
        ...surplusPaymentDataFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
    }
  }
  ${fragments.surplusPaymentData}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = { updateShopId: Mutation['payDealSurplusAmount'] }
type TVariables = MutationPayDealSurplusAmountArgs

export function usePayDealSurplusAmount(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [payDealSurplusAmount] = useMutation<TData, TVariables>(
    PAY_DEAL_SURPLUS_AMOUNT,
    opts,
  )

  return payDealSurplusAmount
}
