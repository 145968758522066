import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation } from '@/schemaTypes'

const IMPORT_REFURBED_PRODUCTS = gql`
  mutation importNewRefurbedProducts {
    importNewRefurbedProducts
  }
`

type TData = {
  importNewRefurbedProducts: Mutation['importNewRefurbedProducts']
}
type TVariables = {}

export function useImportNewRefurbedProducts(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [mutation] = useMutation<TData, TVariables>(
    IMPORT_REFURBED_PRODUCTS,
    opts,
  )
  return mutation
}
