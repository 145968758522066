import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeleteCustomerArgs } from '@/schemaTypes'

const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($customerId: ObjectId!) {
    deleteCustomer(customerId: $customerId)
  }
`

type TData = { deleteCustomer: Mutation['deleteCustomer'] }
type TVariables = MutationDeleteCustomerArgs

export function useDeleteCustomer(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deleteCustomer] = useMutation<TData, TVariables>(DELETE_CUSTOMER, opts)

  return deleteCustomer
}
