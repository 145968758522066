import LoadingSpinner from '../LoadingSpinner'
import {
  Checkbox,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { findLastIndex } from 'lodash'
import * as React from 'react'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import { useGetShops } from '@/domains/shops/hooks'
import {
  AddItemToStorageUnitArgs,
  Deal,
  EItemStatusType,
  Item,
  ItemEvent,
  ShippingLabelCreateArgs,
  ValorizationHistoryEntry,
} from '@/schemaTypes'
import { getNow } from '@/utils/time'
import ItemCollectCarStatus from './ItemCollectCarStatus'
import ItemDerecognizedStatus from './ItemDerecognizedStatus'
import ItemNotSellableStatus from './ItemNotSellableStatus'
import ItemOfferedToSellStatus from './ItemOfferedToSellStatus'
import ItemPaidAndShippedStatus from './ItemPaidAndShippedStatus'
import ItemReturnArrivedStatus from './ItemReturnArrivedStatus'
import ItemReturnRequestedStatus from './ItemReturnRequestedStatus'
import ItemShipBetweenShopsStatus from './ItemShipBetweenShopsStatus'
import styles from './ItemStatus.module.scss'
import ItemStatusRow from './ItemStatusRow'
import ItemStatusWithStorageSelection from './ItemStatusWithStorageSelection'
import ItemVoluntarySelfDeliveryStatus from './ItemVoluntarySelfDeliveryStatus'

export enum EHintType {
  ERROR_LOCKED_CELLPHONE_ON_REFURBED = 'ERROR_LOCKED_CELLPHONE_ON_REFURBED',
  WARNING_SELL_NEW_ITEM_ON_REFURBED = 'WARNING_SELL_NEW_ITEM_ON_REFURBED',
  NONE = 'NONE',
}

export interface ItemStatusProps {
  item: Item
  deal: Deal
  updateStatusLoading: boolean
  onChangeItemStatus: (
    newEvent: ItemEvent,
    hint: EHintType,
    shippingLabelCreateArgs?: ShippingLabelCreateArgs | undefined,
    relocateItem?: boolean,
    relocateNote?: string,
    addItemToStorageUnitArgs?: AddItemToStorageUnitArgs,
  ) => void
  onRemoveLatestEvent: () => void
  valorizationHistories?: ValorizationHistoryEntry[]
  isLockedSmartPhone?: boolean
}

const ItemStatus: FunctionComponent<ItemStatusProps> = (props) => {
  const {
    item,
    onChangeItemStatus,
    updateStatusLoading,
    onRemoveLatestEvent,
    valorizationHistories,
    deal,
    isLockedSmartPhone,
  } = props
  const { t } = useTranslation()
  const [showPrerequisitesItemStatuses, setShowPrerequisitesItemStatuses] =
    useState(true)
  const [newStatus, setNewStatus] = useState<EItemStatusType | undefined>()
  const { getCurrentUser } = useContext(Context)

  let itemStatusIndex = 0
  let advertiserIndex = 0

  useEffect(() => {
    setNewStatus(undefined)
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    itemStatusIndex = 0
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    advertiserIndex = 0
  }, [item])

  const lastEvent = item.events
    ? item.events[item.events.length - 1].event
    : undefined

  const canChangeFromShipBetweenShopsToStatus = React.useCallback(
    (newStatus: EItemStatusType) => {
      const shipBetweenShopsEventIndex = findLastIndex(
        item.events ?? [],
        (event) => event.event === EItemStatusType.ShipBetweenShops,
      )
      return (
        shipBetweenShopsEventIndex > 0 &&
        item.events &&
        shipBetweenShopsEventIndex === item.events.length - 1 &&
        item.events[shipBetweenShopsEventIndex - 1]?.event === newStatus
      )
    },
    [item.events],
  )

  const { shops: auctionedItemShops } = useGetShops({
    variables: {
      opts: {
        filter: {
          storageFacilityIds: [item.storageFacilityId],
        },
      },
    },
    skip: !item.storageFacilityId || lastEvent !== EItemStatusType.Auctioned,
  })

  const returnAllowedEvents = () => {
    if (lastEvent === undefined || lastEvent === null) {
      return [EItemStatusType.NotRelevant]
    } else if (lastEvent === EItemStatusType.NotRelevant) {
      return []
    } else if (lastEvent === EItemStatusType.Pawned) {
      return [EItemStatusType.ShipBetweenShops]
    } else if (lastEvent === EItemStatusType.ReadyToSell) {
      return [
        EItemStatusType.OfferedToSell,
        EItemStatusType.NotSellable,
        EItemStatusType.ShipBetweenShops,
      ]
    } else if (lastEvent === EItemStatusType.OfferedToSell) {
      return [EItemStatusType.ReadyToSell, EItemStatusType.ShipBetweenShops]
    } else if (lastEvent === EItemStatusType.Sold) {
      return [
        EItemStatusType.PaidAndShipped,
        EItemStatusType.PaidAndCollected,
        EItemStatusType.ShipBetweenShops,
      ]
    } else if (
      lastEvent === EItemStatusType.PaidAndShipped ||
      lastEvent === EItemStatusType.PaidAndCollected
    ) {
      return [EItemStatusType.ReturnRequested]
    } else if (lastEvent === EItemStatusType.ReturnRequested) {
      return [
        EItemStatusType.PaidAndShipped,
        EItemStatusType.PaidAndCollected,
        EItemStatusType.ReturnArrived,
      ]
    } else if (lastEvent === EItemStatusType.ReturnArrived) {
      return [
        EItemStatusType.PaidAndShipped,
        EItemStatusType.PaidAndCollected,
        EItemStatusType.Returned,
        EItemStatusType.ShipBetweenShops,
      ]
    } else if (lastEvent === EItemStatusType.Returned) {
      return [
        EItemStatusType.ReadyToSell,
        EItemStatusType.NotSellable,
        EItemStatusType.ShipBetweenShops,
      ]
    } else if (lastEvent === EItemStatusType.NotSellable) {
      return [EItemStatusType.ReadyToSell, EItemStatusType.ShipBetweenShops]
    } else if (lastEvent === EItemStatusType.ShipBetweenShops) {
      return [
        EItemStatusType.ReadyToSell,
        EItemStatusType.NotSellable,
        EItemStatusType.OfferedToSell,
        EItemStatusType.Sold,
        EItemStatusType.Pawned,
        EItemStatusType.Returned,
        EItemStatusType.ReturnArrived,
        EItemStatusType.Auctioned,
      ].filter((status) => canChangeFromShipBetweenShopsToStatus(status))
    } else if (lastEvent === EItemStatusType.PrivateSale) {
      return []
    } else if (lastEvent === EItemStatusType.CollectCar) {
      return [EItemStatusType.VoluntarySelfDelivery, EItemStatusType.Securing]
    } else if (
      lastEvent === EItemStatusType.VoluntarySelfDelivery ||
      lastEvent === EItemStatusType.Securing
    ) {
      return [EItemStatusType.Derecognized, EItemStatusType.CarCollected]
    } else if (lastEvent === EItemStatusType.CarCollected) {
      return [EItemStatusType.InLiquidation]
    } else if (lastEvent === EItemStatusType.InLiquidation) {
      return [EItemStatusType.CarSold]
    } else if (lastEvent === EItemStatusType.Auctioned) {
      const employeePreferenceShopId =
        getCurrentUser()?.preferences?.defaultShopId
      return [
        item.itemInAuctionShop ? EItemStatusType.PaidAndShipped : null,
        item.itemInAuctionShop ||
        (employeePreferenceShopId && auctionedItemShops?.length
          ? employeePreferenceShopId === auctionedItemShops[0]._id
          : true)
          ? EItemStatusType.PaidAndCollected
          : null,
        EItemStatusType.ShipBetweenShops,
      ].filter(Boolean)
    }

    return []
  }

  const newStatusNeedsSaveButton = (newStat: EItemStatusType | undefined) => {
    if (
      newStat &&
      [
        EItemStatusType.Auctioned,
        EItemStatusType.ReadyToSell,
        EItemStatusType.OfferedToSell,
        EItemStatusType.PaidAndShipped,
        EItemStatusType.ShipBetweenShops,
        EItemStatusType.ReturnRequested,
        EItemStatusType.ReturnArrived,
        EItemStatusType.NotSellable,
        EItemStatusType.CollectCar,
        EItemStatusType.Derecognized,
        EItemStatusType.VoluntarySelfDelivery,
      ].includes(newStat)
    )
      return true
    else return false
  }

  const canRemoveLastEvent = (indexPlusOne: number) => {
    if (item.events) {
      const realIndex = indexPlusOne - 1
      if (realIndex === (item.events ? item.events.length - 1 : 0)) {
        if (item.events[realIndex].event === EItemStatusType.ReadyToSell) {
          return false
        }
        // This means that item has more than one event
        if (realIndex > 0) {
          if (
            (item.events[realIndex - 1].event !== EItemStatusType.NotRelevant &&
              item.events[realIndex - 1].event !== EItemStatusType.Pawned &&
              item.events[realIndex].meta &&
              !item.events[realIndex].meta?.valorizationEntryId) || // This means Item status connected to valorization cannot be deleted manually from Status section
            !item.events[realIndex].meta
          )
            return true
        }
      }
    }
  }

  return (
    <Table className={`u-mb-sm`} size="medium">
      <TableBody>
        <TableRow>
          <TableCell
            align="left"
            style={{ verticalAlign: 'top', width: '46%' }}
          >
            <table style={{ width: '100%' }}>
              <tbody>
                <tr className={`${styles.tr}`}>
                  <td align="left" style={{ verticalAlign: 'top' }}>
                    <Typography component="div" variant="body1" gutterBottom>
                      {t('show_prerequisites_item_status')}:
                      <Checkbox
                        checked={showPrerequisitesItemStatuses}
                        onChange={(e) => {
                          setShowPrerequisitesItemStatuses(e.target.checked)
                        }}
                      />
                    </Typography>
                    <Typography component="div" variant="body1" gutterBottom>
                      {t('status')}:
                      <Select
                        className="u-ml-sm"
                        onChange={(e) => {
                          const newStatusTemp = e.target
                            .value as EItemStatusType
                          setNewStatus(newStatusTemp)

                          if (!newStatusNeedsSaveButton(newStatusTemp)) {
                            const now = getNow()
                            onChangeItemStatus(
                              {
                                event: newStatusTemp,
                                createdAt: now,
                                timestamp: now,
                              },
                              EHintType.NONE,
                            )
                          }
                        }}
                      >
                        {(returnAllowedEvents() ?? []).map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      {updateStatusLoading ? (
                        <div className="u-inline-block u-absolute">
                          <div className="u-relative u-ml-15">
                            <LoadingSpinner />
                          </div>
                        </div>
                      ) : null}
                      {/* Load red hint when dropdown list is empty */}
                      {returnAllowedEvents().length === 0 ? (
                        <span style={{ color: 'Red' }}>
                          <p>{t('item_status_cannot_change_manually')}</p>
                        </span>
                      ) : null}
                      {/* Check if we should show other components related to READY_TO_SELL status */}
                      {newStatus === EItemStatusType.ReadyToSell ? (
                        <ItemStatusWithStorageSelection
                          newStatus={EItemStatusType.ReadyToSell}
                          item={item}
                          onChangeItemStatus={onChangeItemStatus}
                          deal={deal}
                        />
                      ) : (
                        ''
                      )}
                      {newStatus === EItemStatusType.Auctioned ? (
                        <ItemStatusWithStorageSelection
                          newStatus={EItemStatusType.Auctioned}
                          item={item}
                          onChangeItemStatus={onChangeItemStatus}
                          deal={deal}
                        />
                      ) : (
                        ''
                      )}
                      {/* Check if we should show other components related to OFFERED_TO_SELL status */}
                      {newStatus === EItemStatusType.OfferedToSell ? (
                        <ItemOfferedToSellStatus
                          deal={deal}
                          item={item}
                          onChangeItemStatus={onChangeItemStatus}
                          updateStatusLoading={updateStatusLoading}
                          isLockedSmartPhone={isLockedSmartPhone}
                        />
                      ) : (
                        ''
                      )}
                      {/* Check if we should show other components related to PAID_AND_SHIPPED status */}
                      {newStatus === EItemStatusType.PaidAndShipped ? (
                        <ItemPaidAndShippedStatus
                          item={item}
                          onChangeItemStatus={onChangeItemStatus}
                          valorizationHistories={valorizationHistories}
                        />
                      ) : (
                        ''
                      )}
                      {newStatus === EItemStatusType.ShipBetweenShops ? (
                        <ItemShipBetweenShopsStatus
                          item={item}
                          onChangeItemStatus={onChangeItemStatus}
                        />
                      ) : (
                        ''
                      )}
                      {/* Check if we should show other components related to RETURN_REQUESTED status */}
                      {newStatus === EItemStatusType.ReturnRequested ? (
                        <ItemReturnRequestedStatus
                          item={item}
                          onChangeItemStatus={onChangeItemStatus}
                        />
                      ) : (
                        ''
                      )}
                      {/* Check if we should show other components related to RETURN_ARRIVED status */}
                      {newStatus === EItemStatusType.ReturnArrived ? (
                        <ItemReturnArrivedStatus
                          item={item}
                          onChangeItemStatus={onChangeItemStatus}
                        />
                      ) : (
                        ''
                      )}
                      {/* Check if we should show other components related to NOT_SELLABLE status */}
                      {newStatus === EItemStatusType.NotSellable ? (
                        <ItemNotSellableStatus
                          item={item}
                          onChangeItemStatus={onChangeItemStatus}
                        />
                      ) : (
                        ''
                      )}
                      {newStatus === EItemStatusType.CollectCar && (
                        <ItemCollectCarStatus
                          onChangeItemStatus={onChangeItemStatus}
                        />
                      )}
                      {newStatus === EItemStatusType.VoluntarySelfDelivery && (
                        <ItemVoluntarySelfDeliveryStatus
                          onChangeItemStatus={onChangeItemStatus}
                        />
                      )}
                      {newStatus === EItemStatusType.Derecognized && (
                        <ItemDerecognizedStatus
                          onChangeItemStatus={onChangeItemStatus}
                        />
                      )}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableCell>
          <TableCell style={{ verticalAlign: 'top', width: '62%' }}>
            <Table className={`u-mb-sm`} size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ width: '3%' }}
                    component="th"
                    scope="row"
                  />
                  <TableCell style={{ width: '22%' }}>{t('status')}</TableCell>
                  <TableCell style={{ width: '22%' }} align="left">
                    {t('date')}
                  </TableCell>
                  <TableCell style={{ width: '52%' }} align="left">
                    {t('details')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.events
                  ? item.events
                      // We do not filter the "showPrerequisitesItemStatuses" here, because we will loose count on event length
                      .map((event) => {
                        itemStatusIndex++
                        return (showPrerequisitesItemStatuses &&
                          event.meta?.isPrerequisites) ||
                          !event.meta?.isPrerequisites ? (
                          <ItemStatusRow
                            key={itemStatusIndex}
                            event={event}
                            itemStatusIndex={itemStatusIndex}
                            advertiserIndex={advertiserIndex}
                            canRemoveLastEvent={canRemoveLastEvent}
                            onRemoveLatestEvent={onRemoveLatestEvent}
                          />
                        ) : null
                      })
                  : null}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default ItemStatus

export function findRelatedEmailForItemEvent(item: Item) {
  if (item && item.events) {
    for (let i = item.events.length - 1; i >= 0; i--) {
      if (item.events[i] && item.events[i].meta) {
        return item.events[i]?.meta?.emailAddress
      }
    }
  }
  return ''
}
