import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import {
  ELanguageCode,
  Query,
  QueryGetCustomDealFormByIdArgs,
} from '@/schemaTypes'

const GET_CUSTOM_DEAL_FORM_BY_ID = gql`
  query getCustomDealFormById($id: ObjectId!, $languageCode: ELanguageCode!) {
    getCustomDealFormById(id: $id) {
      _id
      categoryId
      createdAt
      updatedAt
      isUsedForCommissionSale
      questions {
        ...CustomDealFormQuestionFragment
      }
    }
  }
  ${fragments.customDealFormQuestion}
`

type TData = { getCustomDealFormById: Query['getCustomDealFormById'] }
type TVariables = QueryGetCustomDealFormByIdArgs & {
  languageCode: ELanguageCode
}

export function useGetCustomDealFormById(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_CUSTOM_DEAL_FORM_BY_ID, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
