import { TextField, TextFieldProps, Typography } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import React from 'react'
import styles from './TextInput.module.scss'

type TextFieldOverwrittenPropName = 'error' | 'onChange' | 'className' | 'style'
export type TextInputProps = Omit<
  TextFieldProps,
  TextFieldOverwrittenPropName
> & {
  label?: React.ReactNode
  onChange?: (value: string) => void
  error?: string

  className?: string
  style?: React.CSSProperties
  inputWidth?: string
  variant?: 'standard' | 'outlined' | 'filled'
  icon?: React.ReactNode
  showResetButton?: boolean
  errorMessagePosition?: 'bottom' | 'left'
  onReset?: () => void
  divWidth?: number
  id?: string
}

export default function TextInput(props: TextInputProps) {
  const {
    onChange,
    error,
    inputWidth,
    label,
    style,
    variant,
    className,
    icon,
    showResetButton,
    onReset,
    divWidth,
    errorMessagePosition = 'left',
    ...textFieldProps
  } = props

  return (
    <Typography
      component="div"
      style={style}
      variant="body1"
      gutterBottom
      className={`${styles.TextInput} ${className} u-flex u-ai-center`}
    >
      <span style={{ whiteSpace: 'nowrap' }}>{label ?? ''}</span>
      <div style={{ width: divWidth ?? '100%' }}>
        <TextField
          {...textFieldProps}
          className={'u-ml-5'}
          onChange={(e) => onChange && onChange(e.target.value)}
          error={!!error ?? false}
          style={{
            width: inputWidth,
          }}
          variant={variant ?? 'standard'}
        />

        {error && errorMessagePosition === 'bottom' && (
          <Typography
            style={{ fontSize: '0.75rem', marginTop: 4 }}
            color="error"
          >
            {error}
          </Typography>
        )}

        {showResetButton && textFieldProps.value && (
          <DeleteForeverIcon
            style={{ cursor: 'pointer', marginTop: 7, marginLeft: 5 }}
            onClick={() => onReset?.()}
          />
        )}
      </div>
      {icon}
      {error && errorMessagePosition === 'left' && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </Typography>
  )
}
