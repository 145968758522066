import { RouteComponentProps, Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import { MergeCustomers } from '@/pages/InApp/Customers/merge'
import CustomersListPage from '@/redesign/pages/InApp/Customers'
import CustomersCreate from '@/redesign/pages/InApp/Customers/create'
import getPagePath from '@/utils/getPagePath'
import CustomersEdit from './edit/[customerId]'

export function CustomersRoutes(props: RouteComponentProps) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/create/:customerId')}
        render={(props) => (
          <CustomersCreate
            {...props}
            customerId={props.match.params.customerId}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/edit/:customerId')}
        render={(props) => (
          <CustomersEdit
            {...props}
            customerId={props.match.params.customerId as string}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/merge/:customerIds')}
        render={(props) => {
          return (
            <MergeCustomers
              {...props}
              customerIds={(props.match.params.customerIds as string).split(
                ',',
              )}
            />
          )
        }}
      />
      <SentryRoute
        path={getPagePath(props, '/create')}
        render={() => <CustomersCreate />}
      />
      <SentryRoute path={getPagePath(props)} component={CustomersListPage} />
    </Switch>
  )
}

export default CustomersRoutes
