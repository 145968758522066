import { useGetActiveStorageFacilities } from '../../hooks'
import { useGetStorageUnits } from '../../hooks/itemList/getStorageUnits'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SelectOptionInput from '@/components/SelectOptionInput'

export interface StorageUnitsFilterInputProps {
  storageFacilityId?: string | null
  storageUnitIdLv0?: string | null
  storageUnitIdLv1?: string | null
  storageUnitIdLv2?: string | null
  storageUnitIdLv3?: string | null
  onStorageFacilityIdChanged: (data: string | null) => void
  onStorageUnitIdLv0Changed: (data: string | null) => void
  onStorageUnitIdLv1Changed: (data: string | null) => void
  onStorageUnitIdLv2Changed: (data: string | null) => void
  onStorageUnitIdLv3Changed: (data: string | null) => void
}

export default function ItemStorageUnitsFilterInput({
  storageUnitIdLv0,
  storageUnitIdLv1,
  storageUnitIdLv2,
  storageUnitIdLv3,
  onStorageFacilityIdChanged,
  onStorageUnitIdLv0Changed,
  onStorageUnitIdLv1Changed,
  onStorageUnitIdLv2Changed,
  onStorageUnitIdLv3Changed,
  storageFacilityId,
}: StorageUnitsFilterInputProps) {
  const { t } = useTranslation()

  const { storageFacilities } = useGetActiveStorageFacilities()

  const storageFacility = storageFacilities.find(
    (f) => f._id === storageFacilityId,
  )

  const { storageUnits } = useGetStorageUnits({
    variables: {
      opts: { filter: { ids: storageFacility?.storageUnitIds } },
    },
    fetchPolicy: 'network-only',
    skip: !storageFacility || !storageFacilityId,
  })

  const firstLevelChildIds: string[] = []
  for (const storageUnit of storageUnits) {
    if (storageUnit.childIds) {
      firstLevelChildIds.push(...storageUnit.childIds)
    }
  }

  const { storageUnits: firstLevelChildUnits } = useGetStorageUnits({
    variables: {
      opts: { filter: { ids: firstLevelChildIds } },
    },
    fetchPolicy: 'network-only',
  })

  const secondLevelChildIds: string[] = []
  for (const childUnit of firstLevelChildUnits) {
    if (childUnit.childIds) {
      secondLevelChildIds.push(...childUnit.childIds)
    }
  }

  const { storageUnits: secondLevelChildUnits } = useGetStorageUnits({
    variables: {
      opts: { filter: { ids: secondLevelChildIds } },
    },
    fetchPolicy: 'network-only',
  })

  const thirdLevelChildIds: string[] = []
  for (const childUnit of secondLevelChildUnits) {
    if (childUnit.childIds) {
      thirdLevelChildIds.push(...childUnit.childIds)
    }
  }

  const { storageUnits: thirdLevelChildUnits } = useGetStorageUnits({
    variables: {
      opts: { filter: { ids: thirdLevelChildIds } },
    },
    fetchPolicy: 'network-only',
  })

  return (
    <Box display="flex" justifyContent="start">
      <SelectOptionInput
        selectProps={{ style: { minWidth: 100 } }}
        value={storageFacilityId}
        onChange={onStorageFacilityIdChanged}
        menuItems={storageFacilities.map((storageFacility) => ({
          label: storageFacility.name,
          value: storageFacility._id,
        }))}
        label={
          <Typography variant="body1" style={{ minWidth: 107 }}>
            {t('storage.singular')}
          </Typography>
        }
        className="u-flex u-ai-center"
        showResetButton
        onReset={() => {
          onStorageFacilityIdChanged(null)
          onStorageUnitIdLv0Changed(null)
          onStorageUnitIdLv1Changed(null)
          onStorageUnitIdLv2Changed(null)
          onStorageUnitIdLv3Changed(null)
        }}
      />
      {storageFacilityId && storageUnits.length > 0 && (
        <SelectOptionInput
          selectProps={{ style: { minWidth: 100 } }}
          value={storageUnitIdLv0}
          onChange={(value) => {
            onStorageUnitIdLv0Changed(value)
            onStorageUnitIdLv1Changed(null)
            onStorageUnitIdLv2Changed(null)
            onStorageUnitIdLv3Changed(null)
          }}
          menuItems={storageUnits.map((storageUnit) => ({
            label: storageUnit.name,
            value: storageUnit._id,
          }))}
          showResetButton
          onReset={() => {
            onStorageUnitIdLv0Changed(null)
            onStorageUnitIdLv1Changed(null)
            onStorageUnitIdLv2Changed(null)
            onStorageUnitIdLv3Changed(null)
          }}
        />
      )}
      {storageUnitIdLv0 && firstLevelChildUnits.length > 0 && (
        <SelectOptionInput
          selectProps={{ style: { minWidth: 100 } }}
          value={storageUnitIdLv1}
          onChange={(value) => {
            onStorageUnitIdLv1Changed(value)
            onStorageUnitIdLv2Changed(null)
            onStorageUnitIdLv3Changed(null)
          }}
          menuItems={firstLevelChildUnits
            .filter((unit) => unit.parentId === storageUnitIdLv0)
            .map((storageUnit) => ({
              label: storageUnit.name,
              value: storageUnit._id,
            }))}
          showResetButton
          onReset={() => {
            onStorageUnitIdLv1Changed(null)
            onStorageUnitIdLv2Changed(null)
            onStorageUnitIdLv3Changed(null)
          }}
        />
      )}
      {storageUnitIdLv1 && secondLevelChildUnits.length > 0 && (
        <SelectOptionInput
          selectProps={{ style: { minWidth: 100 } }}
          value={storageUnitIdLv2}
          onChange={(value) => {
            onStorageUnitIdLv2Changed(value)
            onStorageUnitIdLv3Changed(null)
          }}
          menuItems={secondLevelChildUnits
            .filter((unit) => unit.parentId === storageUnitIdLv1)
            .map((storageUnit) => ({
              label: storageUnit.name,
              value: storageUnit._id,
            }))}
          showResetButton
          onReset={() => {
            onStorageUnitIdLv2Changed(null)
            onStorageUnitIdLv3Changed(null)
          }}
        />
      )}
      {storageUnitIdLv2 && thirdLevelChildUnits.length > 0 && (
        <SelectOptionInput
          selectProps={{ style: { minWidth: 100 } }}
          value={storageUnitIdLv3}
          onChange={(value) => {
            onStorageUnitIdLv3Changed(value)
          }}
          menuItems={thirdLevelChildUnits
            .filter((unit) => unit.parentId === storageUnitIdLv2)
            .map((storageUnit) => ({
              label: storageUnit.name,
              value: storageUnit._id,
            }))}
          showResetButton
          onReset={() => {
            onStorageUnitIdLv3Changed(null)
          }}
        />
      )}
    </Box>
  )
}
