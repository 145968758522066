import { Button, Chip, MenuItem, Select, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface ModelsPickerValue {
  label: string
  id: string
}

export interface ModelsPickerProps {
  options: ModelsPickerValue[]
  onAdd: (id: string) => void
  onDelete: (id: string) => void
  onClick: (id: string) => void
  values: ModelsPickerValue[]
  label: string
}

export default function ModelsPicker(props: ModelsPickerProps) {
  const { label, options, onAdd, onDelete, onClick, values } = props
  const { t } = useTranslation()

  const [selectedId, setSelectedId] = useState<string>('')

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        {label}
      </Typography>
      <div>
        <Select
          className="u-ml-sm"
          value={selectedId}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedId(e.target.value)
          }}
          style={{ width: 500 }}
        >
          {options
            .filter((option) => !values.find((value) => option.id === value.id))
            .map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        <Button onClick={() => onAdd(selectedId)} disabled={!selectedId}>
          {t('add')}
        </Button>
      </div>
      <div className="u-mt-sm u-flex" style={{ gap: '10px' }}>
        {values.map((value) => (
          <Chip
            key={value.id}
            label={value.label}
            onDelete={() => onDelete(value.id)}
            onClick={() => onClick(value.id)}
          />
        ))}
      </div>
    </div>
  )
}
