import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateProductArgs } from '@/schemaTypes'

const CREATE_PRODUCT = gql`
  mutation createProduct($args: ProductCreateArgs!) {
    createProduct(args: $args) {
      _id
    }
  }
`

type TData = { createProduct: Mutation['createProduct'] }
type TVariables = MutationCreateProductArgs

export function useCreateProduct(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [mutation] = useMutation<TData, TVariables>(CREATE_PRODUCT, opts)
  return mutation
}
