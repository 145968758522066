import ManualFeeDefinition from '../ManualFeeDefintion/ManualFeeDefinition'
import UsedFeeDefinitionViewer from '../UsedFeeDefinitionViewer'
import { Box, Slider, TextareaAutosize, Typography } from '@material-ui/core'
import { Paper, Switch } from '@mui/material'
import { Checkbox } from '@mui/material'
import dayjs from 'dayjs'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AmountInput from '@/components/AmountInput'
import Info from '@/components/Info'
import ShopSelect from '@/components/ShopSelect'
import TextInput from '@/components/TextInput'
import { useGetExistingDealCalculation } from '@/domains/deals/hooks'
import {
  Deal,
  DealCalculation,
  EBusinessUnit,
  EDealType,
  ETransportMode,
  GetExistingDealCalculationArgs,
} from '@/schemaTypes'
import { getManualFeeDefinitionsFromCalculation } from '@/utils/deal'

interface VerifyBoxProps {
  deal: Deal
  state
  setState
}

const VerifyBox: FunctionComponent<VerifyBoxProps> = (props) => {
  const { deal, state, setState } = props
  const { t } = useTranslation()

  const today = dayjs().toDate()
  const [dealCalculation, setDealCalculation] = useState<DealCalculation>()
  const defaultExistingDealCalculationArgs: GetExistingDealCalculationArgs =
    useMemo(() => {
      const existingDealCalculationArgs: GetExistingDealCalculationArgs = {
        dealType: deal.dealType,
        dropoffTransportMode:
          deal.dropoffData?.transportMode ?? ETransportMode.Shop,
        durationInDays: deal.dealFinalValues.durationInDays,
        overwrittenPayoutAmount: deal.dealFinalValues.payoutAmount,
        shouldOverwritePayoutAmount: true, // We do not want to change the payout.
        manualFeeDefinitionsArgs: [],
        shouldOverwriteManualFees: false,
        isForCustomer: true,
      }
      return existingDealCalculationArgs
    }, [deal])

  const [showManualFeeDetails, setShowManualFeeDetails] =
    useState<boolean>(false)
  const [existingDealCalculationArgs, setExistingDealCalculationArgs] =
    useState<GetExistingDealCalculationArgs>({
      ...defaultExistingDealCalculationArgs,
    })

  const { queryResult: calculationQueryResult } = useGetExistingDealCalculation(
    {
      variables: {
        dealId: props.deal._id,
        args: existingDealCalculationArgs,
      },
      skip: !existingDealCalculationArgs,
    },
  )

  useEffect(() => {
    if (calculationQueryResult?.data?.getExistingDealCalculation)
      setDealCalculation(
        calculationQueryResult?.data?.getExistingDealCalculation,
      )
  }, [calculationQueryResult?.data?.getExistingDealCalculation])

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Box>
        <p style={{ marginBottom: '1rem' }}>
          {t('select_cashbook_for_booking')}:
        </p>
        <ShopSelect
          idPrefix="DEAL_STATUS_VERIFIED_SHOP_ID_"
          companyId={deal.company?._id}
          shopId={state.verifiedShopId}
          onChange={(value) => setState({ ...state, verifiedShopId: value })}
          useDefaultShopId
        />

        {deal.dealType === EDealType.Pawn &&
        deal.businessUnit === EBusinessUnit.Car &&
        deal.items[0].isContinueUsing ? (
          <Box style={{ marginTop: '1rem' }}>
            <div style={{ display: 'inline-block' }}>
              {' '}
              {t('car_inspection.max_pawn_duration')}:
            </div>
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <div className="flex flex-row items-center">
                <TextInput
                  variant="outlined"
                  value={
                    state.finalMaxPawnDate
                      ? state.finalMaxPawnDate.diff(dayjs(), 'days')
                      : 180
                  }
                />
                <Info
                  svgStyles={{
                    width: '1rem',
                    cursor: 'pointer',
                  }}
                  className={'o-media__fixed u-inline-block'}
                  infoText={t('max_pawn_duration_tooltip')}
                />
              </div>
              <Slider
                style={{
                  width: '90%',
                  marginLeft: '0.6rem',
                  marginTop: '-1.5rem',
                }}
                defaultValue={180}
                onChange={(event, newValue: number) => {
                  setState({
                    ...state,
                    finalMaxPawnDate: dayjs(today).add(newValue + 1, 'days'),
                  })
                }}
                valueLabelDisplay="off"
                min={60}
                max={360}
                step={30}
              />
            </div>
          </Box>
        ) : (
          ''
        )}

        {deal.dealType === EDealType.Pawn && (
          <Box style={{ marginTop: '1rem' }}>
            <span style={{ marginBottom: '1rem', textAlign: 'right' }}>
              {t('allow_online_extension')}:
            </span>
            <Switch
              color="secondary"
              checked={!state.isOnlineExtensionPrevented}
              onChange={(event) => {
                setState({
                  ...state,
                  isOnlineExtensionPrevented: !event.target.checked,
                })
              }}
            />
            {state.isOnlineExtensionPrevented && (
              <>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ marginTop: '1rem' }}
                >
                  {t('in_house_reason_for_extension_ban') + '*'}
                </Typography>
                <TextareaAutosize
                  rowsMin={4}
                  placeholder={t('note_placeholder')}
                  style={{ width: 350 }}
                  value={state.onlineExtensionBanNote}
                  onChange={(e) =>
                    setState({
                      ...state,
                      onlineExtensionBanNote: e.target.value,
                    })
                  }
                  name="content"
                />
              </>
            )}
          </Box>
        )}

        <Box style={{ marginTop: '1rem' }}>
          <span style={{ marginBottom: '1rem', textAlign: 'right' }}>
            {t('manual_mode')}:
          </span>
          <Switch
            color="secondary"
            checked={showManualFeeDetails}
            onChange={(event) => {
              setShowManualFeeDetails(event.target.checked)
              setState({
                ...state,
                shouldOverwriteManualFees: event.target.checked,
                manualFeeDefinitionsArgs:
                  getManualFeeDefinitionsFromCalculation(
                    dealCalculation,
                    deal.dealType,
                  ),
              })
            }}
            disabled={!dealCalculation}
          />
          {showManualFeeDetails && (
            <>
              <ManualFeeDefinition
                deal={deal}
                dealCalculation={dealCalculation}
                initialCalculation={dealCalculation}
                calculationQueryResult={calculationQueryResult}
                args={getManualFeeDefinitionsFromCalculation(
                  dealCalculation,
                  deal.dealType,
                )}
                setArgs={(value) => {
                  setExistingDealCalculationArgs({
                    ...existingDealCalculationArgs,
                    manualFeeDefinitionsArgs: value,
                    shouldOverwriteManualFees: showManualFeeDetails,
                  })
                  setState({ ...state, manualFeeDefinitionsArgs: value })
                }}
                showPayoutAmount={true}
                showResetButton={true}
                isReadonly={false}
              />

              <Box style={{ width: '80%', margin: 'auto', marginTop: '2rem' }}>
                <AmountInput
                  label={t('fees_preview_duration')}
                  inputWidth="70px"
                  type="int"
                  labelRightMargin={1}
                  value={existingDealCalculationArgs.durationInDays}
                  onChange={(value) => {
                    setExistingDealCalculationArgs({
                      ...existingDealCalculationArgs,
                      durationInDays: value >= 0 ? value : 0,
                    })
                  }}
                />

                <UsedFeeDefinitionViewer
                  deal={deal}
                  dealCalculation={dealCalculation}
                  items={deal.items}
                  isLoading={calculationQueryResult.loading}
                  showLayoutButton={false}
                  defaultLayout={'module'}
                  showItemDetailsButton={false}
                  itemRecordsButtonDefaultValue={true}
                  showChargTableText={false}
                  showCalculationDate={false}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      {state.showOverruleConfirm && (
        <Box
          style={{
            width: showManualFeeDetails ? '60%' : '100%',
            margin: 'auto',
            marginTop: '1.5rem',
            textAlign: 'left',
          }}
        >
          <Typography>{t('run_ksv_before_deal')}</Typography>
          {t('book_without_query_confirm')}
          <Checkbox
            id="DEAL_STATUS_VERIFIED_CHECKBOX"
            color="error"
            onChange={(e) =>
              setState({ ...state, disableRightButton: !e.target.checked })
            }
          />
        </Box>
      )}
    </Paper>
  )
}

export default VerifyBox
