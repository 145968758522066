import { Paper, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Deal, DealItem } from '@/schemaTypes'
import styles from './AuctionElementComponent.module.scss'
import AuctionElementDealItemInfo from './AuctionElementDealItemInfo'
import { IMinCashyPrice } from './Element'

export interface AuctionElementDealItemsOverViewProps {
  deal: Deal
  itemsFromDeal: DealItem[]
  minCashyPrices: IMinCashyPrice[]
}

const AuctionElementDealItemsOverView: React.FC<
  AuctionElementDealItemsOverViewProps
> = ({ deal, itemsFromDeal, minCashyPrices }) => {
  const { t } = useTranslation()

  const ItemInfoRendering = itemsFromDeal
    ? itemsFromDeal.map((item) => (
        <AuctionElementDealItemInfo
          key={item._id}
          deal={deal}
          item={item}
          minCashyPrice={minCashyPrices.find((c) => c.itemId === item._id)!}
        />
      ))
    : []

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Typography variant="h6" gutterBottom className={'u-flex u-jc-sb'}>
        {t('item_overview')}
      </Typography>
      <Table className={`${styles.auctionDealItemValuesTable}`} size="small">
        <TableBody>
          <TableRow className={`${styles.auctionDealItemValuesTable}`}>
            <TableCell align="left" style={{ fontWeight: 'bold' }}>
              {t('id')}
            </TableCell>
            <TableCell align="left" style={{ fontWeight: 'bold' }}>
              {t('item.title')}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {t('valuation')}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {t('cashy_price')}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {t('internal_sale_data')}
            </TableCell>
            <TableCell />
          </TableRow>
          {ItemInfoRendering}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default AuctionElementDealItemsOverView
