import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetCustomDealsCountArgs } from '@/schemaTypes'

const GET_CUSTOM_DEALS_COUNT = gql`
  query getCustomDealsCount($opts: CustomDealOpts) {
    getCustomDealsCount(opts: $opts)
  }
`

type TData = { getCustomDealsCount: Query['getCustomDealsCount'] }
type TVariables = QueryGetCustomDealsCountArgs

export function useGetCustomDealsCount(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_CUSTOM_DEALS_COUNT, {
    variables: {},
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { customDealsTotal: queryResult.data?.getCustomDealsCount || 0 }
}
