import * as React from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { RefurbedOffer } from '@/schemaTypes'
import { printLocalAmount } from '@/utils/misc'

interface RefurbedOfferStatusRowProps {
  refurbedOffer: RefurbedOffer
}

const RefurbedOfferStatusRow: FunctionComponent<RefurbedOfferStatusRowProps> = (
  props,
) => {
  const { refurbedOffer } = props
  const { t } = useTranslation()

  return (
    <>
      <p>{`${t('regular_price')}: ${printLocalAmount(refurbedOffer.offeredPrice)}`}</p>
      <p>{`${t('minimum_price')}: ${printLocalAmount(refurbedOffer.lowestPrice)}`}</p>
      <p>{`${t('shipping_profile')}: ${
        refurbedOffer.shippingProfileName &&
        refurbedOffer.shippingProfileName.replace(
          'Destinations',
          t('shipping_profile_destinations'),
        )
      }`}</p>
      <p>{`${t('buybox_status')}: ${refurbedOffer.bestBuyBox?.state || ' - '}`}</p>
    </>
  )
}

export default RefurbedOfferStatusRow
