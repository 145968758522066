import { isValidCashbookTransaction } from '../AddPrivateSale/PrivateSaleTransactionsList'
import styled from '@emotion/styled'
import {
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import { ceil } from 'lodash'
import { Fragment, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { manualPaymentTypes } from '@/components/PaymentTypePicker'
import ShopSelect from '@/components/ShopSelect'
import { UppyFileChangeResults, UppyUploader } from '@/components/UppyUploader'
import { ALLOWED_FILE_TYPES } from '@/constants'
import {
  allowedTransactionsIfPrivateSaleAlreadyCreated,
  transactionsNotConnectedToCashbookAnyway,
  transactionsWithSpecificLabelOnBill,
  transactionsWithUStAffect,
} from '@/domains/privateSales/constants'
import {
  EManualPaymentType,
  ETransactionType,
  Item,
  PrivateSale,
  PrivateSaleTransactionArgs,
} from '@/schemaTypes'
import { printLocalAmount } from '@/utils/misc'
import styles from './PrivateSaleTransactionInputDetails.module.scss'

interface PrivateSaleTransactionInputDetailsProps {
  privateSaleTransactionArgs: PrivateSaleTransactionArgs
  setPrivateSaleTransactionArgs: (args: PrivateSaleTransactionArgs) => void
  privateSale?: PrivateSale | null
  isPrivateSaleCreated: boolean
  isEditMode: boolean
  item?: Item
}

const CustomShopSelect = styled(ShopSelect)`
  .MuiInputBase-root {
    margin-left: 0 !important;
  }
`

const manualPaymentTypesForPrivateSale = [...manualPaymentTypes].filter(
  (c) => c.value !== EManualPaymentType.CashOnDelivery,
)

const PrivateSaleTransactionInputDetails: FunctionComponent<
  PrivateSaleTransactionInputDetailsProps
> = (props) => {
  const {
    privateSaleTransactionArgs,
    setPrivateSaleTransactionArgs,
    isPrivateSaleCreated,
    privateSale,
    isEditMode,
    item,
  } = props
  const { t } = useTranslation()
  const transactionTypes = isPrivateSaleCreated
    ? Object.values(ETransactionType)
    : [
        ETransactionType.SellingCar,
        ETransactionType.LiquidationFee,
        ETransactionType.ThirdPartyCosts,
        ETransactionType.CashExpenditures,
        ETransactionType.OtherFees,
      ]

  const handleAutoFillPriceNet = () => {
    setPrivateSaleTransactionArgs({
      ...privateSaleTransactionArgs,
      amount: Math.abs(
        parseFloat(printLocalAmount(privateSale?.dealFinalBalance)),
      ),
    })
  }

  const onFileChanged = ([results]: UppyFileChangeResults[]) => {
    setPrivateSaleTransactionArgs({
      ...privateSaleTransactionArgs,
      transactionFileLink: 'newFile' in results ? results.newFile : null,
    })
  }

  return (
    <Fragment>
      <table>
        <tr className={`${styles.tr}`}>
          <td className={`${styles.td}`}>
            <div className={`${styles.horizontalCellLabel}`}>{t('date')}:</div>
            <div className={`${styles.horizontalCellValue}`}>
              <KeyboardDatePicker
                className={`${styles.datePicker}`}
                clearable
                maxDate={dayjs(new Date()).add(7, 'day').toDate()}
                format="DD.MM.YYYY"
                value={privateSaleTransactionArgs.date}
                onChange={(value) =>
                  setPrivateSaleTransactionArgs({
                    ...privateSaleTransactionArgs,
                    date: value,
                  })
                }
              />
            </div>
          </td>
        </tr>

        <tr className={`${styles.tr}`}>
          <td className={`${styles.td}`}>
            <div className={`${styles.horizontalCellLabel}`}>
              {t('transaction_type')}:
            </div>
            <div className={`${styles.horizontalCellValue}`}>
              <Select
                value={privateSaleTransactionArgs.transactionType}
                onChange={(e) => {
                  const transactionType = e.target.value as ETransactionType
                  setPrivateSaleTransactionArgs({
                    ...privateSaleTransactionArgs,
                    transactionType: transactionType,
                    regenerateBill:
                      !allowedTransactionsIfPrivateSaleAlreadyCreated.includes(
                        transactionType,
                      )
                        ? isEditMode || isPrivateSaleCreated
                        : false,
                  })
                }}
              >
                {transactionTypes.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </td>
        </tr>

        {privateSaleTransactionArgs.transactionType ===
          ETransactionType.SellingCar && (
          <tr className={`${styles.tr}`}>
            <td className={`${styles.td}`}>
              <div className={`${styles.horizontalCellLabel}`}>
                {t('buyer_name')}:
              </div>
              <div className={`${styles.horizontalCellValue}`}>
                <TextField
                  value={privateSaleTransactionArgs.buyerName}
                  onChange={(value) =>
                    setPrivateSaleTransactionArgs({
                      ...privateSaleTransactionArgs,
                      buyerName: value.target.value,
                    })
                  }
                />
              </div>
            </td>
          </tr>
        )}

        {transactionsWithSpecificLabelOnBill.includes(
          privateSaleTransactionArgs.transactionType,
        ) && (
          <tr className={`${styles.tr}`}>
            <td className={`${styles.td}`}>
              <div className={`${styles.horizontalCellLabel}`}>
                {t('cost_name')}:
              </div>
              <div className={`${styles.horizontalCellValue}`}>
                <TextField
                  value={privateSaleTransactionArgs.costName}
                  onChange={(value) =>
                    setPrivateSaleTransactionArgs({
                      ...privateSaleTransactionArgs,
                      costName: value.target.value,
                    })
                  }
                />
              </div>
            </td>
          </tr>
        )}

        {transactionsWithUStAffect.includes(
          privateSaleTransactionArgs.transactionType,
        ) && (
          <>
            <tr className={`${styles.tr}`}>
              <td className={`${styles.td}`}>
                <div className={`${styles.horizontalCellLabel}`}>
                  {t('gross_amount')}:
                </div>
                <div className={`${styles.horizontalCellValue}`}>
                  <TextField
                    type={'number'}
                    value={privateSaleTransactionArgs.grossAmount}
                    onChange={(value) => {
                      if (
                        transactionsWithUStAffect.includes(
                          privateSaleTransactionArgs.transactionType,
                        )
                      ) {
                        const devider =
                          1 + (privateSaleTransactionArgs.ust ?? 1) / 100
                        setPrivateSaleTransactionArgs({
                          ...privateSaleTransactionArgs,
                          grossAmount: parseFloat(value.target.value),
                          amount: ceil(
                            parseFloat(value.target.value) / devider,
                            2,
                          ),
                        })
                      }
                    }}
                  />
                  <span className={styles.unit}>€</span>
                </div>
              </td>
            </tr>

            <tr className={`${styles.tr}`}>
              <td className={`${styles.td}`}>
                <div className={`${styles.horizontalCellLabel}`}>
                  {t('ust')}:
                </div>
                <div className={`${styles.horizontalCellValue}`}>
                  <TextField
                    type={'number'}
                    value={privateSaleTransactionArgs.ust}
                    // disabled={true}
                    onChange={(value) => {
                      if (
                        transactionsWithUStAffect.includes(
                          privateSaleTransactionArgs.transactionType,
                        )
                      ) {
                        const devider =
                          1 + (parseFloat(value.target.value) ?? 1) / 100
                        setPrivateSaleTransactionArgs({
                          ...privateSaleTransactionArgs,
                          ust: parseFloat(value.target.value),
                          amount: ceil(
                            (privateSaleTransactionArgs.grossAmount ?? 0) /
                              devider,
                            2,
                          ),
                        })
                      }
                    }}
                  />
                  <span className={styles.unit}>%</span>
                </div>
              </td>
            </tr>
          </>
        )}

        <tr className={`${styles.tr}`}>
          <td className={`${styles.td}`}>
            <div className={`${styles.horizontalCellLabel}`}>
              {transactionsWithUStAffect.includes(
                privateSaleTransactionArgs.transactionType,
              )
                ? t('net_amount')
                : t('amount.label')}
              :
            </div>

            <div className={`${styles.horizontalCellValue}`}>
              <TextField
                type={'number'}
                value={privateSaleTransactionArgs.amount}
                onChange={(value) => {
                  if (
                    transactionsWithUStAffect.includes(
                      privateSaleTransactionArgs.transactionType,
                    )
                  ) {
                    const mul = 1 + (privateSaleTransactionArgs.ust ?? 1) / 100

                    setPrivateSaleTransactionArgs({
                      ...privateSaleTransactionArgs,
                      amount: parseFloat(value.target.value),
                      grossAmount: ceil(
                        parseFloat(value.target.value) * mul,
                        2,
                      ),
                    })
                  } else {
                    setPrivateSaleTransactionArgs({
                      ...privateSaleTransactionArgs,
                      amount: parseFloat(value.target.value),
                    })
                  }
                }}
              />
              <span className={styles.unit}>€</span>

              {isPrivateSaleCreated &&
              (privateSaleTransactionArgs.transactionType ===
                ETransactionType.PayoutExcessAmount ||
                privateSaleTransactionArgs.transactionType ===
                  ETransactionType.CustomerPaymentAfterSale ||
                privateSaleTransactionArgs.transactionType ===
                  ETransactionType.Derecognization) ? (
                privateSale?.dealFinalBalance &&
                privateSale?.dealFinalBalance > 0 ? (
                  <span
                    className={styles.autoFillPrice}
                    style={{ color: 'green' }}
                    onClick={handleAutoFillPriceNet}
                  >
                    {printLocalAmount(privateSale.dealFinalBalance)}
                  </span>
                ) : privateSale &&
                  privateSale.dealFinalBalance &&
                  privateSale.dealFinalBalance < 0 ? (
                  <span
                    className={styles.autoFillPrice}
                    style={{ color: 'red' }}
                    onClick={handleAutoFillPriceNet}
                  >
                    {printLocalAmount(privateSale.dealFinalBalance)}
                  </span>
                ) : (
                  <span
                    className={styles.autoFillPrice}
                    style={{ color: 'black' }}
                    onClick={handleAutoFillPriceNet}
                  >
                    {printLocalAmount(privateSale?.dealFinalBalance)}
                  </span>
                )
              ) : (
                ''
              )}
            </div>
          </td>
        </tr>
        {privateSaleTransactionArgs.paymentType && (
          <tr className={`${styles.tr}`}>
            <td className={`${styles.td}`}>
              <div className={`${styles.horizontalCellLabel}`}>
                {t('payment_type')}:
              </div>
              <div className={`${styles.horizontalCellValue}`}>
                <Select
                  disabled={transactionsNotConnectedToCashbookAnyway.includes(
                    privateSaleTransactionArgs.transactionType,
                  )}
                  value={privateSaleTransactionArgs.paymentType}
                  onChange={(e) => {
                    const tempType = e.target.value as EManualPaymentType
                    setPrivateSaleTransactionArgs({
                      ...privateSaleTransactionArgs,
                      paymentType: tempType,
                    })
                  }}
                >
                  {manualPaymentTypesForPrivateSale.map((name) => (
                    <MenuItem key={name.label} value={name.value}>
                      {t(name.label)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </td>
          </tr>
        )}

        {isValidCashbookTransaction(privateSaleTransactionArgs) && (
          <tr className={`${styles.tr}`}>
            <td className={`${styles.td}`}>
              <div className={`${styles.horizontalCellLabel}`}>
                {t('cashbook')}:
              </div>
              <div className={`${styles.horizontalCellValue}`}>
                <CustomShopSelect
                  companyId={item?.companyId}
                  shopId={privateSaleTransactionArgs.shopId}
                  onChange={(value) => {
                    setPrivateSaleTransactionArgs({
                      ...privateSaleTransactionArgs,
                      shopId: value,
                    })
                  }}
                  useDefaultShopId
                  hideLabel
                />
              </div>
            </td>
          </tr>
        )}

        <tr className={`${styles.tr}`}>
          <td className={`${styles.td}`}>
            <div className={`${styles.horizontalCellValueTextArea}`}>
              <UppyUploader
                shouldNotConfirmBeforeRemove
                label={t('customer.files')}
                allowedFileTypes={[...ALLOWED_FILE_TYPES, '.pdf']}
                initialFiles={
                  privateSaleTransactionArgs.transactionFileLink
                    ? [privateSaleTransactionArgs.transactionFileLink]
                    : null
                }
                onFilesChanged={onFileChanged}
              />
            </div>
          </td>
        </tr>

        <tr className={`${styles.tr}`}>
          <td className={`${styles.td}`}>
            <div className={`${styles.horizontalCellLabelTextArea}`}>
              {t('description')}:
            </div>
            <div className={`${styles.horizontalCellValueTextArea}`}>
              <TextareaAutosize
                value={privateSaleTransactionArgs.description ?? ''}
                rowsMin={4}
                placeholder={t('any_description_want_to_add')}
                style={{ width: '140%' }}
                onChange={(e) =>
                  setPrivateSaleTransactionArgs({
                    ...privateSaleTransactionArgs,
                    description:
                      e.target.value.length > 0 ? e.target.value : undefined,
                  })
                }
              />
            </div>
          </td>
        </tr>

        {privateSaleTransactionArgs.regenerateBill && (
          <tr className={`${styles.tr}`}>
            <td className={`${styles.td}`}>
              <div className={`${styles.horizontalCheckboxCellValue}`}>
                {t('bill_regenerating_required_warning')}
              </div>
            </td>
          </tr>
        )}
      </table>
    </Fragment>
  )
}

export default PrivateSaleTransactionInputDetails
