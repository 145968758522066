import styled from '@emotion/styled'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'
import { useTranslation } from 'react-i18next'
import Input from '@/redesign/components/Input/Input'

export interface GuestCustomerInfoCardProps {
  customerEmail: string
  onConnectCustomerToDealClick: () => void
}

const GuestCustomerInfoCard = ({
  customerEmail,
  onConnectCustomerToDealClick,
}: GuestCustomerInfoCardProps) => {
  const { t } = useTranslation()

  return (
    <OverviewContainer>
      <Message
        severity="warn"
        text={t('guest_customer.no_customer_connected')}
      />

      <Input
        type="email"
        label={t('email')}
        placeholder={t('email')}
        className=" items-start w-60 mb-6 mt-4"
        labelClassName="text-sm font-semibold"
        value={customerEmail}
        disabled
      />

      <Button
        label={t('guest_customer.connect_customer_to_deal')}
        onClick={onConnectCustomerToDealClick}
      />
    </OverviewContainer>
  )
}

const OverviewContainer = styled.div`
  width: 25rem;
  flex-shrink: 0;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export default GuestCustomerInfoCard
