import styled from '@emotion/styled'
import { Box, Tooltip, Typography } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ClearIcon from '@material-ui/icons/Clear'
import Compressor from '@uppy/compressor'
import Uppy, { UppyFile } from '@uppy/core'
import '@uppy/core/dist/style.css'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import Tus from '@uppy/tus'
import { uniqueId } from 'lodash'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { ALLOWED_FILE_TYPES, MAX_IMAGE_SIZE_MB, UppyError } from '@/constants'
import { Context } from '@/context'
import { useShowConfirmPopup } from '@/hooks'
import PDFIcon from '@/images/pdf.png'
import DeleteIcon from '@/images/trashIcon.svg'
import { FileTusMetadataArgs } from '@/schemaTypes'

export type UppyFileObject = UppyFile

interface UploaderProps {
  idPrefix?: string
  label?: string
  multiple?: boolean
  initialFiles: FileTusMetadataArgs[] | null
  allowedFileTypes?: Array<string>
  checkIcon?: 'success' | 'failure'
  disableUploadButton?: boolean
  shouldNotConfirmBeforeRemove?: boolean
  compress?: boolean
  onFilesChanged?: (result: UppyFileChangeResults[]) => void
  onOpenGalery?: (
    files: Array<{ url: string; type: string }>,
    fileIndex: number,
  ) => void
  hideFileName?: boolean
}

export type FileArgs = FileTusMetadataArgs | UppyFileObject
export type UppyFileChangeResults =
  | { newFile: FileTusMetadataArgs }
  | { deletedFileIndex: number }

export const UppyUploader = ({
  label,
  idPrefix,
  initialFiles,
  onFilesChanged,
  multiple,
  allowedFileTypes = ALLOWED_FILE_TYPES,
  checkIcon,
  shouldNotConfirmBeforeRemove,
  onOpenGalery,
  disableUploadButton,
  compress = true,
  hideFileName,
}: UploaderProps) => {
  const { t } = useTranslation()
  const { closeConfirmModal } = useContext(Context)
  const uploadingNumber = useRef(0)
  const uploadingCount = useRef(0)
  const uploadingFiles = useRef<UppyFileChangeResults[]>([])

  const uppy = useMemo(() => {
    const instance = new Uppy({
      id: 'uppy-' + uniqueId(),
      autoProceed: true,
      restrictions: {
        maxFileSize: MAX_IMAGE_SIZE_MB * 10000000,
        allowedFileTypes: allowedFileTypes,
      },
      locale: {
        strings: {
          noDuplicates: UppyError.FILE_DUPLICATION,
          exceedsSize: UppyError.EXCEED_MAX_FILE_SIZE,
          youCanOnlyUploadFileTypes: UppyError.FILE_TYPE_UNALLOWED,
        },
      },
    })

    if (compress) {
      instance.use(Compressor)
    }

    instance
      .use(Tus, {
        endpoint: process.env.REACT_APP_TUS_SERVER_URL,
        retryDelays: [0, 1000, 3000, 5000],
      })
      .use(ThumbnailGenerator, {
        id: 'ThumbnailGenerator',
      })

    return instance
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showConfirmPopup = useShowConfirmPopup({
    actionText: t('delete'),
    title: t('confirm_delete'),
  })

  const [uppyRestrictionError, setUppyRestrictionError] = useState<
    string | null
  >(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [files, setFiles] = useState<Array<FileArgs>>([])

  useEffect(() => {
    if (initialFiles?.length) {
      setFiles(initialFiles)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (uppyRestrictionError) {
      setTimeout(() => {
        setUppyRestrictionError(null)
      }, 5000)
    }
  }, [uppyRestrictionError])

  const handleUppyError = (file: File, error: any) => {
    if (!(error instanceof Error)) {
      setUppyRestrictionError(t('an_unexpected_error_occured'))
      return
    }

    switch (error.message) {
      case UppyError.FILE_DUPLICATION: {
        setUppyRestrictionError(
          t('file_duplication', {
            filename: file.name,
          }),
        )
        return
      }

      case UppyError.EXCEED_MAX_FILE_SIZE: {
        setUppyRestrictionError(
          t('exceed_max_file_size', {
            size: MAX_IMAGE_SIZE_MB,
          }),
        )
        return
      }

      case UppyError.FILE_TYPE_UNALLOWED: {
        setUppyRestrictionError(
          t('file_type_unallowed', {
            allowedFileTypes: allowedFileTypes.join(', '),
          }),
        )
        return
      }

      default: {
        setUppyRestrictionError(t('an_unexpected_error_occured'))
        return
      }
    }
  }

  const onUppyFileChanges = useCallback(
    (uppyFile: UppyFileObject) => {
      const newFile = uppy.getFile(uppyFile.id)
      setFiles((prev: FileArgs[]) => {
        const next = [...prev]
        const updatedFileIndex = next.findIndex(
          (file) => isUppyFile(file) && file.id === uppyFile.id,
        )

        const prevFile = next[updatedFileIndex]

        if (!prevFile?.response?.uploadURL && newFile.response?.uploadURL) {
          const newData = {
            newFile: {
              fileId:
                newFile.response.uploadURL.replace(
                  process.env.REACT_APP_TUS_SERVER_URL + '/uploads/',
                  '',
                ) ?? null,
              filename: newFile.name,
              url: getUppyFilePreview(newFile),
            },
          }

          // handle finish multiple files
          uploadingFiles.current.push(newData)
          uploadingCount.current += 1
          if (uploadingCount.current === uploadingNumber.current) {
            onFilesChanged?.(uploadingFiles.current)
            // reset flags
            uploadingFiles.current = []
            uploadingCount.current = 0
            uploadingNumber.current = 0
          }
        }

        if (updatedFileIndex !== -1) {
          next[updatedFileIndex] = newFile
        } else {
          return [...next, newFile]
        }

        return next
      })
    },
    [uppy, onFilesChanged],
  )

  const onFileRemoved = (file: FileArgs, index: number) => {
    if (isUppyFile(file)) {
      uppy.removeFile(file.id)
    }

    setFiles((prev) => prev.filter((f, i) => i !== index))
    onFilesChanged([{ deletedFileIndex: index }])
  }

  useEffect(() => {
    uppy.on('thumbnail:generated', onUppyFileChanges)
    uppy.on('upload-success', onUppyFileChanges)
    uppy.on('upload-progress', onUppyFileChanges)

    return () => {
      uppy.off('thumbnail:generated', onUppyFileChanges)
      uppy.off('upload-success', onUppyFileChanges)
      uppy.off('upload-progress', onUppyFileChanges)
    }
  }, [uppy, onUppyFileChanges])

  return (
    <Box>
      {label && (
        <Typography style={{ fontWeight: 500, marginBottom: 15 }}>
          {label}
        </Typography>
      )}

      {files.map((file, i) => {
        return (
          <FileWrapper style={{ opacity: disableUploadButton ? 0.5 : 1 }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <FilePreview
                onClick={() => {
                  if (onOpenGalery) {
                    onOpenGalery(
                      files.map((f) => ({
                        url: isUppyFile(f)
                          ? f.preview ?? f.response?.uploadURL
                          : f.url,
                        type: isUppyFile(f) ? f.data.type : f.contentType,
                      })),
                      i,
                    )
                  } else {
                    window.open(
                      isUppyFile(file)
                        ? file.preview ?? URL.createObjectURL(file.data)
                        : file.url,
                      '_blank',
                    )
                  }
                }}
                key={i}
                url={getFilePreview(file)}
                isUploaded={
                  !isUppyFile(file) ? true : !!file.response?.uploadURL
                }
              >
                {isUppyFile(file) && !file.response?.uploadURL && (
                  <ProgressWrapper>
                    {file.progress?.percentage ?? 0} %
                  </ProgressWrapper>
                )}
              </FilePreview>
              <CloseButton
                onClick={() => {
                  if (shouldNotConfirmBeforeRemove) {
                    onFileRemoved(file, i)
                  } else {
                    showConfirmPopup({
                      action: () => {
                        onFileRemoved(file, i)
                        closeConfirmModal()
                      },
                    })
                  }
                }}
              >
                <ReactSVG src={DeleteIcon} />{' '}
              </CloseButton>
              {checkIcon && (
                <CheckIcon type={checkIcon}>
                  {checkIcon === 'success' && (
                    <Tooltip title={t('read_picea_checked_success')}>
                      <CheckCircleIcon fontSize="small" />
                    </Tooltip>
                  )}
                  {checkIcon === 'failure' && (
                    <Tooltip title={t('read_picea_checked_failure')}>
                      <ClearIcon fontSize="small" />
                    </Tooltip>
                  )}
                </CheckIcon>
              )}
            </div>
            {!hideFileName && (
              <Typography
                style={{ fontSize: 12, marginTop: 10, textAlign: 'center' }}
              >
                {getFilename(file)}
              </Typography>
            )}
          </FileWrapper>
        )
      })}
      {(multiple ? true : files.length === 0) && (
        <FileWrapper>
          <UploadIconWrapper
            onClick={() => {
              inputRef?.current?.click()
            }}
          >
            <div>
              <AddCircleOutlineIcon id={`${idPrefix}ADD_FILE`} />
              <StyledInput
                disabled={disableUploadButton}
                onChange={(e) => {
                  if (e.target.files) {
                    const files = e.target.files
                    uploadingNumber.current = files.length
                    uploadingCount.current = 0

                    for (const file of files) {
                      try {
                        uppy.addFile({
                          source: 'file input',
                          name: file.name,
                          type: file.type,
                          data: file,
                        })
                      } catch (error) {
                        handleUppyError(file, error as Error)
                      }
                    }
                  }

                  e.target.value = null
                }}
                ref={inputRef}
                multiple={multiple}
                type="file"
              />
            </div>
          </UploadIconWrapper>
        </FileWrapper>
      )}
      {uppyRestrictionError && (
        <Typography color="error">{uppyRestrictionError}</Typography>
      )}
    </Box>
  )
}

const isUppyFile = (file: FileArgs): file is UppyFileObject => 'id' in file

const getUppyFilePreview = (file: UppyFileObject) =>
  file.preview ?? (file.data ? URL.createObjectURL(file.data) : null)

const getFilePreview = (file: FileArgs) => {
  if (isUppyFile(file)) {
    return file.data?.type === 'application/pdf'
      ? PDFIcon
      : getUppyFilePreview(file)
  }
  return !file.contentType || file.contentType === 'application/pdf'
    ? PDFIcon
    : file.url
}

const getFilename = (file: FileArgs) => {
  const filename = !isUppyFile(file) ? file.filename : file.name
  return filename && filename.length > 20
    ? filename?.substring(0, 20) + '...'
    : filename
}

export const FilePreview = styled.li<{ url: string; isUploaded?: boolean }>`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  filter: brightness(100%);
  opacity: ${(props) => (props.isUploaded ? 1 : 0.6)};
  list-style: none;
  border-radius: 5px;
  cursor: pointer;
  width: 80px;
  height: 110px;
`

const UploadIconWrapper = styled.div`
  float: left;
  width: 80px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 17px;
  &:hover {
    cursor: pointer;
  }
`

const StyledInput = styled.input`
  display: none;
`

const CloseButton = styled.span`
  cursor: pointer;
  z-index: 100;
  margin-left: -17px;
  margin-top: -12px;
  width: 48px;
  height: 48px;
`

const CheckIcon = styled.div<{ type: 'success' | 'failure' }>`
  cursor: pointer;
  bottom: 45px;
  left: 50%;
  transform: translateX(25px);
  position: absolute;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ type }) => type === 'success' && `background: #04d225;`}
  ${({ type }) => type === 'failure' && `background: #f31c0e;`}
`

const FileWrapper = styled.div`
  display: inline-block;
  margin-right: 30px;
`

const ProgressWrapper = styled.div`
  top: 0px;
  right: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  font-size: 20px;
  color: white;
  font-weight: 600;
  text-shadow: -2px 0px 12px rgba(0, 0, 0, 1);
`
