import withFieldWrapper from '../withFieldWrapper/withFieldWrapper'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { Button } from 'primereact/button'
import { InputText, InputTextProps } from 'primereact/inputtext'
import CopyToClipboard from 'react-copy-to-clipboard'

interface InputCopyClipboardProps extends InputTextProps {
  isInvalid?: boolean
}

const InputCopyClipboard = withFieldWrapper(
  ({ isInvalid, value, ...props }: InputCopyClipboardProps) => {
    return (
      <div className="relative">
        <InputTextStyled
          value={value || ''}
          className={classNames({ 'p-invalid': isInvalid })}
          {...props}
        />
        {value && (
          <CopyToClipboard text={value}>
            <ButtonStyled
              icon="pi pi-copy"
              severity="secondary"
              text
              className="!p-0 !w-6 !h-6"
            />
          </CopyToClipboard>
        )}
      </div>
    )
  },
)

const InputTextStyled = styled(InputText)`
  font-size: 0.875rem;
  height: 2.375rem;
  padding: 0.66rem 2rem 0.66rem 0.66rem;
  width: 100%;
`

const ButtonStyled = styled(Button)`
  position: absolute;
  right: 5px;
  top: 6px;
`

export default InputCopyClipboard
