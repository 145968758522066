import styled from '@emotion/styled'
import { Divider } from 'primereact/divider'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Customer } from '@/schemaTypes'

interface CustomerDealHistoryProps {
  customer?: Partial<Customer> | null
}

const CustomerDealHistory = ({ customer }: CustomerDealHistoryProps) => {
  const { t } = useTranslation()

  return (
    <div className="w-full md:w-64">
      <Title className="mt-6 mb-2">{t('pawn_deal_history')}</Title>
      <div>
        <ContainerRow>
          <DealName>{t('booked_deals')}</DealName>
          <DealValue>
            {customer?.dealHistory?.normalPawn.bookedDealsCount}
          </DealValue>
        </ContainerRow>
        <DividerStyled />
        <ContainerRow>
          <DealName>{t('verified_deals')}</DealName>
          <DealValue>
            {customer?.dealHistory?.normalPawn.verifiedDealsCount}
          </DealValue>
        </ContainerRow>
        <DividerStyled />
        <ContainerRow>
          <DealName>{t('extended_deals')}</DealName>
          <DealValue>
            {customer?.dealHistory?.normalPawn.extendedDealsCount}
          </DealValue>
        </ContainerRow>
        <DividerStyled />
        <ContainerRow>
          <DealName>{t('paidback_deals')}</DealName>
          <DealValue>
            {customer?.dealHistory?.normalPawn.paidBackDealsCount}
          </DealValue>
        </ContainerRow>
        <DividerStyled />
        <ContainerRow>
          <DealName>{t('auctioned_deals')}</DealName>
          <DealValue>
            {customer?.dealHistory?.normalPawn.auctionedDealsCount}
          </DealValue>
        </ContainerRow>
        <DividerStyled />
      </div>

      <Title className="mt-6 mb-2">{t('car_deal_history')}</Title>
      <div>
        <ContainerRow>
          <DealName>{t('booked_car_deals')}</DealName>
          <DealValue>
            {customer?.dealHistory?.carPawn.bookedDealsCount}
          </DealValue>
        </ContainerRow>
        <DividerStyled />
        <ContainerRow>
          <DealName>{t('verified_car_deals')}</DealName>
          <DealValue>
            {customer?.dealHistory?.carPawn.verifiedDealsCount}
          </DealValue>
        </ContainerRow>
        <DividerStyled />
        <ContainerRow>
          <DealName>{t('extended_car_deals')}</DealName>
          <DealValue>
            {customer?.dealHistory?.carPawn.extendedDealsCount}
          </DealValue>
        </ContainerRow>
        <DividerStyled />
        <ContainerRow>
          <DealName>{t('paidback_car_deals')}</DealName>
          <DealValue>
            {customer?.dealHistory?.carPawn.paidBackDealsCount}
          </DealValue>
        </ContainerRow>
        <DividerStyled />
        <ContainerRow>
          <DealName>{t('auctioned_car_deals')}</DealName>
          <DealValue>
            {customer?.dealHistory?.carPawn.auctionedDealsCount}
          </DealValue>
        </ContainerRow>
        <DividerStyled />
      </div>
    </div>
  )
}

const Title = styled.h5`
  color: var(--global-text-color, #495057);
  font-family: 'Inter';
  font-size: 1.09375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.09375rem;
`

const DealName = styled.p`
  color: var(--global-text-secondary-color, #6c757d);
  font-family: 'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.875rem; /* 100% */
`

const DealValue = styled.h5`
  color: var(--global-text-color, #495057);
  text-align: right;
  font-family: 'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0.875rem;
`

const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.44rem;
`

const DividerStyled = styled(Divider)`
  &&& {
    margin: 0;
  }
`

export default CustomerDealHistory
