import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeleteItemCategoryArgs } from '@/schemaTypes'

const DELETE_ITEM_CATEGORY = gql`
  mutation deleteItemCategory($itemCategoryId: ObjectId!) {
    deleteItemCategory(itemCategoryId: $itemCategoryId)
  }
`

type TData = { deleteItemCategory: Mutation['deleteItemCategory'] }
type TVariables = MutationDeleteItemCategoryArgs

export function useDeleteItemCategory(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deleteItemCategory] = useMutation<TData, TVariables>(
    DELETE_ITEM_CATEGORY,
    opts,
  )

  return deleteItemCategory
}
