import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '@/components/Modal'
import { Context } from '@/context'
import { graphql } from '@/gql'
import { useMutationShowingErrors } from '@/hooks'
import { useAppDispatch } from '@/store'
import { EmployeeActiveShop, employeeActiveShop } from '@/store/actions/ui'
import { oneOf } from '@/utils/misc'
import EmployeeShopSelect from './EmployeeShopSelect'
import { useUpdateCurrentEmployeePreferences } from './hooks/updateCurrentEmployeePreferences'

const EmployeeShop_Query = graphql(`
  query EmployeeShop_Query($opts: ShopOpts) {
    getShops(opts: $opts) {
      _id
      name
      ...EmployeeShopSelect_Shops
    }
  }
`)

interface EmployeeShopPopupProps {
  onClose: () => void
}

const EmployeeShopPopup = (props: EmployeeShopPopupProps) => {
  const { getCurrentUser } = useContext(Context)
  const { t } = useTranslation()
  const { onClose } = props
  const dispatch = useAppDispatch()
  const currentUser = getCurrentUser()
  const [shop, setShop] = useState<EmployeeActiveShop>({
    id: currentUser?.preferences?.defaultShopId ?? '',
    name: '',
  })

  const { data } = useQuery(EmployeeShop_Query, {
    variables: {
      opts: {
        filter: {
          companyId: {
            value: currentUser?.company._id,
          },
        },
      },
    },
  })

  const updateEmployee = useMutationShowingErrors({
    mutation: useUpdateCurrentEmployeePreferences(),
    successMessage: t('employee.employee_updated'),
  })

  const onSelectShop = useCallback((shop: EmployeeActiveShop) => {
    setShop(shop)
  }, [])

  const onConfirm = useCallback(async () => {
    const result = await updateEmployee({
      variables: {
        preferences: {
          defaultShopId: shop.id || null,
        },
      },
    })
    if (!result.error) {
      if (shop.id && !shop.name) {
        const matchingShop = data?.getShops?.find((s) => s._id === shop.id)
        if (matchingShop) {
          shop.name = matchingShop.name
        }
      }
      dispatch(employeeActiveShop(shop))
      onClose()
    }
  }, [data?.getShops, shop, updateEmployee, dispatch, onClose])

  const onCancel = useCallback(async () => {
    const result = await updateEmployee({
      variables: {
        preferences: {
          defaultShopId: null,
        },
      },
    })
    if (!result.error) {
      dispatch(employeeActiveShop({ id: '', name: '' }))
    }
    onClose()
  }, [dispatch, onClose, updateEmployee])

  useEffect(() => {
    const close = (e) => {
      if (oneOf(e.keyCode, [27])) {
        onCancel()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal type={'CONFIRM'} hideCloseIcon={true}>
      <ContentModal>
        <div className="u-pt-10">
          <h2 className="o-type-17 u-mb-20">
            {t('employee_active_shop_title')}
          </h2>
        </div>
        <Divider />
        <SelectDescriptionDiv>
          {t('employee_active_shop_select')}:
        </SelectDescriptionDiv>
        <div className="u-text-center u-pb-10">
          <EmployeeShopSelect
            shops={data?.getShops}
            shopId={shop.id}
            setShop={onSelectShop}
          />
        </div>
        <div className="u-text-center u-flex">
          <div className="u-6/12 u-pl-20">
            <StyledButton onClick={onCancel}>{t('abort')}</StyledButton>
          </div>
          <div className="u-6/12 u-pr-20">
            <StyledButton onClick={onConfirm}>{t('confirm')}</StyledButton>
          </div>
        </div>
      </ContentModal>
    </Modal>
  )
}

const Divider = styled.div`
  border-bottom: 2px solid;
  border-color: var(--grey);
  margin-bottom: 0.5rem;
`

const SelectDescriptionDiv = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const ContentModal = styled.div`
  min-width: 600px;

  @media (max-width: 725px) {
    min-width: auto;
  }
`

const StyledButton = styled(Button)`
  &&& {
    color: var(--blue);
  }
`

export default EmployeeShopPopup
