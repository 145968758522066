import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationRefreshItemArgs } from '@/schemaTypes'
import { itemFragments } from './itemFragments'

const REFRESH_ITEM = gql`
  mutation refreshItem($itemId: ObjectId!) {
    refreshItem(itemId: $itemId) {
      _id
      serialNumber
      imei
      itemSpecification
      note
      isForPersonalUse
      policeNumber
      isDamageCase
      damageCasePeriods {
        from
        to
      }
      personalUsePeriods {
        from
        to
      }
      isInvestmentGold
      createdAt
      updatedAt
      ean
      storageFacilityId
      storageFacility {
        _id
        storageUnitIds
      }
      media {
        ...FileMetadataFragment
      }
      dealsHistory {
        dealId
        algoliaReference
        title
        material
        answers {
          questionId
          selectedOptionIndex
          rangeValue
        }
        questions {
          ...ItemQuestionFragment
        }
        questionIds
        itemCategoryId
        values {
          verified {
            currentMarketValue
            predictedMarketValue
            adjustedMarketValue
            loanToValue
            maxMarketValue
          }
        }
      }
      lastBookingNumber
      bookingNumbers
      piceaUploadLink {
        ...FileMetadataFragment
      }
      entrupyCertificateUrl
      checkedBy
      checkedAt
      uploadedFiles {
        ...FileMetadataFragment
      }
      storageHistory {
        storageUnits {
          lvl1
          lvl2
          lvl3
          lvl4
        }
        createdAt
        timestamp
        storageStatus
        employeeId
        note
      }
      storageLabel
      events {
        ...ItemEventFragment
      }
      piceaTest {
        disable
        error
        data {
          label
          value
        }
        createdAt
      }
    }
  }
  ${fragments.fileMetadata}
  ${itemFragments.itemQuestionFragment}
  ${itemFragments.itemEvent}
`

type TData = { refreshItem: Mutation['refreshItem'] }
type TVariables = MutationRefreshItemArgs

export function useRefreshItem(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [refreshItem] = useMutation<TData, TVariables>(REFRESH_ITEM, opts)
  return refreshItem
}
