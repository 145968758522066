import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateProductArgs } from '@/schemaTypes'

const UPDATE_PRODUCT = gql`
  mutation updateProduct($args: ProductUpdateArgs!) {
    updateProduct(args: $args) {
      _id
    }
  }
`

type TData = { updateProduct: Mutation['updateProduct'] }
type TVariables = MutationUpdateProductArgs

export function useUpdateProduct(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [mutation] = useMutation<TData, TVariables>(UPDATE_PRODUCT, opts)
  return mutation
}
