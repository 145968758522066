import AddIcon from '@material-ui/icons/AddOutlined'
import RemoveIcon from '@material-ui/icons/Delete'
import RefetchIcon from '@material-ui/icons/Refresh'
import { Fragment, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectOptionInput from '@/components/SelectOptionInput'
import { useGetItemCategories } from '@/domains/itemCategories/hooks'
import {
  CompanyFeesEvaluation,
  FeeCreateArgs,
  FeeUpdateArgs,
  Scalars,
} from '@/schemaTypes'
import FeeEvaluatorWithItemCategory from './FeeEvaluatorWithItemCategory'
import FeeEvaluatorWithOutItemCategory from './FeeEvaluatorWithOutItemCategory'
import styles from './FeesConfiguration.module.scss'

interface CompanyFeeEvaluatorProps {
  newFeeDefinitionArgs: FeeCreateArgs | FeeUpdateArgs
  setNewFeeDefinitionArgs
  companyFeesEvaluation?: CompanyFeesEvaluation
  onEvaluateFees
  evaluationQueryResult
  isEditMode: boolean
}

const CompanyFeeEvaluator: FunctionComponent<CompanyFeeEvaluatorProps> = (
  props,
) => {
  const {
    newFeeDefinitionArgs,
    setNewFeeDefinitionArgs,
    companyFeesEvaluation,
    onEvaluateFees,
    evaluationQueryResult,
    isEditMode,
  } = props
  const { t } = useTranslation()

  const { itemCategories } = useGetItemCategories({
    variables: {
      opts: {
        filter: {
          includeHided: true,
        },
      },
    },
  })
  const possibleItemCategories = itemCategories.map((str) => ({
    label: str.algoliaCategorySlug ?? str.name,
    value: str._id,
  }))

  const addButtonClicked = () => {
    const tempRestrictedCategories =
      newFeeDefinitionArgs.restrictToCategories?.map((c) => c) ?? []

    // just assign the first parent category
    const otherCatId = itemCategories.filter((c) => !c.parentId)[0]
    tempRestrictedCategories.push(otherCatId._id)

    setNewFeeDefinitionArgs({
      ...newFeeDefinitionArgs,
      restrictToCategories: tempRestrictedCategories,
    })
  }

  const deleteButtonClicked = (itemCategoryId: Scalars['ObjectId']) => {
    const tempRestrictedCategories =
      newFeeDefinitionArgs.restrictToCategories?.filter(
        (c) => c !== itemCategoryId,
      )

    setNewFeeDefinitionArgs({
      ...newFeeDefinitionArgs,
      restrictToCategories: tempRestrictedCategories,
    })
  }

  if (!companyFeesEvaluation) return null

  return (
    <Fragment>
      <div className={`${styles.itemCategoryMainDiv}`}>
        <div className={`${styles.itemCategoryTitleDiv}`}>
          <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
            {t('item_categories.label')}
          </div>

          <div
            onClick={() => addButtonClicked()}
            className={`${styles.AddIntervalIcon}`}
          >
            <AddIcon />
          </div>

          <div onClick={onEvaluateFees} className={`${styles.AddIntervalIcon}`}>
            <RefetchIcon />
          </div>

          {evaluationQueryResult.loading && (
            <div
              onClick={onEvaluateFees}
              className={`${styles.AddIntervalIcon}`}
            >
              <LoadingSpinner />
            </div>
          )}
        </div>
        <table>
          <th>
            <td />

            <td />
          </th>

          {newFeeDefinitionArgs.restrictToCategories &&
          newFeeDefinitionArgs.restrictToCategories.length > 0 ? (
            newFeeDefinitionArgs.restrictToCategories?.map((catId) => {
              const index =
                newFeeDefinitionArgs.restrictToCategories?.indexOf(catId) ?? 0
              const itemCategoryEvaluation =
                companyFeesEvaluation.feeEvaluationItemCategories.filter(
                  (c) => c.itemCategoryId === catId,
                )[0]

              return (
                <Fragment>
                  <tr key={index}>
                    <td>
                      <SelectOptionInput
                        value={catId}
                        onChange={(categoryIds) => {
                          const tempCategoryIds =
                            newFeeDefinitionArgs.restrictToCategories
                              ? newFeeDefinitionArgs.restrictToCategories.map(
                                  (c) => c,
                                )
                              : []
                          tempCategoryIds[index] = categoryIds
                          setNewFeeDefinitionArgs({
                            ...newFeeDefinitionArgs,
                            restrictToCategories: tempCategoryIds,
                          })
                        }}
                        menuItems={possibleItemCategories}
                      />
                    </td>
                    <td>
                      <FeeEvaluatorWithItemCategory
                        itemCategoryEvaluation={itemCategoryEvaluation}
                        newFeeDefinitionArgs={newFeeDefinitionArgs}
                        isEditMode={isEditMode}
                      />
                    </td>
                    <td>
                      <div
                        onClick={() => deleteButtonClicked(catId)}
                        className={`${styles.RemoveIntervalIcon}`}
                      >
                        <RemoveIcon />
                      </div>
                    </td>
                  </tr>
                </Fragment>
              )
            })
          ) : (
            <Fragment>
              <tr>
                <FeeEvaluatorWithOutItemCategory
                  generalFeeDefinitionsEvaluation={
                    companyFeesEvaluation.generalFeesEvaluation
                  }
                  newFeeDefinitionArgs={newFeeDefinitionArgs}
                  isEditMode={isEditMode}
                />
              </tr>
            </Fragment>
          )}
        </table>
      </div>
    </Fragment>
  )
}

export default CompanyFeeEvaluator
