import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryGetActiveCompaniesArgs } from '@/schemaTypes'

const GET_ACTIVE_COMPANIES_NAMES = gql`
  query getActiveCompanies($opts: CompanyOpts) {
    getActiveCompanies(opts: $opts) {
      _id
      name
    }
  }
`

type TData = { getActiveCompanies: Query['getActiveCompanies'] }
type TVariables = QueryGetActiveCompaniesArgs

export function useGetActiveCompaniesNames(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_ACTIVE_COMPANIES_NAMES, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, companies: queryResult.data?.getActiveCompanies || [] }
}
