import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateDealItemAnswersArgs } from '@/schemaTypes'
import { dealCalculationFragments, dealFragments } from './dealFragments'

const UPDATE_DEAL_ITEM_ANSWERS = gql`
  mutation updateDealItemAnswers(
    $dealId: ObjectId!
    $args: UpdateDealItemAnswersArgs!
  ) {
    updateDealItemAnswers(dealId: $dealId, args: $args) {
      _id
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
      pawnData {
        ...PawnDataFragment
      }
      purchaseData {
        ...PurchaseDataFragment
      }
    }
  }
  ${dealFragments.pawnData}
  ${dealFragments.dealItem}
  ${dealFragments.purchaseData}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = { updateDealItemAnswers: Mutation['updateDealItemAnswers'] }
type TVariables = MutationUpdateDealItemAnswersArgs

export function useUpdateDealItemAnswers(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  return useMutation<TData, TVariables>(UPDATE_DEAL_ITEM_ANSWERS, opts)
}
