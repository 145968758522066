// import { ApolloError, ExecutionResult } from 'apollo-boost'
import { ApolloError, MutationHookOptions } from '@apollo/client'
import { useContext } from 'react'
import { Context } from '@/context'
import { useParseErrors } from './useParseErrors'

// TODO
export interface UseMutationShowingErrorsOpts<TData, TVariables> {
  mutation: (options?: MutationHookOptions<TData, TVariables>) => Promise<any> // (options?: MutationFunctionOptions<TData, TVariables>) => Promise<ExecutionResult<TData>>
  // mutationHook: (options?: MutationHookOptions<TData, TVariables> | MutationFunctionOptions<TData, TVariables>) => Promise<any> // (options?: MutationFunctionOptions<TData, TVariables>) => Promise<ExecutionResult<TData>>
  successMessage?: string | ((data: TData) => string)
  closeDialogWhenMutationStarts?: boolean
  hideSuccessMessage?: boolean
}

export interface UseMutationShowingErrorsParams<TData, TVariables>
  extends MutationHookOptions<TData, TVariables> {
  onCompleted?: (data: TData) => void
  onError?: (error: ApolloError) => void
}

export function useMutationShowingErrors<TData, TVariables>(
  opts: UseMutationShowingErrorsOpts<TData, TVariables>,
) {
  const {
    mutation,
    successMessage,
    closeDialogWhenMutationStarts,
    hideSuccessMessage,
  } = opts
  const { closeConfirmModal, showErrors, showInfo } = useContext(Context)
  const parseErrors = useParseErrors()

  return async (params: UseMutationShowingErrorsParams<TData, TVariables>) => {
    const { onCompleted: onSuccess, onError } = params

    const options = { ...params }
    delete options.onCompleted
    delete options.onError

    const result: {
      error: ApolloError | null
      data?: TData
    } = {
      error: null,
    }

    if (closeDialogWhenMutationStarts) {
      closeConfirmModal()
    }

    const mutationResult = await mutation(params).catch((error) => {
      result.error = error

      if (!closeDialogWhenMutationStarts) {
        closeConfirmModal()
      }

      const errors = parseErrors(error)

      console.log('errors', errors)

      showErrors(errors)

      if (typeof onError === 'function') {
        onError(error)
      }
    })

    if (!mutationResult || !mutationResult.data) return result

    result.data = mutationResult.data

    if (!closeDialogWhenMutationStarts) {
      closeConfirmModal()
    }

    if (!hideSuccessMessage && successMessage) {
      // result.data! is okay because above we check it to exist
      showInfo(
        typeof successMessage === 'function'
          ? successMessage(result.data!)
          : successMessage,
      )
    }

    if (typeof onSuccess === 'function') {
      onSuccess(mutationResult.data)
    }

    return result
  }
}
