import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Fragment } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '@/components/LoadingSpinner'
import {
  CustomDealItemDataEntry,
  DealItem,
  ECountry,
  EFeeType,
  UsedFeeDefinition,
} from '@/schemaTypes'
import {
  checkIfAllTheFeesOfTypeIsDealLevel,
  getDistinctFeeTypes,
  getItemFeesOfType,
  getItemNameByStorageLabel,
  getTotalGrossFeeAmountOfType,
} from '@/utils/deal'
import { displayLocalAmount } from '@/utils/misc'
import { printLocalDate } from '@/utils/print'
import {
  addDays,
  defaultTimezone,
  getStartOfDayForTimezone,
} from '@/utils/time'

interface UsedFeeDefinitionModularViewProps {
  appliedUsedFeeDefinitions: UsedFeeDefinition[]
  durationInDays: number
  items?: DealItem[] | CustomDealItemDataEntry[]
  isLoading?: boolean
  itemRecordsView?: boolean
  showChargTableText: boolean
  feeScheduleLink?: string
  companyCountryCode: ECountry
  dealStartDate?: Date
  showCalculationDate?: boolean
}

const UsedFeeDefinitionModularView: FunctionComponent<
  UsedFeeDefinitionModularViewProps
> = (props) => {
  const {
    appliedUsedFeeDefinitions,
    isLoading,
    itemRecordsView,
    items,
    showChargTableText,
    durationInDays,
    feeScheduleLink,
    companyCountryCode,
    dealStartDate,
    showCalculationDate,
  } = props
  const { t } = useTranslation()
  const distinctTypes = getDistinctFeeTypes(appliedUsedFeeDefinitions)
  const hasDescriptionFeeData = appliedUsedFeeDefinitions.some((fee) =>
    fee.calculatedFeeDefinitions.some((feeDef) => feeDef.description),
  )

  return (
    <Fragment>
      <Paper className="u-p-sm u-12/12 u-mb-sm">
        {dealStartDate && showCalculationDate ? (
          <Typography
            component="p"
            style={{
              marginBottom: '1rem',
              textAlign: 'left',
              paddingLeft: '1rem',
            }}
          >
            {t('fees.fee_on_date')}{' '}
            {printLocalDate(
              addDays(
                getStartOfDayForTimezone(dealStartDate, defaultTimezone),
                durationInDays,
              ),
              {
                timezone: defaultTimezone,
              },
            )}
          </Typography>
        ) : (
          ''
        )}
        <Table
          size="small"
          aria-label="a dense table"
          style={{ minWidth: '500px' }}
        >
          <TableHead>
            <TableRow>
              <TableCell>{t('fee_type.label')}</TableCell>
              {hasDescriptionFeeData && (
                <TableCell>{t('description')}</TableCell>
              )}
              <TableCell>{t('fees.label')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {distinctTypes.map((feeType) => {
              const onlyDealLevelFeeTypeAvailable =
                checkIfAllTheFeesOfTypeIsDealLevel(
                  appliedUsedFeeDefinitions,
                  feeType,
                )
              return (
                <TableRow>
                  <TableCell>
                    {feeType === EFeeType.InitialStaggered
                      ? t(
                          `fee_type.fee_type_${feeType}_${companyCountryCode}`.toLowerCase(),
                        )
                      : t(`fee_type.fee_type_${feeType}`.toLowerCase())}

                    {itemRecordsView &&
                    !onlyDealLevelFeeTypeAvailable &&
                    items ? (
                      <>
                        {appliedUsedFeeDefinitions.map((itemFees, index) => {
                          const itemFeesOfType = getItemFeesOfType(
                            appliedUsedFeeDefinitions,
                            feeType,
                            itemFees.storageLabel,
                            index,
                          )
                          const totalFeesAmount = itemFeesOfType.reduce(
                            (n, { finalNetAmount }) => n + finalNetAmount,
                            0,
                          )

                          return [{ total: totalFeesAmount }].map(
                            (itemFee, i) => {
                              const itemName = getItemNameByStorageLabel(
                                items,
                                itemFees.storageLabel,
                                index,
                              )
                              return (
                                <SummarizedItemDetails key={i}>
                                  {itemFees.storageLabel
                                    ? `${itemFees.storageLabel} - ${itemName}`
                                    : itemName}
                                </SummarizedItemDetails>
                              )
                            },
                          )
                        })}
                      </>
                    ) : null}
                  </TableCell>

                  {hasDescriptionFeeData && (
                    <TableCell>
                      {onlyDealLevelFeeTypeAvailable ? (
                        // We don't care because all the items have the fee we need to show its description and all of them are same each other.
                        appliedUsedFeeDefinitions[0].calculatedFeeDefinitions.filter(
                          (c) => c.storedFeeDefinition.feeType === feeType,
                        )[0].description ?? '-'
                      ) : itemRecordsView ? (
                        <>
                          {t('-')}
                          {appliedUsedFeeDefinitions.map((itemFees, index) => {
                            const itemFeesOfType = getItemFeesOfType(
                              appliedUsedFeeDefinitions,
                              feeType,
                              itemFees.storageLabel,
                              index,
                            )
                            return itemFeesOfType.map((itemFee) => {
                              return (
                                <SummarizedItemDetails>
                                  {itemFee.description ?? '-'}
                                </SummarizedItemDetails>
                              )
                            })
                          })}
                        </>
                      ) : null}
                    </TableCell>
                  )}

                  <TableCell>
                    {displayLocalAmount(
                      getTotalGrossFeeAmountOfType(
                        appliedUsedFeeDefinitions,
                        feeType,
                      ),
                    )}

                    {itemRecordsView && !onlyDealLevelFeeTypeAvailable ? (
                      <>
                        {appliedUsedFeeDefinitions.map((itemFees, index) => {
                          const itemFeesOfType = getItemFeesOfType(
                            appliedUsedFeeDefinitions,
                            feeType,
                            itemFees.storageLabel,
                            index,
                          )
                          const totalFeesAmount = itemFeesOfType.reduce(
                            (n, { appliedNetAmount }) => n + appliedNetAmount,
                            0,
                          )
                          return [{ total: totalFeesAmount }].map((itemFee) => {
                            return (
                              <SummarizedItemDetails>
                                {displayLocalAmount(itemFee.total)}
                              </SummarizedItemDetails>
                            )
                          })
                        })}
                      </>
                    ) : null}
                  </TableCell>
                </TableRow>
              )
            })}

            <TableRow>
              <TableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                {t('total')}
              </TableCell>
              {hasDescriptionFeeData && <TableCell />}
              <TableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  displayLocalAmount(
                    getTotalGrossFeeAmountOfType(appliedUsedFeeDefinitions),
                  )
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              {showChargTableText && feeScheduleLink && (
                <TableCell colSpan={3}>
                  <div style={{ marginTop: '1rem', maxWidth: '400px' }}>
                    <span className="o-type-text u-mt-30">
                      {t('fee_type.fees_schedule_info')}
                    </span>
                    <a
                      className="o-color--cashy_violet"
                      href={`${feeScheduleLink}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('table_of_charges')}
                    </a>
                  </div>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  )
}

const SummarizedItemDetails = styled.div`
  margin-top: 0.5rem;
  font-weight: normal;
  font-size: smaller;
  font-style: italic;
  color: rgb(162, 161, 161);
`

export default UsedFeeDefinitionModularView
