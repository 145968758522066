import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationUpdateCashBookCountArgs } from '@/schemaTypes'

const UPDATE_CASH_BOOK_COUNT = gql`
  mutation updateCashBookCount(
    $cashBookCountUpdateArgs: CashBookCountUpdateArgs!
  ) {
    updateCashBookCount(cashBookCountUpdateArgs: $cashBookCountUpdateArgs) {
      ...CashBookCountFragment
    }
  }
  ${fragments.cashBookCount}
`

type TData = { updateCashBookCount: Mutation['updateCashBookCount'] }
type TVariables = MutationUpdateCashBookCountArgs

export function useUpdateCashBookCount(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateCashBookCount] = useMutation<TData, TVariables>(
    UPDATE_CASH_BOOK_COUNT,
    opts,
  )

  return updateCashBookCount
}
