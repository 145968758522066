/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloError } from '@apollo/client'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import dayjs from 'dayjs'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import client from '@/apollo-worker'
import BreadcrumbsBar from '@/components/BreadcrumbsBar'
import { Context } from '@/context'
import CashBookCashFlowFilter from '@/domains/cashbooks/CashBookCashFlowFilter'
import CashBookCashFlowsForm from '@/domains/cashbooks/components/CashBookCashFlowsForm'
import CashBookCashFlowsTable from '@/domains/cashbooks/components/CashBookCashFlowsTable'
import {
  GET_CASH_BOOK_CASH_FLOWS_EXPORT,
  useCancelCashBookCashFlow,
  useCreateCashBookCashFlow,
  useGetCashBookCashFlows,
  useUpdateStorageLabelForCashBookCashFlow,
} from '@/domains/cashbooks/hooks'
import styles from '@/domains/cashbooks/styles/CashBook.module.scss'
import { useMutationShowingErrors, useParseErrors } from '@/hooks'
import {
  CashBookCashFlowArgs,
  CashBookCashFlowOpts,
  ECashBookType,
  FileMetadata,
  QueryGetCashBookCashFlowsExportArgs,
} from '@/schemaTypes'
import { isShopLevelCashbookType } from '@/utils/misc'

function CashBookCashFlows() {
  const { showConfirmModal, closeConfirmModal, showErrors } =
    useContext(Context)
  const { t } = useTranslation()
  const now = dayjs()

  const [cashBookCashFlowFilter, setCashBookCashFlowFilter] =
    useState<CashBookCashFlowOpts>({
      filter: {
        date: {
          betweenValues: {
            from: now.startOf('day').toDate(),
            to: now.endOf('day').toDate(),
          },
        },
      },
    })

  const cashBookCashFlowOpts = useMemo(() => {
    return (!cashBookCashFlowFilter?.filter?.companyId &&
      !cashBookCashFlowFilter?.filter?.shopId) ||
      !cashBookCashFlowFilter?.filter?.cashBookType ||
      !cashBookCashFlowFilter?.filter?.date?.betweenValues?.from ||
      !cashBookCashFlowFilter?.filter?.date?.betweenValues.to
      ? null
      : { ...cashBookCashFlowFilter }
  }, [cashBookCashFlowFilter])

  const [displayCreateEntry, setDisplayCreateEntry] = useState<boolean>(false)
  const [actionInProgress, setActionInProgress] = useState<boolean>(false)

  const parseErrors = useParseErrors()

  const cashBookCashFlowsResult = useGetCashBookCashFlows({
    variables: {
      opts: cashBookCashFlowOpts,
    },
    fetchPolicy: 'network-only',
    skip: !cashBookCashFlowOpts,
  })

  const createCashBookCashFlow = useMutationShowingErrors({
    mutation: useCreateCashBookCashFlow(),
    successMessage: t('cash_book_count.cash_book_cash_flow_saved'),
  })

  const updateItemIdForCashBookCashFlow = useMutationShowingErrors({
    mutation: useUpdateStorageLabelForCashBookCashFlow(),
    successMessage: t('cash_book_count.cash_book_cash_flow_saved'),
  })

  const cancelCashBookCashFlow = useMutationShowingErrors({
    mutation: useCancelCashBookCashFlow(),
    successMessage: t('cash_book_count.cash_book_cash_flow_canceled'),
  })

  const onSave = useCallback(
    (
      args: CashBookCashFlowArgs,
      onSuccess: () => void,
      addAnotherEntry: boolean,
    ) => {
      setActionInProgress(true)

      if (!args.storageLabel) {
        delete args.storageLabel
      }

      if (!args.note) {
        delete args.note
      }

      createCashBookCashFlow({
        variables: {
          cashBookCashFlowArgs: args,
        },
        update: (cache, payload) => {
          if (payload.data?.createCashBookCashFlow) {
            const variables = {
              opts: cashBookCashFlowOpts,
            }

            cache.evict({
              id: 'ROOT_QUERY',
              fieldName: 'getCashBookCashFlows',
              args: variables,
            })
          }
        },
      }).then((data) => {
        if (data.data?.createCashBookCashFlow) {
          if (!addAnotherEntry) {
            setDisplayCreateEntry(false)
          }

          onSuccess()
        }
        setActionInProgress(false)
      })
    },
    [cashBookCashFlowOpts],
  )

  const onCancel = useCallback((cashBookCashFlowId: string) => {
    showConfirmModal({
      title: `${t('cash_book_count.cash_book_cash_flow_cancel_confirm')}`,
      leftButtonText: t('no'),
      rightButtonText: t('yes'),
      leftButtonAction: () => {
        closeConfirmModal()
      },
      rightButtonAction: () => {
        closeConfirmModal()
        cancelCashBookCashFlow({
          variables: {
            cashBookCashFlowId,
          },
          update: (cache, payload) => {
            if (payload.data?.cancelCashBookCashFlow) {
              cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'getCashBookCashFlows',
              })
            }
          },
        })
      },
    })
  }, [])

  const [isExporting, setIsExporting] = useState<boolean>(false)
  const onExport = useCallback(async () => {
    setIsExporting(true)
    const res = await client.query<
      { getCashBookCashFlowsExport: FileMetadata },
      QueryGetCashBookCashFlowsExportArgs
    >({
      query: GET_CASH_BOOK_CASH_FLOWS_EXPORT,
      fetchPolicy: 'network-only',
      variables: {
        opts: cashBookCashFlowOpts,
      },
    })
    // useShowErrorsPopup(response.error) // react hook issue
    // if (res.error) window.alert(res.error)
    if (res.errors) {
      const error = new ApolloError({ graphQLErrors: res.errors })
      const errors = parseErrors(error)
      showErrors(errors)
    }

    document.location.assign(res.data.getCashBookCashFlowsExport.url)
    setIsExporting(false)
  }, [cashBookCashFlowOpts])

  const shopOrCompanyId =
    cashBookCashFlowOpts?.filter?.companyId?.value ??
    cashBookCashFlowOpts?.filter?.shopId?.value
  const cashBookType = cashBookCashFlowOpts?.filter?.cashBookType

  const showCashflowCreation =
    (cashBookType !== ECashBookType.Unzer &&
      !cashBookCashFlowOpts?.filter?.shopId?.value) ||
    isShopLevelCashbookType(cashBookType)

  return (
    <div className={`${styles.root} u-12/12`}>
      <BreadcrumbsBar
        entries={[
          {
            label: t('cash_book.label'),
            url: '/inApp/cashBook',
          },
        ]}
      />
      <h1>{t('revenues_and_expenses')}</h1>
      <CashBookCashFlowFilter
        cashBookCashFlowOpts={cashBookCashFlowFilter}
        setCashBookCashFlowOpts={setCashBookCashFlowFilter}
      />

      <div className={`${styles.toolBar}`}>
        <Tooltip title="Export">
          <IconButton
            disabled={isExporting || !shopOrCompanyId}
            onClick={onExport}
            aria-label="delete"
          >
            <GetAppIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>

      {shopOrCompanyId && cashBookType && (
        <>
          <CashBookCashFlowsTable
            data={cashBookCashFlowsResult.cashBookCashFlows ?? []}
            onUpdateItemId={(value, cashBookCashFlowId) =>
              updateItemIdForCashBookCashFlow({
                variables: {
                  cashBookCashFlowId,
                  storageLabel: value,
                },
              })
            }
            onCancel={onCancel}
          />

          {showCashflowCreation && (
            <>
              {!displayCreateEntry ? (
                <Button
                  onClick={() => setDisplayCreateEntry(true)}
                  variant="contained"
                >
                  {t('new_entry')}
                </Button>
              ) : (
                <CashBookCashFlowsForm
                  onSave={onSave}
                  onCancel={() => setDisplayCreateEntry(false)}
                  shopOrCompanyId={shopOrCompanyId}
                  cashBookType={cashBookType}
                  actionInProgress={actionInProgress}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default CashBookCashFlows
