import { useGetItemQuestions } from '../hooks'
import { Button, Typography } from '@material-ui/core'
import EuroOutlinedIcon from '@material-ui/icons/EuroOutlined'
import { useTranslation } from 'react-i18next'
import { Link, RouteComponentProps } from 'react-router-dom'
import styles from '@/domains/items/components/ItemDetails.module.scss'
import { Deal, Item, ItemCategory } from '@/schemaTypes'
import ItemBlock from './Item'
import ItemDealChain from './Item/ItemDealChain'

export interface ItemDetailsProps {
  item: Item
  itemQueryResult
  deal: Deal
  dealQueryResult
  itemCategory: ItemCategory
}

export function ItemDetails(props: ItemDetailsProps) {
  const { t } = useTranslation()

  const { itemCategory, item, itemQueryResult, deal, dealQueryResult } = props

  const dealsHistoryEntry = item?.dealsHistory[item.dealsHistory.length - 1]

  const { itemQuestions } = useGetItemQuestions({
    variables: {
      opts: {
        filter: {
          ids: dealsHistoryEntry?.questionIds,
          omitDeactivatedQuestionsForNewProducts: Boolean(
            dealsHistoryEntry?.variantId,
          ),
        },
      },
    },
  })

  if (!item) {
    return null
  }
  return (
    <div className={`u-p-sm u-12/12 u-mb-sm ${styles.pathTree}`}>
      {deal && (
        <>
          <Typography variant="h5" gutterBottom className={'u-flex u-jc-sb'}>
            <div className={'u-inline-block'} style={{ marginRight: 'auto' }}>
              {`${t('item.plural')}: `}
              <Link to={`/inApp/items`} className={`${styles.pathTreeLink}`}>
                {t('all')}
              </Link>
              {` / `}
              <ItemDealChain item={item} />
            </div>

            <Link
              to={`/inApp/deals/${deal?._id}`}
              className={`${styles.pathTreeLinkButton}`}
            >
              <Button
                className="u-mr-mini"
                variant="contained"
                classes={{ root: styles.button }}
              >
                <EuroOutlinedIcon classes={{ root: styles.dealIcon }} />{' '}
                {t('to_deal')}
              </Button>
            </Link>
          </Typography>
          <ItemBlock
            deal={deal}
            item={item}
            itemCategory={itemCategory}
            itemQuestions={itemQuestions}
            refetchDeal={dealQueryResult.refetch}
            refetchItem={itemQueryResult.refetch}
          />
        </>
      )}
    </div>
  )
}

export interface ItemDetailsContainerProps extends RouteComponentProps {}

export default ItemDetails
