import {
  useGetParentCategories,
  useParsePiceaTest,
  useUpdateItem,
  useUpdateItemMedia,
  useUpdateItemUploadedFiles,
  useUpdatePiceaUploadLink,
} from '../../../hooks'
import styled from '@emotion/styled'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TableCell,
  TableRow,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import dayjs from 'dayjs'
import { find, last } from 'lodash'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import Loading from '@/components/Loading'
import { UppyFileChangeResults, UppyUploader } from '@/components/UppyUploader'
import { ALLOWED_FILE_TYPES } from '@/constants'
import { Context } from '@/context'
import { ImageGallery } from '@/domains/customDeals/components/CustomDealDetail'
import { IMedia } from '@/domains/customDeals/components/CustomDealDetail/ImageGallery'
import {
  checkItemIsNewCondition,
  shouldRequirePiceaTest,
} from '@/domains/deals/components/VerificationChecklist'
import { useUpdateItemCategorizedUploadedFiles } from '@/domains/items/hooks/itemDetails/updateItemCategorizedUploadedFiles'
import { useUpdateItemManualTest } from '@/domains/items/hooks/itemDetails/useUpdateItemManualTest'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import {
  EItemManualTestAssessment,
  FileTusMetadataArgs,
  Item,
  ItemCategory,
  PiceaTest,
  PiceaTestData,
} from '@/schemaTypes'
import { DownloadFolderZip, downloadFilesInZip, isVehicle } from '@/utils/misc'
import CarItemUploadsOverview from './CarItemUploadsOverview'

export interface ItemUploadsOverviewProps {
  item: Item
  itemCategory: ItemCategory
  bookingNumber: number
}

const checkMatchModel = (itemTitle: string, model: string) =>
  itemTitle.toLowerCase().includes(model.toLowerCase())

const checkMatchStorage = (itemTitle: string, storage: string) =>
  itemTitle
    .toLowerCase()
    .replace(/\s/g, '')
    .includes(storage.toLowerCase().replace(/\s/g, ''))

enum PICEA_LABEL {
  Model = 'Modell',
  Storage = 'Speicher',
  IMEI = 'IMEI',
  SerialNumber = 'Seriennummer',
}

const comparePiceaTestWithItem = (
  item: Item,
  piceaTestData: PiceaTestData[],
) => {
  const itemTitle = last(item.dealsHistory)?.title ?? ''

  const model = find(piceaTestData, { label: PICEA_LABEL.Model })?.value
  const storage = find(piceaTestData, { label: PICEA_LABEL.Storage })?.value
  const imei = find(piceaTestData, { label: PICEA_LABEL.IMEI })?.value
  const serialNumber = find(piceaTestData, {
    label: PICEA_LABEL.SerialNumber,
  })?.value

  const isMatchModel = model && checkMatchModel(itemTitle, model)
  const isMatchStorage = storage && checkMatchStorage(itemTitle, storage)
  const isMatchImei = imei === item.imei
  const isMatchSerialNumber = serialNumber === item.serialNumber

  const errors: string[] = []

  if (!isMatchModel) errors.push(PICEA_LABEL.Model)
  if (!isMatchStorage) errors.push(PICEA_LABEL.Storage)
  if (!isMatchImei) errors.push(PICEA_LABEL.IMEI)
  if (!isMatchSerialNumber) errors.push(PICEA_LABEL.SerialNumber)

  return errors
}

export function ItemUploadsOverview(props: ItemUploadsOverviewProps) {
  const { item, itemCategory, bookingNumber } = props
  const { closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()
  const history = useHistory()

  const isItemVehicle = isVehicle(
    item.dealsHistory[item.dealsHistory.length - 1].itemCategoryId,
  )

  const showPiceaConfirmPopup = useShowConfirmPopup({
    actionText: t('change_item'),
    abortActionText: t('save'),
  })

  const showManualTestConfirmPopup = useShowConfirmPopup({
    actionText: t('test_performed'),
  })

  const updateItemUploadedFiles = useMutationShowingErrors({
    mutation: useUpdateItemUploadedFiles(),
  })

  const updateItemCategorizedUploadedFiles = useMutationShowingErrors({
    mutation: useUpdateItemCategorizedUploadedFiles(),
  })

  const updateItemMedia = useMutationShowingErrors({
    mutation: useUpdateItemMedia(),
  })

  const updatePiceaUploadLink = useMutationShowingErrors({
    mutation: useUpdatePiceaUploadLink(),
  })

  const parsePiceaTest = useMutationShowingErrors({
    mutation: useParsePiceaTest(),
  })

  const updateItemManualTest = useMutationShowingErrors({
    mutation: useUpdateItemManualTest(),
  })

  const { parentCategories } = useGetParentCategories({
    variables: {
      itemCategoryId: itemCategory?._id,
    },
    skip: !itemCategory,
  })

  const updateItem = useMutationShowingErrors({
    mutation: useUpdateItem(),
    successMessage: `${t('item_updated_successfully')}!`,
  })

  const [isPiceaUploadLinkLoading, setIsPiceaUploadLinkLoading] =
    useState<boolean>(false)
  const [isOpenImageGallery, setOpenImageGallery] = useState(false)
  const [mediaGallery, setMediaGallery] = useState<IMedia[]>([])
  const [viewedPositionIndex, setViewedPositionIndex] = useState(0)
  const [disableCheckPiceaTest, setDisableCheckPiceaTest] = useState(
    item.piceaTest?.disable ?? false,
  )
  const [isShowPiceaData, setShowPiceaData] = useState(false)
  const [isParsingPiceaTest, setParsingPiceaTest] = useState(false)

  const isRequiredPiceaTest = useMemo(() => {
    const payoutAmount = last(item.dealsHistory)?.values?.verified?.payoutAmount

    if (!payoutAmount) return false

    return shouldRequirePiceaTest(
      parentCategories?.map((category) => category.name) ?? [],
      payoutAmount,
    )
  }, [parentCategories, item.dealsHistory])

  const isNewCondition = useMemo(() => {
    const { questions, answers } = last(item.dealsHistory)
    return checkItemIsNewCondition({
      questions,
      answers,
      formAnswers: item.formAnswers,
    })
  }, [item])

  const onCloseImageGallery = () => {
    setOpenImageGallery(false)
    setViewedPositionIndex(0)
    setMediaGallery([])
  }

  const onOpenImageGallery = (
    media: Array<{ url: string; type: string }>,
    index: number,
  ) => {
    setViewedPositionIndex(index)
    setMediaGallery(media)
    setOpenImageGallery(true)
  }

  const handleShowPiceaTestPopup = () => {
    showManualTestConfirmPopup({
      state: Object.values(EItemManualTestAssessment).reduce(
        (acc, assessment) => {
          acc[assessment] = false
          return acc
        },
        {},
      ),
      title: t('picea_test_skip_title'),
      preventAutoCloseByKey: true,
      component: (state, setState) => {
        return (
          <div>
            <Typography component="div" className="u-pt-sm" gutterBottom>
              {t('picea_test_skip_desc')}
            </Typography>
            <FormGroup
              className="u-mb-sm"
              style={{ textAlign: 'center', paddingLeft: '200px' }}
            >
              {Object.values(EItemManualTestAssessment).map((assessment) => (
                <FormControlLabel
                  value={assessment}
                  checked={state[assessment]}
                  key={assessment}
                  control={<Checkbox />}
                  label={t(assessment.toLowerCase() + '_tested')}
                  onChange={() => {
                    setState({ ...state, [assessment]: !state[assessment] })
                  }}
                />
              ))}
            </FormGroup>
          </div>
        )
      },
      action: async (state) => {
        updateItemManualTest({
          variables: {
            itemId: item._id,
            args: {
              assessments: Object.entries(state).map(([key, value]) => ({
                assessment: key as EItemManualTestAssessment,
                passed: !!value,
              })),
            },
          },
        })

        setDisableCheckPiceaTest(true)

        updateItem({
          variables: { args: { _id: item._id, disableCheckPiceaTest: true } },
        })

        closeConfirmModal()
      },
    })
  }

  const onChangeCheckPiceaTest = () => {
    const newValue = !disableCheckPiceaTest

    if (newValue && isRequiredPiceaTest) {
      handleShowPiceaTestPopup()
      return
    }

    setDisableCheckPiceaTest(!disableCheckPiceaTest)
    updateItem({
      variables: { args: { _id: item._id, disableCheckPiceaTest: newValue } },
    })
  }

  const handleParsePiceaTest = async (): Promise<PiceaTest | null> => {
    try {
      setParsingPiceaTest(true)
      const { data } = await parsePiceaTest({
        variables: { itemId: item._id },
      })

      if (data && !data.parsePiceaTest.piceaTest?.error) {
        toast.success(t('read_picea_test_succeed'))
      } else {
        toast.error(t('read_picea_test_failed'))
      }

      return data?.parsePiceaTest.piceaTest || null
    } catch {
      toast.error(t('READ_PICEA_TEST_FAILED'))
      return null
    } finally {
      setParsingPiceaTest(false)
    }
  }

  const showComparePiceaTestResultPopup = (piceaTestData: PiceaTestData[]) => {
    const errors = comparePiceaTestWithItem(item, piceaTestData)

    if (!errors.length) return

    showPiceaConfirmPopup({
      title: t('read_picea_test_succeed'),
      component: () => {
        return (
          <PiceaDataTable>
            <tbody>
              {piceaTestData.map((row) => (
                <StyledTableRow
                  key={row.label}
                  error={errors.includes(row.label)}
                >
                  <TableCell scope="row">
                    <b>{row.label}</b>
                  </TableCell>
                  <TableCell scope="row">{row.value}</TableCell>
                </StyledTableRow>
              ))}
            </tbody>
          </PiceaDataTable>
        )
      },
      action: async () => {
        history.push(`/inApp/items/${item._id}/addItem`)
        closeConfirmModal()
      },
      leftButtonAction: async () => {
        const imei =
          find(piceaTestData, { label: PICEA_LABEL.IMEI })?.value ?? null
        const serialNumber =
          find(piceaTestData, { label: PICEA_LABEL.SerialNumber })?.value ??
          null

        const partial: Record<string, string> = {}
        if (imei) partial.imei = imei
        if (serialNumber) partial.serialNumber = serialNumber
        if (Object.keys(partial).length)
          updateItem({ variables: { args: { _id: item._id, ...partial } } })

        closeConfirmModal()
      },
    })
  }

  const onUploadPiceaTest = async ([results]: UppyFileChangeResults[]) => {
    if (isPiceaUploadLinkLoading) return

    try {
      setIsPiceaUploadLinkLoading(true)
      await updatePiceaUploadLink({
        variables: {
          itemId: item._id,
          piceaUploadLinkArgs: 'newFile' in results ? results.newFile : null,
        },
      })

      if (!item.piceaTest?.disable && 'newFile' in results && results.newFile) {
        const piceaTest = await handleParsePiceaTest()

        if (piceaTest && !piceaTest.error) {
          await showComparePiceaTestResultPopup(piceaTest.data ?? [])
        }
      }
    } catch {
      toast.error(t('READ_PICEA_TEST_FAILED'))
    } finally {
      setIsPiceaUploadLinkLoading(false)
    }
  }

  const onUploadProductImages = async (results: UppyFileChangeResults[]) => {
    let media: FileTusMetadataArgs[] = item.media ?? []

    results.forEach((result) => {
      if ('newFile' in result) {
        media.push(result.newFile)
      } else {
        media = media.filter((f, index) => index !== result.deletedFileIndex)
      }
    })

    await updateItemMedia({
      variables: {
        itemId: item._id,
        mediaArgs: media,
      },
    })
  }

  const onUploadFile = async (results: UppyFileChangeResults[]) => {
    let uploadedFilesArgs: FileTusMetadataArgs[] = item.uploadedFiles ?? []

    results.forEach((result) => {
      if ('newFile' in result) {
        uploadedFilesArgs.push(result.newFile)
      } else {
        uploadedFilesArgs = uploadedFilesArgs.filter(
          (f, index) => index !== result.deletedFileIndex,
        )
      }
    })

    await updateItemUploadedFiles({
      variables: {
        itemId: item._id,
        uploadedFilesArgs,
      },
    })
  }

  const onUploadCategorizedFile = async (
    results: UppyFileChangeResults,
    fileCategoryName: string,
  ) => {
    let uploadedFilesArgs: FileTusMetadataArgs[] =
      item.categorizedUploadedFiles?.find(
        (c) => c.fileCategoryName === fileCategoryName,
      )?.uploadedFiles ?? []

    if ('newFile' in results) {
      uploadedFilesArgs.push(results.newFile)
    } else {
      uploadedFilesArgs = uploadedFilesArgs.filter(
        (f, index) => index !== results.deletedFileIndex,
      )
    }

    await updateItemCategorizedUploadedFiles({
      variables: {
        fileCategoryName,
        itemId: item._id,
        uploadedFilesArgs,
      },
    })
  }

  const onShowPiceaData = () => setShowPiceaData((state) => !state)

  const onDownloadFilesInZip = () => {
    const downloadFolders: DownloadFolderZip[] = []

    if (item.media) {
      downloadFolders.push({
        downloadEntries: item.media?.map((c) => {
          return {
            fileExtension: c.contentType.includes('/')
              ? c.contentType?.split('/')[1]
              : c.contentType,
            filename: c.filename,
            url: c.url,
          }
        }),
        folderName: 'Product Pictures',
      })
    }

    if (item.uploadedFiles) {
      downloadFolders.push({
        downloadEntries: item.uploadedFiles?.map((c) => {
          return {
            fileExtension: c.contentType.includes('/')
              ? c.contentType?.split('/')[1]
              : c.contentType,
            filename: c.filename,
            url: c.url,
          }
        }),
        folderName: 'Files',
      })
    }

    if (downloadFolders.length > 0)
      downloadFilesInZip(
        downloadFolders,
        `${bookingNumber} - ${item.storageLabel}`,
      )
  }

  return (
    <div>
      {isRequiredPiceaTest && !isNewCondition && (
        <>
          <Box display="flex" alignItems="flex-end">
            <Typography
              component="div"
              variant="body1"
              gutterBottom
              className={`u-flex u-ai-center`}
              style={{ position: 'relative', marginRight: '1rem' }}
            >
              {!disableCheckPiceaTest ? (
                <UppyUploader
                  label={t('picea_test')}
                  allowedFileTypes={['.pdf']}
                  initialFiles={
                    item.piceaUploadLink ? [item.piceaUploadLink] : []
                  }
                  onFilesChanged={onUploadPiceaTest}
                  shouldNotConfirmBeforeRemove
                  checkIcon={
                    disableCheckPiceaTest || !item.piceaTest
                      ? undefined
                      : item.piceaTest.error
                        ? 'failure'
                        : 'success'
                  }
                />
              ) : (
                <b>{t('picea_test')}</b>
              )}
            </Typography>
            {!disableCheckPiceaTest && !item.piceaUploadLink && (
              <Button
                onClick={onChangeCheckPiceaTest}
                style={{ textDecoration: 'underline' }}
              >
                {t('no_check_picea_test')}
              </Button>
            )}
          </Box>

          {item.piceaUploadLink && !disableCheckPiceaTest && (
            <>
              <Button
                style={{ margin: '1rem 0' }}
                variant="contained"
                onClick={onShowPiceaData}
              >
                {t(isShowPiceaData ? 'HIDE_PICEA_DATA' : 'SHOW_PICEA_DATA')}
              </Button>
              {isParsingPiceaTest && <Loading />}
              {isShowPiceaData && (
                <PiceaDataTable>
                  <tbody>
                    {item.piceaTest?.data?.map((row) => (
                      <TableRow key={row.label}>
                        <TableCell scope="row">
                          <b>{row.label}</b>
                        </TableCell>
                        <TableCell scope="row">{row.value}</TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </PiceaDataTable>
              )}
            </>
          )}
        </>
      )}

      {item.manualTest && disableCheckPiceaTest && (
        <ManualTestWrapper>
          <Typography
            component="div"
            variant="body1"
            gutterBottom
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {t('manual_test_description')}
          </Typography>

          {item.manualTest.assessments.map((test) => (
            <ManualTestResult>
              {test.passed ? (
                <CheckIcon className="success" />
              ) : (
                <CloseIcon className="failure" />
              )}
              <Typography
                component="div"
                variant="body1"
                gutterBottom
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {t(test.assessment.toLowerCase() + '_tested')}
              </Typography>
            </ManualTestResult>
          ))}

          <Typography variant="caption">
            {t('manual_test_by')} {item.manualTest.createdBy.firstname}{' '}
            {item.manualTest.createdBy.lastname} <br />
            {dayjs(item.manualTest.createdAt).format('L LT')}
          </Typography>
        </ManualTestWrapper>
      )}

      {isItemVehicle && (
        <div className="flex flex-col mb-4">
          <StyleUploadTitle>
            {' '}
            {t('car_item_upload_files.car_files')}
          </StyleUploadTitle>
          <CarItemUploadsOverview
            item={item}
            onUploadCategorizedFile={onUploadCategorizedFile}
          />
        </div>
      )}

      <div className="flex flex-col mb-4">
        <StyleUploadTitle> {t('other_files')}</StyleUploadTitle>
        <UppyUploader
          multiple
          shouldNotConfirmBeforeRemove
          allowedFileTypes={[...ALLOWED_FILE_TYPES, '.pdf']}
          initialFiles={item.uploadedFiles ?? []}
          onFilesChanged={onUploadFile}
        />
      </div>

      <div className="flex flex-col mb-4">
        <StyleUploadTitle> {t('product_pictures')}</StyleUploadTitle>
        <UppyUploader
          onOpenGalery={onOpenImageGallery}
          shouldNotConfirmBeforeRemove
          multiple
          initialFiles={item.media ?? []}
          onFilesChanged={onUploadProductImages}
        />
      </div>

      <Typography
        component="div"
        variant="body1"
        gutterBottom
        className={`u-flex u-ai-center`}
      >
        {isItemVehicle && (
          <Button
            onClick={onDownloadFilesInZip}
            variant="contained"
            style={{ marginTop: '1rem' }}
          >
            {t('download_all_files')}
          </Button>
        )}
      </Typography>
      {isOpenImageGallery && (
        <ImageGallery
          media={mediaGallery}
          viewedPositionIndex={viewedPositionIndex}
          onClose={onCloseImageGallery}
        />
      )}
    </div>
  )
}

const StyleUploadTitle = styled.span`
  font-weight: 600;
  font-size: 17.5px;
  margin-bottom: 1rem;
`

const PiceaDataTable = styled.table`
  margin: 1rem 0;
`

const StyledTableRow = styled(TableRow)<{ error?: boolean }>`
  background: ${({ error }) => (error ? '#ffdddd' : 'transparent')};
`

const ManualTestWrapper = styled.div`
  margin: 1rem 0;
`

const ManualTestResult = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 1rem;

    &.success {
      color: green;
    }

    &.failure {
      color: red;
    }
  }
`

export default ItemUploadsOverview
