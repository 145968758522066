import * as React from 'react'
import { FunctionComponent } from 'react'
import {
  EBillingPeriodType,
  EDealType,
  EFeeMode,
  FeeCreateArgs,
  FeeDefinition,
  FeeUpdateArgs,
} from '@/schemaTypes'

interface FeeEvaluationDemonstratorProps {
  inputFeeDefinitions: FeeDefinition[]
  newFeeDefinitionArgs?: FeeCreateArgs | FeeUpdateArgs | undefined
  billingPeriod: EBillingPeriodType
  dealType: EDealType
  isEditMode: boolean
}

const FeeEvaluationDemonstrator: FunctionComponent<
  FeeEvaluationDemonstratorProps
> = (props) => {
  const {
    billingPeriod,
    dealType,
    inputFeeDefinitions,
    isEditMode,
    newFeeDefinitionArgs,
  } = props
  return (
    <div>
      {inputFeeDefinitions
        .filter(
          (c) =>
            (isEditMode &&
              c._id !== (newFeeDefinitionArgs as FeeUpdateArgs)._id) ||
            !isEditMode,
        )
        .map((c) => {
          return (
            <div>
              <span
                style={{
                  padding: '0 0.5rem',
                  background: 'red',
                  color: 'white',
                }}
              >
                {(c.mode === EFeeMode.Constant ? c.amount : `${c.amount} %`) +
                  ` - ${c.feeType}`}
              </span>

              {c.restrictToItemQuestion && c.itemQuestionValidAnswer ? (
                <span
                  style={{
                    padding: '0 0.5rem',
                    marginLeft: '0.3rem',
                    background: 'red',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  ?
                </span>
              ) : null}
            </div>
          )
        })}

      {newFeeDefinitionArgs &&
      newFeeDefinitionArgs.billingPeriodType === billingPeriod &&
      newFeeDefinitionArgs.dealTypes?.includes(dealType) ? (
        <div>
          <span
            style={{
              padding: '0 0.5rem',
              background: 'green',
              color: 'white',
            }}
          >
            {(newFeeDefinitionArgs.mode === EFeeMode.Constant
              ? newFeeDefinitionArgs.amount
              : `${newFeeDefinitionArgs.amount} %`) +
              ` - ${newFeeDefinitionArgs.feeType}`}
          </span>

          {newFeeDefinitionArgs.restrictToItemQuestion &&
          newFeeDefinitionArgs.itemQuestionValidAnswer ? (
            <span
              style={{
                padding: '0 0.5rem',
                marginLeft: '0.3rem',
                background: 'green',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              ?
            </span>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default FeeEvaluationDemonstrator
