import { useShowErrorsPopup } from '../../../hooks/useShowErrorsPopup'
import { dealCalculationFragments } from '../../deals/hooks'
import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Query, QueryCarDealCalculationArgs } from '@/schemaTypes'

const CAR_DEAL_CALCULATION = gql`
  query carDealCalculation($args: CarDealCalculationArgs!) {
    carDealCalculation(args: $args) {
      ...CarDealCalculationFragment
    }
  }
  ${dealCalculationFragments.carDealCalculation}
`

type TData = { carDealCalculation: Query['carDealCalculation'] }
type TVariables = QueryCarDealCalculationArgs

export function useCarDealCalculation(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(CAR_DEAL_CALCULATION, {
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
