import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query } from '@/schemaTypes'

const GetViviEntryPoint = gql`
  query getViviEntryPoint {
    getViviEntryPoint {
      nextStep {
        summary
        name
        description {
          value
          label
        }
        href
        type
        rel
        templated
      }
    }
  }
`

type TData = { getViviEntryPoint: Query['getViviEntryPoint'] }
type TVariables = {}

export function useGetViviEntryPointQuery(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GetViviEntryPoint, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return queryResult
}
