import {
  useRefreshValorizationCalculationForDealItem,
  useUpdateItem,
} from '../../hooks'
import { useAddItemNote } from '../../hooks/itemDetails/addItemNote'
import { useChangeItemStatus } from '../../hooks/itemDetails/changeItemStatus'
import { useRemoveLastItemEvent } from '../../hooks/itemDetails/removeLastItemEvent'
import { useUpdateItemNote } from '../../hooks/itemDetails/updateItemNote'
import { useRefreshItem } from '../../hooks/itemDetails/useRefreshItem'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EHintType } from '@/components/ItemStatus'
import { Context } from '@/context'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import {
  AddItemToStorageUnitArgs,
  EUsedGrade,
  ItemCategory,
  NoteCreateArgs,
  NoteUpdateArgs,
  ShippingLabelCreateArgs,
} from '@/schemaTypes'
import ItemBlock, { ItemBlockProps } from './ItemBlock'

export interface INoteData {
  note: string
}

export interface ItemContainerProps
  extends Pick<ItemBlockProps, 'deal' | 'item' | 'itemQuestions'> {
  refetchDeal: () => Promise<unknown>
  refetchItem: () => Promise<unknown>
  itemCategory: ItemCategory
}

export default function ItemContainer(props: ItemContainerProps) {
  const { item, deal, refetchDeal, refetchItem, itemCategory } = props
  const context = useContext(Context)
  const { closeConfirmModal } = context
  const { t } = useTranslation()
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false)

  const updateItemWithoutMessage = useUpdateItem()

  const updateItem = useMutationShowingErrors({
    mutation: updateItemWithoutMessage,
    successMessage: `${t('item_updated_successfully')}!`,
  })

  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })

  const refreshValorizationCalculation = useMutationShowingErrors({
    mutation: useRefreshValorizationCalculationForDealItem(),
  })

  const changeStatus = useMutationShowingErrors({
    mutation: useChangeItemStatus(),
    successMessage: `${t('item_status_updated_successfully')}!`,
  })

  const removeLastStatus = useMutationShowingErrors({
    mutation: useRemoveLastItemEvent(),
    successMessage: `${t('item_status_updated_successfully')}!`,
  })

  const onSaveUsedGrade = (usedGradeValue: EUsedGrade | null | undefined) => {
    if (usedGradeValue) {
      updateItem({
        variables: {
          args: { _id: item._id, usedGrade: usedGradeValue as EUsedGrade },
        },
      }).then(() => refetchDeal())
    }
  }

  const onSaveTitle = (title: string) =>
    updateItem({ variables: { args: { _id: item._id, title } } }).then(() =>
      refetchDeal(),
    )

  const onChangeVariantId = (variantId: string) => {
    updateItemWithoutMessage({
      variables: { args: { _id: item._id, variantId } },
    }).then(() => refetchDeal())
  }

  const onChangeUnconfirmedPropertyIds = (unconfirmedPropertyIds: string[]) => {
    updateItem({
      variables: { args: { _id: item._id, unconfirmedPropertyIds } },
    }).then(() => refetchDeal())
  }

  const addItemNote = useMutationShowingErrors({
    mutation: useAddItemNote(),
    successMessage: `${t('update_successful')}!`,
  })

  const updateItemNote = useMutationShowingErrors({
    mutation: useUpdateItemNote(),
    successMessage: `${t('update_successful')}!`,
  })

  const onAddItemNote = (noteCreateArgs: NoteCreateArgs) => {
    addItemNote({
      variables: { itemId: item._id, noteCreateArgs },
      onCompleted: () => refetchItem(),
    })
  }

  const onUpdateItemNote = (noteId: string, noteUpdateArgs: NoteUpdateArgs) => {
    updateItemNote({
      variables: { itemId: item._id, noteId, noteUpdateArgs },
      onCompleted: () => refetchItem(),
    })
  }

  const onSaveSerialNumber = (serialNumber: string | null) => {
    updateItem({ variables: { args: { _id: item._id, serialNumber } } })
  }

  const onSaveImei = (imei: string | null) => {
    updateItem({ variables: { args: { _id: item._id, imei } } })
  }

  const onSaveItemSpecification = (itemSpecification: string | null) => {
    updateItem({ variables: { args: { _id: item._id, itemSpecification } } })
  }

  const onChangeIsInvestmentGold = (isInvestmentGold: boolean | null) => {
    showConfirmPopup({
      title: t('update_tax_free'),
      action: async () => {
        closeConfirmModal()
        const res = await updateItem({
          variables: { args: { _id: item._id, isInvestmentGold } },
        })

        if (res.data?.updateItem) {
          refreshValorizationCalculation({ variables: { dealId: deal._id } })
        }
      },
    })
  }

  const onChangeCheckedAfterAuction = (checkedAfterAuction: boolean | null) => {
    showConfirmPopup({
      title: t('update_check_after_auction'),
      action: async () => {
        closeConfirmModal()
        await updateItem({
          variables: { args: { _id: item._id, checkedAfterAuction } },
        })
      },
    })
  }

  const onChangeEntrupyCertificate = (entrupyCertificateUrl: string | null) => {
    updateItem({
      variables: { args: { _id: item._id, entrupyCertificateUrl } },
    })
  }

  const onChangeCheckedBy = (checkedBy: string | null) => {
    updateItem({ variables: { args: { _id: item._id, checkedBy } } })
  }

  const onChangeCheckedAt = (checkedAt: Date | null) => {
    updateItem({ variables: { args: { _id: item._id, checkedAt } } })
  }

  const refreshItem = useMutationShowingErrors({
    mutation: useRefreshItem(),
  })

  const onChangeStorageFacilityId = (storageFacilityId: string) => {
    showConfirmPopup({
      title: t('storage.change_storage_facility'),
      action: async () => {
        closeConfirmModal()
        await updateItem({
          variables: { args: { _id: item._id, storageFacilityId } },
        })
      },
    })
  }

  const onChangeItemStatus = (
    newEvent,
    hint: EHintType,
    shippingLabelCreateArgs?: ShippingLabelCreateArgs,
    relocateItem?: boolean,
    relocateNote?: string,
    addItemToStorageUnitArgs?: AddItemToStorageUnitArgs,
  ) => {
    showConfirmPopup({
      title:
        hint === EHintType.NONE
          ? t('change_item_status_confirmation')
          : hint === EHintType.WARNING_SELL_NEW_ITEM_ON_REFURBED
            ? t('item_status_change_is_not_recommended')
            : '',
      action: async () => {
        closeConfirmModal()
        setUpdateStatusLoading(true)
        await changeStatus({
          variables: {
            itemId: item._id,
            newEvent: {
              createdAt: newEvent.createdAt,
              event: newEvent.event,
              timestamp: newEvent.timestamp,
              meta: newEvent.meta,
            },
            isStatusAddedManually: true,
            shippingLabelCreateArgs: shippingLabelCreateArgs,
            relocateItem: relocateItem,
            relocateNote: relocateNote,
            addItemToStorageUnitArgs,
          },
        })
        setUpdateStatusLoading(false)
      },
    })
  }

  const onRemoveLatestEvent = () => {
    showConfirmPopup({
      title: t('remove_item_status_confirmation'),
      action: async () => {
        closeConfirmModal()
        setUpdateStatusLoading(true)
        await removeLastStatus({
          variables: {
            itemId: item._id,
            isNeededToRemoveRelatedValorizationEntry: true,
          },
        })
        setUpdateStatusLoading(false)
        refreshItem({
          variables: { itemId: item._id },
        })
      },
    })
  }

  return (
    <ItemBlock
      {...props}
      onSaveTitle={onSaveTitle}
      onChangeIsInvestmentGold={onChangeIsInvestmentGold}
      onSaveImei={onSaveImei}
      onSaveItemSpecification={onSaveItemSpecification}
      onSaveSerialNumber={onSaveSerialNumber}
      onChangeEntrupyCertificate={onChangeEntrupyCertificate}
      onChangeCheckedBy={onChangeCheckedBy}
      onChangeCheckedAt={onChangeCheckedAt}
      onChangeStorageFacilityId={onChangeStorageFacilityId}
      onChangeItemStatus={onChangeItemStatus}
      onRemoveLatestEvent={onRemoveLatestEvent}
      onSaveUsedGrade={onSaveUsedGrade}
      updateStatusLoading={updateStatusLoading}
      itemCategory={itemCategory}
      onChangeCheckedAfterAuction={onChangeCheckedAfterAuction}
      onAddItemNote={onAddItemNote}
      onUpdateItemNote={onUpdateItemNote}
      onChangeVariantId={onChangeVariantId}
      onChangeUnconfirmedPropertyIds={onChangeUnconfirmedPropertyIds}
    />
  )
}
