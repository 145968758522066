import { useContext } from 'react'
import { ReactSVG } from 'react-svg'
import { Context } from '@/context'
import InfoIcon from '@/images/07-info.svg'
import InfoIconDisabled from '@/images/07-infoDisabled.svg'
import InfoIconHighlighted from '@/images/07-infoHighlighted.svg'
import styles from './Info.module.scss'

export interface InfoProps {
  infoText: string
  className?: string
  svgClassName?: string
  svgStyles?: Record<string, any>
  highlight?: boolean
  flex?: boolean
  disabled?: boolean
}

export function Info({
  infoText,
  className = '',
  svgClassName = '',
  svgStyles = {},
  highlight = false,
  flex = false,
  disabled = false,
}: InfoProps) {
  const { showInfo } = useContext(Context)

  return (
    <div
      className={`${className ?? ''} ${styles.info} ${flex ? styles.flex : ''}`}
    >
      <ReactSVG
        style={svgStyles}
        className={`${svgClassName ?? ''}`}
        src={
          disabled
            ? InfoIconDisabled
            : highlight
              ? InfoIconHighlighted
              : InfoIcon
        }
        onClick={disabled ? () => {} : () => showInfo(infoText)}
      />
    </div>
  )
}

export default Info
