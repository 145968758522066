import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetProductPropertyDefinitionsArgs } from '@/schemaTypes'

const GET_PRODUCT_PROPERTY_DEFINITIONS = gql`
  query getProductPropertyDefinitions($opts: ProductPropertyDefinitionOpts) {
    getProductPropertyDefinitions(opts: $opts) {
      _id
      key
      type
      impactsLook
      sortPriority
      description {
        allTranslations {
          text
          languageCode
        }
      }
      categories {
        name
      }
      name {
        allTranslations {
          text
          languageCode
        }
      }
      info {
        allTranslations {
          text
          languageCode
        }
      }
      valueFormatting {
        suffix
      }
    }
  }
`

type TData = {
  getProductPropertyDefinitions: Query['getProductPropertyDefinitions']
}
type TVariables = QueryGetProductPropertyDefinitionsArgs

export function useGetProductPropertyDefinitions(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_PRODUCT_PROPERTY_DEFINITIONS,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    productPropertyDefinitions: queryResult.data?.getProductPropertyDefinitions,
  }
}
