import { noteFragments } from '../../deals/hooks/noteFragments'
import gql from 'graphql-tag'
import {
  dealValuesFragment,
  feeFragments,
  itemFragments,
} from '@/domains/deals/hooks'
import { fragments } from '@/hooks/fragments'

export const event = gql`
  fragment CustomDealEventFragment on CustomDealEvent {
    event
    createdAt
    timestamp
    creatorId
  }
`

const customDealCalculation = gql`
  fragment CustomDealCalculationFragment on CustomDealCalculation {
    date
    dealValuesEntry {
      ...DealValuesEntryFragment
    }
    itemsValuesEntries {
      ...ItemValuesEntryFragment
    }
    appliedUsedFeeDefinitions {
      ...UsedFeeDefinitionFragment
    }
  }

  ${dealValuesFragment.dealValuesEntry}
  ${itemFragments.itemValuesEntry}
  ${feeFragments.usedFeeDefinitions}
`

export const customDeal = gql`
  fragment CustomDealFragment on CustomDeal {
    _id
    dealId
    customDealNumber
    createdAt
    payoutApproverId
    contactData {
      email
      phone
      firstname
      lastname
      blacklistInformation {
        isAddedToBlacklist
        hasCashyAccount
      }
    }
    customerId
    companyId
    company {
      _id
      configuration {
        minimumPawnDuration
        gracePeriod
      }
      headquarter {
        countryCode
      }
    }
    customer {
      email
      phone
      note
      isNotePopup
      isTestUser
      blacklistInformation {
        isAddedToBlacklist
        hasCashyAccount
      }
    }
    acceptedBy {
      firstname
      lastname
    }
    status
    events {
      ...CustomDealEventFragment
    }
    link
    dealType
    durationInDays
    issuanceCertificate {
      url
    }
    policeNumber
    employeeId
    overridePayoutAmount
    employeeResponse {
      openingText
      editableText
      closingText
    }
    notes {
      ...NoteFragment
    }
    isReversedFeeCalculation
    pawnCalculation {
      ...CustomDealCalculationFragment
    }
    purchaseCalculation {
      ...CustomDealCalculationFragment
    }
  }
  ${event}
  ${noteFragments.notes}
  ${customDealCalculation}
`

export const customDealItem = gql`
  fragment CustomDealItemFragment on CustomDealItemDataEntry {
    _id
    itemCategoryId
    title
    note
    policeNumber
    pawnPayoutAmount
    purchasePayoutAmount
    counterofferPayoutAmount
    overwrittenItemValue
    material
    foundComparableOffers
    media {
      ...FileMetadataFragment
    }
    note
    algoliaReference
    variantId
    unconfirmedPropertyIds
    vehicleData {
      ...VehicleDataFragment
    }
    answers {
      questionId
      selectedOptionIndex
      rangeValue
    }
    priceFindings {
      url
      platform
      price
      createdAt
      createdBy {
        firstname
        lastname
      }
    }
    itemPawnValueEntry {
      ...ItemValuesEntryFragment
    }
    itemPurchaseValueEntry {
      ...ItemValuesEntryFragment
    }
    foundOffersEvaluationFactor
  }
  ${fragments.fileMetadata}
  ${fragments.vehicleData}
  ${itemFragments.itemValuesEntry}
`
