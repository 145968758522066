import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation } from '@/schemaTypes'

const REFRESH_SHIPPING_PROFILE_LIST = gql`
  mutation refreshShippingProfileList {
    refreshShippingProfileList {
      _id
    }
  }
`

type TData = {
  refreshShippingProfileList: Mutation['refreshShippingProfileList']
}
type TVariables = {}

export function useRefreshShippingProfileList(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [mutation] = useMutation<TData, TVariables>(
    REFRESH_SHIPPING_PROFILE_LIST,
    opts,
  )
  return mutation
}
