import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Mutation,
  MutationVerifyActivationEmployeeTokenArgs,
} from '@/schemaTypes'

const VERIFY_ACTIVATE_EMPLOYEE_TOKEN = gql`
  mutation verifyActivationEmployeeToken($token: String!) {
    verifyActivationEmployeeToken(token: $token) {
      email
    }
  }
`

type TData = {
  verifyActivationEmployeeToken: Mutation['verifyActivationEmployeeToken']
}
type TVariables = MutationVerifyActivationEmployeeTokenArgs

export function useVerifyActivationEmployeeToken(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [activateEmployee] = useMutation<TData, TVariables>(
    VERIFY_ACTIVATE_EMPLOYEE_TOKEN,
    opts,
  )

  return activateEmployee
}
