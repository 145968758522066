import SearchInput from '../../../../components/SearchInput/SearchInput'
import styled from '@emotion/styled'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Nullable } from 'primereact/ts-helpers'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetShopsNames } from '@/hooks'
import FilterDropdown from '@/redesign/components/FilterDropdown/FilterDropdown'
import FilterMultiSelect from '@/redesign/components/FilterMultiSelect/FilterMultiSelect'
import {
  DealsFiltersArgs,
  EBusinessUnit,
  EDateFilterMode,
  EDealStatusType,
  EDealType,
  ETransportMode,
} from '@/schemaTypes'

interface DateFilter<T extends string> {
  names: T[]
  mode: EDateFilterMode
  from?: Date | null
  to?: Date | null
}
export type DealsFilters = Omit<
  DealsFiltersArgs,
  'paginationArgs' | 'status'
> & {
  status?: DateFilter<EDealStatusType>
}

interface DealSearchProps {
  dealFilter: DealsFilters
  setDealFilter: React.Dispatch<React.SetStateAction<DealsFilters>>
  defaultDealsFilter: Pick<DealsFilters, 'companyId'>
}

const DealsFilter = (props: DealSearchProps) => {
  const { dealFilter, setDealFilter, defaultDealsFilter } = props
  const { t, i18n } = useTranslation()
  const calendarRef = useRef(null)
  const [dateRange, setDateRange] = useState<Nullable<(Date | null)[]>>(null)
  const { shops } = useGetShopsNames()

  const currentLanguage = useMemo(() => i18n.language, [i18n.language])
  const shopOptions = useMemo(
    () => shops.map((shop) => ({ name: shop.name, value: shop._id })),
    [shops],
  )
  const filterOptions = useMemo(() => {
    return {
      statusOptions: dealStatusOptions.map((status) => ({
        name: status,
        value: status,
      })),
      typeOptions: createOptionsFromEnum(EDealType),
      businessUnitOptions: createOptionsFromEnum(EBusinessUnit),
      transportTypeOptions: createOptionsFromEnum(ETransportMode),
    }
  }, [])

  const filterChanged = useMemo(
    () => !isEqual(dealFilter, defaultDealsFilter),
    [dealFilter, defaultDealsFilter],
  )

  const handleChangeCurrentState = useCallback(
    (value, mode) => {
      setDealFilter((prev) => {
        const newStatus = value?.length ? { names: value, mode: mode } : null

        return {
          ...prev,
          status: newStatus,
          ...defaultDealsFilter,
        }
      })

      if (mode === 'WAS' && !value?.length) {
        setDateRange(null)
      }
    },
    [defaultDealsFilter, setDealFilter],
  )

  const handleSetDateRange = useCallback(
    ({ value }) => {
      const selectedDates = value as Date[]
      setDateRange(selectedDates)

      if (selectedDates?.length && !selectedDates?.includes(null)) {
        setDealFilter((prev) => {
          const [from, to] = selectedDates.map((date) => dayjs(date).toDate())

          const updatedFilter = {
            ...prev,
            status: { ...prev.status, from, to },
            ...defaultDealsFilter,
          }

          calendarRef.current.hide()

          return updatedFilter
        })
      }
    },
    [defaultDealsFilter, setDealFilter, setDateRange, calendarRef],
  )

  const handleDealsFilterChange = (key, value) => {
    setDealFilter((prev) => ({
      ...prev,
      [key]: value,
      ...defaultDealsFilter,
    }))
  }

  const CalendarTemplate = useCallback(
    () => (
      // Using e.stopPropagation() here resolves the issue of the Calendar dropdown closing immediately when the user clicks on the month, year, or arrow icons.
      <CalendarContainer onClick={(e) => e.stopPropagation()}>
        <CalendarStyled
          className="w-full"
          ref={calendarRef}
          value={dateRange}
          selectionMode="range"
          placeholder="Choose Period"
          showIcon
          dateFormat="dd.mm.yy"
          disabled={!dealFilter.status?.names}
          onChange={handleSetDateRange}
        />
      </CalendarContainer>
    ),
    [dateRange, dealFilter, handleSetDateRange],
  )

  return (
    <div className="flex flex-wrap items-center gap-x-4">
      <div className="w-52">
        <SearchInput
          id="DEALS_SEARCH_INPUT"
          placeholder={t('deal_filter.search')}
          value={dealFilter.search ?? ''}
          onChange={({ target }) =>
            handleDealsFilterChange('search', target?.value)
          }
          onClear={() => handleDealsFilterChange('search', null)}
        />
      </div>

      <FilterMultiSelect
        disabled={dealFilter.status?.mode === 'WAS'}
        showSelectAll={false}
        value={
          dealFilter.status?.mode === 'CURRENT'
            ? dealFilter.status?.names
            : null
        }
        onChange={({ value }) => handleChangeCurrentState(value, 'CURRENT')}
        options={filterOptions?.statusOptions}
        optionLabel="name"
        placeholder={t('deal_filter.current_status')}
        maxSelectedLabels={1}
        showClear
        panelHeaderTemplate={<></>}
        selectedItemsLabel={`${dealFilter.status?.names?.length} selected`}
        className={classNames(
          { 'w-5.5': !dealFilter.status?.mode && !dealFilter.status?.names },
          {
            'w-32 selected':
              dealFilter.status?.mode === 'CURRENT' && dealFilter.status?.names,
          },
          {
            'w-8.4':
              dealFilter.status?.mode === 'CURRENT' &&
              dealFilter.status?.names.length >= 2,
          },
        )}
      />

      <FilterMultiSelect
        disabled={dealFilter.status?.mode === 'CURRENT'}
        showSelectAll={false}
        value={
          dealFilter.status?.mode === 'WAS' ? dealFilter.status?.names : null
        }
        onChange={({ value }) => handleChangeCurrentState(value, 'WAS')}
        options={filterOptions?.statusOptions}
        optionLabel="name"
        placeholder={t('deal_filter.past_status')}
        maxSelectedLabels={1}
        showClear
        panelHeaderTemplate={CalendarTemplate}
        selectedItemsLabel={`${dealFilter.status?.names?.length} selected`}
        className={classNames({
          'w-6.1':
            !dealFilter.status?.mode &&
            !dealFilter.status?.names &&
            currentLanguage !== 'de',
          'w-6.3': !dealFilter.status?.mode && !dealFilter.status?.names,
          'w-32 selected':
            dealFilter.status?.mode === 'WAS' && dealFilter.status?.names,
          'w-8.4':
            dealFilter.status?.mode === 'WAS' &&
            dealFilter.status?.names.length >= 2,
        })}
      />

      <FilterDropdown
        value={dealFilter.initialShopId}
        onChange={({ value }) =>
          handleDealsFilterChange('initialShopId', value)
        }
        options={shopOptions}
        optionLabel="name"
        placeholder={t('deal_filter.initial_shop')}
        showClear
        className={classNames({
          selected: dealFilter.initialShopId,
        })}
      />

      <FilterDropdown
        value={dealFilter.type}
        onChange={({ value }) => handleDealsFilterChange('type', value)}
        options={filterOptions?.typeOptions}
        optionLabel="name"
        placeholder={t('deal_filter.type')}
        showClear
        className={classNames({ selected: dealFilter.type })}
      />

      <FilterDropdown
        value={dealFilter.businessUnit}
        onChange={({ value }) => handleDealsFilterChange('businessUnit', value)}
        options={filterOptions?.businessUnitOptions}
        optionLabel="name"
        placeholder={t('deal_filter.unit')}
        showClear
        className={classNames({ selected: dealFilter.businessUnit })}
      />

      <FilterDropdown
        value={dealFilter.transportType}
        onChange={({ value }) =>
          handleDealsFilterChange('transportType', value)
        }
        options={filterOptions?.transportTypeOptions}
        optionLabel="name"
        placeholder={t('deal_filter.transport')}
        showClear
        className={classNames({
          'w-7.2': !dealFilter.transportType,
          'w-7.2 selected': dealFilter.transportType,
        })}
      />

      <StyledButton
        disabled={!filterChanged}
        label={t('deal_filter.clear_filter')}
        icon="pi pi-replay"
        severity="secondary"
        text
        onClick={() => {
          setDealFilter(defaultDealsFilter)
        }}
      />
    </div>
  )
}

const createOptionsFromEnum = (enumObject) =>
  Object.entries(enumObject).map(([name, value]) => ({ name, value }))

const dealStatusOptions = [
  EDealStatusType.Booked,
  EDealStatusType.Verified,
  EDealStatusType.PayedAndStored,
  EDealStatusType.PaybackConfirmed,
  EDealStatusType.ExtensionConfirmed,
  EDealStatusType.LoanDue,
  EDealStatusType.LoanDueNotified,
  EDealStatusType.OnSell,
  EDealStatusType.SoldIntern,
  EDealStatusType.SoldExtern,
  EDealStatusType.Declined,
  EDealStatusType.Canceled,
  EDealStatusType.Derecognized,
  EDealStatusType.CustomerInCredit,
  EDealStatusType.CustomerInDebt,
  EDealStatusType.ExcessAmountPayed,
  EDealStatusType.ItemReceivedIdMissing,
  EDealStatusType.PayedShipmentPending,
]

const CalendarContainer = styled.div`
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #dee2e6;
  color: #343a40;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
`

const CalendarStyled = styled(Calendar)`
  height: 2.375rem;
  &&& {
    .p-inputtext {
      padding: 0.56rem;
      font-size: 0.875rem;
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem;
    }
    .p-button {
      color: #6c757d;
      background: transparent;
      border: unset;
      position: absolute;
      right: 0.938rem;
      padding: 0;
      top: 0.375rem;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      width: auto;
    }
    .p-button:focus {
      box-shadow: unset;
    }
  }
`

const StyledButton = styled(Button)`
  height: 2.375rem;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.05rem;
  padding: 0.66rem 1.09rem;
`

export default DealsFilter
