import { orderBy } from 'lodash'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import { ERoles, Note, NoteUpdateArgs } from '@/schemaTypes'
import NoteElement from './NoteElement'

interface NoteListProps {
  notes: Note[]
  onUpdateNote: (noteId: string | number, note: NoteUpdateArgs) => void
  horizontalMode?: boolean
}

const NoteList = ({ notes, onUpdateNote, horizontalMode }: NoteListProps) => {
  const { getCurrentUser } = useContext(Context)
  const { t } = useTranslation()
  const currentUser = getCurrentUser()
  const sortedNotes = orderBy(notes, ['updatedAt'], ['desc']) as Array<
    Omit<Note, '_id'> & { _id?: string }
  >

  return (
    <div className="u-block u-mt-20">
      <div className="o-type-bold">{t('notification_notes')}:</div>
      <div style={horizontalMode ? { maxHeight: 250, overflowY: 'auto' } : {}}>
        {sortedNotes.map((note) => {
          const isOwnerNote = currentUser?._id === note.createdBy
          const allowDisablePopup =
            isOwnerNote || currentUser?.roles.includes(ERoles.Superadmin)
          return (
            <NoteElement
              horizontalMode={horizontalMode}
              key={note._id}
              note={note}
              onUpdateNote={onUpdateNote}
              isOwnerNote={isOwnerNote}
              allowDisablePopup={allowDisablePopup}
            />
          )
        })}
      </div>
    </div>
  )
}

export default NoteList
