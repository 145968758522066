import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import styles from '@/domains/materialPrices/components/MaterialPricesConfiguration.module.scss'
import MaterialPricesRow from '@/domains/materialPrices/components/MaterialPricesRow'
import {
  useDeleteMaterialPrice,
  useGetMaterialPrices,
} from '@/domains/materialPrices/hooks'
import { useConfirmDeleteMutationShowingErrors } from '@/hooks'

export function MaterialPricesTable() {
  const { t } = useTranslation()

  const { materialPrices, queryResult } = useGetMaterialPrices()

  const deleteMaterialPrice = useConfirmDeleteMutationShowingErrors({
    successMessage: t('material.material_price_deleted'),
    mutation: useDeleteMaterialPrice(),
  })

  const _handleDeleteClick = async (id: string) => {
    deleteMaterialPrice({
      variables: {
        materialPriceId: id,
      },
      onCompleted: () => {
        queryResult.refetch()
      },
    })
  }

  return (
    <Paper className={`${styles.root} u-mb`}>
      <ConfigurationBreadcrumbs modelName={'material.material_prices'} />
      <Typography variant="h5" gutterBottom>
        {t('material.material_prices_configurations')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Table className="u-mb-sm" size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('id')}</TableCell>
                <TableCell align="right">{t('date')}</TableCell>
                <TableCell align="right">{t('material.singular')}</TableCell>
                <TableCell align="right">{t('price')}</TableCell>
                <TableCell align="right">{t('mu')}</TableCell>
                <TableCell align="right">{t('sigma')}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(materialPrices).map((mpKey) => (
                <MaterialPricesRow
                  key={mpKey}
                  materialPrice={materialPrices[mpKey]}
                  deleteMaterialPrice={() =>
                    _handleDeleteClick(materialPrices[mpKey]._id)
                  }
                />
              ))}
            </TableBody>
          </Table>
          <Link to="/inApp/configurations/materialPrices/create">
            <Button className="u-mr-mini" variant="contained">
              {t('material.material_price_create')}
            </Button>
          </Link>
        </Fragment>
      )}
    </Paper>
  )
}

export default MaterialPricesTable
