import { isEqual } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

export function useHistoryState<T>() {
  const { replace, location } = useHistory<T>()

  const setHistory = useCallback(
    (value: T) => {
      replace(location.pathname, { ...location.state, ...value })
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [replace, location.pathname],
  )

  return { historyState: location.state, setHistory }
}

export function useHistoryFilter<T>(
  historyFilters: T | undefined,
  filters: T,
  setHistory: (value: HistoryFilters<T>) => void,
) {
  const [edited, setEdited] = useState(false)

  useEffect(() => {
    if (!edited) {
      if (isEqual(filters, historyFilters)) {
        return
      }
      setEdited(true)
    }

    setHistory({ filters, scroll: { offset: 0 } })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, edited])

  return { edited }
}

export interface HistoryFilters<T = {}> {
  filters?: T
  scroll?: {
    offset?: number
    items?: number
    rowHeights?: Object
  }
}
