import TimeHourPicker from '../TimeHourPicker'
import { KeyboardDatePicker } from '@material-ui/pickers'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import styles from './DateHourPicker.module.scss'

dayjs.extend(utc)

export interface DateHourPickerProps {
  dateLabel: string
  timeLabel: string
  value?: Date | null
  minDate?: Date
  hasError: boolean
  onHourChange(hours: number): void
  onDateChange(date: Dayjs | null): void
}

export const DateHourPicker = (props: DateHourPickerProps) => (
  <div className={styles.container}>
    {props.dateLabel}
    <KeyboardDatePicker
      className={`${styles.datePicker} u-ml-5`}
      clearable
      format="DD.MM.YYYY"
      minDate={props.minDate}
      value={props.value ? dayjs(props.value) : null}
      onChange={props.onDateChange}
      error={props.hasError}
    />
    {props.value && (
      <TimeHourPicker
        value={props.value}
        label={props.timeLabel}
        onChange={props.onHourChange}
      />
    )}
  </div>
)

export default DateHourPicker
