import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ESalesPartnerType,
  OSalesPartnerData,
  OSalesPartnerDataBaggyluxe,
  OSalesPartnerDataBlitzMobile,
  OSalesPartnerDataChrono24,
  OSalesPartnerDataEbay,
  OSalesPartnerDataEbayKleinanzeigen,
  OSalesPartnerDataOthers,
  OSalesPartnerDataRebelle,
  OSalesPartnerDataRefurbed,
  OSalesPartnerDataVestiaireCollective,
  OSalesPartnerDataWillhaben,
} from '@/schemaTypes'
import styles from './ValorizationHistoryEntry.module.scss'
import ValorizationHistoryEntryTable, {
  ValorizationHistoryEntryTableRow,
} from './ValorizationHistoryEntryTable'

interface ValorizationHistoryEntrySalesPartnerDataRow
  extends Pick<
    ValorizationHistoryEntryTableRow<
      Exclude<keyof OSalesPartnerData, '__typename'>
    >,
    'label' | 'type' | 'key' | 'hidden' | 'options' | 'disabled'
  > {
  partnerKey?:
    | keyof OSalesPartnerDataRefurbed
    | keyof OSalesPartnerDataEbay
    | keyof OSalesPartnerDataWillhaben
    | keyof OSalesPartnerDataChrono24
    | keyof OSalesPartnerDataRebelle
    | keyof OSalesPartnerDataBlitzMobile
    | keyof OSalesPartnerDataBaggyluxe
    | keyof OSalesPartnerDataVestiaireCollective
    | keyof OSalesPartnerDataOthers
    | keyof OSalesPartnerDataEbayKleinanzeigen
}

export interface ValorizationHistoryEntrySalesPartnerDataProps {
  className?: string
  salesPartnerData: OSalesPartnerData
  columnWidths: { label: number; value: number }
  onChangeSalesPartnerData: (updateData: OSalesPartnerData) => void
  isReadonly: boolean
}

export function ValorizationHistorySalesPartnerData(
  props: ValorizationHistoryEntrySalesPartnerDataProps,
) {
  const {
    className,
    salesPartnerData,
    columnWidths,
    onChangeSalesPartnerData,
    isReadonly,
  } = props

  const { t } = useTranslation()

  // get sale partner from ESalesPartnerType
  const salesPartnersList = Object.values(ESalesPartnerType)
  const SALES_PARTNER_TYPES_OPTIONS = salesPartnersList.map((salesPartner) => {
    return { label: t(salesPartner.toLowerCase()), value: salesPartner }
  })

  const rowsData: ValorizationHistoryEntrySalesPartnerDataRow[] = [
    {
      label: t('sales_partner'),
      key: 'type',
      type: 'string',
      options: SALES_PARTNER_TYPES_OPTIONS,
      disabled: isReadonly,
    },
    {
      label: t('ebay_article_number'),
      key: 'ebay',
      partnerKey: 'ebayArticleNumber',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Ebay,
      disabled: isReadonly,
    },
    {
      label: t('shipping_date'),
      key: 'ebay',
      partnerKey: 'shippingDate',
      type: 'date',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Ebay,
      disabled: isReadonly,
    },

    {
      label: t('kleinanzeigen_article_number'),
      key: 'ebaykleinanzeigen',
      partnerKey: 'kleinanzeigenArticleNumber',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.EbayKleinanzeigen,
      disabled: isReadonly,
    },
    {
      label: t('shipping_date'),
      key: 'ebaykleinanzeigen',
      partnerKey: 'shippingDate',
      type: 'date',
      hidden: salesPartnerData?.type !== ESalesPartnerType.EbayKleinanzeigen,
      disabled: isReadonly,
    },
    {
      label: t('willhaben_article_number'),
      key: 'willhaben',
      partnerKey: 'willhabenArticleNumber',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Willhaben,
      disabled: isReadonly,
    },
    {
      label: t('shipping_date'),
      key: 'willhaben',
      partnerKey: 'shippingDate',
      type: 'date',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Willhaben,
      disabled: isReadonly,
    },
    {
      label: t('refurbed_id'),
      key: 'refurbed',
      partnerKey: 'ID',
      type: 'integer',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Refurbed,
      disabled: isReadonly,
    },
    {
      label: t('warranty'),
      key: 'refurbed',
      partnerKey: 'warranty',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Refurbed,
      disabled: isReadonly,
    },
    {
      label: t('quantity'),
      key: 'refurbed',
      partnerKey: 'quantity',
      type: 'integer',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Refurbed,
      disabled: isReadonly,
    },
    {
      label: t('shipping_costs'),
      key: 'refurbed',
      partnerKey: 'shippingCosts',
      type: 'float',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Refurbed,
      disabled: isReadonly,
    },
    {
      label: t('settlement_total_refunded'),
      key: 'refurbed',
      partnerKey: 'settlementTotalRefunded',
      type: 'float',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Refurbed,
      disabled: isReadonly,
    },
    {
      label: t('settlement_total_commission'),
      key: 'refurbed',
      partnerKey: 'settlementTotalCommission',
      type: 'float',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Refurbed,
      disabled: isReadonly,
    },
    {
      label: t('settlement_base_commission'),
      key: 'refurbed',
      partnerKey: 'settlementBaseCommission',
      type: 'float',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Refurbed,
      disabled: isReadonly,
    },
    {
      label: t('settlement_payment_commission'),
      key: 'refurbed',
      partnerKey: 'settlementPaymentCommission',
      type: 'float',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Refurbed,
      disabled: isReadonly,
    },
    {
      label: t('settlement_payout_commission'),
      key: 'refurbed',
      partnerKey: 'settlementPayoutCommission',
      type: 'float',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Refurbed,
      disabled: isReadonly,
    },
    {
      label: t('settlement_dynamic_commission'),
      key: 'refurbed',
      partnerKey: 'settlementDynamicCommission',
      type: 'float',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Refurbed,
      disabled: isReadonly,
    },
    {
      label: t('item_categories.chrono24_transaction_id'),
      key: 'chrono24',
      partnerKey: 'transactionId',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Chrono24,
      disabled: isReadonly,
    },
    {
      label: t('item_categories.chrono24_sale_id'),
      key: 'chrono24',
      partnerKey: 'saleId',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Chrono24,
      disabled: isReadonly,
    },
    {
      label: t('item_categories.rebelle_transaction_id'),
      key: 'rebelle',
      partnerKey: 'transactionId',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Rebelle,
      disabled: isReadonly,
    },
    {
      label: t('item_categories.rebelle_sale_id'),
      key: 'rebelle',
      partnerKey: 'saleId',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Rebelle,
      disabled: isReadonly,
    },
    {
      label: t('item_categories.blitz_mobile_transaction_id'),
      key: 'blitzmobile',
      partnerKey: 'transactionId',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.BlitzMobile,
      disabled: isReadonly,
    },
    {
      label: t('item_categories.blitz_mobile_sale_id'),
      key: 'blitzmobile',
      partnerKey: 'saleId',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.BlitzMobile,
      disabled: isReadonly,
    },
    {
      label: t('item_categories.baggyluxe_transaction_id'),
      key: 'baggyluxe',
      partnerKey: 'transactionId',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Baggyluxe,
      disabled: isReadonly,
    },
    {
      label: t('item_categories.baggyluxe_sale_id'),
      key: 'baggyluxe',
      partnerKey: 'saleId',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Baggyluxe,
      disabled: isReadonly,
    },
    {
      label: t('item_categories.vestiaire_collective_ref_id'),
      key: 'vestiairecollective',
      partnerKey: 'refId',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.VestiaireCollective,
      disabled: isReadonly,
    },
    {
      label: t('item_categories.others_transaction_id'),
      key: 'others',
      partnerKey: 'transactionId',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Others,
      disabled: isReadonly,
    },
    {
      label: t('item_categories.others_sale_id'),
      key: 'others',
      partnerKey: 'saleId',
      type: 'string',
      hidden: salesPartnerData?.type !== ESalesPartnerType.Others,
      disabled: isReadonly,
    },
  ]

  const buildRow = (
    params: ValorizationHistoryEntrySalesPartnerDataRow,
  ): ValorizationHistoryEntryTableRow<keyof OSalesPartnerData> => {
    const { key, partnerKey } = params

    let value: any = null

    if (salesPartnerData) {
      value = salesPartnerData[key] ?? null

      if (partnerKey && value) {
        value = value[partnerKey] ?? null
      }
    }

    return {
      ...params,
      renderKey: `${key}${partnerKey ? `_${partnerKey}` : ''}`,
      value,
      onChange: params.disabled
        ? undefined
        : (value) => {
            if (partnerKey && key !== 'type') {
              onChangeSalesPartnerData({
                ...(salesPartnerData ?? {}),
                [key]: {
                  ...(salesPartnerData[key] ?? {}),
                  [partnerKey]: value,
                },
              } as any)
            } else {
              onChangeSalesPartnerData({
                ...(salesPartnerData ?? {}),
                [key]: value,
              } as any)
            }
          },
    }
  }

  const rows = rowsData.map((rowData) => buildRow(rowData))

  return (
    <section className={className ?? ''}>
      <Typography
        className={`${styles.middleBold}`}
        variant="body1"
        gutterBottom
      >
        {t('sales_partner_data')}
      </Typography>
      <ValorizationHistoryEntryTable rows={rows} columnWidths={columnWidths} />
    </section>
  )
}

// Prevent rerender if parent rerendered and shallow child props comparison did not find differences.
export default React.memo(ValorizationHistorySalesPartnerData)
