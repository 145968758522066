import AddPrivateSaleDeal from '../../../domains/privateSales/components/AddPrivateSale/AddPrivateSaleDeal'
import PrivateSaleCalculationViewer from '../../../domains/privateSales/components/AddPrivateSale/PrivateSaleCalculationViewer'
import PrivateSaleTransactionsList from '../../../domains/privateSales/components/AddPrivateSale/PrivateSaleTransactionsList'
import { useCreatePrivateSale } from '../../../domains/privateSales/hooks/createPrivateSale'
import { Button, DialogProps, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import AddIcon from '@material-ui/icons/Add'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import LinkIcon from '@material-ui/icons/Link'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import YoutubeSearchedForOutlined from '@material-ui/icons/YoutubeSearchedForOutlined'
import dayjs from 'dayjs'
import {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import LoadingSpinner from '@/components/LoadingSpinner'
import { Context } from '@/context'
import {
  useGetDealById,
  useGetExistingDealCalculation,
} from '@/domains/deals/hooks'
import { useGetItemById } from '@/domains/items/hooks'
import styles from '@/domains/privateSales/components/AddPrivateSale/AddPrivateSale.module.scss'
import {
  transactionsWithSpecificLabelOnBill,
  transactionsWithUStAffect,
} from '@/domains/privateSales/constants'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import {
  EPrivateSaleCreationType,
  ETransactionType,
  GetExistingDealCalculationArgs,
  PrivateSaleTransactionArgs,
} from '@/schemaTypes'
import { isDealConnectedToRegisteredCustomer } from '@/utils/deal'
import { defaultTimezone } from '@/utils/time'

export interface AddPrivateSaleProps {}

const AddPrivateSale: FunctionComponent<AddPrivateSaleProps> = () => {
  const { closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()
  const [selectedDealId, setSelectedDealId] = useState(undefined)
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper')
  const [
    loadingSpinnerForCreateingPrivateSale,
    setLoadingSpinnerForCreateingPrivateSale,
  ] = useState(false)

  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })

  const { deal } = useGetDealById({
    variables: {
      dealId: selectedDealId,
    },
    skip: !selectedDealId,
  })

  const [selectedItemId, setSelectedItemId] = useState(
    deal ? deal.itemsIds[0] : undefined,
  )

  const { item } = useGetItemById({
    variables: {
      itemId: selectedItemId,
    },
    skip: !selectedItemId,
  })

  useEffect(() => {
    setSelectedItemId(deal ? deal.itemsIds[0] : undefined)
  }, [deal])

  const [existingCalculationArgs, setExistingCalculationArgs] =
    useState<GetExistingDealCalculationArgs>()

  const {
    dealCalculation: dealCloseCalculation,
    queryResult: closeCalculationQueryResult,
  } = useGetExistingDealCalculation({
    variables: {
      dealId: deal?._id,
      args: existingCalculationArgs!,
    },
    skip: !existingCalculationArgs,
  })

  const history = useHistory()

  const handleClose = (isConfirmed: boolean) => {
    if (!isConfirmed) setSelectedDealId(undefined)
    setOpen(false)
  }

  const addDealHandler = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true)
    setScroll(scrollType)
  }

  const [transactionsArgs, setTransactionsArgs] = useState<
    PrivateSaleTransactionArgs[]
  >([])

  const createPrivateSale = useMutationShowingErrors({
    mutation: useCreatePrivateSale(),
    successMessage: `${t('private_sale_added_successfully')}!`,
  })

  const onSave = () => {
    showConfirmPopup({
      title: t('add_private_sale_confirmation'),
      action: async () => {
        closeConfirmModal()
        setLoadingSpinnerForCreateingPrivateSale(true)

        createPrivateSale({
          variables: {
            args: {
              privateSaleDate: dayjs().toDate(),
              creationType: EPrivateSaleCreationType.Manual,
              dealId: deal?._id,
              itemId: deal?.itemsIds[0],
              transactionArgs: transactionsArgs.map((transactionArg) => {
                if (
                  transactionArg.transactionType !== ETransactionType.SellingCar
                ) {
                  transactionArg.buyerName = undefined
                }
                if (
                  !transactionsWithSpecificLabelOnBill.includes(
                    transactionArg.transactionType,
                  )
                ) {
                  transactionArg.costName = undefined
                }
                if (
                  !transactionsWithUStAffect.includes(
                    transactionArg.transactionType,
                  )
                ) {
                  transactionArg.grossAmount = undefined
                  transactionArg.ust = undefined
                }
                return transactionArg
              }),
              manualFeeDefinitionsArgs:
                existingCalculationArgs?.manualFeeDefinitionsArgs ?? [],
            },
          },
        }).then((c) =>
          c.data?.createPrivateSale
            ? history.push(
                `/inApp/privateSales/${c.data.createPrivateSale._id}`,
              )
            : '',
        )
      },
    })

    setLoadingSpinnerForCreateingPrivateSale(false)
  }

  return (
    <div className={`u-12/12 u-mb-sm`}>
      <Typography variant="h5" gutterBottom className={'u-flex u-jc-sb'}>
        <div className={'u-inline-block'} style={{ marginRight: 'auto' }}>
          {`${t('add_private_sale')} `}
        </div>
      </Typography>

      <Paper
        className="u-p-sm u-12/12 u-mb-sm"
        style={{
          backgroundColor: '#eeeded',
        }}
      >
        <div>
          <div className={`${styles.inlineDivs}`}>
            <Typography
              variant="h6"
              gutterBottom
              classes={{ root: styles.title }}
            >
              {t('car_pawn')}
            </Typography>
          </div>
          <div className={`${styles.inlineDivs}`}>
            <Typography
              variant="h6"
              gutterBottom
              classes={{ root: styles.title }}
            >
              {!deal ? (
                <div className={`${styles.inlineInnerDivs}`}>
                  <Button
                    className={`${styles.button} u-ml-sm`}
                    onClick={addDealHandler('paper')}
                  >
                    <AddIcon />
                  </Button>
                </div>
              ) : (
                <div className={`${styles.inlineInnerDivs}`}>
                  <Button
                    className={`${styles.button} u-ml-sm`}
                    onClick={addDealHandler('paper')}
                  >
                    <YoutubeSearchedForOutlined />
                  </Button>
                </div>
              )}

              {deal && (
                <div className={`${styles.inlineInnerDivs}`}>
                  <Button
                    className={`${styles.button} u-ml-sm`}
                    onClick={() => setSelectedDealId(undefined)}
                  >
                    <CloseOutlinedIcon />
                  </Button>
                </div>
              )}

              {deal && (
                <div className={`${styles.inlineInnerDivs}`}>
                  <Button
                    className={`${styles.button} u-ml-sm`}
                    onClick={onSave}
                  >
                    <SaveOutlinedIcon />
                  </Button>
                </div>
              )}

              {loadingSpinnerForCreateingPrivateSale && (
                <div className={`${styles.inlineInnerSpinner}`}>
                  <LoadingSpinner />
                </div>
              )}
            </Typography>
          </div>
        </div>

        <AddPrivateSaleDeal
          open={open}
          scroll={scroll}
          handleClose={handleClose}
          selectedDealId={selectedDealId}
          setSelectedDealId={setSelectedDealId}
        />
        <br />

        {item ? (
          <Fragment>
            <Paper className="u-p-sm u-12/12 u-mb-sm">
              <div className={`row ${styles.row}`}>
                <div className={`column  ${styles.partialColumn}`}>
                  <Typography variant="h5" gutterBottom>
                    {t('deal.label')}
                    <a
                      target="_blank"
                      className={styles.aTag}
                      href={`/inApp/deals/${deal._id}`}
                      rel="noreferrer"
                    >
                      <LinkIcon style={{ marginBottom: '-0.5rem' }} />
                    </a>
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('deal.booking_number')}:
                    <Link to={`/inApp/deals/${deal._id}`} target="blank">
                      {deal.bookingNumber}
                    </Link>
                    <br />
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('status')}:{' '}
                    {deal.events
                      ? deal.events[deal.events.length - 1].displayName
                      : '-'}
                    <br />
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('deal.business_unit')}: {deal.businessUnit}
                    <br />
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('company.singular')}: {deal.company?.name}
                    <br />
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('enter_shop_name')}: {deal.shop?.name}
                    <br />
                  </Typography>
                </div>

                <div className={`column  ${styles.partialColumn}`}>
                  <Typography variant="h5" gutterBottom>
                    {t('car')}
                    <a
                      target="_blank"
                      className={styles.aTag}
                      href={`/inApp/items/${item._id}`}
                      rel="noreferrer"
                    >
                      <LinkIcon style={{ marginBottom: '-0.5rem' }} />
                    </a>
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('car_make')}: {item.vehicleData?.vehicleProperties?.make}
                    <br />
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('car_model')}:{' '}
                    {item.vehicleData?.vehicleProperties?.model}
                    <br />
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('car_regdate')}:{' '}
                    {item.vehicleData?.vehicleProperties?.regdate}
                    <br />
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('car_engine')}:{' '}
                    {item.vehicleData?.vehicleProperties?.engine}
                    <br />
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('car_odometer')}:{' '}
                    {item.vehicleData?.vehicleProperties?.odometer}
                    <br />
                  </Typography>
                </div>

                {isDealConnectedToRegisteredCustomer(deal) && (
                  <div className={`column  ${styles.partialColumn}`}>
                    <Typography variant="h5" gutterBottom>
                      {t('customer.label')}
                      <a
                        target="_blank"
                        className={styles.aTag}
                        href={`/inApp/customers/edit/${deal.customer._id}`}
                        rel="noreferrer"
                      >
                        <LinkIcon style={{ marginBottom: '-0.5rem' }} />
                      </a>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {t('customer.firstname')}: {deal.customer.firstname}
                      <br />
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {t('customer.lastname')}: {deal.customer.lastname}
                      <br />
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {t('customer.email')}: {deal.customer.email}
                      <br />
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {t('customer.phone')}: {deal.customer.phone}
                      <br />
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {t('customer.sex')}: {deal.customer.sex}
                      <br />
                    </Typography>
                  </div>
                )}
              </div>
            </Paper>

            <PrivateSaleTransactionsList
              transactionsArgs={transactionsArgs}
              setTransactionsArgs={setTransactionsArgs}
              isPrivateSaleCreated={false}
              timezone={defaultTimezone}
              deal={deal}
              item={item}
              setExistingCalculationArgs={setExistingCalculationArgs}
            />
            <br />
            {deal && dealCloseCalculation && transactionsArgs.length > 0 ? (
              <PrivateSaleCalculationViewer
                deal={deal}
                transactionsArgs={transactionsArgs}
                dealCloseCalculation={dealCloseCalculation}
                calculationQueryResult={closeCalculationQueryResult}
                existingCalculationArgs={existingCalculationArgs!}
                setExistingCalculationArgs={setExistingCalculationArgs}
              />
            ) : null}
          </Fragment>
        ) : (
          ''
        )}
      </Paper>
    </div>
  )
}

export default AddPrivateSale
