import AddPrivateSaleTransaction from '../Common/AddPrivateSaleTransaction'
import {
  DialogProps,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import dayjs from 'dayjs'
import * as React from 'react'
import { Fragment, useContext, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import { transactionsWithUStAffect } from '@/domains/privateSales/constants'
import {
  Deal,
  DealItem,
  EBillingPeriodType,
  EDealType,
  EFeeLevel,
  EFeeMode,
  EFeeType,
  EManualPaymentType,
  ETransactionType,
  ETransportMode,
  GetExistingDealCalculationArgs,
  Item,
  ManualFeeDefinitionArgs,
  PrivateSaleTransactionArgs,
} from '@/schemaTypes'
import { getLatestDealManualFeeDefinitionArgs } from '@/utils/deal'
import { manualPaymentTypesConnectedToCashbook } from '@/utils/misc'
import { Timezone, getDurationInDays } from '@/utils/time'
import PrivateSaleTransactionDetails from './PrivateSaleTransactionDetails'

interface PrivateSaleTransactionsListProps {
  transactionsArgs: PrivateSaleTransactionArgs[]
  setTransactionsArgs: (listArgs: PrivateSaleTransactionArgs[]) => void
  isPrivateSaleCreated: boolean
  timezone: Timezone
  deal: Deal
  item: Item
  setExistingCalculationArgs: (args: GetExistingDealCalculationArgs) => void
}

const PrivateSaleTransactionsList: FunctionComponent<
  PrivateSaleTransactionsListProps
> = (props) => {
  const {
    transactionsArgs,
    setTransactionsArgs,
    timezone,
    setExistingCalculationArgs,
    deal,
    item,
  } = props
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [scroll] = useState<DialogProps['scroll']>('paper')
  const defaultUst = 20
  const liquidationFactor = 18 / 100

  const oneTimeTransactionType = [
    ETransactionType.SellingCar,
    ETransactionType.Payout,
    ETransactionType.PaybackFees,
    ETransactionType.LiquidationFee,
  ]

  const onConfirm = (args: PrivateSaleTransactionArgs) => {
    setOpen(false)
    if (
      oneTimeTransactionType.includes(args.transactionType) &&
      transactionsArgs
        .map((c) => c.transactionType)
        .includes(args.transactionType)
    ) {
      return showInfo(t('transaction_already_exists'))
    } else if (!args.paymentType) {
      return showInfo(t('payment_method_is_not_set'))
    } else if (!args.transactionType) {
      return showInfo(t('transaction_type_is_not_set'))
    } else if (
      args.transactionType === ETransactionType.SellingCar &&
      (!args.buyerName || args.buyerName.length <= 0)
    ) {
      return showInfo(t('buyer_name_is_not_set'))
    } else if (isValidCashbookTransaction(args) && !args.shopId) {
      return showInfo(t('must_select_cashbook_for_private_sale_transaction'))
    } else {
      args.order = transactionsArgs.length + 1
      const temp = transactionsArgs
      temp.push(args)

      // Handle Liquidation_Fee
      if (
        args.transactionType === ETransactionType.SellingCar &&
        !temp
          .map((c) => c.transactionType)
          .includes(ETransactionType.LiquidationFee)
      ) {
        const mul = 1 + defaultUst / 100
        temp.push({
          amount: (args.amount * liquidationFactor) / mul,
          grossAmount: args.amount * liquidationFactor,
          date: args.date,
          order: args.order + 1,
          paymentType: EManualPaymentType.Dummy,
          transactionType: ETransactionType.LiquidationFee,
          ust: defaultUst,
        })
      }

      setTransactionsArgs(temp)
      setExistingCalculationArgs({
        dealType: deal.dealType,
        dropoffTransportMode: ETransportMode.PrivateSale,
        durationInDays: getDurationInDays(dayjs().toDate(), deal.dealStartDate),
        shouldOverwriteManualFees: true,
        manualFeeDefinitionsArgs:
          getManualFeeDefinitionArgsFromPrivateSaleTransactionArgs(
            transactionsArgs,
            deal,
            deal.items[0],
          ),
        shouldOverwritePayoutAmount:
          deal.dealFinalValues.shouldOverwritePayoutAmount,
        overwrittenPayoutAmount: deal.dealFinalValues.payoutAmount,
        isForCustomer: false,
      })
    }
  }

  useEffect(() => {
    setExistingCalculationArgs({
      dealType: deal.dealType,
      dropoffTransportMode: deal.dropoffData
        ? deal.dropoffData.transportMode
        : ETransportMode.Shop,
      durationInDays: getDurationInDays(dayjs().toDate(), deal.dealStartDate),
      shouldOverwriteManualFees: true,
      manualFeeDefinitionsArgs:
        getManualFeeDefinitionArgsFromPrivateSaleTransactionArgs(
          transactionsArgs,
          deal,
          deal.items[0],
        ),
      shouldOverwritePayoutAmount:
        deal.dealFinalValues.shouldOverwritePayoutAmount,
      overwrittenPayoutAmount: deal.dealFinalValues.payoutAmount,
      isForCustomer: false,
    })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsArgs])

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="left">{t('date')}</TableCell>
              <TableCell align="left">{t('payment_type')}</TableCell>
              <TableCell align="left">{t('transaction_type')}</TableCell>
              <TableCell align="left">{t('amount.label')}</TableCell>
              <TableCell align="left">{t('description')}</TableCell>
              <TableCell align="left">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(true)}
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsArgs.map((args) => (
              <PrivateSaleTransactionDetails
                key={args.order}
                inputTransactionArgs={args}
                transactionsArgs={transactionsArgs}
                setTransactionsArgs={setTransactionsArgs}
                timezone={timezone}
                item={item}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AddPrivateSaleTransaction
        open={open}
        scroll={scroll}
        handleClose={onClose}
        onConfirm={onConfirm}
        isPrivateSaleCreated={false}
        privateSale={null}
        isEditMode={false} // Before creating the privateSale is also considered as EditMode because the bill is not generated yet
        item={item}
      />
    </Fragment>
  )
}

export default PrivateSaleTransactionsList

const getFeeTypeEquivalentToPrivateSaleTransactionType = (
  transactionType: ETransactionType,
): EFeeType => {
  switch (transactionType) {
    case ETransactionType.LiquidationFee:
      return EFeeType.LiquidationFee
    case ETransactionType.ThirdPartyCosts:
      return EFeeType.ThirdPartyCosts
    case ETransactionType.CashExpenditures:
      return EFeeType.CashExpenditures
    case ETransactionType.OtherFees:
      return EFeeType.Other
    default:
      throw new Error('TransactionType cannot be used as Fee')
  }
}

const listOfTransactionsAsFees = [
  ETransactionType.LiquidationFee,
  ETransactionType.OtherFees,
  ETransactionType.ThirdPartyCosts,
  ETransactionType.CashExpenditures,
]
function getManualFeeDefinitionArgsFromPrivateSaleTransactionArgs(
  transactionsArgs: PrivateSaleTransactionArgs[],
  deal?: Deal | undefined,
  item?: DealItem | undefined,
) {
  const manualFeeDefinitionArgs: ManualFeeDefinitionArgs[] =
    getLatestDealManualFeeDefinitionArgs(deal)

  for (const transArg of transactionsArgs) {
    if (listOfTransactionsAsFees.includes(transArg.transactionType)) {
      manualFeeDefinitionArgs.push({
        amount:
          (transactionsWithUStAffect.includes(transArg.transactionType)
            ? transArg.grossAmount
            : transArg.amount) ?? 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        dealType: deal?.dealType ?? EDealType.Pawn,
        feeType: getFeeTypeEquivalentToPrivateSaleTransactionType(
          transArg.transactionType,
        ),
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: transactionsWithUStAffect.includes(transArg.transactionType)
          ? transArg.ust ?? 0
          : 0,
        date: transArg.date,
        description: transArg.costName,
        storageLabel: item?.storageLabel,
      })
    }
  }
  return manualFeeDefinitionArgs
}

const inFlowTransactions = [
  ETransactionType.SellingCar,
  ETransactionType.CustomerPaymentAfterSale,
]
const outFlowTransactions = [
  ETransactionType.ThirdPartyCosts,
  ETransactionType.CashExpenditures,
  ETransactionType.OtherFees,
  ETransactionType.PayoutExcessAmount,
]
export function isValidCashbookTransaction(
  transaction: PrivateSaleTransactionArgs,
) {
  if (
    (outFlowTransactions.includes(transaction.transactionType) ||
      inFlowTransactions.includes(transaction.transactionType)) &&
    manualPaymentTypesConnectedToCashbook.includes(transaction.paymentType)
  ) {
    return true
  }
  return false
}
