import { compact, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import InputDropdown from '@/redesign/components/InputDropdown/InputDropdown'
import ScreenLoader from '@/redesign/components/ScreenLoader/ScreenLoader'
import { Deal, EBusinessUnit, EDealStatusType, EDealType } from '@/schemaTypes'
import { DealEventType } from '@/types'
import {
  dealEventDTOs,
  isDealConnectedToRegisteredCustomer,
} from '@/utils/deal'
import { oneOf } from '@/utils/misc'

export interface StatusSelectProps {
  loading?: boolean
  selected: DealEventType
  onEventChange: (status: DealEventType) => void
  warnBookedDate?: boolean
  disabled?: boolean
  deal: Deal
}

const StatusDropdown = ({
  selected,
  onEventChange,
  warnBookedDate,
  loading,
  disabled,
  deal,
}: StatusSelectProps) => {
  const { t } = useTranslation()

  const statusOptions = useMemo(() => {
    return [selected, ...getAllowedDealStates(selected, deal)].map(
      (eventType) => ({
        name: dealEventDTOs[eventType],
        value: eventType,
        disabled:
          eventType === 'DealExtensionConfirmedEvent' &&
          deal.dealFinalValues.isOnlineExtensionPrevented &&
          deal.businessUnit === EBusinessUnit.Car &&
          deal.items[0].isContinueUsing,
      }),
    )
  }, [deal, selected])

  return (
    <div className="flex flex-row items-center mb-2">
      <InputDropdown
        value={selected}
        label={t('status')}
        disabled={disabled}
        className="flex flex-col md:flex-row items-start md:items-center mr-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        options={statusOptions}
        optionLabel="name"
        onChange={(e) => {
          const status = e.target.value as DealEventType
          status !== selected && onEventChange(status)
        }}
      />
      <ScreenLoader isLoading={loading} />
      {warnBookedDate && (
        <p className="o-color--warn">{t('warn_booked_date')}!</p>
      )}
    </div>
  )
}

const baseStatuses: DealEventType[] = ['DealVerifiedEvent', 'DealDeclinedEvent']

const getAllowedDealStates = (status: DealEventType, deal: Deal) => {
  if (!isDealConnectedToRegisteredCustomer(deal)) return ['DealDeclinedEvent']

  let statuses: DealEventType[] = []
  switch (status) {
    case 'DealBookedEvent':
      statuses = compact([
        !deal.customer.identityUploadLinks?.length
          ? 'DealItemReceivedIdMissingEvent'
          : null,
        'DealReviewingEvent',
        deal.customer.identityUploadLinks?.length ? 'DealVerifiedEvent' : null,
        'DealDeclinedEvent',
      ])
      break

    case 'DealReviewingEvent':
      statuses = ['DealItemReceivedIdMissingEvent', ...baseStatuses]
      break

    case 'DealItemReceivedIdMissingEvent':
      statuses = baseStatuses
      break
    case 'DealVerifiedEvent':
      statuses = [
        'DealPayedAndStoredEvent',
        'DealExtensionConfirmedEvent',
        'DealPaybackConfirmedEvent',
      ]
      break
    case 'DealPayedAndStoredEvent':
      statuses = ['DealExtensionConfirmedEvent', 'DealPaybackConfirmedEvent']
      break
    case 'DealPayedShipmentPendingEvent':
      statuses = ['DealClosedEvent']
      break
    default:
      if (
        oneOf(status, [
          'DealLoanDueNotifiedEvent',
          'DealLoanDueEvent',
          'DealOnSellEvent',
        ])
      ) {
        statuses = ['DealExtensionConfirmedEvent', 'DealPaybackConfirmedEvent']
      }
      break
  }

  let shouldRemoveVerified = false
  const { identityVerifiedAt, identityVerifiedBy } = deal.customer
  if (
    (deal.status !== EDealStatusType.ExtensionConfirmed &&
      !identityVerifiedAt) ||
    !identityVerifiedBy
  ) {
    // remove DealVerifiedEvent if identity is not verified
    shouldRemoveVerified = true
  }

  // not allow to choose Verifed when Pawn Car and no documents: User Agreement and Power of Attorney
  const { userAgreementDocuments, powerOfAttorneyDocuments } =
    deal.pawnData || {}
  if (
    deal.dealType === EDealType.Pawn &&
    deal.businessUnit === EBusinessUnit.Car
  ) {
    const isContinueUsing = deal.items.some((item) => item.isContinueUsing)
    if (isContinueUsing && isEmpty(userAgreementDocuments)) {
      shouldRemoveVerified = true
    }

    if (isEmpty(powerOfAttorneyDocuments)) {
      shouldRemoveVerified = true
    }
  }

  if (shouldRemoveVerified) {
    statuses = statuses.filter((s) => s !== 'DealVerifiedEvent')
  }

  return statuses
}

export default StatusDropdown
