import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationCreateCashBookCountArgs } from '@/schemaTypes'

export const CREATE_CASH_BOOK_COUNT = gql`
  mutation createCashBookCount($cashBookCountArgs: CashBookCountArgs!) {
    createCashBookCount(cashBookCountArgs: $cashBookCountArgs) {
      ...CashBookCountFragment
    }
  }
  ${fragments.cashBookCount}
`

type TData = { createCashBookCount: Mutation['createCashBookCount'] }
type TVariables = MutationCreateCashBookCountArgs

export function useCreateCashBookCount(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createCashBookCount] = useMutation<TData, TVariables>(
    CREATE_CASH_BOOK_COUNT,
    opts,
  )

  return createCashBookCount
}
