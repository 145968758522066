import styled from '@emotion/styled'
import { Box } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  headers: string[]
}

export function TableHeader({ headers }: Props) {
  const { t } = useTranslation()

  return (
    <HeaderContainer>
      {headers.map((header) => (
        <ItemHeaderBox key={header}>{t(header)}</ItemHeaderBox>
      ))}
    </HeaderContainer>
  )
}

const HeaderContainer = styled(Box)`
  display: flex;
  align-content: center;
  padding: 8px 24px;
  border-bottom: solid 1px #e8e8e8;
`

const ItemHeaderBox = styled(Box)<{ flex?: number }>`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  color: #683ab7c0 !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
`
