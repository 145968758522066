import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationAddCustomDealNoteArgs } from '@/schemaTypes'

const ADD_CUSTOM_DEAL_NOTE = gql`
  mutation addCustomDealNote(
    $customDealId: ObjectId!
    $noteCreateArgs: NoteCreateArgs!
  ) {
    addCustomDealNote(
      customDealId: $customDealId
      noteCreateArgs: $noteCreateArgs
    ) {
      _id
    }
  }
`

type TData = { addNote: Mutation['addCustomDealNote'] }
type TVariables = MutationAddCustomDealNoteArgs

export function useAddCustomDealNote(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [addNote] = useMutation<TData, TVariables>(ADD_CUSTOM_DEAL_NOTE, opts)

  return addNote
}
