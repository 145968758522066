import { IsNotEmpty } from 'class-validator'
import { ResponseTemplate } from '@/schemaTypes'

export class ResponseTemplateValidation {
  @IsNotEmpty()
  name: string

  @IsNotEmpty()
  subject: string

  @IsNotEmpty()
  openingText: string

  @IsNotEmpty()
  editableText: string

  @IsNotEmpty()
  closingText: string

  constructor(responseTemplate: ResponseTemplate) {
    this.name = responseTemplate.name
    this.subject = responseTemplate.subject
    this.openingText = responseTemplate.openingText
    this.editableText = responseTemplate.editableText
    this.closingText = responseTemplate.closingText
  }
}
