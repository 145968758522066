import { CreateCustomerBlacklist_Mutation } from '../../customerBlacklists/helpers/customerBlacklists.graphql'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { Field, FieldProps, Form, Formik } from 'formik'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Panel } from 'primereact/panel'
import { ToggleButton } from 'primereact/togglebutton'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Context } from '@/context'
import { useMutationShowingErrors } from '@/hooks'
import { CustomerBlacklistWarning } from '@/redesign/components/CustomerBlacklistWarning/CustomerBlacklistWarning'
import FormikDatePicker from '@/redesign/components/FormikFields/FormikDatePicker/FormikDatePicker'
import FormikDropdown from '@/redesign/components/FormikFields/FormikDropdown/FormikDropdown'
import FormikInput from '@/redesign/components/FormikFields/FormikInput/FormikInput'
import FormikSwitch from '@/redesign/components/FormikFields/FormikSwitch/FormikSwitch'
import FormikTextarea from '@/redesign/components/FormikFields/FormikTextarea/FormikTextarea'
import { Customer, ESex } from '@/schemaTypes'
import { CustomerArgs } from './CustomerConfiguration'

interface PersonalDetailsPanelProps {
  customer?: Partial<Customer> | null
  maxDate?: Date
  customerArgs: CustomerArgs
}

const PersonalDetailsPanel = ({
  customer,
  maxDate,
  customerArgs,
}: PersonalDetailsPanelProps) => {
  const { t } = useTranslation()
  const { getCurrentUser } = useContext(Context)
  const currentUser = getCurrentUser()

  const [
    openAddCustomerToBlacklistDialog,
    setOpenAddCustomerToBlacklistDialog,
  ] = useState(false)

  const [createCustomerBlacklistMutation] = useMutation(
    CreateCustomerBlacklist_Mutation,
  )

  const createCustomerBlacklist = useMutationShowingErrors({
    mutation: createCustomerBlacklistMutation,
    successMessage: t('add_customer_to_blacklist_successfully'),
  })

  return (
    <Panel header={t('personal_details')} toggleable className="mb-6">
      <CustomerBlacklistWarning
        blacklistInformation={customer?.blacklistInformation}
      />
      {customer?._id && (
        <div className="flex flex-row">
          <FormikInput
            name="customerNumber"
            type="text"
            label={t('customer.customer_number')}
            placeholder={t('customer.customer_number')}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
            disabled={true}
          />
          {!customer.blacklistInformation?.isAddedToBlacklist && (
            <div className="ml-5">
              <Button
                type="button"
                onClick={() => setOpenAddCustomerToBlacklistDialog(true)}
                disabled={!customerArgs?.firstname || !customerArgs?.lastname}
                className="h-10"
                severity="danger"
              >
                <p className="font-semibold">
                  {t('add_customer_to_blacklist')}
                </p>
              </Button>
            </div>
          )}
        </div>
      )}
      {!customer?._id && (
        <FormikInput
          name="password"
          type="text"
          label={t('customer.password')}
          placeholder={t('customer.password')}
          className="flex flex-col md:flex-row items-start md:items-center mb-2"
          labelClassName="text-sm font-semibold w-12.5"
          inputContainerClassName="w-52"
        />
      )}

      <FormikInput
        name="firstname"
        type="text"
        label={t('customer.firstname')}
        placeholder={t('customer.firstname')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <FormikInput
        name="lastname"
        label={t('customer.lastname')}
        placeholder={t('customer.lastname')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <FormikDatePicker
        name="dateOfBirth"
        label={t('customer.date_of_birth')}
        placeholder={t('customer.date_of_birth')}
        maxDate={maxDate}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        showIcon
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-32"
        dateFormat="dd.mm.yy"
      />

      <FormikInput
        name="phone"
        type="text"
        label={t('customer.phone')}
        placeholder={t('customer.phone')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <FormikInput
        name="email"
        type="text"
        label={t('customer.email')}
        placeholder={t('customer.email')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <FormikInput
        name="iban"
        type="text"
        label={t('customer.iban')}
        placeholder={t('customer.iban')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />
      <FormikInput
        name="paypalAddress"
        type="text"
        label={t('paypal_address')}
        placeholder={t('paypal_address')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <div className="flex flex-row items-start">
        <FormikTextarea
          name="note"
          label={t('note')}
          placeholder={t('note')}
          className="flex flex-col md:flex-row items-start mb-2 mr-2"
          labelClassName="text-sm font-semibold w-12.5 pt-1.5"
          inputContainerClassName="w-full md:w-96"
          rows={4}
          cols={20}
        />

        <Field name="isNotePopup">
          {({ field, form }: FieldProps<boolean>) => (
            <div>
              <PopupToggleButton
                onLabel=""
                offLabel=""
                onIcon="pi pi-bell"
                offIcon="pi pi-bell"
                checked={field.value}
                selected={field.value}
                onChange={(e) => {
                  form.setFieldValue('isNotePopup', e.value)
                }}
                className="w-9rem"
              />
            </div>
          )}
        </Field>
      </div>

      <FormikSwitch
        name="isTestUser"
        label={t('test_user')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
      />

      <FormikSwitch
        name="deactivated"
        label={t('deactivated_user')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
      />

      <FormikDropdown
        name="sex"
        label={t('customer.sex')}
        options={sexes}
        placeholder={t('customer.sex')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <FormikDropdown
        name="acquiredBy"
        label={t('acquired_by')}
        options={acquiredBySuggestions}
        placeholder={t('acquired_by')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <Dialog
        header={t('add_customer_to_blacklist')}
        visible={openAddCustomerToBlacklistDialog}
        style={{ width: '50vw' }}
        onHide={() => setOpenAddCustomerToBlacklistDialog(false)}
      >
        <Formik<{ reason: string | null }>
          initialValues={{
            reason: '',
          }}
          validationSchema={Yup.object().shape({
            reason: Yup.string().trim().required('Required'),
          })}
          onSubmit={(values) => {
            if (!values.reason) return

            createCustomerBlacklist({
              variables: {
                args: {
                  firstname: customerArgs.firstname.trim(),
                  lastname: customerArgs.lastname.trim(),
                  email: customerArgs.email.trim(),
                  dateOfBirth: customerArgs.dateOfBirth,
                  reason: values.reason.trim(),
                  customerIds: customer ? [customer._id] : [],
                  employeeId: currentUser?._id,
                },
              },
              onCompleted: () => {
                setOpenAddCustomerToBlacklistDialog(false)
              },
              refetchQueries: ['getCustomer'],
            })
          }}
        >
          {(props) => {
            return (
              <Form noValidate>
                <FormikTextarea
                  name="reason"
                  label={t('reason')}
                  className="flex flex-col md:flex-row items-start gap-20"
                  labelClassName="text-sm font-semibold w-36"
                  inputContainerClassName="w-full h-28"
                  wrapperInputContainerClassName="w-full"
                  errorText={t('required')}
                  autoResize
                  required
                  rows={4}
                  cols={20}
                />
                <div className="flex justify-end mt-3">
                  <div className="flex">
                    <div className="mr-3.5">
                      <Button
                        label={t('cancel')}
                        onClick={() =>
                          setOpenAddCustomerToBlacklistDialog(false)
                        }
                        text
                        severity="secondary"
                        icon="pi pi-times"
                      />
                    </div>
                    <div>
                      <Button
                        disabled={!props.isValid}
                        label={t('block')}
                        severity="danger"
                        type="button"
                        onClick={() => props.submitForm()}
                        icon="pi pi-check"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Dialog>
    </Panel>
  )
}

const acquiredBySuggestions = [
  { label: 'FACEBOOK', value: 'FACEBOOK' },
  { label: 'INSTAGRAM', value: 'INSTAGRAM' },
  { label: 'GOOGLE', value: 'GOOGLE' },
  { label: 'FRIENDS', value: 'FRIENDS' },
  { label: 'FAMILY', value: 'FAMILY' },
  { label: 'PRESS', value: 'PRESS' },
  { label: 'WILLHABEN', value: 'WILLHABEN' },
  { label: 'MEDIA_MARKT', value: 'MEDIA_MARKT' },
  { label: 'GOLD_CO', value: 'GOLD_CO' },
  { label: '2M2M', value: '2M2M' },
  { label: 'BAGGYLUXE', value: 'BAGGYLUXE' },
  { label: 'RADIO', value: 'RADIO' },
  { label: 'GIROMATCH', value: 'GIROMATCH' },
]
const sexes = [
  { label: 'NONE', value: '' },
  { label: 'MALE', value: ESex.Male },
  { label: 'DIVERSE', value: ESex.Diverse },
  { label: 'FEMALE', value: ESex.Female },
]

const PopupToggleButton = styled(ToggleButton)<{ selected?: boolean }>`
  &&& {
    border: 0;
    background: #ffffff;
    border-color: #ffffff;
    color: #ffffff;

    &:not(.p-highlight):hover {
      background: rgba(100, 116, 139, 0.04);
      border-color: transparent;
      color: #64748b;
    }
    &:not(.p-highlight):focus {
      box-shadow: unset;
    }

    &:hover {
      background: #f59e0b;
      border-color: #f59e0b;
      color: #ffffff;
    }

    &:active {
      background: #f59e0b;
      border-color: #f59e0b;
      color: #ffffff;
    }

    &:focus {
      box-shadow: unset;
    }

    .p-button-icon {
      color:;
      color: ${(props) => (props.selected ? '#f59e0b' : '#64748B')};
    }
  }
`

export default PersonalDetailsPanel
