import React, { createContext, useContext, useState } from 'react'
import { ELanguageCode } from '@/schemaTypes'

const TranslationContext = createContext<{
  languages: ELanguageCode[]
  setLanguages: any
}>(undefined)

/* A context to provide a list of active languages as a subset of a total list of languages, which
 * are relevant for a given translation by the user. */
function TranslationContextProvider({ children }) {
  const [languages, setLanguages] = useState<ELanguageCode[]>([
    ELanguageCode.De,
    ELanguageCode.En,
  ])

  return (
    <TranslationContext.Provider value={{ languages, setLanguages }}>
      {children}
    </TranslationContext.Provider>
  )
}

const useTranslationContext = () => {
  const context = useContext(TranslationContext)
  if (!context) throw new Error('Need to be wrapped by TranslationProvider')

  return context
}

export { TranslationContextProvider, TranslationContext, useTranslationContext }
