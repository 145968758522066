import ImportExportProducts from '../../../domains/importExport/components/ImportExportProducts'
import { Buffer } from 'buffer'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as xlsx from 'xlsx'
import client from '@/apollo-worker'
import { Context } from '@/context'
import { useImportProducts } from '@/domains/importExport/hooks/importProducts'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import { useParseErrors } from '@/hooks/useParseErrors'
import {
  CashBookCashFlow,
  Deal,
  EDealStatusType,
  GetMarketingCreditNoteCashBookCashFlowsInTimeRangeDocument,
  GetOtherRevenuesInTimeRangeDocument,
  GetPawnsInTimeRangeDocument,
  GetProductsExportDocument,
  GetPurchasesInTimeRangeDocument,
  GetValorizationEntriesInTimeRangeDocument,
  ImportItemNewInternalPricesArgs,
  ImportItemNewInternalPricesDocument,
  PaginatedProductExport,
  ProductExport,
  QueryGetProductsExportArgs,
  UpdateItemNewInternalPriceArgs,
  ValorizationEntry,
} from '@/schemaTypes'
import fetchAllFromPagination from '@/utils/fetchAllFromPagination'

const updateAbleProductColumnsContent = [
  'timestamp',
  'title',
  'lvl0ContentCategory',
  'lvl1ContentCategory',
  'lvl2ContentCategory',
  'lvl3ContentCategory',
  'pictureFilename',
  'isInactive',
  'maxValue',
  'gewicht',
  'karat',
  'material',
  'ean',
  'slug',
  'isTaxFree',
  'lvl0Category',
  'lvl1Category',
  'lvl2Category',
  'productTags',
]

const updateAbleProductColumnsPrice = ['price']

const createAbleProductColumns = [
  'title',
  'lvl0ContentCategory',
  'lvl1ContentCategory',
  'lvl2ContentCategory',
  'lvl3ContentCategory',
  'pictureFilename',
  'isInactive',
  'maxValue',
  'gewicht',
  'karat',
  'material',
  'ean',
  'slug',
  'isTaxFree',
  'objectID',
  'lvl0Category',
  'lvl1Category',
  'lvl2Category',
  'productTags',
]

interface IRawNewItemInternalPrice {
  'Item ID': string
  'Storage Label': string
  Name: string
  NewValue: number
}

export default function ImportExportProductsContainer() {
  const { showErrors, showInfo, closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()
  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })

  const parseErrors = useParseErrors()

  const [pdfDownloadLoading, setPdfDownloadLoading] = useState<boolean>(false)
  const [productsDownloading, setProductsDownloading] = useState<boolean>(false)
  const [productFileImporting, setProductFileImporting] = useState<
    'price' | 'content' | 'itemInternalPrice' | undefined
  >()
  const [updateProductLoading, setUpdateProductLoading] = useState(false)

  const [productsDiff, setProductsDiff] = useState<any[] | undefined>()

  const importProductsMutation = useMutationShowingErrors({
    mutation: useImportProducts(),
  })

  const handleProductPricesXlsxSelected = async (
    e: any,
    type: 'price' | 'content',
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      setProductFileImporting(type)
      const fileReader = new FileReader()

      fileReader.readAsBinaryString(file)

      const workBook = await new Promise<xlsx.WorkBook>((resolve) => {
        fileReader.onloadend = () => {
          const parsedFile = xlsx.read(fileReader.result, {
            type: 'binary',
          })
          resolve(parsedFile)
        }
      })
      const sheet = workBook.Sheets[workBook.SheetNames[0]]

      const newProductPrices = xlsx.utils.sheet_to_json(sheet, {
        defval: null,
      }) as ProductExport[]

      const oldProductPrices = await fetchAllFromPagination(
        async (paginationArgs) => {
          const res = await client.query<
            { getProductsExport: PaginatedProductExport },
            QueryGetProductsExportArgs
          >({
            query: GetProductsExportDocument,
            fetchPolicy: 'no-cache',
            variables: {
              opts: {
                shouldCreateSlug: false,
                ...paginationArgs,
              },
            },
          })

          return res.data.getProductsExport
        },
        20000,
      )

      const productPricesDiffTemp: any[] = []

      newProductPrices.forEach((newProduct) => {
        const oldProduct = oldProductPrices.find(
          (o) => o._id === newProduct._id,
        )

        try {
          if (oldProduct) {
            const editedColumns = (
              type === 'price'
                ? updateAbleProductColumnsPrice
                : updateAbleProductColumnsContent
            ).filter((column) => {
              return (
                ((oldProduct[column] !== null &&
                  oldProduct[column] !== undefined) ||
                  (newProduct[column] !== null &&
                    newProduct[column] !== undefined)) &&
                oldProduct[column] !== newProduct[column]
              )
            })

            if (editedColumns.length > 0) {
              const columnDiffs = editedColumns.map((column) => {
                return {
                  [`new${column[0].toUpperCase() + column.slice(1)}`]:
                    newProduct[column],
                  [`old${column[0].toUpperCase() + column.slice(1)}`]:
                    oldProduct[column],
                }
              })
              productPricesDiffTemp.push({
                editedColumns: columnDiffs,
                title: oldProduct?.title,
                collectionName: oldProduct?.collectionName,
                _id: oldProduct?._id,
                lastPrice_id: oldProduct.lastPrice_id,
                objectID: oldProduct?.objectID,
              })
            }
          } else {
            if (type === 'price') return

            const editedColumns = createAbleProductColumns.filter((column) => {
              return (
                newProduct[column] !== null && newProduct[column] !== undefined
              )
            })

            if (editedColumns.length > 0) {
              const columnDiffs = editedColumns.map((column) => {
                return {
                  [`new${column[0].toUpperCase() + column.slice(1)}`]:
                    newProduct[column],
                }
              })
              productPricesDiffTemp.push({
                editedColumns: columnDiffs,
                title: newProduct.title,
                objectID: newProduct.objectID,
              })
            }
          }
        } catch (e) {
          console.log('debug', {
            newProductPrice: newProduct,
            e,
          })
        }
      })

      setProductsDiff(productPricesDiffTemp)

      console.log('productPricesDiffTemp', productPricesDiffTemp)

      setProductFileImporting(undefined)
    }
  }

  const handleItemInternalPriceXlsxSelected = async (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      setProductFileImporting('itemInternalPrice')
      const fileReader = new FileReader()

      fileReader.readAsBinaryString(file)

      const workBook = await new Promise<xlsx.WorkBook>((resolve) => {
        fileReader.onloadend = () => {
          const parsedFile = xlsx.read(fileReader.result, {
            type: 'binary',
          })
          resolve(parsedFile)
        }
      })
      const sheet = workBook.Sheets[workBook.SheetNames[0]]

      const newItemInternalPrices = xlsx.utils.sheet_to_json(sheet, {
        defval: null,
      }) as IRawNewItemInternalPrice[]

      const extractedPriceChangeData: UpdateItemNewInternalPriceArgs[] =
        newItemInternalPrices.map((item) => ({
          itemId: item['Item ID'],
          price: item['NewValue'],
        }))

      try {
        await client.mutate<
          { importItemNewInternalPrices: boolean },
          { args: ImportItemNewInternalPricesArgs }
        >({
          mutation: ImportItemNewInternalPricesDocument,
          fetchPolicy: 'no-cache',
          variables: {
            args: {
              updateItemNewInternalPricesArgs: extractedPriceChangeData,
            },
          },
          errorPolicy: 'all',
        })
        showInfo(t('update_successful'))
      } catch (err) {
        const errors = parseErrors(err)

        showErrors(errors)
      }

      setProductFileImporting(undefined)
    }
  }

  const importProducts = async () => {
    if (!productsDiff || productsDiff.length === 0) return null

    console.log('productsDiff', productsDiff)

    showConfirmPopup({
      title: t('really_save_edited_products'),
      action: async () => {
        closeConfirmModal()
        setUpdateProductLoading(true)

        const productData: any[] = productsDiff.map((p) => {
          const updateProduct = {}
          p.editedColumns.forEach((editedColumn) => {
            const newKeyName = Object.keys(editedColumn).find(
              (editedColumnKey) => editedColumnKey.includes('new'),
            )
            if (newKeyName) {
              const keyNameUpperCase = newKeyName.slice(3)
              const keyName =
                keyNameUpperCase[0].toLowerCase() + keyNameUpperCase.slice(1)
              updateProduct[keyName] = editedColumn[newKeyName]
            }
          })
          console.log('updateProduct', updateProduct)

          return {
            _id: p._id,
            lastPrice_id: p.lastPrice_id,
            collectionName: p.collectionName,
            ...updateProduct,
          }
        })

        const res = await importProductsMutation({
          variables: {
            args: {
              updatedProducts: productData.filter((entry) => !!entry._id),
              createdProducts: productData.filter((entry) => !entry._id),
            },
          },
          errorPolicy: 'all',
          fetchPolicy: 'no-cache',
        })

        setUpdateProductLoading(false)

        if (res.data?.importProducts) {
          setProductsDiff(undefined)
          showInfo(t('update_successful'))
        }
      },
    })
  }

  const downloadProducts = async () => {
    setProductsDownloading(true)

    const productPrices = await fetchAllFromPagination(
      async (paginationArgs) => {
        const res = await client.query<
          { getProductsExport: PaginatedProductExport },
          QueryGetProductsExportArgs
        >({
          query: GetProductsExportDocument,
          fetchPolicy: 'no-cache',
          variables: {
            opts: paginationArgs,
          },
        })

        return res.data.getProductsExport
      },
      5_000,
    )

    const propCounts: Record<string, number> = {}

    productPrices.forEach((p, i) => {
      const rowNumber = i + 2
      const propCount = Object.keys(p).length
      if (propCounts[propCount]) propCounts[propCount]++
      else propCounts[propCount] = 1
      ;(p as any).newCurrentMarketValue = {
        t: 'n',
        f: `P${rowNumber} * R${rowNumber} * V${rowNumber}`,
      } // TODO: figure out how to calculate this automatically
    })

    const wb = xlsx.utils.book_new()

    xlsx.utils.book_append_sheet(wb, xlsx.utils.json_to_sheet(productPrices))

    const wbBinary = xlsx.write(wb, {
      type: 'base64',
      compression: true,
    })

    const blob = new Blob([Buffer.from(wbBinary, 'base64') as BlobPart])

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `products-export-${new Date().toISOString()}.xlsx`
    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox

    a.click()
    a.remove()

    setProductsDownloading(false)
  }

  const downloadPdfsInZip = async (
    downloadEntries: { url: string; filename: string }[],
    foldername: string,
  ) => {
    const zip = new JSZip()
    const pawnsFolder = zip.folder(foldername)

    if (pawnsFolder) {
      const downloadPromises = downloadEntries.map(
        (entry) =>
          new Promise<void>((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            xhr.responseType = 'blob'
            xhr.onload = function () {
              const blob = xhr.response
              pawnsFolder.file(`${entry.filename}.pdf`, blob)
              resolve()
            }
            xhr.onerror = function (error) {
              reject(error)
            }
            xhr.open('GET', entry.url)
            xhr.send()
          }),
      )
      Promise.all(downloadPromises)
        .then(() => {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, `${foldername}.zip`)
          })
        })
        .catch((e) => {
          throw new Error(`${t('download_error')} ${foldername}.zip: ${e}`)
        })
    }
  }

  const downloadPawnPdfs = async (
    month: number,
    year: number,
    companyId: string,
    shopId: string,
  ) => {
    const res = await client.query<{ getPawnsInTimeRange: Deal[] }>({
      query: GetPawnsInTimeRangeDocument,
      variables: {
        month: month,
        year: year,
        events: [EDealStatusType.Verified],
        companyId: companyId && companyId.length > 0 ? companyId : undefined,
        shopId: shopId && shopId.length > 0 ? shopId : undefined,
      },
      fetchPolicy: 'network-only',
    })

    const pawnsInTimeRange = res.data.getPawnsInTimeRange

    const downloadEntries = pawnsInTimeRange
      .filter((pawn) => pawn.pawnData?.pawnTicketLink && pawn.bookingNumber)
      .map((pawn) => ({
        url: pawn.pawnData?.pawnTicketLink?.url ?? '',
        filename: `pawn_${pawn.bookingNumber}`,
      }))
    await downloadPdfsInZip(downloadEntries, `pawns_${year}_${month}`)
  }

  const downloadBillPdfs = async (
    month: number,
    year: number,
    companyId: string,
    shopId: string,
  ) => {
    const res = await client.query<{ getPawnsInTimeRange: Deal[] }>({
      query: GetPawnsInTimeRangeDocument,
      variables: {
        month: month,
        year: year,
        events: [
          EDealStatusType.Closed,
          EDealStatusType.PaybackConfirmed,
          EDealStatusType.PayedShipmentPending,
        ],
        companyId: companyId && companyId.length > 0 ? companyId : undefined,
        shopId: shopId && shopId.length > 0 ? shopId : undefined,
      },
      fetchPolicy: 'network-only',
    })

    const pawnsInTimeRange = res.data.getPawnsInTimeRange

    const downloadEntries = pawnsInTimeRange
      .filter((pawn) => pawn.pawnData?.billLink && pawn.pawnData.billNumber)
      .map((pawn) => ({
        url: pawn.pawnData?.billLink?.url ?? '',
        filename: `bill_${pawn.pawnData?.billNumber}`,
      }))
    await downloadPdfsInZip(downloadEntries, `bills_${year}_${month}`)
  }

  const downloadReceiptPdfs = async (
    month: number,
    year: number,
    companyId: string,
    shopId: string,
  ) => {
    const res = await client.query<{ getPurchasesInTimeRange: Deal[] }>({
      query: GetPurchasesInTimeRangeDocument,
      variables: {
        month: month,
        year: year,
        events: [EDealStatusType.Closed],
        companyId: companyId && companyId.length > 0 ? companyId : undefined,
        shopId: shopId && shopId.length > 0 ? shopId : undefined,
      },
      fetchPolicy: 'network-only',
    })

    const purchasesInTimeRange = res.data.getPurchasesInTimeRange

    const downloadEntries = purchasesInTimeRange
      .filter(
        (purchase) =>
          purchase.purchaseData?.receiptLink &&
          purchase.purchaseData.receiptNumber,
      )
      .map((purchase) => ({
        url: purchase.purchaseData?.receiptLink?.url ?? '',
        filename: `receipt_${purchase.purchaseData?.receiptNumber}`,
      }))
    await downloadPdfsInZip(downloadEntries, `receipts_${year}_${month}`)
  }

  const downloadValorizationBillPdfs = async (
    month: number,
    year: number,
    companyId: string,
  ) => {
    const res = await client.query<{
      getValorizationEntriesInTimeRange: ValorizationEntry[]
    }>({
      query: GetValorizationEntriesInTimeRangeDocument,
      variables: {
        month: month,
        year: year,
        companyId: companyId && companyId.length > 0 ? companyId : undefined,
      },
      fetchPolicy: 'network-only',
    })

    const valorizationEntriesInTimeRange =
      res.data.getValorizationEntriesInTimeRange

    const downloadEntries = valorizationEntriesInTimeRange
      .filter((entry) => !!entry.valorizationBillLink)
      .map((entry) => ({
        url: entry.valorizationBillLink?.url ?? '',
        filename: `valorization_bill_${entry.valorizationBillNumber}`,
      }))
    await downloadPdfsInZip(
      downloadEntries,
      `valorization_bills_${year}_${month}`,
    )
  }

  const downloadValorizationCreditNotePdfs = async (
    month: number,
    year: number,
    companyId: string,
  ) => {
    const res = await client.query<{
      getValorizationEntriesInTimeRange: ValorizationEntry[]
    }>({
      query: GetValorizationEntriesInTimeRangeDocument,
      variables: {
        month: month,
        year: year,
        companyId: companyId && companyId.length > 0 ? companyId : undefined,
      },
      fetchPolicy: 'network-only',
    })

    const valorizationEntriesInTimeRange =
      res.data.getValorizationEntriesInTimeRange

    const downloadEntries = valorizationEntriesInTimeRange
      .filter((entry) => !!entry.valorizationCreditNoteLink)
      .map((entry) => ({
        url: entry.valorizationCreditNoteLink?.url ?? '',
        filename: `valorization_credit_note_${entry.valorizationCreditNoteNumber}`,
      }))
    await downloadPdfsInZip(
      downloadEntries,
      `valorization_credit_notes_${year}_${month}`,
    )
  }

  const downloadMarketingCreditNoteCashBookFlowPdfs = async (
    month: number,
    year: number,
    shopId: string,
  ) => {
    const res = await client.query<{
      getMarketingCreditNoteCashBookCashFlowsInTimeRange: CashBookCashFlow[]
    }>({
      query: GetMarketingCreditNoteCashBookCashFlowsInTimeRangeDocument,
      variables: {
        month: month,
        year: year,
        shopId: shopId && shopId.length > 0 ? shopId : undefined,
      },
      fetchPolicy: 'network-only',
    })

    const getMarketingCreditNoteCashBookCashFlowsInTimeRange =
      res.data.getMarketingCreditNoteCashBookCashFlowsInTimeRange

    const downloadEntries = getMarketingCreditNoteCashBookCashFlowsInTimeRange
      .filter((entry) => !!entry.attachment?.url)
      .map((entry) => ({
        url: entry.attachment?.url ?? '',
        filename: `marketing_credit_note_cashbook_flow_${entry.cashFlowNumber}`,
      }))
    await downloadPdfsInZip(
      downloadEntries,
      `marketing_credit_note_cashbook_flows_${year}_${month}`,
    )
  }

  const downloadOtherRevenuesPdfs = async (
    month: number,
    year: number,
    companyId: string,
    shopId: string,
  ) => {
    const result = await client.query<{
      getOtherRevenuesInTimeRange: CashBookCashFlow[]
    }>({
      query: GetOtherRevenuesInTimeRangeDocument,
      variables: {
        month: month,
        year: year,
        companyId: companyId && companyId.length > 0 ? companyId : undefined,
        shopId: shopId && shopId.length > 0 ? shopId : undefined,
      },
      fetchPolicy: 'network-only',
    })

    const { getOtherRevenuesInTimeRange } = result.data
    const downloadEntries = getOtherRevenuesInTimeRange
      ?.filter((entry) => !!entry.attachment?.url)
      ?.map((entry) => ({
        url: entry.attachment?.url ?? '',
        filename: `other_revenues_cashbook_flow_${entry._id}`,
      }))
    await downloadPdfsInZip(
      downloadEntries,
      `other_revenues_cashbook_flow_${year}_${month}`,
    )
  }

  const downloadPdfs = async (
    pdfDownloadType: string,
    pdfDownloadMonth: number,
    pdfDownloadYear: number,
    companyId: string,
    shopId: string,
  ) => {
    setPdfDownloadLoading(true)

    try {
      switch (pdfDownloadType) {
        case 'PAWNS': {
          await downloadPawnPdfs(
            pdfDownloadMonth,
            pdfDownloadYear,
            companyId,
            shopId,
          )
          break
        }
        case 'BILLS': {
          await downloadBillPdfs(
            pdfDownloadMonth,
            pdfDownloadYear,
            companyId,
            shopId,
          )
          break
        }
        case 'VALORIZATION_BILLS': {
          await downloadValorizationBillPdfs(
            pdfDownloadMonth,
            pdfDownloadYear,
            companyId,
          )
          break
        }
        case 'VALORIZATION_CREDIT_NOTES': {
          await downloadValorizationCreditNotePdfs(
            pdfDownloadMonth,
            pdfDownloadYear,
            companyId,
          )
          break
        }
        case 'RECEIPTS': {
          await downloadReceiptPdfs(
            pdfDownloadMonth,
            pdfDownloadYear,
            companyId,
            shopId,
          )
          break
        }

        case 'MARKETING_CREDIT_NOTES': {
          await downloadMarketingCreditNoteCashBookFlowPdfs(
            pdfDownloadMonth,
            pdfDownloadYear,
            shopId,
          )
          break
        }

        case 'OTHER_REVENUE_BILLS': {
          await downloadOtherRevenuesPdfs(
            pdfDownloadMonth,
            pdfDownloadYear,
            companyId,
            shopId,
          )
          break
        }

        default: {
          setPdfDownloadLoading(false)
          throw new Error(
            `${t('unknown_pdf_donwload_type')} ${pdfDownloadType}`,
          )
        }
      }
    } catch (error) {
      console.error(error)
      alert(error)
    } finally {
      setPdfDownloadLoading(false)
    }
  }

  return (
    <ImportExportProducts
      productPrices={productsDiff}
      handleProductPricesXlsxSelected={handleProductPricesXlsxSelected}
      handleItemInternalPriceXlsxSelected={handleItemInternalPriceXlsxSelected}
      downloadPdfs={downloadPdfs}
      pdfDownloadLoading={pdfDownloadLoading}
      productFileImporting={productFileImporting}
      productsDownloading={productsDownloading}
      downLoadProducts={downloadProducts}
      updateProductPrices={importProducts}
      updateProductPricesLoading={updateProductLoading}
    />
  )
}
