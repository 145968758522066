import { MenuItem, Select } from '@material-ui/core'
import React, { Fragment, useContext, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { Context } from '@/context'
import SaveIcon from '@/images/save.svg'
import {
  EItemDerecognizedReason,
  EItemStatusType,
  ItemEvent,
} from '@/schemaTypes'
import { EHintType } from './ItemStatus'
import styles from './ItemStatus.module.scss'

export interface Props {
  onChangeItemStatus: (newEvent: ItemEvent, hint: EHintType) => void
}

const ItemDerecognizedStatus: FunctionComponent<Props> = (props) => {
  const { onChangeItemStatus } = props
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const [derecognizedReason, setDerecognizedReason] =
    useState<EItemDerecognizedReason | null>(null)

  const saveNewStatus = () => {
    if (!derecognizedReason) return showInfo(t('not_sellable_type_is_not_set'))

    const now = new Date()

    onChangeItemStatus(
      {
        createdAt: now,
        timestamp: now,
        event: EItemStatusType.Derecognized,
        meta: {
          derecognizedReason,
        },
      },
      EHintType.NONE,
    )
  }

  return (
    <Fragment>
      <div
        onClick={saveNewStatus}
        className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
      >
        <ReactSVG className={`${styles.saveIcon} u-pl-15`} src={SaveIcon} />
      </div>
      <table className="u-mt-15">
        <tbody>
          <tr>
            <td>
              {t('type')}:
              <Select
                className="u-ml-sm"
                value={derecognizedReason}
                onChange={(e) => {
                  const tempType = e.target.value as EItemDerecognizedReason
                  setDerecognizedReason(tempType)
                }}
              >
                <MenuItem value="">
                  <em>{t('none')}</em>
                </MenuItem>
                {Object.values(EItemDerecognizedReason).map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

export default ItemDerecognizedStatus
