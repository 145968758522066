import { useExchangeDealItem, useUpdateItem } from '../../hooks'
import { CallViviEndpointQuery } from '../../hooks/itemDetails/callViviEndpoint.graphql'
import { useCallViviVinEndpointMutation } from '../../hooks/itemDetails/callViviVinEndpoint'
import { useQueryKSVAssetMutation } from '../../hooks/itemDetails/findKSVAssets'
import { useRefreshItem } from '../../hooks/itemDetails/useRefreshItem'
import styled from '@emotion/styled'
import { Box, Button, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import apollo from '@/apollo'
import Info from '@/components/Info'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectOptionInput from '@/components/SelectOptionInput'
import { Context } from '@/context'
import { useCarDealCalculation } from '@/domains/customDeals/hooks'
import { useUpdatePreviewCalculation } from '@/domains/deals/hooks/updatePreviewCalculation'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import {
  Deal,
  DealItemCreateArgs,
  ECountry,
  EDealType,
  EksvRequestType,
  Item,
  Query,
  QueryCallViviEndpointArgs,
  VehicleData,
} from '@/schemaTypes'
import { getDealValueEntry, getLatestEventForStatus } from '@/utils/deal'
import { getStringBetween, printDateAndTime } from '@/utils/misc'
import styles from './Item.module.scss'
import TextRow from './TextRow'

interface CarIdBlockProps {
  deal: Deal
  item: Item
  vehicleData: VehicleData
  showRequiredField?: boolean
}

interface CarProperty {
  name: string
  selected?: CarOption
  options: CarOption[]
}

interface CarOption {
  name: string
  href: string
}

const definedCarPropertyKeys = [
  'make',
  'model',
  'regdate',
  'body',
  'engine',
  'facelift',
  'seats',
  'transmission',
  'odometer',
]

export interface EditVehiclePropertiesProps {
  item: Item
  deal: Deal
}

const CarIdBlock: React.FC<CarIdBlockProps> = ({
  deal,
  item,
  vehicleData,
  showRequiredField,
}) => {
  const { showInfo, showErrors } = useContext(Context)
  const { t } = useTranslation()
  const refreshItem = useMutationShowingErrors({
    mutation: useRefreshItem(),
  })

  const updatePreviewCalculation = useMutationShowingErrors({
    mutation: useUpdatePreviewCalculation(),
  })

  const updateItem = useMutationShowingErrors({
    mutation: useUpdateItem(),
    successMessage: `${t('item_updated_successfully')}!`,
  })
  const itemId = item._id
  const verifiedEvent = getLatestEventForStatus(deal, 'DealVerifiedEvent')

  const { closeConfirmModal } = useContext(Context)

  const [lastStepHref, setLastStepHref] = useState<string>()
  const [vehicleProperties, setVehicleProperties] = useState<CarProperty[]>([])

  const [questionsLoading, setQuestionsLoading] = useState(false)
  const [isCarLoadViaVin, setIsCarLoadViaVin] = useState(false)

  const odometer = vehicleData.vehicleProperties.odometer

  const itemAnswers =
    item.dealsHistory[item.dealsHistory.length - 1].answers ?? []

  const carDealCalculationRes = useCarDealCalculation({
    variables: {
      args: {
        companyId: deal.company._id,
        href: lastStepHref as string,
        durationInDays: deal.dealFinalValues.durationInDays,
        answers: itemAnswers,
        odometer,
      },
    },
    skip:
      !lastStepHref ||
      !deal.dealFinalValues.durationInDays ||
      !itemAnswers.length ||
      !deal.company._id,
  })

  const valuation =
    carDealCalculationRes.queryResult.data?.carDealCalculation?.valuation
  const competitiveVehiclesForSale =
    carDealCalculationRes.queryResult.data?.carDealCalculation
      ?.competitiveVehiclesForSale

  const newCalculatedPayoutAmount =
    carDealCalculationRes.queryResult.data?.carDealCalculation?.dealValuesEntry
      ?.payoutAmount

  const currentPayoutAmount = getDealValueEntry(
    deal,
    'DealBookedEvent',
  )?.payoutAmount

  const showConfirmPopup = useShowConfirmPopup({
    actionText: t('keep_old_payout_amount'),
    abortActionText: t('apply_new_payout_amount'),
  })

  const [shouldOverwritePayoutAmount, setShouldOverwritePayoutAmount] =
    useState<boolean | null>(null)

  const showPayoutConfirmation =
    newCalculatedPayoutAmount &&
    currentPayoutAmount &&
    newCalculatedPayoutAmount > currentPayoutAmount

  useEffect(() => {
    if (showPayoutConfirmation) {
      showConfirmPopup({
        title: t('higher_payout_possible'),
        component: () => (
          <Typography>
            {t('possible_high_payout_amount_warning', {
              newPayout: newCalculatedPayoutAmount + '€',
              oldPayout: currentPayoutAmount + '€',
            })}
          </Typography>
        ),
        action: async () => {
          setShouldOverwritePayoutAmount(false)
          closeConfirmModal()
        },
        leftButtonAction: () => {
          setShouldOverwritePayoutAmount(true)
          closeConfirmModal()
        },
      })
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    newCalculatedPayoutAmount,
    currentPayoutAmount,
    t,
    showPayoutConfirmation,
  ])

  useEffect(() => {
    if (shouldOverwritePayoutAmount !== null) {
      onSubmit()
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOverwritePayoutAmount])

  useEffect(() => {
    if (
      competitiveVehiclesForSale !== undefined &&
      competitiveVehiclesForSale !== null &&
      competitiveVehiclesForSale < 3
    ) {
      showInfo(t('indicata_competitive_offers_count_warning'))
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitiveVehiclesForSale, t])

  const exchangeDealItem = useMutationShowingErrors({
    mutation: useExchangeDealItem()[0],
  })

  const onSubmit = async () => {
    if (!valuation || !lastStepHref) return

    const definedVehicleProperties: any = {
      odometer,
    }

    const valVehicleProperties = valuation.vehicleProperties

    for (const property of valVehicleProperties) {
      if (definedCarPropertyKeys.includes(property.name)) {
        definedVehicleProperties[property.name] = property.value
      }
    }

    const otherVehicleProperties = valVehicleProperties.filter(
      (c) => !definedCarPropertyKeys.includes(c.name),
    )

    const indicataId = getStringBetween(lastStepHref, '/AT/', '/valuation')

    const dealItemsCreateArgs: DealItemCreateArgs = {
      itemCategoryId:
        item.dealsHistory[item.dealsHistory.length - 1].itemCategoryId,
      answers: itemAnswers,
      customPayoutAmount: valuation.amount,
      title: valVehicleProperties
        .slice(0, 4)
        .map((p) => p.value)
        .join(' '),
      vehicleData: {
        indicataId,
        vin: vehicleData.vin,
        vinLastFetched: new Date(),
        vehicleProperties: {
          ...definedVehicleProperties,
          otherVehicleProperties:
            otherVehicleProperties.length > 0
              ? otherVehicleProperties
                  .filter((p) => p.value)
                  .map((p) => ({ name: p.name, value: p.value as string }))
              : undefined,
        },
      },
    }

    await exchangeDealItem({
      variables: {
        dealId: deal._id,
        newItemArgs: dealItemsCreateArgs,
        oldItemId: item._id,
      },
    })

    if (!shouldOverwritePayoutAmount && deal && currentPayoutAmount) {
      await updatePreviewCalculation({
        variables: {
          dealId: deal._id,
          dealFinalValuesArgs: {
            durationInDays: deal.dealFinalValues.durationInDays,
            shouldOverwritePayoutAmount: true,
            payoutAmount: currentPayoutAmount,
            dealType: deal.dealType,
          },
        },
      })
    }

    showInfo(t('update_successful'))

    refreshItem({
      variables: {
        itemId,
      },
    })
    setLastStepHref(undefined)
    setIsCarLoadViaVin(false)
  }

  const [callViviVinEndpointWithoutError, callViviVinEndpointRes] =
    useCallViviVinEndpointMutation({
      variables: {
        args: {
          vin: vehicleData.vin as string,
        },
      },
      onCompleted(data) {
        if (!data) return
        const nextStep = data.callViviVinEndpoint.nextStep

        if (nextStep?.length > 0) {
          const defaultOption = nextStep.find(
            (option) => option.summary === 'Personenkraftwagen',
          )
          setVehicleProperties([
            {
              name: nextStep[0].rel,
              options: nextStep.map((option) => ({
                name: option.summary,
                href: option.href,
              })),
              selected: defaultOption
                ? { href: defaultOption.href, name: defaultOption.summary }
                : undefined,
            },
          ])

          if (defaultOption) {
            handleOptionSelected(defaultOption.href, 0)
          }
        } else if (data.callViviVinEndpoint.lastStepHref) {
          setLastStepHref(data.callViviVinEndpoint.lastStepHref)
        }
      },
    })

  const [findKSVAssets, findKSVAssetsRes] = useQueryKSVAssetMutation({
    onError: () =>
      showErrors([
        {
          title: t('error.error_title_api_error'),
          description: t('could_not_find_ksv_asset'),
        },
      ]),
    refetchQueries: ['getItemById'],
  })

  const callViviVinEndpoint = useMutationShowingErrors({
    mutation: callViviVinEndpointWithoutError,
  })
  const handleOptionSelected = async (value: string, i: number) => {
    setLastStepHref(undefined)

    setVehicleProperties((old) => {
      const newVehicleProperties = [...old]
      const carProperty = newVehicleProperties[i]
      carProperty.selected = carProperty.options.find((o) => o.href === value)
      return newVehicleProperties
    })

    if (!value) {
      setVehicleProperties((old) => {
        const newVehicleProperties = [...old]
        newVehicleProperties.splice(i + 1)

        return newVehicleProperties
      })
      return
    }
    setQuestionsLoading(true)
    const res = await apollo.query<
      { callViviEndpoint: Query['callViviEndpoint'] },
      QueryCallViviEndpointArgs
    >({
      query: CallViviEndpointQuery,
      variables: {
        args: {
          href: value,
        },
      },
    })
    setQuestionsLoading(false)

    const viviRes = res?.data?.callViviEndpoint
    const nextStep = viviRes.nextStep
    if (!nextStep) return

    if (nextStep.length < 1 && viviRes.lastStepHref) {
      setLastStepHref(viviRes.lastStepHref)
      return
    }

    setVehicleProperties((old) => {
      const newVehicleProperties = [...old]
      newVehicleProperties.splice(i + 1)

      return [
        ...newVehicleProperties,
        {
          name: nextStep[0].rel,
          options: nextStep.map((option) => ({
            name: option.summary,
            href: option.href,
          })),
        },
      ]
    })
  }

  const getKSVRecordInfo = (ksvRecords: Array<IKSVAssetRecord>): string => {
    return `
      <style>
        #ksvRecordsTable * {
          border: 1px solid
        }
      </style>
      <table id="ksvRecordsTable">
        <tr>
          <th>ContractState</th>
          <th>ContractOwner</th>
          <th>ContractType</th>
          <th>ContractNo</th>
          <th>contractStartDate</th>
          <th>contractEndDate</th>
        </tr>
        ${ksvRecords
          .map(
            (record) => `
          <tr>
            <td>${record.contractState ?? ''}</td>
            <td>${record.contractOwner ?? ''}</td>
            <td>${record.contractType ?? ''}</td>
            <td>${record.contractNo ?? ''}</td>
            <td>${record.contractStartDate ?? ''}</td>
            <td>${record.contractEndDate ?? ''}</td>
          </tr>
        `,
          )
          .join('')}
      </table>
    `
  }

  const ksvFindAssetHistory = item.vehicleData?.ksvQueryHistories?.find(
    (history) => history.type === EksvRequestType.FindAssets,
  )

  return (
    <Box>
      <TextRow
        label={
          <span>
            {t('vin')}
            {showRequiredField && <span className={styles.alert}>*</span>}
          </span>
        }
        defaultValue={vehicleData.vin ?? ''}
        onChange={(v) => {
          updateItem({
            variables: { args: { _id: itemId, vehicleData: { vin: v } } },
          })
        }}
        addionalColumns={
          <>
            <td style={{ minWidth: '200px', paddingLeft: '1rem' }}>
              <Typography>
                {t('vin_last_fetched') +
                  ' ' +
                  (vehicleData.vinLastFetched
                    ? printDateAndTime(vehicleData.vinLastFetched)
                    : '')}
              </Typography>
            </td>
            {vehicleData.vin && !verifiedEvent && (
              <td style={{ minWidth: '120px', paddingLeft: '1rem' }}>
                <Box display="flex" flexDirection="column">
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (!vehicleData.vin) return
                      callViviVinEndpoint({})
                      setIsCarLoadViaVin(true)
                    }}
                  >
                    {t('fetch_car_via_vin')}
                  </Button>
                </Box>
              </td>
            )}
          </>
        }
      />
      {deal.dealType === EDealType.Pawn &&
        vehicleData.vin &&
        item.company.headquarter?.countryCode === ECountry.At && (
          <Box>
            <td>
              <Typography>{t('ksv_inquiry')}</Typography>
            </td>
            {findKSVAssetsRes.loading ? (
              <td>
                <LoadingSpinnerWrapper>
                  <LoadingSpinner />
                </LoadingSpinnerWrapper>
              </td>
            ) : (
              <>
                {ksvFindAssetHistory?.meta?.assets && (
                  <>
                    {ksvFindAssetHistory.meta.assets.length ? (
                      <td>
                        <Typography
                          color="error"
                          style={{ marginLeft: '1rem' }}
                        >
                          {t('ksv_record_found', {
                            queryAt: dayjs(ksvFindAssetHistory.queryAt).format(
                              'DD.MM.YYYY HH:mm',
                            ),
                          })}
                          <Info
                            className={'o-media__fixed u-inline-block'}
                            svgClassName={`${styles.infoIcon} u-pl-5 u-pr-5`}
                            infoText={getKSVRecordInfo(
                              ksvFindAssetHistory.meta.assets,
                            )}
                          />
                        </Typography>
                      </td>
                    ) : (
                      <td>
                        <Typography
                          style={{ color: 'green', marginLeft: '1rem' }}
                        >
                          {t('no_ksv_record', {
                            VIN: ksvFindAssetHistory.vin,
                            queryAt: dayjs(ksvFindAssetHistory.queryAt).format(
                              'DD.MM.YYYY HH:mm',
                            ),
                          })}
                        </Typography>
                      </td>
                    )}
                  </>
                )}
              </>
            )}
            {!verifiedEvent && (
              <td style={{ minWidth: '120px', paddingLeft: '1rem' }}>
                <Box display="flex" flexDirection="column">
                  <Button
                    variant="contained"
                    onClick={() => {
                      findKSVAssets({ variables: { itemId } })
                    }}
                  >
                    {t('ksv_inquiry')}
                  </Button>
                </Box>
              </td>
            )}
          </Box>
        )}
      {callViviVinEndpointRes.loading && (
        <Box textAlign="left">
          <LoadingSpinner />
        </Box>
      )}
      {isCarLoadViaVin && callViviVinEndpointRes.data && (
        <Box mt="0rem">
          {callViviVinEndpointRes.data.callViviVinEndpoint.nextStep.length >
            0 && (
            <Typography className="u-pt-sm">
              Wähle die richtige Konfiguration
            </Typography>
          )}
          <Box marginY="2rem">
            <Box marginLeft="3rem">
              {vehicleProperties.map((carOption, i) => {
                return (
                  <Box marginBottom="1rem">
                    <SelectOptionInput
                      hasNone
                      value={carOption.selected?.href ?? ''}
                      label={t(`car_${carOption.name}`)} // t(`common:car.${carOption.name}`)
                      menuItems={carOption.options.map((option) => ({
                        label: option.name,
                        value: option.href,
                      }))}
                      onChange={(value) => {
                        handleOptionSelected(value, i)
                      }}
                    />
                  </Box>
                )
              })}

              {questionsLoading && (
                <Box textAlign="left">
                  <LoadingSpinner />
                </Box>
              )}

              {valuation && !showPayoutConfirmation && (
                <Box>
                  <Box>Der aktuelle Preis des Auto ist {valuation.amount}</Box>
                  <Button
                    style={{ marginTop: '1rem' }}
                    variant="contained"
                    onClick={onSubmit}
                  >
                    {t('save')}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

const LoadingSpinnerWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`

interface IKSVAssetDetail {
  category: string
  identificationNo1: string
  brand: string
  model: string
  type: string
}

interface IKSVAssetRecord {
  asset?: IKSVAssetDetail
  contractNo?: string
  contractOwner?: string
  contractState?: string
  contractType?: string
  contractStartDate?: string
  contractEndDate?: string
}

export default CarIdBlock
