import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeactivateStorageFacilityArgs } from '@/schemaTypes'

const DELETE_STORAGE_FACILITY = gql`
  mutation deactivateStorageFacility($storageFacilityId: ObjectId!) {
    deactivateStorageFacility(storageFacilityId: $storageFacilityId) {
      _id
    }
  }
`

type TData = {
  deactivateStorageFacility: Mutation['deactivateStorageFacility']
}
type TVariables = MutationDeactivateStorageFacilityArgs

export function useDeactivateStorageFacility(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deactivateStorageFacility] = useMutation<TData, TVariables>(
    DELETE_STORAGE_FACILITY,
    opts,
  )

  return deactivateStorageFacility
}
