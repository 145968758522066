import { Checkbox, CircularProgress } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import { KeyboardDatePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import { useState } from 'react'
import { ensureDate } from '@/utils/misc'
import styles from './TableValue.module.scss'

export const TableValue = ({
  idPrefix,
  label,
  value,
  formatFunc = (value) => value,
  onChange,
  addPaddingRight = false,
  addPaddingLeft = false,
  type = 'string', // Note: This is not the HTML input type.
  minDate,
  minValue,
  maxValue,
  showLoadingAnimationForNullValues = false,
  options,
  textAlign = 'right',
  inputBaseSize = 8,
  inputWidth = undefined,
  disabled = false,
  disableFuture,
  bold = false,
}: any) => {
  // Supported input types: https://www.w3schools.com/tags/att_input_type.asp
  const inputType = ['number', 'text'].includes(type) ? type : 'text'
  let [formattedValue, setFormattedValue] = useState(
    showLoadingAnimationForNullValues && value === null
      ? value
      : formatFunc(value),
  )
  const [inactive, setInactive] = useState(true)

  if (showLoadingAnimationForNullValues === true && value === null) {
    return <CircularProgress className="u-mt-5" size={20} />
  }

  if (type === 'integer') {
    if (parseInt(formattedValue) !== value) {
      if (inactive) {
        formattedValue = formatFunc(value)
      }
    }
  }

  if (type === 'float') {
    if (parseFloat(formattedValue) !== value) {
      if (inactive) {
        formattedValue = formatFunc(value)
      }
    }
  }

  if (type === 'string') {
    if (formattedValue !== value) {
      if (inactive) {
        formattedValue = formatFunc(value)
      }
    }
  }

  if (onChange) {
    if (options && options.length > 0) {
      return (
        <Select
          id={`${idPrefix ? idPrefix : ''}${label}`}
          className={styles.tableSelect}
          native
          value={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
        >
          {options.map((option) => (
            <option
              key={`${option.label}_${option.value}`}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </Select>
      )
    }

    if (type === 'date') {
      return (
        <KeyboardDatePicker
          id={`${idPrefix ? idPrefix : ''}${label}`}
          className={styles.datePicker}
          clearable
          format="DD.MM.YYYY"
          value={value}
          onChange={onChange || (() => {})}
          InputAdornmentProps={{ position: 'start' }}
          minDate={minDate}
          disabled={disabled}
          disableFuture={disableFuture}
        />
      )
    }

    if (type === 'boolean') {
      return (
        <Checkbox
          id={`${idPrefix ? idPrefix : ''}${label}`}
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
      )
    }

    const width = inputWidth
      ? inputWidth
      : (formattedValue ? formattedValue.toString() : '').length *
          inputBaseSize +
        inputBaseSize * (bold ? 1.6 : 1.25)

    return (
      <Input
        id={`${idPrefix ? idPrefix : ''}${label}`}
        type={inputType}
        style={{ width: `${width}px` }}
        inputProps={{
          style: bold ? { fontSize: '16px', fontWeight: 600 } : {},
        }}
        className={`${styles.tableInput} ${textAlign === 'left' ? styles.tableInputLeft : textAlign === 'right' ? styles.tableInputRight : ''}`}
        value={formattedValue}
        disabled={disabled}
        onChange={(e: any) => {
          let changedValueStr = e.target.value
          let changedValue = changedValueStr

          if (type === 'float') {
            changedValueStr = changedValueStr.replace(/,/g, '.')
            changedValueStr = changedValueStr.replace(/[^\d.-]/g, '')

            changedValue = parseFloat(changedValueStr) as any

            if (isNaN(changedValue)) {
              changedValue = null
            }
          }

          if (type === 'integer') {
            changedValueStr = changedValueStr.replace(/[^\d]/g, '')

            changedValue = parseInt(changedValueStr) as any

            if (isNaN(changedValue)) {
              changedValue = null
            }
          }

          onChange(changedValue)
          setFormattedValue(changedValueStr)
          setInactive(false)
        }}
        onBlur={(e: any) => {
          let changedValueStr = e.target.value
          let changedValue = changedValueStr

          if (type === 'float') {
            changedValueStr = changedValueStr.replace(/,/g, '.')
            changedValueStr = changedValueStr.replace(/[^\d.-]/g, '')

            changedValue = parseFloat(changedValueStr)

            if (typeof minValue !== 'undefined' && changedValue < minValue) {
              changedValue = minValue
            }

            if (typeof maxValue !== 'undefined' && changedValue > maxValue) {
              changedValue = maxValue
            }

            if (isNaN(changedValue)) {
              changedValue = null
            }
          }

          if (type === 'integer') {
            changedValueStr = changedValueStr.replace(/[^\d]/g, '')

            changedValue = parseInt(changedValueStr)

            if (typeof minValue !== 'undefined' && changedValue < minValue) {
              changedValue = minValue
            }

            if (typeof maxValue !== 'undefined' && changedValue > maxValue) {
              changedValue = maxValue
            }

            if (isNaN(changedValue)) {
              changedValue = null
            }
          }

          if (changedValue !== value) {
            onChange(changedValue)
          }

          setFormattedValue(formatFunc(changedValue))
          setInactive(true)
        }}
      />
    )
  } else {
    if (type === 'boolean') {
      return (
        <Checkbox
          id={`${idPrefix ? idPrefix : ''}${label}`}
          checked={value}
          disabled={true}
        />
      )
    }

    if (type === 'date') {
      value = value ? dayjs(ensureDate(value)).format('DD.MM.YYYY') : '-'
    }

    return (
      <div
        id={`${idPrefix ? idPrefix : ''}${label}`}
        className={`${addPaddingRight && 'u-pr-5'} ${addPaddingLeft && 'u-pl-5'} u-inline-block`}
      >
        {formatFunc(value)}
      </div>
    )
  }
}

export default TableValue
