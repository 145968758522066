import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationRemoveShopIdArgs } from '@/schemaTypes'

const REMOVE_SHOP_ID = gql`
  mutation removeShopId($employeeId: ObjectId!, $shopId: ObjectId!) {
    removeShopId(employeeId: $employeeId, shopId: $shopId) {
      _id
      firstname
      lastname
      shopIds
    }
  }
`

type TData = { removeShopId: Mutation['removeShopId'] }
type TVariables = MutationRemoveShopIdArgs

export function useRemoveShopId(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [removeShopId] = useMutation<TData, TVariables>(REMOVE_SHOP_ID, opts)

  return removeShopId
}
