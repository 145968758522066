import styled from '@emotion/styled'
import InfoIcon from '@material-ui/icons/Info'
import ViewListIcon from '@material-ui/icons/ViewList'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import * as React from 'react'
import { Fragment } from 'react'
import { FunctionComponent } from 'react'
import { useState } from 'react'

interface UsedFeeDefinitionViewerWrapperProps {
  showLayoutButton: boolean
  defaultLayout: 'list' | 'module'
  showItemDetailsButton: boolean // This is just for list view
  itemRecordsButtonDefaultValue?: boolean // This is just for list view
  onChangeView?: (view: 'list' | 'module') => void
  onChangeItemRecordsView?: (itemRecordsView: boolean) => void
}

const UsedFeeDefinitionViewerWrapper: FunctionComponent<
  UsedFeeDefinitionViewerWrapperProps
> = (props) => {
  const {
    showLayoutButton,
    defaultLayout,
    showItemDetailsButton,
    itemRecordsButtonDefaultValue,
    children,
    onChangeView,
    onChangeItemRecordsView,
  } = props

  const [view, setView] = useState(defaultLayout)
  const [itemRecordsView, setItemRecordsView] = useState(
    itemRecordsButtonDefaultValue ?? false,
  )

  const handleViewChange = (view, nextView) => {
    setView(nextView)
  }

  React.useEffect(() => {
    onChangeView?.(view)
  }, [view, onChangeView])

  React.useEffect(() => {
    onChangeItemRecordsView?.(itemRecordsView)
  }, [itemRecordsView, onChangeItemRecordsView])

  return (
    <Fragment>
      <div
        style={{
          textAlign: 'left',
        }}
      >
        <StyledToolBar>
          {showLayoutButton && (
            <ToggleButtonGroup
              orientation="horizontal"
              size="small"
              value={view}
              exclusive
              onChange={handleViewChange}
            >
              <ToggleButton value="module" aria-label="module">
                <ViewModuleIcon />
              </ToggleButton>
              <ToggleButton value="list" aria-label="list">
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </StyledToolBar>
        {view === 'list' && showItemDetailsButton ? (
          <StyledToolBar style={{ verticalAlign: 'top' }}>
            <ToggleButton
              value={itemRecordsView}
              size="small"
              selected={itemRecordsView}
              onChange={() => {
                setItemRecordsView(!itemRecordsView)
              }}
            >
              <InfoIcon />
            </ToggleButton>
          </StyledToolBar>
        ) : null}
      </div>

      {children}
    </Fragment>
  )
}

const StyledToolBar = styled.div`
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
`

export default UsedFeeDefinitionViewerWrapper
