import { useGetPropertyDefinitionsSortPriorities } from '../hooks/getPropertyDefinitionsSortPriorities'
import { Button, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { useFormik } from 'formik'
import { uniq } from 'lodash'
import { Checkbox } from 'primereact/checkbox'
import { Tooltip } from 'primereact/tooltip'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import CategorySelector from '@/components/CategorySelector'
import {
  includeHidedCategories,
  useGetItemCategories,
} from '@/domains/itemCategories/hooks'
import { EProductProperyDefinitionSuffix } from '@/domains/productPropertyDefinitions/components/enums'
import { useCreateProductPropertyDefinition } from '@/domains/productPropertyDefinitions/hooks/createProductPropertyDefinition'
import { useGetProductPropertyDefinition } from '@/domains/productPropertyDefinitions/hooks/getProductPropertyDefinition'
import { useUpdateProductPropertyDefinition } from '@/domains/productPropertyDefinitions/hooks/updateProductPropertyDefinition'
import { useMutationShowingErrors } from '@/hooks'
import BreadCrumbBar from '@/redesign/components/BreadCrumbBar/BreadCrumbBar'
import { Label } from '@/redesign/components/FieldWrapper/FieldWrapper'
import Input from '@/redesign/components/Input/Input'
import InputDropdown from '@/redesign/components/InputDropdown/InputDropdown'
import InputNum from '@/redesign/components/InputNumber/InputNumber'
import Textarea from '@/redesign/components/Textarea/Textarea'
import {
  ELanguageCode,
  EOrderBy,
  EProductPropertyType,
  MultipleLanguageTextArgs,
  MutationCreateProductPropertyDefinitionArgs,
  MutationUpdateProductPropertyDefinitionArgs,
} from '@/schemaTypes'
import { LanguagePicker } from './LanguagePicker'

interface Props {
  id?: string
}

type MutationCreateOrUpdateProductPropertyDefinitionArgs =
  | MutationCreateProductPropertyDefinitionArgs
  | MutationUpdateProductPropertyDefinitionArgs

const typeMapping: Record<
  number,
  {
    type: EProductPropertyType
    suffix?: EProductProperyDefinitionSuffix
    orderBy?: EOrderBy
  }
> = {
  0: { type: EProductPropertyType.Translation },
  1: {
    type: EProductPropertyType.Number,
    suffix: EProductProperyDefinitionSuffix.Gb,
  },
  2: {
    type: EProductPropertyType.Number,
    suffix: EProductProperyDefinitionSuffix.Cm,
  },
  3: {
    type: EProductPropertyType.Number,
    suffix: EProductProperyDefinitionSuffix.Inch,
  },
  4: {
    type: EProductPropertyType.Number,
    suffix: EProductProperyDefinitionSuffix.G,
  },
  5: {
    type: EProductPropertyType.Number,
    suffix: EProductProperyDefinitionSuffix.Ounce,
    orderBy: EOrderBy.Desc,
  },
  6: { type: EProductPropertyType.Boolean },
  7: { type: EProductPropertyType.Number },
}

export function ProductPropertyDefinitionDetails(props: Props) {
  const { t } = useTranslation()
  const [initialCategorieIds, setInitialCategorieIds] = useState([])
  const [languages, setLanguages] = useState([
    ELanguageCode.De,
    ELanguageCode.En,
  ])

  const history = useHistory()
  const { id } = props

  const { itemCategories } = useGetItemCategories(includeHidedCategories)

  const { productPropertyDefinition, queryResult } =
    useGetProductPropertyDefinition({
      variables: {
        productPropertyDefinitionId: id,
      },
      skip: !id,
    })

  const { propertyDefinitionsSortPriorities } =
    useGetPropertyDefinitionsSortPriorities()

  const createProductPropertyDefinition = useMutationShowingErrors({
    mutation: useCreateProductPropertyDefinition(),
    hideSuccessMessage: true,
  })

  const updateProductPropertyDefinition = useMutationShowingErrors({
    mutation: useUpdateProductPropertyDefinition(),
    successMessage: t('product_property_definition.save_success'),
  })

  const typeOptions = useMemo(() => {
    return [
      { label: `${t('translated_text')}`, value: 0 },
      {
        label: `${t('product_property_definition.storage')} (${EProductProperyDefinitionSuffix.Gb})`,
        value: 1,
      },
      {
        label: `${t('product_property_definition.size')} (${EProductProperyDefinitionSuffix.Cm})`,
        value: 2,
      },
      {
        label: `${t('product_property_definition.size')} (${EProductProperyDefinitionSuffix.Inch})`,
        value: 3,
      },
      {
        label: `${t('weight')} (${EProductProperyDefinitionSuffix.G})`,
        value: 4,
      },
      {
        label: `${t('weight')} (${EProductProperyDefinitionSuffix.Ounce})`,
        value: 5,
      },
      { label: `${t('boolean')}`, value: 6 },
      { label: `${t('number')}`, value: 7 },
    ]
  }, [t])

  const initialDefinitionType = useMemo(() => {
    if (!productPropertyDefinition) return null

    const type = productPropertyDefinition.type
    const suffix = productPropertyDefinition.valueFormatting?.suffix

    if (type === EProductPropertyType.Translation) return 0
    if (
      type === EProductPropertyType.Number &&
      suffix === EProductProperyDefinitionSuffix.Gb
    )
      return 1
    if (
      type === EProductPropertyType.Number &&
      suffix === EProductProperyDefinitionSuffix.Cm
    )
      return 2
    if (
      type === EProductPropertyType.Number &&
      suffix === EProductProperyDefinitionSuffix.Inch
    )
      return 3
    if (
      type === EProductPropertyType.Number &&
      suffix === EProductProperyDefinitionSuffix.G
    )
      return 4
    if (
      type === EProductPropertyType.Number &&
      suffix === EProductProperyDefinitionSuffix.Ounce
    )
      return 5
    if (type === EProductPropertyType.Boolean) return 6

    if (type === EProductPropertyType.Number) return 7

    return null
  }, [productPropertyDefinition])

  const productPropertyDefinitionValidationSchema = useMemo(
    () =>
      buildFormDataValidationSchema(
        propertyDefinitionsSortPriorities,
        productPropertyDefinition?.sortPriority,
      ),
    [
      propertyDefinitionsSortPriorities,
      productPropertyDefinition?.sortPriority,
    ],
  )

  const [prevCategoryIds, setPrevCategoryIds] = useState(
    productPropertyDefinition?.categoryIds,
  )
  if (productPropertyDefinition?.categoryIds !== prevCategoryIds) {
    setPrevCategoryIds(productPropertyDefinition?.categoryIds)
    setInitialCategorieIds(productPropertyDefinition?.categoryIds)
  }

  const formik = useFormik<ProductPropertyDefinitionFormData>({
    initialValues: {
      key: productPropertyDefinition?.key,
      name: productPropertyDefinition?.name?.allTranslations ?? [],
      idealoName: productPropertyDefinition?.idealoName,
      typeIndex: initialDefinitionType ?? 0,
      impactsLook: productPropertyDefinition?.impactsLook,
      showOnWebsite: productPropertyDefinition?.showOnWebsite,
      categoryIds: productPropertyDefinition?.categoryIds,
      sortPriority: productPropertyDefinition?.sortPriority,
      description:
        productPropertyDefinition?.description?.allTranslations ?? [],
    },
    enableReinitialize: true,
    validationSchema: productPropertyDefinitionValidationSchema,
    onSubmit: (values, actions) => {
      const {
        key,
        name,
        categoryIds,
        typeIndex,
        impactsLook,
        showOnWebsite,
        idealoName,
        sortPriority,
        description,
      } = values

      if (!key || !name || typeof typeIndex === 'undefined') {
        return
      }

      const valueFormatting: {
        suffix?: string
        orderBy?: EOrderBy
      } = {}
      if (typeMapping[typeIndex].suffix)
        valueFormatting.suffix = typeMapping[typeIndex].suffix
      if (typeMapping[typeIndex].orderBy)
        valueFormatting.orderBy = typeMapping[typeIndex].orderBy

      const variables: MutationCreateOrUpdateProductPropertyDefinitionArgs = {
        args: {
          key,
          name: name as MultipleLanguageTextArgs[],
          idealoName,
          impactsLook: impactsLook ?? false,
          showOnWebsite: showOnWebsite ?? true,
          categoryIds: uniq(categoryIds),
          type: typeMapping[typeIndex].type,
          valueFormatting,
          sortPriority,
          description: description as MultipleLanguageTextArgs[],
        },
      }

      if (id) {
        updateProductPropertyDefinition({
          variables: {
            args: {
              ...variables.args,
              _id: id,
            },
          },
          onCompleted: () => {
            actions.setSubmitting(false)
            queryResult.refetch()
          },
        })
        return
      }
      createProductPropertyDefinition({
        variables,
        onCompleted: () => {
          actions.setSubmitting(false)
          history.push(`/inApp/product-property-definitions`)
        },
      })
    },
  })

  const { values, setFieldValue, errors } = formik

  const currentDefaultCategories = itemCategories.filter((category) =>
    values.categoryIds?.includes(category._id),
  )

  return (
    <div>
      <Tooltip target=".custom-target-icon" />
      <BreadCrumbBar />

      <div className="flex flex-col md:flex-row mb-4 mt-4">
        <Typography variant="h5" gutterBottom>
          {t('product_property_definition.title_details', {
            propertyName: values.key,
          })}
        </Typography>
      </div>

      <LanguagePicker
        languages={languages}
        setLanguages={setLanguages}
        className="mb-8"
      />

      <Input
        className="flex flex-col md:flex-row items-start md:items-center mb-4"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        value={values.key}
        onChange={(e) => setFieldValue('key', e.target.value)}
        label={t('product_property_definition.property_name')}
        isInvalid={!!errors.key}
        errorText={t(`${errors.key}`)}
      />

      <Input
        className="flex flex-col md:flex-row items-start md:items-center mb-4"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        value={values.idealoName}
        onChange={(e) => setFieldValue('idealoName', e.target.value.trim())}
        label={t('product_property_definition.idealo_name')}
        isInvalid={!!errors.idealoName}
        errorText={t(`${errors.idealoName}`)}
      />

      <div className="flex flex-col md:flex-row">
        <Label className="text-sm font-semibold w-12.5 mt-1">
          {t('product_property_definition.display_name')}
        </Label>

        <div className="flex flex-col gap-10 md:flex-row">
          {languages.map((language) => {
            return (
              <Input
                key={language}
                className="mb-4"
                labelClassName=" ml-1 text-sm font-semibold"
                inputContainerClassName="w-52"
                value={
                  values?.name?.find((elem) => elem.languageCode === language)
                    ?.text
                }
                onChange={(e) => {
                  if (
                    !values.name.find((entry) => entry.languageCode == language)
                  ) {
                    setFieldValue(`name`, [
                      ...values.name,
                      {
                        languageCode: language,
                        text: e.target.value,
                      },
                    ])
                    return
                  }
                  setFieldValue(
                    'name',
                    values.name.map((entry) => {
                      if (entry.languageCode === language)
                        return {
                          languageCode: language,
                          text: e.target.value,
                        }
                      return entry
                    }),
                  )
                }}
                label={t(`language_label.${language}`)}
                isInvalid={!!errors.name?.[language]}
                errorText={t(`${errors.name?.[language]}`)}
              />
            )
          })}
        </div>
      </div>

      <div className="flex flex-col md:flex-row">
        <Label className="text-sm font-semibold w-12.5 mt-1">
          {t('product_property_definition.description')}
        </Label>

        <div className="flex flex-col gap-1 md:flex-row">
          {languages.map((language) => {
            return (
              <Textarea
                className="mb-4"
                labelClassName=" ml-1 text-sm font-semibold"
                inputContainerClassName="w-52"
                value={
                  values?.description?.find(
                    (elem) => elem.languageCode === language,
                  )?.text
                }
                onChange={(e) => {
                  if (
                    !values.description.find(
                      (entry) => entry.languageCode == language,
                    )
                  ) {
                    setFieldValue(`description`, [
                      ...values.description,
                      {
                        languageCode: language,
                        text: e.target.value,
                      },
                    ])
                    return
                  }
                  setFieldValue(
                    'description',
                    values.description.map((entry) => {
                      if (entry.languageCode === language)
                        return {
                          languageCode: language,
                          text: e.target.value,
                        }
                      return entry
                    }),
                  )
                }}
                label={t(`language_label.${language}`)}
                isInvalid={!!errors.description}
                errorText={t(`${errors.description}`)}
                tooltipText={t(
                  'product_property_definition.description_tooltip',
                )}
                tooltipIcon={'pi pi-question-circle'}
              />
            )
          })}
        </div>
      </div>

      <InputNum
        mode="decimal"
        maxFractionDigits={0}
        value={values.sortPriority}
        label={t('product_property_definition.sort_priority')}
        className="flex flex-col md:flex-row items-start md:items-center mb-4"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        onChange={(e) => setFieldValue('sortPriority', e.value)}
        tooltipText={t('product_property_definition.sort_priority_tooltip')}
        tooltipIcon={'pi pi-question-circle'}
        isInvalid={!!errors.sortPriority}
        errorText={t(`${errors.sortPriority}`)}
      />

      <InputDropdown
        label={t('type')}
        options={typeOptions}
        value={values.typeIndex}
        onChange={(e) => setFieldValue('typeIndex', e.target.value)}
        className="flex flex-col md:flex-row items-start md:items-center mb-6"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        isInvalid={!!errors.typeIndex}
        errorText={t(`${errors.typeIndex}`)}
        disabled={productPropertyDefinition?.isInUse}
      />

      <div className="flex flex-col md:flex-row mb-4">
        <Label className="text-sm font-semibold w-12.5 mt-1">
          {t('categories')}
        </Label>

        <Typography variant="h6" gutterBottom className="u-flex u-ai-center" />
        <CategorySelector
          multiple
          hideLabel
          categories={itemCategories}
          onChange={(itemCategoryIds) =>
            setFieldValue('categoryIds', itemCategoryIds)
          }
          currentCategories={currentDefaultCategories}
          disableClear={productPropertyDefinition?.isInUse}
          initialCategorieIds={initialCategorieIds}
        />
        {typeof errors.categoryIds === 'string' && (
          <Typography variant="caption" color="error">
            {t(errors.categoryIds)}
          </Typography>
        )}
      </div>

      <div className="flex flex-col md:flex-row mb-4">
        <Label className="text-sm font-semibold w-12.5">
          {t('product_property_definition.impacts_look')}
        </Label>

        <Checkbox
          onChange={(e) => setFieldValue('impactsLook', e.checked)}
          checked={values.impactsLook ?? false}
        />
      </div>

      <div className="flex flex-col md:flex-row mb-4">
        <Label className="text-sm font-semibold w-12.5">
          {t('product_property_definition.is_visible_on_website')}
        </Label>

        <Checkbox
          onChange={(e) => setFieldValue('showOnWebsite', e.checked)}
          checked={values.showOnWebsite ?? true}
        />
      </div>

      <div>
        <Button
          className="!mt-4"
          color="primary"
          variant="contained"
          startIcon={<CheckIcon />}
          onClick={formik.submitForm}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {t('save')}
        </Button>
      </div>
    </div>
  )
}

type ProductPropertyDefinitionFormData = Yup.InferType<
  ReturnType<typeof buildFormDataValidationSchema>
>

const buildFormDataValidationSchema = (
  propertyDefinitionsSortPriorities: number[],
  existingPropertySortPriority: number,
) => {
  return Yup.object().shape({
    key: Yup.string().required('error.error_validation_is_required'),
    idealoName: Yup.string().nullable(),
    typeIndex: Yup.number().required('error.error_validation_is_required'),
    name: Yup.array()
      .of(
        Yup.object().shape({
          languageCode: Yup.mixed<ELanguageCode>().oneOf(
            Object.values(ELanguageCode),
          ),
          text: Yup.string().nullable(),
        }),
      )
      .test(
        'has-german-and-english',
        {
          en: 'error.error_validation_is_required',
          de: 'error.error_validation_is_required',
        },
        (value) => {
          return (
            !!value?.find((elem) => elem.languageCode === ELanguageCode.De)
              ?.text ||
            !!value?.find((elem) => elem.languageCode === ELanguageCode.En)
              ?.text
          )
        },
      )
      .test(
        'has-german',
        { de: 'error.error_validation_is_required' },
        (value) => {
          return !!value?.find((elem) => elem.languageCode === ELanguageCode.De)
            ?.text
        },
      )
      .test(
        'has-english',
        { en: 'error.error_validation_is_required' },
        (value) => {
          return !!value?.find((elem) => elem.languageCode === ELanguageCode.En)
            ?.text
        },
      ),
    description: Yup.array().of(
      Yup.object().shape({
        languageCode: Yup.mixed<ELanguageCode>().oneOf(
          Object.values(ELanguageCode),
        ),
        text: Yup.string().nullable(),
      }),
    ),
    categoryIds: Yup.array(),
    impactsLook: Yup.bool(),
    showOnWebsite: Yup.bool(),
    sortPriority: Yup.number()
      .required('error.error_validation_is_required')
      .test('', function (value) {
        return !propertyDefinitionsSortPriorities
          .filter((c) => c !== existingPropertySortPriority)
          .includes(Number(value))
      })
      .label('sort priority'),
  })
}
