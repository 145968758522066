import React from 'react'
import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import PrivateSalesList from '@/redesign/pages/InApp/PrivateSales'
import getPagePath from '@/utils/getPagePath'
import PrivateSaleDetails from './[privateSaleId]'
import AddPrivateSale from './create'

function PrivateSalesRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/create')}
        component={AddPrivateSale}
      />
      <SentryRoute
        path={getPagePath(props, '/:privateSaleId')}
        component={PrivateSaleDetails}
      />
      <SentryRoute path={getPagePath(props)} component={PrivateSalesList} />
    </Switch>
  )
}

export default PrivateSalesRoutes
