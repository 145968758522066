import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationUpdatePiceaUploadLinkArgs } from '@/schemaTypes'

const UPDATE_ITEM_PICEA_UPLOAD_LINK = gql`
  mutation updatePiceaUploadLink(
    $itemId: ObjectId!
    $piceaUploadLinkArgs: FileTusMetadataArgs
  ) {
    updatePiceaUploadLink(
      itemId: $itemId
      piceaUploadLinkArgs: $piceaUploadLinkArgs
    ) {
      _id
      piceaUploadLink {
        ...FileMetadataFragment
      }
    }
  }
  ${fragments.fileMetadata}
`

type TData = { updatePiceaUploadLink: Mutation['updatePiceaUploadLink'] }
type TVariables = MutationUpdatePiceaUploadLinkArgs

export function useUpdatePiceaUploadLink(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updatePiceaUploadLink] = useMutation<TData, TVariables>(
    UPDATE_ITEM_PICEA_UPLOAD_LINK,
    opts,
  )

  return updatePiceaUploadLink
}
