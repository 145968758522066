import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import styles from '@/domains/shops/components/ShopsConfiguration.module.scss'
import ShopsRow from '@/domains/shops/components/ShopsRow'
import { useGetShops } from '@/domains/shops/hooks'

export function ShopsTable() {
  const { t } = useTranslation()

  const { shops, queryResult } = useGetShops({
    variables: { opts: { filter: { includeDisabled: true } } },
  })

  return (
    <Paper className={`${styles.root} u-mb`}>
      <ConfigurationBreadcrumbs modelName={'shop.label'} />
      <Typography variant="h5" gutterBottom>
        {t('shop.shops_configurations')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Table className="u-mb-sm" size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('id')}</TableCell>
                <TableCell align="right">{t('name')}</TableCell>
                <TableCell align="right">{t('customer.phone')}</TableCell>
                <TableCell align="right">{t('customer.email')}</TableCell>
                <TableCell align="right">{t('timezone')}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(shops).map((clKey) => (
                <ShopsRow key={clKey} shop={shops[clKey]} />
              ))}
            </TableBody>
          </Table>
          <Link to="/inApp/configurations/shops/create">
            <Button className="u-mr-mini" variant="contained">
              {t('shop.shop_create')}
            </Button>
          </Link>
        </Fragment>
      )}
    </Paper>
  )
}

export default ShopsTable
