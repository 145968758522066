import { useField } from 'formik'
import { FormEvent } from 'primereact/ts-helpers'
import { ComponentType, FocusEvent, useCallback } from 'react'

const connectFormikField = <
  P extends {
    isInvalid?: boolean
    errorText?: string
    warningText?: string
    onBlur?: (event: FocusEvent<any, Element>) => void
  },
>(
  Input: ComponentType<P>,
) => {
  const FormikInput = ({
    name,
    onBlur: customOnBlur,
    ...props
  }: Omit<P, 'onChange' | 'value'> & { name: string }) => {
    const [field, meta, helpers] = useField({ name })
    const isInvalid = meta.touched && typeof meta.error === 'string'

    const handleBlur = useCallback(
      (event: FocusEvent<any, Element>) => {
        field.onBlur(event)
        customOnBlur?.(event)
      },
      [field, customOnBlur],
    )

    // useFields default onChange method does not update the formik state for the number
    // input. As the used primereact number field is of type="text", this is probably the
    // related issue: https://github.com/jaredpalmer/formik/issues/3338
    const handleChange = useCallback(
      (event: FormEvent<HTMLInputElement>) => {
        // primereacts inputText does not emit event.value in the onChange event
        const value =
          event?.value !== undefined ? event?.value : event?.target?.value
        helpers.setValue(value)
      },
      [helpers],
    )

    return (
      <Input
        {...(props as unknown as P)}
        {...field}
        isInvalid={isInvalid}
        errorText={isInvalid ? meta.error : ''}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    )
  }

  return FormikInput
}

export default connectFormikField
