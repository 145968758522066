import gql from 'graphql-tag'

export const CallViviEndpointQuery = gql`
  query callViviEndpoint($args: ViviRequestArgs!) {
    callViviEndpoint(args: $args) {
      nextStep {
        summary
        name
        description {
          value
          label
        }
        href
        type
        rel
        templated
      }
      lastStepHref
    }
  }
`
