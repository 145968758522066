import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import { FragmentType, graphql, useFragment } from '@/gql'
import { EmployeeActiveShop } from '@/store/actions/ui'

export const ALL_COMPANIES = 'ALL'

const EmployeeShopSelect_ShopsFragment = graphql(`
  fragment EmployeeShopSelect_Shops on Shop {
    _id
    name
    cashBoxNames
  }
`)

interface EmployeeShopSelectProps {
  shops: FragmentType<typeof EmployeeShopSelect_ShopsFragment>[]
  shopId: string
  setShop: (shop: EmployeeActiveShop) => void
}

const EmployeeShopSelect = (props: EmployeeShopSelectProps) => {
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const { shopId, setShop } = props
  const shops = useFragment(EmployeeShopSelect_ShopsFragment, props.shops)

  const shopsRendering = useMemo(() => {
    return shops?.map((shop) => (
      <ShopSelectButton
        key={shop._id}
        selected={shopId === shop._id}
        className="u-mb-15"
        variant="outlined"
        onClick={() => setShop({ id: shop._id, name: shop.name })}
      >
        {shop.name}
      </ShopSelectButton>
    ))
  }, [shops, shopId, setShop])

  return (
    <ShopSelectWrapper>
      <NoneButtonWrapper className="flex flex-row items-center">
        <ShopSelectButton
          selected={!shopId}
          className="u-mb-15"
          variant="outlined"
          onClick={() => setShop({ id: '', name: '' })}
        >
          {t('none')}
        </ShopSelectButton>
        <InfoButton
          onClick={() => showInfo(t('employee_no_shop_select_warning'))}
        />
      </NoneButtonWrapper>
      {shopsRendering}
    </ShopSelectWrapper>
  )
}

const ShopSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ShopSelectButton = styled(Button)<{ selected?: boolean }>`
  &&& {
    width: 400px;
    height: 50px;
    border-radius: 10px;
    &:hover {
      border: 2px solid var(--cashy-violet);
      background-color: transparent;
    }
    ${({ selected }) => selected && `border: 2px solid var(--cashy-violet)`}
    text-transform: uppercase;

    @media (max-width: 725px) {
      width: 100%;
    }
  }
`

const NoneButtonWrapper = styled.div`
  margin-left: 2.3rem;
  @media (max-width: 725px) {
    width: 100%;
    margin-left: 0rem;
  }
`
const InfoButton = styled(InfoIcon)`
  margin-left: 1rem;
  cursor: pointer;
`

export default EmployeeShopSelect
