import TableValue from '../TableValue'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import { formatFuncForPrices } from '@/utils/misc'

export interface ValorizationHistoryEntryTableRow<T extends string = string> {
  label: string
  key: T
  renderKey: string
  type: 'float' | 'integer' | 'string' | 'date' | 'boolean'
  hidden?: boolean
  disabled?: boolean
  value: any
  onChange: undefined | ((value: any) => void)
  options?: { label: string; value: any }[]
  disableFuture?: boolean
  minDate?: Date
}

interface ValorizationHistoryEntryTableProps {
  rows: ValorizationHistoryEntryTableRow[]
  columnWidths: { label: number; value: number }
}

export function ValorizationHistoryEntryTable(
  props: ValorizationHistoryEntryTableProps,
) {
  const { rows, columnWidths } = props

  return (
    <Table size="small">
      <TableBody>
        {rows.map((row) =>
          row.hidden ? null : (
            <TableRow key={row.renderKey}>
              <TableCell
                component="th"
                scope="row"
                style={{ width: `${columnWidths.label}px` }}
              >
                {row.label}
              </TableCell>
              <TableCell
                style={row.options ? { width: columnWidths.value } : {}}
              >
                <TableValue
                  key={row.key}
                  value={row.value}
                  onChange={row.onChange}
                  type={row.type}
                  textAlign="left"
                  inputWidth={
                    row.type === 'string' ? columnWidths.value : undefined
                  }
                  formatFunc={
                    row.type === 'float'
                      ? formatFuncForPrices
                      : (value) => {
                          if (row.type === 'string') {
                            return value ?? ''
                          }

                          return value
                        }
                  }
                  addPaddingLeft={row.disabled}
                  options={row.options}
                  disableFuture={row.disableFuture}
                  minDate={row.minDate}
                />
              </TableCell>

              <TableCell>
                {row.key === 'trackingLink' && row.value
                  ? (
                      <a
                        target="_blank"
                        style={{ marginLeft: '-2rem', fontSize: '0.7rem' }}
                        href={`//${row.value}`}
                        rel="noreferrer"
                      >
                        <LinkIcon style={{ marginTop: '0.5rem' }} />
                      </a>
                    ) ?? ''
                  : ''}
              </TableCell>
            </TableRow>
          ),
        )}
      </TableBody>
    </Table>
  )
}

export default ValorizationHistoryEntryTable
