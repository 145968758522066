import TextInput from '../TextInput'
import styled from '@emotion/styled'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { find } from 'lodash'
import {
  BooleanCustomDealFormQuestion,
  CustomDealFormQuestion,
  CustomDealFormQuestionCreateArgs,
  ECustomDealFormQuestionType,
  ELanguageCode,
  FormAnswer,
  MultipleLanguageTextArgs,
  NumberDefaultCustomDealFormQuestion,
  NumberOptionsCustomDealFormQuestion,
  TextDefaultCustomDealFormQuestion,
  TextMultilineCustomDealFormQuestion,
  TextOptionsCustomDealFormQuestion,
} from '@/schemaTypes'
import { ItemFormBooleanAnswer } from './ItemFormBooleanAnswer'
import {
  ItemFormSelectNumberAnswer,
  ItemFormSelectTextAnswer,
} from './ItemFormSelectAnswer'

export type NewFormAnswer =
  | {
      type:
        | ECustomDealFormQuestionType.NumberDefault
        | ECustomDealFormQuestionType.NumberOptions
      value?: number
      customValue?: number | string
      question:
        | NumberDefaultCustomDealFormQuestion
        | NumberOptionsCustomDealFormQuestion
    }
  | {
      type:
        | ECustomDealFormQuestionType.TextDefault
        | ECustomDealFormQuestionType.TextMultiline
        | ECustomDealFormQuestionType.TextOptions
      value: string
      question:
        | TextDefaultCustomDealFormQuestion
        | TextMultilineCustomDealFormQuestion
        | TextOptionsCustomDealFormQuestion
    }
  | {
      type: ECustomDealFormQuestionType.Boolean
      value: boolean
      question: BooleanCustomDealFormQuestion
    }

interface Props {
  formAnswers: FormAnswer[]
  isEditable?: boolean
  onChangeAnswer?: (args: {
    index: number
    newFormAnswer: NewFormAnswer
    allowEnterAnswer?: boolean
  }) => void
}

const ItemFormAnswer = ({
  formAnswer,
  index,
  isEditable,
  onChangeAnswer,
}: {
  formAnswer: FormAnswer
  isEditable?: boolean
  index: number
  onChangeAnswer?: (args: {
    index: number
    newFormAnswer: NewFormAnswer
    allowEnterAnswer?: boolean
  }) => void
}) => {
  const { question } = formAnswer

  return (
    <TableRow>
      <TableCell>{question.label.text}</TableCell>
      <TableCell align="right">
        {formAnswer.__typename === 'FormOptionNumberAnswer' ? (
          <ItemFormSelectNumberAnswer
            formAnswer={formAnswer}
            disabled={!isEditable}
            onChange={(newFormAnswer, allowEnterAnswer) =>
              onChangeAnswer?.({ index, newFormAnswer, allowEnterAnswer })
            }
          />
        ) : formAnswer.__typename === 'FormOptionTextAnswer' ? (
          <ItemFormSelectTextAnswer
            formAnswer={formAnswer}
            disabled={!isEditable}
            onChange={(newFormAnswer, allowEnterAnswer) =>
              onChangeAnswer?.({ index, newFormAnswer, allowEnterAnswer })
            }
          />
        ) : formAnswer.__typename === 'FormBooleanAnswer' ? (
          <ItemFormBooleanAnswer
            formAnswer={formAnswer}
            disabled={!isEditable}
            onChange={(newFormAnswer) =>
              onChangeAnswer?.({ index, newFormAnswer })
            }
          />
        ) : (
          <StyledTextInput
            fullWidth
            value={getAnswer(formAnswer)}
            disabled={!isEditable}
            onChange={(value) => {
              switch (question.__typename) {
                case 'NumberDefaultCustomDealFormQuestion':
                  onChangeAnswer?.({
                    index,
                    newFormAnswer: {
                      type: ECustomDealFormQuestionType.NumberDefault,
                      value: +value,
                      question,
                    },
                  })
                  break
                case 'TextDefaultCustomDealFormQuestion':
                  onChangeAnswer?.({
                    index,
                    newFormAnswer: {
                      type: ECustomDealFormQuestionType.TextDefault,
                      value: value,
                      question,
                    },
                  })
                  break
                case 'TextMultilineCustomDealFormQuestion':
                  onChangeAnswer?.({
                    index,
                    newFormAnswer: {
                      type: ECustomDealFormQuestionType.TextMultiline,
                      value: value,
                      question,
                    },
                  })
                  break
              }
            }}
            rows={5}
            type={
              question.type === ECustomDealFormQuestionType.NumberDefault
                ? 'number'
                : 'text'
            }
            variant={
              question.type === ECustomDealFormQuestionType.TextMultiline
                ? 'outlined'
                : undefined
            }
            multiline={
              question.type === ECustomDealFormQuestionType.TextMultiline
            }
          />
        )}
      </TableCell>
    </TableRow>
  )
}

export const ItemFormAnswers = ({
  formAnswers,
  isEditable = true,
  onChangeAnswer,
}: Props) => {
  return (
    <Table>
      <TableBody>
        {formAnswers.map((formAnswer, i) => (
          <ItemFormAnswer
            key={i}
            formAnswer={formAnswer}
            index={i}
            isEditable={isEditable}
            onChangeAnswer={onChangeAnswer}
          />
        ))}
      </TableBody>
    </Table>
  )
}

const StyledTextInput = styled(TextInput)`
  margin-bottom: 0;
  .MuiFormControl-fullWidth {
    margin-left: 0 !important;
  }
`

export const checkIsCustomDealFormQuestion = (
  question: CustomDealFormQuestion | CustomDealFormQuestionCreateArgs,
): question is CustomDealFormQuestion => {
  return 'text' in question.label
}

const getAnswer = (formAnswer: FormAnswer) => {
  switch (formAnswer.__typename) {
    case 'FormTextAnswer':
      return formAnswer.textAnswer
    case 'FormMultilineTextAnswer':
      return formAnswer.textMultilineAnswer
    case 'FormNumberAnswer':
      return formAnswer.numberAnswer
    default:
      return null
  }
}

export const getQuestionLabel = (
  label: MultipleLanguageTextArgs[],
  language: ELanguageCode,
) => find(label, { languageCode: language })?.text ?? ''
