import Login from '../redesign/pages/Login'
import styled from '@emotion/styled'
import { Button, CircularProgress } from '@material-ui/core'
import { deepPurple } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import gql from 'graphql-tag'
import { Suspense, useCallback, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { Router, useHistory, useLocation } from 'react-router-dom'
import { Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import client from '@/apollo'
import ErrorsDisplayer from '@/components/ErrorsDisplayer'
import Modal from '@/components/Modal'
import SentryRoute from '@/components/SentryRoute'
import { Context } from '@/context'
import { ELanguageCode } from '@/gql/graphql'
import i18n from '@/i18n'
import EmployeeActivation from '@/redesign/pages/InApp/EmployeeActivation'
import ExpiredLink from '@/redesign/pages/InApp/ExpiredLink'
import { ERoles, Employee } from '@/schemaTypes'
import { setLanguage as setLanguageAction } from '@/store/actions/misc'
import { closeModal, employeeActiveShop, showModal } from '@/store/actions/ui'
import {
  logoutCurrentUser,
  setCurrentUser as setCurrentUserAction,
} from '@/store/actions/user'
import store, { useAppDispatch, useAppSelector } from '@/store/index'
import { InApp } from './InApp'

const FourZeroFour = () => <div>404</div>

const theme = createTheme({
  palette: {
    primary: deepPurple,
  },
})

const GET_CURRENT_EMPLOYEE = gql`
  query getCurrentEmployee {
    getCurrentEmployee {
      _id
      company {
        _id
      }
      preferences {
        defaultShopId
      }
    }
  }
`

type ConfirmModalType = {
  autoFocusCloseButton?: boolean
  preventAutoCloseByKey: boolean
  title: string
  component?: (
    state: any,
    updateState: (stateOrUpdater: any) => void,
  ) => JSX.Element
  state?: {
    disableRightButton?: boolean
    [key: string]: any
  }
  leftButtonAction: (state?: any) => void
  rightButtonAction: (state?: any) => void
  leftButtonText: string
  rightButtonText: string
}

function App() {
  const history = useHistory()
  const { pathname: path, search } = useLocation()
  const alerts = useAppSelector((state) => state.ui.alerts) || []
  const alert = alerts && alerts.length > 0 ? alerts[0] : null
  const registrationToken = new URLSearchParams(search).get('token')
  const currentUser = useAppSelector((state) => state.user.current)
  const [language, setLanguage] = useState<ELanguageCode.De | ELanguageCode.En>(
    ELanguageCode.De,
  )
  const [confirmModal, setConfirmModal] = useState<
    ConfirmModalType | undefined
  >(undefined)
  const dispatch = useAppDispatch()

  const dispatchCloseModal = useCallback(() => {
    dispatch(closeModal())
  }, [dispatch])

  const dispatchSetCurrentUser = useCallback(
    (data) => {
      dispatch(setCurrentUserAction(data))
    },
    [dispatch],
  )

  const dispatchSetLanguage = useCallback(
    (data) => {
      dispatch(setLanguageAction(data))
    },
    [dispatch],
  )

  const dispatchShowErrors = useCallback(
    (errors, callback) => {
      dispatch(showModal({ type: 'ERROR', errors, closeCallback: callback }))
    },
    [dispatch],
  )

  const dispatchShowInfo = useCallback(
    (msg) => {
      dispatch(showModal({ type: 'INFO', message: msg }))
    },
    [dispatch],
  )

  const dispatchResetEmployeeActiveShop = useCallback(() => {
    dispatch(employeeActiveShop(null))
  }, [dispatch])

  const dispatchLogoutCurrentUser = useCallback(() => {
    dispatch(logoutCurrentUser())
  }, [dispatch])

  const closeConfirmModal = useCallback(() => {
    setConfirmModal(undefined)
  }, [])

  const onLogout = useCallback(() => {
    dispatchResetEmployeeActiveShop()
    dispatchLogoutCurrentUser()
    history.replace('/')
  }, [dispatchLogoutCurrentUser, dispatchResetEmployeeActiveShop, history])

  const setContextLanguage = useCallback(
    (newLanguage) => {
      dispatchSetLanguage(newLanguage)
      setLanguage(newLanguage)
    },
    [dispatchSetLanguage],
  )

  useEffect(() => {
    const persistedLanguage = window.localStorage.getItem('language')
    if (persistedLanguage) {
      setLanguage(persistedLanguage as ELanguageCode.De | ELanguageCode.En)
    }
  }, [])

  useEffect(() => {
    let isAborted = false
    const user = getCurrentUser()
    if (user) {
      client
        .query({ query: GET_CURRENT_EMPLOYEE })
        .then((result) => {
          if (isAborted) {
            return
          }
          const company = result.data?.getCurrentEmployee?.company
          const preferences = result.data?.getCurrentEmployee?.preferences
          dispatchSetCurrentUser({ ...user, company, preferences })
        })
        .catch(() => {
          if (isAborted) {
            return
          }
          window.localStorage.removeItem('token')
          window.localStorage.removeItem('user')
          history.replace('/')
        })
    }
    return () => {
      isAborted = true
    }
  }, [history, dispatchSetCurrentUser])

  useEffect(() => {
    if (path.indexOf('expired-link') !== -1) {
      return
    }
    if (currentUser && path.indexOf('inApp') === -1) {
      history.replace('/inApp')
    }
    if (
      !currentUser &&
      !window.localStorage.getItem('user') &&
      !registrationToken
    ) {
      history.replace('/')
    }
  }, [currentUser, history, path, registrationToken])

  return (
    <div id="App">
      <Context.Provider
        value={{
          language,
          t: (key) => i18n.t(key),
          showConfirmModal: setConfirmModal,
          setLanguage: setContextLanguage,
          closeConfirmModal,
          showErrors: dispatchShowErrors,
          showInfo: dispatchShowInfo,
          history,
          getCurrentUser,
          isGuestUser,
        }}
      >
        <ThemeProvider theme={theme}>
          <Switch>
            <SentryRoute path="/inApp">
              <InApp onLogout={onLogout} />
            </SentryRoute>
            <SentryRoute path="/" exact component={Login} />
            <SentryRoute
              path="/employee-activation"
              exact
              component={EmployeeActivation}
            />
            <SentryRoute path="/expired-link" exact component={ExpiredLink} />
            <SentryRoute component={FourZeroFour} />
          </Switch>
          {alert && (
            <Modal
              type={alert.type}
              onClose={() => {
                dispatchCloseModal()
                if (alert.closeCallback) {
                  alert.closeCallback()
                }
              }}
            >
              {alert.type === 'ERROR' ? (
                <ErrorsDisplayer errors={alert.errors} />
              ) : (
                <p
                  className="o-type-alert"
                  dangerouslySetInnerHTML={{ __html: alert.message }}
                />
              )}
            </Modal>
          )}
          {confirmModal && (
            <Modal
              type={'CONFIRM'}
              onClose={closeConfirmModal}
              autoFocusCloseButton={Boolean(confirmModal?.autoFocusCloseButton)}
              preventAutoCloseByKey={confirmModal.preventAutoCloseByKey}
            >
              <div className="u-text-center u-pt-10">
                <h2 className="o-type-17 u-mb-20">{confirmModal.title}</h2>
              </div>
              <div className="u-text-center u-pb-10">
                {confirmModal.component
                  ? confirmModal.component(
                      confirmModal?.state,
                      (stateOrUpdater: any) => {
                        setConfirmModal((old) => {
                          if (!old) {
                            return old
                          }
                          return {
                            ...old,
                            state:
                              typeof stateOrUpdater === 'function'
                                ? stateOrUpdater(old.state)
                                : stateOrUpdater,
                          }
                        })
                      },
                    )
                  : undefined}
              </div>
              <div className="u-text-center u-flex">
                <div className="u-6/12 u-pl-20">
                  <Button
                    onClick={() =>
                      confirmModal.leftButtonAction(confirmModal?.state)
                    }
                  >
                    {confirmModal.leftButtonText}
                  </Button>
                </div>
                <div className="u-6/12 u-pr-20">
                  <Button
                    id="CONFIRM_MODAL_RIGHT_BUTTON"
                    disabled={confirmModal?.state?.disableRightButton}
                    onClick={() =>
                      confirmModal.rightButtonAction(confirmModal?.state)
                    }
                  >
                    {confirmModal.rightButtonText}
                  </Button>
                </div>
              </div>
            </Modal>
          )}
          <StyledToastContainer />
        </ThemeProvider>
      </Context.Provider>
    </div>
  )
}

const StyledToastContainer = styled(ToastContainer)`
  z-index: 10001;
`

function getCurrentUser() {
  const persistedUserJson = window.localStorage.getItem('user')
  if (persistedUserJson) {
    return JSON.parse(persistedUserJson) as Employee
  } else {
    return null
  }
}

function isGuestUser() {
  const currentUser = getCurrentUser()
  return !currentUser || currentUser.roles.includes(ERoles.Guest)
}

const Root = ({ history }) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Router history={history}>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </Suspense>
  )
}
export default Root
