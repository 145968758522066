import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationRemoveValorizationEntryArgs } from '@/schemaTypes'

const REMOVE_VALORIZATION_ENTRY = gql`
  mutation removeValorizationEntry($valorizationHistoryId: ObjectId!) {
    removeValorizationEntry(valorizationHistoryId: $valorizationHistoryId)
  }
`

type TData = { removeLastItemEvent: Mutation['removeValorizationEntry'] }
type TVariables = MutationRemoveValorizationEntryArgs

export function useRemoveValorizationEntry(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [removeValorizationEntry] = useMutation<TData, TVariables>(
    REMOVE_VALORIZATION_ENTRY,
    opts,
  )

  return removeValorizationEntry
}
