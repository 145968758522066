import styled from '@emotion/styled'
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { MergeCustomerAddressGroup } from '@/domains/customers/components/MergeCustomerAddressGroup'
import { MergeCustomerRow } from '@/domains/customers/components/MergeCustomerRow'
import { useCustomerMerging } from '@/hooks/useCustomerMerging'
import { EMergingCustomerReason } from '@/schemaTypes'

type Props = {
  customerIds: string[]
}

export const MergeCustomers = ({ customerIds }: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    groups,
    addressGroups,
    selectValue,
    selectGroupValue,
    reset,
    submit,
    cancel,
    reason,
    setReason,
    enableSubmitButton,
    removeMergedValue,
  } = useCustomerMerging(customerIds as [string, string])

  return (
    <Wrapper>
      <Typography variant="h4" gutterBottom>
        {t('merge_customer')}
      </Typography>
      <Container>
        {groups.map((g) => {
          return (
            <GroupBox>
              <Typography variant="h4" gutterBottom>
                {g.title}
              </Typography>

              <Typography variant="h5" gutterBottom>
                {g.subTitle}
              </Typography>

              {g.rows.map((r) => {
                return (
                  <MergeCustomerRow
                    onSelectLeftValue={() => {
                      selectValue(r.rowId, 'left')
                    }}
                    onSelectRightValue={() => {
                      selectValue(r.rowId, 'right')
                    }}
                    onRemoveMergedValue={() => {
                      removeMergedValue(r.rowId)
                    }}
                    key={r.rowId}
                    {...r}
                  />
                )
              })}
            </GroupBox>
          )
        })}

        {addressGroups.map((g) => {
          return (
            <GroupBox key={g.addressType}>
              <Typography variant="h4" gutterBottom />

              <Typography variant="h5" gutterBottom>
                {g.subTitle}
              </Typography>

              <MergeCustomerAddressGroup
                onSelectLeftValue={() => {
                  selectGroupValue(g.addressType, 'left')
                }}
                onSelectRightValue={() => {
                  selectGroupValue(g.addressType, 'right')
                }}
                onRemoveMergedValue={() => {
                  removeMergedValue('', g.addressType)
                }}
                {...g}
              />
            </GroupBox>
          )
        })}

        <ReasonContainer>
          <Typography variant="h5" gutterBottom>
            {t('merge_customer_reasons')}
          </Typography>
          <RadioGroupBox
            value={reason}
            onChange={(e) =>
              setReason(e.target.value as EMergingCustomerReason)
            }
          >
            <FormControlLabel
              value={EMergingCustomerReason.Duplicated}
              control={<Radio />}
              label={t('duplicated_email')}
            />
            <FormControlLabel
              value={EMergingCustomerReason.Request}
              control={<Radio />}
              label={t('requested_by_customer')}
            />
          </RadioGroupBox>
        </ReasonContainer>

        <Footer>
          <FooterLeftButtons>
            <Button
              variant="contained"
              style={{ width: '200px' }}
              disabled={!enableSubmitButton}
              onClick={submit}
            >
              {t('save')}
            </Button>
            <Button
              style={{ marginLeft: 12, width: '200px' }}
              variant="contained"
              onClick={reset}
            >
              {t('reset')}
            </Button>
          </FooterLeftButtons>
          <FooterRightButtons>
            <Button
              style={{ width: '200px' }}
              variant="contained"
              onClick={cancel}
            >
              {t('abort')}
            </Button>
          </FooterRightButtons>
        </Footer>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: calc(100vh - 64px);
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  display: flex;
  flex-direction: column;
  padding: 30px;
`

const Container = styled(Box)<{ flex?: number }>`
  flex: ${(props) => (props.flex ? props.flex : 1)};
`

const GroupBox = styled(Box)<{ flex?: number }>`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin-bottom: 50px;
`

const Footer = styled(Box)`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: space-between;
`

const FooterLeftButtons = styled(Box)``

const FooterRightButtons = styled(Box)``

const ReasonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`

const RadioGroupBox = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  column-gap: 200px;
`
