import { QueryHookOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import useDebouncedQuery from '@/hooks/useDebouncedQuery'
import { Query, QuerySearchDealsArgs } from '@/schemaTypes'

const SEARCH_DEALS = gql`
  query searchDeals($args: DealsFiltersArgs!) {
    searchDeals(args: $args) {
      nodes {
        _id
        companyId
        bookingNumber
        dealType
        businessUnit
        status
        createdAt
        dealFinalValues {
          payoutAmount
        }
        customer {
          ...GuestCustomerDataFragment
          ... on Customer {
            _id
            firstname
            lastname
            email
          }
        }
        pickupData {
          transportMode
          standardShippingData {
            sentShippingLabels {
              shippingPartner
            }
          }
        }
        events {
          __typename
          ... on BaseDealEvent {
            createdAt
            createdAt
            timestamp
          }
        }
        dropoffData {
          transportMode
        }
        initialShopId
        shop {
          _id
          name
        }
      }
      pageInfo {
        skip
        limit
        hasPrevPage
        hasNextPage
        total
      }
    }
  }
  ${fragments.guestCustomerData}
`

type TData = { searchDeals: Query['searchDeals'] }
type TVariables = QuerySearchDealsArgs

export function useSearchDeals(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useDebouncedQuery<TData, TVariables>(
    SEARCH_DEALS,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
    200,
  )

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
