import styled from '@emotion/styled'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'

interface TableFilterHeaderProps {
  filterOptions?: React.ReactNode
  title?: string
  resultsCount?: number
}

const TableFilterHeader = ({
  filterOptions,
  resultsCount,
  title,
}: TableFilterHeaderProps) => {
  const { t } = useTranslation()
  const [containerRef, containerBounds] = useMeasure<HTMLDivElement>()
  const [titleContainerRef, titleContainerBounds] = useMeasure<HTMLDivElement>()

  const isTallLayout = containerBounds.height > titleContainerBounds.height + 20

  return (
    <div ref={containerRef} className="flex flex-wrap items-center mb-4">
      <div
        ref={titleContainerRef}
        className={classNames('flex-none mb-3 ', {
          'mr-20': isTallLayout,
          'mr-6': !isTallLayout,
        })}
      >
        <Title>{title}</Title>
      </div>
      <div
        className={classNames(`flex-grow min-w-0`, {
          'order-2': isTallLayout,
          'order-1 mr-6': !isTallLayout,
        })}
      >
        {filterOptions}
      </div>
      <div
        className={classNames(`flex-none ml-auto py-3`, {
          'order-1': isTallLayout,
          'order-2': !isTallLayout,
        })}
      >
        <FilterResultsText>
          {resultsCount} {t('table_filter.results')}
        </FilterResultsText>
      </div>
    </div>
  )
}

const Title = styled.h5`
  color: #000;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
`

const FilterResultsText = styled.p`
  color: var(--global-text-secondary-color, #6c757d);
  text-align: right;
  font-family: 'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3125rem;
`

export default TableFilterHeader
