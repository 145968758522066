import { Checkbox, TextField, TextareaAutosize } from '@material-ui/core'
import dayjs from 'dayjs'
import React, { Fragment, useContext, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { Context } from '@/context'
import SaveIcon from '@/images/save.svg'
import { EItemStatusType, Item, ItemEvent } from '@/schemaTypes'
import { EHintType, findRelatedEmailForItemEvent } from './ItemStatus'
import styles from './ItemStatus.module.scss'

export interface ItemReturnArrivedStatusProps {
  item: Item
  onChangeItemStatus: (newEvent: ItemEvent, hint: EHintType) => void
}

const ItemReturnArrivedStatus: FunctionComponent<
  ItemReturnArrivedStatusProps
> = (props) => {
  const { onChangeItemStatus, item } = props
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const [sendNotification, setSendNotification] = useState(true)
  const [description, setDescription] = useState('')
  const [email, setEmail] = useState(findRelatedEmailForItemEvent(item))

  const saveNewStatus = () => {
    if (email === '' && sendNotification) {
      return showInfo(t('email_address_is_not_set'))
    }

    const now = dayjs().toDate()
    onChangeItemStatus(
      {
        createdAt: now,
        timestamp: now,
        event: EItemStatusType.ReturnArrived,
        meta: {
          sendNotification: sendNotification,
          emailAddress: email,
          description: description,
        },
      },
      EHintType.NONE,
    )
  }

  return (
    <Fragment>
      <div
        onClick={saveNewStatus}
        className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
      >
        <ReactSVG className={`${styles.saveIcon} u-pl-15`} src={SaveIcon} />
      </div>
      <table>
        <tr className={`${styles.notSellableTr}`}>
          <td>
            {sendNotification ? <br /> : ''}
            {t('notify_customer')}:
            <Checkbox
              checked={sendNotification}
              onChange={(e) => setSendNotification(e.target.checked)}
            />
            <div hidden={!sendNotification}>
              {t('customer.email')}:
              <TextField
                style={{
                  marginLeft: '0.5rem',
                  marginTop: '-0.5rem',
                  width: '70%',
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </td>
        </tr>

        <tr className={`${styles.notSellableTr}`}>
          <td>
            {sendNotification ? <br /> : ''}
            {t('description')}:
            <TextareaAutosize
              rowsMin={4}
              placeholder={t('any_description_want_to_add')}
              style={{ width: '100%' }}
              onChange={(e) => setDescription(e.target.value)}
            />
          </td>
        </tr>
      </table>
    </Fragment>
  )
}

export default ItemReturnArrivedStatus
