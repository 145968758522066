import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { Query, QueryGetPrivateSaleByDealIdArgs } from '@/schemaTypes'

const GET_PRIVATE_SALE_BY_DEAL_ID = gql`
  query getPrivateSaleByDealId($dealId: ObjectId!) {
    getPrivateSaleByDealId(dealId: $dealId) {
      _id
      createdAt
      creationType
      privateSaleDate
      dealId
      itemId
      bookingNumber
      carTitle
      customerFullName
      status
      dealFinalBalance
      privateSaleTransactions {
        ...PrivateSaleTransactionFragment
      }
    }
  }
  ${fragments.privateSaleTransaction}
`

type TData = { getPrivateSaleByDealId: Query['getPrivateSaleByDealId'] }
type TVariables = QueryGetPrivateSaleByDealIdArgs

export function useGetPrivateSaleByDealId(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_PRIVATE_SALE_BY_DEAL_ID, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, privateSale: queryResult.data?.getPrivateSaleByDealId }
}
