import { Box } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DateFilterInput, { DateFilter } from '@/components/DateFilter'
import TextInput from '@/components/TextInput'
import {
  EItemStatusType,
  ESalesPartnerType,
  ItemsFiltersArgs,
} from '@/schemaTypes'
import ItemCategoriesFilterInput, {
  ItemCategoriesFilterInputDetails,
} from './ItemCategoriesFilterInput'
import OpticalConditionFilterInput from './ItemOpticalConditionFilterInput'
import ItemStorageUnitsFilterInput from './ItemStorageUnitsFilterInput'

export type ItemsFilters = Omit<
  ItemsFiltersArgs,
  'paginationArgs' | 'status' | 'salePartnerType'
> & {
  status?: DateFilter<EItemStatusType>
  salePartnerType?: DateFilter<ESalesPartnerType>
} & ItemCategoriesFilterInputDetails

const itemStatusOptions = Object.values(EItemStatusType)
const itemSalePartnerOptions = Object.values(ESalesPartnerType)

interface ItemSearchProps {
  itemFilter: ItemsFilters
  setItemFilter: React.Dispatch<React.SetStateAction<ItemsFilters>>
  defaultItemFilter: Pick<ItemsFilters, 'companyId'>
  isBasicMode?: boolean
}

const ItemsSearch = (props: ItemSearchProps) => {
  const { itemFilter, setItemFilter, defaultItemFilter, isBasicMode } = props
  const { t } = useTranslation()

  return (
    <Box>
      <TextInput
        value={itemFilter.search ?? ''}
        onChange={(value) =>
          setItemFilter((prev) => ({
            ...prev,
            search: value,
            ...defaultItemFilter,
          }))
        }
        label={<div style={{ minWidth: 116 }}>{t('search')}</div>}
        style={{ marginBottom: 10 }}
        showResetButton
        onReset={() =>
          setItemFilter((prev) => ({
            ...prev,
            search: null,
            ...defaultItemFilter,
          }))
        }
      />
      <DateFilterInput
        filter={itemFilter.status}
        onFilter={(filter) =>
          setItemFilter((prev) => ({
            ...prev,
            status: filter,
            ...defaultItemFilter,
          }))
        }
        options={itemStatusOptions}
        secondaryLabel={'Was in Status'}
        label={t('status')}
        labelStyle={{ minWidth: 120, marginTop: 4 }}
      />
      <ItemCategoriesFilterInput
        itemCategoryIdLv0={itemFilter.itemCategoryIdLv0}
        itemCategoryIdLv1={itemFilter.itemCategoryIdLv1}
        itemCategoryIdLv2={itemFilter.itemCategoryIdLv2}
        onItemCategoryIdChanged={(value) => {
          setItemFilter((prev) => ({
            ...prev,
            ...value,
            ...defaultItemFilter,
          }))
        }}
      />
      {!isBasicMode && (
        <>
          <ItemStorageUnitsFilterInput
            storageFacilityId={itemFilter.storageFacilityId}
            onStorageFacilityIdChanged={(value) =>
              setItemFilter((prev) => ({
                ...prev,
                storageFacilityId: value,
                ...defaultItemFilter,
              }))
            }
            onStorageUnitIdLv0Changed={(value) =>
              setItemFilter((prev) => ({
                ...prev,
                storageUnitIdLv0: value,
                ...defaultItemFilter,
              }))
            }
            onStorageUnitIdLv1Changed={(value) =>
              setItemFilter((prev) => ({
                ...prev,
                storageUnitIdLv1: value,
                ...defaultItemFilter,
              }))
            }
            onStorageUnitIdLv2Changed={(value) =>
              setItemFilter((prev) => ({
                ...prev,
                storageUnitIdLv2: value,
                ...defaultItemFilter,
              }))
            }
            onStorageUnitIdLv3Changed={(value) =>
              setItemFilter((prev) => ({
                ...prev,
                storageUnitIdLv3: value,
                ...defaultItemFilter,
              }))
            }
            storageUnitIdLv0={itemFilter.storageUnitIdLv0}
            storageUnitIdLv1={itemFilter.storageUnitIdLv1}
            storageUnitIdLv2={itemFilter.storageUnitIdLv2}
            storageUnitIdLv3={itemFilter.storageUnitIdLv3}
          />
          <DateFilterInput
            filter={itemFilter.salePartnerType}
            label={t('sales_platform')}
            secondaryLabel={'Ad Created'}
            onFilter={(filter) =>
              setItemFilter((prev) => ({
                ...prev,
                salePartnerType: filter,
                ...defaultItemFilter,
              }))
            }
            options={itemSalePartnerOptions}
            labelStyle={{ minWidth: 120, marginTop: 4 }}
          />

          <OpticalConditionFilterInput
            opticalCondition={itemFilter.opticalCondition}
            setItemFilter={setItemFilter}
            defaultItemFilter={defaultItemFilter}
          />
        </>
      )}
    </Box>
  )
}

export default ItemsSearch
