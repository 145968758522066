import { useUpdateDealTransport } from '../../hooks/updateDealTransport'
import { ApolloQueryResult } from '@apollo/client'
import { Button } from '@material-ui/core'
import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '@/components/LoadingSpinner'
import { Context } from '@/context'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import { DealTransportUpdateArgs, ETransportType, Query } from '@/schemaTypes'

interface ShopInfoProps {
  isEditable: boolean
  newTransportArgs: DealTransportUpdateArgs
  refetchDeal: () => Promise<
    ApolloQueryResult<{ getDealById: Query['getDealById'] }>
  >
}
const ShopInfo = (props: ShopInfoProps) => {
  const { isEditable, newTransportArgs, refetchDeal } = props
  const { closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()
  const [spinnerRunning, setSpinnerRunning] = useState(false)
  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })

  const saveDealTransport = useMutationShowingErrors({
    mutation: useUpdateDealTransport(),
    successMessage: `${t('shipping_tracking_updated_successfully')}!`,
  })

  const onSaveTrasnport = () => {
    showConfirmPopup({
      title:
        newTransportArgs.transportType === ETransportType.Pickup
          ? t('shop.save_shop_as_pickup_transport')
          : t('shop.save_shop_as_dropoff_transport'),
      action: async () => {
        closeConfirmModal()

        setSpinnerRunning(true)
        await saveDealTransport({
          variables: {
            args: { ...newTransportArgs, shippingLabelCreateArgs: undefined },
          },
          onCompleted: () => refetchDeal(),
        })

        setSpinnerRunning(false)
      },
    })
  }

  return (
    <Fragment>
      <div style={{ marginTop: '2rem', display: 'inline-block' }}>
        <Button
          disabled={!isEditable}
          variant="contained"
          component="label"
          className={'u-mt-5 u-mb-5'}
          onClick={onSaveTrasnport}
        >
          {t('save')}
        </Button>
      </div>
      <div style={{ display: 'inline-block', verticalAlign: 'bottom' }}>
        {spinnerRunning ? (
          <div
            style={{
              display: 'inline-block',
              marginLeft: '2rem',
              verticalAlign: 'top',
            }}
          >
            <LoadingSpinner />
          </div>
        ) : (
          ''
        )}
      </div>
    </Fragment>
  )
}

export default ShopInfo
