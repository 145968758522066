import styled from '@emotion/styled'
import { Box } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import ClearIcon from '@material-ui/icons/Clear'
import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'
import ErrorIcon from '@material-ui/icons/Error'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import WarningIcon from '@material-ui/icons/Warning'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { GetParentCategories } from '@/components/CategorySelector/GetParentCategories'
import {
  EItemSituation,
  EItemStatusType,
  Item,
  ItemCategory,
} from '@/schemaTypes'
import { formatFuncForPrices, printLocalFloat } from '@/utils/misc'
import { getNow } from '@/utils/time'
import { EditableItemOfferedPrice } from './Item/ItemOfferedPrice'
import { EditableItemRefurbedOfferPrice } from './Item/ItemRefurbedOfferPrice'
import styles from './ItemsList.module.scss'

interface ItemElementProps {
  item: Item
  storageHistoryDescription: string
  itemCategories: ItemCategory[]
  updateItemOfferedPrice: (itemId: string) => (price: number) => void
}

const ItemElement = React.forwardRef<any, ItemElementProps>(
  (
    { item, storageHistoryDescription, itemCategories, updateItemOfferedPrice },
    ref,
  ) => {
    const { t } = useTranslation()

    const dealsHistoryEntry = item?.dealsHistory[item.dealsHistory.length - 1]

    const events = [...(item?.events ?? [])].reverse()

    const isOfferedToSell = events[0].event === EItemStatusType.OfferedToSell

    const offeredToSellStatus = events.find(
      (c) => c.event === EItemStatusType.OfferedToSell,
    )

    const offeredPriceDate =
      offeredToSellStatus?.meta?.offeredPriceAt ||
      offeredToSellStatus?.createdAt

    const overExpectedTurnoverTime = isOfferedToSell
      ? checkOverExpectedTurnoverTime(
          offeredPriceDate,
          itemCategories,
          item.dealsHistory[item.dealsHistory.length - 1].itemCategoryId,
        )
      : false

    const isRefurbedOffer = isOfferedToSell && events[0].meta?.refurbedOffer

    return (
      <ItemElementContainer key={item._id} ref={ref as any}>
        <ItemRowBox>{item.storageLabel}</ItemRowBox>
        <ItemRowBox flex={3}>
          {dealsHistoryEntry ? dealsHistoryEntry.title : ''}
        </ItemRowBox>
        <ItemRowBox>
          {isRefurbedOffer ? (
            <EditableItemRefurbedOfferPrice item={item} itemEvent={events[0]} />
          ) : isOfferedToSell &&
            offeredToSellStatus?.meta?.advertiserPlatformName &&
            offeredToSellStatus?.meta?.advertisementLink ? (
            <div className={styles.salePlatforms}>
              <a
                href={offeredToSellStatus.meta.advertisementLink[0]}
                target="blank"
              >
                {offeredToSellStatus.meta.advertiserPlatformName[0]}
              </a>
              <div className={styles.flexCenterWrapper}>
                <div className={styles.offerDate}>
                  Offered at:{' '}
                  {dayjs(offeredToSellStatus.createdAt).format('DD.MM.YYYY')}
                </div>
                {overExpectedTurnoverTime && (
                  <Tooltip
                    title={t('over_expected_turnover_time', {
                      XX: overExpectedTurnoverTime,
                    })}
                  >
                    <ErrorIcon className={styles.warningIcon} />
                  </Tooltip>
                )}
              </div>
              <div className={styles.flexCenterWrapper}>
                <EditableItemOfferedPrice
                  item={item}
                  itemOfferedPrice={offeredToSellStatus.meta.offeredPrice || 0}
                  onSave={updateItemOfferedPrice(item._id)}
                />
                {offeredToSellStatus.meta.offeredPrice &&
                  item.internalSaleData?.price && (
                    <div className={styles.margin}>
                      {calculateMargin(
                        offeredToSellStatus.meta.offeredPrice,
                        item.internalSaleData.price,
                      )}
                    </div>
                  )}
              </div>
            </div>
          ) : (
            '-'
          )}
        </ItemRowBox>
        <ItemRowBox>
          {item.internalSaleData ? (
            <>
              <div>
                {item.internalSaleData.price
                  ? formatFuncForPrices(item.internalSaleData.price)
                  : ''}{' '}
                €
              </div>
              <div>
                {' '}
                {item.internalSaleData.date
                  ? dayjs(item.internalSaleData.date).format('DD.MM.YYYY')
                  : ''}
              </div>
              <div>
                {item.internalSaleData.purchasedDirectly
                  ? t('direct_purchase')
                  : ''}
              </div>
            </>
          ) : (
            '-'
          )}
        </ItemRowBox>
        <ItemRowBox>
          {item.events ? item.events[item.events.length - 1].event : ''}
        </ItemRowBox>
        <ItemRowBox flex={0.5}>
          {item.isInStore === EItemSituation.ItemInStore ? (
            <Tooltip title={t('item_is_in_store')}>
              <DoneIcon className={`${styles.check} u-ml-sm`} />
            </Tooltip>
          ) : item.isInStore === EItemSituation.ItemNotInStore ? (
            <Tooltip title={t('item_is_not_in_store')}>
              <ClearIcon className={`${styles.unCheck} u-ml-sm`} />
            </Tooltip>
          ) : (
            <Tooltip title={t('inconsistent_data')}>
              <WarningIcon className={`${styles.warning} u-ml-sm`} />
            </Tooltip>
          )}
        </ItemRowBox>

        <ItemRowBox flex={2}>{storageHistoryDescription}</ItemRowBox>

        <ItemRowBox flex={0.5}>
          {item.itemNotifications && item.itemNotifications.length > 0 ? (
            <NotificationsActiveIcon className={`${styles.notification}`} />
          ) : (
            ''
          )}
          <Link to={`/inApp/items/${item._id}`}>
            <EditIcon classes={{ root: styles.editIcon }} />
          </Link>
        </ItemRowBox>
      </ItemElementContainer>
    )
  },
)

export const calculateMargin = (
  offeredPrice: number,
  internalValorizationPrice: number,
) => {
  const margin =
    ((offeredPrice - internalValorizationPrice) / offeredPrice) * 100
  return `(M: ${printLocalFloat(margin)}%)`
}

const checkOverExpectedTurnoverTime = (
  offeredPriceDate: string,
  itemCategories: ItemCategory[],
  itemCategoryId: string,
) => {
  if (!itemCategoryId) return

  const itemOfferedSaleDurationTime = dayjs(getNow()).diff(
    offeredPriceDate,
    'days',
  )

  const parentCategories = GetParentCategories(itemCategoryId, itemCategories)

  const expectedTurnOverTimeCategory = parentCategories.find(
    (c) => c.expectedTurnOverTime,
  )

  if (
    !expectedTurnOverTimeCategory ||
    itemOfferedSaleDurationTime <
      expectedTurnOverTimeCategory.expectedTurnOverTime!
  )
    return

  return expectedTurnOverTimeCategory.expectedTurnOverTime
}

const ItemRowBox = styled(Box)<{ flex?: number }>`
  flex: ${(props) => (props.flex ? props.flex : 1)};
`

const ItemElementContainer = styled.div`
  display: flex;
`

export default ItemElement
