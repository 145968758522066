import { TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopy from '@material-ui/icons/FileCopy'
import React from 'react'
import { Link } from 'react-router-dom'
import { Company } from '@/schemaTypes'
import globalStyles from '@/styles/global.module.scss'

export interface CompaniesRowProps {
  company: Company
  deleteCompany: () => void
}

const CompaniesRow = (props: CompaniesRowProps) => {
  const { company } = props
  const id = company._id ?? ''

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell align="right">{company.name}</TableCell>
      <TableCell align="right">{company.uid}</TableCell>
      <TableCell align="right">{company.fibuNumber}</TableCell>
      <TableCell>
        <Link to={`/inApp/configurations/companies/create/${id}`}>
          <FileCopy />
        </Link>
        <Link to={`/inApp/configurations/companies/edit/${id}`}>
          <EditIcon />
        </Link>
        <DeleteIcon
          className={globalStyles.clickable}
          onClick={props.deleteCompany}
        />
      </TableCell>
    </TableRow>
  )
}

export default CompaniesRow
