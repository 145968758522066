import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import {
  Mutation,
  MutationGenerateAuctionAnnouncementPdfArgs,
} from '@/schemaTypes'

const GENERATE_AUCTION_ANNOUNCEMENT_PDF = gql`
  mutation generateAuctionAnnouncementPdf($auctionId: ObjectId!) {
    generateAuctionAnnouncementPdf(auctionId: $auctionId) {
      _id
      auctionAnnouncementLink {
        ...FileMetadataFragment
      }
      internalAuctionAnnouncementLink {
        ...FileMetadataFragment
      }
    }
  }
  ${fragments.fileMetadata}
`

type TData = {
  generateAuctionAnnouncementPdf: Mutation['generateAuctionAnnouncementPdf']
}
type TVariables = MutationGenerateAuctionAnnouncementPdfArgs

export function useGenerateAuctionAnnouncementPdf(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [auctionAnnouncement] = useMutation<TData, TVariables>(
    GENERATE_AUCTION_ANNOUNCEMENT_PDF,
    opts,
  )
  return auctionAnnouncement
}
