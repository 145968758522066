import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateItemCategoryIdArgs } from '@/schemaTypes'

const UPDATE_ITEM_CATEGORY_ID = gql`
  mutation updateItemCategoryId(
    $itemId: ObjectId!
    $dealId: ObjectId!
    $itemCategoryId: ObjectId!
  ) {
    updateItemCategoryId(
      itemId: $itemId
      dealId: $dealId
      itemCategoryId: $itemCategoryId
    ) {
      _id
      dealsHistory {
        dealId
        itemCategoryId
      }
    }
  }
`

type TData = { updateItemCategoryId: Mutation['updateItemCategoryId'] }
type TVariables = MutationUpdateItemCategoryIdArgs

export function useUpdateItemCategoryId(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateItemCategoryId] = useMutation<TData, TVariables>(
    UPDATE_ITEM_CATEGORY_ID,
    opts,
  )

  return updateItemCategoryId
}
