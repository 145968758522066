import { TabPanel, TabView } from 'primereact/tabview'
import { useTranslation } from 'react-i18next'
import BreadCrumbBar from '@/redesign/components/BreadCrumbBar/BreadCrumbBar'
import BankNoteAndCoinCount from '@/redesign/domains/cashbook/components/BankNoteAndCoinCount'
import BankNoteAndCoinHistory from '@/redesign/domains/cashbook/components/BankNoteAndCoinHistory'
import { Employee } from '@/schemaTypes'

export function CashBookCounts({ currentUser }: { currentUser: Employee }) {
  const { t } = useTranslation()

  return (
    <div>
      <BreadCrumbBar />
      <h1 className="font-bold text-2xl mt-2">{t('bills_and_coins_count')}</h1>

      <TabView>
        <TabPanel
          header={t('cash_book_count.bank_note_and_coin_count')}
          leftIcon="pi pi-money-bill mr-2"
        >
          <BankNoteAndCoinCount currentUser={currentUser} />
        </TabPanel>
        <TabPanel header={t('history')} leftIcon="pi pi-calendar mr-2">
          <BankNoteAndCoinHistory currentUser={currentUser} />
        </TabPanel>
      </TabView>
    </div>
  )
}
