import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputDropdown from '@/redesign/components/InputDropdown/InputDropdown'
import {
  EAutomaticPaymentType,
  EBusinessUnit,
  EManualPaymentType,
} from '@/schemaTypes'
import { isManualPaymentType, oneOf } from '@/utils/misc'

export interface PaymentTypePickerProps {
  paymentType?: EManualPaymentType | EAutomaticPaymentType | undefined | null
  onChangePaymentType?: (paymentType: EManualPaymentType) => void
  disabled?: boolean
  businessUnit?: EBusinessUnit
  includeDummy?: boolean
  includeValorizationEntriesSpecificEnums?: boolean
  isOutFlow?: boolean
  removePadding?: boolean
  carPawnDisallowedPaymentTypes?: Array<EManualPaymentType>
  label?: string
  inputContainerClassName?: string
}

export function PaymentTypePicker(props: PaymentTypePickerProps) {
  const {
    paymentType: initValue,
    onChangePaymentType,
    disabled,
    businessUnit,
    includeDummy,
    includeValorizationEntriesSpecificEnums,
    isOutFlow,
    label,
    carPawnDisallowedPaymentTypes = [
      EManualPaymentType.Card,
      EManualPaymentType.Paypal,
    ],
    inputContainerClassName = 'w-52',
  } = props
  const [paymentType, setPaymentType] = useState(initValue)
  const { t } = useTranslation()

  const paymentTypeOptions = useMemo(
    () =>
      [...manualPaymentTypes, ...automaticPaymentTypes].map((paymentType) => ({
        name: t(paymentType.label.toLowerCase()),
        value: paymentType.value,
        disabled:
          !isManualPaymentType(paymentType.value) ||
          (isOutFlow && paymentType.value === EManualPaymentType.Card) ||
          (!includeDummy && paymentType.value === EManualPaymentType.Dummy) ||
          (!includeValorizationEntriesSpecificEnums &&
            paymentType.value === EManualPaymentType.CashOnDelivery) ||
          (businessUnit === EBusinessUnit.Car &&
            oneOf(paymentType.value, carPawnDisallowedPaymentTypes)),
      })),

    [
      t,
      businessUnit,
      carPawnDisallowedPaymentTypes,
      includeDummy,
      includeValorizationEntriesSpecificEnums,
      isOutFlow,
    ],
  )

  return (
    <InputDropdown
      value={paymentType}
      label={label !== undefined ? label : t('payout_type')}
      disabled={disabled}
      className="flex flex-col md:flex-row items-start md:items-center"
      labelClassName="text-sm font-semibold w-12.5"
      inputContainerClassName={inputContainerClassName}
      options={paymentTypeOptions}
      optionLabel="name"
      appendTo="self"
      onChange={(e) => {
        const assignedPaymentType = e.target.value as
          | EManualPaymentType
          | EAutomaticPaymentType
        setPaymentType(assignedPaymentType)
        if (onChangePaymentType && isManualPaymentType(assignedPaymentType)) {
          onChangePaymentType(assignedPaymentType as EManualPaymentType)
        }
      }}
    />
  )
}

export const manualPaymentTypes = Object.values(EManualPaymentType).map(
  (c) => ({ label: c, value: c }),
)
const automaticPaymentTypes = Object.values(EAutomaticPaymentType).map((c) => ({
  label: c,
  value: c,
}))

export default PaymentTypePicker
