import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { Query, QueryGetCashBookCashFlowsArgs } from '@/schemaTypes'

export const GET_CASH_BOOK_CASH_FLOWS = gql`
  query getCashBookCashFlows($opts: CashBookCashFlowOpts!) {
    getCashBookCashFlows(opts: $opts) {
      ...CashBookCashFlowFragment
    }
  }
  ${fragments.cashBookCashFlow}
  ${fragments.fileMetadata}
`

export const GET_CASH_BOOK_CASH_FLOWS_EXPORT = gql`
  query getCashBookCashFlowsExport($opts: CashBookCashFlowOpts!) {
    getCashBookCashFlowsExport(opts: $opts) {
      ...FileMetadataFragment
    }
  }
  ${fragments.fileMetadata}
`

type TData = { getCashBookCashFlows: Query['getCashBookCashFlows'] }
type TVariables = QueryGetCashBookCashFlowsArgs

export function useGetCashBookCashFlows(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_CASH_BOOK_CASH_FLOWS,
    opts,
  )
  useShowErrorsPopup(queryResult.error)
  return {
    queryResult,
    cashBookCashFlows: queryResult.data?.getCashBookCashFlows,
  }
}
