import { UppyFileChangeResults, UppyUploader } from '../UppyUploader'
import LinkIcon from '@material-ui/icons/Link'
import { TextField } from '@mui/material'
import * as React from 'react'
import { Fragment } from 'react'
import { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ALLOWED_FILE_TYPES } from '@/constants'
import {
  Deal,
  ECountry,
  EShippingLabelDirection,
  EShippingLabelEntity,
  EShippingLabelFileSource,
  EStandardShipmentType,
  IAddress,
  Item,
  ShippingLabelCreateArgs,
} from '@/schemaTypes'
import SenderReceiver from './SenderReceiver'
import styles from './ShippingLabel.module.scss'

interface ShippingLabelUploadProps {
  deal: Deal
  item?: Item
  directionType: EShippingLabelDirection
  entityType: EShippingLabelEntity
  shippingPartner: EStandardShipmentType
  shippingLabelCreateArgsFromUpload: ShippingLabelCreateArgs | undefined
  setShippingLabelCreateArgsFromUpload
}

const ShippingLabelUpload: FunctionComponent<ShippingLabelUploadProps> = (
  props,
) => {
  const {
    deal,
    item,
    directionType,
    entityType,
    shippingPartner,
    shippingLabelCreateArgsFromUpload,
    setShippingLabelCreateArgsFromUpload,
  } = props
  const { t } = useTranslation()
  const [senderAddressIsValid, setSenderAddressIsValid] = useState(false)
  const [receiverAddressIsValid, setReceiverAddressIsValid] = useState(false)

  const [createSenderArgs, setCreateSenderArgs] = useState<IAddress>({})
  const [createReceiverArgs, setCreateReceiverArgs] = useState<IAddress>({})
  const [createArgs, setCreateArgs] = useState<ShippingLabelCreateArgs>({
    dealId: deal._id,
    itemId: item ? item._id : undefined,
    shippingPartner: shippingPartner,
    direction: directionType,
    entity: entityType,
    shippingLabelFileSource: EShippingLabelFileSource.Upload,
    receiverAddress: createSenderArgs,
    receiverName: '',
    senderAddress: createSenderArgs,
    senderName: '',
  })

  useEffect(() => {
    setShippingLabelCreateArgsFromUpload({ ...createArgs })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createArgs])

  const onFileChanged = ([results]: UppyFileChangeResults[]) => {
    setShippingLabelCreateArgsFromUpload({
      ...shippingLabelCreateArgsFromUpload,
      labelFile: 'newFile' in results ? results.newFile : null,
      shippingPartner: shippingPartner,
    })
  }

  return (
    <Fragment>
      <SenderReceiver
        deal={deal}
        item={item}
        directionType={directionType}
        entityType={entityType}
        fileSource={EShippingLabelFileSource.Upload}
        shippingPartner={shippingPartner}
        createSenderArgs={createSenderArgs}
        setCreateSenderArgs={setCreateSenderArgs}
        createReceiverArgs={createReceiverArgs}
        setCreateReceiverArgs={setCreateReceiverArgs}
        createArgs={createArgs}
        setCreateArgs={setCreateArgs}
        senderAddressIsValid={senderAddressIsValid}
        setSenderAddressIsValid={setSenderAddressIsValid}
        receiverAddressIsValid={receiverAddressIsValid}
        setReceiverAddressIsValid={setReceiverAddressIsValid}
      />

      <div className={styles.fileDiv} style={{ marginLeft: '0.7rem' }}>
        <UppyUploader
          disableUploadButton={!senderAddressIsValid || !receiverAddressIsValid}
          allowedFileTypes={[...ALLOWED_FILE_TYPES, '.pdf']}
          initialFiles={[]}
          shouldNotConfirmBeforeRemove
          onFilesChanged={onFileChanged}
        />
      </div>

      {senderAddressIsValid && receiverAddressIsValid ? (
        <div className={styles.codeDiv}>
          <TextField
            error={!shippingLabelCreateArgsFromUpload?.trackingCode}
            variant="standard"
            sx={{ width: '200px' }}
            className="u-mr-25"
            value={shippingLabelCreateArgsFromUpload?.trackingCode}
            label={t('tracking_code')}
            onChange={(e) =>
              setShippingLabelCreateArgsFromUpload({
                ...shippingLabelCreateArgsFromUpload,
                trackingCode: e.target.value,
                shippingPartner: shippingPartner,
                trackingLink: buildTrackingLink(
                  shippingPartner,
                  ECountry.At,
                  e.target.value,
                ),
              })
            }
          />

          <br />
          <br />
          <TextField
            focused
            error={!shippingLabelCreateArgsFromUpload?.trackingLink}
            variant="standard"
            sx={{ width: '200px' }}
            className="u-mr-25"
            value={shippingLabelCreateArgsFromUpload?.trackingLink}
            label={t('tracking_link')}
            InputProps={{
              readOnly: true,
            }}
            // onChange={e =>
            //   setShippingLabelCreateArgsFromUpload({
            //     ...shippingLabelCreateArgsFromUpload,
            //     trackingLink: e.target.value,
            //     shippingPartner: shippingPartner,
            //   })
            // }
          />
          <a
            target="_blank"
            style={{ marginLeft: '-1rem', verticalAlign: 'bottom' }}
            href={shippingLabelCreateArgsFromUpload?.trackingLink ?? ''}
            rel="noreferrer"
          >
            <LinkIcon />
          </a>
        </div>
      ) : (
        ''
      )}
    </Fragment>
  )
}

const buildTrackingLink = (
  shipingPartner: EStandardShipmentType,
  region: ECountry,
  trackingCode: string,
) => {
  switch (shipingPartner) {
    case EStandardShipmentType.Post: {
      return `https://www.post.${region.toLowerCase()}/sv/sendungsdetails?snr=${trackingCode}`
    }
    case EStandardShipmentType.DpdStandard: {
      return `https://www.mydpd.${region.toLowerCase()}/?f=parcel.load&p=${trackingCode}`
    }
    default:
      return null
  }
}

export default ShippingLabelUpload
