import {
  IsBoolean,
  IsDate,
  IsNotEmpty,
  IsOptional,
  IsString,
} from 'class-validator'
import { EQuestionType, EQuestionValueMode, ItemQuestion } from '@/schemaTypes'

export class ItemQuestionValidation {
  @IsDate()
  validFrom: Date

  @IsDate()
  validTo: Date

  @IsNotEmpty()
  order: number

  @IsNotEmpty()
  questionType: EQuestionType

  @IsNotEmpty()
  valueMode: EQuestionValueMode

  @IsNotEmpty()
  titleKey: string

  @IsString()
  @IsOptional()
  infoKey?: string

  @IsBoolean()
  @IsOptional()
  isInternal?: boolean

  constructor(itemQuestion: ItemQuestion) {
    this.validFrom = itemQuestion.validFrom
    this.validTo = itemQuestion.validTo
    this.order = itemQuestion.order
    this.questionType = itemQuestion.questionType
    this.valueMode = itemQuestion.valueMode
    this.titleKey = itemQuestion.titleKey
    this.infoKey = itemQuestion.infoKey ?? undefined
    this.isInternal = itemQuestion.isInternal ?? undefined
  }
}
