import { CategorySelectorWithMultiple } from '../CategorySelectorWithMultiple/CategorySelectorWithMultiple'
import { MultiLevelCategorySelector } from '../MultiLevelCategorySelector/MultiLevelCategorySelector'
import React from 'react'
import { ItemCategory } from '@/schemaTypes'
import { GetParentCategories } from './GetParentCategories'

// Comment by CAS-1531 Can not select CAR as category on item questions
// const defaultRestrictions = ['car', 'motorrad', 'CarStored', 'MotorradStored']
const defaultRestrictions = []

export interface CategorySelectorProps {
  onChange?: (itemCategoryIds: string[]) => void
  categories: ItemCategory[]
  multiple?: boolean
  hideLabel?: boolean
  label?: string | React.ReactNode
  currentCategories: ItemCategory[]
  restrictions?: string[]
  manualUpdate?: boolean
  disabled?: boolean
  disableClear?: boolean
  initialCategorieIds?: string[]
}

export function CategorySelector(props: CategorySelectorProps) {
  const { onChange, multiple, hideLabel, label, disabled, disableClear } = props
  const restrictions = props.restrictions ?? defaultRestrictions
  const { categories, currentCategories } = restrictCategoriesForAll(
    restrictions,
    props.categories,
    props.currentCategories,
  )

  if (multiple) {
    return (
      <CategorySelectorWithMultiple
        onChange={onChange}
        currentCategories={currentCategories}
        categories={categories}
        disableClear={disableClear}
        initialCategorieIds={props.initialCategorieIds}
      />
    )
  }

  return (
    <div>
      {categories[0] ? (
        <MultiLevelCategorySelector
          hideLabel={hideLabel}
          label={label}
          manualUpdate={props.manualUpdate}
          allCategories={categories}
          onChange={onChange}
          parentCategories={GetParentCategories(
            currentCategories[0]?._id,
            categories,
          ).reverse()}
          categories={currentCategories}
          disabled={disabled}
        />
      ) : null}
    </div>
  )
}

function restrictCategoriesForAll(
  categoryRestrictions: string[],
  categories: ItemCategory[],
  currentCategories: ItemCategory[],
) {
  //We are running the restricategories for every restriction type, and these are mutually exclusive, so should work as expected.
  //For example, if category is car OR bike, it will be car or bike, and nothing else es available option.
  //Otherwise, the items themselves will get reduced by each item.
  let filteredCategories: ItemCategory[] = categories
  let filteredCurrentCategories: ItemCategory[] = currentCategories

  for (const restriction of categoryRestrictions) {
    const { categories, currentCategories } = restrictCategoriesFor(
      restriction,
      filteredCategories,
      filteredCurrentCategories,
    )
    filteredCategories = categories
    filteredCurrentCategories = currentCategories
  }

  return {
    categories: filteredCategories,
    currentCategories: filteredCurrentCategories,
  }
}

function restrictCategoriesFor(
  categoryRestriction: string,
  categories: ItemCategory[],
  currentCategories: ItemCategory[],
): { categories: ItemCategory[]; currentCategories: ItemCategory[] } {
  let restrictedCategories: ItemCategory[] = []
  let restrictedCurrentCategories: ItemCategory[] = []

  const restrictionTriggered = currentCategories.some(
    (category) => category.name === categoryRestriction,
  )

  if (restrictionTriggered) {
    //If the restricted type is in the current categories, no other parent category is selectable.
    restrictedCategories = [...currentCategories]
    restrictedCurrentCategories = currentCategories
  } else {
    //If the restricted type is NOT in the current categories, it is not even selectable.
    restrictedCurrentCategories = currentCategories
    restrictedCategories = categories.filter(
      (category) => category.name !== categoryRestriction,
    )
  }

  return {
    categories: restrictedCategories,
    currentCategories: restrictedCurrentCategories,
  }
}

export default CategorySelector
