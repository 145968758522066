import {
  ProductAvailableProperty,
  ProductFormData,
  ProductVariant,
  PropertyValue,
} from '../interfaces'
import { Box, Button, Checkbox, Typography } from '@material-ui/core'
import { FormikErrors } from 'formik'
import { cloneDeep } from 'lodash'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { LabelWrapper } from '@/pages/InApp/Products/create'
import { EProductPropertyType } from '@/schemaTypes'
import { printLocalDate } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'

interface Props {
  variants: ProductVariant[]
  availableProperties: ProductAvailableProperty[]
  setFieldValue: <T extends keyof ProductFormData>(
    field: T,
    value: ProductFormData[T],
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<ProductFormData>>
}

export function ProductVariants(props: Props) {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const { availableProperties, variants, setFieldValue } = props
  const allVariantsEnabled = !variants.find((c) => !c.enabled)

  const onToggleVariantEnabled = useCallback(
    (variantIndex: number) => {
      if (!props.variants) {
        return
      }

      const variants = cloneProductVariants(props.variants)
      variants[variantIndex].enabled = !variants[variantIndex].enabled
      setFieldValue('variants', variants)
    },
    [props.variants, setFieldValue],
  )

  function unwrapVariantPropertyValue(value: PropertyValue) {
    return value.propertyDefinitionType === EProductPropertyType.Boolean
      ? value.value
        ? t('yes')
        : t('no')
      : value.propertyDefinitionType === EProductPropertyType.Number
        ? value.value
        : value.propertyDefinitionType === EProductPropertyType.Translation
          ? value.value[language]
          : null
  }

  if (!variants.length) {
    return (
      <Box style={{ marginTop: 20 }}>
        <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography variant="h5" style={{ width: 200 }}>
            {t('variants')}
          </Typography>
        </Box>
        {t('product_page.single_variant')}
      </Box>
    )
  }

  return (
    <Box className="mt-1.5 w-fit">
      <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Typography variant="h5" style={{ width: 200 }}>
          {t('variants')}
        </Typography>
      </Box>

      {variants.length && (
        <div className="flex justify-start mt-4 mb-6">
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="large"
            onClick={() =>
              setFieldValue(
                'variants',
                variants.map((variant) => {
                  return {
                    ...variant,
                    enabled: !allVariantsEnabled,
                  }
                }),
              )
            }
          >
            {t(
              allVariantsEnabled
                ? 'product_page.deactivate_all_variants'
                : 'product_page.activate_all_variants',
            )}
          </Button>
        </div>
      )}

      {availableProperties && (
        <Box className="flex flex-row">
          {availableProperties.map((p) => {
            return (
              <LabelWrapper className="w-[140px]">
                {p.propertyDefinition?.key}
              </LabelWrapper>
            )
          })}
          <LabelWrapper className="w-[140px]">{t('release_date')}</LabelWrapper>
          <LabelWrapper className="w-[140px]">{t('active')}</LabelWrapper>
        </Box>
      )}

      {variants.map((variant, variantIndex) => {
        const propertiesValues = variant.propertiesValues
        return (
          <Box className="flex flex-row">
            {propertiesValues.map((value) => (
              <Box className="flex w-[140px] pt-2">
                {unwrapVariantPropertyValue(value)}
              </Box>
            ))}
            <label className="py-2 w-[140px]">
              {printLocalDate(variant.releasedAt, {
                timezone: defaultTimezone,
              })}
            </label>
            <Checkbox
              checked={variant.enabled}
              onChange={() => {
                onToggleVariantEnabled(variantIndex)
              }}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export const cloneProductVariants = (variants: ProductVariant[]) => {
  return variants.map((variant) => {
    return {
      ...variant,
      propertiesValues: variant.propertiesValues.map((v) => ({
        ...v,
        value: cloneDeep(v.value),
      })),
    }
  }) as Array<ProductVariant>
}
