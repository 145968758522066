import { MenuItem, Select, TextareaAutosize } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { Context } from '@/context'
import SaveIcon from '@/images/save.svg'
import {
  EItemNotSellablePermanentlyReason,
  EItemNotSellableTemporaryReason,
  EItemNotSellableType,
  EItemStatusType,
  EReturnReasons,
  Item,
  ItemEvent,
  ItemEventMeta,
} from '@/schemaTypes'
import { getNow } from '@/utils/time'
import { EHintType } from './ItemStatus'
import styles from './ItemStatus.module.scss'

export interface ItemNotSellableStatusProps {
  item: Item
  onChangeItemStatus: (newEvent: ItemEvent, hint: EHintType) => void
}

const ItemNotSellableStatus: FunctionComponent<ItemNotSellableStatusProps> = (
  props,
) => {
  const { item, onChangeItemStatus } = props
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const notSellableTypes = Object.values(EItemNotSellableType)

  // not sellable reasons are a subset of return reasons
  const damageCaseReasonsList = [
    { label: '', value: '' },
    { label: t('technical_error'), value: EReturnReasons.TechnicalError },
    { label: t('account_lock'), value: EReturnReasons.AccountLock },
    { label: t('optical_error'), value: EReturnReasons.OpticalError },
    { label: t('fake'), value: EReturnReasons.Fake },
    { label: t('other'), value: EReturnReasons.Other },
  ]

  const permanentlyNotSellableReasons = [
    { label: '', value: '' },
    {
      label: t('self_use'),
      value: EItemNotSellablePermanentlyReason.PersonalUse,
    },
    {
      label: t('damage_case'),
      value: EItemNotSellablePermanentlyReason.DamageCase,
    },
    {
      label: t('item_lost'),
      value: EItemNotSellablePermanentlyReason.ItemLost,
    },
  ]

  const temporaryNotSellableReasons = [
    { label: '', value: '' },
    {
      label: t('temporary_notsellable_seasonal'),
      value: EItemNotSellableTemporaryReason.Seasonal,
    },
    {
      label: t('temporary_notsellable_repairing'),
      value: EItemNotSellableTemporaryReason.Repairing,
    },
  ]

  const [notSellableType, setNotSellableType] = useState('')
  const [damageCaseReason, setDamageCaseReason] = useState<string>('')
  const [untilDate, setUntilDate] = useState(dayjs().add(1, 'd'))
  const [fromDate, setFromDate] = useState(dayjs())
  const [description, setDescription] = useState('')
  const [
    latestReturnReasonFromPreviousStatuses,
    setLatestReturnReasonFromPreviousStatuses,
  ] = useState('')
  const [permanentlyNotSellableReason, setPermanentlyNotSellableReason] =
    useState('')
  const [temporaryNotSellableReason, setTemporaryNotSellableReason] =
    useState('')

  const getLatestItemEventForStatus = (status: EItemStatusType) => {
    if (!item.events || item.events.length < 0) {
      return null
    }

    return [...item.events].reverse().find((event) => event.event === status)
  }

  useEffect(() => {
    // Check if we can initialize notSellableReason based on previous Return_Reasons
    const lastReturned = getLatestItemEventForStatus(EItemStatusType.Returned)
    const lastReturnRequested = getLatestItemEventForStatus(
      EItemStatusType.ReturnRequested,
    )

    let lastReturned_Reason: string | undefined = undefined
    let lastReturned_Reason_Is_Valid: boolean = false
    let lastReturnRequested_Reason: string | undefined = undefined
    let lastReturnRequested_Reason_Is_Valid: boolean = false

    if (lastReturned && lastReturned.meta && lastReturned.meta.statusReason) {
      lastReturned_Reason = lastReturned.meta.statusReason

      // This is for checking if the return_reason derived from RETURNED is also valid for NOT_SELLABLE or not
      if (
        damageCaseReasonsList
          .map((c) => c.value.toString())
          .includes(lastReturned?.meta?.statusReason.toString())
      )
        lastReturned_Reason_Is_Valid = true
    }

    if (
      lastReturnRequested &&
      lastReturnRequested.meta &&
      lastReturnRequested.meta.statusReason
    ) {
      lastReturnRequested_Reason = lastReturnRequested.meta.statusReason

      // This is for checking if the return_reason derived from RETURN_REQUESTED is also valid for NOT_SELLABLE or not
      if (
        damageCaseReasonsList
          .map((c) => c.value.toString())
          .includes(lastReturnRequested?.meta?.statusReason)
      )
        lastReturnRequested_Reason_Is_Valid = true
    }

    if (lastReturned_Reason && !lastReturnRequested_Reason) {
      setLatestReturnReasonFromPreviousStatuses(lastReturned_Reason)
      if (lastReturned_Reason_Is_Valid) setDamageCaseReason(lastReturned_Reason)
    } else if (!lastReturned_Reason && lastReturnRequested_Reason) {
      setLatestReturnReasonFromPreviousStatuses(lastReturnRequested_Reason)
      if (lastReturnRequested_Reason_Is_Valid)
        setDamageCaseReason(lastReturnRequested_Reason)
    } else if (
      lastReturned_Reason &&
      lastReturned_Reason_Is_Valid &&
      lastReturnRequested_Reason &&
      lastReturnRequested_Reason_Is_Valid
    ) {
      // we need to peek the latest
      if (lastReturned?.createdAt > lastReturnRequested?.createdAt) {
        setLatestReturnReasonFromPreviousStatuses(lastReturned_Reason)
        setDamageCaseReason(lastReturned_Reason)
      } else {
        setLatestReturnReasonFromPreviousStatuses(lastReturnRequested_Reason)
        setDamageCaseReason(lastReturnRequested_Reason)
      }
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setUntilDateValue = (value) => {
    setUntilDate(value)
  }

  const setFromDateValue = (value) => {
    setFromDate(value)
  }

  const saveNewStatus = () => {
    if (notSellableType === '') {
      return showInfo(t('not_sellable_type_is_not_set'))
    } else if (
      notSellableType === EItemNotSellableType.Permanently &&
      permanentlyNotSellableReason === ''
    ) {
      return showInfo(t('not_sellable_sub_type_is_not_set'))
    } else if (
      notSellableType === EItemNotSellableType.Permanently &&
      permanentlyNotSellableReason ===
        EItemNotSellablePermanentlyReason.DamageCase &&
      damageCaseReason === ''
    ) {
      return showInfo(
        t('not_sellable_permanently_damage_case_reason_is_not_set'),
      )
    } else if (
      notSellableType === EItemNotSellableType.Temporary &&
      temporaryNotSellableReason === ''
    ) {
      return showInfo(t('not_sellable_sub_type_is_not_set'))
    }

    const now = getNow()
    let meta: ItemEventMeta

    if (notSellableType === EItemNotSellableType.Permanently) {
      if (
        permanentlyNotSellableReason ===
        EItemNotSellablePermanentlyReason.PersonalUse
      ) {
        meta = {
          notSellableType: notSellableType as EItemNotSellableType,
          permanentlyNotSellableReason:
            permanentlyNotSellableReason as EItemNotSellablePermanentlyReason,
          validFrom: fromDate,
          description: description,
        }
      } else {
        meta = {
          notSellableType: notSellableType as EItemNotSellableType,
          permanentlyNotSellableReason:
            permanentlyNotSellableReason as EItemNotSellablePermanentlyReason,
          statusReason: damageCaseReason as string,
          validFrom: fromDate,
          description: description,
        }
      }
    } else {
      meta = {
        notSellableType: notSellableType as EItemNotSellableType,
        temporaryNotSellableReason:
          temporaryNotSellableReason as EItemNotSellableTemporaryReason,
        description: description,
        // validFrom: dayjs().toDate(),
        validUntil: untilDate,
      }
    }

    onChangeItemStatus(
      {
        createdAt: now,
        timestamp: now,
        event: EItemStatusType.NotSellable,
        meta: meta,
      },
      EHintType.NONE,
    )
  }

  return (
    <Fragment>
      <div
        onClick={saveNewStatus}
        className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
      >
        <ReactSVG className={`${styles.saveIcon} u-pl-15`} src={SaveIcon} />
      </div>
      <table>
        <tr className={`${styles.notSellableTr}`}>
          <td>
            {t('type')}:
            <Select
              className="u-ml-sm"
              value={notSellableType}
              onChange={(e) => {
                const tempType = e.target.value as EItemNotSellableType
                setNotSellableType(tempType)
              }}
            >
              {notSellableTypes.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </td>
        </tr>

        <tr
          className={`${styles.notSellableTr}`}
          hidden={notSellableType !== EItemNotSellableType.Permanently}
        >
          <td>
            {t('not_sellable_reason')}:
            <Select
              className="u-ml-sm"
              value={permanentlyNotSellableReason}
              onChange={(e) => {
                const tempType = e.target
                  .value as EItemNotSellablePermanentlyReason
                setPermanentlyNotSellableReason(tempType)
              }}
            >
              {permanentlyNotSellableReasons.map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  {name.label}
                </MenuItem>
              ))}
            </Select>
          </td>
        </tr>

        <tr
          className={`${styles.notSellableTr}`}
          hidden={notSellableType !== EItemNotSellableType.Temporary}
        >
          <td>
            {t('not_sellable_reason')}:
            <Select
              className="u-ml-sm"
              value={temporaryNotSellableReason}
              onChange={(e) => {
                const tempType = e.target
                  .value as EItemNotSellableTemporaryReason
                setTemporaryNotSellableReason(tempType)
              }}
            >
              {temporaryNotSellableReasons.map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  {name.label}
                </MenuItem>
              ))}
            </Select>
          </td>
        </tr>

        <tr
          className={`${styles.notSellableTr}`}
          hidden={notSellableType !== EItemNotSellableType.Permanently}
        >
          <td>
            {t('from')}:
            <KeyboardDatePicker
              className={' u-ml-5'}
              clearable
              format="DD.MM.YYYY"
              value={fromDate ?? null}
              onChange={(value) => setFromDateValue(value)}
            />
          </td>
        </tr>

        <tr
          className={`${styles.notSellableTr}`}
          hidden={notSellableType !== EItemNotSellableType.Temporary}
        >
          <td>
            {t('to')}:
            <KeyboardDatePicker
              className={' u-ml-5'}
              clearable
              disablePast
              format="DD.MM.YYYY"
              value={untilDate ?? null}
              onChange={(value) => setUntilDateValue(value)}
            />
          </td>
        </tr>

        <tr
          className={`${styles.notSellableTr}`}
          hidden={
            notSellableType !== EItemNotSellableType.Permanently ||
            permanentlyNotSellableReason !==
              EItemNotSellablePermanentlyReason.DamageCase
          }
        >
          <td>
            {t('status_reason')}:
            <Select
              className="u-ml-sm"
              value={damageCaseReason}
              onChange={(e) => {
                setDamageCaseReason(e.target.value as string)
              }}
            >
              {damageCaseReasonsList.map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  {name.label}
                </MenuItem>
              ))}
            </Select>
            {latestReturnReasonFromPreviousStatuses ? (
              <Fragment>
                <span
                  style={{ marginLeft: '1.2rem', fontSize: '0.7rem' }}
                >{`${t('last_return_reason')}: `}</span>
                <span
                  style={{ color: 'red', fontSize: '0.7rem' }}
                >{` ${t(latestReturnReasonFromPreviousStatuses)}`}</span>
              </Fragment>
            ) : (
              ''
            )}
          </td>
        </tr>

        <tr className={`${styles.notSellableTr}`}>
          <td>
            {t('description')}:
            <TextareaAutosize
              rowsMin={4}
              placeholder={t('any_description_want_to_add')}
              style={{ width: '100%' }}
              onChange={(e) => setDescription(e.target.value)}
            />
          </td>
        </tr>
      </table>
    </Fragment>
  )
}

export default ItemNotSellableStatus
