import { TextField } from '@material-ui/core'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { Context } from '@/context'
import { useParseErrors } from '@/hooks'
import ClearIcon from '@/images/clear.svg'
import EditIcon from '@/images/edit.svg'
import SaveIcon from '@/images/save.svg'
import styles from './Item.module.scss'

type ItemTitleProps = {
  itemTitle: string
}

export const ItemTitle = ({ itemTitle }: ItemTitleProps) => {
  return (
    <Typography
      component="div"
      variant="h5"
      gutterBottom
      classes={{ root: styles.headTitle }}
    >
      {itemTitle}
    </Typography>
  )
}

type EditableItemTitleProps = {
  itemTitle: string
  onSaveTitle: (title: string) => Promise<unknown>
}

export const EditableItemTitle = ({
  itemTitle,
  onSaveTitle,
}: EditableItemTitleProps) => {
  const { showErrors } = useContext(Context)
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const [editedTitle, setEditedTitle] = useState(itemTitle)
  const parseErrors = useParseErrors()

  const editedTitleHasValue = editedTitle !== ''

  const handleSaveTitle = () => {
    onSaveTitle(editedTitle)
      .then(({ error }) => {
        setEditMode(false)
        if (error) {
          showErrors(parseErrors(error))
        }
      })
      .catch((error) => {
        showErrors(parseErrors(error))
      })
  }

  useEffect(() => {
    if (editMode === false) {
      setEditedTitle(itemTitle)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode])

  return editMode ? (
    <Box display="flex">
      <TextField
        style={{ width: '400px' }}
        error={!editedTitleHasValue}
        helperText={
          !editedTitleHasValue
            ? t('validation.validation_is_not_empty')
            : undefined
        }
        value={editedTitle}
        onChange={(e) => setEditedTitle(e.target.value)}
      />
      {editedTitleHasValue && (
        <ReactSVG
          className={`${styles.infoIcon} u-pl-30`}
          src={SaveIcon}
          onClick={handleSaveTitle}
        />
      )}
      <ReactSVG
        className={`${styles.infoIcon} u-pl-30`}
        src={ClearIcon}
        onClick={() => setEditMode(false)}
      />
    </Box>
  ) : (
    <Box display="flex" alignItems="baseline">
      <ItemTitle itemTitle={itemTitle} />
      <ReactSVG
        className={`${styles.infoIcon} u-pl-30 h-6`}
        src={EditIcon}
        onClick={() => setEditMode(true)}
      />
    </Box>
  )
}
