import { Messages } from 'primereact/messages'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { BlacklistInformation } from '@/schemaTypes'

export const CustomerBlacklistWarning = ({
  blacklistInformation,
}: {
  blacklistInformation: BlacklistInformation | null | undefined
}) => {
  const { t } = useTranslation()
  const msgs = useRef<Messages>(null)

  useEffect(() => {
    const hasCashyAccount = blacklistInformation?.hasCashyAccount

    if (msgs.current) {
      msgs.current.clear()
      msgs.current.show(
        hasCashyAccount
          ? [
              {
                sticky: true,
                severity: 'error',
                summary: 'Error',
                detail: t('user_account_currently_on_blacklist'),
                closable: false,
              },
            ]
          : [
              {
                sticky: true,
                severity: 'warn',
                summary: 'Warning',
                detail: t('an_blacklist_entry_exists'),
                closable: false,
              },
            ],
      )
    }
  }, [t, blacklistInformation?.hasCashyAccount])

  if (!blacklistInformation?.isAddedToBlacklist) {
    return null
  }

  return <Messages ref={msgs} />
}
