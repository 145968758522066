import { useUpdateDealItemInternalSaleData } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { useMutationShowingErrors } from '@/hooks'
import { useGetActivePartners } from '@/hooks/getActivePartners'
import { Deal, EBusinessUnit, InternalSaleDataArgs } from '@/schemaTypes'
import ItemValorizationSalesTable, {
  ItemValorizationSalesTableProps,
} from './ItemValorizationSalesTable'

export interface ItemValorizationSalesTableContainerProps
  extends Pick<
    ItemValorizationSalesTableProps,
    'item' | 'directPurchaseFactor' | 'timezone'
  > {
  deal: Deal
  businessUnit: EBusinessUnit
}

export function ItemValorizationSalesTableContainer(
  props: ItemValorizationSalesTableContainerProps,
) {
  const { deal, item, businessUnit } = props
  const { t } = useTranslation()

  const updateDealItemInternalSaleData = useMutationShowingErrors({
    mutation: useUpdateDealItemInternalSaleData(),
    successMessage: t('update_successful'),
  })

  const onUpdateInternalSaleData = (internalSaleData: InternalSaleDataArgs) => {
    updateDealItemInternalSaleData({
      variables: {
        dealId: deal._id,
        itemId: item._id,
        args: {
          date: internalSaleData.date,
          price: internalSaleData.price,
          purchasedDirectly: internalSaleData.purchasedDirectly,
          buyerData: internalSaleData.buyerData,
          isCashyPrice: internalSaleData.isCashyPrice,
        },
      },
    })
  }

  const partnersResult = useGetActivePartners()
  const partners = partnersResult.partners ?? []

  return (
    <ItemValorizationSalesTable
      {...props}
      onUpdateInternalSaleData={onUpdateInternalSaleData}
      partners={partners}
      businessUnit={businessUnit}
    />
  )
}

export default ItemValorizationSalesTableContainer
