import { useMutationShowingErrors } from '.'
import { TFunction } from 'i18next'
import { get, isEmpty, isNull, isUndefined, uniqueId } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useGetCustomer, useUpdateCustomer } from '@/domains/customers/hooks'
import { useMergeCustomers } from '@/domains/customers/hooks/mergeCustomers'
import { useGetCustomDealsCount } from '@/domains/deals/hooks/getCustomDealsCount'
import { useGetDealsCount } from '@/domains/deals/hooks/getDealsCount'
import {
  EIdFilterConditions,
  EMergingCustomerReason,
  MutationUpdateCustomerArgs,
  OCustomerAddress,
} from '@/schemaTypes'

export type Row = {
  label: string
  fieldPath?: string
  rowId: string
  fieldType: EFieldTypes
  firstValue?: any
  secondValue?: any
  mergedValue?: any
  error?: string
}

export type Group = {
  title: string
  subTitle?: string
  rows: Row[]
}

enum EAddressType {
  isBillingAddress = 'isBillingAddress',
  isDeliveryAddress = 'isDeliveryAddress',
  isRegistrationAddress = 'isRegistrationAddress',
}

export type AddressGroup = Group & {
  // because we select a group of field, so not use row firstValue, secondValue, mergedValue
  firstValue?: OCustomerAddress
  secondValue?: OCustomerAddress
  mergedValue?: OCustomerAddress
  addressType: EAddressType
}

enum MergeFields {
  customerNumber = 'customerNumber',
  firstname = 'firstname',
  lastname = 'lastname',
  dateOfBirth = 'dateOfBirth',
  email = 'email',
  iban = 'iban',
  paypalAddress = 'paypalAddress',
  note = 'note',
  sex = 'sex',
  acquiredBy = 'acquiredBy',
  citizenship = 'additionalInformationForCarPawn.citizenship',
  registeredInAustriaSince = 'additionalInformationForCarPawn.registeredInAustriaSince',
  lastResidentialAddressSince = 'additionalInformationForCarPawn.lastResidentialAddressSince',
  employed = 'additionalInformationForCarPawn.employed',
  paySlipsPresented = 'additionalInformationForCarPawn.paySlipsPresented',
  hasSeizureEvident = 'additionalInformationForCarPawn.hasSeizureEvident',
  inInsolvencyDatabaseIn3Years = 'additionalInformationForCarPawn.inInsolvencyDatabaseIn3Years',
  appearSerious = 'additionalInformationForCarPawn.appearSerious',
  hasCarPawnRelationshipWithCashy = 'additionalInformationForCarPawn.hasCarPawnRelationshipWithCashy',
  identityUploadLinks = 'identityUploadLinks',
  identityType = 'identityType',
  identityNumber = 'identityNumber',
  registrationCertificate = 'registrationCertificate',
  uploadedFiles = 'uploadedFiles',
  street = 'street',
  houseNumber = 'houseNumber',
  stairway = 'stairway',
  floor = 'floor',
  door = 'door',
  city = 'city',
  zipCode = 'zipCode',
  country = 'country',
}

export enum EFieldTypes {
  string = 'string',
  date = 'date',
  boolean = 'boolean',
  number = 'number',
  file = 'file',
  fileList = 'fileList',
}

const genRowId = () => uniqueId('row')

const genGroupConfigForAddress = (t: TFunction): Row[] => [
  {
    fieldType: EFieldTypes.string,
    fieldPath: MergeFields.street,
    label: t('address.street'),
    rowId: genRowId(),
  },
  {
    fieldType: EFieldTypes.string,
    fieldPath: MergeFields.houseNumber,
    label: t('address.house_number'),
    rowId: genRowId(),
  },
  {
    fieldType: EFieldTypes.string,
    fieldPath: MergeFields.stairway,
    label: t('address.stairway'),
    rowId: genRowId(),
  },
  {
    fieldType: EFieldTypes.string,
    fieldPath: MergeFields.floor,
    label: t('address.floor'),
    rowId: genRowId(),
  },
  {
    fieldType: EFieldTypes.string,
    fieldPath: MergeFields.door,
    label: t('address.door'),
    rowId: genRowId(),
  },
  {
    fieldType: EFieldTypes.string,
    fieldPath: MergeFields.city,
    label: t('address.city'),
    rowId: genRowId(),
  },
  {
    fieldType: EFieldTypes.string,
    fieldPath: MergeFields.zipCode,
    label: t('address.zip_code'),
    rowId: genRowId(),
  },
  {
    fieldType: EFieldTypes.string,
    fieldPath: MergeFields.country,
    label: t('address.country'),
    rowId: genRowId(),
  },
]

const convertValue = (
  obj: object,
  fieldType: EFieldTypes,
  fieldPath: any,
): string => {
  const v = get(obj, fieldPath)
  if (isUndefined(v) || isNull(v)) return ''

  switch (fieldType) {
    case EFieldTypes.boolean:
      return (v as boolean).toString()
    default:
      return v
  }
}

const revertValueType = (value: string, fieldType: EFieldTypes) => {
  switch (fieldType) {
    case EFieldTypes.boolean:
      return value === 'true'
    default:
      return value
  }
}

const genDealCountInput = (customerId: string) => ({
  opts: {
    filter: {
      customerId: {
        condition: EIdFilterConditions.Equals,
        value: customerId,
      },
    },
  },
})

const useGetDealTotal = (customerId: string): number => {
  const { dealsTotal } = useGetDealsCount({
    variables: genDealCountInput(customerId),
    skip: !customerId,
  })

  const { customDealsTotal } = useGetCustomDealsCount({
    variables: genDealCountInput(customerId),
    skip: !customerId,
  })

  return dealsTotal + customDealsTotal
}

const useGetCustomerInfo = (customerId: string) => {
  const { customer } = useGetCustomer({
    variables: {
      customerId: customerId,
    },
    skip: !customerId,
  })

  return customer
}

export const useCustomerMerging = (customerIds: [string, string]) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [groups, setGroups] = useState<Group[]>([])
  const [addressGroups, setAddressGroups] = useState<AddressGroup[]>([])
  const [reason, setReason] = useState<EMergingCustomerReason>(
    EMergingCustomerReason.Duplicated,
  )
  const configGroups: Group[] = useMemo(() => {
    return [
      {
        title: '',
        rows: [
          {
            fieldPath: MergeFields.customerNumber,
            fieldType: EFieldTypes.number,
            label: t('customer.customer_number'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.firstname,
            fieldType: EFieldTypes.string,
            label: t('customer.firstname'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.lastname,
            fieldType: EFieldTypes.string,
            label: t('customer.lastname'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.dateOfBirth,
            fieldType: EFieldTypes.date,
            label: t('customer.date_of_birth'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.email,
            fieldType: EFieldTypes.string,
            label: t('customer.customer_email'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.iban,
            fieldType: EFieldTypes.string,
            label: t('customer.iban'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.paypalAddress,
            fieldType: EFieldTypes.string,
            label: t('paypal_address'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.note,
            fieldType: EFieldTypes.string,
            label: t('notes'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.sex,
            fieldType: EFieldTypes.string,
            label: t('customer.sex'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.acquiredBy,
            fieldType: EFieldTypes.string,
            label: t('acquired_by'),
            rowId: genRowId(),
          },
        ],
      },
      {
        title: t('documents'),
        rows: [
          {
            fieldPath: MergeFields.identityUploadLinks,
            fieldType: EFieldTypes.fileList,
            label: t('customer.identity_files'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.identityType,
            fieldType: EFieldTypes.string,
            label: t('type'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.identityNumber,
            fieldType: EFieldTypes.string,
            label: t('id_number'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.registrationCertificate,
            fieldType: EFieldTypes.file,
            label: t('registration_certificate'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.uploadedFiles,
            fieldType: EFieldTypes.fileList,
            label: t('customer.files'),
            rowId: genRowId(),
          },
        ],
      },
      {
        title: t('customer.label'),
        rows: [
          {
            fieldPath: MergeFields.citizenship,
            fieldType: EFieldTypes.string,
            label: t('citizenship'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.registeredInAustriaSince,
            fieldType: EFieldTypes.date,
            label: t('registered_in_austria_since'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.lastResidentialAddressSince,
            fieldType: EFieldTypes.date,
            label: t('last_residential_address_since'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.employed,
            fieldType: EFieldTypes.boolean,
            label: t('employed'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.paySlipsPresented,
            fieldType: EFieldTypes.boolean,
            label: t('payslips_presented'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.hasSeizureEvident,
            fieldType: EFieldTypes.boolean,
            label: t('seizure_evident'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.inInsolvencyDatabaseIn3Years,
            fieldType: EFieldTypes.boolean,
            label: t('customer_in_insolvency_database_in_3_year'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.appearSerious,
            fieldType: EFieldTypes.boolean,
            label: t('customer_appears_serious'),
            rowId: genRowId(),
          },
          {
            fieldPath: MergeFields.hasCarPawnRelationshipWithCashy,
            fieldType: EFieldTypes.boolean,
            label: t('customer_car_pawn_relationship_with_cashy'),
            rowId: genRowId(),
          },
        ],
      },
    ] as Group[]
  }, [t])
  const configAddressGroups: AddressGroup[] = useMemo(() => {
    return [
      {
        title: t('addresses'),
        subTitle: t('address.is_billing_address'),
        addressType: EAddressType.isBillingAddress,
        rows: genGroupConfigForAddress(t),
      },
      {
        title: '',
        subTitle: t('address.is_delivery_address'),
        addressType: EAddressType.isDeliveryAddress,
        rows: genGroupConfigForAddress(t),
      },
      {
        title: '',
        subTitle: t('address.is_registration_address'),
        addressType: EAddressType.isRegistrationAddress,
        rows: genGroupConfigForAddress(t),
      },
    ] as AddressGroup[]
  }, [t])
  const first = useGetCustomerInfo(customerIds[0])
  const firstDealTotal = useGetDealTotal(customerIds[0])
  const second = useGetCustomerInfo(customerIds[1])
  const secondDealTotal = useGetDealTotal(customerIds[1])
  const addressGroupNotMerge = useMemo(() => {
    return addressGroups.some((g) => {
      const { firstValue, secondValue, mergedValue } = g
      return firstValue && secondValue && !mergedValue
    })
  }, [addressGroups])
  const enableSubmitButton: boolean = useMemo(() => {
    if (!first || !second) {
      return false
    }

    const hasError = groups
      .map((g) => g.rows)
      .flat()
      .some((r) => {
        const { firstValue, secondValue, mergedValue, fieldType } = r
        if (fieldType === EFieldTypes.fileList) {
          return (
            !isEmpty(firstValue) &&
            !isEmpty(secondValue) &&
            isEmpty(mergedValue)
          )
        }
        const hasError =
          firstValue &&
          secondValue &&
          firstValue !== secondValue &&
          !mergedValue
        return hasError
      })

    return !hasError && !addressGroupNotMerge
  }, [groups, first, second, addressGroupNotMerge])
  const updateCustomer = useMutationShowingErrors({
    mutation: useUpdateCustomer(),
  })

  const mergeCustomers = useMutationShowingErrors({
    mutation: useMergeCustomers(),
    successMessage: t('customer.updated'),
  })

  const initMergedValue = useCallback(
    (firstValue: any, secondValue: any, fieldType: EFieldTypes): any => {
      if (fieldType === EFieldTypes.fileList) {
        if (isEmpty(firstValue) && !isEmpty(secondValue)) {
          return secondValue
        }

        if (!isEmpty(firstValue) && isEmpty(secondValue)) {
          return firstValue
        }

        return undefined
      }

      // force use first value if second customer has no deal
      if (firstDealTotal === 0 && secondDealTotal !== 0) {
        return secondValue
      }

      // force use second value if first customer has no deal
      if (firstDealTotal !== 0 && secondDealTotal === 0) {
        return firstValue
      }

      // if both values are the same
      if (firstValue && secondValue && firstValue === secondValue) {
        return firstValue
      }

      // if second value is empty
      if (firstValue && !secondValue) {
        return firstValue
      }

      // if first value is empty
      if (!firstValue && secondValue) {
        return secondValue
      }

      return undefined
    },
    [firstDealTotal, secondDealTotal],
  )

  const initMergedAddressValue = useCallback(
    (firstValue: OCustomerAddress, secondValue: OCustomerAddress) => {
      // force use first value if second customer has no deal
      if (firstDealTotal === 0 && secondDealTotal !== 0) {
        return secondValue
      }

      // force use second value if first customer has no deal
      if (firstDealTotal !== 0 && secondDealTotal === 0) {
        return firstValue
      }

      // if second value is empty object
      if (firstValue && !secondValue) {
        return firstValue
      }

      // if first value is empty object
      if (!firstValue && secondValue) {
        return secondValue
      }

      return undefined
    },
    [firstDealTotal, secondDealTotal],
  )

  const extractInitValueOfAField = useCallback(
    (row: Row) => {
      const { fieldPath, fieldType } = row
      const firstValue = convertValue(first, fieldType, fieldPath)
      const secondValue = convertValue(second, fieldType, fieldPath)
      const mergedValue = initMergedValue(firstValue, secondValue, fieldType)

      return {
        firstValue,
        secondValue,
        mergedValue,
      }
    },
    [first, second, initMergedValue],
  )

  const selectValue = (rowId: string, from: 'left' | 'right') => {
    const newRows = groups.map((group) => {
      const r = {
        ...group,
      }
      r.rows = group.rows.map((row) => {
        if (row.rowId !== rowId) return row
        const mergedValue = convertValue(
          from === 'left' ? first : second,
          row.fieldType,
          row.fieldPath,
        )
        return {
          ...row,
          mergedValue,
        }
      })

      return r
    })
    setGroups(newRows)
  }

  const selectGroupValue = (
    addressType: EAddressType,
    from: 'left' | 'right',
  ) => {
    const newAddressGroups = addressGroups.map((group) => {
      if (group.addressType !== addressType) {
        return group
      }

      const r = {
        ...group,
        mergedValue: (from === 'left' ? first : second).addresses?.find(
          (a) => a[addressType] === true,
        ),
      }

      return r
    })
    setAddressGroups(newAddressGroups)
  }

  const removeMergedValue = (rowId: string, addressType?: EAddressType) => {
    if (rowId) {
      setGroups((prevGroups) => {
        return prevGroups.map((group) => {
          const r = {
            ...group,
          }
          r.rows = group.rows.map((row) => {
            if (row.rowId !== rowId) return row
            return {
              ...row,
              mergedValue: undefined,
            }
          })

          return r
        })
      })
    }

    if (addressType) {
      setAddressGroups((prevGroups) => {
        return prevGroups.map((group) => {
          if (group.addressType !== addressType) {
            return group
          }

          const r: AddressGroup = {
            ...group,
            mergedValue: undefined,
          }
          r.rows = group.rows.map((row) => {
            return {
              ...row,
              mergedValue: undefined,
            }
          })

          return r
        })
      })
    }
  }

  const initFormValues = useCallback(() => {
    if (!first || !second) return
    const newNormalGroups = configGroups.map((group) => {
      const r = {
        ...group,
      }
      r.rows = group.rows.map((row) => {
        const { firstValue, secondValue, mergedValue } =
          extractInitValueOfAField(row)
        return {
          ...row,
          firstValue,
          secondValue,
          mergedValue,
        }
      })

      return r
    })
    setGroups(newNormalGroups)

    const newAdressGroups = configAddressGroups.map((group) => {
      const firstValue = first.addresses?.find((a) => a[group.addressType])
      const secondValue = second.addresses?.find((a) => a[group.addressType])

      return {
        ...group,
        firstValue,
        secondValue,
        mergedValue: initMergedAddressValue(firstValue, secondValue),
      }
    })
    setAddressGroups(newAdressGroups)
  }, [
    configGroups,
    configAddressGroups,
    first,
    second,
    extractInitValueOfAField,
    initMergedAddressValue,
  ])

  const reset = () => {
    initFormValues()
  }

  const detectPrimaryCustomer = useCallback(() => {
    const customerNumber = groups
      .map((g) => g.rows)
      .flat()
      .find((r) => r.fieldPath === MergeFields.customerNumber)?.mergedValue
    if (!customerNumber) return {}

    if (customerNumber === first?.customerNumber) {
      return {
        primaryCustomer: first,
        secondaryCustomer: second,
      }
    }

    return {
      primaryCustomer: second,
      secondaryCustomer: first,
    }
  }, [groups, first, second])

  const generateUpdatingObject = useCallback((): MutationUpdateCustomerArgs => {
    const result: MutationUpdateCustomerArgs = {}
    const carPawn: MutationUpdateCustomerArgs['additionalInformationForCarPawn'] =
      {}

    // (not address) fields
    const allRows = groups.map((g) => g.rows).flat()
    allRows.forEach((row) => {
      if (row.fieldPath.startsWith('additionalInformationForCarPawn.')) {
        const nestedFieldPath = row.fieldPath.replace(
          'additionalInformationForCarPawn.',
          '',
        )
        if (row.mergedValue) {
          carPawn[nestedFieldPath] = revertValueType(
            row.mergedValue,
            row.fieldType,
          )
        }

        return
      }

      if (row.mergedValue) {
        result[row.fieldPath] = revertValueType(row.mergedValue, row.fieldType)
      }
    })

    if (Object.keys(carPawn).length > 0) {
      result.additionalInformationForCarPawn = carPawn
    }

    // address objects
    const addresses = addressGroups
      .filter((g) => g.mergedValue)
      .map((g) => {
        return {
          ...g.mergedValue,
          isBillingAddress: false,
          isDeliveryAddress: false,
          isRegistrationAddress: false,
          ...{
            [g.addressType]: true, // override address type
          },
        } as OCustomerAddress
      })
      .filter(Boolean)
    if (addresses.length > 0) {
      result.addresses = addresses
    }

    return result
  }, [groups, addressGroups])

  const submit = async () => {
    if (!enableSubmitButton) {
      return
    }

    // get primary customer id
    const updateCustomerVariables = generateUpdatingObject()
    const { primaryCustomer, secondaryCustomer } = detectPrimaryCustomer()
    updateCustomerVariables._id = primaryCustomer?._id

    // deactive merged customer, change email (add postfix _id to email)
    const [p1, p2] = secondaryCustomer.email.split('@')
    await updateCustomer({
      variables: {
        _id: secondaryCustomer?._id,
        email: `${p1}_${secondaryCustomer._id.toString()}@${p2}`,
        deactivated: true,
        deactivatedAt: new Date(),
      },
    })

    // update primary customer by form value
    await updateCustomer({
      variables: updateCustomerVariables,
    })

    // merge customers (new api)
    await mergeCustomers({
      variables: {
        input: {
          primaryCustomerId: primaryCustomer?._id,
          mergedCustomerId: secondaryCustomer?._id,
          reason,
        },
      },
    })

    history.push('/inApp/customers')
  }

  const cancel = () => {
    history.push('/inApp/customers')
  }

  useEffect(() => {
    initFormValues()
  }, [initFormValues])

  return {
    enableSubmitButton,
    groups,
    addressGroups,
    reset,
    submit,
    cancel,
    selectValue,
    selectGroupValue,
    reason,
    setReason,
    removeMergedValue,
    customer: {
      first,
      second,
    },
  }
}
