import styled from '@emotion/styled'
import { Box, Typography } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { KeyboardDatePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SelectOptionInput from '@/components/SelectOptionInput'
import AddMoreIcon from '@/images/ico_addMoreStatus.svg'
import { EDateFilterMode } from '@/schemaTypes'
import { defaultTimezone } from '@/utils/time'

export interface DateFilter<T extends string> {
  names: T[]
  mode: EDateFilterMode
  from?: Date | null
  to?: Date | null
}

export interface DateFilterInputProps<T extends string> {
  filter?: DateFilter<T> | null
  onFilter: (data?: DateFilter<T>) => void
  options: Array<T>
  labelStyle?: React.CSSProperties
  label?: string | JSX.Element
  secondaryLabel?: string
}

export default function DateFilterInput<T extends string>({
  filter,
  onFilter,
  options,
  labelStyle,
  label,
  secondaryLabel,
}: DateFilterInputProps<T>) {
  const { t } = useTranslation()

  const defaultValue = options[0]

  const handleFromDateChange = (date) => {
    const fromDate = date?.tz(defaultTimezone).startOf('day')

    if (!filter) {
      return
    }

    onFilter({
      ...filter,
      from: fromDate?.toDate(),
      to: filter.to && fromDate?.isAfter(dayjs(filter.to)) ? null : filter.to,
    })
  }

  const handleToDateChange = (date) => {
    const toDate = date?.tz(defaultTimezone).startOf('day')

    if (!filter) {
      return
    }

    onFilter({
      ...filter,
      to: toDate?.toDate(),
      from:
        filter.from && toDate?.isBefore(dayjs(filter.from))
          ? null
          : filter.from,
    })
  }

  const modeOptions = useMemo(
    () =>
      Object.keys(EDateFilterMode).map((option) => ({
        label: option,
        value: EDateFilterMode[option],
      })),
    [],
  )

  const inputOptions = useMemo(
    () =>
      options.map((option) => ({
        label: option,
        value: option,
      })),
    [options],
  )

  return (
    <Box display="flex" justifyContent="start">
      <Typography variant="body1" gutterBottom style={labelStyle}>
        {label}&nbsp;&nbsp;
      </Typography>
      <SelectOptionInput
        selectProps={{ style: { minWidth: 100 } }}
        value={filter?.mode}
        onChange={(value) => {
          onFilter({ ...filter, mode: value, names: [defaultValue] })
        }}
        label={t('mode')}
        menuItems={modeOptions}
      />
      {filter?.names.map((name, index) => (
        <>
          <SelectOptionInput
            selectProps={{ style: { minWidth: 150 } }}
            value={name ?? ''}
            onChange={(value) => {
              const newStatusNames = filter.names
              newStatusNames[index] = value
              onFilter({ ...filter, names: [...newStatusNames] })
            }}
            menuItems={inputOptions}
            showResetButton
            onReset={() => {
              const newFilters = filter.names.filter(
                (_, resetIndex) => resetIndex !== index,
              )
              onFilter(
                newFilters.length
                  ? { ...filter, names: newFilters }
                  : undefined,
              )
            }}
          />
          {index === filter.names.length - 1 && index < 4 && (
            <AddMoreIconImg
              src={AddMoreIcon}
              onClick={() =>
                onFilter({ ...filter, names: [...filter.names, defaultValue] })
              }
            />
          )}
        </>
      ))}
      {filter?.mode === EDateFilterMode.Was && (
        <Box display="flex" style={{ marginTop: -14 }}>
          <Typography
            component="div"
            variant="body1"
            gutterBottom
            style={{ marginTop: 21, paddingLeft: 15, paddingRight: 15 }}
          >
            {secondaryLabel}
          </Typography>

          <KeyboardDatePicker
            clearable
            disableFuture
            format="DD.MM.YYYY"
            label={t('from')}
            value={filter.from ?? null}
            onChange={handleFromDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          {filter.from && (
            <DeleteForeverIcon
              style={{ cursor: 'pointer', marginTop: 23 }}
              onClick={() => {
                onFilter({
                  ...filter,
                  from: null,
                })
              }}
            />
          )}

          <KeyboardDatePicker
            clearable
            disableFuture
            format="DD.MM.YYYY"
            label={t('to')}
            value={filter.to ?? null}
            style={{ marginLeft: 10 }}
            onChange={handleToDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          {filter.to && (
            <DeleteForeverIcon
              style={{ cursor: 'pointer', marginTop: 23 }}
              onClick={() => {
                onFilter({
                  ...filter,
                  to: null,
                })
              }}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

const AddMoreIconImg = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 7px;
  margin-top: 8px;
  cursor: pointer;
`
