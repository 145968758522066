import { TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopy from '@material-ui/icons/FileCopy'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import { Link } from 'react-router-dom'
import { Employee } from '@/schemaTypes'
import globalStyles from '@/styles/global.module.scss'

dayjs.extend(utc)

export interface EmployeesRowProps {
  employee: Employee
  deleteEmployee: () => void
}

const EmployeesRow = (props: EmployeesRowProps) => {
  const { employee } = props
  const id = employee._id ?? ''

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell align="right">{employee.email}</TableCell>
      <TableCell align="right">{employee.firstname}</TableCell>
      <TableCell align="right">{employee.lastname}</TableCell>
      <TableCell align="right">
        {employee.dateOfBirth
          ? dayjs(employee.dateOfBirth).format('DD.MM.YYYY')
          : ''}
      </TableCell>
      <TableCell align="right">{employee.phone}</TableCell>
      <TableCell align="right">{employee.sex}</TableCell>
      <TableCell align="right">{employee.roles.join(' ')}</TableCell>
      <TableCell>
        <Link to={`/inApp/configurations/employees/create/${id}`}>
          <FileCopy />
        </Link>
        <Link to={`/inApp/configurations/employees/edit/${id}`}>
          <EditIcon />
        </Link>
        <DeleteIcon
          className={globalStyles.clickable}
          onClick={props.deleteEmployee}
        />
      </TableCell>
    </TableRow>
  )
}

export default EmployeesRow
