import styled from '@emotion/styled'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EProductPriceMode, Product } from '@/schemaTypes'
import { CrawledBase } from './CrawledBase'
import { MetalBase } from './MetalBase'
import PropertyBase from './PropertyBase'
import VariantBase from './VariantBase'

interface Props {
  product: Product
}

function ProductPrices(props: Props) {
  const { t } = useTranslation()
  const { product } = props
  const [mode, setMode] = React.useState<EProductPriceMode | undefined>(
    product.price?.mode,
  )

  return (
    <Wrapper>
      <div>
        <Typography
          gutterBottom
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          {t('price_mode')}
        </Typography>
        {mode !== EProductPriceMode.Crawled && (
          <div>
            <RadioGroup
              name="mode"
              value={mode}
              onChange={(e, v) => setMode(v as EProductPriceMode)}
            >
              {[
                EProductPriceMode.ManualVariant,
                EProductPriceMode.ManualProperty,
                EProductPriceMode.ManualPreciousMetal,
              ].map((mode) => (
                <RadioItem key={mode}>
                  <FormControlLabel
                    value={mode}
                    control={<Radio size="small" color="primary" />}
                    label={
                      <div>
                        <Typography style={{ fontWeight: 'bold' }}>
                          {t(mode.toLowerCase())}
                        </Typography>
                        <Typography variant="caption">
                          {t(`${mode}_DESC`.toLowerCase())}
                        </Typography>
                      </div>
                    }
                  />
                </RadioItem>
              ))}
            </RadioGroup>
          </div>
        )}
      </div>

      {mode === EProductPriceMode.Crawled && <CrawledBase product={product} />}
      {mode === EProductPriceMode.ManualVariant && (
        <VariantBase product={product} />
      )}
      {mode === EProductPriceMode.ManualProperty && (
        <PropertyBase product={product} />
      )}
      {mode === EProductPriceMode.ManualPreciousMetal && (
        <MetalBase product={product} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 16px 0;
`

const RadioItem = styled.div`
  margin-bottom: 1rem;

  label {
    display: flex;
    background-color: #ede7f8;
    color: #683ab7c0;
    border-radius: 0.25rem;
    margin: 0;
    padding: 1rem;
    flex: 1;
  }
`

export default ProductPrices
