import AmountInput from '../AmountInput'
import Countries from '../Countries'
import TextInput from '../TextInput'
import { Typography } from '@material-ui/core'
import { useRef } from 'react'
import Geosuggest from 'react-geosuggest'
import { useTranslation } from 'react-i18next'
import { IAddress } from '@/schemaTypes'
import styles from './CustomerAddress.module.scss'

export interface CustomerAddressProps {
  address: IAddress
  onChangeAddress: (address: IAddress) => void
}

const CustomerAddress = (props: CustomerAddressProps) => {
  const { onChangeAddress, address } = props
  const { t } = useTranslation()

  const geoSuggest = useRef<Geosuggest>()

  const handleSuggestSelect = (suggest: any, addressState: any) => {
    if (!suggest) return

    const addressComponents = suggest.gmaps.address_components
    const houseNumberComponent = addressComponents.find((component) =>
      component.types.includes('street_number'),
    )
    const streetComponent = addressComponents.find((component) =>
      component.types.includes('route'),
    )
    const cityComponent = addressComponents.find((component) =>
      component.types.includes('locality'),
    )
    const zipCodeComponent = addressComponents.find((component) =>
      component.types.includes('postal_code'),
    )

    const houseNumber =
      (houseNumberComponent && houseNumberComponent.long_name) || ''
    const street = (streetComponent && streetComponent.long_name) || ''
    const city = (cityComponent && cityComponent.long_name) || ''
    const zipCode = (zipCodeComponent && zipCodeComponent.long_name) || ''
    const lat = suggest.location.lat
    const lng = suggest.location.lng

    const resetData = {
      stairway: '',
      floor: '',
      door: '',
    }

    const newData = {
      queryString: suggest.label,
      houseNumber,
      street,
      city,
      zipCode,
      coordinates: {
        lat: lat,
        lng: lng,
      },
    }

    if (
      addressState.street !== street ||
      addressState.houseNumber !== houseNumber ||
      addressState.city !== city ||
      addressState.zipCode !== zipCode
    ) {
      onChangeAddress({ ...address, ...resetData, ...newData })
    } else {
      onChangeAddress({ ...address, ...newData })
    }
  }

  return (
    <div>
      <div className="u-mb-30">
        <Typography variant="body1" gutterBottom className="u-mr-25">
          {t('search')}
        </Typography>
        <Geosuggest
          ref={geoSuggest}
          initialValue={address.queryString ?? ''}
          placeholder={t('search')}
          types={['address']}
          country={[address.countryCode ?? 'at']}
          autoActivateFirstSuggest
          suggestsClassName={styles.geoVisible}
          inputClassName="MuiInputBase-input MuiInput-input"
          onSuggestSelect={(e) => handleSuggestSelect(e, address)}
          style={{
            input: {
              width: '100%',
            },
          }}
        />
      </div>
      {/*  <TextInput
        inputWidth="400px"
        className="u-mb-20"
        value={address.queryString ?? ''}
        onChange={value => onChangeAddress({ ...address, queryString: value })}
        label={<div style={{ minWidth: 116 }}>{t('search')}</div>}
      /> */}
      <div className="u-flex">
        <TextInput
          value={address.street ?? ''}
          onChange={(value) => onChangeAddress({ ...address, street: value })}
          label={t('address.street')}
          inputWidth="400px"
          className="u-mr-25"
        />
        <TextInput
          inputWidth="70px"
          value={address.houseNumber ?? ''}
          onChange={(value) =>
            onChangeAddress({ ...address, houseNumber: value })
          }
          label={t('address.house_number')}
        />
      </div>
      <div className="u-flex">
        <TextInput
          className="u-mr-25"
          value={address.city ?? ''}
          onChange={(value) => onChangeAddress({ ...address, city: value })}
          label={t('address.city')}
        />
        <TextInput
          value={address.zipCode ?? ''}
          onChange={(value) => onChangeAddress({ ...address, zipCode: value })}
          label={t('address.zip_code')}
        />
      </div>
      <div className="u-flex">
        <TextInput
          inputWidth="70px"
          className="u-mr-25"
          value={address.stairway ?? ''}
          onChange={(value) => onChangeAddress({ ...address, stairway: value })}
          label={t('address.stairway')}
        />
        <TextInput
          inputWidth="70px"
          className="u-mr-25"
          value={address.floor ?? ''}
          onChange={(value) => onChangeAddress({ ...address, floor: value })}
          label={t('address.floor')}
        />
        <TextInput
          inputWidth="70px"
          value={address.door ?? ''}
          onChange={(value) => onChangeAddress({ ...address, door: value })}
          label={t('address.door')}
        />
      </div>
      <TextInput
        value={address.state ?? ''}
        onChange={(value) => onChangeAddress({ ...address, state: value })}
        label={t('address.state')}
      />
      {/* <TextInput value={address.address1 ?? ''} onChange={value => onChangeAddress({ ...address, address1: value })} label={t('address.address_1')} />
      <TextInput value={address.address2 ?? ''} onChange={value => onChangeAddress({ ...address, address2: value })} label={t('address.address_2')} /> */}
      <div className="u-flex">
        <AmountInput
          value={address.coordinates?.lat ?? 0}
          onChange={(value) => {
            const updatedAddress = address
            const updatedCoordinates = updatedAddress.coordinates ?? {
              lat: 0,
              lng: 0,
            }
            updatedCoordinates.lat =
              typeof value === 'number' && !Number.isNaN(value) ? value : 0
            onChangeAddress({ ...address, coordinates: updatedCoordinates })
          }}
          label={t('latitude')}
        />
        <AmountInput
          value={address.coordinates?.lng ?? 0}
          onChange={(value) => {
            const updatedAddress = address
            const updatedCoordinates = updatedAddress.coordinates ?? {
              lat: 0,
              lng: 0,
            }
            updatedCoordinates.lng =
              typeof value === 'number' && !Number.isNaN(value) ? value : 0
            onChangeAddress({ ...address, coordinates: updatedCoordinates })
          }}
          label={t('longitude')}
        />
      </div>
      {/* <TextInput value={address.country ?? ''} onChange={value => onChangeAddress({ ...address, country: value })} label={t('address.country')} /> */}
      <Countries
        value={address.countryCode}
        onChange={(value) =>
          onChangeAddress({ ...address, countryCode: value })
        }
        label={t('address.country')}
      />
      <TextInput
        multiline={true}
        value={address.note ?? ''}
        onChange={(value) => onChangeAddress({ ...address, note: value })}
        label={t('note')}
      />
    </div>
  )
}

export default CustomerAddress
