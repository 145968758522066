import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Mutation,
  MutationRefreshValorizationCalculationForDealItemArgs,
} from '@/schemaTypes'
import { dealCalculationFragments, dealFragments } from './dealFragments'

const REFRESH_VALORIZATION_CALCULATION_FOR_DEAL_ITEM = gql`
  mutation refreshValorizationCalculationForDealItem($dealId: ObjectId!) {
    refreshValorizationCalculationForDealItem(dealId: $dealId) {
      _id
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
    }
  }
  ${dealFragments.dealItem}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = {
  refreshValorizationCalculationForDealItem: Mutation['refreshValorizationCalculationForDealItem']
}
type TVariables = MutationRefreshValorizationCalculationForDealItemArgs

export function useRefreshValorizationCalculationForDealItem(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [refreshValorizationCalculationForDealItem] = useMutation<
    TData,
    TVariables
  >(REFRESH_VALORIZATION_CALCULATION_FOR_DEAL_ITEM, opts)
  return refreshValorizationCalculationForDealItem
}
