import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetResponseTemplateArgs } from '@/schemaTypes'

const GET_RESPONSE_TEMPLATE = gql`
  query getResponseTemplate($responseTemplateId: ObjectId!) {
    getResponseTemplate(responseTemplateId: $responseTemplateId) {
      _id
      name
      subject
      openingText
      editableText
      closingText
    }
  }
`

type TData = { getResponseTemplate: Query['getResponseTemplate'] }
type TVariables = QueryGetResponseTemplateArgs

export function useGetResponseTemplate(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_RESPONSE_TEMPLATE, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.responseTemplateId,
  })

  useShowErrorsPopup(queryResult.error)

  const responseTemplate = opts.variables?.responseTemplateId
    ? queryResult.data?.getResponseTemplate
    : null

  return { queryResult, responseTemplate }
}
