import Modal from '../Modal'
import styled from '@emotion/styled'
import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Note } from '@/schemaTypes'
import NoteElement from './NoteElement'

export interface INoteBuild {
  title: string
  notes: Partial<Note>[]
  hideUserInfo?: boolean
}

interface NotePopupProps {
  open: boolean
  onClose: () => void
  noteBuilds: INoteBuild[]
  customerBlacklistNote?: JSX.Element
}

interface NoteBuildProps {
  noteBuild: INoteBuild
}

const NoteBuild = ({ noteBuild }: NoteBuildProps) => {
  const sortedNotes = orderBy(noteBuild.notes, ['updatedAt'], ['desc'])
  return (
    <NoteBuildWrapper>
      <h5>{noteBuild.title}</h5>
      {sortedNotes.map((note) => (
        <NoteElement
          note={note}
          readOnly
          hideUserInfo={noteBuild.hideUserInfo}
        />
      ))}
    </NoteBuildWrapper>
  )
}

const NotePopup = ({
  noteBuilds,
  open,
  onClose,
  customerBlacklistNote,
}: NotePopupProps) => {
  const { t } = useTranslation()
  return (
    <Modal open={open} onClose={onClose} fullWidth maxWidth="md">
      <CenterHeader>{t('popup_notes')}</CenterHeader>
      {customerBlacklistNote ?? null}
      {noteBuilds.map((noteBuild) => (
        <NoteBuild noteBuild={noteBuild} />
      ))}
    </Modal>
  )
}

const CenterHeader = styled.h3`
  text-align: center;
`

const NoteBuildWrapper = styled.div`
  margin-top: 20px;
`

export default NotePopup
