import { dealFragments } from '../dealFragments'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { useMutationShowingErrors } from '@/hooks'
import { Deal, Mutation, MutationCancelDealArgs } from '@/schemaTypes'

const CANCEL_DEAL = gql`
  mutation cancelDeal($dealId: ObjectId!) {
    cancelDeal(dealId: $dealId) {
      _id
      events {
        ...DealEventFragment
      }
      pickupData {
        ...TransportDataFragment
      }
      dropoffData {
        ...TransportDataFragment
      }
    }
  }
  ${dealFragments.event}
  ${dealFragments.transportData}
`

type TData = { cancelDeal: Mutation['cancelDeal'] }
type TVariables = MutationCancelDealArgs

export function useOnDealStatusCanceled(deal: Deal) {
  const { t } = useTranslation()

  const [cancelDeal] = useMutation<TData, TVariables>(CANCEL_DEAL, {
    variables: { dealId: deal._id },
  })

  return useMutationShowingErrors({
    mutation: cancelDeal,
    successMessage: t('update_successful'),
  })
}
