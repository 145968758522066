import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeleteCustomDealFormArgs } from '@/schemaTypes'

const DELETE_CUSTOM_DEAL_FORM = gql`
  mutation updateCustomDealForm($customDealFormId: ObjectId!) {
    deleteCustomDealForm(customDealFormId: $customDealFormId)
  }
`

type TData = { deleteCustomDealForm: Mutation['deleteCustomDealForm'] }
type TVariables = MutationDeleteCustomDealFormArgs

export function useDeleteCustomDealForm(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [mutation] = useMutation<TData, TVariables>(DELETE_CUSTOM_DEAL_FORM, {
    refetchQueries: ['getCustomDealForms'],
    ...opts,
  })
  return mutation
}
