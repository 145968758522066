import styled from '@emotion/styled'
import { IconButton, TableCell, TableRow, TextField } from '@material-ui/core'
import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import PasteClipboardIcon from '@/images/ico_PasteClipboard.svg'
import { ECountry } from '@/schemaTypes'

export const IbanPaypalInformation = ({
  deal,
  ibanOrPaypalEmail,
  germanLabel,
  austriaLabel,
}) => {
  const { t } = useTranslation()

  return (
    <TableRow>
      <TableCell width={160}>
        {deal.company.headquarter?.countryCode === ECountry.At
          ? t(austriaLabel)
          : t(germanLabel)}
      </TableCell>
      <TableCell>
        <div
          style={{
            position: 'relative',
            top: 3,
            left: 5,
            display: 'inline-block',
          }}
        >
          <TextField
            style={{ width: 160 }}
            disabled
            value={ibanOrPaypalEmail}
          />
          <CopyToClipboard text={ibanOrPaypalEmail}>
            <IconButton aria-label="copy" size="small">
              <PasteClipboardIconImg src={PasteClipboardIcon} />
            </IconButton>
          </CopyToClipboard>
        </div>
      </TableCell>
    </TableRow>
  )
}

const PasteClipboardIconImg = styled.img`
  cursor: pointer;
`
