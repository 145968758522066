import withFieldWrapper from '../withFieldWrapper/withFieldWrapper'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { InputNumber, InputNumberProps } from 'primereact/inputnumber'
import { Slider } from 'primereact/slider'

interface InputProps extends InputNumberProps {
  isInvalid?: boolean
  disabled?: boolean
  sliderMinValue?: number
  sliderMaxValue?: number
  sliderStep?: number
  onChangeSlider: (value: number | [number, number]) => void
}

const InputSlider = withFieldWrapper(
  ({
    isInvalid,
    onChangeSlider,
    disabled,
    sliderMinValue,
    sliderMaxValue,
    sliderStep,
    ...props
  }: InputProps) => {
    return (
      <>
        <InputNumberStyled
          className={classNames({ 'p-invalid': isInvalid })}
          {...props}
        />
        <Slider
          value={+(props.value ?? 0)}
          onChange={(e) => onChangeSlider(e.value)}
          min={sliderMinValue}
          max={sliderMaxValue}
          step={sliderStep}
          disabled={disabled}
        />
      </>
    )
  },
)

const InputNumberStyled = styled(InputNumber)`
  &&& {
    .p-inputnumber-input {
      font-size: 0.875rem;
      height: 2.375rem;
      padding: 0.66rem;
    }
  }
`
export default InputSlider
