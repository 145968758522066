import { IsDate, IsNotEmpty, IsNumber } from 'class-validator'
import { MaterialPrice } from '@/schemaTypes'

export class MaterialPriceValidation {
  @IsDate()
  date: Date

  @IsNotEmpty()
  material: string

  @IsNumber()
  price: number

  @IsNumber()
  mu: number

  @IsNumber()
  sigma: number

  constructor(materialPrice: MaterialPrice) {
    this.date = materialPrice.date
    this.material = materialPrice.material
    this.price = materialPrice.price
    this.mu = materialPrice.mu
    this.sigma = materialPrice.sigma
  }
}
