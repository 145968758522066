import StorageFacilitiesTable from '.'
import React from 'react'
import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import getPagePath from '@/utils/getPagePath'
import StorageFacilitiesCreate from './create'
import StorageFacilitiesEdit from './edit/[storageFacilityId]'

export function StorageFacilitiesRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/create/:storageFacilityId')}
        render={(props) => (
          <StorageFacilitiesCreate
            {...props}
            storageFacilityId={props.match.params.storageFacilityId as string}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/edit/:storageFacilityId')}
        render={(props) => (
          <StorageFacilitiesEdit
            {...props}
            storageFacilityId={props.match.params.storageFacilityId as string}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/create')}
        render={(props) => (
          <StorageFacilitiesCreate {...props} storageFacilityId={null} />
        )}
      />
      <SentryRoute
        path={getPagePath(props)}
        component={StorageFacilitiesTable}
      />
    </Switch>
  )
}

export default StorageFacilitiesRoutes
