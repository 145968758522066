import { QueryHookOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import useDebouncedQuery from '@/hooks/useDebouncedQuery'
import { Query, QueryGetRefurbedProductsByInstanceIdArgs } from '@/schemaTypes'

const GET_REFURBED_PRODUCTS_BY_INSTANCE_ID = gql`
  query getRefurbedProductsByInstanceId($instanceId: String!) {
    getRefurbedProductsByInstanceId(instanceId: $instanceId) {
      instanceId
      nameDe
      searchTags
    }
  }
`

type TData = {
  getRefurbedProductsByInstanceId: Query['getRefurbedProductsByInstanceId']
}
type TVariables = QueryGetRefurbedProductsByInstanceIdArgs

export function useGetRefurbedProductsByInstanceId(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useDebouncedQuery<TData, TVariables>(
    GET_REFURBED_PRODUCTS_BY_INSTANCE_ID,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    refurbedProductsByInstanceId:
      queryResult.data?.getRefurbedProductsByInstanceId ?? [],
  }
}
