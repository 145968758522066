import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Mutation,
  MutationCreateProductPropertyDefinitionArgs,
} from '@/schemaTypes'

const CREATE_PRODUCT_PROPERTY_DEFINITION = gql`
  mutation createProductPropertyDefinition(
    $args: ProductPropertyDefinitionCreateArgs!
  ) {
    createProductPropertyDefinition(args: $args) {
      _id
    }
  }
`

type TData = {
  createProductPropertyDefinition: Mutation['createProductPropertyDefinition']
}
type TVariables = MutationCreateProductPropertyDefinitionArgs

export function useCreateProductPropertyDefinition(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [mutation] = useMutation<TData, TVariables>(
    CREATE_PRODUCT_PROPERTY_DEFINITION,
    opts,
  )
  return mutation
}
