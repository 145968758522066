import { QueryHookOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import useDebouncedQuery from '@/hooks/useDebouncedQuery'
import { Query, QuerySearchPrivateSalesArgs } from '@/schemaTypes'

const SEARCH_PRIVATE_SALES = gql`
  query searchPrivateSales($args: PrivateSalesFiltersArgs!) {
    searchPrivateSales(args: $args) {
      nodes {
        _id
        createdAt
        creationType
        privateSaleDate
        dealId
        itemId
        bookingNumber
        carTitle
        customerFullName
        status
        dealFinalBalance
        privateSaleTransactions {
          ...PrivateSaleTransactionFragment
        }
      }
      pageInfo {
        skip
        limit
        hasPrevPage
        hasNextPage
        total
      }
    }
  }
  ${fragments.privateSaleTransaction}
`

type TData = { searchPrivateSales: Query['searchPrivateSales'] }
type TVariables = QuerySearchPrivateSalesArgs

export function useSearchPrivateSales(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useDebouncedQuery<TData, TVariables>(
    SEARCH_PRIVATE_SALES,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
    200,
  )

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
