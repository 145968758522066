import InputAutoComplete from '../../AutoComplete/AutoComplete'
import connectFormikField from '../connectFormikField/connectFormikField'
import { ComponentProps } from 'react'

const FormikInputAutoComplete =
  connectFormikField<ComponentProps<typeof InputAutoComplete>>(
    InputAutoComplete,
  )

export default FormikInputAutoComplete
