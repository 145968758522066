import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { useContext } from 'react'
import { ReactSVG } from 'react-svg'
import TableValue from '@/components/TableValue'
import { Context } from '@/context'
import ResetIcon from '@/images/reset.svg'
import SaveIcon from '@/images/save.svg'
import styles from './ProductIdentificationTable.module.scss'

const ProductIdenfiticationTable = ({ rows }) => {
  const { isGuestUser } = useContext(Context)

  return (
    <Table className={`${styles.productIdentificationTable}`} size="small">
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.label}>
            <TableCell
              component="th"
              scope="row"
              className={styles.dateOverviewFirstColumn}
            >
              {row.label}
            </TableCell>
            <TableCell>
              <TableValue
                idPrefix={`PRODUCT_IDENTIFICATION_VALUE_`}
                label={row.label}
                value={row.value}
                onChange={isGuestUser() ? undefined : row.onChange}
                textAlign={'left'}
                inputBaseSize={12}
              />
              {row.hint && <span>{row.hint}</span>}
            </TableCell>
            <TableCell>
              {!isGuestUser() && (
                <div>
                  <div
                    onClick={row.onReset || (() => {})}
                    className={`o-media__fixed u-inline-block ${row.onReset ? '' : styles.hidden}`}
                  >
                    <ReactSVG
                      className={`${styles.infoIcon} u-pl-15`}
                      src={ResetIcon}
                    />
                  </div>
                  <div
                    id={`PRODUCT_IDENTIFICATION_SAVE_BUTTON_${row.label}`}
                    onClick={row.onSave || (() => {})}
                    className={`o-media__fixed u-inline-block ${row.onSave ? '' : styles.hidden}`}
                  >
                    <ReactSVG
                      className={`${styles.infoIcon} u-pl-15`}
                      src={SaveIcon}
                    />
                  </div>
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ProductIdenfiticationTable
