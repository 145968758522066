import styled from '@emotion/styled'
import { Button } from 'primereact/button'

export interface DownloadButtonProps {
  link: string
  label: string
  className?: string
}

const DownloadButton = (props: DownloadButtonProps) => {
  const { link, label, className } = props

  return (
    <span className={className ?? ''}>
      <a target="_blank" rel="noopener noreferrer" href={link}>
        <StyledButton label={label} severity="secondary" />
      </a>
    </span>
  )
}

const StyledButton = styled(Button)`
  &&& {
    color: var(--button-secondary-color, #fff);
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.05rem;
  }
`

export default DownloadButton
