import { ApolloQueryResult, QueryResult } from '@apollo/client'
import dayjs from 'dayjs'
import { Panel } from 'primereact/panel'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { ArrayValues } from 'type-fest'
import WarningStolenItemsPopup from '@/components/WarningStolenItemsPopup'
import TransportInfo from '@/domains/deals/components/TransportInfo'
import { useAddDealNote, useUpdateDealNote } from '@/domains/deals/hooks'
import { useCheckDealItemsPossiblyStolen } from '@/domains/deals/hooks/checkDealItemsPossiblyStolen'
import { useMutationShowingErrors } from '@/hooks'
import BreadCrumbBar from '@/redesign/components/BreadCrumbBar/BreadCrumbBar'
import { CustomerBlacklistWarning } from '@/redesign/components/CustomerBlacklistWarning/CustomerBlacklistWarning'
import NoteContainer from '@/redesign/components/Note/NoteContainer'
import NotePopup, { INoteBuild } from '@/redesign/components/Note/NotePopup'
import TitleBar from '@/redesign/components/TitleBar/TitleBar'
import {
  Deal,
  EDealType,
  ETransportMode,
  ETransportType,
  NoteCreateArgs,
  NoteUpdateArgs,
  Query,
  QueryGetDealByIdArgs,
} from '@/schemaTypes'
import {
  getLatestDealValues,
  getLatestEventForStatus,
  getRegisteredCustomer,
  isDealConnectedToRegisteredCustomer,
} from '@/utils/deal'
import { CustomerPanel } from './CustomerPanel/CustomerPanel'
import DateOverview from './DateOverview/DateOverview'
import DealEventsCalculationOverview from './DealEventsCalculationOverview/DealEventsCalculationOverview'
import DealValorization from './DealValorization/DealValorization'
import DealsChain from './DealsChain'
import ExtensionBox from './ExtensionBox/ExtensionBox'
import GeneralInfoContainer from './GeneralInfo/GeneralInfo.container'
import ItemOverview from './ItemOverview/ItemOverview'
import PaybackBox from './PaybackBox/PaybackBox'
import ReservedPaybackBox from './ReservedPaybackBox/ReservedPaybackBox'

export interface DealDetailsProps {
  deal: Deal
  queryResult: QueryResult<
    { getDealById: Query['getDealById'] },
    QueryGetDealByIdArgs
  >
  refetchDeal: (
    variables?: Partial<QueryGetDealByIdArgs> | undefined,
  ) => Promise<ApolloQueryResult<{ getDealById: Query['getDealById'] }>>
}

export function DealDetails(props: DealDetailsProps) {
  const { deal, queryResult, refetchDeal } = props
  const { t } = useTranslation()
  const [popupNotesOpen, setPopupNotesOpen] = useState(false)
  const [noteBuilds, setNoteBuilds] = useState<INoteBuild[]>([])
  const [openWarningStolenItemsPopup, setOpenWarningStolenItemsPopup] =
    useState(false)
  const elementRef = useRef<HTMLDivElement>(null)
  const [notesPanelHeight, setNotesPanelHeight] = useState<number>(261)
  const [customerPanelCollapsed, setCustomerPanelCollapsed] = useState(false)

  const event = useMemo(() => {
    const latestDealValues = getLatestDealValues(deal) ?? undefined
    const bookedEvent = getLatestEventForStatus(deal, 'DealBookedEvent')
    const verifiedEvent = getLatestEventForStatus(deal, 'DealVerifiedEvent')
    const paybackConfirmedEvent = getLatestEventForStatus(
      deal,
      'DealPaybackConfirmedEvent',
    )
    const extensionConfirmedEvent = getLatestEventForStatus(
      deal,
      'DealExtensionConfirmedEvent',
    )
    const closedEvent = getLatestEventForStatus(deal, 'DealClosedEvent')
    const onSellEvent = getLatestEventForStatus(deal, 'DealOnSellEvent')

    return {
      latestDealValues,
      bookedEvent,
      verifiedEvent,
      paybackConfirmedEvent,
      extensionConfirmedEvent,
      closedEvent,
      onSellEvent,
    }
  }, [deal])

  const warnBookedDate =
    event.bookedEvent && !event.verifiedEvent
      ? dayjs().diff(event.bookedEvent.createdAt, 'day') > 28
      : false
  const dealEvents = useMemo(
    () => deal.events.map((c) => c.__typename),
    [deal.events],
  )
  const registeredCustomer = getRegisteredCustomer(deal)

  const addDealNote = useMutationShowingErrors({
    mutation: useAddDealNote(),
    successMessage: `${t('update_successful')}!`,
  })

  const updateDealNote = useMutationShowingErrors({
    mutation: useUpdateDealNote(),
    successMessage: `${t('update_successful')}!`,
  })

  const onAddDealNote = (noteCreateArgs: NoteCreateArgs) => {
    addDealNote({
      variables: { dealId: deal._id, noteCreateArgs },
      onCompleted: () => refetchDeal(),
    })
  }

  const onUpdateDealNote = (
    noteId: string,
    noteUpdateArgs: NoteUpdateArgs,
    chainDealId?: string,
  ) => {
    updateDealNote({
      variables: { dealId: chainDealId ?? deal._id, noteId, noteUpdateArgs },
      onCompleted: () => refetchDeal(),
    })
  }

  const handleNotePanelSize = () => {
    const element = elementRef.current
    if (element) {
      setNotesPanelHeight(element?.offsetHeight)
    }
  }

  const [popupNotesChecked, setPopupNotesChecked] = useState(false)
  if (!popupNotesChecked && deal) {
    setPopupNotesChecked(true)

    handleNotePanelSize()
    const popupNotePopups: INoteBuild[] = []
    const pushPopupNotePopups = (
      title: string,
      notes: (ArrayValues<INoteBuild['notes']> & { isPopup?: boolean })[],
      hideUserInfo = false,
    ) => {
      const filteredPopupNotes = notes?.filter((note) => note.isPopup)
      if (filteredPopupNotes?.length) {
        popupNotePopups.push({
          title,
          notes: filteredPopupNotes,
          hideUserInfo,
        })
      }
    }

    pushPopupNotePopups('On Deal', deal.notes)

    deal.items.forEach((item) => {
      pushPopupNotePopups(`On Item ${item.title}`, item.notes)
    })

    if (registeredCustomer?.note && registeredCustomer?.isNotePopup) {
      pushPopupNotePopups(
        'On Customer',
        [
          {
            _id: 'customer_note',
            isPopup: true,
            content: registeredCustomer.note,
          },
        ],
        true,
      )
    }

    if (
      popupNotePopups.length ||
      deal.customer.blacklistInformation?.isAddedToBlacklist
    ) {
      setNoteBuilds(popupNotePopups)
      setPopupNotesOpen(true)
    }
  }

  const { dealItemsPossiblyStolen } = useCheckDealItemsPossiblyStolen({
    variables: {
      dealId: deal._id,
    },
    skip:
      !deal ||
      !registeredCustomer ||
      deal.events[deal.events.length - 1]?.__typename !== 'DealBookedEvent' ||
      registeredCustomer.isTestUser,
  })

  useEffect(() => {
    if (dealItemsPossiblyStolen) {
      setOpenWarningStolenItemsPopup(true)
    }
  }, [dealItemsPossiblyStolen])

  useEffect(() => {
    handleNotePanelSize()
    window.addEventListener('resize', handleNotePanelSize)
    return () => {
      window.removeEventListener('resize', handleNotePanelSize)
    }
  }, [])

  return (
    <div className="w-full">
      <BreadCrumbBar
        customBreadCrumb={`Booking number ${deal.bookingNumber || '-'}`}
      />
      <TitleBar title={`Booking number: ${deal.bookingNumber || '-'}`} />

      <div className="w-full">
        <div className="flex flex-col md:flex-row">
          <Panel
            header={t('customer.label')}
            className="mb-6 w-full mr-3.5"
            toggleable
            onCollapse={() => {
              setNotesPanelHeight(261)
              setCustomerPanelCollapsed(false)
            }}
            onExpand={() => {
              setCustomerPanelCollapsed(true)
              setTimeout(() => {
                handleNotePanelSize()
              }, 500)
            }}
          >
            <div ref={elementRef}>
              <CustomerPanel deal={deal} />
            </div>
          </Panel>
          <Panel
            header={t('deal_conversation')}
            className="mb-6 w-full"
            style={{
              height: customerPanelCollapsed ? notesPanelHeight + 71 : 'auto',
            }}
            toggleable
            onExpand={() => {
              handleNotePanelSize()
            }}
          >
            <NoteContainer
              notes={deal.notes ?? []}
              chainNotes={deal.chainNotes ?? []}
              onAddNote={onAddDealNote}
              onUpdateNote={onUpdateDealNote}
              conversationListHeight={notesPanelHeight}
            />
          </Panel>
        </div>

        <Panel header={t('general_information')} className="mb-6" toggleable>
          <GeneralInfoContainer
            deal={deal}
            latestDealValues={event.latestDealValues}
            activeEvent={deal.events[deal.events.length - 1]}
            warnBookedDate={warnBookedDate}
            queryResult={queryResult}
          />
        </Panel>

        <Panel header={t('date_overview')} className="mb-6" toggleable>
          <DateOverview deal={deal} warnBookedDate={warnBookedDate} />
        </Panel>

        {event.extensionConfirmedEvent ||
        deal.pawnData?.reservedPaybackData?.isExtension ? (
          <Panel header={t('extension')} className="mb-6" toggleable>
            {event.extensionConfirmedEvent ? (
              <ExtensionBox deal={deal} />
            ) : (
              deal.pawnData?.reservedPaybackData && (
                <ReservedPaybackBox
                  reservedPaybackData={deal.pawnData?.reservedPaybackData}
                />
              )
            )}
          </Panel>
        ) : null}

        {event.paybackConfirmedEvent ||
        (deal.pawnData?.reservedPaybackData &&
          !deal.pawnData.reservedPaybackData.isExtension) ? (
          <Panel header={t('payback')} className="mb-6" toggleable>
            {event.paybackConfirmedEvent ? (
              <PaybackBox deal={deal} />
            ) : (
              deal.pawnData?.reservedPaybackData && (
                <ReservedPaybackBox
                  reservedPaybackData={deal.pawnData?.reservedPaybackData}
                />
              )
            )}
          </Panel>
        ) : null}

        {deal.dealChain && deal.dealChain.length > 1 && (
          <Panel header={t('deal_chain')} className="mb-6" toggleable>
            <DealsChain deal={deal} />
          </Panel>
        )}

        <Panel header={t('transport_pickup')} className="mb-6" toggleable>
          <TransportInfo
            deal={deal}
            transportType={ETransportType.Pickup}
            transportData={deal.pickupData}
            refetchDeal={refetchDeal}
            isEditable={
              !dealEvents.includes('DealVerifiedEvent') &&
              !dealEvents.includes('DealCanceledEvent') &&
              !dealEvents.includes('DealDeclinedEvent') &&
              isDealConnectedToRegisteredCustomer(deal)
            }
          />
        </Panel>
        {event.verifiedEvent && (
          <Panel header={t('transport_dropoff')} className="mb-6" toggleable>
            <TransportInfo
              deal={deal}
              transportType={ETransportType.Dropoff}
              transportData={
                deal.dropoffData ?? { transportMode: ETransportMode.Shop }
              }
              isEditable={
                !event.closedEvent && isDealConnectedToRegisteredCustomer(deal)
              }
              refetchDeal={refetchDeal}
            />
          </Panel>
        )}

        <Panel header={t('item_overview')} className="mb-6" toggleable>
          <ItemOverview
            deal={deal}
            activeEvent={deal.events[deal.events.length - 1]}
            refetchDeal={refetchDeal}
          />
        </Panel>

        {event.onSellEvent && deal.dealType === EDealType.Pawn ? (
          <Panel
            header={t('valorization_overview')}
            className="mb-6"
            toggleable
          >
            <DealValorization deal={deal} />
          </Panel>
        ) : null}

        <Panel header={t('payment_overview')} className="mb-6" toggleable>
          <DealEventsCalculationOverview
            dealQueryResult={queryResult}
            deal={deal}
          />
        </Panel>
      </div>

      <NotePopup
        customerBlacklistNote={
          <CustomerBlacklistWarning
            blacklistInformation={deal.customer.blacklistInformation}
          />
        }
        noteBuilds={noteBuilds}
        open={popupNotesOpen}
        onClose={() => setPopupNotesOpen(false)}
      />
      <WarningStolenItemsPopup
        open={!!openWarningStolenItemsPopup}
        onClose={() => setOpenWarningStolenItemsPopup(false)}
      />
    </div>
  )
}

export type DealDetailsContainerProps = RouteComponentProps

export default DealDetails
