import { dealCalculationFragments } from '../../hooks'
import gql from 'graphql-tag'

export const paybackFragments = {
  dealPaybackCalculation: gql`
    fragment PersistedDealPaybackCalculationFragment on PersistedDealPaybackCalculation {
      dealPaybackCalculationId
      dealCalculation {
        ...DealCalculationFragment
      }
    }
    ${dealCalculationFragments.dealCalculation}
  `,
}
