import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { EStorageStatus, ItemStorageHistoryEntry } from '@/schemaTypes'
import styles from './StorageUnitsConfiguration.module.scss'

export interface StorageUnitsItemControlsProps {
  onClickAdd: () => void
  onClickRemove: () => void
  disabled: boolean
  latestStorageHistoryEntry: ItemStorageHistoryEntry | null
}

export function StorageUnitsItemControls(props: StorageUnitsItemControlsProps) {
  const { onClickAdd, onClickRemove, disabled, latestStorageHistoryEntry } =
    props
  const { t } = useTranslation()

  const disabledAddToStorage =
    disabled ||
    latestStorageHistoryEntry?.storageStatus === EStorageStatus.AddedToStorage

  return (
    <>
      <Button
        variant="contained"
        className="u-sm u-mr-sm u-sm"
        classes={{
          root: disabledAddToStorage
            ? styles.button_disabled
            : styles.button_enable,
        }}
        onClick={onClickAdd}
        disabled={disabledAddToStorage}
      >
        {t('add_to_storage_unit')}
      </Button>
      <br />
      <Button
        variant="contained"
        className="u-mt-sm u-mr-sm u-mb-sm"
        classes={{
          root: disabled ? styles.button_disabled : styles.button_enable,
        }}
        onClick={onClickRemove}
        disabled={disabled}
      >
        {t('storage.remove_from_storage_unit')}
      </Button>
    </>
  )
}

export default StorageUnitsItemControls
