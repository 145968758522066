import { Timezone, getDayjsInTimezone } from './time'

export function printHour(hourUTC: number) {
  return `${hourUTC.toString().padStart(2, '0')}:00`
}

export function printHourTimespan(hourUTC: number) {
  const from = hourUTC
  const to = hourUTC + 1

  return `${printHour(from)} - ${printHour(to)}`
}

export function printLocalDate(
  date: Date,
  options: { locale?: string; timezone: Timezone },
) {
  const localDate = getDayjsInTimezone(date, options)

  return localDate.format('DD.MM.YYYY')
}

export function printLocalDateTime(
  date: Date,
  options: { locale?: string; timezone: Timezone },
) {
  const localDate = getDayjsInTimezone(date, options)

  return localDate.format('DD.MM.YYYY HH:mm')
}
