import { Paper, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import CompaniesDetails from '@/domains/companies/components/CompaniesDetails'
import { useGetCompany, useUpdateCompany } from '@/domains/companies/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { MutationUpdateCompanyArgs } from '@/schemaTypes'

export interface CompaniesEditProps {
  companyId: string
}

export function CompaniesEdit(props: CompaniesEditProps) {
  const { companyId } = props
  const { t } = useTranslation()

  const { company, queryResult: getCompanyQueryResult } = useGetCompany({
    variables: {
      companyId,
    },
  })

  const updateCompany = useMutationShowingErrors({
    mutation: useUpdateCompany(),
    successMessage: t('company.company_updated'),
  })

  const onSave = (company: MutationUpdateCompanyArgs) => {
    updateCompany({
      variables: {
        ...company,
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={companyId}
        modelName={'companies'}
        mode={'edit'}
      />
      <Typography variant="h5" gutterBottom>
        {t('company.company_edit')}
      </Typography>
      {getCompanyQueryResult.loading ? (
        <Loading />
      ) : (
        company && <CompaniesDetails company={company} onSave={onSave} />
      )}
    </Paper>
  )
}

export default CompaniesEdit
