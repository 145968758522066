import { useShowErrorsPopup } from '../../../hooks'
import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { EOrderBy, Query, QueryGetCustomDealsArgs } from '@/schemaTypes'
import { customDeal } from './customDealFragments'

const GET_CUSTOM_DEALS = gql`
  query getCustomDeals($opts: CustomDealOpts) {
    getCustomDeals(opts: $opts) {
      records {
        ...CustomDealFragment
        items {
          _id
          title
        }
      }
      totalCount
      totalPages
    }
  }
  ${customDeal}
`

type TData = { getCustomDeals: Query['getCustomDeals'] }
type TVariables = QueryGetCustomDealsArgs

const emptyArray = []

export function useGetCustomDeals(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_CUSTOM_DEALS, {
    variables: {
      opts: {
        order: { createdAt: EOrderBy.Desc },
      },
    },
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    customDeals: queryResult.data?.getCustomDeals?.records || emptyArray,
    totalCount: queryResult.data?.getCustomDeals?.totalCount,
    totalPages: queryResult.data?.getCustomDeals?.totalPages,
  }
}
