import styled from '@emotion/styled'
import { MultiSelect, MultiSelectProps } from 'primereact/multiselect'
import { useEffect, useState } from 'react'

interface FilterMultiSelectProps extends MultiSelectProps {
  className?: string
}

const FilterMultiSelect = ({ className, ...props }: FilterMultiSelectProps) => {
  const [scrollHeight, setScrollHeight] = useState<string>('200px')
  const { options } = props

  useEffect(() => {
    const calculateDropdownHeight = () => {
      const screenHeight = window.innerHeight
      const maxDropdownItems = 20 // Set the maximum number of items to display without scrolling
      const itemHeight = 40 // Height of each item in pixels
      const bufferHeight = 20 // Additional buffer height

      // Calculate the maximum height based on a percentage of screen height
      const maxHeight = screenHeight * 0.6 // Maximum percentage of screen height for dropdown

      // Calculate the height based on options length and screen height
      const dropdownHeight =
        Math.min(options?.length ?? 0, maxDropdownItems) * itemHeight +
        bufferHeight

      // Set the final height considering the maxHeight constraint
      const finalHeight = Math.min(dropdownHeight, maxHeight)

      setScrollHeight(`${finalHeight}px`)
    }

    calculateDropdownHeight()

    const handleResize = () => {
      calculateDropdownHeight()
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [options])

  return (
    <StyledMultiSelect
      className={className}
      scrollHeight={scrollHeight}
      {...props}
    />
  )
}

const StyledMultiSelect = styled(MultiSelect)`
  &&& {
    border-width: 0;

    .p-multiselect-trigger {
      width: 1.6rem;
    }

    .p-multiselect-clear-icon {
      right: 1.5rem;
      cursor: pointer;
    }

    .p-multiselect-label {
      padding: 0.65rem 0.5rem;
    }

    &.selected {
      border-width: 1px;
      border-color: #6366f1;
      .p-multiselect-label {
        padding: 0.65rem 1.3rem 0.65rem 0.5rem;
        color: #495057;
      }
    }

    &:hover {
      background: #e9ecef;
      color: #495057;
    }
  }
`

export default FilterMultiSelect
