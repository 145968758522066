import { ApolloError } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { ErrorCodes } from '@/domains/deals/components/constants/enums'
import { UIError } from '@/types'
import { camelToUpperSnake } from '@/utils/misc'

function parseValidationError(validationError, validationConstraints, path, t) {
  if (validationError.children && validationError.children.length > 0) {
    for (const validationErrorChild of validationError.children) {
      parseValidationError(
        validationErrorChild,
        validationConstraints,
        `${path}.${validationErrorChild.property}`,
        t,
      )
    }
  }

  if (validationError.constraints) {
    Object.keys(validationError.constraints).forEach((constraintKey) => {
      const translation = t(
        `ERROR_VALIDATION_${camelToUpperSnake(constraintKey)}`,
      )

      if (translation) {
        validationConstraints.push(`"${path}" ${translation}!`)
      } else {
        const constraintSplit =
          validationError.constraints[constraintKey].split(' ')
        constraintSplit.shift()
        const constraint = constraintSplit.join(' ')

        validationConstraints.push(`"${path}" ${constraint}!`)
      }
    })
  }
}

function parseApiError(
  requestError: { error: string; message?: string; statusCode?: number },
  t,
) {
  const error: UIError = {
    title: '',
    description: '',
    data: undefined,
  }

  if (requestError.message) {
    try {
      const errorData = JSON.parse(requestError.message)

      if (typeof errorData.code !== 'undefined') {
        switch (errorData.code) {
          case ErrorCodes.FILE_UPLOAD_NOT_FINISHED:
            error.title = t(`error.file_upload_not_finished.label`)
            error.description =
              errorData.data?.file === 'general'
                ? t('error.file_upload_not_finished.description_multiple_files')
                : t('error.file_upload_not_finished.description_single_file', {
                    file: t(
                      `error.file_upload_not_finished.${errorData.data?.file}`,
                    ),
                  })
            break
          default:
            error.title =
              t(`error.error_title_${errorData.code.toLowerCase()}`) ??
              `Error Code: ${errorData.code}`
            error.description =
              t(`error.error_description_${errorData.code.toLowerCase()}`) ??
              errorData.message
            break
        }
      } else {
        error.title = t('error.error_title_api_error')
        error.description = errorData.message
      }

      if (typeof errorData.data !== 'undefined') {
        if (
          errorData.validationErrors &&
          Array.isArray(errorData.validationErrors)
        ) {
          const { validationErrors, ...otherData } = errorData.data
          const validationConstraints: string[] = []

          validationErrors.forEach((validationError) => {
            parseValidationError(
              validationError,
              validationConstraints,
              validationError.property,
              t,
            )
          })

          error.description = validationConstraints
          error.data = otherData
        } else {
          error.data = errorData.data
        }
      }
    } catch (e) {
      // error, message, statusCode
      error.title = `${requestError.statusCode}: ${requestError.error}`
      error.description = requestError.message
    }
  } else {
    error.title = t('error.error_title_api_error')
    error.description = requestError.error
  }

  console.log('error.data', error.data)

  return error
}

export const useParseErrors = () => {
  const { t } = useTranslation()

  return (e: ApolloError) => {
    const networkError:
      | (ApolloError['networkError'] & {
          result?: { errors?: ApolloError['graphQLErrors'] }
        })
      | null = e.networkError
    let graphQLErrors: ApolloError['graphQLErrors'] = []

    if (e.graphQLErrors && e.graphQLErrors.length > 0) {
      graphQLErrors = e.graphQLErrors
    } else if (
      networkError &&
      networkError.result &&
      networkError.result.errors &&
      networkError.result.errors.length > 0
    ) {
      graphQLErrors = networkError.result.errors
    }

    const errors: UIError[] = []

    if (graphQLErrors.length === 0) {
      errors.push({
        title: t('error.error_title_unknown_error'),
        description: e.message,
      })
    } else {
      try {
        for (const graphQLError of graphQLErrors) {
          const apiResponse = graphQLError.extensions?.originalError as any

          if (!apiResponse) {
            errors.push({
              title: t('error.error_title_graphql_error'),
              description: graphQLError.message,
            })
          } else {
            if (apiResponse.message.indexOf('{') >= 0) {
              let message = apiResponse.message

              // api error code
              if (message[0] !== '{') {
                const start = message.indexOf('{')
                message = message.substr(start, message.length - start)
              }

              const error = parseApiError(
                {
                  error: apiResponse.error,
                  message,
                  statusCode: apiResponse.statusCode,
                },
                t,
              )

              errors.push(error)
            } else {
              errors.push({
                title: t('error.error_title_graphql_error'),
                description: apiResponse,
              })
            }
          }
        }
      } catch (error) {
        errors.push({
          title: t('error.error_title_unknown_error'),
          description: t('error.error_description_unknown_error'),
        })
      }
    }

    return errors
  }
}
