import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationEmployeeChangePasswordArgs } from '@/schemaTypes'

const EMPLOYEE_CHANGE_PASSWORD = gql`
  mutation employeeChangePassword(
    $oldPassword: String!
    $newPassword: String!
  ) {
    employeeChangePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`

type TData = { employeeChangePassword: Mutation['employeeChangePassword'] }
type TVariables = MutationEmployeeChangePasswordArgs

export function useEmployeeChangePassword(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [employeeChangePassword] = useMutation<TData, TVariables>(
    EMPLOYEE_CHANGE_PASSWORD,
    opts,
  )

  return employeeChangePassword
}
