import TextInput from '../TextInput'
import styled from '@emotion/styled'
import { MenuItem, Select } from '@material-ui/core'
import { find } from 'lodash'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import {
  ECustomDealFormQuestionType,
  FormOptionNumberAnswer,
  FormOptionTextAnswer,
  TranslatedString,
} from '@/schemaTypes'
import { NewFormAnswer } from './ItemFormAnswers'

type ItemFormSelectAnswerProps = {
  disabled?: boolean
  onChange?: (newFormAnswer: NewFormAnswer, allowEnterAnswer?: boolean) => void
}

type ItemFormSelectTextAnswerProps = ItemFormSelectAnswerProps & {
  formAnswer: FormOptionTextAnswer
}

const getQuestionLabel = (label: TranslatedString, language) => {
  if (Array.isArray(label)) {
    return find(label, { languageCode: language })?.text
  }

  return ''
}

export const ItemFormSelectTextAnswer = ({
  formAnswer,
  disabled,
  onChange,
}: ItemFormSelectTextAnswerProps) => {
  const { language } = useContext(Context)
  const { t } = useTranslation()

  const isOption =
    formAnswer.optionTextAnswer === undefined ||
    formAnswer.optionTextAnswer === null ||
    formAnswer.question.options.some(
      (option) =>
        formAnswer.optionTextAnswer !== undefined &&
        formAnswer.optionTextAnswer !== null &&
        option.label.allTranslations
          .map((tran) => tran.text)
          .includes(formAnswer.optionTextAnswer),
    )

  const [showOtherOption, setShowOtherOption] = useState(!isOption)

  return showOtherOption ? (
    <StyledTextInput
      fullWidth
      value={formAnswer.optionTextAnswer}
      disabled={disabled}
      onChange={(value) => {
        onChange?.(
          {
            type: ECustomDealFormQuestionType.TextOptions,
            value: value,
            question: formAnswer.question,
          },
          true,
        )
      }}
    />
  ) : (
    <Select
      value={formAnswer.question.options.findIndex((option) =>
        option.label.allTranslations.some(
          (translation) => translation.text === formAnswer.optionTextAnswer,
        ),
      )}
      disabled={disabled}
      onChange={(e) => {
        onChange?.({
          type: ECustomDealFormQuestionType.TextOptions,
          value:
            formAnswer.question.options[e.target.value as number]?.label.text,
          question: formAnswer.question,
        })
      }}
      fullWidth
      style={{ textAlign: 'left' }}
    >
      {formAnswer.question.__typename === 'TextOptionsCustomDealFormQuestion' &&
        formAnswer.question.options?.map((option, i) => {
          return (
            <MenuItem key={i} value={i}>
              {option.label.text ?? getQuestionLabel(option.label, language)}
            </MenuItem>
          )
        })}
      {formAnswer.question.__typename === 'TextOptionsCustomDealFormQuestion' &&
        formAnswer.question.allowEnterAnswer && (
          <MenuItem value="" onClick={() => setShowOtherOption(true)}>
            {t('other')}
          </MenuItem>
        )}
    </Select>
  )
}

type ItemFormSelectNumberAnswerProps = ItemFormSelectAnswerProps & {
  formAnswer: FormOptionNumberAnswer
}

export const ItemFormSelectNumberAnswer = ({
  formAnswer,
  disabled,
  onChange,
}: ItemFormSelectNumberAnswerProps) => {
  const { language } = useContext(Context)
  const { t } = useTranslation()

  const isOption = formAnswer.question.options.some(
    (option) =>
      formAnswer.optionNumberAnswer !== undefined &&
      formAnswer.optionNumberAnswer !== null &&
      option.label.allTranslations
        .map((tran) => parseFloat(tran.text))
        .includes(formAnswer.optionNumberAnswer),
  )

  const [showOtherOption, setShowOtherOption] = useState(!isOption)

  return showOtherOption ? (
    <StyledTextInput
      fullWidth
      value={formAnswer.optionNumberAnswer}
      type="number"
      disabled={disabled}
      onChange={(value) =>
        onChange?.(
          {
            type: ECustomDealFormQuestionType.NumberOptions,
            customValue: +value,
            question: formAnswer.question,
          },
          true,
        )
      }
    />
  ) : (
    <Select
      value={formAnswer.optionNumberAnswer}
      disabled={disabled}
      onChange={(e) =>
        onChange?.({
          type: ECustomDealFormQuestionType.NumberOptions,
          value: e.target.value as number,
          question: formAnswer.question,
        })
      }
      fullWidth
      style={{ textAlign: 'left' }}
    >
      {formAnswer.question.__typename ===
        'NumberOptionsCustomDealFormQuestion' &&
        formAnswer.question.options?.map((option, i) => (
          <MenuItem key={i} value={parseFloat(option.label.text)}>
            {option.label.text ?? getQuestionLabel(option.label, language)}
          </MenuItem>
        ))}
      {formAnswer.question.__typename ===
        'NumberOptionsCustomDealFormQuestion' &&
        formAnswer.question.allowEnterAnswer && (
          <MenuItem value="" onClick={() => setShowOtherOption(true)}>
            {t('other')}
          </MenuItem>
        )}
    </Select>
  )
}

const StyledTextInput = styled(TextInput)`
  margin-bottom: 0;
  .MuiFormControl-fullWidth {
    margin-left: 0 !important;
  }
`
