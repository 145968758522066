import styled from '@emotion/styled'
import { Field, FieldProps } from 'formik'
import { Button } from 'primereact/button'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { ToggleButton } from 'primereact/togglebutton'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useConfirmationPopup from '@/redesign/helpers/useConfirmationPopup'
import { OCustomerAddress } from '@/schemaTypes'

interface AddressOverviewCardProps {
  address: OCustomerAddress
  addressIndex: number | null
  onEdit: () => void
  onRemove: () => any
  onChangeAddressType: (target: {
    id: string | null
    name: string
    value: boolean
  }) => void
  countryOptions: { label: string; value: string }[]
  index: number
}

const AddressOverviewCard = ({
  address,
  addressIndex,
  countryOptions,
  index,
  onEdit,
  onRemove,
  onChangeAddressType,
}: AddressOverviewCardProps) => {
  const { t } = useTranslation()
  const { confirmPopupAction } = useConfirmationPopup()

  const handleConfirm = useCallback(
    (event) => {
      event.preventDefault()
      confirmPopupAction({
        target: event.currentTarget,
        onAccept: () => onRemove(),
      })
    },
    [onRemove, confirmPopupAction],
  )

  const country = useMemo(() => {
    return address?.countryCode
      ? countryOptions.find((country) => country.value === address.countryCode)
      : undefined
  }, [address?.countryCode, countryOptions])

  return (
    <>
      <ConfirmPopup />
      <AddressOverviewContainer className="mr-0 md:mr-6 mb-6 pl-4">
        <div className="flex justify-end">
          <Button
            disabled={!!addressIndex}
            icon="pi pi-pencil"
            severity="secondary"
            text
            onClick={onEdit}
          />
          <Button
            disabled={!!addressIndex}
            icon="pi pi-trash"
            text
            severity="danger"
            onClick={handleConfirm}
          />
        </div>

        <div className="mb-4">
          <p className="font-semibold">
            {address.street} {address.houseNumber}/{address.floor}
          </p>
          <p className="font-semibold">
            {address.zipCode} {address.city}
          </p>

          <p className="font-semibold">{country && country.label}</p>
        </div>

        <div className="flex flex-wrap">
          <div className="mb-2 mr-2">
            <Field name={`addresses[${index}].isBillingAddress`}>
              {({ field }: FieldProps<boolean | undefined>) => {
                return (
                  <div>
                    <ToggleButtonStyled
                      checked={field.value}
                      onLabel={t('billing')}
                      offLabel={t('billing')}
                      onIcon="pi pi-check"
                      offIcon="pi pi-plus-circle"
                      className="w-9rem"
                      name={field.name}
                      onChange={({ target }) => onChangeAddressType(target)}
                    />
                  </div>
                )
              }}
            </Field>
          </div>
          <div className="mb-2 mr-2">
            <Field name={`addresses[${index}].isDeliveryAddress`}>
              {({ field }: FieldProps<boolean | undefined>) => (
                <div>
                  <ToggleButtonStyled
                    checked={field.value}
                    onLabel={t('shipping')}
                    offLabel={t('shipping')}
                    onIcon="pi pi-check"
                    offIcon="pi pi-plus-circle"
                    className="w-9rem"
                    name={field.name}
                    onChange={({ target }) => onChangeAddressType(target)}
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="mb-2 mr-2">
            <Field name={`addresses[${index}].isRegistrationAddress`}>
              {({ field }: FieldProps<boolean | undefined>) => (
                <div>
                  <ToggleButtonStyled
                    checked={field.value}
                    onLabel={t('registration')}
                    offLabel={t('registration')}
                    onIcon="pi pi-check"
                    offIcon="pi pi-plus-circle"
                    className="w-9rem"
                    name={field.name}
                    onChange={({ target }) => onChangeAddressType(target)}
                  />
                </div>
              )}
            </Field>
          </div>
        </div>
      </AddressOverviewContainer>
    </>
  )
}
const AddressOverviewContainer = styled.div`
  width: 18.125rem;
  height: 15rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  background: var(--surface-50, #fafafa);
  @media (max-width: 768px) {
    width: 100%;
  }
`

const ToggleButtonStyled = styled(ToggleButton)`
  border-radius: 2rem;
  padding: 0.65625rem 1.09375rem;
  font-size: 0.875rem;
  font-family: 'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.0625rem;
  height: 2.375rem;
`

export default AddressOverviewCard
