import { Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import LoanToValuesDetails from '@/domains/loanToValues/components/LoanToValuesDetails'
import {
  useCreateLoanToValuesEntry,
  useGetLoanToValuesEntry,
} from '@/domains/loanToValues/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { MutationCreateLoanToValuesEntryArgs } from '@/schemaTypes'

export interface LoanToValuesCreateProps {
  loanToValuesEntryId: string | null
}

export function LoanToValuesCreate(props: LoanToValuesCreateProps) {
  const { loanToValuesEntryId } = props
  const { t } = useTranslation()
  const history = useHistory()

  const { loanToValuesEntry, queryResult } = useGetLoanToValuesEntry({
    variables: {
      loanToValuesEntryId: loanToValuesEntryId ?? '',
    },
  })

  const createLoanToValuesEntry = useMutationShowingErrors({
    mutation: useCreateLoanToValuesEntry(),
    successMessage: t('loan.loan_to_values_entry_created'),
  })

  const onSave = (loanToValuesEntry: MutationCreateLoanToValuesEntryArgs) => {
    createLoanToValuesEntry({
      variables: {
        ...loanToValuesEntry,
      },
      onCompleted: (data) => {
        history.replace(
          `/inApp/configurations/loanToValues/edit/${data.createLoanToValuesEntry._id}`,
        )
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={loanToValuesEntryId ?? ''}
        modelName={'loanToValues'}
        mode={'create'}
      />
      <Typography variant="h5" gutterBottom>
        {t('loan.loan_to_values_entry_create')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        loanToValuesEntry && (
          <LoanToValuesDetails
            loanToValuesEntry={{ ...loanToValuesEntry, validFrom: null }}
            onSave={onSave}
          />
        )
      )}
    </Paper>
  )
}

export default LoanToValuesCreate
