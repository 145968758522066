import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EReturnReasons, ValorizationHistoryEntry } from '@/schemaTypes'
import { getNow } from '@/utils/time'
import styles from './ValorizationHistoryEntry.module.scss'
import ValorizationHistoryEntryTable, {
  ValorizationHistoryEntryTableRow,
} from './ValorizationHistoryEntryTable'

type ValorizationHistoryEntryGeneralDataRow = Pick<
  ValorizationHistoryEntryTableRow<keyof ValorizationHistoryEntry>,
  | 'label'
  | 'type'
  | 'key'
  | 'hidden'
  | 'options'
  | 'disabled'
  | 'disableFuture'
  | 'minDate'
>

export interface ValorizationHistoryEntryGeneralDataProps {
  className?: string
  entry: ValorizationHistoryEntry
  columnWidths: { label: number; value: number }
  isPersisted?: boolean
  onChangeEntry: (updateData: Partial<ValorizationHistoryEntry>) => void
  itemValorizationHistoryEntries: ValorizationHistoryEntry[]
  isReadonly: boolean
}

export function ValorizationHistoryEntryGeneralData(
  props: ValorizationHistoryEntryGeneralDataProps,
) {
  const {
    className,
    entry,
    columnWidths,
    isPersisted,
    onChangeEntry,
    itemValorizationHistoryEntries,
    isReadonly,
  } = props
  const { t } = useTranslation()

  const RETURN_REASON = [
    { label: '', value: '' },
    { label: t('technical_error'), value: EReturnReasons.TechnicalError },
    { label: t('account_lock'), value: EReturnReasons.AccountLock },
    { label: t('optical_error'), value: EReturnReasons.OpticalError },
    { label: t('wrong_product'), value: EReturnReasons.WrongProduct },
    {
      label: t('return_for_no_reason'),
      value: EReturnReasons.ReturnForNoReason,
    },
    { label: t('fake'), value: EReturnReasons.Fake },
    { label: t('cancelled'), value: EReturnReasons.Cancelled },
    { label: t('other'), value: EReturnReasons.Other },
  ]
  const IS_ITEM_RETURNED = [
    { label: '', value: '' },
    { label: t('no'), value: false },
    { label: t('yes'), value: true },
  ]
  const TAX_FREE_TRANSACTION = [
    { label: t('no'), value: false },
    { label: t('yes'), value: true },
  ]

  const BILL_NUMBERS = [{ label: '', value: '' }]
  itemValorizationHistoryEntries.map((c) => {
    if (c.valorizationBillLink) {
      BILL_NUMBERS.push({
        label: c.valorizationBillNumber ? c.valorizationBillNumber : '',
        value: c._id,
      })
    }
  })

  const buildRow = (
    params: ValorizationHistoryEntryGeneralDataRow,
  ): ValorizationHistoryEntryTableRow<keyof ValorizationHistoryEntry> => {
    const { key } = params

    return {
      ...params,
      renderKey: key,
      value: entry[key],
      onChange: params.disabled
        ? undefined
        : (value) => {
            const updateData = { ...entry, [key]: value }
            onChangeEntry(updateData)
          },
    }
  }

  const rowsData: ValorizationHistoryEntryGeneralDataRow[] = [
    {
      label: t('buyer_name'),
      key: 'buyerName',
      type: 'string',
      disabled: isReadonly,
    },
    {
      label: `${t('buyer_email')}${entry.sendBill ? '*' : ''}`,
      key: 'buyerEmail',
      type: 'string',
      disabled: isReadonly,
    },
    {
      label:
        entry.cashflow >= 0
          ? t('should_send_bill')
          : t('should_send_credit_note'),
      key: 'sendBill',
      type: 'boolean',
      disabled: isPersisted || isReadonly,
    },
    {
      label: t('buyer_phone'),
      key: 'buyerPhone',
      type: 'string',
      disabled: isReadonly,
    },
    {
      label: `${t('date')}*`,
      key: 'date',
      type: 'date',
      disabled: isPersisted || isReadonly,
      minDate: getNow(),
    },
    {
      label: `${t('payment')}*`,
      key: 'cashflow',
      type: 'float',
      disabled: isReadonly,
    },
    {
      label: `${t('is_item_returned')}*`,
      key: 'isItemReturned',
      type: 'boolean',
      options: IS_ITEM_RETURNED,
      hidden: entry.cashflow >= 0,
      disabled: isReadonly,
    },
    {
      label: `${t('is_tax_free_transaction')}`,
      key: 'isTaxFreeTransaction',
      type: 'boolean',
      options: TAX_FREE_TRANSACTION,
      hidden: entry.cashflow >= 0,
      disabled: isReadonly,
    },
    {
      label: t('return_reason'),
      key: 'returnReason',
      type: 'string',
      options: RETURN_REASON,
      hidden: entry.cashflow >= 0,
      disabled: isReadonly,
    },
    {
      label: `${t('return_reason_details')}`,
      key: 'returnReasonDetails',
      type: 'string',
      hidden: entry.cashflow >= 0,
      disabled: isReadonly,
    },
    {
      label: t('payments_cumulated'),
      key: 'cumulatedCashflow',
      type: 'float',
      disabled: true,
    },
    {
      label: t('valorization_deviation'),
      key: 'deviation',
      type: 'float',
      disabled: true,
    },
    {
      label: t('sales_price_gross'),
      key: 'externalSalePriceGross',
      type: 'float',
      disabled: true,
    },
    {
      label: t('sales_price_net'),
      key: 'externalSalePriceNet',
      type: 'float',
      disabled: true,
    },
    {
      label: t('external_sale_price_gross_deduction'),
      key: 'externalSalePriceGrossDeduction',
      type: 'float',
      hidden: entry.cashflow >= 0,
      disabled: true,
    },
    {
      label: t('external_sale_price_net_deduction'),
      key: 'externalSalePriceNetDeduction',
      type: 'float',
      hidden: entry.cashflow >= 0,
      disabled: true,
    },
    {
      label: t('cost_of_goods_sold'),
      key: 'costOfGoodsSold',
      type: 'float',
      disabled: true,
    },
    {
      label: t('taxable_amount'),
      key: 'taxableAmount',
      type: 'float',
      disabled: true,
    },
    {
      label: t('valorization_diff_gross'),
      key: 'differenceGross',
      type: 'float',
      disabled: true,
    },
    {
      label: t('valorization_diff_net'),
      key: 'differenceNet',
      type: 'float',
      disabled: true,
    },
    {
      label: t('cash_book_count.difference_tax_delta'),
      key: 'deltaDifferenceTax',
      type: 'float',
      disabled: true,
    },
    {
      label: t('gross_profit'),
      key: 'grossProfit',
      type: 'float',
      disabled: true,
    },
    {
      label: t('credit_note_number'),
      key: 'valorizationCreditNoteNumber',
      type: 'string',
      disabled: true,
      hidden: entry.cashflow >= 0,
    },
    {
      label: t('deal.pawn.bill_number'),
      key: 'relatedBillId',
      type: 'string',
      options: BILL_NUMBERS,
      disabled: entry.cashflow >= 0 || isReadonly,
      hidden: isReadonly,
    },
    {
      label: t('deal.pawn.bill_number'),
      key: 'valorizationBillNumber',
      type: 'string',
      disabled: entry.cashflow >= 0 || isReadonly,
      hidden: !isReadonly,
    },
  ]
  const rows = rowsData.map((rowData) => buildRow(rowData))

  return (
    <section className={className ?? ''}>
      <Typography
        className={`${styles.middleBold}`}
        variant="body1"
        gutterBottom
      >
        {t('general')}
      </Typography>
      <ValorizationHistoryEntryTable rows={rows} columnWidths={columnWidths} />
    </section>
  )
}

// Prevent rerender if parent rerendered and shallow child props comparison did not find differences.
export default React.memo(ValorizationHistoryEntryGeneralData)
