import { dealCalculationFragments, dealFragments } from '../dealFragments'
import { itemFragments } from '../itemFragments'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { useMutationShowingErrors } from '@/hooks'
import { Deal, Mutation, MutationSetSoldExternArgs } from '@/schemaTypes'

const SET_SOLD_EXTERN = gql`
  mutation setSoldExtern($dealId: ObjectId!) {
    setSoldExtern(dealId: $dealId) {
      _id
      events {
        ...DealEventFragment
      }
      pawnData {
        ...PawnDataFragment
      }
      purchaseData {
        ...PurchaseDataFragment
      }
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
    }
  }
  ${dealFragments.event}
  ${dealFragments.purchaseData}
  ${dealFragments.pawnData}
  ${itemFragments.itemValuesEntry}
  ${dealFragments.dealItem}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = { setSoldExtern: Mutation['setSoldExtern'] }
type TVariables = MutationSetSoldExternArgs

export function useOnDealStatusSoldExtern(deal: Deal) {
  const { t } = useTranslation()
  const [setSoldExtern] = useMutation<TData, TVariables>(SET_SOLD_EXTERN, {
    variables: { dealId: deal._id },
  })
  return useMutationShowingErrors({
    mutation: setSoldExtern,
    successMessage: t('update_successful'),
  })
}
