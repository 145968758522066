import { MenuItem, Select } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetShopsNames } from '@/hooks'
import { useGetCompaniesNames } from '@/hooks/getCompanies'
import { CashBookCashFlowOpts, ECashBookType } from '@/schemaTypes'
import { isShopLevelCashbookType } from '@/utils/misc'
import styles from './styles/CashBook.module.scss'

const cashbookTypesOptions = Object.values(ECashBookType).map(
  (paymentType) => ({
    name: paymentType,
    value: paymentType,
  }),
)

interface CashBookCashFlowFilterProps {
  cashBookCashFlowOpts: CashBookCashFlowOpts | undefined
  setCashBookCashFlowOpts: (value: CashBookCashFlowOpts) => void
}

function CashBookCashFlowFilter(props: CashBookCashFlowFilterProps) {
  const { cashBookCashFlowOpts, setCashBookCashFlowOpts } = props

  const { t } = useTranslation()

  const [companyLevelCashFlows, setCompanyLevelCashFlows] = useState<
    'OLD' | 'NEW'
  >('NEW')

  const companyLevelCashFlowsOptions = [
    { label: t('new'), value: 'NEW' },
    { label: t('old'), value: 'OLD' },
  ]

  const { shops } = useGetShopsNames({
    variables: {
      opts: {
        filter: {
          includeDisabled: false,
        },
      },
    },
  })

  const { companies } = useGetCompaniesNames({
    variables: {
      opts: {
        filter: {},
      },
    },
  })

  const cashBookType = cashBookCashFlowOpts?.filter?.cashBookType
  // const shopOrCompanyId = cashBookCashFlowOpts?.filter?.shopId ?? cashBookCashFlowOpts?.filter?.companyId

  return (
    <div className={`u-flex ${styles.filterBar}`}>
      <div className={`u-inline-block u-ml-10`}>
        <span>{t('cashbook')}: </span>
        <Select
          className="u-ml-sm u-mb-sm"
          value={cashBookType}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCashBookCashFlowOpts({
              filter: {
                ...cashBookCashFlowOpts?.filter,
                cashBookType: e.target.value as ECashBookType,
              },
            })
          }}
        >
          {cashbookTypesOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      {cashBookType && (
        <div className={`u-inline-block u-ml-10`}>
          {isShopLevelCashbookType(cashBookType) ? (
            <div className={`u-inline-block u-ml-10`}>
              <span>{t('shop.label')}: </span>
              <Select
                className="u-ml-sm u-mb-sm"
                value={cashBookCashFlowOpts?.filter?.shopId?.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCashBookCashFlowOpts({
                    filter: {
                      ...cashBookCashFlowOpts?.filter,
                      companyId: undefined,
                      shopId: { value: e.target.value },
                    },
                  })
                }}
              >
                {shops.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          ) : (
            <>
              <div className={`u-inline-block u-ml-10`}>
                <span>{t('select')}: </span>
                <Select
                  className="u-ml-sm u-mb-sm"
                  value={companyLevelCashFlows}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCashBookCashFlowOpts({
                      filter: {
                        ...cashBookCashFlowOpts?.filter,
                        shopId: undefined,
                        companyId: undefined,
                      },
                    })
                    setCompanyLevelCashFlows(
                      e.target.value === 'NEW' ? 'NEW' : 'OLD',
                    )
                  }}
                >
                  {companyLevelCashFlowsOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              {companyLevelCashFlows === 'NEW' ? (
                <div className={`u-inline-block u-ml-10`}>
                  <span>{t('company.singular')}: </span>
                  <Select
                    className="u-ml-sm u-mb-sm"
                    value={cashBookCashFlowOpts?.filter?.companyId?.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setCashBookCashFlowOpts({
                        filter: {
                          ...cashBookCashFlowOpts?.filter,
                          companyId: { value: e.target.value },
                        },
                      })
                    }
                  >
                    {companies.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ) : (
                <div className={`u-inline-block u-ml-10`}>
                  <span>{t('shop.label')}: </span>
                  <Select
                    className="u-ml-sm u-mb-sm"
                    value={cashBookCashFlowOpts?.filter?.shopId?.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setCashBookCashFlowOpts({
                        filter: {
                          ...cashBookCashFlowOpts?.filter,
                          shopId: { value: e.target.value },
                        },
                      })
                    }}
                  >
                    {shops.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            </>
          )}
        </div>
      )}
      <div className={`u-ml-40`}>
        <div className={`u-inline-block`}>
          <span className={`u-relative ${styles.dateLabel} u-mr-5`}>
            {t('from')}:{' '}
          </span>
          <KeyboardDatePicker
            className={`${styles.datePicker} u-mb-sm`}
            clearable
            format="DD.MM.YYYY"
            value={
              cashBookCashFlowOpts?.filter?.date?.betweenValues?.from ??
              dayjs().toDate()
            }
            onChange={(value: Dayjs) =>
              setCashBookCashFlowOpts({
                filter: {
                  ...cashBookCashFlowOpts?.filter,
                  date: {
                    betweenValues: {
                      to:
                        cashBookCashFlowOpts?.filter?.date?.betweenValues?.to ??
                        dayjs().toDate(),
                      from: dayjs(value?.toDate()).startOf('day').toDate(),
                    },
                  },
                },
              })
            }
          />
        </div>
        <div className={`u-inline-block u-ml-10`}>
          <span className={`u-relative ${styles.dateLabel} u-mr-5`}>
            {t('to')}:{' '}
          </span>
          <KeyboardDatePicker
            className={`${styles.datePicker} u-mb-sm`}
            clearable
            format="DD.MM.YYYY"
            value={
              cashBookCashFlowOpts?.filter?.date?.betweenValues?.to ??
              dayjs().toDate()
            }
            onChange={(value: Dayjs) =>
              setCashBookCashFlowOpts({
                filter: {
                  ...cashBookCashFlowOpts?.filter,
                  date: {
                    betweenValues: {
                      from:
                        cashBookCashFlowOpts?.filter?.date?.betweenValues
                          ?.from ?? dayjs().toDate(),
                      to: dayjs(value?.toDate()).startOf('day').toDate(),
                    },
                  },
                },
              })
            }
          />
        </div>
      </div>
    </div>
  )
}

export default CashBookCashFlowFilter
