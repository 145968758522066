import classNames from 'classnames'
import { Dropdown } from 'primereact/dropdown'
import React, { useMemo } from 'react'
import { FragmentType, graphql, useFragment } from '@/gql'

const CompanyDropdown_CompaniesFragment = graphql(`
  fragment CompanyDropdown_Companies on Company {
    _id
    name
  }
`)

interface CompanyDropdownProps {
  companies: FragmentType<typeof CompanyDropdown_CompaniesFragment>[]
  companyId: string
  onChangeCompany: (_id: string) => void
  defaultValue?: { name: string; _id: string }
  className?: string
}

const CompanyDropdown: React.FC<CompanyDropdownProps> = (props) => {
  const companies = useFragment(
    CompanyDropdown_CompaniesFragment,
    props.companies,
  )

  const companyOptions = useMemo(() => {
    if (companies?.length) {
      if (props.defaultValue) {
        return [props.defaultValue, ...companies]
      } else {
        return [...companies]
      }
    }
  }, [props.defaultValue, companies])

  const selectedCompany = useMemo(() => {
    if (
      companies?.length &&
      props.companyId &&
      props.companyId !== props.defaultValue?._id
    ) {
      return companies.find((company) => company._id === props.companyId)
    }
    if (props.defaultValue) {
      return props.defaultValue
    }
    return null
  }, [props.companyId, companies, props.defaultValue])

  return (
    <Dropdown
      className={classNames('w-full', props.className)}
      value={selectedCompany}
      onChange={(e) => props.onChangeCompany(e.value._id)}
      options={companyOptions}
      optionLabel="name"
    />
  )
}

export default CompanyDropdown
