import queryString from 'query-string'
import { RouteComponentProps, Switch, useParams } from 'react-router-dom'
import FullScreenLoading from '@/components/FullScreenLoading'
import SentryRoute from '@/components/SentryRoute'
import AddItemPage from '@/domains/deals/components/AddItem/AddItem'
import { EItemEvaluationActionType } from '@/domains/deals/components/constants/enums'
import { useGetDealById } from '@/domains/deals/hooks'
import DealDetails from '@/redesign/domains/deals/components/DealDetails'
import ItemEvaluation from './addItem/[algoliaObjectId]'

function DealDetailPage(props: RouteComponentProps) {
  const { dealId } = useParams<{ dealId?: string }>()

  const { deal, queryResult } = useGetDealById({
    variables: { dealId },
    skip: !dealId,
  })

  if (!dealId) {
    return null
  }

  return (
    <div className={'u-12/12'}>
      {(queryResult.loading || !deal) && <FullScreenLoading />}
      {!queryResult.loading && deal && (
        <Switch>
          <SentryRoute
            path={`${props.match.path}/addItem/:productRef`}
            render={(props) => {
              const urlParts = props.location.pathname.split('?')
              const pathNameSearch =
                urlParts.length > 0 ? `?${urlParts[1]}` : ''
              const search = props.location.search || pathNameSearch

              const { productRef } = props.match.params
              const product =
                productRef.length === 24 // mongo object id
                  ? { productVariantId: productRef }
                  : { productObjectID: productRef }

              const { currentItemId } = queryString.parse(search)

              const currentItem = deal.items.find((item) => {
                return item._id === currentItemId
              })

              return (
                <ItemEvaluation
                  deal={deal}
                  actionType={
                    currentItem
                      ? EItemEvaluationActionType.EXCHANGE_ITEM
                      : EItemEvaluationActionType.ADD_PRODUCT_TO_DEAL
                  }
                  item={currentItem}
                  {...product}
                />
              )
            }}
          />
          <SentryRoute
            path={`${props.match.path}/addItem`}
            render={(props) => {
              const urlParts = props.location.pathname.split('?')
              const pathNameSearch =
                urlParts.length > 0 ? `?${urlParts[1]}` : ''
              const search = props.location.search || pathNameSearch

              const { currentItemId } = queryString.parse(search)

              const currentItem = deal.items.find((item) => {
                return item._id === currentItemId
              })
              return <AddItemPage deal={deal} currentItem={currentItem} />
            }}
          />
          <SentryRoute
            path={props.match.path}
            render={() => {
              return (
                <DealDetails
                  deal={deal}
                  queryResult={queryResult}
                  refetchDeal={queryResult.refetch}
                />
              )
            }}
          />
        </Switch>
      )}
    </div>
  )
}

export default DealDetailPage
