import { useGetActiveCompanies } from '../../companies/hooks'
import { useGetShops } from '../../deals/hooks'
import styled from '@emotion/styled'
import { Button, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import { Fragment, useState } from 'react'
import { JsonTree } from 'react-editable-json-tree'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import BreadcrumbsBar from '@/components/BreadcrumbsBar'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectOptionInput from '@/components/SelectOptionInput'
import { DEPRECIATION_ITEMS_TEMPLATE_URL } from '@/domains/deals/components/constants/template'
import DownloadIcon from '@/images/download.svg'
import { ProductPriceDiff } from '@/types'
import styles from './ImportExport.module.scss'

const pdfDownloadTypes = [
  { value: 'PAWNS', label: 'Pfandscheine' },
  { value: 'BILLS', label: 'Rechnungen' },
  { value: 'MARKETING_CREDIT_NOTES', label: 'Marketing-Gutschrift' },
  { value: 'VALORIZATION_BILLS', label: 'Verwertungsrechnungen' },
  { value: 'VALORIZATION_CREDIT_NOTES', label: 'Verwertungsgutschriften' },
  { value: 'RECEIPTS', label: 'Quittungen' },
  { value: 'OTHER_REVENUE_BILLS', label: 'Other Revenue' },
]

const months = [
  { value: 1, label: 'Jänner' },
  { value: 2, label: 'Februar' },
  { value: 3, label: 'März' },
  { value: 4, label: 'April' },
  { value: 5, label: 'Mai' },
  { value: 6, label: 'Juni' },
  { value: 7, label: 'Juli' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'Oktober' },
  { value: 11, label: 'November' },
  { value: 12, label: 'Dezember' },
]

const startYear = 2019
const years: { value: number; label: string }[] = []

const yearNow = dayjs().year()
const monthNow = dayjs().month() + 1

for (let i = startYear; i <= yearNow; ++i) {
  years.push({ value: i, label: i.toString() })
}

export interface ImportExportProductsProps {
  downloadPdfs: (
    type: string,
    month: number,
    year: number,
    companyId: string,
    shopId: string,
  ) => void
  pdfDownloadLoading: boolean
  downLoadProducts: () => void
  productsDownloading: boolean
  productFileImporting?: 'price' | 'content' | 'itemInternalPrice'
  handleProductPricesXlsxSelected: (
    e: any,
    type: 'price' | 'content' | 'itemInternalPrice',
  ) => void
  handleItemInternalPriceXlsxSelected: (e: any) => void
  productPrices?: ProductPriceDiff[]
  updateProductPrices: () => void
  updateProductPricesLoading: boolean
}

export function ImportExportProducts(props: ImportExportProductsProps) {
  const { t } = useTranslation()

  const {
    downloadPdfs,
    pdfDownloadLoading,
    downLoadProducts,
    productsDownloading,
    handleProductPricesXlsxSelected,
    productFileImporting,
    productPrices,
    updateProductPrices,
    updateProductPricesLoading,
    handleItemInternalPriceXlsxSelected,
  } = props
  const [pdfDownloadType, setPdfDownloadType] = useState<string>('PAWNS')
  const [pdfDownloadMonth, setPdfDownloadMonth] = useState<number>(monthNow)
  const [pdfDownloadYear, setPdfDownloadYear] = useState<number>(yearNow)
  const [pdfDownloadCompanyId, setPdfDownloadCompanyId] = useState('')
  const [pdfDownloadShopId, setPdfDownloadShopId] = useState('')

  const { companies } = useGetActiveCompanies()

  const { shops } = useGetShops({
    variables: {
      opts: {
        filter: {
          includeDisabled: true,
        },
      },
    },
  })

  const _downloadPdfs = () => {
    if (pdfDownloadType && pdfDownloadMonth && pdfDownloadYear) {
      downloadPdfs(
        pdfDownloadType,
        pdfDownloadMonth,
        pdfDownloadYear,
        pdfDownloadCompanyId,
        pdfDownloadShopId,
      )
    }
  }

  const buildFileSelector = (
    handleFileSelection: (e: any) => void,
    fileType?: string,
  ) => {
    const fileSelector: HTMLInputElement = document.createElement('input')
    fileSelector.setAttribute('type', 'file')
    if (fileType) fileSelector.accept = fileType
    fileSelector.onchange = handleFileSelection

    return fileSelector
  }

  const _openFileSelectMenu = (
    e: any,
    handleFileSelection: (e: any) => void,
    fileType?: string,
  ) => {
    e.preventDefault()
    const fileSelector: HTMLInputElement = buildFileSelector(
      handleFileSelection,
      fileType,
    )
    fileSelector.click()
  }

  const shouldNotShowShopSelects = [
    'VALORIZATION_BILLS',
    'VALORIZATION_CREDIT_NOTES',
  ].includes(pdfDownloadType)

  return (
    <div className={`${styles.root} u-12/12`}>
      <BreadcrumbsBar
        entries={[
          {
            label: t('import_export'),
            url: '/inApp/importExport',
          },
        ]}
      />
      <Fragment>
        <Typography variant="h4" gutterBottom className="u-mt-20">
          {t('import')}
        </Typography>
        <div className="u-flex u-ai-center">
          <Button
            className="u-mv-sm"
            onClick={(e) =>
              _openFileSelectMenu(
                e,
                (e) => handleProductPricesXlsxSelected(e, 'price'),
                '.xlsx',
              )
            }
            variant="contained"
          >
            {t('import_product_prices')}
          </Button>
          {productFileImporting === 'price' && (
            <div className="u-ml-sm u-inline-block">
              <LoadingSpinner />
            </div>
          )}
          <Button
            className="u-mv-sm u-ml-30"
            onClick={(e) =>
              _openFileSelectMenu(
                e,
                (e) => handleProductPricesXlsxSelected(e, 'content'),
                '.xlsx',
              )
            }
            variant="contained"
          >
            {t('import_product_content')}
          </Button>
          {productFileImporting === 'content' && (
            <div className="u-ml-sm u-inline-block">
              <LoadingSpinner />
            </div>
          )}
        </div>
        <div className="u-flex u-ai-center" />
        {productPrices && (
          <div>
            <JsonTree
              data={productPrices}
              rootName={t('edited_products')}
              readOnly={true}
              isCollapsed={() => false}
            />
            {productPrices && productPrices.length > 0 && (
              <Button
                className="u-mv-sm"
                onClick={() => updateProductPrices()}
                variant="contained"
              >
                {t('save_edited_products')}
              </Button>
            )}
            {updateProductPricesLoading && (
              <div className="u-ml-sm u-inline-block">
                <LoadingSpinner />
              </div>
            )}
          </div>
        )}
      </Fragment>

      <div>
        <Typography variant="h4" gutterBottom className="u-mt-20">
          {t('export')}
        </Typography>
      </div>
      <div className="u-flex u-ai-center">
        <Button
          className="u-mv-sm u-mr-sm"
          onClick={_downloadPdfs}
          variant="contained"
        >
          {t('download_pdf')}
        </Button>
        <SelectOptionInput
          className={'u-mr-30'}
          value={pdfDownloadType}
          onChange={(value) => setPdfDownloadType(value)}
          menuItems={pdfDownloadTypes}
          label={`${t('pdf_download_type')}:`}
        />
        <SelectOptionInput
          className={'u-mr-30'}
          value={pdfDownloadMonth}
          onChange={(value) => setPdfDownloadMonth(value)}
          menuItems={months}
          label={`${t('month')}:`}
        />
        <SelectOptionInput
          className={'u-mr-30'}
          value={pdfDownloadYear}
          onChange={(value) => setPdfDownloadYear(value)}
          menuItems={years}
          label={`${t('year')}:`}
        />
        <SelectOptionInput
          hasNone
          value={pdfDownloadCompanyId}
          onChange={(value) => setPdfDownloadCompanyId(value)}
          menuItems={companies.map((company) => ({
            label: company.name,
            value: company._id,
          }))}
          label={`${t('company.singular')}:`}
        />
        {!shouldNotShowShopSelects && (
          <SelectOptionInput
            hasNone
            value={pdfDownloadShopId}
            onChange={(value) => setPdfDownloadShopId(value)}
            menuItems={shops.map((shop) => ({
              label: shop.name,
              value: shop._id,
            }))}
            label={`${t('shop.label')}:`}
          />
        )}

        {pdfDownloadLoading && (
          <div className="u-ml-sm u-inline-block">
            <LoadingSpinner />
          </div>
        )}
      </div>
      <div className="u-flex u-ai-center">
        <Button
          className="u-mv-sm u-mr-sm"
          onClick={downLoadProducts}
          variant="contained"
        >
          {t('download_products')}
        </Button>
        {productsDownloading && (
          <div className="u-ml-sm u-inline-block">
            <LoadingSpinner />
          </div>
        )}
      </div>
      <div className="u-ai-center u-mt-20">
        <Typography variant="h6">
          {t('upload_depreciation_for_items')}
        </Typography>
        <UploadButtonWrapper>
          <Button
            className="u-mv-sm"
            onClick={(e) =>
              _openFileSelectMenu(
                e,
                (e) => handleItemInternalPriceXlsxSelected(e),
                '.xlsx',
              )
            }
            variant="contained"
          >
            {t('customer.file_upload')}
          </Button>
          <p className="u-ml-30">{t('download_template')}</p>
          <a
            href={DEPRECIATION_ITEMS_TEMPLATE_URL}
            target="_blank"
            rel="noreferrer"
          >
            <ReactSVG src={DownloadIcon} className={styles.downloadIcon} />
          </a>
          {productFileImporting === 'itemInternalPrice' && (
            <div className="u-ml-sm u-inline-block">
              <LoadingSpinner />
            </div>
          )}
        </UploadButtonWrapper>
      </div>
    </div>
  )
}

const UploadButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export default ImportExportProducts
