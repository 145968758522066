import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationFindKsvAssetsArgs } from '@/schemaTypes'

const mutation = gql`
  mutation findKSVAssets($itemId: ObjectId!) {
    findKSVAssets(itemId: $itemId)
  }
`

type TData = { findKSVAssets: Mutation['findKSVAssets'] }
type TVariables = MutationFindKsvAssetsArgs

export function useQueryKSVAssetMutation(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const queryResult = useMutation<TData, TVariables>(mutation, {
    ...opts,
  })
  return queryResult
}
