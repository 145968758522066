import { Button } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AmountInput from '@/components/AmountInput'
import TextInput from '@/components/TextInput'
import ValidFromToDateHourPicker from '@/components/ValidFromToDateHourPicker'
import {
  useValidFromToDateChange,
  useValidFromToHourChange,
  useValidationErrorMap,
} from '@/hooks'
import { MaterialPrice } from '@/schemaTypes'
import { ValidFromToFieldName } from '@/types'
import { MaterialPriceValidation } from '@/validation'

export type MaterialPriceValidationErrorObject = Partial<
  Pick<MaterialPrice, 'date' | 'material' | 'price' | 'mu' | 'sigma'>
>
export interface MaterialPriceValidationError {
  fieldName: keyof MaterialPriceValidationErrorObject
  message: string
}
export interface MaterialPricesDetailsProps {
  materialPrice: MaterialPrice
  onSave: (materialPrice: MaterialPrice) => void
}

dayjs.extend(utc)

export function MaterialPricesDetails(props: MaterialPricesDetailsProps) {
  const { materialPrice: persistedMaterialPrice } = props
  const { t } = useTranslation()

  const [materialPrice, setMaterialPrice] = useState<MaterialPrice>(
    persistedMaterialPrice,
  )
  const { validationErrorsMap, isValid } = useValidationErrorMap(
    materialPrice,
    MaterialPriceValidation,
  )

  const setValidFromTo = (newDate: Date, fieldName: ValidFromToFieldName) =>
    setMaterialPrice({ ...materialPrice, [fieldName]: newDate })

  const onDateChange = useValidFromToDateChange(setValidFromTo)
  const onHourChange = useValidFromToHourChange(setValidFromTo)

  const onReset = () => {
    setMaterialPrice(persistedMaterialPrice)
  }

  const onSave = () => {
    if (isValid) {
      props.onSave(materialPrice)
    }
  }

  return (
    <Fragment>
      <ValidFromToDateHourPicker
        dateLabel={t('loan.valid_from')}
        timeLabel={t('utc_time')}
        error={validationErrorsMap.date}
        minDate={!materialPrice._id ? new Date() : undefined}
        value={materialPrice.date}
        onDateChange={(date) => onDateChange(date, materialPrice, 'date')}
        onHourChange={(hour) => onHourChange(hour, materialPrice, 'date')}
      />
      <TextInput
        error={validationErrorsMap.material}
        value={materialPrice.material}
        onChange={(value) =>
          setMaterialPrice({ ...materialPrice, material: value })
        }
        label={t('material.singular')}
      />
      <AmountInput
        error={validationErrorsMap.price}
        value={materialPrice.price}
        onChange={(value) =>
          setMaterialPrice({ ...materialPrice, price: value })
        }
        label={t('price')}
      />
      <AmountInput
        error={validationErrorsMap.mu}
        value={materialPrice.mu}
        onChange={(value) => setMaterialPrice({ ...materialPrice, mu: value })}
        label={t('mu')}
      />
      <AmountInput
        error={validationErrorsMap.sigma}
        value={materialPrice.sigma}
        onChange={(value) =>
          setMaterialPrice({ ...materialPrice, sigma: value })
        }
        label={t('sigma')}
      />
      <Button onClick={onSave} variant="contained" disabled={!isValid}>
        {t('save')}
      </Button>
      <Button onClick={onReset} variant="contained">
        {t('reset')}
      </Button>
    </Fragment>
  )
}

export default MaterialPricesDetails
