import { dealFragments } from '.'
import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationGeneratePowerOfAttorneyArgs } from '@/schemaTypes'

const GENERATE_POWER_OF_ATTORNEY = gql`
  mutation generatePowerOfAttorney(
    $generatePowerOfAttorneyArgs: GeneratePowerOfAttorneyArgs!
  ) {
    generatePowerOfAttorney(
      generatePowerOfAttorneyArgs: $generatePowerOfAttorneyArgs
    ) {
      _id
      pawnData {
        ...PawnDataFragment
      }
    }
  }
  ${dealFragments.pawnData}
`

type TData = { generatePowerOfAttorney: Mutation['generatePowerOfAttorney'] }
type TVariables = MutationGeneratePowerOfAttorneyArgs

export function useGeneratePowerOfAttorney(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [generatePowerOfAttorney] = useMutation<TData, TVariables>(
    GENERATE_POWER_OF_ATTORNEY,
    opts,
  )

  return generatePowerOfAttorney
}
