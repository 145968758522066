import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Mutation,
  MutationUpdateProductPropertyDefinitionArgs,
} from '@/schemaTypes'

const UPDATE_PRODUCT_PROPERTY_DEFINITION = gql`
  mutation updateProductPropertyDefinition(
    $args: ProductPropertyDefinitionUpdateArgs!
  ) {
    updateProductPropertyDefinition(args: $args) {
      _id
    }
  }
`

type TData = {
  updateProductPropertyDefinition: Mutation['updateProductPropertyDefinition']
}
type TVariables = MutationUpdateProductPropertyDefinitionArgs

export function useUpdateProductPropertyDefinition(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [mutation] = useMutation<TData, TVariables>(
    UPDATE_PRODUCT_PROPERTY_DEFINITION,
    opts,
  )
  return mutation
}
