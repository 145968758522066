import gql from 'graphql-tag'
import { dealCalculationFragments } from './dealFragments'

export const extensionFragments = {
  dealExtensionCalculation: gql`
    fragment PersistedDealExtensionCalculationFragment on PersistedDealExtensionCalculation {
      dueAmount
      dealExtensionCalculationId
      partialRedemptionAmount
      overwrittenPartialRedemptionAmount
      itemsExtensionAnswers {
        storageLabel
        answers {
          questionId
          selectedOptionIndex
          rangeValue
        }
      }
      parentDealCalculation {
        ...DealCalculationFragment
      }
      childDealCalculation {
        ...DealCalculationFragment
      }
    }
    ${dealCalculationFragments.dealCalculation}
  `,
}
