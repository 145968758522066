import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeactivateEmployeeArgs } from '@/schemaTypes'

const DEACTIVATE_EMPLOYEE = gql`
  mutation deactivateEmployee($employeeId: ObjectId!) {
    deactivateEmployee(employeeId: $employeeId)
  }
`

type TData = { deactivateEmployee: Mutation['deactivateEmployee'] }
type TVariables = MutationDeactivateEmployeeArgs

export function useDeactivateEmployee(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deactivateEmployee] = useMutation<TData, TVariables>(
    DEACTIVATE_EMPLOYEE,
    opts,
  )

  return deactivateEmployee
}
