import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Observable,
  split,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import * as Sentry from '@sentry/react'
import { withScalars } from 'apollo-link-scalars'
import { createUploadLink } from 'apollo-upload-client'
import { buildClientSchema } from 'graphql'
import { createClient } from 'graphql-ws'
import introspectionQueryResultData from './fragmentTypes.json'
import SCHEMA from './graphql.schema.json'
import { verifyToken } from './utils/jwt'

const schema = buildClientSchema(SCHEMA as any)

// parse DateTime scalar as JavaScript date
const typesMap = {
  DateTime: {
    serialize: (parsed: Date) => {
      return parsed.toISOString()
    },
    parseValue: (raw: string | number | null): Date | null => {
      return raw ? new Date(raw) : null
    },
  },
}

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_WS_URI ?? '',
    connectionParams: () => {
      const token = window.localStorage.getItem('token')
      const valid = verifyToken(token)

      return {
        authorization: token !== null && valid ? `Bearer ${token}` : '',
      }
    },
  }),
)

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_HTTP_URI,
  credentials: 'same-origin',
})
// const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_HTTP_URI, credentials: 'same-origin' }) // replaced by uploadLink

const link = ApolloLink.from([
  withScalars({
    schema,
    typesMap,
    removeTypenameFromInputs: true,
    validateEnums: true,
  }),
  split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    uploadLink,
  ),
])

const request = async (operation) => {
  const token = window.localStorage.getItem('token')
  const valid = verifyToken(token)

  operation.setContext({
    headers: {
      Accept: 'application/json',
      authorization: token !== null && valid ? `Bearer ${token}` : '',
    },
  })
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    }),
)

const mergeResultsForPagination = (existing, incoming) => {
  if (!incoming) {
    return existing
  }

  if (!existing) {
    return incoming
  }

  const nodes: Array<{ __ref: string }> = [...existing.nodes]

  const existingNodeRefs = existing.nodes.map((node) => node.__ref)
  const incomingNodeRefs = incoming.nodes.map((node) => node.__ref)

  if (incoming.pageInfo.skip >= existing.nodes.length) {
    // Only add incoming nodes when moving forward to avoid adding it again when moving backward
    nodes.push(...incoming.nodes)
  } else if (
    !incoming.nodes.length ||
    existingNodeRefs.length !== incomingNodeRefs.length ||
    existingNodeRefs.some((ref, index) => ref !== incomingNodeRefs[index])
  ) {
    /**
     * There are 2 cases which can happen here:
     * - The search filters changes -> the existing nodes should be replaced by incoming nodes definitely
     * - The data in DB are changed when moving backward which impacts the already fetched entries in existing nodes -> impact the offseting -> the existing nodes should be replaced by incoming nodes definitely
     */
    return incoming
  }

  return {
    nodes,
    pageInfo: {
      ...incoming.pageInfo,
    },
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError((e) => {
      /**
      Who is at fault: Request vs Server Errors

      Request Errors occur when the client is at fault. There are 3 phases to a GraphQL query and client-caused errors may occur in any of them:

      - Parse Phase: Client sent malformed GraphQL request, i.e. syntax error
      - Validation Phase: Client sent inputs that failed GraphQL type checking
      - Execution Phase: Client sent bad user input (request out of range, resource not found, duplicate key) or had bad permissions (user is not authorized to perform some action).

      On the other hand, Server Errors are execution errors where the server is at fault. This could be caused by a downstream API or database failure, or some other program bug.

      Where the error occurred: graphQLErrors vs networkError

      Apollo Client also distinguishes between two kinds of errors in the GraphQL response — graphQLErrors and networkError.
      Both of these fields are present on the error field, but they each have different semantics.
      The best way to think about the difference between the two is to consider where the error occurred.

      - networkError: Errors that are thrown outside of your resolvers. If networkError is present in your response, it means your entire query was rejected, and therefore no data was returned. For example, the client failed to connect to your GraphQL endpoint, or some error occurred within your request middleware, or there was an error in the parse/validation phase of your query.
      - graphQLErrors: Any error that is thrown within your resolvers. Since we have multiple resolvers executing that are all potential sources of errors, we need an array to represent all of them. More importantly, graphQLErrors from failed resolvers can be returned alongside data/fields that resolved successfully.
    */

      let errorMessage = ''

      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        errorMessage = `[GraphQLError] Operation "${e.operation.operationName}" failed:\n\n${e.graphQLErrors
          .map(
            (gqlError) => `${gqlError.extensions?.code}: ${gqlError.message}`,
          )
          .join('\n')}`
      } else {
        errorMessage = `[${e.networkError?.name}] Operation "${e.operation.operationName}" failed: ${e.networkError?.message}`
      }
      Sentry.captureException(errorMessage, {
        extra: {
          error: e,
        },
      })
      console.error(errorMessage)
    }),
    requestLink,
    link,
  ]),
  cache: new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          getNotifications: {
            keyArgs: false,
            merge(existing, incoming, options) {
              if (!incoming) return existing

              if (!existing || !options?.args?.args?.paginationArgs?.after) {
                return incoming
              }

              return {
                edges: [...existing.edges, ...incoming.edges],
                pageInfo: {
                  ...incoming.pageInfo,
                  startCursor: existing.pageInfo.startCursor,
                  hasPreviousPage: existing.pageInfo.hasPreviousPage,
                },
              }
            },
          },
          searchItems: {
            keyArgs: false,
            merge: mergeResultsForPagination,
          },
          searchProducts: {
            keyArgs: false,
            merge: mergeResultsForPagination,
          },
          searchDeals: {
            keyArgs: false,
            merge: mergeResultsForPagination,
          },
          searchCustomDeals: {
            keyArgs: false,
            merge: mergeResultsForPagination,
          },
          searchCustomers: {
            keyArgs: false,
            merge: mergeResultsForPagination,
          },
          searchPrivateSales: {
            keyArgs: false,
            merge: mergeResultsForPagination,
          },
          searchAuctions: {
            keyArgs: false,
            merge: mergeResultsForPagination,
          },
          searchCustomerBlacklists: {
            keyArgs: false,
            merge: mergeResultsForPagination,
          },
        },
      },
    },
  }),
})

export default client
