import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateDealNoteArgs } from '@/schemaTypes'

const UPDATE_DEAL_NOTE = gql`
  mutation updateDealNote(
    $dealId: ObjectId!
    $noteId: ObjectId!
    $noteUpdateArgs: NoteUpdateArgs!
  ) {
    updateDealNote(
      dealId: $dealId
      noteId: $noteId
      noteUpdateArgs: $noteUpdateArgs
    ) {
      _id
    }
  }
`

type TData = { updateNote: Mutation['updateDealNote'] }
type TVariables = MutationUpdateDealNoteArgs

export function useUpdateDealNote(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateNote] = useMutation<TData, TVariables>(UPDATE_DEAL_NOTE, opts)

  return updateNote
}
