import styled from '@emotion/styled'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { TreeItem, TreeView } from '@material-ui/lab'
import { cloneDeep, remove } from 'lodash'
import React, { useMemo } from 'react'
import { ItemCategory } from '@/schemaTypes'
import ItemCategoriesRow from './ItemCategoriesRow'

interface ItemCategoriesTreeViewProps {
  itemCategories: ItemCategory[]
  filterCategoryId?: string
}
const ItemCategoriesTreeView = ({
  itemCategories,
  filterCategoryId,
}: ItemCategoriesTreeViewProps) => {
  const categoryTreeData = useMemo(
    () => buildCategoryTree(itemCategories, filterCategoryId),
    [itemCategories, filterCategoryId],
  )

  return (
    <TreeWrapper>
      <TreeView
        aria-label="rich object"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        style={{ flexGrow: 1, overflowY: 'auto' }}
      >
        {categoryTreeData.map((c) => renderTree(c))}
      </TreeView>
    </TreeWrapper>
  )
}

type CategoryTree = ItemCategory & { children?: CategoryTree[] }

const filterCategories = (
  filterCategoryId: string,
  itemCategories: ItemCategory[],
) => {
  const currentCategory = itemCategories.find((c) => c._id === filterCategoryId)
  if (!currentCategory) return itemCategories

  const childCategories = itemCategories.filter(
    (c) => c.parentId === currentCategory._id,
  )

  return [currentCategory, ...childCategories]
}

const buildChildrenCategoryTree = (
  categoryTree: CategoryTree,
  itemCategories: ItemCategory[],
) => {
  if (!itemCategories.length) return []
  const childrenCategories: CategoryTree[] = remove(
    itemCategories,
    (c) => c.parentId === categoryTree._id,
  )

  for (const childCategory of childrenCategories) {
    childCategory.children = buildChildrenCategoryTree(
      childCategory,
      itemCategories,
    )
  }

  return childrenCategories
}

const buildCategoryTree = (
  itemCategories: ItemCategory[],
  filterCategoryId?: string,
) => {
  const clonedItemCateogories = cloneDeep(itemCategories)
  const filteredItemCategories = filterCategoryId
    ? filterCategories(filterCategoryId, clonedItemCateogories)
    : clonedItemCateogories

  const categoryRootNodes: CategoryTree[] = filterCategoryId
    ? remove(
        filteredItemCategories,
        (c: ItemCategory) => c._id === filterCategoryId,
      )
    : remove(filteredItemCategories, (c: ItemCategory) => !c.parentId)

  for (const node of categoryRootNodes) {
    node.children = buildChildrenCategoryTree(node, filteredItemCategories)
  }

  return categoryRootNodes
}

const renderTree = (node: CategoryTree) => (
  <TreeItem
    key={node._id}
    nodeId={node._id}
    label={<ItemCategoriesRow key={node._id} itemCategory={node} />}
  >
    {Array.isArray(node.children)
      ? node.children.map((node) => renderTree(node))
      : null}
  </TreeItem>
)

export default ItemCategoriesTreeView

const TreeWrapper = styled.div`
  padding: 20px;
`
