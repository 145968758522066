import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { isEqual, sum } from 'lodash'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import { usePayDealSurplusAmount } from '@/domains/deals/hooks/payDealSurplusAmount'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import {
  Deal,
  DealSurplusPaymentArgs,
  EBusinessUnit,
  EManualPaymentType,
} from '@/schemaTypes'
import {
  displayLocalAmount,
  isManualPaymentType,
  isShopLevelPaymentType,
  paymentTypeUnion,
} from '@/utils/misc'
import { printLocalDate } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'
import SurplusCashbookConnection from './SurplusCashbookConnection'

export interface DealValorizationProps {
  deal: Deal
}

const DealValorization = ({ deal }: DealValorizationProps) => {
  const { t } = useTranslation()
  const { showInfo, closeConfirmModal } = useContext(Context)

  const { dealValorizationCalculation } = deal

  const [surplusPaymentArgs] = useState<DealSurplusPaymentArgs>({
    amount: dealValorizationCalculation?.surplusAmount ?? 0,
    createNewCashbook: true,
    dealId: deal._id,
    paymentDate: dayjs().toDate(),
    paymentType: isManualPaymentType(
      paymentTypeUnion(deal.payoutData.paymentType),
    )
      ? (paymentTypeUnion(deal.payoutData.paymentType) as EManualPaymentType)
      : EManualPaymentType.Cash,
  })

  const showConfirmPayCustomerSurplusPopup = useShowConfirmPopup({
    actionText: t('save'),
    abortActionText: t('cancel'),
  })

  const payDealSurplus = useMutationShowingErrors({
    mutation: usePayDealSurplusAmount(),
    successMessage: `${t('update_successful')}!`,
  })

  const handleConfirmPayCustomerSurplus = useCallback(() => {
    showConfirmPayCustomerSurplusPopup({
      state: surplusPaymentArgs,
      title: t('deal_valorization.payout_customer_surplus_for_deal', {
        bookingNumber: deal.bookingNumber,
      }),
      component: (
        state: DealSurplusPaymentArgs,
        setState: (data: DealSurplusPaymentArgs) => void,
      ) => {
        return (
          <SurplusCashbookConnection
            deal={deal}
            dealValorizationCalculation={dealValorizationCalculation}
            surplusPaymentArgs={state}
            setSurplusPaymentArgs={setState}
          />
        )
      },
      action: (state: DealSurplusPaymentArgs) => {
        if (
          state.createNewCashbook &&
          isShopLevelPaymentType(state.paymentType) &&
          !state.shopId
        ) {
          return showInfo(t('select_cashbook_for_booking'))
        }
        payDealSurplus({
          variables: {
            dealSurplusPaymentArgs: state,
          },
        })
        closeConfirmModal()
      },
      leftButtonAction: () => {
        closeConfirmModal()
      },
      preventAutoCloseByKey: true,
    })
  }, [
    closeConfirmModal,
    deal,
    dealValorizationCalculation,
    payDealSurplus,
    showConfirmPayCustomerSurplusPopup,
    showInfo,
    surplusPaymentArgs,
    t,
  ])

  const uniquePaybackCostsTitles = dealValorizationCalculation?.paybackCosts
    .map((c) => c.title)
    .filter((value, index, array) => array.indexOf(value) === index)

  if (!dealValorizationCalculation) return null

  return (
    <div className="inline-block min-w-min max-w-max">
      <Title className="mt-6 mb-2">{t('coverage')}</Title>
      <div className="min-w-[30rem]">
        <ContainerRow>
          <TableName>{t('deal_valorization.auction_revenues')}</TableName>
          <TableValue>
            {displayLocalAmount(
              dealValorizationCalculation.auctionRevenues
                .map((c) => c.finalPrice)
                .reduce((a, b) => {
                  return a + b
                }),
            )}
          </TableValue>
        </ContainerRow>

        {dealValorizationCalculation.auctionRevenues.map((itemRevenue) => (
          <ContainerRow key={itemRevenue.itemId}>
            <TableName disabled>{itemRevenue.title}</TableName>
            <TableValue disabled className="ml-4 w-1/5">
              {displayLocalAmount(itemRevenue.finalPrice)}
            </TableValue>
          </ContainerRow>
        ))}

        <DividerStyled />

        <ContainerRow>
          <TableName>{t('payout.payout_amount')}</TableName>
          <TableValue>
            {displayLocalAmount(-dealValorizationCalculation.payoutAmount)}
          </TableValue>
        </ContainerRow>

        {uniquePaybackCostsTitles.map((costTitle) => {
          const filtered = dealValorizationCalculation.paybackCosts.filter(
            (c) => isEqual(costTitle, c.title),
          )

          return (
            <>
              <ContainerRow key={costTitle}>
                <TableName>
                  {t(
                    costTitle
                      .toLowerCase()
                      .replace('deal_valorization_', 'deal_valorization.'),
                  )}
                </TableName>
                <TableValue>
                  {displayLocalAmount(-sum(filtered.map((c) => c.grossAmount)))}
                </TableValue>
              </ContainerRow>

              {filtered.length > 1 &&
                filtered.map((cost) => (
                  <ContainerRow key={cost.description}>
                    <TableName disabled>{cost.description}</TableName>
                    <TableValue disabled>
                      {displayLocalAmount(-cost.grossAmount)}
                    </TableValue>
                  </ContainerRow>
                ))}
            </>
          )
        })}

        <ContainerRow>
          <TableName>
            {t('deal_valorization.valorization_fee_inc_vat')}
          </TableName>
          <TableValue>
            {dealValorizationCalculation.valorizationFeeGross > 0
              ? displayLocalAmount(
                  -dealValorizationCalculation.valorizationFeeGross,
                )
              : '-'}
          </TableValue>
        </ContainerRow>

        <DividerStyled />

        <ContainerRow>
          <TableName>{t('deal_valorization.total_customer_debt')}</TableName>
          <TableValue>
            {displayLocalAmount(-dealValorizationCalculation.customerDebt)}
          </TableValue>
        </ContainerRow>

        <DividerStyled />
        <DividerStyled />

        <ContainerRow>
          <TableName>
            {t(
              dealValorizationCalculation.surplusAmount > 0
                ? 'deal_valorization.customer_surplus'
                : 'deal_valorization.outstanding_balance',
            )}
          </TableName>
          <TableValue>
            {displayLocalAmount(dealValorizationCalculation.surplusAmount)}
          </TableValue>
        </ContainerRow>

        {deal.surplusPaymentsData?.map((surplusPaymentData) => (
          <>
            <DividerStyled />

            <ContainerRow>
              <TableName color={'#22C55E'}>
                {t('deal_valorization.surplus_paid_out')}
              </TableName>
              <TableValue color={'#22C55E'}>
                {displayLocalAmount(surplusPaymentData.outFlow)}
              </TableValue>
            </ContainerRow>

            <DividerStyled />

            <ContainerRow>
              <TableName color={'#64748B'}>
                {`${printLocalDate(surplusPaymentData?.date, {
                  timezone: defaultTimezone,
                })} by ${surplusPaymentData.employee.firstname + ' ' + surplusPaymentData.employee.lastname} - ${surplusPaymentData.cashBookType} - ${
                  surplusPaymentData.cashBookLocation
                }`}
              </TableName>
            </ContainerRow>
          </>
        ))}
      </div>
      {deal.businessUnit === EBusinessUnit.General &&
      dealValorizationCalculation.surplusAmount > 0 &&
      !deal.surplusPaymentsData ? (
        <div className="text-center mt-8">
          <Button onClick={handleConfirmPayCustomerSurplus}>
            {t('deal_valorization.pay_customer_surplus')}
          </Button>
        </div>
      ) : null}
    </div>
  )
}

const Title = styled.h4`
  color: var(--global-text-color, #495057);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.09375rem;
`

const TableName = styled.p<{ disabled?: boolean; color?: string }>`
  color: ${(props) =>
    props.color ? props.color : 'var(--global-text-color, #6c757d)'};
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
  opacity: ${(props) => (props.disabled ? '0.6' : '1')};
`

const TableValue = styled.h5<{ disabled?: boolean; color?: string }>`
  color: ${(props) =>
    props.color ? props.color : 'var(--global-text-color, #495057)'};
  text-align: right;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.875rem;
  opacity: ${(props) => (props.disabled ? '0.6' : '1')};
`

const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.765rem;
`

const DividerStyled = styled(Divider)`
  &&& {
    margin: 0;
    margin-bottom: 3px;
  }
`

export default DealValorization
