import { useGetItemQuestionsByCategoryV2 } from '../../../hooks'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import React, { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Info from '@/components/Info'
import { ProductVariant } from '@/gql/graphql'
import {
  EQuestionType,
  ItemAnswerArgs,
  ItemQuestion,
  ProductLegacy,
  Scalars,
} from '@/schemaTypes'
import { buildQuestionsDefaultAnswer } from '@/utils/misc'
import styles from './ItemEvaluation.module.scss'

interface ItemCategoryQuestionsComponentProps {
  itemCategoryId: Scalars['ObjectId'] // Used to load questions
  itemAnswerArgs: ItemAnswerArgs[]
  setItemAnswerArgs: (value: ItemAnswerArgs[]) => void
  isReadOnly: boolean
  questions?: ItemQuestion[] // Sometimes we have the questions
  productLegacy?: ProductLegacy
  // TODO: make required to ensure it's passed wherever applicable
  productVariant?: ProductVariant
  hideConditionQuestion?: boolean
  loadCurrentAnswers?: boolean
  disabledQuestionsIds?: string[]
  omitDeactivatedQuestionsForNewProducts: boolean
}

const ItemCategoryQuestions: FunctionComponent<
  ItemCategoryQuestionsComponentProps
> = ({
  itemCategoryId,
  questions,
  itemAnswerArgs,
  setItemAnswerArgs,
  isReadOnly,
  productLegacy,
  productVariant,
  hideConditionQuestion,
  loadCurrentAnswers,
  disabledQuestionsIds,
  omitDeactivatedQuestionsForNewProducts,
}) => {
  const { t } = useTranslation()

  const { getItemQuestionsByCategoryV2, itemQuestions } =
    useGetItemQuestionsByCategoryV2({
      onCompleted: () => {
        if (!questions || questions.length === 0) {
          setItemAnswerArgs(
            buildQuestionsDefaultAnswer({
              questions: itemQuestions,
              t,
              productLegacy,
              productVariant,
              hideConditionQuestion,
            }),
          )

          if (loadCurrentAnswers) setItemAnswerArgs(itemAnswerArgs)
        }
      },
      skip: questions && questions.length > 0,
    })

  useEffect(() => {
    if ((!questions || questions.length === 0) && itemCategoryId) {
      getItemQuestionsByCategoryV2({
        variables: {
          itemCategoryId: itemCategoryId,
          date: dayjs().toDate(),
          omitDeactivatedQuestionsForNewProducts,
        },
      })
    }
  }, [
    itemCategoryId,
    getItemQuestionsByCategoryV2,
    questions,
    omitDeactivatedQuestionsForNewProducts,
  ])

  const onQuestionAnswer = (question: ItemQuestion, value: number) => {
    setItemAnswerArgs(
      itemAnswerArgs.map((answerArg) => {
        if (isEqual(answerArg.questionId, question._id)) {
          return {
            ...answerArg,
            selectedOptionIndex:
              question.questionType === EQuestionType.SingleChoice
                ? value
                : undefined,
            rangeValue:
              question.questionType === EQuestionType.Range ? value : undefined,
          }
        } else return answerArg
      }),
    )
  }
  return (
    <Table>
      <TableBody>
        {itemAnswerArgs.map((answerArg) => {
          let infoText = ''
          let optionsText: string | boolean = ''
          const question = (itemQuestions ?? questions)?.find((c) =>
            isEqual(c._id, answerArg.questionId),
          )

          if (!question) return

          if (
            typeof answerArg.selectedOptionIndex === 'number' &&
            question.singleChoiceOptions
          ) {
            optionsText =
              question.singleChoiceOptions.some((option) =>
                t(option.infoKey || ''),
              ) &&
              question.singleChoiceOptions
                .map((option) => {
                  const labelKey = t(option.labelKey || '')
                  const infoKey = t(option.infoKey || '')
                  const description = infoKey ? ': ' + infoKey : ''
                  return (
                    '<div style="padding-bottom: 5px"><b>' +
                    labelKey +
                    '</b>' +
                    description +
                    '</div>'
                  )
                })
                .join('')

            const option =
              question.singleChoiceOptions[answerArg.selectedOptionIndex]
            infoText = t(option?.infoKey || '')
          } else if (
            typeof answerArg.rangeValue === 'number' &&
            question.rangeData
          ) {
            infoText = question.infoKey
              ? t(question.infoKey) ?? question.infoKey
              : ''
          }

          const value =
            question?.questionType === EQuestionType.SingleChoice
              ? (answerArg.selectedOptionIndex as number)
              : (answerArg.rangeValue as number)

          const isQuestionReadOnly = disabledQuestionsIds?.includes(
            question._id,
          )

          return (
            <TableRow key={question._id} className={`${styles.tr}`}>
              <TableCell component="th" scope="row" className={`${styles.td}`}>
                {t(question.titleKey)}
                {optionsText && (
                  <Info
                    className={'o-media__fixed u-inline-block'}
                    svgClassName={`${styles.infoIcon} u-pl-5 u-pr-5`}
                    infoText={optionsText}
                  />
                )}
              </TableCell>
              <TableCell align="right">
                {question.questionType === EQuestionType.SingleChoice &&
                  question.singleChoiceOptions && (
                    <QuestionTypeSelect
                      isReadOnly={isReadOnly || isQuestionReadOnly}
                      value={value}
                      options={question.singleChoiceOptions.map((option, i) => {
                        return {
                          label: t(option.labelKey),
                          value: i,
                        }
                      })}
                      onChange={(value) => onQuestionAnswer(question, value)}
                    />
                  )}
                {question.questionType === EQuestionType.Range && (
                  <QuestionTypeRange
                    value={value}
                    disabled={isReadOnly || isQuestionReadOnly}
                    key={question._id}
                    min={question.rangeData?.minValue}
                    max={question.rangeData?.maxValue}
                    onChange={(value) => onQuestionAnswer(question, value)}
                  />
                )}
                {infoText && (
                  <Info
                    className={'o-media__fixed u-inline-block'}
                    svgClassName={`${styles.infoIcon} u-pl-5 u-pr-5`}
                    infoText={infoText}
                  />
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default ItemCategoryQuestions

interface QuestionTypeSelectProps {
  isReadOnly: boolean
  value: any
  options: {
    label: string
    value: any
  }[]
  onChange: (value: any) => void
}

const QuestionTypeSelect: React.FC<QuestionTypeSelectProps> = ({
  value,
  options,
  onChange,
  isReadOnly,
}) => {
  return (
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={isReadOnly}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}

const QuestionTypeRange = ({ min, max, onChange, value, disabled }) => {
  return (
    <Input
      type="number"
      value={value}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e: any) => {
        let value = Number(e.target.value)

        if (typeof min !== 'undefined' && min !== null) {
          value = Math.max(value, Number(min))
        }

        if (typeof max !== 'undefined' && max !== null) {
          value = Math.min(value, Number(max))
        }

        onChange(value)
      }}
    />
  )
}
