import { TableCell, TableRow } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import FileCopy from '@material-ui/icons/FileCopy'
import React from 'react'
import { Link } from 'react-router-dom'
import { Shop } from '@/schemaTypes'

export interface ShopsRowProps {
  shop: Shop
}

const ShopsRow = (props: ShopsRowProps) => {
  const { shop } = props
  const id = shop._id ?? ''

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell align="right">{shop.name}</TableCell>
      <TableCell align="right">{shop.phone}</TableCell>
      <TableCell align="right">{shop.email}</TableCell>
      <TableCell>
        <Link to={`/inApp/configurations/shops/create/${id}`}>
          <FileCopy />
        </Link>
        <Link to={`/inApp/configurations/shops/edit/${id}`}>
          <EditIcon />
        </Link>
      </TableCell>
    </TableRow>
  )
}

export default ShopsRow
