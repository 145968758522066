import withFieldWrapper from '../withFieldWrapper/withFieldWrapper'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { AutoComplete, AutoCompleteProps } from 'primereact/autocomplete'

interface TextareaProps extends AutoCompleteProps {
  isInvalid?: boolean
}

const InputAutoComplete = withFieldWrapper(
  ({ isInvalid, ...props }: TextareaProps) => {
    return (
      <InputAutoCompleteStyled
        className={classNames({ 'p-invalid': isInvalid })}
        {...props}
      />
    )
  },
)

const InputAutoCompleteStyled = styled(AutoComplete)`
  height: 2.375rem;
  &&& {
    .p-inputtext {
      padding: 0.56rem;
      font-size: 0.875rem;
    }
    .p-dropdown-label.p-placeholder {
      opacity: 1;
      color: #9ca3af;
    }
  }
`

export default InputAutoComplete
