import { RouteComponentProps, Switch, useParams } from 'react-router-dom'
import FullScreenLoading from '@/components/FullScreenLoading'
import SentryRoute from '@/components/SentryRoute'
import AddItemPage from '@/domains/deals/components/AddItem/AddItem'
import { EItemEvaluationActionType } from '@/domains/deals/components/constants/enums'
import { useGetItemCategory } from '@/domains/itemCategories/hooks'
import ItemEvaluation from '@/domains/items/components/Item/ItemEvaluation/ItemEvaluation'
import { ItemDetails } from '@/domains/items/components/ItemDetails'
import styles from '@/domains/items/components/ItemDetails.module.scss'
import { useGetDealById, useGetItemById } from '@/domains/items/hooks'
import { isVehicle } from '@/utils/misc'

export type ItemDetailsContainerProps = RouteComponentProps

export function ItemDetailsPage(props: ItemDetailsContainerProps) {
  const { itemId = '' } = useParams() as any
  const { item, queryResult: itemQueryresult } = useGetItemById({
    variables: { itemId },
    skip: !itemId,
  })

  const dealId = item?.dealsHistory[item.dealsHistory.length - 1].dealId
  const { deal, queryResult: dealQueryresult } = useGetDealById({
    variables: { dealId },
    skip: !dealId,
  })

  const { itemCategory } = useGetItemCategory({
    variables: { itemCategoryId: item?.dealsHistory[0].itemCategoryId },
    skip: !itemId,
  })

  if (!itemId) {
    return null
  }

  if (!deal || !itemCategory) return null

  return (
    <div className={`${styles.root} u-12/12`}>
      {(itemQueryresult.loading || !item) && <FullScreenLoading />}
      {!itemQueryresult.loading && item && (
        <Switch>
          <SentryRoute
            exact
            path={`${props.match.path}/editAnswers`}
            render={() => {
              if (!item) {
                return <h2>Item not found</h2>
              }
              return (
                <ItemEvaluation
                  deal={deal}
                  actionType={EItemEvaluationActionType.CHANGE_ITEM_ANSWER}
                  item={item}
                />
              )
            }}
          />
          <SentryRoute
            exact
            path={`${props.match.path}/editVehicleProperties`}
            render={() => {
              if (!item) {
                return <h2>Item not found</h2>
              }
              if (itemCategory && !isVehicle(itemCategory._id))
                return <h2>Only for cars allowed</h2>

              return (
                <ItemEvaluation
                  deal={deal}
                  actionType={EItemEvaluationActionType.EXCHANGE_ITEM}
                  item={item}
                />
              )
            }}
          />
          EditVehicleProperties
          <SentryRoute
            path={`${props.match.path}/addItem`}
            render={() => {
              return <AddItemPage deal={deal} currentItem={item} />
            }}
          />
          <SentryRoute
            path={`${props.match.path}`}
            render={() => {
              return (
                <ItemDetails
                  item={item}
                  itemCategory={itemCategory}
                  itemQueryResult={itemQueryresult}
                  deal={deal}
                  dealQueryResult={dealQueryresult}
                />
              )
            }}
          />
        </Switch>
      )}
    </div>
  )
}

export default ItemDetailsPage
