import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Mutation,
  MutationUpdateCurrentEmployeePreferencesArgs,
} from '@/schemaTypes'

const UPDATE_CURRENT_EMPLOYEE_PREFERENCES = gql`
  mutation updateCurrentEmployeePreferences(
    $preferences: EmployeePreferencesInput!
  ) {
    updateCurrentEmployeePreferences(preferences: $preferences) {
      _id
      preferences {
        defaultShopId
      }
    }
  }
`

type TData = {
  updateCurrentEmployeePreferences: Mutation['updateCurrentEmployeePreferences']
}
type TVariables = MutationUpdateCurrentEmployeePreferencesArgs

export function useUpdateCurrentEmployeePreferences(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateCurrentEmployeePreferences] = useMutation<TData, TVariables>(
    UPDATE_CURRENT_EMPLOYEE_PREFERENCES,
    opts,
  )

  return updateCurrentEmployeePreferences
}
