import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationCreateCashBookCashFlowArgs } from '@/schemaTypes'

export const CREATE_CASH_BOOK_CASH_FLOW = gql`
  mutation createCashBookCashFlow(
    $cashBookCashFlowArgs: CashBookCashFlowArgs!
  ) {
    createCashBookCashFlow(cashBookCashFlowArgs: $cashBookCashFlowArgs) {
      ...CashBookCashFlowFragment
    }
  }
  ${fragments.cashBookCashFlow}
  ${fragments.fileMetadata}
`

type TData = { createCashBookCashFlow: Mutation['createCashBookCashFlow'] }
type TVariables = MutationCreateCashBookCashFlowArgs

export function useCreateCashBookCashFlow(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createCashBookCashFlow] = useMutation<TData, TVariables>(
    CREATE_CASH_BOOK_CASH_FLOW,
    opts,
  )

  return createCashBookCashFlow
}
