import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateMaintenanceWindowArgs } from '@/schemaTypes'

const UPDATE_MAINTENANCE_WINDOW = gql`
  mutation updateMaintenanceWindow(
    $id: ObjectId!
    $scheduledStartTime: DateTime
    $isFinished: Boolean
    $duration: Float
  ) {
    updateMaintenanceWindow(
      id: $id
      scheduledStartTime: $scheduledStartTime
      isFinished: $isFinished
      duration: $duration
    )
  }
`

type TData = { updateMaintenanceWindow: Mutation['updateMaintenanceWindow'] }
type TVariables = MutationUpdateMaintenanceWindowArgs

export function useUpdateMaintenanceWindow(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateMaintenanceWindow] = useMutation<TData, TVariables>(
    UPDATE_MAINTENANCE_WINDOW,
    opts,
  )

  return updateMaintenanceWindow
}
