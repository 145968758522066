import withFieldWrapper from '../withFieldWrapper/withFieldWrapper'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { InputNumber, InputNumberProps } from 'primereact/inputnumber'

interface InputNumProps extends InputNumberProps {
  isInvalid?: boolean
  locale?: string
}

const InputNum = withFieldWrapper(
  ({ isInvalid, locale, ...props }: InputNumProps) => {
    return (
      <InputNumberStyled
        className={classNames({ 'p-invalid': isInvalid })}
        {...props}
        mode={props.mode || 'currency'}
        currency={props.currency || 'EUR'}
        locale={locale || 'de-DE'}
      />
    )
  },
)

const InputNumberStyled = styled(InputNumber)`
  &&& {
    .p-inputnumber-input {
      font-size: 0.875rem;
      height: 2.375rem;
      padding: 0.66rem;
    }
  }
`

export default InputNum
