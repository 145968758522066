import styled from '@emotion/styled'
import {
  Button,
  Checkbox,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { useShowConfirmPopup } from '@/hooks'
import AlarmIcon from '@/images/alarm.svg'
import EditIcon from '@/images/modeEdit.svg'
import { Note, NoteUpdateArgs } from '@/schemaTypes'

interface NoteElementProps {
  note: Partial<Note>
  onUpdateNote?: (noteId: string, note: NoteUpdateArgs) => void
  readOnly?: boolean
  isOwnerNote?: boolean
  allowDisablePopup?: boolean
  hideUserInfo?: boolean
  horizontalMode?: boolean
}

const NoteElement = ({
  note,
  onUpdateNote = () => {},
  readOnly = false,
  isOwnerNote = false,
  allowDisablePopup = false,
  horizontalMode = false,
  hideUserInfo = false,
}: NoteElementProps) => {
  const { t } = useTranslation()
  const showConfirmPopup = useShowConfirmPopup({
    actionText: t('yes'),
    abortActionText: t('no'),
  })
  const [editMode, setEditMode] = useState(false)
  const [localNote, setLocalNote] = useState<
    Pick<Partial<Note>, 'content' | 'isPopup'>
  >({
    content: note.content,
    isPopup: note.isPopup,
  })

  const onSave = () => {
    onUpdateNote(note._id, localNote)
    setEditMode(false)
  }

  const disablePopup = () => {
    onUpdateNote(note._id, { isPopup: false })
  }

  const showDisablePopup = () => {
    if (!allowDisablePopup) return
    showConfirmPopup({
      title: t('disable_note_popup'),
      action: disablePopup,
    })
  }

  useEffect(() => {
    setLocalNote({
      content: note.content,
      isPopup: note.isPopup,
    })
  }, [note])

  const employeeName = note.employee
    ? `${note.employee?.firstname} ${note.employee?.lastname}`
    : 'Unknown'
  const modifiedDate = note.updatedAt
    ? dayjs(note.updatedAt).format('DD.MM.YYYY - HH:mm')
    : 'Unknown Date'

  return (
    <NoteWrapper>
      <NoteOwner>
        {!hideUserInfo && (
          <div>
            {employeeName} - {modifiedDate}
          </div>
        )}
        {!readOnly && note.isPopup && (
          <IconAlarmWrapper
            allowDisablePopup={allowDisablePopup}
            onClick={showDisablePopup}
          >
            <ReactSVG src={AlarmIcon} />
          </IconAlarmWrapper>
        )}
        {!readOnly && !editMode && isOwnerNote && (
          <IconWrapper onClick={() => setEditMode(true)}>
            <ReactSVG src={EditIcon} />
          </IconWrapper>
        )}
      </NoteOwner>
      {!editMode ? (
        <NoteContent>{note.content}</NoteContent>
      ) : (
        <div
          style={
            horizontalMode
              ? { display: 'flex', justifyContent: 'space-between' }
              : {}
          }
        >
          <TextareaAutosize
            rowsMin={5}
            placeholder={t('note_placeholder')}
            style={{ width: 500 }}
            value={localNote.content}
            onChange={(e) =>
              setLocalNote({ ...localNote, [e.target.name]: e.target.value })
            }
            name="content"
          />
          <br />
          <Typography
            variant="body1"
            gutterBottom
            style={{ marginTop: horizontalMode ? 22 : 0 }}
          >
            {t('popup_note')}:
            <Checkbox
              name="isPopup"
              checked={localNote.isPopup ? localNote.isPopup : false}
              onChange={(e) =>
                setLocalNote({
                  ...localNote,
                  [e.target.name]: e.target.checked,
                })
              }
            />
          </Typography>
          <br />
          <NoteButton
            variant="contained"
            onClick={onSave}
            className="u-mt-sm u-mr-sm u-mb-sm"
            disabled={!note.content}
          >
            {t('save_note')}
          </NoteButton>
        </div>
      )}
    </NoteWrapper>
  )
}

const NoteButton = styled(Button)`
  background-color: #683ab7c0;
  color: white;
  &:hover {
    background-color: #673aba;
  }
`

const NoteWrapper = styled.div`
  font-size: 14px;
  color: #000000;
`

const NoteOwner = styled.div`
  font-weight: bold;
  color: #a6a6a6;
  display: flex;
  gap: 0.5rem;
  padding-top: 1rem;
`

const NoteContent = styled.div`
  white-space: pre-line;
`

const IconWrapper = styled.div`
  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`

const IconAlarmWrapper = styled.div`
  pointer-events: ${(props: Pick<NoteElementProps, 'allowDisablePopup'>) =>
    props.allowDisablePopup ? 'inherit' : 'none'};
  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`

export default NoteElement
