import { dealCalculationFragments, dealFragments } from '../dealFragments'
import { useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { useMutationShowingErrors } from '@/hooks'
import {
  ConfirmExtensionArgs,
  Mutation,
  MutationConfirmExtensionArgs,
} from '@/schemaTypes'

dayjs.extend(utc)

const CONFIRM_EXTENSION = gql`
  mutation confirmExtension($args: ConfirmExtensionArgs!) {
    confirmExtension(args: $args) {
      _id
      events {
        ...DealEventFragment
      }
      pawnData {
        ...PawnDataFragment
      }
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
      dealChain {
        bookingNumber
        dealId
        chainType
      }
    }
  }
  ${dealFragments.event}
  ${dealFragments.pawnData}
  ${dealFragments.dealItem}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = { confirmExtension: Mutation['confirmExtension'] }
type TVariables = MutationConfirmExtensionArgs

export function useOnDealStatusExtensionConfirmed(
  confirmExtensionArgs: ConfirmExtensionArgs,
) {
  const { t } = useTranslation()

  const [confirmExtension] = useMutation<TData, TVariables>(CONFIRM_EXTENSION, {
    variables: {
      args: confirmExtensionArgs,
    },
  })

  return useMutationShowingErrors({
    mutation: confirmExtension,
    successMessage: t('update_successful'),
  })
}
