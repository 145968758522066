import {
  UIState,
  closeModal,
  employeeActiveShop,
  showModal,
  switchCompany,
  switchDefaultRefurbedShippingProfile,
} from '../actions/ui'
import { createReducer } from '@reduxjs/toolkit'

const initialState: UIState = {
  alerts: [],
  companyId: window.localStorage.getItem('companyId'),
  employeeActiveShop: JSON.parse(
    window.localStorage.getItem('employeeActiveShop') || 'null',
  ),
  defaultRefurbedShippingProfile:
    window.localStorage.getItem('defaultRefurbedShippingProfile') || '',
}

const uiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(showModal, (state, action) => {
      state.alerts.push(action.payload)
    })
    .addCase(closeModal, (state) => {
      state.alerts.shift()
    })
    .addCase(switchCompany, (state, action) => {
      window.localStorage.setItem('companyId', action.payload)
      state.companyId = action.payload
    })
    .addCase(employeeActiveShop, (state, action) => {
      window.localStorage.setItem(
        'employeeActiveShop',
        JSON.stringify(action.payload),
      )
      state.employeeActiveShop = action.payload
    })
    .addCase(switchDefaultRefurbedShippingProfile, (state, action) => {
      window.localStorage.setItem(
        'defaultRefurbedShippingProfile',
        action.payload,
      )
      state.defaultRefurbedShippingProfile = action.payload
    })
})

export default uiReducer
