import { itemFragments } from '../itemDetails/itemFragments'
import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { Query, QueryGetItemsArgs } from '@/schemaTypes'

const GET_ITEMS = gql`
  query getItems($opts: ItemOpts) {
    getItems(opts: $opts) {
      _id
      createdAt
      policeNumber
      isDamageCase
      isForPersonalUse
      isInvestmentGold
      companyId
      isValuable
      usedGrade
      itemCategory {
        _id
        name
        expectedTurnOverTime
      }
      media {
        ...FileMetadataFragment
      }
      note
      serialNumber
      storageFacilityId
      updatedAt
      vehicleData {
        vehicleProperties {
          make
          model
          regdate
          body
          facelift
          seats
          engine
          transmission
          odometer
          otherVehicleProperties {
            value
          }
        }
        vin
        indicataId
        carPriceLastFetched
        vinLastFetched
        hasTypeCertificate
        hasApprovalCertificate
        has57aGutachten
        has57aGutachtenDue
        hasSecondKey
        hasSaleContract
      }
      dealsHistory {
        dealId
        algoliaReference
        title
        material
        answers {
          questionId
          selectedOptionIndex
          rangeValue
        }
        questions {
          ...ItemQuestionFragment
        }
        questionIds
        itemCategoryId
        values {
          verified {
            currentMarketValue
            predictedMarketValue
            adjustedMarketValue
            loanToValue
            maxMarketValue
          }
        }
      }
      isInStore
      itemNotifications
      lastBookingNumber
      bookingNumbers
      storageHistory {
        storageUnits {
          lvl1
          lvl2
          lvl3
          lvl4
        }
        createdAt
        timestamp
        storageStatus
        employeeId
        note
      }
      storageLabel
      events {
        ...ItemEventFragment
      }
      internalSaleData {
        date
        price
        purchasedDirectly
      }
    }
  }
  ${fragments.fileMetadata}
  ${itemFragments.itemQuestionFragment}
  ${itemFragments.itemEvent}
`

type TData = { getItems: Query['getItems'] }
type TVariables = QueryGetItemsArgs

export function useGetItems(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useQuery<TData, TVariables>(GET_ITEMS, {
    variables: {},
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    items: queryResult.data?.getItems || [],
    itemsTotal: queryResult.data?.getItems.length,
  }
}
