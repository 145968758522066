import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryGetActiveStorageFacilitiesArgs } from '@/schemaTypes'

const GET_ACTIVE_STORAGE_FACILITIES = gql`
  query getActiveStorageFacilities($opts: StorageFacilityOpts) {
    getActiveStorageFacilities(opts: $opts) {
      _id
      name
      storageUnitIds
      address {
        ...AddressFragment
      }
      external
    }
  }
  ${fragments.address}
`

type TData = { getActiveStorageFacilities: Query['getActiveStorageFacilities'] }
type TVariables = QueryGetActiveStorageFacilitiesArgs

export function useGetActiveStorageFacilities(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_ACTIVE_STORAGE_FACILITIES,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    storageFacilities: queryResult.data?.getActiveStorageFacilities || [],
  }
}
