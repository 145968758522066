import { Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import ResponseTemplateDetails from '@/domains/responseTemplates/components/ResponseTemplateDetails'
import { useGetResponseTemplate } from '@/domains/responseTemplates/hooks'
import { useUpdateResponseTemplate } from '@/domains/responseTemplates/hooks/updateResponseTemplate'
import { useMutationShowingErrors } from '@/hooks'
import { ResponseTemplate } from '@/schemaTypes'

export interface ResponseTemplateEditProps {
  responseTemplateId: string | null
}

export function ResponseTemplateEdit(props: ResponseTemplateEditProps) {
  const { responseTemplateId } = props
  const { t } = useTranslation()
  const history = useHistory()

  const { responseTemplate, queryResult } = useGetResponseTemplate({
    variables: {
      responseTemplateId: responseTemplateId,
    },
  })

  const updateResponseTemplate = useMutationShowingErrors({
    mutation: useUpdateResponseTemplate(),
    successMessage: t('response_template_updated'),
  })

  const onSave = (responseTemplate: ResponseTemplate) => {
    updateResponseTemplate({
      variables: {
        responseTemplate,
      },
    })
  }

  const onClose = () => {
    history.replace(`/inApp/configurations/responseTemplates`)
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={responseTemplateId ?? ''}
        modelName={'responseTemplates'}
        mode={'edit'}
      />
      <Typography variant="h5" gutterBottom>
        {t('response_template_edit')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        responseTemplate && (
          <ResponseTemplateDetails
            responseTemplate={responseTemplate}
            onSave={onSave}
            onClose={onClose}
          />
        )
      )}
    </Paper>
  )
}

export default ResponseTemplateEdit
