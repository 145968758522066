import gql from 'graphql-tag'

export const paymentFragments = {
  anyPaymentType: gql`
    fragment AnyPaymentTypeFragment on AnyPaymentType {
      ... on ManualPaymentWrapper {
        manual: value
      }
      ... on AutomaticPaymentWrapper {
        automatic: value
      }
    }
  `,
}

export const fragments = {
  address: gql`
    fragment AddressFragment on OAddress {
      countryCode
      country
      coordinates {
        lat
        lng
      }
      stairway
      floor
      door
      state
      queryString
      address1
      address2
      houseNumber
      street
      city
      zipCode
      note
      name
      companyName
    }
  `,
  buyerBillingAddress: gql`
    fragment BuyerBillingAddressFragment on BuyerBillingAddress {
      countryCode
      country
      coordinates {
        lat
        lng
      }
      stairway
      floor
      door
      state
      queryString
      address1
      address2
      houseNumber
      street
      city
      zipCode
      note
      name
      companyName
      uid
    }
  `,
  storeboxPickupAddress: gql`
    fragment StoreboxPickupAddressFragment on StoreboxPickupAddress {
      countryCode
      country
      stairway
      floor
      door
      state
      houseNumber
      street
      city
      zipCode
      note
      name
    }
  `,
  customerAddress: gql`
    fragment CustomerAddressFragment on OCustomerAddress {
      countryCode
      country
      coordinates {
        lat
        lng
      }
      stairway
      floor
      door
      state
      queryString
      address1
      address2
      houseNumber
      street
      city
      zipCode
      note
      isBillingAddress
      isDeliveryAddress
      isRegistrationAddress
      name
      companyName
    }
  `,
  paymentData: gql`
    fragment PaymentDataFragment on PaymentData {
      paymentType {
        ...AnyPaymentTypeFragment
      }
      iban
      paypalAddress
      paypalTransactionId
    }
    ${paymentFragments.anyPaymentType}
  `,
  secondaryPaymentData: gql`
    fragment SecondaryPaymentDataFragment on SecondaryPaymentData {
      paymentType {
        ...AnyPaymentTypeFragment
      }
      iban
      paypalAddress
      paypalTransactionId
      amount
    }
    ${paymentFragments.anyPaymentType}
  `,
  fileMetadata: gql`
    fragment FileMetadataFragment on FileMetadata {
      url
      contentType
      filename
      createdAt
    }
  `,
  pawnDocument: gql`
    fragment PawnDocumentFragment on PawnDocument {
      customerId
      fileMetadata {
        url
        contentType
        filename
        createdAt
      }
    }
  `,
  cashBookCount: gql`
    fragment CashBookCountFragment on CashBookCount {
      _id
      shopId
      employeeId
      employee {
        _id
        firstname
        lastname
      }
      date
      paymentType
      temporary
      cashBoxEntries {
        cashBoxName
        counts {
          EURO_500
          EURO_200
          EURO_100
          EURO_50
          EURO_20
          EURO_10
          EURO_5
          EURO_2
          EURO_1
          EURO_CENT_50
          EURO_CENT_20
          EURO_CENT_10
          EURO_CENT_5
          EURO_CENT_2
          EURO_CENT_1
        }
      }
    }
  `,
  cashBookCashFlow: gql`
    fragment CashBookCashFlowFragment on CashBookCashFlow {
      _id
      cashFlowNumber
      cashBookType
      date
      dealId
      deal {
        _id
        bookingNumber
        customer {
          ... on Customer {
            _id
            email
            customerNumber
          }
        }
      }
      itemId
      storageLabel
      employeeId
      shopId
      paymentReference
      note
      inflow
      outflow
      closedFeesTotalAmount
      accumulatedBalance
      date
      canceledById
      ready2OrderInvoices {
        numberFull
      }
      canceledBy {
        _id
        cashFlowNumber
      }
      cancelsId
      cancels {
        _id
        cashFlowNumber
      }
      attachment {
        ...FileMetadataFragment
      }
    }
  `,
  privateSaleTransaction: gql`
    fragment PrivateSaleTransactionFragment on PrivateSaleTransaction {
      date
      amount
      balance
      paymentType {
        ...AnyPaymentTypeFragment
      }
      order
      sortingOrder
      transactionType
      description
      buyerName
      costName
      ust
      grossAmount
      cashFlowId
      employeeId
      employeeName
      shopId
      transactionFileLink {
        url
        contentType
        filename
      }
    }
    ${paymentFragments.anyPaymentType}
  `,
  userTask: gql`
    fragment UserTaskFragment on UserTask {
      createdAt
      description
      updatedAt
      dueDate
      assigneeIds
      status
    }
  `,
  feeDefinition: gql`
    fragment FeeDefinitionFragment on FeeDefinition {
      _id
      company {
        name
      }
      companyId
      businessUnit
      priority
      amount
      amountForCategories {
        categoryId
        amount
      }
      billingPeriodType
      level
      mode
      feeType
      dealTypes
      restrictToCategories
      restrictToItemQuestion
      itemQuestionValidAnswer
      itemQuestionTitle
      transportMode
      isActive
      feeIntervals {
        from
        type
        diffAmount
      }
      withdrawalFeeIntervals {
        from
        factor
      }
      includedOnExtensions
      onlyWhenInAuction
    }
  `,
  feeDefinitionEvaluation: gql`
    fragment FeeDefinitionEvaluationFragment on FeeDefinitionEvaluation {
      oneTimePurchaseFeeEvaluation {
        ...FeeDefinitionFragment
      }
      oneTimePawnFeeEvaluation {
        ...FeeDefinitionFragment
      }
      dailyPurchaseFeeEvaluation {
        ...FeeDefinitionFragment
      }
      dailyPawnFeeEvaluation {
        ...FeeDefinitionFragment
      }
      halfMonthPurchaseFeeEvaluation {
        ...FeeDefinitionFragment
      }
      halfMonthPawnFeeEvaluation {
        ...FeeDefinitionFragment
      }
      fullMonthPurchaseFeeEvaluation {
        ...FeeDefinitionFragment
      }
      fullMonthPawnFeeEvaluation {
        ...FeeDefinitionFragment
      }
    }
  `,
  calculatedFeeDefinition: gql`
    fragment CalculatedFeeDefinitionFragment on CalculatedFeeDefinition {
      description
      date
      finalGrossAmount
      finalNetAmount
      ust
      isManual
      appliedNetAmount
      appliedGrossAmount
      storedFeeDefinition {
        companyId
        priority
        amount
        billingPeriodType
        level
        mode
        feeType
        dealTypes
        transportMode
        isActive
        feeIntervals {
          from
          type
          diffAmount
        }
      }
    }
  `,
  vehicleData: gql`
    fragment VehicleDataFragment on VehicleData {
      vehicleProperties {
        make
        model
        regdate
        body
        facelift
        seats
        engine
        fuel
        horsePower
        transmission
        odometer
        otherVehicleProperties {
          value
          name
        }
      }
      vin
      indicataId
      carPriceLastFetched
      vinLastFetched
      hasTypeCertificate
      hasApprovalCertificate
      has57aGutachten
      has57aGutachtenDue
      hasSecondKey
      hasSaleContract
    }
  `,
  additionalInformationForCarPawn: gql`
    fragment AdditionalInformationForCarPawnFragment on AdditionalInformationForCarPawn {
      citizenship
      registeredInAustriaSince
      lastResidentialAddressSince
      employed
      paySlipsPresented
      hasSeizureEvident
      inInsolvencyDatabaseIn3Years
      appearSerious
      hasCarPawnRelationshipWithCashy
    }
  `,
  customDealFormQuestion: gql`
    fragment CustomDealFormQuestionFragment on CustomDealFormQuestion {
      __typename

      ... on BaseCustomDealFormQuestion {
        _id
        type
        titleKey
        label {
          allTranslations {
            languageCode
            text
          }
          text(languageCode: $languageCode)
        }
        showOnIssuanceCertificate
        showOnSalesBill
        info {
          allTranslations {
            languageCode
            text
          }
          text(languageCode: $languageCode)
        }
      }
      ... on TextOptionsCustomDealFormQuestion {
        options {
          default
          description {
            allTranslations {
              languageCode
              text
            }
            text(languageCode: $languageCode)
          }
          label {
            allTranslations {
              languageCode
              text
            }
            text(languageCode: $languageCode)
          }
        }
        allowEnterAnswer
      }

      ... on NumberOptionsCustomDealFormQuestion {
        options {
          default
          label {
            allTranslations {
              languageCode
              text
            }
            text(languageCode: $languageCode)
          }
        }
        allowEnterAnswer
        restrictions {
          conditionOperator
          conditionValue
          errorMessage {
            allTranslations {
              languageCode
              text
            }
            text(languageCode: $languageCode)
          }
        }
      }
    }
  `,
  dealItemCustomDealFormQuestion: gql`
    fragment DealItemCustomDealFormQuestionFragment on CustomDealFormQuestion {
      ... on BaseCustomDealFormQuestion {
        titleKey
      }
      ... on TextOptionsCustomDealFormQuestion {
        options {
          default
          description {
            allTranslations {
              languageCode
              text
            }
          }
          label {
            allTranslations {
              languageCode
              text
            }
          }
        }
      }

      ... on NumberOptionsCustomDealFormQuestion {
        options {
          default
          label {
            allTranslations {
              languageCode
              text
            }
          }
        }
      }
    }
  `,
  formAnswers: gql`
    fragment FormAnswerFragment on FormAnswer {
      ... on FormNumberAnswer {
        numberAnswer
        question {
          ...CustomDealFormQuestionFragment
        }
      }

      ... on FormTextAnswer {
        textAnswer
        question {
          ...CustomDealFormQuestionFragment
        }
      }

      ... on FormMultilineTextAnswer {
        textMultilineAnswer
        question {
          ...CustomDealFormQuestionFragment
        }
      }

      ... on FormOptionTextAnswer {
        optionTextAnswer
        question {
          ...CustomDealFormQuestionFragment
        }
      }

      ... on FormOptionNumberAnswer {
        optionNumberAnswer
        question {
          ...CustomDealFormQuestionFragment
        }
      }

      ... on FormBooleanAnswer {
        booleanAnswer
        question {
          ...CustomDealFormQuestionFragment
        }
      }

      ... on FormFileUploadAnswer {
        question {
          ...CustomDealFormQuestionFragment
        }
      }
    }
  `,
  dealItemFormAnswers: gql`
    fragment DealItemFormAnswerFragment on FormAnswer {
      ... on FormNumberAnswer {
        numberAnswer
        question {
          ...DealItemCustomDealFormQuestionFragment
        }
      }

      ... on FormTextAnswer {
        textAnswer
        question {
          ...DealItemCustomDealFormQuestionFragment
        }
      }

      ... on FormMultilineTextAnswer {
        textMultilineAnswer
        question {
          ...DealItemCustomDealFormQuestionFragment
        }
      }

      ... on FormOptionTextAnswer {
        optionTextAnswer
        question {
          ...DealItemCustomDealFormQuestionFragment
        }
      }

      ... on FormOptionNumberAnswer {
        optionNumberAnswer
        question {
          ...DealItemCustomDealFormQuestionFragment
        }
      }

      ... on FormBooleanAnswer {
        booleanAnswer
        question {
          ...DealItemCustomDealFormQuestionFragment
        }
      }

      ... on FormFileUploadAnswer {
        question {
          ...DealItemCustomDealFormQuestionFragment
        }
      }
    }
  `,
  surplusPaymentData: gql`
    fragment surplusPaymentDataFragment on SurplusPaymentData {
      cashBookCashFlowId
      cashFlowNumber
      cashBookType
      cashBookLocation
      employee {
        _id
        firstname
        lastname
      }
      outFlow
      paymentReference
      date
    }
  `,
  product: gql`
    fragment ProductFragment on Product {
      _id
      name
      manufacturer
      isCrawled
      category {
        name
        algoliaCategorySlug
      }
      categoryId
      isPublished
      imageMode
      image(size: "original") {
        id
        url
      }
      overviewImage(size: "original") {
        id
        url
      }
      variants {
        id
        image(size: "original") {
          id
          url
        }
        propertiesValues {
          ... on ProductVariantPropertyTextValue {
            value {
              translation {
                allTranslations {
                  text
                  languageCode
                }
              }
            }
          }
          ... on ProductVariantPropertyNumberValue {
            value {
              numberValue
            }
          }
          ... on ProductVariantPropertyBooleanValue {
            value {
              booleanValue
            }
          }
        }
      }
    }
  `,
  guestCustomerData: gql`
    fragment GuestCustomerDataFragment on GuestCustomerData {
      email
      iban
      paypalAddress

      blacklistInformation {
        isAddedToBlacklist
        hasCashyAccount
      }
    }
  `,
  productVariantValue: gql`
    fragment ProductVariantValueFragment on ProductVariantValue {
      ... on ProductVariantPropertyTextValue {
        propertyDefinitionId
        propertyDefinition {
          _id
          type
          impactsLook
          idealoName
          name {
            allTranslations {
              languageCode
              text
            }
          }
          key
          valueFormatting {
            suffix
          }
        }
        value {
          translation {
            allTranslations {
              languageCode
              text
            }
          }
        }
      }
      ... on ProductVariantPropertyNumberValue {
        propertyDefinitionId
        propertyDefinition {
          _id
          type
          impactsLook
          name {
            allTranslations {
              languageCode
              text
            }
          }
          key
          valueFormatting {
            suffix
          }
        }
        value {
          numberValue
        }
      }
      ... on ProductVariantPropertyBooleanValue {
        propertyDefinitionId
        propertyDefinition {
          _id
          type
          impactsLook
          name {
            allTranslations {
              languageCode
              text
            }
          }
          key
          valueFormatting {
            suffix
          }
        }
        value {
          booleanValue
        }
      }
    }
  `,
}
