import styled from '@emotion/styled'
import { Box, Container, TextField, Typography } from '@material-ui/core'
import { Field, Form, FormikProvider, useFormik } from 'formik'
import { flatten, map } from 'lodash'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import { useMutationShowingErrors, useParseErrors } from '@/hooks'
import {
  EProductPriceMode,
  Product,
  ProductPriceManualPropertyMode,
} from '@/schemaTypes'
import { printMoneyAmount } from '@/utils/misc'
import { printLocalDate } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'
import { TableFooter } from './TableFooter'
import { TableHeader } from './TableHeader'
import {
  calculateVariantPrice,
  getAvailablePropertiesHeaders,
  mergedProperties,
  parseTextToPriceAdjustment,
  useProductPricePropertyValueMapById,
  usePropertiesMapByVariantId,
  validateAdjustment,
} from './helpers'
import { useUpdateProductPrice } from './hooks/useUpdateProductPrice'

interface Props {
  product: Product
}

type ProductPriceFormData = {
  basePrice: string
  propertiesById: Record<string, Record<string, string>>
}

const getInitialData = (product: Product) => {
  const { availableProperties } = product

  return {
    basePrice: '',
    propertiesById: availableProperties.reduce((acc, p) => {
      if (p.__typename === 'ProductAvailableTextProperty') {
        acc[p.propertyDefinitionId] = p.values.reduce((childAcc, value) => {
          childAcc[value.id] = ''
          return childAcc
        }, {})
        return acc
      } else if (p.__typename === 'ProductAvailableNumberProperty') {
        acc[p.propertyDefinitionId] = p.values.reduce((childAcc, value) => {
          childAcc[value.numberValue] = ''
          return childAcc
        }, {})
        return acc
      } else if (p.__typename === 'ProductAvailableBooleanProperty') {
        acc[p.propertyDefinitionId] = { yes: '', no: '' }
        return acc
      } else {
        throw new Error('Invalid property value type')
      }
    }, {}),
  }
}

export default function PropertyBase({ product }: Props) {
  const { t, i18n } = useTranslation()
  const { showErrors } = useContext(Context)
  const parseErrors = useParseErrors()
  const { language } = i18n

  const updateProductPrice = useMutationShowingErrors({
    mutation: useUpdateProductPrice({
      refetchQueries: ['getProduct'],
    }),
    successMessage: t('product_updated_successfully'),
  })

  const price = product.price as ProductPriceManualPropertyMode | undefined

  const propertiesMapByVariantId = usePropertiesMapByVariantId(product.variants)
  const propertyValuesMapById = useProductPricePropertyValueMapById(
    price?.properties ?? [],
  )

  const formik = useFormik<ProductPriceFormData>({
    initialValues: getInitialData(product),
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const properties = flatten(
        map(values.propertiesById, (properties, propertyDefinitionId) => {
          return map(properties, (adjustmentText, propertyValue) => {
            const adjustment = adjustmentText
              ? parseTextToPriceAdjustment(adjustmentText)
              : null

            return {
              propertyDefinitionId,
              propertyValue,
              adjustment,
            }
          })
        }),
      )

      updateProductPrice({
        variables: {
          args: {
            _id: product._id,
            price: {
              basePrice:
                values.basePrice !== ''
                  ? Number(values.basePrice)
                  : price?.basePrice,
              mode: EProductPriceMode.ManualProperty,
              properties,
            },
          },
        },
        onError: (error) => {
          actions.setSubmitting(false)
          const errors = parseErrors(error)
          showErrors(errors)
        },
        onCompleted: () => {
          actions.setSubmitting(false)
          actions.resetForm()
        },
      })
    },
  })

  const { values } = formik

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <PropertyForm maxWidth="sm">
            <TableContainer>
              <HeaderContainer>
                <ItemHeaderBox />
                <ItemHeaderBox>{t('current_price')}</ItemHeaderBox>
                <ItemHeaderBox>{t('new_price')}</ItemHeaderBox>
              </HeaderContainer>
              <TableBody>
                <Block>
                  <TableRow>
                    <ItemRowBox flex={1} bold>
                      {t('base_price')}
                    </ItemRowBox>
                    <ItemRowBox flex={1}>
                      {price ? printMoneyAmount(price.basePrice) : '-'}
                    </ItemRowBox>
                    <ItemRowBox flex={1}>
                      <Field name="basePrice">
                        {({ field }) => (
                          <TextField
                            type="number"
                            size="small"
                            fullWidth
                            {...field}
                          />
                        )}
                      </Field>
                    </ItemRowBox>
                  </TableRow>
                </Block>
                {product.availableProperties.map((p) => (
                  <Block key={p.propertyDefinitionId}>
                    <TableRow>
                      <ItemRowBox flex={1} bold>
                        {p.propertyDefinition.key}
                      </ItemRowBox>
                    </TableRow>
                    {p.__typename === 'ProductAvailableTextProperty' &&
                      p.values.map((value) => (
                        <TableRow key={value.id}>
                          <ItemRowBox flex={1}>
                            {
                              value.translation.allTranslations.find(
                                (a) => a.languageCode === language,
                              )?.text
                            }
                          </ItemRowBox>
                          <ItemRowBox flex={1}>
                            {
                              propertyValuesMapById[p.propertyDefinitionId]?.[
                                value.id
                              ]
                            }
                          </ItemRowBox>
                          <ItemRowBox flex={1}>
                            <Field
                              name={`propertiesById.${p.propertyDefinitionId}.${value.id}`}
                              validate={validateAdjustment}
                            >
                              {({ field, meta }) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  {...field}
                                  error={meta.touched && !!meta.error}
                                  helperText={meta.touched && t(meta.error)}
                                />
                              )}
                            </Field>
                          </ItemRowBox>
                        </TableRow>
                      ))}

                    {p.__typename === 'ProductAvailableNumberProperty' &&
                      p.values.map((value) => (
                        <TableRow key={value.numberValue}>
                          <ItemRowBox flex={1}>{value.numberValue}</ItemRowBox>
                          <ItemRowBox flex={1}>
                            {
                              propertyValuesMapById[p.propertyDefinitionId]?.[
                                value.numberValue
                              ]
                            }
                          </ItemRowBox>
                          <ItemRowBox flex={1}>
                            <Field
                              name={`propertiesById.${p.propertyDefinitionId}.${value.numberValue}`}
                              validate={validateAdjustment}
                            >
                              {({ field, meta }) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  {...field}
                                  error={meta.touched && !!meta.error}
                                  helperText={meta.touched && t(meta.error)}
                                />
                              )}
                            </Field>
                          </ItemRowBox>
                        </TableRow>
                      ))}

                    {p.__typename === 'ProductAvailableBooleanProperty' && (
                      <>
                        <TableRow>
                          <ItemRowBox flex={1}>{t('yes')}</ItemRowBox>
                          <ItemRowBox flex={1}>
                            {
                              propertyValuesMapById[p.propertyDefinitionId]?.[
                                'yes'
                              ]
                            }
                          </ItemRowBox>
                          <ItemRowBox flex={1}>
                            <Field
                              name={`propertiesById.${p.propertyDefinitionId}.yes`}
                              validate={validateAdjustment}
                            >
                              {({ field, meta }) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  {...field}
                                  error={meta.touched && !!meta.error}
                                  helperText={meta.touched && t(meta.error)}
                                />
                              )}
                            </Field>
                          </ItemRowBox>
                        </TableRow>
                        <TableRow>
                          <ItemRowBox flex={1}>{t('no')}</ItemRowBox>
                          <ItemRowBox flex={1}>
                            {
                              propertyValuesMapById[p.propertyDefinitionId]?.[
                                'no'
                              ]
                            }
                          </ItemRowBox>
                          <ItemRowBox flex={1}>
                            <Field
                              name={`propertiesById.${p.propertyDefinitionId}.no`}
                              validate={validateAdjustment}
                            >
                              {({ field, meta }) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  {...field}
                                  error={meta.touched && !!meta.error}
                                  helperText={meta.touched && t(meta.error)}
                                />
                              )}
                            </Field>
                          </ItemRowBox>
                        </TableRow>
                      </>
                    )}
                  </Block>
                ))}
              </TableBody>
            </TableContainer>
          </PropertyForm>

          <TablePanel>
            <TablePanelTitle>
              <Typography gutterBottom>{t('result_variant_price')}</Typography>
            </TablePanelTitle>
            <TablePanelBody>
              <TableHeader
                headers={[
                  ...getAvailablePropertiesHeaders(
                    product.availableProperties,
                    language,
                  ),
                  'release_date',
                  'current_price',
                  'new_price',
                ]}
              />
              <TableBody>
                {product.variants.map((variant) => (
                  <TableRow key={variant.id}>
                    {propertiesMapByVariantId[variant.id]?.map((p, i) => (
                      <ItemRowBox key={i} flex={1}>
                        {p}
                      </ItemRowBox>
                    ))}
                    <ItemRowBox>
                      {printLocalDate(variant.releasedAt, {
                        timezone: defaultTimezone,
                      })}
                    </ItemRowBox>
                    <ItemRowBox flex={1}>
                      {price && price.basePrice !== undefined
                        ? printMoneyAmount(
                            calculateVariantPrice(
                              price.basePrice,
                              variant.propertiesValues,
                              propertyValuesMapById,
                            ),
                          )
                        : '-'}
                    </ItemRowBox>
                    <ItemRowBox flex={1}>
                      {values.basePrice !== '' || formik.dirty
                        ? printMoneyAmount(
                            calculateVariantPrice(
                              values.basePrice !== ''
                                ? Number(values.basePrice)
                                : price?.basePrice || 0,
                              variant.propertiesValues,
                              mergedProperties(
                                values.propertiesById,
                                propertyValuesMapById,
                              ),
                            ),
                          )
                        : '-'}
                    </ItemRowBox>
                  </TableRow>
                ))}
              </TableBody>
            </TablePanelBody>
          </TablePanel>

          <TableFooter
            isValid={formik.isValid && formik.dirty}
            handleReset={formik.resetForm}
          />
        </Form>
      </FormikProvider>
    </>
  )
}

const PropertyForm = styled(Container)`
  margin: 0 !important;
  padding: 0 !important;
`

const TableBody = styled.div``

const Block = styled.div`
  margin: 24px 0;
  border: solid 1px #e8e8e8;
`

const TableContainer = styled.div`
  margin-top: 16px;
`

const TablePanelBody = styled.div`
  margin: 16px;
  margin-bottom: 0;
  background-color: white;
  border-radius: 8px;
`

const HeaderContainer = styled(Box)`
  display: flex;
  align-content: center;
  padding: 8px 24px;
  border: solid 1px #e8e8e8;
`

const ItemHeaderBox = styled(Box)<{ flex?: number }>`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  color: #683ab7c0 !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
`

const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 24px;

  &:nth-of-type(odd) {
    background-color: #f8f8f8;
  }
`

const ItemRowBox = styled.div<{ flex?: number; bold?: boolean }>`
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`

const TablePanel = styled.div`
  background-color: #ede7f8;
  border-radius: 8px;
  padding: 16px 0;
`

const TablePanelTitle = styled.div`
  > p {
    font-weight: bolder;
    margin-bottom: 0;
  }
  color: #683ab7c0;
  padding: 0 1rem;
`
