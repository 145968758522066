import { IsDate, IsString } from 'class-validator'

export class LoanToValuesEntryValidation {
  @IsDate()
  validFrom: Date

  @IsString()
  companyId: string

  constructor(loanToValuesEntry: { validFrom: Date; companyId: string }) {
    this.validFrom = loanToValuesEntry.validFrom
    this.companyId = loanToValuesEntry.companyId
  }
}
