import styled from '@emotion/styled'
import { Box, Checkbox, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Loading from '@/components/Loading'
import { getVariantValue } from '@/components/ProductPrices/helpers'
import { ImageGallery } from '@/domains/customDeals/components/CustomDealDetail'
import {
  ImageWrapper,
  LookVariantContainer,
  LookVariantWrapper,
  ProductImageModeSelection,
} from '@/domains/products/components/ProductImages'
import { useGetProduct } from '@/domains/products/hooks/getProduct'
import { useGetLookVariants } from '@/domains/products/hooks/useGetLookVariants'
import { useGetProductFormData } from '@/domains/products/hooks/useGetProductFormData'
import DatePicker from '@/redesign/components/DatePicker/DatePicker'
import Input from '@/redesign/components/Input/Input'
import {
  AvailablePropertyValues,
  EProductImageMode,
  ProductVariantValue,
} from '@/schemaTypes'
import { printLocalDate } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'
import { getTranslatedText } from './helpers'

export const ProductOverview = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { productId: id } = useParams<{ productId: string }>()

  const { product } = useGetProduct({
    variables: {
      productId: id,
      variantsArgs: {
        includeDisabled: true,
      },
    },
    skip: !id,
  })

  const [activeImageGalleryType, setActiveImageGalleryType] =
    useState<GalleryType | null>(null)
  const [selectedImageToView, setSelectedImageToView] = useState(0)

  const onCloseImageGallery = () => {
    setActiveImageGalleryType(null)
    setSelectedImageToView(0)
  }

  const { variants } = useGetProductFormData(product)

  const { lookVariants, error: lookVariantsError } =
    useGetLookVariants(variants)

  const onOpenImageGallery = (type: GalleryType, index: number) => {
    setSelectedImageToView(index)
    setActiveImageGalleryType(type)
  }

  if (!product) {
    return <Loading />
  }

  return (
    <div>
      <div className="p-1 flex flex-col mt-4">
        <Input
          className="flex flex-col md:flex-row items-start md:items-center mb-2"
          style={{ width: '25rem' }}
          labelClassName="text-sm font-semibold w-12.5"
          label={t('category')}
          value={product.category.algoliaCategorySlug}
          disabled
        />
        <Input
          className="flex flex-col md:flex-row items-start md:items-center mb-2"
          labelClassName="text-sm font-semibold w-12.5"
          label={t('manufacturer')}
          value={product.manufacturer}
          disabled
        />
        <Input
          className="flex flex-col md:flex-row items-start md:items-center mb-2"
          labelClassName="text-sm font-semibold w-12.5"
          label={t('product')}
          value={product.name}
          disabled
        />

        {!product.isCrawled && (
          <DatePicker
            label={t('release_date')}
            dateFormat="dd.mm.yy"
            className="flex flex-col md:flex-row items-start md:items-center"
            labelClassName="text-sm font-semibold w-12.5"
            value={product.variants[0].releasedAt}
            disabled
          />
        )}
      </div>

      {product.availableProperties && (
        <>
          <TablePanel>
            <TablePanelTitle>
              <Typography>{t('properties')}</Typography>
            </TablePanelTitle>
            <TablePanelBody>
              <div className="p-1">
                {product.availableProperties?.length
                  ? product.availableProperties.map((property, i) => {
                      return (
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginTop: 10,
                          }}
                          key={i}
                        >
                          <ProductPreviewAvailableProperties
                            productAvailableProperty={property}
                          />
                        </Box>
                      )
                    })
                  : t('product_page.no_properties')}
              </div>
            </TablePanelBody>
          </TablePanel>
          <TablePanel>
            <TablePanelTitle>
              <Typography>{t('variants')}</Typography>
            </TablePanelTitle>
            <TablePanelBody className="p-6">
              {product.availableProperties?.length ? (
                <div
                  className="grid gap-y-2"
                  style={{
                    // Available Properties + releasedAt + Active Status
                    gridTemplateColumns: `repeat(${(product.availableProperties?.length || 1) + 2}, minmax(0, 200px))`,
                  }}
                >
                  {product.availableProperties?.map((ap) => (
                    <b key={ap.propertyDefinitionId}>
                      {getTranslatedText(
                        ap.propertyDefinition.name.allTranslations,
                        language,
                      )}
                    </b>
                  ))}
                  <b>{t('release_date')}</b>

                  <b>{t('active')}</b>

                  {product.variants.map((variant) => (
                    <Variant
                      key={variant.id}
                      propertiesValues={variant.propertiesValues}
                      enabled={variant.enabled}
                      releasedAt={variant.releasedAt}
                    />
                  ))}
                </div>
              ) : (
                t('product_page.single_variant')
              )}
            </TablePanelBody>
          </TablePanel>
        </>
      )}

      {(lookVariants.length > 0 ||
        product.imageMode === EProductImageMode.OneImageForAllProducts ||
        product.overviewImage) && (
        <TablePanel>
          <TablePanelTitle>
            <Typography>{t('pictures')}</Typography>
          </TablePanelTitle>
          <TablePanelBody>
            <div className="p-1">
              <ProductImageModeSelection
                imageMode={product.imageMode}
                viewOnly
                styles={{ marginBottom: 10 }}
              />
              {product.imageMode === EProductImageMode.ImagePerVariant ? (
                <>
                  {lookVariants.map((lookVariant, index) => (
                    <LookVariantContainer>
                      <LookVariantWrapper>
                        <ImageWrapper
                          key={index}
                          url={lookVariant.imageUrl ?? ''}
                          isUploaded={true}
                          onClick={() =>
                            onOpenImageGallery('variant-image', index)
                          }
                        />
                        <div className="text-center font-semibold">
                          {lookVariant.name}
                        </div>
                      </LookVariantWrapper>
                    </LookVariantContainer>
                  ))}
                  {lookVariantsError && (
                    <Typography style={{ marginTop: 10 }} color="error">
                      {t(lookVariantsError)}
                    </Typography>
                  )}
                  <LookVariantContainer>
                    <LookVariantWrapper>
                      <ImageWrapper
                        url={product.overviewImage?.url ?? ''}
                        isUploaded={true}
                        onClick={() =>
                          onOpenImageGallery('product-overview-image', 0)
                        }
                      />
                      <div className="text-center font-semibold">
                        {t('overview_image')}
                      </div>
                    </LookVariantWrapper>
                  </LookVariantContainer>
                </>
              ) : (
                <LookVariantContainer>
                  <LookVariantWrapper>
                    <ImageWrapper
                      url={product.image?.url ?? ''}
                      isUploaded={true}
                      onClick={() => onOpenImageGallery('product-image', 0)}
                    />
                  </LookVariantWrapper>
                </LookVariantContainer>
              )}
            </div>
          </TablePanelBody>
        </TablePanel>
      )}

      {activeImageGalleryType && (
        <ImageGallery
          media={
            activeImageGalleryType === 'variant-image'
              ? lookVariants.map((v) => ({ url: v.imageUrl ?? '' }))
              : activeImageGalleryType === 'product-overview-image'
                ? [
                    {
                      url: product.overviewImage?.url ?? '',
                    },
                  ]
                : [
                    {
                      url: product.image?.url ?? '',
                    },
                  ]
          }
          viewedPositionIndex={selectedImageToView}
          onClose={onCloseImageGallery}
        />
      )}
    </div>
  )
}

const PropertyKeyWrapper = styled.div`
  min-width: 200px;
  font-weight: bolder;
`

const ProductPreviewAvailableProperties = ({
  productAvailableProperty,
}: {
  productAvailableProperty: AvailablePropertyValues
}) => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  if (productAvailableProperty.__typename === 'ProductAvailableTextProperty') {
    return (
      <>
        <PropertyKeyWrapper>
          {getTranslatedText(
            productAvailableProperty.propertyDefinition.name.allTranslations,
            language,
          )}
        </PropertyKeyWrapper>
        <Box style={{ width: 200 }}>
          {productAvailableProperty.values.map((value) => (
            <Typography key={value.id}>
              {getTranslatedText(value.translation.allTranslations, language)}
            </Typography>
          ))}
        </Box>
      </>
    )
  }

  if (
    productAvailableProperty.__typename === 'ProductAvailableNumberProperty'
  ) {
    return (
      <>
        <PropertyKeyWrapper>
          {getTranslatedText(
            productAvailableProperty.propertyDefinition.name.allTranslations,
            language,
          )}
        </PropertyKeyWrapper>
        <div>
          {productAvailableProperty.values.map((value) => (
            <Typography key={value.numberValue}>{value.numberValue}</Typography>
          ))}
        </div>
      </>
    )
  }

  if (
    productAvailableProperty.__typename === 'ProductAvailableBooleanProperty'
  ) {
    return (
      <>
        <PropertyKeyWrapper>
          {getTranslatedText(
            productAvailableProperty.propertyDefinition.name.allTranslations,
            language,
          )}
        </PropertyKeyWrapper>
        <Typography>
          {t('yes')}/{t('no')}
        </Typography>
      </>
    )
  }

  return null
}

type VariantProps = {
  propertiesValues: ProductVariantValue[]
  enabled: boolean
  releasedAt: Date
}

function Variant({ propertiesValues, releasedAt, enabled }: VariantProps) {
  if (!propertiesValues.length) {
    throw new Error('Variant missing property values.')
  }
  const {
    t,
    i18n: { language },
  } = useTranslation()
  return (
    <>
      {propertiesValues.map((pv) => (
        <div className="py-3" key={pv.propertyDefinitionId}>
          {getVariantValue(pv, [t, language])}
        </div>
      ))}
      <label className="py-3">
        {printLocalDate(releasedAt, {
          timezone: defaultTimezone,
        })}
      </label>
      <Checkbox className="w-fit" checked={enabled} disabled />
    </>
  )
}

type GalleryType = 'product-image' | 'variant-image' | 'product-overview-image'

const TablePanel = styled.div`
  background-color: #ede7f8;
  border-radius: 8px;
  padding: 16px 0;
  margin: 16px 0;
`

const TablePanelTitle = styled.div`
  > p {
    font-weight: bolder;
    margin-bottom: 0;
  }
  color: #683ab7c0;
  padding: 0 1rem;
`

const TablePanelBody = styled.div`
  margin: 16px;
  margin-bottom: 0;
  background-color: white;
  border-radius: 8px;
`
