import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import {
  ECountry,
  Query,
  QueryGetStorageFacilityArgs,
  StorageFacility,
} from '@/schemaTypes'

const GET_STORAGE_FACILITY = gql`
  query getStorageFacility($storageFacilityId: ObjectId!) {
    getStorageFacility(storageFacilityId: $storageFacilityId) {
      _id
      name
      storageUnitIds
    }
  }
`

type TData = { getStorageFacility: Query['getStorageFacility'] }
type TVariables = QueryGetStorageFacilityArgs

const defaultStorageFacility: StorageFacility = {
  _id: '',
  name: '',
  external: false,
  address: {
    coordinates: {
      lat: 0,
      lng: 0,
    },
    houseNumber: '',
    stairway: '',
    door: '',
    floor: '',
    street: '',
    city: '',
    state: '',
    countryCode: ECountry.At,
    country: '',
    zipCode: '',
    queryString: '',
    address1: '',
    address2: '',
    note: '',
  },
  shopIds: [],
  shops: [],
  storageUnitIds: [],
  storageUnits: [],
}

export function useGetStorageFacility(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_STORAGE_FACILITY, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.storageFacilityId,
  })

  useShowErrorsPopup(queryResult.error)

  const storageFacility = opts.variables?.storageFacilityId
    ? queryResult.data?.getStorageFacility
    : defaultStorageFacility
  return { queryResult, storageFacility }
}
