import { Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import { last } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useGetActiveEmployees } from '@/domains/items/hooks'
import { CustomDealEvent, ECustomDealStatusType } from '@/schemaTypes'
import styles from './StatusHistory.module.scss'

export interface Props {
  events: CustomDealEvent[]
}

export function StatusHistory(props: Props) {
  const { t } = useTranslation()
  const { events } = props
  const { employees } = useGetActiveEmployees()

  const getEmployeeName = (employeeId: string) => {
    const employee = employees.find((employee) => employee._id === employeeId)

    if (employee) {
      return `${employee.firstname} ${employee.lastname}`
    }

    return 'Customer'
  }

  const lastEvent = last(events)?.event

  const closeEvent = lastEvent
    ? [
        ECustomDealStatusType.Closed,
        ECustomDealStatusType.Declined,
        ECustomDealStatusType.Canceled,
        ECustomDealStatusType.Expired,
      ].includes(lastEvent)
    : []

  return (
    <div className={styles.events}>
      {events.map((event) => (
        <div
          className={styles.event}
          style={getStyle(event.event)}
          key={event.createdAt}
        >
          <Typography className={styles.name} variant="subtitle2">
            {event.event === ECustomDealStatusType.Closed
              ? t('deal_booked')
              : event.event}
          </Typography>
          <Typography className={styles.createdDate} variant="caption">
            {dayjs(event.createdAt).format('YYYY-MM-DD HH:mm')}
          </Typography>
          <Typography className={styles.creatorName} variant="caption">
            {t('by')}: {getEmployeeName(event.creatorId)}
          </Typography>
        </div>
      ))}
      {!closeEvent && (
        <div className={styles.event}>
          <Typography className={styles.name} variant="subtitle2">
            {t(
              [
                ECustomDealStatusType.Accepted,
                ECustomDealStatusType.Counteroffer,
              ].includes(lastEvent as ECustomDealStatusType)
                ? 'waiting_for_customer'
                : 'waiting_for_cashy',
            )}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default StatusHistory

const getStyle = (event: ECustomDealStatusType) => {
  let background = '#dfc8e5'
  let color

  switch (event) {
    case ECustomDealStatusType.Closed:
      background = '#65ad48'
      color = 'white'
      break
    case ECustomDealStatusType.Declined:
    case ECustomDealStatusType.Canceled:
      background = '#e57d3c'
      color = 'white'
      break
    default:
      break
  }

  return { background, color }
}
