import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateCustomDealItemArgs } from '@/schemaTypes'

const UPDATE_ITEM = gql`
  mutation UpdateCustomDealItem(
    $customDealId: ObjectId!
    $customDealItemUpdateArgs: CustomDealItemUpdateArgs!
  ) {
    updateCustomDealItem(
      customDealId: $customDealId
      customDealItemUpdateArgs: $customDealItemUpdateArgs
    ) {
      _id
    }
  }
`

type TData = { updateCustomDealItem: Mutation['updateCustomDealItem'] }
type TVariables = MutationUpdateCustomDealItemArgs

export function useUpdateItem(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateCustomDealItem] = useMutation<TData, TVariables>(UPDATE_ITEM, {
    refetchQueries: ['getCustomDealById'],
    ...opts,
  })
  return updateCustomDealItem
}
