import styled from '@emotion/styled'
import { ArrowBackIos } from '@material-ui/icons'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'
import { ToggleButton } from 'primereact/togglebutton'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetProduct } from '@/domains/products/hooks/getProduct'
import {
  GetProductVariantsArgs,
  ProductVariant,
  QueryGetProductArgs,
} from '@/schemaTypes'
import {
  SelectedProductProps,
  findDefaultProperties,
  findVariantBySelectedProperties,
  formatAvailableProperties,
} from './helper'

interface ProductVariantSelectorProps {
  productId: string
  onClickBack: () => void
  onSelectProductVariant: (
    productVariant: ProductVariant,
    categoryId: string,
  ) => void
  allowProductsWithoutValidPrice: boolean
}

function ProductVariantSelector({
  productId,
  onClickBack,
  onSelectProductVariant,
  allowProductsWithoutValidPrice,
}: ProductVariantSelectorProps) {
  const [selectedProperties, setSelectedProperties] = useState<
    SelectedProductProps[]
  >([])

  const [isValidVariant, setIsValidVariant] = useState(true)
  const [isPriceValid, setIsPriceValid] = useState(false)

  const { t, i18n } = useTranslation()
  const language = i18n.languages[0]

  const variables = useMemo(
    (): QueryGetProductArgs & {
      variantsArgs?: GetProductVariantsArgs
    } => ({
      productId,
    }),
    [productId],
  )

  const { product } = useGetProduct({
    variables,
  })

  useEffect(() => {
    if (product?.variants) {
      const defaultProperties =
        findDefaultProperties(product.variants, language) ?? []
      setSelectedProperties(defaultProperties)

      const variant = findVariantBySelectedProperties(
        defaultProperties,
        product.variants,
      )
      setIsValidVariant(!!variant)
      setIsPriceValid(variant?.isPriceValid)
    }
  }, [product?.variants, product?.price, language])

  const handleToggleChange = (
    propertyId: string,
    value: number | string | boolean,
  ) => {
    const newSelectedProperties = [...selectedProperties]
    const existingPropertyIndex = newSelectedProperties.findIndex(
      (sp) => sp.propertyDefinitionId === propertyId,
    )

    if (existingPropertyIndex !== -1) {
      newSelectedProperties[existingPropertyIndex].value = value
    } else {
      newSelectedProperties.push({ propertyDefinitionId: propertyId, value })
    }

    setSelectedProperties(newSelectedProperties)

    const variant = findVariantBySelectedProperties(
      newSelectedProperties,
      product?.variants ?? [],
    )
    setIsValidVariant(!!variant)
    setIsPriceValid(variant?.isPriceValid)
  }

  return (
    <>
      <div className="flex items-center mb-4">
        <ArrowBackIos
          className="mr-2 mt-0.5 cursor-pointer"
          onClick={onClickBack}
        />
        <h3 className="center text-2xl font-medium">{product?.name}</h3>
      </div>
      {/* Not Valid Variant Message */}
      {!isValidVariant && (
        <div>
          <Message
            className="!mb-2"
            text={t('no_valid_product_variant_matching_this_selection')}
            severity="error"
          />
        </div>
      )}
      {/* Missing price info Message */}
      {!isPriceValid && (
        <div>
          <Message
            className="!mb-2"
            text={t('item_value_must_be_provided_manually')}
            severity="warn"
          />
        </div>
      )}
      <div className="flex-grow flex flex-col">
        {product?.availableProperties &&
          formatAvailableProperties(product?.availableProperties, language).map(
            (p) => {
              return (
                <div className="mb-4">
                  <PropertyTitle>{p.title}</PropertyTitle>
                  <div>
                    {p.values.map((v) => {
                      return (
                        <ToggleButton
                          key={p.propertyDefinitionId}
                          onLabel={v.text}
                          offLabel={v.text}
                          checked={selectedProperties.some(
                            (sp) =>
                              sp.propertyDefinitionId ===
                                p.propertyDefinitionId && sp.value === v.value,
                          )}
                          onChange={() =>
                            handleToggleChange(p?.propertyDefinitionId, v.value)
                          }
                          className="!mr-2 !mt-2"
                        />
                      )
                    })}
                  </div>
                </div>
              )
            },
          )}
      </div>
      <div className="flex justify-end">
        <Button
          label={t('continue')}
          onClick={() => {
            const variant = findVariantBySelectedProperties(
              selectedProperties,
              product?.variants ?? [],
            )
            onSelectProductVariant(variant, product.categoryId)
          }}
          disabled={
            !isValidVariant ||
            (!isPriceValid && !allowProductsWithoutValidPrice)
          }
        />
      </div>
    </>
  )
}

const PropertyTitle = styled.h6`
  color: #495057;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
`

export default ProductVariantSelector
