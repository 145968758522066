import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateProductPriceArgs } from '@/schemaTypes'

const UPDATE_PRODUCT_PRICE = gql`
  mutation updateProductPrice($args: ProductUpdatePriceArgs!) {
    updateProductPrice(args: $args) {
      _id
    }
  }
`

type TData = { updateProductPrice: Mutation['updateProductPrice'] }
type TVariables = MutationUpdateProductPriceArgs

export function useUpdateProductPrice(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [mutation] = useMutation<TData, TVariables>(UPDATE_PRODUCT_PRICE, opts)
  return mutation
}
