import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateDealTransportArgs } from '@/schemaTypes'

const UPDATE_DEAL_TRANSPORT = gql`
  mutation updateDealTransport($args: DealTransportUpdateArgs!) {
    updateDealTransport(args: $args) {
      _id
    }
  }
`

type TData = { updateDealTransport: Mutation['updateDealTransport'] }
type TVariables = MutationUpdateDealTransportArgs

export function useUpdateDealTransport(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateDealTransport] = useMutation<TData, TVariables>(
    UPDATE_DEAL_TRANSPORT,
    opts,
  )

  return updateDealTransport
}
