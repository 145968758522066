import { useGetDealById } from '../../../deals/hooks'
import { useRegenerateBillForDeal } from '../../../deals/hooks/regenerateBillForDeal'
import { useReevaluateFinalPrivateSaleFinalState } from '../../hooks/reevaluateFinalPrivateSaleFinalState'
import { Paper, TableHead, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import SyncIcon from '@material-ui/icons/Sync'
import * as React from 'react'
import { FunctionComponent, useContext } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import LoadingSpinner from '@/components/LoadingSpinner'
import { Context } from '@/context'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import { Scalars } from '@/schemaTypes'
import { printLocalDate } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'
import styles from './PrivateSaleDetails.module.scss'

export interface PrivateSaleDetailsDealOverViewProps {
  privateSaleId: Scalars['ObjectId']
  dealId: Scalars['ObjectId']
}

const PrivateSaleDetailsDealOverView: FunctionComponent<
  PrivateSaleDetailsDealOverViewProps
> = (props) => {
  const { t } = useTranslation()
  const { closeConfirmModal } = useContext(Context)

  const [dealId, setDealId] = useState(props.dealId)

  const { deal } = useGetDealById({
    variables: {
      dealId: dealId,
    },
    skip: !dealId,
  })

  const [
    loadingSpinnerForRegeneratingBill,
    setLoadingSpinnerForRegeneratingBill,
  ] = useState(false)
  const [
    loadingSpinnerForReevaluatingFinalState,
    setLoadingSpinnerForReevaluatingFinalState,
  ] = useState(false)

  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })

  const regenerateBill = useMutationShowingErrors({
    mutation: useRegenerateBillForDeal(),
    successMessage: `${t('bill_regenerated_successfully')}!`,
  })

  const handleRegenerateBillClick = () => {
    showConfirmPopup({
      title: t('regenerate_bill_confirm'),
      action: async () => {
        setLoadingSpinnerForRegeneratingBill(true)
        closeConfirmModal()
        await regenerateBill({
          variables: {
            dealId: deal?._id,
          },
        })
        setLoadingSpinnerForRegeneratingBill(false)
      },
    })
  }

  const reevaluateFinalState = useMutationShowingErrors({
    mutation: useReevaluateFinalPrivateSaleFinalState(),
  })

  const handleReevaluateFinalState = () => {
    showConfirmPopup({
      title: t('reevaluate_final_state_confirm'),
      action: async () => {
        setLoadingSpinnerForReevaluatingFinalState(true)
        setDealId('')
        closeConfirmModal()
        await reevaluateFinalState({
          variables: {
            privateSaleId: props.privateSaleId,
          },
        })
        setLoadingSpinnerForReevaluatingFinalState(false)
        setDealId(deal?._id)
      },
    })
  }

  if (!deal) return null

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Typography variant="h6" gutterBottom className={'u-flex u-jc-sb'}>
        {t('deal_overview')}
      </Typography>
      <div className={`${styles.inlineDivs}`}>
        <br />

        <Table className={`${styles.dealValuesTable}`} size="small">
          <TableBody>
            <TableRow className={`${styles.dealValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {t('id')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                <Link to={`/inApp/deals/${deal._id}`} target="blank">
                  {deal._id}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow className={`${styles.dealValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {t('deal.booking_number')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {deal.bookingNumber}
              </TableCell>
            </TableRow>
            <TableRow className={`${styles.dealValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {t('deal.deal_types')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {deal.dealType}
              </TableCell>
            </TableRow>
            <TableRow className={`${styles.dealValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {t('company.singular')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {deal?.company?.name}
              </TableCell>
            </TableRow>
            <TableRow className={`${styles.dealValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {t('enter_shop_name')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {deal.shop?.name}
              </TableCell>
            </TableRow>
            <TableRow className={`${styles.dealValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {t('pawn_ticket')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {deal.pawnData && deal.pawnData.pawnTicketLink ? (
                  <a
                    target="blank"
                    href={`${deal.pawnData.pawnTicketLink.url}`}
                  >
                    {deal.pawnData.pawnNumber}
                  </a>
                ) : (
                  ''
                )}
              </TableCell>
            </TableRow>
            <TableRow className={`${styles.dealValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {t('bill')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {deal.pawnData && deal.pawnData.billLink ? (
                  <div>
                    <a target="blank" href={`${deal.pawnData.billLink.url}`}>
                      {deal.pawnData.billNumber}
                    </a>
                    {loadingSpinnerForRegeneratingBill ? (
                      <span className={`${styles.regenerateSpinner}`}>
                        <LoadingSpinner />
                      </span>
                    ) : (
                      <SyncIcon
                        className={`${styles.regenerateButton}`}
                        onClick={handleRegenerateBillClick}
                      />
                    )}
                  </div>
                ) : (
                  ''
                )}
              </TableCell>
            </TableRow>
            <TableRow className={`${styles.dealValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                {t('final_private_sale_state')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.dealValuesTable}`}
              >
                <div>
                  {deal.events[deal.events.length - 1].displayName}
                  {loadingSpinnerForReevaluatingFinalState ? (
                    <span className={`${styles.regenerateSpinner}`}>
                      <LoadingSpinner />
                    </span>
                  ) : (
                    <SyncIcon
                      className={`${styles.regenerateButton}`}
                      onClick={handleReevaluateFinalState}
                    />
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <div className={`${styles.inlineDivs}`}>
        <br />

        <Table className={`${styles.dealValuesTable}`} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('process_step')}</TableCell>
              <TableCell>{t('date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deal.events.map((row) => (
              <TableRow key={row.__typename}>
                <TableCell component="th" scope="row">
                  {row.displayName}
                </TableCell>
                <TableCell>
                  <span>
                    {printLocalDate(row.createdAt, {
                      timezone: defaultTimezone,
                    })}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default PrivateSaleDetailsDealOverView
