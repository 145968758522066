import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useTranslation } from 'react-i18next'

interface ProductsTableProps {
  products: {
    id: string
    name: string
    categoryName: string
  }[]
  onSelectProduct: (id: string) => void
  loading?: boolean
}

function ProductsTable({
  products,
  onSelectProduct,
  loading,
}: ProductsTableProps) {
  const { t } = useTranslation()

  return (
    <DataTable
      value={products}
      selectionMode="single"
      onSelectionChange={(e) => onSelectProduct(e.value.id)}
      dataKey="id"
      emptyMessage={t('no_matching_results_found')}
      loading={loading}
    >
      <Column field="name" header={t('name')} />
      <Column field="categoryName" header={t('category')} className="w-64" />
    </DataTable>
  )
}

export default ProductsTable
