import EurCent1 from '@/images/eur_0_1.png'
import EurCent2 from '@/images/eur_0_2.png'
import EurCent5 from '@/images/eur_0_5.png'
import EurCent10 from '@/images/eur_0_10.png'
import EurCent20 from '@/images/eur_0_20.png'
import EurCent50 from '@/images/eur_0_50.png'
import Eur1 from '@/images/eur_1.png'
import Eur2 from '@/images/eur_2.png'
import Eur5 from '@/images/eur_5.png'
import Eur10 from '@/images/eur_10.png'
import Eur20 from '@/images/eur_20.png'
import Eur50 from '@/images/eur_50.png'
import Eur100 from '@/images/eur_100.png'
import Eur200 from '@/images/eur_200.png'
import Eur500 from '@/images/eur_500.png'
import { CashBookCountCashBoxEntry, EEuroDenomination } from '@/schemaTypes'

export const euroDenominations = {
  [EEuroDenomination.Euro_500]: 500,
  [EEuroDenomination.Euro_200]: 200,
  [EEuroDenomination.Euro_100]: 100,
  [EEuroDenomination.Euro_50]: 50,
  [EEuroDenomination.Euro_20]: 20,
  [EEuroDenomination.Euro_10]: 10,
  [EEuroDenomination.Euro_5]: 5,
  [EEuroDenomination.Euro_2]: 2,
  [EEuroDenomination.Euro_1]: 1,
  [EEuroDenomination.EuroCent_50]: 0.5,
  [EEuroDenomination.EuroCent_20]: 0.2,
  [EEuroDenomination.EuroCent_10]: 0.1,
  [EEuroDenomination.EuroCent_5]: 0.05,
  [EEuroDenomination.EuroCent_2]: 0.02,
  [EEuroDenomination.EuroCent_1]: 0.01,
}

export const euroMoneyImages = {
  [EEuroDenomination.Euro_500]: Eur500,
  [EEuroDenomination.Euro_200]: Eur200,
  [EEuroDenomination.Euro_100]: Eur100,
  [EEuroDenomination.Euro_50]: Eur50,
  [EEuroDenomination.Euro_20]: Eur20,
  [EEuroDenomination.Euro_10]: Eur10,
  [EEuroDenomination.Euro_5]: Eur5,
  [EEuroDenomination.Euro_2]: Eur2,
  [EEuroDenomination.Euro_1]: Eur1,
  [EEuroDenomination.EuroCent_50]: EurCent50,
  [EEuroDenomination.EuroCent_20]: EurCent20,
  [EEuroDenomination.EuroCent_10]: EurCent10,
  [EEuroDenomination.EuroCent_5]: EurCent5,
  [EEuroDenomination.EuroCent_2]: EurCent2,
  [EEuroDenomination.EuroCent_1]: EurCent1,
}

export function buildInitialCashBoxEntries(cashBoxNames: string[]) {
  const cashBoxEntries: CashBookCountCashBoxEntry[] = cashBoxNames.map(
    (name) => ({
      cashBoxName: name,
      counts: {
        EURO_500: 0,
        EURO_200: 0,
        EURO_100: 0,
        EURO_50: 0,
        EURO_20: 0,
        EURO_10: 0,
        EURO_5: 0,
        EURO_2: 0,
        EURO_1: 0,
        EURO_CENT_50: 0,
        EURO_CENT_20: 0,
        EURO_CENT_10: 0,
        EURO_CENT_5: 0,
        EURO_CENT_2: 0,
        EURO_CENT_1: 0,
      },
    }),
  )

  return cashBoxEntries
}
