import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeleteCustomDealArgs } from '@/schemaTypes'

const DELETE_CUSTOM_DEAL = gql`
  mutation DeleteCustomDeal($customDealId: ObjectId!) {
    deleteCustomDeal(customDealId: $customDealId) {
      _id
    }
  }
`

type TData = { deleteCustomDeal: Mutation['deleteCustomDeal'] }
type TVariables = MutationDeleteCustomDealArgs

export function useDeleteCustomDeal(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deleteCustomDeal] = useMutation<TData, TVariables>(
    DELETE_CUSTOM_DEAL,
    {
      refetchQueries: ['searchCustomDeals'],
      ...opts,
    },
  )
  return deleteCustomDeal
}
