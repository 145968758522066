import { MenuItem, Select } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import { padZeros } from '@/utils/misc'
import styles from './TimeHourPicker.module.scss'

dayjs.extend(utc)

export interface TimeHourPickerProps {
  label: string
  value: Date
  onChange(hour: number): void
}

const hours: number[] = []

for (let i = 0; i < 24; ++i) {
  hours.push(i)
}

export const TimeHourPicker = (props: TimeHourPickerProps) => {
  const date = dayjs(props.value)

  return (
    <div className={styles.container}>
      {props.label}
      <Select
        className="u-ml-sm"
        value={date.get('hour')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.onChange(parseInt(e.target.value))
        }}
      >
        {hours.map((value) => (
          <MenuItem key={value} value={value}>
            {padZeros(value, 2)}
          </MenuItem>
        ))}
      </Select>
      {`:${padZeros(date.get('minute'), 2)}:${padZeros(date.get('second'), 2)}.${padZeros(date.get('millisecond'), 3)}`}
    </div>
  )
}

export default TimeHourPicker
