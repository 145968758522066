import { orderBy } from 'lodash'
import { useContext, useMemo } from 'react'
import { Context } from '@/context'
import { ERoles, Note, NoteUpdateArgs } from '@/schemaTypes'
import NoteElement from './NoteElement'

interface NoteListProps {
  notes: Note[]
  onUpdateNote: (
    noteId: string | number,
    note: NoteUpdateArgs,
    chainDealId?: string,
  ) => void
}

const NoteList = ({ notes, onUpdateNote }: NoteListProps) => {
  const { getCurrentUser } = useContext(Context)
  const currentUser = getCurrentUser()
  const sortedNotes = useMemo(
    () =>
      orderBy(notes, ['updatedAt'], ['asc']) as (Omit<Note, '_id'> & {
        _id?: string
      })[],
    [notes],
  )

  return (
    <div className="mb-2">
      {sortedNotes.map((note) => {
        const isOwnerNote = currentUser?._id === note.createdBy

        return (
          <NoteElement
            key={note._id}
            note={note}
            onUpdateNote={onUpdateNote}
            isOwnerNote={isOwnerNote}
            isToggleNotePopupAllowed={
              isOwnerNote || currentUser?.roles.includes(ERoles.Superadmin)
            }
          />
        )
      })}
    </div>
  )
}

export default NoteList
