import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { Customer, ERoles, Query, QueryGetCustomerArgs } from '@/schemaTypes'

export const GET_CUSTOMER = gql`
  query getCustomer($customerId: ObjectId!) {
    getCustomer(customerId: $customerId) {
      _id
      email
      firstname
      lastname
      dateOfBirth
      phone
      sex
      note
      isNotePopup
      iban
      paypalAddress
      createdAt
      updatedAt
      customerNumber
      guestEmail
      identityType
      hasAlreadyDeals
      identityUploadLinks {
        ...FileMetadataFragment
      }
      identityVerifiedAt
      identityVerifiedBy
      lastReminderIdentitySentAt
      uploadedFiles {
        ...FileMetadataFragment
      }
      registrationCertificate {
        ...FileMetadataFragment
      }
      isTestUser
      deactivated
      deactivatedAt
      acquiredBy
      userPermissionId
      blacklistInformation {
        isAddedToBlacklist
        hasCashyAccount
      }
      roles
      addresses {
        ...CustomerAddressFragment
      }
      identityType
      identityNumber
      additionalInformationForCarPawn {
        ...AdditionalInformationForCarPawnFragment
      }
      dealHistory {
        normalPawn {
          bookedDealsCount
          verifiedDealsCount
          extendedDealsCount
          paidBackDealsCount
          auctionedDealsCount
        }
        carPawn {
          bookedDealsCount
          verifiedDealsCount
          extendedDealsCount
          paidBackDealsCount
          auctionedDealsCount
        }
      }
      identityVerifiedByEmployee {
        firstname
        lastname
      }
    }
  }
  ${fragments.customerAddress}
  ${fragments.fileMetadata}
  ${fragments.additionalInformationForCarPawn}
`

type TData = { getCustomer: Query['getCustomer'] }
type TVariables = QueryGetCustomerArgs

const defaultCustomer: Customer = {
  _id: '',
  email: '',
  firstname: '',
  lastname: '',
  dateOfBirth: null,
  phone: '',
  createdAt: null,
  updatedAt: null,
  customerNumber: 0,
  userPermissionId: '',
  roles: [ERoles.Customer],
  addresses: [],
  deals: [],
  customRequests: [],
  userPermission: {
    _id: '',
    userPermissions: [],
  },
  activeDeals: [],
  closedDeals: [],
  isTestUser: false,
  customDeals: [],
  hasAlreadyDeals: false,
  dealHistory: {
    carPawn: {
      bookedDealsCount: 0,
      verifiedDealsCount: 0,
      extendedDealsCount: 0,
      paidBackDealsCount: 0,
      auctionedDealsCount: 0,
    },
    normalPawn: {
      bookedDealsCount: 0,
      verifiedDealsCount: 0,
      extendedDealsCount: 0,
      paidBackDealsCount: 0,
      auctionedDealsCount: 0,
    },
  },
}

export function useGetCustomer(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useQuery<TData, TVariables>(GET_CUSTOMER, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.customerId,
  })

  useShowErrorsPopup(queryResult.error)

  const customer = opts.variables?.customerId
    ? queryResult.data?.getCustomer
    : defaultCustomer
  return { queryResult, customer }
}
