import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryCallViviValuationArgs } from '@/schemaTypes'

const query = gql`
  query callViviValuation($args: ViviValuationArgs!) {
    callViviValuation(args: $args) {
      amount
    }
  }
`

type TData = { callViviValuation: Query['callViviValuation'] }
type TVariables = QueryCallViviValuationArgs

export function useCallViviValuationQuery(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(query, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return queryResult
}
