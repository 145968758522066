import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetMaterialPriceForDateArgs } from '@/schemaTypes'

const GET_MATERIAL_PRICE_FOR_DATE = gql`
  query getMaterialPriceForDate($material: String!, $date: DateTime!) {
    getMaterialPriceForDate(material: $material, date: $date) {
      price
    }
  }
`

type TData = { getMaterialPriceForDate: Query['getMaterialPriceForDate'] }
type TVariables = QueryGetMaterialPriceForDateArgs

export function useGetMaterialPriceForDate(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_MATERIAL_PRICE_FOR_DATE, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
