import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { Query, QueryGetFeesArgs } from '@/schemaTypes'

const GET_FEES = gql`
  query getFees($opts: FeeOpts) {
    getFees(opts: $opts) {
      ...FeeDefinitionFragment
    }
  }
  ${fragments.feeDefinition}
`

type TData = { getFees: Query['getFees'] }
type TVariables = QueryGetFeesArgs

export function useGetFees(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useQuery<TData, TVariables>(GET_FEES, {
    variables: {},
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, fees: queryResult.data?.getFees || [] }
}
