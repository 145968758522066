import styled from '@emotion/styled'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

interface TaskbarProps {
  children?: React.ReactNode
  className?: string
}

const Taskbar: React.FC<TaskbarProps> = ({ children, className }) => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement || document.body

      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setIsScrolled(false)
      } else {
        setIsScrolled(true)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isScrolled])

  return (
    <Wrapper
      className={classNames(
        'mt-4 sticky bottom-0 z-10 flex items-start py-1.5 rounded-md',
        className,
        {
          scrolled: isScrolled,
          'rounded-none': isScrolled,
        },
      )}
    >
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid var(--panel-header-border-color, #dee2e6);
  background: var(--panel-header-background, #f8f9fa);
  gap: 0.625rem;
  transition: all 0.3s ease;
  box-shadow: unset;
  margin-left: 0rem;
  margin-right: 0rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;

  &.scrolled {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.1);
  }
`

export default Taskbar
