import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query } from '@/schemaTypes'

export const GET_MAINTENANCE_WINDOW = gql`
  query getMaintenanceWindow {
    getMaintenanceWindow {
      _id
      scheduledStartTime
      duration
    }
  }
`

type TData = { getMaintenanceWindow: Query['getMaintenanceWindow'] }

export function useGetMaintenanceWindow(
  opts: QueryHookOptions<TData, {}> = {},
) {
  const queryResult = useQuery<TData, {}>(GET_MAINTENANCE_WINDOW, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { maintenanceWindow: queryResult.data?.getMaintenanceWindow }
}
