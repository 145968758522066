import InputTextarea from '../FormikFields/FormikTextarea/FormikTextarea'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { Form, Formik } from 'formik'
import { Button } from 'primereact/button'
import { ToggleButton } from 'primereact/togglebutton'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Context } from '@/context'
import { NoteCreateArgs } from '@/schemaTypes'

interface CreateNoteProps {
  onAddNote: (note: NoteCreateArgs) => void
  isCreateMode?: boolean
}

const CreateNote = ({ onAddNote, isCreateMode }: CreateNoteProps) => {
  const { isGuestUser, language } = useContext(Context)
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={initialNote}
      validationSchema={NoteValidationSchema}
      onSubmit={(values, { resetForm }) => {
        onAddNote({ content: values.content, isPopup: values.isPopup })
        resetForm()
      }}
    >
      {(props) => {
        return (
          <Form className="flex flex-row items-center flex-1">
            <div className="flex items-center grow mr-2.5">
              <InputTextarea
                name="content"
                className="mr-2.5 w-full"
                inputContainerClassName="h-16 w-full"
                autoResize={true}
                disabled={isGuestUser()}
              />
              <PopupToggleButton
                onLabel=""
                offLabel=""
                onIcon="pi pi-bell"
                offIcon="pi pi-bell"
                name="isPopup"
                checked={props.values.isPopup}
                selected={props.values.isPopup}
                onChange={(e) => {
                  props.setFieldValue('isPopup', e.value)
                }}
                className="w-9rem"
              />
            </div>
            <div
              className={classNames('flex-none', {
                'w-36': language === 'en',
                'w-48': language === 'de',
              })}
            >
              {!isGuestUser() && !isCreateMode && (
                <Button
                  type="submit"
                  label={t('save_note')}
                  severity="secondary"
                  icon="pi pi-save"
                />
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

const initialNote = {
  content: '',
  isPopup: false,
}

const NoteValidationSchema = Yup.object().shape({
  content: Yup.string().min(2).required('Required'),
})

const PopupToggleButton = styled(ToggleButton)<{ selected?: boolean }>`
  &&& {
    border: 0;
    background: transparent;
    border-color: transparent;
    color: #ffffff;

    &:not(.p-highlight):hover {
      background: rgba(100, 116, 139, 0.04);
      border-color: transparent;
      color: #64748b;
    }
    &:not(.p-highlight):focus {
      box-shadow: unset;
    }

    &:hover {
      background: #f59e0b;
      border-color: #f59e0b;
      color: #ffffff;
    }

    &:active {
      background: #f59e0b;
      border-color: #f59e0b;
      color: #ffffff;
    }

    &:focus {
      box-shadow: unset;
    }

    .p-button-icon {
      color:;
      color: ${(props) => (props.selected ? '#f59e0b' : '#64748B')};
    }
  }
`

export default CreateNote
