import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { defaultLocale, setLocale } from 'yup'
import { de } from 'yup-locales'
import { ELanguageCode } from '@/schemaTypes'

setLocale(de)

interface Language {
  name: string
  code: string
}

const LanguageDropdown = () => {
  const { i18n } = useTranslation()

  const selectedLanguage = useMemo(() => {
    return languages.find((language) => language.code === i18n.language)
  }, [i18n.language])

  const onChange = (e: DropdownChangeEvent) => {
    const language = e.value as Language

    i18n.changeLanguage(language.code)
    setLocale(language.code === ELanguageCode.De ? de : defaultLocale)
  }

  return (
    <Dropdown
      className="w-full lg:w-36 p-0"
      value={selectedLanguage}
      onChange={onChange}
      options={languages}
      optionLabel="name"
    />
  )
}

const languages = [
  {
    name: 'Deutsch',
    code: ELanguageCode.De,
  },
  {
    name: 'English',
    code: ELanguageCode.En,
  },
] satisfies Language[]

export default LanguageDropdown
