import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdatePaybackDataArgs } from '@/schemaTypes'
import { dealFragments } from './dealFragments'

const UPDATE_PAYBACK_DATA = gql`
  mutation updatePayoutData($dealId: ObjectId!, $args: PaymentDataArgs!) {
    updatePaybackData(dealId: $dealId, args: $args) {
      _id
      events {
        ...DealEventFragment
      }
      pawnData {
        ...PawnDataFragment
      }
    }
  }
  ${dealFragments.event}
  ${dealFragments.pawnData}
`

type TData = { updatePaybackData: Mutation['updatePaybackData'] }
type TVariables = MutationUpdatePaybackDataArgs

export function useUpdatePaybackData(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updatePaybackData] = useMutation<TData, TVariables>(
    UPDATE_PAYBACK_DATA,
    opts,
  )

  return updatePaybackData
}
