import { dealFragments } from '../dealFragments'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { useMutationShowingErrors } from '@/hooks'
import {
  Deal,
  Mutation,
  MutationSetDealReceivedIdMissingArgs,
} from '@/schemaTypes'

const SET_DEAL_ITEM_RECEIVED_ID_MISSING = gql`
  mutation setDealReceivedIdMissing($dealId: ObjectId!) {
    setDealReceivedIdMissing(dealId: $dealId) {
      _id
      events {
        ...DealEventFragment
      }
    }
  }
  ${dealFragments.event}
`

type TData = { setDealReceivedIdMissing: Mutation['setDealReceivedIdMissing'] }
type TVariables = MutationSetDealReceivedIdMissingArgs

export function useOnDealStatusReceivedIdMissing(deal: Deal) {
  const { t } = useTranslation()

  const [setDealReceivedIdMissing] = useMutation<TData, TVariables>(
    SET_DEAL_ITEM_RECEIVED_ID_MISSING,
    {
      variables: { dealId: deal._id },
    },
  )

  return useMutationShowingErrors({
    mutation: setDealReceivedIdMissing,
    successMessage: t('update_successful'),
  })
}
