import { Box } from '@material-ui/core'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import TextInput from '@/components/TextInput'
import {
  Company,
  CompanyUnzerConfiguration,
  CompanyUnzerConfigurationArgs,
} from '@/schemaTypes'
import { CompanyUnzerConfigurationValidation } from '@/validation'

export interface CompanyUnzerConfigurationDetailsProps {
  persistedCompany?: Partial<Company> | null
  unzerConfiguration?:
    | (CompanyUnzerConfigurationArgs & CompanyUnzerConfiguration)
    | null
  setUnzerConfiguration: (value: CompanyUnzerConfigurationArgs) => void
  validationErrorsMap?: Partial<
    Record<keyof CompanyUnzerConfigurationValidation, any>
  >
}

export function CompanyUnzerConfigurationDetails(
  props: CompanyUnzerConfigurationDetailsProps,
) {
  const {
    persistedCompany,
    unzerConfiguration,
    setUnzerConfiguration,
    validationErrorsMap,
  } = props
  const { t } = useTranslation()

  return (
    <Fragment>
      <Box width={400}>
        <TextInput
          errorMessagePosition="left"
          error={validationErrorsMap?.companyCode}
          value={unzerConfiguration?.companyCode}
          onChange={(value) =>
            setUnzerConfiguration({
              companyCode: value.trim(),
              privateKey: unzerConfiguration?.privateKey,
              publicKey: unzerConfiguration?.publicKey,
            })
          }
          label={t('unzer_company_code')}
        />
      </Box>
      <TextInput
        inputWidth="400px"
        placeholder={
          persistedCompany?.unzerConfiguration?.privateKeyStored
            ? t('unzer_private_key_is_created')
            : t('unzer_private_key_is_not_created')
        }
        value={unzerConfiguration?.privateKey ?? ''}
        onChange={(value) =>
          setUnzerConfiguration({
            privateKey: value,
            companyCode: unzerConfiguration?.companyCode ?? '',
            publicKey: unzerConfiguration?.publicKey,
          })
        }
        label={t('unzer_private_key')}
      />
      <TextInput
        inputWidth="400px"
        value={unzerConfiguration?.publicKey}
        onChange={(value) =>
          setUnzerConfiguration({
            publicKey: value,
            companyCode: unzerConfiguration?.companyCode ?? '',
            privateKey: unzerConfiguration?.privateKey,
          })
        }
        label={t('unzer_public_key')}
      />
      <TextInput
        inputWidth="400px"
        value={unzerConfiguration?.webhookId}
        disabled
        label={t('unzer_webhook_id')}
      />
    </Fragment>
  )
}

export default CompanyUnzerConfigurationDetails
