import { Box, Button } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  isValid?: boolean
  handleReset?: () => void
}

export const TableFooter = ({ isValid, handleReset }: Props) => {
  const { t } = useTranslation()

  return (
    <Box
      style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 30 }}
    >
      <Button
        style={{ borderRadius: 100 }}
        variant="outlined"
        color="primary"
        size="large"
        onClick={handleReset}
      >
        {t('cancel')}
      </Button>
      <Button
        disabled={!isValid}
        style={{ borderRadius: 100, marginLeft: 10 }}
        variant="contained"
        color="primary"
        size="large"
        type="submit"
      >
        {t('save')}
      </Button>
    </Box>
  )
}
