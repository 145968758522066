import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationRemoveDealItemArgs } from '@/schemaTypes'
import { dealCalculationFragments, dealFragments } from './dealFragments'

const REMOVE_DEAL_ITEM = gql`
  mutation removeDealItem($dealId: ObjectId!, $itemId: ObjectId!) {
    removeDealItem(dealId: $dealId, itemId: $itemId) {
      _id
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
      pawnData {
        ...PawnDataFragment
      }
      purchaseData {
        ...PurchaseDataFragment
      }
    }
  }
  ${dealFragments.pawnData}
  ${dealFragments.dealItem}
  ${dealFragments.purchaseData}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = { removeDealItem: Mutation['removeDealItem'] }
type TVariables = MutationRemoveDealItemArgs

export function useRemoveDealItem(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  return useMutation<TData, TVariables>(REMOVE_DEAL_ITEM, opts)
}
