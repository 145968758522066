import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EBillingPeriodType,
  EDealType,
  FeeCreateArgs,
  FeeDefinitionEvaluation,
  FeeUpdateArgs,
} from '@/schemaTypes'
import FeeEvaluationDemonstrator from './FeeEvaluationDemonstrator'
import styles from './FeesConfiguration.module.scss'

interface FeeEvaluatorWithOutItemCategoryProps {
  generalFeeDefinitionsEvaluation: FeeDefinitionEvaluation // This object contains all general (not restricted to categories) Item level fee definitions
  newFeeDefinitionArgs: FeeCreateArgs | FeeUpdateArgs
  isEditMode: boolean
}

const FeeEvaluatorWithOutItemCategory: FunctionComponent<
  FeeEvaluatorWithOutItemCategoryProps
> = (props) => {
  const { generalFeeDefinitionsEvaluation, newFeeDefinitionArgs, isEditMode } =
    props
  const { t } = useTranslation()

  return (
    <table>
      <tr>
        <td className={`${styles.evaluationColumn}`} />
        <td className={`${styles.evaluationColumn}`}>{`${t('daily')}`}</td>
        <td className={`${styles.evaluationColumn}`}>{`${t('one_time')}`}</td>
        <td className={`${styles.evaluationColumn}`}>{`${t('half_month')}`}</td>
        <td className={`${styles.evaluationColumn}`}>{`${t('month')}`}</td>
      </tr>
      {generalFeeDefinitionsEvaluation && (
        <>
          <tr>
            <td
              className={`${styles.evaluationColumn}`}
            >{`${t('purchase')}`}</td>
            <td className={`${styles.evaluationColumn}`}>
              <FeeEvaluationDemonstrator
                inputFeeDefinitions={
                  generalFeeDefinitionsEvaluation.dailyPurchaseFeeEvaluation
                }
                newFeeDefinitionArgs={newFeeDefinitionArgs}
                isEditMode={isEditMode}
                billingPeriod={EBillingPeriodType.Day}
                dealType={EDealType.Purchase}
              />
            </td>
            <td className={`${styles.evaluationColumn}`}>
              <FeeEvaluationDemonstrator
                inputFeeDefinitions={
                  generalFeeDefinitionsEvaluation.oneTimePurchaseFeeEvaluation
                }
                newFeeDefinitionArgs={newFeeDefinitionArgs}
                isEditMode={isEditMode}
                billingPeriod={EBillingPeriodType.OneTime}
                dealType={EDealType.Purchase}
              />
            </td>
            <td className={`${styles.evaluationColumn}`}>
              <FeeEvaluationDemonstrator
                inputFeeDefinitions={
                  generalFeeDefinitionsEvaluation.halfMonthPurchaseFeeEvaluation
                }
                newFeeDefinitionArgs={newFeeDefinitionArgs}
                isEditMode={isEditMode}
                billingPeriod={EBillingPeriodType.HalfMonth}
                dealType={EDealType.Purchase}
              />
            </td>
            <td className={`${styles.evaluationColumn}`}>
              <FeeEvaluationDemonstrator
                inputFeeDefinitions={
                  generalFeeDefinitionsEvaluation.fullMonthPurchaseFeeEvaluation
                }
                newFeeDefinitionArgs={newFeeDefinitionArgs}
                isEditMode={isEditMode}
                billingPeriod={EBillingPeriodType.Month}
                dealType={EDealType.Purchase}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.evaluationColumn}`}>{`${t('pawn')}`}</td>

            <td className={`${styles.evaluationColumn}`}>
              <FeeEvaluationDemonstrator
                inputFeeDefinitions={
                  generalFeeDefinitionsEvaluation.dailyPawnFeeEvaluation
                }
                newFeeDefinitionArgs={newFeeDefinitionArgs}
                isEditMode={isEditMode}
                billingPeriod={EBillingPeriodType.Day}
                dealType={EDealType.Pawn}
              />
            </td>
            <td className={`${styles.evaluationColumn}`}>
              <FeeEvaluationDemonstrator
                inputFeeDefinitions={
                  generalFeeDefinitionsEvaluation.oneTimePawnFeeEvaluation
                }
                newFeeDefinitionArgs={newFeeDefinitionArgs}
                isEditMode={isEditMode}
                billingPeriod={EBillingPeriodType.OneTime}
                dealType={EDealType.Pawn}
              />
            </td>
            <td className={`${styles.evaluationColumn}`}>
              <FeeEvaluationDemonstrator
                inputFeeDefinitions={
                  generalFeeDefinitionsEvaluation.halfMonthPawnFeeEvaluation
                }
                newFeeDefinitionArgs={newFeeDefinitionArgs}
                isEditMode={isEditMode}
                billingPeriod={EBillingPeriodType.HalfMonth}
                dealType={EDealType.Pawn}
              />
            </td>
            <td className={`${styles.evaluationColumn}`}>
              <FeeEvaluationDemonstrator
                inputFeeDefinitions={
                  generalFeeDefinitionsEvaluation.fullMonthPawnFeeEvaluation
                }
                newFeeDefinitionArgs={newFeeDefinitionArgs}
                isEditMode={isEditMode}
                billingPeriod={EBillingPeriodType.Month}
                dealType={EDealType.Pawn}
              />
            </td>
          </tr>
        </>
      )}
    </table>
  )
}

export default FeeEvaluatorWithOutItemCategory
