import { useGetPrivateSaleSuggestedDeals } from '../../hooks/getPrivateSaleSuggestedDeals'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { FunctionComponent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './AddPrivateSaleDeal.module.scss'
import PrivateSaleDealsList from './PrivateSaleDealsList'

interface AddPrivateSaleDealProps {
  open
  scroll
  handleClose
  selectedDealId
  setSelectedDealId
}

const AddPrivateSaleDeal: FunctionComponent<AddPrivateSaleDealProps> = (
  props,
) => {
  const { open, scroll, handleClose, selectedDealId, setSelectedDealId } = props
  const { t } = useTranslation()
  const { suggestedDeals } = useGetPrivateSaleSuggestedDeals({
    variables: {},
  })

  const descriptionElementRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <div>
      <Dialog
        disableBackdropClick
        maxWidth={'xl'}
        open={open}
        onClose={() => handleClose(false)}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <Typography variant="h6" gutterBottom classes={{ root: styles.title }}>
          <DialogTitle>{t('auction.auction_add_element_title')}</DialogTitle>
        </Typography>
        <DialogContent dividers={scroll === 'paper'}>
          <PrivateSaleDealsList
            suggestedDeals={suggestedDeals}
            selectedDealId={selectedDealId}
            setSelectedDealId={setSelectedDealId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            {t('abort')}
          </Button>
          <Button onClick={() => handleClose(true)} color="primary">
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddPrivateSaleDeal
