import {
  useAddItemToStorageUnit,
  useCreateChildUnit,
  useCreateStorageUnit,
  useDeleteStorageUnit,
  useGetStorageFacility,
  useGetStorageUnits,
  useRemoveItemFromStorageUnit,
  useUpdateStorageUnit,
} from '../hooks'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import { last } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import StorageUnitsControls from '@/components/StorageUnitsControls'
import StorageUnitsEdit from '@/components/StorageUnitsEdit'
import StorageUnitsSelect from '@/components/StorageUnitsSelect'
import { Context } from '@/context'
import {
  useConfirmDeleteMutationShowingErrors,
  useMutationShowingErrors,
  useShowConfirmPopup,
} from '@/hooks'
import {
  Deal,
  DealItem,
  EDealType,
  EManualPaymentType,
  EStorageStatus,
  Item,
  ItemStorageHistoryEntry,
  ItemStorageUnitsArgs,
  StorageUnit,
} from '@/schemaTypes'
import { paymentTypeUnion } from '@/utils/misc'
import StorageUnitsConfigurationOnItemStatus from './StorageUnitsConfigurationOnItemStatus'
import StorageUnitsItemControls from './StorageUnitsItemControls'

export interface StorageUnitsProps {
  item?: Item
  storageFacilityId: string
  controlBarStatus: boolean
  hideActionButtons?: boolean
  storageHistory?: ItemStorageHistoryEntry[] | null
  items?: DealItem[]
  deal?: Deal
  showOnItemStatus?: boolean
  onChangeStorageUnits?: (data: {
    lvl1?: string
    lvl2?: string
    lvl3?: string
    lvl4?: string
  }) => void
}

export function StorageUnitsConfiguration(props: StorageUnitsProps) {
  const {
    item,
    storageFacilityId,
    controlBarStatus,
    storageHistory,
    items,
    deal,
    showOnItemStatus,
    onChangeStorageUnits,
    hideActionButtons,
  } = props
  const defaultStorageUnit: {
    _id: string
    name: string
    showTextInput: boolean
    isSecureLocation?: boolean
  } = {
    _id: '',
    name: '',
    showTextInput: false,
  }
  const context = useContext(Context)
  const { closeConfirmModal } = context
  const { t } = useTranslation()
  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })

  const [operation, setOperation] = useState<string | undefined>('add')

  const [firstLevelUnit, setFirstLevelUnit] = useState(defaultStorageUnit)
  const [secondLevelUnit, setSecondLevelUnit] = useState(defaultStorageUnit)
  const [thirdLevelUnit, setThirdLevelUnit] = useState(defaultStorageUnit)
  const [lastLevelUnit, setLastLevelUnit] = useState(defaultStorageUnit)

  const checkedDealStatusChange = useMemo(() => {
    const itemsWithoutStorageLable = items?.filter(
      (item) =>
        !item.storageHistory ||
        item.storageHistory[0].storageStatus ===
          EStorageStatus.RemovedFromStorage,
    )
    if (
      itemsWithoutStorageLable &&
      last(deal?.events)?.__typename === 'DealVerifiedEvent' &&
      deal?.dealType !== EDealType.Purchase
    ) {
      return (
        itemsWithoutStorageLable.length === 1 &&
        itemsWithoutStorageLable[0]._id === item?._id
      )
    }
    return false
  }, [items, deal, item?._id])

  useEffect(() => {
    if (
      getLatestStorageHistoryEntry() &&
      getLatestStorageHistoryEntry()?.storageStatus ===
        EStorageStatus.AddedToStorage
    ) {
      if (
        typeof getLatestStorageHistoryEntry()?.storageUnits.lvl1 === 'string'
      ) {
        const storageUnit = storageUnits.find(
          (unit) =>
            unit._id === getLatestStorageHistoryEntry()?.storageUnits.lvl1,
        )
        setFirstLevelUnit({
          _id: getLatestStorageHistoryEntry()?.storageUnits.lvl1,
          name: storageUnit?.name ?? '',
          showTextInput: false,
          isSecureLocation: storageUnit?.isSecureLocation,
        })
      }
      if (
        typeof getLatestStorageHistoryEntry()?.storageUnits.lvl2 === 'string'
      ) {
        const storageUnit = firstLevelChildUnits.find(
          (unit) =>
            unit._id === getLatestStorageHistoryEntry()?.storageUnits.lvl2,
        )
        setSecondLevelUnit({
          _id: getLatestStorageHistoryEntry()?.storageUnits.lvl2,
          name: storageUnit?.name ?? '',
          showTextInput: false,
          isSecureLocation: storageUnit?.isSecureLocation,
        })
      }
      if (
        typeof getLatestStorageHistoryEntry()?.storageUnits.lvl3 === 'string'
      ) {
        const storageUnit = secondLevelChildUnits.find(
          (unit) =>
            unit._id === getLatestStorageHistoryEntry()?.storageUnits.lvl3,
        )
        setThirdLevelUnit({
          _id: getLatestStorageHistoryEntry()?.storageUnits.lvl3,
          name: storageUnit?.name ?? '',
          showTextInput: false,
          isSecureLocation: storageUnit?.isSecureLocation,
        })
      }
      if (
        typeof getLatestStorageHistoryEntry()?.storageUnits.lvl4 === 'string'
      ) {
        const storageUnit = thirdLevelChildUnits.find(
          (unit) =>
            unit._id === getLatestStorageHistoryEntry()?.storageUnits.lvl4,
        )
        setLastLevelUnit({
          _id: getLatestStorageHistoryEntry()?.storageUnits.lvl4,
          name: storageUnit?.name ?? '',
          showTextInput: false,
          isSecureLocation: storageUnit?.isSecureLocation,
        })
      }
    }

    if (
      getLatestStorageHistoryEntry() &&
      getLatestStorageHistoryEntry()?.storageStatus ===
        EStorageStatus.RemovedFromStorage
    ) {
      _handleResetForSelectors('first')
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageHistory])

  const { storageFacility, queryResult: getStorageFacilityQueryResult } =
    useGetStorageFacility({
      variables: {
        storageFacilityId,
      },
      fetchPolicy: 'network-only',
    })

  const { storageUnits, queryResult } = useGetStorageUnits({
    variables: {
      opts: { filter: { ids: storageFacility?.storageUnitIds } },
    },
    fetchPolicy: 'network-only',
  })

  const firstLevelChildIds: string[] = []
  for (const storageUnit of storageUnits) {
    if (storageUnit.childIds) {
      firstLevelChildIds.push(...storageUnit.childIds)
    }
  }

  const {
    storageUnits: firstLevelChildUnits,
    queryResult: getFirstLevelChildUnitsQueryResult,
  } = useGetStorageUnits({
    variables: {
      opts: { filter: { ids: firstLevelChildIds } },
    },
    fetchPolicy: 'network-only',
  })

  const secondLevelChildIds: string[] = []
  for (const childUnit of firstLevelChildUnits) {
    if (childUnit.childIds) {
      secondLevelChildIds.push(...childUnit.childIds)
    }
  }

  const {
    storageUnits: secondLevelChildUnits,
    queryResult: getSecondLevelChildUnitsQueryResult,
  } = useGetStorageUnits({
    variables: {
      opts: { filter: { ids: secondLevelChildIds } },
    },
    fetchPolicy: 'network-only',
  })

  const thirdLevelChildIds: string[] = []
  for (const childUnit of secondLevelChildUnits) {
    if (childUnit.childIds) {
      thirdLevelChildIds.push(...childUnit.childIds)
    }
  }

  const {
    storageUnits: thirdLevelChildUnits,
    queryResult: getThirdLevelChildUnitsQueryResult,
  } = useGetStorageUnits({
    variables: {
      opts: { filter: { ids: thirdLevelChildIds } },
    },
    fetchPolicy: 'network-only',
  })

  const getChildUnits = (parentId: string) => {
    if (typeof parentId !== 'undefined') {
      const firstLevelParentUnit = storageUnits.find(
        (unit) => unit._id === parentId,
      )

      if (firstLevelParentUnit) {
        return firstLevelChildUnits.filter(
          (unit) =>
            !unit.isDeleted &&
            firstLevelParentUnit.childIds?.includes(unit._id),
        )
      } else {
        const secondLevelParentUnit = firstLevelChildUnits.find(
          (unit) => unit._id === parentId,
        )
        if (secondLevelParentUnit) {
          return secondLevelChildUnits.filter(
            (unit) =>
              !unit.isDeleted &&
              secondLevelParentUnit.childIds?.includes(unit._id),
          )
        } else {
          const thirdLevelParentUnit = secondLevelChildUnits.find(
            (unit) => unit._id === parentId,
          )
          if (thirdLevelParentUnit) {
            return thirdLevelChildUnits.filter(
              (unit) =>
                !unit.isDeleted &&
                thirdLevelParentUnit.childIds?.includes(unit._id),
            )
          }
        }
      }
    }
    return []
  }

  const getLatestStorageHistoryEntry = () => {
    if (storageHistory && storageHistory.length > 0) {
      return storageHistory[0]
    }

    return null
  }

  const createStorageUnit = useMutationShowingErrors({
    mutation: useCreateStorageUnit(),
    successMessage: t('storage.storage_unit_created'),
  })

  const createChildUnit = useMutationShowingErrors({
    mutation: useCreateChildUnit(),
    successMessage: t('storage.storage_unit_created'),
  })

  const deleteStorageUnit = useConfirmDeleteMutationShowingErrors({
    mutation: useDeleteStorageUnit(),
    successMessage: t('storage.storage_unit_deleted'),
  })

  const updateStorageUnit = useMutationShowingErrors({
    mutation: useUpdateStorageUnit(),
    successMessage: t('storage.storage_unit_updated'),
  })

  const addItemToStorageUnit = useMutationShowingErrors({
    mutation: useAddItemToStorageUnit(),
    successMessage: t('storage.item_added_to_storage_unit'),
  })

  const removeItemFromStorageUnit = useMutationShowingErrors({
    mutation: useRemoveItemFromStorageUnit(),
    successMessage: t('storage.item_removed_from_storage_unit'),
  })

  const _handleCreateFirstLevelUnit = async () => {
    const createStorageUnitRes = await createStorageUnit({
      variables: {
        name: firstLevelUnit.name,
        storageFacilityId,
        isSecureLocation: firstLevelUnit.isSecureLocation,
      },
      onCompleted: () => {
        getStorageFacilityQueryResult.refetch()
        queryResult.refetch()
      },
    })
    const _id = createStorageUnitRes.data?.createStorageUnit._id
    setFirstLevelUnit({
      _id: _id,
      name: firstLevelUnit.name,
      showTextInput: false,
    })
  }

  const _handleCreateChildUnit = async (name: string, parentId: string) => {
    if (typeof parentId !== 'undefined') {
      const createChildUnitRes = await createChildUnit({
        variables: {
          name,
          storageFacilityId,
          parentId,
        },
        onCompleted: () => {
          queryResult.refetch()
          getFirstLevelChildUnitsQueryResult.refetch()
          getSecondLevelChildUnitsQueryResult.refetch()
          getThirdLevelChildUnitsQueryResult.refetch()
        },
      })
      const _id = createChildUnitRes.data?.createChildUnit?._id
      if (!secondLevelUnit._id && secondLevelUnit.showTextInput && _id) {
        setSecondLevelUnit({ _id: _id, name: name, showTextInput: false })
      }
      if (
        secondLevelUnit._id &&
        !thirdLevelUnit._id &&
        thirdLevelUnit.showTextInput &&
        _id
      ) {
        setThirdLevelUnit({ _id: _id, name: name, showTextInput: false })
      }
      if (
        thirdLevelUnit._id &&
        !lastLevelUnit._id &&
        lastLevelUnit.showTextInput &&
        _id
      ) {
        setLastLevelUnit({ _id: _id, name: name, showTextInput: false })
      }
    }
  }

  const _handleUpdateStorageUnit = async (
    _id: string,
    name: string,
    isSecureLocation?: boolean,
  ) => {
    if (_id && name) {
      await updateStorageUnit({
        variables: {
          _id,
          name,
          isSecureLocation,
        },
        onCompleted: () => {
          queryResult.refetch()
          getFirstLevelChildUnitsQueryResult.refetch()
          getSecondLevelChildUnitsQueryResult.refetch()
          getThirdLevelChildUnitsQueryResult.refetch()
        },
      })
    }
    setFirstLevelUnit({ ...firstLevelUnit, showTextInput: false })
    setSecondLevelUnit({ ...secondLevelUnit, showTextInput: false })
    setThirdLevelUnit({ ...thirdLevelUnit, showTextInput: false })
    setLastLevelUnit({ ...lastLevelUnit, showTextInput: false })
  }

  const firstLevelChildUnitSecureLocation = firstLevelChildUnits.find(
    (unit) => unit._id === secondLevelUnit._id,
  )?.isSecureLocation
  const secondLevelChildUnitSecureLocation = secondLevelChildUnits.find(
    (unit) => unit._id === thirdLevelUnit._id,
  )?.isSecureLocation
  const thirdLevelChildUnitSecureLocation = thirdLevelChildUnits.find(
    (unit) => unit._id === lastLevelUnit._id,
  )?.isSecureLocation

  useEffect(() => {
    if (
      firstLevelUnit.isSecureLocation !== undefined &&
      firstLevelUnit.isSecureLocation !== null
    ) {
      setSecondLevelUnit({
        ...secondLevelUnit,
        isSecureLocation: firstLevelChildUnitSecureLocation,
      })
      setThirdLevelUnit({
        ...thirdLevelUnit,
        isSecureLocation: secondLevelChildUnitSecureLocation,
      })
      setLastLevelUnit({
        ...lastLevelUnit,
        isSecureLocation: thirdLevelChildUnitSecureLocation,
      })
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstLevelUnit.isSecureLocation,
    firstLevelChildUnitSecureLocation,
    secondLevelChildUnitSecureLocation,
    thirdLevelChildUnitSecureLocation,
  ])

  useEffect(() => {
    if (
      secondLevelUnit.isSecureLocation !== undefined &&
      secondLevelUnit.isSecureLocation !== null
    ) {
      setThirdLevelUnit({
        ...thirdLevelUnit,
        isSecureLocation: secondLevelChildUnitSecureLocation,
      })
      setLastLevelUnit({
        ...lastLevelUnit,
        isSecureLocation: thirdLevelChildUnitSecureLocation,
      })
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    secondLevelUnit.isSecureLocation,
    secondLevelChildUnitSecureLocation,
    thirdLevelChildUnitSecureLocation,
  ])

  useEffect(() => {
    if (
      thirdLevelUnit.isSecureLocation !== undefined &&
      thirdLevelUnit.isSecureLocation !== null
    ) {
      setLastLevelUnit({
        ...lastLevelUnit,
        isSecureLocation: thirdLevelChildUnitSecureLocation,
      })
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdLevelUnit.isSecureLocation, thirdLevelChildUnitSecureLocation])

  const _handleDeleteClick = async (id: string, level: string) => {
    await deleteStorageUnit({
      variables: {
        storageUnitId: id,
      },
      onCompleted: () => {
        queryResult.refetch()
        getFirstLevelChildUnitsQueryResult.refetch()
        getSecondLevelChildUnitsQueryResult.refetch()
      },
    })

    const deleteStatus = storageUnits.find((unit) => unit._id === id)
    if (deleteStatus) {
      _handleResetForSelectors(level)
    }
  }

  const _handleResetForSelectors = async (level: string) => {
    switch (level) {
      case 'first':
        setFirstLevelUnit(defaultStorageUnit)
        setSecondLevelUnit(defaultStorageUnit)
        setThirdLevelUnit(defaultStorageUnit)
        setLastLevelUnit(defaultStorageUnit)
        break

      case 'second':
        setSecondLevelUnit(defaultStorageUnit)
        setThirdLevelUnit(defaultStorageUnit)
        setLastLevelUnit(defaultStorageUnit)
        break

      case 'third':
        setThirdLevelUnit(defaultStorageUnit)
        setLastLevelUnit(defaultStorageUnit)
        break

      case 'last':
        setLastLevelUnit(defaultStorageUnit)
        break

      default:
        setFirstLevelUnit(defaultStorageUnit)
        setSecondLevelUnit(defaultStorageUnit)
        setThirdLevelUnit(defaultStorageUnit)
        setLastLevelUnit(defaultStorageUnit)
    }
    setOperation('add')
  }

  const handleOnChangeParent = (id: string, isSecureLocation?: boolean) => {
    setOperation('update')
    if (id) {
      const name = storageUnits.find((unit) => unit._id === id)?.name ?? ''
      setFirstLevelUnit({
        _id: id,
        name: name,
        showTextInput: false,
        isSecureLocation,
      })
      setSecondLevelUnit(defaultStorageUnit)
      setThirdLevelUnit(defaultStorageUnit)
      setLastLevelUnit(defaultStorageUnit)
    }
  }

  const handleOnChangeChildSecond = (
    id: string,
    isSecureLocation?: boolean,
  ) => {
    if (id) {
      const name =
        getChildUnits(firstLevelUnit._id).find((unit) => unit._id === id)
          ?.name ?? ''
      setSecondLevelUnit({
        _id: id,
        name: name,
        showTextInput: false,
        isSecureLocation,
      })
      setThirdLevelUnit(defaultStorageUnit)
      setLastLevelUnit(defaultStorageUnit)
    }
  }
  const handleOnChangeChildThird = (id: string, isSecureLocation?: boolean) => {
    if (id) {
      const name =
        getChildUnits(secondLevelUnit._id).find((unit) => unit._id === id)
          ?.name ?? ''
      setThirdLevelUnit({
        _id: id,
        name: name,
        showTextInput: false,
        isSecureLocation,
      })
      setLastLevelUnit(defaultStorageUnit)
    }
  }

  const handleOnChangeChildLast = (id: string, isSecureLocation?: boolean) => {
    if (id) {
      const name =
        getChildUnits(thirdLevelUnit._id).find((unit) => unit._id === id)
          ?.name ?? ''
      setLastLevelUnit({
        _id: id,
        name: name,
        showTextInput: false,
        isSecureLocation,
      })
    }
  }

  useEffect(() => {
    if (onChangeStorageUnits) {
      onChangeStorageUnits({
        lvl1: firstLevelUnit?._id ? firstLevelUnit?._id : undefined,
        lvl2: secondLevelUnit?._id ? secondLevelUnit?._id : undefined,
        lvl3: thirdLevelUnit?._id ? thirdLevelUnit?._id : undefined,
        lvl4: lastLevelUnit?._id ? lastLevelUnit?._id : undefined,
      })
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLevelUnit, secondLevelUnit, thirdLevelUnit, lastLevelUnit])

  const buildStorageUnits = () => {
    const storageUnits: ItemStorageUnitsArgs = {
      lvl1: firstLevelUnit._id,
    }

    if (secondLevelUnit._id) {
      storageUnits.lvl2 = secondLevelUnit._id
    }
    if (thirdLevelUnit._id) {
      storageUnits.lvl3 = thirdLevelUnit._id
    }
    if (lastLevelUnit._id) {
      storageUnits.lvl4 = lastLevelUnit._id
    }

    return storageUnits
  }

  const _handleAddItemToStorageUnit = async () => {
    showConfirmPopup({
      state: {
        storageDate: new Date(),
        note: '',
        dealStatusChangeAllowance:
          deal?.payoutData?.paymentType &&
          paymentTypeUnion(deal?.payoutData?.paymentType) ===
            EManualPaymentType.Cash,
      },
      title: t('storage.change_storage_unit'),
      component: (state, setState) => {
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <KeyboardDateTimePicker
                clearable
                ampm={false}
                style={{
                  width: '40%',
                  marginRight: '41px',
                }}
                value={state.storageDate}
                label={t('date') + ' :'}
                onChange={(date) => {
                  setState({ ...state, storageDate: date?.toDate() })
                }}
                format="DD.MM.YYYY"
              />
              <TextField
                style={{
                  width: '60%',
                }}
                id="note"
                label={t('note') + ' :'}
                value={state.note}
                onChange={(e) => {
                  setState({ ...state, note: e.target.value })
                }}
                variant="standard"
              />
            </div>
            {checkedDealStatusChange && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dealStatusChangeAllowance}
                    onChange={(e) => {
                      setState({
                        ...state,
                        dealStatusChangeAllowance: e.target.checked,
                      })
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={t('storage.last_item_stored')}
                style={{ marginTop: '30px' }}
              />
            )}
          </>
        )
      },
      autoFocusCloseButton: false,
      action: async (state) => {
        closeConfirmModal()
        await addItemToStorageUnit({
          variables: {
            itemId: item?._id,
            storageUnits: buildStorageUnits(),
            storageDate: state.storageDate,
            note: state.note,
            setDealToPayedAndStored: state.dealStatusChangeAllowance,
          },
        })
      },
    })
  }

  const _handleRemoveItemFromStorageUnit = async () => {
    showConfirmPopup({
      state: {
        storageDate: new Date(),
        note: '',
      },
      title: t('change_storage_unit'),
      component: (state, setState) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <KeyboardDateTimePicker
              clearable
              ampm={false}
              style={{
                width: '40%',
                marginRight: '41px',
              }}
              value={state.storageDate}
              label={t('date') + ' :'}
              onChange={(date) => {
                setState({ ...state, storageDate: date?.toDate() })
              }}
              format="DD.MM.YYYY"
            />
            <TextField
              style={{
                width: '60%',
              }}
              id="note"
              label={t('note') + ' :'}
              value={state.note}
              onChange={(e) => {
                setState({ ...state, note: e.target.value })
              }}
              variant="standard"
            />
          </div>
        )
      },
      autoFocusCloseButton: false,
      action: async (state) => {
        closeConfirmModal()
        await removeItemFromStorageUnit({
          variables: {
            itemId: item?._id,
            storageUnits: buildStorageUnits(),
            storageDate: state.storageDate,
            note: state.note,
          },
        })
      },
    })
  }

  const filterSecureLocation = (storageUnit: StorageUnit) => {
    return item?.isValuable ? storageUnit.isSecureLocation : true
  }

  if (showOnItemStatus) {
    return (
      <StorageUnitsConfigurationOnItemStatus
        firstLevelUnit={{
          _id: firstLevelUnit._id,
          onChange: (value) =>
            handleOnChangeParent(
              value,
              storageUnits.find((unit) => unit._id === value)?.isSecureLocation,
            ),
          menuItems: storageUnits
            .filter(filterSecureLocation)
            .map((firstLevel) => {
              return { label: firstLevel.name, value: firstLevel._id }
            }),
        }}
        secondLevelUnit={{
          _id: secondLevelUnit._id,
          onChange: (value) => {
            return handleOnChangeChildSecond(
              value,
              firstLevelChildUnits.find((unit) => unit._id === value)
                ?.isSecureLocation,
            )
          },
          menuItems: getChildUnits(firstLevelUnit._id)
            .filter(filterSecureLocation)
            .map((secondLevel) => {
              return { label: secondLevel.name, value: secondLevel._id }
            }),
        }}
        thirdLevelUnit={{
          _id: thirdLevelUnit._id,
          onChange: (value) =>
            handleOnChangeChildThird(
              value,
              secondLevelChildUnits.find((unit) => unit._id === value)
                ?.isSecureLocation,
            ),
          menuItems: getChildUnits(secondLevelUnit._id)
            .filter(filterSecureLocation)
            .map((thirdLevel) => {
              return { label: thirdLevel.name, value: thirdLevel._id }
            }),
        }}
        lastLevelUnit={{
          _id: lastLevelUnit._id,
          onChange: (value) =>
            handleOnChangeChildLast(
              value,
              thirdLevelChildUnits.find((unit) => unit._id === value)
                ?.isSecureLocation,
            ),
          menuItems: getChildUnits(thirdLevelUnit._id)
            .filter(filterSecureLocation)
            .map((lastLevel) => {
              return { label: lastLevel.name, value: lastLevel._id }
            }),
        }}
        _handleResetForSelectors={_handleResetForSelectors}
        latestStorageHistoryEntry={getLatestStorageHistoryEntry()}
      />
    )
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('storage.storage_units_section')}
      </Typography>

      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">{t('level') + ' 1'}</TableCell>
            <TableCell align="center">{t('level') + ' 2'}</TableCell>
            <TableCell align="center">{t('level') + ' 3'}</TableCell>
            <TableCell align="center">{t('level') + ' 4'}</TableCell>
            <TableCell align="center">Operationen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StorageUnitsSelect
            firstLevelUnit={{
              _id: firstLevelUnit._id,

              onChange: (value) => {
                return handleOnChangeParent(
                  value,
                  storageUnits.find((unit) => unit._id === value)
                    ?.isSecureLocation,
                )
              },
              menuItems: storageUnits
                .filter(filterSecureLocation)
                .map((firstLevel) => {
                  return { label: firstLevel.name, value: firstLevel._id }
                }),
            }}
            secondLevelUnit={{
              _id: secondLevelUnit._id,
              onChange: (value) => {
                return handleOnChangeChildSecond(
                  value,
                  firstLevelChildUnits.find((unit) => unit._id === value)
                    ?.isSecureLocation,
                )
              },
              menuItems: getChildUnits(firstLevelUnit._id)
                .filter(filterSecureLocation)
                .map((secondLevel) => {
                  return { label: secondLevel.name, value: secondLevel._id }
                }),
            }}
            thirdLevelUnit={{
              _id: thirdLevelUnit._id,
              onChange: (value) =>
                handleOnChangeChildThird(
                  value,
                  secondLevelChildUnits.find((unit) => unit._id === value)
                    ?.isSecureLocation,
                ),
              menuItems: getChildUnits(secondLevelUnit._id)
                .filter(filterSecureLocation)
                .map((thirdLevel) => {
                  return { label: thirdLevel.name, value: thirdLevel._id }
                }),
            }}
            lastLevelUnit={{
              _id: lastLevelUnit._id,
              onChange: (value) =>
                handleOnChangeChildLast(
                  value,
                  thirdLevelChildUnits.find((unit) => unit._id === value)
                    ?.isSecureLocation,
                ),
              menuItems: getChildUnits(thirdLevelUnit._id)
                .filter(filterSecureLocation)
                .map((lastLevel) => {
                  return { label: lastLevel.name, value: lastLevel._id }
                }),
            }}
            _handleResetForSelectors={_handleResetForSelectors}
            latestStorageHistoryEntry={getLatestStorageHistoryEntry()}
          />
          {controlBarStatus ? (
            <StorageUnitsControls
              firstLevelUnit={{
                _id: firstLevelUnit._id,
                onClickAdd: () => {
                  setFirstLevelUnit({
                    _id: firstLevelUnit._id,
                    name: '',
                    showTextInput: true,
                  })
                  setOperation('add')
                },
                onClickDelete: () =>
                  _handleDeleteClick(firstLevelUnit._id, 'first'),
                onClickUpdate: () => {
                  setFirstLevelUnit({ ...firstLevelUnit, showTextInput: true })
                  setOperation('update')
                },
              }}
              secondLevelUnit={{
                _id: secondLevelUnit._id,
                onClickAdd: () => {
                  setSecondLevelUnit({
                    _id: secondLevelUnit._id,
                    name: '',
                    showTextInput: true,
                  })
                  setOperation('add')
                },
                onClickDelete: () =>
                  _handleDeleteClick(secondLevelUnit._id, 'second'),
                onClickUpdate: () => {
                  setSecondLevelUnit({
                    ...secondLevelUnit,
                    showTextInput: true,
                  })
                  setOperation('update')
                },
              }}
              thirdLevelUnit={{
                _id: thirdLevelUnit._id,
                onClickAdd: () => {
                  setThirdLevelUnit({
                    _id: thirdLevelUnit._id,
                    name: '',
                    showTextInput: true,
                  })
                  setOperation('add')
                },
                onClickDelete: () =>
                  _handleDeleteClick(thirdLevelUnit._id, 'third'),
                onClickUpdate: () => {
                  setThirdLevelUnit({ ...thirdLevelUnit, showTextInput: true })
                  setOperation('update')
                },
              }}
              lastLevelUnit={{
                _id: lastLevelUnit._id,
                onClickAdd: () => {
                  setLastLevelUnit({
                    _id: lastLevelUnit._id,
                    name: '',
                    showTextInput: true,
                  })
                  setOperation('add')
                },
                onClickDelete: () =>
                  _handleDeleteClick(lastLevelUnit._id, 'last'),
                onClickUpdate: () => {
                  setLastLevelUnit({ ...lastLevelUnit, showTextInput: true })
                  setOperation('update')
                },
              }}
            />
          ) : !hideActionButtons ? (
            <TableRow>
              <TableCell colSpan={2} />
              <TableCell align="center">
                <StorageUnitsItemControls
                  disabled={
                    !firstLevelUnit._id &&
                    !secondLevelUnit._id &&
                    !thirdLevelUnit._id &&
                    !lastLevelUnit._id
                  }
                  onClickAdd={_handleAddItemToStorageUnit}
                  onClickRemove={_handleRemoveItemFromStorageUnit}
                  latestStorageHistoryEntry={getLatestStorageHistoryEntry()}
                />
              </TableCell>
            </TableRow>
          ) : null}

          {controlBarStatus && (
            <StorageUnitsEdit
              firstLevelUnit={{
                name: firstLevelUnit.name,
                show: firstLevelUnit.showTextInput,
                isSecureLocation: firstLevelUnit.isSecureLocation,
                disableSecureLocationUpdate: false,
                onChange: (name, isSecureLocation) => {
                  if (name) {
                    setFirstLevelUnit({ ...firstLevelUnit, name })
                  }

                  if (isSecureLocation !== null) {
                    setFirstLevelUnit({ ...firstLevelUnit, isSecureLocation })
                  }
                },
                onClickCreate: _handleCreateFirstLevelUnit,
                onClickUpdate: () =>
                  _handleUpdateStorageUnit(
                    firstLevelUnit._id,
                    firstLevelUnit.name,
                    firstLevelUnit.isSecureLocation,
                  ),
              }}
              secondLevelUnit={{
                name: secondLevelUnit.name,
                show: secondLevelUnit.showTextInput,
                isSecureLocation: secondLevelUnit.isSecureLocation,
                disableSecureLocationUpdate: firstLevelUnit.isSecureLocation,
                onChange: (name, isSecureLocation) => {
                  if (name) {
                    setSecondLevelUnit({ ...secondLevelUnit, name })
                  }

                  if (isSecureLocation !== null) {
                    setSecondLevelUnit({ ...secondLevelUnit, isSecureLocation })
                  }
                },
                onClickCreate: () =>
                  _handleCreateChildUnit(
                    secondLevelUnit.name,
                    firstLevelUnit._id,
                  ),
                onClickUpdate: () =>
                  _handleUpdateStorageUnit(
                    secondLevelUnit._id,
                    secondLevelUnit.name,
                    secondLevelUnit.isSecureLocation,
                  ),
              }}
              thirdLevelUnit={{
                name: thirdLevelUnit.name,
                show: thirdLevelUnit.showTextInput,
                isSecureLocation: thirdLevelUnit.isSecureLocation,
                disableSecureLocationUpdate:
                  firstLevelUnit.isSecureLocation ||
                  secondLevelUnit.isSecureLocation,
                onChange: (name, isSecureLocation) => {
                  if (name) {
                    setThirdLevelUnit({ ...thirdLevelUnit, name })
                  }

                  if (isSecureLocation !== null) {
                    setThirdLevelUnit({ ...thirdLevelUnit, isSecureLocation })
                  }
                },
                onClickCreate: () =>
                  _handleCreateChildUnit(
                    thirdLevelUnit.name,
                    secondLevelUnit._id,
                  ),
                onClickUpdate: () =>
                  _handleUpdateStorageUnit(
                    thirdLevelUnit._id,
                    thirdLevelUnit.name,
                    thirdLevelUnit.isSecureLocation,
                  ),
              }}
              lastLevelUnit={{
                name: lastLevelUnit.name,
                show: lastLevelUnit.showTextInput,
                isSecureLocation: lastLevelUnit.isSecureLocation,
                disableSecureLocationUpdate:
                  firstLevelUnit.isSecureLocation ||
                  secondLevelUnit.isSecureLocation ||
                  thirdLevelUnit.isSecureLocation,
                onChange: (name, isSecureLocation) => {
                  if (name) {
                    setLastLevelUnit({ ...lastLevelUnit, name })
                  }

                  if (isSecureLocation !== null) {
                    setLastLevelUnit({ ...lastLevelUnit, isSecureLocation })
                  }
                },
                onClickCreate: () =>
                  _handleCreateChildUnit(
                    lastLevelUnit.name,
                    thirdLevelUnit._id,
                  ),
                onClickUpdate: () =>
                  _handleUpdateStorageUnit(
                    lastLevelUnit._id,
                    lastLevelUnit.name,
                    lastLevelUnit.isSecureLocation,
                  ),
              }}
              operation={operation}
            />
          )}
        </TableBody>
      </Table>
    </>
  )
}

export default StorageUnitsConfiguration
