import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetAssignedStorageFeesByCategoryArgs } from '@/schemaTypes'

const GET_ASSIGNED_STORAGE_FEES_BY_CATEGORY = gql`
  query getAssignedStorageFeesByCategory($itemCategoryId: ObjectId!) {
    getAssignedStorageFeesByCategory(itemCategoryId: $itemCategoryId) {
      _id
      amount
      billingPeriodType
      level
      mode
      feeType
      dealTypes
      restrictToCategories
      transportMode
    }
  }
`

type TData = {
  getAssignedStorageFeesByCategory: Query['getAssignedStorageFeesByCategory']
}
type TVariables = QueryGetAssignedStorageFeesByCategoryArgs

export function useGetAssignedStorageFeesByCategory(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_ASSIGNED_STORAGE_FEES_BY_CATEGORY,
    {
      fetchPolicy: 'network-only',
      ...opts,
      skip: !opts.variables?.itemCategoryId,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    fees: queryResult.data?.getAssignedStorageFeesByCategory,
  }
}
