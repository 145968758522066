import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { paymentFragments } from '@/hooks/fragments'
import { Mutation, MutationUpdateSecondaryPayoutDataArgs } from '@/schemaTypes'

const UPDATE_SECONDARY_PAYOUT_DATA = gql`
  mutation updateSecondaryPayoutData(
    $dealId: ObjectId!
    $args: SecondaryPaymentDataArgs
  ) {
    updateSecondaryPayoutData(dealId: $dealId, args: $args) {
      _id
      secondaryPayoutData {
        paymentType {
          ...AnyPaymentTypeFragment
        }
        iban
        paypalAddress
        amount
      }
    }
  }
  ${paymentFragments.anyPaymentType}
`

type TData = {
  updateSecondaryPayoutData: Mutation['updateSecondaryPayoutData']
}
type TVariables = MutationUpdateSecondaryPayoutDataArgs

export function useUpdateSecondaryPayoutData(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateSecondaryPayoutData] = useMutation<TData, TVariables>(
    UPDATE_SECONDARY_PAYOUT_DATA,
    opts,
  )

  return updateSecondaryPayoutData
}
