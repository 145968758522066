import { Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import MaterialPricesDetails from '@/domains/materialPrices/components/MaterialPricesDetails'
import {
  useCreateMaterialPrice,
  useGetMaterialPrice,
} from '@/domains/materialPrices/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { MaterialPrice } from '@/schemaTypes'

export interface MaterialPricesCreateProps {
  materialPriceId: string | null
}

export function MaterialPricesCreate(props: MaterialPricesCreateProps) {
  const { materialPriceId } = props
  const { t } = useTranslation()
  const history = useHistory()

  const { materialPrice, queryResult } = useGetMaterialPrice({
    variables: {
      materialPriceId: materialPriceId ?? '',
    },
  })

  const createMaterialPrice = useMutationShowingErrors({
    mutation: useCreateMaterialPrice(),
    successMessage: t('material.material_price_created'),
  })

  const onSave = (materialPrice: MaterialPrice) => {
    createMaterialPrice({
      variables: {
        ...materialPrice,
      },
      onCompleted: (data) => {
        history.replace(
          `/inApp/configurations/materialPrices/edit/${data.createMaterialPrice._id}`,
        )
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={materialPriceId ?? ''}
        modelName={'materialPrices'}
        mode={'create'}
      />
      <Typography variant="h5" gutterBottom>
        {t('material.material_price_create')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        materialPrice && (
          <MaterialPricesDetails
            materialPrice={materialPrice}
            onSave={onSave}
          />
        )
      )}
    </Paper>
  )
}

export default MaterialPricesCreate
