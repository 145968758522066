import styled from '@emotion/styled'
import Geosuggest from '@ubilabs/react-geosuggest'
import { Field, FieldProps } from 'formik'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { ToggleButton } from 'primereact/togglebutton'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import FormikDropdown from '@/redesign/components/FormikFields/FormikDropdown/FormikDropdown'
import FormikInput from '@/redesign/components/FormikFields/FormikInput/FormikInput'
import { OCustomerAddress } from '@/schemaTypes'

interface AddressFormCardProps {
  address: OCustomerAddress
  index: number
  onSave: () => void
  onClose: () => void
  onChangeAddressType: (target: {
    id: string | null
    name: string
    value: boolean
  }) => void
  countryOptions: { label: string; value: string }[]
  isEditAddress: boolean
  setFieldValue: (field: string, value: any) => void
}

interface AddressComponentsProps {
  long_name: string
  short_name: string
  types: string[]
}

const AddressFormCard = ({
  address,
  index,
  onSave,
  onClose,
  onChangeAddressType,
  countryOptions,
  isEditAddress,
  setFieldValue,
}: AddressFormCardProps) => {
  const { t } = useTranslation()
  const geosuggestEl = useRef(null)

  const handleSuggestSelect = useCallback((suggest, index) => {
    if (!suggest) return

    const { gmaps, location } = suggest
    const addressComponents = gmaps.address_components

    const getField = (fieldName: string) => {
      const component = addressComponents.find(
        (component: AddressComponentsProps) =>
          component.types.includes(fieldName),
      )
      return component?.long_name || ''
    }

    setFieldValue(`addresses[${index}].queryString`, suggest.label)
    setFieldValue(`addresses[${index}].houseNumber`, getField('street_number'))
    setFieldValue(`addresses[${index}].street`, getField('route'))
    setFieldValue(`addresses[${index}].city`, getField('locality'))
    setFieldValue(`addresses[${index}].zipCode`, getField('postal_code'))
    setFieldValue(`addresses[${index}].coordinates.lat`, location?.lat || 0)
    setFieldValue(`addresses[${index}].coordinates.lng`, location?.lng || 0)

    // On change Geosuggestion reset value for these three fields
    setFieldValue(`addresses[${index}].stairway`, '')
    setFieldValue(`addresses[${index}].floor`, '')
    setFieldValue(`addresses[${index}].door`, '')
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AddressFormContainer className="p-4 mr-6 relative">
      <div className="flex flex-col">
        <div className="flex flex-wrap">
          <div className="mr-0 md:mr-7 mb-4 md:mb-0 w-full md:w-auto">
            <GeosuggestStyled
              className="w-full my-0"
              ref={geosuggestEl}
              initialValue={address.queryString ?? ''}
              placeholder={t('search')}
              types={['address']}
              country={[address.countryCode ?? 'at']}
              autoActivateFirstSuggest
              onSuggestSelect={(e) => handleSuggestSelect(e, index)}
            />
          </div>
          <div className="mr-2 mb-2 md:mb-0">
            <Field name={`addresses[${index}].isBillingAddress`}>
              {({ field }: FieldProps<boolean | undefined>) => {
                return (
                  <div>
                    <ToggleButtonStyled
                      checked={field.value}
                      onLabel={t('billing')}
                      offLabel={t('billing')}
                      onIcon="pi pi-check"
                      offIcon="pi pi-plus-circle"
                      className="w-9rem"
                      name={field.name}
                      onChange={({ target }) => onChangeAddressType(target)}
                    />
                  </div>
                )
              }}
            </Field>
          </div>
          <div className="mr-2 mb-2 md:mb-0">
            <Field name={`addresses[${index}].isDeliveryAddress`}>
              {({ field }: FieldProps<boolean | undefined>) => (
                <div>
                  <ToggleButtonStyled
                    checked={field.value}
                    onLabel={t('shipping')}
                    offLabel={t('shipping')}
                    onIcon="pi pi-check"
                    offIcon="pi pi-plus-circle"
                    className="w-9rem"
                    name={field.name}
                    onChange={({ target }) => onChangeAddressType(target)}
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="mb-2 md:mb-0">
            <Field name={`addresses[${index}].isRegistrationAddress`}>
              {({ field }: FieldProps<boolean | undefined>) => (
                <div>
                  <ToggleButtonStyled
                    checked={field.value}
                    onLabel={t('registration')}
                    offLabel={t('registration')}
                    onIcon="pi pi-check"
                    offIcon="pi pi-plus-circle"
                    className="w-9rem"
                    name={field.name}
                    onChange={({ target }) => {
                      onChangeAddressType(target)
                    }}
                  />
                </div>
              )}
            </Field>
          </div>
        </div>
        <DividerStyled />
        <div className="flex flex-col md:flex-row mb-3">
          <FormikInput
            name={`addresses[${index}].street`}
            type="text"
            label={t('address.street')}
            placeholder={'e.g. Burggasse'}
            labelClassName="text-xs"
            className="mr-0 md:mr-2"
            inputContainerClassName="w-full md:w-19.43"
            required
          />
          <FormikInput
            name={`addresses[${index}].houseNumber`}
            type="text"
            label={t('address.house_number')}
            placeholder={'e.g. 107'}
            labelClassName="text-xs"
            className="mr-0 md:mr-2"
            inputContainerClassName="w-full md:w-6.25"
            required
          />
          <FormikInput
            name={`addresses[${index}].stairway`}
            type="text"
            label={t('address.stairway')}
            placeholder={'e.g. 3'}
            labelClassName="text-xs"
            className="mr-0 md:mr-2"
            inputContainerClassName="w-full md:w-4.68"
          />
          <FormikInput
            name={`addresses[${index}].floor`}
            type="text"
            label={t('address.floor')}
            placeholder={'e.g. 2'}
            className="mr-0 md:mr-2"
            labelClassName="text-xs"
            inputContainerClassName="w-full md:w-4.68"
          />
          <FormikInput
            name={`addresses[${index}].door`}
            type="text"
            label={t('address.door')}
            placeholder={'e.g. 8'}
            labelClassName="text-xs"
            inputContainerClassName="w-full md:w-4.68"
          />
        </div>
        <div className="flex flex-col md:flex-row">
          <FormikInput
            name={`addresses[${index}].zipCode`}
            type="text"
            label={t('address.zip_code')}
            placeholder={'e.g. 1080'}
            labelClassName="text-xs"
            className="mr-0 md:mr-2"
            inputContainerClassName="w-full md:w-5.625"
            required
          />
          <FormikInput
            name={`addresses[${index}].city`}
            type="text"
            label={t('address.city')}
            placeholder={'e.g. Wien'}
            labelClassName="text-xs"
            className="mr-0 md:mr-2"
            inputContainerClassName="w-full md:w-6.25"
            required
          />
          <FormikDropdown
            name={`addresses[${index}].countryCode`}
            label={t('address.country')}
            options={countryOptions}
            labelClassName="text-xs"
            className="mr-0 md:mr-2"
            inputContainerClassName="w-full md:w-9.375"
          />
        </div>
      </div>
      <div className="relative md:absolute bottom-0 md:bottom-3.5 right-0 md:right-4 mt-4 md:mt-0 flex">
        <div className=" mr-1">
          <ButtonStyled
            className="h-10 text-sm"
            icon="pi pi-save"
            label={t('save')}
            onClick={onSave}
          />
        </div>
        <div>
          <ButtonStyled
            className="h-10 text-sm"
            icon="pi pi-times"
            label={`${isEditAddress ? t('address_cancel') : t('close')} `}
            severity="secondary"
            text
            onClick={onClose}
          />
        </div>
      </div>
    </AddressFormContainer>
  )
}

const AddressFormContainer = styled.div`
  width: 43.75rem;
  height: 15rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  background: var(--surface-50, #fafafa);
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`

const ToggleButtonStyled = styled(ToggleButton)`
  border-radius: 2rem;
  padding: 0.65625rem 1.09375rem;
  font-size: 0.875rem;
  font-family: 'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.0625rem;
  height: 2.375rem;
`

const DividerStyled = styled(Divider)`
  &&& {
    margin: 1rem 0;
  }
`

const ButtonStyled = styled(Button)`
  padding: 0.65625rem 1.09375rem;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.05rem;
  border-radius: 0.375rem;
`

const GeosuggestStyled = styled(Geosuggest)`
  .geosuggest__input-wrapper {
    background-color: transparent;
    width: auto;
    height: auto;
    border-radius: 0.375rem;
  }
  .geosuggest__input-wrapper input {
    border-radius: 0.375rem;
    background: var(--input-background, #fff);
    font-size: 0.875rem;
    height: 2.375rem;
    padding: 0.66rem;
    color: #495057;
    background: #ffffff;
    border: 1px solid #ced4da;
    transition:
      background-color 0.2s,
      color 0.2s,
      border-color 0.2s,
      box-shadow 0.2s;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 6px;
    width: 100%;
    /* border: 2px solid transparent; */
    box-shadow: unset;
    padding: 0.5em 1em;
    -webkit-transition:
      border 0.2s,
      box-shadow 0.2s;
    transition:
      border 0.2s,
      box-shadow 0.2s;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
  }
  .geosuggest__input:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }

  /**
 * A geosuggest suggests
 */

  .geosuggest__suggests-wrapper {
    border: unset;
  }

  .geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    padding: 0;
    margin-top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition:
      max-height 0.2s,
      border 0.2s;
    transition:
      max-height 0.2s,
      border 0.2s;
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 6px;
  }

  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  .geosuggest__item {
    margin: 0;
    padding: 0.75rem 1.25rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
    cursor: pointer;
  }

  .geosuggest__item span {
    font-size: 1rem;
    color: #495057;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
  }

  .geosuggest__item--active span {
    font-weight: 400;
    color: #4338ca;
    background: #eef2ff;
  }

  .geosuggest__item:hover,
  .geosuggest__item:focus {
    color: #495057;
    background: #e9ecef;
  }

  .geosuggest__item--active {
    color: #4338ca;
    background: #eef2ff;
  }

  .geosuggest__item--active:hover,
  .geosuggest__item--active:focus {
    color: #4338ca;
    background: #eef2ff;
  }

  .geosuggest__item__matched-text {
    font-weight: bold;
  }
`

export default AddressFormCard
