import styled from '@emotion/styled'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import { useFormik } from 'formik'
import { useContext, useMemo, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import * as Yup from 'yup'
import { Context } from '@/context'
import { useGetShippingProfileList } from '@/domains/items/hooks/itemDetails/getAllShippingProfileList'
import { useGetRefurbedProductsByInstanceId } from '@/domains/items/hooks/itemDetails/getRefurbedProductsByInstanceId'
import { useGetSimilarRefurbedProducts } from '@/domains/items/hooks/itemDetails/getSimilarRefurbedProducts'
import FindAndReplaceIcon from '@/images/find_and_replace.svg'
import {
  Deal,
  EItemStatusType,
  ESalesPartnerType,
  Item,
  ItemEvent,
  RefurbedProduct,
} from '@/schemaTypes'
import { useAppSelector } from '@/store'
import { getNow } from '@/utils/time'
import { EHintType } from './ItemStatus'
import { EMarginType, calculateRefurbedPrice } from './util/refurbedProductUtil'

export interface ItemRefurbedOfferedToSellStatusProps {
  deal: Deal
  item: Item
  onChangeItemStatus: (newEvent: ItemEvent, hint: EHintType) => void
  updateStatusLoading: boolean
}

interface IRefurbedOffer {
  instanceId?: string
  productName?: string
  offeredPrice: number
  lowestPrice: number
  grading: string
  warranty: string
  shippingProfileId: string
}

export interface ShippingProfileOption {
  key: string
  value: string
}

const ItemRefurbedOfferedToSellStatus: FunctionComponent<
  ItemRefurbedOfferedToSellStatusProps
> = (props) => {
  const { onChangeItemStatus, item, deal, updateStatusLoading } = props
  const { getCurrentUser, showInfo } = useContext(Context)
  const { t } = useTranslation()
  const defaultRefurbedShippingProfile = useAppSelector(
    (state) => state.ui.defaultRefurbedShippingProfile,
  )

  const dealItem = deal.items.find((dealItem) => dealItem._id === item._id)

  const [openSelectRefurbedProduct, setOpenSelectRefurbedProduct] =
    useState<boolean>(false)

  const [selectedProduct, setSelectedProduct] =
    useState<RefurbedProduct | null>(null)

  const [inputInstanceId, setInputInstanceId] = useState<string>('')

  const onReset = async () => {
    await setFieldValue('productName', '')
    formik.resetForm()
  }

  const onSubmit = async () => {
    const now = getNow()
    onChangeItemStatus(
      {
        createdAt: now,
        timestamp: now,
        event: EItemStatusType.OfferedToSell,
        meta: {
          refurbedOffer: {
            ...formik.values,
            shippingProfileName:
              shippingProfileOptions.find((s) => s.key === shippingProfileId)
                ?.value || '',
          },
          advertiserPlatformName: [ESalesPartnerType.Refurbed],
          creatorId: getCurrentUser()?._id,
        },
      },
      EHintType.NONE,
    )
  }

  const formik = useFormik<IRefurbedOffer>({
    initialValues: {
      offeredPrice: calculateRefurbedPrice(
        EMarginType.EXPECTED_MARGIN,
        item,
        deal.company.salesConfiguration,
      ),
      lowestPrice: calculateRefurbedPrice(
        EMarginType.MINIMUM_MARGIN,
        item,
        deal.company.salesConfiguration,
      ),
      warranty: REFURBED_WARRANTY[0].key,
      grading: REFURBED_GRADING[0].key,
      shippingProfileId: defaultRefurbedShippingProfile,
    },
    onSubmit,
    enableReinitialize: true,
    validationSchema: refurbedOfferValidationSchema,
  })

  const { values, errors, handleChange, setFieldValue } = formik
  const {
    instanceId,
    offeredPrice,
    productName,
    lowestPrice,
    warranty,
    grading,
    shippingProfileId,
  } = values

  const handleSubmit = () => {
    formik.submitForm()
  }

  const updateRefurbedProductFromDB = (data: RefurbedProduct | null) => {
    setFieldValue('instanceId', data?.instanceId)
    setFieldValue('productName', data?.nameDe)
  }

  const { getShippingProfileList } = useGetShippingProfileList({
    fetchPolicy: 'cache-first',
  })

  const { similarRefurbedProducts } = useGetSimilarRefurbedProducts({
    variables: {
      opts: {
        keyword: dealItem.title,
      },
    },

    nextFetchPolicy: 'cache-and-network',
    skip: !dealItem.title,
  })

  const searchStarted = inputInstanceId.length > 2

  const { refurbedProductsByInstanceId } = useGetRefurbedProductsByInstanceId({
    variables: {
      instanceId: inputInstanceId,
    },
    skip: !searchStarted,
    fetchPolicy: 'cache-first',
  })

  const bestMatchedRefurbedProducts = searchStarted
    ? refurbedProductsByInstanceId
    : similarRefurbedProducts.slice(0, 2)
  const otherMatchedRefurbedProducts = searchStarted
    ? []
    : similarRefurbedProducts.slice(2)

  const shippingProfileOptions: ShippingProfileOption[] = useMemo(() => {
    return getShippingProfileList.map((profile) => ({
      key: profile.profileId,
      value: `${profile.name} - ${profile.sourceCountryMarketCode.toUpperCase()} (${t(
        'SHIPPING_PROFILE_DESTINATIONS',
      )}: ${profile.destinations.map((d) => d.marketCode.toUpperCase()).join(' - ')})`,
    }))
  }, [t, getShippingProfileList])

  const handleCloseRefurbedDialog = (shouldSave: boolean) => {
    setOpenSelectRefurbedProduct(false)

    if (shouldSave) {
      updateRefurbedProductFromDB(selectedProduct)
    }
  }

  return (
    <>
      <OfferTitle>{t('generate_offer_on_refurbed')}</OfferTitle>
      <OfferContent>
        <OfferRow>
          <Label>{t('refurbed_instance_id')}:</Label>
          <Typography style={{ width: '65%' }}>{instanceId}</Typography>
        </OfferRow>
        <OfferRow>
          <Label>{t('refurbed_product_name')}:</Label>
          {productName ? (
            <div style={{ display: 'flex' }}>
              <Typography style={{ fontWeight: 600, cursor: 'pointer' }}>
                {productName}
              </Typography>
              <ReactSVG
                style={{
                  cursor: 'pointer',
                  width: 50,
                  marginTop: 5,
                  marginLeft: 5,
                }}
                onClick={() => setOpenSelectRefurbedProduct(true)}
                src={FindAndReplaceIcon}
              />
            </div>
          ) : (
            <div
              onClick={() => setOpenSelectRefurbedProduct(true)}
              style={{ fontWeight: 600, cursor: 'pointer' }}
            >
              {t('select_refurbed_product')}
            </div>
          )}

          <Dialog
            maxWidth="lg"
            open={!!openSelectRefurbedProduct}
            onClose={() => setOpenSelectRefurbedProduct(false)}
          >
            <DialogTitle style={{ fontWeight: 'bold' }}>
              {t('found_multiple_refurbed_products_in_database')}
            </DialogTitle>
            <DialogContent dividers>
              <Typography>
                {t('for_our_item')}{' '}
                <span style={{ fontWeight: 600 }}>{dealItem.title}</span>{' '}
                {t('multiple_items_match')}:
              </Typography>
              <Box style={{ marginTop: 10 }}>
                <Typography style={{ fontWeight: 600 }}>
                  {t('best_matches')}:{' '}
                </Typography>
                <ProductListWrapper>
                  {bestMatchedRefurbedProducts.map((product) => (
                    <ProductRow
                      onClick={() => setSelectedProduct(product)}
                      isSelected={
                        product.instanceId === selectedProduct?.instanceId
                      }
                    >
                      {product.nameDe}
                    </ProductRow>
                  ))}
                </ProductListWrapper>
              </Box>
              {otherMatchedRefurbedProducts.length > 0 && (
                <>
                  <Box style={{ marginTop: 10 }}>
                    <Typography style={{ fontWeight: 600 }}>
                      {t('other_matches')}:{' '}
                    </Typography>

                    <ProductListWrapper>
                      {otherMatchedRefurbedProducts.map((product) => (
                        <ProductRow
                          onClick={() => setSelectedProduct(product)}
                          isSelected={
                            product.instanceId === selectedProduct?.instanceId
                          }
                        >
                          {product.nameDe}
                        </ProductRow>
                      ))}
                    </ProductListWrapper>
                  </Box>
                </>
              )}
              <Box style={{ marginTop: 10 }}>
                <Typography style={{ fontWeight: 600 }}>
                  {t('or_enter_refurbed_id_manually')}
                </Typography>
                <TextField
                  onChange={(e) => setInputInstanceId(e.target.value)}
                  style={{ marginLeft: 12 }}
                  value={inputInstanceId ?? ''}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleCloseRefurbedDialog(false)}
                color="primary"
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={() => handleCloseRefurbedDialog(true)}
                color="primary"
                autoFocus
              >
                {t('save')}
              </Button>{' '}
            </DialogActions>
          </Dialog>
        </OfferRow>
        <OfferRow>
          <Label>{t('regular_price')}:</Label>
          <TextField
            fullWidth
            style={{ width: '65%' }}
            type="number"
            name="offeredPrice"
            value={offeredPrice}
            onChange={handleChange}
            error={!!errors.offeredPrice}
            helperText={errors.offeredPrice && t(errors.offeredPrice)}
          />
        </OfferRow>
        <OfferRow>
          <Label>{t('minimum_price')}:</Label>
          <TextField
            fullWidth
            style={{ width: '65%' }}
            type="number"
            name="lowestPrice"
            value={lowestPrice}
            onChange={handleChange}
            error={!!errors.lowestPrice}
            helperText={errors.lowestPrice && t(errors.lowestPrice)}
          />
        </OfferRow>
        <OfferRow>
          <Label>{t('refurbed_grading')}:</Label>
          <Select
            fullWidth
            style={{ width: '65%' }}
            className="select"
            value={grading}
            onChange={handleChange}
            name="grading"
          >
            {REFURBED_GRADING.map((g) => (
              <MenuItem key={g.key} value={g.key}>
                {g.value}
              </MenuItem>
            ))}
          </Select>
        </OfferRow>
        <OfferRow>
          <Label>{t('warranty')}:</Label>
          <Select
            fullWidth
            style={{ width: '65%' }}
            className="select"
            value={warranty}
            onChange={handleChange}
            name="warranty"
          >
            {REFURBED_WARRANTY.map((g) => (
              <MenuItem key={g.key} value={g.key}>
                {g.value}
              </MenuItem>
            ))}
          </Select>
        </OfferRow>
        <OfferRow>
          <Label>{t('shipping_profile')}:</Label>
          <Select
            fullWidth
            style={{ width: '65%' }}
            className="select"
            value={shippingProfileId}
            onChange={handleChange}
            name="shippingProfileId"
          >
            {shippingProfileOptions.map((g) => (
              <MenuItem key={g.key} value={g.key}>
                {g.value}
              </MenuItem>
            ))}
          </Select>
          <Icon
            className="o-media__fixed u-inline-block"
            onClick={() => showInfo(t('shipping_profile_list_helper'))}
          >
            <HelpIcon />
          </Icon>
        </OfferRow>
        <Footer>
          <Button
            className="u-mt-20 u-mb-20"
            variant="contained"
            color="secondary"
            onClick={onReset}
            disabled={updateStatusLoading}
          >
            {t('reset')}
          </Button>
          <OfferButton
            className="u-mt-20 u-mb-20"
            variant="contained"
            onClick={handleSubmit}
            disabled={updateStatusLoading || !formik.isValid}
          >
            {t('place_offer')}
          </OfferButton>
        </Footer>
      </OfferContent>
    </>
  )
}

const OfferTitle = styled(Typography)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
`

const OfferContent = styled.div`
  max-width: 1000px;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
`

const OfferRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`

const Label = styled(Typography)`
  min-width: 200px;
`

const OfferButton = styled(Button)`
  &&& {
    background-color: rgba(104, 58, 183, 0.7529411765);
    color: white;
    &:hover {
      background-color: #673abf;
    }
  }
`

const Icon = styled.div`
  position: absolute;
  right: -30px;
  top: 5px;
  &:hover {
    cursor: pointer;
  }
`

const ProductRow = styled(Typography)<{ isSelected?: boolean }>`
  padding: 5px;
  border-radius: 5px;
  border: solid 1px #c2c2c2;

  ${({ isSelected }) =>
    isSelected ? 'background-color: #EDE7F8;' : 'background-color: white;'}

  cursor: pointer;
  margin-bottom: 5px;
`

const ProductListWrapper = styled(Box)`
  margin-top: 10px;
  margin-left: 12px;
`

const REFURBED_GRADING = [
  {
    key: 'A',
    value: 'New/Like New',
  },
  {
    key: 'B',
    value: 'Good',
  },
  {
    key: 'C',
    value: 'Used',
  },
]

const REFURBED_WARRANTY = [
  {
    key: 'M12',
    value: '12 Months',
  },
  {
    key: 'M18',
    value: '18 Months',
  },
  {
    key: 'M24',
    value: '24 Months',
  },
  {
    key: 'M30',
    value: '30 Months',
  },
  {
    key: 'M36',
    value: '36 Months',
  },
]

const refurbedOfferValidationSchema = Yup.object()
  .shape({
    instanceId: Yup.string().required(),
    productName: Yup.string().required(),
    offeredPrice: Yup.number().required(),
    lowestPrice: Yup.number().required(),
    grading: Yup.string().required(),
    warranty: Yup.string().required(),
    shippingProfileId: Yup.string().required(),
  })
  .strict(true)

export default ItemRefurbedOfferedToSellStatus
