import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useContext } from 'react'
import { Context } from '@/context'
import { fragments } from '@/hooks/fragments'
import {
  ELanguageCode,
  Mutation,
  MutationChangeAuctionItemStatusArgs,
} from '@/schemaTypes'
import { auctionFragments } from './auctionFragments'

const CHANGE_AUCTION_ITEM_STATUS = gql`
  mutation changeAuctionItemStatus(
    $newItemStatus: EAuctionItemStatus!
    $args: AuctionItemArgs!
    $languageCode: ELanguageCode!
  ) {
    changeAuctionItemStatus(newItemStatus: $newItemStatus, args: $args) {
      ...AuctionDetailsFragment
    }
  }
  ${fragments.customDealFormQuestion}
  ${fragments.formAnswers}
  ${auctionFragments.auctionDetails}
  ${fragments.address}
`

type TData = { setAuctionItemSold: Mutation['changeAuctionItemStatus'] }
type TVariables = {
  languageCode?: ELanguageCode
} & MutationChangeAuctionItemStatusArgs

export function useChangeAuctionItemStatus(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const { language } = useContext(Context)

  const [changeAuctionItemStatus] = useMutation<TData, TVariables>(
    CHANGE_AUCTION_ITEM_STATUS,
    {
      ...opts,
      variables: { ...(opts.variables as TVariables), languageCode: language },
    },
  )

  return changeAuctionItemStatus
}
