import { debounce } from 'lodash'
import { useCallback } from 'react'

/**
 * Attention: Make sure that the callback function is a pure function
 * and does not variables that were created outside of its scope!
 *
 * Otherwise, these variables will be cached and not updated once
 * the debounced function has been created!
 *
 * @param callback
 * @param delay
 */
export function useDebouncedCallback(
  callback: (...args: any) => any,
  delay: number,
) {
  const d = callback

  // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callbackfunc = useCallback(debounce(d, delay), [])

  return [callbackfunc]
}
