import { Button } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import React from 'react'

export interface StorageUnitsControlsButtonsProps {
  id: string
  disabled: boolean
  addStatus?: boolean
  onClickAdd?: () => void
  onClickUpdate?: () => void
  onClickDelete: (id: string) => void
}
export function StorageUnitsControlsButtons(
  props: StorageUnitsControlsButtonsProps,
) {
  const { id, disabled, addStatus, onClickAdd, onClickUpdate, onClickDelete } =
    props
  return (
    <>
      <Button
        onClick={onClickAdd ? onClickAdd : undefined}
        disabled={disabled || !addStatus}
      >
        <AddCircleOutlineIcon />
      </Button>
      <Button
        onClick={onClickUpdate ? onClickUpdate : undefined}
        disabled={!disabled}
      >
        <EditTwoToneIcon />
      </Button>
      <Button onClick={() => onClickDelete(id)} disabled={!disabled}>
        <RemoveCircleOutlineIcon />
      </Button>
    </>
  )
}

export default StorageUnitsControlsButtons
