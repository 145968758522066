import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeactivateCompanyArgs } from '@/schemaTypes'

const DEACTIVATE_COMPANY = gql`
  mutation deactivateCompany($companyId: ObjectId!) {
    deactivateCompany(companyId: $companyId) {
      _id
    }
  }
`

type TData = { deactivateCompany: Mutation['deactivateCompany'] }
type TVariables = MutationDeactivateCompanyArgs

export function useDeactivateCompany(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deactivateCompany] = useMutation<TData, TVariables>(
    DEACTIVATE_COMPANY,
    opts,
  )

  return deactivateCompany
}
