import React from 'react'
import { Deal, EBusinessUnit } from '@/schemaTypes'
import ItemValorizationDetails, {
  ItemValorizationDetailsProps,
} from './ItemValorizationDetails'

export interface ItemValorizationDetailsContainerProps
  extends Pick<
    ItemValorizationDetailsProps,
    'item' | 'directPurchaseFactor' | 'timezone'
  > {
  deal: Deal
  businessUnit: EBusinessUnit
}

export function ItemValorizationDetailsContainer(
  props: ItemValorizationDetailsContainerProps,
) {
  return <ItemValorizationDetails {...props} />
}

export default ItemValorizationDetailsContainer
