import styled from '@emotion/styled'
import { Box } from '@material-ui/core'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import HighlightOff from '@material-ui/icons/HighlightOff'
import { get } from 'lodash'
import { useMemo } from 'react'
import { AddressGroup } from '@/hooks/useCustomerMerging'

type Props = AddressGroup & {
  onSelectLeftValue?: () => void
  onSelectRightValue?: () => void
  onRemoveMergedValue?: () => void
}

export const MergeCustomerAddressGroup = ({
  rows,
  firstValue,
  secondValue,
  mergedValue,
  onSelectLeftValue,
  onSelectRightValue,
  onRemoveMergedValue,
}: Props): JSX.Element => {
  const showRemoveButton = useMemo(() => {
    return !!mergedValue
  }, [mergedValue])

  const showMergeButton = useMemo(() => {
    return (
      rows.some((r) => {
        return get(firstValue, r.fieldPath) !== get(secondValue, r.fieldPath)
      }) && !mergedValue
    )
  }, [rows, firstValue, secondValue, mergedValue])

  return (
    <Container>
      <Part flex={2}>
        {rows.map((r) => {
          return <Label key={r.fieldPath}>{r.label}</Label>
        })}
      </Part>

      <Part flex={1}>
        {rows.map((r) => {
          return (
            <UnderlinedLabel key={r.fieldPath}>
              {get(firstValue, r.fieldPath)}
            </UnderlinedLabel>
          )
        })}
      </Part>

      <Part flex={0.3} justifyContent="center" alignItems="center">
        {showMergeButton && <ArrowButton onClick={onSelectLeftValue} />}
      </Part>

      <Part flex={1}>
        {rows.map((r) => {
          return (
            <MergedLabel key={r.fieldPath}>
              {get(mergedValue, r.fieldPath)}
              {showRemoveButton && <RemoveIcon onClick={onRemoveMergedValue} />}
            </MergedLabel>
          )
        })}
      </Part>

      <Part flex={0.3} justifyContent="center" alignItems="center">
        {showMergeButton && (
          <ArrowButton
            style={{ rotate: '180deg' }}
            onClick={onSelectRightValue}
          />
        )}
      </Part>

      <Part flex={1}>
        {rows.map((r) => {
          return (
            <UnderlinedLabel key={r.fieldPath}>
              {get(secondValue, r.fieldPath)}
            </UnderlinedLabel>
          )
        })}
      </Part>
    </Container>
  )
}

const Container = styled(Box)`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

const Part = styled(Box)<{ flex?: number }>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
`

const Label = styled(Box)`
  height: 25px;
  line-height: 25px;
  color: #b8b8b8;
  font-size: 12px;
  word-break: break-all;
  overflow: hidden;
  margin-bottom: 4px;
`

const UnderlinedLabel = styled(Label)`
  border-bottom: solid 1px #e8e8e8;
`

const MergedLabel = styled(UnderlinedLabel)`
  color: black;
  border-bottom: solid 1px #000000;
  position: relative;
  display: flex;
  flex-direction: column;
`

const ArrowButton = styled(ArrowRightAlt)`
  color: green;
  cursor: pointer;
  &:hover {
    color: #683ab7c0;
  }
`

const RemoveIcon = styled(HighlightOff)`
  position: absolute;
  right: 0;
  top: 0;
  color: red;
  cursor: pointer;
  &:hover {
    color: #ff8383;
  }
`
