import { Box, Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Modal from '@/components/Modal'

interface ConfirmPopupProps {
  open: boolean
  onClose: () => void
  onSave: () => void
}

export const ProductPublishConfirmPopup = ({
  text,
  open,
  onClose,
  onSave,
}: ConfirmPopupProps & { text: string }) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose} fullWidth maxWidth="md">
      <Typography>{text}</Typography>
      <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <Button
          style={{ marginRight: 20 }}
          onClick={() => onClose()}
          variant="outlined"
        >
          {t('cancel')}
        </Button>
        <Button onClick={() => onSave()} variant="contained">
          {t('save')}
        </Button>
      </Box>
    </Modal>
  )
}
