import AmountInput from '../AmountInput'
import Countries from '../Countries'
import TextInput from '../TextInput'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IAddress } from '@/schemaTypes'

export interface AddressProps {
  address: IAddress
  onChangeAddress: (address: IAddress) => void
}

const Address = (props: AddressProps) => {
  const { onChangeAddress, address } = props
  const { t } = useTranslation()

  return (
    <div style={{ width: '100%' }}>
      <TextInput
        value={address.street ?? ''}
        onChange={(value) => onChangeAddress({ ...address, street: value })}
        label={t('address.street')}
        fullWidth
        inputWidth="50%"
      />
      <TextInput
        value={address.houseNumber ?? ''}
        onChange={(value) =>
          onChangeAddress({ ...address, houseNumber: value })
        }
        label={t('address.house_number')}
        fullWidth
        inputWidth="50%"
      />
      <TextInput
        value={address.city ?? ''}
        onChange={(value) => onChangeAddress({ ...address, city: value })}
        label={t('address.city')}
        fullWidth
        inputWidth="50%"
      />
      <TextInput
        value={address.zipCode ?? ''}
        onChange={(value) => onChangeAddress({ ...address, zipCode: value })}
        label={t('address.zip_code')}
        fullWidth
        inputWidth="50%"
      />
      <TextInput
        value={address.stairway ?? ''}
        onChange={(value) => onChangeAddress({ ...address, stairway: value })}
        label={t('address.stairway')}
        fullWidth
        inputWidth="50%"
      />
      <TextInput
        value={address.floor ?? ''}
        onChange={(value) => onChangeAddress({ ...address, floor: value })}
        label={t('address.floor')}
        fullWidth
        inputWidth="50%"
      />
      <TextInput
        value={address.door ?? ''}
        onChange={(value) => onChangeAddress({ ...address, door: value })}
        label={t('address.door')}
        fullWidth
        inputWidth="50%"
      />
      <TextInput
        value={address.state ?? ''}
        onChange={(value) => onChangeAddress({ ...address, state: value })}
        label={t('address.state')}
        fullWidth
        inputWidth="50%"
      />
      <TextInput
        value={address.queryString ?? ''}
        onChange={(value) =>
          onChangeAddress({ ...address, queryString: value })
        }
        label={t('query_string')}
        fullWidth
        inputWidth="50%"
      />
      <TextInput
        value={address.address1 ?? ''}
        onChange={(value) => onChangeAddress({ ...address, address1: value })}
        label={t('address.address_1')}
        fullWidth
        inputWidth="50%"
      />
      <TextInput
        value={address.address2 ?? ''}
        onChange={(value) => onChangeAddress({ ...address, address2: value })}
        label={t('address.address_2')}
        fullWidth
        inputWidth="50%"
      />
      <AmountInput
        value={address.coordinates?.lat || 0}
        onChange={(value) => {
          const updatedAddress = address
          const updatedCoordinates = {
            lat: updatedAddress.coordinates?.lat || 0,
            lng: updatedAddress.coordinates?.lng || 0,
          }
          updatedCoordinates.lat =
            typeof value === 'number' && !Number.isNaN(value) ? value : 0
          onChangeAddress({ ...address, coordinates: updatedCoordinates })
        }}
        label={t('latitude')}
        inputWidth="50%"
      />
      <AmountInput
        value={address.coordinates?.lng || 0}
        onChange={(value) => {
          const updatedAddress = address
          const updatedCoordinates = {
            lat: updatedAddress.coordinates?.lat || 0,
            lng: updatedAddress.coordinates?.lng || 0,
          }
          updatedCoordinates.lng =
            typeof value === 'number' && !Number.isNaN(value) ? value : 0
          onChangeAddress({ ...address, coordinates: updatedCoordinates })
        }}
        label={t('longitude')}
        inputWidth="50%"
      />
      <Countries
        value={address.countryCode}
        onChange={(value) =>
          onChangeAddress({ ...address, countryCode: value })
        }
        label={t('address.country')}
      />
      <TextInput
        multiline={true}
        value={address.note ?? ''}
        onChange={(value) => onChangeAddress({ ...address, note: value })}
        label={t('note')}
        inputWidth="50%"
      />
    </div>
  )
}

export default Address
