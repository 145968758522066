import withFieldWrapper from '../withFieldWrapper/withFieldWrapper'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { MultiSelectProps } from 'primereact/multiselect'
import { MultiSelect } from 'primereact/multiselect'

interface InputMultiSelectProps extends MultiSelectProps {
  isInvalid?: boolean
}

const InputMultiSelect = withFieldWrapper(
  ({ isInvalid, ...props }: InputMultiSelectProps) => {
    return (
      <DropdownStyled
        className={classNames({ 'p-invalid': isInvalid })}
        {...props}
      />
    )
  },
)

const DropdownStyled = styled(MultiSelect)`
  height: 2.375rem;
  &&& {
    .p-inputtext {
      padding: 0.56rem;
      font-size: 0.875rem;
    }
    .p-multiselect-label {
      padding: 0.25rem;

      > div {
        font-size: 0.625rem;
        padding: 0.25rem 0.5rem;
        margin-right: 0.25rem;
      }
    }
  }
`

export default InputMultiSelect
