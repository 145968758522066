import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeleteLoanToValuesEntryArgs } from '@/schemaTypes'

const DELETE_LOAN_TO_VALUES_ENTRY = gql`
  mutation deleteLoanToValuesEntry($loanToValuesEntryId: ObjectId!) {
    deleteLoanToValuesEntry(loanToValuesEntryId: $loanToValuesEntryId)
  }
`

type TData = { deleteLoanToValuesEntry: Mutation['deleteLoanToValuesEntry'] }
type TVariables = MutationDeleteLoanToValuesEntryArgs

export function useDeleteLoanToValuesEntry(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deleteLoanToValuesEntry] = useMutation<TData, TVariables>(
    DELETE_LOAN_TO_VALUES_ENTRY,
    opts,
  )

  return deleteLoanToValuesEntry
}
