import { ProductLookVariant, ProductVariant } from '../interfaces'
import { groupBy } from 'lodash'
import { useContext, useMemo } from 'react'
import { Context } from '@/context'
import { EProductPropertyType } from '@/schemaTypes'

export const useGetLookVariants = (variants: ProductVariant[]) => {
  const { language } = useContext(Context)

  const lookVariants = useMemo<{
    error: string | null
    lookVariants: Array<ProductLookVariant>
  }>(() => {
    if (!variants) {
      return {
        error: null,
        lookVariants: [],
      }
    }
    const groupedLookVariants = Object.entries(
      groupBy(variants, (variant: ProductVariant) => {
        const values = variant.propertiesValues
          .filter((v) => v.propertyDefinition.impactsLook)
          .map((v) => v.value)
        return values
          .sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)))
          .map((value) => JSON.stringify(value))
      }),
    )
      .filter(([key]) => key)
      .map(([_key, value]) => value)

    // The same variants should have the same imageId
    const invalidImagesError =
      groupedLookVariants.length &&
      groupedLookVariants.every((variants) => {
        const ids = variants.map((v) => v.imageId).filter((id) => !!id)
        return ids.length > 0 && !ids.every((id) => id === ids[0])
      })

    const lookVariants = groupedLookVariants.map((variants) => {
      const name = variants[0].propertiesValues
        .filter((v) => v.propertyDefinition.impactsLook)
        .map((v) => {
          if (v.propertyDefinitionType === EProductPropertyType.Translation) {
            return v.value?.[language]?.toString() ?? null
          }

          if (
            v.propertyDefinitionType === EProductPropertyType.Number ||
            v.propertyDefinitionType === EProductPropertyType.Boolean
          ) {
            return v.value?.toString() ?? null
          }

          throw new Error('Invalid property type')
        })

      return {
        imageId: variants[0]?.imageId ?? null, // Variants in one look variant should have the same imageId and uppyFileObject
        imageUrl: variants[0]?.imageUrl ?? null,
        uppyFileObject: variants[0]?.uppyFileObject,
        variantPropertyValueIds: variants.map((variant) =>
          variant.propertiesValues.map((v) => {
            if (
              v.propertyDefinitionType === EProductPropertyType.Translation ||
              v.propertyDefinitionType === EProductPropertyType.Number
            ) {
              return v.valueId
            }

            if (v.propertyDefinitionType === EProductPropertyType.Boolean) {
              return v.value
            }

            throw new Error('Invalid property type')
          }),
        ),
        name,
      }
    })

    return {
      error: invalidImagesError
        ? 'PRODUCT_VARIANTS_AND_IMAGES_VALIDATION_ERROR'
        : null,
      lookVariants,
    }
  }, [variants, language])

  return lookVariants
}
