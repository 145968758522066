import { Checkbox } from '@material-ui/core'
import React, { ComponentProps, useState } from 'react'
import { ReactSVG } from 'react-svg'
import SaveIcon from '@/images/save.svg'
import styles from './Item.module.scss'

export interface CheckBoxRowProps {
  label: string
  value: boolean
  onChange: (value: boolean) => void
  checkBoxProps?: ComponentProps<typeof Checkbox>
  hasAsterisk?: boolean
}

const CheckBoxRow: React.FC<CheckBoxRowProps> = ({
  label,
  value,
  onChange,
  checkBoxProps,
  hasAsterisk,
}) => {
  const [checked, setChecked] = useState(value)

  return (
    <tr style={{ width: '100%' }}>
      <td style={{ width: '40%' }}>
        <p>
          {label}
          {hasAsterisk && <span className={styles.alert}>*</span>}:
        </p>
      </td>
      <td style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          id="anlagegold"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
          {...(checkBoxProps ?? {})}
        />
        <ReactSVG
          className={`${styles.saveIcon} u-pl-15`}
          src={SaveIcon}
          onClick={() => onChange(checked)}
        />
      </td>
    </tr>
  )
}

export default CheckBoxRow
