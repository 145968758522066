import {
  useAddStorageFacilityId,
  useGetActiveStorageFacilities,
} from '../../storageFacilities/hooks'
import {
  TData,
  TVariables,
  useRemoveStorageFacilityId,
  useSetMainStorageFacilityId,
} from '../hooks'
import { ApolloQueryResult } from '@apollo/client'
import styled from '@emotion/styled'
import { Button, Typography } from '@material-ui/core'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import StarIcon from '@material-ui/icons/Star'
import StarOutlineIcon from '@material-ui/icons/StarBorderOutlined'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectOptionInput from '@/components/SelectOptionInput'
import { useMutationShowingErrors } from '@/hooks'
import { Shop } from '@/schemaTypes'

export interface ShopsStorageConfigurationProps {
  shop?: Partial<Shop>
  inputWidth?: string
  shopRefetch?: (
    variables?: Partial<TVariables> | undefined,
  ) => Promise<ApolloQueryResult<TData>>
}

export function ShopStorageConfiguration(
  props: ShopsStorageConfigurationProps,
) {
  const { shop, inputWidth, shopRefetch } = props
  const { t } = useTranslation()

  const [storageId, setStorageId] = useState('')

  const { storageFacilities } = useGetActiveStorageFacilities()

  const addStorageFacilityId = useMutationShowingErrors({
    mutation: useAddStorageFacilityId(),
    successMessage: t('shop_storage_facility_added'),
  })

  const removeStorageFacilityId = useMutationShowingErrors({
    mutation: useRemoveStorageFacilityId(),
    successMessage: t('shop_storage_facility_removed'),
  })

  const setMainStorageFacilityId = useMutationShowingErrors({
    mutation: useSetMainStorageFacilityId(),
    successMessage: t('set_main_shop_storage_facility'),
  })

  const onAddStorageFacilityId = () => {
    addStorageFacilityId({
      variables: {
        shopId: shop?._id,
        storageFacilityId: storageId,
      },
      onCompleted: () => {
        setStorageId('')
        shopRefetch && shopRefetch()
      },
    })
  }

  const onRemoveStorageFacilityId = (id: string) => {
    const isMainStorage = shop?.mainStorageFacilityId === id

    removeStorageFacilityId({
      variables: {
        shopId: shop?._id,
        storageFacilityId: id,
      },
      onCompleted: () => {
        if (isMainStorage) onSetMainStorageFacilityId(id)
        else {
          shopRefetch && shopRefetch()
        }
      },
    })
  }

  const onSetMainStorageFacilityId = (id: string) => {
    const mainId = id === shop?.mainStorageFacilityId ? null : id
    setMainStorageFacilityId({
      variables: {
        shopId: shop?._id,
        storageFacilityId: mainId,
      },
      onCompleted: () => {
        shopRefetch && shopRefetch()
      },
    })
  }

  const storageFacilitiesOptions = useMemo(
    () =>
      (storageFacilities || []).map((s) => ({ label: s.name, value: s._id })),
    [storageFacilities],
  )

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('storage_configuration')}
      </Typography>
      <AddStorageDiv>
        <SelectOptionInput
          value={storageId}
          onChange={setStorageId}
          label={t('add_storage_location')}
          menuItems={storageFacilitiesOptions}
          inputWidth={'100%'}
          style={{ width: inputWidth }}
        />
        <Button variant="outlined" onClick={onAddStorageFacilityId}>
          {t('add')}
        </Button>
      </AddStorageDiv>
      <div className="u-mb-10">{t('added_storages')}</div>
      {shop?.storageFacilities?.map((s) => (
        <AddedStorageDiv>
          <StorageNameDiv>{s.name}</StorageNameDiv>
          <ButtonIcon onClick={() => onSetMainStorageFacilityId(s._id)}>
            {s._id === shop.mainStorageFacilityId ? (
              <CustomStarIcon />
            ) : (
              <CustomStarOutlineIcon />
            )}
          </ButtonIcon>
          <ButtonIcon onClick={() => onRemoveStorageFacilityId(s._id)}>
            <RemoveCircleOutlineIcon />
          </ButtonIcon>
        </AddedStorageDiv>
      ))}
    </>
  )
}

const AddStorageDiv = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1rem;
  margin-bottom: 1rem;
`

const AddedStorageDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`

const CustomStarIcon = styled(StarIcon)`
  color: #f9b300;
`

const CustomStarOutlineIcon = styled(StarOutlineIcon)`
  color: #949494;
`

const ButtonIcon = styled(Button)`
  &&& {
    padding: 0;
    min-width: auto;
  }
`

const StorageNameDiv = styled.div`
  font-weight: 500;
  margin-bottom: 0.5rem;
  width: 30%;
`

export default ShopStorageConfiguration
