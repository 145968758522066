import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateCustomDealFormArgs } from '@/schemaTypes'

const UPDATE_CUSTOM_DEAL_FORM = gql`
  mutation updateCustomDealForm(
    $customDealFormUpdateArgs: CustomDealFormUpdateArgs!
  ) {
    updateCustomDealForm(customDealFormUpdateArgs: $customDealFormUpdateArgs) {
      _id
    }
  }
`

type TData = { updateCustomDealForm: Mutation['updateCustomDealForm'] }
type TVariables = MutationUpdateCustomDealFormArgs

export function useUpdateCustomDealForm(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [mutation] = useMutation<TData, TVariables>(
    UPDATE_CUSTOM_DEAL_FORM,
    opts,
  )
  return mutation
}
