import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { cloneDeep } from 'lodash'
import { Fragment, useState } from 'react'
import { JsonTree } from 'react-editable-json-tree'
import { useTranslation } from 'react-i18next'
import EnhancedToolbar from '@/components/EnhancedToolbar'
import SelectOptionInput from '@/components/SelectOptionInput'
import TextInput from '@/components/TextInput'
import { EQuestionConditionValue, ItemQuestionOption } from '@/schemaTypes'
import styles from './ItemQuestionsConfiguration.module.scss'

export interface SingleChoiceOptionsTableProps {
  options: ItemQuestionOption[]
  onAdd: (option: ItemQuestionOption) => void
  onDelete: (options: ItemQuestionOption[]) => void
  onChange: (options: ItemQuestionOption[]) => void
}

const conditionValues = [
  { label: 'none', value: null },
  { label: 'excellent', value: EQuestionConditionValue.Excellent },
  { label: 'good', value: EQuestionConditionValue.Good },
  { label: 'poor', value: EQuestionConditionValue.Poor },
]

export default function SingleChoiceOptionsTable(
  props: SingleChoiceOptionsTableProps,
) {
  const { options, onAdd, onDelete, onChange } = props
  const { t } = useTranslation()

  const [selected, setSelected] = useState<ItemQuestionOption[]>([])
  const [option, setOption] = useState<ItemQuestionOption>({
    labelKey: '',
    categoryValues: {},
    conditionValue: conditionValues[0].value as EQuestionConditionValue,
    infoKey: '',
  })

  const handleClick = (option: ItemQuestionOption) => {
    const selectedIndex = selected.indexOf(option)
    let newSelected: ItemQuestionOption[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, option)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  const handleDeleteClick = (options: ItemQuestionOption[]) => {
    onDelete(options)
    setSelected([])
  }

  const isSelected = (option: ItemQuestionOption) =>
    selected.indexOf(option) !== -1

  const onUpdate = (index: number, option: ItemQuestionOption) => {
    const updatedOptions = cloneDeep(options) as ItemQuestionOption[]
    updatedOptions[index] = option
    onChange(updatedOptions)
  }

  const toggleIsDefault = (option: ItemQuestionOption) => {
    const updatedOptions = options.map((o) => {
      if (o === option) {
        return {
          ...o,
          isDefaultValue: !o.isDefaultValue,
        }
      }

      return {
        ...o,
        isDefaultValue: false,
      }
    })

    onChange(updatedOptions)
  }

  return (
    <Fragment>
      <TextInput
        value={option.labelKey}
        onChange={(value) => setOption({ ...option, labelKey: value })}
        label={t('label_key')}
      />
      <TextInput
        value={option.infoKey ?? ''}
        onChange={(value) =>
          setOption({ ...option, infoKey: value || undefined })
        }
        label={t('info_key')}
      />
      <SelectOptionInput
        value={option.conditionValue}
        onChangeNullable={(value) =>
          setOption({ ...option, conditionValue: value })
        }
        label={t('condition_value')}
        menuItems={conditionValues}
      />
      <JsonTree
        data={option.categoryValues}
        rootName={options.length + 1}
        onFullyUpdate={(value) =>
          setOption({ ...option, categoryValues: value })
        }
      />
      <Button
        className="u-mt-10"
        onClick={() => onAdd(option)}
        variant="contained"
        disabled={!option}
      >
        {t('add_new_option')}
      </Button>
      {options.length > 0 && (
        <TableContainer>
          <EnhancedToolbar
            selected={selected}
            onDelete={() => handleDeleteClick(selected)}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell
                  className={styles.categoryValuesTableCell}
                  padding="none"
                >
                  {t('category_values')}
                </TableCell>
                <TableCell padding="none">{t('default_value')}</TableCell>
                <TableCell padding="none">{t('label_key')}</TableCell>
                <TableCell padding="none">{t('info_key')}</TableCell>
                <TableCell padding="none">{t('condition_value')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {options.map((option, index) => {
                const isItemSelected = isSelected(option)
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onChange={() => handleClick(option)}
                      />
                    </TableCell>
                    <TableCell
                      className={styles.categoryValuesTableCell}
                      padding="none"
                    >
                      <JsonTree
                        data={cloneDeep(option.categoryValues)}
                        rootName={index.toString}
                        onFullyUpdate={(value) =>
                          onUpdate(index, { ...option, categoryValues: value })
                        }
                        readOnly={false}
                      />
                    </TableCell>
                    <TableCell padding="none">
                      <Checkbox
                        checked={!!option.isDefaultValue}
                        onChange={() => toggleIsDefault(option)}
                      />
                    </TableCell>
                    <TableCell padding="none">
                      <TextInput
                        value={option.labelKey}
                        onChange={(value) => {
                          onUpdate(index, { ...option, labelKey: value })
                        }}
                      />
                    </TableCell>
                    <TableCell padding="none">
                      <TextInput
                        value={option.infoKey ?? ''}
                        onChange={(value) =>
                          onUpdate(index, {
                            ...option,
                            infoKey: value || undefined,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell padding="none">
                      <SelectOptionInput
                        value={option.conditionValue}
                        onChangeNullable={(value) =>
                          onUpdate(index, { ...option, conditionValue: value })
                        }
                        menuItems={conditionValues}
                        defaultValue={conditionValues[0].value}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  )
}
