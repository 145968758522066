import styled from '@emotion/styled'
import { Button, IconButton, InputBase, Paper } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Loading from '@/components/Loading'
import { ProductProperyDefinitionPreview } from '@/domains/productPropertyDefinitions/components/ProductPropertyDefinitionPreview'
import { useGetProductPropertyDefinitions } from '@/domains/productPropertyDefinitions/hooks/getProductPropertyDefinitions'

export function ProductProperyDefinitionList() {
  const { t } = useTranslation()

  const history = useHistory()

  const [textSearch, setTextSearch] = useState<string | null>(null)

  const queryVariables = useMemo(
    () => ({
      opts: {
        filter: {
          text: textSearch,
        },
      },
    }),
    [textSearch],
  )

  const { productPropertyDefinitions, queryResult } =
    useGetProductPropertyDefinitions({
      variables: queryVariables,
    })

  return (
    <PageWrapper>
      <HeaderWrapper>
        <SearchBoxWrapper component="form">
          <IconButton style={{ padding: 10 }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            style={{ marginLeft: 20, width: '80%' }}
            placeholder="Search Property Configurations"
            inputProps={{
              'aria-label': 'search product property configurations',
            }}
          />
        </SearchBoxWrapper>

        <CreateButton
          onClick={() =>
            history.push(`/inApp/product-property-definitions/create`)
          }
        >
          {t('product_property_definition.title_new')}
        </CreateButton>
      </HeaderWrapper>

      {queryResult.loading ? (
        <Loading />
      ) : (
        <ListWrapper>
          {productPropertyDefinitions?.map((p) => (
            <div
              style={{
                display: 'inline-block',
                width: '32%',
                minWidth: '320px',
                minHeight: 250,
              }}
            >
              <ProductProperyDefinitionPreview productPropertyDefinition={p} />
            </div>
          ))}
        </ListWrapper>
      )}
    </PageWrapper>
  )
}

const HeaderWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: 40px;
  align-items: center;
`

const SearchBoxWrapper = styled(Paper)`
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 500px;
  border-radius: 100px;
`

const CreateButton = styled(Button)`
  &&& {
    background: linear-gradient(
        0deg,
        rgba(103, 80, 164, 0.05),
        rgba(103, 80, 164, 0.05)
      ),
      #fffbfe;
    height: 34px;
    width: 200px;
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    font-size: 12px !important;
    color: #6750a4;
    margin-left: 20px;
  }
`

const PageWrapper = styled.div`
  padding-top: 40px;
  width: 100%;
  padding-left: 8%;
  padding-right: 8%;
`

const ListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 30px;
`
