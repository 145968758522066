import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import { Deal, EDealType } from '@/schemaTypes'
import { getLatestEventForStatus } from '@/utils/deal'

dayjs.extend(utc)

export function useModifyDealItemsWithWarning(deal: Deal) {
  const { showConfirmModal, closeConfirmModal } = useContext(Context)

  const { t } = useTranslation()

  let warn = ''

  if (getLatestEventForStatus(deal, 'DealClosedEvent')) {
    warn =
      deal.dealType === EDealType.Purchase
        ? t('items_edited_closed_warn_purchase')
        : t('items_edited_closed_warn_pawn')
  } else if (getLatestEventForStatus(deal, 'DealVerifiedEvent')) {
    warn =
      deal.dealType === EDealType.Purchase
        ? t('items_edited_verified_warn_purchase')
        : t('items_edited_verified_warn_pawn')
  } else {
    warn =
      deal.dealType === EDealType.Purchase
        ? t('items_edited_booked_warn_purchase')
        : t('items_edited_booked_warn_pawn')
  }

  warn = `${warn} ${t('pdf_will_be_regenerated')}`

  const modifyDealItemsWithWarning = useCallback(
    (continueAction: () => any) => {
      showConfirmModal({
        title: warn,
        leftButtonText: t('abort'),
        rightButtonText: t('continue'),
        leftButtonAction: () => {
          closeConfirmModal()
        },
        rightButtonAction: () => {
          closeConfirmModal()
          continueAction()
        },
      })
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [warn],
  )

  return modifyDealItemsWithWarning
}
