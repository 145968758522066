import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import CompaniesRow from '@/domains/companies/components/CompaniesRow'
import {
  useDeactivateCompany,
  useGetActiveCompanies,
} from '@/domains/companies/hooks'
import styles from '@/domains/companies/styles/CompaniesConfiguration.module.scss'
import { useConfirmDeleteMutationShowingErrors } from '@/hooks'

export function CompaniesTable() {
  const { t } = useTranslation()

  const { companies, queryResult } = useGetActiveCompanies()

  const deleteCompany = useConfirmDeleteMutationShowingErrors({
    successMessage: t('company.company_deleted'),
    mutation: useDeactivateCompany(),
  })

  const _handleDeleteClick = async (id: string) => {
    deleteCompany({
      variables: {
        companyId: id,
      },
      onCompleted: () => {
        queryResult.refetch()
      },
    })
  }

  return (
    <Paper className={`${styles.root} u-mb`}>
      <ConfigurationBreadcrumbs modelName={'company.plural'} />
      <Typography variant="h5" gutterBottom>
        {t('company.companies_configurations')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Table className="u-mb-sm" size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('id')}</TableCell>
                <TableCell align="right">{t('name')}</TableCell>
                <TableCell align="right">{t('uid')}</TableCell>
                <TableCell align="right">
                  {t('company.company_number')}
                </TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(companies).map((cKey) => (
                <CompaniesRow
                  key={cKey}
                  company={companies[cKey]}
                  deleteCompany={() => _handleDeleteClick(companies[cKey]._id)}
                />
              ))}
            </TableBody>
          </Table>
          <Link to="/inApp/configurations/companies/create">
            <Button className="u-mr-mini" variant="contained">
              {t('company.company_create')}
            </Button>
          </Link>
        </Fragment>
      )}
    </Paper>
  )
}

export default CompaniesTable
