import withFieldWrapper from '../withFieldWrapper/withFieldWrapper'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { InputText, InputTextProps } from 'primereact/inputtext'

interface InputProps extends InputTextProps {
  isInvalid?: boolean
}

const Input = withFieldWrapper(({ isInvalid, value, ...props }: InputProps) => {
  return (
    <InputTextStyled
      value={value || ''}
      className={classNames({ 'p-invalid': isInvalid })}
      {...props}
    />
  )
})

const InputTextStyled = styled(InputText)`
  font-size: 0.875rem;
  height: 2.375rem;
  padding: 0.66rem;
`

export default Input
