import React from 'react'
import { Note, NoteCreateArgs, NoteUpdateArgs } from '@/schemaTypes'
import CreateNote from './CreateNote'
import NoteList from './NoteList'

interface INoteContainerProps {
  notes: Note[]
  onAddNote: (note: NoteCreateArgs) => void
  onUpdateNote: (noteId: string | number, note: NoteUpdateArgs) => void
  horizontalMode?: boolean
}

const NoteContainer = ({
  notes,
  onAddNote,
  onUpdateNote,
  horizontalMode,
}: INoteContainerProps) => {
  return (
    <>
      <NoteList
        notes={notes}
        onUpdateNote={onUpdateNote}
        horizontalMode={horizontalMode}
      />
      <CreateNote horizontalMode={horizontalMode} onAddNote={onAddNote} />
    </>
  )
}

export default NoteContainer
