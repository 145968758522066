import styled from '@emotion/styled'
import { Button, Paper, Typography } from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactJson from 'react-json-view'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectOptionInput from '@/components/SelectOptionInput'
import { useStartAuction } from '@/domains/auctions/hooks/auctionDetails/startAuction'
import { useGetActiveCompanies } from '@/domains/companies/hooks'
import { useGetShops } from '@/domains/shops/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { Auction, EAuctionItemStatus, EAuctionStatus } from '@/schemaTypes'
import styles from './AuctionGeneralInfo.module.scss'

interface Props {
  isEditable?: boolean
  auction?: Auction
  setAuctionDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>
  setShopId: React.Dispatch<React.SetStateAction<string | null>>
  companyId: string | null
  setCompanyId: React.Dispatch<React.SetStateAction<string | null>>
  auctionDate: dayjs.Dayjs
  shopId: string | null
  onCloseAuction?: (id: string) => void
  closingAuctionInProgress?: boolean
}

const AuctionGeneralInfo = ({
  isEditable,
  auction,
  setAuctionDate,
  setShopId,
  companyId,
  setCompanyId,
  auctionDate,
  shopId,
  onCloseAuction,
  closingAuctionInProgress,
}: Props) => {
  const { t } = useTranslation()

  const { companies } = useGetActiveCompanies()
  const [startingAuctionInProgress, setStartingAuctionInProgress] =
    useState(false)

  const { shops } = useGetShops({
    variables: {
      opts: {
        filter: {
          companyId: {
            value: companyId,
          },
        },
      },
    },
    skip: !companyId,
  })

  const startAuction = useMutationShowingErrors({
    mutation: useStartAuction(),
    successMessage: `${t('auction.auction_updated_successfully')}!`,
  })

  const closeErrorData = useMemo(() => {
    let data = null
    let isObject = false

    if (!auction?.closeError?.data) {
      return {
        data,
        isObject,
      }
    }

    try {
      data = JSON.parse(auction.closeError.data)
      isObject = true
    } catch (error) {
      data = auction.closeError.data
    }

    return {
      data,
      isObject,
    }
  }, [auction?.closeError])

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Typography variant="h6" gutterBottom style={{ fontSize: 24 }}>
        {t('auction.auction_general_info')}
      </Typography>

      <div style={{ marginTop: 20 }}>
        <SelectOptionInput
          value={companyId}
          onChange={(value) => {
            setCompanyId(value)
            setShopId(null)
          }}
          disabled={!isEditable}
          label={
            <div style={{ width: 150, float: 'left', fontWeight: 500 }}>
              {t('company.singular')}
            </div>
          }
          menuItems={companies.map((company) => ({
            label: company.name,
            value: company._id,
          }))}
        />
        <SelectOptionInput
          value={shopId}
          disabled={!isEditable}
          onChange={(value) => setShopId(value)}
          label={
            <div style={{ width: 150, float: 'left', fontWeight: 500 }}>
              {t('auction.auction_location')}
            </div>
          }
          menuItems={shops.map((shop) => ({
            label: shop.name,
            value: shop._id,
          }))}
        />
        {auction?.status !== EAuctionStatus.Started && (
          <AuctionGeneralInfoItemWrapper>
            <div style={{ width: 160, fontWeight: 500 }}>
              {t('auction.auction_date')}
            </div>
            {isEditable ? (
              <KeyboardDateTimePicker
                className={`${styles.datePicker} u-ml-5`}
                clearable
                disablePast
                maxDate={dayjs(Date()).add(7, 'day').toDate()}
                format="DD.MM.YYYY HH:mm"
                ampm={false}
                value={dayjs(auctionDate) ?? null}
                onChange={(value) => setAuctionDate(value)}
              />
            ) : (
              <span>{dayjs(auctionDate).format('DD.MM.YYYY').toString()}</span>
            )}
          </AuctionGeneralInfoItemWrapper>
        )}
        {auction && (
          <>
            {auction.status !== EAuctionStatus.Started && (
              <AuctionGeneralInfoItemWrapper>
                <div style={{ width: 160, fontWeight: 500 }}>
                  {t('deal.number_of_deals')}
                </div>
                <Typography>
                  {' '}
                  {auction.auctionElements.length} Deals{' '}
                </Typography>
              </AuctionGeneralInfoItemWrapper>
            )}

            <AuctionGeneralInfoItemWrapper>
              <div style={{ width: 160, fontWeight: 500 }}>
                {t('auction.auction_state')}
              </div>
              <Typography
                style={{
                  fontWeight: 600,
                  color:
                    auction.status === EAuctionStatus.Started
                      ? '#2ECC71'
                      : [
                            EAuctionStatus.Open,
                            EAuctionStatus.ClosingInProgress,
                          ].includes(auction.status)
                        ? 'black'
                        : 'red',
                }}
              >
                {' '}
                {auction.status?.replace(/_/g, ' ')}{' '}
              </Typography>
            </AuctionGeneralInfoItemWrapper>
            {auction.status === EAuctionStatus.Started && (
              <div style={{ marginTop: '1rem' }}>
                <div>
                  {auction?.auctionAnnouncementLink && (
                    <a
                      href={auction?.auctionAnnouncementLink?.url}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.documentLink}
                    >
                      {auction?.auctionAnnouncementLink?.url && (
                        <span>
                          {auction?.auctionAnnouncementLink?.filename}
                        </span>
                      )}
                    </a>
                  )}
                </div>
                <div>
                  {auction?.internalAuctionAnnouncementLink && (
                    <a
                      href={auction?.internalAuctionAnnouncementLink?.url}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.documentLink}
                    >
                      {auction?.internalAuctionAnnouncementLink?.url && (
                        <span>
                          {auction?.internalAuctionAnnouncementLink?.filename}
                        </span>
                      )}
                    </a>
                  )}
                </div>
              </div>
            )}
            {auction._id && (
              <AuctionGeneralInfoItemWrapper style={{ marginTop: 5 }}>
                <div style={{ width: 160, fontWeight: 500 }} />
                {auction.status === EAuctionStatus.Open && (
                  <>
                    <CreateButton
                      disabled={startingAuctionInProgress}
                      onClick={() => {
                        setStartingAuctionInProgress(true)
                        startAuction({
                          variables: {
                            auctionId: auction._id,
                          },
                          refetchQueries: ['getAuctionById'],
                          onCompleted: () =>
                            setStartingAuctionInProgress(false),
                        })
                      }}
                    >
                      {t('start_auction')}
                    </CreateButton>
                    {startingAuctionInProgress && (
                      <div className="u-ml-sm u-inline-block">
                        <LoadingSpinner />
                      </div>
                    )}
                  </>
                )}
                {[
                  EAuctionStatus.Started,
                  EAuctionStatus.ClosingInProgress,
                  EAuctionStatus.CloseError,
                ].includes(auction.status) &&
                  onCloseAuction &&
                  auction.items?.every(
                    (item) =>
                      item.status === EAuctionItemStatus.Sold &&
                      (item.buyerName
                        ? item.buyer?.type && item.surchargeConfirmationLink
                        : true),
                  ) && (
                    <>
                      <CreateButton
                        disabled={
                          auction.status === EAuctionStatus.ClosingInProgress
                        }
                        onClick={() => onCloseAuction(auction._id)}
                      >
                        {auction.status === EAuctionStatus.ClosingInProgress
                          ? t('auction.closing_in_progress')
                          : t('auction.close_auction')}
                      </CreateButton>
                      {closingAuctionInProgress && (
                        <div className="u-ml-sm u-inline-block">
                          <LoadingSpinner />
                        </div>
                      )}
                    </>
                  )}
              </AuctionGeneralInfoItemWrapper>
            )}
            <>
              {auction.closeError && (
                <div style={{ marginLeft: 160, marginTop: 10 }}>
                  {auction.closeError.message && (
                    <Typography color="error">
                      {auction.closeError.message}
                    </Typography>
                  )}
                  {auction.closeError.data && (
                    <div style={{ marginTop: 10 }}>
                      {closeErrorData.isObject ? (
                        <ReactJson
                          displayDataTypes={false}
                          displayObjectSize={false}
                          src={closeErrorData.data}
                          enableClipboard={false}
                        />
                      ) : (
                        <Typography color="error">
                          {closeErrorData.data}
                        </Typography>
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          </>
        )}
      </div>
    </Paper>
  )
}

const AuctionGeneralInfoItemWrapper = styled.div`
  display: flex;
  justify-content: left;
  margin-top: 20px;
`

const CreateButton = styled(Button)<{ disabled?: boolean }>`
  &&& {
    background: linear-gradient(
        0deg,
        rgba(103, 80, 164, 0.05),
        rgba(103, 80, 164, 0.05)
      ),
      #6750a4;
    height: 30px;
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    font-size: 12px !important;
    color: #ffffff !important;

    ${({ disabled }) =>
      disabled
        ? 'background: #e0e0e0 !important; color: #807d7d !important; font-weight: 700'
        : ''}
  }
`

export default AuctionGeneralInfo
