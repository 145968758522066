import { verifyToken } from '../../utils/jwt'
import { logoutCurrentUser, setCurrentUser } from '../actions/user'
import { createReducer } from '@reduxjs/toolkit'
import { Employee } from '@/schemaTypes'

const token = verifyToken(window.localStorage.getItem('token') || '')
  ? window.localStorage.getItem('token')
  : null

interface UserState {
  token: string | null
  current: Employee | null
}

const initialState: UserState = {
  token,
  current: null,
}

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCurrentUser, (state, action) => {
      window.localStorage.setItem('token', action.payload.token || '')
      window.localStorage.setItem('user', JSON.stringify(action.payload))
      state.current = action.payload
      state.token = action.payload.token as string
    })
    .addCase(logoutCurrentUser, (state) => {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
      state.token = null
      state.current = null
    })
})

export default userReducer
