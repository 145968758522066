import { Button, makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import WarningIcon from '@material-ui/icons/Warning'
import { round, sumBy } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AuctionElement, Deal, DealItem } from '@/schemaTypes'
import {
  assertDealConnectedToRegisteredCustomer,
  calculateLiquidationFee,
  getTotalFeeAmountOfItem,
} from '@/utils/deal'
import { displayLocalAmount, paymentTypeUnion } from '@/utils/misc'
import styles from './AuctionElementComponent.module.scss'
import AuctionElementCustomerOverView from './AuctionElementCustomerOverView'
import AuctionElementDealItemsOverView from './AuctionElementDealItemsOverView'
import AuctionElementDealOverView from './AuctionElementDealOverView'

export interface ElementProps {
  isEditable: boolean
  auctionElement: AuctionElement
  onDelete: (itemId) => void
  deal: Deal
}

export interface IMinCashyPrice {
  itemId: string
  minPrice: number | string
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

const Element: React.FC<ElementProps> = ({
  isEditable,
  auctionElement,
  onDelete,
  deal,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()

  assertDealConnectedToRegisteredCustomer(deal)

  const feeData = useMemo(() => calculateFeeData(deal), [deal])
  const minCashyPrices = useMemo(() => calculateMinCashyPrices(deal), [deal])
  const buybackData = deal?.pawnData?.buyBackData
  const hasBuybackData = Boolean(buybackData)
  const buybackPaymentType = paymentTypeUnion(
    deal.pawnData?.paybackData?.paymentType,
  )

  const hasWarning = (deal: Deal) => {
    if (deal.events.some((event) => event.__typename === 'DealOnSellEvent')) {
      const items = deal.items.filter((item) => item.internalSaleData === null)
      if (items.length > 0) {
        return 1
      } else return 0
    } else return -1
  }

  return (
    <React.Fragment>
      <TableRow style={{ backgroundColor: '#ede7f8' }} className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Link to={`/inApp/deals/${auctionElement.dealId}`} target="blank">
            {auctionElement.bookingNumber}
          </Link>
        </TableCell>
        <TableCell align="center">
          {deal?.shop ? deal.shop.name : '-'}
        </TableCell>
        {!isEditable && (
          <TableCell>
            {hasBuybackData ? (
              <div>
                <Tooltip title={t('reserve_buyback_is_valid')}>
                  <p>
                    {'Reserve Data:'}{' '}
                    <DoneIcon className={`${styles.checkReserve} u-ml-sm`} />
                  </p>
                </Tooltip>

                {buybackData.isPawnAgain ? (
                  <Tooltip
                    title={t('reserve_buy_back_with_pawn_again_description')}
                  >
                    <p>
                      {'Pawn Again: '}
                      <DoneIcon
                        className={`${styles.checkPawnAgain} u-ml-sm`}
                      />
                    </p>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={t(
                      'reserve_buy_back_with_out_pawn_again_description',
                    )}
                  >
                    <p>
                      {'Pawn Again: '}
                      <ClearIcon
                        className={`${styles.errorPawnAgain} u-ml-sm`}
                      />
                    </p>
                  </Tooltip>
                )}
              </div>
            ) : (
              ''
            )}
          </TableCell>
        )}
        {!isEditable && (
          <TableCell align="left" style={{ width: '16rem' }}>
            {hasBuybackData ? (
              <div>
                <p>
                  {buybackData.isPawnAgain
                    ? `${t('repurchase_fees')}: ${displayLocalAmount(buybackData.repurchaseFee ?? 0)}`
                    : `${t('total_repurchase_fees')}: ${displayLocalAmount(buybackData.totalRepurchaseFee ?? 0)}`}
                </p>
                {buybackData.isPawnAgain && (
                  <>
                    <p>
                      {t('partial_repayment')}:{' '}
                      {displayLocalAmount(buybackData.partialRepayment ?? 0)}
                    </p>
                    <p>
                      {t('gross_profit')}:{'   '}
                      {displayLocalAmount(buybackData.grossProfit ?? 0)}
                    </p>
                  </>
                )}
                {buybackPaymentType && (
                  <p>{`${t('payment_type')}: ${buybackPaymentType}`}</p>
                )}
              </div>
            ) : (
              ''
            )}
          </TableCell>
        )}
        <TableCell align="center">
          {deal ? displayLocalAmount(deal.dealFinalValues.payoutAmount) : '-'}
        </TableCell>
        <TableCell align="center">
          {feeData ? displayLocalAmount(feeData.fees) : '-'}
        </TableCell>
        <TableCell align="center">
          {feeData ? displayLocalAmount(feeData.paybackAmount) : '-'}
        </TableCell>
        <TableCell align="center">
          {feeData ? displayLocalAmount(feeData.liquidationFee) : '-'}
        </TableCell>
        <TableCell align="center">
          {feeData && feeData.summedCashPrices
            ? displayLocalAmount(feeData.summedCashPrices)
            : '-'}
        </TableCell>
        <TableCell align="center">
          {deal ? deal.events[deal.events.length - 1].displayName : '-'}
        </TableCell>
        <TableCell align="center">
          {deal ? (
            hasWarning(deal) === 1 ? (
              <Tooltip title={t('auction.auction_internal_sale_warning')}>
                <WarningIcon className={`${styles.warning} u-ml-sm`} />
              </Tooltip>
            ) : feeData &&
              !feeData.isAllCashyPrices &&
              feeData.summedCashPrices ? (
              <Tooltip title={t('auction.auction_has_cashy_price_warning')}>
                <WarningIcon
                  className={`${styles.warning} ${styles.warning_o} u-ml-sm`}
                />
              </Tooltip>
            ) : hasWarning(deal) === 0 ? (
              <DoneIcon className={`${styles.check} u-ml-sm`} />
            ) : (
              ''
            )
          ) : null}
        </TableCell>
        {isEditable ? (
          <TableCell align="center">
            <Button
              className={`${styles.button} u-ml-sm`}
              onClick={() => onDelete(auctionElement.dealId)}
            >
              <DeleteIcon />
            </Button>
          </TableCell>
        ) : null}
      </TableRow>
      <TableRow style={{ backgroundColor: '#ede7f8' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {deal ? <AuctionElementDealOverView deal={deal} /> : ''}
              {deal && deal.items ? (
                <AuctionElementDealItemsOverView
                  deal={deal}
                  itemsFromDeal={deal.items}
                  minCashyPrices={minCashyPrices}
                />
              ) : (
                ''
              )}
              <AuctionElementCustomerOverView customer={deal.customer} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const sumCashyPrices = (dealItems: DealItem[]) => ({
  summedCashPrices: sumBy(
    dealItems.filter((i) => i.internalSaleData?.isCashyPrice),
    (i) => i.internalSaleData?.price ?? 0,
  ),
  isAllCashyPrices: dealItems.every((i) => i.internalSaleData?.isCashyPrice),
})

const calculateFeeData = (deal: Deal) => {
  const { items, auctionDealCalculation } = deal
  if (!auctionDealCalculation) return
  const paybackAmount = auctionDealCalculation.dealValuesEntry.paybackAmount
  const fees =
    paybackAmount - auctionDealCalculation.dealValuesEntry.payoutAmount
  const liquidationFee = calculateLiquidationFee(
    paybackAmount,
    deal.company.configuration.liquidationFee,
  )
  return { fees, paybackAmount, liquidationFee, ...sumCashyPrices(items) }
}

const calculateMinCashyPrices = (deal: Deal) => {
  const { auctionDealCalculation } = deal
  if (!auctionDealCalculation) return []

  const minCashyPrices = deal.items.map((item): IMinCashyPrice => {
    const itemAppliedUsedFeeDefinitins =
      auctionDealCalculation.appliedUsedFeeDefinitions.find(
        (c) => c.storageLabel === item.storageLabel,
      )

    let minCashyPrices = 0

    if (itemAppliedUsedFeeDefinitins) {
      const weightFactor = itemAppliedUsedFeeDefinitins.feeWeight
      const itemWeightPayout = deal.dealFinalValues.payoutAmount * weightFactor
      const fees = getTotalFeeAmountOfItem(
        itemAppliedUsedFeeDefinitins.calculatedFeeDefinitions,
      )
      minCashyPrices = itemWeightPayout + fees
    }

    return {
      itemId: item._id,
      minPrice: round(minCashyPrices, 2),
    }
  })

  return minCashyPrices
}

export default Element
