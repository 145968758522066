import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { isEmpty, last } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PICEA_TEST_MINIMUM } from '@/constants'
import {
  CUSTOM_QUESTION_OPTICAL_CONDITION,
  QUESTION_OPTICAL_CONDITION,
} from '@/domains/deals/components/constants/enums'
import SectionTitle from '@/redesign/components/SectionTitle/SectionTitle'
import {
  Customer,
  Deal,
  DealItem,
  EBusinessUnit,
  ECountry,
  EDealType,
  VehicleData,
} from '@/schemaTypes'
import {
  getDealValueEntry,
  isDealConnectedToRegisteredCustomer,
} from '@/utils/deal'
import {
  isElectronic,
  isRequiredSerialNoOrImeiCategory,
  isUsedCondition,
  isVehicle,
} from '@/utils/misc'

interface VerificationChecklistProps {
  deal: Deal
}

interface CheckListField {
  name: keyof VehicleData
  transKey: string
  canBeFalse?: boolean
}

export const VERIFICATION_CHECKLIST_CONTAINER_ID = 'verification-checklist'

const VerificationChecklist = (props: VerificationChecklistProps) => {
  const { deal } = props
  const { t } = useTranslation()

  const checks: {
    isDisplayed: boolean
    element: string | JSX.Element
    links?: string[]
  }[] = getVerificationChecklist(deal, t)

  const failedChecks = checks.filter((check) => check.isDisplayed)

  if (failedChecks.length === 0) {
    return null
  }

  return (
    <div className="my-3.5" id={VERIFICATION_CHECKLIST_CONTAINER_ID}>
      <SectionTitle
        title={t('verification_checklist.verification_checklist')}
      />
      {failedChecks.map((check, i) => (
        <div key={i}>
          {check.links ? (
            check.links.map<React.ReactNode>((link, i) => {
              return (
                <div key={i} className="flex items-center mb-2">
                  <ChecklistMark className="flex items-center justify-center mr-2">
                    <i
                      className="pi pi-times "
                      style={{ color: '#fff', fontSize: '.55rem' }}
                    />
                  </ChecklistMark>

                  <ChecklistText>
                    <Link to={`/inApp/items/${link}?show_required_field=true`}>
                      {check.element}
                    </Link>
                    <Link
                      to={`/inApp/items/${link}?show_required_field=true`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="pi pi-external-link ml-2 cursor-pointer"
                        style={{ fontSize: '0.8rem' }}
                      />
                    </Link>
                  </ChecklistText>
                </div>
              )
            })
          ) : (
            <div className="flex items-center mb-2">
              <ChecklistMark className="flex items-center justify-center mr-2">
                <i
                  className="pi pi-times "
                  style={{ color: '#fff', fontSize: '.55rem' }}
                />
              </ChecklistMark>

              <ChecklistText>{check.element}</ChecklistText>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

const serialNumberAndIMEIMissing = (deal: Deal) => {
  const items = deal.items.filter((item) => {
    return (
      !item.serialNumber &&
      !item.imei &&
      isRequiredSerialNoOrImeiCategory(item.itemCategory)
    )
  })
  return {
    isDisplayed: items.length > 0,
    itemIds: items.map((item) => item._id),
  }
}

const isUsedGradeMissing = (deal: Deal) => {
  const items = deal.items.filter((item) => {
    const answeredQuestions = (item.answers ?? []).map((answer) => ({
      answer,
      question: (item.questions ?? []).find(
        (question) => question._id === answer.questionId,
      ),
    }))
    return (
      isElectronic(item.itemCategory) &&
      isUsedCondition(answeredQuestions) &&
      !item.usedGrade
    )
  })
  return {
    isDisplayed: items.length > 0,
    itemIds: items.map((item) => item._id),
  }
}

export const shouldRequirePiceaTest = (
  categories: string[],
  payoutAmount: number,
) => {
  const isSmartphone = categories.some((category) => category === 'Smartphones')

  const isTablet = categories.some((category) => category === 'Tablets')

  return (
    (isSmartphone && payoutAmount >= PICEA_TEST_MINIMUM.Smartphones) ||
    (isTablet && payoutAmount >= PICEA_TEST_MINIMUM.Tablets)
  )
}

export const checkItemIsNewCondition = (
  args: Pick<DealItem, 'questions' | 'answers' | 'formAnswers'>,
) => {
  const { questions, answers, formAnswers } = args
  const conditionQuestion = questions?.find(
    (q) => q.titleKey === QUESTION_OPTICAL_CONDITION,
  )
  const conditionAnswer = answers?.find(
    (a) => a.questionId === conditionQuestion?._id,
  )

  const isNewConditionItem =
    conditionAnswer && conditionAnswer.selectedOptionIndex === 0

  const customConditionQuestion = formAnswers?.find(
    (formAnswer) =>
      formAnswer.question.titleKey === CUSTOM_QUESTION_OPTICAL_CONDITION,
  )

  const isNewConditionCustomItem =
    customConditionQuestion &&
    customConditionQuestion.__typename === 'FormOptionTextAnswer' &&
    customConditionQuestion.question.options[0]?.label.allTranslations
      .map((tran) => tran.text)
      .includes(customConditionQuestion.optionTextAnswer ?? '')

  return !!isNewConditionItem || !!isNewConditionCustomItem
}

const piceaUploadMissing = (deal: Deal) => {
  const items = deal.items.filter((item) => {
    const categories = item.itemCategory.algoliaCategorySlug
      ? item.itemCategory.algoliaCategorySlug.split(' > ')
      : [item.itemCategory.name]

    const isNewCondition = checkItemIsNewCondition(item)

    return (
      categories &&
      shouldRequirePiceaTest(
        categories,
        last(item.dealsHistory)?.values?.verified?.payoutAmount ?? 0,
      ) &&
      !item.piceaTest?.disable &&
      !isNewCondition &&
      !item.piceaUploadLink
    )
  })
  return {
    isDisplayed: items.length > 0,
    itemIds: items.map((item) => item._id),
  }
}

const itemHasInvalidVehicleData = (item: DealItem, field: CheckListField) => {
  if (!item.vehicleData) {
    return true
  }
  if (field.canBeFalse) {
    return item.vehicleData[field.name] == null
  } else {
    // Check in case of number type
    if (
      field.name in item.vehicleData &&
      typeof item.vehicleData[field.name] === 'number'
    ) {
      return false
    }
    return !item.vehicleData[field.name]
  }
}

const missingVehicleData = (deal: Deal) => {
  const requiredCarFields: CheckListField[] = [
    /* { name: 'has57aGutachten', transKey: '57aGutachten' }, */
    { name: 'has57aGutachtenDue', transKey: 'has57aGutachtenDue' },

    { name: 'has57aGutachten', transKey: '57aGutachten', canBeFalse: true },
    { name: 'hasApprovalCertificate', transKey: 'approval_certificate' },
    { name: 'hasSaleContract', transKey: 'sale_contract' },
    { name: 'hasSecondKey', transKey: 'second_key', canBeFalse: true },
    { name: 'hasTypeCertificate', transKey: 'type_certificate' },
    { name: 'vin', transKey: 'vin' },
    /* { name: 'registrationCertificate', transKey: 'REGISTRATION_CERTIFICATE' }, */
  ]
  if (deal.company.carPawnSettings?.includeLicensePlate) {
    requiredCarFields.push({ name: 'licensePlate', transKey: 'license_plate' })
  }
  if (deal.company.carPawnSettings?.includeSpareTireCount) {
    requiredCarFields.push({
      name: 'spareTireCount',
      transKey: 'spare_tires_count',
    })
  }
  return requiredCarFields
    .map((field) => {
      const items = deal.items.filter((item) => {
        const categories = item.itemCategory.algoliaCategorySlug
          ? item.itemCategory.algoliaCategorySlug.split(' > ')
          : [item.itemCategory.name]
        const mainCategory = categories.map((category) => category.trim())[0]

        return (
          mainCategory &&
          isVehicle(item.itemCategoryId) &&
          itemHasInvalidVehicleData(item, field)
        )
      })
      return {
        isDisplayed: items.length > 0,
        itemIds: items.map((item) => item._id),
        ...field,
      }
    })
    .filter((o) => o.isDisplayed)
}

const checkedByMissing = (deal: Deal) => {
  const items = deal.items.filter((item) => {
    const categories = item.itemCategory.algoliaCategorySlug
      ? item.itemCategory.algoliaCategorySlug.split(' > ')
      : [item.itemCategory.name]
    const mainCategory = categories.map((category) => category.trim())[0]

    return mainCategory && mainCategory === 'Uhren' && !item.checkedBy
  })
  return {
    isDisplayed: items.length > 0,
    itemIds: items.map((item) => item._id),
  }
}

const checkedAtMissing = (deal: Deal) => {
  const items = deal.items.filter((item) => {
    const categories = item.itemCategory.algoliaCategorySlug
      ? item.itemCategory.algoliaCategorySlug.split(' > ')
      : [item.itemCategory.name]
    const mainCategory = categories.map((category) => category.trim())[0]

    return mainCategory && mainCategory === 'Uhren' && !item.checkedAt
  })
  return {
    isDisplayed: items.length > 0,
    itemIds: items.map((item) => item._id),
  }
}

const checkSecondaryPayoutAmount = (deal: Deal) => {
  return deal.secondaryPayoutData
    ? deal.secondaryPayoutData.amount >
        (getDealValueEntry(deal, 'DealVerifiedEvent').payoutAmount ?? 0)
    : false
}

const checkRegistrationAddress = (customer: Customer) => {
  const registrationAddress = customer.addresses?.find(
    (address) => address.isRegistrationAddress,
  )
  if (!registrationAddress) return true
  const requiredAddressFields = ['street', 'houseNumber', 'zipCode', 'city']
  return requiredAddressFields.some((field) => !registrationAddress[field])
}

const entrupyCertificateMissing = (deal: Deal) => {
  const items = deal.items.filter((item) => {
    const categories = item.itemCategory.algoliaCategorySlug
      ? item.itemCategory.algoliaCategorySlug.split(' > ')
      : [item.itemCategory.name]
    const mainCategory = categories.map((category) => category.trim())[0]

    return (
      mainCategory && mainCategory === 'Taschen' && !item.entrupyCertificateUrl
    )
  })
  return {
    isDisplayed: items.length > 0,
    itemIds: items.map((item) => item._id),
  }
}

const customerRiskAssessmentMissing = (customer: Customer) => {
  const allProvided =
    typeof customer.additionalInformationForCarPawn?.appearSerious ===
      'boolean' &&
    customer.additionalInformationForCarPawn?.citizenship &&
    typeof customer.additionalInformationForCarPawn?.employed === 'boolean' &&
    typeof customer.additionalInformationForCarPawn
      ?.hasCarPawnRelationshipWithCashy === 'boolean' &&
    typeof customer.additionalInformationForCarPawn?.hasSeizureEvident ===
      'boolean' &&
    typeof customer.additionalInformationForCarPawn
      ?.inInsolvencyDatabaseIn3Years === 'boolean' &&
    customer.additionalInformationForCarPawn?.lastResidentialAddressSince &&
    typeof customer.additionalInformationForCarPawn?.paySlipsPresented ===
      'boolean' &&
    customer.additionalInformationForCarPawn?.registeredInAustriaSince

  return !allProvided
}

export type VerificationChecklistItem = {
  isDisplayed: boolean
  element: string | JSX.Element
  links?: string[]
  key?: string
}

export const getVerificationChecklist = (
  deal: Deal,
  t: TFunction,
): VerificationChecklistItem[] => {
  if (!isDealConnectedToRegisteredCustomer(deal))
    return [
      {
        isDisplayed: true,
        element: `${t('verification_checklist.no_customer_connected')} `,
      },
    ]

  return [
    {
      isDisplayed: !deal.customer.sex,
      element: `${t('verification_checklist.customer_sex')} `,
    },
    {
      isDisplayed: !deal.customer.acquiredBy,
      element: `${t('verification_checklist.customer_acquired_by')} `,
    },
    {
      isDisplayed:
        !deal.customer.identityUploadLinks ||
        deal.customer.identityUploadLinks.length === 0,
      element: `${t('verification_checklist.customer_identity')} `,
    },
    {
      isDisplayed: !deal.customer.identityVerifiedAt,
      element: `${t('verification_checklist.customer_identity_verified')} `,
    },
    {
      isDisplayed:
        deal.businessUnit === EBusinessUnit.Car &&
        !deal.customer.registrationCertificate,
      element: `${t('verification_checklist.registration_certificate_from_customer')} `,
    },
    {
      isDisplayed: serialNumberAndIMEIMissing(deal).isDisplayed,
      element: `${t('verification_checklist.item_serial_number')} ${t('or')} ${t('imei')} `,
      links: serialNumberAndIMEIMissing(deal).itemIds,
    },
    {
      isDisplayed: piceaUploadMissing(deal).isDisplayed,
      element: `${t('verification_checklist.smartphone_picea')} `,
      links: piceaUploadMissing(deal).itemIds,
    },
    {
      isDisplayed: entrupyCertificateMissing(deal).isDisplayed,
      element: `${t('verification_checklist.bag_entrupy')} `,
      links: entrupyCertificateMissing(deal).itemIds,
    },
    {
      isDisplayed: checkedByMissing(deal).isDisplayed,
      element: `${t('verification_checklist.watch_verified_by')} `,
      links: checkedByMissing(deal).itemIds,
    },
    {
      isDisplayed: checkedAtMissing(deal).isDisplayed,
      element: `${t('verification_checklist.watch_verified_at')} `,
      links: checkedAtMissing(deal).itemIds,
    },
    {
      isDisplayed: checkSecondaryPayoutAmount(deal),
      element: `${t('verification_checklist.secondary_payout_amount_exceeds_total_payout_amount')} `,
    },
    {
      isDisplayed:
        deal.businessUnit === EBusinessUnit.Car &&
        checkRegistrationAddress(deal.customer),
      element: `${t('verification_checklist.is_registration_address')} `,
    },
    {
      isDisplayed: isUsedGradeMissing(deal).isDisplayed,
      element: `${t('verification_checklist.use_grade_mandatory')} `,
      links: isUsedGradeMissing(deal).itemIds,
    },
    ...missingVehicleData(deal).map((missing) => ({
      ...missing,
      links: missing.itemIds,
      element: `${t(missing.transKey)}`,
    })),
    {
      isDisplayed:
        deal.businessUnit === EBusinessUnit.Car && // We may not need this because isVehicle() is enough
        deal.company.headquarter?.countryCode === ECountry.At &&
        deal.items[0]?.isContinueUsing &&
        isVehicle(deal.items[0]?.itemCategoryId) &&
        deal.dealType === EDealType.Pawn &&
        customerRiskAssessmentMissing(deal.customer),
      element: (
        <Link to={`/inApp/customers/edit/${deal.customer._id}`}>
          {t('verification_checklist.please_complete_customer_risk_assessment')}
        </Link>
      ),
    },
    {
      isDisplayed:
        deal.company.headquarter?.countryCode === ECountry.De &&
        (!deal.customer.identityNumber || !deal.customer.identityType),
      element: (
        <Link to={`/inApp/customers/edit/${deal.customer._id}`}>
          {t('verification_checklist.please_specify_id_files')}
        </Link>
      ),
    },
    {
      isDisplayed:
        deal.businessUnit === EBusinessUnit.Car &&
        isEmpty(deal.pawnData?.userAgreementDocuments) &&
        deal.items[0].isContinueUsing,
      element: t('verification_checklist.missing_user_aggrement_document'),
      key: 'missing_user_aggrement_document',
    },
    {
      isDisplayed:
        deal.businessUnit === EBusinessUnit.Car &&
        isEmpty(deal.pawnData?.powerOfAttorneyDocuments),
      element: t('verification_checklist.missing_attorney_document'),
      key: 'missing_attorney_document',
    },
    {
      isDisplayed:
        deal.businessUnit === EBusinessUnit.Car &&
        dayjs()
          .add(deal.dealFinalValues.durationInDays, 'days')
          .isAfter(
            dayjs(deal.items[0].vehicleData?.has57aGutachtenDue).add(
              ASSESSMENT_57_TOLERANCE_DURATION_MONTH,
              'month',
            ),
            'date',
          ),
      element: t('verification_checklist.assessment_57_a_not_valid_enough', {
        pawnDuration: deal.dealFinalValues.durationInDays,
      }),
    },
    {
      isDisplayed: deal.items.some(
        (item) => item.unconfirmedPropertyIds?.length,
      ),
      element: t('verification_checklist.unconfirmed_properties'),
      links: deal.items
        .filter((item) => item.unconfirmedPropertyIds?.length)
        .map((item) => item._id),
    },
  ]
}

const ChecklistMark = styled.div`
  padding: 0.1875rem;
  border-radius: 6.25rem;
  background: var(--global-errorColor, #e24c4c);
  width: 1.1rem;
  height: 1.1rem;
`

const ChecklistText = styled.a`
  color: var(--global-textSecondaryColor, #6c757d);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 0.825rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.875rem; /* 140% */
`

export const ASSESSMENT_57_TOLERANCE_DURATION_MONTH = 4

export default VerificationChecklist
