import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import { TreeNode } from 'primereact/treenode'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Loading from '@/components/Loading'
import { useGetItemCategories } from '@/domains/itemCategories/hooks'
import { useSearchProducts } from '@/domains/products/hooks/searchProducts'
import {
  HistoryFilters,
  useHistoryFilter,
  useHistoryState,
} from '@/hooks/useHistoryState'
import InfiniteScrollTable from '@/redesign/components/InfiniteScrollTable/InfiniteScrollTable'
import TableFilterHeader from '@/redesign/components/TableFilterHeade/TableFilterHeader'
import Taskbar from '@/redesign/components/Taskbar/Taskbar'
import ProductsFilter, {
  ProductsFilters,
} from '@/redesign/domains/products/components/ProductsSearch/ProductsFilter'
import { ItemCategory, Product, QuerySearchProductsArgs } from '@/schemaTypes'

const LIMIT = 10

const defaultProductsFilterArgs: Omit<ProductsFilters, 'createdBefore'> = {
  isPublished: undefined,
  isCrawled: undefined,
}

export function Products() {
  const { t } = useTranslation()
  const history = useHistory()

  const { historyState, setHistory } =
    useHistoryState<HistoryFilters<ProductsFilters>>()
  const [productsFilter, setProductsFilter] = useState<ProductsFilters>({
    ...historyState?.filters,
    ...defaultProductsFilterArgs,
    createdBefore: new Date(),
  })
  useHistoryFilter<ProductsFilters>(
    historyState?.filters,
    productsFilter,
    setHistory,
  )

  const productsFilterArgs = useMemo(() => {
    const categoryIds = Object.keys(productsFilter.categoryIds ?? {})
      .map((key) => (productsFilter.categoryIds[key].checked ? key : null))
      .filter(Boolean)
    return {
      ...productsFilter,
      categoryIds,
      paginationArgs: { skip: 0, limit: LIMIT },
      createdBefore: new Date(),
    }
  }, [productsFilter])

  const queryVariables: QuerySearchProductsArgs = useMemo(
    () => ({
      args: productsFilterArgs,
    }),
    [productsFilterArgs],
  )

  const { queryResult } = useSearchProducts({
    variables: queryVariables,
    nextFetchPolicy: 'cache-first',
  })

  const { itemCategories } = useGetItemCategories()

  const loadNextPage = async () => {
    const pageInfo = queryResult?.data?.searchProducts?.pageInfo
    const paginationLimit = pageInfo?.limit || LIMIT
    const paginationSkip = (pageInfo?.skip || 0) + paginationLimit

    await queryResult.fetchMore({
      variables: {
        args: {
          ...productsFilterArgs,
          paginationArgs: {
            skip: paginationSkip,
            limit: paginationLimit,
          },
        },
      },
    })
  }

  const products = useMemo(
    () => queryResult?.data?.searchProducts?.nodes,
    [queryResult?.data],
  )

  const handleClickItem = useCallback(
    ({ value }) => {
      history.push(`/inApp/products/${value._id}`)
    },
    [history],
  )

  const itemCategoryOptions = useMemo(() => {
    const processArray = (
      array: ItemCategory[],
      parentId: string = null,
    ): TreeNode[] => {
      const result = []
      for (const item of array) {
        if (item.parentId === parentId) {
          const newItem = {
            key: item._id,
            label: item.name,
            value: item._id,
            children: processArray(array, item._id),
          }
          result.push(newItem)
        }
      }
      return result
    }

    return processArray(itemCategories)
  }, [itemCategories])

  const tableActionsTemplate = (rowData: Product) => {
    return (
      <div className="flex justify-end">
        <Link
          className="mr-2"
          to={`/inApp/products/${rowData._id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon="pi pi-external-link" severity="secondary" text />
        </Link>
        <Button
          icon="pi pi-pencil"
          className="me-1 !mr-2"
          text
          severity="secondary"
          onClick={() => history.push(`/inApp/products/edit/${rowData?._id}`)}
        />
      </div>
    )
  }

  return (
    <div className="h-full flex flex-col">
      <TableFilterHeader
        title={t('products')}
        resultsCount={queryResult.data?.searchProducts.pageInfo.total || 0}
        filterOptions={
          <ProductsFilter
            defaultProductsFilter={defaultProductsFilterArgs}
            productFilter={productsFilter}
            setProductsFilter={setProductsFilter}
            itemCategoryOptions={itemCategoryOptions}
          />
        }
      />

      {queryResult.loading ? (
        <Loading />
      ) : (
        <>
          <InfiniteScrollTable
            handleLoadNextPage={loadNextPage}
            dataKey="_id"
            value={products ?? []}
            onClickRow={handleClickItem}
            limit={queryResult.data?.searchProducts.pageInfo.limit ?? LIMIT}
            hasNextPage={
              queryResult.data?.searchProducts.pageInfo.hasNextPage ?? true
            }
          >
            <Column field="name" header={t('name')} />
            <Column field="category.name" header={t('category')} />
            <Column field="manufacturer" header={t('manufacturer')} />
            <Column
              header={t('type')}
              body={(rowData: Product) =>
                rowData.isCrawled ? `${t('crawled')}` : `${t('manual')}`
              }
            />
            <Column
              field="status"
              header={t('status')}
              body={(rowData) => (
                <Tag
                  value={
                    rowData.isPublished ? t('published') : t('unpublished')
                  }
                  severity={!rowData.isPublished ? 'warning' : undefined}
                />
              )}
            />
            <Column style={{ width: '9rem' }} body={tableActionsTemplate} />
          </InfiniteScrollTable>
          <Taskbar>
            <Link to="/inApp/products/create">
              <Button
                label={t('create_manual_product')}
                icon="pi pi-plus"
                severity="secondary"
              />
            </Link>
          </Taskbar>
        </>
      )}
    </div>
  )
}
