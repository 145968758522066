import StorageUnitsControlsButtons from '../StorageUnitsControlsButtons/StorageUnitsControlsButtons'
import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'

export interface StorageUnitsControlsProps {
  firstLevelUnit: {
    _id: string
    onClickAdd: () => void
    onClickDelete: (_id: string, level: string) => void
    onClickUpdate: () => void
  }
  secondLevelUnit: {
    _id: string
    onClickAdd: () => void
    onClickDelete: (_id: string, level: string) => void
    onClickUpdate: () => void
  }
  thirdLevelUnit: {
    _id: string
    onClickAdd: () => void
    onClickDelete: (_id: string, level: string) => void
    onClickUpdate: () => void
  }
  lastLevelUnit: {
    _id: string
    onClickAdd: () => void
    onClickDelete: (_id: string, level: string) => void
    onClickUpdate: () => void
  }
}

export function StorageUnitsControls(props: StorageUnitsControlsProps) {
  const { firstLevelUnit, secondLevelUnit, thirdLevelUnit, lastLevelUnit } =
    props
  return (
    <TableRow>
      <TableCell align="center">
        <StorageUnitsControlsButtons
          id={firstLevelUnit._id}
          disabled={!!firstLevelUnit._id}
          addStatus={true}
          onClickAdd={() => firstLevelUnit.onClickAdd()}
          onClickDelete={() =>
            firstLevelUnit.onClickDelete(firstLevelUnit._id, 'first')
          }
          onClickUpdate={() => firstLevelUnit.onClickUpdate()}
        />
      </TableCell>
      <TableCell align="center">
        <StorageUnitsControlsButtons
          id={secondLevelUnit._id}
          disabled={!!secondLevelUnit._id}
          addStatus={!!firstLevelUnit._id}
          onClickAdd={() => secondLevelUnit.onClickAdd()}
          onClickDelete={() =>
            secondLevelUnit.onClickDelete(secondLevelUnit._id, 'second')
          }
          onClickUpdate={() => secondLevelUnit.onClickUpdate()}
        />
      </TableCell>
      <TableCell align="center">
        <StorageUnitsControlsButtons
          id={thirdLevelUnit._id}
          disabled={!!thirdLevelUnit._id}
          addStatus={!!secondLevelUnit._id}
          onClickAdd={() => thirdLevelUnit.onClickAdd()}
          onClickDelete={() =>
            thirdLevelUnit.onClickDelete(thirdLevelUnit._id, 'third')
          }
          onClickUpdate={() => thirdLevelUnit.onClickUpdate()}
        />
      </TableCell>
      <TableCell align="center">
        <StorageUnitsControlsButtons
          id={lastLevelUnit._id}
          disabled={!!lastLevelUnit._id}
          addStatus={!!thirdLevelUnit._id}
          onClickAdd={() => lastLevelUnit.onClickAdd()}
          onClickDelete={() =>
            lastLevelUnit.onClickDelete(lastLevelUnit._id, 'last')
          }
          onClickUpdate={() => lastLevelUnit.onClickUpdate()}
        />
      </TableCell>
      <TableCell align="center" />
    </TableRow>
  )
}

export default StorageUnitsControls
