import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '@/components/LoadingSpinner'
import { AuctionElement, Deal } from '@/schemaTypes'
import Element from './Element'

export interface AuctionElementsViewerProps {
  isEditable: boolean
  auctionElements: AuctionElement[]
  deals: Deal[]
  deleteAuctionElement?: (dealId: string) => void
}

const AuctionElementsViewer: React.FC<AuctionElementsViewerProps> = ({
  isEditable,
  auctionElements,
  deleteAuctionElement,
  deals,
}) => {
  const { t } = useTranslation()

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow style={{ backgroundColor: '#e9e2f8' }}>
            <TableCell />
            <TableCell>{t('deal.booking_number')}</TableCell>
            <TableCell align="center">{t('shop.label')}</TableCell>
            {!isEditable && <TableCell align="left">{t('buyback')}</TableCell>}
            {!isEditable && (
              <TableCell align="left">{t('repurchase_fees')}</TableCell>
            )}
            <TableCell align="center">{t('payout.payout_amount')}</TableCell>
            <TableCell align="center">{t('fees.label')}</TableCell>
            <TableCell align="center">{t('payout_and_fees')}</TableCell>
            <TableCell align="center">{t('liquidation_fee')}</TableCell>
            <TableCell align="center">{t('cashy_price')}</TableCell>
            <TableCell align="center">{t('deal_latest_event')}</TableCell>
            <TableCell align="center">{t('internal_sale_data')}</TableCell>
            {isEditable && <TableCell align="center" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {auctionElements.map((element) => {
            const deal = deals.find((c) => c._id === element.dealId)
            if (!deal) {
              return (
                <TableRow>
                  <TableCell colSpan={isEditable ? 13 : 12} align="center">
                    <LoadingSpinner />
                  </TableCell>
                </TableRow>
              )
            }

            return (
              <Element
                key={element.dealId}
                deal={deal}
                isEditable={isEditable}
                auctionElement={element}
                onDelete={deleteAuctionElement}
              />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AuctionElementsViewer
