import { euroDenominations, euroMoneyImages } from '../helpers'
import styled from '@emotion/styled'
import { sortBy } from 'lodash'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputNumber } from 'primereact/inputnumber'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { moneyPieceValues } from '@/domains/deals/components/constants/moneyPieces'
import { CashBookCountCashBoxEntry, EEuroDenomination } from '@/schemaTypes'
import { calculateCashBoxAmounts } from '@/utils/calculation'
import { printMoneyAmount } from '@/utils/misc'

export interface CashBookCountTableProps {
  cashBoxEntries: CashBookCountCashBoxEntry[]
  readOnly?: boolean
  latestBalance?: number
  onChange: (data: {
    count: number
    cashBoxName: string
    moneyPiece: EEuroDenomination
  }) => void
}

export function CashBookCountTableRow(props: {
  moneyPiece: EEuroDenomination
  cashBoxName: string
  cashBoxCount: number
  readOnly?: boolean
  onChange: (count: number, cashBoxName: string) => void
}) {
  const { moneyPiece, cashBoxCount, cashBoxName, readOnly, onChange } = props

  return (
    <div className="flex items-center">
      <InputNumber
        className="w-[150px] h-[37px]"
        inputClassName="w-full text-center"
        value={cashBoxCount}
        onValueChange={(e) => onChange(e.value, cashBoxName)}
        showButtons
        buttonLayout="horizontal"
        min={0}
        decrementButtonClassName="p-button-secondary"
        incrementButtonClassName="p-button-secondary"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        disabled={readOnly}
      />
      <div className="font-bold text-sm text-black ml-8">
        {printMoneyAmount(cashBoxCount * moneyPieceValues[moneyPiece])}
      </div>
    </div>
  )
}

const moneyPieces = sortBy(
  Object.values(EEuroDenomination),
  (value) => euroDenominations[value],
).reverse()

export function CashBookCountTable(props: CashBookCountTableProps) {
  const { cashBoxEntries, onChange, latestBalance, readOnly } = props
  const { t } = useTranslation()

  const total = useMemo(() => {
    return calculateCashBoxAmounts(cashBoxEntries)
  }, [cashBoxEntries])

  const data = useMemo(
    () =>
      moneyPieces.map((moneyPiece) => {
        return {
          moneyPiece,
          ...cashBoxEntries.reduce((acc: Record<string, number>, entry) => {
            return {
              ...acc,
              [entry.cashBoxName]: entry.counts[moneyPiece.toUpperCase()],
            }
          }, {}),
        }
      }),
    [cashBoxEntries],
  )

  return (
    <div className="mb-4">
      <DataTable value={data} cellClassName={() => '!py-2'}>
        <Column
          body={({ moneyPiece }) => (
            <img
              src={euroMoneyImages[moneyPiece] ?? ''}
              alt={moneyPiece}
              className="w-14 mx-auto"
            />
          )}
          headerClassName="text-sm"
        />
        {cashBoxEntries.map((entry) => (
          <Column
            key={entry.cashBoxName}
            header={entry.cashBoxName}
            headerClassName="text-sm"
            className="text-left min-w-[300px]"
            body={({ moneyPiece }) => (
              <CashBookCountTableRow
                moneyPiece={moneyPiece}
                cashBoxName={entry.cashBoxName}
                cashBoxCount={entry.counts[moneyPiece.toUpperCase()]}
                readOnly={readOnly}
                onChange={(count, cashBoxName) => {
                  onChange({ count, cashBoxName, moneyPiece })
                }}
              />
            )}
          />
        ))}
      </DataTable>

      <SummaryRow className="flex justify-between row py-4 px-4 font-bold text-black">
        <div className="text-sm">{t('cash_book_count.total_counted')}</div>
        <div className="text-sm">{printMoneyAmount(total.totalAmount)}</div>
      </SummaryRow>

      <SummaryRow className="flex justify-between row py-4 px-4 font-bold text-black">
        <div className="text-sm">{t('cash_book_count.expected_total')}</div>
        <div className="text-sm">
          {latestBalance ? printMoneyAmount(latestBalance) : '-'}
        </div>
      </SummaryRow>

      <SummaryRow className="flex justify-between row py-4 px-4 font-bold text-black">
        <div className="text-sm">{t('cash_book_count.difference')}</div>
        <div className="text-sm">
          {latestBalance
            ? printMoneyAmount(total.totalAmount - latestBalance)
            : '-'}
        </div>
      </SummaryRow>
    </div>
  )
}

const SummaryRow = styled.div`
  border-bottom: 1px solid #dee2e6;
  background: #f8f9fa;
`

export default CashBookCountTable
