import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateItemManualTestArgs } from '@/schemaTypes'

const ITEM_MANUAL_TEST = gql`
  mutation updateItemManualTest(
    $itemId: ObjectId!
    $args: ItemManualTestArgs!
  ) {
    updateItemManualTest(itemId: $itemId, args: $args) {
      _id
      manualTest {
        createdAt
        createdBy {
          firstname
          lastname
        }
        assessments {
          assessment
          passed
        }
      }
    }
  }
`

type TData = { updateItemManualTest: Mutation['updateItemManualTest'] }
type TVariables = MutationUpdateItemManualTestArgs

export function useUpdateItemManualTest(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateItemManualTest] = useMutation<TData, TVariables>(
    ITEM_MANUAL_TEST,
    opts,
  )

  return updateItemManualTest
}
