import { MenuItem, Select } from '@material-ui/core'
import { useContext } from 'react'
import { Context } from '@/context'
import {
  ECustomDealFormQuestionType,
  ELanguageCode,
  FormBooleanAnswer,
} from '@/schemaTypes'
import { NewFormAnswer, getQuestionLabel } from './ItemFormAnswers'

interface Props {
  formAnswer: FormBooleanAnswer
  disabled?: boolean
  onChange?: (newFormAnswer: NewFormAnswer, allowEnterAnswer?: boolean) => void
}

const options = [
  {
    allTranslations: [
      { languageCode: ELanguageCode.En, text: 'No' },
      { languageCode: ELanguageCode.De, text: 'Nein' },
    ],
  },
  {
    allTranslations: [
      { languageCode: ELanguageCode.En, text: 'Yes' },
      { languageCode: ELanguageCode.De, text: 'Ja' },
    ],
  },
]

const getBooleanValue = (booleanAnswer: boolean | null | undefined) => {
  if (booleanAnswer === null || booleanAnswer === undefined) {
    return null
  }

  return booleanAnswer === true ? 1 : 0
}

export const ItemFormBooleanAnswer = ({
  formAnswer,
  disabled,
  onChange,
}: Props) => {
  const { language } = useContext(Context)

  return (
    <Select
      value={getBooleanValue(formAnswer.booleanAnswer)}
      disabled={disabled}
      onChange={(e) =>
        onChange?.({
          type: ECustomDealFormQuestionType.Boolean,
          value: e.target.value === 1 ? true : false,
          question: formAnswer.question,
        })
      }
      fullWidth
      style={{ textAlign: 'left' }}
    >
      {options.map((option, i) => (
        <MenuItem key={i} value={i}>
          {getQuestionLabel(option.allTranslations, language)}
        </MenuItem>
      ))}
    </Select>
  )
}
