import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationCreateFeeArgs } from '@/schemaTypes'

const CREATE_FEE = gql`
  mutation createFee($feeCreateArgs: FeeCreateArgs!) {
    createFee(feeCreateArgs: $feeCreateArgs) {
      ...FeeDefinitionFragment
    }
  }
  ${fragments.feeDefinition}
`

type TData = { createFee: Mutation['createFee'] }
type TVariables = MutationCreateFeeArgs

export function useCreateFee(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createFee] = useMutation<TData, TVariables>(CREATE_FEE, opts)

  return createFee
}
