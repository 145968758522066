import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { Query, QueryGetShopArgs, StorageFacilityOpts } from '@/schemaTypes'

const GET_SHOP = gql`
  query getShop($shopId: ObjectId!, $storageFacilityOpts: StorageFacilityOpts) {
    getShop(shopId: $shopId) {
      _id
      name
      googleMapsLink
      address {
        ...AddressFragment
      }
      phone
      email
      partnerId
      hideLocationOnWebsite
      disabled
      successionShopId
      ready2OrderApiKey
      ready2OrderApiKeyStored
      successionShop {
        _id
        name
        disabled
      }
      openingHours
      storageFacilityIds
      storageFacilities(opts: $storageFacilityOpts) {
        _id
        name
      }
      cashBoxNames
      itemCategoryIds
      itemCategories {
        _id
        name
        parentId
      }
      companyId
      company {
        _id
        name
      }
      mainStorageFacilityId
      r2oProductMaps {
        productName
        paymentReference
      }
    }
  }
  ${fragments.address}
`

export type TData = { getShop: Query['getShop'] }
export type TVariables = QueryGetShopArgs & {
  storageFacilityOpts?: StorageFacilityOpts
}

export function useGetShop(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useQuery<TData, TVariables>(GET_SHOP, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.shopId,
  })

  useShowErrorsPopup(queryResult.error)

  const shop = opts.variables?.shopId ? queryResult.data?.getShop : undefined
  return { queryResult, shop }
}
