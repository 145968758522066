import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateStorageUnitArgs } from '@/schemaTypes'

const UPDATE_STORAGE_UNIT = gql`
  mutation updateStorageUnit(
    $_id: ObjectId!
    $name: String!
    $storageFacilityId: ObjectId
    $parentId: ObjectId
    $childIds: [ObjectId!]
    $capacity: Float
    $isSecureLocation: Boolean
  ) {
    updateStorageUnit(
      _id: $_id
      name: $name
      storageFacilityId: $storageFacilityId
      parentId: $parentId
      childIds: $childIds
      capacity: $capacity
      isSecureLocation: $isSecureLocation
    ) {
      _id
      name
      storageFacilityId
      childIds
      isSecureLocation
      childUnits {
        _id
        name
        storageFacilityId
        childIds
        childUnits {
          _id
          name
          storageFacilityId
          childIds
          capacity
          isDeleted
        }
        capacity
        isDeleted
      }
      capacity
      isDeleted
    }
  }
`

type TData = { updateStorageUnit: Mutation['updateStorageUnit'] }
type TVariables = MutationUpdateStorageUnitArgs

export function useUpdateStorageUnit(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateStorageUnit] = useMutation<TData, TVariables>(
    UPDATE_STORAGE_UNIT,
    opts,
  )

  return updateStorageUnit
}
