import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryGetShopsArgs } from '@/schemaTypes'

const GET_SHOPS_NAMES = gql`
  query getShops($opts: ShopOpts) {
    getShops(opts: $opts) {
      _id
      name
      cashBoxNames
    }
  }
`

type TData = { getShops: Query['getShops'] }
type TVariables = QueryGetShopsArgs

export function useGetShopsNames(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_SHOPS_NAMES, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, shops: queryResult.data?.getShops || [] }
}
