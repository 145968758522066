import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreatePrivateSaleArgs } from '@/schemaTypes'

const CREATE_PRIVATE_SALE = gql`
  mutation createPrivateSale($args: PrivateSaleCreateArgs!) {
    createPrivateSale(args: $args) {
      _id
    }
  }
`

type TData = { createPrivateSale: Mutation['createPrivateSale'] }
type TVariables = MutationCreatePrivateSaleArgs

export function useCreatePrivateSale(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createPrivateSale] = useMutation<TData, TVariables>(
    CREATE_PRIVATE_SALE,
    opts,
  )
  return createPrivateSale
}
