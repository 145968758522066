import { ALL_COMPANIES } from '../../../../components/CompanySwitch/CompanySwitch'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Loading from '@/components/Loading'
import { DealsFilters } from '@/domains/deals/components/DealsSearch'
import { useSearchDeals } from '@/domains/deals/hooks/searchDeals'
import {
  HistoryFilters,
  useHistoryFilter,
  useHistoryState,
} from '@/hooks/useHistoryState'
import shippingLabel from '@/images/shippingLabel.png'
import InfiniteScrollTable from '@/redesign/components/InfiniteScrollTable/InfiniteScrollTable'
import TableFilterHeader from '@/redesign/components/TableFilterHeade/TableFilterHeader'
import DealsFilter from '@/redesign/domains/deals/components/DealsFilter/DealsFilter'
import {
  DealEvent,
  DealsFiltersArgs,
  EBusinessUnit,
  EDealType,
  Maybe,
  TransportData,
} from '@/schemaTypes'
import { useAppSelector } from '@/store'
import {
  getDealTransportType,
  getDealValueEntry,
  getRegisteredCustomer,
  isSentShippingLabel,
} from '@/utils/deal'
import { displayLocalAmount } from '@/utils/misc'

const LIMIT = 10

export type PaginationArgs = DealsFiltersArgs['paginationArgs']

const DealsList = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const companyId = useAppSelector((state) => state.ui.companyId)
  const defaultDealsFilterArgs = useMemo(
    () => ({ companyId: companyId === ALL_COMPANIES ? undefined : companyId }),
    [companyId],
  )
  const { historyState, setHistory } =
    useHistoryState<HistoryFilters<DealsFilters>>()
  const [dealFilter, setDealFilter] = useState<DealsFilters>({
    ...historyState?.filters,
    ...defaultDealsFilterArgs,
  })
  const { edited } = useHistoryFilter<DealsFilters>(
    historyState?.filters,
    dealFilter,
    setHistory,
  )

  const dealsFiltersArgs = useMemo(() => {
    const status = dealFilter.status
      ? {
          ...dealFilter.status,
          names: [...dealFilter.status.names], // create new string array instance so Apollo can detect the change in it
        }
      : undefined

    return {
      ...dealFilter,
      status,
      createdBefore: new Date(),
      paginationArgs: {
        limit: edited ? LIMIT : historyState?.scroll?.items || LIMIT,
      },
    } as DealsFiltersArgs
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealFilter, edited])

  useEffect(() => {
    setDealFilter((prev) => ({
      ...prev,
      companyId: companyId === ALL_COMPANIES ? undefined : companyId,
    }))
  }, [companyId, defaultDealsFilterArgs])

  const { queryResult } = useSearchDeals({
    variables: {
      args: dealsFiltersArgs,
    },
    nextFetchPolicy: 'cache-first',
  })

  const deals = useMemo(() => {
    return queryResult?.data?.searchDeals?.nodes.map((node) => {
      const registeredCustomer = getRegisteredCustomer(node)
      return {
        ...node,
        name: registeredCustomer
          ? `${registeredCustomer?.firstname} ${registeredCustomer?.lastname}`
          : node.customer.email,
        shop: node.shop ? node.shop.name : '',
        payout: displayLocalAmount(
          getDealValueEntry(node, 'DealVerifiedEvent').payoutAmount,
        ),
        duration:
          node.businessUnit === EBusinessUnit.General
            ? node.dealType === EDealType.Purchase
              ? t('purchase')
              : t('pawn')
            : t('car_pawn'),
        createdAt: dayjs(node.createdAt).format('DD.MM.YYYY HH:mm'),
      }
    })
  }, [queryResult?.data, t])

  const tableActionsTemplate = ({ _id }: { _id: string }) => {
    return (
      <Link
        to={`/inApp/deals/${_id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button icon="pi pi-external-link" severity="secondary" text />
      </Link>
    )
  }

  const handleClickDeal = useCallback(
    ({ value }) => {
      history.push(`/inApp/deals/${value._id}`)
    },
    [history],
  )

  const loadNextPage = useCallback(async () => {
    const pageInfo = queryResult?.data?.searchDeals?.pageInfo
    const paginationLimit = pageInfo?.limit || LIMIT
    const paginationSkip = (pageInfo?.skip || 0) + paginationLimit

    await queryResult.fetchMore({
      variables: {
        args: {
          ...dealsFiltersArgs,
          paginationArgs: {
            skip: paginationSkip,
            limit: paginationLimit,
          },
        },
      },
    })
  }, [queryResult, dealsFiltersArgs])

  return (
    <div className="h-full flex flex-col">
      <TableFilterHeader
        title={t('deal.plural')}
        resultsCount={queryResult.data?.searchDeals.pageInfo.total || 0}
        filterOptions={
          <DealsFilter
            defaultDealsFilter={defaultDealsFilterArgs}
            dealFilter={dealFilter}
            setDealFilter={setDealFilter}
          />
        }
      />

      {queryResult.loading ? (
        <Loading />
      ) : (
        <>
          <InfiniteScrollTable
            handleLoadNextPage={loadNextPage}
            dataKey="_id"
            value={deals ?? []}
            onClickRow={handleClickDeal}
            limit={queryResult.data?.searchDeals.pageInfo.limit ?? LIMIT}
            hasNextPage={
              queryResult.data?.searchDeals.pageInfo.hasNextPage ?? true
            }
          >
            <Column
              field="bookingNumber"
              style={{ width: '50px' }}
              header={'#'}
            />
            <Column field="name" style={{ width: '80px' }} header={t('name')} />
            <Column
              field="shop"
              style={{ width: '80px' }}
              header={t('shop.label')}
            />
            <Column
              field="payout"
              style={{ width: '40px' }}
              header={t('payout.label')}
            />
            <Column
              field="duration"
              style={{ width: '40px' }}
              header={t('duration')}
            />
            <Column
              body={transportTypeTemplate}
              style={{ width: '80px' }}
              header={t('transport_type')}
            />
            <Column
              field="status"
              style={{ width: '80px' }}
              header={t('status')}
            />
            <Column
              field="createdAt"
              style={{ width: '70px' }}
              header={t('created_at')}
            />
            <Column
              style={{ width: '30px', textAlign: 'right' }}
              body={tableActionsTemplate}
            />
          </InfiniteScrollTable>
        </>
      )}
    </div>
  )
}

interface TransportTypeTemplateProps {
  pickupData: Maybe<TransportData>
  events: DealEvent[]
  dropoffData: Maybe<TransportData>
}

const transportTypeTemplate = ({
  pickupData,
  events,
  dropoffData,
}: TransportTypeTemplateProps) => {
  return (
    <div className="flex flex-row">
      {getDealTransportType(dropoffData, pickupData)}
      {isSentShippingLabel(pickupData) &&
        events[events.length - 1]?.__typename === 'DealBookedEvent' && (
          <ShippingLabelImage src={shippingLabel} />
        )}
    </div>
  )
}

const ShippingLabelImage = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`

export default DealsList
