import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateEmployeeArgs } from '@/schemaTypes'

const CREATE_EMPLOYEE = gql`
  mutation createEmployee(
    $email: String!
    $roles: [ERoles!]!
    $companyId: ObjectId!
  ) {
    createEmployee(email: $email, companyId: $companyId, roles: $roles) {
      _id
    }
  }
`

type TData = { createEmployee: Mutation['createEmployee'] }
type TVariables = MutationCreateEmployeeArgs

export function useCreateEmployee(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createEmployee] = useMutation<TData, TVariables>(CREATE_EMPLOYEE, opts)

  return createEmployee
}
