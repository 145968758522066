import {
  IsBoolean,
  IsDate,
  IsEmail,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPhoneNumber,
  IsString,
  Min,
} from 'class-validator'
import { ERoles, Employee } from '@/schemaTypes'

export class EmployeeValidation {
  @IsEmail()
  email: string

  @IsNotEmpty()
  firstname: string

  @IsNotEmpty()
  lastname: string

  @IsDate()
  dateOfBirth: Date

  @IsPhoneNumber('AT')
  phone: string

  @IsNotEmpty()
  roles: ERoles[]

  @IsString()
  companyId: string

  @IsOptional()
  @IsDate()
  scheduledDeactivationAt: Date

  @IsOptional()
  @IsNumber()
  @Min(0)
  payoutLimit?: number

  @IsOptional()
  @IsNumber()
  @Min(0)
  carPayoutLimit?: number

  @IsOptional()
  @IsBoolean()
  noLimit?: boolean

  constructor(employee: Employee) {
    this.email = employee.email
    this.firstname = employee.firstname
    this.lastname = employee.lastname
    this.dateOfBirth = employee.dateOfBirth
    this.phone = employee.phone
    this.roles = employee.roles
    this.companyId = employee.companyId
    this.scheduledDeactivationAt = employee.scheduledDeactivationAt
    this.payoutLimit = employee.payoutConfigurations?.payoutLimit
    this.carPayoutLimit = employee.payoutConfigurations?.carPayoutLimit
    this.noLimit = employee.payoutConfigurations?.noLimit
  }
}
