import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateDealItemArgs } from '@/schemaTypes'

const UPDATE_DEAL_ITEM = gql`
  mutation updateDealItem($dealId: ObjectId!, $args: DealItemUpdateArgs!) {
    updateDealItem(dealId: $dealId, args: $args) {
      _id
      serialNumber
      imei
      itemSpecification
      note
      policeNumber
      isDamageCase
      isForPersonalUse
      isInvestmentGold
      piceaUploadLink {
        url
        contentType
      }
      entrupyCertificateUrl
      checkedBy
      checkedAt
    }
  }
`

type TData = { updateDealItem: Mutation['updateDealItem'] }
type TVariables = MutationUpdateDealItemArgs

export function useUpdateDealItem(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateDealItem] = useMutation<TData, TVariables>(
    UPDATE_DEAL_ITEM,
    opts,
  )

  return updateDealItem
}
