import { QueryHookOptions, gql, useQuery } from '@apollo/client'
import { Query, QueryProductVariantArgs } from '@/gql/graphql'
import { useShowErrorsPopup } from '@/hooks'

const QUERY = gql`
  query getProductVariantForUpdateCustomItem($args: GetProductVariantArgs!) {
    productVariant(args: $args) {
      id
      isPriceValid
      name
      propertiesValues {
        ... on ProductVariantPropertyTextValue {
          propertyDefinitionId
          propertyDefinition {
            _id
            type
            impactsLook
            sortPriority
            description {
              allTranslations {
                text
                languageCode
              }
            }
            name {
              allTranslations {
                text
                languageCode
              }
            }
            key
            valueFormatting {
              suffix
            }
          }
          value {
            id
            translation {
              allTranslations {
                text
                languageCode
              }
            }
          }
        }
        ... on ProductVariantPropertyNumberValue {
          propertyDefinitionId
          propertyDefinition {
            _id
            type
            impactsLook
            sortPriority
            description {
              allTranslations {
                text
                languageCode
              }
            }
            name {
              allTranslations {
                text
                languageCode
              }
            }
            key
            valueFormatting {
              suffix
            }
          }
          value {
            numberValue
          }
        }
        ... on ProductVariantPropertyBooleanValue {
          propertyDefinitionId
          propertyDefinition {
            _id
            type
            impactsLook
            sortPriority
            description {
              allTranslations {
                text
                languageCode
              }
            }
            name {
              allTranslations {
                text
                languageCode
              }
            }
            key
            valueFormatting {
              suffix
            }
          }
          value {
            booleanValue
          }
        }
      }
    }
  }
`

type Data = { productVariant: Query['productVariant'] }
type Variables = QueryProductVariantArgs
export default function useProductVariantForUpdateCustomItem(
  opts?: QueryHookOptions<Data, Variables>,
) {
  const queryResult = useQuery<Data, Variables>(QUERY, opts)

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    productVariant: queryResult.data?.productVariant,
  }
}
