import { Button, Checkbox, Typography } from '@material-ui/core'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandableAddress from '@/components/ExpandableAddress/ExpandableAddress'
import TextInput from '@/components/TextInput'
import { useValidationErrorMap } from '@/hooks'
import { StorageFacility } from '@/schemaTypes'
import { StorageFacilityValidation } from '@/validation'

export type StorageFacilityValidationErrorObject = Partial<
  Pick<StorageFacility, 'name' | 'external'>
>
export interface StorageFacilityValidationError {
  fieldName: keyof StorageFacilityValidationErrorObject
  message: string
}
export interface StorageFacilitiesDetailsProps {
  storageFacility: StorageFacility
  onSave: (storageFacility: StorageFacility) => void
}

export function StorageFacilitiesDetails(props: StorageFacilitiesDetailsProps) {
  const { storageFacility: persistedStorageFacility } = props
  const { t } = useTranslation()

  const [storageFacility, setStorageFacility] = useState<StorageFacility>(
    persistedStorageFacility,
  )
  const { validationErrorsMap, isValid } = useValidationErrorMap(
    storageFacility,
    StorageFacilityValidation,
  )

  const onReset = () => {
    setStorageFacility(persistedStorageFacility)
  }

  const onSave = () => {
    if (isValid) {
      props.onSave(storageFacility)
    }
  }

  return (
    <Fragment>
      <TextInput
        error={validationErrorsMap.name}
        value={storageFacility.name}
        onChange={(value) =>
          setStorageFacility({ ...storageFacility, name: value })
        }
        label={t('name')}
      />
      <Typography variant="body1" gutterBottom>
        {t('external')}
        <Checkbox
          checked={storageFacility.external}
          onChange={(e) =>
            setStorageFacility({
              ...storageFacility,
              external: e.target.checked,
            })
          }
        />
      </Typography>
      <ExpandableAddress
        title={t('address.label')}
        address={storageFacility.address}
        onChangeAddress={(value) =>
          setStorageFacility({ ...storageFacility, address: value })
        }
      />
      <br />
      <Button onClick={onSave} variant="contained" disabled={!isValid}>
        {t('save')}
      </Button>
      <Button onClick={onReset} variant="contained">
        {t('reset')}
      </Button>
    </Fragment>
  )
}

export default StorageFacilitiesDetails
