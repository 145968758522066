import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { Query, QueryGetCustomRequestArgs } from '@/schemaTypes'

const GET_CUSTOM_REQUEST = gql`
  query getCustomRequest($customRequestId: ObjectId!) {
    getCustomRequest(customRequestId: $customRequestId) {
      _id
      createdAt
      updatedAt
      note
      noteToCustomer
      events {
        event
        createdAt
        timestamp
        responsibleAdminId
      }
      dealData {
        selected {
          durationInDays
          payoutAmount
        }
        verified {
          durationInDays
          payoutAmount
        }
      }
      itemsData {
        selected {
          title
          itemCategoryId
          condition
          isBulkyGood
          note
          policeNumber
          media {
            ...FileMetadataFragment
          }
          itemValuesEntry {
            adjustedMarketValue
            loanToValue
          }
          vehicleData {
            vehicleProperties {
              make
              model
              regdate
              fuel
              horsePower
              odometer
            }
          }
        }
        verified {
          title
          itemCategoryId
          condition
          isBulkyGood
          note
          policeNumber
          media {
            ...FileMetadataFragment
          }
          itemValuesEntry {
            adjustedMarketValue
            loanToValue
          }
          vehicleData {
            vehicleProperties {
              make
              model
              regdate
              fuel
              horsePower
              odometer
            }
          }
        }
      }
      customer {
        email
        firstname
        lastname
        phone
        dateOfBirth
      }

      acceptedBy
      declinedBy
    }
  }
  ${fragments.fileMetadata}
`

type TData = { getCustomRequest: Query['getCustomRequest'] }
type TVariables = QueryGetCustomRequestArgs

export function useGetCustomRequest(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_CUSTOM_REQUEST, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.customRequestId,
  })

  useShowErrorsPopup(queryResult.error)

  const customRequest = opts.variables?.customRequestId
    ? queryResult.data?.getCustomRequest
    : null

  return { queryResult, customRequest }
}
