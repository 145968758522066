import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetItemQuestionsByCategoryV2 } from '@/domains/items/hooks'
import InputDropdown from '@/redesign/components/InputDropdown/InputDropdown'
import InputNum from '@/redesign/components/InputNumber/InputNumber'
import {
  EQuestionType,
  ItemAnswerArgs,
  ItemQuestion,
  ProductLegacy,
  Scalars,
} from '@/schemaTypes'
import { buildQuestionsDefaultAnswer, oneOf } from '@/utils/misc'

interface ItemCategoryQuestionsComponentProps {
  itemCategoryId: Scalars['ObjectId'] // Used to load questions
  itemAnswerArgs: ItemAnswerArgs[]
  setItemAnswerArgs: (value: ItemAnswerArgs[]) => void
  isReadOnly: boolean
  questions?: ItemQuestion[] // Sometimes we have the questions
  productLegacy?: ProductLegacy
  hideConditionQuestion?: boolean
  loadCurrentAnswers?: boolean
  disabledQuestionsIds?: string[]
  infoIconVisible?: boolean
  omitDeactivatedQuestionsForNewProducts: boolean
}

const ItemCategoryQuestions: FunctionComponent<
  ItemCategoryQuestionsComponentProps
> = ({
  itemCategoryId,
  questions,
  itemAnswerArgs,
  setItemAnswerArgs,
  isReadOnly,
  productLegacy,
  hideConditionQuestion,
  loadCurrentAnswers,
  disabledQuestionsIds,
  infoIconVisible = false,
  omitDeactivatedQuestionsForNewProducts,
}) => {
  const { t } = useTranslation()

  const { getItemQuestionsByCategoryV2, itemQuestions } =
    useGetItemQuestionsByCategoryV2({
      onCompleted: () => {
        if (!questions || questions.length === 0) {
          setItemAnswerArgs(
            buildQuestionsDefaultAnswer({
              questions: itemQuestions,
              t,
              productLegacy,
              hideConditionQuestion,
            }),
          )

          if (loadCurrentAnswers) setItemAnswerArgs(itemAnswerArgs)
        }
      },
      skip: questions && questions.length > 0,
    })

  useEffect(() => {
    if ((!questions || questions.length === 0) && itemCategoryId) {
      getItemQuestionsByCategoryV2({
        variables: {
          itemCategoryId: itemCategoryId,
          date: dayjs().toDate(),
          omitDeactivatedQuestionsForNewProducts,
        },
      })
    }
  }, [
    itemCategoryId,
    productLegacy,
    getItemQuestionsByCategoryV2,
    questions,
    omitDeactivatedQuestionsForNewProducts,
  ])

  const onQuestionAnswer = (question: ItemQuestion, value: number) => {
    setItemAnswerArgs(
      itemAnswerArgs.map((answerArg) => {
        if (isEqual(answerArg.questionId, question._id)) {
          return {
            ...answerArg,
            selectedOptionIndex:
              question.questionType === EQuestionType.SingleChoice
                ? value
                : undefined,
            rangeValue:
              question.questionType === EQuestionType.Range ? value : undefined,
          }
        } else return answerArg
      }),
    )
  }

  return (
    <>
      {itemAnswerArgs.map((answerArg) => {
        let infoText = ''
        const question = (itemQuestions ?? questions)?.find((question) =>
          isEqual(question._id, answerArg.questionId),
        )

        if (!question) return null

        if (
          typeof answerArg.selectedOptionIndex === 'number' &&
          question.singleChoiceOptions
        ) {
          const option =
            question.singleChoiceOptions[answerArg.selectedOptionIndex]
          infoText = t(option?.infoKey || '')
        } else if (
          typeof answerArg.rangeValue === 'number' &&
          question.rangeData
        ) {
          infoText = question.infoKey
            ? t(question.infoKey) ?? question.infoKey
            : ''
        }

        const value =
          question?.questionType === EQuestionType.SingleChoice
            ? answerArg.selectedOptionIndex
            : answerArg.rangeValue

        return (
          <div key={answerArg.questionId} className="flex flex-row">
            {question.questionType === EQuestionType.SingleChoice &&
              question.singleChoiceOptions && (
                <InputDropdown
                  label={t(question.titleKey)}
                  disabled={
                    isReadOnly || oneOf(disabledQuestionsIds, question._id)
                  }
                  className="flex flex-col md:flex-row items-start md:items-center mb-2"
                  labelClassName="text-sm font-semibold w-12.5"
                  inputContainerClassName="w-52"
                  value={value}
                  options={question.singleChoiceOptions.map((option, i) => ({
                    label: t(option.labelKey),
                    value: i,
                  }))}
                  onChange={(e) => onQuestionAnswer(question, e.value)}
                  tooltipText={infoIconVisible ? infoText : undefined}
                />
              )}

            {question.questionType === EQuestionType.Range && (
              <InputNum
                label={t(question.titleKey)}
                disabled={
                  isReadOnly || oneOf(disabledQuestionsIds, question._id)
                }
                className="flex flex-col md:flex-row items-start md:items-center mb-2"
                labelClassName="text-sm font-semibold w-12.5"
                inputContainerClassName="w-52"
                value={value}
                key={question._id}
                tooltipText={infoIconVisible ? infoText : undefined}
                onChange={(e) => {
                  let value = Number(e.value)
                  const min = question.rangeData?.minValue
                  const max = question.rangeData?.maxValue

                  if (typeof min !== 'undefined' && min !== null) {
                    value = Math.max(value, Number(min))
                  }

                  if (typeof max !== 'undefined' && max !== null) {
                    value = Math.min(value, Number(max))
                  }

                  onQuestionAnswer(question, value)
                }}
                mode="decimal"
              />
            )}
          </div>
        )
      })}
    </>
  )
}

export default ItemCategoryQuestions
