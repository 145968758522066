import ShopsCreate from '../../../pages/InApp/Configurations/Shops/create'
import { useCreateShop, useGetShops } from '../../shops/hooks'
import { useGetActiveCompanies } from '../hooks'
import { useDisablePostalCode } from '../hooks/disablePostalCode'
import { useEnablePostalCodes } from '../hooks/enablePostalCodes'
import { useGetEnabledPostalCodes } from '../hooks/getEnabledPostalCodes'
import {
  Box,
  Button,
  Chip,
  Dialog,
  InputAdornment,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import ClearIcon from '@material-ui/icons/Clear'
import { omit } from 'lodash'
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import ExpandableAddress from '@/components/ExpandableAddress/ExpandableAddress'
import ExpansionBox from '@/components/ExpansionBox/ExpansionBox'
import Loading from '@/components/Loading'
import SelectOptionInput from '@/components/SelectOptionInput'
import TextInput from '@/components/TextInput'
import { Context } from '@/context'
import { defaultCompanyConfiguration } from '@/domains/deals/components/constants/company'
import { useMutationShowingErrors, useValidationErrorMap } from '@/hooks'
import {
  Company,
  CompanyCreateArgs,
  CompanySalesConfiguration,
  CompanyUnzerConfigurationArgs,
  DpdConfigurationArgs,
  IAddress,
  PrimaryShopLocationArgs,
} from '@/schemaTypes'
import { constructAddress } from '@/utils/address'
import { useNestedState } from '@/utils/misc'
import { CompanyValidation } from '@/validation'
import CompanyCounterDetails from './CompanyCounterDetails'
import CompanyDPDConfigurationDetails from './CompanyDPDConfigurationDetails'
import CompanySalesConfigurationDetails from './CompanySalesConfigurationDetails'
import CompanyUnzerConfigurationDetails from './CompanyUnzerConfigurationDetails'

const useStyles = makeStyles((theme) => ({
  addressContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  addressTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    '& button:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
    marginTop: theme.spacing(2),
  },
  configurationContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  configurationUnitContainer: {
    padding: theme.spacing(1),
  },
  configurationWrapper: {
    display: 'flex',
    gap: theme.spacing(20),
  },
  configurationSection: {},
  counterSection: {},
}))

export type CompanyValidationErrorObject = Partial<
  Pick<Company, 'name' | 'uid' | 'fibuNumber'>
>

export type CompanyArgs = Partial<
  CompanyCreateArgs &
    Pick<
      Company,
      '_id' | 'primaryShopLocationId' | 'companyCounter' | 'dpdAccountInfo'
    >
>

export interface CompanyValidationError {
  fieldName: keyof CompanyValidationErrorObject
  message: string
}

export interface CompaniesDetailsProps {
  company?: Partial<Company>
  onSave: (company: CompanyArgs, primaryShop?: PrimaryShopLocationArgs) => void
}

interface expansionBoxFilterInput {
  expandedInformation?: boolean
  expandedPaymentInformation?: boolean
  expandedContactInformation?: boolean
  expandedConfiguration?: boolean
  expandedEnabledPostalCodes?: boolean
  expandedEnabledApiKeys?: boolean
  expandedSalesConfiguration?: boolean
}

export function CompaniesDetails(props: CompaniesDetailsProps) {
  const { company: persistedCompany } = props
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const classes = useStyles()

  const [openAddNewShopLocationDialog, setOpenAddNewShopLocationDialog] =
    useState(false)
  const [company, setCompany] = useState<CompanyArgs | undefined>({
    ...persistedCompany,
    dpdConfiguration: convertAccountInfoToArgs(persistedCompany),
  })
  const [primaryShopLocationId, setPrimaryShopLocationId] = useState(
    company?.primaryShopLocationId,
  )
  const [newShop, setNewShop] = useState<PrimaryShopLocationArgs | undefined>()
  const expansionBoxFilter = useNestedState<expansionBoxFilterInput>(
    {},
    {
      persistent: 'expansionBoxFilter',
    },
  )

  const [newEnablePostalCodes, setNewEnablePostalCodes] = useState<
    string | null
  >(null)

  const handleExpansionBoxes = (value, name) => {
    if (name === 'expandedInformation') {
      expansionBoxFilter.setProperty('expandedInformation', value)
    } else if (name === 'expandedPaymentInformation') {
      expansionBoxFilter.setProperty('expandedPaymentInformation', value)
    } else if (name === 'expandedContactInformation') {
      expansionBoxFilter.setProperty('expandedContactInformation', value)
    } else if (name === 'expandedConfiguration') {
      expansionBoxFilter.setProperty('expandedConfiguration', value)
    } else if (name === 'expandedEnabledPostalCodes') {
      expansionBoxFilter.setProperty('expandedEnabledPostalCodes', value)
    } else if (name === 'expandedEnabledApiKeys') {
      expansionBoxFilter.setProperty('expandedEnabledApiKeys', value)
    } else if (name === 'expandedSalesConfiguration') {
      expansionBoxFilter.setProperty('expandedSalesConfiguration', value)
    }

    expansionBoxFilter.persist()
  }

  const { shops, queryResult } = useGetShops({
    fetchPolicy: 'network-only',
  })

  const { companies } = useGetActiveCompanies()

  const defaultShops = company?._id
    ? shops.filter((location) => location.companyId === company._id)
    : []
  const filteredCompanies = companies.filter((c) => c._id !== company?._id)

  const { validationErrorsMap, isValid } = useValidationErrorMap(
    company ?? {},
    CompanyValidation,
    newShop ? ['primaryShopLocationId'] : undefined,
  )

  const [postalCodeSearchText, setPostalCodeSearchText] = useState<
    string | null
  >()

  const { enablePostalCodes, queryResult: getEnabledPostalCodesQueryResult } =
    useGetEnabledPostalCodes({
      variables: {
        companyId: company?._id,
        text: postalCodeSearchText,
      },
      skip: !company?._id,
    })

  const disablePostalCode = useMutationShowingErrors({
    mutation: useDisablePostalCode(),
  })

  const createEnabledPostalCodes = useMutationShowingErrors({
    mutation: useEnablePostalCodes(),
  })

  const createShop = useMutationShowingErrors({
    mutation: useCreateShop(),
    successMessage: t('company.company_location_created'),
  })

  useEffect(() => {
    setOpenAddNewShopLocationDialog(false)
    setCompany({ ...(company ?? {}), primaryShopLocationId })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryShopLocationId])

  const onReset = () => {
    setCompany({
      ...persistedCompany,
      dpdConfiguration: convertAccountInfoToArgs(persistedCompany),
    })
  }

  const onSave = () => {
    if (!isValid) {
      return
    }

    if (!onValidateRefurbedStaggeredMargins(company)) {
      return showInfo(t('the_following_from_can_not_lower_than_previous_value'))
    }

    props.onSave(
      {
        ...company,
        unzerConfiguration: company.unzerConfiguration
          ? omit(company.unzerConfiguration, ['webhookId', 'privateKeyStored'])
          : undefined,
      },
      !company?._id ? newShop : undefined,
    )
  }

  const setSalesConfiguration = (value: CompanySalesConfiguration) => {
    setCompany((c) => ({
      ...c,
      salesConfiguration: value,
    }))
  }

  const setUnzerConfiguration = (value: CompanyUnzerConfigurationArgs) => {
    setCompany((c) => ({
      ...c,
      unzerConfiguration: value,
    }))
  }

  const handleCompanyHeadquarterChange = useCallback(
    (newAddress: IAddress) => {
      setCompany((prev) => ({ ...prev, headquarter: newAddress }))
    },
    [setCompany],
  )

  const handleAddNewShopLocation = () => {
    setOpenAddNewShopLocationDialog(true)
  }

  const onDeleteEnabledPostalCode = (id: string) => {
    disablePostalCode({
      variables: {
        id,
      },
      refetchQueries: ['getEnabledPostalCodes'],
    })
  }

  const onCreateNewPostalCodes = async () => {
    if (!newEnablePostalCodes || !company) {
      return
    }

    const newEnablePostalCodesList = newEnablePostalCodes.split(/[\s,]+/)

    const { error } = await createEnabledPostalCodes({
      variables: {
        postalCodes: newEnablePostalCodesList,
        companyId: company._id,
      },
      refetchQueries: ['getEnabledPostalCodes'],
    })

    if (!error) {
      setNewEnablePostalCodes(null)
    }
  }

  const AddNewShopLocationIcon = () => (
    <Tooltip title={t('create_shop_location')}>
      <AddCircleOutlineIcon
        onClick={handleAddNewShopLocation}
        fontSize="small"
        style={{ marginLeft: '1rem', cursor: 'pointer' }}
      />
    </Tooltip>
  )

  const onSaveShop = (companyLocation: PrimaryShopLocationArgs) => {
    setNewShop(companyLocation)
    setOpenAddNewShopLocationDialog(false)

    // We are on Edit Company page
    if (company?._id) {
      createShop({
        variables: {
          ...companyLocation,
          companyId: company._id,
        },
        onCompleted: (data) => {
          setPrimaryShopLocationId(data.createShop._id)
          queryResult.refetch()
        },
        refetchQueries: ['getShops'],
      })
    }
  }

  return (
    <Fragment>
      <ExpansionBox
        title={t('company_data')}
        name={'expandedInformation'}
        openAtLoad={expansionBoxFilter.values.expandedInformation ?? false}
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <TextInput
            error={validationErrorsMap.name}
            value={company?.name}
            onChange={(value) =>
              setCompany({ ...(company ?? {}), name: value })
            }
            label={t('name')}
          />
          <TextInput
            error={validationErrorsMap.uid}
            value={company?.uid}
            onChange={(value) => setCompany({ ...(company ?? {}), uid: value })}
            label={t('uid')}
          />
          <TextInput
            error={validationErrorsMap.fibuNumber}
            value={company?.fibuNumber}
            onChange={(value) =>
              setCompany({ ...(company ?? {}), fibuNumber: value })
            }
            label={t('company.company_number')}
          />
          <TextInput
            value={company?.jurisdiction ?? ''}
            onChange={(value) =>
              setCompany({ ...(company ?? {}), jurisdiction: value })
            }
            label={t('jurisdiction')}
          />
        </Paper>
      </ExpansionBox>
      <ExpansionBox
        title={t('payment_information')}
        name={'expandedPaymentInformation'}
        openAtLoad={
          expansionBoxFilter.values.expandedPaymentInformation ?? false
        }
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <TextInput
            value={company?.iban ?? ''}
            onChange={(value) =>
              setCompany({ ...(company ?? {}), iban: value })
            }
            label={t('customer.iban')}
          />
          <TextInput
            value={company?.bic ?? ''}
            onChange={(value) => setCompany({ ...(company ?? {}), bic: value })}
            label={t('bic')}
          />
          <TextInput
            value={company?.accountOwner ?? ''}
            onChange={(value) =>
              setCompany({ ...(company ?? {}), accountOwner: value })
            }
            label={t('account_owner')}
          />
          <TextInput
            error={validationErrorsMap.paypalEmail}
            value={company?.paypalEmail ?? ''}
            onChange={(value) =>
              setCompany({ ...(company ?? {}), paypalEmail: value })
            }
            label={t('paypal')}
          />
        </Paper>
      </ExpansionBox>
      <ExpansionBox
        title={t('contact_information')}
        name={'expandedContactInformation'}
        openAtLoad={
          expansionBoxFilter.values.expandedContactInformation ?? false
        }
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <TextInput
            error={validationErrorsMap.email}
            value={company?.email ?? ''}
            onChange={(value) =>
              setCompany({ ...(company ?? {}), email: value })
            }
            label={t('customer.email')}
          />
          <TextInput
            error={validationErrorsMap.supportEmail}
            value={company?.supportEmail ?? ''}
            onChange={(value) =>
              setCompany({ ...(company ?? {}), supportEmail: value })
            }
            label={t('support_email')}
          />
          <TextInput
            error={validationErrorsMap.saleSupportEmail}
            value={company?.saleSupportEmail ?? ''}
            onChange={(value) =>
              setCompany({ ...(company ?? {}), saleSupportEmail: value })
            }
            label={t('sale_support_email')}
          />
          <TextInput
            error={validationErrorsMap.whatsappNumber}
            value={company?.whatsappNumber ?? ''}
            onChange={(value) =>
              setCompany({ ...(company ?? {}), whatsappNumber: value })
            }
            label={t('whatsapp_number')}
          />
          <TextInput
            error={validationErrorsMap.phoneNumber}
            value={company?.phoneNumber ?? ''}
            onChange={(value) =>
              setCompany({ ...(company ?? {}), phoneNumber: value })
            }
            label={t('phone_number')}
          />
          <TextInput
            value={company?.website ?? ''}
            onChange={(value) =>
              setCompany({ ...(company ?? {}), website: value })
            }
            label={t('website')}
          />
          <Typography variant="h6" className={classes.addressTitle}>
            {t('office_address')}
          </Typography>
          <ExpandableAddress
            title=""
            onChangeAddress={handleCompanyHeadquarterChange}
            address={company?.headquarter ?? constructAddress()}
          />
        </Paper>
      </ExpansionBox>
      <ExpansionBox
        title={t('configuration')}
        name={'expandedConfiguration'}
        openAtLoad={expansionBoxFilter.values.expandedConfiguration ?? false}
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <div className={classes.configurationWrapper}>
            <div className={classes.configurationSection}>
              <Box className={classes.configurationContainer}>
                <TextInput
                  type="number"
                  error={validationErrorsMap.configuration?.minimumPawnDuration}
                  value={company?.configuration?.minimumPawnDuration.toString()}
                  onChange={(value) =>
                    setCompany((c) => ({
                      ...c,
                      configuration: {
                        ...(c?.configuration ?? defaultCompanyConfiguration),
                        minimumPawnDuration: parseInt(value),
                      },
                    }))
                  }
                  label={t('minimum_pawn_duration')}
                />
                <Typography className={classes.configurationUnitContainer}>
                  {t('days')}
                </Typography>
              </Box>
              <Box className={classes.configurationContainer}>
                <TextInput
                  type="number"
                  error={validationErrorsMap.configuration?.gracePeriod}
                  value={company?.configuration?.gracePeriod.toString()}
                  onChange={(value) =>
                    setCompany((c) => ({
                      ...c,
                      configuration: {
                        ...(c?.configuration ?? defaultCompanyConfiguration),
                        gracePeriod: parseInt(value),
                      },
                    }))
                  }
                  label={t('grace_period')}
                />
                <Typography className={classes.configurationUnitContainer}>
                  {t('days')}
                </Typography>
              </Box>
              <TextInput
                type="number"
                error={validationErrorsMap.configuration?.insuranceFactor}
                value={company?.configuration?.insuranceFactor.toString()}
                inputProps={{ step: 0.1 }}
                onChange={(value) =>
                  setCompany((c) => ({
                    ...c,
                    configuration: {
                      ...(c?.configuration ?? defaultCompanyConfiguration),
                      insuranceFactor: parseFloat(value),
                    },
                  }))
                }
                label={t('insurance_factor')}
              />
              <Box className={classes.configurationContainer}>
                <TextInput
                  type="number"
                  error={validationErrorsMap.configuration?.liquidationFee}
                  value={company?.configuration?.liquidationFee.toString()}
                  onChange={(value) =>
                    setCompany((c) => ({
                      ...c,
                      configuration: {
                        ...(c?.configuration ?? defaultCompanyConfiguration),
                        liquidationFee: parseFloat(value),
                      },
                    }))
                  }
                  label={t('liquidation_fee')}
                />
              </Box>
              <Box className={classes.configurationContainer}>
                <TextInput
                  type="number"
                  error={validationErrorsMap.configuration?.valueAddedTaxRate}
                  value={company?.configuration?.valueAddedTaxRate.toString()}
                  onChange={(value) =>
                    setCompany((c) => ({
                      ...c,
                      configuration: {
                        ...(c?.configuration ?? defaultCompanyConfiguration),
                        valueAddedTaxRate: parseFloat(value),
                      },
                    }))
                  }
                  label={t('value_added_tax')}
                />
              </Box>
              <Box className={classes.configurationContainer}>
                <TextInput
                  type="number"
                  error={validationErrorsMap.configuration?.differenceTaxRate}
                  value={company?.configuration?.differenceTaxRate.toString()}
                  onChange={(value) =>
                    setCompany((c) => ({
                      ...c,
                      configuration: {
                        ...(c?.configuration ?? defaultCompanyConfiguration),
                        differenceTaxRate: parseFloat(value),
                      },
                    }))
                  }
                  label={t('cash_book_count.difference_tax')}
                />
              </Box>
              <Box className={classes.configurationContainer}>
                <TextInput
                  type="number"
                  error={validationErrorsMap.configuration?.valorizationFeeRate}
                  value={company?.configuration?.valorizationFeeRate.toString()}
                  onChange={(value) =>
                    setCompany((c) => ({
                      ...c,
                      configuration: {
                        ...(c?.configuration ?? defaultCompanyConfiguration),
                        valorizationFeeRate: parseFloat(value),
                      },
                    }))
                  }
                  label={t('valorization.fee')}
                />
              </Box>
              <SelectOptionInput
                value={company?.successionCompanyId}
                onChange={(value) => {
                  setCompany({ ...(company ?? {}), successionCompanyId: value })
                }}
                menuItems={filteredCompanies.map((c) => ({
                  label: c.name,
                  value: c._id,
                }))}
                label={t('succession_company')}
                hasNone
              />
              <Box className={classes.configurationContainer}>
                <TextInput
                  type="number"
                  error={validationErrorsMap.configuration?.payoutLimit}
                  value={company?.configuration?.payoutConfigurations?.payoutLimit?.toString()}
                  onChange={(value) =>
                    setCompany((c) => ({
                      ...c,
                      configuration: {
                        ...(c?.configuration ?? defaultCompanyConfiguration),
                        payoutConfigurations: {
                          payoutLimit: value ? parseFloat(value) : null,
                          carPayoutLimit:
                            c?.configuration?.payoutConfigurations
                              ?.carPayoutLimit,
                        },
                      },
                    }))
                  }
                  label={t('default_payout_limit')}
                />
                <Typography className={classes.configurationUnitContainer}>
                  €
                </Typography>
              </Box>

              <Box className={classes.configurationContainer}>
                <TextInput
                  type="number"
                  error={validationErrorsMap.configuration?.carPayoutLimit}
                  value={company?.configuration?.payoutConfigurations?.carPayoutLimit?.toString()}
                  onChange={(value) =>
                    setCompany((c) => ({
                      ...c,
                      configuration: {
                        ...(c?.configuration ?? defaultCompanyConfiguration),
                        payoutConfigurations: {
                          carPayoutLimit: value ? parseFloat(value) : null,
                          payoutLimit:
                            c?.configuration?.payoutConfigurations?.payoutLimit,
                        },
                      },
                    }))
                  }
                  label={t('car_payout_limit')}
                />
                <Typography className={classes.configurationUnitContainer}>
                  €
                </Typography>
              </Box>
            </div>
            <div className={classes.counterSection}>
              {company?.companyCounter && (
                <CompanyCounterDetails
                  companyCounter={company?.companyCounter}
                />
              )}
              <Box marginY={'1rem'}>
                <Typography variant="h6" gutterBottom>
                  {t('primary_shop_location')}
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                  }}
                >
                  {defaultShops.length ? (
                    <SelectOptionInput
                      error={validationErrorsMap.primaryShopLocationId}
                      value={primaryShopLocationId}
                      onChange={(value) => {
                        setPrimaryShopLocationId(value)
                        setCompany({
                          ...(company ?? {}),
                          primaryShopLocationId: primaryShopLocationId,
                        })
                      }}
                      menuItems={defaultShops.map((location) => ({
                        label: location.name,
                        value: location._id,
                      }))}
                    />
                  ) : (
                    <>
                      {newShop ? (
                        <Typography>{newShop.name}</Typography>
                      ) : (
                        <Typography color="error" gutterBottom>
                          {t('create_new_shop_location_for_company')}
                        </Typography>
                      )}
                    </>
                  )}
                  <AddNewShopLocationIcon />
                </Box>
              </Box>
            </div>
          </div>
        </Paper>
      </ExpansionBox>

      <ExpansionBox
        title={t('api_keys')}
        name={'expandedEnabledApiKeys'}
        openAtLoad={expansionBoxFilter.values.expandedEnabledApiKeys ?? false}
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <CompanyUnzerConfigurationDetails
            persistedCompany={persistedCompany}
            unzerConfiguration={company?.unzerConfiguration}
            setUnzerConfiguration={setUnzerConfiguration}
            validationErrorsMap={validationErrorsMap.unzerconfiguration}
          />
        </Paper>
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <CompanyDPDConfigurationDetails
            shops={shops}
            company={company}
            onCompanyChange={setCompany}
            validationErrorsMap={validationErrorsMap.dpdConfiguration}
          />
        </Paper>
      </ExpansionBox>
      <ExpansionBox
        title={t('pickup_configurations')}
        name={'expandedEnabledPostalCodes'}
        openAtLoad={
          expansionBoxFilter.values.expandedEnabledPostalCodes ?? false
        }
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <Typography variant="h6" className={classes.addressTitle}>
            {t('enabled_postal_codes')}
          </Typography>
          <TextInput
            value={postalCodeSearchText}
            placeholder="Search by postal name or postal code"
            onChange={(value) => setPostalCodeSearchText(value)}
            variant="outlined"
            style={{ marginBottom: 20 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ClearIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setPostalCodeSearchText('')}
                  />
                </InputAdornment>
              ),
            }}
          />
          {getEnabledPostalCodesQueryResult.loading ? (
            <Loading />
          ) : (
            <Box>
              {enablePostalCodes?.map((code) => (
                <Chip
                  label={`${code.postalCode} ${code.postalName}`}
                  onDelete={() => onDeleteEnabledPostalCode(code._id)}
                  style={{ marginRight: 10, marginBottom: 10 }}
                />
              ))}
            </Box>
          )}

          <Typography variant="h6" style={{ marginTop: 30 }}>
            {t('add_more')}
          </Typography>
          <Box
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
            }}
          >
            <TextInput
              inputWidth="500px"
              multiline
              rowsMax={5}
              value={newEnablePostalCodes ?? ''}
              placeholder="Paste codes here (separated by commas, spaces or new lines)"
              onChange={(value) => setNewEnablePostalCodes(value)}
              variant="outlined"
            />
            <AddCircleOutlineIcon
              onClick={onCreateNewPostalCodes}
              fontSize="small"
              style={{ cursor: 'pointer', marginLeft: '1rem' }}
            />
            <Typography style={{ marginLeft: '0.5rem' }}>
              Add to enabled postal codes
            </Typography>
          </Box>
        </Paper>
      </ExpansionBox>

      <ExpansionBox
        title={t('sales_configuration')}
        name={'expandedSalesConfiguration'}
        openAtLoad={
          expansionBoxFilter.values.expandedSalesConfiguration ?? false
        }
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <CompanySalesConfigurationDetails
            salesConfiguration={company?.salesConfiguration}
            setSalesConfiguration={setSalesConfiguration}
            validationErrorsMap={validationErrorsMap.salesConfiguration}
          />
        </Paper>
      </ExpansionBox>

      <Box className={classes.buttonGroup}>
        <Button onClick={onSave} variant="contained" disabled={!isValid}>
          {t('save')}
        </Button>
        <Button onClick={onReset} variant="contained">
          {t('reset')}
        </Button>
      </Box>

      {openAddNewShopLocationDialog && (
        <Dialog
          open={openAddNewShopLocationDialog}
          onClose={() => setOpenAddNewShopLocationDialog(false)}
        >
          <ShopsCreate
            requireCompanyId={false}
            shopId={null}
            isDialog={true}
            onSave={onSaveShop}
          />
        </Dialog>
      )}
    </Fragment>
  )
}

const onValidateRefurbedStaggeredMargins = (
  company: Partial<Company> | undefined,
) => {
  return company?.salesConfiguration?.refurbedStaggeredMargins.every(
    (item, index) =>
      index === 0 ||
      item.from >=
        company!.salesConfiguration!.refurbedStaggeredMargins[index - 1].from,
  )
}

const convertAccountInfoToArgs = (
  companyArgs: CompanyArgs,
): DpdConfigurationArgs => {
  return {
    accountName: companyArgs.dpdAccountInfo?.username,
    accountToken: companyArgs.dpdConfiguration?.accountToken,
    shopSubAccounts: companyArgs.dpdAccountInfo?.subAccountsInfo?.map(
      (c, index) => {
        return {
          shopId: c.shopId,
          userId: c.username,
          userToken:
            companyArgs.dpdConfiguration?.shopSubAccounts[index]?.userToken,
        }
      },
    ),
  }
}

export default CompaniesDetails
