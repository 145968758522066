import { manualPaymentTypes } from '../PaymentTypePicker'
import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EManualPaymentType, PaymentData } from '@/schemaTypes'
import { paymentTypeUnion } from '@/utils/misc'
import styles from './ValorizationHistoryEntry.module.scss'
import ValorizationHistoryEntryTable, {
  ValorizationHistoryEntryTableRow,
} from './ValorizationHistoryEntryTable'

type ValorizationHistoryEntryPaymentDataRow = Pick<
  ValorizationHistoryEntryTableRow<keyof PaymentData>,
  'label' | 'type' | 'key' | 'hidden' | 'options' | 'disabled'
>

export interface ValorizationHistoryEntryPaymentDataProps {
  className?: string
  paymentData: PaymentData | null
  columnWidths: { label: number; value: number }
  onChangePaymentData: (updateData: Partial<PaymentData> | null) => void
  isReadonly: boolean
}

export function ValorizationHistoryEntryPaymentData(
  props: ValorizationHistoryEntryPaymentDataProps,
) {
  const {
    className,
    paymentData,
    columnWidths,
    onChangePaymentData,
    isReadonly,
  } = props
  const { t } = useTranslation()

  const buildRow = (
    params: ValorizationHistoryEntryPaymentDataRow,
  ): ValorizationHistoryEntryTableRow<keyof PaymentData> => {
    const { key } = params
    return {
      ...params,
      renderKey: key,
      value: paymentData
        ? key === 'paymentType'
          ? paymentTypeUnion(paymentData[key])
          : paymentData[key]
        : undefined,
      onChange: params.disabled
        ? undefined
        : (value) => {
            let updateData
            if (key === 'paymentType') {
              if (value && value !== t('no_information')) {
                updateData = {
                  ...(paymentData ?? {}),
                  [key]: { manual: value },
                }
              }
            } else {
              updateData = { ...(paymentData ?? {}), [key]: value }
            }

            onChangePaymentData(updateData)
          },
    }
  }

  const rowsData: ValorizationHistoryEntryPaymentDataRow[] = [
    {
      label: `${t('payment_method')}*`,
      key: 'paymentType',
      type: 'string',
      options: [
        { label: t('no_information'), value: undefined },
        ...manualPaymentTypes.map((c) => {
          return { label: t(c.label.toLowerCase()), value: c.value }
        }),
      ],
      disabled: isReadonly,
    },
    {
      label: t('customer.iban'),
      key: 'iban',
      type: 'string',
      hidden:
        paymentTypeUnion(paymentData?.paymentType) !== EManualPaymentType.Bank,
      disabled: isReadonly,
    },
    {
      label: t('paypal_address'),
      key: 'paypalAddress',
      type: 'string',
      hidden:
        paymentTypeUnion(paymentData?.paymentType) !==
        EManualPaymentType.Paypal,
      disabled: isReadonly,
    },
    {
      label: `${t('paypal_transaction_id')}`,
      key: 'paypalTransactionId',
      type: 'string',
      hidden:
        paymentTypeUnion(paymentData?.paymentType) !==
        EManualPaymentType.Paypal,
      disabled: isReadonly,
    },
  ]
  const rows = rowsData.map((rowData) => buildRow(rowData))

  return (
    <section className={className ?? ''}>
      <Typography
        className={`${styles.middleBold}`}
        variant="body1"
        gutterBottom
      >
        {t('buyer_payment_data')}
      </Typography>
      <ValorizationHistoryEntryTable rows={rows} columnWidths={columnWidths} />
    </section>
  )
}

// Prevent rerender if parent rerendered and shallow child props comparison did not find differences.
export default React.memo(ValorizationHistoryEntryPaymentData)
