import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateCarInspectionArgs } from '@/schemaTypes'

const UPDATE_CAR_INSPECTION = gql`
  mutation updateCarInspection($args: CarInspectionArgs!) {
    updateCarInspection(args: $args) {
      _id
      dealFinalValues {
        isOnlineExtensionPrevented
      }
    }
  }
`

type TData = { updateCarInspection: Mutation['updateCarInspection'] }
type TVariables = MutationUpdateCarInspectionArgs

export function useUpdateCarInspection(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateCarInspection] = useMutation<TData, TVariables>(
    UPDATE_CAR_INSPECTION,
    {
      refetchQueries: ['getDealById'],
      ...opts,
    },
  )

  return updateCarInspection
}
