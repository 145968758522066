import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Mutation,
  MutationOverwriteCustomDealPayoutAmountToCustomerRequestedArgs,
} from '@/schemaTypes'

const OVERWRITE_CUSTOM_DEAL_PAYOUT = gql`
  mutation overwriteCustomDealPayoutAmountToCustomerRequested(
    $customDealId: ObjectId!
  ) {
    overwriteCustomDealPayoutAmountToCustomerRequested(
      customDealId: $customDealId
    ) {
      _id
    }
  }
`

type TData = {
  overwriteCustomDealPayoutAmountToCustomerRequested: Mutation['overwriteCustomDealPayoutAmountToCustomerRequested']
}
type TVariables = MutationOverwriteCustomDealPayoutAmountToCustomerRequestedArgs

export function useOverwriteCustomDealPayoutAmountToCustomerRequested(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [overwriteCustomDealPayoutAmountToCustomerRequested] = useMutation<
    TData,
    TVariables
  >(OVERWRITE_CUSTOM_DEAL_PAYOUT, {
    refetchQueries: ['getCustomDealById'],
    ...opts,
  })
  return overwriteCustomDealPayoutAmountToCustomerRequested
}
