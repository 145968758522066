import dayjs, { Dayjs } from 'dayjs'
import { ValidFromToFieldName } from '@/types'

const hourChangeConfig: Record<
  ValidFromToFieldName,
  { hour: number; minute: number; second: number; millisecond: number }
> = {
  validFrom: {
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  },
  validTo: {
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
  },
  date: {
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  },
  dateOfBirth: {
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  },
  checkedAt: {
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  },
}

export function useValidFromToDateChange(
  changeFunc: (newDate: Date | null, fieldName: ValidFromToFieldName) => void,
) {
  return (
    date: Dayjs | null,
    model: Partial<Record<ValidFromToFieldName, Date>>,
    fieldName: ValidFromToFieldName,
  ) => {
    const previousDate =
      model[fieldName]?.getTime && !isNaN(model[fieldName]!.getTime())
        ? dayjs(model[fieldName])
        : null
    const newDate = date
      ? dayjs(date)
          .set(
            'hour',
            previousDate
              ? previousDate.get('hour')
              : hourChangeConfig[fieldName].hour,
          )
          .set('minute', hourChangeConfig[fieldName].minute)
          .set('second', hourChangeConfig[fieldName].second)
          .set('millisecond', hourChangeConfig[fieldName].millisecond)
          .toDate()
      : date

    changeFunc(newDate as Date, fieldName)
  }
}
