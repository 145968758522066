import React from 'react'
import Spinner from 'react-spinkit'
import styles from './Loading.module.scss'

export interface LoadingProps {
  position?: 'absolute' | 'relative'
}

const Loading = (props: LoadingProps) => (
  <div
    id="Loading"
    className={styles.root}
    style={{ position: props.position || 'relative' }}
  >
    <Spinner name="line-scale" color="#5017f4" fadeIn="none" {...props} />
  </div>
)

export default Loading
