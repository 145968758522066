import { Button, Typography } from '@material-ui/core'
import { DialogProps } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import AddIcon from '@material-ui/icons/Add'
import dayjs from 'dayjs'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Loading from '@/components/Loading'
import LoadingSpinner from '@/components/LoadingSpinner'
import { Context } from '@/context'
import AddAuctionElement from '@/domains/auctions/components/Auction/AddAuctionElement'
import AuctionElementsViewer from '@/domains/auctions/components/Auction/AuctionElementComponents'
import AuctionGeneralInfo from '@/domains/auctions/components/Auction/AuctionGeneralInfo'
import AuctionSaveConfirmation from '@/domains/auctions/components/Auction/AuctionSaveCofirmation'
import styles from '@/domains/auctions/components/AuctionDetails.module.scss'
import { useCreateAuction } from '@/domains/auctions/hooks/auctionDetails/addAuction'
import { useGetAuctionDeals } from '@/domains/auctions/hooks/auctionDetails/getAuctionDeals'
import { useMutationShowingErrors } from '@/hooks'
import { AuctionElement, EAuctionCreationType } from '@/schemaTypes'

export interface AddAuctionProps {}

export const AddAuction: React.FC<AddAuctionProps> = () => {
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const [auctionDate, setAuctionDate] = useState(dayjs())
  const [shopId, setShopId] = useState<string | null>(null)
  const [companyId, setCompanyId] = useState<string | null>(null)
  const [finalElements, setFinalElements] = useState<AuctionElement[]>([])
  const [open, setOpen] = React.useState(false)
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper')
  const [isNotifyCustomerChecked, setNotifyCustomer] = useState<boolean>(false)
  const [defaultConfirmationCheckBox, setDefaultConfirmationCheckBox] =
    useState(false)
  const [addOrUpdateIsInProgress, setAddOrUpdateIsInProgress] =
    useState<boolean>(false)
  const [isSuggestedDealsLoadingFinished, setIsSuggestedDealsLoadingFinished] =
    useState(false)
  const [suggestedDealsCount, setSuggestedDealsCount] = useState(0)

  const { deals, queryResult } = useGetAuctionDeals({
    variables: {
      opts: {
        filter: {
          ids: finalElements.map((e) => e.dealId),
        },
      },
      auctionDate: auctionDate?.toDate(),
    },
    skip: !finalElements.length,
  })

  const history = useHistory()

  const deleteAuctionElement = (dealId) => {
    const newList = finalElements.filter((c) => c.dealId !== dealId)
    setFinalElements(newList)
    setDefaultConfirmationCheckBox(false)
    setNotifyCustomer(false)
  }

  const HandleClose = (inputFinalElements) => {
    setDefaultConfirmationCheckBox(false)
    setNotifyCustomer(false)
    setOpen(false)

    if (inputFinalElements !== null && inputFinalElements !== undefined)
      setFinalElements(inputFinalElements)
  }

  const addElementHandler = (scrollType: DialogProps['scroll']) => () => {
    if (!auctionDate) showInfo(t('loan.enter_date'))
    else {
      setOpen(true)
      setScroll(scrollType)
    }
    setDefaultConfirmationCheckBox(false)
    setNotifyCustomer(false)
  }

  const onReload = () => {
    setFinalElements([])
    setAuctionDate(dayjs())
    setShopId('')
    setDefaultConfirmationCheckBox(false)
    setNotifyCustomer(false)
  }

  const onNotifyCustomerChanged = (value) => {
    setNotifyCustomer(value)
  }

  const addAuctionToDB = useMutationShowingErrors({
    mutation: useCreateAuction(),
    successMessage: `${t('auction.auction_added_successfully')}!`,
  })

  const onSave = () => {
    if (!auctionDate) {
      return showInfo(t('auction.auction_invalid_date'))
    } else if (!shopId) {
      return showInfo(t('auction.auction_invalid_location'))
    } else if (!finalElements || finalElements.length <= 0) {
      return showInfo(t('auction.auction_invalid_elements'))
    }

    setAddOrUpdateIsInProgress(true)
    addAuctionToDB({
      variables: {
        args: {
          auctionDate: auctionDate,
          createdAt: new Date(),
          creationType: EAuctionCreationType.Manual,
          shopId: shopId,
          companyId,
          auctionElementCreateArgs: finalElements,
        },
        shouldNotifyCustomers: isNotifyCustomerChecked,
      },
    }).then(
      (c) => (
        c.data?.createAuction
          ? (history.push('/inApp/auctions'), setAddOrUpdateIsInProgress(false))
          : setDefaultConfirmationCheckBox(false),
        setNotifyCustomer(false),
        setAddOrUpdateIsInProgress(false)
      ),
    )
  }

  const onDelete = () => {
    // we do nothing becaus adding an auction does not have a delete button
  }

  return (
    <div className={`u-12/12 u-mb-sm`}>
      <Typography variant="h5" gutterBottom className={'u-flex u-jc-sb'}>
        <div className={'u-inline-block'} style={{ marginRight: 'auto' }}>
          {`${t('auction.add_auction')} `}
        </div>
      </Typography>

      <AuctionGeneralInfo
        isEditable={true}
        setAuctionDate={setAuctionDate}
        setShopId={setShopId}
        setCompanyId={setCompanyId}
        companyId={companyId}
        auctionDate={auctionDate}
        shopId={shopId}
      />

      <Paper className="u-p-sm u-12/12 u-mb-sm">
        <div>
          <div className={`${styles.inlineDivs}`}>
            <Typography
              variant="h6"
              gutterBottom
              classes={{ root: styles.title }}
            >
              {t('auction.auction_elements')}
            </Typography>
          </div>
          <div className={`${styles.inlineDivs}`}>
            <Typography
              variant="h6"
              gutterBottom
              classes={{ root: styles.title }}
            >
              <div className="u-ml-sm u-inline-block">
                {isSuggestedDealsLoadingFinished ? (
                  <div className={`${styles.spinner} u-ml-sm u-inline-block`}>
                    <LoadingSpinner />
                  </div>
                ) : (
                  <Button
                    disabled={suggestedDealsCount === 0}
                    className={`${styles.button} u-ml-sm`}
                    onClick={addElementHandler('paper')}
                  >
                    <AddIcon />
                  </Button>
                )}
              </div>
            </Typography>
          </div>
        </div>
        {queryResult.loading ? (
          <Loading />
        ) : (
          <AuctionElementsViewer
            isEditable={true}
            deals={deals}
            auctionElements={finalElements}
            deleteAuctionElement={deleteAuctionElement}
          />
        )}
        {/* We send a copy inside to avoid changing our original version of finalElements */}
        <AddAuctionElement
          open={open}
          scroll={scroll}
          handleClose={HandleClose}
          auctionDate={auctionDate}
          auctionElements={[...finalElements]}
          companyId={companyId}
          setIsSuggestedDealsLoadingFinished={
            setIsSuggestedDealsLoadingFinished
          }
          setSuggestedDealsCount={setSuggestedDealsCount}
        />
      </Paper>

      <Paper className="u-p-sm u-12/12 u-mb-sm">
        <AuctionSaveConfirmation
          isNotifyCustomerChecked={isNotifyCustomerChecked}
          onNotifyCustomerChanged={onNotifyCustomerChanged}
          onSave={onSave}
          onReload={onReload}
          isComponentUsedforUpdating={false}
          onDelete={onDelete}
          defaultConfirmationCheckBox={defaultConfirmationCheckBox}
          setDefaultConfirmationCheckBox={setDefaultConfirmationCheckBox}
          addOrUpdateIsInProgress={addOrUpdateIsInProgress}
          shopId={shopId}
          companyId={companyId}
        />
      </Paper>
    </div>
  )
}
