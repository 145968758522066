import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import {
  Mutation,
  MutationCreateReceiptWithoutIdentityArgs,
} from '@/schemaTypes'
import { dealCalculationFragments, dealFragments } from './dealFragments'

const CREATE_RECEIPT_WITHOUT_IDENTITY = gql`
  mutation createReceiptWithoutIdentity($dealId: ObjectId!) {
    createReceiptWithoutIdentity(dealId: $dealId) {
      _id
      dealStartDate
      createdAt
      updatedAt
      dealType
      businessUnit
      bookingNumber
      itemsIds
      company {
        _id
        name
        iban
        paypalEmail
      }
      pawnData {
        ...PawnDataFragment
      }
      purchaseData {
        ...PurchaseDataFragment
      }
      events {
        ...DealEventFragment
      }
      payoutData {
        ...PaymentDataFragment
      }
      secondaryPayoutData {
        ...SecondaryPaymentDataFragment
      }
      initialShopId
      pickupData {
        ...TransportDataFragment
      }
      dropoffData {
        ...TransportDataFragment
      }
      items {
        ...DealItemFragment
      }
      requestedItems {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
      shop {
        _id
        name
      }
      verifiedBy
      extendedBy
      dealPartnerData {
        type
        knp {
          fileReferenceNumber
          debtAmount
        }
      }
      uploadedFiles {
        ...FileMetadataFragment
      }
      extensionChain {
        extensionBookingNumbers
        extensionDealIds
      }
      dealChain {
        bookingNumber
        dealId
        chainType
      }
    }
  }
  ${dealFragments.dealItem}
  ${dealFragments.event}
  ${dealFragments.purchaseData}
  ${dealFragments.pawnData}
  ${dealFragments.transportData}
  ${dealCalculationFragments.dealValorizationCalculation}
  ${fragments.paymentData}
  ${fragments.secondaryPaymentData}
  ${fragments.fileMetadata}
`

type TData = {
  createReceiptWithoutIdentity: Mutation['createReceiptWithoutIdentity']
}
type TVariables = MutationCreateReceiptWithoutIdentityArgs

export function useCreateReceiptWithoutIdentity(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createReceiptWithoutIdentity] = useMutation<TData, TVariables>(
    CREATE_RECEIPT_WITHOUT_IDENTITY,
    opts,
  )

  return createReceiptWithoutIdentity
}
