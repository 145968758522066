import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateEmployeePasswordArgs } from '@/schemaTypes'

const UPDATE_EMPLOYEE_PASSWORD = gql`
  mutation updateEmployeePassword($employeeId: ObjectId!, $password: String!) {
    updateEmployeePassword(employeeId: $employeeId, password: $password) {
      _id
    }
  }
`

type TData = { updateEmployeePassword: Mutation['updateEmployeePassword'] }
type TVariables = MutationUpdateEmployeePasswordArgs

export function useUpdateEmployeePassword(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateEmployeePassword] = useMutation<TData, TVariables>(
    UPDATE_EMPLOYEE_PASSWORD,
    opts,
  )

  return updateEmployeePassword
}
