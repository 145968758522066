import {
  Deal,
  DealTransportUpdateArgs,
  EShippingLabelDirection,
  EShippingLabelEntity,
  EStandardShipmentType,
  ETransportMode,
  ETransportType,
  Query,
  ShippingLabelCreateArgs,
} from '../../../../schemaTypes'
import { useUpdateDealTransport } from '../../hooks/updateDealTransport'
import { ApolloQueryResult } from '@apollo/client'
import { Button, Checkbox, TableHead } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import LinkIcon from '@material-ui/icons/Link'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '@/components/LoadingSpinner'
import ShippingLabelComponent from '@/components/ShippingLabelComponent'
import { Context } from '@/context'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import { getFilenameFromURL } from '@/utils/file'
import { printLocalDateTime } from '@/utils/print'
import styles from './ShipmentInfo.module.scss'

interface ShipmentInfoProp {
  deal: Deal
  isEditable: boolean
  newTransportArgs: DealTransportUpdateArgs
  setNewTransportArgs: (
    dealTransportUpdateArgs: DealTransportUpdateArgs,
  ) => void
  refetchDeal: () => Promise<
    ApolloQueryResult<{ getDealById: Query['getDealById'] }>
  >
}
const ShipmentInfo = (props: ShipmentInfoProp) => {
  const {
    deal,
    isEditable,
    newTransportArgs,
    setNewTransportArgs,
    refetchDeal,
  } = props
  const { showInfo, closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()
  const standardShipmentTyped = [
    EStandardShipmentType.DpdStandard,
    EStandardShipmentType.Post,
  ]

  const [sendNotification] = useState(newTransportArgs.notifyCustomer)
  const [shippingLabelCreateArgs, setShippingLabelCreateArgs] =
    useState<ShippingLabelCreateArgs>()
  const [shippingLabelCreateArgsIsValid, setShippingLabelCreateArgsIsValid] =
    useState(false)

  const [spinnerRunning, setSpinnerRunning] = useState(false)
  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })

  const saveDealTransport = useMutationShowingErrors({
    mutation: useUpdateDealTransport(),
    successMessage: `${t('shipping_tracking_updated_successfully')}!`,
  })

  const shippingLabels = useMemo(() => {
    const pickUpLabels =
      deal.pickupData &&
      deal.pickupData.standardShippingData &&
      deal.pickupData.standardShippingData.sentShippingLabels
        ? deal.pickupData.standardShippingData.sentShippingLabels
        : []
    const dropOffLabels =
      deal.dropoffData &&
      deal.dropoffData.standardShippingData &&
      deal.dropoffData.standardShippingData.sentShippingLabels
        ? deal.dropoffData.standardShippingData.sentShippingLabels
        : []

    return [...pickUpLabels, ...dropOffLabels].sort((a, b) => {
      if (a.labelCreateDate > b.labelCreateDate) {
        return 1
      }
      if (a.labelCreateDate < b.labelCreateDate) {
        return -1
      }
      return 0
    })
  }, [deal])

  useEffect(() => {
    setNewTransportArgs({
      ...newTransportArgs,
      shippingLabelCreateArgs: shippingLabelCreateArgs,
    })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingLabelCreateArgs, shippingLabelCreateArgsIsValid])

  const onSaveTrasnport = () => {
    /* Check Standard Shipping Transport
       When we are in Standard_Shipping Mode, we should have:
       - ShippingLabelCreateArgs (This object automatically check 
          trackingLink,
          trackingCode,
          labelFile,
          senderInfo,
          receiverInfo)
     */
    if (newTransportArgs.transportMode === ETransportMode.StandardShipment) {
      console.log(
        '🚀 ~ file: ShipmentInfo.tsx:79 ~ onSaveTrasnport ~ shippingLabelCreateArgsIsValid:',
        shippingLabelCreateArgsIsValid,
        shippingLabelCreateArgs,
      )

      if (!shippingLabelCreateArgsIsValid || !shippingLabelCreateArgs) {
        return showInfo(t('shipping_label_is_not_provided_correctly'))
      }
    }

    showConfirmPopup({
      title: t('save_standard_shipping_tracking_link'),
      action: async () => {
        closeConfirmModal()

        setSpinnerRunning(true)
        await saveDealTransport({
          variables: {
            args: newTransportArgs,
          },
          onCompleted: () => {
            refetchDeal()
          },
        })

        setSpinnerRunning(false)
      },
    })
  }

  return (
    <Fragment>
      <div style={{ display: 'inline-block', width: '600px' }}>
        {isEditable && (
          <div>
            <ShippingLabelComponent
              dealId={deal._id}
              entityType={EShippingLabelEntity.Deal}
              directionType={
                newTransportArgs.transportType === ETransportType.Pickup
                  ? EShippingLabelDirection.Receive
                  : EShippingLabelDirection.Send
              }
              shippingLabelCreateArgs={shippingLabelCreateArgs}
              setShippingLabelCreateArgs={setShippingLabelCreateArgs}
              logisticServiceProviders={standardShipmentTyped}
              shippingLabelCreateArgsIsValid={shippingLabelCreateArgsIsValid}
              setShippingLabelCreateArgsIsValid={
                setShippingLabelCreateArgsIsValid
              }
              customerEmailAddress={
                sendNotification ? deal.customer.email : null
              }
              betweenShops={false}
            />
          </div>
        )}

        {isEditable && (
          <div style={{ marginTop: '1rem' }}>
            {t('notify_customer')}:
            <span style={{ marginLeft: '2.6rem', color: 'gray' }}>
              {deal.customer.email}
            </span>
            <span style={{ marginLeft: '2rem' }}>
              <Checkbox
                disabled={!isEditable}
                checked={sendNotification}
                onChange={(e) =>
                  setNewTransportArgs({
                    ...newTransportArgs,
                    notifyCustomer: e.target.checked,
                  })
                }
              />
            </span>
          </div>
        )}

        <div style={{ marginTop: '2rem', display: 'inline-block' }}>
          <Button
            disabled={!isEditable}
            variant="contained"
            component="label"
            className={'u-mt-5 u-mb-5'}
            onClick={onSaveTrasnport}
          >
            {t('save')}
          </Button>
        </div>
        <div style={{ display: 'inline-block' }}>
          {spinnerRunning ? (
            <div
              style={{
                display: 'inline-block',
                marginLeft: '2rem',
                verticalAlign: 'middle',
              }}
            >
              <LoadingSpinner />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      {/* This is the history of SENT labels */}
      <div className={styles.historyDiv}>
        <p style={{ marginBottom: '1rem', fontWeight: 'bold' }}>
          {t('sent_labels_history')}
        </p>
        {shippingLabels &&
        shippingLabels.filter(
          (c) =>
            c.direction ===
            (newTransportArgs.transportType === ETransportType.Pickup
              ? EShippingLabelDirection.Receive
              : EShippingLabelDirection.Send),
        ).length > 0 ? (
          <Table sx={{ minWidth: 500 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('date')}</TableCell>
              <TableCell>{t('partner')}</TableCell>
              <TableCell>{t('tracking_link')}</TableCell>
              <TableCell>{t('label')}</TableCell>
            </TableHead>
            <TableBody>
              {shippingLabels
                .filter(
                  (c) =>
                    c.direction ===
                    (newTransportArgs.transportType === ETransportType.Pickup
                      ? EShippingLabelDirection.Receive
                      : EShippingLabelDirection.Send),
                )
                .map((label) => (
                  <TableRow key={shippingLabels?.indexOf(label)}>
                    <TableCell sx={{ minWidth: '260px', maxWidth: '260px' }}>
                      {getFilenameFromURL(label.labelFile.url).filename +
                        getFilenameFromURL(label.labelFile.url).fileExtension}
                    </TableCell>
                    <TableCell sx={{ minWidth: '170px', maxWidth: '170px' }}>
                      {label.labelCreateDate
                        ? printLocalDateTime(label.labelCreateDate, {
                            timezone: 'Europe/Vienna',
                          })
                        : ''}
                    </TableCell>
                    <TableCell sx={{ minWidth: '150px', maxWidth: '150px' }}>
                      {label.shippingPartner}
                    </TableCell>
                    <TableCell sx={{ minWidth: '100px', maxWidth: '100px' }}>
                      <a
                        target="_blank"
                        style={{ marginLeft: '0.5rem', fontSize: '0.8rem' }}
                        href={label.trackingLink}
                        rel="noreferrer"
                      >
                        <LinkIcon style={{ marginBottom: '-0.5rem' }} />
                      </a>
                    </TableCell>
                    <TableCell sx={{ minWidth: '50px', maxWidth: '50px' }}>
                      <a
                        target="_blank"
                        style={{ marginLeft: '0.5rem', fontSize: '0.8rem' }}
                        href={label.labelFile.url}
                        rel="noreferrer"
                      >
                        <AttachFileIcon style={{ marginBottom: '-0.5rem' }} />
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <span style={{ color: 'red', fontSize: '14px' }}>
            {t('no_shipping_label_sent_to_customer')}
          </span>
        )}
      </div>

      {/* {transport.premiumShippingData && (
        <Typography component="div" variant="body1" gutterBottom>
          Datum: {dayjs(transport.premiumShippingData?.date).format('DD.MM.YYYY')}
          <br />
          Slot: {`${transport.premiumShippingData.fromHourUTC}:00 - ${transport.premiumShippingData.toHourUTC}:00`}
        </Typography>
      )} */}
    </Fragment>
  )
}

export default ShipmentInfo
