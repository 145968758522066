import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper/FieldWrapper'
import React, { ComponentType } from 'react'

function withFieldWrapper<P extends Record<string, any>>(
  WrappedComponent: ComponentType<P>,
) {
  const FieldWithWrapper: React.FC<P & Omit<FieldWrapperProps, 'children'>> = (
    props,
  ) => {
    const { fieldWrapperProps, innerProps } = Object.entries(props).reduce(
      (result, [key, value]) => {
        if (key === 'isInvalid') {
          result.innerProps[key as keyof P] = value
          result.fieldWrapperProps[key] = value
        } else if (isFieldWrapperPropName(key)) {
          result.fieldWrapperProps[key as string] = value
        } else {
          result.innerProps[key as keyof P] = value
        }
        return result
      },
      {
        fieldWrapperProps: {} as FieldWrapperProps,
        innerProps: {} as P,
      },
    )

    return (
      <FieldWrapper {...fieldWrapperProps}>
        <WrappedComponent {...innerProps} />
      </FieldWrapper>
    )
  }

  return FieldWithWrapper
}

export default withFieldWrapper

function isFieldWrapperPropName(key: string): key is keyof FieldWrapperProps {
  return fieldWrapperPropNames.includes(key)
}

const fieldWrapperPropNames: string[] = [
  'label',
  'errorText',
  'warningText',
  'isInvalid',
  'helperText',
  'className',
  'labelClassName',
  'inputContainerClassName',
  'tooltipText',
  'required',
  'wrapperInputContainerClassName',
  'loading',
] satisfies (keyof FieldWrapperProps)[]
