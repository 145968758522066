import { IbanPaypalInformation } from '../IbanPaypalInformation'
import { TermsAndConditions } from '../TermsAndConditions/TermsAndConditions'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  tableCellClasses,
} from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Fragment } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import AmountInput from '@/components/AmountInput'
import PaymentTypePicker from '@/components/PaymentTypePicker'
import { useDealExtensionCalculation } from '@/domains/deals/hooks'
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback'
import {
  Deal,
  DealExtensionCalculationArgs,
  EManualPaymentType,
  PersistedDealExtensionCalculation,
} from '@/schemaTypes'
import { getLatestEventForStatus, getLatestItemAnswersArgs } from '@/utils/deal'
import { paymentTypeUnion, printLocalAmount } from '@/utils/misc'
import { addDays, getDurationInDays } from '@/utils/time'
import styles from './ExtensionBox.module.scss'
import ExtensionChildDetails from './ExtensionChildDetails'
// import { useDealExtensionCalculation } from '../../hooks'
// import { IbanPaypalInformation } from '../IbanPaypalInformation'
import ExtensionParentDetails from './ExtensionParentDetails'

interface ExtensionBoxProps {
  deal: Deal
  newPaymentType?: EManualPaymentType
  onChangeDealExtensionCalculationId?: (
    dealExtensionCalculationId: string,
  ) => unknown
  onChangePaymentType?: (paymentType: EManualPaymentType) => unknown
  onChangeChildOverwriteManualFees?: (
    childShouldOverwriteManualFees: boolean,
  ) => unknown
  isOnlineExtensionPrevented?: boolean
  onChangeIsOnlineExtensionPrevented?: (
    isOnlineExtensionPrevented: boolean,
  ) => unknown
  onlineExtensionBanNote?: string
  onChangeOnlineExtensionBanNote?: (onlineExtensionBanNote: string) => void
  withdrawalFeeTermsAccepted?: boolean
  setWithdrawalFeeTermsAccepted?: (value: boolean) => void
}

const ExtensionBox: FunctionComponent<ExtensionBoxProps> = (props) => {
  const {
    deal,
    onChangeDealExtensionCalculationId,
    onChangePaymentType,
    newPaymentType,
    onChangeChildOverwriteManualFees,
    isOnlineExtensionPrevented,
    onChangeIsOnlineExtensionPrevented,
    onlineExtensionBanNote,
    onChangeOnlineExtensionBanNote,
    withdrawalFeeTermsAccepted,
    setWithdrawalFeeTermsAccepted,
  } = props
  const { t } = useTranslation()
  const today = dayjs().toDate()
  const isPreviewMode = Boolean(onChangeDealExtensionCalculationId)
  const confirmedExtension = getLatestEventForStatus(
    deal,
    'DealExtensionConfirmedEvent',
  )
  const isExtensionConfirmed = Boolean(confirmedExtension)
  const [dealExtensionCalculation, setDealExtensionCalculation] =
    useState<PersistedDealExtensionCalculation>()

  const paymentType = paymentTypeUnion(confirmedExtension?.paymentType)

  const [dealExtensionCalculationArgs, setDealExtensionCalculationArgs] =
    useState<DealExtensionCalculationArgs>(() =>
      getDefaultDealExtensionArgs({ deal, today }),
    )

  const [setDebouncedDealExtensionCalculationArgs] = useDebouncedCallback(
    (newDealExtensionCalculationArgs: DealExtensionCalculationArgs) => {
      setDealExtensionCalculationArgs(newDealExtensionCalculationArgs)
    },
    500,
  )

  const [initialExtensionCalculation, setInitialExtensionCalculation] =
    useState<PersistedDealExtensionCalculation | undefined>(undefined)

  const iban = deal.company.iban
  const paypalEmail = deal.company.paypalEmail

  const { queryResult: extensionCalculationQueryResult } =
    useDealExtensionCalculation({
      variables: {
        dealId: deal._id,
        args: dealExtensionCalculationArgs,
      },
      skip: !isPreviewMode,
      onCompleted: ({ dealExtensionCalculation }) => {
        if (onChangeDealExtensionCalculationId && dealExtensionCalculation) {
          onChangeDealExtensionCalculationId(
            dealExtensionCalculation.dealExtensionCalculationId,
          )
        }
      },
    })

  if (
    extensionCalculationQueryResult.data?.dealExtensionCalculation &&
    dealExtensionCalculation !==
      extensionCalculationQueryResult.data?.dealExtensionCalculation
  ) {
    setDealExtensionCalculation(
      extensionCalculationQueryResult.data.dealExtensionCalculation,
    )
  }

  if (
    !initialExtensionCalculation &&
    extensionCalculationQueryResult.data?.dealExtensionCalculation
  ) {
    setInitialExtensionCalculation(
      extensionCalculationQueryResult.data.dealExtensionCalculation,
    )
  }

  if (
    !isPreviewMode &&
    (!confirmedExtension ||
      !confirmedExtension.dealCalculation ||
      !confirmedExtension.childCalculation)
  ) {
    return (
      <div>
        <span
          style={{
            color: 'red',
          }}
        >
          {t('deal_extension_confirmed_event_calculation_cannot_be_fetched')}
        </span>
      </div>
    )
  }

  return (
    <Fragment>
      <Paper className="u-p-sm u-12/12 u-mb-sm">
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
              },
              minWidth: 400,
            }}
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              <TableRow>
                <TableCell width={150}>{`${t('date_of_request')}:`}</TableCell>
                <TableCell
                  sx={{
                    paddingLeft: '25px',
                  }}
                >
                  <KeyboardDatePicker
                    className={`${styles.datePicker} u-ml-5`}
                    clearable
                    format="DD.MM.YYYY"
                    value={
                      confirmedExtension && confirmedExtension.dealCalculation
                        ? confirmedExtension.dealCalculation.date
                        : dealExtensionCalculationArgs.extensionDate
                    }
                    minDate={deal.dealStartDate}
                    onChange={(date) => {
                      setDebouncedDealExtensionCalculationArgs({
                        ...dealExtensionCalculationArgs,
                        extensionDate: addDays(
                          deal.dealStartDate,
                          getDurationInDays(
                            date ? date?.toDate() : today,
                            deal.dealStartDate,
                          ),
                        ),
                      })
                    }}
                    disabled={isExtensionConfirmed}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{`${t('payout.label')}:`}</TableCell>
                <TableCell
                  sx={{
                    paddingLeft: '30px',
                  }}
                >
                  {printLocalAmount(
                    confirmedExtension && confirmedExtension.dealCalculation
                      ? confirmedExtension.dealCalculation.dealValuesEntry
                          .payoutAmount
                      : dealExtensionCalculation?.parentDealCalculation
                          .dealValuesEntry.payoutAmount,
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{`${t('new_duration')}:`}</TableCell>
                <TableCell
                  sx={{
                    paddingLeft: '30px',
                  }}
                >
                  <AmountInput
                    disabled={isExtensionConfirmed}
                    error={
                      !dealExtensionCalculationArgs.durationInDays ||
                      dealExtensionCalculationArgs.durationInDays <= 0
                        ? t('duration_in_days_should_be_greater_than_zero')
                        : undefined
                    }
                    size="small"
                    type="int"
                    inputWidth="70px"
                    value={dealExtensionCalculationArgs.durationInDays ?? 0}
                    onChange={(value) =>
                      setDealExtensionCalculationArgs({
                        ...dealExtensionCalculationArgs,
                        durationInDays: value || 0,
                      })
                    }
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={180}>{`${t('duration_end')}:`}</TableCell>
                <TableCell
                  sx={{
                    paddingLeft: '25px',
                  }}
                >
                  <KeyboardDatePicker
                    className={`${styles.datePicker} u-ml-5`}
                    clearable
                    format="DD.MM.YYYY"
                    value={addDays(
                      confirmedExtension && confirmedExtension.dealCalculation
                        ? confirmedExtension.dealCalculation.date
                        : dealExtensionCalculationArgs.extensionDate,
                      dealExtensionCalculationArgs.durationInDays ?? 0,
                    )}
                    minDate={
                      confirmedExtension && confirmedExtension.dealCalculation
                        ? confirmedExtension.dealCalculation.date
                        : dealExtensionCalculationArgs.extensionDate
                    }
                    onChange={(date) => {
                      setDebouncedDealExtensionCalculationArgs({
                        ...dealExtensionCalculationArgs,
                        durationInDays: getDurationInDays(
                          date ? date?.toDate() : today,
                          confirmedExtension &&
                            confirmedExtension.dealCalculation
                            ? confirmedExtension.dealCalculation.date
                            : dealExtensionCalculationArgs.extensionDate,
                        ),
                      })
                    }}
                    disabled={isExtensionConfirmed}
                  />
                </TableCell>
              </TableRow>

              {!isExtensionConfirmed && (
                <TableRow>
                  <TableCell>{`${t('allow_online_extension')}:`}</TableCell>
                  <TableCell>
                    <Switch
                      color="secondary"
                      checked={!isOnlineExtensionPrevented}
                      onChange={(event) => {
                        onChangeIsOnlineExtensionPrevented(
                          !event.target.checked,
                        )
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}

              {!isExtensionConfirmed && isOnlineExtensionPrevented && (
                <TableRow>
                  <TableCell>{`${t('in_house_reason_for_extension_ban')}*:`}</TableCell>
                  <TableCell>
                    <TextareaAutosize
                      rowsMin={4}
                      placeholder={t('note_placeholder')}
                      style={{ width: 250 }}
                      value={onlineExtensionBanNote}
                      onChange={(e) =>
                        onChangeOnlineExtensionBanNote(e.target.value)
                      }
                      name="content"
                    />
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell>{`${t('payback_type')}:`}</TableCell>
                <TableCell>
                  <PaymentTypePicker
                    label={''}
                    paymentType={paymentType}
                    onChangePaymentType={onChangePaymentType}
                    disabled={isExtensionConfirmed}
                    businessUnit={deal.businessUnit}
                    carPawnDisallowedPaymentTypes={[EManualPaymentType.Card]}
                  />
                </TableCell>
              </TableRow>

              {newPaymentType === EManualPaymentType.Bank && iban && (
                <IbanPaypalInformation
                  deal={deal}
                  ibanOrPaypalEmail={iban}
                  austriaLabel={'cashy_austria_iban'}
                  germanLabel={'cashy_germany_iban'}
                />
              )}

              {newPaymentType === EManualPaymentType.Paypal && paypalEmail && (
                <IbanPaypalInformation
                  deal={deal}
                  ibanOrPaypalEmail={paypalEmail}
                  austriaLabel={'cashy_austria_paypal_email'}
                  germanLabel={'cashy_germany_paypal_email'}
                />
              )}

              <TableRow>
                <TableCell colSpan={2}>
                  <ExtensionParentDetails
                    deal={deal}
                    dealCalculation={
                      dealExtensionCalculation?.parentDealCalculation ??
                      confirmedExtension?.dealCalculation
                    }
                    dealExtensionCalculationArgs={dealExtensionCalculationArgs}
                    setDealExtensionCalculationArgs={
                      setDealExtensionCalculationArgs
                    }
                    isExtensionConfirmed={isExtensionConfirmed}
                    isLoading={
                      !isExtensionConfirmed &&
                      extensionCalculationQueryResult.loading
                    }
                    partialRedemptionAmount={
                      !isExtensionConfirmed
                        ? dealExtensionCalculation?.partialRedemptionAmount
                        : confirmedExtension.partialRedemptionAmount
                    }
                    extensionCalculationQueryResult={
                      extensionCalculationQueryResult
                    }
                    initialCalculation={
                      initialExtensionCalculation?.parentDealCalculation
                    }
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  <ExtensionChildDetails
                    deal={deal}
                    dealCalculation={
                      dealExtensionCalculation?.childDealCalculation ??
                      confirmedExtension?.childCalculation
                    }
                    dealExtensionCalculationArgs={dealExtensionCalculationArgs}
                    setDealExtensionCalculationArgs={
                      setDealExtensionCalculationArgs
                    }
                    isExtensionConfirmed={isExtensionConfirmed}
                    isLoading={
                      !isExtensionConfirmed &&
                      extensionCalculationQueryResult.loading
                    }
                    extensionCalculationQueryResult={
                      extensionCalculationQueryResult
                    }
                    onChangeChildOverwriteManualFees={
                      onChangeChildOverwriteManualFees
                    }
                    initialCalculation={
                      initialExtensionCalculation?.childDealCalculation
                    }
                  />
                </TableCell>
              </TableRow>

              {deal.acceptingWithdrawalFeeTermsRequired &&
                !isExtensionConfirmed && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <TermsAndConditions
                        withdrawalFeeTermsAccepted={Boolean(
                          withdrawalFeeTermsAccepted,
                        )}
                        setWithdrawalFeeTermsAccepted={
                          setWithdrawalFeeTermsAccepted
                        }
                      />
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </Paper>
      </Paper>
    </Fragment>
  )
}

export default ExtensionBox

function getDefaultDealExtensionArgs({
  deal,
  today,
}: {
  deal: Deal
  today: Date
}): DealExtensionCalculationArgs {
  const confirmedExtension = getLatestEventForStatus(
    deal,
    'DealExtensionConfirmedEvent',
  )

  const overwrittenPartialRedemptionAmount =
    deal.pawnData?.reservedPaybackData?.partialRedemptionAmount

  return {
    durationInDays:
      confirmedExtension && confirmedExtension.childCalculation
        ? confirmedExtension.childCalculation.dealValuesEntry.durationInDays
        : deal.company.configuration.minimumPawnDuration,
    // extensionDate is for ending the parent deal
    extensionDate: addDays(
      deal.dealStartDate,
      getDurationInDays(today, deal.dealStartDate),
    ),
    // it is important we send undefined to BE
    overwrittenPartialRedemptionAmount:
      overwrittenPartialRedemptionAmount ||
      (confirmedExtension &&
        confirmedExtension.dealCalculation &&
        confirmedExtension.partialRedemptionAmount) ||
      undefined,
    shouldOverwriteManualFees: false,
    manualFeeDefinitionsArgs: [],
    shouldOverwriteItemAnswers: true,
    itemsExtensionAnswersArgs: getLatestItemAnswersArgs(deal),
    childManualFeeDefinitionsArgs: [],
    childShouldOverwriteManualFees: false,
  }
}
