import styled from '@emotion/styled'
import { last } from 'lodash'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Dispatch, Fragment, FunctionComponent, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '@/redesign/components/Input/Input'
import InputNum from '@/redesign/components/InputNumber/InputNumber'
import { FeeCreateArgs, FeeUpdateArgs } from '@/schemaTypes'

interface WithdrawalFeeDefinitionProps {
  feeDefinition: FeeCreateArgs | FeeUpdateArgs
  setFeeDefinition: Dispatch<SetStateAction<FeeCreateArgs | FeeUpdateArgs>>
}

const WithdrawalFeeDefinition: FunctionComponent<
  WithdrawalFeeDefinitionProps
> = (props) => {
  const { feeDefinition, setFeeDefinition } = props
  const { t } = useTranslation()

  const addInterval = () => {
    setFeeDefinition((feeDefinition) => ({
      ...feeDefinition,
      withdrawalFeeIntervals: [
        ...(feeDefinition.withdrawalFeeIntervals ?? []),
        {
          factor: 0,
          from: feeDefinition.withdrawalFeeIntervals?.length
            ? last(feeDefinition.withdrawalFeeIntervals).from + 100
            : 0,
        },
      ],
    }))
  }

  const removeLastInterval = () => {
    setFeeDefinition((feeDefinition: FeeCreateArgs | FeeUpdateArgs) => ({
      ...feeDefinition,
      withdrawalFeeIntervals: feeDefinition.withdrawalFeeIntervals.slice(0, -1),
    }))
  }

  return (
    <div>
      <div className="flex flex-row items-center">
        <label className="mb-4">
          {t('fees.apply_only_to_deals_in_auctions')}
        </label>
        <Checkbox
          className="ml-4 mb-5"
          onChange={(e) =>
            setFeeDefinition((feeDefinition) => ({
              ...feeDefinition,
              onlyWhenInAuction: e.checked,
            }))
          }
          checked={feeDefinition.onlyWhenInAuction}
        />
      </div>

      <div className="flex flex-col mb-4">
        {feeDefinition.withdrawalFeeIntervals?.map(
          (interval, index, { length }) => {
            const isLastInterval = index + 1 === length
            return (
              <Fragment key={index}>
                <div className="flex flex-row items-center mb-2">
                  <div className="flex flex-col items-center">
                    <InputNum
                      readOnly={index === 0}
                      className="flex flex-col md:flex-row items-start md:items-center"
                      inputContainerClassName="w-52"
                      value={interval.from}
                      onChange={(e) => {
                        setFeeDefinition((feeDefinition) => ({
                          ...feeDefinition,
                          withdrawalFeeIntervals:
                            feeDefinition.withdrawalFeeIntervals.map(
                              (interval, intervalIndex) =>
                                intervalIndex === index
                                  ? { ...interval, from: e.value }
                                  : interval,
                            ),
                        }))
                      }}
                    />
                  </div>

                  <StyledSpan className="flex flex-col items-center mx-2">
                    -
                  </StyledSpan>

                  <div className="flex flex-col items-center">
                    {isLastInterval ? (
                      <Input
                        className="flex flex-col md:flex-row items-start md:items-center"
                        inputContainerClassName="w-52"
                        value={'∞'}
                        readOnly
                      />
                    ) : (
                      <InputNum
                        className="flex flex-col md:flex-row items-start md:items-center"
                        inputContainerClassName="w-52"
                        value={Math.max(
                          feeDefinition.withdrawalFeeIntervals[index + 1].from -
                            0.01,
                          0,
                        )}
                        currency="EUR"
                      />
                    )}
                  </div>

                  <StyledSpan className="flex flex-col items-center mx-2">
                    {'->'}
                  </StyledSpan>

                  <div className="flex flex-col items-center">
                    <InputNum
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      suffix=" %"
                      mode="decimal"
                      className="flex flex-col md:flex-row items-start md:items-center"
                      inputContainerClassName="w-52"
                      value={interval.factor * 100}
                      onChange={(e) => {
                        setFeeDefinition((feeDefinition) => ({
                          ...feeDefinition,
                          withdrawalFeeIntervals:
                            feeDefinition.withdrawalFeeIntervals.map(
                              (interval, intervalIndex) =>
                                intervalIndex === index
                                  ? { ...interval, factor: e.value / 100 }
                                  : interval,
                            ),
                        }))
                      }}
                    />
                  </div>

                  {isLastInterval && (
                    <Button
                      icon="pi pi-trash"
                      text
                      onClick={removeLastInterval}
                    />
                  )}
                </div>
              </Fragment>
            )
          },
        )}
      </div>

      <AddIntervalButton
        className="mb-20"
        label={t('fees.add_interval')}
        onClick={addInterval}
      />
    </div>
  )
}

export default WithdrawalFeeDefinition

const StyledSpan = styled.span`
  font-weight: 700;
  font-size: 18px;
`

const AddIntervalButton = styled(Button)`
  color: var(--button-secondary-color, #fff);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.05rem;
`
