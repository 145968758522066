import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateCustomDealArgs } from '@/schemaTypes'

const UPDATE_CUSTOM_DEAL = gql`
  mutation UpdateCustomDeal($customDealUpdateArgs: CustomDealUpdateArgs!) {
    updateCustomDeal(customDealUpdateArgs: $customDealUpdateArgs) {
      _id
    }
  }
`

type TData = { updateCustomDeal: Mutation['updateCustomDeal'] }
type TVariables = MutationUpdateCustomDealArgs

export function useUpdateCustomDeal(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateCustomDeal] = useMutation<TData, TVariables>(
    UPDATE_CUSTOM_DEAL,
    {
      refetchQueries: ['getCustomDealById'],
      ...opts,
    },
  )
  return updateCustomDeal
}
