import { TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'
import { ResponseTemplate } from '@/schemaTypes'
import globalStyles from '@/styles/global.module.scss'

export interface ResponseTemplateRowProps {
  responseTemplate: ResponseTemplate
  deleteResponseTemplate: (responseTemplateId: string) => void
}

const ResponseTemplateRow = (props: ResponseTemplateRowProps) => {
  const { responseTemplate } = props
  const id = responseTemplate._id ?? ''

  return (
    <TableRow>
      <TableCell align="left">{responseTemplate.name}</TableCell>
      <TableCell align="left">{responseTemplate.subject}</TableCell>
      <TableCell>
        <Link to={`/inApp/configurations/responseTemplates/edit/${id}`}>
          <EditIcon />
        </Link>
        <DeleteIcon
          className={globalStyles.clickable}
          onClick={() => props.deleteResponseTemplate(responseTemplate._id)}
        />
      </TableCell>
    </TableRow>
  )
}

export default ResponseTemplateRow
