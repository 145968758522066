import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

// maybe we should replace this with string (or nothing in the best case) in the future,
// for now this makes for easier development because of autocompletion
export type Timezone = 'Europe/Vienna'
export const defaultTimezone = 'Europe/Vienna'

export function getTimezoneOffsetInMinutes(date: Date, timezone?: Timezone) {
  const newTimeZoneDate = dayjs(date).tz(timezone)
  const offset = dayjs(date).diff(newTimeZoneDate, 'm')
  return offset
}

export function getDurationInDays(
  dateNew: Date,
  dateOld: Date,
  timezone?: Timezone,
) {
  if (timezone) {
    // Note: The dayjs library behaves different on the client and server.
    return dayjs(dateNew)
      .tz(timezone)
      .startOf('day')
      .diff(dayjs(dateOld).tz(timezone).startOf('day'), 'd')
  } else {
    // Note: Using .tz(undefined) leads to a wrong output.
    return dayjs(dateNew)
      .startOf('day')
      .diff(dayjs(dateOld).startOf('day'), 'd')
  }
}

export function getDayjsInTimezone(
  date: Date,
  options?: { locale?: string; timezone?: Timezone },
) {
  return dayjs(date, { locale: options?.locale }).tz(
    options?.timezone ?? defaultTimezone,
  )
}

/**
 * @deprecated The method should not be used. Use the dayjs methods instead.
 */
export function getNow() {
  return dayjs().toDate()
}

export function getStartOfDayForTimezone(date: Date, timezone: Timezone) {
  return dayjs(date).tz(timezone).startOf('day').toDate()
}

export function getEndOfDayForTimezone(date: Date, timezone: Timezone) {
  return dayjs(date).tz(timezone).endOf('day').toDate()
}

export function addDays(date: Date, days: number, timezone?: Timezone) {
  return dayjs(date).tz(timezone).add(days, 'd').toDate()
}

export function removeDays(date: Date, days: number, timezone?: Timezone) {
  return dayjs(date).tz(timezone).subtract(days, 'd').toDate()
}

export function isToday(date: Date, timezone?: Timezone) {
  return dayjs(date).tz(timezone).isSame(dayjs(), 'day')
}
