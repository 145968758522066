import { MenuItem, Select } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import RemoveIcon from '@material-ui/icons/Delete'
import { Fragment, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import AmountInput from '@/components/AmountInput'
import TextInput from '@/components/TextInput'
import { EFeeIntervalType, FeeCreateArgs, FeeUpdateArgs } from '@/schemaTypes'
import styles from './FeesConfiguration.module.scss'

interface FeeDefinitionIntervalProps {
  newFeeDefinitionArgs: FeeCreateArgs | FeeUpdateArgs
  setNewFeeDefinitionArgs: (value: FeeCreateArgs | FeeUpdateArgs) => void
}

const FeeDefinitionInterval: FunctionComponent<FeeDefinitionIntervalProps> = (
  props,
) => {
  const { newFeeDefinitionArgs, setNewFeeDefinitionArgs } = props
  const { t } = useTranslation()

  const handleFromValue = (index: number, fromValue: number) => {
    const tempIntervals = newFeeDefinitionArgs.feeIntervals

    if (tempIntervals && tempIntervals.length > index) {
      tempIntervals[index] = {
        from: fromValue,
        diffAmount: tempIntervals[index].diffAmount,
        type: tempIntervals[index].type,
      }
    }

    setNewFeeDefinitionArgs({
      ...newFeeDefinitionArgs,
      feeIntervals: tempIntervals,
    })
  }

  const handleType = (index: number, type: EFeeIntervalType) => {
    const tempIntervals = newFeeDefinitionArgs.feeIntervals

    if (tempIntervals && tempIntervals.length > index) {
      tempIntervals[index] = {
        from: tempIntervals[index].from,
        diffAmount: tempIntervals[index].diffAmount,
        type: type,
      }
    }

    setNewFeeDefinitionArgs({
      ...newFeeDefinitionArgs,
      feeIntervals: tempIntervals,
    })
  }

  const handleDiffAmountValue = (index: number, diffAmount: number) => {
    const tempIntervals = newFeeDefinitionArgs.feeIntervals

    if (tempIntervals && tempIntervals.length > index) {
      tempIntervals[index].diffAmount = diffAmount
    }

    setNewFeeDefinitionArgs({
      ...newFeeDefinitionArgs,
      feeIntervals: tempIntervals,
    })
  }

  const addButtonClicked = () => {
    const tempIntervals = newFeeDefinitionArgs.feeIntervals ?? []

    tempIntervals.push({
      diffAmount: 0,
      from: 0,
      type: EFeeIntervalType.Fixed,
    })

    setNewFeeDefinitionArgs({
      ...newFeeDefinitionArgs,
      feeIntervals: tempIntervals,
    })
  }

  const deleteButtonClicked = () => {
    const tempIntervals = newFeeDefinitionArgs.feeIntervals ?? []

    if (tempIntervals && tempIntervals.length > 0) tempIntervals.pop()

    setNewFeeDefinitionArgs({
      ...newFeeDefinitionArgs,
      feeIntervals: tempIntervals,
    })
  }

  return (
    <div className={`${styles.interrvalMainDiv}`}>
      <div className={`${styles.interrvalTitleDiv}`}>
        <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
          {t('fee_intervals')}
        </div>

        <div
          onClick={() => addButtonClicked()}
          className={`${styles.AddIntervalIcon}`}
        >
          <AddIcon />
        </div>
        <div
          onClick={() => deleteButtonClicked()}
          className={`${styles.RemoveIntervalIcon}`}
        >
          <RemoveIcon />
        </div>
      </div>
      <table>
        <tr>
          <td className={`${styles.interrvalColumn}`}>{`${t('from')}`}</td>
          <td
            className={`${styles.interrvalColumn}`}
          >{`${t('diff_amount')}`}</td>
          <td className={`${styles.interrvalColumn}`}>{`${t('type')}`}</td>

          <td className={`${styles.interrvalColumn}`} />
          <td
            className={`${styles.interrvalColumn}`}
          >{`${t('final_amount')}`}</td>
        </tr>

        {newFeeDefinitionArgs.feeIntervals?.map((interval) => {
          const index =
            newFeeDefinitionArgs.feeIntervals?.indexOf(interval) ?? 0

          return (
            <Fragment>
              <tr key={index}>
                <td className={`${styles.interrvalColumn}`}>
                  <AmountInput
                    value={interval.from ?? 0}
                    onChange={(value) => handleFromValue(index, value)}
                  />
                </td>

                <td className={`${styles.interrvalColumn}`}>
                  <AmountInput
                    value={interval.diffAmount ?? 0}
                    onChange={(value) => handleDiffAmountValue(index, value)}
                  />
                </td>
                <td className={`${styles.interrvalColumn}`}>
                  <Select
                    style={{ marginLeft: '1rem' }}
                    value={interval.type}
                    onChange={(e) => {
                      handleType(index, e.target.value as EFeeIntervalType)
                    }}
                  >
                    <MenuItem value="" />
                    {Object.values(EFeeIntervalType).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
                <td className={`${styles.interrvalColumn}`} />
                <td className={`${styles.interrvalColumn}`}>
                  <TextInput
                    value={`${interval.diffAmount + (newFeeDefinitionArgs.amount ?? 0)}`}
                  />
                </td>
              </tr>
            </Fragment>
          )
        })}
      </table>
    </div>
  )
}

export default FeeDefinitionInterval
