import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationAddShopIdArgs } from '@/schemaTypes'

const ADD_SHOP_ID = gql`
  mutation addShopId($shopId: ObjectId!, $employeeId: ObjectId!) {
    addShopId(shopId: $shopId, employeeId: $employeeId) {
      _id
      firstname
      lastname
      shopIds
    }
  }
`

type TData = { addShopId: Mutation['addShopId'] }
type TVariables = MutationAddShopIdArgs

export function useAddShopId(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [addShopId] = useMutation<TData, TVariables>(ADD_SHOP_ID, opts)

  return addShopId
}
