import { Typography } from '@material-ui/core'
import { Paper } from '@mui/material'
import * as React from 'react'
import { Fragment } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ReservedPaybackData } from '@/schemaTypes'
import { printLocalAmount } from '@/utils/misc'
import { printLocalDate } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'

interface ReservedPaybackProps {
  reservedPaybackData: ReservedPaybackData
}

const ReservedPaybackBox: FunctionComponent<ReservedPaybackProps> = (props) => {
  const { reservedPaybackData } = props
  const { t } = useTranslation()

  return (
    <Fragment>
      <Paper className="u-p-sm u-12/12 u-mb-sm">
        {reservedPaybackData.isExtension ? (
          <>
            <Typography variant="h6" gutterBottom>
              {t('extension_reserved_data', {
                date: printLocalDate(reservedPaybackData.date, {
                  timezone: defaultTimezone,
                }),
              })}
            </Typography>

            <Typography variant="body1" gutterBottom>
              {t('extension_total', {
                amount: printLocalAmount(reservedPaybackData.dueAmount),
              })}
            </Typography>

            <Typography variant="body2">
              {t('extension_fee', {
                fee: printLocalAmount(reservedPaybackData.feeAmount),
              })}
            </Typography>

            <Typography variant="body2">
              {t('extension_partial_refund', {
                partialRedemptionAmount: printLocalAmount(
                  reservedPaybackData.partialRedemptionAmount ?? 0,
                ),
              })}
            </Typography>
          </>
        ) : (
          <Typography>
            {t('payback_reserved_data', {
              amount: printLocalAmount(reservedPaybackData.dueAmount),
            })}
          </Typography>
        )}
      </Paper>
    </Fragment>
  )
}

export default ReservedPaybackBox
