import connectFormikField from '../connectFormikField/connectFormikField'
import { ComponentProps } from 'react'
import Switch from '@/redesign/components/Switch/Switch'

interface FormikInputSwitchProps
  extends Omit<ComponentProps<typeof Switch>, 'checked' | 'value'> {
  tooltipText?: string
  value?: boolean
}

const FormikSwitch = connectFormikField(
  ({ value, ...props }: FormikInputSwitchProps) => {
    return <Switch {...props} checked={!!value} />
  },
)

export default FormikSwitch
