import styled from '@emotion/styled'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

export interface UploadButtonProps {
  idPrefix?: string
  name: string
  disableUploadButton?: boolean
  multiple?: boolean
  onAddFiles: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const UploadButton = ({
  idPrefix,
  name,
  disableUploadButton,
  multiple,
  onAddFiles,
}: UploadButtonProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()

  return (
    <UploadButtonContainer
      className="flex justify-center items-center"
      onClick={() => {
        inputRef?.current?.click()
      }}
      id={`${idPrefix}add-file`}
    >
      <div className="flex flex-row">
        <i className="pi pi-plus mr-2" />
        <UploadButtonText>{t('upload_placeholder')}</UploadButtonText>
        <input
          name={name}
          className="hidden"
          disabled={disableUploadButton}
          onChange={onAddFiles}
          ref={inputRef}
          multiple={multiple}
          type="file"
        />
      </div>
    </UploadButtonContainer>
  )
}

const UploadButtonContainer = styled.div`
  border-radius: 0.375rem;
  border: 2px dashed var(--surface-200, #eee);
  background: var(--surface-0, #fff);
  width: 6.54331rem;
  height: 8.21875rem;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
  }
`

const UploadButtonText = styled.p`
  color: var(--button-secondary-background, #64748b);
  font-family: 'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.05rem; /* 120% */
`

export default UploadButton
