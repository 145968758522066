import styled from '@emotion/styled'
import { TableBody, TableCell, TableRow, TextField } from '@material-ui/core'
import { Autocomplete } from '@mui/material'
import { Avatar } from 'primereact/avatar'
import { Divider } from 'primereact/divider'
import { RadioButton } from 'primereact/radiobutton'
import { FunctionComponent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PaymentTypePicker from '@/components/PaymentTypePicker'
import ShopSelect from '@/components/ShopSelect'
import TableValue from '@/components/TableValue'
import { useSearchCashBookCashFlows } from '@/domains/deals/hooks/searchCashBookCashFlows'
import {
  Deal,
  DealSurplusPaymentArgs,
  DealValorizationCalculation,
  ECashBookPaymentReference,
} from '@/schemaTypes'
import { assertDealConnectedToRegisteredCustomer } from '@/utils/deal'
import {
  isShopLevelPaymentType,
  printLocalAmount,
  useIsUserSuperAdmin,
} from '@/utils/misc'

export interface SurplusCashbookConnectionProps {
  deal: Deal
  dealValorizationCalculation: DealValorizationCalculation
  surplusPaymentArgs: DealSurplusPaymentArgs
  setSurplusPaymentArgs: (data: DealSurplusPaymentArgs) => void
}

const SurplusCashbookConnection: FunctionComponent<
  SurplusCashbookConnectionProps
> = (props) => {
  const {
    deal,
    dealValorizationCalculation,
    surplusPaymentArgs,
    setSurplusPaymentArgs,
  } = props

  assertDealConnectedToRegisteredCustomer(deal)

  const { t } = useTranslation()
  const [searchedCashFlowNumber, setSearchedCashFlowNumber] = useState<string>()
  const isSuperAdmin = useIsUserSuperAdmin()

  const { queryResult } = useSearchCashBookCashFlows({
    variables: {
      cashFlowNumber: searchedCashFlowNumber ?? '',
    },
    nextFetchPolicy: 'cache-first',
    skip: !searchedCashFlowNumber,
  })

  return (
    <StyledDiv isSuperAdmin={isSuperAdmin}>
      <StyledInnerDiv isSuperAdmin={isSuperAdmin}>
        <CustomerViewer customer={deal.customer} />
        <Divider />
        {isSuperAdmin && (
          <>
            <div className="flex align-items-center mb-4">
              <RadioButton
                name="cashbookEntry"
                value={true}
                onChange={() =>
                  setSurplusPaymentArgs({
                    ...surplusPaymentArgs,
                    createNewCashbook: true,
                  })
                }
                checked={surplusPaymentArgs.createNewCashbook}
              />
              <label className="ml-2">
                {t('deal_valorization.create_new_cashbook_entry')}
              </label>
            </div>

            <div className="flex align-items-center">
              <RadioButton
                name="cashbookEntry"
                value={false}
                onChange={() =>
                  setSurplusPaymentArgs({
                    ...surplusPaymentArgs,
                    createNewCashbook: false,
                  })
                }
                checked={!surplusPaymentArgs.createNewCashbook}
              />
              <label className="ml-2">
                {t('deal_valorization.connect_to_existing_cashbook_entry')}
              </label>
            </div>
          </>
        )}

        <StyledTable>
          <TableBody>
            {surplusPaymentArgs.createNewCashbook ? (
              <>
                <TableRow>
                  <StyledCell align="right">
                    {t('payout.payout_amount')}:
                  </StyledCell>
                  <StyledCell>
                    <TableValue
                      type="float"
                      formatFunc={printLocalAmount}
                      value={dealValorizationCalculation.surplusAmount}
                    />
                  </StyledCell>
                </TableRow>

                <TableRow>
                  <StyledCell align="right">{t('payout_type')}:</StyledCell>
                  <StyledCell>
                    <PaymentTypePicker
                      label=""
                      paymentType={surplusPaymentArgs.paymentType}
                      onChangePaymentType={(value) => {
                        setSurplusPaymentArgs({
                          ...surplusPaymentArgs,
                          paymentType: value,
                        })
                      }}
                      businessUnit={deal.businessUnit}
                      isOutFlow={true}
                      removePadding={true}
                    />
                  </StyledCell>
                </TableRow>

                <TableRow>
                  <StyledCell align="right">
                    {t('payment_reference')}:
                  </StyledCell>
                  <StyledCell>
                    <TableValue
                      type="float"
                      value={ECashBookPaymentReference.SurplusPayout}
                    />
                  </StyledCell>
                </TableRow>
                {isShopLevelPaymentType(surplusPaymentArgs.paymentType) && (
                  <TableRow>
                    <StyledCell align="right">{t('shop.label')}:</StyledCell>
                    <StyledCell>
                      <ShopSelect
                        style={{
                          marginLeft: '-1rem',
                        }}
                        shopId={surplusPaymentArgs.shopId}
                        hideLabel
                        onChange={(value) =>
                          setSurplusPaymentArgs({
                            ...surplusPaymentArgs,
                            shopId: value,
                          })
                        }
                      />
                    </StyledCell>
                  </TableRow>
                )}
              </>
            ) : (
              <>
                <TableRow>
                  <StyledCell>{t('cash_book_number')}:</StyledCell>
                  <StyledCell>
                    <Autocomplete
                      options={
                        queryResult.data?.searchCashBookCashFlows
                          .filter((c) => c.outflow)
                          .map((c) => {
                            const option = {
                              value: c._id,
                              label: `No: ${c.cashFlowNumber} - ${t('payment_reference_' + c.paymentReference)}: ${printLocalAmount(c.outflow)} - ${t(
                                c.cashBookType,
                              )}`,
                            }
                            return option
                          }) ?? []
                      }
                      getOptionLabel={(option) => option.label}
                      style={{ width: 310 }}
                      onChange={(event, data: any) => {
                        setSurplusPaymentArgs({
                          ...surplusPaymentArgs,
                          existingCashbookId: data?.value,
                        })
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          onChange={(value) => {
                            setSearchedCashFlowNumber(value.target.value)
                          }}
                        />
                      )}
                    />
                  </StyledCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </StyledTable>
      </StyledInnerDiv>
    </StyledDiv>
  )
}

const CustomerViewer = ({ customer }) => {
  const avatarLetters = useMemo(() => {
    return `${customer?.firstname.charAt(0)}${customer?.lastname.charAt(0)}`
  }, [customer?.firstname, customer?.lastname])

  return (
    <div className="flex flex-row items-center mb-4">
      <Avatar
        label={avatarLetters}
        className="mr-5 pb-0"
        size="large"
        shape="circle"
      />

      <div className="flex flex-col">
        <span className="flex items-center text-lg font-bold">
          {customer.firstname} {customer.lastname}
        </span>
        <div className="flex items-center ">
          <i className="pi pi-envelope text-sm mr-2" />
          <span>{customer.email}</span>
        </div>
        <div className="flex items-center">
          <i className="pi pi-phone text-sm mr-2" />
          <span>{customer.phone}</span>
        </div>
      </div>
    </div>
  )
}

export default SurplusCashbookConnection

interface StyledProps {
  isSuperAdmin?: boolean
}

const StyledDiv = styled.div`
  margin-top: 2rem;
  min-width: ${(props: StyledProps) =>
    props.isSuperAdmin ? '550px' : '450px'};
  margin-bottom: 2rem;
`

const StyledInnerDiv = styled.div`
  width: ${(props: StyledProps) => (props.isSuperAdmin ? '480px' : '380px')};
  margin: auto;
`

const StyledTable = styled.table`
  margin: 0 auto;
  margin-top: 1rem;
  min-width: 300px;
`

const StyledCell = styled(TableCell)`
  border-bottom: none;
  padding: 0.7rem 0 0 1.5rem;
`
