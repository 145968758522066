import styled from '@emotion/styled'
import Compressor from '@uppy/compressor'
import Uppy, { UploadResult } from '@uppy/core'
import '@uppy/core/dist/style.css'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import Tus from '@uppy/tus'
import { FieldHookConfig, useField } from 'formik'
import { useFormikContext } from 'formik'
import { isArray, uniqueId } from 'lodash'
import { Button } from 'primereact/button'
import { ConfirmPopup } from 'primereact/confirmpopup'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ALLOWED_FILE_TYPES, MAX_IMAGE_SIZE_MB, UppyError } from '@/constants'
import PDFIcon from '@/images/pdf_icon.png'
import useConfirmationPopup from '@/redesign/helpers/useConfirmationPopup'
import { FileTusMetadataArgs } from '@/schemaTypes'
import UploadButton from './UploadButton'

export type UppyFileObject = UppyFile

export type UpppUploaderRefMethods = {
  remove: (file: UppyUploaderFile) => void
}

export interface UppyUploaderFile {
  id: string
  filename: string
  contentType: string
  url?: string
  fileId?: string
}

export type UppyUploaderResults =
  | FileTusMetadataArgs
  | FileTusMetadataArgs[]
  | null

type UppyUploaderProps = FieldHookConfig<UppyUploaderFile[]> & {
  idPrefix?: string
  label?: string
  multiple?: boolean
  allowedFileTypes?: Array<string>
  checkIcon?: 'success' | 'failure'
  disableUploadButton?: boolean
  confirmBeforeRemove?: boolean
  confirmPopupBeforeRemove?: boolean
  onConfirmRemovingFile?: (file: UppyUploaderFile) => void
  compress?: boolean
  onOpenGalery?: (
    files: Array<{ url: string; type: string }>,
    fileIndex: number,
  ) => void
  onFileChanged?: (result: UppyUploaderResults) => void
}

export const UppyUploader = forwardRef<
  UpppUploaderRefMethods,
  UppyUploaderProps
>(
  (
    {
      label,
      idPrefix,
      multiple,
      allowedFileTypes = ALLOWED_FILE_TYPES,
      checkIcon,
      confirmBeforeRemove,
      confirmPopupBeforeRemove,
      onOpenGalery,
      disableUploadButton,
      compress = true,
      onConfirmRemovingFile,
      onFileChanged,
      ...fieldProps
    }: UppyUploaderProps,
    ref,
  ) => {
    const { setSubmitting } = useFormikContext()
    const { t } = useTranslation()
    const [field, _, helpers] = useField(fieldProps)
    const [internalFileStateById, setInternalFileStateById] = useState<
      Record<
        string,
        { progress: number; uploadComplete: boolean; thumbnailUrl: string }
      >
    >({})
    const [uppyRestrictionError, setUppyRestrictionError] = useState<
      string | null
    >(null)
    const { confirmPopupAction } = useConfirmationPopup()

    const [uppyInstance] = useState(() => {
      const instance = new Uppy({
        id: uniqueId('uppy-'),
        autoProceed: true,
        restrictions: {
          maxFileSize: MAX_IMAGE_SIZE_MB * 10000000,
          allowedFileTypes: allowedFileTypes,
        },
        locale: {
          strings: {
            noDuplicates: UppyError.FILE_DUPLICATION,
            exceedsSize: UppyError.EXCEED_MAX_FILE_SIZE,
            youCanOnlyUploadFileTypes: UppyError.FILE_TYPE_UNALLOWED,
          },
        },
      })

      if (compress) {
        instance.use(Compressor)
      }

      instance
        .use(Tus, {
          endpoint: process.env.REACT_APP_TUS_SERVER_URL,
          retryDelays: [0, 1000, 3000, 5000],
        })
        .use(ThumbnailGenerator, {
          id: 'ThumbnailGenerator',
        })

      instance.on('upload-progress', (file: UppyFileObject) => {
        setInternalFileStateById((prev) => ({
          ...prev,
          [file.id]: {
            ...prev[file.id],
            progress: instance.getFile(file.id).progress?.percentage ?? 0,
            uploadComplete: instance.getFile(file.id).progress?.uploadComplete,
          },
        }))
      })

      instance.on('thumbnail:generated', (file: UppyFileObject) => {
        setInternalFileStateById((prev) => ({
          ...prev,
          [file.id]: {
            ...prev[file.id],
            thumbnailUrl: getUppyFilePreview(instance.getFile(file.id)) ?? '',
          },
        }))
      })

      return instance
    })

    useEffect(() => {
      function handleUploadComplete(result: UploadResult) {
        if (result.failed.length === 0) {
          setSubmitting(false)
          result.successful.map((f: any) => {
            setInternalFileStateById((prev) => ({
              ...prev,
              [f.id]: {
                ...prev[f.id],
                progress: uppyInstance.getFile(f.id).progress?.percentage,
                uploadComplete: uppyInstance.getFile(f.id).progress
                  ?.uploadComplete,
              },
            }))
          })

          helpers.setValue(
            field.value.map((f) => {
              if (f.url) {
                return f
              } else {
                const file = uppyInstance.getFile(f.id)
                return {
                  ...f,
                  url: getUppyFilePreview(file) || undefined,
                  fileId: getUppyFileId(file) || undefined,
                }
              }
            }),
          )

          onFileChanged &&
            onFileChanged(
              field.value.map((f) => {
                return f.url
                  ? f
                  : {
                      ...f,
                      url: getUppyFilePreview(uppyInstance.getFile(f.id)),
                      fileId: getUppyFileId(uppyInstance.getFile(f.id)),
                    }
              }),
            )
        }
      }
      uppyInstance.on('upload', () => {
        setSubmitting(true)
      })

      uppyInstance.on('complete', handleUploadComplete)

      return () => {
        uppyInstance.off('complete', handleUploadComplete)
      }
      // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uppyInstance, field.value, helpers])

    const files = useMemo(() => {
      if (field.value) {
        if (isArray(field.value)) {
          return field.value
        }

        return [field.value]
      }

      return []
    }, [field.value])

    useEffect(() => {
      if (uppyRestrictionError) {
        const timeout = setTimeout(() => {
          setUppyRestrictionError(null)
        }, 5000)

        return () => clearTimeout(timeout)
      }
    }, [uppyRestrictionError])

    const handleUppyError = useCallback(
      (file: File, error: unknown) => {
        if (!(error instanceof Error)) {
          setUppyRestrictionError(t('an_unexpected_error_occurred'))
        } else {
          let errorMessage = t('an_unexpected_error_occurred')
          switch (error.message) {
            case UppyError.FILE_DUPLICATION:
              errorMessage = t('file_duplication', { filename: file.name })
              break

            case UppyError.EXCEED_MAX_FILE_SIZE:
              errorMessage = t('exceed_max_file_size', {
                size: MAX_IMAGE_SIZE_MB,
              })
              break

            case UppyError.FILE_TYPE_UNALLOWED:
              errorMessage = t('file_type_unallowed', {
                allowedFileTypes: allowedFileTypes.join(', '),
              })
              break
          }

          setUppyRestrictionError(errorMessage)
        }
      },
      [allowedFileTypes, t],
    )

    const handleAddFiles = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
          return
        }

        const files = Array.from(e.target.files)
        const formikFiles: UppyUploaderFile[] = []
        for (const file of files) {
          try {
            const id = uppyInstance?.addFile({
              source: 'file input',
              name: file.name,
              type: file.type,
              data: file,
            })

            formikFiles.push({
              id,
              filename: file.name,
              contentType: file.type,
            })
          } catch (error) {
            handleUppyError(file, error as Error)
          }
        }
        if (multiple) {
          helpers.setValue([...(field.value || []), ...formikFiles])
        } else {
          helpers.setValue(formikFiles)
        }

        e.target.value = ''
      },
      [uppyInstance, handleUppyError, field.value, helpers, multiple],
    )

    const removeFile = useCallback(
      (file: UppyUploaderFile) => {
        if (!file.url) {
          return
        }

        helpers.setValue(
          multiple ? field.value.filter((f) => f.url !== file.url) : [],
        )
        onFileChanged?.(
          multiple ? field.value.filter((f) => f.url !== file.url) : [],
        )

        const uppyFile = uppyInstance.getFiles().find((f) => f.url === file.url)
        if (uppyFile) {
          uppyInstance.removeFile(file.url)
        }
      },
      [uppyInstance, field.value, helpers, multiple, onFileChanged],
    )

    const handleRemoveFile = useCallback(
      (file: UppyUploaderFile, event) => {
        event.preventDefault()
        if (!confirmBeforeRemove && !confirmPopupBeforeRemove) {
          removeFile(file)
          return
        }

        // custom confirming from outside
        if (onConfirmRemovingFile) {
          onConfirmRemovingFile(file)
          return
        }

        if (confirmPopupBeforeRemove) {
          confirmPopupAction({
            target: event.currentTarget,
            onAccept: () => removeFile(file),
          })
          return
        }
      },
      // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        removeFile,
        confirmBeforeRemove,
        confirmPopupAction,
        confirmPopupBeforeRemove,
      ],
    )

    const handleOpenGallery = useCallback(
      (file: UppyUploaderFile, index: number) => {
        if (onOpenGalery) {
          onOpenGalery(
            field.value
              .filter(
                (
                  f,
                ): f is typeof f & {
                  url: Exclude<(typeof f)['url'], undefined>
                } => f.url !== undefined,
              )
              .map((f) => ({
                url: f.url!,
                type: f.contentType,
              })),
            index,
          )
        } else {
          window.open(
            file.url ?? internalFileStateById[file.id].thumbnailUrl,
            '_blank',
          )
        }
      },
      [field.value, onOpenGalery, internalFileStateById],
    )

    useImperativeHandle(ref, () => {
      return {
        remove: (file: UppyUploaderFile) => {
          removeFile(file)
        },
      }
    })

    return (
      <>
        <ConfirmPopup />
        <div className="mb-6">
          {label && <Label>{label}</Label>}

          <div className={`flex flex-wrap ${label ? 'mt-4' : ''}`}>
            {files?.map((file, i) => {
              const internalState = internalFileStateById[file?.id]
              const preview =
                file?.contentType === 'application/pdf'
                  ? PDFIcon
                  : internalState?.thumbnailUrl ?? file?.url

              return (
                <div
                  key={i}
                  className={`mr-6 ${disableUploadButton ? 'opacity-50' : 'opacity-100'}`}
                >
                  <div className="flex justify-center relative mb-1.5">
                    <FilePreview
                      onClick={() => handleOpenGallery(file, i)}
                      url={preview}
                      isUploaded={
                        !file?.id && !internalState
                          ? true
                          : internalState?.uploadComplete ?? false
                      }
                    >
                      {internalState && !internalState?.uploadComplete && (
                        <ProgressWrapper>
                          {internalState.progress} %
                        </ProgressWrapper>
                      )}
                    </FilePreview>

                    <RemoveButton
                      onClick={(e) => {
                        handleRemoveFile(file, e)
                      }}
                      icon="pi pi-trash"
                      rounded
                      severity="secondary"
                      type="button"
                    />

                    {checkIcon && (
                      <>
                        <CheckIcon
                          type={checkIcon}
                          className="flex justify-center items-center"
                        >
                          {checkIcon === 'success' && (
                            <i
                              className="custom-target-icon pi pi-check text-sm cursor-pointer"
                              data-pr-tooltip={t('read_picea_checked_success')}
                            />
                          )}
                          {checkIcon === 'failure' && (
                            <i
                              className="custom-target-icon pi pi-times text-sm cursor-pointer"
                              data-pr-tooltip={t('read_picea_checked_failure')}
                            />
                          )}
                        </CheckIcon>
                      </>
                    )}
                  </div>
                  <FileName>{file && getFilename(file)}</FileName>
                </div>
              )
            })}
            {(multiple ? true : files.length === 0) && (
              <UploadButton
                idPrefix={idPrefix}
                name={field.name}
                disableUploadButton={disableUploadButton}
                multiple={multiple}
                onAddFiles={handleAddFiles}
              />
            )}
          </div>
          {uppyRestrictionError && (
            <ErrorText>{uppyRestrictionError}</ErrorText>
          )}
        </div>
      </>
    )
  },
)

const getUppyFilePreview = (file: UppyFileObject) =>
  file.preview ?? (file.data ? URL.createObjectURL(file.data) : null)

const getFilename = (file: UppyUploaderFile) => {
  const filename = file.filename
  return filename?.length > 20 ? filename?.substring(0, 20) + '...' : filename
}

const getUppyFileId = (file: UppyFileObject): string | null => {
  if (file?.response?.uploadURL) {
    return file.response.uploadURL.replace(
      process.env.REACT_APP_TUS_SERVER_URL + '/uploads/',
      '',
    )
  }
  return null
}

const Label = styled.label`
  color: var(--global-text-color, #495057);
  font-family: 'Inter';
  font-size: 1.09375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.09375rem;
`

export const FilePreview = styled.li<{ url: string; isUploaded?: boolean }>`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  filter: brightness(100%);
  opacity: ${(props) => (props.isUploaded ? 1 : 0.6)};
  list-style: none;
  border-radius: 5px;
  cursor: pointer;
  width: 80px;
  height: 110px;
`

const RemoveButton = styled(Button)`
  margin-left: -1.188rem;
  margin-top: -0.563rem;
  width: 2.625rem !important;
  height: 2.625rem !important;
  padding: 0.65625rem 0rem !important;
`

const CheckIcon = styled.div<{ type: 'success' | 'failure' }>`
  cursor: pointer;
  transform: translateX(25px);
  position: absolute;
  color: white;
  border-radius: 50%;
  bottom: 0.625rem;
  left: 45%;
  padding: 0.2rem;

  ${({ type }) => type === 'success' && `background: #04d225;`}
  ${({ type }) => type === 'failure' && `background: #f31c0e;`}
`

const ProgressWrapper = styled.div`
  top: 0px;
  right: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  font-size: 20px;
  color: white;
  font-weight: 600;
  text-shadow: -2px 0px 12px rgba(0, 0, 0, 1);
`
const FileName = styled.p`
  color: var(--global-text-color, #495057);
  font-family: 'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.875rem;
`

const ErrorText = styled.p`
  font-size: 1rem;
  font-family: 'Inter';
  font-weight: 400;
  line-height: 1.5;
  color: #f44336;
`

interface IndexedObject<T> {
  [key: string]: T
  [key: number]: T
}

export type InternalMetadata = { name: string; type?: string }
export interface FileProgress {
  uploadStarted: number | null
  uploadComplete: boolean
  percentage: number
  bytesUploaded: number
  bytesTotal: number
}

export interface UppyFile<
  TMeta = IndexedObject<any>,
  TBody = IndexedObject<any>,
> {
  data: Blob | File
  extension: string
  id: string
  isPaused?: boolean
  isRemote: boolean
  meta: InternalMetadata & TMeta
  name: string
  preview?: string
  progress?: FileProgress
  remote?: {
    host: string
    url: string
    body?: Record<string, unknown>
  }
  size: number
  source?: string
  type?: string
  response?: {
    body: TBody
    status: number
    uploadURL: string | undefined
  }
}
