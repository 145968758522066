import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationExchangeDealItemArgs } from '@/schemaTypes'
import { dealCalculationFragments, dealFragments } from './dealFragments'

const EXCHANGE_DEAL_ITEM = gql`
  mutation exchangeDealItem(
    $dealId: ObjectId!
    $oldItemId: ObjectId!
    $newItemArgs: DealItemCreateArgs!
  ) {
    exchangeDealItem(
      dealId: $dealId
      oldItemId: $oldItemId
      newItemArgs: $newItemArgs
    ) {
      _id
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
      pawnData {
        ...PawnDataFragment
      }
      purchaseData {
        ...PurchaseDataFragment
      }
    }
  }
  ${dealFragments.pawnData}
  ${dealFragments.dealItem}
  ${dealFragments.purchaseData}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = { exchangeDealItem: Mutation['exchangeDealItem'] }
type TVariables = MutationExchangeDealItemArgs

export function useExchangeDealItem(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  return useMutation<TData, TVariables>(EXCHANGE_DEAL_ITEM, opts)
}
