import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useContext } from 'react'
import { Context } from '@/context'
import { fragments } from '@/hooks/fragments'
import {
  ELanguageCode,
  Mutation,
  MutationUpdateAuctionItemArgs,
} from '@/schemaTypes'
import { auctionFragments } from './auctionFragments'

const UPDATE_AUCTION_ITEM = gql`
  mutation updateAuctionItem(
    $auctionItemArgs: AuctionItemArgs!
    $languageCode: ELanguageCode!
  ) {
    updateAuctionItem(auctionItemArgs: $auctionItemArgs) {
      ...AuctionDetailsFragment
    }
  }
  ${fragments.customDealFormQuestion}
  ${fragments.formAnswers}
  ${auctionFragments.auctionDetails}
  ${fragments.address}
`

type TData = { updateAuctionItem: Mutation['updateAuctionItem'] }
type TVariables = {
  languageCode?: ELanguageCode
} & MutationUpdateAuctionItemArgs

export function useUpdateAuctionItem(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const { language } = useContext(Context)

  const [updateAuctionItem] = useMutation<TData, TVariables>(
    UPDATE_AUCTION_ITEM,
    {
      ...opts,
      variables: { ...(opts.variables as TVariables), languageCode: language },
    },
  )

  return updateAuctionItem
}
