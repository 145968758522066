import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateMaintenanceWindowArgs } from '@/schemaTypes'

const CREATE_MAINTENANCE_WINDOW = gql`
  mutation createMaintenanceWindow(
    $scheduledStartTime: DateTime!
    $duration: Float!
  ) {
    createMaintenanceWindow(
      scheduledStartTime: $scheduledStartTime
      duration: $duration
    ) {
      _id
      scheduledStartTime
      duration
    }
  }
`

type TData = { createMaintenanceWindow: Mutation['createMaintenanceWindow'] }
type TVariables = MutationCreateMaintenanceWindowArgs

export function useCreateMaintenanceWindow(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createMaintenanceWindow] = useMutation<TData, TVariables>(
    CREATE_MAINTENANCE_WINDOW,
    opts,
  )

  return createMaintenanceWindow
}
