import { CustomDealPayout } from '..'
import { useAddCustomDealNote } from '../../../hooks/useAddCustomDealNote'
import { useUpdateCustomDealNote } from '../../../hooks/useUpdateCustomDealNote'
import { CustomDealStatus } from '../CustomDealStatus'
import { ApolloQueryResult } from '@apollo/client'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import EuroOutlinedIcon from '@material-ui/icons/EuroOutlined'
import { Autocomplete } from '@material-ui/lab'
import dayjs from 'dayjs'
import DOMPurify from 'dompurify'
import { FormikErrors } from 'formik'
import parse from 'html-react-parser'
import { pick } from 'lodash'
import get from 'lodash/get'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CreateNote from '@/components/Note/CreateNote'
import NoteContainer from '@/components/Note/NoteContainer'
import { useGetResponseTemplates } from '@/domains/responseTemplates/hooks'
import { useMutationShowingErrors } from '@/hooks'
import {
  Company,
  CustomDeal,
  CustomDealCreateArgs,
  CustomDealEmployeeResponse,
  CustomDealEmployeeResponseArgs,
  CustomDealUpdateArgs,
  ECustomDealStatusType,
  EDealType,
  ELanguageCode,
  ETransportMode,
  Employee,
  Note,
  NoteCreateArgs,
  NoteUpdateArgs,
  Query,
  QueryGetCustomDealByIdArgs,
  ResponseTemplate,
} from '@/schemaTypes'
import styles from './styles.module.scss'

type TData = { getCustomDealById: Query['getCustomDealById'] }
type TVariables = { languageCode: ELanguageCode } & QueryGetCustomDealByIdArgs

interface Props {
  onEventChange: (status: ECustomDealStatusType) => void
  customDeal: Omit<Partial<CustomDeal>, 'notes'> & {
    notes?: Array<Omit<Note, '_id'> & { _id?: string }> | null
  }
  onChangeField: (
    field: string,
    value:
      | string
      | number
      | null
      | CustomDealEmployeeResponseArgs
      | boolean
      | NoteCreateArgs[],
  ) => void
  errors?: FormikErrors<CustomDealCreateArgs | CustomDealUpdateArgs>
  employees?: Employee[]
  isCarPawn?: boolean
  companies: Company[]
  totalPayoutAmount: number
  minimumPawnDuration: number
  setFieldError: (field: string, value: string | undefined) => void
  refetchCustomDeal?: (
    variables?: Partial<TVariables> | undefined,
  ) => Promise<ApolloQueryResult<TData>>
}

interface GridItem {
  translationLabel: string
  value: React.ReactNode
  isHidden?: boolean
}

const validTransportTypes = [
  ETransportMode.Shop,
  ETransportMode.StandardShipment,
  ETransportMode.Storebox,
]

export function CustomDealOverview(props: Props) {
  const {
    customDeal,
    onEventChange,
    onChangeField,
    errors,
    employees,
    isCarPawn,
    setFieldError,
    totalPayoutAmount,
    minimumPawnDuration,
    companies,
    refetchCustomDeal,
  } = props
  const { t } = useTranslation()
  const [selectedResponseTemplate, setSelectedResponseTemplate] =
    useState<ResponseTemplate>()

  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false)

  const isShowNoteToCustomer = [
    ECustomDealStatusType.Declined,
    ECustomDealStatusType.Closed,
    ECustomDealStatusType.Expired,
  ].includes(customDeal.status!)

  const errorsOnUpdate = errors as FormikErrors<CustomDealUpdateArgs>

  const { responseTemplates } = useGetResponseTemplates()

  const onSelectResponseTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    const responseTemplate = responseTemplates.find(
      (template) => template._id === value,
    )
    setSelectedResponseTemplate(responseTemplate)
    onChangeField(
      'employeeResponse',
      pick(responseTemplate, [
        'name',
        'subject',
        'closingText',
        'editableText',
        'openingText',
      ]),
    )
  }

  const onChangeEventStatus = (status: ECustomDealStatusType) => {
    setIsUpdatingStatus(true)
    onEventChange(status)
  }

  const onChangeDealType = (dealType: EDealType) => {
    onChangeField('dealType', dealType)
    onChangeField(
      'durationInDays',
      dealType === EDealType.Pawn ? minimumPawnDuration : 0,
    )
  }

  const addDealNote = useMutationShowingErrors({
    mutation: useAddCustomDealNote(),
    successMessage: `${t('update_successful')}!`,
  })

  const updateCustomDealNote = useMutationShowingErrors({
    mutation: useUpdateCustomDealNote(),
    successMessage: `${t('update_successful')}!`,
  })

  const onAddDealNote = (noteCreateArgs: NoteCreateArgs) => {
    addDealNote({
      variables: { customDealId: customDeal._id, noteCreateArgs },
      onCompleted: () => refetchCustomDeal && refetchCustomDeal(),
    })
  }

  const onUpdateDealNote = (
    noteId: string | number,
    noteUpdateArgs: NoteUpdateArgs,
  ) => {
    updateCustomDealNote({
      variables: { customDealId: customDeal._id, noteId, noteUpdateArgs },
      onCompleted: () => refetchCustomDeal && refetchCustomDeal(),
    })
  }

  useEffect(() => {
    const error =
      (customDeal?.durationInDays ?? 0) < minimumPawnDuration &&
      customDeal?.dealType === EDealType.Pawn
    if (error) {
      setFieldError(
        'durationInDays',
        'CUSTOM_DEAL_DURATION_IN_DAYS_BELOW_MINIMUM_PAWN_DURATION',
      )
    } else {
      setFieldError('durationInDays', errors?.durationInDays)
    }
  }, [
    minimumPawnDuration,
    customDeal?.durationInDays,
    customDeal?.dealType,
    setFieldError,
    errors?.durationInDays,
  ])

  const durationInDaysErrorMessage = useMemo(() => {
    if (errors?.durationInDays) {
      return t(errors?.durationInDays, { durationInDays: minimumPawnDuration })
    }

    return null
  }, [errors?.durationInDays, minimumPawnDuration, t])

  const dealGridItems: GridItem[] = [
    {
      translationLabel: 'company.singular',
      value: (
        <Select
          value={customDeal?.companyId ?? ''}
          onChange={(event) =>
            onChangeField('companyId', event.target.value as string)
          }
        >
          {companies.map((c) => (
            <MenuItem
              key={c._id}
              value={c._id}
              disabled={!!c.successionCompanyId}
            >
              {c.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      translationLabel: 'created_at',
      value: dayjs(customDeal.createdAt).format('DD.MM.YYYY HH:mm'),
      isHidden: !customDeal.createdAt,
    },
    {
      translationLabel: 'status',
      value: (
        <>
          <div style={{ marginBottom: 15 }}>
            <CustomDealStatus
              status={customDeal.status}
              onEventChange={onChangeEventStatus}
            />
          </div>
          {customDeal.status === ECustomDealStatusType.Reviewing &&
            customDeal.issuanceCertificate?.url && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={customDeal.issuanceCertificate.url}
              >
                <u>{t('download_issuance_certificate')}</u>
              </a>
            )}
        </>
      ),
      isHidden: !customDeal.events,
    },
    {
      translationLabel: 'note',
      value: (
        <div>
          <FormControl fullWidth className="u-mb-sm">
            <InputLabel>{t('template_response')}</InputLabel>
            <Select
              value={selectedResponseTemplate?._id}
              onChange={onSelectResponseTemplate}
            >
              <MenuItem value="" disabled>
                {t('template_response')}
              </MenuItem>
              {responseTemplates.map((template) => (
                <MenuItem key={template._id} value={template._id}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
            {errorsOnUpdate?.employeeResponse &&
              typeof errorsOnUpdate.employeeResponse === 'string' && (
                <Typography color="error">
                  {t(errorsOnUpdate.employeeResponse)}
                </Typography>
              )}
          </FormControl>
          {customDeal?.employeeResponse && (
            <>
              <Typography variant="subtitle1">
                {' '}
                {parse(
                  DOMPurify.sanitize(customDeal.employeeResponse.openingText),
                )}
              </Typography>
              <TextField
                rows={5}
                multiline={true}
                fullWidth={true}
                variant="outlined"
                value={customDeal.employeeResponse.editableText}
                onChange={(e) => {
                  onChangeField('employeeResponse', {
                    ...(customDeal.employeeResponse as CustomDealEmployeeResponse),
                    editableText: e.target.value,
                  })
                }}
                className="u-mb-xs"
                style={{ paddingTop: 5, paddingBottom: 5 }}
              />
              {typeof errorsOnUpdate.employeeResponse === 'object' &&
                get(errorsOnUpdate, 'employeeResponse.editableText') && (
                  <Typography color="error">
                    {t(get(errorsOnUpdate, 'employeeResponse.editableText'))}
                  </Typography>
                )}
              <Typography variant="subtitle1">
                {' '}
                {parse(
                  DOMPurify.sanitize(customDeal.employeeResponse.closingText),
                )}
              </Typography>
            </>
          )}
        </div>
      ),
      isHidden: !isShowNoteToCustomer || !isUpdatingStatus,
    },
    {
      translationLabel: 'note',
      value: (
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            {' '}
            {parse(
              DOMPurify.sanitize(
                customDeal.employeeResponse?.openingText ?? '',
              ),
            )}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {' '}
            {parse(
              DOMPurify.sanitize(
                customDeal.employeeResponse?.editableText ?? '',
              ),
            )}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {' '}
            {parse(
              DOMPurify.sanitize(
                customDeal.employeeResponse?.closingText ?? '',
              ),
            )}
          </Typography>
        </div>
      ),
      isHidden:
        customDeal.status !== ECustomDealStatusType.Declined ||
        !customDeal.employeeResponse ||
        isUpdatingStatus,
    },
    {
      translationLabel: 'type',
      value: (
        <Select
          value={customDeal.dealType}
          onChange={(e) => onChangeDealType(e.target.value as EDealType)}
          style={{ width: 250 }}
          disabled={isCarPawn}
        >
          {isCarPawn ? (
            <MenuItem value={EDealType.Pawn}>{t('car_pawn')}</MenuItem>
          ) : (
            Object.values(EDealType).map((name) => (
              <MenuItem key={name} value={name}>
                {t(name)}
              </MenuItem>
            ))
          )}
        </Select>
      ),
    },
    {
      translationLabel: 'employee.label',
      value: (
        <Autocomplete
          value={
            employees?.find(
              (employee) => employee._id === customDeal.employeeId,
            ) ?? null
          }
          renderInput={(params) => <TextField {...params} />}
          onChange={(e, option) => {
            onChangeField('employeeId', (option as any)?._id ?? null)
          }}
          style={{ width: 250 }}
          options={employees ?? []}
          getOptionLabel={(employee) =>
            `${employee.firstname} ${employee.lastname}`
          }
        />
      ),
      isHidden: !employees,
    },
    {
      translationLabel: 'loan.loan_to_values',
      value: <CustomDealPayout payoutAmount={totalPayoutAmount} />,
    },
    {
      translationLabel: 'duration_in_days',
      value: (
        <TextField
          label={t('duration_in_days')}
          variant="filled"
          style={{ width: 250 }}
          size="small"
          type="number"
          value={customDeal.durationInDays}
          onChange={(e) => onChangeField('durationInDays', e.target.value)}
          error={!!errors?.durationInDays}
          helperText={
            <Typography style={{ fontSize: 12 }} color="error">
              {durationInDaysErrorMessage}
            </Typography>
          }
        />
      ),
    },
    {
      translationLabel: 'transport_type',
      value: (
        <Select
          value={customDeal.pickupTransportMode ?? ''}
          onChange={(e) => {
            onChangeField(
              'pickupTransportMode',
              e.target.value as ETransportMode,
            )
          }}
          style={{ width: 250 }}
          disabled={isCarPawn}
        >
          <MenuItem value="">
            <em>{t('none')}</em>
          </MenuItem>
          {validTransportTypes.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      ),
      isHidden: isCarPawn,
    },
    {
      translationLabel: 'payout_reviewed_by',
      value: (
        <Autocomplete
          value={
            employees?.find(
              (employee) => employee._id === customDeal.payoutApproverId,
            ) ?? null
          }
          renderInput={(params) => <TextField {...params} />}
          onChange={(e, option) => {
            onChangeField('payoutApproverId', (option as any)?._id ?? null)
          }}
          style={{ width: 250 }}
          options={employees ?? []}
          getOptionLabel={(employee) =>
            `${employee.firstname} ${employee.lastname}`
          }
        />
      ),
      isHidden: !employees,
    },
  ].filter((item) => !item.isHidden || !item.value)

  return (
    <div className="u-p-sm">
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6" className="u-mb-sm">
              {t('custom_deal_overview')}
              {customDeal.customDealNumber && (
                <span> #{customDeal.customDealNumber}</span>
              )}
            </Typography>
            <div>
              {customDeal.dealId && (
                <Link to={`/inApp/deals/${customDeal.dealId}`}>
                  <Button>
                    <EuroOutlinedIcon classes={{ root: styles.dealIcon }} />
                    {t('to_deal')}
                  </Button>
                </Link>
              )}
            </div>
          </Box>
          <Grid container spacing={2} alignItems="center">
            {dealGridItems.map((item) => (
              <React.Fragment key={item.translationLabel}>
                <Grid item xs={4}>
                  <Typography variant="subtitle1">
                    {t(item.translationLabel)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {item.value}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={6}>
          {customDeal._id ? (
            <NoteContainer
              horizontalMode
              notes={(customDeal.notes as Note[]) ?? []}
              onAddNote={onAddDealNote}
              onUpdateNote={onUpdateDealNote}
            />
          ) : (
            <>
              <CreateNote
                horizontalMode
                isCreateMode
                onAddNote={(note) => {
                  onChangeField('notes', [note])
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
