import withFieldWrapper from '../withFieldWrapper/withFieldWrapper'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { Dropdown, DropdownProps } from 'primereact/dropdown'

interface InputDropdownProps extends DropdownProps {
  isInvalid?: boolean
}

const InputDropdown = withFieldWrapper(
  ({ isInvalid, ...props }: InputDropdownProps) => {
    return (
      <DropdownStyled
        className={classNames({ 'p-invalid': isInvalid })}
        {...props}
      />
    )
  },
)

const DropdownStyled = styled(Dropdown)`
  height: 2.375rem;
  &&& {
    .p-inputtext {
      padding: 0.56rem;
      font-size: 0.875rem;
    }
    .p-dropdown-label.p-placeholder {
      opacity: 1;
      color: #9ca3af;
    }
  }
`

export default InputDropdown
