import { QueryHookOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import useDebouncedQuery from '@/hooks/useDebouncedQuery'
import { Query, QuerySearchProductsArgs } from '@/schemaTypes'

const SEARCH_PRODUCTS = gql`
  query searchProducts($args: ProductsFiltersArgs!) {
    searchProducts(args: $args) {
      nodes {
        ...ProductFragment
      }
      pageInfo {
        skip
        limit
        hasPrevPage
        hasNextPage
        total
      }
    }
  }
  ${fragments.product}
`

type TData = { searchProducts: Query['searchProducts'] }
type TVariables = QuerySearchProductsArgs

export function useSearchProducts(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useDebouncedQuery<TData, TVariables>(
    SEARCH_PRODUCTS,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
    200,
  )

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
