import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import AmountInput from '@/components/AmountInput'
import TextInput from '@/components/TextInput'
import { ItemQuestionRangeData } from '@/schemaTypes'

export interface RangeDataProps {
  rangeData: ItemQuestionRangeData
  onChangeRangeData: (rangeData: ItemQuestionRangeData) => void
}

const RangeData = (props: RangeDataProps) => {
  const { rangeData, onChangeRangeData } = props
  const { t } = useTranslation()

  return (
    <Fragment>
      <AmountInput
        value={rangeData.minValue}
        onChange={(value) =>
          onChangeRangeData({ ...rangeData, minValue: value })
        }
        label={t('min_value')}
      />
      <AmountInput
        value={rangeData.maxValue}
        onChange={(value) =>
          onChangeRangeData({ ...rangeData, maxValue: value })
        }
        label={t('max_value')}
      />
      <AmountInput
        value={rangeData.defaultValue}
        onChange={(value) =>
          onChangeRangeData({ ...rangeData, defaultValue: value })
        }
        label={t('default_value')}
      />
      <TextInput
        value={rangeData.unitKey}
        onChange={(value) =>
          onChangeRangeData({ ...rangeData, unitKey: value })
        }
        label={t('unit_key')}
      />
      <AmountInput
        value={rangeData.unitPrice}
        onChange={(value) =>
          onChangeRangeData({ ...rangeData, unitPrice: value })
        }
        label={t('unit_price')}
      />
    </Fragment>
  )
}

export default RangeData
