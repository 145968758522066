import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import StorageUnitsSelectInput from '@/components/StorageUnitsSelectInput'
import { ItemStorageHistoryEntry } from '@/schemaTypes'

interface StorageUnitMenuItem {
  label: string
  value: string
}

export interface StorageUnitsConfigurationOnItemStatusProps {
  firstLevelUnit: {
    _id: string
    onChange: (_id: string) => void
    menuItems: StorageUnitMenuItem[]
  }
  secondLevelUnit: {
    _id: string
    onChange: (_id: string) => void
    menuItems: StorageUnitMenuItem[]
  }
  thirdLevelUnit: {
    _id: string
    onChange: (_id: string) => void
    menuItems: StorageUnitMenuItem[]
  }
  lastLevelUnit: {
    _id: string
    onChange: (_id: string) => void
    menuItems: StorageUnitMenuItem[]
  }
  _handleResetForSelectors: (selector?: string | undefined) => Promise<void>
  latestStorageHistoryEntry?: ItemStorageHistoryEntry | null
}

function StorageUnitsConfigurationOnItemStatus(
  props: StorageUnitsConfigurationOnItemStatusProps,
) {
  const {
    firstLevelUnit,
    secondLevelUnit,
    thirdLevelUnit,
    lastLevelUnit,
    _handleResetForSelectors,
    latestStorageHistoryEntry,
  } = props
  const { t } = useTranslation()

  return (
    <>
      <Box>
        {t('level') + ' 1'}&nbsp;&nbsp;
        <StorageUnitsSelectInput
          value={firstLevelUnit._id}
          onChange={(value) => firstLevelUnit.onChange(value)}
          menuItems={firstLevelUnit.menuItems}
          iconOnClick={() => _handleResetForSelectors('first')}
          latestStorageHistoryEntry={latestStorageHistoryEntry}
        />
      </Box>

      <Box>
        {t('level') + ' 2'}&nbsp;&nbsp;
        <StorageUnitsSelectInput
          value={secondLevelUnit._id}
          onChange={(value) => secondLevelUnit.onChange(value)}
          menuItems={secondLevelUnit.menuItems}
          iconOnClick={() => _handleResetForSelectors('second')}
          latestStorageHistoryEntry={latestStorageHistoryEntry}
        />
      </Box>
      <Box>
        {t('level') + ' 3'}&nbsp;&nbsp;
        <StorageUnitsSelectInput
          value={thirdLevelUnit._id}
          onChange={(value) => thirdLevelUnit.onChange(value)}
          menuItems={thirdLevelUnit.menuItems}
          iconOnClick={() => _handleResetForSelectors('third')}
          latestStorageHistoryEntry={latestStorageHistoryEntry}
        />
      </Box>
      <Box>
        {t('level') + ' 4'}&nbsp;&nbsp;
        <StorageUnitsSelectInput
          value={lastLevelUnit._id}
          onChange={(value) => lastLevelUnit.onChange(value)}
          menuItems={lastLevelUnit.menuItems}
          iconOnClick={() => _handleResetForSelectors('last')}
          latestStorageHistoryEntry={latestStorageHistoryEntry}
        />
      </Box>
    </>
  )
}

export default StorageUnitsConfigurationOnItemStatus
