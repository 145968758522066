import { companyFragments } from '../../companies/hooks/companyFragments'
import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { Query, QueryGetDealByIdArgs } from '@/schemaTypes'
import { dealCalculationFragments, dealFragments } from './dealFragments'
import { noteFragments } from './noteFragments'

const GET_DEAL_BY_ID = gql`
  query getDealById($dealId: ObjectId!) {
    getDealById(dealId: $dealId) {
      _id
      dealStartDate
      createdAt
      updatedAt
      dealType
      businessUnit
      bookingNumber
      itemsIds
      companyId
      isInOpenAuction
      acceptingWithdrawalFeeTermsRequired
      dealFinalValues {
        ...DealFinalValuesFragment
      }
      customer {
        ...GuestCustomerDataFragment

        ... on Customer {
          _id
          firstname
          lastname
          customerNumber
          dateOfBirth
          email
          phone
          note
          isNotePopup
          sex
          acquiredBy
          identityType
          identityNumber
          isTestUser
          iban
          paypalAddress
          identityVerifiedAt
          identityVerifiedBy
          identityUploadLinks {
            url
            contentType
          }
          registrationCertificate {
            url
            contentType
          }
          addresses {
            ...CustomerAddressFragment
          }
          blacklistInformation {
            isAddedToBlacklist
            hasCashyAccount
          }
          additionalInformationForCarPawn {
            ...AdditionalInformationForCarPawnFragment
          }
        }
      }
      secondaryCustomers {
        _id
        firstname
        lastname
        customerNumber
        dateOfBirth
        email
        phone
        note
        isNotePopup
        sex
        acquiredBy
        identityUploadLinks {
          url
          contentType
        }
        addresses {
          ...CustomerAddressFragment
        }
      }
      company {
        _id
        name
        iban
        paypalEmail
        configuration {
          minimumPawnDuration
          gracePeriod
          insuranceFactor
        }
        salesConfiguration {
          ...CompanySalesConfigurationFragment
        }
        carPawnSettings {
          includeLicensePlate
          includeSpareTireCount
        }
        successionCompany {
          _id
          name
        }
        headquarter {
          countryCode
        }
      }
      pawnData {
        ...PawnDataFragment
      }
      purchaseData {
        ...PurchaseDataFragment
      }
      events {
        ...DealEventFragment
      }
      payoutData {
        ...PaymentDataFragment
      }
      secondaryPayoutData {
        ...SecondaryPaymentDataFragment
      }
      initialShopId
      pickupData {
        ...TransportDataFragment
      }
      dropoffData {
        ...TransportDataFragment
      }
      items {
        ...DealItemFragment
        formAnswers {
          ...DealItemFormAnswerFragment
        }
      }
      requestedItems {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
      shop {
        _id
        name
        disabled
        successionShop {
          name
        }
      }
      verifiedBy
      extendedBy
      closedBy
      dealPartnerData {
        type
        knp {
          fileReferenceNumber
          debtAmount
        }
      }
      uploadedFiles {
        ...FileMetadataFragment
      }
      extensionChain {
        extensionBookingNumbers
        extensionDealIds
      }
      dealChain {
        bookingNumber
        dealId
        chainType
      }
      insuredAmount
      customDealId
      chainNotes {
        ...NoteFragment
        bookingNumber
        dealId
      }
      notes {
        ...NoteFragment
      }
      surplusPaymentsData {
        ...surplusPaymentDataFragment
      }
      customerCategory
    }
  }
  ${dealFragments.dealItem}
  ${dealFragments.event}
  ${dealFragments.purchaseData}
  ${dealFragments.pawnData}
  ${dealFragments.transportData}
  ${dealCalculationFragments.dealValorizationCalculation}
  ${fragments.paymentData}
  ${fragments.secondaryPaymentData}
  ${fragments.fileMetadata}
  ${fragments.customerAddress}
  ${dealFragments.dealFinalValues}
  ${fragments.additionalInformationForCarPawn}
  ${noteFragments.notes}
  ${companyFragments.salesConfiguration}
  ${fragments.dealItemCustomDealFormQuestion}
  ${fragments.dealItemFormAnswers}
  ${fragments.surplusPaymentData}
  ${fragments.guestCustomerData}
`

type TData = { getDealById: Query['getDealById'] }
type TVariables = QueryGetDealByIdArgs

export function useGetDealById(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useQuery<TData, TVariables>(GET_DEAL_BY_ID, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, deal: queryResult.data?.getDealById }
}
