import styled from '@emotion/styled'
import { Dropdown, DropdownProps } from 'primereact/dropdown'

interface FilterDropdownProps extends DropdownProps {
  className: string
}

const FilterDropdown = ({ className, ...props }: FilterDropdownProps) => {
  return <StyledDropDown className={className} {...props} />
}

const StyledDropDown = styled(Dropdown)`
  &&& {
    border-width: 0;

    .p-dropdown-trigger {
      width: 1.6rem;
    }

    .p-dropdown-clear-icon {
      right: 1.5rem;
      cursor: pointer;
    }

    .p-dropdown-label {
      padding: 0.65rem 0.5rem;
    }

    &.selected {
      border-width: 1px;
      border-color: #6366f1;
      .p-dropdown-label {
        padding: 0.65rem 1.4rem 0.65rem 0.5rem;
      }
    }

    &:hover {
      background: #e9ecef;
      color: #495057;
    }
  }
`

export default FilterDropdown
