import { useGetFees } from '../../fees/hooks'
import {
  includeHidedCategories,
  useGetCategoryPages,
  useGetItemCategories,
} from '../hooks'
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ChipView from '@/components/ChipView'
import TextInput from '@/components/TextInput'
import {
  CategoryPage,
  EMaximumPawnDuration,
  EVisibleOnDomain,
  FeeDefinition,
  ItemCategory,
} from '@/schemaTypes'
import ItemCategoriesVisibleOnDomain from './ItemCategoriesVisibleOnDomain'

export interface ItemCategoriesDetailsProps {
  itemCategory: ItemCategory
  onSave: (itemCategory: ItemCategory, storageFeeIds: string[]) => void
}

const getCategoryPageFullPath = (categoryPage: CategoryPage) => {
  const lvlContent = [
    'lvl0ContentCategory',
    'lvl1ContentCategory',
    'lvl2ContentCategory',
    'lvl3ContentCategory',
  ]

  return lvlContent
    .map((lvl) => categoryPage[lvl])
    .filter(Boolean)
    .join(' > ')
}

export function ItemCategoriesDetails(props: ItemCategoriesDetailsProps) {
  const { itemCategory: persistedItemCategory } = props
  const { t } = useTranslation()
  const history = useHistory()

  const { itemCategories } = useGetItemCategories(includeHidedCategories)
  const { categoryPages } = useGetCategoryPages()
  const [itemCategory, setItemCategory] = useState<
    Omit<ItemCategory, 'visibleOnDomain'> & {
      visibleOnDomain?: EVisibleOnDomain | null
    }
  >(persistedItemCategory)
  const { queryResult: storageFeesQueryResult } = useGetFees()

  const storageFees: FeeDefinition[] = []

  storageFeesQueryResult.data?.getFees.map((fee, idx) => {
    if (fee.restrictToCategories && fee.restrictToCategories.length > 0) {
      const found = fee.restrictToCategories.includes(persistedItemCategory._id)
      found &&
        storageFees.push(
          storageFeesQueryResult.data?.getFees[idx] as FeeDefinition,
        )
    }
  })

  const onReset = () => {
    setItemCategory(persistedItemCategory)
  }

  const onSave = () => {
    /* Validate fields before submitting */
    if (!itemCategory.visibleOnDomain) {
      return
    }

    props.onSave(
      itemCategory as ItemCategory,
      storageFees?.map((fee) => fee._id),
    )
  }

  const onClickStorageFee = (feeId: string) => {
    history.push(`/inApp/configurations/fees/edit/${feeId}`)
  }

  const defaultVisibleOnDomainOptions = useMemo(
    () => ({
      showOnAllDomains: itemCategory.visibleOnDomain === EVisibleOnDomain.All,
      de: itemCategory.visibleOnDomain === EVisibleOnDomain.De,
      at: itemCategory.visibleOnDomain === EVisibleOnDomain.At,
      hideOnAllDomains: itemCategory.visibleOnDomain === EVisibleOnDomain.None,
    }),
    [itemCategory.visibleOnDomain],
  )

  const parentCategory = itemCategory.parentId
    ? itemCategories.filter(
        (category) => category._id === itemCategory.parentId,
      )[0]
    : undefined

  const currentCategoryPages = itemCategory.categoryPageIds
    ? categoryPages.filter((page) =>
        itemCategory.categoryPageIds?.includes(page._id),
      )
    : []

  return (
    <Box pb="32px">
      <TextInput
        value={itemCategory.name}
        onChange={(value) => setItemCategory({ ...itemCategory, name: value })}
        label={t('name')}
        inputWidth={'600px'}
      />

      <TextInput
        value={itemCategory.slug}
        disabled={true}
        label={t('slug')}
        style={{ width: 600 }}
      />

      <TextInput
        value={itemCategory.algoliaCategorySlug ?? ''}
        onChange={(value) =>
          setItemCategory({ ...itemCategory, algoliaCategorySlug: value })
        }
        label={t('algolia_category_slug')}
        inputWidth={'600px'}
      />

      <TextInput
        type="number"
        value={itemCategory.expectedTurnOverTime?.toString()}
        onChange={(value) =>
          setItemCategory({
            ...itemCategory,
            expectedTurnOverTime: value ? parseInt(value) : null,
          })
        }
        label={t('expected_turnover_time')}
        inputWidth={'600px'}
      />

      <TextInput
        value={itemCategory.helpLink ?? ''}
        onChange={(value) =>
          setItemCategory({ ...itemCategory, helpLink: value })
        }
        label={t('help_link')}
        inputWidth={'600px'}
      />

      <Typography variant="h5" gutterBottom>
        {t('category_pages')}
        <Autocomplete
          options={categoryPages.map((page) => ({
            id: page._id,
            label: getCategoryPageFullPath(page),
          }))}
          getOptionLabel={(option) => option.label}
          style={{ width: 600 }}
          multiple={true}
          onChange={(event, value) =>
            setItemCategory({
              ...itemCategory,
              categoryPageIds: value ? value.map((x) => (x as any).id) : [],
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('select_category_pages')}
              variant="outlined"
            />
          )}
          value={currentCategoryPages.map((page) => ({
            id: page._id,
            label: getCategoryPageFullPath(page),
          }))}
        />
      </Typography>

      <Typography variant="h5" gutterBottom>
        {t('parent_category')}
        <Autocomplete
          options={itemCategories
            .filter((category) => category._id !== itemCategory._id)
            .map((category) => ({ id: category._id, label: category.name }))}
          getOptionLabel={(option) => option.label}
          style={{ width: 600 }}
          onChange={(event, value) =>
            setItemCategory({
              ...itemCategory,
              parentId: value ? (value as any).id : null,
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('select_parent_category')}
              variant="outlined"
            />
          )}
          value={
            parentCategory
              ? { id: parentCategory._id, label: parentCategory.name }
              : { id: '', label: '' }
          }
        />
      </Typography>

      {storageFees.length > 0 && (
        <ChipView
          label={t('fees.label')}
          values={storageFees.map((fee) => ({
            id: fee._id,
            label: `${fee.company?.name}: ${fee.feeType} ${t('fees.fees_amount')}: ${fee.amount}`,
          }))}
          onClick={(id) => onClickStorageFee(id)}
        />
      )}

      <Box mt="8px">
        <Typography variant="h5" gutterBottom>
          {t('maximum_pawn_duration')}

          <RadioGroup
            value={itemCategory.maximumPawnDuration}
            onChange={(e) =>
              setItemCategory({
                ...itemCategory,
                maximumPawnDuration: e.target.value as EMaximumPawnDuration,
              })
            }
          >
            {Object.values(EMaximumPawnDuration).map((duration) => (
              <FormControlLabel
                key={duration}
                value={duration}
                control={<Radio />}
                label={t(duration.toLowerCase())}
              />
            ))}
          </RadioGroup>
        </Typography>
      </Box>

      <Box mt="8px">
        <Typography variant="h5" gutterBottom>
          {t('minimum_item_value')}
          <TextInput
            type="number"
            value={itemCategory.minimumItemValue?.toString() ?? ''}
            onChange={(value) =>
              setItemCategory({
                ...itemCategory,
                minimumItemValue: value ? parseInt(value) : null,
              })
            }
            inputWidth={'100px'}
            icon={<EuroSymbolIcon />}
          />
        </Typography>
      </Box>

      <ItemCategoriesVisibleOnDomain
        defaultVisibleOnDomainOptions={defaultVisibleOnDomainOptions}
        onChangeVisibleOnDomain={(visibleOnDomain) =>
          setItemCategory({
            ...itemCategory,
            visibleOnDomain,
          })
        }
      />
      {!itemCategory.visibleOnDomain && (
        <Typography style={{ marginLeft: 30, color: 'red' }}>
          {t('error.error_validation_is_required')}
        </Typography>
      )}
      <Box mt="20px">
        <Box mr="1rem" display="inline-block">
          <Button onClick={onSave} variant="contained">
            {t('save')}
          </Button>
        </Box>

        <Box display="inline-block">
          <Button onClick={onReset} variant="contained">
            {t('reset')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ItemCategoriesDetails
