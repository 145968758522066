import TableValue from '../TableValue'
import { TableCell, TableRow } from '@material-ui/core'
import AttachmentIcon from '@material-ui/icons/Attachment'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import LinkIcon from '@material-ui/icons/Link'
import dayjs from 'dayjs'
import * as React from 'react'
import { Fragment, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EItemReturnType,
  EItemStatusType,
  ESalesPartnerType,
  ItemEvent,
} from '@/schemaTypes'
import { printLocalAmount } from '@/utils/misc'
import styles from './ItemStatus.module.scss'
import RefurbedOfferStatusRow from './RefurbedOfferStatusRow'

interface ItemStatusRowProps {
  event: ItemEvent
  itemStatusIndex: number
  advertiserIndex: number
  canRemoveLastEvent
  onRemoveLatestEvent
}

const ItemStatusRow: FunctionComponent<ItemStatusRowProps> = (props) => {
  const { event, itemStatusIndex, canRemoveLastEvent, onRemoveLatestEvent } =
    props
  let { advertiserIndex } = props
  const { t } = useTranslation()
  const rowColor =
    event.meta && event.meta.isPrerequisites ? '#e6dcf3' : '#FFFFFF'

  return (
    <TableRow key={event.event} style={{ backgroundColor: `${rowColor}` }}>
      <TableCell component="th" scope="row">
        {canRemoveLastEvent(itemStatusIndex) ? (
          <div
            onClick={onRemoveLatestEvent}
            className={`o-media__fixed u-inline-block ${styles.removeStatusIcon}`}
          >
            <DeleteForeverIcon />
          </div>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell component="th" scope="row">
        <TableValue value={event.event} />
      </TableCell>
      <TableCell align="left">
        <TableValue value={dayjs(event.timestamp).format('DD.MM.YYYY')} />
      </TableCell>
      <TableCell align="left" style={{ width: '12rem' }}>
        {event &&
        event.meta &&
        event.meta.returnType === EItemReturnType.ReturnedInShop ? (
          <p style={{ fontWeight: 500 }}>{`${t('item_returned_in_store')}`}</p>
        ) : null}

        {event && event.meta && event.meta.notSellableType ? (
          <p>{`${t('type')}: ${event.meta.notSellableType}`}</p>
        ) : null}

        {event && event.meta && event.meta.temporaryNotSellableReason ? (
          <p>{`${t('not_sellable_reason')}: ${event.meta.temporaryNotSellableReason}`}</p>
        ) : null}

        {event && event.meta && event.meta.validUntil ? (
          <p>{`${t('to')}: ${dayjs(event.meta.validUntil).format('DD.MM.YYYY')}`}</p>
        ) : null}

        {event && event.meta && event.meta.validFrom ? (
          <p>{`${t('from')}: ${dayjs(event.meta.validFrom).format('DD.MM.YYYY')}`}</p>
        ) : null}

        {event && event.meta && event.meta.permanentlyNotSellableReason ? (
          <p>{`${t('not_sellable_reason')}: ${event.meta.permanentlyNotSellableReason}`}</p>
        ) : null}

        {event && event.meta && event.meta.salesPartnerName ? (
          <p>{`${t('sales_partner')}: ${event.meta.salesPartnerName}`}</p>
        ) : null}

        {event && event.meta && event.meta.salesPartnerId ? (
          <p>{`${t('sales_partner_number_or_id')}: ${event.meta.salesPartnerId}`}</p>
        ) : null}

        {event && event.meta && event.meta.salesPartnerTrackingLink ? (
          <p>
            {`${t('tracking_link')}: `}
            <a
              target="_blank"
              style={{ marginLeft: '0.5rem', fontSize: '0.8rem' }}
              href={`${event.meta.salesPartnerTrackingLink}`}
              rel="noreferrer"
            >
              <LinkIcon style={{ marginBottom: '-0.5rem' }} />
            </a>
          </p>
        ) : null}

        {event && event.meta && event.meta.logisticPartnerName ? (
          <p>{`${t('logistics_partner')}: ${event.meta.logisticPartnerName}`}</p>
        ) : null}

        {event?.event === EItemStatusType.ShipBetweenShops &&
        event.meta &&
        event.meta.cashyEmployeeId ? (
          <p>{`${t('cashy_employee')}: ${event.meta.cashyEmployee?.firstname} ${event.meta.cashyEmployee?.lastname}`}</p>
        ) : null}

        {event && event.meta && event.meta.logisticPartnerTrackingCode ? (
          <p>{`${t('tracking_code')}: ${event.meta.logisticPartnerTrackingCode}`}</p>
        ) : null}

        {event && event.meta && event.meta.logisticPartnerTrackingLink ? (
          <p>
            {`${t('tracking_link')}: `}
            <a
              target="_blank"
              style={{ marginLeft: '0.5rem', fontSize: '0.8rem' }}
              href={`${event.meta.logisticPartnerTrackingLink}`}
              rel="noreferrer"
            >
              <LinkIcon style={{ marginBottom: '-0.5rem' }} />
            </a>
          </p>
        ) : null}

        {event && event.meta && event.meta.advertiserPlatformName
          ? event.meta.advertiserPlatformName.map((name) => {
              advertiserIndex++
              const isRefurbed = name === ESalesPartnerType.Refurbed
              return (
                <Fragment>
                  <p>{`${t('advertisement_partner')}: ${name} ${
                    event.meta?.offeredPrice
                      ? `- ${printLocalAmount(event.meta.offeredPrice)}`
                      : ''
                  }`}</p>
                  {isRefurbed && event.meta?.refurbedOffer && (
                    <RefurbedOfferStatusRow
                      refurbedOffer={event.meta.refurbedOffer}
                    />
                  )}
                  {event.meta &&
                  event.meta.advertisementLink &&
                  event.meta.advertisementLink[advertiserIndex - 1] !== '' ? (
                    <p>
                      {`${t('advertisement_link')}: `}
                      <a
                        target="_blank"
                        style={{ marginLeft: '0.5rem', fontSize: '0.8rem' }}
                        href={`${event.meta && event.meta.advertisementLink ? event.meta.advertisementLink[advertiserIndex - 1] : ''}`}
                        rel="noreferrer"
                      >
                        <LinkIcon style={{ marginBottom: '-0.5rem' }} />
                      </a>
                    </p>
                  ) : (
                    ''
                  )}
                  {/* Temporary Commented */}
                  {/* <p style={{ marginTop: '-0.5rem' }}>...............</p> */}
                </Fragment>
              )
            })
          : ''}

        {event && event.meta && event.meta.statusReason ? (
          <p>{`${t('status_reason')}: ${t(event.meta.statusReason)}`}</p>
        ) : null}

        {event && event.meta && event.meta.logisticPartnerLabelFileLink ? (
          <p>
            {t('description')}:
            <a
              target="_blank"
              style={{ marginLeft: '0.5rem', fontSize: '0.8rem' }}
              href={event.meta.logisticPartnerLabelFileLink.url}
              rel="noreferrer"
            >
              <AttachmentIcon style={{ marginBottom: '-0.5rem' }} />
            </a>
          </p>
        ) : null}

        {event &&
          [
            EItemStatusType.PaidAndShipped,
            EItemStatusType.ReturnRequested,
            EItemStatusType.ReturnArrived,
          ].includes(event.event) &&
          (event.meta && event.meta.sendNotification ? (
            <p>
              {t('valorization_entry.customer_is_notified')}:{'  '}
              <span style={{ color: '#14C112' }}>{t('yes')}</span>
            </p>
          ) : (
            <p>
              {t('valorization_entry.customer_is_notified')}:{'  '}
              <span style={{ color: 'red' }}>{t('no')}</span>
            </p>
          ))}

        {event &&
          [EItemStatusType.Sold, EItemStatusType.Returned].includes(
            event.event,
          ) &&
          event.meta &&
          (event.meta.sendNotification ? (
            <p>
              {t('valorization_entry.buyer_is_notified')}:{'  '}
              <span style={{ color: '#14C112' }}>{t('yes')}</span>
            </p>
          ) : (
            <p>
              {t('valorization_entry.buyer_is_notified')}:{'  '}
              <span style={{ color: 'red' }}>{t('no')}</span>
            </p>
          ))}

        {event &&
        event.meta &&
        !event.meta.isPrerequisites &&
        event.meta.emailAddress ? (
          <p>{`${t('customer.email')}: ${event.meta.emailAddress}`}</p>
        ) : null}

        {event && event.meta && event.meta.description ? (
          <p>{`${t('description')}: ${event.meta.description}`}</p>
        ) : null}
        {event && event.meta && event.meta.opticalCondition ? (
          <p>{`${t('optical_condition')}: ${t(event.meta.opticalCondition)}`}</p>
        ) : null}
        {event?.meta?.voluntarySelfDelivery?.appointment && (
          <p>{`${t('appointment')}: 
          ${dayjs(event.meta.voluntarySelfDelivery.appointment).format('DD.MM.YYYY HH:mm')}`}</p>
        )}
        {event?.meta?.derecognizedReason && (
          <p>
            {t('not_sellable_reason')}: {event.meta.derecognizedReason}
          </p>
        )}
      </TableCell>
    </TableRow>
  )
}

export default ItemStatusRow
