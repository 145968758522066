import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { Query, QueryGetCompanyFeesEvaluationArgs } from '@/schemaTypes'

const GET_COMPANY_FEE_EVALUATION = gql`
  query getCompanyFeesEvaluation(
    $companyId: ObjectId!
    $businessUnit: EBusinessUnit!
  ) {
    getCompanyFeesEvaluation(
      companyId: $companyId
      businessUnit: $businessUnit
    ) {
      feeEvaluationItemCategories {
        itemCategoryId
        feeDefinitionEvaluations {
          ...FeeDefinitionEvaluationFragment
        }
      }
      generalFeesEvaluation {
        ...FeeDefinitionEvaluationFragment
      }
    }
  }
  ${fragments.feeDefinition}
  ${fragments.feeDefinitionEvaluation}
`

type TData = { getCompanyFeesEvaluation: Query['getCompanyFeesEvaluation'] }
type TVariables = QueryGetCompanyFeesEvaluationArgs

export function useGetCompanyFeesEvaluation(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_COMPANY_FEE_EVALUATION, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    companyFeesEvaluation: queryResult.data?.getCompanyFeesEvaluation,
  }
}
