import styles from '../styles/CompaniesConfiguration.module.scss'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { CompanyCounter } from '@/schemaTypes'

export interface CompanyCounterDetailsProps {
  companyCounter: CompanyCounter
}

function sortByYearDescending(a: { year: number }, b: { year: number }) {
  return b.year - a.year
}

export function CompanyCounterDetails(props: CompanyCounterDetailsProps) {
  const { companyCounter } = props
  const { t } = useTranslation()
  const defaultYear = dayjs().get('year')
  const defaultCounter = { year: defaultYear, counter: 0 }

  const sortedBillNumbers = [...companyCounter.billNumbers].sort(
    sortByYearDescending,
  )
  const sortedReceiptNumbers = [...companyCounter.receiptNumbers].sort(
    sortByYearDescending,
  )
  const sortedValorizationBillNumbers = [
    ...companyCounter.valorizationBillNumbers,
  ].sort(sortByYearDescending)
  const sortedValorizationCreditNoteNumbers = [
    ...companyCounter.valorizationCreditNoteNumbers,
  ].sort(sortByYearDescending)

  const latestBillNumber =
    sortedBillNumbers.length > 0 ? sortedBillNumbers[0] : defaultCounter
  const latestReceiptNumber =
    sortedReceiptNumbers.length > 0 ? sortedReceiptNumbers[0] : defaultCounter
  const latestValorizationBillNumber =
    sortedValorizationBillNumbers.length > 0
      ? sortedValorizationBillNumbers[0]
      : defaultCounter
  const latestValorizationCreditNoteNumber =
    sortedValorizationCreditNoteNumbers.length > 0
      ? sortedValorizationCreditNoteNumbers[0]
      : defaultCounter

  const rows: { name: string; value: number; year: string }[] = [
    {
      name: t('deal.booking_number'),
      value: companyCounter.bookingNumber ?? 0,
      year: '',
    },
    {
      name: t('deal.pawn.number'),
      value: companyCounter.pawnNumber ?? 0,
      year: '',
    },
    {
      name: t('deal.pawn.bill_number'),
      value: latestBillNumber.counter,
      year: latestBillNumber.year.toString(),
    },
    {
      name: t('receipt_number'),
      value: latestReceiptNumber.counter,
      year: latestReceiptNumber.year.toString(),
    },
    {
      name: t('valorization_bill_number'),
      value: latestValorizationBillNumber.counter,
      year: latestValorizationBillNumber.year.toString(),
    },
    {
      name: t('credit_note_number'),
      value: latestValorizationCreditNoteNumber.counter,
      year: latestValorizationCreditNoteNumber.year.toString(),
    },
  ]

  return (
    <div className={styles.counterTable}>
      <Table className="u-mb-sm" size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('counter_name')}</TableCell>
            <TableCell align="right">{t('counter_value')}</TableCell>
            <TableCell align="right">{t('counter_year')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow>
              <TableCell>{row.name}</TableCell>
              <TableCell align="right">{row.value}</TableCell>
              <TableCell align="right">{row.year}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default CompanyCounterDetails
