import { Box, TextareaAutosize } from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import { DateTimePicker } from '@material-ui/pickers'
import React, { Fragment, useContext, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { Context } from '@/context'
import SaveIcon from '@/images/save.svg'
import { EItemStatusType, ItemEvent } from '@/schemaTypes'
import { defaultTimezone } from '@/utils/time'
import { EHintType } from './ItemStatus'
import styles from './ItemStatus.module.scss'

export interface Props {
  onChangeItemStatus: (newEvent: ItemEvent, hint: EHintType) => void
}

const ItemVoluntarySelfDeliveryStatus: FunctionComponent<Props> = (props) => {
  const { onChangeItemStatus } = props
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const [description, setDescription] = useState('')
  const [appointmentDate, setAppointmentDate] = useState<Date | null>(null)

  const saveNewStatus = () => {
    if (!appointmentDate) return showInfo(t('appointment_is_not_set'))

    const now = new Date()

    onChangeItemStatus(
      {
        createdAt: now,
        timestamp: now,
        event: EItemStatusType.VoluntarySelfDelivery,
        meta: {
          description,
          voluntarySelfDelivery: {
            appointment: appointmentDate,
          },
        },
      },
      EHintType.NONE,
    )
  }

  return (
    <Fragment>
      <div
        onClick={saveNewStatus}
        className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
      >
        <ReactSVG className={`${styles.saveIcon} u-pl-15`} src={SaveIcon} />
      </div>
      <table className="u-mt-15">
        <tbody>
          <tr>
            <td>
              <Box display="flex" alignItems="center">
                <span className="u-mr-15">{t('appointment')}:</span>
                <div className={styles.datetimepicker}>
                  <DateTimePicker
                    ampm={false}
                    format="L LT"
                    disablePast
                    value={appointmentDate}
                    onChange={(date) =>
                      setAppointmentDate(
                        date ? date.tz(defaultTimezone).toDate() : null,
                      )
                    }
                  />
                  <EventIcon className={styles.icon} />
                </div>
              </Box>
            </td>
          </tr>
          <tr>
            <td>
              <div className="u-mt-15">{t('description')}:</div>
              <TextareaAutosize
                className="u-mt-10"
                rowsMin={4}
                placeholder={t('add_description')}
                style={{ width: '100%' }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

export default ItemVoluntarySelfDeliveryStatus
