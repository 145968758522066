import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import * as React from 'react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Item } from '@/schemaTypes'
import styles from './ItemDealChain.module.scss'

export interface ItemDealChainProps {
  item: Item
}

const ItemDealChain: React.FunctionComponent<ItemDealChainProps> = (props) => {
  const { t } = useTranslation()

  const { item } = props
  let ctr = 1
  const getDealId = (bookingNumber) => {
    const index = [...item.bookingNumbers].reverse().indexOf(bookingNumber)
    return item.dealsHistory[index].dealId
  }

  return (
    <Fragment>
      {`${t('deal.booking_number')}: `}
      {item.bookingNumbers.map((bookingNumber) => {
        ctr++
        return (
          <Fragment key={bookingNumber}>
            <Link
              className={`${styles.pathTreeLink}`}
              target={'_blank'}
              to={`/inApp/deals/${getDealId(bookingNumber)}`}
            >{`${bookingNumber}`}</Link>
            {ctr <= item.bookingNumbers.length ? (
              <ArrowRightAltIcon className={`${styles.arrowBackIcon}`} />
            ) : null}
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default ItemDealChain
