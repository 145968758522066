import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationUpdateShopArgs } from '@/schemaTypes'

const UPDATE_SHOP = gql`
  mutation updateShop(
    $name: String!
    $phone: String!
    $email: String!
    $googleMapsLink: String!
    $address: IAddress!
    $openingHours: String!
    $_id: ObjectId!
    $partnerId: ObjectId
    $hideLocationOnWebsite: Boolean
    $disabled: Boolean
    $successionShopId: ObjectId
    $cashBoxNames: [String!]
    $itemCategoryIds: [ObjectId!]
    $companyId: ObjectId
    $ready2OrderApiKey: String
  ) {
    updateShop(
      name: $name
      phone: $phone
      email: $email
      googleMapsLink: $googleMapsLink
      address: $address
      openingHours: $openingHours
      _id: $_id
      partnerId: $partnerId
      hideLocationOnWebsite: $hideLocationOnWebsite
      disabled: $disabled
      successionShopId: $successionShopId
      cashBoxNames: $cashBoxNames
      itemCategoryIds: $itemCategoryIds
      companyId: $companyId
      ready2OrderApiKey: $ready2OrderApiKey
    ) {
      _id
      name
      phone
      email
      googleMapsLink
      company {
        _id
        name
      }
      address {
        ...AddressFragment
      }
      partnerId
      hideLocationOnWebsite
      disabled
      successionShopId
      cashBoxNames
      itemCategoryIds
      itemCategories {
        _id
        name
        parentId
      }
      openingHours
    }
  }
  ${fragments.address}
`

type TData = { updateShop: Mutation['updateShop'] }
type TVariables = MutationUpdateShopArgs

export function useUpdateShop(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateShop] = useMutation<TData, TVariables>(UPDATE_SHOP, opts)

  return updateShop
}
