import * as _ from 'lodash'
import {
  CompanySalesConfiguration,
  Item,
  RefurbedStaggeredMargin,
} from '@/schemaTypes'

export const enum EMarginType {
  EXPECTED_MARGIN = 'EXPECTED_MARGIN',
  MINIMUM_MARGIN = 'MINIMUM_MARGIN',
}
interface IMemorySizes {
  tb?: number[]
  gb?: number[]
  mb?: number[]
}

export const noSpecialCharsRegex = new RegExp(/[^a-zA-Z0-9.+ ]/gi)

export const noStorageSizeDeviceRegex = new RegExp(
  /(\d+|\d+.\d+) ?(GB|TB|MB)( (SSD|HDD))*/gi,
)

export const storageSizeRegex = new RegExp(/(\w+ )*(\d+|\d+.\d+) ?(GB|TB|MB)/gi)

export const parsedNameToSearchTags = (name: string): string[] =>
  _.compact(
    name
      .toLowerCase()
      .replace(noSpecialCharsRegex, '')
      .replace(noStorageSizeDeviceRegex, '')
      .split(' '),
  )

export const extractStorageSizeFromName = (name: string) => {
  const lowerCaseName = name.toLowerCase()
  const storageSizes: IMemorySizes = {
    tb: [],
    gb: [],
    mb: [],
  }

  let currentMatch: RegExpExecArray | null = null
  do {
    currentMatch = storageSizeRegex.exec(lowerCaseName)
    if (currentMatch) {
      storageSizes[currentMatch[3]].push(parseFloat(currentMatch[2]))
    }
  } while (currentMatch)
  return storageSizes
}

export const calculateByRefurbedFormula = (
  internalValorizationAmount: number,
  margin: number,
  refurbedCommision: number,
) => {
  return (
    (internalValorizationAmount * (1 + margin * 1.2)) /
    (1 - refurbedCommision * 1.2)
  )
}

export const calculateRefurbedPrice = (
  marginType: EMarginType,
  item: Item,
  companySalesConfiguration: CompanySalesConfiguration,
) => {
  if (!item.internalSaleData?.price || !companySalesConfiguration) return 0

  const price = item.internalSaleData.price
  let matchedSaleConfig: RefurbedStaggeredMargin =
    companySalesConfiguration.refurbedStaggeredMargins[0]
  for (const config of companySalesConfiguration.refurbedStaggeredMargins) {
    if (price >= config.from) matchedSaleConfig = config
    else break
  }

  const margin =
    marginType === EMarginType.EXPECTED_MARGIN
      ? matchedSaleConfig.expectedMargin
      : matchedSaleConfig.minimumMargin

  return parseFloat(
    calculateByRefurbedFormula(
      price,
      margin,
      companySalesConfiguration.refurbedCommission,
    ).toFixed(2),
  )
}
