import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationRemoveCustomDealItemArgs } from '@/schemaTypes'

const REMOVE_ITEM = gql`
  mutation RemoveCustomDealItem(
    $customDealRemoveItemArgs: CustomDealRemoveItemArgs!
  ) {
    removeCustomDealItem(customDealRemoveItemArgs: $customDealRemoveItemArgs)
  }
`

type TData = { createCustomDeal: Mutation['removeCustomDealItem'] }
type TVariables = MutationRemoveCustomDealItemArgs

export function useRemoveItem(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [removeItemFromCustomDeal] = useMutation<TData, TVariables>(
    REMOVE_ITEM,
    {
      refetchQueries: ['getCustomDealById'],
      ...opts,
    },
  )
  return removeItemFromCustomDeal
}
