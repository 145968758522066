import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import {
  BooleanCustomDealFormQuestion,
  ELanguageCode,
  FileUploadCustomDealFormQuestion,
  NumberDefaultCustomDealFormQuestion,
  NumberOptionsCustomDealFormQuestion,
  Query,
  TextDefaultCustomDealFormQuestion,
  TextMultilineCustomDealFormQuestion,
  TextOptionsCustomDealFormQuestion,
} from '@/schemaTypes'

const GET_CUSTOM_DEAL_FORM_BY_CATEGORY = gql`
  query getCustomDealFormByCategory(
    $categoryId: ObjectId!
    $languageCode: ELanguageCode!
  ) {
    getCustomDealFormByCategory(categoryId: $categoryId) {
      questions {
        __typename
        ...CustomDealFormQuestionFragment
      }
    }
  }

  ${fragments.customDealFormQuestion}
`

export type TDataCustomDealFormQuestion =
  | TextDefaultCustomDealFormQuestion
  | TextMultilineCustomDealFormQuestion
  | TextOptionsCustomDealFormQuestion
  | NumberDefaultCustomDealFormQuestion
  | NumberOptionsCustomDealFormQuestion
  | BooleanCustomDealFormQuestion
  | FileUploadCustomDealFormQuestion

type TData = {
  getCustomDealFormByCategory: Omit<
    Query['getCustomDealFormByCategory'],
    'questions'
  > & {
    questions: Array<TDataCustomDealFormQuestion>
  }
}
type TVariables = {
  categoryId: string
  languageCode: ELanguageCode
}

export function useGetCustomDealFormByCategory(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_CUSTOM_DEAL_FORM_BY_CATEGORY,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
