import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateCustomDealArgs } from '@/schemaTypes'

const CREATE_CUSTOM_DEAL = gql`
  mutation CreateCustomDeal($customDealCreateArgs: CustomDealCreateArgs!) {
    createCustomDeal(customDealCreateArgs: $customDealCreateArgs) {
      _id
    }
  }
`

type TData = { createCustomDeal: Mutation['createCustomDeal'] }
type TVariables = MutationCreateCustomDealArgs

export function useCreateCustomDeal(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createCustomDeal] = useMutation<TData, TVariables>(
    CREATE_CUSTOM_DEAL,
    opts,
  )
  return createCustomDeal
}
