import { CustomItemDraftFormData } from '../CustomItemDraft/CustomItemDraft'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { FieldArray, FormikErrors } from 'formik'
import { Message } from 'primereact/message'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { onlyOverwriteIsPossible } from '@/domains/customDeals/components/CustomDealDetail/CustomDealItems'
import { getMaxComparableOffersCount } from '@/domains/customDeals/helpers'
import FormikInput from '@/redesign/components/FormikFields/FormikInput/FormikInput'
import FormikInputNum from '@/redesign/components/FormikFields/FormikInputNum/FormikInputNum'
import Input from '@/redesign/components/Input/Input'
import InputDropdown from '@/redesign/components/InputDropdown/InputDropdown'
import InputNum from '@/redesign/components/InputNumber/InputNumber'
import InputSlider from '@/redesign/components/InputSlider/InputSlider'
import Switch from '@/redesign/components/Switch/Switch'
import {
  ECustomItemFoundComparableOffers,
  EDealType,
  ItemValuesEntry,
  PriceFinding,
  PriceFindingArgs,
  Scalars,
} from '@/schemaTypes'

interface PriceDeterminationProps {
  isVehicle: boolean
  itemPawnValuesEntry?: ItemValuesEntry
  itemPurchaseValuesEntry?: ItemValuesEntry
  dealType: EDealType
  foundComparableOffers: ECustomItemFoundComparableOffers | undefined
  values: (PriceFindingArgs | PriceFinding)[]
  readonly?: boolean
  formikErrors?: FormikErrors<CustomItemDraftFormData>
  setFieldValue: (field: string, value: unknown) => void
  calculationRunning: boolean
  overwrittenItemValue?: number
  foundOffersEvaluationFactor?: number
  overwriteItemValue?: boolean
  setOverWriteItemValue?: (value: boolean) => void
  priceFindingLengthError?: string
  overwrittenItemValueError?: string
  counterofferPayoutAmount?: number | null
  variantId?: Scalars['ObjectId']
}

const PriceDetermination = (props: PriceDeterminationProps) => {
  const {
    isVehicle,
    itemPawnValuesEntry,
    itemPurchaseValuesEntry,
    dealType,
    foundComparableOffers,
    values,
    setFieldValue,
    readonly,
    calculationRunning,
    overwrittenItemValue,
    foundOffersEvaluationFactor,
    overwriteItemValue,
    setOverWriteItemValue,
    priceFindingLengthError,
    overwrittenItemValueError,
    counterofferPayoutAmount,
    variantId,
  } = props

  const [overwriteItemDisabled, setOverWriteItemDisabled] = useState<boolean>(
    Boolean(overwrittenItemValue) ||
      foundComparableOffers === ECustomItemFoundComparableOffers.OneOrTwo ||
      foundComparableOffers === ECustomItemFoundComparableOffers.NoOffersFound,
  )
  const errors = props.formikErrors as FormikErrors<CustomItemDraftFormData>
  const priceFindingsErrors =
    errors?.priceFindings as FormikErrors<PriceFindingArgs>
  const { t } = useTranslation()
  const foundComparableOffersOptions = useMemo(
    () =>
      Object.values(ECustomItemFoundComparableOffers).map((data) => ({
        label: t(data.toLowerCase()),
        value: data,
      })),
    [t],
  )

  useEffect(() => {
    if (variantId)
      setFieldValue(
        'payoutAmount',
        dealType === EDealType.Pawn
          ? itemPawnValuesEntry?.payoutAmount
          : itemPurchaseValuesEntry?.payoutAmount,
      )
  }, [
    itemPawnValuesEntry,
    itemPurchaseValuesEntry,
    dealType,
    variantId,
    setFieldValue,
  ])

  const onLeaveField = (i: number) => {
    if (
      values.length < getMaxComparableOffersCount(foundComparableOffers) &&
      !priceFindingsErrors &&
      Boolean(values[i].price) &&
      Boolean(values[i].url)
    ) {
      addPriceFindingRecord()
    }
  }

  const onChangeComparableOffers = (
    comparableOffer: ECustomItemFoundComparableOffers,
  ) => {
    setFieldValue('overwrittenItemValue', undefined)

    const shouldOverwrite =
      comparableOffer === ECustomItemFoundComparableOffers.OneOrTwo ||
      comparableOffer === ECustomItemFoundComparableOffers.NoOffersFound

    setOverWriteItemDisabled(shouldOverwrite)
    setOverWriteItemValue?.(shouldOverwrite)
    if (shouldOverwrite) {
      setFieldValue('foundOffersEvaluationFactor', '0.6')
    }

    setFieldValue(`foundComparableOffers`, comparableOffer)
    const maxoffers = getMaxComparableOffersCount(comparableOffer)

    if (values.length > maxoffers) {
      setFieldValue('priceFindings', values.slice(0, maxoffers))
    } else if (values.length < maxoffers && !values.some((c) => !c.price)) {
      addPriceFindingRecord()
    }
  }

  const addPriceFindingRecord = () => {
    setFieldValue(`priceFindings`, [...values, { url: null, price: null }])
  }

  return (
    <div>
      <TitleStyled>{t('cashy_determination_price')}</TitleStyled>

      {!variantId && (
        <div>
          <FormikInputNum
            name="payoutAmount"
            label={t('customer_requested_price')}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />
        </div>
      )}

      <Message
        className="!mb-2"
        text={t('cashy_determination_price_description')}
      />

      {!counterofferPayoutAmount && (
        <div className="flex mb-2">
          <InputDropdown
            label={t('comparable_offers_found')}
            options={foundComparableOffersOptions}
            className="flex flex-col md:flex-row items-start md:items-center "
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
            value={foundComparableOffers}
            onChange={(e) =>
              onChangeComparableOffers(
                e.target.value as ECustomItemFoundComparableOffers,
              )
            }
          />
          {priceFindingLengthError && (
            <Message
              className="!ml-4"
              severity="warn"
              text={t(priceFindingLengthError)}
            />
          )}
        </div>
      )}

      <FieldArray
        name="priceFindings"
        render={({ form }) => {
          return (
            <>
              {values.map((priceFinding, i) => {
                return (
                  <div key={i} className="flex flex-col md:flex-row ">
                    <FormikInput
                      label={t('url')}
                      name={`priceFindings.[${i}].url`}
                      onBlur={() => onLeaveField(i)}
                      disabled={readonly}
                      className="flex flex-col md:flex-row items-start md:items-center mr-24 w-2/3 mb-2"
                      labelClassName="text-sm font-semibold w-24"
                      wrapperInputContainerClassName="w-full"
                      required
                    />

                    <InputNum
                      label={t('price')}
                      value={priceFinding.price}
                      onBlur={() => onLeaveField(i)}
                      disabled={readonly}
                      className="flex flex-col md:flex-row items-start md:items-center w-1/3 mb-2"
                      labelClassName="text-sm font-semibold w-24"
                      wrapperInputContainerClassName="w-full"
                      tooltipText={getCreatorInfo(priceFinding)}
                      required
                      onValueChange={(e) => {
                        form.setFieldValue(
                          `priceFindings.[${i}].price`,
                          e.value,
                        )
                      }}
                    />
                  </div>
                )
              })}
            </>
          )
        }}
      />

      <div className="flex justify-end">
        <PriceCalcContainer className="w-full lg:w-auto">
          {!counterofferPayoutAmount && (
            <div>
              <div className="flex justify-between items-center mb-3.5">
                <TitleStyled>{t('price_calculation')}</TitleStyled>
                <Switch
                  checked={!!overwriteItemValue}
                  label={t('override_value')}
                  className="flex flex-row items-start md:items-center mb-2"
                  labelClassName="text-sm font-semibold mr-4"
                  onChange={(e) => {
                    setFieldValue(
                      'overwrittenItemValue',
                      itemPawnValuesEntry?.adjustedMarketValue,
                    )
                    setOverWriteItemValue?.(!!e.value)
                  }}
                  disabled={!foundComparableOffers || overwriteItemDisabled}
                />
              </div>

              {!overwriteItemValue && !overwriteItemDisabled ? (
                <div>
                  <InputNum
                    label={t('item_value')}
                    className="flex flex-col md:flex-row items-start md:items-center mb-2"
                    labelClassName="text-sm font-semibold w-12.5"
                    inputContainerClassName="w-52"
                    value={
                      itemPawnValuesEntry &&
                      itemPawnValuesEntry?.adjustedMarketValue
                    }
                    onChange={(e) => {
                      setFieldValue(
                        'overwrittenItemValue',
                        e.value && e.value > 0 ? e.value : undefined,
                      )
                    }}
                    disabled
                    loading={calculationRunning}
                  />
                </div>
              ) : (
                <>
                  <div className="p-input-icon-right mb-2">
                    {!onlyOverwriteIsPossible(foundComparableOffers) && (
                      <i
                        className="pi pi-times !top-5 cursor-pointer"
                        onClick={() => {
                          setFieldValue('overwrittenItemValue', undefined)
                          setOverWriteItemValue?.(false)
                        }}
                      />
                    )}
                    <InputNum
                      label={t('manual_value_estimation')}
                      className="flex flex-col md:flex-row items-start md:items-center"
                      labelClassName="text-sm font-semibold w-12.5"
                      inputContainerClassName="w-52"
                      value={
                        overwriteItemValue || overwriteItemDisabled
                          ? overwrittenItemValue
                          : itemPawnValuesEntry?.adjustedMarketValue
                      }
                      onChange={(e) => {
                        const value = e.value
                        setFieldValue(
                          'overwrittenItemValue',
                          value && value > 0 ? value : undefined,
                        )
                        if (!value) setOverWriteItemValue?.(false)
                      }}
                      errorText={
                        overwrittenItemValueError &&
                        t(overwrittenItemValueError)
                      }
                      isInvalid={!!overwrittenItemValueError}
                      disabled={
                        (!overwriteItemValue && !overwriteItemDisabled) ||
                        readonly
                      }
                    />
                  </div>

                  {onlyOverwriteIsPossible(foundComparableOffers) && (
                    <InputSlider
                      label={t('payout_factor', { dealType: t(dealType) })}
                      className="flex flex-col md:flex-row items-start md:items-center mb-2"
                      labelClassName="text-sm font-semibold w-12.5"
                      inputContainerClassName="w-52"
                      onChange={(e) => {
                        if (onlyOverwriteIsPossible(foundComparableOffers)) {
                          setFieldValue('foundOffersEvaluationFactor', e.value)
                        }
                      }}
                      value={foundOffersEvaluationFactor}
                      onChangeSlider={(value) => {
                        if (onlyOverwriteIsPossible(foundComparableOffers)) {
                          setFieldValue('foundOffersEvaluationFactor', value)
                        }
                      }}
                      sliderMinValue={0.4}
                      sliderMaxValue={1}
                      sliderStep={0.01}
                      disabled={!onlyOverwriteIsPossible(foundComparableOffers)}
                      errorText={
                        errors?.foundOffersEvaluationFactor &&
                        t(errors?.foundOffersEvaluationFactor)
                      }
                      isInvalid={!!errors?.foundOffersEvaluationFactor}
                    />
                  )}
                </>
              )}

              <InputNum
                label={t('payout_pawn')}
                className="flex flex-col md:flex-row items-start md:items-center mb-2"
                labelClassName="text-sm font-semibold w-12.5"
                inputContainerClassName="w-52"
                value={itemPawnValuesEntry && itemPawnValuesEntry?.payoutAmount}
                disabled
                errorText={
                  errors?.counterofferPayoutAmount &&
                  t(errors?.counterofferPayoutAmount)
                }
                isInvalid={!!errors?.counterofferPayoutAmount}
                loading={calculationRunning}
              />

              <InputNum
                label={t('payout_purchase')}
                className="flex flex-col md:flex-row items-start md:items-center mb-2"
                labelClassName="text-sm font-semibold w-12.5"
                inputContainerClassName="w-52"
                value={
                  itemPurchaseValuesEntry && !isVehicle
                    ? itemPurchaseValuesEntry.payoutAmount ?? undefined
                    : undefined
                }
                disabled
                errorText={
                  errors?.counterofferPayoutAmount &&
                  t(errors?.counterofferPayoutAmount)
                }
                isInvalid={!!errors?.counterofferPayoutAmount}
                loading={calculationRunning}
              />
            </div>
          )}

          {counterofferPayoutAmount && counterofferPayoutAmount > 0 && (
            <Input
              label={t('final_offer_price', { dealType: t(dealType) })}
              className="flex flex-col md:flex-row items-start md:items-center mb-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-52"
              disabled
              value={`${counterofferPayoutAmount}`}
            />
          )}
        </PriceCalcContainer>
      </div>
    </div>
  )
}

const getCreatorInfo = (priceFinding: PriceFinding | PriceFindingArgs) => {
  if ('createdAt' in priceFinding && 'createdBy' in priceFinding) {
    return `${priceFinding.createdBy?.firstname} ${priceFinding.createdBy?.lastname} - ${dayjs(priceFinding.createdAt).format('DD.MM.YYYY HH:mm')}`
  }

  return undefined
}

const TitleStyled = styled.h5`
  color: #000;
  font-family: Inter;
  font-size: 1.09375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.09375rem;
  margin-bottom: 0.66rem;
`

const PriceCalcContainer = styled.div`
  padding: 1.3125rem;
  gap: 0.875rem;
  border-radius: 0.3125rem;
  background: var(--surface-50, #fafafa);
`
export default PriceDetermination
