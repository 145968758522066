import { QueryHookOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import useDebouncedQuery from '@/hooks/useDebouncedQuery'
import { Query, QuerySearchCustomersArgs } from '@/schemaTypes'

const SEARCH_CUSTOMERS = gql`
  query searchCustomers($args: CustomersFiltersArgs!) {
    searchCustomers(args: $args) {
      nodes {
        _id
        email
        firstname
        lastname
        dateOfBirth
        phone
        sex
        note
        isNotePopup
        iban
        createdAt
        updatedAt
        customerNumber
        guestEmail
        userPermissionId
        roles
      }

      pageInfo {
        limit
        skip
        hasPrevPage
        hasNextPage
        total
      }
    }
  }
`

type TData = { searchCustomers: Query['searchCustomers'] }
type TVariables = QuerySearchCustomersArgs

export function useSearchCustomers(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useDebouncedQuery<TData, TVariables>(SEARCH_CUSTOMERS, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
