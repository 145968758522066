import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query } from '@/schemaTypes'

const GET_ALL_SHIPPING_PROFILE_LIST = gql`
  query getShippingProfileList {
    getShippingProfileList {
      profileId
      name
      sourceCountryMarketCode
      sourceCountryMarketName
      destinations {
        marketCode
        marketName
        currencyCode
      }
    }
  }
`

type TData = { getShippingProfileList: Query['getShippingProfileList'] }
type TVariables = {}

export function useGetShippingProfileList(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_ALL_SHIPPING_PROFILE_LIST,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    getShippingProfileList: queryResult.data?.getShippingProfileList || [],
  }
}
