import { useMutation } from '@apollo/client'
import { graphql } from '@/gql'

const ChangeMainCustomerOfDealMutation = graphql(`
  mutation changeMainCustomerOfDeal(
    $dealId: ObjectId!
    $selectedCustomerId: ObjectId!
  ) {
    changeMainCustomerOfDeal(
      dealId: $dealId
      selectedCustomerId: $selectedCustomerId
    ) {
      _id
      customer {
        ... on Customer {
          _id
          firstname
          lastname
          email
        }
      }
    }
  }
`)

export const useChangeMainCustomerOfDealMutation = () =>
  useMutation(ChangeMainCustomerOfDealMutation)
