import { Button, DialogProps } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import ReplayIcon from '@material-ui/icons/Replay'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpansionBox from '@/components/ExpansionBox/ExpansionBox'
import Loading from '@/components/Loading'
import LoadingSpinner from '@/components/LoadingSpinner'
import { Context } from '@/context'
import { useGetAuctionDeals } from '@/domains/auctions/hooks/auctionDetails/getAuctionDeals'
import { Auction, AuctionItem, EAuctionStatus } from '@/schemaTypes'
import AddAuctionElement from './AddAuctionElement/AddAuctionElement'
import styles from './Auction.module.scss'
import { AuctionBuyerInformation } from './AuctionBuyerInformation'
import { AuctionCloseTable } from './AuctionCloseTable'
import AuctionElementsViewer from './AuctionElementComponents'
import AuctionGeneralInfo from './AuctionGeneralInfo'
import { AuctionItemsTable } from './AuctionItemsTable'
import AuctionSaveConfirmation from './AuctionSaveCofirmation'
import AuctionStart from './AuctionStart'
import { WithdrawnDeals } from './WithdrawnDeals'

export interface AuctionBlockProps {
  auctionFromDB: Auction
  onUpdateAuction
  onCloseAuctionManually: (auctionId) => void
  onDeleteAuction: (auctionId) => Promise<void>
  closingAuctionInProgress
  addOrUpdateIsInProgress
  generateAuctionAnnouncement
  generatingAuctionAnnouncement: boolean
}

export function AuctionBlock(props: AuctionBlockProps) {
  const {
    auctionFromDB,
    onUpdateAuction,
    onDeleteAuction,
    onCloseAuctionManually,
    closingAuctionInProgress,
    addOrUpdateIsInProgress,
    generateAuctionAnnouncement,
    generatingAuctionAnnouncement,
  } = props

  const { t } = useTranslation()
  const { showInfo } = useContext(Context)
  const [auctionDate, setAuctionDate] = useState(auctionFromDB.auctionDate)
  const [shopId, setShopId] = useState<string | null>(auctionFromDB.shopId)
  const [companyId, setCompanyId] = useState<string | null>(
    auctionFromDB.companyId,
  )
  const [finalElements, setFinalElements] = useState(
    [...auctionFromDB.auctionElements] ?? undefined,
  )
  const [open, setOpen] = React.useState(false)
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper')
  const [isNotifyCustomerChecked, setNotifyCustomer] = useState<boolean>(false)
  const [defaultConfirmationCheckBox, setDefaultConfirmationCheckBox] =
    useState(false)
  const [isSuggestedDealsLoadingFinished, setIsSuggestedDealsLoadingFinished] =
    useState(false)
  const [suggestedDealsCount, setSuggestedDealsCount] = useState(0)
  const [auctionItemListOpen, setAuctionItemListOpen] = useState(true)
  const isEditable = auctionFromDB.status === EAuctionStatus.Open

  const [selectedItemIds, setSelectedItemIds] = useState<Array<string>>([])

  const [auctionPage, setAuctionPage] = useState<
    'AuctionStart' | 'AuctionOverview' | 'AuctionBuyerInformation'
  >('AuctionStart')

  const { deals, queryResult } = useGetAuctionDeals({
    variables: {
      opts: {
        filter: {
          ids: finalElements.map((e) => e.dealId),
        },
      },
      auctionDate,
    },
  })

  const { buybackDeals, buyBackElements } = useMemo(() => {
    const buybackDeals = deals.filter((c) => c.pawnData?.buyBackData)

    const buyBackDealsIds = buybackDeals?.map((c) => c._id) ?? []
    const buyBackElements = finalElements.filter((c) =>
      buyBackDealsIds.includes(c.dealId),
    )
    return {
      buybackDeals,
      buyBackElements,
    }
  }, [deals, finalElements])

  const [auctionItems, setAuctionItems] = useState<Array<AuctionItem>>(
    auctionFromDB.items ?? [],
  )
  useEffect(() => {
    setAuctionItems(auctionFromDB.items ?? [])
  }, [auctionFromDB.items])

  const deleteAuctionElement = (dealId) => {
    const newList = finalElements.filter((c) => c.dealId !== dealId)
    setFinalElements(newList)
    setDefaultConfirmationCheckBox(false)
    setNotifyCustomer(false)
  }

  const HandleClose = (inputFinalElements) => {
    setOpen(false)

    if (inputFinalElements !== null && inputFinalElements !== undefined)
      setFinalElements(inputFinalElements)
    setDefaultConfirmationCheckBox(false)
    setNotifyCustomer(false)
  }

  const addElementHandler = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true)
    setScroll(scrollType)
    setDefaultConfirmationCheckBox(false)
    setNotifyCustomer(false)
  }

  const onReloadElementHandler = () => {
    setFinalElements([...auctionFromDB.auctionElements])
    setDefaultConfirmationCheckBox(false)
    setNotifyCustomer(false)
  }

  const onReloadAuctionHandler = () => {
    setFinalElements([...auctionFromDB.auctionElements])
    setAuctionDate(auctionFromDB.auctionDate)
    setShopId(auctionFromDB.shopId)
    setDefaultConfirmationCheckBox(false)
    setNotifyCustomer(false)
  }

  const onNotifyCustomerChanged = (value) => {
    setNotifyCustomer(value)
  }

  const onDelete = () => {
    onDeleteAuction(auctionFromDB._id).then(
      () => setDefaultConfirmationCheckBox(false),
      () => setNotifyCustomer(false),
    )
  }

  const onCloseAuction = (auctionId) => {
    onCloseAuctionManually(auctionId)
  }

  const prepareAuctionForSaving = async () => {
    if (!auctionDate) {
      return showInfo(t('auction.auction_invalid_date'))
    } else if (!shopId) {
      return showInfo(t('auction.auction_invalid_location'))
    } else if (!finalElements || finalElements.length <= 0) {
      return showInfo(t('auction.auction_invalid_elements'))
    }

    onUpdateAuction(
      {
        _id: auctionFromDB._id,
        auctionDate: auctionDate,
        shopId: shopId,
        createdAt: auctionFromDB.createdAt,
        creationType: auctionFromDB.creationType,
        auctionElements: finalElements,
        status: EAuctionStatus.Open,
        companyId,
      },
      isNotifyCustomerChecked,
    ).then(setDefaultConfirmationCheckBox(false), setNotifyCustomer(false))
  }

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <AuctionGeneralInfo
        isEditable={isEditable}
        auction={auctionFromDB}
        setAuctionDate={setAuctionDate}
        setShopId={setShopId}
        setCompanyId={setCompanyId}
        companyId={companyId}
        auctionDate={auctionDate}
        shopId={shopId}
        onCloseAuction={onCloseAuction}
        closingAuctionInProgress={closingAuctionInProgress}
      />
      {auctionFromDB.status === EAuctionStatus.Started ? (
        <>
          <ExpansionBox
            title={t('item.plural')}
            openAtLoad={auctionItemListOpen ?? false}
            onClickBox={() => setAuctionItemListOpen(!auctionItemListOpen)}
          >
            {renderStartedAuctionItems()}
          </ExpansionBox>

          <WithdrawnDeals withdrawnDeals={auctionFromDB.withdrawnDeals} />
        </>
      ) : auctionFromDB.status === EAuctionStatus.Closed &&
        auctionItems?.length ? (
        <div>
          <ExpansionBox
            title={t('item.plural')}
            openAtLoad={auctionItemListOpen ?? false}
            onClickBox={() => setAuctionItemListOpen(!auctionItemListOpen)}
          >
            <AuctionCloseTable auctionItems={auctionItems} deals={deals} />
          </ExpansionBox>

          <ExpansionBox
            title={t('buyback_deals')}
            openAtLoad={auctionItemListOpen ?? false}
            onClickBox={() => setAuctionItemListOpen(!auctionItemListOpen)}
          >
            <AuctionElementsViewer
              auctionElements={buyBackElements}
              deals={buybackDeals}
              isEditable={false}
            />
          </ExpansionBox>

          <WithdrawnDeals withdrawnDeals={auctionFromDB.withdrawnDeals} />
        </div>
      ) : (
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <div>
            <div className={`${styles.inlineDivs}`}>
              <Typography
                variant="h6"
                gutterBottom
                classes={{ root: styles.title }}
              >
                {t('auction.auction_elements')}
              </Typography>
            </div>
            <div className={`${styles.inlineDivs}`}>
              <Typography
                variant="h6"
                gutterBottom
                classes={{ root: styles.title }}
              >
                {isEditable ? (
                  <div className="u-ml-sm u-inline-block">
                    {isSuggestedDealsLoadingFinished ? (
                      <div
                        className={`${styles.spinner} u-ml-sm u-inline-block`}
                      >
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <Button
                        disabled={suggestedDealsCount === 0}
                        className={`${styles.button} u-ml-sm`}
                        onClick={addElementHandler('paper')}
                      >
                        <AddIcon />
                      </Button>
                    )}
                    <Button
                      className={`${styles.button} u-ml-sm`}
                      onClick={onReloadElementHandler}
                    >
                      <ReplayIcon />
                    </Button>
                  </div>
                ) : null}
              </Typography>
            </div>
          </div>

          {queryResult.loading ? (
            <Loading />
          ) : (
            <AuctionElementsViewer
              auctionElements={finalElements}
              deals={deals}
              deleteAuctionElement={deleteAuctionElement}
              isEditable={isEditable}
            />
          )}

          <WithdrawnDeals withdrawnDeals={auctionFromDB.withdrawnDeals} />

          <AddAuctionElement
            open={open}
            scroll={scroll}
            handleClose={HandleClose}
            auctionElements={[...finalElements]}
            auctionDate={auctionDate}
            companyId={companyId}
            setIsSuggestedDealsLoadingFinished={
              setIsSuggestedDealsLoadingFinished
            }
            setSuggestedDealsCount={setSuggestedDealsCount}
          />
        </Paper>
      )}
      {isEditable ? (
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <AuctionSaveConfirmation
            shopId={shopId}
            companyId={companyId}
            isNotifyCustomerChecked={isNotifyCustomerChecked}
            onNotifyCustomerChanged={onNotifyCustomerChanged}
            onSave={prepareAuctionForSaving}
            onReload={onReloadAuctionHandler}
            isComponentUsedforUpdating={true}
            onDelete={onDelete}
            defaultConfirmationCheckBox={defaultConfirmationCheckBox}
            setDefaultConfirmationCheckBox={setDefaultConfirmationCheckBox}
            addOrUpdateIsInProgress={addOrUpdateIsInProgress}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '2rem',
              marginLeft: '2rem',
            }}
          >
            <Button
              style={{ width: '19rem' }}
              variant="contained"
              onClick={generateAuctionAnnouncement}
            >
              {t('auction.auction_pdf_generation')}
            </Button>
            {generatingAuctionAnnouncement && (
              <div className={`${styles.spinner}`}>
                <LoadingSpinner />
              </div>
            )}
            <div>
              <div>
                {!generatingAuctionAnnouncement &&
                  auctionFromDB?.auctionAnnouncementLink && (
                    <a
                      href={auctionFromDB?.auctionAnnouncementLink?.url}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.documentLink}
                      style={{ marginLeft: '2rem' }}
                    >
                      {auctionFromDB?.auctionAnnouncementLink?.url && (
                        <span>
                          {auctionFromDB?.auctionAnnouncementLink?.filename}
                        </span>
                      )}
                    </a>
                  )}
              </div>
              <div>
                {!generatingAuctionAnnouncement &&
                  auctionFromDB?.internalAuctionAnnouncementLink && (
                    <a
                      href={auctionFromDB?.internalAuctionAnnouncementLink?.url}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.documentLink}
                      style={{ marginLeft: '2rem' }}
                    >
                      {auctionFromDB?.internalAuctionAnnouncementLink?.url && (
                        <span>
                          {
                            auctionFromDB?.internalAuctionAnnouncementLink
                              ?.filename
                          }
                        </span>
                      )}
                    </a>
                  )}
              </div>
            </div>
            {!generatingAuctionAnnouncement &&
              !auctionFromDB?.internalAuctionAnnouncementLink &&
              !auctionFromDB?.internalAuctionAnnouncementLink && (
                <span className={`${styles.notExistMsg}`}>
                  {t('auction.auction_announcement_does_not_exist')}
                </span>
              )}
          </div>
        </Paper>
      ) : null}
    </Paper>
  )

  function renderStartedAuctionItems() {
    switch (auctionPage) {
      case 'AuctionStart':
        return (
          <AuctionStart
            auction={auctionFromDB}
            onClickOverview={() => setAuctionPage('AuctionOverview')}
          />
        )
      case 'AuctionOverview':
        return (
          <AuctionItemsTable
            setAuctionItems={setAuctionItems}
            auctionItems={auctionItems}
            onClickSetBuyerInformation={() =>
              setAuctionPage('AuctionBuyerInformation')
            }
            onClickItemView={() => setAuctionPage('AuctionStart')}
            setSelectedItemIds={setSelectedItemIds}
            selectedItemIds={selectedItemIds}
          />
        )

      case 'AuctionBuyerInformation':
        return (
          <AuctionBuyerInformation
            selectedItemIds={selectedItemIds}
            auction={{
              ...auctionFromDB,
              items: auctionItems,
            }}
            onCompleted={() => {
              setAuctionPage('AuctionOverview')
              setSelectedItemIds([])
            }}
          />
        )

      default:
        return <p>Error happened on showing started-auction items.</p>
    }
  }
}

export default AuctionBlock
