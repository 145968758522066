import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { ELanguageCode, Query, QueryGetCustomDealByIdArgs } from '@/schemaTypes'
import { customDeal, customDealItem } from './customDealFragments'

const GET_CUSTOM_DEAL_BY_ID = gql`
  query getCustomDealById(
    $customDealId: ObjectId!
    $languageCode: ELanguageCode!
  ) {
    getCustomDealById(customDealId: $customDealId) {
      ...CustomDealFragment
      items {
        ...CustomDealItemFragment
        formAnswers {
          ...FormAnswerFragment
        }
      }
    }
  }
  ${customDeal}
  ${customDealItem}
  ${fragments.customDealFormQuestion}
  ${fragments.formAnswers}
`

type TData = { getCustomDealById: Query['getCustomDealById'] }
type TVariables = { languageCode: ELanguageCode } & QueryGetCustomDealByIdArgs

export function useGetCustomDealById(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_CUSTOM_DEAL_BY_ID, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, customDeal: queryResult.data?.getCustomDealById }
}
