import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import React from 'react'
import SelectOptionInput from '@/components/SelectOptionInput'
import { EStorageStatus, ItemStorageHistoryEntry } from '@/schemaTypes'
import globalStyles from '@/styles/global.module.scss'

export interface SelectStorageUnitProps {
  value: string | undefined
  menuItems?: any
  onChange?: (value: string) => void
  iconOnClick?: () => void
  latestStorageHistoryEntry?: ItemStorageHistoryEntry | null
}

export function StorageUnitsSelectInput(props: SelectStorageUnitProps) {
  const { value, menuItems, onChange, iconOnClick, latestStorageHistoryEntry } =
    props

  const isEnabled =
    !latestStorageHistoryEntry ||
    latestStorageHistoryEntry.storageStatus !== EStorageStatus.AddedToStorage
  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <SelectOptionInput
        value={value}
        onChange={onChange}
        menuItems={menuItems}
        disabled={!isEnabled}
      />
      &nbsp;&nbsp;
      {isEnabled && (
        <RotateLeftIcon
          className={globalStyles.clickable}
          onClick={iconOnClick}
        />
      )}
    </div>
  )
}

export default StorageUnitsSelectInput
