import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateShopIdArgs } from '@/schemaTypes'

const UPDATE_SHOP_ID = gql`
  mutation updateShopId($dealId: ObjectId!, $initialShopId: ObjectId!) {
    updateShopId(dealId: $dealId, initialShopId: $initialShopId) {
      _id
      initialShopId
    }
  }
`

type TData = { updateShopId: Mutation['updateShopId'] }
type TVariables = MutationUpdateShopIdArgs

export function useUpdateShopId(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateShopId] = useMutation<TData, TVariables>(UPDATE_SHOP_ID, opts)

  return updateShopId
}
