import { ValidationError, validateSync } from 'class-validator'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ConstructorType } from '@/types'
import { camelToUpperSnake } from '@/utils/misc'

const validateNested = <C>(
  error: ValidationError,
  root: Partial<Record<keyof C, any>>,
  t: Function,
) => {
  if (error?.children && error.children.length > 0) {
    root[error.property] = error.property in root ? root[error.property] : {}
    error.children.forEach((childError) => {
      validateNested(childError, root[error.property], t)
    })
  } else {
    root[error.property] = error.constraints
      ? Object.keys(error.constraints)
          .map(
            (key) =>
              `${t(`validation.validation_${camelToUpperSnake(key)}`.toLowerCase())}!`,
          )
          .join(' ')
      : undefined
  }
}

export function useValidationErrorMap<T extends object, C extends object>(
  model: T,
  ValidationClass: ConstructorType<C>,
  skipFields?: Array<string>,
) {
  const { t } = useTranslation()

  const validationModel = new ValidationClass(model)
  const validationErrorsMap: Partial<Record<keyof C, any>> = {}

  validateSync(validationModel).forEach((error) => {
    validateNested<C>(error, validationErrorsMap, t)
  })

  if (skipFields) {
    skipFields.forEach((field) => delete validationErrorsMap[field])
  }

  return {
    validationErrorsMap,
    isValid: isEmpty(validationErrorsMap),
  }
}
