import BreadcrumbsBar, { BreadcrumbsBarEntry } from '../BreadcrumbsBar'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { camelToSnake } from '@/utils/misc'

export interface ConfigurationBreadcrumbsProps {
  modelId?: string
  labelAppendix?: string
  modelName?: string
  mode?: 'create' | 'edit'
  hideConfigurations?: boolean
}

export default function ConfigurationBreadcrumbs(
  props: ConfigurationBreadcrumbsProps,
) {
  const { modelId, modelName, mode, hideConfigurations, labelAppendix } = props
  const { t } = useTranslation()

  const entries: BreadcrumbsBarEntry[] = []

  if (!hideConfigurations) {
    entries.push({ label: t('configurations'), url: '/inApp/configurations' })
  }

  const configurationsUrlFragment = hideConfigurations ? '' : '/configurations'

  if (modelName) {
    const translationKey = camelToSnake(modelName)

    entries.push({
      label: t(translationKey),
      url: `/inApp${configurationsUrlFragment}/${modelName}`,
    })
  }

  if (mode) {
    const labelIdAppendix = modelId ? ` ${labelAppendix ?? modelId}` : ''
    const urlIdAppendix = modelId ? `/${modelId}` : ''

    entries.push({
      label: `${t(mode)}${labelIdAppendix}`,
      url: `/inApp${configurationsUrlFragment}/${modelName}/${mode}${urlIdAppendix}`,
    })
  }

  return <BreadcrumbsBar entries={entries} />
}
