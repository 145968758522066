import { CarInspection } from '../CarInspection'
import styled from '@emotion/styled'
import classnames from 'classnames'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DatePicker from '@/redesign/components/DatePicker/DatePicker'
import { Deal, EBusinessUnit } from '@/schemaTypes'
import { LatestDealEvents } from './../GeneralInfo/GeneralInfo'

export interface CarExtensionDateAndInspectionProps {
  deal: Deal
  event: LatestDealEvents
  className?: string
}

export const CarExtensionDateAndInspection: FunctionComponent<
  CarExtensionDateAndInspectionProps
> = ({ deal, event, className }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const isCarInspectionButtonVisible =
    event.verifiedEvent &&
    !event.closedEvent &&
    !event.paybackConfirmedEvent &&
    !event.extensionConfirmedEvent &&
    deal.businessUnit === EBusinessUnit.Car &&
    deal.items[0].isContinueUsing &&
    deal.dealFinalValues.isOnlineExtensionPrevented

  return (
    <div className={classnames('flex flex-row items-center', className)}>
      <DatePicker
        dateFormat="dd.mm.yy"
        value={deal.pawnData?.finalMaxPawnDate}
        label={t('car_inspection.extension_possible_until')}
        disabled={true}
        className="flex flex-col md:flex-row items-start md:items-center mr-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        tooltipText={t('car_inspection.extension_possible_until_tooltip')}
        tooltipIcon={'pi pi-question-circle'}
        tooltipOptions={{ showOnDisabled: true }}
      />

      {isCarInspectionButtonVisible && (
        <StyledButton severity="info" onClick={() => setOpen(true)}>
          {t('car_inspection.perform_car_inspection')}
        </StyledButton>
      )}

      <Dialog
        contentStyle={{
          overflow: 'visible',
        }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        header={t('car_inspection.dialog_title')}
        visible={open}
        style={{ width: '40vw' }}
        onHide={() => setOpen(false)}
        draggable={false}
      >
        <CarInspection deal={deal} setOpen={setOpen} />
      </Dialog>
    </div>
  )
}

const StyledButton = styled(Button)`
  color: var(--button-secondary-color, #fff);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.05rem;
`
