import styled from '@emotion/styled'
import {
  DataTable,
  DataTableProps,
  DataTableSelectionSingleChangeEvent,
  DataTableValueArray,
} from 'primereact/datatable'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface InfiniteScrollTableProps<T extends DataTableValueArray> {
  value: T
  handleLoadNextPage: () => Promise<void>
  onClickRow?: (e: DataTableSelectionSingleChangeEvent<T>) => void
  dataKey: string
  emptyMessage?: string
  children: React.ReactNode
  rows?: number
  itemSize?: number
  hasNextPage: boolean
  limit: number
}

const InfiniteScrollTable = <T extends DataTableValueArray>({
  value,
  handleLoadNextPage,
  onClickRow,
  dataKey,
  children,
  emptyMessage,
  rows = 10,
  itemSize = 70,
  hasNextPage,
  limit,
}: InfiniteScrollTableProps<T>) => {
  const { t } = useTranslation()
  const loadingRef = useRef(false)

  const handleLazyLoad = useCallback(
    async ({ last }) => {
      if (loadingRef.current) {
        return
      }
      if (!hasNextPage || last + limit <= value?.length) {
        return
      }
      loadingRef.current = true
      try {
        await handleLoadNextPage()
      } finally {
        loadingRef.current = false
      }
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleLoadNextPage, value, limit],
  )

  return (
    <DataTableStyled<T>
      scrollHeight="flex"
      value={value}
      scrollable
      rows={rows}
      virtualScrollerOptions={{
        lazy: true,
        onLazyLoad: handleLazyLoad,
        itemSize: itemSize,
        loaderDisabled: true,
      }}
      selectionMode="single"
      onSelectionChange={(e) => {
        const selection = window.getSelection()
        if (selection && selection.toString().length <= 0) {
          onClickRow?.(e)
        }
      }}
      dataKey={dataKey}
      tableStyle={{
        minWidth: '100rem',
        borderCollapse: 'separate',
        tableLayout: 'fixed',
      }}
      emptyMessage={emptyMessage || t('no_matching_results_found')}
    >
      {children}
    </DataTableStyled>
  )
}

const DataTableStyled = styled(DataTable<any>)`
  &&& {
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;

    .p-datatable-wrapper {
      border-radius: 0.375rem;
    }
    tr > td {
      padding: 0rem 1rem !important;
    }
  }
` as unknown as <T extends DataTableValueArray>(
  props: DataTableProps<T>,
) => JSX.Element

export default InfiniteScrollTable
