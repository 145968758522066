import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationAddCompanyIdArgs } from '@/schemaTypes'

const ADD_COMPANY_ID = gql`
  mutation addCompanyId($companyId: ObjectId!, $employeeId: ObjectId!) {
    addCompanyId(companyId: $companyId, employeeId: $employeeId) {
      _id
      firstname
      lastname
      companyId
    }
  }
`

type TData = { addCompanyId: Mutation['addCompanyId'] }
type TVariables = MutationAddCompanyIdArgs

export function useAddCompanyId(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [addCompanyId] = useMutation<TData, TVariables>(ADD_COMPANY_ID, opts)

  return addCompanyId
}
