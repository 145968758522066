import { Button, Checkbox, FormLabel, Typography } from '@material-ui/core'
import * as React from 'react'
import { Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import LoadingSpinner from '@/components/LoadingSpinner'
import { Context } from '@/context'
import styles from './AuctionSaveConfirmation.module.scss'

export interface AuctionSaveConfirmationProps {
  isNotifyCustomerChecked: boolean
  onNotifyCustomerChanged
  onSave
  onReload
  onDelete
  isComponentUsedforUpdating
  defaultConfirmationCheckBox
  setDefaultConfirmationCheckBox
  addOrUpdateIsInProgress
  companyId?: string | null
  shopId?: string | null
}

const AuctionSaveConfirmation: React.FC<AuctionSaveConfirmationProps> = (
  props,
) => {
  const {
    isNotifyCustomerChecked,
    onNotifyCustomerChanged,
    onSave,
    onReload,
    isComponentUsedforUpdating,
    onDelete,
    defaultConfirmationCheckBox,
    setDefaultConfirmationCheckBox,
    addOrUpdateIsInProgress,
    companyId,
    shopId,
  } = props

  const { isGuestUser } = useContext(Context)
  const { t } = useTranslation()
  const onChangeSaveConfirmed = (value) => {
    setDefaultConfirmationCheckBox(value)
    onNotifyCustomerChanged(value)
  }

  return (
    <Fragment>
      <Typography variant="h6" gutterBottom classes={{ root: styles.title }}>
        Save Confirmation
      </Typography>

      <table style={{ marginBottom: '1rem' }}>
        <tbody>
          <tr>
            <td>
              <Checkbox
                disabled={isGuestUser()}
                checked={defaultConfirmationCheckBox}
                onChange={(e) => onChangeSaveConfirmed(e.target.checked)}
              />
            </td>
            <td>
              <FormLabel>{t('auction.auction_save_confirmation')}</FormLabel>
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox
                disabled={isGuestUser() || !defaultConfirmationCheckBox}
                checked={isNotifyCustomerChecked}
                onChange={(e) => onNotifyCustomerChanged(e.target.checked)}
              />
            </td>
            <td>
              <FormLabel
                disabled={isGuestUser() || !defaultConfirmationCheckBox}
              >
                {t('auction.auction_notify_customer')}
              </FormLabel>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td>
              <Link to={`/inApp/auctions`}>
                <Button
                  style={{ width: '8rem', marginLeft: '2rem' }}
                  variant="contained"
                >
                  {t('abort')}
                </Button>
              </Link>
            </td>
            <td>
              <Button
                style={{ width: '8rem', marginLeft: '2rem' }}
                variant="contained"
                onClick={onReload}
              >
                {t('reset')}
              </Button>
            </td>
            <td>
              <Button
                style={{ width: '8rem', marginLeft: '2rem' }}
                disabled={
                  isGuestUser() ||
                  !defaultConfirmationCheckBox ||
                  !shopId ||
                  !companyId
                }
                variant="contained"
                onClick={onSave}
              >
                {isComponentUsedforUpdating
                  ? t('auction.auction_update')
                  : t('auction.add_auction')}
              </Button>
            </td>
            <td>
              {isComponentUsedforUpdating && (
                <Button
                  style={{ width: '8rem', marginLeft: '2rem' }}
                  disabled={isGuestUser() || !defaultConfirmationCheckBox}
                  variant="contained"
                  onClick={onDelete}
                >
                  {t('auction.auction_delete')}
                </Button>
              )}
            </td>

            <td>
              <div className={`o-media__fixed u-inline-block`}>
                {addOrUpdateIsInProgress && (
                  <div className="u-ml-sm u-inline-block">
                    <LoadingSpinner />
                  </div>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

export default AuctionSaveConfirmation
