import { buildInitialCashBoxEntries } from '../helpers'
import dayjs from 'dayjs'
import { cloneDeep, round } from 'lodash'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Message } from 'primereact/message'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useCreateCashBookCount,
  useGetCashBookCount,
  useUpdateCashBookCount,
} from '@/domains/cashbooks/hooks'
import { useGetShopsNames, useMutationShowingErrors } from '@/hooks'
import CashBookCountTable from '@/redesign/domains/cashbook/components/CashBookCountTable'
import {
  CashBookCount,
  EBooleanFilterConditions,
  EManualPaymentType,
  EOrderBy,
  Employee,
} from '@/schemaTypes'
import { calculateCashBoxAmounts } from '@/utils/calculation'
import { addDays, defaultTimezone, isToday } from '@/utils/time'

export default function BankNoteAndCoinCount(props: { currentUser: Employee }) {
  const { t } = useTranslation()

  const [date] = useState<Date>(new Date())
  const [shopId, setShopId] = useState<string>('')
  const [cashBookCount, setCashBookCount] = useState<
    CashBookCount | undefined
  >()
  const [latestBalance, setLatestBalance] = useState<number | undefined>()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [loadedDate, setLoadedDate] = useState<Date | undefined>()

  const [cautionModalVisible, setCautionModalVisible] = useState<boolean>(false)

  const { shops } = useGetShopsNames({
    variables: {
      opts: {
        filter: {
          disabled: {
            value: true,
            condition: EBooleanFilterConditions.NotEquals,
          },
        },
      },
    },
  })

  useGetCashBookCount({
    variables: {
      opts: {
        filter: {
          date: {
            betweenValues: {
              from: dayjs(date).startOf('day').toDate(),
              to: dayjs(date).endOf('day').toDate(),
            },
          },
          shopId: { value: shopId },
          paymentType: EManualPaymentType.Cash,
        },
        order: {
          date: EOrderBy.Desc,
        },
      },
      filterArgs: {
        shopId: { value: shopId },
        date: {
          betweenValues: {
            from: date,
            to: addDays(date, 1, defaultTimezone),
          },
        },
        paymentType: EManualPaymentType.Cash,
      },
    },
    fetchPolicy: 'network-only',
    skip: !date || !shopId,
    onCompleted: (data) => {
      if (data.getCashBookCount && isToday(data.getCashBookCount.date)) {
        setCashBookCount(data.getCashBookCount)
      } else {
        setInitialCashBookCount()
      }

      setLatestBalance(data.getLatestCashFlowBalanceForDate)
    },
  })

  const lastItems = useGetCashBookCount({
    variables: {
      opts: {
        filter: {
          shopId: { value: shopId },
          paymentType: EManualPaymentType.Cash,
          temporary: {
            value: true,
            condition: EBooleanFilterConditions.NotEquals,
          },
        },
        order: {
          date: EOrderBy.Desc,
        },
      },
      filterArgs: {
        shopId: { value: shopId },
        date: {
          betweenValues: {
            from: date,
            to: addDays(date, 1, defaultTimezone),
          },
        },
        paymentType: EManualPaymentType.Cash,
      },
    },
    fetchPolicy: 'network-only',
    skip: !date || !shopId,
  })

  const setInitialCashBookCount = () => {
    const shop = shops.find((entry) => entry._id === shopId)

    const cashBoxNames = shop?.cashBoxNames ?? []

    const cashbookCount: CashBookCount = {
      _id: '',
      shopId,
      employeeId: props.currentUser._id,
      employee: props.currentUser,
      date,
      paymentType: EManualPaymentType.Cash,
      cashBoxEntries: buildInitialCashBoxEntries(cashBoxNames),
    }

    setCashBookCount(cashbookCount)
  }

  const createCashBookCount = useMutationShowingErrors({
    mutation: useCreateCashBookCount(),
    successMessage: t('cash_book_count.cash_book_count_saved'),
  })

  const updateCashBookCount = useMutationShowingErrors({
    mutation: useUpdateCashBookCount(),
    successMessage: t('cash_book_count.cash_book_count_saved'),
  })

  const onCloseDay = () => {
    setCautionModalVisible(true)
  }

  const onLoadLastValues = () => {
    if (lastItems.cashBookCount.cashBoxEntries?.length > 0) {
      setCashBookCount((state) => ({
        ...state,
        cashBoxEntries: lastItems.cashBookCount.cashBoxEntries,
      }))
      setLoadedDate(lastItems.cashBookCount.date)
    }
  }

  const onSave = async (temporary = false) => {
    if (!cashBookCount) return

    const { employeeId, date, shopId, cashBoxEntries, paymentType } =
      cashBookCount

    setIsSaving(true)

    try {
      if (cashBookCount._id) {
        await updateCashBookCount({
          variables: {
            cashBookCountUpdateArgs: {
              _id: cashBookCount._id,
              cashBoxEntries,
              employeeId,
              temporary,
            },
          },
          refetchQueries: ['getCashBookCount'],
        })
      } else {
        await createCashBookCount({
          variables: {
            cashBookCountArgs: {
              cashBoxEntries,
              shopId,
              employeeId,
              date,
              paymentType,
              temporary,
            },
          },
          refetchQueries: ['getCashBookCount'],
        })
      }
      setCautionModalVisible(false)
    } finally {
      setIsSaving(false)
    }
  }

  const onStoreTemporary = () => {
    onSave(true)
    setLoadedDate(null)
  }

  return (
    <div className="max-w-[720px]">
      <div className="mb-4">
        <div className="flex items-center">
          <div className="mr-16">{t('cash_book_count.select_cashbook')}</div>
          <Dropdown
            value={shopId}
            onChange={(e) => {
              setShopId(e.value)
              setLoadedDate(undefined)
            }}
            options={shops.map((option) => ({
              name: option.name,
              value: option._id,
            }))}
            optionLabel="name"
            className="mr-auto w-[200px]"
          />
          {cashBookCount && !cashBookCount?._id && (
            <Button
              severity="secondary"
              className="uppercase font-bold"
              size="small"
              disabled={!shopId || !!loadedDate}
              icon="pi pi-download"
              label={t('cash_book_count.load_last_known_values')}
              onClick={onLoadLastValues}
            />
          )}
        </div>
      </div>

      {cashBookCount?.temporary && (
        <Message
          severity="info"
          text={t('cash_book_count.store_temp_info', {
            date: dayjs(cashBookCount.date).format('HH:mm DD.MM.YYYY'),
          })}
          className="!mb-4"
        />
      )}

      {loadedDate && (
        <Message
          severity="info"
          text={t('cash_book_count.loaded_date', {
            date: dayjs(loadedDate).format('DD.MM.YYYY'),
          })}
          className="!mb-4"
        />
      )}

      {cashBookCount && shopId && (
        <>
          <CashBookCountTable
            cashBoxEntries={cashBookCount.cashBoxEntries}
            readOnly={!!cashBookCount._id && !cashBookCount.temporary}
            onChange={(data) => {
              const { cashBoxName, count, moneyPiece } = data

              const cashBoxEntries = cloneDeep(cashBookCount.cashBoxEntries)
              const cashBoxEntry = cashBoxEntries.find(
                (entry) => entry.cashBoxName === cashBoxName,
              )

              if (cashBoxEntry) {
                cashBoxEntry.counts[moneyPiece.toUpperCase()] = count
              }

              setCashBookCount({ ...cashBookCount, cashBoxEntries })
            }}
            latestBalance={latestBalance}
          />

          {cashBookCount._id && !cashBookCount.temporary ? (
            <Message
              severity="info"
              text={t('cash_book_count.perform_count_completed')}
            />
          ) : (
            <div className="flex justify-between">
              <Button
                onClick={onStoreTemporary}
                label={t('cash_book_count.store_temp')}
                severity="secondary"
                size="small"
                disabled={isSaving}
              />
              <Button
                onClick={onCloseDay}
                label={t('cash_book_count.close_day')}
                severity="info"
                size="small"
                disabled={isSaving}
              />
            </div>
          )}
        </>
      )}

      <Dialog
        header={t('cash_book_count.perform_count_close')}
        visible={cautionModalVisible}
        onHide={() => setCautionModalVisible(false)}
        className="w-50vw"
        footer={
          <>
            <div className="flex justify-end gap-2">
              <Button
                label={t('cancel')}
                severity="secondary"
                text
                icon="pi pi-times"
                type="button"
                onClick={() => setCautionModalVisible(false)}
              />
              <Button
                label={t('continue')}
                severity="info"
                icon="pi pi-check"
                type="submit"
                onClick={() => onSave()}
                disabled={isSaving}
              />
            </div>
          </>
        }
      >
        <p className="mb-4 font-bold">
          {t('cash_book_count.perform_count_close_modal_title')}
        </p>

        {cashBookCount &&
        round(
          calculateCashBoxAmounts(cashBookCount.cashBoxEntries).totalAmount -
            latestBalance,
          2,
        ) !== 0 ? (
          <Message
            severity="error"
            text={t('cash_book_count.perform_count_close_modal_caution')}
            className="whitespace-pre w-full !justify-start"
          />
        ) : (
          <Message
            severity="info"
            text={t('cash_book_count.perform_count_close_modal_warning')}
            className="whitespace-pre w-full !justify-start"
          />
        )}
      </Dialog>
    </div>
  )
}
