import { useCallback } from 'react'
import { Deal } from '@/schemaTypes'
import { DealEventType } from '@/types'
import {
  useOnDealStatusCanceled,
  useOnDealStatusDeclined,
  useOnDealStatusPayedAndStored,
  useOnDealStatusReceivedIdMissing,
  useOnDealStatusReviewing,
  useOnDealStatusSoldExtern, // useOnDealStatusVerified,
} from './dealStatusChanges'

// interface HandleOnDealStatusChangeOptions {
//   // verifiedShopId?: string
// }

export function useOnDealStatusChange(
  deal: Deal,
  //  options: HandleOnDealStatusChangeOptions
) {
  const onDealStatusCanceled = useOnDealStatusCanceled(deal)
  const onDealStatusDeclined = useOnDealStatusDeclined(deal)
  const onDealStatusReceivedIdMissing = useOnDealStatusReceivedIdMissing(deal)
  const onDealStatusReviewing = useOnDealStatusReviewing(deal)
  // const onDealStatusVerified = useOnDealStatusVerified(deal, options.verifiedShopId)
  const onDealStatusPayedAndStored = useOnDealStatusPayedAndStored(deal)
  const onDealStatusSoldExtern = useOnDealStatusSoldExtern(deal)

  const handleOnDealStatusChange = useCallback(
    (
      status: Exclude<
        DealEventType,
        | 'DealPaybackConfirmedEvent'
        | 'DealExtensionConfirmedEvent'
        | 'DealClosedEvent'
      >,
    ) => {
      if (status === 'DealCanceledEvent') {
        return onDealStatusCanceled({})
      } else if (status === 'DealDeclinedEvent') {
        return onDealStatusDeclined({})
      } else if (status === 'DealItemReceivedIdMissingEvent') {
        return onDealStatusReceivedIdMissing({})
      } else if (status === 'DealReviewingEvent') {
        return onDealStatusReviewing({})
      }
      // else if (status === 'DealVerifiedEvent') {
      //   return onDealStatusVerified({})
      // }
      else if (status === 'DealPayedAndStoredEvent') {
        return onDealStatusPayedAndStored({})
      } else if (status === 'DealSoldExternEvent') {
        return onDealStatusSoldExtern({})
      }
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deal],
  )

  return handleOnDealStatusChange
}
