import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Loading from '@/components/Loading'
import { useGetCustomer, useRegisterCustomer } from '@/domains/customers/hooks'
import { useMutationShowingErrors } from '@/hooks'
import BreadCrumbBar from '@/redesign/components/BreadCrumbBar/BreadCrumbBar'
import TitleBar from '@/redesign/components/TitleBar/TitleBar'
import CustomersConfiguration, {
  CustomerArgs,
} from '@/redesign/domains/customer/components/CustomerConfiguration'

export interface CustomersCreateProps {
  customerId?: string
}

export function CustomersCreate(props: CustomersCreateProps) {
  const { customerId } = props
  const { t } = useTranslation()
  const history = useHistory()

  const { customer, queryResult } = useGetCustomer({
    variables: {
      customerId: customerId ?? '',
    },
  })

  const registerCustomer = useMutationShowingErrors({
    mutation: useRegisterCustomer(),
    successMessage: t('customer.created'),
  })

  const onSubmit = async (values: CustomerArgs) => {
    if (!values.dateOfBirth || !values.password) {
      return
    }

    await registerCustomer({
      variables: {
        ...values,
        dateOfBirth: values.dateOfBirth!,
        password: values.password!,
        address: values.addresses ? values.addresses[0] : null,
      },
      onCompleted: (data) => {
        history.replace(`/inApp/customers/edit/${data.registerCustomer._id}`)
      },
    })
  }

  return (
    <div className="w-full">
      <BreadCrumbBar />
      <TitleBar title={t('customer_create_config')} />
      {queryResult.loading ? (
        <Loading />
      ) : (
        customer && (
          <CustomersConfiguration
            customer={customer}
            onSave={onSubmit}
            maxAddresses={1}
          />
        )
      )}
    </div>
  )
}

export default CustomersCreate
