import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'

export interface ShowConfirmPopupOpts {
  title?: string
  actionText: string
  abortActionText?: string
}

export interface ShowConfirmPopupParams {
  action: (state?: any) => void
  leftButtonAction?: (state?: any) => void
  title?: string
  component?: (state, setState) => JSX.Element
  state?: any
  autoFocusCloseButton?: boolean
  preventAutoCloseByKey?: boolean
}

export function useShowConfirmPopup(opts: ShowConfirmPopupOpts) {
  const { actionText, abortActionText } = opts
  const { showConfirmModal, closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()
  return (params: ShowConfirmPopupParams) =>
    showConfirmModal({
      title: params.title ?? opts.title ?? '',
      leftButtonText: abortActionText ?? t('abort'),
      rightButtonText: actionText,
      leftButtonAction: params.leftButtonAction ?? closeConfirmModal,
      rightButtonAction: params.action,
      component: params.component ?? undefined,
      state: params.state,
      autoFocusCloseButton: params.autoFocusCloseButton,
      preventAutoCloseByKey: params.preventAutoCloseByKey,
    })
}

export function useShowConfirmDeletePopup(opts?: ShowConfirmPopupOpts) {
  const { t } = useTranslation()

  return useShowConfirmPopup({
    title: opts?.title ?? t('confirm_delete'),
    actionText: opts?.actionText ?? t('delete'),
    abortActionText: opts?.abortActionText ?? t('abort'),
  })
}
