import { useCreateEmployee } from '../hooks'
import { Form, Formik } from 'formik'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useGetActiveCompaniesNames, useMutationShowingErrors } from '@/hooks'
import FormikDropdown from '@/redesign/components/FormikFields/FormikDropdown/FormikDropdown'
import FormikInput from '@/redesign/components/FormikFields/FormikInput/FormikInput'
import FormikMultiSelect from '@/redesign/components/FormikFields/FormikMultiSelect/FormikMultiSelect'
import { ERoles, MutationCreateEmployeeArgs } from '@/schemaTypes'

interface Props {
  visible: boolean
  setVisible: (visible: boolean) => void
}

const roles = [
  { label: 'SUPERADMIN', value: ERoles.Superadmin },
  { label: 'ADMIN', value: ERoles.Admin },
  { label: 'LOCATIONADMIN', value: ERoles.Locationadmin },
  { label: 'LOCATIONSTAFF', value: ERoles.Locationstaff },
  { label: 'CUSTOMER', value: ERoles.Customer },
  { label: 'GUEST', value: ERoles.Guest },
]

export default function EmployeeCreationDialog({ visible, setVisible }: Props) {
  const { t } = useTranslation()

  const { companies } = useGetActiveCompaniesNames()

  const createEmployee = useMutationShowingErrors({
    mutation: useCreateEmployee(),
    successMessage: t('employee.employee_created'),
  })

  const onSave = async (args: MutationCreateEmployeeArgs, formik) => {
    formik.setSubmitting(true)

    try {
      await createEmployee({
        variables: args,
        refetchQueries: ['getActiveEmployees'],
      })
      setVisible(false)
    } finally {
      formik.setSubmitting(false)
    }
  }

  return (
    <Dialog
      header={t('employee.employee_creation_dialog')}
      visible={visible}
      style={{ width: '90vw', maxWidth: '595px' }}
      onHide={() => setVisible(false)}
    >
      <Formik<MutationCreateEmployeeArgs>
        validationSchema={schema}
        initialValues={{
          email: '',
          companyId: '',
          roles: [ERoles.Locationstaff],
        }}
        onSubmit={onSave}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <p className="mb-4">
              {t('employee.employee_creation_dialog_desc')}
            </p>

            <div className="flex items-center justify-center mb-2">
              <div className="w-48 mr-2">
                <div className="text-base font-bold text-gray-500">
                  {t('customer.email')}
                  <span className="text-red-500 ml-1">*</span>
                </div>
              </div>
              <div className="flex-1">
                <FormikInput
                  style={{ width: '280px' }}
                  name="email"
                  type="email"
                />
              </div>
            </div>

            <div className="flex items-center justify-center mb-2">
              <div className="w-48 mr-2">
                <div className="text-base font-bold text-gray-500">
                  {t('company.singular')}
                  <span className="text-red-500 ml-1">*</span>
                </div>
              </div>
              <div className="flex-1">
                <FormikDropdown
                  name="companyId"
                  style={{ width: '280px' }}
                  options={companies.map((company) => ({
                    label: company.name,
                    value: company._id,
                  }))}
                  optionLabel="label"
                  editable
                />
              </div>
            </div>

            <div className="flex items-center justify-center mb-2">
              <div className="w-48 mr-2">
                <div className="text-base font-bold text-gray-500">
                  {t('customer.role')}
                  <span className="text-red-500 ml-1">*</span>
                </div>
              </div>
              <div className="flex-1">
                <FormikMultiSelect
                  name="roles"
                  options={roles}
                  optionLabel="label"
                  display="chip"
                  style={{ width: '280px' }}
                />
              </div>
            </div>

            <div className="flex justify-end mt-12 gap-2">
              <Button
                label={t('cancel')}
                severity="secondary"
                text
                icon="pi pi-times"
                type="button"
                onClick={() => setVisible(false)}
              />
              <Button
                label={t('continue')}
                severity="info"
                icon="pi pi-check"
                type="submit"
                disabled={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

const schema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  roles: Yup.array().min(1),
  companyId: Yup.string().required(),
})
