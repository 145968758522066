import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import dayjs from 'dayjs'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Context } from '@/context'
import { Customer } from '@/schemaTypes'

export interface CustomerInfoProps {
  customer: Customer
  isMainCustomer: boolean
  onRemoveSecondaryCustomerFromDeal?
  canEditCustomer: boolean
}

export function CustomerInfo(props: CustomerInfoProps) {
  const {
    customer,
    isMainCustomer,
    onRemoveSecondaryCustomerFromDeal,
    canEditCustomer,
  } = props
  const { isGuestUser } = useContext(Context)
  const { t } = useTranslation()
  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Typography variant="body1" gutterBottom>
        {t('customer.firstname')}: {customer.firstname}
        <br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('customer.lastname')}: {customer.lastname}
        <br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('customer.customer_number')}: {customer.customerNumber}
        <br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('customer.date_of_birth')}:{' '}
        {dayjs(customer.dateOfBirth).format('DD.MM.YYYY')}
        <br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('customer.email')}: {customer.email}
        <br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('customer.phone')}: {customer.phone}
        <br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('customer.sex')}: {customer.sex ?? '-'}
        <br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('acquired_by')}: {customer.acquiredBy ?? '-'}
        <br />
      </Typography>
      <div className={`u-block`}>
        {t('note')}:{' '}
        <span style={{ fontSize: 'small', color: 'red' }}>
          {' '}
          {customer.isNotePopup ? ' (Popup Activated)' : ''}
        </span>
        <br />
        <TextField multiline value={customer.note ?? ''} disabled />
      </div>
      <div className={'u-mt-15'}>
        {canEditCustomer && !isGuestUser() ? (
          <Link
            to={`/inApp/customers/edit/${customer._id}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button className="u-mr-mini" variant="contained">
              <EditIcon /> {t('edit_customer')}
            </Button>
          </Link>
        ) : (
          ''
        )}

        {!isMainCustomer && (
          <Button
            className="u-mr-mini"
            variant="contained"
            onClick={() => onRemoveSecondaryCustomerFromDeal(customer._id)}
          >
            <DeleteIcon />
          </Button>
        )}
      </div>
    </Paper>
  )
}

export default CustomerInfo
