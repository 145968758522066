import StorageUnitsInput from '../StorageUnitsInput/StorageUnitsInput'
import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'

export interface StorageUnitsControlsProps {
  firstLevelUnit: {
    name: string
    show: boolean
    isSecureLocation?: boolean
    disableSecureLocationUpdate: boolean
    onChange: (name?: string, isSecureLocation?: boolean) => void
    onClickCreate: () => void
    onClickUpdate: () => void
  }
  secondLevelUnit: {
    name: string
    show: boolean
    isSecureLocation?: boolean
    disableSecureLocationUpdate: boolean
    onChange: (name?: string, isSecureLocation?: boolean) => void
    onClickCreate: () => void
    onClickUpdate: () => void
  }
  thirdLevelUnit: {
    name: string
    show: boolean
    isSecureLocation?: boolean
    disableSecureLocationUpdate: boolean
    onChange: (name?: string, isSecureLocation?: boolean) => void
    onClickCreate: () => void
    onClickUpdate: () => void
  }
  lastLevelUnit: {
    name: string
    show: boolean
    isSecureLocation?: boolean
    disableSecureLocationUpdate: boolean
    onChange: (name?: string, isSecureLocation?: boolean) => void
    onClickCreate: () => void
    onClickUpdate: () => void
  }
  operation: string | undefined
}

export function StorageUnitsControls(props: StorageUnitsControlsProps) {
  const {
    firstLevelUnit,
    secondLevelUnit,
    thirdLevelUnit,
    lastLevelUnit,
    operation,
  } = props
  return (
    <TableRow>
      <TableCell align="center">
        <StorageUnitsInput
          name={firstLevelUnit.name}
          show={firstLevelUnit.show}
          onChange={(value, isSecureLocation) =>
            firstLevelUnit.onChange(value, isSecureLocation)
          }
          onClickCreate={() => firstLevelUnit.onClickCreate()}
          onClickUpdate={() => firstLevelUnit.onClickUpdate()}
          isSecureLocation={firstLevelUnit.isSecureLocation}
          operation={operation}
          disableSecureLocationUpdate={
            firstLevelUnit.disableSecureLocationUpdate
          }
        />
      </TableCell>
      <TableCell align="center">
        <StorageUnitsInput
          name={secondLevelUnit.name}
          show={secondLevelUnit.show}
          onChange={(value, isSecureLocation) =>
            secondLevelUnit.onChange(value, isSecureLocation)
          }
          onClickCreate={() => secondLevelUnit.onClickCreate()}
          onClickUpdate={() => secondLevelUnit.onClickUpdate()}
          isSecureLocation={secondLevelUnit.isSecureLocation}
          operation={operation}
          disableSecureLocationUpdate={
            secondLevelUnit.disableSecureLocationUpdate
          }
        />
      </TableCell>
      <TableCell align="center">
        <StorageUnitsInput
          name={thirdLevelUnit.name}
          show={thirdLevelUnit.show}
          onChange={(value, isSecureLocation) =>
            thirdLevelUnit.onChange(value, isSecureLocation)
          }
          onClickCreate={() => thirdLevelUnit.onClickCreate()}
          onClickUpdate={() => thirdLevelUnit.onClickUpdate()}
          isSecureLocation={thirdLevelUnit.isSecureLocation}
          operation={operation}
          disableSecureLocationUpdate={
            thirdLevelUnit.disableSecureLocationUpdate
          }
        />
      </TableCell>
      <TableCell align="center">
        <StorageUnitsInput
          name={lastLevelUnit.name}
          show={lastLevelUnit.show}
          onChange={(value, isSecureLocation) =>
            lastLevelUnit.onChange(value, isSecureLocation)
          }
          onClickCreate={() => lastLevelUnit.onClickCreate()}
          onClickUpdate={() => lastLevelUnit.onClickUpdate()}
          isSecureLocation={lastLevelUnit.isSecureLocation}
          operation={operation}
          disableSecureLocationUpdate={
            lastLevelUnit.disableSecureLocationUpdate
          }
        />
      </TableCell>
      <TableCell />
    </TableRow>
  )
}

export default StorageUnitsControls
