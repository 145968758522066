import styled from '@emotion/styled'
import { Box, Button, Paper } from '@material-ui/core'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useFormik } from 'formik'
import { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextInput from '@/components/TextInput'
import {
  Deal,
  DealCalculation,
  EFeeLevel,
  ManualFeeDefinitionArgs,
} from '@/schemaTypes'
import { getManualFeeDefinitionsFromCalculation } from '@/utils/deal'
import { displayLocalAmount } from '@/utils/misc'
import FeeDefinition from './FeeDefinition'

export interface ManualFeeDefinitionFormData {
  inEditFeeIndex: number
  removedAutomaticFeeIndexes: number[]
  manualFeeDefinitionsArgs: ManualFeeDefinitionArgs[]
}

interface ManualFeeDefinitionProps {
  deal: Deal
  initialCalculation: DealCalculation
  dealCalculation: DealCalculation
  calculationQueryResult
  args: ManualFeeDefinitionArgs[]
  setArgs: (value: ManualFeeDefinitionArgs[]) => void
  showPayoutAmount: boolean
  showResetButton: boolean
  isReadonly: boolean
}

const ManualFeeDefinition: FunctionComponent<ManualFeeDefinitionProps> = (
  props,
) => {
  const {
    deal,
    initialCalculation,
    args,
    setArgs,
    dealCalculation,
    calculationQueryResult,
    showPayoutAmount,
    showResetButton,
    isReadonly,
  } = props
  const { t } = useTranslation()
  const [showUst, setShowUst] = useState(false)
  const onFormikSubmit = () => {
    setArgs([...formik.values.manualFeeDefinitionsArgs])
    calculationQueryResult.refetch()
  }
  const onFormikReset = () => {
    const newArgs = getManualFeeDefinitionsFromCalculation(
      initialCalculation,
      deal.dealType,
    )
    setArgs(newArgs)
    setFieldValue('removedAutomaticFeeIndexes', [])
    setFieldValue('manualFeeDefinitionsArgs', newArgs)
    calculationQueryResult.refetch()
  }

  const formik = useFormik<ManualFeeDefinitionFormData>({
    initialValues: {
      inEditFeeIndex: -1,
      removedAutomaticFeeIndexes: [],
      manualFeeDefinitionsArgs: args,
    },
    onSubmit: onFormikSubmit,
  })

  const { setFieldValue } = formik

  return (
    <Paper
      style={{ minWidth: '750px', maxWidth: isReadonly ? '60%' : '100%' }}
      className="u-p-sm u-12/12 u-mb-sm"
    >
      <Box
        style={{
          display: 'flex',
          marginBottom: '0.3rem',
          justifyContent: 'space-between',
        }}
      >
        {showPayoutAmount && (
          <TextInput
            divWidth={100}
            label={`${t('payout.payout_amount')}:`}
            value={displayLocalAmount(deal.dealFinalValues.payoutAmount)}
          />
        )}
        {showResetButton && !isReadonly ? (
          <Button variant="outlined" onClick={() => onFormikReset()}>
            {t('reset')}
          </Button>
        ) : (
          ''
        )}
      </Box>

      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              colSpan={8}
              sx={{
                textAlign: 'center',
                backgroundColor: '#845FC7',
                fontSize: 18,
              }}
            >
              {t('deal.label')}
            </StyledTableCell>
          </TableRow>
          <TableRow
            sx={{
              textAlign: 'left',
              backgroundColor: '#845FC7',
            }}
          >
            <StyledTableCell>{`${t('fee_type.label')}`}</StyledTableCell>
            <StyledTableCell>{`${t('fees.fees_amount')}`}</StyledTableCell>
            <StyledTableCell>{`${t('mode')}`}</StyledTableCell>
            <StyledTableCell>{`${t(showUst ? 'gross_preview' : 'preview')}`}</StyledTableCell>
            {showUst && (
              <>
                <StyledTableCell>{`${t('ust')} %`}</StyledTableCell>
                <StyledTableCell>{`${t('net_preview')}`}</StyledTableCell>
              </>
            )}
            <StyledTableCell colSpan={2}>{`${t('note')}`}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            textAlign: 'left',
            alignContent: 'left',
          }}
        >
          <FeeDefinition
            deal={deal}
            initialCalculation={initialCalculation}
            isDealLevel={true}
            dealCalculation={dealCalculation}
            levelFeeDefinitionArgs={formik.values.manualFeeDefinitionsArgs.filter(
              (c) => c.level === EFeeLevel.Deal,
            )}
            allFeeDefinitionArgs={formik.values.manualFeeDefinitionsArgs}
            inEditFeeIndex={formik.values.inEditFeeIndex}
            removedAutomaticFeeIndexes={
              formik.values.removedAutomaticFeeIndexes
            }
            onFormikSubmit={onFormikSubmit}
            setFieldValue={setFieldValue}
            isReadonly={isReadonly}
            showUst={showUst}
            setShowUst={setShowUst}
          />

          <TableRow>
            <StyledTableCell
              colSpan={8}
              sx={{
                textAlign: 'center',
                backgroundColor: '#845FC7',
                fontSize: 18,
              }}
            >
              {t('items')}
            </StyledTableCell>
          </TableRow>

          {deal.items.map((item) => {
            return (
              <FeeDefinition
                deal={deal}
                isDealLevel={false}
                dealCalculation={dealCalculation}
                levelFeeDefinitionArgs={formik.values.manualFeeDefinitionsArgs.filter(
                  (c) =>
                    c.level === EFeeLevel.Item &&
                    c.storageLabel === item.storageLabel,
                )}
                allFeeDefinitionArgs={formik.values.manualFeeDefinitionsArgs}
                inEditFeeIndex={formik.values.inEditFeeIndex}
                removedAutomaticFeeIndexes={
                  formik.values.removedAutomaticFeeIndexes
                }
                onFormikSubmit={onFormikSubmit}
                setFieldValue={setFieldValue}
                storageLabel={item.storageLabel}
                isReadonly={isReadonly}
                showUst={showUst}
                setShowUst={setShowUst}
                initialCalculation={initialCalculation}
              />
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default ManualFeeDefinition

const StyledTableCell = styled(TableCell)`
  color: white;
`
