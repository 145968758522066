import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query } from '@/schemaTypes'

const REFURBED_PRODUCTS_STATUS = gql`
  query getRefurbedProductsImportStatus {
    getRefurbedProductsImportStatus {
      status
      addedProductsCount
    }
  }
`

type TData = {
  getRefurbedProductsImportStatus: Query['getRefurbedProductsImportStatus']
}
type TVariables = {}

export function useGetRefurbedProductsImportStatus(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const querySuggestedResult = useQuery<TData, TVariables>(
    REFURBED_PRODUCTS_STATUS,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(querySuggestedResult.error)

  return {
    getRefurbedProductsImportStatusResult:
      querySuggestedResult.data?.getRefurbedProductsImportStatus,
  }
}
