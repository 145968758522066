import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationImportProductsArgs } from '@/schemaTypes'

const IMPORT_PRODUCTS = gql`
  mutation importProducts($args: ImportProductsArgs!) {
    importProducts(args: $args)
  }
`

type TData = { importProducts: Mutation['importProducts'] }
type TVariables = MutationImportProductsArgs

export function useImportProducts(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [importProducts] = useMutation<TData, TVariables>(IMPORT_PRODUCTS, opts)
  return importProducts
}
