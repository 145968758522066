import ItemOverviewTable from '../ItemOverviewTable/ItemOverviewTable'
import { ApolloQueryResult } from '@apollo/client'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from '@/components/Loading'
import { Context } from '@/context'
import {
  useModifyDealItemsWithWarning,
  useRemoveDealItem,
} from '@/domains/deals/hooks'
import { useConfirmDeleteMutationShowingErrors } from '@/hooks'
import {
  Deal,
  DealEvent,
  EBusinessUnit,
  Query,
  QueryGetDealByIdArgs,
} from '@/schemaTypes'

export interface ItemOverviewProps {
  deal: Deal
  activeEvent: DealEvent
  refetchDeal: (
    variables?: Partial<QueryGetDealByIdArgs> | undefined,
  ) => Promise<ApolloQueryResult<{ getDealById: Query['getDealById'] }>>
}

const ItemOverview = (props: ItemOverviewProps) => {
  const { deal, refetchDeal } = props
  const { t } = useTranslation()

  const { isGuestUser } = useContext(Context)
  const [removeDealItemMutation, removeDealItemRes] = useRemoveDealItem()
  const isBookedDeal =
    deal.events[deal.events.length - 1].__typename === 'DealBookedEvent'

  const removeDealItem = useConfirmDeleteMutationShowingErrors({
    mutation: removeDealItemMutation,
    successMessage: `${t('item_deleted_successfully')}!`,
    closeDialogWhenMutationStarts: true,
  })

  const modifyDealItemsWithWarning = useModifyDealItemsWithWarning(deal)

  const hasCustomItem = useMemo(
    () =>
      deal.items.some(
        (item) =>
          item.customDealId && !item.algoliaReference && !item.variantId,
      ),
    [deal.items],
  )

  if (removeDealItemRes.loading) {
    return <Loading />
  }

  const deleteItem = async (itemId: string) => {
    modifyDealItemsWithWarning(() => {
      removeDealItem({
        variables: {
          dealId: deal?._id,
          itemId: itemId,
        },
      })
    })
  }

  const dealItems = deal.items.map((item) => ({
    title: item.title,
    productRef:
      item.algoliaReference ??
      item.variantId ??
      (item.vehicleData ? t('vehicle') : t('custom')),
    id: item._id,
    valuesVerified: item.values.verified ?? undefined,
    valuesClosed: item.values.closed ?? undefined,
    storageLabel: item.storageLabel ?? '',
    storageFacilityId: item.storageFacilityId,
    storageHistory: item.storageHistory,
  }))

  return (
    <ItemOverviewTable
      items={dealItems}
      deleteItem={deleteItem}
      deal={deal}
      refetchDeal={refetchDeal}
      isAddItemVisible={
        !isGuestUser() &&
        isBookedDeal &&
        deal.businessUnit !== EBusinessUnit.Car
      }
      hasCustomItem={hasCustomItem}
    />
  )
}

export default ItemOverview
