import React from 'react'
import { useTranslation } from 'react-i18next'
import Info from '@/components/Info'
import { ItemAnswer, ItemQuestion } from '@/schemaTypes'
import styles from './FeatureQuestionAnswer.module.scss'

export interface FeatureQuestionAnswerProps {
  answer: ItemAnswer
  question?: ItemQuestion
}

export function FeatureQuestionAnswer(props: FeatureQuestionAnswerProps) {
  const { answer, question } = props
  const { t } = useTranslation()

  if (!question) {
    return null
  }

  let label = ''
  let infoText = ''
  let optionsText: string | boolean = ''

  if (
    typeof answer.selectedOptionIndex === 'number' &&
    question.singleChoiceOptions
  ) {
    optionsText =
      question.singleChoiceOptions.some((option) => t(option.infoKey || '')) &&
      question.singleChoiceOptions
        .map((option) => {
          const labelKey = t(option.labelKey || '')
          const infoKey = t(option.infoKey || '')
          const description = infoKey ? ': ' + infoKey : ''
          return (
            '<div style="padding-bottom: 5px"><b>' +
            labelKey +
            '</b>' +
            description +
            '</div>'
          )
        })
        .join('')

    const option = question.singleChoiceOptions[answer.selectedOptionIndex]
    label = t(option?.labelKey || '')
    infoText = t(option?.infoKey || '')
  } else if (typeof answer.rangeValue === 'number' && question.rangeData) {
    label = `${answer.rangeValue} ${t(question.rangeData.unitKey) ?? question.rangeData.unitKey}`
    infoText = question.infoKey ? t(question.infoKey) ?? question.infoKey : ''
  }

  return (
    <tr
      id={`featureQuestionAnswer_${answer.questionId}`}
      className={`${styles.tr}`}
    >
      <td className={`${styles.td}`}>
        {t(question.titleKey)}:{' '}
        {optionsText && (
          <Info
            className={'o-media__fixed u-inline-block'}
            svgClassName={`${styles.infoIcon} u-pl-5 u-pr-5`}
            infoText={optionsText}
          />
        )}
      </td>
      <td>{label}</td>
      <td>
        {infoText && (
          <Info
            className={'o-media__fixed u-inline-block'}
            svgClassName={`${styles.infoIcon} u-pl-5 u-pr-5`}
            infoText={infoText}
          />
        )}
      </td>
    </tr>
  )
}

export default FeatureQuestionAnswer
