import { Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import ItemCategoriesDetails from '@/domains/itemCategories/components/ItemCategoriesDetails'
import {
  useCreateItemCategory,
  useGetItemCategory,
} from '@/domains/itemCategories/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { ItemCategory } from '@/schemaTypes'

export interface ItemCategoriesCreateProps {
  itemCategoryId: string | null
}

export function ItemCategoriesCreate(props: ItemCategoriesCreateProps) {
  const { itemCategoryId } = props
  const { t } = useTranslation()
  const history = useHistory()

  const { itemCategory, queryResult } = useGetItemCategory({
    variables: {
      itemCategoryId: itemCategoryId ?? '',
    },
  })

  const createItemCategory = useMutationShowingErrors({
    mutation: useCreateItemCategory(),
  })

  const onSave = (itemCategory: ItemCategory, storageFeeIds: string[]) => {
    createItemCategory({
      variables: {
        ...itemCategory,
        storageFeeIds,
      },
      onCompleted: () => {
        history.replace(`/inApp/configurations/itemCategories/`)
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={itemCategoryId ?? ''}
        modelName={'itemCategories'}
        mode={'create'}
      />
      <Typography variant="h5" gutterBottom>
        {t('item_categories.item_category_create')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        itemCategory && (
          <ItemCategoriesDetails itemCategory={itemCategory} onSave={onSave} />
        )
      )}
    </Paper>
  )
}

export default ItemCategoriesCreate
