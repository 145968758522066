import {
  ProductAvailablePropertyTextOptions,
  PropertyValue,
} from '../interfaces'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { EProductPropertyType } from '@/schemaTypes'

export const useGetPropertyValueErrorMessages = () => {
  const { t } = useTranslation()

  const callback = useCallback(
    (value: PropertyValue) => {
      const errorMessages: string[] = []
      if (value.error) {
        errorMessages.push(t(value.error))
      }

      if (
        !isNotEmptyPropertyValue(value.propertyDefinition.type, value.value)
      ) {
        errorMessages.push(t('error.invalid_value'))
      }

      return errorMessages.join(', ')
    },
    [t],
  )

  return [callback]
}

export const isNotEmptyPropertyValue = (
  propertyType: EProductPropertyType,
  value: number | boolean | ProductAvailablePropertyTextOptions | null,
) => {
  if (propertyType === EProductPropertyType.Translation) {
    const textValue = value as ProductAvailablePropertyTextOptions
    return textValue.de && textValue.en
  }

  return value !== null
}
