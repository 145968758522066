import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetParentCategoriesArgs } from '@/schemaTypes'

const GET_PARENT_CATEGORIES = gql`
  query getParentCategories($itemCategoryId: ObjectId!) {
    getParentCategories(itemCategoryId: $itemCategoryId) {
      _id
      name
      parentId
      createdAt
      updatedAt
      slug
      helpLink
    }
  }
`

type TData = { getParentCategories: Query['getParentCategories'] }
type TVariables = QueryGetParentCategoriesArgs

export function useGetParentCategories(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_PARENT_CATEGORIES, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    parentCategories: queryResult.data?.getParentCategories,
  }
}
