import { Paper, Typography } from '@material-ui/core'
import omit from 'lodash/omit'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import ResponseTemplateDetails from '@/domains/responseTemplates/components/ResponseTemplateDetails'
import { useCreateResponseTemplate } from '@/domains/responseTemplates/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { ResponseTemplate } from '@/schemaTypes'

export function ResponseTemplateCreate() {
  const { t } = useTranslation()
  const history = useHistory()

  const createResponseTemplate = useMutationShowingErrors({
    mutation: useCreateResponseTemplate(),
    successMessage: t('response_template_created'),
  })

  const onSave = (responseTemplate: ResponseTemplate) => {
    createResponseTemplate({
      variables: {
        responseTemplate: omit(responseTemplate, '_id'),
      },
      onCompleted: () => {
        history.replace('/inApp/configurations/responseTemplates')
      },
    })
  }

  const onClose = () => {
    history.replace(`/inApp/configurations/responseTemplates`)
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelName={'responseTemplates'}
        mode={'create'}
      />
      <Typography variant="h5" gutterBottom>
        {t('response_template_create')}
      </Typography>
      <ResponseTemplateDetails onSave={onSave} onClose={onClose} />
    </Paper>
  )
}

export default ResponseTemplateCreate
