import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateResponseTemplateArgs } from '@/schemaTypes'

const UPDATE_RESPONSE_TEMPLATE = gql`
  mutation updateResponseTemplate(
    $responseTemplate: ResponseTemplateUpdateArgs!
  ) {
    updateResponseTemplate(responseTemplate: $responseTemplate) {
      _id
      subject
      openingText
      editableText
      closingText
    }
  }
`

type TData = { updateResponseTemplate: Mutation['updateResponseTemplate'] }
type TVariables = MutationUpdateResponseTemplateArgs

export function useUpdateResponseTemplate(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateResponseTemplate] = useMutation<TData, TVariables>(
    UPDATE_RESPONSE_TEMPLATE,
    opts,
  )

  return updateResponseTemplate
}
