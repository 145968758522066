import { ItemsFilters } from '.'
import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectOptionInput from '@/components/SelectOptionInput'
import {
  EDealItemCondition,
  EUsedGrade,
  ItemsFilterOpticalCondition,
} from '@/schemaTypes'

export interface OpticalConditionFilterInputProps {
  opticalCondition?: ItemsFilterOpticalCondition | null
  setItemFilter: React.Dispatch<React.SetStateAction<ItemsFilters>>
  defaultItemFilter: Pick<ItemsFilters, 'companyId'>
}

export default function OpticalConditionFilterInput({
  opticalCondition,
  setItemFilter,
  defaultItemFilter,
}: OpticalConditionFilterInputProps) {
  const { t } = useTranslation()

  return (
    <Box display="flex" justifyContent="start">
      <SelectOptionInput
        selectProps={{ style: { minWidth: 100 } }}
        label={
          <Typography variant="body1" style={{ minWidth: 107 }}>
            {t('condition')}
          </Typography>
        }
        value={opticalCondition?.conditionAnswer}
        onChange={(value) => {
          setItemFilter((prev) => ({
            ...prev,
            opticalCondition: {
              ...(prev.opticalCondition ?? {}),
              conditionAnswer: value,
            },
            ...defaultItemFilter,
          }))
        }}
        showResetButton
        className="u-flex u-ai-center"
        onReset={() => {
          setItemFilter((prev) => ({
            ...prev,
            opticalCondition: null,
            ...defaultItemFilter,
          }))
        }}
        menuItems={Object.values(EDealItemCondition).map((condition) => ({
          label: t(condition),
          value: condition,
        }))}
      />
      {opticalCondition?.conditionAnswer === EDealItemCondition.Used && (
        <SelectOptionInput
          selectProps={{ style: { minWidth: 100 } }}
          label={
            <Typography variant="body1" style={{ minWidth: 70, marginTop: 5 }}>
              {t('used_grade')}
            </Typography>
          }
          style={{
            marginLeft: 10,
          }}
          value={opticalCondition?.usedGrade}
          onChange={(value) => {
            setItemFilter((prev) => ({
              ...prev,
              opticalCondition: {
                conditionAnswer: prev.opticalCondition?.conditionAnswer,
                usedGrade: value,
              },
              ...defaultItemFilter,
            }))
          }}
          showResetButton
          className="u-flex u-ai-center"
          onReset={() => {
            setItemFilter((prev) => ({
              ...prev,
              opticalCondition: {
                conditionAnswer: prev.opticalCondition?.conditionAnswer,
                usedGrade: null,
              },
              ...defaultItemFilter,
            }))
          }}
          menuItems={Object.values(EUsedGrade).map((condition) => ({
            label: t(condition),
            value: condition,
          }))}
        />
      )}
    </Box>
  )
}
