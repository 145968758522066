import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import {
  Mutation,
  MutationUpdateItemCategorizedUploadedFilesArgs,
} from '@/schemaTypes'

const UPDATE_ITEM_UPLOADED_FILES = gql`
  mutation updateItemCategorizedUploadedFiles(
    $itemId: ObjectId!
    $fileCategoryName: String!
    $uploadedFilesArgs: [FileTusMetadataArgs!]!
  ) {
    updateItemCategorizedUploadedFiles(
      itemId: $itemId
      fileCategoryName: $fileCategoryName
      uploadedFilesArgs: $uploadedFilesArgs
    ) {
      _id
      categorizedUploadedFiles {
        fileCategoryName
        uploadedFiles {
          ...FileMetadataFragment
        }
      }
    }
  }
  ${fragments.fileMetadata}
`

type TData = {
  updateItemCategorizedUploadedFiles: Mutation['updateItemCategorizedUploadedFiles']
}
type TVariables = MutationUpdateItemCategorizedUploadedFilesArgs

export function useUpdateItemCategorizedUploadedFiles(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateItemCategorizedUploadedFiles] = useMutation<TData, TVariables>(
    UPDATE_ITEM_UPLOADED_FILES,
    opts,
  )

  return updateItemCategorizedUploadedFiles
}
