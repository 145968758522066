import { useGetDealById } from '../../../deals/hooks'
import { Grid, Paper } from '@material-ui/core'
import { FunctionComponent } from 'react'
import { EBusinessUnit, Scalars } from '@/schemaTypes'
import { assertDealConnectedToRegisteredCustomer } from '@/utils/deal'
import CustomerInfo from './CustomerInfo'

export interface PrivateSaleDetailsCustomerOverViewProps {
  dealId: Scalars['ObjectId']
}

const PrivateSaleDetailsCustomerOverView: FunctionComponent<
  PrivateSaleDetailsCustomerOverViewProps
> = ({ dealId }) => {
  const { deal } = useGetDealById({
    variables: {
      dealId: dealId,
    },
    skip: !dealId,
  })

  if (!deal) return null

  assertDealConnectedToRegisteredCustomer(deal)

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <CustomerInfo
            customer={deal.customer}
            isMainCustomer={true}
            canEditCustomer={false}
          />
        </Grid>
        {deal.businessUnit === EBusinessUnit.Car &&
          deal.secondaryCustomers &&
          deal.secondaryCustomers.length > 0 &&
          deal.secondaryCustomers.map((secondaryCustomer) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <CustomerInfo
                  customer={secondaryCustomer}
                  isMainCustomer={true}
                  canEditCustomer={false}
                />
              </Grid>
            )
          })}
      </Grid>
    </Paper>
  )
}

export default PrivateSaleDetailsCustomerOverView
