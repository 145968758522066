import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateResponseTemplateArgs } from '@/schemaTypes'

const CREATE_RESPONSE_TEMPLATE = gql`
  mutation createResponseTemplate(
    $responseTemplate: ResponseTemplateCreateArgs!
  ) {
    createResponseTemplate(responseTemplate: $responseTemplate) {
      _id
      subject
      openingText
      editableText
      closingText
    }
  }
`

type TData = { createResponseTemplate: Mutation['createResponseTemplate'] }
type TVariables = MutationCreateResponseTemplateArgs

export function useCreateResponseTemplate(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createResponseTemplate] = useMutation<TData, TVariables>(
    CREATE_RESPONSE_TEMPLATE,
    opts,
  )

  return createResponseTemplate
}
