import ConfigurationOverview from '.'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, Switch, useHistory } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import FullScreenLoading from '@/components/FullScreenLoading'
import SentryRoute from '@/components/SentryRoute'
import RefurbedOffers from '@/domains/refurbedOffers'
import { ERoles } from '@/schemaTypes'
import getPagePath from '@/utils/getPagePath'
import CompaniesRoutes from './Companies/companies.routes'
import CustomDealFormsRoutes from './CustomDealForms/customDealForms.routes'
import EmployeesRoutes from './Employees/employees.routes'
import FeesRoutes from './Fees/fees.routes'
import ItemCategoriesRoutes from './ItemCategories/itemCategories.routes'
import ItemQuestionsRoutes from './ItemQuestions/itemQuestions.routes'
import LoanToValuesRoutes from './LoanToValues/loanToValues.routes'
import { Mainternance } from './Maintenance'
import MaterialPricesRoutes from './MaterialPrices/materialPrices.routes'
import ResponseTemplatesRoutes from './ResponseTemplates/responseTemplates.routes'
import ShopsRoutes from './Shops/shops.routes'
import StorageFacilitiesRoutes from './StorageFacilities/storageFacilities.routes'

function ConfigurationsRoutes(
  props: RouteComponentProps & { currentUser: any },
) {
  const history = useHistory()

  useEffect(() => {
    if (
      props.currentUser &&
      !props.currentUser.roles.includes(ERoles.Superadmin)
    ) {
      history.replace('/inApp')
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser])

  return (
    <div>
      {!props.currentUser && <FullScreenLoading />}
      {!!props.currentUser && (
        <Switch>
          <SentryRoute
            path={getPagePath(props, '/loanToValues')}
            component={LoanToValuesRoutes}
          />
          <SentryRoute
            path={getPagePath(props, '/fees')}
            component={FeesRoutes}
          />
          <SentryRoute
            path={getPagePath(props, '/materialPrices')}
            component={MaterialPricesRoutes}
          />
          <SentryRoute
            path={getPagePath(props, '/shops')}
            component={ShopsRoutes}
          />
          <SentryRoute
            path={getPagePath(props, '/companies')}
            component={CompaniesRoutes}
          />
          <SentryRoute
            path={getPagePath(props, '/storageFacilities')}
            component={StorageFacilitiesRoutes}
          />
          <SentryRoute
            path={getPagePath(props, '/employees')}
            component={EmployeesRoutes}
          />
          <SentryRoute
            path={getPagePath(props, '/itemQuestions')}
            component={ItemQuestionsRoutes}
          />
          <SentryRoute
            path={getPagePath(props, '/itemCategories')}
            component={ItemCategoriesRoutes}
          />
          <SentryRoute
            path={getPagePath(props, '/responseTemplates')}
            component={ResponseTemplatesRoutes}
          />
          <SentryRoute
            path={getPagePath(props, '/customDealForms')}
            component={CustomDealFormsRoutes}
          />
          <SentryRoute
            path={getPagePath(props, '/refurbedOffers')}
            component={RefurbedOffers}
          />
          <SentryRoute
            path={getPagePath(props, '/maintenance')}
            component={Mainternance}
          />
          <SentryRoute
            path={getPagePath(props)}
            component={ConfigurationOverview}
          />
        </Switch>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentUser: state.user.current,
})

export default connect(mapStateToProps)(withRouter(ConfigurationsRoutes))
