import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useContext } from 'react'
import { Context } from '@/context'
import { fragments } from '@/hooks/fragments'
import {
  ELanguageCode,
  Mutation,
  MutationSetAuctionItemsBuyersAndGenerateSurchargeCertificateArgs,
} from '@/schemaTypes'
import { auctionFragments } from './auctionFragments'

const SET_AUCTION_ITEMS_BUYERS_AND_GENERATE_SURCHARGE_CERTIFICATE = gql`
  mutation setAuctionItemsBuyersAndGenerateSurchargeCertificate(
    $args: SetAuctionItemsBuyersAndGenerateSurchargeCertificateArgs!
    $languageCode: ELanguageCode!
  ) {
    setAuctionItemsBuyersAndGenerateSurchargeCertificate(args: $args) {
      ...AuctionDetailsFragment
    }
  }
  ${fragments.customDealFormQuestion}
  ${fragments.formAnswers}
  ${auctionFragments.auctionDetails}
  ${fragments.address}
`

type TData = {
  setAuctionItemsBuyersAndGenerateSurchargeCertificate: Mutation['setAuctionItemsBuyersAndGenerateSurchargeCertificate']
}
type TVariables = {
  languageCode?: ELanguageCode
} & MutationSetAuctionItemsBuyersAndGenerateSurchargeCertificateArgs

export function useSetAuctionItemsBuyersAndGenerateSurchargeCertificate(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const { language } = useContext(Context)

  const [setAuctionItemsBuyersAndGenerateSurchargeCertificate] = useMutation<
    TData,
    TVariables
  >(SET_AUCTION_ITEMS_BUYERS_AND_GENERATE_SURCHARGE_CERTIFICATE, {
    ...opts,
    variables: { ...(opts.variables as TVariables), languageCode: language },
  })

  return setAuctionItemsBuyersAndGenerateSurchargeCertificate
}
