import styled from '@emotion/styled'
import classNames from 'classnames'
import { Tooltip } from 'primereact/tooltip'
import React from 'react'

export interface FieldWrapperProps {
  label?: string
  errorText?: string
  warningText?: string
  isInvalid?: boolean
  helperText?: string
  className?: string
  labelClassName?: string
  inputContainerClassName?: string
  wrapperInputContainerClassName?: string
  tooltipText?: string
  tooltipIcon?: string
  required?: boolean
  checked?: boolean
  children?: React.ReactNode
  loading?: boolean
}

const FieldWrapper: React.FC<FieldWrapperProps> = (props) => {
  const {
    label,
    errorText,
    warningText,
    helperText,
    className,
    labelClassName = 'text-lg font-semibold',
    tooltipText,
    tooltipIcon = 'pi pi-info-circle',
    required,
    isInvalid,
    children,
    inputContainerClassName = 'w-full',
    wrapperInputContainerClassName,
    loading = false,
  } = props

  return (
    <div className={className}>
      {label && (
        <Label className={labelClassName} required={required}>
          {label}
        </Label>
      )}
      <div
        className={classNames(
          'flex flex-row items-center',
          wrapperInputContainerClassName,
        )}
      >
        <span
          className={classNames('w-full', { 'p-input-icon-right': loading })}
        >
          {loading && <i className="pi pi-spin pi-spinner" />}
          <div className={classNames('flex flex-col', inputContainerClassName)}>
            {children}
            {errorText && isInvalid && (
              <small className="p-error">{errorText}</small>
            )}
            {warningText && (
              <small className="text-yellow-500">{warningText}</small>
            )}
            {helperText && <small>{helperText}</small>}
          </div>
        </span>
        {tooltipText && (
          <>
            <Tooltip target=".custom-target-tooltip-icon" />
            <i
              className={`custom-target-tooltip-icon ${tooltipIcon} p-text-secondary text-sm cursor-pointer ml-2.5 mr-2.5`}
              data-pr-tooltip={tooltipText}
            />
          </>
        )}
      </div>
    </div>
  )
}

export const Label = styled.label<{ required?: boolean }>`
  color: var(--global-text-color, #495057);
  font-family: 'Inter';
  font-style: normal;
  ${({ required }) =>
    required &&
    `&:after {
      content: ' *'
    }`}
`

export default FieldWrapper
