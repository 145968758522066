import { Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import ItemCategoriesDetails from '@/domains/itemCategories/components/ItemCategoriesDetails'
import {
  useGetItemCategory,
  useUpdateItemCategory,
} from '@/domains/itemCategories/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { ItemCategory } from '@/schemaTypes'

export interface ItemCategoriesEditProps {
  itemCategoryId: string
}

export function ItemCategoriesEdit(props: ItemCategoriesEditProps) {
  const { itemCategoryId } = props
  const { t } = useTranslation()

  const { itemCategory, queryResult } = useGetItemCategory({
    variables: {
      itemCategoryId,
    },
  })

  const updateItemCategory = useMutationShowingErrors({
    mutation: useUpdateItemCategory(),
  })

  const onSave = (itemCategory: ItemCategory, storageFeeIds: string[]) => {
    updateItemCategory({
      variables: {
        ...itemCategory,
        storageFeeIds,
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={itemCategoryId}
        modelName={'item_categories.label'}
        mode={'edit'}
      />
      <Typography variant="h5" gutterBottom>
        {t('item_categories.item_category_edit')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        itemCategory && (
          <ItemCategoriesDetails itemCategory={itemCategory} onSave={onSave} />
        )
      )}
    </Paper>
  )
}

export default ItemCategoriesEdit
