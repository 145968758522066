import {
  VERIFICATION_CHECKLIST_CONTAINER_ID,
  getVerificationChecklist,
} from '../VerificationChecklist/VerificationChecklist'
import styled from '@emotion/styled'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { find } from 'lodash'
import { SplitButton } from 'primereact/splitbutton'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ShopSelect from '@/components/ShopSelect'
import { Context } from '@/context'
import {
  useGeneratePowerOfAttorney,
  useGenerateUserAgreement,
} from '@/domains/deals/hooks'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import { Customer, Deal, EBusinessUnit, ICustomerAddress } from '@/schemaTypes'
import { useAppSelector } from '@/store'
import { openUrl } from '@/utils/openUrl'

interface CarDocumentsProps {
  isDealVerified: boolean
  deal: Deal
  customer: Customer
}

const CarDocuments = ({
  deal,
  isDealVerified,
  customer,
}: CarDocumentsProps) => {
  const { businessUnit, items } = deal
  const { closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()
  const history = useHistory()
  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })
  const [isGeneratingUserAgreementPdf, setGeneratingUserAgreementPdf] =
    useState(false)
  const [isGeneratingAttorneyPdf, setGeneratingAttorneyPdf] = useState(false)
  const defaultShopId = useAppSelector(
    (state) => state.ui.employeeActiveShop,
  )?.id

  const missedDocumentDataFields = checkMissingDocumentData(deal, customer, t)
  const isMissedDocumentData = !!missedDocumentDataFields.length

  const userAgreementDocument = useMemo(
    () =>
      find(deal.pawnData?.userAgreementDocuments, { customerId: customer._id }),
    [deal, customer],
  )
  const powerOfAttorneyDocument = useMemo(
    () =>
      find(deal.pawnData?.powerOfAttorneyDocuments, {
        customerId: customer._id,
      }),
    [deal, customer],
  )

  const generateUserAgreement = useMutationShowingErrors({
    mutation: useGenerateUserAgreement(),
  })

  const generatePowerOfAttorney = useMutationShowingErrors({
    mutation: useGeneratePowerOfAttorney(),
  })

  const onGenerateUserAgreement = async (params: {
    note: string
    uses: string
    shopId: string
  }) => {
    try {
      setGeneratingUserAgreementPdf(true)

      const { note, uses, shopId } = params
      const response = await generateUserAgreement({
        variables: {
          generateUserAgreementArgs: {
            dealId: deal._id,
            customerId: customer._id,
            note,
            uses,
            shopId,
          },
        },
      })
      const url = find(
        response.data?.generateUserAgreement?.pawnData?.userAgreementDocuments,
        { customerId: customer._id },
      )?.fileMetadata?.url
      if (url) {
        openUrl(url)
      }
    } finally {
      setGeneratingUserAgreementPdf(false)
    }
  }

  const onGeneratePowerOfAttorney = async ({ shopId }: { shopId: string }) => {
    try {
      setGeneratingAttorneyPdf(true)

      const response = await generatePowerOfAttorney({
        variables: {
          generatePowerOfAttorneyArgs: {
            dealId: deal._id,
            customerId: customer._id,
            shopId,
          },
        },
      })
      const url = find(
        response.data?.generatePowerOfAttorney?.pawnData
          ?.powerOfAttorneyDocuments,
        { customerId: customer._id },
      )?.fileMetadata?.url
      if (url) {
        openUrl(url)
      }
    } finally {
      setGeneratingAttorneyPdf(false)
    }
  }

  const handleGenerateUserAgreement = () => {
    showConfirmPopup({
      state: {
        shopId: '',
        note: '',
        uses: '',
        disableRightButton: true,
      },
      title: t('select_shop_for_car_document'),
      preventAutoCloseByKey: true,
      component: (state, setState) => {
        const isSubmitValid = (shopId: string, usedFor: string) => {
          return !Boolean(shopId) || !Boolean(usedFor)
        }

        return (
          <div>
            <ShopSelect
              companyId={deal.companyId}
              shopId={state.shopId}
              onChange={(value) =>
                setState({
                  ...state,
                  shopId: value,
                  disableRightButton: isSubmitValid(value, state.uses),
                })
              }
              useDefaultShopId
              includeDisabled
              hasAsterisk={true}
            />
            <Typography component="div" className="u-pt-sm" gutterBottom>
              {t('need_for_use_description') + ' :'}
            </Typography>
            <TextareaAutosize
              rowsMin={4}
              onBlur={(e) => setState({ ...state, note: e.target.value })}
              style={{
                width: '100%',
                border: '1px solid rgb(97, 97, 97)',
                borderRadius: '5px',
              }}
            />
            <RadioGroup
              aria-label={t('need_for_use')}
              name="controlled-radio-buttons-group"
              value={state.uses ?? ''}
              onChange={(e, value) =>
                setState({
                  ...state,
                  uses: value,
                  disableRightButton: isSubmitValid(state.shopId, value),
                })
              }
              className="u-mb-sm"
            >
              <FormControlLabel
                value="private"
                control={<Radio />}
                label={t('private_use')}
              />
              <FormControlLabel
                value="business"
                control={<Radio />}
                label={t('business_use')}
              />
            </RadioGroup>
          </div>
        )
      },
      action: async (state) => {
        closeConfirmModal()
        await onGenerateUserAgreement(state)
      },
    })
  }

  const handleGeneratePowerOfAttorney = async () => {
    if (defaultShopId) {
      await onGeneratePowerOfAttorney({ shopId: defaultShopId })
      return
    }

    showConfirmPopup({
      state: {
        shopId: '',
        disableRightButton: !defaultShopId,
      },
      title: t('select_shop_for_car_document'),
      preventAutoCloseByKey: true,
      component: (state, setState) => {
        return (
          <div>
            <ShopSelect
              companyId={deal.companyId}
              shopId={state.shopId}
              onChange={(value) =>
                setState({
                  ...state,
                  shopId: value,
                  disableRightButton: !Boolean(value),
                })
              }
              useDefaultShopId
              includeDisabled
              hasAsterisk={true}
            />
          </div>
        )
      },
      action: async (state) => {
        closeConfirmModal()
        await onGeneratePowerOfAttorney(state)
      },
    })
  }

  const onClickMissedField = (field: {
    link: string
    onClick?: () => void
  }) => {
    if (field.onClick) {
      field.onClick()
    } else {
      history.push(field.link)
    }
  }

  const itemsMenu = [
    businessUnit === EBusinessUnit.Car &&
      items.length > 0 &&
      items[0].isContinueUsing && {
        label: t('generate_user_agreement'),
        command: handleGenerateUserAgreement,
        disabled: isGeneratingUserAgreementPdf || isMissedDocumentData,
      },
    {
      label: t('generate_power_of_attorney'),
      command: handleGeneratePowerOfAttorney,
      disabled: isGeneratingAttorneyPdf || isMissedDocumentData,
    },
  ].filter(Boolean)

  if (businessUnit !== EBusinessUnit.Car) return null

  return (
    <CarDocumentsWrapper>
      {!isDealVerified && (
        <div>
          {missedDocumentDataFields.map((field) => (
            <div className="flex items-center mb-2">
              <ChecklistMark className="flex items-center justify-center mr-2">
                <i
                  className="pi pi-times "
                  style={{ color: '#fff', fontSize: '.55rem' }}
                />
              </ChecklistMark>

              <ChecklistText>
                {t(field.transKey)}{' '}
                <i
                  onClick={() => onClickMissedField(field)}
                  className="pi pi-link ml-2 cursor-pointer"
                />
              </ChecklistText>
            </div>
          ))}
          <SplitButtonStyled
            label="Generate Documents"
            icon="pi pi-file-pdf"
            severity="info"
            onClick={() => {
              console.log('save')
            }}
            model={itemsMenu}
          />
        </div>
      )}
      {userAgreementDocument && (
        <DocumentLink
          href={userAgreementDocument.fileMetadata.url}
          target="_blank"
          rel="noreferrer"
        >
          {
            <span>
              {userAgreementDocument.fileMetadata.filename
                ? `${userAgreementDocument.fileMetadata.filename} (${dayjs(userAgreementDocument.fileMetadata.createdAt).format('L LT')})`
                : t('user_agreement')}
            </span>
          }
        </DocumentLink>
      )}
      {powerOfAttorneyDocument && (
        <DocumentLink
          href={powerOfAttorneyDocument.fileMetadata.url}
          target="_blank"
          rel="noreferrer"
        >
          {
            <span>
              {powerOfAttorneyDocument.fileMetadata.filename
                ? `${powerOfAttorneyDocument.fileMetadata.filename} (${dayjs(powerOfAttorneyDocument.fileMetadata.createdAt).format('L LT')})`
                : t('power_of_attorney')}
            </span>
          }
        </DocumentLink>
      )}
    </CarDocumentsWrapper>
  )
}

const checkMissingRegistrationAddress = (customer: Customer) => {
  const address = customer.addresses?.find(
    (address) => address.isRegistrationAddress,
  )

  const link = `/inApp/customers/edit/${customer._id}`

  if (!address)
    return [
      { isDisplayed: true, transKey: 'address.is_registration_address', link },
    ]

  const requiredAddressFields: {
    name: keyof ICustomerAddress
    transKey: string
    link: string
  }[] = [
    { name: 'street', transKey: 'address.street', link },
    { name: 'houseNumber', transKey: 'address.house_number', link },
    { name: 'zipCode', transKey: 'address.zip_code', link },
    { name: 'city', transKey: 'address.city', link },
  ]

  return requiredAddressFields.map((field) => {
    const fieldName = field.name
    return {
      isDisplayed: !address[fieldName],
      ...field,
    }
  })
}

const checkMissingDocumentData = (
  deal: Deal,
  customer: Customer,
  t: TFunction,
) => {
  const verificationChecklist =
    getVerificationChecklist(deal, t)?.filter((o) => o.isDisplayed) || []

  // hide verification checklist if missing attorney and user agreement documents only
  const checkKeys = verificationChecklist.map((o) => o.key).filter(Boolean)
  const missingUserAggrementDocument = checkKeys.includes(
    'missing_user_aggrement_document',
  )
  const missingAttorneyDocument = checkKeys.includes(
    'missing_attorney_document',
  )
  let isDisplayed = true

  switch (verificationChecklist.length) {
    case 0:
      // there's no error
      isDisplayed = false
      break
    case 1:
      // there's only one error, and it's either missing attorney or user agreement document
      if (missingUserAggrementDocument || missingAttorneyDocument) {
        isDisplayed = false
      }
      break
    case 2:
      // there are two errors, and they are missing attorney and user agreement documents
      if (missingUserAggrementDocument && missingAttorneyDocument) {
        isDisplayed = false
      }
      break
    default:
      break
  }

  return [
    ...checkMissingRegistrationAddress(customer),
    {
      transKey: 'verification_checklist.verification_checklist',
      isDisplayed,
      link: '',
      onClick: () =>
        document
          .getElementById(VERIFICATION_CHECKLIST_CONTAINER_ID)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' }),
    },
  ].filter((o) => o.isDisplayed)
}

const SplitButtonStyled = styled(SplitButton)`
  &&& {
    .p-splitbutton-defaultbutton {
      border: 1px solid var(--button-borderColor, #3b82f6);
      opacity: 0.7;
      background: var(--button-background, #3b82f6);
      cursor: not-allowed;
      pointer-events: none;
    }
  }
`

const CarDocumentsWrapper = styled.div`
  background: var(--surface-200, #eee);
  display: flex;
  padding: 0.875rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.875rem;
  align-self: stretch;
  width: 25rem;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
`

const DocumentLink = styled.a`
  display: block;
  color: blue;
  cursor: pointer;
  margin-bottom: 4px;
`

const ChecklistMark = styled.div`
  padding: 0.1875rem;
  border-radius: 6.25rem;
  background: var(--global-errorColor, #e24c4c);
  width: 1.1rem;
  height: 1.1rem;
`

const ChecklistText = styled.a`
  color: var(--global-textSecondaryColor, #6c757d);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 0.825rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.875rem; /* 140% */
`

export default CarDocuments
