import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n
  .use(
    resourcesToBackend(
      (language, namespace) =>
        import(`./locales/${language}/${namespace}.json`),
    ),
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['common'],
    defaultNS: 'common',
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'language',
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    lng: localStorage.getItem('language') || 'de',
    fallbackLng: 'de',
    // debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
  })

export default i18n
