interface ForwardPaginationArgs {
  first?: number | null
  after?: string | null
}

interface Paginated<T> {
  edges: Array<{
    cursor: string
    node: T
  }>

  pageInfo: {
    startCursor?: string | null
    endCursor?: string | null

    hasNextPage?: boolean | null
    hasPrevPage?: boolean | null
  }
}

const fetchAllFromPagination = async <TNode>(
  query: (args: ForwardPaginationArgs) => Promise<Paginated<TNode>>,
  pageSize: number,
) => {
  const nodes: TNode[] = []

  let page = await query({ first: pageSize })
  while (page.pageInfo.hasNextPage) {
    nodes.push(...page.edges.map((e) => e.node))
    page = await query({ after: page.pageInfo.endCursor, first: pageSize })
  }
  nodes.push(...page.edges.map((e) => e.node))

  return nodes
}

export default fetchAllFromPagination
