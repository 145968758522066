import gql from 'graphql-tag'
import { dealCalculationFragments } from '@/domains/deals/hooks'
import { paymentFragments } from '@/hooks/fragments'

export const auctionFragments = {
  auctionDetails: gql`
    fragment AuctionDetailsFragment on Auction {
      _id
      createdAt
      creationType
      auctionDate
      shopId
      companyId
      company {
        name
      }
      closeError {
        message
        data
      }
      items {
        itemId
        dealId
        status
        startPrice
        note
        finalPrice
        paymentMethod
        surchargeConfirmationLink {
          url
        }
        buyer {
          ... on AuctionItemAnonyBuyer {
            type
          }
          ... on AuctionItemExistingCustomer {
            type
            customerId
            customer {
              firstname
              lastname
              email
              phone
            }
          }
          ... on AuctionItemNewCustomer {
            type
            firstname
            lastname
            phoneNumber
            email
            cashyAccountCreated
            newCustomerId
            newCustomer {
              firstname
              lastname
            }
            address {
              ...AddressFragment
            }
          }
        }
        buyerName
        item {
          _id
          dealsHistory {
            title
            deal {
              bookingNumber
            }
            dealId
            questions {
              titleKey
              _id
            }
            answers {
              questionId
              rangeValue
              selectedOptionIndex
            }
          }
          status
          storageLabel
          storageFacilityId
          storageFacility {
            name
            storageUnitIds
          }
          formAnswers {
            ...FormAnswerFragment
          }
          storageHistory {
            storageStatus
            storageUnits {
              lvl1
              lvl2
              lvl3
              lvl4
            }
          }
        }
      }
      status
      auctionElements {
        dealId
        bookingNumber
        addedToAuctionAt
        addedToAuctionType
      }
      auctionAnnouncementLink {
        url
        contentType
        filename
        createdAt
      }
      internalAuctionAnnouncementLink {
        url
        contentType
        filename
        createdAt
      }
      withdrawnDeals {
        deal {
          _id
          bookingNumber
          dealFinalValues {
            payoutAmount
          }
          events {
            ... on DealPaybackConfirmedEvent {
              dealCalculation {
                ...DealCalculationFragment
              }
            }
            ... on DealExtensionConfirmedEvent {
              dealCalculation {
                ...DealCalculationFragment
              }
            }
          }
        }
        shop {
          name
        }
        withdrawalDate
        withdrawalReason
        paymentType {
          ...AnyPaymentTypeFragment
        }
      }
    }
    ${paymentFragments.anyPaymentType}
    ${dealCalculationFragments.dealCalculation}
  `,
}
