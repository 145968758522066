import React from 'react'

export interface CustomDealOriginalValueProps {
  value: string
  className?: string
}

export function CustomDealOriginalValue(props: CustomDealOriginalValueProps) {
  const { value, className } = props

  return (
    <span
      className={`o-color--darkGrey u-ml-5 ${className ?? ''}`}
    >{`(${value})`}</span>
  )
}
