import { Route, RouteComponentProps, Switch } from 'react-router'
import NotFound from '@/components/NotFound'
import ProductDetail from '@/domains/productDetail'
import { Products } from '@/redesign/pages/InApp/Products'
import getPagePath from '@/utils/getPagePath'
import { ProductDetails } from './create'

function ProductsRoutes(props: RouteComponentProps) {
  return (
    <Switch>
      <Route path={getPagePath(props)} exact render={() => <Products />} />
      <Route
        path={getPagePath(props, '/create')}
        exact
        render={() => <ProductDetails />}
      />
      <Route
        path={getPagePath(props, '/edit/:productId')}
        exact
        render={(props) => <ProductDetails id={props.match.params.productId} />}
      />
      <Route
        path={getPagePath(props, '/:productId')}
        component={ProductDetail}
      />
      <Route component={NotFound} />
    </Switch>
  )
}

export default ProductsRoutes
