import styled from '@emotion/styled'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Fragment } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '@/components/LoadingSpinner'
import {
  CustomDealItemDataEntry,
  DealItem,
  ECountry,
  EFeeType,
  UsedFeeDefinition,
} from '@/schemaTypes'
import {
  checkIfAllTheFeesOfTypeIsDealLevel,
  getDistinctFeeTypes,
  getItemFeesOfType,
  getItemNameByStorageLabel,
  getTotalFeeAmountOfType,
  getTotalGrossFeeAmountOfType,
} from '@/utils/deal'
import { displayLocalAmount, printDate, printLocalFloat } from '@/utils/misc'

interface UsedFeeDefinitionListViewProps {
  appliedUsedFeeDefinitions: UsedFeeDefinition[]
  calculationDate: Date
  isLoading?: boolean
  items: DealItem[] | CustomDealItemDataEntry[]
  companyCountryCode?: ECountry
  itemRecordsView: boolean
}

const UsedFeeDefinitionListView: FunctionComponent<
  UsedFeeDefinitionListViewProps
> = (props) => {
  const {
    appliedUsedFeeDefinitions,
    isLoading,
    itemRecordsView,
    companyCountryCode,
    calculationDate,
    items,
  } = props
  const { t } = useTranslation()
  const distinctTypes = getDistinctFeeTypes(appliedUsedFeeDefinitions)

  return (
    <Fragment>
      <Paper className="u-p-sm u-12/12 u-mb-sm">
        <Table
          size="small"
          aria-label="a dense table"
          style={{ minWidth: '500px' }}
        >
          <TableHead>
            <TableRow>
              <TableCell>{t('fee_type.label')}</TableCell>
              <TableCell>{t('description')}</TableCell>
              <TableCell>{t('date')}</TableCell>
              <TableCell>{t('gross_amount')}</TableCell>
              <TableCell>{t('ust')}</TableCell>
              <TableCell>{t('net_amount')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {distinctTypes.map((feeType) => {
              const onlyDealLevelFeeTypeAvailable =
                checkIfAllTheFeesOfTypeIsDealLevel(
                  appliedUsedFeeDefinitions,
                  feeType,
                )
              return (
                <TableRow>
                  <TableCell>
                    {feeType === EFeeType.InitialStaggered
                      ? t(
                          `fee_type.fee_type_${feeType}_${companyCountryCode}`.toLowerCase(),
                        )
                      : t(`fee_type.fee_type_${feeType}`.toLowerCase())}

                    {itemRecordsView && !onlyDealLevelFeeTypeAvailable ? (
                      <>
                        {appliedUsedFeeDefinitions.map((itemFees, index) => {
                          const itemFeesOfType = getItemFeesOfType(
                            appliedUsedFeeDefinitions,
                            feeType,
                            itemFees.storageLabel,
                            index,
                          )
                          const itemName = getItemNameByStorageLabel(
                            items,
                            itemFees.storageLabel,
                            index,
                          )

                          return itemFeesOfType.map(() => {
                            return (
                              <StyledSummarizedItemDetail>
                                {itemFees.storageLabel || itemName}
                              </StyledSummarizedItemDetail>
                            )
                          })
                        })}
                      </>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {onlyDealLevelFeeTypeAvailable ? (
                      // We don't care because all the items have the fee we need to show its description and all of them are same each other.
                      appliedUsedFeeDefinitions[0].calculatedFeeDefinitions.filter(
                        (c) => c.storedFeeDefinition.feeType === feeType,
                      )[0].description ?? '-'
                    ) : itemRecordsView ? (
                      <>
                        {t('-')}
                        {appliedUsedFeeDefinitions.map((itemFees, index) => {
                          const itemFeesOfType = getItemFeesOfType(
                            appliedUsedFeeDefinitions,
                            feeType,
                            itemFees.storageLabel,
                            index,
                          )
                          return itemFeesOfType.map((itemFee) => {
                            return (
                              <StyledSummarizedItemDetail>
                                {itemFee.description ?? '-'}
                              </StyledSummarizedItemDetail>
                            )
                          })
                        })}
                      </>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {onlyDealLevelFeeTypeAvailable ? (
                      // We don't care because all the items have the fee we need to show its description and all of them are same each other.
                      appliedUsedFeeDefinitions[0].calculatedFeeDefinitions.filter(
                        (c) => c.storedFeeDefinition.feeType === feeType,
                      )[0].date ? (
                        printDate(
                          appliedUsedFeeDefinitions[0].calculatedFeeDefinitions.filter(
                            (c) => c.storedFeeDefinition.feeType === feeType,
                          )[0].date,
                        )
                      ) : (
                        printDate(calculationDate)
                      )
                    ) : itemRecordsView ? (
                      <>
                        {t('-')}
                        {appliedUsedFeeDefinitions.map((itemFees, index) => {
                          const itemFeesOfType = getItemFeesOfType(
                            appliedUsedFeeDefinitions,
                            feeType,
                            itemFees.storageLabel,
                            index,
                          )

                          return itemFeesOfType.map((itemFee) => {
                            return (
                              <StyledSummarizedItemDetail>
                                {itemFee.date
                                  ? printDate(itemFee.date)
                                  : printDate(calculationDate)}
                              </StyledSummarizedItemDetail>
                            )
                          })
                        })}
                      </>
                    ) : null}
                  </TableCell>

                  <TableCell>
                    {onlyDealLevelFeeTypeAvailable ? (
                      // We don't care because all the items have the fees we need to show its description and all of them are same each other.
                      displayLocalAmount(
                        getTotalGrossFeeAmountOfType(
                          appliedUsedFeeDefinitions,
                          feeType,
                        ),
                      )
                    ) : itemRecordsView ? (
                      <>
                        {t('-')}
                        {appliedUsedFeeDefinitions.map((itemFees, index) => {
                          const itemFeesOfType = getItemFeesOfType(
                            appliedUsedFeeDefinitions,
                            feeType,
                            itemFees.storageLabel,
                            index,
                          )

                          return itemFeesOfType.map((itemFee) => {
                            return (
                              <StyledSummarizedItemDetail>
                                {displayLocalAmount(itemFee.appliedGrossAmount)}
                              </StyledSummarizedItemDetail>
                            )
                          })
                        })}
                      </>
                    ) : null}
                  </TableCell>

                  <TableCell>
                    {onlyDealLevelFeeTypeAvailable ? (
                      // We don't care because all the items have the fees we need to show its description and all of them are same each other.
                      `${printLocalFloat(
                        appliedUsedFeeDefinitions[0].calculatedFeeDefinitions.filter(
                          (c) => c.storedFeeDefinition.feeType === feeType,
                        )[0].ust,
                      )} %`
                    ) : itemRecordsView ? (
                      <>
                        {t('-')}
                        {appliedUsedFeeDefinitions.map((itemFees, index) => {
                          const itemFeesOfType = getItemFeesOfType(
                            appliedUsedFeeDefinitions,
                            feeType,
                            itemFees.storageLabel,
                            index,
                          )

                          return itemFeesOfType.map((itemFee) => {
                            return (
                              <StyledSummarizedItemDetail>{`${printLocalFloat(itemFee.ust)} %`}</StyledSummarizedItemDetail>
                            )
                          })
                        })}
                      </>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {displayLocalAmount(
                      getTotalFeeAmountOfType(
                        appliedUsedFeeDefinitions,
                        feeType,
                      ),
                    )}
                    {itemRecordsView && !onlyDealLevelFeeTypeAvailable ? (
                      <>
                        {appliedUsedFeeDefinitions.map((itemFees, index) => {
                          const itemFeesOfType = getItemFeesOfType(
                            appliedUsedFeeDefinitions,
                            feeType,
                            itemFees.storageLabel,
                            index,
                          )

                          return itemFeesOfType.map((itemFee) => {
                            return (
                              <StyledSummarizedItemDetail>
                                {displayLocalAmount(itemFee.appliedNetAmount)}
                              </StyledSummarizedItemDetail>
                            )
                          })
                        })}
                      </>
                    ) : null}
                  </TableCell>
                </TableRow>
              )
            })}

            <TableRow>
              <TableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                {t('total')}
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  displayLocalAmount(
                    getTotalGrossFeeAmountOfType(appliedUsedFeeDefinitions),
                  )
                )}
              </TableCell>
              <TableCell />
              <TableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  displayLocalAmount(
                    getTotalFeeAmountOfType(appliedUsedFeeDefinitions),
                  )
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  )
}

const StyledSummarizedItemDetail = styled.div`
  margin-top: 0.5rem;
  font-weight: normal;
  font-size: smaller;
  font-style: italic;
  color: rgb(255, 0, 0);
`

export default UsedFeeDefinitionListView
