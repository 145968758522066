import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import styles from '@/domains/loanToValues/components/LoanToValuesConfiguration.module.scss'
import LoanToValuesRow from '@/domains/loanToValues/components/LoanToValuesRow'
import {
  useDeleteLoanToValuesEntry,
  useGetLoanToValuesEntries,
} from '@/domains/loanToValues/hooks'
import { useConfirmDeleteMutationShowingErrors } from '@/hooks'
import { LoanToValuesEntry } from '@/schemaTypes'

const sortByValidFrom = (a: LoanToValuesEntry, b: LoanToValuesEntry) =>
  b.validFrom - a.validFrom

export function LoanToValuesTable() {
  const { t } = useTranslation()

  const { loanToValuesEntries, queryResult } = useGetLoanToValuesEntries()

  const deleteLoantoValues = useConfirmDeleteMutationShowingErrors({
    successMessage: t('loan.loan_to_values_entry_deleted'),
    mutation: useDeleteLoanToValuesEntry(),
  })

  const _handleDeleteClick = async (id: string) => {
    deleteLoantoValues({
      variables: {
        loanToValuesEntryId: id,
      },
      onCompleted: () => {
        queryResult.refetch()
      },
    })
  }

  return (
    <Paper className={`${styles.root} u-mb`}>
      <ConfigurationBreadcrumbs modelName={'loan.loanToValues'} />
      <Typography variant="h5" gutterBottom>
        {t('loan.loan_to_values_configurations')}
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Link to="/inApp/configurations/loanToValues/create">
          <Button className="u-mr-mini" variant="contained">
            {t('loan.loan_to_values_entry_create')}
          </Button>
        </Link>
      </Box>
      {queryResult.loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Table className="u-mb-sm" size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('loan.valid_from_now_on')}</TableCell>
                <TableCell>{t('company.singular')}</TableCell>
                <TableCell align="center">{t('category_values')}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {[...loanToValuesEntries]
                .sort(sortByValidFrom)
                .map((ltvEntry) => (
                  <LoanToValuesRow
                    key={ltvEntry._id}
                    loanToValuesEntry={ltvEntry}
                    deleteLoanToValuesEntry={() =>
                      _handleDeleteClick(ltvEntry._id)
                    }
                    company={ltvEntry.company}
                  />
                ))}
            </TableBody>
          </Table>
        </Fragment>
      )}
    </Paper>
  )
}

export default LoanToValuesTable
