import { Paper, TableHead, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Deal } from '@/schemaTypes'
import { printLocalDate } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'
import styles from './AuctionElementComponent.module.scss'

export interface AuctionElementDealProps {
  deal: Deal
}

const AuctionElementDealOverView: React.FC<AuctionElementDealProps> = ({
  deal,
}) => {
  const { t } = useTranslation()

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Typography variant="h6" gutterBottom className={'u-flex u-jc-sb'}>
        {t('deal_overview')}
      </Typography>
      <div className={`${styles.inlineDivs}`}>
        <Table className={`${styles.auctionDealValuesTable}`} size="small">
          <TableBody>
            <TableRow className={`${styles.auctionDealValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.auctionDealValuesTable}`}
              >
                {t('id')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.auctionDealValuesTable}`}
              >
                {deal._id}
              </TableCell>
            </TableRow>
            <TableRow className={`${styles.auctionDealValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.auctionDealValuesTable}`}
              >
                {t('deal.booking_number')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.auctionDealValuesTable}`}
              >
                {deal.bookingNumber}
              </TableCell>
            </TableRow>
            <TableRow className={`${styles.auctionDealValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.auctionDealValuesTable}`}
              >
                {t('deal.deal_types')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.auctionDealValuesTable}`}
              >
                {deal.dealType}
              </TableCell>
            </TableRow>
            <TableRow className={`${styles.auctionDealValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.auctionDealValuesTable}`}
              >
                {t('enter_shop_name')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.auctionDealValuesTable}`}
              >
                {deal.shop?.name}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={`${styles.inlineDivs}`}>
        <Table className={`${styles.auctionDealValuesTable}`} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('process_step')}</TableCell>
              <TableCell>{t('date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deal.events.map((row) => (
              <TableRow key={row.__typename}>
                <TableCell
                  component="th"
                  scope="row"
                  className={styles.dateOverviewFirstColumn}
                >
                  {row.displayName}
                </TableCell>
                <TableCell>
                  <span>
                    {printLocalDate(row.createdAt, {
                      timezone: defaultTimezone,
                    })}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default AuctionElementDealOverView
