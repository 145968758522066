import { FilterDefinition, FilterPropertyType } from '@/components/FilterForm'
import {
  CustomDealCreateArgs,
  CustomDealFilterArgs,
  CustomDealItemDataEntry,
  EBooleanFilterConditions,
  ECustomItemFoundComparableOffers,
  EDealType,
  EStringFilterConditions,
} from '@/schemaTypes'
import { isVehicle, printLocalFloat } from '@/utils/misc'
import { Field, FilterObject } from './components/definitions'

export type CustomDealItem = Omit<CustomDealItemDataEntry, '_id'> & {
  _id?: string
}

export type CustomDealArgs = Omit<CustomDealCreateArgs, 'items'> & {
  items: CustomDealItem[]
}

export const sumTotalRequestedPayoutAmount = (
  items: CustomDealItem[],
  dealType: EDealType,
) => {
  const sum = items.reduce(
    (total, item) =>
      total +
      (dealType === EDealType.Pawn
        ? item.pawnPayoutAmount
        : item.purchasePayoutAmount),
    0,
  )
  return Number(printLocalFloat(sum))
}

export const checkIsCarPawn = (
  items: Pick<CustomDealItemDataEntry, 'itemCategoryId'>[] | undefined,
) => {
  if (!items) return false

  const isCarPawn = !!items?.some((item) => isVehicle(item.itemCategoryId))

  if (isCarPawn) {
    const isNotAllCarPawn = items.every(
      (item) => !isVehicle(item.itemCategoryId),
    )

    if (isNotAllCarPawn) throw new Error('Car pawn only allows car items')
  }

  return isCarPawn
}

export const buildQueryFieldFilter = (
  filterValues: FilterObject,
  definitions: FilterDefinition<Field>[],
) => {
  const queryFilters: CustomDealFilterArgs = {}

  const definitionsMap = definitions.reduce((result, definition) => {
    result[definition.field] = definition
    return result
  }, {})

  for (const [key, value] of Object.entries(filterValues)) {
    const definition: FilterDefinition<Field> | undefined = definitionsMap[key]

    if (key === 'page') return

    let condition: EStringFilterConditions | EBooleanFilterConditions

    switch (definition?.type) {
      case FilterPropertyType.EnumSearchDefinition:
        condition = EStringFilterConditions.Equals
        break
      case FilterPropertyType.StringSearchDefinition:
        condition = EStringFilterConditions.Contains
        break
      default:
        condition = EBooleanFilterConditions.Equals
        break
    }

    const filterObject = {
      condition,
      value,
    }

    if (key.includes('-')) {
      const [entity, field] = key.split('-')
      if (!queryFilters[entity]) queryFilters[entity] = {}
      queryFilters[entity][field] = filterObject
    } else {
      queryFilters[key] = filterObject
    }
  }

  return queryFilters
}

export const checkIsCustomItem = (
  item: Pick<CustomDealItemDataEntry, 'vehicleData' | 'algoliaReference'>,
) => {
  if (item.vehicleData?.indicataId) return false
  if (item.algoliaReference) return false
  return true
}

export const isCustomItemDataEntry = (
  item: CustomDealItem,
): item is CustomDealItemDataEntry => {
  return '_id' in item
}

export const getMaxComparableOffersCount = (
  foundComparableOffers: ECustomItemFoundComparableOffers,
) =>
  foundComparableOffers === ECustomItemFoundComparableOffers.NoOffersFound
    ? 0
    : foundComparableOffers === ECustomItemFoundComparableOffers.OneOrTwo
      ? 2
      : 3
