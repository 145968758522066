import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import {
  ItemQuestion,
  Query,
  QueryGetItemQuestionByTitleArgs,
} from '@/schemaTypes'

const GET_ITEM_QUESTIONS_BY_TITLE = gql`
  query getItemQuestionByTitle($titleKey: String!, $itemCategoryId: ObjectId) {
    getItemQuestionByTitle(
      titleKey: $titleKey
      itemCategoryId: $itemCategoryId
    ) {
      _id
      validFrom
      validTo
      order
      questionType
      valueMode
      titleKey
      infoKey
      isInternal
      predictionTag
      singleChoiceOptions {
        labelKey
        infoKey
        categoryValues
        conditionValue
        isDefaultValue
      }
      rangeData {
        minValue
        maxValue
        unitPrice
        unitKey
      }
      itemCategoryIds
    }
  }
`

type TData = { getItemQuestionByTitle: Query['getItemQuestionByTitle'] }
type TVariables = QueryGetItemQuestionByTitleArgs

export function useGetItemQuestionByTitle(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResultItemQuestion = useQuery<TData, TVariables>(
    GET_ITEM_QUESTIONS_BY_TITLE,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResultItemQuestion.error)

  return {
    queryResultItemQuestion,
    itemQuestions: queryResultItemQuestion.data?.getItemQuestionByTitle as
      | ItemQuestion
      | undefined,
  }
}
