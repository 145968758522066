import { useDeletePrivateSaleTransaction } from '../../hooks/deletePrivateSaleTransaction'
import { useUpdatePrivateSaleTransaction } from '../../hooks/updatePrivateSaleTransaction'
import { isValidCashbookTransaction } from '../AddPrivateSale/PrivateSaleTransactionsList'
import PrivateSaleTransactionInputDetails from '../Common/PrivateSaleTransactionInputDetails'
import {
  Button,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import omit from 'lodash/omit'
import * as React from 'react'
import { Fragment, useContext, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import { useMutationShowingErrors } from '@/hooks'
import {
  ETransactionType,
  Item,
  PrivateSale,
  PrivateSaleTransaction,
  PrivateSaleTransactionArgs,
} from '@/schemaTypes'
import {
  getDefaultManualPaymentType,
  isManualPaymentType,
  paymentTypeUnion,
  printLocalAmount,
} from '@/utils/misc'
import { printLocalDate } from '@/utils/print'
import { Timezone } from '@/utils/time'
import styles from './PrivateSaleDetails.module.scss'

interface PrivateSaleTransactionDetailsProps {
  privateSale: PrivateSale
  privateSaleTransaction: PrivateSaleTransaction
  timezone: Timezone
  item?: Item
}

const PrivateSaleTransactionDetails: FunctionComponent<
  PrivateSaleTransactionDetailsProps
> = (props) => {
  const { privateSale, privateSaleTransaction, timezone, item } = props
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const [newTransactionArgs, setNewTransactionArgs] =
    useState<PrivateSaleTransactionArgs>(
      createPrivateSaleTransactionArgsFromModel(privateSaleTransaction),
    )
  console.log(
    '🚀 ~ file: PrivateSaleTransactionDetails.tsx:38 ~ newTransactionArgs:',
    newTransactionArgs.transactionFileLink,
  )

  const [saveDisabled, setSaveDisabled] = useState(true)
  const [restoreDisabled, setRestoreDisabled] = useState(true)
  const [deleteDisabled] = useState(
    privateSale.privateSaleTransactions?.length !==
      privateSaleTransaction.sortingOrder,
  )

  useEffect(() => {
    if (
      newTransactionArgs.amount !== privateSaleTransaction.amount ||
      (privateSaleTransaction.buyerName &&
        newTransactionArgs.buyerName !== privateSaleTransaction.buyerName) ||
      (privateSaleTransaction.costName &&
        newTransactionArgs.costName !== privateSaleTransaction.costName) ||
      newTransactionArgs.date !== privateSaleTransaction.date ||
      newTransactionArgs.description !== privateSaleTransaction.description ||
      (privateSaleTransaction.grossAmount &&
        newTransactionArgs.grossAmount !==
          privateSaleTransaction.grossAmount) ||
      (isManualPaymentType(newTransactionArgs.paymentType) &&
        newTransactionArgs.paymentType !==
          paymentTypeUnion(privateSaleTransaction.paymentType)) ||
      newTransactionArgs.transactionFileLink?.fileId ||
      (!newTransactionArgs.transactionFileLink &&
        privateSaleTransaction.transactionFileLink) ||
      newTransactionArgs.transactionType !==
        privateSaleTransaction.transactionType ||
      newTransactionArgs.shopId !== privateSaleTransaction.shopId
    ) {
      setSaveDisabled(false)
      setRestoreDisabled(false)
    } else {
      setSaveDisabled(true)
      setRestoreDisabled(true)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTransactionArgs])

  const onRestoreButtonClick = () => {
    setNewTransactionArgs(
      createPrivateSaleTransactionArgsFromModel(privateSaleTransaction),
    )
    setOpen(!open)
  }

  const updateTrasnaction = useMutationShowingErrors({
    mutation: useUpdatePrivateSaleTransaction(),
    successMessage: `${t('private_sale_updated_successfully')}!`,
  })

  const createUpdateArgsFromTransaction = () => {
    const args: PrivateSaleTransactionArgs = {
      amount: newTransactionArgs.amount,
      date: newTransactionArgs.date,
      paymentType: newTransactionArgs.paymentType,
      transactionType: newTransactionArgs.transactionType,
      description: newTransactionArgs.description,
      buyerName: newTransactionArgs.buyerName,
      costName: newTransactionArgs.costName,
      grossAmount: newTransactionArgs.grossAmount,
      ust: newTransactionArgs.ust,
      transactionFileLink: newTransactionArgs.transactionFileLink,
      order: privateSaleTransaction.order,
      regenerateBill: newTransactionArgs.regenerateBill,
      shopId: newTransactionArgs.shopId,
    }

    return args
  }

  const onUpdateButtonClick = () => {
    if (
      newTransactionArgs.transactionType === ETransactionType.SellingCar &&
      (!newTransactionArgs.buyerName ||
        newTransactionArgs.buyerName.length <= 0)
    ) {
      return showInfo(t('buyer_name_is_not_set'))
    } else if (
      isValidCashbookTransaction(newTransactionArgs) &&
      !newTransactionArgs.shopId
    ) {
      return showInfo(t('must_select_cashbook_for_private_sale_transaction'))
    } else {
      updateTrasnaction({
        variables: {
          privateSaleId: privateSale._id,
          args: createUpdateArgsFromTransaction(),
          transactionOrder: privateSaleTransaction.order,
        },
      })
      setOpen(!open)
    }
  }

  const deleteTrasnaction = useMutationShowingErrors({
    mutation: useDeletePrivateSaleTransaction(),
    successMessage: `${t('private_sale_deleted_successfully')}!`,
  })

  const onDeleteButtonClick = () => {
    deleteTrasnaction({
      variables: {
        privateSaleId: privateSale._id,
        transactionOrder: privateSaleTransaction.order,
      },
    })
    setOpen(!open)
  }

  return (
    <Fragment>
      <TableRow>
        <TableCell component="th" scope="row">
          {privateSaleTransaction.sortingOrder}
        </TableCell>
        <TableCell align="left">
          {printLocalDate(privateSaleTransaction.date, { timezone: timezone })}
        </TableCell>
        <TableCell align="left">
          {privateSaleTransaction.employeeName ?? '-'}
        </TableCell>
        <TableCell align="left">
          {t(paymentTypeUnion(privateSaleTransaction.paymentType))}
        </TableCell>
        <TableCell align="left">
          {privateSaleTransaction.transactionType}
        </TableCell>
        <TableCell align="left">
          {privateSaleTransaction.grossAmount ? (
            <div>
              <p>{printLocalAmount(privateSaleTransaction.grossAmount)}</p>
              <p
                style={{ color: 'gray', fontSize: 'small' }}
              >{`(${printLocalAmount(privateSaleTransaction.amount)})`}</p>
            </div>
          ) : (
            printLocalAmount(privateSaleTransaction.amount)
          )}
        </TableCell>
        <TableCell align="left">
          {(privateSaleTransaction.balance &&
          privateSaleTransaction.balance < 0.01 &&
          privateSaleTransaction.balance > -0.01
            ? `     (${privateSaleTransaction.balance}) ~ `
            : '') + printLocalAmount(privateSaleTransaction.balance)}
        </TableCell>
        <TableCell align="left">
          {privateSaleTransaction.cashFlowId ? (
            <div className={`${styles.attachedIcon}`}>
              <EuroSymbolIcon />
            </div>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell align="left">
          {privateSaleTransaction.transactionFileLink ? (
            <div className={`${styles.attachedIcon}`}>
              <AttachFileIcon />
            </div>
          ) : (
            ''
          )}
        </TableCell>

        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className={`${styles.editTable}`}>
              <PrivateSaleTransactionInputDetails
                privateSaleTransactionArgs={newTransactionArgs}
                setPrivateSaleTransactionArgs={setNewTransactionArgs}
                privateSale={privateSale}
                isPrivateSaleCreated={true}
                isEditMode={true}
                item={item}
              />
              <table>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>
                    <Button
                      className="u-mh-mini u-mt-20 u-mb-20"
                      variant="contained"
                      onClick={onUpdateButtonClick}
                      disabled={saveDisabled}
                    >
                      {t('update')}
                    </Button>
                    <Button
                      className="u-mh-mini u-mt-20 u-mb-20"
                      variant="contained"
                      onClick={onRestoreButtonClick}
                      disabled={restoreDisabled}
                    >
                      {t('restore')}
                    </Button>
                    <Button
                      className="u-mh-mini u-mt-20 u-mb-20"
                      variant="contained"
                      onClick={onDeleteButtonClick}
                      disabled={deleteDisabled}
                    >
                      {t('delete')}
                    </Button>
                  </td>
                </tr>
              </table>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

function createPrivateSaleTransactionArgsFromModel(
  privateSaleTransaction: PrivateSaleTransaction,
) {
  const transactionArgs: PrivateSaleTransactionArgs = {
    ...omit(privateSaleTransaction, 'paymentType'),
    paymentType: isManualPaymentType(
      paymentTypeUnion(privateSaleTransaction.paymentType),
    )
      ? getDefaultManualPaymentType(privateSaleTransaction.paymentType)
      : undefined,
  }

  return transactionArgs
}

export default PrivateSaleTransactionDetails
