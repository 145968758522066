import styled from '@emotion/styled'
import { Box } from '@material-ui/core'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import HighlightOff from '@material-ui/icons/HighlightOff'
import dayjs from 'dayjs'
import { isArray, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { EFieldTypes, Row } from '@/hooks/useCustomerMerging'
import { FileMetadata } from '@/schemaTypes'

type Props = Row & {
  onSelectLeftValue?: () => void
  onSelectRightValue?: () => void
  onRemoveMergedValue?: () => void
}

export const MergeCustomerRow = ({
  label,
  firstValue,
  secondValue,
  mergedValue,
  onSelectLeftValue,
  onSelectRightValue,
  onRemoveMergedValue,
  fieldType,
}: Props) => {
  const showRemoveButton = useMemo(() => {
    if (fieldType === EFieldTypes.fileList || fieldType === EFieldTypes.file) {
      return (
        !isEmpty(mergedValue) && !isEmpty(firstValue) && !isEmpty(secondValue)
      )
    }

    return (
      mergedValue && firstValue && secondValue && secondValue !== firstValue
    )
  }, [mergedValue, firstValue, secondValue, fieldType])

  const showMergeButton = useMemo(() => {
    if (fieldType === EFieldTypes.fileList) {
      return (
        !isEmpty(firstValue) && !isEmpty(secondValue) && isEmpty(mergedValue)
      )
    }

    return (
      firstValue && secondValue && firstValue !== secondValue && !mergedValue
    )
  }, [firstValue, secondValue, mergedValue, fieldType])

  const renderValue = (value: any) => {
    let files: FileMetadata[] = []
    switch (fieldType) {
      case EFieldTypes.file:
      case EFieldTypes.fileList:
        if (!value) {
          return <></>
        }

        files = isArray(value) ? value : [value]

        return (
          <>
            {files.map((f) => {
              return (
                <FileLink href={(f as FileMetadata).url} target="_blank">
                  {(f as FileMetadata).filename || '_no_name_'}
                </FileLink>
              )
            })}
          </>
        )
      case EFieldTypes.date:
        return value ? dayjs(value).format('DD/MM/YYYY') : ''
      default:
        return value
    }
  }
  return (
    <Container>
      <Part flex={2}>
        <b>{label}</b>
      </Part>

      <UnderlinedPart flex={1}>{renderValue(firstValue)}</UnderlinedPart>

      <Part flex={0.3} justifyContent="center" alignItems="center">
        {showMergeButton && <ArrowButton onClick={onSelectLeftValue} />}
      </Part>

      <MergedPart flex={1}>
        {renderValue(mergedValue)}{' '}
        {showRemoveButton && <RemoveIcon onClick={onRemoveMergedValue} />}
      </MergedPart>

      <Part flex={0.3} justifyContent="center" alignItems="center">
        {showMergeButton && (
          <ArrowButton
            onClick={onSelectRightValue}
            style={{ rotate: '180deg' }}
          />
        )}
      </Part>

      <UnderlinedPart flex={1}>{renderValue(secondValue)}</UnderlinedPart>
    </Container>
  )
}

const Container = styled(Box)`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

const Part = styled(Box)<{ flex?: number }>`
  display: flex;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  height: 25px;
  line-height: 25px;
  color: #b8b8b8;
  font-size: 12px;
  word-break: break-all;
  overflow: hidden;
`

const UnderlinedPart = styled(Part)`
  border-bottom: solid 1px #e8e8e8;
`

const MergedPart = styled(Part)`
  color: black;
  border-bottom: solid 1px #000000;
  position: relative;
  display: flex;
  flex-direction: column;
`

const ArrowButton = styled(ArrowRightAlt)`
  color: green;
  cursor: pointer;
  &:hover {
    color: #683ab7c0;
  }
`

const FileLink = styled.a`
  margin-right: 10px;
  text-decoration: underline;
`

const RemoveIcon = styled(HighlightOff)`
  position: absolute;
  right: 0;
  top: 0;
  color: red;
  cursor: pointer;
  &:hover {
    color: #ff8383;
  }
`
