import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationAddCustomDealItemArgs } from '@/schemaTypes'

const ADD_NEW_ITEM = gql`
  mutation AddCustomDealItem(
    $customDealId: ObjectId!
    $customDealItemCreateArgs: CustomDealItemCreateArgs!
  ) {
    addCustomDealItem(
      customDealId: $customDealId
      customDealItemCreateArgs: $customDealItemCreateArgs
    ) {
      _id
    }
  }
`

type TData = { addCustomDealItem: Mutation['addCustomDealItem'] }
type TVariables = MutationAddCustomDealItemArgs

export function useAddNewItem(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [addCustomDealItem] = useMutation<TData, TVariables>(ADD_NEW_ITEM, {
    refetchQueries: ['getCustomDealById'],
    ...opts,
  })
  return addCustomDealItem
}
