import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetCustomDealByIdArgs } from '@/schemaTypes'

const GET_CUSTOM_DEAL_FOR_DEAL_DETAIL = gql`
  query getCustomDealForDealDetail($customDealId: ObjectId!) {
    getCustomDealById(customDealId: $customDealId) {
      _id
      acceptedBy {
        firstname
        lastname
      }
    }
  }
`

type TData = { getCustomDealById: Query['getCustomDealById'] }
type TVariables = QueryGetCustomDealByIdArgs

export function useGetCustomDealForDealDetail(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_CUSTOM_DEAL_FOR_DEAL_DETAIL,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return { queryResult, customDeal: queryResult.data?.getCustomDealById }
}
