import { useMutation, useQuery } from '@apollo/client'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages'
import { Panel } from 'primereact/panel'
import { ProgressSpinner } from 'primereact/progressspinner'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactJson from 'react-json-view'
import Loading from '@/components/Loading'
import { graphql } from '@/gql'
import { useMutationShowingErrors, useParseErrors } from '@/hooks'
import Input from '@/redesign/components/Input/Input'
import InputDropdown from '@/redesign/components/InputDropdown/InputDropdown'
import { ECashBookPaymentReference, Shop } from '@/schemaTypes'
import { UIError } from '@/types'

interface Ready2OrderProps {
  shop?: Partial<Shop>
  setShop: React.Dispatch<React.SetStateAction<Partial<Shop>>>
  onR2OApiKeySaved: () => void
}

const createOrUpdateReady2OrderApiKeyDocument = graphql(`
  mutation createOrUpdateReady2OrderApiKey(
    $args: CreateOrUpdateReady2OrderApiKeyArgs!
  ) {
    createOrUpdateReady2OrderApiKey(args: $args)
  }
`)

const saveReady2OrderProductMapsDocument = graphql(`
  mutation saveReady2OrderProductMaps($args: SaveReady2OrderProductMapsArgs!) {
    saveReady2OrderProductMaps(args: $args)
  }
`)

const getReady2OrderProductNamesDocument = graphql(`
  query getReady2OrderProductNames($shopId: ObjectId!) {
    getReady2OrderProductNames(shopId: $shopId)
  }
`)

export const Ready2OrderPanel = ({
  shop,
  setShop,
  onR2OApiKeySaved,
}: Ready2OrderProps) => {
  const { t } = useTranslation()

  const [isUpdatingR2OApiKey, setIsUpdatingR2OApiKey] = useState<boolean>(false)
  const [r2oApiKeyUpdated, setR2oApiKeyUpdated] = useState<boolean>(false)
  const [isSavingR2OProductMaps, setIsSavingR2OProductMaps] =
    useState<boolean>(false)

  const [updateR2OApiKeyErrors, setUpdateR2OApiKeyErrors] = useState<UIError[]>(
    [],
  )

  const getReady2OrderProductNamesQueryResult = useQuery(
    getReady2OrderProductNamesDocument,
    {
      fetchPolicy: 'network-only',
      variables: {
        shopId: shop._id,
      },
      skip: !shop?._id || !r2oApiKeyUpdated,
    },
  )

  const [createOrUpdateReady2OrderApiKeyMutation] = useMutation(
    createOrUpdateReady2OrderApiKeyDocument,
  )

  const [saveReady2OrderProductMapsMutation] = useMutation(
    saveReady2OrderProductMapsDocument,
  )

  const parseErrors = useParseErrors()

  const saveReady2OrderProductMaps = useMutationShowingErrors({
    mutation: saveReady2OrderProductMapsMutation,
    successMessage: t('save_ready_2_order_product_maps_successfully'),
  })

  const onSaveR2OApiKeyAndLoadProducts = async (ready2OrderApiKey: string) => {
    if (!shop?._id || !ready2OrderApiKey) return

    setIsUpdatingR2OApiKey(true)

    try {
      await createOrUpdateReady2OrderApiKeyMutation({
        variables: {
          args: {
            shopId: shop._id,
            ready2OrderApiKey,
          },
        },
      })

      await getReady2OrderProductNamesQueryResult.refetch()

      setUpdateR2OApiKeyErrors([])
      setR2oApiKeyUpdated(true)
      setIsUpdatingR2OApiKey(false)
      onR2OApiKeySaved()
    } catch (error) {
      setUpdateR2OApiKeyErrors(parseErrors(error))
      setR2oApiKeyUpdated(false)
      setIsUpdatingR2OApiKey(false)
    }
  }

  const errorMessages = useRef(null)
  const successMessages = useRef(null)

  useEffect(() => {
    if (errorMessages.current) {
      errorMessages.current.clear()
      errorMessages.current.show({
        sticky: true,
        severity: 'error',
        summary: 'Error',
        detail: t('r2o_connection_failed'),
        closable: true,
      })
    }
  }, [t, updateR2OApiKeyErrors])

  const r2oProductNames =
    getReady2OrderProductNamesQueryResult.data?.getReady2OrderProductNames
  const isloadingR2OProductNames = getReady2OrderProductNamesQueryResult.loading

  useEffect(() => {
    if (successMessages.current && r2oApiKeyUpdated && r2oProductNames) {
      successMessages.current.clear()
      successMessages.current.show({
        sticky: true,
        severity: 'success',
        detail: t('r2o_connection_established'),
        closable: true,
      })
    }
  }, [t, r2oApiKeyUpdated, r2oProductNames])

  return (
    <Panel header={t('ready_2_order')} toggleable className="mb-6">
      <Input
        type="text"
        label={t('ready_2_order_api_key')}
        placeholder={
          shop?.ready2OrderApiKeyStored
            ? t('ready_2_order_api_key_is_created')
            : t('ready_2_order_api_key_is_not_created')
        }
        className="flex flex-col md:flex-row items-start md:items-center mb-3"
        labelClassName="text-sm font-semibold w-48"
        value={shop?.ready2OrderApiKey ?? null}
        onChange={(e) =>
          setShop({ ...(shop ?? {}), ready2OrderApiKey: e.target.value })
        }
        inputContainerClassName="w-96"
        disabled={!shop?._id}
      />
      <Button
        onClick={() => onSaveR2OApiKeyAndLoadProducts(shop?.ready2OrderApiKey)}
        severity="info"
        className="h-10"
        disabled={!shop?.ready2OrderApiKey || isUpdatingR2OApiKey}
      >
        {t('connect_to_r2o_button_text')}
      </Button>
      <div className="mt-5">
        {(isUpdatingR2OApiKey || isloadingR2OProductNames) && (
          <ProgressSpinner style={{ width: '2.5rem', height: '2.5rem' }} />
        )}
      </div>
      <div>
        {updateR2OApiKeyErrors.length > 0 ? (
          <>
            <Messages ref={errorMessages} />
            <div>
              {updateR2OApiKeyErrors.map((error) => {
                const descriptions: string[] = []

                if (typeof error.description === 'string') {
                  descriptions.push(error.description)
                } else {
                  descriptions.push(error.description.message)
                }

                return (
                  <div className="u-mb-15">
                    {!!error.data && (
                      <div className="o-type-12 u-mt-15">
                        <ReactJson
                          name={t('error.error_details')}
                          displayDataTypes={false}
                          displayObjectSize={false}
                          src={error}
                          enableClipboard={false}
                        />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <>
            <>
              <Messages
                ref={successMessages}
                onRemove={() => {
                  onR2OApiKeySaved()
                }}
              />
              {r2oProductNames && r2oProductNames.length > 0 && (
                <p className="mt-3">
                  {' '}
                  {t('r2o_products_loaded', {
                    productCount: r2oProductNames.length,
                  })}
                </p>
              )}
            </>
          </>
        )}
      </div>

      {shop?.ready2OrderApiKeyStored && !updateR2OApiKeyErrors.length && (
        <>
          <p className="mt-8 w-2/3">
            {t('r2o_product_maps_intro_text_1')}
            <span
              className="text-blue-600 underline cursor-pointer font-semibold"
              onClick={() => getReady2OrderProductNamesQueryResult.refetch()}
            >
              {t('r2o_product_maps_intro_text_2')}
            </span>
            <span>{t('r2o_product_maps_intro_text_3')}.</span>
          </p>
          {getReady2OrderProductNamesQueryResult.loading ? (
            <Loading />
          ) : (
            <div className="w-1/2 mt-5">
              <div className="flex flex-col md:flex-row items-start md:items-center mb-5">
                <p className="w-64 font-semibold">{t('cashy_payment_type')}</p>
                <p className="w-52 font-semibold">{t('r2o_product')}</p>
              </div>
              {shop.r2oProductMaps?.map(
                ({ paymentReference, productName }, index) => {
                  return (
                    <InputDropdown
                      label={paymentReference}
                      options={
                        getReady2OrderProductNamesQueryResult.data?.getReady2OrderProductNames?.map(
                          (p) => ({
                            label: p,
                            value: p,
                          }),
                        ) ?? []
                      }
                      value={productName}
                      onChange={(e) => {
                        const r2oProductMaps = [...shop.r2oProductMaps]
                        r2oProductMaps[index].productName = e.target.value
                        setShop({ ...(shop ?? {}), r2oProductMaps })
                      }}
                      placeholder={t('select_r2o_product')}
                      className="flex flex-col md:flex-row items-start md:items-center mb-4"
                      labelClassName="text-sm font-semibold w-64"
                      required={requiredPaymentTypes.includes(paymentReference)}
                      inputContainerClassName="w-52"
                      errorText={t('required')}
                      isInvalid={
                        requiredPaymentTypes.includes(paymentReference) &&
                        !productName
                      }
                    />
                  )
                },
              )}

              <Button
                onClick={() => {
                  setIsSavingR2OProductMaps(true)
                  saveReady2OrderProductMaps({
                    variables: {
                      args: {
                        shopId: shop?._id,
                        r2oProductMaps: shop.r2oProductMaps ?? [],
                      },
                    },
                    onCompleted: () => {
                      setIsSavingR2OProductMaps(false)
                      onR2OApiKeySaved()
                    },
                  })
                }}
                severity="info"
                disabled={
                  !shop?.ready2OrderApiKeyStored || isSavingR2OProductMaps
                }
              >
                {t('save_configuration')}
              </Button>
            </div>
          )}
        </>
      )}
    </Panel>
  )
}

const requiredPaymentTypes = [
  'FEES_AND_INTEREST',
  ECashBookPaymentReference.ValorizationSale,
  ECashBookPaymentReference.Payout,
  ECashBookPaymentReference.Payback,
  ECashBookPaymentReference.Extension,
  ECashBookPaymentReference.BankWithdrawal,
  ECashBookPaymentReference.ExtensionPayout,
]
