import { getStoragePlace } from '../../helpers'
import { useSetAuctionItemsBuyersAndGenerateSurchargeCertificate } from '../../hooks/auctionDetails/setAuctionItemsBuyersAndGenerateSurchargeCertificate'
import styled from '@emotion/styled'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import CloseIcon from '@material-ui/icons/Close'
import { compact, flatten, sum } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Spinner from 'react-spinkit'
import TextInput from '@/components/TextInput'
import { Context } from '@/context'
import { useSearchCustomers } from '@/domains/customers/hooks/searchCustomers'
import { useGetStorageUnits } from '@/domains/storageUnits/hooks'
import { useMutationShowingErrors } from '@/hooks'
import ProfileIcon from '@/images/profile.png'
import {
  Auction,
  AuctionItem,
  AuctionItemBuyer,
  AuctionItemBuyerArgs,
  AuctionItemExistingCustomer,
  AuctionItemNewCustomer,
  EAuctionBuyerType,
  EStorageStatus,
} from '@/schemaTypes'
import { EManualPaymentType } from '@/schemaTypes'

export type TAuctionItem = Omit<AuctionItem, 'buyer'> & {
  buyer?: Partial<AuctionItemBuyer> | null
  removedFromStorage?: boolean
}

export interface AuctionBuyerInformationProps {
  auction: Omit<Auction, 'items'> & { items?: Array<TAuctionItem> | null }
  onCompleted: () => void
  selectedItemIds: Array<string>
}

export function AuctionBuyerInformation(props: AuctionBuyerInformationProps) {
  const { auction, onCompleted, selectedItemIds } = props
  const { getCurrentUser } = useContext(Context)

  const [auctionItems, setAuctionItems] = useState<Array<TAuctionItem>>([])

  const { t } = useTranslation()

  const auctionItemBuyer = useMemo(() => auctionItems[0]?.buyer, [auctionItems])

  const paymentMethod = useMemo(
    () => auctionItems[0]?.paymentMethod ?? EManualPaymentType.Cash,
    [auctionItems],
  )

  const [isSettingItemsToDone, setIsSettingItemsToDone] = useState(false)

  useEffect(() => {
    setAuctionItems(
      auction.items?.filter((item) => selectedItemIds.includes(item.itemId)) ??
        [],
    )
  }, [auction.items, selectedItemIds])

  const setAuctionItemsDone = useMutationShowingErrors({
    mutation: useSetAuctionItemsBuyersAndGenerateSurchargeCertificate(),
    successMessage: t('enter_buyer_information_successfully'),
  })

  const { storageUnits } = useGetStorageUnits({
    variables: {
      opts: {
        filter: {
          ids: compact(
            flatten(
              auctionItems.map((auctionItem) => {
                const storageHistory = auctionItem.item?.storageHistory?.[0]
                if (
                  storageHistory?.storageStatus !==
                  EStorageStatus.AddedToStorage
                ) {
                  return []
                }

                return [
                  storageHistory?.storageUnits.lvl1,
                  storageHistory?.storageUnits.lvl2,
                  storageHistory?.storageUnits.lvl3,
                  storageHistory?.storageUnits.lvl4,
                ]
              }),
            ),
          ),
        },
      },
    },
    skip: !auctionItems.length,
  })

  const isValid = useMemo(() => {
    return auctionItems.every((item) => {
      if (!item.buyer || !item.buyer.type) {
        return false
      }

      if (
        item.buyer.__typename === 'AuctionItemExistingCustomer' &&
        !item.buyer.customerId
      ) {
        return false
      }

      if (
        item.buyer.__typename === 'AuctionItemNewCustomer' &&
        (!item.buyer.address ||
          !item.buyer.firstname ||
          !item.buyer.lastname ||
          !item.buyer.email)
      ) {
        return false
      }

      return true
    })
  }, [auctionItems])

  const onSave = () => {
    if (auctionItems.some((item) => !item.buyer)) {
      return
    }

    const currentUser = getCurrentUser()
    setIsSettingItemsToDone(true)

    setAuctionItemsDone({
      variables: {
        args: {
          auctionId: auction._id,
          employeeId: currentUser?._id,
          items: auctionItems.map((item) => {
            if (!item.buyer?.type) {
              throw new Error('Buyer type not found')
            }

            const buyerArgs: AuctionItemBuyerArgs = {
              type: item.buyer.type,
            }

            if (item.buyer?.__typename === 'AuctionItemExistingCustomer') {
              buyerArgs.customerId = item.buyer?.customerId
            }

            if (item.buyer?.__typename === 'AuctionItemNewCustomer') {
              buyerArgs.firstname = item.buyer?.firstname
              buyerArgs.lastname = item.buyer?.lastname
              buyerArgs.email = item.buyer?.email
              buyerArgs.phoneNumber = item.buyer?.phoneNumber
              buyerArgs.address = item.buyer?.address
              buyerArgs.createCashyAccount = item.buyer?.cashyAccountCreated
            }

            return {
              itemId: item.itemId,
              auctionId: auction._id,
              buyer: buyerArgs,
              finalPrice: item.finalPrice,
              paymentMethod: item.paymentMethod ?? EManualPaymentType.Cash,
              shouldBeRemovedFromStorage: item.removedFromStorage,
            }
          }),
        },
      },
      onCompleted: () => {
        setIsSettingItemsToDone(false)
        onCompleted()
      },
      refetchQueries: ['getAuctionById'],
    })
  }

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Typography style={{ fontSize: 22, textAlign: 'center', width: '100%' }}>
        Enter Buyer Information
      </Typography>
      <Box>
        <RadioGroup
          row
          name="controlled-radio-buttons-group"
          value={auctionItemBuyer?.type}
          onChange={(e, value) => {
            setAuctionItems((prev) =>
              prev.map((item) => {
                const newItem = { ...item }
                if (!newItem.buyer) {
                  newItem.buyer = {}
                }

                if (newItem.buyer) {
                  if (value === EAuctionBuyerType.Anonym) {
                    newItem.buyer.__typename = 'AuctionItemAnonyBuyer'
                  }
                  if (value === EAuctionBuyerType.ExistingCustomer) {
                    newItem.buyer.__typename = 'AuctionItemExistingCustomer'
                  }
                  if (value === EAuctionBuyerType.NewCustomer) {
                    newItem.buyer.__typename = 'AuctionItemNewCustomer'
                  }
                  newItem.buyer.type = value as EAuctionBuyerType
                }

                return newItem
              }),
            )
          }}
          className="u-mb-sm"
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: 30,
          }}
        >
          <FormControlLabel
            value={EAuctionBuyerType.Anonym}
            control={<Radio />}
            label={
              <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                {t('anonym')}
              </Typography>
            }
          />
          <FormControlLabel
            value={EAuctionBuyerType.ExistingCustomer}
            control={<Radio />}
            label={
              <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                {t('existing_customer')}
              </Typography>
            }
          />{' '}
          <FormControlLabel
            value={EAuctionBuyerType.NewCustomer}
            control={<Radio />}
            label={
              <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                {t('enter_customer_data')}
              </Typography>
            }
          />
        </RadioGroup>
        <Box>
          {auctionItemBuyer?.__typename === 'AuctionItemExistingCustomer' && (
            <AuctionBuyerInformationExistingCustomer
              auctionItemBuyer={auctionItemBuyer}
              setAuctionItems={setAuctionItems}
            />
          )}
          {auctionItemBuyer?.__typename === 'AuctionItemNewCustomer' && (
            <AuctionBuyerInformationNewCustomer
              auctionItemBuyer={auctionItemBuyer}
              setAuctionItems={setAuctionItems}
            />
          )}
        </Box>
        <Box>
          <Typography style={{ fontSize: 16, fontWeight: 500 }}>
            {t('items_overview')}
          </Typography>
          <ItemsOverviewRow>
            <ItemsOverviewHeaderLabel>
              {t('storage.label')}
            </ItemsOverviewHeaderLabel>
            <ItemsOverviewHeaderLabel>
              {t('item_name')}
            </ItemsOverviewHeaderLabel>
            <ItemsOverviewHeaderLabel>
              {t('storage_facility')}
            </ItemsOverviewHeaderLabel>
            <ItemsOverviewHeaderLabel>
              {t('storage_place')}
            </ItemsOverviewHeaderLabel>
            <ItemsOverviewHeaderLabel>
              {t('remove_from_storage')}
            </ItemsOverviewHeaderLabel>
            <ItemsOverviewHeaderLabel>{t('sold_for')}</ItemsOverviewHeaderLabel>
          </ItemsOverviewRow>
          {auctionItems.map((item) => {
            const hasBeenRemovedFromStorage =
              item.item?.storageHistory?.[0]?.storageStatus ===
              EStorageStatus.RemovedFromStorage

            return (
              <ItemsOverviewRow>
                <ItemsOverviewValue>
                  {item.item?.storageLabel}
                </ItemsOverviewValue>
                <ItemsOverviewValue>
                  {
                    item.item?.dealsHistory?.find(
                      (h) => h.dealId === item.dealId,
                    )?.title
                  }
                </ItemsOverviewValue>
                <ItemsOverviewValue>
                  {item.item?.storageFacility?.name}
                </ItemsOverviewValue>
                <ItemsOverviewValue>
                  {getStoragePlace(item, storageUnits) ?? t('not_available')}
                </ItemsOverviewValue>
                <ItemsOverviewValue>
                  {
                    <Checkbox
                      style={{ padding: 0, marginTop: -4 }}
                      checked={item.removedFromStorage}
                      disabled={hasBeenRemovedFromStorage}
                      onClick={(e) => e.stopPropagation()}
                      onFocus={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        const newItems = [...auctionItems]
                        const updatedItemIndex = newItems.findIndex(
                          (v) => v.itemId === item.itemId,
                        )
                        if (updatedItemIndex !== -1) {
                          newItems[updatedItemIndex].removedFromStorage =
                            e.target.checked
                        }
                        setAuctionItems(newItems)
                      }}
                    />
                  }
                </ItemsOverviewValue>
                <ItemsOverviewValue>{item.finalPrice}€</ItemsOverviewValue>
              </ItemsOverviewRow>
            )
          })}

          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ItemsOverviewValue
              style={{
                borderTop: 'solid 2px black',
                width: '17%',
                fontWeight: 700,
                lineHeight: 3,
              }}
            >
              Total{' '}
              {sum(auctionItems.map((item) => item.finalPrice ?? 0)).toFixed(2)}
              €
            </ItemsOverviewValue>
          </Box>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            <Typography style={{ fontWeight: 500, marginTop: 5 }}>
              {t('payment_method')}:<span style={{ color: 'red' }}>*</span>
            </Typography>
            <Select
              className="u-ml-sm"
              style={{ width: 150 }}
              value={paymentMethod}
              onChange={(e) => {
                const newPaymentMethod = e.target.value as EManualPaymentType
                setAuctionItems((prev) =>
                  prev.map((item) => {
                    const newItem = { ...item }
                    if (!newItem.buyer) {
                      newItem.buyer = {}
                    }
                    newItem.paymentMethod = newPaymentMethod
                    return newItem
                  }),
                )
              }}
            >
              {auctionPaymentMethods.map((name) => (
                <MenuItem key={name} value={name}>
                  {t(name)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <StyledButton onClick={() => onCompleted()} style={{ width: 120 }}>
          {t('cancel')}
        </StyledButton>
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {isSettingItemsToDone && (
            <Spinner
              fadeIn="none"
              name="line-scale"
              color="#5017f4"
              style={{ marginRight: 10 }}
            />
          )}
          <StyledButton
            disabled={!isValid || isSettingItemsToDone}
            onClick={() => onSave()}
            style={{ width: 125, opacity: isValid ? 1 : 0.6 }}
          >
            {t('save')}
          </StyledButton>
        </Box>
      </Box>
    </Paper>
  )
}

export const AuctionBuyerInformationExistingCustomer = ({
  auctionItemBuyer,
  setAuctionItems,
}: {
  auctionItemBuyer: Partial<AuctionItemExistingCustomer>
  setAuctionItems: React.Dispatch<React.SetStateAction<Array<TAuctionItem>>>
}) => {
  const { t } = useTranslation()

  const [textSearch, setTextSearch] = useState<string | null>(null)

  const customersFiltersArgs = useMemo(() => {
    return {
      search: textSearch,
      createdBefore: new Date(),
      paginationArgs: { limit: 1 },
    }
  }, [textSearch])

  const { queryResult } = useSearchCustomers({
    variables: {
      args: customersFiltersArgs,
    },
    skip: !textSearch,
    nextFetchPolicy: 'cache-first',
  })

  const customer = queryResult.data?.searchCustomers?.nodes?.[0]

  useEffect(() => {
    setAuctionItems((prev) =>
      prev.map((item) => {
        const newItem = { ...item }
        newItem.buyer = customer
          ? {
              __typename: 'AuctionItemExistingCustomer',
              type: EAuctionBuyerType.ExistingCustomer,
              customerId: customer._id,
              customer,
            }
          : {
              __typename: 'AuctionItemExistingCustomer',
              type: EAuctionBuyerType.ExistingCustomer,
            }

        return newItem
      }),
    )
  }, [customer, setAuctionItems])

  return (
    <>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <TextInput
          onChange={(value) => setTextSearch(value?.trim())}
          inputProps={{
            style: {
              padding: 5,
              height: 25,
              width: 300,
            },
          }}
          variant={'outlined'}
          label={
            <Typography
              style={{ marginRight: 18, fontSize: 12, fontWeight: 500 }}
            >
              {t('customer.label')}:
            </Typography>
          }
        />
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        {auctionItemBuyer.customer && (
          <Box
            style={{
              padding: 10,
              width: '40%',
              border: '1px solid #B8B8B8',
              position: 'relative',
              borderRadius: 5,
              marginTop: 10,
            }}
          >
            <CloseIcon
              onClick={() =>
                setAuctionItems((prev) =>
                  prev.map((item) => {
                    const newItem = { ...item }
                    newItem.buyer = {
                      __typename: 'AuctionItemExistingCustomer',
                      type: EAuctionBuyerType.ExistingCustomer,
                    }

                    return newItem
                  }),
                )
              }
              style={{
                position: 'absolute',
                top: 5,
                right: 5,
                cursor: 'pointer',
              }}
              fontSize="default"
            />

            <Box style={{ display: 'flex', justifyContent: 'start' }}>
              <Box
                style={{
                  width: 60,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={ProfileIcon} alt="file-review" />
              </Box>
              <Box>
                <Typography style={{ fontWeight: 700, fontSize: 16 }}>
                  {auctionItemBuyer.customer?.firstname}{' '}
                  {auctionItemBuyer.customer?.lastname}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {auctionItemBuyer.customer?.email}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {auctionItemBuyer.customer?.phone}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export const AuctionBuyerInformationNewCustomer = ({
  auctionItemBuyer,
  setAuctionItems,
}: {
  auctionItemBuyer: Partial<AuctionItemNewCustomer>
  setAuctionItems: React.Dispatch<React.SetStateAction<Array<TAuctionItem>>>
}) => {
  const { t } = useTranslation()

  const onChangeAddress = (
    field: 'street' | 'houseNumber' | 'stairway' | 'door' | 'zipCode' | 'city',
    value: string,
  ) =>
    setAuctionItems((prev) =>
      prev.map((item) => {
        const newItem = { ...item }
        if (!newItem.buyer) {
          return newItem
        }

        newItem.buyer.__typename = 'AuctionItemNewCustomer'
        if (newItem.buyer.__typename === 'AuctionItemNewCustomer') {
          if (!newItem.buyer.address) {
            newItem.buyer.address = {}
          }
          newItem.buyer.address[field] = value
        }

        return newItem
      }),
    )

  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Box style={{ width: '40%' }}>
        <TextInput
          errorMessagePosition={'bottom'}
          error={!auctionItemBuyer?.firstname ? t('required') : null}
          inputProps={{ style: { width: 350 } }}
          label={
            <NewCustomerTextInputLabel>
              {t('customer.firstname')}
            </NewCustomerTextInputLabel>
          }
          onChange={(value) => {
            setAuctionItems((prev) =>
              prev.map((item) => {
                const newItem = { ...item }
                if (!newItem.buyer) {
                  return newItem
                }

                newItem.buyer.__typename = 'AuctionItemNewCustomer'
                if (newItem.buyer.__typename === 'AuctionItemNewCustomer') {
                  newItem.buyer.firstname = value
                }

                return newItem
              }),
            )
          }}
        />
        <TextInput
          errorMessagePosition={'bottom'}
          error={!auctionItemBuyer?.lastname ? t('required') : null}
          inputProps={{ style: { width: 350 } }}
          label={
            <NewCustomerTextInputLabel>
              {t('customer.lastname')}
            </NewCustomerTextInputLabel>
          }
          onChange={(value) => {
            setAuctionItems((prev) =>
              prev.map((item) => {
                const newItem = { ...item }
                if (!newItem.buyer) {
                  return newItem
                }

                newItem.buyer.__typename = 'AuctionItemNewCustomer'
                if (newItem.buyer.__typename === 'AuctionItemNewCustomer') {
                  newItem.buyer.lastname = value
                }

                return newItem
              }),
            )
          }}
        />
        <TextInput
          error={!auctionItemBuyer?.email ? t('required') : null}
          errorMessagePosition={'bottom'}
          inputProps={{ style: { width: 350 } }}
          label={
            <NewCustomerTextInputLabel>
              {t('customer.email')}
            </NewCustomerTextInputLabel>
          }
          onChange={(value) => {
            setAuctionItems((prev) =>
              prev.map((item) => {
                const newItem = { ...item }
                if (!newItem.buyer) {
                  return newItem
                }

                newItem.buyer.__typename = 'AuctionItemNewCustomer'
                if (newItem.buyer.__typename === 'AuctionItemNewCustomer') {
                  newItem.buyer.email = value
                }

                return newItem
              }),
            )
          }}
        />
        <TextInput
          error={!auctionItemBuyer?.phoneNumber ? t('required') : null}
          errorMessagePosition={'bottom'}
          inputProps={{ style: { width: 350 } }}
          label={
            <NewCustomerTextInputLabel>
              {t('customer.phone')}
            </NewCustomerTextInputLabel>
          }
          onChange={(value) => {
            setAuctionItems((prev) =>
              prev.map((item) => {
                const newItem = { ...item }
                if (!newItem.buyer) {
                  return newItem
                }

                newItem.buyer.__typename = 'AuctionItemNewCustomer'
                if (newItem.buyer.__typename === 'AuctionItemNewCustomer') {
                  newItem.buyer.phoneNumber = value
                }

                return newItem
              }),
            )
          }}
        />

        <TextInput
          errorMessagePosition={'bottom'}
          style={{ marginTop: 20 }}
          inputProps={{ style: { width: 350 } }}
          label={
            <NewCustomerTextInputLabel>
              {t('address.street')}
            </NewCustomerTextInputLabel>
          }
          onChange={(value) => {
            onChangeAddress('street', value)
          }}
        />
        <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <TextInput
            error={
              !auctionItemBuyer?.address?.houseNumber ? t('required') : null
            }
            errorMessagePosition={'bottom'}
            inputProps={{ style: { width: 83 } }}
            label={
              <NewCustomerTextInputLabel>
                {t('address.house_number')}
              </NewCustomerTextInputLabel>
            }
            onChange={(value) => {
              onChangeAddress('houseNumber', value)
            }}
          />
          <TextInput
            errorMessagePosition={'bottom'}
            inputProps={{ style: { width: 83 } }}
            label={
              <NewCustomerTextInputLabel style={{ marginLeft: 10, width: 30 }}>
                {t('stairs')}
              </NewCustomerTextInputLabel>
            }
            onChange={(value) => {
              onChangeAddress('stairway', value)
            }}
          />
          <TextInput
            errorMessagePosition={'bottom'}
            inputProps={{ style: { width: 83 } }}
            label={
              <NewCustomerTextInputLabel style={{ marginLeft: 10, width: 30 }}>
                {t('address.door')}
              </NewCustomerTextInputLabel>
            }
            onChange={(value) => {
              onChangeAddress('door', value)
            }}
          />
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <TextInput
            errorMessagePosition={'bottom'}
            label={
              <NewCustomerTextInputLabel>
                {t('address.zip_code')}
              </NewCustomerTextInputLabel>
            }
            onChange={(value) => {
              onChangeAddress('zipCode', value)
            }}
          />
          <TextInput
            errorMessagePosition={'bottom'}
            inputProps={{ style: { width: 133 } }}
            label={
              <NewCustomerTextInputLabel style={{ marginLeft: 10, width: 30 }}>
                {t('address.city')}
              </NewCustomerTextInputLabel>
            }
            onChange={(value) => {
              onChangeAddress('city', value)
            }}
          />
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Checkbox
            checked={auctionItemBuyer?.cashyAccountCreated ?? false}
            onClick={(e) => e.stopPropagation()}
            onFocus={(e) => e.stopPropagation()}
            onChange={(e) => {
              setAuctionItems((prev) =>
                prev.map((item) => {
                  const newItem = { ...item }
                  if (!newItem.buyer) {
                    return newItem
                  }

                  newItem.buyer.__typename = 'AuctionItemNewCustomer'
                  if (newItem.buyer.__typename === 'AuctionItemNewCustomer') {
                    newItem.buyer.cashyAccountCreated = e.target.checked
                  }

                  return newItem
                }),
              )
            }}
          />
          {
            <Typography
              style={{ fontSize: 12, fontWeight: 500, marginTop: 12 }}
            >
              {t('create_cashy_account')}
            </Typography>
          }
        </Box>
        <Typography
          style={{ fontSize: 12, textAlign: 'center' }}
          color={'error'}
        >
          {t('create_cashy_account_warning')}
        </Typography>
      </Box>
    </Box>
  )
}

export const NewCustomerTextInputLabel = styled(Typography)`
  width: 120px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 12px;
`

export const ItemsOverviewRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`

export const ItemsOverviewHeaderLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  width: 23%;
`

export const ItemsOverviewValue = styled(Typography)`
  font-size: 14px;
  text-align: left;
  width: 23%;
`

const StyledButton = styled(Button)`
  &&& {
    background: #6750a4;
    height: 30px;
    text-transform: none;
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    font-size: 14px !important;
    color: #ffffff !important;
  }
`

export const auctionPaymentMethods = [
  EManualPaymentType.Card,
  EManualPaymentType.Cash,
  EManualPaymentType.Bank,
  EManualPaymentType.Paypal,
]
