export const DAYS_PER_HALF_MONTH = 15
export const DAYS_PER_MONTH = DAYS_PER_HALF_MONTH * 2
export const BILLING_PERIOD = {
  ONE_TIME: { type: 'ONE_TIME', days: 0 },
  DAY: { type: 'DAY', days: 1 },
  HALF_MONTH: { type: 'HALF_MONTH', days: DAYS_PER_HALF_MONTH },
  MONTH: { type: 'MONTH', days: DAYS_PER_MONTH },
}
export const FEE_MODE = {
  CONSTANT: 'CONSTANT',
  MULTIPLIER: 'MULTIPLIER',
}

export const REFURBED_SOLD_OVER_TIME = 21
