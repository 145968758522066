import styled from '@emotion/styled'
import { FormikProps } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OCustomerAddress } from '@/schemaTypes'
import { constructCustomerAddress } from '@/utils/address'
import AddressFormCard from './AddressFormCard'
import AddressOverviewCard from './AddressOverviewCard'

interface CustomerAdressViewProps {
  remove: (index: number) => void
  push: (address: OCustomerAddress) => void
  countryOptions: { label: string; value: string }[]
  maxAddresses?: number
  form: FormikProps<{
    addresses: OCustomerAddress[]
  }>
}

const CustomerAdressView = ({
  remove,
  push,
  countryOptions,
  maxAddresses,
  form,
}: CustomerAdressViewProps) => {
  const { t } = useTranslation()
  const [addressIndex, setAddressIndex] = useState<number | null>(null)
  const [editAddressActive, setEditAddressActive] = useState(false)

  const { setFieldTouched, setFieldValue, values, isValid, dirty } = form

  const maxAddressesExceeded =
    maxAddresses && values?.addresses?.length >= maxAddresses

  const updateAddressType = (name: string, index: number) => {
    if (name.includes('isBillingAddress')) {
      setFieldValue(`addresses[${index}].isBillingAddress`, false)
    } else if (name.includes('isDeliveryAddress')) {
      setFieldValue(`addresses[${index}].isDeliveryAddress`, false)
    } else {
      setFieldValue(`addresses[${index}].isRegistrationAddress`, false)
    }
  }

  const handleChangeAddressType = useCallback(
    (target: { name: string; value: boolean }) => {
      values?.addresses?.forEach((_, index) => {
        updateAddressType(target.name, index)
      })

      setFieldValue(target.name, target.value)
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values?.addresses, setFieldValue],
  )

  // This method is for hide Address form and display added address preview
  const handleSave = useCallback(() => {
    if (!isValid) {
      setFieldTouched('addresses', true)
    } else {
      setAddressIndex(null)
      setEditAddressActive(false)
    }
  }, [isValid, setFieldTouched])

  const handleClose = useCallback(
    (index) => {
      if (!editAddressActive) {
        remove(index)
      }

      setAddressIndex(null)
      setEditAddressActive(false)
    },
    [editAddressActive, remove],
  )

  useEffect(() => {
    if (!dirty) {
      setAddressIndex(null)
    }
  }, [dirty])

  return (
    <div className="flex flex-wrap">
      {values?.addresses?.length > 0 &&
        values?.addresses.map((address, index) => {
          return (
            <div key={index}>
              {addressIndex === index ? (
                <AddressFormCard
                  address={address}
                  index={index}
                  isEditAddress={editAddressActive}
                  countryOptions={countryOptions}
                  onChangeAddressType={handleChangeAddressType}
                  onSave={handleSave}
                  onClose={() => handleClose(index)}
                  setFieldValue={setFieldValue}
                />
              ) : (
                <AddressOverviewCard
                  address={address}
                  index={index}
                  addressIndex={addressIndex}
                  countryOptions={countryOptions}
                  onEdit={() => {
                    setAddressIndex(index)
                    setEditAddressActive(true)
                  }}
                  onRemove={() => remove(index)}
                  onChangeAddressType={handleChangeAddressType}
                />
              )}
            </div>
          )
        })}
      {!maxAddressesExceeded && typeof addressIndex !== 'number' && (
        <AddAddressCard
          onClick={() => {
            if (!addressIndex) {
              push(constructCustomerAddress())
              setAddressIndex(values?.addresses?.length)
            }
          }}
          className="flex justify-center items-center cursor-pointer"
        >
          <div className="flex flex-row">
            <i className="pi pi-plus" />
            <AddAddressText>{t('address.add_address')}</AddAddressText>
          </div>
        </AddAddressCard>
      )}
    </div>
  )
}

const AddAddressCard = styled.div`
  width: 15.9375rem;
  height: 14.875rem;
  padding: 0.65625rem 1.09375rem;
  gap: 0.4375rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 3px dashed var(--surface-200, #eee);
  background: var(--surface-0, #fff);
  box-shadow: unset;
  &:hover,
  &:focus {
    background-color: #fafafa;
  }
  &:active {
    background-color: #fafafa;
  }
`

const AddAddressText = styled.p`
  color: var(--button-secondary-background, #64748b);
  font-family: 'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.05rem;
`

export default CustomerAdressView
