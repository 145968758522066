import { useCreateFee, useUpdateFee } from '../hooks'
import { useGetCompanyFeesEvaluation } from '../hooks/getCompanyFeesEvaluation'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { omit, pick } from 'lodash'
import { Fragment, useContext, useEffect } from 'react'
import { FunctionComponent } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import { useMutationShowingErrors } from '@/hooks'
import {
  Company,
  EBillingPeriodType,
  EBusinessUnit,
  EDealType,
  EFeeLevel,
  EFeeMode,
  EFeeType,
  FeeCreateArgs,
  FeeDefinition,
  FeeIntervalArgs,
  FeeUpdateArgs,
  Scalars,
  WithdrawalFeeIntervalArgs,
} from '@/schemaTypes'
import FeeDefinitionComponent from './FeeDefinitionComponent'

interface FeeDefinitionDialogProps {
  queryResult
  company: Company
  businessUnit: EBusinessUnit
  isEditMode: boolean
  originalFeeDefinition?: FeeDefinition | null
  openDialog: boolean
  setOpenDialog
}

const scroll: DialogProps['scroll'] = 'paper'

const FeeDefinitionDialog: FunctionComponent<FeeDefinitionDialogProps> = (
  props,
) => {
  const {
    queryResult,
    company,
    businessUnit,
    isEditMode,
    originalFeeDefinition,
    openDialog,
    setOpenDialog,
  } = props
  const { t } = useTranslation()
  const { showInfo } = useContext(Context)

  const [evaluateCounter, setEvaluateCounter] = useState(0) // This is used just for refetching the query. Should be removed

  const [newFeeDefinitionArgs, setNewFeeDefinitionArgs] = useState<
    FeeCreateArgs | FeeUpdateArgs | undefined
  >()

  useEffect(() => {
    setNewFeeDefinitionArgs(
      isEditMode && originalFeeDefinition
        ? { ...convertFeeDefinitionToFeeUpdateArgs(originalFeeDefinition) }
        : { ...createDefaultFeeCreateArgs(company._id, businessUnit) },
    )
  }, [company, businessUnit, originalFeeDefinition, isEditMode])

  const { companyFeesEvaluation, queryResult: evaluationQueryResult } =
    useGetCompanyFeesEvaluation({
      variables: {
        companyId: company._id,
        businessUnit,
      },
      skip: !newFeeDefinitionArgs,
    })

  const createFee = useMutationShowingErrors({
    mutation: useCreateFee(),
    successMessage: t('fees.fee_created'),
  })

  const updateFee = useMutationShowingErrors({
    mutation: useUpdateFee(),
    successMessage: t('fees.fee_updated'),
  })

  const intervalValidation = (
    intervals: FeeIntervalArgs[] | WithdrawalFeeIntervalArgs[],
  ): boolean => {
    if (!isSorted(intervals.map((interval) => interval.from))) {
      showInfo(t('fees.interval_order_not_correct'))
      return false
    }
    return true
  }

  const isSorted = (numbers: number[]) =>
    numbers.every((number, index) => {
      if (index === 0) {
        return true
      }

      return number >= numbers[index - 1]
    })

  const onConfirmDialog = () => {
    if (newFeeDefinitionArgs) {
      if (
        (newFeeDefinitionArgs.feeIntervals &&
          !intervalValidation(newFeeDefinitionArgs.feeIntervals)) ||
        (newFeeDefinitionArgs.withdrawalFeeIntervals &&
          !intervalValidation(newFeeDefinitionArgs.withdrawalFeeIntervals))
      ) {
        return
      }
      if (isEditMode) {
        updateFee({
          variables: {
            feeUpdateArgs: newFeeDefinitionArgs as FeeUpdateArgs,
          },
          onCompleted: () => {
            if (queryResult) queryResult.refetch()
          },
        })
      } else {
        createFee({
          variables: {
            feeCreateArgs: newFeeDefinitionArgs,
          },
          onCompleted: () => {
            if (queryResult) queryResult.refetch()
          },
        })
      }
      setOpenDialog(false)
    }
  }

  const onResetDialog = () => {
    isEditMode && originalFeeDefinition
      ? convertFeeDefinitionToFeeUpdateArgs(originalFeeDefinition)
      : createDefaultFeeCreateArgs(company._id, businessUnit)
  }

  const onAbortDialog = () => {
    setOpenDialog(false)
  }

  const onEvaluateFees = () => {
    const tempCounter = evaluateCounter
    setEvaluateCounter(tempCounter + 1)
  }

  if (!newFeeDefinitionArgs) return null

  return (
    <Fragment>
      <Dialog
        disableBackdropClick
        maxWidth={'md'}
        open={openDialog}
        onClose={onAbortDialog}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Typography variant="h6" gutterBottom>
          <DialogTitle>
            {isEditMode ? t('update_fee') : t('create_fee')}
          </DialogTitle>
        </Typography>
        <DialogContent dividers={scroll === 'paper'}>
          <FeeDefinitionComponent
            company={company}
            businessUnit={businessUnit}
            isEditMode={isEditMode}
            newFeeDefinitionArgs={newFeeDefinitionArgs}
            setNewFeeDefinitionArgs={setNewFeeDefinitionArgs}
            companyFeesEvaluation={companyFeesEvaluation}
            onEvaluateFees={onEvaluateFees}
            evaluationQueryResult={evaluationQueryResult}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onAbortDialog} color="primary">
            {t('abort')}
          </Button>
          <Button onClick={onResetDialog} color="primary">
            {t('reset')}
          </Button>
          <Button onClick={onConfirmDialog} color="primary">
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default FeeDefinitionDialog

// For using this function you should make sure that input parameter is not null or undefined
function convertFeeDefinitionToFeeUpdateArgs(
  originalFeeDefinition: FeeDefinition,
) {
  const feeUpdateArgs: FeeUpdateArgs = {
    ...omit(originalFeeDefinition, ['intervalSelectedIndex', 'company']),
    amountForCategories: originalFeeDefinition.amountForCategories?.map((c) =>
      pick(c, ['amount', 'categoryId']),
    ),
    feeIntervals: originalFeeDefinition.feeIntervals?.map((c) =>
      pick(c, ['diffAmount', 'from', 'type']),
    ),
    withdrawalFeeIntervals: originalFeeDefinition.withdrawalFeeIntervals?.map(
      (c) => pick(c, ['factor', 'from']),
    ),
  }
  return feeUpdateArgs
}

function createDefaultFeeCreateArgs(
  companyId: Scalars['ObjectId'],
  businessUnit: EBusinessUnit,
) {
  const feeCreateArgs: FeeCreateArgs = {
    companyId: companyId,
    businessUnit,
    priority: 0,
    amount: 0,
    billingPeriodType: EBillingPeriodType.HalfMonth,
    level: EFeeLevel.Deal,
    mode: EFeeMode.Constant,
    feeType: EFeeType.Initial,
    dealTypes: [EDealType.Pawn, EDealType.Purchase],
    restrictToCategories: [],
    isActive: true,
    includedOnExtensions: true,
  }
  return feeCreateArgs
}
