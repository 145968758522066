import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { EOrderBy, Query } from '@/schemaTypes'

const GET_CUSTOM_DEAL_FORMS = gql`
  query getCustomDealForms {
    getCustomDealForms {
      _id
      categoryId
      createdAt
      updatedAt
    }
  }
`

type TData = { getCustomDealForms: Query['getCustomDealForms'] }

export function useGetCustomDealForms(opts: QueryHookOptions<TData> = {}) {
  const queryResult = useQuery<TData>(GET_CUSTOM_DEAL_FORMS, {
    variables: {
      opts: {
        order: { createdAt: EOrderBy.Desc },
      },
    },
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
