import ResponseTemplatesTable from '.'
import React from 'react'
import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import getPagePath from '@/utils/getPagePath'
import ResponseTemplateCreate from './create'
import ResponseTemplateEdit from './edit/[responseTemplateId]'

export function ResponseTemplatesRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/edit/:responseTemplateId')}
        render={(props) => (
          <ResponseTemplateEdit
            {...props}
            responseTemplateId={props.match.params.responseTemplateId as string}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/create')}
        component={ResponseTemplateCreate}
      />
      <SentryRoute
        path={getPagePath(props)}
        component={ResponseTemplatesTable}
      />
    </Switch>
  )
}

export default ResponseTemplatesRoutes
