import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetLoanToValuesEntriesArgs } from '@/schemaTypes'

const GET_LOAN_TO_VALUES_ENTRIES = gql`
  query getLoanToValuesEntries($opts: LoanToValueOpts) {
    getLoanToValuesEntries(opts: $opts) {
      _id
      validFrom
      categoryValues
      company {
        _id
        name
      }
    }
  }
`

type TData = { getLoanToValuesEntries: Query['getLoanToValuesEntries'] }
type TVariables = QueryGetLoanToValuesEntriesArgs

export function useGetLoanToValuesEntries(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_LOAN_TO_VALUES_ENTRIES, {
    variables: {},
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    loanToValuesEntries: queryResult.data?.getLoanToValuesEntries || [],
  }
}
