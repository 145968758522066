import styled from '@emotion/styled'
import classNames from 'classnames'
import { Sidebar } from 'primereact/sidebar'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { ERoles, Employee } from '@/schemaTypes'

interface SidebarMenuProps {
  visible: boolean
  onClose: () => void
  currentUser: Employee | null
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  visible,
  onClose,
  currentUser,
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [expandedItems, setExpandedItems] = useState<
    Record<string, boolean | undefined>
  >({})

  const toggleSubMenu = useCallback((index: string) => {
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [index]: !prevExpandedItems[index],
    }))
  }, [])

  const isSubMenuItemActive = useCallback(
    (submenuItems: MenuItemProps[]) => {
      return submenuItems.some((subMenuItem) => {
        if (subMenuItem.route && subMenuItem.route === location.pathname) {
          return true
        } else if (
          subMenuItem.items &&
          isSubMenuItemActive(subMenuItem.items)
        ) {
          return true
        }
        return false
      })
    },
    [location.pathname],
  )

  useEffect(() => {
    const calculateActiveSubMenus = (sections: SectionProps[]) => {
      const activeSubMenus: Record<string, boolean> = {}

      sections.forEach((section, index) => {
        section.children &&
          section.children
            .filter(
              (e) =>
                !e.isHidden &&
                e.roles?.some((role) => currentUser?.roles.includes(role)),
            )
            .forEach((menuItem) => {
              const submenuActive = isSubMenuItemActive(menuItem.items || [])
              if (submenuActive) {
                activeSubMenus[`${index}${menuItem.label}`] = true
              }
            })
      })
      setExpandedItems(activeSubMenus)
    }

    calculateActiveSubMenus(menuOptions)
  }, [currentUser?.roles, isSubMenuItemActive, location])

  const renderSubMenuItems = useCallback(
    (submenuItems: MenuItemProps[]) =>
      submenuItems
        .filter((e) => !e.isHidden)
        .map((subMenuItem, subIndex) => (
          <StyledNavLink
            key={subIndex}
            to={subMenuItem.route}
            activeClassName="active-sub"
            style={{ textDecoration: 'none' }}
          >
            <SubMenuItem
              className="flex flex-row content-center items-center pl-12 p-1"
              onClick={onClose}
            >
              {subMenuItem?.icon && (
                <i className={classNames('mx-1', subMenuItem.icon)} />
              )}
              <SubMenuText>{t(subMenuItem.label)}</SubMenuText>
            </SubMenuItem>
          </StyledNavLink>
        )),
    [onClose, t],
  )

  const renderMenuItems = useCallback(
    (menuItem: MenuItemProps, index: number) => {
      const isActive = isSubMenuItemActive(menuItem.items || []) ? 'active' : ''

      if (menuItem.route) {
        return (
          <StyledNavLink
            to={menuItem.route}
            activeClassName="active"
            style={{ textDecoration: 'none' }}
            key={index}
          >
            <MenuItem
              className={classNames(
                'flex flex-row content-center items-center',
                isActive,
                { 'mb-2.5': !menuItem.items },
              )}
              onClick={() => {
                toggleSubMenu(`${index}${menuItem.label}`)
                !menuItem.items && onClose()
              }}
            >
              {menuItem.items && (
                <ExpandIcon
                  className="px-1"
                  expanded={expandedItems[`${index}${menuItem.label}`]}
                >
                  <i className="pi pi-angle-right" />
                </ExpandIcon>
              )}

              <div
                className={classNames(
                  'flex flex-row content-center items-center',
                  { 'ml-6': !menuItem.items },
                )}
              >
                <i className={classNames('mx-1', menuItem.icon)} />
                <MenuItemText>{t(menuItem.label)}</MenuItemText>
              </div>
            </MenuItem>
          </StyledNavLink>
        )
      }

      return (
        <MenuItem
          className={classNames(
            'flex flex-row content-center items-center',
            isActive,
            { 'mb-2.5': !menuItem.items },
          )}
          onClick={() => {
            toggleSubMenu(`${index}${menuItem.label}`)
            !menuItem.items && onClose()
          }}
        >
          {menuItem.items && (
            <ExpandIcon
              className="px-1"
              expanded={!!expandedItems[`${index}${menuItem.label}`]}
            >
              <i className="pi pi-angle-right" />
            </ExpandIcon>
          )}

          <div
            className={classNames('flex flex-row content-center items-center', {
              'ml-6': !menuItem.items,
            })}
          >
            <i className={classNames('mx-1', menuItem.icon)} />
            <MenuItemText>{t(menuItem.label)}</MenuItemText>
          </div>
        </MenuItem>
      )
    },
    [expandedItems, onClose, toggleSubMenu, t, isSubMenuItemActive],
  )

  const menuItems = useMemo(
    () =>
      menuOptions.map((section, index) => (
        <div key={index}>
          <Title className="uppercase mb-1">{section.title}</Title>
          <div className="mb-8">
            {section.children &&
              section.children
                .filter(
                  (e) =>
                    !e.isHidden &&
                    e.roles?.some((role) => currentUser?.roles.includes(role)),
                )
                .map((menuItem, innerIndex) => (
                  <React.Fragment key={innerIndex}>
                    {renderMenuItems(menuItem, index)}
                    {menuItem.items && (
                      <SubMenu
                        key={`sub_${innerIndex}`}
                        className="mb-2.5"
                        expanded={!!expandedItems[`${index}${menuItem.label}`]}
                      >
                        {renderSubMenuItems(menuItem.items)}
                      </SubMenu>
                    )}
                  </React.Fragment>
                ))}
          </div>
        </div>
      )),
    [expandedItems, renderMenuItems, renderSubMenuItems, currentUser?.roles],
  )

  return (
    <SidebarStyled visible={visible} onHide={() => onClose()}>
      <div className="flex flex-col">{menuItems}</div>
    </SidebarStyled>
  )
}

type MenuItemProps = {
  label: string
  route?: string
  icon?: string
  items?: MenuItemProps[]
  isHidden?: boolean
  roles?: ERoles[]
}

type SectionProps = {
  title: string
  children: MenuItemProps[]
}

const menuOptions: SectionProps[] = [
  {
    title: 'Shop',
    children: [
      {
        label: 'deal.plural',
        route: '/inApp/deals',
        roles: [ERoles.Superadmin, ERoles.Locationstaff, ERoles.Guest],
        icon: 'pi pi-fw pi-shopping-cart',
      },
      {
        label: 'custom_deal.plural',
        route: '/inApp/customDeals',
        roles: [ERoles.Superadmin, ERoles.Locationstaff],
        icon: 'pi pi-fw pi-shopping-bag',
      },
      {
        label: 'item.plural',
        route: '/inApp/items',
        roles: [ERoles.Superadmin, ERoles.Locationstaff, ERoles.Guest],
        icon: 'pi pi-fw pi-pencil',
      },
      {
        label: 'auctions',
        route: '/inApp/auctions',
        roles: [ERoles.Superadmin],
        icon: 'pi pi-fw pi-megaphone',
      },
      {
        label: 'private_sales',
        route: '/inApp/privatesales',
        roles: [ERoles.Superadmin],
        icon: 'pi pi-fw pi-percentage',
      },
      {
        label: 'customers',
        route: '/inApp/customers',
        roles: [ERoles.Superadmin, ERoles.Locationstaff],
        icon: 'pi pi-fw pi-users',
      },
      {
        label: 'blacklists',
        route: '/inApp/customerBlacklists',
        roles: [ERoles.Superadmin, ERoles.Locationstaff],
        icon: 'pi pi-fw pi-ban',
      },
      {
        label: 'cash_book.label',
        roles: [ERoles.Superadmin, ERoles.Locationstaff],
        icon: 'pi pi-fw pi-money-bill',
        items: [
          {
            label: 'revenues_and_expenses',
            route: '/inApp/cashBook/cashFlows',
          },
          {
            label: 'bills_and_coins_count',
            route: '/inApp/cashBook/counts',
          },
        ],
      },
    ],
  },
  {
    title: 'Admin',
    children: [
      {
        label: 'import_export',
        roles: [ERoles.Superadmin, ERoles.Locationstaff],
        icon: 'pi pi-fw pi-file-import',
        items: [
          {
            label: 'imports_and_exports',
            route: '/inApp/importExport/products',
          },
        ],
      },
      {
        label: 'csv_exports',
        route: '/inApp/exportEntries',
        roles: [ERoles.Superadmin, ERoles.Locationstaff],
        icon: 'pi pi-fw pi-table',
      },
      {
        label: 'products',
        roles: [ERoles.Superadmin],
        icon: 'pi pi-fw pi-database',
        items: [
          {
            label: 'products',
            route: '/inApp/products',
          },
          {
            label: 'properties',
            route: '/inApp/product-property-definitions',
          },
          {
            label: 'crawler_config',
            route: '/inApp/productconfigurator',
          },
        ],
      },
      {
        label: 'configurations',
        roles: [ERoles.Superadmin],
        icon: 'pi pi-fw pi-wrench',
        items: [
          {
            label: 'loan.loan_to_values',
            route: '/inApp/configurations/loanToValues',
          },
          {
            label: 'fees.label',
            route: '/inApp/configurations/fees',
          },
          {
            label: 'material.material_prices',
            route: '/inApp/configurations/materialPrices',
          },
          {
            label: 'shop.plural',
            route: '/inApp/configurations/shops',
          },
          {
            label: 'company.plural',
            route: '/inApp/configurations/companies',
          },
          {
            label: 'storage.storage_facilities',
            route: '/inApp/configurations/storageFacilities',
          },
          {
            label: 'employees',
            route: '/inApp/configurations/employees',
          },
          {
            label: 'item_questions',
            route: '/inApp/configurations/itemQuestions',
          },
          {
            label: 'item_categories.label',
            route: '/inApp/configurations/itemCategories',
          },
          {
            label: 'response_templates',
            route: '/inApp/configurations/responseTemplates',
          },
          {
            label: 'custom_deal_form.plural',
            route: '/inApp/configurations/customDealForms',
          },
          {
            label: 'refurbed_offers',
            route: '/inApp/configurations/refurbedOffers',
          },
          {
            label: 'maintenance',
            route: '/inApp/configurations/maintenance',
          },
        ],
      },
    ],
  },
]

const SidebarStyled = styled(Sidebar)`
  &&& {
    width: 17.25rem;
    .p-sidebar-header {
      padding: 1rem;
    }
    .p-sidebar-content {
      padding: 1rem;
    }
  }
`

const Title = styled.p`
  color: var(--global-text-color, #495057);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.875rem;
`

const MenuItem = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  position: relative;
  height: 2.625rem;
  &:hover {
    background: #e9ecef;
  }
  &.active {
    color: #6366f1;
  }
`

const MenuItemText = styled.p`
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const SubMenu = styled.div<{ expanded: boolean }>`
  overflow: hidden;
  max-height: ${({ expanded }) => (expanded ? '700px' : '0')};
  transition: max-height 0.5s ease-in-out;
`

const SubMenuText = styled.p`
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const SubMenuItem = styled.div`
  cursor: pointer;
  height: 2.625rem;
  &:hover {
    background: #e9ecef;
  }
`

const StyledNavLink = styled(NavLink)`
  color: var(--menuitem-color, #495057);
  &.active {
    color: #6366f1;
  }
  &.active-sub {
    color: #6366f1;
  }
`

const ExpandIcon = styled.span<{ expanded: boolean }>`
  font-size: 14px;
  transform: translateY(0%);
  transition: transform 0.5s ease-in-out;
  ${({ expanded }) => expanded && `transform: translateY(0%) rotate(90deg);`}
`

export default SidebarMenu
