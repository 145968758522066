import styles from '../styles/CashBook.module.scss'
import { TextField } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'
import SaveIcon from '@material-ui/icons/Save'
import { useEffect, useState } from 'react'
import { ECashBookPaymentReference } from '@/schemaTypes'

function CashBookTableStorageLabel(props: {
  value: string
  itemId?: string | null
  paymentReferance: ECashBookPaymentReference
  onSave: (value: string) => void
}) {
  const [value, setValue] = useState<string>(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <TextField
        className={styles.cashbookCashFlowStorageLabelInput}
        style={{ width: '84px' }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        error={!value}
      />
      <a
        href={`/inApp/items/${props.itemId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <LaunchIcon className={`${styles.linkIcon} u-pl-5`} />
      </a>
      {props.paymentReferance !== ECashBookPaymentReference.ValorizationSale &&
      props.paymentReferance !==
        ECashBookPaymentReference.ValorizationCreditNote ? (
        <SaveIcon
          onClick={() => {
            props.onSave(value)
          }}
          className={`${styles.saveIcon}  u-pl-5`}
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default CashBookTableStorageLabel
