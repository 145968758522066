import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Form, Formik, FormikHelpers } from 'formik'
import { TFunction } from 'i18next'
import { omit } from 'lodash'
import { Button } from 'primereact/button'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import * as Yup from 'yup'
import { useActivateEmployee } from '@/domains/employees/hooks/activateEmployee'
import { useVerifyActivationEmployeeToken } from '@/domains/employees/hooks/verifyActivationEmployeeToken'
import { useMutationShowingErrors } from '@/hooks'
import FormikDatePicker from '@/redesign/components/FormikFields/FormikDatePicker/FormikDatePicker'
import FormikInput from '@/redesign/components/FormikFields/FormikInput/FormikInput'
import { EmployeeActivationArgs } from '@/schemaTypes'

type EmployeeActivationFormData = Omit<EmployeeActivationArgs, 'token'> & {
  passwordRepeat: string
  email: string
}

export default function EmployeeCompletion() {
  const { t } = useTranslation()
  const history = useHistory()
  const [email, setEmail] = useState('')

  const token = useMemo(
    () => new URLSearchParams(window.location.search).get('token') ?? '',
    [],
  )

  const activateEmployee = useMutationShowingErrors({
    mutation: useActivateEmployee(),
    successMessage: t('employee.employee_updated'),
  })

  const verifyActivationEmployeeToken = useMutationShowingErrors({
    mutation: useVerifyActivationEmployeeToken(),
  })

  useEffect(() => {
    if (!token) {
      history.push('/expired-link')
      return
    }

    verifyActivationEmployeeToken({
      variables: {
        token,
      },
      onCompleted: (data) => {
        if (!data.verifyActivationEmployeeToken) {
          history.push('/expired-link')
        } else {
          setEmail(data.verifyActivationEmployeeToken.email)
        }
      },
      onError: () => {
        history.push('/expired-link')
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSave = async (
    args: EmployeeActivationFormData,
    formik: FormikHelpers<EmployeeActivationFormData>,
  ) => {
    formik.setSubmitting(true)

    if (!token) {
      return
    }

    try {
      await activateEmployee({
        variables: {
          args: {
            ...omit(args, ['passwordRepeat', 'email']),
            token,
          },
        },
      })

      history.push('/')
    } finally {
      formik.setSubmitting(false)
    }
  }

  return (
    <Formik<EmployeeActivationFormData>
      validationSchema={employeeCompletionSchema(t)}
      initialValues={{
        email,
        firstname: '',
        lastname: '',
        phone: '',
        dateOfBirth: null,
        password: '',
        passwordRepeat: '',
      }}
      enableReinitialize
      onSubmit={onSave}
    >
      {({ isSubmitting }) => (
        <Form noValidate style={{ maxWidth: 433 }} className="w-full">
          <div>
            <p className="text-3xl font-semibold mb-4 text-gray-600 text-center">
              {t('personal_data')}
            </p>

            <StyledFormikInput
              name="email"
              type="email"
              label={t('customer.email')}
              labelClassName="text-base font-bold text-gray-600 after:text-red-500 w-[50%]"
              className="flex items-center justify-center mb-2"
              disabled
              required
              readOnly
            />

            <StyledFormikInput
              name="firstname"
              type="text"
              label={t('customer.firstname')}
              labelClassName="text-base font-bold text-gray-600 after:text-red-500 w-[50%]"
              className="flex items-center justify-center mb-2"
              required
            />

            <StyledFormikInput
              name="lastname"
              type="text"
              label={t('customer.lastname')}
              labelClassName="text-base font-bold text-gray-600 after:text-red-500 w-[50%]"
              className="flex items-center justify-center mb-2"
              required
            />

            <StyledFormikInput
              name="phone"
              type="text"
              label={t('customer.phone')}
              labelClassName="text-base text-gray-600 after:text-red-500 w-[50%]"
              className="flex items-center justify-center mb-2"
            />

            <div className="p-input-icon-left w-full">
              <StyledFormikDatePicker
                name="dateOfBirth"
                label={t('customer.date_of_birth')}
                labelClassName="text-base text-gray-600 after:text-red-500 w-[50%]"
                className="flex items-center justify-center mb-2"
                inputContainerClassName="w-40"
                dateFormat="dd.mm.yy"
                placeholder="DD.MM.YYYY"
                maxDate={dayjs(new Date()).subtract(18, 'year').toDate()}
              />
              <Icon className="pi pi-calendar" />
            </div>

            <div className="text-3xl font-semibold text-gray-600 text-center mt-4 mb-4">
              {t('customer.password')}
            </div>

            <StyledFormikInput
              name="password"
              type="password"
              label={t('customer.password')}
              labelClassName="text-base font-bold text-gray-600 after:text-red-500 w-[50%]"
              className="flex items-center justify-center mb-2"
              required
            />

            <StyledFormikInput
              name="passwordRepeat"
              type="password"
              label={t('repeat_password')}
              labelClassName="text-base font-bold text-gray-600 after:text-red-500 w-[50%]"
              className="flex items-center justify-center mb-2"
              required
            />

            <div className="flex justify-center mt-8">
              <Button
                className="w-10/12"
                severity="info"
                label={t('activate_account')}
                type="submit"
                disabled={isSubmitting}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const employeeCompletionSchema = (t: TFunction) =>
  Yup.object().shape({
    firstname: Yup.string().required().label(t('customer.firstname')),
    lastname: Yup.string().required().label(t('customer.lastname')),
    password: Yup.string()
      .min(16)
      .required()
      .test('customer.password_not_meet_requirement', function (value) {
        const regexPassword = /^(?=.*\d)(?=.*[-_.?!@#$%^&*])(.{16,})$/
        return regexPassword.test(value)
      })
      .label(t('customer.password')),
    passwordRepeat: Yup.string()
      .required()
      .test('password_not_equal', function (value) {
        return value === this.parent.password
      })
      .label(t('repeat_password')),
  })

const StyledFormikInput = styled(FormikInput)`
  > div {
    flex: 1;

    input {
      height: 2.725rem;
    }
  }
`

const StyledFormikDatePicker = styled(FormikDatePicker)`
  > div {
    flex: 1;

    input {
      height: 2.725rem;
    }
  }
`

const Icon = styled.i`
  margin-top: -0.75rem !important;
  left: calc(50% + 8rem) !important;
`
