import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IAddress } from '@/schemaTypes'
import styles from './ValorizationHistoryEntry.module.scss'
import ValorizationHistoryEntryTable, {
  ValorizationHistoryEntryTableRow,
} from './ValorizationHistoryEntryTable'

type ValorizationHistoryEntryBuyerAddressDataRow = Pick<
  ValorizationHistoryEntryTableRow<keyof (IAddress & { uid?: string | null })>,
  'label' | 'type' | 'key' | 'options' | 'disabled'
>

export interface ValorizationHistoryEntryBuyerAddressDataProps {
  className?: string
  label: string
  address: IAddress & { uid?: string | null }
  columnWidths: { label: number; value: number }
  onChangeAddressData: (addressData: IAddress & { uid?: string | null }) => void
  isReadonly: boolean
  countries
  billingAddressDiffers?: boolean
}

export function ValorizationHistoryEntryBuyerAddressData(
  props: ValorizationHistoryEntryBuyerAddressDataProps,
) {
  const {
    className,
    address,
    label,
    columnWidths,
    onChangeAddressData,
    isReadonly,
    countries,
    billingAddressDiffers,
  } = props
  const { t } = useTranslation()

  const buildRow = (
    params: ValorizationHistoryEntryBuyerAddressDataRow,
  ): ValorizationHistoryEntryTableRow<
    keyof (IAddress & { uid?: string | null })
  > => {
    const { key } = params

    return {
      ...params,
      renderKey: key,
      value: address
        ? address[key] ?? (key === 'countryCode' ? '' : undefined)
        : undefined, // dropdown does not reset otherwise
      onChange: params.disabled
        ? undefined
        : (value) => onChangeAddressData({ ...(address ?? {}), [key]: value }),
    }
  }

  const rowsData: Array<ValorizationHistoryEntryBuyerAddressDataRow | null> = [
    {
      label: t('company.singular'),
      key: 'companyName',
      type: 'string',
      disabled: isReadonly,
    },
    billingAddressDiffers
      ? null
      : { label: t('uid'), key: 'uid', type: 'string', disabled: isReadonly },
    { label: t('name'), key: 'name', type: 'string', disabled: isReadonly },
    {
      label: t('address.country'),
      key: 'countryCode',
      type: 'string',
      options: countries,
      disabled: isReadonly,
    },
    {
      label: t('address.city'),
      key: 'city',
      type: 'string',
      disabled: isReadonly,
    },
    {
      label: t('address.zip_code'),
      key: 'zipCode',
      type: 'string',
      disabled: isReadonly,
    },
    {
      label: t('address.street'),
      key: 'street',
      type: 'string',
      disabled: isReadonly,
    },
    {
      label: t('address.house_number'),
      key: 'houseNumber',
      type: 'string',
      disabled: isReadonly,
    },
    {
      label: t('address.stairway'),
      key: 'stairway',
      type: 'string',
      disabled: isReadonly,
    },
    {
      label: t('address.door'),
      key: 'door',
      type: 'string',
      disabled: isReadonly,
    },
  ]
  const rows = rowsData
    .filter((rowData) => rowData)
    .map((rowData) => buildRow(rowData!))

  return (
    <section className={className ?? ''}>
      <Typography
        className={`${styles.middleBold}`}
        variant="body1"
        gutterBottom
      >
        {label}
      </Typography>
      <ValorizationHistoryEntryTable rows={rows} columnWidths={columnWidths} />
    </section>
  )
}

// Prevent rerender if parent rerendered and shallow child props comparison did not find differences.
export default React.memo(ValorizationHistoryEntryBuyerAddressData)
