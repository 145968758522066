import ShippingLabelComponent from '../ShippingLabelComponent'
import { Checkbox, TextField, TextareaAutosize } from '@material-ui/core'
import dayjs from 'dayjs'
import { Fragment, useContext, useMemo, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { Context } from '@/context'
import SaveIcon from '@/images/save.svg'
import {
  EItemStatusType,
  ELogisticsPartnerType,
  EShippingLabelDirection,
  EShippingLabelEntity,
  EStandardShipmentType,
  Item,
  ItemEvent,
  ShippingLabelCreateArgs,
  ValorizationHistoryEntry,
} from '@/schemaTypes'
import { isItemSoldByLastnamePaymentType } from '@/utils/valorization'
import { EHintType, findRelatedEmailForItemEvent } from './ItemStatus'
import styles from './ItemStatus.module.scss'

export interface ItemPaidAndShippedStatusProps {
  item: Item
  onChangeItemStatus: (
    newEvent: ItemEvent,
    hint: EHintType,
    shippingLabelCreateArgs?: ShippingLabelCreateArgs | undefined,
  ) => void
  valorizationHistories?: ValorizationHistoryEntry[]
}

const logisticPartnersList = Object.values(ELogisticsPartnerType)
const standardShipmentTyped = Object.values(EStandardShipmentType)

const ItemPaidAndShippedStatus: FunctionComponent<
  ItemPaidAndShippedStatusProps
> = (props) => {
  const { onChangeItemStatus, item, valorizationHistories } = props
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const isSoldPaymentTypeLastName = useMemo(
    () => isItemSoldByLastnamePaymentType(item),
    [item],
  )

  const finalLogisticPartnersList: string[] = useMemo(() => {
    let tempList: string[] = []

    if (isSoldPaymentTypeLastName) {
      tempList = standardShipmentTyped.filter(
        (c) => c === EStandardShipmentType.Post,
      )
    } else {
      logisticPartnersList.map((c) => tempList.push(c as string))
      standardShipmentTyped.map((c) => tempList.push(c as string))
      tempList.push('OTHER')
    }

    return tempList
  }, [isSoldPaymentTypeLastName])

  const [sendNotification, setSendNotification] = useState(true)
  const [description, setDescription] = useState('')
  const [email, setEmail] = useState(findRelatedEmailForItemEvent(item))
  const [shippingLabelCreateArgs, setShippingLabelCreateArgs] =
    useState<ShippingLabelCreateArgs>(
      isSoldPaymentTypeLastName && finalLogisticPartnersList
        ? ({
            shippingPartner: finalLogisticPartnersList[0],
          } as any)
        : undefined,
    )
  const [shippingLabelCreateArgsIsValid, setShippingLabelCreateArgsIsValid] =
    useState(false)

  const saveNewStatus = () => {
    if (!shippingLabelCreateArgsIsValid) {
      return showInfo(t('shipping_label_is_not_provided_correctly'))
    } else if (email === '' && sendNotification) {
      return showInfo(t('email_address_is_not_set'))
    }

    const now = dayjs().toDate()
    onChangeItemStatus(
      {
        createdAt: now,
        timestamp: now,
        event: EItemStatusType.PaidAndShipped,
        meta: {
          logisticPartnerName: shippingLabelCreateArgs?.shippingPartner,
          logisticPartnerTrackingCode: shippingLabelCreateArgs?.trackingCode,
          logisticPartnerTrackingLink: shippingLabelCreateArgs?.trackingLink,
          logisticPartnerLabelFile: shippingLabelCreateArgs?.labelFile,
          sendNotification: sendNotification,
          emailAddress: email,
          description: description,
        },
      },
      EHintType.NONE,
      shippingLabelCreateArgs,
    )
  }

  return (
    <Fragment>
      <div
        onClick={saveNewStatus}
        className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
      >
        <ReactSVG className={`${styles.saveIcon} u-pl-15`} src={SaveIcon} />
      </div>
      <table>
        <tr className={`${styles.notSellableTr}`}>
          <ShippingLabelComponent
            dealId={item.dealsHistory[item.dealsHistory.length - 1].dealId}
            item={item}
            valorizationHistories={valorizationHistories}
            entityType={EShippingLabelEntity.Item}
            directionType={EShippingLabelDirection.Send}
            shippingLabelCreateArgs={shippingLabelCreateArgs}
            setShippingLabelCreateArgs={setShippingLabelCreateArgs}
            logisticServiceProviders={finalLogisticPartnersList}
            shippingLabelCreateArgsIsValid={shippingLabelCreateArgsIsValid}
            setShippingLabelCreateArgsIsValid={
              setShippingLabelCreateArgsIsValid
            }
            customerEmailAddress={sendNotification ? email : null}
            allowCODPayment
            betweenShops={false}
          />
        </tr>
        <tr className={`${styles.notSellableTr}`}>
          <td>
            {sendNotification ? <br /> : ''}
            {t('notify_customer')}:
            <Checkbox
              checked={sendNotification}
              onChange={(e) => setSendNotification(e.target.checked)}
            />
            <div hidden={!sendNotification}>
              {t('customer.email')}:
              <TextField
                style={{
                  marginLeft: '0.5rem',
                  marginTop: '-0.5rem',
                  width: '70%',
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </td>
        </tr>

        <tr className={`${styles.notSellableTr}`}>
          <td>
            {sendNotification ? <br /> : ''}
            {t('description')}:
            <TextareaAutosize
              rowsMin={4}
              placeholder={t('any_description_want_to_add')}
              style={{ width: '100%' }}
              onChange={(e) => setDescription(e.target.value)}
            />
          </td>
        </tr>
      </table>
    </Fragment>
  )
}

export default ItemPaidAndShippedStatus
