import styled from '@emotion/styled'
import { Box, TextField } from '@material-ui/core'
import { Field, FormikProvider, useFormik } from 'formik'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useUpdateProductPrice } from '@/domains/products/hooks/useUpdateProductPrice'
import { useMutationShowingErrors } from '@/hooks'
import {
  EProductPriceMode,
  Product,
  ProductPriceManualVariantMode,
} from '@/schemaTypes'
import { printDateAndTime, printMoneyAmount } from '@/utils/misc'
import { printLocalDate } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'
import { TableFooter } from './TableFooter'
import { TableHeader } from './TableHeader'
import {
  getAvailablePropertiesHeaders,
  useProductPriceMapByVariantId,
  usePropertiesMapByVariantId,
} from './helpers'

interface Props {
  product: Product
}

type ProductPriceFormData = {
  priceByVariant: Record<string, string>
}

export default function VariantBase({ product }: Props) {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  const price = product.price as ProductPriceManualVariantMode | undefined

  const propertiesMapByVariantId = usePropertiesMapByVariantId(product.variants)
  const productPriceMapByVariantId = useProductPriceMapByVariantId(
    price?.variants ?? [],
  )

  const updateProductPrice = useMutationShowingErrors({
    mutation: useUpdateProductPrice({
      refetchQueries: ['getProduct'],
    }),
    successMessage: t('product_updated_successfully'),
  })

  const formik = useFormik<ProductPriceFormData>({
    initialValues: {
      priceByVariant: product.variants.reduce(
        (acc: Record<string, string>, variant) => {
          acc[variant.id as string] = ''
          return acc
        },
        {},
      ),
    },
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const variants = map(values.priceByVariant, (price, variantId) => {
        return price !== '' && !Number.isNaN(Number(price))
          ? { price: Number(price), variantId }
          : { variantId }
      })

      updateProductPrice({
        variables: {
          args: {
            _id: product._id,
            price: {
              mode: EProductPriceMode.ManualVariant,
              variants,
            },
          },
        },
        onError: () => {
          actions.setSubmitting(false)
        },
        onCompleted: () => {
          actions.setSubmitting(false)
          actions.resetForm()
        },
      })
    },
  })

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <TableContainer>
            <TableHeader
              headers={[
                ...getAvailablePropertiesHeaders(
                  product.availableProperties,
                  language,
                ),
                'release_date',
                'last_price_update',
                'current_price',
                'new_price',
              ]}
            />
            <TableBody>
              {product.variants.map((variant) => {
                const variantPrice = productPriceMapByVariantId[variant.id]

                return (
                  <TableRow key={variant.id}>
                    {propertiesMapByVariantId[variant.id]?.map((child) => (
                      <ItemRowBox key={child}>{child}</ItemRowBox>
                    ))}
                    <ItemRowBox>
                      {printLocalDate(variant.releasedAt, {
                        timezone: defaultTimezone,
                      })}
                    </ItemRowBox>
                    <ItemRowBox flex={1}>
                      {variantPrice?.updatedAt
                        ? printDateAndTime(new Date(variantPrice?.updatedAt))
                        : '-'}
                    </ItemRowBox>
                    <ItemRowBox flex={1}>
                      {variantPrice?.price
                        ? printMoneyAmount(variantPrice?.price)
                        : '-'}
                    </ItemRowBox>
                    <ItemRowBox>
                      <Field name={`priceByVariant.${variant.id}`}>
                        {({ field }) => (
                          <TextField
                            {...field}
                            type="number"
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        )}
                      </Field>
                    </ItemRowBox>
                  </TableRow>
                )
              })}
            </TableBody>
          </TableContainer>

          <TableFooter
            isValid={formik.isValid && formik.dirty}
            handleReset={formik.resetForm}
          />
        </form>
      </FormikProvider>
    </>
  )
}

const TableBody = styled.div``

const TableContainer = styled.div`
  margin-top: 16px;
  border: solid 1px #e8e8e8;
`

const ItemRowBox = styled(Box)<{ flex?: number }>`
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  flex: ${(props) => (props.flex ? props.flex : 1)};
`

const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 24px;

  &:nth-of-type(odd) {
    background-color: #f8f8f8;
  }
`
