import { Chip, Typography } from '@material-ui/core'
import React from 'react'

export interface ChipViewValue {
  label: string
  id: string
}

export interface ChipViewProps {
  onDelete?: (id: string) => void
  onClick?: (id: string) => void
  values: ChipViewValue[]
  label?: string
}

export default function ChipView(props: ChipViewProps) {
  const { label, onDelete, onClick, values } = props

  return (
    <div>
      {label && (
        <Typography variant="h5" gutterBottom>
          {label}
        </Typography>
      )}
      <div className="u-mt-sm u-flex" style={{ gap: 10 }}>
        {values.map((value) => (
          <Chip
            key={value.id}
            label={value.label}
            onDelete={onDelete ? () => onDelete(value.id) : undefined}
            onClick={onClick ? () => onClick(value.id) : undefined}
          />
        ))}
      </div>
    </div>
  )
}
