import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import AuctionsList from '@/redesign/pages/InApp/Auctions'
import getPagePath from '@/utils/getPagePath'
import { AuctionDetailsPage } from './[auctionId]'
import { AddAuction } from './create'

function AuctionsRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/create')}
        component={AddAuction}
      />
      <SentryRoute
        path={getPagePath(props, '/:auctionId')}
        render={(props) => <AuctionDetailsPage {...props} />}
      />
      <SentryRoute path={getPagePath(props)} component={AuctionsList} />
    </Switch>
  )
}

export default AuctionsRoutes
