import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationUpdateCustomerArgs } from '@/schemaTypes'

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $email: String
    $firstname: String
    $lastname: String
    $dateOfBirth: DateTime
    $phone: String
    $sex: ESex
    $note: String
    $isNotePopup: Boolean
    $iban: String
    $paypalAddress: String
    $guestEmail: String
    $identityUploadLinks: [FileTusMetadataArgs!]
    $identityVerifiedAt: DateTime
    $identityVerifiedBy: ObjectId
    $isTestUser: Boolean
    $deactivated: Boolean
    $acquiredBy: String
    $_id: ObjectId!
    $addresses: [ICustomerAddress!]
    $uploadedFiles: [FileTusMetadataArgs!]
    $registrationCertificate: FileTusMetadataArgs
    $identityType: ECustomerIdentityType
    $identityNumber: String
    $additionalInformationForCarPawn: AdditionalInformationForCarPawnArgs
  ) {
    updateCustomer(
      _id: $_id
      email: $email
      firstname: $firstname
      lastname: $lastname
      dateOfBirth: $dateOfBirth
      phone: $phone
      note: $note
      isNotePopup: $isNotePopup
      sex: $sex
      iban: $iban
      paypalAddress: $paypalAddress
      guestEmail: $guestEmail
      identityUploadLinks: $identityUploadLinks
      identityVerifiedAt: $identityVerifiedAt
      identityVerifiedBy: $identityVerifiedBy
      isTestUser: $isTestUser
      deactivated: $deactivated
      acquiredBy: $acquiredBy
      addresses: $addresses
      uploadedFiles: $uploadedFiles
      registrationCertificate: $registrationCertificate
      identityType: $identityType
      identityNumber: $identityNumber
      additionalInformationForCarPawn: $additionalInformationForCarPawn
    ) {
      _id
      email
      firstname
      lastname
      dateOfBirth
      phone
      sex
      note
      isNotePopup
      iban
      paypalAddress
      createdAt
      updatedAt
      customerNumber
      guestEmail
      identityUploadLinks {
        ...FileMetadataFragment
      }
      uploadedFiles {
        ...FileMetadataFragment
      }
      registrationCertificate {
        ...FileMetadataFragment
      }
      isTestUser
      deactivated
      deactivatedAt
      acquiredBy
      userPermissionId
      roles
      addresses {
        ...CustomerAddressFragment
      }
      identityType
      identityNumber
      additionalInformationForCarPawn {
        ...AdditionalInformationForCarPawnFragment
      }
    }
  }
  ${fragments.customerAddress}
  ${fragments.fileMetadata}
  ${fragments.additionalInformationForCarPawn}
`

type TData = { updateCustomer: Mutation['updateCustomer'] }
type TVariables = MutationUpdateCustomerArgs

export function useUpdateCustomer(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateCustomer] = useMutation<TData, TVariables>(UPDATE_CUSTOMER, opts)

  return updateCustomer
}
