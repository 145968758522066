import { useGetItemById } from '../../../items/hooks'
import { Paper, TableHead, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as React from 'react'
import { FunctionComponent } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Scalars } from '@/schemaTypes'
import { printLocalDate } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'
import styles from './PrivateSaleDetails.module.scss'

export interface PrivateSaleDetailsItemOverViewProps {
  itemId: Scalars['ObjectId']
}

const PrivateSaleDetailsItemOverView: FunctionComponent<
  PrivateSaleDetailsItemOverViewProps
> = (props) => {
  const { t } = useTranslation()

  const [itemId] = useState(props.itemId)

  const { item } = useGetItemById({
    variables: {
      itemId: itemId,
    },
    skip: !itemId,
  })

  if (!item || !item.events) return null

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Typography variant="h6" gutterBottom className={'u-flex u-jc-sb'}>
        {t('item_overview')}
      </Typography>

      <div className={`${styles.inlineDivs}`}>
        <br />

        <Table className={`${styles.itemValuesTable}`} size="small">
          <TableBody>
            <TableRow className={`${styles.itemValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {t('id')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                <Link to={`/inApp/items/${item._id}`} target="blank">
                  {item._id}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow className={`${styles.itemValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {t('storage.label')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {item.storageLabel}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <div className={`${styles.inlineDivs}`}>
        <br />

        <Table className={`${styles.itemValuesTable}`} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('properties')}</TableCell>
              <TableCell>{t('value')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow className={`${styles.itemValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {t('car_make')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {item.vehicleData?.vehicleProperties?.make}
              </TableCell>
            </TableRow>

            <TableRow className={`${styles.itemValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {t('car_model')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {item.vehicleData?.vehicleProperties?.model}
              </TableCell>
            </TableRow>

            <TableRow className={`${styles.itemValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {t('car_regdate')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {item.vehicleData?.vehicleProperties?.regdate}
              </TableCell>
            </TableRow>

            <TableRow className={`${styles.itemValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {t('car_engine')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {item.vehicleData?.vehicleProperties?.engine}
              </TableCell>
            </TableRow>

            <TableRow className={`${styles.itemValuesTable}`}>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {t('car_odometer')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={`${styles.itemValuesTable}`}
              >
                {item.vehicleData?.vehicleProperties?.odometer}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <div className={`${styles.inlineDivs}`}>
        <br />
        <Table className={`${styles.itemValuesTable}`} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('process_step')}</TableCell>
              <TableCell>{t('date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {item.events.map((row) => (
              <TableRow key={row.event}>
                <TableCell component="th" scope="row">
                  {row.event}
                </TableCell>
                <TableCell>
                  <span>
                    {printLocalDate(row.createdAt, {
                      timezone: defaultTimezone,
                    })}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default PrivateSaleDetailsItemOverView
