import { useChangeAuctionItemStatus } from '../../hooks/auctionDetails/changeAuctionItemStatus'
import { useUpdateAuctionItem } from '../../hooks/auctionDetails/updateAuctionItem'
import styled from '@emotion/styled'
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
} from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined'
import { compact, uniq } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextInput from '@/components/TextInput'
import { useGetItemQuestions } from '@/domains/itemQuestions/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback'
import {
  Auction,
  AuctionItem,
  EAuctionItemStatus,
  ECustomDealFormQuestionType,
  EQuestionType,
  FormAnswer,
  ItemAnswer,
  ItemQuestion,
} from '@/schemaTypes'

export interface AuctionStartProps {
  auction: Auction
  onClickOverview: () => void
}

export function AuctionStart(props: AuctionStartProps) {
  const { auction, onClickOverview } = props

  const { t } = useTranslation()

  const [selectedItemIndex, setSelectedItemIndex] = useState(0)

  const [currentBuyerName, setCurrentBuyerName] = useState<string | null>('')

  const [auctionItems, setAuctionItems] = useState<AuctionItem[]>([])

  const [isSavingNote, setIsSavingNote] = useState<boolean>(false)

  useEffect(() => {
    if (!auction.items) return

    setAuctionItems(
      auction.items.map((item) => ({
        ...item,
        finalPrice: item.finalPrice ?? item.startPrice,
        buyerName: item.buyerName,
      })),
    )
  }, [auction])

  useEffect(() => {
    setCurrentBuyerName('')
  }, [selectedItemIndex])

  const updateAuctionItem = useMutationShowingErrors({
    mutation: useUpdateAuctionItem(),
    hideSuccessMessage: true,
  })

  const changeAuctionItemStatus = useMutationShowingErrors({
    mutation: useChangeAuctionItemStatus(),
    hideSuccessMessage: true,
  })

  const setNextItem = () => {
    let index = selectedItemIndex + 1

    if (index > auctionItems.length - 1) {
      index = 0
    }

    setSelectedItemIndex(index)
  }

  const setPrevItem = () => {
    let index = selectedItemIndex - 1

    if (index < 0) {
      index = auctionItems.length - 1
    }

    setSelectedItemIndex(index)
  }

  const currentItem = useMemo(() => {
    return auctionItems?.[selectedItemIndex]
  }, [selectedItemIndex, auctionItems])

  const currentItemAnswers = useMemo(
    () =>
      currentItem?.item?.dealsHistory.find(
        (h) => h.dealId === currentItem?.dealId,
      )?.answers ?? [],
    [currentItem],
  )

  const currentItemFormAnwers = useMemo(
    () => currentItem?.item?.formAnswers ?? [],
    [currentItem],
  )

  const isNewProduct = useMemo(
    () =>
      Boolean(
        currentItem?.item?.dealsHistory.find(
          (d) => d.dealId === currentItem?.dealId,
        )?.variantId,
      ),
    [currentItem],
  )

  const { itemQuestions } = useGetItemQuestions({
    variables: {
      opts: {
        filter: {
          ids: currentItemAnswers.map((answer) => answer.questionId) ?? [],
          omitDeactivatedQuestionsForNewProducts: isNewProduct,
        },
      },
    },
    skip: !currentItemAnswers,
  })

  useEffect(() => {
    if (currentItem?.buyerName) {
      setCurrentBuyerName(currentItem?.buyerName ?? '')
    }
  }, [currentItem?.buyerName])

  const onItemSold = () => {
    if (!currentItem?.finalPrice) return
    const buyerName = currentBuyerName?.trim()

    changeAuctionItemStatus({
      variables: {
        newItemStatus: EAuctionItemStatus.Sold,
        args: {
          auctionId: auction._id,
          itemId: currentItem.itemId,
          buyerName: buyerName === '' ? null : buyerName,
          finalPrice: currentItem.finalPrice,
          note: currentItem?.note,
        },
      },
      refetchQueries: ['getAuctionById'],
      onCompleted: () => setNextItem(),
    })
  }

  const [saveNote] = useDebouncedCallback(
    (newNote: string, auctionId: string, currentItemId: string) => {
      if (!currentItemId) return

      updateAuctionItem({
        variables: {
          auctionItemArgs: {
            auctionId: auctionId,
            itemId: currentItemId,
            note: newNote,
          },
        },
        onCompleted: () => {
          setIsSavingNote(false)
        },
      })
    },
    800,
  )

  const buildItemAnswer = useCallback(
    (answer: ItemAnswer, itemQuestions: ItemQuestion[]) => {
      const question = itemQuestions.find(
        (question) => question._id === answer.questionId,
      )

      if (!question) {
        return null
      }

      const itemAnswer = {
        titleKey: t(
          question.titleKey === 'QUESTION_CHARGING_EQUIPMENT_TITLE'
            ? question.titleKey + '_WITHOUT_DESCRIPTION'
            : question.titleKey,
        ),
        valueLabel: '',
      }

      if (question.questionType === EQuestionType.SingleChoice) {
        const labelKey =
          question.singleChoiceOptions[answer.selectedOptionIndex].labelKey

        itemAnswer.valueLabel = t(labelKey) ?? labelKey
      } else if (question.questionType === EQuestionType.Range) {
        const unitKey = question.rangeData?.unitKey

        itemAnswer.valueLabel = `${answer.rangeValue} ${unitKey ? unitKey : ''}`
      }

      return itemAnswer
    },
    [t],
  )

  const itemAnswers = useMemo(() => {
    return compact([
      ...currentItemAnswers.map((answer) =>
        buildItemAnswer(answer, itemQuestions),
      ),
      ...currentItemFormAnwers.map((itemFormAnswer) =>
        buildCustomDealFormAnswers(itemFormAnswer),
      ),
    ])
  }, [
    currentItemAnswers,
    currentItemFormAnwers,
    itemQuestions,
    buildItemAnswer,
  ])

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div style={{ width: 300, overflowY: 'auto', height: 1100, margin: 5 }}>
          {auction?.items?.map((item, index) => (
            <ItemCardWrapper
              onClick={() => setSelectedItemIndex(index)}
              style={{
                border:
                  selectedItemIndex === index
                    ? '4px solid #BEA9FB'
                    : '2px solid #D9D9D9',
                opacity:
                  item.status !== EAuctionItemStatus.ReadyForAuction ? 0.5 : 1,
              }}
            >
              <Typography style={{ fontWeight: 500 }}>
                {item.item?.dealsHistory?.[0]?.title}
              </Typography>
              {item.item?.storageLabel && (
                <Typography style={{ fontSize: 10 }}>
                  {item.item?.storageLabel}
                </Typography>
              )}
              {item.status !== EAuctionItemStatus.ReadyForAuction &&
                item.finalPrice && (
                  <Typography style={{ fontSize: 10 }}>
                    {t('sold_for')}: {item.finalPrice}€
                  </Typography>
                )}
              {item.status === EAuctionItemStatus.ReadyForAuction && (
                <Typography style={{ fontSize: 10 }}>
                  {t('start_price')}: {item.startPrice}€
                </Typography>
              )}
            </ItemCardWrapper>
          ))}
        </div>
        <div
          style={{
            width: 'calc(100% - 300px)',
            padding: 20,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            style={{
              width: 800,
              position: 'relative',
              height: 1100,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              style={{
                top: 10,
                position: 'absolute',
                marginLeft: -125,
                width: '80%',
              }}
            >
              <Typography
                style={{ fontWeight: 500, textAlign: 'center', fontSize: 16 }}
              >
                {
                  currentItem?.item?.dealsHistory.find(
                    (h) => h.dealId === currentItem?.dealId,
                  )?.title
                }
              </Typography>
              <Box style={{ marginTop: 20 }}>
                <SecondaryItemInformation
                  label={t('storage.label')}
                  value={currentItem?.item?.storageLabel ?? ''}
                />
                <SecondaryItemInformation
                  label={t('storage_facility')}
                  value={currentItem?.item?.storageFacility?.name ?? ''}
                />
                <ItemAnswersWrapper>
                  {itemAnswers.map((itemAnswer) => (
                    <SecondaryItemInformation
                      label={itemAnswer.titleKey}
                      value={itemAnswer.valueLabel}
                    />
                  ))}
                </ItemAnswersWrapper>
              </Box>
              <Box
                style={{
                  marginTop: 25,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Box style={{ width: '60%' }}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      paddingLeft: 5,
                      paddingBottom: 5,
                    }}
                  >
                    {t('note_for_auction')}:
                  </Typography>
                  <TextInput
                    multiline={true}
                    minRows={4}
                    maxRows={4}
                    variant="outlined"
                    inputWidth={'400px'}
                    value={currentItem?.note ?? ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ marginTop: 73 }}
                        >
                          {isSavingNote && <CircularProgress size={16} />}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(value) => {
                      setIsSavingNote(true)
                      const newItems = [...auctionItems]
                      if (newItems[selectedItemIndex]) {
                        newItems[selectedItemIndex].note = value
                      }

                      setAuctionItems(newItems)
                      saveNote(value, auction._id, currentItem?.itemId)
                    }}
                  />
                </Box>
              </Box>

              <Box style={{ marginTop: 30 }}>
                <SecondaryItemInput
                  inputType="number"
                  label={t('start_price')}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  disabled
                  labelStyles={{ marginTop: 5 }}
                  value={currentItem?.startPrice ?? 0}
                />
                <SecondaryItemInput
                  inputType="number"
                  label={t('final_price')}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  disabled={
                    currentItem?.status !== EAuctionItemStatus.ReadyForAuction
                  }
                  labelStyles={{ marginTop: 5 }}
                  error={!currentItem?.finalPrice ? t('required') : undefined}
                  value={currentItem?.finalPrice ?? null}
                  onChange={(value) => {
                    const newItems = [...auctionItems]
                    if (newItems[selectedItemIndex] && value) {
                      newItems[selectedItemIndex].finalPrice = parseFloat(value)
                    }

                    setAuctionItems(newItems)
                  }}
                />
              </Box>
            </Box>
            <Box
              style={{
                position: 'absolute',
                bottom: 10,
                marginLeft: -125,
                width: '80%',
              }}
            >
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <SecondaryItemInput
                  inputType="string"
                  label={t('buyer')}
                  disabled={
                    currentItem?.status !== EAuctionItemStatus.ReadyForAuction
                  }
                  labelStyles={{ marginTop: 5 }}
                  value={currentBuyerName ?? ''}
                  onChange={(value) => {
                    setCurrentBuyerName(value as string)
                  }}
                />
              </Box>
              <Typography
                style={{ fontWeight: 500, textAlign: 'center', fontSize: 12 }}
              >
                Quick Selection:
              </Typography>

              <Box
                style={{
                  marginTop: 15,
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: 65,
                }}
              >
                <Box style={{ width: '80%' }}>
                  {uniq(auctionItems.map((item) => item.buyerName)).map(
                    (buyerName) =>
                      buyerName ? (
                        <Chip
                          disabled={
                            currentItem?.status !==
                            EAuctionItemStatus.ReadyForAuction
                          }
                          onClick={() => setCurrentBuyerName(buyerName)}
                          style={{
                            backgroundColor: '#D0BCFF',
                            fontWeight: 500,
                            cursor: 'pointer',
                            margin: 5,
                          }}
                          label={buyerName}
                        />
                      ) : null,
                  )}
                </Box>
              </Box>
              <Box
                style={{
                  marginTop: 50,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <SoldButton
                  style={{
                    opacity:
                      currentItem?.status !==
                        EAuctionItemStatus.ReadyForAuction || isSavingNote
                        ? 0.5
                        : 1,
                  }}
                  disabled={
                    currentItem?.status !==
                      EAuctionItemStatus.ReadyForAuction || isSavingNote
                  }
                  onClick={() => onItemSold()}
                >
                  {t('sold')}
                </SoldButton>
                {currentItem?.status === EAuctionItemStatus.Sold &&
                  !currentItem?.surchargeConfirmationLink && (
                    <LockOpenOutlinedIcon
                      onClick={() => {
                        changeAuctionItemStatus({
                          variables: {
                            newItemStatus: EAuctionItemStatus.ReadyForAuction,
                            args: {
                              auctionId: auction._id,
                              itemId: currentItem.itemId,
                            },
                          },
                          refetchQueries: ['getAuctionById'],
                        })
                      }}
                      style={{
                        marginLeft: 10,
                        fontSize: 30,
                        marginTop: 5,
                        cursor: 'pointer',
                      }}
                    />
                  )}
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  style={{
                    marginTop: 30,
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '80%',
                  }}
                >
                  <Navigator onClick={() => setPrevItem()}>{'<'}</Navigator>
                  <Box style={{ fontWeight: 500 }}>
                    {selectedItemIndex + 1}/{auctionItems.length}
                  </Box>
                  <Navigator onClick={() => setNextItem()}>{'>'}</Navigator>
                </Box>
              </Box>
              <Box
                style={{
                  marginTop: 30,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <OverviewButton onClick={() => onClickOverview()}>
                  Overview
                </OverviewButton>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </Paper>
  )
}

const Navigator = styled(Box)`
  background: #6750a4;
  border-radius: 100px;
  width: 42px;
  height: 26px;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
`

const SoldButton = styled(Button)`
  &&& {
    background: #6750a4;
    height: 44px;
    width: 150px;
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    font-size: 14px !important;
    color: #ffffff !important;
  }
`

const OverviewButton = styled(Button)`
  &&& {
    height: 44px;
    width: 150px;
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    font-size: 14px !important;
  }
`

const ItemCardWrapper = styled.div`
  padding: 5px;
  font-size: 11px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
`

const ItemAnswersWrapper = styled(Box)`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  max-height: 400px;
  overflow: auto;
`

const buildCustomDealFormAnswers = (formAnswer: FormAnswer) => {
  const question = formAnswer.question

  const itemAnswer = {
    titleKey: question.label.text,
    valueLabel: '',
  }

  if (
    (question.type === ECustomDealFormQuestionType.NumberOptions ||
      question.type === ECustomDealFormQuestionType.TextOptions) &&
    (formAnswer.__typename === 'FormOptionNumberAnswer' ||
      formAnswer.__typename === 'FormOptionTextAnswer')
  ) {
    itemAnswer.valueLabel =
      formAnswer.__typename === 'FormOptionNumberAnswer'
        ? formAnswer.optionNumberAnswer?.toString()
        : formAnswer.optionTextAnswer
  } else if (formAnswer.__typename === 'FormBooleanAnswer') {
    itemAnswer.valueLabel = formAnswer.booleanAnswer ? 'Ja' : 'Nein'
  } else if (formAnswer.__typename === 'FormTextAnswer') {
    itemAnswer.valueLabel = formAnswer.textAnswer
  } else if (formAnswer.__typename === 'FormNumberAnswer') {
    itemAnswer.valueLabel = formAnswer.numberAnswer?.toString()
  }

  if (!itemAnswer.valueLabel?.trim()) {
    return null
  }

  return itemAnswer
}

export const SecondaryItemInformation = ({
  label,
  value,
}: {
  label: string
  value: string
}) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: 7,
    }}
  >
    <div style={{ width: '60%', textAlign: 'end', fontWeight: 500 }}>
      {' '}
      <Typography style={{ fontWeight: 600 }}>{label}:</Typography>
    </div>

    <div style={{ width: '40%', textAlign: 'start', paddingLeft: 10 }}>
      {' '}
      <Typography>{value}</Typography>
    </div>
  </div>
)

export const SecondaryItemInput = ({
  label,
  labelStyles = {},
  value,
  inputType,
  inputProps = {},
  disabled,
  error,
  onChange,
}: {
  label: string
  labelStyles?: Record<string, string | number>
  value: string | number | null
  inputType: string
  disabled?: boolean
  inputProps?: Record<string, any>
  error?: string
  onChange?: (value: string) => void
}) => (
  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
    <div
      style={{
        width: '40%',
        textAlign: 'end',
        fontWeight: 500,
        ...labelStyles,
      }}
    >
      {' '}
      <Typography style={{ fontWeight: 600 }}>{label}:</Typography>
    </div>

    <div style={{ width: '60%', textAlign: 'start', paddingLeft: 10 }}>
      {' '}
      <TextInput
        InputProps={inputProps}
        error={error}
        errorMessagePosition="bottom"
        disabled={disabled}
        value={value}
        type={inputType}
        onChange={onChange}
      />
    </div>
  </div>
)

export default AuctionStart
