import { QueryHookOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import useDebouncedQuery from '@/hooks/useDebouncedQuery'
import { Query, QueryDealExtensionCalculationArgs } from '@/schemaTypes'
import { extensionFragments } from './extensionFragments'

const DEAL_EXTENSION_CALCULATION = gql`
  query dealExtensionCalculation(
    $dealId: ObjectId!
    $args: DealExtensionCalculationArgs!
  ) {
    dealExtensionCalculation(dealId: $dealId, args: $args) {
      ...PersistedDealExtensionCalculationFragment
    }
  }
  ${extensionFragments.dealExtensionCalculation}
`

type TData = { dealExtensionCalculation: Query['dealExtensionCalculation'] }
type TVariables = QueryDealExtensionCalculationArgs

export function useDealExtensionCalculation(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useDebouncedQuery<TData, TVariables>(
    DEAL_EXTENSION_CALCULATION,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    dealExtensionCalculation: queryResult.data?.dealExtensionCalculation,
  }
}
