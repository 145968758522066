import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Mutation,
  MutationUpdateSavedValorizationHistoryEntryArgs,
} from '@/schemaTypes'

const UPDATE_SAVED_VALORIZATION_HISTOY_ENTRY = gql`
  mutation updateSavedValorizationHistoryEntry(
    $args: ValorizationEntryUpdateArgs!
    $shopId: ObjectId
  ) {
    updateSavedValorizationHistoryEntry(args: $args, shopId: $shopId) {
      _id
    }
  }
`

type TData = {
  updateSavedValorizationHistoryEntry: Mutation['updateSavedValorizationHistoryEntry']
}
type TVariables = MutationUpdateSavedValorizationHistoryEntryArgs

export function useUpdateSavedValorizationHistoryEntry(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateSavedValorizationHistoryEntry] = useMutation<TData, TVariables>(
    UPDATE_SAVED_VALORIZATION_HISTOY_ENTRY,
    opts,
  )

  return updateSavedValorizationHistoryEntry
}
