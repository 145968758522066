import { ECountry, IAddress, ICustomerAddress } from '@/schemaTypes'

const defaultAddress: IAddress = {
  coordinates: {
    lat: 0,
    lng: 0,
  },
  houseNumber: '',
  stairway: '',
  door: '',
  floor: '',
  street: '',
  city: '',
  state: '',
  countryCode: ECountry.At,
  country: '',
  zipCode: '',
  queryString: '',
  address1: '',
  address2: '',
}

const defaultCustomerAddress: ICustomerAddress = {
  ...defaultAddress,
  isBillingAddress: false,
  isDeliveryAddress: false,
  isRegistrationAddress: false,
}

export const constructAddress = (props: Partial<IAddress> = {}): IAddress => ({
  ...defaultAddress,
  ...props,
})
export const constructCustomerAddress = (
  props: Partial<ICustomerAddress> = {},
): ICustomerAddress => ({ ...defaultCustomerAddress, ...props })

export function firstAddressLine(address: IAddress) {
  return `${address.street} ${address.houseNumber}, ${address.zipCode} ${address.city}`
}

export function secondAddressLine(address: IAddress) {
  const parts: string[] = []

  if (address.stairway) {
    parts.push(`Stiege ${address.stairway}`)
  }

  if (address.floor) {
    parts.push(`Stock ${address.floor}`)
  }

  if (address.door) {
    parts.push(`Tür ${address.door}`)
  }

  return parts.join(', ')
}

export function singleAddressLine(address: IAddress) {
  if (typeof address === 'string') {
    // old adddress format
    return address
  }

  const extraInfoLine = secondAddressLine(address)
  const extraInfo = extraInfoLine ? ` (${extraInfoLine})` : ''

  return `${address.street} ${address.houseNumber}${extraInfo}, ${address.zipCode} ${address.city} ${address.country ? ', ' + address.country : ''}`
}

export function addressToTwoLineHTML(address: IAddress) {
  const secondLine = secondAddressLine(address)
  const firstLineHtml = <div>{firstAddressLine(address)}</div>
  const secondLineHtml = secondLine ? <div>{secondLine}</div> : null

  return (
    <div>
      {firstLineHtml}
      {secondLineHtml}
    </div>
  )
}

export const createIAddress = (
  inputCreateArgs: IAddress | IAddress | undefined | null,
) => {
  if (!inputCreateArgs) return {}

  const iAddress: IAddress = {
    street: inputCreateArgs.street,
    city: inputCreateArgs.city,
    countryCode: inputCreateArgs.countryCode,
    door: inputCreateArgs.door,
    floor: inputCreateArgs.floor,
    country: inputCreateArgs.country,
    zipCode: inputCreateArgs.zipCode,
    houseNumber: inputCreateArgs.houseNumber,
    stairway: inputCreateArgs.stairway,
    state: inputCreateArgs.state,
    address1: inputCreateArgs.address1,
    address2: inputCreateArgs.address2,
    coordinates: inputCreateArgs.coordinates,
    note: inputCreateArgs.note,
    queryString: inputCreateArgs.queryString,
    name: inputCreateArgs.name,
    companyName: inputCreateArgs.companyName,
  }

  return iAddress
}

export const areAddressesSame = (
  address1: IAddress | IAddress | undefined | null,
  address2: IAddress | IAddress | undefined | null,
) => {
  if (
    (address1 === null || address1 === undefined) &&
    (address2 === null || address2 === undefined)
  ) {
    return true
  } else if (address1 && address2) {
    if (
      address1.street === address2.street &&
      address1.houseNumber === address2.houseNumber &&
      address1.state === address2.state &&
      address1.stairway === address2.stairway &&
      address1.floor === address2.floor &&
      address1.door === address2.door &&
      address1.zipCode === address2.zipCode &&
      address1.city === address2.city &&
      address1.countryCode === address2.countryCode
    ) {
      return true
    } else return false
  }
}
