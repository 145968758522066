import { dealCalculationFragments, dealFragments } from '../dealFragments'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { useMutationShowingErrors } from '@/hooks'
import { Mutation, MutationCloseDealArgs, Scalars } from '@/schemaTypes'

const CLOSE_DEAL = gql`
  mutation closeDeal($dealId: ObjectId!, $removeItemsFromStorage: Boolean!) {
    closeDeal(
      dealId: $dealId
      removeItemsFromStorage: $removeItemsFromStorage
    ) {
      _id
      events {
        ...DealEventFragment
      }
      pawnData {
        ...PawnDataFragment
      }
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
    }
  }
  ${dealFragments.event}
  ${dealFragments.pawnData}
  ${dealFragments.dealItem}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = { closeDeal: Mutation['closeDeal'] }
type TVariables = MutationCloseDealArgs

export function useOnDealStatusClosed(
  dealId: Scalars['ObjectId'],
  removeItemsFromStorage: boolean,
) {
  const { t } = useTranslation()

  const [closeDeal] = useMutation<TData, TVariables>(CLOSE_DEAL, {
    variables: {
      dealId,
      removeItemsFromStorage,
    },
  })
  return useMutationShowingErrors({
    mutation: closeDeal,
    successMessage: t('update_successful'),
  })
}
