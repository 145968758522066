import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Mutation,
  MutationSendReminderEmailForUploadingIdArgs,
} from '@/schemaTypes'

const SEND_REMINDER = gql`
  mutation sendReminderEmailForUploadingID(
    $customerId: ObjectId!
    $type: ERemindUploadIDType!
  ) {
    sendReminderEmailForUploadingID(customerId: $customerId, type: $type) {
      _id
      lastReminderIdentitySentAt
    }
  }
`

type TData = {
  sendReminderEmailForUploadingID: Mutation['sendReminderEmailForUploadingID']
}
type TVariables = MutationSendReminderEmailForUploadingIdArgs

export function useSendReminderUploadingID(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [resp] = useMutation<TData, TVariables>(SEND_REMINDER, opts)

  return resp
}
