import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface Props {
  payoutAmount?: number
}

export function CustomDealPayout(props: Props) {
  const { payoutAmount } = props
  const { t } = useTranslation()

  return (
    <TextField
      label={t('loan.loan_to_values')}
      disabled
      variant="filled"
      style={{ width: 250 }}
      size="small"
      type="number"
      value={payoutAmount || ''}
    />
  )
}
