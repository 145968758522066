import { useMutation } from '@apollo/client'
import { Form, Formik } from 'formik'
import { last } from 'lodash'
import { Button } from 'primereact/button'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { FragmentType, graphql, useFragment } from '@/gql'
import { useMutationShowingErrors } from '@/hooks'
import BreadCrumbBar from '@/redesign/components/BreadCrumbBar/BreadCrumbBar'
import FormikInputAutoComplete from '@/redesign/components/FormikFields/FormikAutoComplete/FormikAutoComplete'
import FormikInput from '@/redesign/components/FormikFields/FormikInput/FormikInput'
import Taskbar from '@/redesign/components/Taskbar/Taskbar'
import { ItemCategoryIdealoIdMapArgs } from '@/schemaTypes'

const updateItemCategoryIdealoIdMapsDocument = graphql(`
  mutation updateItemCategoryIdealoIdMaps(
    $args: [ItemCategoryIdealoIdMapArgs!]!
  ) {
    updateItemCategoryIdealoIdMaps(args: $args)
  }
`)

type ItemCategoryOption = { label: string; value: string }
type CrawlerConfigArgs = {
  args: Array<{
    idealoId: string
    itemCategoryOption: ItemCategoryOption | null
  }>
}

export const CrawlerConfig_ItemCategoriesFragment = graphql(`
  fragment CrawlerConfig_ItemCategories on ItemCategory {
    _id
    idealoId
    name
    algoliaCategorySlug
  }
`)

interface CrawlerConfigProps {
  itemCategories: FragmentType<typeof CrawlerConfig_ItemCategoriesFragment>[]
}

const CrawlerConfig = (props: CrawlerConfigProps) => {
  const { t } = useTranslation()

  const itemCategories = useFragment(
    CrawlerConfig_ItemCategoriesFragment,
    props.itemCategories ?? [],
  )

  const itemCategoryOptions = useMemo<ItemCategoryOption[]>(() => {
    return (
      itemCategories.map((c) => ({
        label: c.algoliaCategorySlug || '',
        value: c._id,
      })) ?? []
    )
  }, [itemCategories])

  const [filteredItemCategoryOptions, setFilteredItemCategoryOptions] =
    useState<Array<{ label: string; value: string }>>([])

  const crawlerConfigValidationSchema = Yup.object().shape({
    args: Yup.array().of(
      Yup.object().shape({
        itemCategoryOption: Yup.object()
          .shape({
            label: Yup.string().required(t('required')),
            value: Yup.string().required(t('required')),
          })
          .nullable()
          .required(t('required'))
          .test(
            'testUnique',
            t('duplicated'),
            function (
              value,
              context: Yup.TestContext<ItemCategoryIdealoIdMapArgs> & {
                from: Array<{ value: CrawlerConfigArgs }>
              },
            ) {
              if (!value?.value) return true
              const parent =
                context.from && Array.isArray(context.from)
                  ? last(context.from)
                  : null

              if (
                parent &&
                parent.value.args.filter(
                  (p) =>
                    p.itemCategoryOption &&
                    value.value &&
                    p.itemCategoryOption.value === value.value,
                ).length > 1
              ) {
                return false
              }

              return true
            },
          ),
        idealoId: Yup.string().required(t('required')),
      }),
    ),
  })

  const [updateItemCategoryIdealoIdMapsMutation] = useMutation(
    updateItemCategoryIdealoIdMapsDocument,
  )

  const updateItemCategoryIdealoIdMaps = useMutationShowingErrors({
    mutation: updateItemCategoryIdealoIdMapsMutation,
    successMessage: t('product_updated_successfully'),
  })

  const searchItemCategories = useCallback(
    (event) => {
      let filteredOptions = []

      if (!event.query.trim().length) {
        filteredOptions = [...itemCategoryOptions]
      } else {
        filteredOptions = itemCategoryOptions.filter((option) => {
          return option.label.toLowerCase().includes(event.query.toLowerCase())
        })
      }

      setFilteredItemCategoryOptions(filteredOptions)
    },
    [itemCategoryOptions, setFilteredItemCategoryOptions],
  )

  const initialValues = {
    args: itemCategories
      .filter((c) => c.idealoId)
      .map((c) => ({
        itemCategoryOption: itemCategoryOptions.find(
          (option) => option.value === c._id,
        ),
        idealoId: c.idealoId,
      })),
  }

  return (
    <div className="w-full mb-20">
      <BreadCrumbBar />
      <p className="text-3xl mt-2 mb-2 font-semibold">
        {t('product_crawler_configuration')}
      </p>
      <div className="text-base w-2/4 mt-5">
        <p>{t('product_crawler_configuration_instruction.1')}</p>
        <p>{t('product_crawler_configuration_instruction.2')}</p>
        <p>{t('product_crawler_configuration_instruction.3')}</p>
      </div>
      <div className="mt-16">
        <Formik<CrawlerConfigArgs>
          initialValues={initialValues}
          onSubmit={(values) => {
            if (values.args.some((v) => !v.itemCategoryOption)) {
              return
            }

            updateItemCategoryIdealoIdMaps({
              variables: {
                args: values.args.map((arg) => ({
                  itemCategoryId: arg.itemCategoryOption.value,
                  idealoId: arg.idealoId,
                })),
              },
            })
          }}
          enableReinitialize
          validationSchema={crawlerConfigValidationSchema}
        >
          {(props) => (
            <Form>
              {props.values.args.map((c, index) => {
                return (
                  <div className="flex mt-5" key={index}>
                    <FormikInputAutoComplete
                      field="label"
                      name={`args[${index}].itemCategoryOption`}
                      label={t('category')}
                      suggestions={filteredItemCategoryOptions}
                      className="flex flex-col md:flex-row items-start md:items-center mb-0.62 mr-10"
                      labelClassName="text-sm font-semibold w-5.5 mr-0.63"
                      inputContainerClassName="w-80 ml-3"
                      completeMethod={searchItemCategories}
                      dropdown
                    />
                    <FormikInput
                      name={`args[${index}].idealoId`}
                      type="text"
                      label={t('idealo_id')}
                      className="flex flex-col md:flex-row items-start md:items-center mb-0.62 ml-3"
                      labelClassName="text-sm font-semibold w-5.5 mr-0.63"
                      inputContainerClassName="w-52 ml-3"
                    />
                    <i
                      className="pi pi-trash mt-3 ml-3 cursor-pointer"
                      style={{ fontSize: '1.2rem' }}
                      onClick={() => {
                        props.setFieldValue(
                          'args',
                          props.values.args.filter((_, i) => i !== index),
                        )
                      }}
                    />
                  </div>
                )
              })}

              <Button
                severity="info"
                label={t('add_entry')}
                type="button"
                icon="pi pi-plus"
                className="!mr-3.5 !mt-16"
                onClick={() => {
                  props.setFieldValue('args', [
                    ...props.values.args,
                    { itemCategoryOption: null, idealoId: '' },
                  ])
                }}
              />

              <Taskbar className="!fixed w-[calc(100%-2rem)] bottom-[10px] left-[14px]">
                <div className="flex flex-col md:flex-row justify-between w-full">
                  <div className="flex flex-col sm:flex-row mb-2 md:mb-0">
                    <div>
                      <Button
                        severity="info"
                        label={t('save')}
                        icon="pi pi-save"
                        disabled={!props.isValid}
                        type="button"
                        onClick={() => props.handleSubmit()}
                      />
                    </div>

                    <div className="ml-3">
                      <Button
                        label={t('reset')}
                        onClick={() => {
                          props.setFieldValue('args', initialValues.args)
                        }}
                        severity="secondary"
                        text
                        type="button"
                        icon="pi pi-replay"
                      />
                    </div>
                  </div>
                </div>
              </Taskbar>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default CrawlerConfig
