import dayjs from 'dayjs'
import { FieldArray, Form, Formik } from 'formik'
import * as ibanTools from 'ibantools'
import { Button } from 'primereact/button'
import { Panel } from 'primereact/panel'
import { Tooltip } from 'primereact/tooltip'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ALLOWED_FILE_TYPES } from '@/constants'
import { Context } from '@/context'
import { useSendReminderUploadingID } from '@/domains/customers/hooks/remindUploadingID'
import { useMutationShowingErrors } from '@/hooks'
import Taskbar from '@/redesign/components/Taskbar/Taskbar'
import {
  Customer,
  ECustomerIdentityType,
  ERemindUploadIdType,
  ERoles,
  FileMetadata,
  FileTusMetadataArgs,
} from '@/schemaTypes'
import { useAppSelector } from '@/store'
import { buildPopupNote } from '@/utils/misc'
import CustomerAdressView from './CustomerAddress/CustomerAddressView'
import CustomerDealHistory from './CustomerDealHistory'
import CustomerDocumentsPanel from './CustomerDocumentsPanel'
import CustomerInfoPanel from './CustomerInfoPanel'
import PersonalDetailsPanel from './PersonalDetailsPanel'

export type CustomerArgs = Omit<
  Customer,
  'uploadedFiles' | 'identityUploadLinks' | 'registrationCertificate'
> & {
  uploadedFiles?: FileTusMetadataArgs[]
  registrationCertificate?: FileTusMetadataArgs
  identityUploadLinks?: FileTusMetadataArgs[]
  password?: string
}

export interface CustomersDetailsProps {
  customer: Customer
  onSave: (values: CustomerArgs) => void
  maxAddresses?: number
  isLoading?: boolean
}

const CustomersConfiguration = (props: CustomersDetailsProps) => {
  const { customer, onSave, maxAddresses, isLoading } = props
  const currentUser = useAppSelector((state) => state.user.current)
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const [shouldSendEmailReminder, setShouldSendEmailReminder] = useState(false)

  const sendEmail = useMutationShowingErrors({
    mutation: useSendReminderUploadingID(),
  })

  const yesNoOptions = useMemo(
    () =>
      ['YES', 'NO'].map((value) => ({
        label: t(value),
        value: value === 'YES',
      })),
    [t],
  )
  const countryOptions = useMemo(() => {
    const countries = t('COUNTRY_CODES', {
      returnObjects: true,
    })

    return Object.entries(countries).map(([value, label]) => ({
      value,
      label: label as string,
    }))
  }, [t])
  const identityTypeOptions = useMemo(
    () =>
      Object.values(ECustomerIdentityType).map((type) => ({
        label: t(type.toLowerCase()),
        value: type,
      })),
    [t],
  )
  const maxDate = dayjs().subtract(18, 'year').toDate()
  const isAddingNewIdentity = (data: FileMetadata) => {
    if (data) {
      const currentFileName = customer.identityUploadLinks?.[0]?.filename || ''
      return data.filename && currentFileName !== data.filename
    }
    return false
  }

  useEffect(() => {
    if (customer?.note && customer?.isNotePopup) {
      const text = buildPopupNote(
        t('customer.note_popup_header'),
        customer.note,
      )
      showInfo(text)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCheckboxSendEmailReminder = (data: boolean) => {
    setShouldSendEmailReminder(data)
  }

  return (
    <Formik
      initialValues={{
        //  This "dateOfBirth: maxDate" resolves the issue with the dateOfBirth DatePicker maxDate.
        //  When a maxDate is specified on DatePicker, it should also be used as the default value for the dateOfBirth field in this case.
        ...customer,
        dateOfBirth: customer?.dateOfBirth || maxDate,
        addresses: customer?.addresses || [],
      }}
      validationSchema={customerValidationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const customerConfig: any = { ...values }
        function extractPropertiesFromArray(arr: any[]) {
          return arr.map(({ id, ...rest }) => rest)
        }

        const identityUploadLinks = extractPropertiesFromArray(
          customerConfig.identityUploadLinks || [],
        )
        const registrationCertificate =
          Array.isArray(customerConfig.registrationCertificate) &&
          customerConfig.registrationCertificate.length > 0
            ? extractPropertiesFromArray(
                customerConfig.registrationCertificate || [],
              )[0]
            : customerConfig.registrationCertificate
        const uploadedFiles = extractPropertiesFromArray(
          customerConfig.uploadedFiles || [],
        )

        // DatePicker in Admin tool should also return DOB in UTC tz the same as website
        const dateOfBirth =
          values.dateOfBirth &&
          (!customer?.dateOfBirth ||
            !dayjs(customer.dateOfBirth).isSame(dayjs(values.dateOfBirth)))
            ? dayjs(values.dateOfBirth).startOf('day').utc(true).toDate()
            : values.dateOfBirth

        const formData = {
          ...values,
          identityUploadLinks,
          registrationCertificate,
          uploadedFiles,
          dateOfBirth,
          roles: [ERoles.Customer],
          // auto verify identity if employee upload new identity
          ...(isAddingNewIdentity(identityUploadLinks?.[0]) &&
            currentUser?._id && {
              identityVerifiedAt: new Date(),
              identityVerifiedBy: currentUser?._id,
            }),
        }

        if (shouldSendEmailReminder) {
          sendEmail({
            variables: {
              customerId: customerConfig._id,
              type: ERemindUploadIdType.InvalidId,
            },
          })
        }

        onSave(formData)
        setSubmitting(false)
        resetForm({ values: formData })
      }}
    >
      {(props) => {
        return (
          <Form>
            <Tooltip target=".custom-target-icon" />
            <PersonalDetailsPanel
              customer={customer as Customer}
              maxDate={maxDate}
              customerArgs={props.values}
            />

            {customer._id && (
              <CustomerDocumentsPanel
                customer={customer}
                identityTypeOptions={identityTypeOptions}
                identityTypeSelected={!!props.values.identityType}
                allowedFileTypes={[...ALLOWED_FILE_TYPES, '.pdf']}
                maxDate={maxDate}
                formikProps={props}
                onChangeCheckboxSendEmailReminder={
                  handleCheckboxSendEmailReminder
                }
              />
            )}
            <CustomerInfoPanel
              yesNoOptions={yesNoOptions}
              countryOptions={countryOptions}
            >
              <CustomerDealHistory customer={customer as Customer} />
            </CustomerInfoPanel>
            <Panel header={t('addresses')} toggleable className="mb-6">
              <FieldArray
                name="addresses"
                render={({ form, push, remove }) => {
                  return (
                    <CustomerAdressView
                      push={push}
                      remove={remove}
                      form={form}
                      countryOptions={countryOptions}
                      maxAddresses={maxAddresses}
                    />
                  )
                }}
              />
            </Panel>
            <Taskbar>
              <div className="flex flex-row">
                <Button
                  label={t('save')}
                  type="submit"
                  icon="pi pi-save"
                  disabled={
                    !props.isValid || !props.dirty || props.isSubmitting
                  }
                  className="!mr-3.5"
                />
                <Button
                  label={t('reset')}
                  onClick={props.handleReset}
                  severity="secondary"
                  text
                  icon="pi pi-replay"
                  disabled={isLoading || !props.dirty}
                />
              </div>
            </Taskbar>
          </Form>
        )
      }}
    </Formik>
  )
}

const customerValidationSchema = Yup.object().shape({
  paypalAddress: Yup.string().nullable().email(),
  iban: Yup.string()
    .nullable()
    .test(
      'ibanValidation',
      'Falsches Format',
      (iban: string | null | undefined) => {
        if (!iban) {
          return true
        }

        const ibanValue = ibanTools.electronicFormatIBAN(iban)
        return ibanValue ? ibanTools.isValidIBAN(ibanValue) : false
      },
    ),
  firstname: Yup.string().min(2).required('Required'),
  lastname: Yup.string().min(2).required('Required'),
  phone: Yup.string().min(6).required('Required'),
  dateOfBirth: Yup.date()
    .max(
      dayjs().subtract(18, 'year').hour(23).minute(23).toDate(),
      'Under minimum age',
    )
    .required('Required'),
  email: Yup.string().email().required('Required'),
  password: Yup.string()
    .min(6)
    .when('customer', {
      is: false,
      then: (schema) => schema.required('Required'),
    }),
  note: Yup.string().nullable(),
  addresses: Yup.array().of(
    Yup.object().shape({
      street: Yup.string().required(''),
      houseNumber: Yup.string().required(''),
      zipCode: Yup.string().required(''),
      city: Yup.string().required(''),
    }),
  ),
})

export default CustomersConfiguration
