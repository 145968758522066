import { Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import StorageFacilitiesDetails from '@/domains/storageFacilities/components/StorageFacilitiesDetails'
import {
  useCreateStorageFacility,
  useGetStorageFacility,
} from '@/domains/storageFacilities/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { StorageFacility } from '@/schemaTypes'

export interface StorageFacilitiesCreateProps {
  storageFacilityId: string | null
}

export function StorageFacilitiesCreate(props: StorageFacilitiesCreateProps) {
  const { storageFacilityId } = props
  const { t } = useTranslation()
  const history = useHistory()

  const { storageFacility, queryResult } = useGetStorageFacility({
    variables: {
      storageFacilityId: storageFacilityId ?? '',
    },
  })

  const createStorageFacility = useMutationShowingErrors({
    mutation: useCreateStorageFacility(),
    successMessage: t('storage.storage_facility_created'),
  })

  const onSave = (storageFacility: StorageFacility) => {
    createStorageFacility({
      variables: {
        ...storageFacility,
      },
      onCompleted: (data) => {
        history.replace(
          `/inApp/configurations/storageFacilities/edit/${data.createStorageFacility._id}`,
        )
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={storageFacilityId ?? ''}
        modelName={'storageFacilities'}
        mode={'create'}
      />
      <Typography variant="h5" gutterBottom>
        {t('storage.storage_facility_create')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        storageFacility && (
          <StorageFacilitiesDetails
            storageFacility={storageFacility}
            onSave={onSave}
          />
        )
      )}
    </Paper>
  )
}

export default StorageFacilitiesCreate
