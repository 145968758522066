import styled from '@emotion/styled'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { Button } from 'primereact/button'
import { Calendar, CalendarChangeEvent } from 'primereact/calendar'
import { SelectButton } from 'primereact/selectbutton'
import { TreeNode } from 'primereact/treenode'
import {
  TreeSelect,
  TreeSelectCheckboxSelectionKeyType,
} from 'primereact/treeselect'
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import FilterDropdown from '@/redesign/components/FilterDropdown/FilterDropdown'
import FilterMultiSelect from '@/redesign/components/FilterMultiSelect/FilterMultiSelect'
import SearchInput from '@/redesign/components/SearchInput/SearchInput'
import {
  CustomDealsFiltersArgs,
  EBusinessUnit,
  ECustomDealStatusType,
  EDateFilterMode,
  EDealType,
} from '@/schemaTypes'

interface DateFilter<T extends string> {
  names: T[]
  mode: EDateFilterMode
  from?: Date | null
  to?: Date | null
}

interface Option {
  name: string
  value: string | number
}

export type CustomDealsFilters = Omit<
  CustomDealsFiltersArgs,
  'paginationArgs' | 'status' | 'categoryIds'
> & {
  status?: DateFilter<ECustomDealStatusType>
  categoryIds?: { [key: string]: TreeSelectCheckboxSelectionKeyType }
}

interface CustomDealsFilterProps {
  customDealsFilter: CustomDealsFilters
  setCustomDealsFilter: React.Dispatch<React.SetStateAction<CustomDealsFilters>>
  defaultCustomDealsFilter: Pick<CustomDealsFilters, 'companyId'>
  employeeOptions: { name: string; value: string }[]
  itemCategoryOptions: TreeNode[]
}

const CustomDealsFilter = (props: CustomDealsFilterProps) => {
  const {
    customDealsFilter,
    setCustomDealsFilter,
    defaultCustomDealsFilter,
    employeeOptions,
    itemCategoryOptions,
  } = props
  const { t } = useTranslation()
  const calendarRef = useRef<Calendar>(null)
  const [dateRange, setDateRange] = useState<Date[] | null>(null)
  const { language } = useContext(Context)

  const filterOptions = useMemo(() => {
    return {
      dealsMode: [
        { label: t('current_deals'), value: false },
        { label: t('all_deals_option'), value: true },
      ],
      statusOptions: customDealStatusOptions.map((status) => ({
        name: status,
        value: status,
      })),
      typeOptions: createOptionsFromEnum(EDealType),
      dealBusinessUnit: createOptionsFromEnum(EBusinessUnit),
      employeeOptions,
      itemCategoryOptions,
    }
  }, [t, employeeOptions, itemCategoryOptions])

  const filterChanged = useMemo(
    () => !isEqual(customDealsFilter, defaultCustomDealsFilter),
    [customDealsFilter, defaultCustomDealsFilter],
  )

  const handleChangeCurrentState = useCallback(
    (value, mode) => {
      setCustomDealsFilter((prev) => {
        const newStatus = value?.length
          ? { names: value, mode: mode }
          : undefined

        return {
          ...prev,
          status: newStatus,
          ...defaultCustomDealsFilter,
        }
      })

      if (mode === 'WAS' && !value?.length) {
        setDateRange(null)
      }
    },
    [defaultCustomDealsFilter, setCustomDealsFilter],
  )

  const handleSetDateRange = useCallback(
    (event: CalendarChangeEvent) => {
      if (!Array.isArray(event.value)) {
        throw new Error('Expected event.value to be a Date[]')
      }

      const selectedDates = event.value
      setDateRange(selectedDates)

      if (
        selectedDates?.length &&
        selectedDates.every((date) => date !== null)
      ) {
        setCustomDealsFilter((prev) => {
          const [from, to] = selectedDates.map((date) => dayjs(date).toDate())

          const updatedFilter = {
            ...prev,
            status: {
              ...prev.status,
              from,
              to,
              names: prev.status?.names ?? [],
              mode: prev.status?.mode ?? EDateFilterMode.Was,
            },
            ...defaultCustomDealsFilter,
          }

          calendarRef.current?.hide()

          return updatedFilter
        })
      }
    },
    [defaultCustomDealsFilter, setCustomDealsFilter, setDateRange, calendarRef],
  )

  const handleDealsFilterChange = <T extends keyof CustomDealsFilters>(
    key: T,
    value: CustomDealsFilters[T],
  ) => {
    setCustomDealsFilter((prev) => ({
      ...prev,
      [key]: value,
      ...defaultCustomDealsFilter,
    }))
  }

  const CalendarTemplate = useCallback(
    () => (
      // Using e.stopPropagation() here resolves the issue of the Calendar dropdown closing immediately when the user clicks on the month, year, or arrow icons.
      <CalendarContainer onClick={(e) => e.stopPropagation()}>
        <CalendarStyled
          className="w-full"
          ref={calendarRef}
          value={dateRange}
          selectionMode="range"
          placeholder="Choose Period"
          showIcon
          dateFormat="dd.mm.yy"
          disabled={!customDealsFilter.status?.names}
          onChange={handleSetDateRange}
        />
      </CalendarContainer>
    ),
    [dateRange, customDealsFilter, handleSetDateRange],
  )

  const valueTemplate = useCallback(
    (selectedNodes: TreeNode[]) => {
      if (!selectedNodes || selectedNodes.length === 0) {
        return t('category')
      }

      return selectedNodes.length > 1
        ? `${selectedNodes.length} selected`
        : selectedNodes[0].label
    },
    [t],
  )

  const renderItem = useCallback((node) => <div>{node.label}</div>, [])

  return (
    <div className="flex flex-wrap justify-between items-center">
      <div className="flex flex-wrap items-center">
        <div className="w-52 mr-4">
          <SearchInput
            id="CUSTOM_DEALS_SEARCH_INPUT"
            placeholder={t('search')}
            value={customDealsFilter.search ?? ''}
            onChange={({ target }) =>
              handleDealsFilterChange('search', target?.value)
            }
            onClear={() => handleDealsFilterChange('search', null)}
          />
        </div>
        <div className="flex flex-wrap items-center gap-x-4">
          <FilterMultiSelect
            disabled={customDealsFilter.status?.mode === 'WAS'}
            showSelectAll={false}
            value={
              customDealsFilter.status?.mode === 'CURRENT'
                ? customDealsFilter.status?.names
                : null
            }
            onChange={({ value }) => handleChangeCurrentState(value, 'CURRENT')}
            options={filterOptions.statusOptions}
            optionLabel="name"
            placeholder={t('current_status')}
            maxSelectedLabels={1}
            showClear
            scrollHeight="215px"
            panelHeaderTemplate={<></>}
            selectedItemsLabel={`${customDealsFilter.status?.names?.length} selected`}
            className={classNames(
              {
                'w-5.5':
                  !customDealsFilter.status?.mode &&
                  !customDealsFilter.status?.names,
              },
              {
                'w-32 selected':
                  customDealsFilter.status?.mode === 'CURRENT' &&
                  customDealsFilter.status?.names,
              },
              {
                'w-8.4':
                  customDealsFilter.status?.mode === 'CURRENT' &&
                  customDealsFilter.status?.names.length >= 2,
              },
            )}
          />

          <FilterMultiSelect
            disabled={customDealsFilter.status?.mode === 'CURRENT'}
            showSelectAll={false}
            value={
              customDealsFilter.status?.mode === 'WAS'
                ? customDealsFilter.status?.names
                : null
            }
            onChange={({ value }) => handleChangeCurrentState(value, 'WAS')}
            options={filterOptions.statusOptions}
            optionLabel="name"
            placeholder={t('past_status')}
            maxSelectedLabels={1}
            showClear
            scrollHeight="215px"
            panelHeaderTemplate={CalendarTemplate}
            selectedItemsLabel={`${customDealsFilter.status?.names?.length} selected`}
            className={classNames({
              'w-24':
                !customDealsFilter.status?.mode &&
                !customDealsFilter.status?.names &&
                language !== 'de',
              'w-6.1':
                !customDealsFilter.status?.mode &&
                !customDealsFilter.status?.names &&
                language === 'de',
              'w-32 selected':
                customDealsFilter.status?.mode === 'WAS' &&
                customDealsFilter.status?.names,
              'w-8.4':
                customDealsFilter.status?.mode === 'WAS' &&
                customDealsFilter.status?.names.length >= 2,
            })}
          />

          <FilterDropdown
            value={customDealsFilter.type}
            onChange={({ value }) => handleDealsFilterChange('type', value)}
            options={filterOptions.typeOptions}
            optionLabel="name"
            placeholder={t('type')}
            showClear
            className={classNames({
              selected: customDealsFilter.type,
            })}
          />

          <FilterDropdown
            value={customDealsFilter.dealBusinessUnit}
            onChange={({ value }) =>
              handleDealsFilterChange('dealBusinessUnit', value)
            }
            options={filterOptions?.dealBusinessUnit}
            optionLabel="name"
            placeholder={t('unit')}
            showClear
            className={classNames({
              selected: customDealsFilter.dealBusinessUnit,
            })}
          />

          <div className=" relative">
            <StyledTreeSelect
              placeholder={t('category')}
              value={customDealsFilter.categoryIds}
              options={filterOptions.itemCategoryOptions}
              selectionMode="checkbox"
              display="chip"
              onChange={({ value }) => {
                if (!isCategoryIds(value)) {
                  throw new Error(
                    'Expected value to be a [key: string]: TreeSelectCheckboxSelectionKeyType',
                  )
                }

                handleDealsFilterChange('categoryIds', value)
              }}
              nodeTemplate={renderItem}
              valueTemplate={valueTemplate}
              filter
            />
            {Object.keys(customDealsFilter.categoryIds ?? {}).length > 0 && (
              <i
                className="pi pi-times absolute cursor-pointer text-[#6c757d] right-[1.4rem] top-[1rem]"
                onClick={() => handleDealsFilterChange('categoryIds', {})}
              />
            )}
          </div>

          <FilterMultiSelect
            value={customDealsFilter.employeeIds}
            onChange={({ value }) =>
              handleDealsFilterChange('employeeIds', value)
            }
            options={filterOptions.employeeOptions}
            optionLabel="name"
            placeholder={t('employee.label')}
            showClear
            showSelectAll={false}
            scrollHeight="215px"
            maxSelectedLabels={1}
            className={classNames({
              selected: customDealsFilter.employeeIds,
            })}
            selectedItemsLabel={`${customDealsFilter.employeeIds?.length} selected`}
            filter
          />

          <StyledButton
            disabled={!filterChanged}
            label={t('clear_filter')}
            icon="pi pi-replay"
            severity="secondary"
            text
            onClick={() => {
              setCustomDealsFilter(defaultCustomDealsFilter)
            }}
          />
        </div>
      </div>

      <SelectButton
        value={!!customDealsFilter.includeClosedCustomDeals}
        onChange={({ value }) => {
          handleDealsFilterChange('includeClosedCustomDeals', value)
        }}
        options={filterOptions.dealsMode}
      />
    </div>
  )
}

const isCategoryIds = (
  value: any,
): value is { [key: string]: TreeSelectCheckboxSelectionKeyType } => {
  return value !== null && typeof value === 'object' && !Array.isArray(value)
}

const createOptionsFromEnum = <T extends Record<string, string | number>>(
  enumObject: T,
): Option[] =>
  Object.entries(enumObject).map(([name, value]) => ({ name, value }))

const customDealStatusOptions = Object.values(ECustomDealStatusType)

const CalendarContainer = styled.div`
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #dee2e6;
  color: #343a40;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
`

const CalendarStyled = styled(Calendar)`
  height: 2.375rem;
  &&& {
    .p-inputtext {
      padding: 0.56rem;
      font-size: 0.875rem;
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem;
    }
    .p-button {
      color: #6c757d;
      background: transparent;
      border: unset;
      position: absolute;
      right: 0.938rem;
      padding: 0;
      top: 0.375rem;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      width: auto;
    }
    .p-button:focus {
      box-shadow: unset;
    }
  }
`

const StyledButton = styled(Button)`
  height: 2.375rem;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.05rem;
  padding: 0.66rem 1.09rem;
`

const StyledTreeSelect = styled(TreeSelect)`
  border: none !important;
  color: #495057;

  &:hover {
    background: #e9ecef;
    color: #495057;
  }

  &.p-inputwrapper-filled {
    border: 1px solid #6366f1 !important;
  }

  .p-treeselect-label {
    padding: 0.75rem !important;
    padding-right: 1.25rem !important;
  }

  .p-treeselect-trigger {
    width: 1.6rem;
  }
`

export default CustomDealsFilter
