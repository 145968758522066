import { useGetResponseTemplates } from '../hooks'
import {
  Box,
  Button,
  TextField,
  TextareaAutosize,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  Autocomplete,
  FilterOptionsState,
  createFilterOptions,
} from '@material-ui/lab'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import { Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from '@/components/Loading'
import TextInput from '@/components/TextInput'
import { useValidationErrorMap } from '@/hooks'
import { ResponseTemplate } from '@/schemaTypes'
import { ResponseTemplateValidation } from '@/validation/ResponseTemplateValidation'

export interface ResponseTemplateDetailsProps {
  responseTemplate?: ResponseTemplate | null
  onSave: (itemQuestion: ResponseTemplate) => void
  onClose: () => void
}

interface OptionTextType {
  inputValue?: string
  title: string
  value: string
}

const filter = createFilterOptions<OptionTextType>({
  limit: 1,
  matchFrom: 'start',
})

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    '& button:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}))

export function ResponseTemplateDetails(props: ResponseTemplateDetailsProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { onSave, onClose } = props

  const { responseTemplates, queryResult } = useGetResponseTemplates()

  const [responseTemplate, setResponseTemplate] = useState<
    Partial<ResponseTemplate> | undefined | null
  >(props.responseTemplate)
  const { validationErrorsMap, isValid } = useValidationErrorMap(
    responseTemplate ?? {},
    ResponseTemplateValidation,
  )

  const handleSave = useCallback(() => {
    if (responseTemplate && isValid) {
      onSave(responseTemplate as ResponseTemplate)
    }
  }, [isValid, onSave, responseTemplate])

  const filterOptions = useCallback(
    (options: OptionTextType[], params: FilterOptionsState<OptionTextType>) => {
      const filtered = filter(options, params)
      // Suggest the creation of a new value
      if (params.inputValue !== '') {
        filtered.push({
          title: `${t('add')} "${params.inputValue}"`,
          value: params.inputValue,
        })
      }

      return filtered
    },
    [t],
  )

  if (queryResult.loading) {
    return <Loading />
  }

  return (
    <Fragment>
      <TextInput
        inputWidth={'500px'}
        error={validationErrorsMap.name}
        value={responseTemplate?.name}
        onChange={(value) =>
          setResponseTemplate({ ...responseTemplate, name: value })
        }
        label={t('template_name')}
      />
      <TextInput
        error={validationErrorsMap.subject}
        inputWidth={'500px'}
        value={responseTemplate?.subject}
        onChange={(value) =>
          setResponseTemplate({ ...responseTemplate, subject: value })
        }
        label={t('template_subject')}
      />
      <br />
      <br />
      <br />
      <Typography variant="body1" gutterBottom className={`u-flex u-ai-center`}>
        {t('opening_text')}
        &nbsp;&nbsp;
        <Autocomplete
          options={responseTemplates.map(
            (template) =>
              ({
                title: template.openingText,
                value: template.openingText,
              }) as OptionTextType,
          )}
          filterOptions={filterOptions}
          onChange={(event, newValue: OptionTextType | null) =>
            newValue &&
            setResponseTemplate((template) => ({
              ...template,
              openingText: newValue.value ?? '',
            }))
          }
          getOptionLabel={(option) => option.title}
          style={{ width: 600 }}
          selectOnFocus
          clearOnBlur
          freeSolo
          handleHomeEndKeys
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('select_opening_text')}
              variant="outlined"
            />
          )}
          value={{
            title: responseTemplate?.openingText ?? '',
            value: responseTemplate?.openingText ?? '',
          }}
        />
        <Typography color="error" variant="caption">
          &nbsp;{validationErrorsMap.openingText}
        </Typography>
        &nbsp;&nbsp;
        <Typography variant="subtitle1">
          {' '}
          {parse(DOMPurify.sanitize(responseTemplate?.openingText ?? ''))}
        </Typography>
      </Typography>
      <br />
      <br />
      <Typography variant="body1" gutterBottom className={`u-flex u-ai-center`}>
        {t('editable_text')}
        &nbsp;&nbsp;
        <TextareaAutosize
          rowsMin={6}
          style={{ width: '600px' }}
          defaultValue={responseTemplate?.editableText}
          onChange={(e) => {
            const { value } = e.target
            setResponseTemplate((template) => ({
              ...template,
              editableText: value,
            }))
          }}
        />{' '}
        <Typography color="error" variant="caption">
          &nbsp;{validationErrorsMap.editableText}
        </Typography>
        &nbsp;&nbsp;
        <Typography variant="subtitle1">
          {' '}
          {parse(DOMPurify.sanitize(responseTemplate?.editableText ?? ''))}
        </Typography>
      </Typography>
      <br />
      <br />
      <Typography variant="body1" gutterBottom className={`u-flex u-ai-center`}>
        {t('closing_text')}
        &nbsp;&nbsp;
        <Autocomplete
          options={responseTemplates.map(
            (template) =>
              ({
                title: template.closingText,
                value: template.closingText,
              }) as OptionTextType,
          )}
          filterOptions={filterOptions}
          onChange={(event, newValue: OptionTextType | null) =>
            newValue &&
            setResponseTemplate((template) => ({
              ...template,
              closingText: newValue.value ?? '',
            }))
          }
          getOptionLabel={(option) => option.title}
          style={{ width: 600 }}
          selectOnFocus
          clearOnBlur
          freeSolo
          handleHomeEndKeys
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('select_closing_text')}
              variant="outlined"
            />
          )}
          value={{
            title: responseTemplate?.closingText ?? '',
            value: responseTemplate?.closingText ?? '',
          }}
        />
        <Typography color="error" variant="caption">
          &nbsp;{validationErrorsMap.closingText}
        </Typography>
        &nbsp;&nbsp;
        <Typography variant="subtitle1">
          {' '}
          {parse(DOMPurify.sanitize(responseTemplate?.closingText ?? ''))}
        </Typography>
      </Typography>
      <br />
      <br />
      <Box className={classes.buttonGroup}>
        <Button onClick={onClose} variant="contained">
          {t('close')}
        </Button>
        <Button onClick={handleSave} variant="contained" disabled={!isValid}>
          {t('save')}
        </Button>
      </Box>
    </Fragment>
  )
}

export default ResponseTemplateDetails
