import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Form, FormikProvider, useFormik } from 'formik'
import { ListBox } from 'primereact/listbox'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '@/components/LoadingSpinner'
import { useGetCustomer } from '@/domains/customers/hooks'
import { useGetSearchedCustomers } from '@/domains/deals/hooks/getSearchedCustomers'
import FormikInput from '@/redesign/components/FormikFields/FormikInput/FormikInput'
import { Customer, Scalars } from '@/schemaTypes'

export interface CustomerSelectionProps {
  selectedCustomerId: string | null
  setSelectedCustomerId: (customerId: string | null) => void
}

const CustomerSelection = (props: CustomerSelectionProps) => {
  const { selectedCustomerId, setSelectedCustomerId } = props
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      customerNumber: '',
    },
    onSubmit: () => {
      //
    },
  })

  const { customer, queryResult: queryResultCustomer } = useGetCustomer({
    variables: {
      customerId: selectedCustomerId,
    },
  })

  const { customers, queryResult: queryResultSearchedCustomer } =
    useGetSearchedCustomers({
      variables: {
        opts: {
          firstname: formik.values.firstname,
          lastname: formik.values.lastname,
          email: formik.values.email,
          customerNumber: formik.values.customerNumber,
        },
      },
    })

  const onSearchResult = (selectedId: Scalars['ObjectId']) => {
    if (selectedId) {
      setSelectedCustomerId(selectedId)
    }
  }

  useEffect(() => {
    setSelectedCustomerId(null)
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.firstname,
    formik.values.lastname,
    formik.values.email,
    formik.values.customerNumber,
  ])

  const renderLoadingSpinner = () => (
    <div>
      <LoadingSpinner />
    </div>
  )

  const customerListTemplate = useCallback(
    (option) => {
      if (option.customerNumber.toString() === '1') {
        return <p className="font-extrabold">{t('customer.create')}</p>
      }

      return (
        <div className="flex flex-col">
          <div>
            <p className="font-semibold">
              {option.firstname} {option.lastname}
            </p>
            {/* Translation - TODO */}
            <p>{`Email: ${option.email} | Customer No: ${option.customerNumber}`}</p>
          </div>
        </div>
      )
    },
    [t],
  )

  const renderCustomerList = () => {
    return (
      <ListBox
        onChange={(e) => {
          e.value.customerNumber.toString() !== '1'
            ? onSearchResult(e.value._id)
            : window.open('/inApp/customers/create', '_blank')
        }}
        options={customers}
        optionLabel="name"
        itemTemplate={customerListTemplate}
        className="w-full md:w-14rem"
        listStyle={{ maxHeight: '200px', height: '200px' }}
        listClassName="p-0"
        emptyMessage={t('deal.no_customer_found')}
      />
    )
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <FormikInput
            name="firstname"
            label={t('customer.firstname')}
            placeholder={t('customer.firstname')}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />

          <FormikInput
            name="lastname"
            label={t('customer.lastname')}
            placeholder={t('customer.lastname')}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />

          <FormikInput
            name="email"
            label={t('email')}
            placeholder={t('email')}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />

          <FormikInput
            name="customerNumber"
            type="number"
            label={t('customer.customer_number')}
            placeholder={t('customer.customer_number')}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />
        </Form>
      </FormikProvider>
      <div className="mt-6 h-52">
        {queryResultSearchedCustomer.loading && renderLoadingSpinner()}

        {customer && selectedCustomerId && (
          <SelectedCustomerCard customerData={customer} />
        )}

        {queryResultCustomer.loading && renderLoadingSpinner()}

        {!queryResultSearchedCustomer.loading &&
          !selectedCustomerId &&
          !queryResultCustomer.loading &&
          renderCustomerList()}
      </div>
    </>
  )
}

const SelectedCustomerCard = ({ customerData }: { customerData: Customer }) => (
  <CustomerCard className="flex flex-col p-3.5">
    <CustomerElement>
      <IconStyled className="pi pi-id-card" />
      <TextStyled>
        {customerData.firstname + ' ' + customerData.lastname}
      </TextStyled>
    </CustomerElement>
    <CustomerElement>
      <IconStyled className="pi pi-users" />
      <TextStyled>{customerData.customerNumber}</TextStyled>
    </CustomerElement>
    <CustomerElement>
      <IconStyled className="pi pi-calendar" />
      <TextStyled>
        {dayjs(customerData.dateOfBirth).format('DD.MM.YYYY')}
      </TextStyled>
    </CustomerElement>
    <CustomerElement>
      <IconStyled className="pi pi-send" />
      <TextStyled>{customerData.email}</TextStyled>
    </CustomerElement>
    <CustomerElement>
      <IconStyled className="pi pi-phone" />
      <TextStyled>{customerData.phone}</TextStyled>
    </CustomerElement>
    <CustomerElement>
      <IconStyled className="pi pi-user" />
      <TextStyled className="text-ellipsis line-clamp-6">
        {customerData.sex ?? '-'}
      </TextStyled>
    </CustomerElement>
  </CustomerCard>
)

const CustomerCard = styled.div`
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  background: var(--surface-50, #fafafa);
`

const CustomerElement = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.4375rem;
  margin-bottom: 0.5rem;
`

const TextStyled = styled.p`
  color: var(--global-textColor, #495057);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.875rem;
`

const IconStyled = styled.i`
  font-size: 1.1rem;
  color: #9e9e9e;
`
export default CustomerSelection
