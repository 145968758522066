import { isArray } from 'util'
import {
  EBillingPeriodType,
  EDealType,
  EFeeLevel,
  EFeeMode,
  EFeeType,
  ManualFeeDefinitionArgs,
  Scalars,
} from '@/schemaTypes'
import { FEE_MODE } from './constants'
import {
  mapAndBuildSum as calcSum,
  decimal,
  getBillingPeriodIterations,
} from './helperFunctions'

export function calcFeeTotalAmount({
  fee,
  durationInDays,
  payoutAmount = decimal(0),
}) {
  const iterations = getBillingPeriodIterations(
    durationInDays,
    fee.billingPeriod,
  )

  let feeTotalAmount

  switch (fee.mode) {
    case FEE_MODE.CONSTANT:
      feeTotalAmount = fee.amount.times(iterations)
      break
    case FEE_MODE.MULTIPLIER:
      feeTotalAmount = fee.amount.times(payoutAmount).times(iterations)
      break
    default:
      throw Error(`Unknown fee mode: ${fee.mode}`)
  }

  return feeTotalAmount
}

export function calcFeesTotalAmount({
  fees,
  durationInDays,
  payoutAmount = decimal(0),
}) {
  const feesTotalAmount = calcSum(fees, (fee) =>
    calcFeeTotalAmount({ fee, durationInDays, payoutAmount }),
  )

  return feesTotalAmount
}

export function aggregateFeesPerType({ items, pawnFees }) {
  const itemFeesPerType = {}
  const pawnFeesPerType = {}

  items.forEach((item) => {
    item.fees.forEach((fee) => {
      const feePerType = { itemTitle: item.title, ...fee }

      if (isArray(itemFeesPerType[fee.type])) {
        itemFeesPerType[fee.type].push(feePerType)
      } else {
        itemFeesPerType[fee.type] = [feePerType]
      }
    })
  })

  pawnFees.forEach((fee) => {
    const feePerType = { ...fee }

    if (isArray(itemFeesPerType[fee.type])) {
      pawnFeesPerType[fee.type].push(feePerType)
    } else {
      pawnFeesPerType[fee.type] = [feePerType]
    }
  })

  return {
    itemFeesPerType,
    pawnFeesPerType,
  }
}

export const companyManualFees = (
  dealType: EDealType,
): {
  companyId: Scalars['ObjectId']
  manualFees: ManualFeeDefinitionArgs[]
}[] => [
  {
    companyId: '6033b1dd3b41e57aaa8065d9',
    manualFees: [
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.HalfMonth,
        feeType: EFeeType.Interest,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.MultiplierPayout,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.InitialStaggered,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.PickupTransport,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.DropoffTransport,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.Discount,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.DiscountFirstMonth,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.Withdrawal,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.HalfMonth,
        feeType: EFeeType.Manipulation,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.MultiplierPayout,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.Storage,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.Cleaning,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.Verification,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.LiquidationFee,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.Other,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.ThirdPartyCosts,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.CashExpenditures,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
    ],
  },
  {
    companyId: '632d8bea13557d052466110f',
    manualFees: [
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.Month,
        feeType: EFeeType.Interest,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.MultiplierPayout,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.Month,
        feeType: EFeeType.InitialStaggered,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.Custome,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.PickupTransport,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.DropoffTransport,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.Discount,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.DiscountFirstMonth,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Deal,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.Storage,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.Cleaning,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.Verification,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.LiquidationFee,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.Other,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.ThirdPartyCosts,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
      {
        amount: 0,
        billingPeriodType: EBillingPeriodType.OneTime,
        feeType: EFeeType.CashExpenditures,
        isActive: true,
        isManual: true,
        level: EFeeLevel.Item,
        mode: EFeeMode.Constant,
        ust: 0,
        dealType,
      },
    ],
  },
]

export const FEES_WITH_UST = [
  EFeeType.Other,
  EFeeType.ThirdPartyCosts,
  EFeeType.LiquidationFee,
  EFeeType.Withdrawal,
]
