import { Paper, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import dayjs from 'dayjs'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Customer } from '@/schemaTypes'
import styles from './AuctionElementComponent.module.scss'

export interface AuctionElementCustomerOverViewProps {
  customer: Customer
}

const AuctionElementCustomerOverView: React.FC<
  AuctionElementCustomerOverViewProps
> = ({ customer }) => {
  const { t } = useTranslation()

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Typography variant="h6" gutterBottom className={'u-flex u-jc-sb'}>
        {t('customer_overview')}
      </Typography>
      <Table className={`${styles.auctionDealValuesTable}`} size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {t('id')}
            </TableCell>
            <TableCell component="th" scope="row">
              {customer._id}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {t('customer.firstname')}
            </TableCell>
            <TableCell component="th" scope="row">
              {customer.firstname}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {t('customer.lastname')}
            </TableCell>
            <TableCell component="th" scope="row">
              {customer.lastname}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {t('customer.email')}
            </TableCell>
            <TableCell component="th" scope="row">
              {customer.email}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {t('customer.phone')}
            </TableCell>
            <TableCell component="th" scope="row">
              {customer.phone}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {t('customer.date_of_birth')}
            </TableCell>
            <TableCell component="th" scope="row">
              {dayjs(customer.dateOfBirth).format('DD.MM.YYYY')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

export default AuctionElementCustomerOverView
