import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import {
  Query,
  QueryGetCashBookCountArgs,
  QueryGetLatestCashFlowBalanceForDateArgs,
} from '@/schemaTypes'

export const GET_CASH_BOOK_COUNT = gql`
  query getCashBookCount(
    $opts: CashBookCountOpts!
    $filterArgs: CashBookCashFlowLatestBalanceFilterArgs!
  ) {
    getCashBookCount(opts: $opts) {
      ...CashBookCountFragment
    }
    getLatestCashFlowBalanceForDate(filterArgs: $filterArgs)
  }
  ${fragments.cashBookCount}
`

type TData = {
  getCashBookCount: Query['getCashBookCount']
  getLatestCashFlowBalanceForDate: Query['getLatestCashFlowBalanceForDate']
}
type TVariables = QueryGetCashBookCountArgs &
  QueryGetLatestCashFlowBalanceForDateArgs

export function useGetCashBookCount(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_CASH_BOOK_COUNT, opts)

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    cashBookCount: queryResult.data?.getCashBookCount,
    latestBalance: queryResult.data?.getLatestCashFlowBalanceForDate,
  }
}
