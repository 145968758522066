import dayjs from 'dayjs'
import { useMemo, useRef, useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetExistingDealCalculation } from '@/domains/deals/hooks'
import { useUpdatePreviewCalculation } from '@/domains/deals/hooks/updatePreviewCalculation'
import { useMutationShowingErrors } from '@/hooks'
import {
  Deal,
  DealFinalValuesArgs,
  ETransportMode,
  GetExistingDealCalculationArgs,
} from '@/schemaTypes'
import {
  getLatestEventForStatus,
  isDealClosed,
  isDealVerified,
} from '@/utils/deal'
import { getDurationInDays } from '@/utils/time'
import DealCalculationTable from './DealCalculationTable'

interface DealEventsCalculationOverviewProps {
  dealQueryResult
  deal: Deal
}

const DealEventsCalculationOverview = (
  props: DealEventsCalculationOverviewProps,
) => {
  const { deal, dealQueryResult } = props
  const { t } = useTranslation()
  const today = dayjs().toDate()
  const isClosed = isDealClosed(deal)
  const updatePreviewRef = useRef(false)

  const [manualDurationInDays] = useState(deal.dealFinalValues.durationInDays)
  const isVerifiedDeal = isDealVerified(deal)

  const statusEvents = useMemo(() => {
    const bookedDealEvent = getLatestEventForStatus(deal, 'DealBookedEvent')
    const verifiedDealEvent = getLatestEventForStatus(deal, 'DealVerifiedEvent')
    const paybackOrExtensionConfirmed =
      getLatestEventForStatus(deal, 'DealPaybackConfirmedEvent') ??
      getLatestEventForStatus(deal, 'DealExtensionConfirmedEvent')
    const closedEvent = getLatestEventForStatus(deal, 'DealClosedEvent')

    return {
      bookedDealEvent,
      verifiedDealEvent,
      paybackOrExtensionConfirmed,
      closedEvent,
    }
  }, [deal])

  const durationInDays = useMemo(
    () =>
      isClosed || !statusEvents.verifiedDealEvent
        ? deal.dealFinalValues.durationInDays
        : getDurationInDays(today, deal.dealStartDate),
    [
      deal.dealFinalValues.durationInDays,
      deal.dealStartDate,
      isClosed,
      today,
      statusEvents.verifiedDealEvent,
    ],
  )

  const dealFinalCalculation = statusEvents.paybackOrExtensionConfirmed
    ? statusEvents.paybackOrExtensionConfirmed.dealCalculation
    : statusEvents.closedEvent
      ? statusEvents.closedEvent.dealCalculation
      : undefined

  const persistedDealCalculation =
    statusEvents.paybackOrExtensionConfirmed &&
    statusEvents.paybackOrExtensionConfirmed.dealCalculation
      ? statusEvents.paybackOrExtensionConfirmed.dealCalculation
      : statusEvents.verifiedDealEvent &&
          statusEvents.verifiedDealEvent.dealCalculation
        ? statusEvents.verifiedDealEvent.dealCalculation
        : statusEvents.bookedDealEvent &&
            statusEvents.bookedDealEvent.dealCalculation
          ? statusEvents.bookedDealEvent.dealCalculation
          : undefined

  const [dealFinalValuesArgs, setDealFinalValuesArgs] =
    useState<DealFinalValuesArgs>({
      dealType: deal.dealFinalValues.dealType,
      durationInDays: deal.dealFinalValues.durationInDays,
      payoutAmount: deal.dealFinalValues.payoutAmount,
      shouldOverwritePayoutAmount:
        deal.dealFinalValues.shouldOverwritePayoutAmount,
    })

  const [existingDealCalculationArgs, setExistingDealCalculationArgs] =
    useState<GetExistingDealCalculationArgs>({
      dealType: deal.dealFinalValues.dealType,
      dropoffTransportMode: deal.dropoffData
        ? deal.dropoffData.transportMode
        : ETransportMode.Shop,
      durationInDays: durationInDays,
      shouldOverwritePayoutAmount:
        deal.dealFinalValues.shouldOverwritePayoutAmount,
      overwrittenPayoutAmount: deal.dealFinalValues.payoutAmount,
      shouldOverwriteManualFees: false,
      manualFeeDefinitionsArgs: [],
      isForCustomer: true,
    })

  const { dealCalculation: existingCaluclation } =
    useGetExistingDealCalculation({
      variables: {
        dealId: props.deal._id,
        args: existingDealCalculationArgs,
      },
      skip: isClosed,
    })

  useEffect(() => {
    if (existingCaluclation) {
      updatePreviewRef.current = true
      setDealFinalValuesArgs({
        dealType: existingDealCalculationArgs.dealType,
        durationInDays: existingDealCalculationArgs.durationInDays,
        payoutAmount: existingCaluclation.dealValuesEntry.payoutAmount,
        shouldOverwritePayoutAmount:
          existingDealCalculationArgs.shouldOverwritePayoutAmount,
      })
    }
  }, [existingDealCalculationArgs, existingCaluclation])

  const updatePreview = useMutationShowingErrors({
    mutation: useUpdatePreviewCalculation(),
    successMessage: t('updated'),
  })

  const updatePreviewCalculation = () => {
    updatePreview({
      variables: {
        dealId: deal._id,
        dealFinalValuesArgs: dealFinalValuesArgs,
      },
      onCompleted: () => {
        if (dealQueryResult) dealQueryResult.refetch()
        updatePreviewRef.current = false
      },
    })
  }

  const resetPreviewCalculation = () => {
    setExistingDealCalculationArgs({
      dealType: deal.dealType,
      dropoffTransportMode: deal.dropoffData
        ? deal.dropoffData.transportMode
        : ETransportMode.Shop,
      durationInDays: durationInDays,
      shouldOverwritePayoutAmount:
        deal.dealFinalValues.shouldOverwritePayoutAmount,
      overwrittenPayoutAmount: deal.dealFinalValues.payoutAmount,
      shouldOverwriteManualFees: false,
      manualFeeDefinitionsArgs: [],
      isForCustomer: true,
    })
  }

  return (
    <DealCalculationTable
      deal={deal}
      bookedDealCalculation={statusEvents.bookedDealEvent?.dealCalculation}
      verifiedDealCalculation={statusEvents.verifiedDealEvent?.dealCalculation}
      dealFinalCalculation={dealFinalCalculation}
      existingCaluclation={existingCaluclation}
      persistedDealCalculation={persistedDealCalculation}
      isDealClosed={isClosed}
      existingDealCalculationArgs={existingDealCalculationArgs}
      setExistingDealCalculationArgs={setExistingDealCalculationArgs}
      closedEvent={statusEvents.closedEvent}
      paybackOrExtensionConfirmed={statusEvents.paybackOrExtensionConfirmed}
      isVerifiedDeal={isVerifiedDeal}
      manualDurationInDays={manualDurationInDays}
      updatePreviewCompleted={updatePreviewRef.current}
      resetPreviewCalculation={resetPreviewCalculation}
      updatePreviewCalculation={updatePreviewCalculation}
    />
  )
}

export default DealEventsCalculationOverview
