import { AuctionItem, StorageUnit } from '@/schemaTypes'

const getStorageUnitName = (
  storageUnits: StorageUnit[],
  storageUnitId?: string | null,
) => {
  return storageUnits?.find((s) => s._id === storageUnitId)?.name
}

export const getStoragePlace = (
  item: Omit<AuctionItem, 'buyer'>,
  storageUnits: StorageUnit[],
) => {
  const storageHistory = item.item?.storageHistory?.[0]
  const lv1 = getStorageUnitName(
    storageUnits,
    storageHistory?.storageUnits.lvl1,
  )
  const lv2 = getStorageUnitName(
    storageUnits,
    storageHistory?.storageUnits.lvl2,
  )
  const lv3 = getStorageUnitName(
    storageUnits,
    storageHistory?.storageUnits.lvl3,
  )
  const lv4 = getStorageUnitName(
    storageUnits,
    storageHistory?.storageUnits.lvl4,
  )

  let storagePlace = null
  if (lv1) {
    storagePlace = lv1
  }
  if (lv2) {
    storagePlace += ' > ' + lv2
  }
  if (lv3) {
    storagePlace += ' > ' + lv3
  }
  if (lv4) {
    storagePlace += ' > ' + lv4
  }

  return storagePlace
}
