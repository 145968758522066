import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { defaultCompany } from '@/domains/deals/components/constants/company'
import { useShowErrorsPopup } from '@/hooks'
import {
  LoanToValuesEntry,
  Query,
  QueryGetLoanToValuesEntryArgs,
} from '@/schemaTypes'

const GET_LOAN_TO_VALUES_ENTRY = gql`
  query getLoanToValuesEntry($loanToValuesEntryId: ObjectId!) {
    getLoanToValuesEntry(loanToValuesEntryId: $loanToValuesEntryId) {
      _id
      validFrom
      categoryValues
    }
  }
`

type TData = { getLoanToValuesEntry: Query['getLoanToValuesEntry'] }
type TVariables = QueryGetLoanToValuesEntryArgs

export const defaultLoanToValuesEntry: LoanToValuesEntry = {
  _id: '',
  validFrom: null,
  company: defaultCompany,
  categoryValues: {
    Elektronik: {
      default: 0.6,
    },
    Uhren: {
      default: 0.7,
    },
    Schmuck: {
      default: 0.8,
    },
    'Münzen & Barren': {
      default: 0.8,
    },
    Taschen: {
      default: 0.6,
    },
    default: 0.7,
  },
}

export function useGetLoanToValuesEntry(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_LOAN_TO_VALUES_ENTRY, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.loanToValuesEntryId,
  })

  useShowErrorsPopup(queryResult.error)

  const loanToValuesEntry = opts.variables?.loanToValuesEntryId
    ? queryResult.data?.getLoanToValuesEntry
    : defaultLoanToValuesEntry

  return { queryResult, loanToValuesEntry }
}
