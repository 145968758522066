import React, { ComponentProps, useState } from 'react'
import { ReactSVG } from 'react-svg'
import AmountInput from '@/components/AmountInput'
import SaveIcon from '@/images/save.svg'
import styles from './Item.module.scss'

export interface AmountRowProps {
  label: string
  defaultValue: number | undefined
  onChange: (value: number | undefined) => void
  amountInputProps?: ComponentProps<typeof AmountInput>
}

const AmountRow: React.FC<AmountRowProps> = ({
  label,
  defaultValue,
  onChange,
  amountInputProps,
}) => {
  const [value, setValue] = useState(defaultValue)

  return (
    <tr style={{ width: '100%' }}>
      <td style={{ width: '40%' }}>
        <p>{label}:</p>
      </td>
      <td style={{ display: 'flex', alignItems: 'center' }}>
        <AmountInput
          value={value}
          onChange={(value) => setValue(value)}
          {...amountInputProps}
        />
        <ReactSVG
          className={`${styles.saveIcon} u-pl-15`}
          src={SaveIcon}
          onClick={() => onChange(value)}
        />
      </td>
    </tr>
  )
}

export default AmountRow
