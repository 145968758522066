import classNames from 'classnames'
import { Avatar } from 'primereact/avatar'
import { Menu } from 'primereact/menu'
import { MenuItem } from 'primereact/menuitem'
import { Toast } from 'primereact/toast'
import React, { SyntheticEvent, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Employee } from '@/schemaTypes'

interface IProfileDropdown {
  currentUser: Employee
  onEmployeeChangeShop: () => void
  onLogout: () => void
}

const ProfileDropdown: React.FC<IProfileDropdown> = ({
  currentUser,
  onEmployeeChangeShop,
  onLogout,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dropdownMenu = useRef<Menu>(null)
  const toast = useRef<Toast>(null)

  useEffect(() => {
    const handleScroll = (event: Event) => {
      dropdownMenu.current?.hide(event as unknown as SyntheticEvent)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const avatarLetters = useMemo(() => {
    return `${currentUser?.firstname.charAt(0)}${currentUser?.lastname.charAt(0)}`
  }, [currentUser?.firstname, currentUser?.lastname])

  const profileItems = useMemo(
    (): MenuItem[] => [
      {
        command: () => {
          toast.current?.show({
            severity: 'info',
            summary: 'Info',
            detail: 'Item Selected',
            life: 3000,
          })
        },
        template: (_, options) => (
          <div
            className={classNames(
              options.className,
              'flex align-items-center p-2 pl-4',
            )}
          >
            <div className="flex flex-column align">
              <span className="font-bold">
                {currentUser?.firstname} {currentUser?.lastname}
              </span>
            </div>
          </div>
        ),
      },
      { separator: true },
      {
        label: t('change_shop_menu'),
        icon: 'pi pi-fw pi-map-marker',
        command: onEmployeeChangeShop,
      },
      {
        label: t('change_password_menu'),
        icon: 'pi pi-fw pi-key',
        command: () => history.push('/inApp/change-password'),
      },
      {
        label: t('logout_menu'),
        icon: 'pi pi-fw pi-sign-out',
        command: onLogout,
      },
    ],
    [
      currentUser?.firstname,
      currentUser?.lastname,
      t,
      history,
      onEmployeeChangeShop,
      onLogout,
    ],
  )

  if (!currentUser) {
    return null
  }

  return (
    <>
      <Toast ref={toast} />
      <Menu
        model={profileItems}
        popup
        ref={dropdownMenu}
        className="mt-0.5"
        popupAlignment="right"
      />
      <Avatar
        onClick={(event) => dropdownMenu.current?.toggle(event)}
        label={avatarLetters}
        className="pb-0"
        size="large"
        shape="circle"
      />
    </>
  )
}

export default ProfileDropdown
