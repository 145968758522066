import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationChangeItemStatusArgs } from '@/schemaTypes'
import { itemFragments } from './itemFragments'

const CHANGE_ITEM_STATUS = gql`
  mutation changeItemStatus(
    $itemId: ObjectId!
    $newEvent: ItemEventCreateArgs!
    $isStatusAddedManually: Boolean!
    $shippingLabelCreateArgs: ShippingLabelCreateArgs
    $relocateItem: Boolean
    $relocateNote: String
    $addItemToStorageUnitArgs: AddItemToStorageUnitArgs
  ) {
    changeItemStatus(
      itemId: $itemId
      newEvent: $newEvent
      isStatusAddedManually: $isStatusAddedManually
      shippingLabelCreateArgs: $shippingLabelCreateArgs
      relocateItem: $relocateItem
      relocateNote: $relocateNote
      addItemToStorageUnitArgs: $addItemToStorageUnitArgs
    ) {
      _id
      serialNumber
      imei
      itemSpecification
      note
      policeNumber
      isDamageCase
      damageCasePeriods {
        from
        to
      }
      isForPersonalUse
      personalUsePeriods {
        from
        to
      }
      isInvestmentGold
      createdAt
      updatedAt
      ean
      storageFacilityId
      storageFacility {
        _id
        storageUnitIds
      }
      media {
        ...FileMetadataFragment
      }
      dealsHistory {
        dealId
        algoliaReference
        title
        material
        answers {
          questionId
          selectedOptionIndex
          rangeValue
        }
        questions {
          ...ItemQuestionFragment
        }
        questionIds
        itemCategoryId
        values {
          verified {
            currentMarketValue
            predictedMarketValue
            adjustedMarketValue
            loanToValue
            maxMarketValue
          }
        }
      }
      lastBookingNumber
      bookingNumbers
      piceaUploadLink {
        ...FileMetadataFragment
      }
      entrupyCertificateUrl
      checkedBy
      checkedAt
      uploadedFiles {
        ...FileMetadataFragment
      }
      storageHistory {
        storageUnits {
          lvl1
          lvl2
          lvl3
          lvl4
        }
        createdAt
        timestamp
        storageStatus
        employeeId
        note
      }
      storageLabel
      events {
        ...ItemEventFragment
      }
    }
  }
  ${fragments.fileMetadata}
  ${itemFragments.itemQuestionFragment}
  ${itemFragments.itemEvent}
`

type TData = { changeItemStatus: Mutation['changeItemStatus'] }
type TVariables = MutationChangeItemStatusArgs

export function useChangeItemStatus(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [changeItemStatus] = useMutation<TData, TVariables>(
    CHANGE_ITEM_STATUS,
    opts,
  )

  return changeItemStatus
}
