import {
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import { Fragment, useContext, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import AmountInput from '@/components/AmountInput'
import { Context } from '@/context'
import SaveIcon from '@/images/save.svg'
import {
  Deal,
  EItemStatusType,
  ESalesPartnerType,
  Item,
  ItemEvent,
} from '@/schemaTypes'
import { getNow } from '@/utils/time'
import ItemRefurbedOfferedToSellStatus from './ItemRefurbedOfferedToSellStatus'
import { EHintType } from './ItemStatus'
import styles from './ItemStatus.module.scss'

export interface ItemOfferedToSellStatusProps {
  deal: Deal
  item: Item
  onChangeItemStatus: (newEvent: ItemEvent, hint: EHintType) => void
  updateStatusLoading: boolean
  isLockedSmartPhone?: boolean
}

const adsCount = [1]

const ItemOfferedToSellStatus: FunctionComponent<
  ItemOfferedToSellStatusProps
> = (props) => {
  const {
    onChangeItemStatus,
    item,
    deal,
    updateStatusLoading,
    isLockedSmartPhone,
  } = props
  const { showInfo, getCurrentUser } = useContext(Context)
  const { t } = useTranslation()
  const salesPartnersList = Object.values(ESalesPartnerType)

  const [adsPartner, setAdsPartner] = useState<string[]>([''])
  const [adsLink, setAdsLink] = useState<string[]>([''])
  const [adsHint, setAdsHint] = useState<EHintType[]>([EHintType.NONE])
  const [offeredPrice, setOfferedPrice] = useState<number>(0)

  const [description, setDescription] = useState('')

  const [renderCount, setRenderCount] = useState<number>(0)
  const isRefurbedOffer = adsPartner.includes(ESalesPartnerType.Refurbed)

  const saveNewStatus = () => {
    let newStatusWarning: EHintType = EHintType.NONE
    if (
      adsPartner.length === 0 ||
      adsPartner[0].length === 0 ||
      adsPartner.length !== adsCount.length
    ) {
      return showInfo(t('sale_partner_is_not_set'))
    }

    if (!offeredPrice) {
      return showInfo(t('offered_price_is_not_set'))
    }
    const errorExists = adsHint.filter(
      (c) => c === EHintType.ERROR_LOCKED_CELLPHONE_ON_REFURBED,
    )
    if (errorExists && errorExists.length > 0) {
      return showInfo(t('error.error_locked_cellphone_on_refurbed'))
    }
    const warningExists = adsHint.filter(
      (c) => c === EHintType.WARNING_SELL_NEW_ITEM_ON_REFURBED,
    )
    if (warningExists && warningExists.length) {
      newStatusWarning = EHintType.WARNING_SELL_NEW_ITEM_ON_REFURBED
    }

    const now = getNow()
    onChangeItemStatus(
      {
        createdAt: now,
        timestamp: now,
        event: EItemStatusType.OfferedToSell,
        meta: {
          advertiserPlatformName: adsPartner as ESalesPartnerType[],
          advertisementLink: adsLink as string[],
          description: description,
          offeredPrice,
          offeredPriceAt: now,
          creatorId: getCurrentUser()?._id,
        },
      },
      newStatusWarning,
    )
  }

  const handleAdsPartner = (newAdsPartner: ESalesPartnerType, key: number) => {
    const temp = adsPartner
    temp[key - 1] = newAdsPartner as string
    setAdsPartner(temp)
    handleAdsHint(key)
    setRenderCount(renderCount + 1)
  }

  const handleAdsLink = (newLink: string, key: number) => {
    const temp = adsLink
    temp[key - 1] = newLink
    setAdsLink(temp)
    setRenderCount(renderCount + 1)
  }

  const handleAdsHint = (key: number) => {
    const tempHint = adsHint
    tempHint[key - 1] = EHintType.NONE
    const partner = adsPartner[key - 1]

    let simLockQuestionId
    let isOpenForAllNetworks
    let brandNewQuestionId
    let isBrandNew

    if (
      item.dealsHistory &&
      item.dealsHistory.length > 0 &&
      item.dealsHistory[item.dealsHistory.length - 1].questions
    ) {
      // Rule 1
      // If our item (ususally cellphone) is not open for all networks, we cannot sell it on refurbed!
      const filteredQuestionsForSimLock = item.dealsHistory[
        item.dealsHistory.length - 1
      ].questions?.filter(
        (c) => c.titleKey === 'QUESTION_OPEN_FOR_ALL_CARRIERS',
      )
      simLockQuestionId =
        filteredQuestionsForSimLock && filteredQuestionsForSimLock.length > 0
          ? filteredQuestionsForSimLock[0]._id
          : null
      let answers = item.dealsHistory[
        item.dealsHistory.length - 1
      ].answers?.filter((c) => c.questionId === simLockQuestionId)
      isOpenForAllNetworks =
        answers && answers?.length > 0 && answers[0].selectedOptionIndex === 0
          ? true
          : false

      // Rule 2
      // If our item is Brand New, it is not recommended to sell it on refurbed!
      const filteredQuestionsForBrandNew = item.dealsHistory[
        item.dealsHistory.length - 1
      ].questions?.filter((c) => c.titleKey === 'QUESTION_OPTICAL_CONDITION')
      brandNewQuestionId =
        filteredQuestionsForBrandNew && filteredQuestionsForBrandNew.length > 0
          ? filteredQuestionsForBrandNew[0]._id
          : null
      answers = item.dealsHistory[item.dealsHistory.length - 1].answers?.filter(
        (c) => c.questionId === brandNewQuestionId,
      )
      isBrandNew =
        answers && answers?.length > 0 && answers[0].selectedOptionIndex === 0
          ? true
          : false
    }

    if (partner === ESalesPartnerType.Refurbed) {
      // Order is important because error is more important than warning
      if (simLockQuestionId && isOpenForAllNetworks === false)
        tempHint[key - 1] = EHintType.ERROR_LOCKED_CELLPHONE_ON_REFURBED
      else if (brandNewQuestionId && isBrandNew === true)
        tempHint[key - 1] = EHintType.WARNING_SELL_NEW_ITEM_ON_REFURBED
    }

    setAdsHint(tempHint)
    setRenderCount(renderCount + 1)
  }

  const onBlurOfferedPrice = () => {
    if (
      item.internalSaleData?.price &&
      offeredPrice &&
      item.internalSaleData.price > offeredPrice
    ) {
      showInfo(t('ad_price_lower_than_internal_price'))
    }
  }

  return (
    <Fragment>
      {!isRefurbedOffer && (
        <div
          onClick={saveNewStatus}
          className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
        >
          <ReactSVG className={`${styles.saveIcon} u-pl-15`} src={SaveIcon} />
        </div>
      )}
      <table style={{ width: '100%' }}>
        {adsCount.map((key) => {
          return (
            <Fragment>
              <tr key={key} className={`${styles.notSellableTr}`}>
                <td>
                  {t('advertisement_partner')}:
                  <Select
                    className="u-ml-sm"
                    value={adsPartner[key - 1]}
                    onChange={(e) => {
                      handleAdsPartner(e.target.value as ESalesPartnerType, key)
                    }}
                  >
                    {salesPartnersList.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        disabled={
                          name === ESalesPartnerType.Refurbed &&
                          isLockedSmartPhone
                        }
                      >
                        {t(name)}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              {isRefurbedOffer ? (
                <ItemRefurbedOfferedToSellStatus
                  deal={deal}
                  item={item}
                  onChangeItemStatus={onChangeItemStatus}
                  updateStatusLoading={updateStatusLoading}
                />
              ) : (
                <>
                  <tr className={`${styles.notSellableTr}`}>
                    <td>
                      {t('price')}:
                      <AmountInput
                        onChange={setOfferedPrice}
                        onBlur={onBlurOfferedPrice}
                      />
                    </td>
                  </tr>
                  <tr className={`${styles.notSellableTr}`}>
                    <td>
                      {t('advertisement_link')}:
                      {adsLink[key - 1].length > 0 ? (
                        <a
                          target="_blank"
                          style={{ marginLeft: '1.5rem', fontSize: '0.7rem' }}
                          href={`${adsLink[key - 1]}`}
                          rel="noreferrer"
                        >
                          <LinkIcon style={{ marginBottom: '-0.5rem' }} />
                        </a>
                      ) : (
                        ''
                      )}
                      <TextField
                        style={{ width: '100%' }}
                        onChange={(e) => handleAdsLink(e.target.value, key)}
                      />
                    </td>
                  </tr>
                </>
              )}
            </Fragment>
          )
        })}

        {!isRefurbedOffer && (
          <tr className={`${styles.notSellableTr}`}>
            <td>
              <br />
              {t('description')}:
              <TextareaAutosize
                rowsMin={4}
                placeholder={t('any_description_want_to_add')}
                style={{ width: '100%' }}
                onChange={(e) => setDescription(e.target.value)}
              />
            </td>
          </tr>
        )}
      </table>
    </Fragment>
  )
}

export default ItemOfferedToSellStatus
