import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Mutation,
  MutationRegenerateValorizationReceiptsArgs,
} from '@/schemaTypes'

const REGENERATE_VALORIZATION_FINAL_RECEIPT = gql`
  mutation regenerateValorizationReceipts($valorizationEntryId: ObjectId!) {
    regenerateValorizationReceipts(valorizationEntryId: $valorizationEntryId) {
      _id
    }
  }
`

type TData = {
  regenerateValorizationReceipts: Mutation['regenerateValorizationReceipts']
}
type TVariables = MutationRegenerateValorizationReceiptsArgs

export function useRegenerateValorizationReceipts(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [regenerateValorizationReceipts] = useMutation<TData, TVariables>(
    REGENERATE_VALORIZATION_FINAL_RECEIPT,
    opts,
  )

  return regenerateValorizationReceipts
}
