import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { auctionFragments } from '@/domains/auctions/hooks/auctionDetails/auctionFragments'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import {
  ELanguageCode,
  Query,
  QueryGetAuctionByItemIdArgs,
} from '@/schemaTypes'

const GET_AUCTION_BY_ITEM_ID = gql`
  query getAuctionByItemId($itemId: ObjectId!, $languageCode: ELanguageCode!) {
    getAuctionByItemId(itemId: $itemId) {
      ...AuctionDetailsFragment
    }
  }
  ${fragments.customDealFormQuestion}
  ${fragments.formAnswers}
  ${auctionFragments.auctionDetails}
  ${fragments.address}
`

type TData = { getAuctionByItemId: Query['getAuctionByItemId'] }
type TVariables = { languageCode?: ELanguageCode } & QueryGetAuctionByItemIdArgs

export function useGetAuctionByItemId(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_AUCTION_BY_ITEM_ID, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, auction: queryResult.data?.getAuctionByItemId }
}
