import { Container } from '@material-ui/core'
import React from 'react'

function ProductPriceHistories() {
  return (
    <Container className="u-p" maxWidth={false}>
      <></>
    </Container>
  )
}

export default ProductPriceHistories
