import { itemFragments } from '..'
import { dealCalculationFragments, dealFragments } from '../dealFragments'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { useMutationShowingErrors } from '@/hooks'
import { Mutation, MutationVerifyDealArgs, VerifyDealArgs } from '@/schemaTypes'

const VERIFY_DEAL = gql`
  mutation verifyDeal($verifyDealArgs: VerifyDealArgs!) {
    verifyDeal(verifyDealArgs: $verifyDealArgs) {
      _id
      events {
        ...DealEventFragment
      }
      pawnData {
        ...PawnDataFragment
      }
      purchaseData {
        ...PurchaseDataFragment
      }
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
    }
  }
  ${dealFragments.event}
  ${dealFragments.purchaseData}
  ${dealFragments.pawnData}
  ${itemFragments.itemValuesEntry}
  ${dealFragments.dealItem}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = { verifyDeal: Mutation['verifyDeal'] }
type TVariables = MutationVerifyDealArgs

export function useOnDealStatusVerified(verifyDealArgs: VerifyDealArgs) {
  const { t } = useTranslation()

  const [verifyDeal] = useMutation<TData, TVariables>(VERIFY_DEAL, {
    variables: { verifyDealArgs },
  })

  return useMutationShowingErrors({
    mutation: verifyDeal,
    successMessage: t('update_successful'),
  })
}
