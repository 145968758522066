import styles from '../styles/CashBook.module.scss'
import { Button, Typography } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AmountInput from '@/components/AmountInput'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectOptionInput from '@/components/SelectOptionInput'
import TextInput from '@/components/TextInput'
import { UppyFileChangeResults, UppyUploader } from '@/components/UppyUploader'
import { ALLOWED_FILE_TYPES } from '@/constants'
import { useValidationErrorMap } from '@/hooks'
import {
  CashBookCashFlowArgs,
  ECashBookPaymentReference,
  ECashBookType,
  EManualPaymentType,
} from '@/schemaTypes'
import { isShopLevelCashbookType } from '@/utils/misc'
import { CashBookCashFlowValidation } from '@/validation/CashBookCashFlowValidation'

export interface CashBookCashFlowsFormProps {
  onSave: (
    data: CashBookCashFlowArgs,
    onSuccess: () => void,
    addAnotherEntry: boolean,
  ) => void
  onCancel: () => void
  shopOrCompanyId: string
  cashBookType: ECashBookType
  actionInProgress: boolean
}

const paymentReferenceSuggestions = [
  ECashBookPaymentReference.BankWithdrawal,
  ECashBookPaymentReference.MarketingCreditNote,
  ECashBookPaymentReference.MiscellaneousBooking,
  ECashBookPaymentReference.Bill,
  ECashBookPaymentReference.Tipico,
  ECashBookPaymentReference.OtherRevenue,
]

const paymentReferenceOtherRevenueNotes = [
  'Verifizierung / Echtheitsprüfung Luxushandtasche',
  'Transport',
  'Sonstiges',
]

export function CashBookCashFlowsForm(props: CashBookCashFlowsFormProps) {
  const { shopOrCompanyId, cashBookType, actionInProgress } = props
  const { t } = useTranslation()

  const [manualNote, setManualNote] = useState<string | null>(null)

  const paymentType = getManualPaymentType(cashBookType)
  const setInitialCashBookCashFlow = () => {
    return {
      paymentType,
      ...(isShopLevelCashbookType(cashBookType)
        ? {
            shopId: shopOrCompanyId,
          }
        : {
            companyId: shopOrCompanyId,
          }),
      date: new Date(),
      paymentReference: '' as ECashBookPaymentReference,
      storageLabel: '',
      bookingNumber: undefined,
      note: '',
      inflow: 0,
      outflow: 0,
    }
  }

  const [cashBookCashFlow, setCashBookCashFlow] =
    useState<CashBookCashFlowArgs>(setInitialCashBookCashFlow())

  const { validationErrorsMap, isValid } = useValidationErrorMap(
    cashBookCashFlow,
    CashBookCashFlowValidation,
  )

  useEffect(() => {
    if (paymentType)
      setCashBookCashFlow({
        ...cashBookCashFlow,
        ...(isShopLevelCashbookType(cashBookType)
          ? {
              shopId: shopOrCompanyId,
            }
          : {
              companyId: shopOrCompanyId,
            }),
        paymentType,
      })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopOrCompanyId, paymentType])

  const onSave = (addAnotherEntry: boolean) => {
    if (isValid) {
      props.onSave(
        {
          ...cashBookCashFlow,
          note:
            manualNote && cashBookCashFlow.note === 'Sonstiges'
              ? manualNote
              : cashBookCashFlow.note,
        },
        () => {
          setCashBookCashFlow(setInitialCashBookCashFlow())
        },
        addAnotherEntry,
      )
    }
  }

  const isCashflowValid = () => {
    const inflow = cashBookCashFlow.inflow ?? 0
    const outflow = cashBookCashFlow.outflow ?? 0

    if ((inflow > 0 && !(outflow > 0)) || (outflow > 0 && !(inflow > 0))) {
      return true
    }

    return false
  }

  const onUpload = async ([results]: UppyFileChangeResults[]) => {
    setCashBookCashFlow({
      ...cashBookCashFlow,
      attachmentArgs: 'newFile' in results ? results.newFile : null,
    })
  }

  const cashflowValid = isCashflowValid()

  return (
    <div>
      <SelectOptionInput
        error={t(validationErrorsMap.paymentReference)}
        value={cashBookCashFlow.paymentReference}
        onChange={(value) => {
          setCashBookCashFlow({
            ...cashBookCashFlow,
            storageLabel: '',
            outflow: 0,
            inflow: 0,
            paymentReference: (value ?? '') as ECashBookPaymentReference,
          })
        }}
        label={t('payment_reference')}
        menuItems={paymentReferenceSuggestions
          .filter((entry) => {
            if (
              entry === ECashBookPaymentReference.BankWithdrawal &&
              paymentType !== EManualPaymentType.Cash
            )
              return null
            return entry
          })
          .map((entry) => ({
            label: t(`cash_book.payment_reference_${entry.toLowerCase()}`),
            value: entry,
          }))}
      />
      {cashBookCashFlow.paymentReference ===
      ECashBookPaymentReference.OtherRevenue ? (
        <SelectOptionInput
          error={validationErrorsMap.note}
          value={cashBookCashFlow.note}
          onChange={(value) =>
            setCashBookCashFlow({ ...cashBookCashFlow, note: value })
          }
          label={t('note')}
          menuItems={paymentReferenceOtherRevenueNotes.map((entry) => ({
            label: entry,
            value: entry,
          }))}
        />
      ) : (
        <TextInput
          error={validationErrorsMap.note}
          value={cashBookCashFlow.note ?? ''}
          onChange={(value) =>
            setCashBookCashFlow({ ...cashBookCashFlow, note: value })
          }
          label={`${t('note')}:`}
        />
      )}
      {cashBookCashFlow.paymentReference ===
        ECashBookPaymentReference.OtherRevenue &&
        cashBookCashFlow.note === 'Sonstiges' && (
          <TextInput
            value={manualNote ?? ''}
            onChange={(value) => setManualNote(value)}
            label={`${t('manual_note')}:`}
          />
        )}

      {!(
        cashBookCashFlow.paymentReference ===
        ECashBookPaymentReference.MarketingCreditNote
      ) && (
        <TextInput
          error={validationErrorsMap.storageLabel}
          value={cashBookCashFlow.storageLabel ?? ''}
          onChange={(value) =>
            setCashBookCashFlow({ ...cashBookCashFlow, storageLabel: value })
          }
          label={`${t('storage.label')}:`}
        />
      )}
      {cashBookCashFlow.paymentReference ===
        ECashBookPaymentReference.MarketingCreditNote && (
        <>
          <TextInput
            error={validationErrorsMap.bookingNumber}
            value={
              cashBookCashFlow.bookingNumber
                ? cashBookCashFlow.bookingNumber.toString()
                : ''
            }
            onChange={(value) =>
              setCashBookCashFlow({
                ...cashBookCashFlow,
                bookingNumber: Number(value) ? Number(value) : undefined,
              })
            }
            label={`${t('deal.booking_number')}:`}
          />
          <TextInput
            error={validationErrorsMap.customerNumber}
            value={
              cashBookCashFlow.customerNumber
                ? cashBookCashFlow.customerNumber.toString()
                : ''
            }
            onChange={(value) =>
              setCashBookCashFlow({
                ...cashBookCashFlow,
                customerNumber: Number(value) ? Number(value) : undefined,
              })
            }
            label={`${t('customer.customer_number')}:`}
          />
          <TextInput
            error={validationErrorsMap.billNumber}
            value={
              cashBookCashFlow.billNumber ? cashBookCashFlow.billNumber : ''
            }
            onChange={(value) =>
              setCashBookCashFlow({ ...cashBookCashFlow, billNumber: value })
            }
            label={`${t('deal.pawn.bill_number')}:`}
          />
        </>
      )}
      {!(
        cashBookCashFlow.paymentReference ===
          ECashBookPaymentReference.MarketingCreditNote ||
        cashBookCashFlow.paymentReference === ECashBookPaymentReference.Bill
      ) && (
        <AmountInput
          error={validationErrorsMap.inflow}
          value={cashBookCashFlow.inflow ?? 0}
          onChange={(value) =>
            setCashBookCashFlow({ ...cashBookCashFlow, inflow: value || 0 })
          }
          label={`${t('inflow')}:`}
          type={'float'}
        />
      )}
      {!(
        cashBookCashFlow.paymentReference ===
          ECashBookPaymentReference.BankWithdrawal ||
        cashBookCashFlow.paymentReference ===
          ECashBookPaymentReference.OtherRevenue
      ) && (
        <AmountInput
          error={validationErrorsMap.outflow}
          value={cashBookCashFlow.outflow ?? 0}
          onChange={(value) =>
            setCashBookCashFlow({ ...cashBookCashFlow, outflow: value || 0 })
          }
          label={`${t('outflow')}:`}
          type={'float'}
        />
      )}
      <div className={`u-inline-block`}>
        <span className={`u-relative ${styles.dateLabel} u-mr-5`}>
          {t('date')}:{' '}
        </span>
        <KeyboardDatePicker
          className={`${styles.datePicker} u-mb-sm`}
          clearable
          format="DD.MM.YYYY"
          value={cashBookCashFlow.date}
          onChange={(value: Dayjs) =>
            setCashBookCashFlow({ ...cashBookCashFlow, date: value.toDate() })
          }
        />
      </div>
      {cashBookCashFlow.paymentReference !==
        ECashBookPaymentReference.MarketingCreditNote && (
        <div className="u-mb-10">
          <UppyUploader
            label={t('customer.files')}
            allowedFileTypes={[...ALLOWED_FILE_TYPES, '.pdf']}
            initialFiles={[]}
            onFilesChanged={onUpload}
          />
          {validationErrorsMap.attachmentArgs && (
            <Typography color="error">{t('bill_upload_mandatory')}</Typography>
          )}
        </div>
      )}
      <div>
        {!cashflowValid && (
          <div className={'u-m-10'}>
            <Typography variant="caption" color="error">
              {t('either_inflow_or_outflow_needs_to_be_greater_than_zero')}
            </Typography>
          </div>
        )}
        <Button
          onClick={() => onSave(false)}
          variant="contained"
          disabled={!isValid || !cashflowValid}
        >
          {t('save')}
        </Button>
        <Button
          className="u-ml-10"
          onClick={() => onSave(true)}
          variant="contained"
          disabled={!isValid || !cashflowValid}
        >
          {t('save_and_add_new_entry')}
        </Button>
        <Button
          className="u-ml-10"
          onClick={props.onCancel}
          variant="contained"
        >
          {t('abort')}
        </Button>
        {actionInProgress && (
          <div
            className={`u-ml-sm u-inline-block ${styles.verticalAlignMiddle}`}
          >
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  )
}

export default CashBookCashFlowsForm

function getManualPaymentType(cashBookType: ECashBookType) {
  switch (cashBookType) {
    case ECashBookType.Bank:
      return EManualPaymentType.Bank

    case ECashBookType.Card:
      return EManualPaymentType.Card

    case ECashBookType.Cash:
      return EManualPaymentType.Cash

    case ECashBookType.Paypal:
      return EManualPaymentType.Paypal

    default:
      return EManualPaymentType.Dummy
  }
}
