import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateCustomDealFormArgs } from '@/schemaTypes'

const CREATE_CUSTOM_DEAL_FORM = gql`
  mutation createCustomDealForm(
    $customDealFormCreateArgs: CustomDealFormCreateArgs!
  ) {
    createCustomDealForm(customDealFormCreateArgs: $customDealFormCreateArgs) {
      _id
    }
  }
`

type TData = { createCustomDealForm: Mutation['createCustomDealForm'] }
type TVariables = MutationCreateCustomDealFormArgs

export function useCreateCustomDealForm(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [mutation] = useMutation<TData, TVariables>(
    CREATE_CUSTOM_DEAL_FORM,
    opts,
  )
  return mutation
}
