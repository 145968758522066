import Loading from '../../components/Loading'
import ProductPriceHistories from '../../components/ProductPriceHistories'
import ProductPrices from '../../components/ProductPrices'
import styled from '@emotion/styled'
import { Container, IconButton, Paper, Tab, Tabs } from '@material-ui/core'
import { ArrowBackIos } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import {
  Link,
  RouteComponentProps,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import { useGetProduct } from '@/domains/products/hooks/getProduct'
import { ProductOverview } from '@/pages/InApp/Products/[productId]'
import getPagePath from '@/utils/getPagePath'
import ProductTitle from './components/ProductTitle'

const TAB = {
  PROPERTIES: 'properties',
  PRICES: 'prices',
  PRICE_HISTORY: 'price_history',
}

export default function ProductDetail(props: RouteComponentProps) {
  const lastPath = useLocation().pathname.split('/').pop()
  const selectedTab =
    Object.keys(TAB).find((key) => TAB[key] === lastPath) || 'PROPERTIES'
  const { t } = useTranslation()
  const history = useHistory()
  const { productId } = useParams<{ productId: string }>()

  const { product } = useGetProduct({
    variables: {
      productId,
      variantsArgs: {
        includeDisabled: true,
      },
    },
    skip: !productId,
  })

  const handleChangeTab = (key: string) => {
    history.replace(`/inApp/products/${productId}/${TAB[key]}`)
  }

  if (!product) return <Loading />

  return (
    <>
      <Container className="u-p" maxWidth="xl">
        <Wrapper className="u-p-sm">
          <Link to={`/inApp/products`}>
            <BackIcon>
              <ArrowBackIos />
            </BackIcon>
          </Link>

          <Content>
            <Paper className="u-p u-12/12">
              {product && <ProductTitle product={product} />}

              <TabWrapper>
                <Tabs
                  value={selectedTab}
                  onChange={(e, key) => handleChangeTab(key)}
                  TabIndicatorProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                >
                  {Object.keys(TAB).map((key) => (
                    <StyledTab label={t(TAB[key])} value={key} key={key} />
                  ))}
                </Tabs>
              </TabWrapper>

              <Switch>
                <SentryRoute
                  path={getPagePath(props, '/')}
                  exact
                  component={ProductOverview}
                />
                <SentryRoute
                  path={getPagePath(props, '/properties')}
                  exact
                  component={ProductOverview}
                />
                <SentryRoute
                  path={getPagePath(props, '/prices')}
                  exact
                  render={() => <ProductPrices product={product} />}
                />
                <SentryRoute
                  path={getPagePath(props, '/price_history')}
                  exact
                  component={ProductPriceHistories}
                />
              </Switch>
            </Paper>
          </Content>
        </Wrapper>
      </Container>
    </>
  )
}

const Wrapper = styled.div`
  background-color: #683ab7c0 !important;
  color: white;

  .pathTreeLink {
    color: rgba(255, 255, 255, 0.829);
    text-decoration: none !important;
  }
  .pathTreeLinkButton {
    margin-right: 1.375rem;
  }
`

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledTab = styled(Tab)`
  padding: 1rem;
  margin-right: 0.5rem;
  color: #683ab7c0;
  background-color: #683ab77d;
  font-weight: 700 !important;
  border-radius: 0.5rem;

  &.Mui-selected {
    color: white !important;
    background-color: #683ab7c0;
  }
`

const Content = styled.div``

const BackIcon = styled(IconButton)`
  color: white;
  margin-bottom: 1rem;
`
