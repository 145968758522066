import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { EOrderBy, Query, QueryGetItemQuestionsArgs } from '@/schemaTypes'

const GET_ITEM_QUESTIONS = gql`
  query getItemQuestions($opts: ItemQuestionOpts) {
    getItemQuestions(opts: $opts) {
      _id
      order
      questionType
      valueMode
      titleKey
      predictionTag
      itemCategoryIds
      singleChoiceOptions {
        labelKey
        isDefaultValue
      }
    }
  }
`

type TData = { getItemQuestions: Query['getItemQuestions'] }
type TVariables = QueryGetItemQuestionsArgs

const emptyItemQuestions = []

export function useGetItemQuestions(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_ITEM_QUESTIONS, {
    variables: {
      opts: {
        order: {
          order: EOrderBy.Asc,
        },
      },
    },
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    itemQuestions: queryResult.data?.getItemQuestions || emptyItemQuestions,
  }
}
