import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryGetActiveCompaniesArgs } from '@/schemaTypes'

const GET_ACTIVE_PARTNERS = gql`
  query getActivePartners($opts: PartnerOpts) {
    getActivePartners(opts: $opts) {
      _id
      name
    }
  }
`

type TData = { getActivePartners: Query['getActivePartners'] }
type TVariables = QueryGetActiveCompaniesArgs

export function useGetActivePartners(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_ACTIVE_PARTNERS, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, partners: queryResult.data?.getActivePartners || [] }
}
