import { useMutation } from '@apollo/client'
import { graphql } from '@/gql'

const RemoveSecondaryCustomerFromDealMutation = graphql(`
  mutation removeSecondaryCustomerToDeal(
    $dealId: ObjectId!
    $selectedCustomerId: ObjectId!
  ) {
    removeSecondaryCustomerToDeal(
      dealId: $dealId
      selectedCustomerId: $selectedCustomerId
    ) {
      _id
      secondaryCustomers {
        _id
        firstname
        lastname
        email
      }
    }
  }
`)

export const useRemoveSecondaryCustomerFromDealMutation = () =>
  useMutation(RemoveSecondaryCustomerFromDealMutation)
