import { Button } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined'
import { useTranslation } from 'react-i18next'
import TextInput from '@/components/TextInput'

export interface StorageUnitsInputProps {
  name: string
  show?: boolean
  isSecureLocation?: boolean
  onChange: (name?: string | null, isSecureLocation?: boolean | null) => void
  onClickCreate?: () => void
  onClickUpdate?: () => void
  operation: string | undefined
  disableSecureLocationUpdate: boolean
}

export function StorageUnitsInput(props: StorageUnitsInputProps) {
  const {
    name,
    operation,
    onChange,
    onClickCreate,
    onClickUpdate,
    show,
    isSecureLocation,
    disableSecureLocationUpdate,
  } = props

  return (
    <>
      {show && (
        <>
          <TextInput
            value={name}
            onChange={(value) => onChange(value, null)}
            className="u-block"
          />
          <SecureLocationConfiguration
            disabled={disableSecureLocationUpdate}
            isSecureLocation={isSecureLocation}
            onChangeStorageUnit={() => {
              onChange(null, !isSecureLocation)
            }}
          />
          {operation === 'add' && onClickCreate && (
            <Button onClick={() => onClickCreate()} disabled={!name}>
              <LibraryAddOutlinedIcon />
            </Button>
          )}
          {operation === 'update' && onClickUpdate && (
            <Button onClick={() => onClickUpdate()} disabled={!name}>
              <CheckCircleOutlineIcon />
            </Button>
          )}
        </>
      )}
    </>
  )
}

const SecureLocationConfiguration = ({
  isSecureLocation,
  onChangeStorageUnit,
  disabled,
}) => {
  const { t } = useTranslation()

  const disablingStyles = disabled
    ? { opacity: 0.7, cursor: 'unset' }
    : { opacity: 1, cursor: 'pointer' }

  return (
    <div
      style={{ marginTop: 20, marginBottom: 10, ...disablingStyles }}
      onClick={() => {
        if (!disabled) {
          onChangeStorageUnit()
        }
      }}
    >
      {' '}
      {isSecureLocation ? (
        <>
          <i className="pi pi-lock" style={{ fontSize: 16 }} />{' '}
          <span style={{ fontSize: 14 }}>{t('secure_storage_location')}</span>
        </>
      ) : (
        <>
          <i className="pi pi-lock-open" style={{ fontSize: 16 }}>
            {' '}
          </i>{' '}
          <span style={{ fontSize: 14 }}>{t('normal_storage_location')}</span>
        </>
      )}
    </div>
  )
}

export default StorageUnitsInput
