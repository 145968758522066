import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationAddKsvAssetArgs } from '@/schemaTypes'

const mutation = gql`
  mutation addKSVAsset($itemId: ObjectId!) {
    addKSVAsset(itemId: $itemId)
  }
`

type TData = { addKSVAsset: Mutation['addKSVAsset'] }
type TVariables = MutationAddKsvAssetArgs

export function useAddKSVAssetMutation(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [addKSVAsset] = useMutation<TData, TVariables>(mutation, opts)
  return addKSVAsset
}
