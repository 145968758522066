import { Paper } from '@material-ui/core'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import EmployeesDetails from '@/domains/employees/components/EmployeesDetails'
import { useGetEmployee, useUpdateEmployee } from '@/domains/employees/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { Employee } from '@/schemaTypes'

export interface EmployeesEditProps {
  employeeId: string
}

export function EmployeesEdit(props: EmployeesEditProps) {
  const { employeeId } = props
  const { t } = useTranslation()

  const { employee, queryResult: getEmployeeQueryResult } = useGetEmployee({
    variables: {
      employeeId,
    },
  })

  const updateEmployee = useMutationShowingErrors({
    mutation: useUpdateEmployee(),
    successMessage: t('employee.employee_updated'),
  })

  const onSave = (employee: Employee) => {
    updateEmployee({
      variables: {
        ...employee,
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={employeeId}
        labelAppendix={`${employee?.firstname} ${employee?.lastname}`}
        modelName={'employees'}
        mode={'edit'}
      />
      {getEmployeeQueryResult.loading ? (
        <Loading />
      ) : (
        employee && (
          <Fragment>
            <EmployeesDetails
              employee={employee}
              companies={[employee.company]}
              onSave={onSave}
              disableCompanySelect={true}
            />
          </Fragment>
        )
      )}
    </Paper>
  )
}

export default EmployeesEdit
