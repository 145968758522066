import { useGetItemCategories } from '../../itemCategories/hooks'
import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import RemoveIcon from '@material-ui/icons/Delete'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import AmountInput from '@/components/AmountInput'
import SelectOptionInput from '@/components/SelectOptionInput'
import { FeeCreateArgs, FeeUpdateArgs } from '@/schemaTypes'
import styles from './FeesConfiguration.module.scss'

interface Props {
  newFeeDefinitionArgs: FeeCreateArgs | FeeUpdateArgs
  setNewFeeDefinitionArgs
}

const FeeAmountForCategories: FunctionComponent<Props> = (props) => {
  const { newFeeDefinitionArgs, setNewFeeDefinitionArgs } = props
  const { t } = useTranslation()

  const { itemCategories } = useGetItemCategories({
    variables: {
      opts: {
        filter: {
          includeHided: true,
        },
      },
    },
  })

  const possibleItemCategories = itemCategories.map((str) => ({
    label: str.algoliaCategorySlug ?? str.name,
    value: str._id,
  }))

  const addButtonClicked = () => {
    // just assign the first parent category
    const categoryId = itemCategories.filter((c) => !c.parentId)[0]._id
    const amountForCategories = [
      ...(newFeeDefinitionArgs.amountForCategories ?? []),
      { categoryId, amount: 0 },
    ]

    setNewFeeDefinitionArgs({ ...newFeeDefinitionArgs, amountForCategories })
  }

  const deleteButtonClicked = (index: number) => {
    setNewFeeDefinitionArgs({
      ...newFeeDefinitionArgs,
      amountForCategories: newFeeDefinitionArgs.amountForCategories?.filter(
        (_, i) => i !== index,
      ),
    })
  }

  return (
    <div className={`${styles.itemCategoryMainDiv}`}>
      <div className={`${styles.itemCategoryTitleDiv}`}>
        <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
          {t('fees.fees_amount_for_categories')}
        </div>
        <div
          onClick={() => addButtonClicked()}
          className={`${styles.AddIntervalIcon}`}
        >
          <AddIcon />
        </div>
      </div>
      <Row>
        {t('default')}: {newFeeDefinitionArgs.amount}
      </Row>
      {newFeeDefinitionArgs.amountForCategories?.map((amountForCategory, i) => (
        <Row key={i}>
          <div>
            <StyledSelectOptionInput
              value={amountForCategory.categoryId}
              onChange={(categoryId) => {
                setNewFeeDefinitionArgs({
                  ...newFeeDefinitionArgs,
                  amountForCategories:
                    newFeeDefinitionArgs.amountForCategories?.map(
                      (item, index) =>
                        index === i ? { ...item, categoryId } : item,
                    ),
                })
              }}
              menuItems={possibleItemCategories}
            />
          </div>
          <div>
            <AmountInput
              value={amountForCategory.amount}
              onChange={(amount) => {
                setNewFeeDefinitionArgs({
                  ...newFeeDefinitionArgs,
                  amountForCategories:
                    newFeeDefinitionArgs.amountForCategories?.map(
                      (item, index) =>
                        index === i ? { ...item, amount } : item,
                    ),
                })
              }}
            />
          </div>
          <div>
            <IconButton onClick={() => deleteButtonClicked(i)}>
              <RemoveIcon />
            </IconButton>
          </div>
        </Row>
      ))}
    </div>
  )
}

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem;
  > div:first-child {
    flex: 1;
  }
`

const StyledSelectOptionInput = styled(SelectOptionInput)`
  > div {
    margin-left: 0 !important;
  }
`

export default FeeAmountForCategories
