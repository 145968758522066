import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { sumBy } from 'lodash'
import { EVehicleCategory } from '@/domains/deals/components/constants/enums'
import {
  CalculatedFeeDefinition,
  CustomDealItemDataEntry,
  Customer,
  Deal,
  DealCalculation,
  DealEvent,
  DealExtensionCalculationArgs,
  DealItem,
  DealValuesEntry,
  EBillingPeriodType,
  EDealStatusType,
  EDealType,
  EFeeIntervalType,
  EFeeLevel,
  EFeeMode,
  EFeeType,
  ETransportMode,
  GuestCustomerData,
  ItemAnswer,
  ItemAnswerArgs,
  ItemExtensionAnswers,
  ItemExtensionAnswersArgs,
  ItemQuestion,
  ManualFeeDefinitionArgs,
  Maybe,
  TransportData,
  UsedFeeDefinition,
} from '@/schemaTypes'
import { DealEventType } from '@/types'
import { ensureDate } from './misc'

dayjs.extend(utc)

export function getDealDuration(deal: Deal) {
  if (getDealValueEntry(deal, 'DealClosedEvent')) {
    return getDealValueEntry(deal, 'DealClosedEvent').durationInDays
  } else {
    return getDealValueEntry(deal, 'DealVerifiedEvent').durationInDays
  }
}

export function getDealTransportType(
  dropoffData?: Maybe<TransportData>,
  pickupData?: Maybe<TransportData>,
) {
  if (dropoffData) {
    return 'Dropoff: ' + dropoffData.transportMode
  } else if (pickupData) {
    return 'Pickup: ' + pickupData.transportMode
  }
  return ''
}

export function isSentShippingLabel(pickupData?: Maybe<TransportData>) {
  return !!(
    pickupData?.transportMode === ETransportMode.StandardShipment &&
    (pickupData.standardShippingData?.sentShippingLabels?.length ?? 0) > 0
  )
}

/**
 * Get the download URL of a file
 * @param {*} storage the storage object
 * @param {*} path the path to the file
 */
export const getDownloadUrl = (storage, path) => {
  return storage.ref(path).getDownloadURL()
}

export function answerQuestion({
  itemFeatures,
  label = undefined,
  value,
  questionId,
}) {
  const itemFeature = itemFeatures.find(
    (r) => r.questionId === questionId || r.id === questionId,
  )
  const question = itemFeature.question
  const selectionType = question.type
  const options = question.options

  let answer: any = null

  switch (selectionType) {
    case 'singlechoice': {
      const option = options.find((i) => i.label === label)

      if (option) {
        answer = {
          ...option,
          answerType: question.deductionType,
          questionId,
          questionTitle: question.title,
        }
      }
      break
    }
    case 'range': {
      answer = {
        label: `${value || 0}${question.unit}`,
        answerType: question.deductionType,
        unitprice: question.unitprice,
        value: value || question.minValue,
        questionId,
        questionTitle: question.title,
      }

      break
    }
    default: {
      console.warn('unhandled default case', selectionType)
    }
  }

  if (itemFeature.tag) {
    answer.tag = itemFeature.tag
  }

  return answer
}

export function getEventsSortedByCreatedAtDesc(events: DealEvent[]) {
  const saveEvents: DealEvent[] = events || []
  const sortedEvents = [...saveEvents].sort((a, b) => {
    const res =
      ensureDate(b.createdAt).getTime() - ensureDate(a.createdAt).getTime()

    if (res === 0) {
      return (
        saveEvents.findIndex(
          (event) =>
            event.__typename === b.__typename &&
            event.createdAt === b.createdAt,
        ) -
        saveEvents.findIndex(
          (event) =>
            event.__typename === a.__typename &&
            event.createdAt === a.createdAt,
        )
      )
    } else {
      return res
    }
  })

  return sortedEvents
}

export function getEventsSortedByCreatedAtAsc(events: DealEvent[]) {
  return [...getEventsSortedByCreatedAtDesc(events)].reverse()
}

export function getLatestDealValues(deal: Deal) {
  if (getLatestEventForStatus(deal, 'DealClosedEvent')) {
    return getDealValueEntry(deal, 'DealClosedEvent')
  } else {
    return getDealValueEntry(deal, 'DealVerifiedEvent')
  }
}

export function isDealVerified(deal: Deal) {
  let verifiedEvents

  if (deal && deal.events) {
    verifiedEvents = deal.events.filter(
      (c) => c.__typename === 'DealVerifiedEvent',
    )
    if (verifiedEvents && verifiedEvents.length > 0) return true
  }

  return false
}

export function isDealClosed(deal: Deal) {
  let verifiedEvents

  if (deal && deal.events) {
    verifiedEvents = deal.events.filter(
      (c) => c.__typename === 'DealClosedEvent',
    )
    if (verifiedEvents && verifiedEvents.length > 0) return true
  }

  return false
}

export function getDistinctFeeTypes(
  appliedUsedFeeDefinitions: UsedFeeDefinition[],
) {
  const feeTypes: EFeeType[] = []
  for (const itemInDeal of appliedUsedFeeDefinitions) {
    for (const feeDef of itemInDeal.calculatedFeeDefinitions) {
      if (!feeTypes.includes(feeDef.storedFeeDefinition.feeType)) {
        feeTypes.push(feeDef.storedFeeDefinition.feeType)
      }
    }
  }

  return feeTypes
}

/**
 * This function allways returns the appliedNetFees.
 */
export function getTotalFeeAmountOfType(
  usedFeeDefinitions: UsedFeeDefinition[],
  feeType?: EFeeType,
) {
  let retValue = 0

  for (const usedFeeDefinition of usedFeeDefinitions) {
    for (const feeDef of usedFeeDefinition.calculatedFeeDefinitions.filter(
      (c) => (feeType ? c.storedFeeDefinition.feeType === feeType : true),
    )) {
      retValue += feeDef.appliedNetAmount
    }
  }

  return retValue
}

/**
 * This function allways returns the appliedNetFees.
 */
export function getTotalGrossFeeAmountOfType(
  usedFeeDefinitions: UsedFeeDefinition[],
  feeType?: EFeeType,
) {
  let retValue = 0

  for (const usedFeeDefinition of usedFeeDefinitions) {
    for (const feeDef of usedFeeDefinition.calculatedFeeDefinitions.filter(
      (c) => (feeType ? c.storedFeeDefinition.feeType === feeType : true),
    )) {
      retValue += feeDef.appliedGrossAmount
    }
  }

  return retValue
}

/**
 * This function sums and returns the netAmount of all the fees aplied to one item.
 */
export function getTotalFeeAmountOfItem(
  itemAppliedFeeDefinitions: CalculatedFeeDefinition[],
) {
  return itemAppliedFeeDefinitions.reduce(
    (partialSum, a) => partialSum + a.appliedNetAmount,
    0,
  )
}

export function getTotalDealLevelFeesAmount(
  usedFeeDefinitions: UsedFeeDefinition[],
) {
  if (
    usedFeeDefinitions.length > 0 &&
    usedFeeDefinitions[0].calculatedFeeDefinitions.length > 0
  ) {
    const sum = sumBy(
      usedFeeDefinitions[0].calculatedFeeDefinitions.filter(
        (c) => c.storedFeeDefinition.level === EFeeLevel.Deal,
      ),
      'appliedNetAmount',
    )

    return sum
  }
}

export function getLatestEventForStatus<TType extends DealEventType>(
  deal: Deal,
  eventType: TType,
): Extract<DealEvent, { __typename?: TType }> | undefined {
  const temp = [...deal.events]
    .reverse()
    .find(
      (v): v is Extract<DealEvent, { __typename?: TType }> =>
        v.__typename === eventType,
    )

  return temp
}

export function getDealValueEntry<TType extends DealEventType>(
  deal: Deal,
  eventType: TType,
): DealValuesEntry {
  let retValue
  const latesEvent = getLatestEventForStatus(deal, eventType)
  if (latesEvent && 'dealCalculation' in latesEvent) {
    retValue = { ...latesEvent.dealCalculation.dealValuesEntry }
  } else {
    if (eventType === 'DealClosedEvent') {
      retValue = { ...getDealValueEntry(deal, 'DealVerifiedEvent') }
    } else if (eventType === 'DealVerifiedEvent') {
      retValue = { ...getDealValueEntry(deal, 'DealBookedEvent') }
    } else {
      const tempDealValueEntry: DealValuesEntry = {
        calculationPaybackAmount: -1,
        calculationPayoutAmount: -1,
        durationInDays: -1,
        paybackAmount: -1,
        payoutAmount: -1,
        overwrittenPayoutAmount: -1,
        shouldOverwritePayoutAmount: false,
      }
      retValue = { ...tempDealValueEntry }
    }
  }

  // payoutAmount and durationInDays are always up to date in dealFinalValues
  // In future after the migration this check will be removed
  if (deal.dealFinalValues) {
    retValue.payoutAmount = deal.dealFinalValues.payoutAmount
    retValue.durationInDays = deal.dealFinalValues.durationInDays
  }
  return retValue
}

export function getLatestDealManualFeeDefinitionArgs(deal: Deal) {
  const isClosed = isDealClosed(deal)
  const isVerified = isDealVerified(deal)

  let properDealCalculation: DealCalculation | undefined | null

  if (isClosed) {
    const latestEvent = getLatestEventForStatus(deal, 'DealClosedEvent')
    if (latestEvent) {
      properDealCalculation = latestEvent.dealCalculation
    }
  } else if (isVerified) {
    const latestEvent = getLatestEventForStatus(deal, 'DealVerifiedEvent')
    if (latestEvent) {
      properDealCalculation = latestEvent.dealCalculation
    }
  } else {
    const latestEvent = getLatestEventForStatus(deal, 'DealBookedEvent')
    if (latestEvent) {
      properDealCalculation = latestEvent.dealCalculation
    }
  }

  return getManualFeeDefinitionsFromCalculation(
    properDealCalculation,
    deal.dealType,
  )
}

export function getManualFeeDefinitionsFromCalculation(
  dealCalculation: DealCalculation,
  dealType: EDealType,
) {
  const manualFeesDistinctByFeeTypes: ManualFeeDefinitionArgs[] = []

  if (dealCalculation) {
    // First we add all DealLevel fees to our Args object
    const allDealLevelfees = getDealLevelFees(
      dealCalculation.appliedUsedFeeDefinitions,
    )
    for (const dealLevelFee of allDealLevelfees) {
      manualFeesDistinctByFeeTypes.push(
        convertCalculatedFeeToManualFeeDefinitionArgs(
          dealCalculation.dealValuesEntry.payoutAmount,
          dealType,
          dealLevelFee,
          '',
        ),
      )
    }
    // Then we handle each items ItemLevelFees
    for (const itemFeeDef of dealCalculation.appliedUsedFeeDefinitions) {
      for (const feeDef of itemFeeDef.calculatedFeeDefinitions) {
        if (feeDef.storedFeeDefinition.level === EFeeLevel.Item) {
          manualFeesDistinctByFeeTypes.push(
            convertCalculatedFeeToManualFeeDefinitionArgs(
              dealCalculation.dealValuesEntry.payoutAmount,
              dealType,
              feeDef,
              itemFeeDef.storageLabel ?? '',
            ),
          )
        }
      }
    }

    return manualFeesDistinctByFeeTypes
  } else {
    return manualFeesDistinctByFeeTypes
  }
}

export function convertCalculatedFeeToManualFeeDefinitionArgs(
  dealPayoutAmount: number,
  dealType: EDealType,
  calculatedFee: CalculatedFeeDefinition,
  storageLabel: string,
) {
  const mode =
    calculatedFee.storedFeeDefinition.mode === EFeeMode.Custome
      ? convertCustomMode(calculatedFee, dealPayoutAmount)
      : calculatedFee.storedFeeDefinition.mode
  const amount = !calculatedFee.storedFeeDefinition.feeIntervals
    ? calculatedFee.storedFeeDefinition.amount
    : convertAmount(calculatedFee, dealPayoutAmount)

  const manualFeesDistinctByFeeType: ManualFeeDefinitionArgs = {
    amount,
    billingPeriodType: calculatedFee.storedFeeDefinition.billingPeriodType,
    dealType: dealType,
    feeType: calculatedFee.storedFeeDefinition.feeType,
    isActive: calculatedFee.storedFeeDefinition.isActive,
    isManual: calculatedFee.isManual,
    level: calculatedFee.storedFeeDefinition.level,
    mode,
    ust: calculatedFee.ust,
    storageLabel:
      calculatedFee.storedFeeDefinition.level === EFeeLevel.Item
        ? storageLabel
        : undefined,
    date: calculatedFee.date,
    description: calculatedFee.description,
  }
  return manualFeesDistinctByFeeType
}

function convertAmount(
  calculatedFee: CalculatedFeeDefinition,
  dealPayoutAmount: number,
) {
  let amount = 0

  if (calculatedFee.storedFeeDefinition.feeIntervals) {
    calculatedFee.storedFeeDefinition.feeIntervals.forEach((interval) => {
      if (dealPayoutAmount >= interval.from)
        amount = calculatedFee.storedFeeDefinition.amount + interval.diffAmount
    })
  }

  return amount
}

function convertCustomMode(
  calculatedFee: CalculatedFeeDefinition,
  dealPayoutAmount: number,
) {
  if (calculatedFee.storedFeeDefinition.feeIntervals) {
    let selectedIntervalIndex = 0

    calculatedFee.storedFeeDefinition.feeIntervals.forEach(
      (interval, index) => {
        if (dealPayoutAmount >= interval.from) selectedIntervalIndex = index
      },
    )

    if (
      calculatedFee.storedFeeDefinition.feeIntervals[selectedIntervalIndex]
        .type === EFeeIntervalType.Percentage
    )
      return EFeeMode.MultiplierPayout
  }

  return EFeeMode.Constant
}

export function getLatestItemAnswersArgs(
  deal: Deal,
  cleaningFeeQuestion?: ItemQuestion,
) {
  const itemsExtensionAnswersArgs: ItemExtensionAnswersArgs[] = []

  // Since we do not have an active extension request any more we just use the answers from the item.
  // In this case we aggreed to set CLEANING_FEE to NO for our extensions
  for (const item of deal.items) {
    const tempItemAnswerExtensionArgs: ItemExtensionAnswersArgs = {
      storageLabel: item.storageLabel ?? '',
      answers: item.answers
        ? item.answers.map((answer) => {
            const tempAnswer: ItemAnswerArgs = {
              ...answer,
            }
            return tempAnswer
          })
        : [],
    }

    // Try to find and set NO for cleaning fee
    // I check the question by the title, but this should be fixed when we refactor the itemQuestions in future.
    if (cleaningFeeQuestion) {
      const itemCleaningQuestion = tempItemAnswerExtensionArgs.answers.find(
        (c) => c.questionId === cleaningFeeQuestion._id,
      )
      if (itemCleaningQuestion) {
        const cleaningQuestionIndex =
          tempItemAnswerExtensionArgs.answers.indexOf(itemCleaningQuestion)
        tempItemAnswerExtensionArgs.answers[
          cleaningQuestionIndex
        ].selectedOptionIndex = cleaningFeeQuestion.singleChoiceOptions
          ? cleaningFeeQuestion.singleChoiceOptions.indexOf(
              cleaningFeeQuestion.singleChoiceOptions.filter(
                (c) => c.labelKey === 'QUESTION_NO',
              )[0],
            )
          : undefined
      }
    }
    itemsExtensionAnswersArgs.push(tempItemAnswerExtensionArgs)
  }

  return itemsExtensionAnswersArgs
}

export function convertItemAnswersExtensionToArgs(
  itemAnswersExtension: ItemExtensionAnswers[],
) {
  const itemsExtensionAnswersArgs: ItemExtensionAnswersArgs[] = []
  itemAnswersExtension.map((c) =>
    itemsExtensionAnswersArgs.push({
      storageLabel: c.storageLabel,
      answers: c.answers.map((a) => {
        const tempAnswer: ItemAnswerArgs = { ...a }
        return tempAnswer
      }),
    }),
  )

  return itemsExtensionAnswersArgs
}

export function convertItemAnswersExtensionFromArgs(
  itemAnswersExtensionArgs: ItemExtensionAnswersArgs[],
) {
  const itemsExtensionAnswers: ItemExtensionAnswers[] = []
  itemAnswersExtensionArgs.map((c) =>
    itemsExtensionAnswers.push({
      storageLabel: c.storageLabel,
      answers: c.answers.map((a) => {
        const tempAnswer: ItemAnswer = { ...a }
        return tempAnswer
      }),
    }),
  )

  return itemsExtensionAnswers
}

export function dealExtensionCalculationArgsIsValid(
  args: DealExtensionCalculationArgs,
) {
  if (
    (args.overwrittenPartialRedemptionAmount &&
      args.overwrittenPartialRedemptionAmount < 0) ||
    !args.durationInDays ||
    args.durationInDays <= 0
  ) {
    return false
  } else {
    return true
  }
}

export function checkIfAllTheFeesOfTypeIsDealLevel(
  usedFeeDefinitions: UsedFeeDefinition[],
  feeType: EFeeType,
) {
  for (const itemFees of usedFeeDefinitions) {
    for (const itemFee of itemFees.calculatedFeeDefinitions) {
      if (
        itemFee.storedFeeDefinition.feeType === feeType &&
        itemFee.storedFeeDefinition.level !== EFeeLevel.Deal
      )
        return false
    }
  }

  return true
}

export function getItemFeesOfType(
  usedFeeDefinitions: UsedFeeDefinition[],
  feeType: EFeeType,
  storageLabel?: string | null,
  itemIndex?: number | null,
) {
  if (!storageLabel && (itemIndex === null || itemIndex === undefined)) {
    throw new Error('At least storageLabel or itemIndex should be provided')
  }

  let retFees: CalculatedFeeDefinition[] = []
  const hasItemLevelFeeDefinition = storageLabel
    ? usedFeeDefinitions.map((c) => c.storageLabel).includes(storageLabel)
    : !!usedFeeDefinitions[itemIndex]

  if (hasItemLevelFeeDefinition) {
    const itemLevelFeeDefinitions: CalculatedFeeDefinition[] = storageLabel
      ? usedFeeDefinitions.filter((c) => c.storageLabel === storageLabel)[0]
          .calculatedFeeDefinitions
      : usedFeeDefinitions[itemIndex].calculatedFeeDefinitions

    retFees = itemLevelFeeDefinitions.filter(
      (c) => c.storedFeeDefinition.feeType === feeType,
    )
  }

  return retFees
}

/**
 * The DealLevel fees exist in all items and they are exactly same as each other (Except applied amount because of itemWeigth which is not important here!)
 */
export function getDealLevelFees(usedFeeDefinitions: UsedFeeDefinition[]) {
  const retFees: CalculatedFeeDefinition[] = []
  if (
    usedFeeDefinitions.length > 0 &&
    usedFeeDefinitions[0].calculatedFeeDefinitions.length > 0
  ) {
    return usedFeeDefinitions[0].calculatedFeeDefinitions.filter(
      (c) => c.storedFeeDefinition.level === EFeeLevel.Deal,
    )
  }
  return retFees
}

export function isMotorVehicle(itemCategoryId: string) {
  return [
    EVehicleCategory.MOTORCYCLE,
    EVehicleCategory.MOTORCYCLE_STORED,
  ].includes(itemCategoryId as EVehicleCategory)
}

export function calculateLiquidationFee(value: number, liquidationFeeFactor) {
  return (value / (1 - liquidationFeeFactor)) * liquidationFeeFactor
}

export function getItemNameByStorageLabel(
  items: DealItem[] | CustomDealItemDataEntry[],
  storageLabel?: string | null,
  itemIndex?: number | null,
) {
  let itemName = ''
  items.forEach((item, i) => {
    if (
      item.vehicleData !== null &&
      (storageLabel ? item.storageLabel === storageLabel : itemIndex === i)
    ) {
      const carData = [
        item.vehicleData?.vehicleProperties.make,
        item.vehicleData?.vehicleProperties.model,
        item.vehicleData?.vehicleProperties.engine,
        item.vehicleData?.vehicleProperties.regdate,
      ]
      itemName = carData.join(' ')
    } else if (storageLabel && storageLabel === item.storageLabel) {
      itemName = item.title
    } else if (
      itemIndex !== undefined &&
      itemIndex !== null &&
      itemIndex === i
    ) {
      itemName = item.title
    }
  })

  return itemName
}

export const manipulatedAppliedFeeDefinitions = (
  appliedUsedFeeDefinitions: UsedFeeDefinition[],
  oldDurationInDays: number,
  newDurationInDays: number,
) => {
  return appliedUsedFeeDefinitions.map((appliedFeeDefinition) => {
    const newUsedFeeDefinition: UsedFeeDefinition = {
      ...appliedFeeDefinition,
      calculatedFeeDefinitions:
        appliedFeeDefinition.calculatedFeeDefinitions.map(
          (calculatedFeeDefinition) => {
            const divider = getBillingPeriodIterations(
              oldDurationInDays,
              calculatedFeeDefinition.storedFeeDefinition.billingPeriodType,
            )
            const multiplier = getBillingPeriodIterations(
              newDurationInDays,
              calculatedFeeDefinition.storedFeeDefinition.billingPeriodType,
            )

            const newCalculatedFeeDefinition: CalculatedFeeDefinition = {
              ...calculatedFeeDefinition,
              appliedGrossAmount:
                (calculatedFeeDefinition.appliedGrossAmount / divider) *
                multiplier,
              appliedNetAmount:
                (calculatedFeeDefinition.appliedNetAmount / divider) *
                multiplier,
            }
            return newCalculatedFeeDefinition
          },
        ),
    }
    return newUsedFeeDefinition
  })
}

export const manipulateFeesForCustomer = (
  dealCalculation: DealCalculation,
  newDurationInDays: number,
) => {
  if (dealCalculation) {
    const newSignedDealCalculation: DealCalculation = {
      ...dealCalculation,
      appliedUsedFeeDefinitions: manipulatedAppliedFeeDefinitions(
        dealCalculation.appliedUsedFeeDefinitions,
        dealCalculation.dealValuesEntry.durationInDays,
        newDurationInDays,
      ),
    }

    return newSignedDealCalculation
  }
  return dealCalculation
}

function getBillingPeriodIterations(
  durationInDays: number,
  billingPeriodType: EBillingPeriodType,
) {
  switch (billingPeriodType) {
    case EBillingPeriodType.OneTime:
      return 1
    case EBillingPeriodType.Day:
    case EBillingPeriodType.HalfMonth:
    case EBillingPeriodType.Month:
      return Math.max(
        Math.ceil(durationInDays / BILLING_PERIOD_DAYS[billingPeriodType]),
        1,
      )
    default:
      throw new Error(`Unknown billing period ${billingPeriodType}`)
  }
}

const BILLING_PERIOD_DAYS = {
  [EBillingPeriodType.OneTime]: 0,
  [EBillingPeriodType.Day]: 1,
  [EBillingPeriodType.HalfMonth]: 15,
  [EBillingPeriodType.Month]: 30,
}

export const dealEventDTOs = {
  DealBookedEvent: EDealStatusType.Booked,
  DealReviewingEvent: EDealStatusType.Reviewing,
  DealVerifiedEvent: EDealStatusType.Verified,
  DealCanceledEvent: EDealStatusType.Canceled,
  DealPickedUpEvent: EDealStatusType.PickedUp,
  DealPickedUpUnsuccessfulEvent: EDealStatusType.PickupUnsuccessful,
  DealPayedAndStoredEvent: EDealStatusType.PayedAndStored,
  DealPaybackConfirmedEvent: EDealStatusType.PaybackConfirmed,
  DealPayedShipmentPendingEvent: EDealStatusType.PayedShipmentPending,
  DealLoanDueNotifiedEvent: EDealStatusType.LoanDueNotified,
  DealLoanDueEvent: EDealStatusType.LoanDue,
  DealExtensionConfirmedEvent: EDealStatusType.ExtensionConfirmed,
  DealOnSellEvent: EDealStatusType.OnSell,
  DealSoldInternEvent: EDealStatusType.SoldIntern,
  DealSoldExternEvent: EDealStatusType.SoldExtern,
  DealDeclinedEvent: EDealStatusType.Declined,
  DealCustomerInDebtEvent: EDealStatusType.CustomerInDebt,
  DealCustomerInCreditEvent: EDealStatusType.CustomerInCredit,
  DealCollectedEvent: EDealStatusType.Collected,
  DealDerecognizedEvent: EDealStatusType.Derecognized,
  DealExcessAmountPayedEvent: EDealStatusType.ExcessAmountPayed,
  DealItemReceivedIdMissingEvent: EDealStatusType.ItemReceivedIdMissing,
  DealClosedEvent: EDealStatusType.Closed,
}

export function isDealConnectedToRegisteredCustomer<
  TDeal extends { customer: Customer | GuestCustomerData },
>(
  deal: TDeal,
): deal is TDeal & { customer: Extract<TDeal['customer'], Customer> } {
  if (!deal) throw new Error('Deal is not available')

  return deal.customer.__typename === 'Customer'
}

export function assertDealConnectedToRegisteredCustomer<
  TDeal extends { customer: Customer | GuestCustomerData },
>(
  deal: TDeal,
): asserts deal is TDeal & { customer: Extract<TDeal['customer'], Customer> } {
  if (!isDealConnectedToRegisteredCustomer(deal))
    throw new Error('Deal expected to be connected to a registered customer')
}

export function getRegisteredCustomer(deal: Deal): Customer {
  if (!deal) throw new Error('Deal is not available')

  return deal.customer.__typename === 'Customer' ? deal.customer : undefined
}
