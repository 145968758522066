import { Button, Paper, Typography } from '@material-ui/core'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import TextInput from '@/components/TextInput'
import { useUpdateEmployeePassword } from '@/domains/employees/hooks'
import { useMutationShowingErrors } from '@/hooks'

export interface EmployeesChangePasswordProps {
  employeeId: string
}

export function EmployeesChangePassword(props: EmployeesChangePasswordProps) {
  const { employeeId } = props
  const { t } = useTranslation()
  const history = useHistory()

  const [password, setPassword] = useState<string>('')

  const updateEmployeePassword = useMutationShowingErrors({
    mutation: useUpdateEmployeePassword(),
    successMessage: t('employee_password_updated'),
  })

  const onSave = (password: string) => {
    updateEmployeePassword({
      variables: {
        employeeId,
        password,
      },
      onCompleted: () => {
        history.goBack()
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <Fragment>
        <Typography variant="h5" gutterBottom>
          {t('change_password')}
        </Typography>
        <TextInput
          value={password}
          onChange={(value) => setPassword(value)}
          label={t('customer.password')}
        />
        <Button
          onClick={() => onSave(password)}
          variant="contained"
          disabled={!password}
        >
          {t('save')}
        </Button>
      </Fragment>
    </Paper>
  )
}

export default EmployeesChangePassword
