import * as React from 'react'
import { FunctionComponent } from 'react'
import { useState } from 'react'
import UsedFeeDefinitionViewerWrapper from '@/components/UsedFeeDefinitionViewerWrapper'
import UsedFeeDefinitionListView from '@/components/UsedFeeDefinitionViewerWrapper/UsedFeeDefinitionListView'
import UsedFeeDefinitionModularView from '@/components/UsedFeeDefinitionViewerWrapper/UsedFeeDefinitionModularView'
import { cashyDocumentLinks } from '@/constants'
import { Deal, DealCalculation, DealItem, EBusinessUnit } from '@/schemaTypes'

interface UsedFeeDefinitionViewerProps {
  deal: Deal
  dealCalculation: DealCalculation
  items: DealItem[]
  isLoading: boolean
  showLayoutButton: boolean
  defaultLayout: 'list' | 'module'
  showItemDetailsButton: boolean // This is just for list view
  itemRecordsButtonDefaultValue: boolean // This is just for list view
  showChargTableText: boolean // This is just for modular View
  showCalculationDate?: boolean // This is just for modular View
}

const UsedFeeDefinitionViewer: FunctionComponent<
  UsedFeeDefinitionViewerProps
> = (props) => {
  const {
    deal,
    dealCalculation,
    items,
    isLoading,
    defaultLayout,
    itemRecordsButtonDefaultValue,
    showChargTableText,
    showCalculationDate,
  } = props

  const [view, setView] = useState(defaultLayout)
  const [itemRecordsView, setItemRecordsView] = useState(
    itemRecordsButtonDefaultValue,
  )

  return (
    <UsedFeeDefinitionViewerWrapper
      {...props}
      onChangeView={(view) => setView(view)}
      onChangeItemRecordsView={(itemRecordsView) =>
        setItemRecordsView(itemRecordsView)
      }
    >
      {view === 'module' ? (
        <UsedFeeDefinitionModularView
          appliedUsedFeeDefinitions={dealCalculation.appliedUsedFeeDefinitions}
          dealStartDate={deal.dealStartDate}
          durationInDays={dealCalculation.dealValuesEntry.durationInDays}
          companyCountryCode={deal.company.headquarter?.countryCode}
          feeScheduleLink={
            deal.businessUnit === EBusinessUnit.General
              ? cashyDocumentLinks.feesCashyLink
              : cashyDocumentLinks.feesCashyAutomotiveLink
          }
          items={items}
          isLoading={isLoading}
          itemRecordsView={itemRecordsView}
          showChargTableText={showChargTableText}
          showCalculationDate={showCalculationDate}
        />
      ) : (
        <UsedFeeDefinitionListView
          calculationDate={dealCalculation.date}
          isLoading={isLoading}
          items={items}
          itemRecordsView={itemRecordsView}
          companyCountryCode={deal.company.headquarter?.countryCode}
          appliedUsedFeeDefinitions={dealCalculation.appliedUsedFeeDefinitions}
        />
      )}
    </UsedFeeDefinitionViewerWrapper>
  )
}

export default UsedFeeDefinitionViewer
