import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateItemCategoryArgs } from '@/schemaTypes'

const UPDATE_ITEM_CATEGORY = gql`
  mutation updateItemCategory(
    $_id: ObjectId!
    $name: String!
    $algoliaCategorySlug: String
    $categoryPageIds: [ObjectId!]
    $parentId: ObjectId
    $storageFeeIds: [ObjectId!]
    $visibleOnDomain: EVisibleOnDomain
    $expectedTurnOverTime: Float
    $helpLink: String
    $maximumPawnDuration: EMaximumPawnDuration
    $minimumItemValue: Float
  ) {
    updateItemCategory(
      _id: $_id
      name: $name
      algoliaCategorySlug: $algoliaCategorySlug
      categoryPageIds: $categoryPageIds
      parentId: $parentId
      storageFeeIds: $storageFeeIds
      visibleOnDomain: $visibleOnDomain
      expectedTurnOverTime: $expectedTurnOverTime
      helpLink: $helpLink
      maximumPawnDuration: $maximumPawnDuration
      minimumItemValue: $minimumItemValue
    ) {
      _id
      name
      slug
      algoliaCategorySlug
      categoryPageIds
      categoryPages {
        _id
        lvl0ContentCategory
        lvl1ContentCategory
      }
      parentId
      visibleOnDomain
      expectedTurnOverTime
      helpLink
      maximumPawnDuration
      minimumItemValue
    }
  }
`

type TData = { updateItemCategory: Mutation['updateItemCategory'] }
type TVariables = MutationUpdateItemCategoryArgs

export function useUpdateItemCategory(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateItemCategory] = useMutation<TData, TVariables>(
    UPDATE_ITEM_CATEGORY,
    opts,
  )

  return updateItemCategory
}
