import { Breadcrumbs } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'

export interface BreadcrumbsBarEntry {
  url: string
  label: string
}

export interface BreadcrumbsBarProps {
  entries: BreadcrumbsBarEntry[]
}

export default function BreadcrumbsBar(props: BreadcrumbsBarProps) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {props.entries.map((entry, index) => (
        <Link key={index} color="inherit" to={entry.url}>
          {entry.label}
        </Link>
      ))}
    </Breadcrumbs>
  )
}
