import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import LabelValueTable, {
  LabelValueTableRowProps,
} from '@/components/LabelValueTable'
import { CustomDealContactData, Customer } from '@/schemaTypes'

export interface CustomDealUserDataProps {
  customer?: Customer
  contactData?: CustomDealContactData
}

export function CustomDealUserData(props: CustomDealUserDataProps) {
  const { customer, contactData } = props
  const { t } = useTranslation()

  const rows: LabelValueTableRowProps[] = customer
    ? [
        {
          label: t('name'),
          value: `${customer.firstname} ${customer.lastname}`,
        },
        {
          label: t('customer.phone'),
          value: customer.phone,
        },
        {
          label: t('customer.email'),
          value: customer.email,
        },
        {
          label: t('customer.date_of_birth'),
          value: dayjs(customer.dateOfBirth).format('DD.MM.YYYY'),
        },
      ]
    : [
        {
          label: t('customer.phone'),
          value: contactData.phone,
        },
        {
          label: t('customer.email'),
          value: contactData.email,
        },
      ]

  return (
    <div style={{ maxWidth: 400 }}>
      <LabelValueTable
        rows={rows}
        displayHeader={false}
        renderLabelColumn={(label) => (
          <span className="o-type-semibold">{label}</span>
        )}
      />
    </div>
  )
}

export default CustomDealUserData
