import styled from '@emotion/styled'
import { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { UppyFileChangeResults, UppyUploader } from '@/components/UppyUploader'
import { ALLOWED_FILE_TYPES } from '@/constants'
import { CategorizedUploadedFile, Item } from '@/schemaTypes'

const fileCategories = [
  'registration_certificate_1',
  'registration_certificate_2',
  'type_certificate',
  'assessment_57_a',
  'purchase_contract',
  'agreement_on_continued_use',
  'power_of_attorney',
  'takeover_protocol',
]
export interface CarItemUploadsOverviewProps {
  item: Item
  onUploadCategorizedFile: (
    results: UppyFileChangeResults,
    fileCategoryName: string,
  ) => void
}

const CarItemUploadsOverview: FunctionComponent<CarItemUploadsOverviewProps> = (
  props,
) => {
  const { item, onUploadCategorizedFile } = props
  const { t } = useTranslation()

  const categorizedUploadedFiles = useMemo(() => {
    return fileCategories.map((cat) => {
      const categorizedUploadedFile: CategorizedUploadedFile = {
        fileCategoryName: cat,
        uploadedFiles: item.categorizedUploadedFiles?.find(
          (c) => c.fileCategoryName === cat,
        )?.uploadedFiles,
      }
      return categorizedUploadedFile
    })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex flex-row mb-4">
      {categorizedUploadedFiles.map((fileCategory) => {
        if (
          !item.isContinueUsing &&
          fileCategory.fileCategoryName === 'agreement_on_continued_use'
        )
          return

        return (
          <UploaderWrapper>
            <UppyUploader
              label={t(
                `car_item_upload_files.${fileCategory.fileCategoryName}`,
              )}
              hideFileName={true}
              allowedFileTypes={[...ALLOWED_FILE_TYPES, '.pdf']}
              initialFiles={
                categorizedUploadedFiles.find(
                  (c) => c.fileCategoryName === fileCategory.fileCategoryName,
                ).uploadedFiles ?? []
              }
              onFilesChanged={(result) =>
                onUploadCategorizedFile(
                  result[0],
                  fileCategory.fileCategoryName,
                )
              }
            />
          </UploaderWrapper>
        )
      })}
    </div>
  )
}

export default CarItemUploadsOverview

const UploaderWrapper = styled.div`
  margin-right: 2rem;
`
