import { useGetItemById } from '../../../items/hooks'
import { useCreatePrivateSaleTransaction } from '../../hooks/createPrivateSaleTransaction'
import { isValidCashbookTransaction } from '../AddPrivateSale/PrivateSaleTransactionsList'
import {
  DialogProps,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Fragment, useContext, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import AddPrivateSaleTransaction from '@/domains/privateSales/components/Common/AddPrivateSaleTransaction'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import { PrivateSale, PrivateSaleTransactionArgs } from '@/schemaTypes'
import { Timezone } from '@/utils/time'
import PrivateSaleTransactionDetails from './PrivateSaleTransactionDetails'

interface PrivateSaleTransactionsListProps {
  privateSale: PrivateSale
  timezone: Timezone
  itemId: string
}

const scroll: DialogProps['scroll'] = 'paper'

const PrivateSaleTransactionsList: FunctionComponent<
  PrivateSaleTransactionsListProps
> = (props) => {
  const { privateSale, timezone, itemId } = props
  const { closeConfirmModal, showInfo } = useContext(Context)
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })

  const { item } = useGetItemById({
    variables: {
      itemId,
    },
    skip: !itemId,
  })

  const addTransaction = useMutationShowingErrors({
    mutation: useCreatePrivateSaleTransaction(),
    successMessage: `${t('transaction_created_successfully')}!`,
  })

  const onConfirm = (args: PrivateSaleTransactionArgs) => {
    setOpen(false)
    if (isValidCashbookTransaction(args) && !args.shopId) {
      return showInfo(t('must_select_cashbook_for_private_sale_transaction'))
    }

    showConfirmPopup({
      title: t('add_private_sale_transaction'),
      action: async () => {
        closeConfirmModal()
        await addTransaction({
          variables: {
            privateSaleId: privateSale._id,
            args: args,
            needToHandleCashbook: true,
          },
        })
      },
    })
  }

  const onClose = () => {
    setOpen(false)
  }

  if (!privateSale) return null

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="left">{t('date')}</TableCell>
              <TableCell align="left">
                {t('item_categories.created_by')}
              </TableCell>
              <TableCell align="left">{t('payment_type')}</TableCell>
              <TableCell align="left">{t('transaction_type')}</TableCell>
              <TableCell align="left">{t('amount.label')}</TableCell>
              <TableCell align="left">{t('balance')}</TableCell>
              <TableCell align="left">{t('cashbook_connection')}</TableCell>
              <TableCell align="left">{t('attachment')}</TableCell>
              <TableCell align="left">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(true)}
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          {privateSale.privateSaleTransactions && (
            <TableBody>
              {privateSale.privateSaleTransactions.map((trasnaction) => (
                <PrivateSaleTransactionDetails
                  key={trasnaction.order}
                  privateSale={privateSale}
                  privateSaleTransaction={trasnaction}
                  timezone={timezone}
                  item={item}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <AddPrivateSaleTransaction
        open={open}
        scroll={scroll}
        handleClose={onClose}
        onConfirm={onConfirm}
        isPrivateSaleCreated={true}
        privateSale={privateSale}
        isEditMode={false}
        item={item}
      />
    </Fragment>
  )
}

export default PrivateSaleTransactionsList
