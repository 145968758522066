import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationUpdateItemUploadedFilesArgs } from '@/schemaTypes'

const UPDATE_ITEM_UPLOADED_FILES = gql`
  mutation updateItemUploadedFiles(
    $itemId: ObjectId!
    $uploadedFilesArgs: [FileTusMetadataArgs!]!
  ) {
    updateItemUploadedFiles(
      itemId: $itemId
      uploadedFilesArgs: $uploadedFilesArgs
    ) {
      _id
      uploadedFiles {
        ...FileMetadataFragment
      }
    }
  }
  ${fragments.fileMetadata}
`

type TData = { updateItemUploadedFiles: Mutation['updateItemUploadedFiles'] }
type TVariables = MutationUpdateItemUploadedFilesArgs

export function useUpdateItemUploadedFiles(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateItemUploadedFiles] = useMutation<TData, TVariables>(
    UPDATE_ITEM_UPLOADED_FILES,
    opts,
  )

  return updateItemUploadedFiles
}
