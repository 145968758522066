import { QueryHookOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import useDebouncedQuery from '@/hooks/useDebouncedQuery'
import { Query, QuerySearchCashBookCashFlowsArgs } from '@/schemaTypes'

const SEARCH_CASH_BOOK_CASH_FLOWS = gql`
  query searchCashBookCashFlows($cashFlowNumber: String!) {
    searchCashBookCashFlows(cashFlowNumber: $cashFlowNumber) {
      _id
      cashFlowNumber
      cashBookType
      date
      outflow
      paymentReference
      cashBookType
    }
  }
`

type TData = { searchCashBookCashFlows: Query['searchCashBookCashFlows'] }
type TVariables = QuerySearchCashBookCashFlowsArgs

export function useSearchCashBookCashFlows(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useDebouncedQuery<TData, TVariables>(
    SEARCH_CASH_BOOK_CASH_FLOWS,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
    200,
  )

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
