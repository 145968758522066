import ManualFeeDefinition from '../ManualFeeDefintion/ManualFeeDefinition'
import UsedFeeDefinitionViewer from '../UsedFeeDefinitionViewer'
import styled from '@emotion/styled'
import InfoIcon from '@material-ui/icons/Info'
import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ToggleButton,
} from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { Fragment } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import AmountInput from '@/components/AmountInput'
import LoadingSpinner from '@/components/LoadingSpinner'
import {
  Deal,
  DealCalculation,
  DealExtensionCalculationArgs,
} from '@/schemaTypes'
import {
  getManualFeeDefinitionsFromCalculation,
  getTotalGrossFeeAmountOfType,
} from '@/utils/deal'
import { displayLocalAmount } from '@/utils/misc'
import styles from './ExtensionBox.module.scss'

interface ExtensionParentDetailsProps {
  deal: Deal
  dealExtensionCalculationArgs: DealExtensionCalculationArgs
  setDealExtensionCalculationArgs: Dispatch<
    SetStateAction<DealExtensionCalculationArgs>
  >
  partialRedemptionAmount?: number
  dealCalculation?: DealCalculation
  isExtensionConfirmed: boolean
  isLoading: boolean
  extensionCalculationQueryResult
  initialCalculation?: DealCalculation
}

const ExtensionParentDetails: FunctionComponent<ExtensionParentDetailsProps> = (
  props,
) => {
  const {
    deal,
    dealExtensionCalculationArgs,
    setDealExtensionCalculationArgs,
    dealCalculation,
    partialRedemptionAmount,
    isExtensionConfirmed,
    isLoading,
    extensionCalculationQueryResult,
    initialCalculation,
  } = props
  const { t } = useTranslation()

  const [showParentFeeDetails, setShowParentFeeDetails] = useState(false)
  const [showPartialRedemptionDesc, setShowPartialRedemptionDesc] =
    useState(false)
  const [showParentManualFeeDetails, setShowParentManualFeeDetails] =
    useState(false)

  return (
    <Fragment>
      <div className={`${styles.boxDiv}`}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell>
                <span style={{ marginRight: '0.5rem', marginLeft: '-0.7rem' }}>
                  <ToggleButtonStyled
                    value={showParentFeeDetails}
                    size="small"
                    selected={showParentFeeDetails}
                    onChange={() => {
                      setShowParentFeeDetails(!showParentFeeDetails)
                    }}
                  >
                    <InfoIcon />
                  </ToggleButtonStyled>
                </span>
                {t('due_fees')}
              </TableCell>

              <TableCell
                sx={{
                  paddingLeft: '70px',
                }}
              >
                {dealCalculation
                  ? displayLocalAmount(
                      getTotalGrossFeeAmountOfType(
                        dealCalculation?.appliedUsedFeeDefinitions,
                      ),
                    )
                  : '-'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <span style={{ marginRight: '0.5rem', marginLeft: '-0.7rem' }}>
                  <ToggleButtonStyled
                    value={showPartialRedemptionDesc}
                    size="small"
                    selected={showPartialRedemptionDesc}
                    onChange={() => {
                      setShowPartialRedemptionDesc((v) => !v)
                    }}
                  >
                    <InfoIcon />
                  </ToggleButtonStyled>
                </span>
                {`${t('fees.partial_redemption')}:`}
              </TableCell>
              <TableCell
                sx={{
                  paddingLeft: '70px',
                }}
              >
                <AmountInput
                  disabled={isExtensionConfirmed}
                  error={
                    !isExtensionConfirmed && partialRedemptionAmount < 0
                      ? t('partial_redemption_cannot_be_negative')
                      : undefined
                  }
                  size="small"
                  type="float"
                  inputWidth="70px"
                  value={
                    dealExtensionCalculationArgs.overwrittenPartialRedemptionAmount ??
                    partialRedemptionAmount
                  }
                  onChange={(value) =>
                    setDealExtensionCalculationArgs((args) => ({
                      ...args,
                      overwrittenPartialRedemptionAmount:
                        value >= 0 ? value : undefined,
                    }))
                  }
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  borderTop: '1px solid black',
                  paddingLeft: '5px',
                }}
              >{`${t('due_amount')}:`}</TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  borderTop: '1px solid black',
                  fontSize: '16px',
                  paddingLeft: '70px',
                }}
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : dealCalculation ? (
                  displayLocalAmount(
                    (partialRedemptionAmount ?? 0) +
                      getTotalGrossFeeAmountOfType(
                        dealCalculation.appliedUsedFeeDefinitions,
                      ),
                  )
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  width: '250px',
                  paddingLeft: '5px',
                }}
              >
                {t('add_additional_fees_parent')}
              </TableCell>

              <TableCell>
                <span style={{ marginRight: '1rem', paddingLeft: '45px' }}>
                  <Switch
                    color="secondary"
                    checked={showParentManualFeeDetails}
                    onChange={(event) => {
                      setShowParentManualFeeDetails(event.target.checked)
                      setDealExtensionCalculationArgs((args) => ({
                        ...args,
                        manualFeeDefinitionsArgs:
                          getManualFeeDefinitionsFromCalculation(
                            dealCalculation,
                            deal.dealType,
                          ),

                        shouldOverwriteManualFees: event.target.checked,
                      }))
                    }}
                    disabled={!dealCalculation && !isExtensionConfirmed}
                  />
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      {(dealCalculation || isExtensionConfirmed) && (
        <div>
          <div style={{ marginTop: '1rem' }}>
            {showParentFeeDetails && (
              <UsedFeeDefinitionViewer
                deal={deal}
                dealCalculation={dealCalculation}
                items={deal.items}
                isLoading={isLoading}
                showLayoutButton
                defaultLayout={'module'}
                showItemDetailsButton
                itemRecordsButtonDefaultValue
                showChargTableText
              />
            )}
          </div>

          {showPartialRedemptionDesc && (
            <div className={`${styles.boxDiv}`}>
              {t('fees.partial_redemption_info')}
            </div>
          )}

          {showParentManualFeeDetails && (
            <div style={{ marginTop: '1rem' }}>
              <ManualFeeDefinition
                deal={deal}
                args={dealExtensionCalculationArgs.manualFeeDefinitionsArgs}
                setArgs={(value) => {
                  setDealExtensionCalculationArgs((args) => ({
                    ...args,
                    manualFeeDefinitionsArgs: value,
                    shouldOverwriteManualFees: showParentManualFeeDetails,
                  }))
                }}
                dealCalculation={dealCalculation}
                calculationQueryResult={extensionCalculationQueryResult}
                showPayoutAmount={false}
                showResetButton={true}
                isReadonly={isExtensionConfirmed}
                initialCalculation={initialCalculation}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  )
}

const ToggleButtonStyled = styled(ToggleButton)`
  &&& {
    paddingtop: 5px;
    width: 1px;
    height: 1px;
  }
`

export default ExtensionParentDetails
