export enum EWeekDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum EVehicleCategory {
  CAR = '612e510d880278dd21d87ddf',
  CAR_STORED = '618b9219697fcfab9a619b1b',
  MOTORCYCLE = '616e8d5bf99947cba49f492b',
  MOTORCYCLE_STORED = '618b93f24466a86c06e73a7e',
}

export enum EItemCategory {
  LIFE_INSURANCE = '612e5234d3a8d0ddeb428102',
}

export enum ErrorCodes {
  CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
  ENTITY_NOT_CREATED = 'ENTITY_NOT_CREATED',
  ENTITY_NOT_UPDATED = 'ENTITY_NOT_UPDATED',
  ENTITY_ALREADY_RELEASED = 'ENTITY_ALREADY_RELEASED',
  ENTITY_NOT_DELETED = 'ENTITY_NOT_DELETED',
  ENTITY_VALIDATION_FAILED = 'ENTITY_VALIDATION_FAILED',
  INSUFFICIENT_PRIVILEGES = 'INSUFFICIENT_PRIVILEGES',
  ITEM_DEAL_HISTORY_ENTRY_NOT_FOUND = 'ITEM_DEAL_HISTORY_ENTRY_NOT_FOUND',
  FILE_TOO_BIG = 'FILE_TOO_BIG',
  FILE_UPLOAD_NOT_FINISHED = 'FILE_UPLOAD_NOT_FINISHED',
}

export const USED_OPTICAL_CONDITION_ANSWER = 3
export const CATEGORY_IDS_WITH_USED_GRADE = [
  '612e510d880278dd21d87dd9', // Elektronik
]
export const QUESTION_OPTICAL_CONDITION = 'QUESTION_OPTICAL_CONDITION'
export const CUSTOM_QUESTION_OPTICAL_CONDITION = 'CONDITION'
export const QUESTION_ITEM_CLEANING = 'QUESTION_ITEM_CLEANING'
export const QUESTION_CUSTOMER_CONTINUE_USING_ITEM_TITLE =
  'QUESTION_CUSTOMER_CONTINUE_USING_ITEM'
export const QUESTION_CUSTOMER_CONTINUE_USING_ITEM_ID =
  '62cd2634b79f4ed327cc1483'
export const PRICE_QUESTION_TITLE_KEY = 'PRICE'
export const PRODUCT_NAME_QUESTION_TITLE_KEY = 'PRODUCT_NAME'
export const ANSWER_CUSTOMER_CONTINUE_USING_ITEM_YES = 0
export const ANSWER_CUSTOMER_CONTINUE_USING_ITEM_NO = 1
export const POST_COD_AMOUNT_MAXIMUM = 10000

export enum EItemEvaluationActionType {
  ADD_PRODUCT_TO_DEAL = 'ADD_PRODUCT_TO_DEAL',
  EXCHANGE_ITEM = 'EXCHANGE_ITEM',
  CHANGE_ITEM_ANSWER = 'CHANGE_ITEM_ANSWER',
  ADD_PRODUCT_TO_CUSTOME_DEAL = 'ADD_PRODUCT_TO_CUSTOME_DEAL',
}
