import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationActivateEmployeeArgs } from '@/schemaTypes'

const ACTIVATE_EMPLOYEE = gql`
  mutation activateEmployee($args: EmployeeActivationArgs!) {
    activateEmployee(args: $args) {
      _id
    }
  }
`

type TData = { activateEmployee: Mutation['activateEmployee'] }
type TVariables = MutationActivateEmployeeArgs

export function useActivateEmployee(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [activateEmployee] = useMutation<TData, TVariables>(
    ACTIVATE_EMPLOYEE,
    opts,
  )

  return activateEmployee
}
