import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import { Message } from 'primereact/message'
import { Panel } from 'primereact/panel'
import { useState } from 'react'
import { PanViewer } from 'react-image-pan-zoom-rotate'
import FormikDatePicker from '@/redesign/components/FormikFields/FormikDatePicker/FormikDatePicker'
import FormikDropdown from '@/redesign/components/FormikFields/FormikDropdown/FormikDropdown'
import FormikText from '@/redesign/components/FormikFields/FormikInput/FormikInput'

type Props = {
  url: string
  identityTypeOptions: { label: string; value: string }[]
  maxDate?: Date
  t: TFunction
}

const ZOOM_STEP = 0.3

export const CheckIdentityZone = ({
  url,
  maxDate,
  t,
  identityTypeOptions,
}: Props) => {
  const [dx, setDx] = useState(0)
  const [dy, setDy] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)

  const zoomIn = () => {
    setZoom((prevZoom) => prevZoom + ZOOM_STEP)
  }

  const zoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - ZOOM_STEP, ZOOM_STEP))
  }

  const rotateLeft = () => {
    setRotation((prevRotation) => (prevRotation - 1) % 4)
  }

  const rotateRight = () => {
    setRotation((prevRotation) => (prevRotation + 1) % 4)
  }

  const onPan = (dx: number, dy: number) => {
    setDx(dx)
    setDy(dy)
  }

  return (
    <>
      <Wrapper>
        <ImageWrapper>
          <Panel
            header={
              <ControlIconWrapper>
                <span onClick={zoomIn}>
                  <i className="pi pi-search-plus" />
                </span>
                <span onClick={zoomOut}>
                  <i className="pi pi-search-minus" />
                </span>
                <span onClick={rotateLeft}>
                  <i className="pi pi-replay" />
                </span>
                <span onClick={rotateRight}>
                  <i className="pi pi-refresh" />
                </span>
              </ControlIconWrapper>
            }
          >
            <Zone>
              <PanViewer
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1,
                }}
                zoom={zoom}
                setZoom={setZoom}
                pandx={dx}
                pandy={dy}
                onPan={onPan}
                rotation={rotation}
                key={dx}
              >
                <img
                  style={{
                    transform: `rotate(${rotation * 90}deg) scaleX(1)`,
                    width: '100%',
                  }}
                  src={url}
                />
              </PanViewer>
            </Zone>
          </Panel>
        </ImageWrapper>
        <InfoWrapper>
          <CustomerInfo>
            <FormikText
              required
              name="firstname"
              label={t('customer.firstname')}
              placeholder={t('customer.firstname')}
              className="flex flex-col md:flex-row items-start md:items-center mb-4"
              labelClassName="text-sm font-semibold w-32"
              inputContainerClassName="w-48"
            />

            <FormikText
              required
              name="lastname"
              label={t('customer.lastname')}
              placeholder={t('customer.lastname')}
              className="flex flex-col md:flex-row items-start md:items-center mb-4"
              labelClassName="text-sm font-semibold w-32"
              inputContainerClassName="w-48"
            />

            <FormikDatePicker
              required
              appendTo={document.body}
              name="dateOfBirth"
              dateFormat="dd.mm.yy"
              label={t('customer.date_of_birth')}
              placeholder={t('customer.date_of_birth')}
              maxDate={maxDate}
              className="flex flex-col md:flex-row items-start md:items-center mb-4"
              showIcon
              labelClassName="text-sm font-semibold w-32"
              inputContainerClassName="w-32"
            />

            <FormikDropdown
              required
              name="identityType"
              label={t('type')}
              options={identityTypeOptions}
              placeholder={t('type')}
              className="flex flex-col md:flex-row items-start md:items-center mb-4"
              labelClassName="text-sm font-semibold w-32"
              inputContainerClassName="w-48"
            />

            <FormikText
              required
              name="identityNumber"
              label={t('id_number')}
              placeholder={t('id_number')}
              className="flex flex-col md:flex-row items-start md:items-center mb-4"
              labelClassName="text-sm font-semibold w-32"
              inputContainerClassName="w-48"
            />
          </CustomerInfo>
        </InfoWrapper>
      </Wrapper>
      <InfoMessageWrapper>
        <Message
          severity="warn"
          text={t('identify_verification.warn_match_identity_info')}
        />
      </InfoMessageWrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 80vw;
  max-width: 1200px;
  gap: 2em;
`

const ImageWrapper = styled.div`
  flex: 1;

  .p-panel-content {
    padding: 0 !important;
  }

  .p-panel-header {
    padding: 0 !important;

    .p-panel-title {
      width: 100%;
      height: 100%;
    }
  }
`

const InfoWrapper = styled.div`
  width: 370px;
`

const Zone = styled.div`
  overflow: hidden;
  width: 100%;
  height: 600px;
  background-color: #fafafa;
`

const CustomerInfo = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ControlIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0.7em;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    aspect-ratio: 1;
    cursor: pointer;

    &:hover {
      & > i {
        font-size: 1.3rem;
      }
    }

    & > i {
      font-size: 1.2rem;
    }
  }
`

const InfoMessageWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
`
