import TextInput from '../TextInput/TextInput'
import { Button, Chip, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './TextInputList.module.scss'

export interface TextInputListProps {
  onAdd: (id: string) => void
  onDelete: (index: number) => void
  values: string[]
  label: string
  unique?: boolean
  inputWidth?: string
}

export function TextInputList(props: TextInputListProps) {
  const { label, onAdd, onDelete, values, unique, inputWidth } = props
  const { t } = useTranslation()

  const [inputValue, setInputValue] = useState<string>('')

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        {label}
      </Typography>
      <div className={'u-flex'} style={{ width: '100%' }}>
        <TextInput
          value={inputValue}
          onChange={(value) => setInputValue(value)}
          style={{ width: inputWidth }}
          inputWidth="100%"
        />
        <Button
          onClick={() => {
            onAdd(inputValue)
          }}
          disabled={unique && !!values.find((value) => inputValue === value)}
        >
          {t('add')}
        </Button>
      </div>
      <div className="u-mt-sm">
        {values.map((value, index) => (
          <Chip
            className={styles.chip}
            key={index}
            label={value}
            onDelete={() => onDelete(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default TextInputList
