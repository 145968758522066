import { Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import ItemQuestionsDetails from '@/domains/itemQuestions/components/ItemQuestionsDetails'
import {
  useGetItemQuestion,
  useUpdateItemQuestion,
} from '@/domains/itemQuestions/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { ItemQuestion } from '@/schemaTypes'

export interface ItemQuestionsEditProps {
  itemQuestionId: string
}

export function ItemQuestionsEdit(props: ItemQuestionsEditProps) {
  const { itemQuestionId } = props
  const { t } = useTranslation()

  const { itemQuestion, queryResult: getItemQuestionQueryResult } =
    useGetItemQuestion({
      variables: {
        itemQuestionId,
      },
    })

  const updateItemQuestion = useMutationShowingErrors({
    mutation: useUpdateItemQuestion(),
    successMessage: t('item_question_updated'),
  })

  const onSave = (itemQuestion: ItemQuestion) => {
    updateItemQuestion({
      variables: {
        ...itemQuestion,
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={itemQuestionId}
        modelName={'itemQuestions'}
        mode={'edit'}
      />
      <Typography variant="h5" gutterBottom>
        {t('item_question_edit')}
      </Typography>
      {getItemQuestionQueryResult.loading ? (
        <Loading />
      ) : (
        itemQuestion && (
          <ItemQuestionsDetails itemQuestion={itemQuestion} onSave={onSave} />
        )
      )}
    </Paper>
  )
}

export default ItemQuestionsEdit
