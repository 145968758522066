import styled from '@emotion/styled'
import { Box, Button, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ProductPublishConfirmPopup } from '@/domains/products/components/ProductModals'
import { useUpdateProductPublication } from '@/domains/products/hooks/updateProductPublication'
import { useMutationShowingErrors } from '@/hooks'
import { Product } from '@/schemaTypes'

interface Props {
  product: Product
}

export default function ProductTitle({ product }: Props) {
  const { t } = useTranslation()
  const [openPublishConfirmPopup, setOpenPublishConfirmPopup] = useState(false)

  const updateProductPublication = useMutationShowingErrors({
    mutation: useUpdateProductPublication(),
    successMessage: t('update_product_publication_successfully'),
  })

  return (
    <Wrapper>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <HeadTitle variant="h4">{product.name}</HeadTitle>

        <div>
          <Link to={`/inApp/products/edit/${product._id}`}>
            <EditIconButton className="u-mr-mini" variant="contained">
              <EditIcon /> {t('edit_product')}
            </EditIconButton>
          </Link>
          <PublishButton
            published={product.isPublished || undefined}
            variant="contained"
            onClick={() => setOpenPublishConfirmPopup(true)}
          >
            {product.isPublished ? 'Published' : 'Unpublished'}
          </PublishButton>
        </div>
      </Box>

      <ProductPublishConfirmPopup
        text={
          product.isPublished
            ? t('product_unpublish_confirm_popup')
            : t('product_publish_confirm_popup')
        }
        open={!!openPublishConfirmPopup}
        onClose={() => setOpenPublishConfirmPopup(false)}
        onSave={() => {
          setOpenPublishConfirmPopup(false)
          if (!product) return
          updateProductPublication({
            variables: {
              productId: product._id,
              isPublished: !product.isPublished,
            },
            refetchQueries: ['getProduct'],
          })
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 1rem;
`

const HeadTitle = styled(Typography)`
  color: #683ab7c0;
  font-weight: 700;
`

const EditIconButton = styled(Button)`
  &&& {
    padding: 0 1rem;
    background-color: #683ab7c0;
    color: white;
    font-size: 0.875rem;
    text-transform: uppercase;
    height: 46px;
    min-width: 120px;

    &:hover {
      background-color: #673aba !important;
    }

    svg {
      border-radius: 80%;
      height: 30px !important;
      width: 30px !important;
      background-color: white;
      padding: 0.25rem !important;
      margin: 0.5rem !important;
      color: #683ab7c0 !important;
    }
  }
`

const PublishButton = styled(Button)<{
  published?: boolean
}>`
  &&& {
    height: 46px;
    min-width: 120px;
    background: ${(props) => (props.published ? '#673aba' : '#C0392B')};
    color: white;

    &:hover {
      background: ${(props) => (props.published ? '#673aba' : '#C0392B')};
    }
  }
`
