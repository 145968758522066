import { onlyOverwriteIsPossible } from '../CustomDealItems'
import { CustomItemDraftFormData } from '../CustomItemDraft'
import styled from '@emotion/styled'
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Slider,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import dayjs from 'dayjs'
import { FormikErrors } from 'formik'
import { useTranslation } from 'react-i18next'
import AmountInput from '@/components/AmountInput'
import LoadingSpinner from '@/components/LoadingSpinner'
import { getMaxComparableOffersCount } from '@/domains/customDeals/helpers'
import {
  ECustomItemFoundComparableOffers,
  EDealType,
  ItemValuesEntry,
  PriceFinding,
  PriceFindingArgs,
} from '@/schemaTypes'

interface Props {
  isVehicle: boolean
  itemPawnValuesEntry?: ItemValuesEntry
  itemPurchaseValuesEntry?: ItemValuesEntry
  dealType: EDealType
  payoutAmount: number
  foundComparableOffers: ECustomItemFoundComparableOffers
  values: (PriceFindingArgs | PriceFinding)[]
  readonly?: boolean
  formikErrors?: FormikErrors<CustomItemDraftFormData>
  setFieldValue: (field: string, value: unknown) => void
  calculationRunning: boolean
  overwrittenItemValue?: number
  foundOffersEvaluationFactor?: number
  overwriteItemValue?: boolean
  setOverWriteItemValue?: (value: boolean) => void
  priceFindingLengthError?: string
  overwrittenItemValueError?: string
  counterofferPayoutAmount?: number
}

const FOUND_COMPARABLE_OFFERS = Object.values(ECustomItemFoundComparableOffers)

const getCreatorInfo = (priceFinding: PriceFinding | PriceFindingArgs) => {
  if ('createdAt' in priceFinding && 'createdBy' in priceFinding) {
    return `${priceFinding.createdBy?.firstname} ${priceFinding.createdBy?.lastname} - ${dayjs(priceFinding.createdAt).format('DD.MM.YYYY HH:mm')}`
  }

  return undefined
}

export function PriceDetermination(props: Props) {
  const {
    isVehicle,
    itemPawnValuesEntry,
    itemPurchaseValuesEntry,
    dealType,
    payoutAmount,
    foundComparableOffers,
    values,
    setFieldValue,
    readonly,
    calculationRunning,
    overwrittenItemValue,
    foundOffersEvaluationFactor,
    overwriteItemValue,
    setOverWriteItemValue,
    priceFindingLengthError,
    overwrittenItemValueError,
    counterofferPayoutAmount,
  } = props

  const errors = props.formikErrors as FormikErrors<CustomItemDraftFormData>
  const priceFindingsErrors =
    errors?.priceFindings as FormikErrors<PriceFindingArgs>
  const { t } = useTranslation()

  const onChangeField = (field: string, value: string, i: number) => {
    setFieldValue(`priceFindings.[${i}].${field}`, value)
  }

  const onLeaveField = (i: number) => {
    if (
      values.length < getMaxComparableOffersCount(foundComparableOffers) &&
      !priceFindingsErrors &&
      Boolean(values[i].price) &&
      Boolean(values[i].url)
    ) {
      addPriceFindingRecord()
    }
  }

  const onChangeFoundComparableOffers = (
    foundComparableOffers: ECustomItemFoundComparableOffers,
  ) => {
    setFieldValue('overwrittenItemValue', undefined)

    if (
      foundComparableOffers === ECustomItemFoundComparableOffers.OneOrTwo ||
      foundComparableOffers === ECustomItemFoundComparableOffers.NoOffersFound
    ) {
      setOverWriteItemValue(true)
    } else {
      setOverWriteItemValue(false)
    }

    setFieldValue(`foundComparableOffers`, foundComparableOffers)
    const maxoffers = getMaxComparableOffersCount(foundComparableOffers)

    if (values.length > maxoffers) {
      setFieldValue(`priceFindings`, values.slice(0, maxoffers))
    } else if (values.length < maxoffers && !values.some((c) => !c.price)) {
      addPriceFindingRecord()
    }
  }

  const addPriceFindingRecord = () => {
    setFieldValue(`priceFindings`, [...values, { url: null, price: null }])
  }

  return (
    <div>
      <Typography variant="h6">{t('cashy_determination_price')}</Typography>

      <CustomerRequestedPrice display="flex" alignItems="center">
        <Typography variant="body1" style={{ marginRight: '1rem' }}>
          {t('customer_requested_price')}:
        </Typography>
        <AmountInput
          value={payoutAmount}
          onChange={(value) => {
            setFieldValue('payoutAmount', value)
          }}
          error={errors?.payoutAmount && t(errors?.payoutAmount)}
        />
      </CustomerRequestedPrice>

      <Typography variant="body1" gutterBottom style={{ color: '#FF2121' }}>
        {t('cashy_determination_price_description')}
      </Typography>

      {!counterofferPayoutAmount && (
        <Box display="flex" style={{ marginBottom: '1rem' }}>
          <Typography variant="body1" style={{ marginTop: '0.5rem' }}>
            {t('comparable_offers_found')}:
          </Typography>

          <Select
            error={Boolean(priceFindingLengthError)}
            style={{ marginLeft: '1rem' }}
            value={foundComparableOffers}
            onChange={(event) =>
              onChangeFoundComparableOffers(
                event.target.value as ECustomItemFoundComparableOffers,
              )
            }
          >
            {FOUND_COMPARABLE_OFFERS.map((c) => (
              <MenuItem key={c} value={c}>
                {t(c)}
              </MenuItem>
            ))}
          </Select>
          {priceFindingLengthError && (
            <span
              style={{ color: 'red', marginLeft: '1rem', marginTop: '0.5rem' }}
            >
              {t(priceFindingLengthError)}
            </span>
          )}
        </Box>
      )}

      {values.map((priceFinding, i) => (
        <DeterminationItem key={i}>
          <Column display="flex" alignItems="center">
            <Typography variant="body1">{t('url')}:</Typography>
            <TextField
              fullWidth
              value={priceFinding.url ?? ''}
              onChange={(e) => onChangeField('url', e.target.value, i)}
              onBlur={() => onLeaveField(i)}
              disabled={readonly}
              error={!!priceFindingsErrors?.[i]?.url}
              helperText={priceFindingsErrors?.[i]?.url}
            />
          </Column>

          <Column display="flex" alignItems="center">
            <Typography variant="body1">{t('price')}:</Typography>
            <TextField
              style={{ alignItems: 'right' }}
              type="float"
              fullWidth
              value={priceFinding.price}
              onChange={(e) => onChangeField('price', e.target.value, i)}
              onBlur={() => onLeaveField(i)}
              disabled={readonly}
              error={!!priceFindingsErrors?.[i]?.price}
              helperText={priceFindingsErrors?.[i]?.price}
            />
            <StyledIconButton>
              <InfoIcon />
              {getCreatorInfo(priceFinding) && (
                <Typography className="popover" variant="caption">
                  {getCreatorInfo(priceFinding)}
                </Typography>
              )}
            </StyledIconButton>
          </Column>
        </DeterminationItem>
      ))}

      {!counterofferPayoutAmount && (
        <StyledTable>
          <TableBody>
            {!overwriteItemValue ? (
              <>
                <TableRow>
                  <StyledCell isLabel={true} align="right" />
                  <StyledCell>
                    <Button
                      style={{
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        setFieldValue(
                          'overwrittenItemValue',
                          itemPawnValuesEntry?.adjustedMarketValue,
                        )
                        setOverWriteItemValue(true)
                      }}
                      disabled={!foundComparableOffers}
                    >
                      {t('set_individual_value')}
                    </Button>
                  </StyledCell>
                </TableRow>

                <TableRow>
                  <StyledCell isLabel={true} align="right">
                    {t('item_value')}:
                  </StyledCell>
                  <StyledCell>
                    {itemPawnValuesEntry ? (
                      <AmountInput
                        inputWidth="80%"
                        value={itemPawnValuesEntry?.adjustedMarketValue}
                        onChange={(value) => {
                          setFieldValue(
                            'overwrittenItemValue',
                            value > 0 ? value : undefined,
                          )
                        }}
                        error={
                          errors?.counterofferPayoutAmount &&
                          t(errors?.counterofferPayoutAmount)
                        }
                        disabled
                      />
                    ) : calculationRunning ? (
                      <LoadingSpinner />
                    ) : (
                      '-'
                    )}
                  </StyledCell>
                </TableRow>
              </>
            ) : (
              <>
                <TableRow>
                  <StyledCell isLabel={true} align="right">
                    {t('individual_value_estimation')}:
                  </StyledCell>
                  <StyledCell align="right">
                    <AmountInput
                      inputWidth="80%"
                      value={
                        overwriteItemValue
                          ? overwrittenItemValue
                          : itemPawnValuesEntry?.adjustedMarketValue
                      }
                      onChange={(value) => {
                        setFieldValue(
                          'overwrittenItemValue',
                          value > 0 ? value : undefined,
                        )
                        if (!value) setOverWriteItemValue(false)
                      }}
                      error={t(overwrittenItemValueError)}
                      disabled={!overwriteItemValue || readonly}
                      showRemoveIcon={
                        !onlyOverwriteIsPossible(foundComparableOffers)
                      }
                    />
                  </StyledCell>
                </TableRow>

                {onlyOverwriteIsPossible(foundComparableOffers) && (
                  <TableRow>
                    <StyledCell isLabel={true} align="right">
                      {t('payout_factor', { dealType: t(dealType) })}:
                    </StyledCell>
                    <StyledCell>
                      <span
                        style={{
                          marginLeft: '0.5rem',
                          verticalAlign: 'middle',
                        }}
                      >
                        <Slider
                          style={{ width: '70%', marginTop: '0.75rem' }}
                          defaultValue={foundOffersEvaluationFactor}
                          valueLabelDisplay="auto"
                          onChange={(event, newValue) => {
                            if (
                              onlyOverwriteIsPossible(foundComparableOffers)
                            ) {
                              setFieldValue(
                                'foundOffersEvaluationFactor',
                                newValue,
                              )
                            }
                          }}
                          min={0.4}
                          max={1}
                          step={0.01}
                          disabled={
                            !onlyOverwriteIsPossible(foundComparableOffers)
                          }
                        />
                      </span>

                      <span
                        style={{
                          marginLeft: '1rem',
                          verticalAlign: 'middle',
                          marginTop: '0.5rem',
                        }}
                      >
                        {foundOffersEvaluationFactor}
                      </span>
                    </StyledCell>
                  </TableRow>
                )}
              </>
            )}
            <TableRow>
              <StyledCell isLabel={true} align="right">
                {t('payout_pawn')}:
              </StyledCell>
              <StyledCell>
                {itemPawnValuesEntry ? (
                  <AmountInput
                    inputWidth="80%"
                    value={itemPawnValuesEntry?.payoutAmount}
                    onChange={undefined}
                    error={
                      errors?.counterofferPayoutAmount &&
                      t(errors?.counterofferPayoutAmount)
                    }
                    disabled
                  />
                ) : calculationRunning ? (
                  <LoadingSpinner />
                ) : (
                  '-'
                )}
              </StyledCell>
            </TableRow>
            <TableRow>
              <StyledCell isLabel={true} align="right">
                {t('payout_purchase')}:
              </StyledCell>
              <StyledCell>
                {itemPurchaseValuesEntry && !isVehicle ? (
                  <AmountInput
                    inputWidth="80%"
                    value={itemPurchaseValuesEntry?.payoutAmount}
                    onChange={undefined}
                    error={
                      errors?.counterofferPayoutAmount &&
                      t(errors?.counterofferPayoutAmount)
                    }
                    disabled
                  />
                ) : calculationRunning ? (
                  <LoadingSpinner />
                ) : (
                  '-'
                )}
              </StyledCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      )}

      {counterofferPayoutAmount > 0 && (
        <FinalPrice display="flex" alignItems="center">
          <Typography
            variant="body1"
            style={{ fontWeight: 'bold', marginRight: '1rem' }}
            gutterBottom
          >
            {t('final_offer_price', { dealType: t(dealType) })}
          </Typography>
          <AmountInput
            inputWidth="100%"
            value={counterofferPayoutAmount as number}
            disabled
            onChange={() => undefined}
          />
        </FinalPrice>
      )}
    </div>
  )
}

const DeterminationItem = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.24fr;
  column-gap: 3rem;
`

const Column = styled(Box)`
  > p:first-of-type {
    margin-right: 1rem;
  }
`

const StyledIconButton = styled(IconButton)`
  position: relative;

  .popover {
    position: absolute;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0.5rem;
    right: 3.25rem;
    width: 200px;
    display: none;
  }

  &:hover {
    .popover {
      display: block;
    }
  }
`

const CustomerRequestedPrice = styled(Box)`
  color: #00a611;
  margin-bottom: 1rem;
`

interface StyledProps {
  isLabel?: boolean
}

const StyledTable = styled.table`
  align: right;
  padding-right: 0;
  margin-left: auto;
  margin-top: 2.5rem;
  width: 55%;
`

const StyledCell = styled(TableCell)`
  border-style: none;
  padding-left: auto;
  padding-top: ${(props: StyledProps) => (props.isLabel ? '0.5rem' : '0')};
  font-weight: ${(props: StyledProps) => (props.isLabel ? 'bold' : 'normal')};
`
const FinalPrice = styled(Box)`
  margin-left: auto;
  margin-top: 2rem;
  width: 50%;
  display: flex;

  > div {
    width: 100%;
    flex: 1;
  }
`
