import styled from '@emotion/styled'
import {
  Box,
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface SearchWrapperProps {
  onResetFilter: () => void
  additionalComponent?: React.ReactNode
  children?: React.ReactNode
  name?: string
  numberOfFound?: number
}

export function SearchWrapper({
  children,
  numberOfFound,
  name,
  onResetFilter,
  additionalComponent,
}: SearchWrapperProps) {
  const { t } = useTranslation()

  const [filterExpanded, setFilterExpanded] = useState(true)

  return (
    <ExpansionPanel expanded={filterExpanded} style={{ width: '100%' }}>
      <ExpansionPanelSummary
        onClick={() => setFilterExpanded((old) => !old)}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ backgroundColor: '#ede7f8' }}
      >
        <ExpansionPanelSummaryWrapper>
          <Typography
            style={{
              color: 'rgba(104, 58, 183, 0.7529411765)',
              fontSize: 19,
              fontWeight: 600,
            }}
          >
            {t('filters')}
          </Typography>
          <Typography>
            {numberOfFound !== undefined && (
              <FoundText>
                {numberOfFound} {name} {t('found')}
              </FoundText>
            )}
          </Typography>
        </ExpansionPanelSummaryWrapper>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ width: '100%' }}>
        <Box style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <ResetButton
              style={{ height: 30, width: 110 }}
              className={'u-mv-sm u-ml-sm'}
              onClick={onResetFilter}
              variant="contained"
            >
              {t('reset')}
            </ResetButton>
            <Box style={{ display: 'flex' }}>
              {additionalComponent ? additionalComponent : null}
            </Box>
          </div>
          {children}
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const ResetButton = styled(Button)`
  &&& {
    margin: 10px 0px 10px 0px !important;
  }
`

const ExpansionPanelSummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const FoundText = styled.span`
  color: #6d6a71;
  font-weight: 600;
`
