import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryGetShopsArgs } from '@/schemaTypes'

const GET_SHOPS = gql`
  query getShops($opts: ShopOpts) {
    getShops(opts: $opts) {
      _id
      name
      phone
      email
      googleMapsLink
      companyId
      disabled
      successionShopId
      mainStorageFacilityId
      successionShop {
        _id
        name
        disabled
      }
      address {
        ...AddressFragment
      }
      openingHours
      company {
        primaryShopLocationId
      }
    }
  }
  ${fragments.address}
`

type TData = { getShops: Query['getShops'] }
type TVariables = QueryGetShopsArgs

export function useGetShops(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useQuery<TData, TVariables>(GET_SHOPS, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, shops: queryResult.data?.getShops || [] }
}
