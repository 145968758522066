import { dealFragments } from '../../deals/hooks'
import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import {
  Mutation,
  MutationReevaluateFinalPrivateSaleFinalStateArgs,
} from '@/schemaTypes'

const REEVALUATE_FINAL_STATE = gql`
  mutation reevaluateFinalPrivateSaleFinalState($privateSaleId: ObjectId!) {
    reevaluateFinalPrivateSaleFinalState(privateSaleId: $privateSaleId) {
      _id
      dealStartDate
      createdAt
      updatedAt
      dealType
      businessUnit
      bookingNumber
      itemsIds
      company {
        _id
        name
        iban
        paypalEmail
      }
      pawnData {
        ...PawnDataFragment
      }
      events {
        ...DealEventFragment
      }
      payoutData {
        ...PaymentDataFragment
      }
      secondaryPayoutData {
        ...SecondaryPaymentDataFragment
      }
      items {
        ...DealItemFragment
      }
      shop {
        _id
        name
      }
      verifiedBy
      extendedBy
      uploadedFiles {
        ...FileMetadataFragment
      }
    }
  }
  ${dealFragments.dealItem}
  ${dealFragments.event}
  ${dealFragments.pawnData}
  ${fragments.paymentData}
  ${fragments.secondaryPaymentData}
  ${fragments.fileMetadata}
`

type TData = {
  reevaluateFinalPrivateSaleFinalState: Mutation['reevaluateFinalPrivateSaleFinalState']
}
type TVariables = MutationReevaluateFinalPrivateSaleFinalStateArgs

export function useReevaluateFinalPrivateSaleFinalState(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [reevaluateFinalPrivateSaleFinalState] = useMutation<TData, TVariables>(
    REEVALUATE_FINAL_STATE,
    opts,
  )

  return reevaluateFinalPrivateSaleFinalState
}
