import LoadingSpinner from '../LoadingSpinner'
import TextInput from '../TextInput'
import { UppyUploader } from '../UppyUploader'
import LinkIcon from '@material-ui/icons/Link'
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { Fragment, useContext } from 'react'
import { FunctionComponent, useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ALLOWED_FILE_TYPES } from '@/constants'
import { Context } from '@/context'
import { POST_COD_AMOUNT_MAXIMUM } from '@/domains/deals/components/constants/enums'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import { useCreateShippingLabel } from '@/hooks/createShippingLabel'
import {
  Company,
  Deal,
  EShippingLabelDirection,
  EShippingLabelEntity,
  EShippingLabelFileSource,
  EStandardShipmentType,
  IAddress,
  Item,
  ShippingLabelCreateArgs,
  ValorizationHistoryEntry,
} from '@/schemaTypes'
import { createIAddress } from '@/utils/address'
import { isItemSoldByLastnamePaymentType } from '@/utils/valorization'
import SenderReceiver from './SenderReceiver'
import styles from './ShippingLabel.module.scss'
import { convertShippingLabelModelToCreteArgs } from './ShippingLabelComponent'

interface ShippingLabelGenerateProps {
  deal: Deal
  item?: Item
  valorizationHistories?: ValorizationHistoryEntry[]
  directionType: EShippingLabelDirection
  entityType: EShippingLabelEntity
  shippingPartner: EStandardShipmentType
  shippingLabelCreateArgsFromAPI: ShippingLabelCreateArgs | undefined
  setShippingLabelCreateArgsFromAPI
  customerEmailAddress?: string | null
  betweenShops?: boolean
  allowCODPayment?: boolean
  onChangeDestinationShopId?: (shopId: string | undefined) => void
}

const getBankInfo = (company: Company) => {
  if (!company || !company.iban || !company.bic || !company.accountOwner) return
  return `${company.iban.replace(/\s/g, '')}|${company.bic.replace(/\s/g, '')}|${company.accountOwner}`
}

const ShippingLabelGenerate: FunctionComponent<ShippingLabelGenerateProps> = (
  props,
) => {
  const {
    deal,
    item,
    entityType,
    directionType,
    shippingPartner,
    shippingLabelCreateArgsFromAPI,
    setShippingLabelCreateArgsFromAPI,
    customerEmailAddress,
    betweenShops,
    valorizationHistories,
    allowCODPayment,
    onChangeDestinationShopId,
  } = props

  const { showInfo, closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()
  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })

  const isSoldPaymentTypeLastName =
    item && isItemSoldByLastnamePaymentType(item)
  const [fileGeneratingProcessIsRunning, setFileGeneratingProcessIsRunning] =
    useState(false)
  const [senderAddressIsValid, setSenderAddressIsValid] = useState(false)
  const [receiverAddressIsValid, setReceiverAddressIsValid] = useState(false)
  const [isCODPayment, setCODPayment] = useState(isSoldPaymentTypeLastName)
  const [amount, setAmount] = useState(
    valorizationHistories?.[0]?.cumulatedCashflow ?? 0,
  )
  const [paymentReference, setPaymentReference] = useState(item?.storageLabel)

  const [createSenderArgs, setCreateSenderArgs] = useState<IAddress>({})
  const [createReceiverArgs, setCreateReceiverArgs] = useState<IAddress>({})
  const [createArgs, setCreateArgs] = useState<ShippingLabelCreateArgs>({
    dealId: deal._id,
    itemId: item ? item._id : undefined,
    shippingPartner: shippingPartner,
    direction: directionType,
    entity: entityType,
    shippingLabelFileSource: EShippingLabelFileSource.Generate,
    receiverAddress: createSenderArgs,
    receiverName: '',
    senderAddress: createSenderArgs,
    senderName: '',
  })

  const showCODPayment =
    allowCODPayment && shippingPartner === EStandardShipmentType.Post

  const bankInfo = item && getBankInfo(item.company)
  const invalidCODPayment =
    allowCODPayment &&
    isCODPayment &&
    (!bankInfo ||
      !amount ||
      amount > POST_COD_AMOUNT_MAXIMUM ||
      !paymentReference)
  const invalidCODAmount = !amount
    ? t('amount.invalid_amount')
    : amount > POST_COD_AMOUNT_MAXIMUM
      ? t('amount.post_cod_amount_over_maximum', {
          value: POST_COD_AMOUNT_MAXIMUM,
        })
      : undefined

  const createShippingLabel = useMutationShowingErrors({
    mutation: useCreateShippingLabel(),
  })

  useEffect(() => {
    setShippingLabelCreateArgsFromAPI(undefined)
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createArgs, createSenderArgs, createReceiverArgs])

  useEffect(() => {
    setShippingLabelCreateArgsFromAPI((prev) =>
      prev
        ? {
            ...prev,
            isCODPayment: showCODPayment ? isCODPayment ?? false : null,
          }
        : undefined,
    )
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCODPayment, showCODPayment])

  const onCreateShippingLabelClick = () => {
    showConfirmPopup({
      title: t('add_post_shipping_confirmation'),
      action: async () => {
        if (
          !createArgs ||
          (createArgs && !createArgs.senderAddress) ||
          (createArgs && !createArgs.receiverAddress)
        )
          return showInfo(t('create_args_are_not_valid'))
        closeConfirmModal()
        setFileGeneratingProcessIsRunning(true)
        const res = await createShippingLabel({
          variables: {
            createArgs: {
              dealId: deal._id,
              itemId: item ? item._id : undefined,
              shippingPartner: shippingPartner,
              direction: directionType,
              entity: entityType,
              shippingLabelFileSource: EShippingLabelFileSource.Generate,
              senderAddress: createIAddress(createSenderArgs),
              senderName: createArgs.senderName,
              senderCompanyName: createArgs.senderCompanyName,
              receiverAddress: createIAddress(createReceiverArgs),
              receiverName: createArgs.receiverName,
              receiverCompanyName: createArgs.receiverCompanyName,
              isCODPayment: showCODPayment ? isCODPayment ?? false : null,
              customerEmailAddress,
              senderShopId: createArgs.senderShopId,
              receiverShopId: createArgs.receiverShopId,
              ...(shippingPartner === EStandardShipmentType.Post &&
                isCODPayment && {
                  additionalInfo: {
                    amount,
                    paymentReference,
                    bankInfo,
                  },
                }),
            },
          },
        })

        if (res && res.data && res.data.createShippingLabel) {
          setShippingLabelCreateArgsFromAPI(
            convertShippingLabelModelToCreteArgs(
              res.data.createShippingLabel,
              deal._id,
              item ? item._id : undefined,
              entityType,
              EShippingLabelFileSource.Generate,
            ),
          )
          setFileGeneratingProcessIsRunning(false)
        } else setFileGeneratingProcessIsRunning(false)
      },
    })
    setFileGeneratingProcessIsRunning(false)
  }

  return (
    <Fragment>
      <SenderReceiver
        deal={deal}
        item={item}
        directionType={directionType}
        entityType={entityType}
        fileSource={EShippingLabelFileSource.Generate}
        shippingPartner={shippingPartner}
        createSenderArgs={createSenderArgs}
        setCreateSenderArgs={setCreateSenderArgs}
        createReceiverArgs={createReceiverArgs}
        setCreateReceiverArgs={setCreateReceiverArgs}
        createArgs={createArgs}
        setCreateArgs={setCreateArgs}
        senderAddressIsValid={senderAddressIsValid}
        setSenderAddressIsValid={setSenderAddressIsValid}
        receiverAddressIsValid={receiverAddressIsValid}
        setReceiverAddressIsValid={setReceiverAddressIsValid}
        betweenShops={betweenShops}
        onChangeDestinationShopId={
          betweenShops ? onChangeDestinationShopId : undefined
        }
      />
      {showCODPayment && (
        <>
          <div className={styles.codDiv}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCODPayment}
                  onChange={(e) =>
                    !isSoldPaymentTypeLastName &&
                    setCODPayment(e.target.checked)
                  }
                />
              }
              label={t('cash_on_delivery_payment')}
              labelPlacement="end"
            />
          </div>
          {isCODPayment && (
            <>
              {!bankInfo && (
                <Typography variant="caption" color="error">
                  {t('missing_iban_bic_owner')}
                </Typography>
              )}
              <div className={styles.codField}>
                {t('fees.fees_amount')}:
                <TextInput
                  error={invalidCODAmount}
                  className="u-mr-25 u-ml-10"
                  variant="standard"
                  type="number"
                  value={amount}
                  onChange={(value) => setAmount(parseFloat(value))}
                />
              </div>
              <div className={styles.codField}>
                {t('payment_reference_cod')}:
                <TextInput
                  error={
                    !paymentReference && t('validation.validation_is_not_empty')
                  }
                  className="u-mr-25 u-ml-10"
                  variant="standard"
                  value={paymentReference}
                  onChange={setPaymentReference}
                />
              </div>
            </>
          )}
        </>
      )}
      <div className={styles.columnDiv}>
        {fileGeneratingProcessIsRunning && (
          <div className={styles.spinner}>
            <LoadingSpinner />
          </div>
        )}
      </div>

      <div className={styles.fileDiv}>
        {shippingLabelCreateArgsFromAPI && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: 100,
            }}
          >
            <UppyUploader
              allowedFileTypes={[...ALLOWED_FILE_TYPES, '.pdf']}
              initialFiles={
                shippingLabelCreateArgsFromAPI.labelFile
                  ? [shippingLabelCreateArgsFromAPI.labelFile]
                  : []
              }
              onFilesChanged={() => {
                setShippingLabelCreateArgsFromAPI(undefined)
              }}
            />
          </div>
        )}
      </div>

      {shippingLabelCreateArgsFromAPI && (
        <div className={styles.codeDiv}>
          <TextField
            error={!shippingLabelCreateArgsFromAPI?.trackingCode}
            variant="standard"
            sx={{ width: '200px' }}
            className="u-mr-25"
            value={shippingLabelCreateArgsFromAPI?.trackingCode}
            label={t('tracking_code')}
          />

          <br />
          <br />
          <TextField
            error={!shippingLabelCreateArgsFromAPI?.trackingCode}
            variant="standard"
            sx={{ width: '200px' }}
            className="u-mr-25"
            value={shippingLabelCreateArgsFromAPI?.trackingLink}
            label={t('tracking_link')}
          />
          <a
            target="_blank"
            style={{ marginLeft: '-1rem', verticalAlign: 'bottom' }}
            href={shippingLabelCreateArgsFromAPI?.trackingLink ?? ''}
            rel="noreferrer"
          >
            <LinkIcon />
          </a>
        </div>
      )}

      <div className={styles.buttonDiv}>
        {!shippingLabelCreateArgsFromAPI && (
          <Button
            disabled={
              !senderAddressIsValid ||
              !receiverAddressIsValid ||
              invalidCODPayment
            }
            variant="contained"
            onClick={onCreateShippingLabelClick}
          >
            {t('create_shipping_label')}
          </Button>
        )}
      </div>
    </Fragment>
  )
}

export default ShippingLabelGenerate
