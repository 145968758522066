import { confirmPopup } from 'primereact/confirmpopup'
import { useTranslation } from 'react-i18next'

interface ConfirmationPopupOptionsProps {
  message?: string
  icon?: string
  acceptClassName?: string
  acceptLabel?: string
  acceptIcon?: string
  rejectClassName?: string
  rejectLabel?: string
  rejectIcon?: string
  onAccept: () => void
  onReject?: () => void
  target: HTMLButtonElement
}

// For enhanced control and positioning of the confirmation popup, ensure to include the <ConfirmPopup />
// component directly within the JSX code of the component where you intend to use the confirmation hook.
// This inclusion allows for precise handling and positioning of the confirmation dialog
const useConfirmationPopup = () => {
  const { t } = useTranslation()

  const confirmPopupAction = (options: ConfirmationPopupOptionsProps) => {
    const {
      message = t('delete_this_permanently'),
      icon = 'pi pi-exclamation-triangle',
      acceptClassName = 'p-button-danger',
      acceptLabel = t('delete'),
      acceptIcon = 'pi pi-trash',
      rejectClassName = 'p-button-text p-button-secondary',
      rejectLabel = t('cancel'),
      rejectIcon = 'pi pi-times',
      onAccept,
      onReject,
      target,
    } = options

    confirmPopup({
      target,
      message,
      icon,
      acceptClassName,
      acceptLabel,
      acceptIcon,
      rejectClassName,
      rejectLabel,
      rejectIcon,
      accept: () => {
        onAccept && onAccept()
      },
      reject: () => {
        onReject && onReject()
      },
    })
  }

  return { confirmPopupAction }
}

export default useConfirmationPopup
