import {
  IsArray,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
} from 'class-validator'
import {
  EBillingPeriodType,
  EDealType,
  EFeeLevel,
  EFeeMode,
  EFeeType,
  FeeDefinition,
} from '@/schemaTypes'

export class FeeValidation {
  // A.B Commented
  // @IsDate()
  // validFrom: Date

  // @IsDate()
  // validTo: Date

  @IsNumber({ maxDecimalPlaces: 9 })
  amount: number

  @IsNotEmpty()
  billingPeriodType: EBillingPeriodType

  @IsNotEmpty()
  level: EFeeLevel

  @IsNotEmpty()
  mode: EFeeMode

  @IsNotEmpty()
  feeType: EFeeType

  @IsNotEmpty()
  dealTypes: EDealType[]

  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  restrictToCategories?: string[] | null

  constructor(fee: FeeDefinition) {
    // A.B Commented
    // this.validFrom = fee.validFrom
    // this.validTo = fee.validTo
    this.amount = fee.amount
    this.billingPeriodType = fee.billingPeriodType
    this.level = fee.level
    this.mode = fee.mode
    this.feeType = fee.feeType
    this.dealTypes = fee.dealTypes
    this.restrictToCategories = fee.restrictToCategories || []
  }
}
