import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateCustomDealNoteArgs } from '@/schemaTypes'

const UPDATE_CUSTOM_DEAL_NOTE = gql`
  mutation updateCustomDealNote(
    $customDealId: ObjectId!
    $noteId: ObjectId!
    $noteUpdateArgs: NoteUpdateArgs!
  ) {
    updateCustomDealNote(
      customDealId: $customDealId
      noteId: $noteId
      noteUpdateArgs: $noteUpdateArgs
    ) {
      _id
    }
  }
`

type TData = { updateNote: Mutation['updateCustomDealNote'] }
type TVariables = MutationUpdateCustomDealNoteArgs

export function useUpdateCustomDealNote(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateNote] = useMutation<TData, TVariables>(
    UPDATE_CUSTOM_DEAL_NOTE,
    opts,
  )

  return updateNote
}
