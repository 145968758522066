import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationUpdateDealUploadedFilesArgs } from '@/schemaTypes'

const UPDATE_DEAL_UPLOADED_FILES = gql`
  mutation updateDealUploadedFiles(
    $dealId: ObjectId!
    $uploadedFilesArgs: [FileTusMetadataArgs!]!
  ) {
    updateDealUploadedFiles(
      dealId: $dealId
      uploadedFilesArgs: $uploadedFilesArgs
    ) {
      _id
      uploadedFiles {
        ...FileMetadataFragment
      }
    }
  }
  ${fragments.fileMetadata}
`

type TData = { updateDealUploadedFiles: Mutation['updateDealUploadedFiles'] }
type TVariables = MutationUpdateDealUploadedFilesArgs

export function useUpdateDealUploadedFiles(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateDealUploadedFiles] = useMutation<TData, TVariables>(
    UPDATE_DEAL_UPLOADED_FILES,
    opts,
  )

  return updateDealUploadedFiles
}
