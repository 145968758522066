import { useTranslation } from 'react-i18next'
import { AvailablePropertyValues, ProductVariant } from '@/schemaTypes'

export interface SelectedProductProps {
  propertyDefinitionId?: string
  value: number | string | boolean | undefined
}

export const formatAvailableProperties = (
  availableProperties: AvailablePropertyValues[],
  language = 'de',
) => {
  return availableProperties.map((property) => {
    const title =
      property.propertyDefinition.name.allTranslations.find(
        (translation) => translation.languageCode === language,
      )?.text ?? ''

    if ('values' in property) {
      const values = property.values.map((value) => {
        if ('numberValue' in value) {
          const suffix =
            property.propertyDefinition.valueFormatting?.suffix || ''
          return {
            text: `${value.numberValue}${suffix}`,
            value: value.numberValue,
          }
        }

        if ('translation' in value) {
          return {
            text:
              value.translation.allTranslations.find(
                (translation) => translation.languageCode === language,
              )?.text ?? '',
            value:
              value.translation.allTranslations.find(
                (translation) => translation.languageCode === language,
              )?.text ?? '',
          }
        }
      })
      const propertyDefinitionId = property.propertyDefinitionId
      return { title, values, propertyDefinitionId }
    } else {
      const propertyDefinitionId = property.propertyDefinitionId
      return {
        title: title,
        values: [
          { text: 'Ja', value: true },
          { text: 'Nein', value: false },
        ],
        propertyDefinitionId,
      }
    }
  })
}

export const findDefaultProperties = (
  variants: ProductVariant[] | undefined,
  language = 'de',
) => {
  if (!variants || variants.length === 0) {
    return undefined
  }

  const defaultVariant = variants[0]

  return defaultVariant?.propertiesValues.map((pv) => {
    let value

    if ('numberValue' in pv.value) {
      value = pv.value.numberValue
    } else if ('translation' in pv.value) {
      value = pv.value.translation.allTranslations.find(
        (translation) => translation.languageCode === language,
      )?.text
    } else if ('booleanValue' in pv.value) {
      value = pv.value.booleanValue
    }

    return {
      propertyDefinitionId: pv.propertyDefinitionId,
      value,
    }
  })
}

export const findVariantBySelectedProperties = (
  selectedProperties: SelectedProductProps[],
  variants: ProductVariant[],
) => {
  return variants?.find((v) =>
    selectedProperties.every((sp) =>
      v.propertiesValues.some((pv) => {
        let value
        if (pv.propertyDefinitionId === sp.propertyDefinitionId) {
          if ('numberValue' in pv.value) {
            value = pv.value.numberValue === sp.value
          } else if ('translation' in pv.value) {
            value = pv.value.translation.allTranslations.some(
              (translation) => translation.text === sp.value,
            )
          } else {
            value = pv.value.booleanValue === sp.value
          }
        }
        return value
      }),
    ),
  )
}

export const useFormatVariantProperties = (variant?: ProductVariant) => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  return variant?.propertiesValues?.map((property) => {
    const title =
      property.propertyDefinition.name.allTranslations.find(
        (translation) => translation.languageCode === language,
      )?.text ?? ''

    let value: string
    if ('value' in property) {
      if ('translation' in property.value) {
        value =
          property.value.translation.allTranslations.find(
            (translation) => translation.languageCode === language,
          )?.text ?? ''
      } else if ('numberValue' in property.value) {
        value = `${property.value.numberValue}${property.propertyDefinition.valueFormatting?.suffix || ''}`
      } else if ('booleanValue' in property.value) {
        value = property.value.booleanValue ? t('yes') : t('no')
      }
    }

    return {
      id: property.propertyDefinitionId,
      title,
      value,
    }
  })
}
