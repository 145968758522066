import {
  Company,
  CompanyConfiguration,
  CompanySalesConfiguration,
} from '@/schemaTypes'

export const defaultCompanyConfiguration: CompanyConfiguration = {
  minimumPawnDuration: 30,
  insuranceFactor: 1.5,
  gracePeriod: 28,
  liquidationFee: 0.18,
  valueAddedTaxRate: 0.2,
  differenceTaxRate: 0.2,
  valorizationFeeRate: 0.18,
}

export const defaultSalesConfiguration: CompanySalesConfiguration = {
  refurbedCommission: 0.1,
  refurbedStaggeredMargins: [
    {
      from: 0,
      expectedMargin: 1,
      minimumMargin: 0.2,
    },
    {
      from: 300,
      expectedMargin: 1,
      minimumMargin: 0.15,
    },
    {
      from: 1000,
      expectedMargin: 1,
      minimumMargin: 0.08,
    },
  ],
}

export const defaultCompany: Company = {
  _id: '',
  name: '',
  uid: '',
  fibuNumber: '',
  iban: '',
  bic: '',
  accountOwner: '',
  paypalEmail: '',
  companyCounterId: null,
  companyCounter: null,
  configuration: defaultCompanyConfiguration,
  primaryShopLocationId: null,
  salesConfiguration: defaultSalesConfiguration,
}
