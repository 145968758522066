import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryGetActiveEmployeesArgs } from '@/schemaTypes'

const GET_EMPLOYEES = gql`
  query getActiveEmployees($opts: EmployeeOpts) {
    getActiveEmployees(opts: $opts) {
      _id
      firstname
      lastname
    }
  }
`

type TData = { getActiveEmployees: Query['getActiveEmployees'] }
type TVariables = QueryGetActiveEmployeesArgs

export function useGetActiveEmployees(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_EMPLOYEES, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, employees: queryResult.data?.getActiveEmployees || [] }
}
