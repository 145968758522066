import styled from '@emotion/styled'
import { Button, MenuItem, Select, TextField } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import RemoveIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/EditOutlined'
import RestoreIcon from '@material-ui/icons/Replay'
import SaveIcon from '@material-ui/icons/Save'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { isEqual, sum } from 'lodash'
import { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AmountInput from '@/components/AmountInput'
import SelectOptionInput from '@/components/SelectOptionInput'
import {
  Deal,
  DealCalculation,
  EDealType,
  EFeeLevel,
  EFeeMode,
  EFeeType,
  ManualFeeDefinitionArgs,
  Scalars,
} from '@/schemaTypes'
import { getManualFeeDefinitionsFromCalculation } from '@/utils/deal'
import { displayLocalAmount } from '@/utils/misc'
import { FEES_WITH_UST, companyManualFees } from '@/utils/pawnCalculation'

const manualFeesMenueItems = (
  feeLevel: EFeeLevel,
  dealType: EDealType,
  companyId: Scalars['ObjectId'],
) =>
  companyManualFees(dealType)
    .find((c) => isEqual(c.companyId, companyId))
    ?.manualFees.filter((c) => c.level === feeLevel)
    .map((fee) => ({
      label: fee.feeType,
      value: fee.feeType,
    }))

const feeModeMenueItems = [
  { label: '€', value: EFeeMode.Constant },
  { label: '%', value: EFeeMode.MultiplierPayout },
]

interface FeeDefinitionProps {
  deal: Deal
  initialCalculation: DealCalculation
  isDealLevel: boolean
  dealCalculation: DealCalculation
  levelFeeDefinitionArgs: ManualFeeDefinitionArgs[]
  allFeeDefinitionArgs: ManualFeeDefinitionArgs[]
  inEditFeeIndex: number
  removedAutomaticFeeIndexes: number[]
  setFieldValue: (field: string, value: unknown) => void
  onFormikSubmit: () => void
  isReadonly: boolean
  showUst: boolean
  setShowUst: (value: boolean) => void
  storageLabel?: string
}

const FeeDefinition: FunctionComponent<FeeDefinitionProps> = (props) => {
  const {
    deal,
    initialCalculation,
    isDealLevel,
    dealCalculation,
    levelFeeDefinitionArgs,
    allFeeDefinitionArgs,
    inEditFeeIndex,
    removedAutomaticFeeIndexes,
    setFieldValue,
    onFormikSubmit,
    isReadonly,
    showUst,
    setShowUst,
    storageLabel,
  } = props
  const { t } = useTranslation()

  setShowUst(
    allFeeDefinitionArgs.some((elem) => FEES_WITH_UST.includes(elem.feeType)),
  )

  const originalFeeDefinitionArgs = useMemo(() => {
    return getManualFeeDefinitionsFromCalculation(
      initialCalculation,
      deal.dealType,
    )
  }, [initialCalculation, deal])

  const removeButtonClicked = (index: number) => {
    if (!allFeeDefinitionArgs[index].isManual) {
      allFeeDefinitionArgs[index].amount = 0
      setFieldValue('removedAutomaticFeeIndexes', [
        ...removedAutomaticFeeIndexes,
        index,
      ])
    } else {
      allFeeDefinitionArgs.splice(index, 1)
    }
    setFieldValue('manualFeeDefinitionsArgs', allFeeDefinitionArgs)
    onFormikSubmit()
  }

  const editButtonClicked = (index: number) => {
    setFieldValue('inEditFeeIndex', index)
  }

  const saveButtonClicked = () => {
    setFieldValue('inEditFeeIndex', -1)
    onFormikSubmit()
  }

  const discardButtonClicked = (index: number) => {
    if (allFeeDefinitionArgs[index].isManual) {
      allFeeDefinitionArgs.splice(index, 1)
    } else {
      allFeeDefinitionArgs[index] = { ...originalFeeDefinitionArgs[index] }
      setFieldValue('manualFeeDefinitionsArgs', allFeeDefinitionArgs)
    }
    setFieldValue('inEditFeeIndex', -1)
    setFieldValue('manualFeeDefinitionsArgs', allFeeDefinitionArgs)
    onFormikSubmit()
  }

  const restoreButtonClicked = (index: number) => {
    setFieldValue(
      'removedAutomaticFeeIndexes',
      removedAutomaticFeeIndexes.filter((c) => c !== index),
    )
    discardButtonClicked(index)
  }

  const onAddButtonClicked = (storageLabel?: string) => {
    const defaultManualFee = isDealLevel
      ? companyManualFees(deal.dealType)
          .find((c) => isEqual(c.companyId, deal.companyId))
          .manualFees.find((c) => c.feeType === EFeeType.Discount)
      : companyManualFees(deal.dealType)
          .find((c) => isEqual(c.companyId, deal.companyId))
          .manualFees.find((c) => c.feeType === EFeeType.Storage)

    const newManualFeeDefinitionArgs: ManualFeeDefinitionArgs = {
      ...defaultManualFee,
      dealType: deal.dealType,
      storageLabel: storageLabel,
    }

    setFieldValue('inEditFeeIndex', allFeeDefinitionArgs.length)
    setFieldValue('manualFeeDefinitionsArgs', [
      ...allFeeDefinitionArgs,
      newManualFeeDefinitionArgs,
    ])
  }

  const onFeeTypeChange = (index: number, value: EFeeType) => {
    const changedManualFee = companyManualFees(deal.dealType)
      .find((c) => isEqual(c.companyId, deal.companyId))
      .manualFees.find((c) => c.feeType === value)
    allFeeDefinitionArgs[index] = {
      ...changedManualFee,
      dealType: deal.dealType,
      storageLabel: allFeeDefinitionArgs[index].storageLabel,
      amount: 0,
    }
    setFieldValue('manualFeeDefinitionsArgs', allFeeDefinitionArgs)
  }

  return (
    <>
      {!isDealLevel && (
        <TableRow>
          <TableCell
            colSpan={8}
            style={{
              backgroundColor: '#DCD0F7',
              fontWeight: 'bold',
            }}
          >
            {`${storageLabel} - ${deal.items.find((c) => c.storageLabel === storageLabel)?.title}`}
          </TableCell>
        </TableRow>
      )}

      {levelFeeDefinitionArgs.map((feeDefArgs) => {
        const generalFeeIndex = allFeeDefinitionArgs.findIndex(
          (c) => c === feeDefArgs,
        )
        const finalGrossAmount = isDealLevel
          ? sum(
              dealCalculation.appliedUsedFeeDefinitions.map(
                (c) =>
                  c.calculatedFeeDefinitions.find(
                    (c) =>
                      c.storedFeeDefinition.feeType === feeDefArgs.feeType &&
                      // DiscountFirstMonth is the only fee which the amount is 0 and will be calculated in api
                      (c.storedFeeDefinition.feeType !==
                      EFeeType.DiscountFirstMonth
                        ? c.storedFeeDefinition.amount === feeDefArgs.amount
                        : true),
                  )?.appliedGrossAmount,
              ),
            )
          : dealCalculation.appliedUsedFeeDefinitions
              .find((c) => c.storageLabel === feeDefArgs.storageLabel)
              ?.calculatedFeeDefinitions.find(
                (c) =>
                  c.storedFeeDefinition.feeType === feeDefArgs.feeType &&
                  c.storedFeeDefinition.amount === feeDefArgs.amount,
              )?.appliedGrossAmount
        const finalNetAmount = isDealLevel
          ? sum(
              dealCalculation.appliedUsedFeeDefinitions.map(
                (c) =>
                  c.calculatedFeeDefinitions.find(
                    (c) =>
                      c.storedFeeDefinition.feeType === feeDefArgs.feeType &&
                      // DiscountFirstMonth is the only fee which the amount is 0 and will be calculated in api
                      (c.storedFeeDefinition.feeType !==
                      EFeeType.DiscountFirstMonth
                        ? c.storedFeeDefinition.amount === feeDefArgs.amount
                        : true),
                  )?.appliedNetAmount,
              ),
            )
          : dealCalculation.appliedUsedFeeDefinitions
              .find((c) => c.storageLabel === feeDefArgs.storageLabel)
              ?.calculatedFeeDefinitions.find(
                (c) =>
                  c.storedFeeDefinition.feeType === feeDefArgs.feeType &&
                  Math.abs(c.storedFeeDefinition.amount) ===
                    Math.abs(feeDefArgs.amount),
              )?.finalNetAmount
        const disabled = generalFeeIndex !== inEditFeeIndex
        const standardFeeIsDisabled =
          removedAutomaticFeeIndexes.includes(generalFeeIndex) ||
          (!feeDefArgs.isManual && finalNetAmount === 0)
        const notRestorableDisabledFee =
          standardFeeIsDisabled &&
          originalFeeDefinitionArgs[generalFeeIndex].amount === 0

        return (
          <TableRow key={generalFeeIndex}>
            <TableCell
              sx={{
                paddingLeft: 0,
              }}
            >
              <Select
                style={{
                  textDecoration: standardFeeIsDisabled
                    ? 'line-through'
                    : 'inherit',
                }}
                value={feeDefArgs.feeType}
                onChange={(value) =>
                  onFeeTypeChange(
                    generalFeeIndex,
                    value.target.value as EFeeType,
                  )
                }
                disabled={!feeDefArgs.isManual || disabled}
              >
                {manualFeesMenueItems(
                  isDealLevel ? EFeeLevel.Deal : EFeeLevel.Item,
                  deal.dealType,
                  deal.companyId,
                ).map((e) => (
                  <MenuItem
                    key={e.value}
                    value={e.value}
                    disabled={
                      isDealLevel &&
                      levelFeeDefinitionArgs
                        .map((c) => c.feeType)
                        .includes(e.value)
                    }
                  >
                    {t(
                      `fee_type.FEE_TYPE_${e.label}${e.value === EFeeType.InitialStaggered ? '_' + deal.company.headquarter?.countryCode : ''}`.toLowerCase(),
                    )}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
            <TableCell>
              <AmountInput
                isStriked={standardFeeIsDisabled}
                disabled={
                  disabled || feeDefArgs.feeType === EFeeType.DiscountFirstMonth
                }
                inputWidth="90px"
                value={feeDefArgs.amount}
                type={'float'}
                onChange={(value) => {
                  allFeeDefinitionArgs[generalFeeIndex].amount = value
                  setFieldValue(
                    'manualFeeDefinitionsArgs',
                    allFeeDefinitionArgs,
                  )
                }}
              />
            </TableCell>
            <TableCell>
              <SelectOptionInput
                isStriked={standardFeeIsDisabled}
                value={feeDefArgs.mode}
                onChange={(value) => {
                  allFeeDefinitionArgs[generalFeeIndex].mode = value
                  setFieldValue(
                    'manualFeeDefinitionsArgs',
                    allFeeDefinitionArgs,
                  )
                }}
                menuItems={feeModeMenueItems}
                disabled={
                  disabled || feeDefArgs.feeType === EFeeType.DiscountFirstMonth
                }
              />
            </TableCell>
            <TableCell
              style={{
                textDecoration: standardFeeIsDisabled
                  ? 'line-through'
                  : 'inherit',
              }}
            >
              {displayLocalAmount(finalGrossAmount)}
            </TableCell>
            {showUst && (
              <>
                <TableCell>
                  <AmountInput
                    isStriked={standardFeeIsDisabled}
                    disabled={
                      disabled || !FEES_WITH_UST.includes(feeDefArgs.feeType)
                    }
                    inputWidth="75px"
                    value={feeDefArgs.ust}
                    type={'float'}
                    onChange={(value) => {
                      allFeeDefinitionArgs[generalFeeIndex].ust = value
                      setFieldValue(
                        'manualFeeDefinitionsArgs',
                        allFeeDefinitionArgs,
                      )
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    textDecoration: standardFeeIsDisabled
                      ? 'line-through'
                      : 'inherit',
                  }}
                >
                  {displayLocalAmount(finalNetAmount)}
                </TableCell>
              </>
            )}
            <TableCell>
              <TextField
                style={{
                  textDecoration: standardFeeIsDisabled
                    ? 'line-through'
                    : 'inherit',
                }}
                disabled={generalFeeIndex !== inEditFeeIndex}
                size="small"
                value={feeDefArgs.description}
                onChange={(value) => {
                  allFeeDefinitionArgs[generalFeeIndex].description =
                    value.target.value
                  setFieldValue(
                    'manualFeeDefinitionsArgs',
                    allFeeDefinitionArgs,
                  )
                }}
              />
            </TableCell>
            {!isReadonly && (
              <TableCell>
                {standardFeeIsDisabled ? (
                  <>
                    <span
                      style={{
                        color: notRestorableDisabledFee ? 'silver' : 'red',
                        textDecoration: 'unset',
                      }}
                    >
                      {'Fee Disabled'}
                    </span>

                    <StyledButton
                      onClick={() => restoreButtonClicked(generalFeeIndex)}
                      iconColor={notRestorableDisabledFee ? 'silver' : 'red'}
                      disabled={notRestorableDisabledFee}
                    >
                      <RestoreIcon />
                    </StyledButton>
                  </>
                ) : inEditFeeIndex >= 0 &&
                  inEditFeeIndex === generalFeeIndex ? (
                  <>
                    <StyledButton
                      onClick={() => saveButtonClicked()}
                      iconColor={'#4d2c86c0'}
                    >
                      <SaveIcon />
                    </StyledButton>
                    <StyledButton
                      onClick={() => discardButtonClicked(generalFeeIndex)}
                      iconColor={'red'}
                    >
                      <ClearIcon />
                    </StyledButton>
                  </>
                ) : (
                  <>
                    <StyledButton
                      onClick={() => editButtonClicked(generalFeeIndex)}
                      iconColor={'black'}
                      disabled={inEditFeeIndex >= 0}
                    >
                      <EditIcon />
                    </StyledButton>
                    <StyledButton
                      onClick={() => removeButtonClicked(generalFeeIndex)}
                      iconColor={'red'}
                      disabled={inEditFeeIndex >= 0}
                    >
                      <RemoveIcon />
                    </StyledButton>
                  </>
                )}
              </TableCell>
            )}
          </TableRow>
        )
      })}

      {!isReadonly && (
        <TableRow>
          <TableCell colSpan={8} style={{ borderTop: '1px solid grey' }}>
            <StyledButton
              onClick={() =>
                onAddButtonClicked(isDealLevel ? undefined : storageLabel)
              }
              iconColor={'#02be1f'}
              disabled={inEditFeeIndex >= 0}
            >
              <AddIcon />
            </StyledButton>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default FeeDefinition

interface StyledBottonProps {
  disabled?: boolean
  iconColor?: string
}
const StyledButton = styled(Button)`
  color: ${(props: StyledBottonProps) =>
    props.disabled ? 'silver' : props.iconColor};
`
