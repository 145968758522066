import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import styles from '@/domains/itemQuestions/components/ItemQuestionsConfiguration.module.scss'
import ItemQuestionsRow from '@/domains/itemQuestions/components/ItemQuestionsRow'
import {
  useDeleteItemQuestion,
  useGetItemQuestions,
} from '@/domains/itemQuestions/hooks'
import { useConfirmDeleteMutationShowingErrors } from '@/hooks'

export function ItemQuestionsTable() {
  const { t } = useTranslation()

  const { itemQuestions, queryResult } = useGetItemQuestions()

  const deleteItemQuestion = useConfirmDeleteMutationShowingErrors({
    successMessage: t('item_question_deleted'),
    mutation: useDeleteItemQuestion(),
  })

  const _handleDeleteClick = async (id: string) => {
    deleteItemQuestion({
      variables: {
        itemQuestionId: id,
      },
      onCompleted: () => {
        queryResult.refetch()
      },
    })
  }

  return (
    <Paper className={`${styles.root} u-mb`}>
      <ConfigurationBreadcrumbs modelName={'itemQuestions'} />
      <Typography variant="h5" gutterBottom>
        {t('item_questions_configurations')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Table className="u-mb-sm" size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('id')}</TableCell>
                <TableCell align="right">{t('order')}</TableCell>
                <TableCell align="left">{t('title_key')}</TableCell>
                <TableCell align="right">{t('question_type')}</TableCell>
                <TableCell align="center">{t('value_mode')}</TableCell>
                <TableCell align="center">{t('prediction_tag')}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(itemQuestions).map((iqKey) => (
                <ItemQuestionsRow
                  key={iqKey}
                  itemQuestion={itemQuestions[iqKey]}
                  deleteItemQuestion={() =>
                    _handleDeleteClick(itemQuestions[iqKey]._id)
                  }
                />
              ))}
            </TableBody>
          </Table>
          <Link to="/inApp/configurations/itemQuestions/create">
            <Button className="u-mr-mini" variant="contained">
              {t('item_question_create')}
            </Button>
          </Link>
        </Fragment>
      )}
    </Paper>
  )
}

export default ItemQuestionsTable
