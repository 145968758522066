import { Checkbox } from 'primereact/checkbox'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

interface TermsAndConditionsProps {
  withdrawalFeeTermsAccepted: boolean
  setWithdrawalFeeTermsAccepted: (value: boolean) => void
}

export const TermsAndConditions: FunctionComponent<TermsAndConditionsProps> = (
  props,
) => {
  const { withdrawalFeeTermsAccepted, setWithdrawalFeeTermsAccepted } = props
  const { t } = useTranslation()

  return (
    <div className="flex flex-row max-w-md align-text-top">
      <Checkbox
        className="mr-5"
        onChange={(e) => setWithdrawalFeeTermsAccepted(e.checked)}
        checked={withdrawalFeeTermsAccepted}
        value={withdrawalFeeTermsAccepted}
      />
      <label>{t('terms_and_conditions.withdrawal_fee')}</label>
    </div>
  )
}
