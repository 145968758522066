import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Modal from '@/components/Modal'

interface WarningStolenItemsPopupProps {
  open: boolean
  onClose: () => void
}

const WarningStolenItemsPopup = ({
  open,
  onClose,
}: WarningStolenItemsPopupProps) => {
  const { t } = useTranslation()
  return (
    <Modal open={open} onClose={onClose} fullWidth maxWidth="md">
      <Typography>{t('warning_stolen_item_text')}</Typography>
    </Modal>
  )
}

export default WarningStolenItemsPopup
