import { useShowErrorsPopup } from '../../../hooks/useShowErrorsPopup'
import { dealCalculationFragments } from '../../deals/hooks'
import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Query, QueryCustomDealCalculationArgs } from '@/schemaTypes'

const CUSTOM_DEAL_PAWN_PURCHASE_CALCULATIONS = gql`
  query customDealPawnPurchaseCalculations(
    $customDealCalculationArgs: CustomDealCalculationArgs!
  ) {
    customDealPawnPurchaseCalculations(
      customDealCalculationArgs: $customDealCalculationArgs
    ) {
      pawnCalculation {
        ...CustomDealCalculationFragment
      }
      purchaseCalculation {
        ...CustomDealCalculationFragment
      }
    }
  }
  ${dealCalculationFragments.customDealCalculation}
`

export type TData = {
  customDealPawnPurchaseCalculations: Query['customDealPawnPurchaseCalculations']
}
type TVariables = QueryCustomDealCalculationArgs

export function useCustomDealPawnPurchaseCalculations(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    CUSTOM_DEAL_PAWN_PURCHASE_CALCULATIONS,
    {
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
