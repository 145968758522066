import { sumTotalRequestedPayoutAmount } from '../../helpers'
import {
  Button,
  Dialog,
  DialogContent,
  Paper,
  Typography,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpansionBox from '@/components/ExpansionBox/ExpansionBox'
import ItemValuesTable from '@/components/ItemValuesTable'
import Loading from '@/components/Loading'
import LoadingBackground from '@/components/LoadingBackground'
import { CustomDeal, EDealType, ItemValuesEntry } from '@/schemaTypes'
import { getTotalGrossFeeAmountOfType } from '@/utils/deal'
import { useNestedState } from '@/utils/misc'
import CustomDealUsedFeeDefinitionViewer from './CustomDealUsedFeeDefinitionViewer'

interface Props {
  customDeal: CustomDeal
  loading: boolean
  onOverwriteCustomDealPayout: () => void
}

interface expansionBoxFilterInput {
  expandedItems?: boolean
}

export function CustomDealPayoutOverview(props: Props) {
  const { customDeal, loading, onOverwriteCustomDealPayout } = props
  const { t } = useTranslation()
  const customDealCalculation =
    customDeal?.dealType === EDealType.Pawn
      ? customDeal?.pawnCalculation
      : customDeal?.purchaseCalculation
  const payoutDifference = getReuqestedPayoutDifference(customDeal)
  const finalPayoutCanBeOverwritten =
    payoutDifference > 0 &&
    payoutDifference <=
      (customDealCalculation?.dealValuesEntry.payoutAmount * 3) / 100

  const [showFeeDialog, setShowFeeDialog] = useState(false)
  const itemValuesRows = [
    {
      label: t('payout.payout_amount'),
      values: {
        verified: customDealCalculation?.dealValuesEntry.payoutAmount,
      },
    },
    {
      label: (
        <div style={{ display: 'flex', justifyContent: 'start' }}>
          <Typography style={{ marginTop: 10, marginRight: 5 }}>
            {t('fees.label')}
          </Typography>
          <div>
            <HelpIcon
              style={{ cursor: 'pointer', marginTop: 10 }}
              onClick={() => setShowFeeDialog(true)}
            />
          </div>
        </div>
      ),
      values: {
        verified: getTotalGrossFeeAmountOfType(
          customDealCalculation?.appliedUsedFeeDefinitions ?? [],
        ),
      },
    },
    {
      label: t('payout.payback_amount'),
      values: {
        verified: customDealCalculation?.dealValuesEntry?.paybackAmount,
      },
    },
  ]

  const expansionBoxFilter = useNestedState<expansionBoxFilterInput>(
    {},
    {
      persistent: 'expansionBoxFilter',
    },
  )

  const handleExpansionBoxes = (value, name) => {
    if (name === 'expandedItems') {
      expansionBoxFilter.setProperty('expandedItems', value)
    }

    expansionBoxFilter.persist()
  }

  return (
    <div>
      <div className="u-relative">
        {loading && <LoadingBackground />}
        {loading && (
          <div>
            <Loading position="absolute" />
          </div>
        )}

        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: 1,
              maxWidth: finalPayoutCanBeOverwritten ? '25%' : '100%',
            }}
          >
            <Paper className="u-p-sm u-12/12 u-mb-sm">
              <ItemValuesTable
                rows={itemValuesRows}
                columns={itemValuesColumns}
              />
            </Paper>
          </div>
          {finalPayoutCanBeOverwritten && (
            <div style={{ marginLeft: '1rem', width: '80%', height: '200px' }}>
              <Paper
                className="u-p-sm u-12/12 u-mb-sm "
                style={{ height: '100%' }}
              >
                <div>
                  {t(
                    'custom_deal_final_payout_amount_overwrite_description_1',
                    { differencePayout: payoutDifference },
                  )}
                </div>
                <div style={{ marginTop: '0.5rem', marginBottom: '2rem' }}>
                  {t('custom_deal_final_payout_amount_overwrite_description_2')}
                </div>
                <Button
                  variant="contained"
                  onClick={onOverwriteCustomDealPayout}
                >
                  {t('increase_payout_amount_to', {
                    amount: sumTotalRequestedPayoutAmount(
                      customDeal.items ?? [],
                      customDeal.dealType,
                    ),
                  })}
                </Button>
                {loading && (
                  <div>
                    <Loading position="absolute" />
                  </div>
                )}
              </Paper>
            </div>
          )}
        </div>

        <ExpansionBox
          title={t('item.plural')}
          name={'expandedItems'}
          openAtLoad={expansionBoxFilter.values.expandedItems ?? false}
          onClickBox={(value, name) => handleExpansionBoxes(value, name)}
        >
          {customDealCalculation?.itemsValuesEntries?.map(
            (itemsValuesEntry, i) => (
              <Paper className="u-p-sm u-12/12 u-mb-sm" key={i}>
                <Typography variant="body1" gutterBottom>
                  {customDeal.items[i].title}
                </Typography>
                <ItemValuesTable
                  rows={getItemValueEntryValueTable(itemsValuesEntry)}
                  columns={itemValuesColumns}
                />
              </Paper>
            ),
          )}
        </ExpansionBox>
      </div>

      <Dialog
        onEscapeKeyDown={() => setShowFeeDialog(false)}
        onBackdropClick={() => setShowFeeDialog(false)}
        maxWidth={'xl'}
        open={showFeeDialog}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>
          {customDealCalculation && (
            <CustomDealUsedFeeDefinitionViewer
              customDeal={customDeal}
              customDealCalculation={customDealCalculation}
              itemRecordsButtonDefaultValue={true}
              showLayoutButton={true}
              defaultLayout={'module'}
              showItemDetailsButton={true}
              showChargTableText={true}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

const itemValuesColumns = {
  verified: { displayed: true, showLoadingAnimationForNullValues: true },
}

const getItemValueEntryValueTable = (itemValuesEntry: ItemValuesEntry) => {
  return [
    {
      label: 'Current Market Value',
      values: {
        verified: itemValuesEntry.currentMarketValue ?? '-',
      },
    },
    {
      label: 'Loan To Value',
      values: {
        verified: itemValuesEntry.loanToValue ?? '-',
      },
    },
    {
      label: 'Payout Amount',
      values: {
        verified: itemValuesEntry.payoutAmount ?? '-',
      },
    },
  ]
}

const getReuqestedPayoutDifference = (customDeal: Partial<CustomDeal>) => {
  const totalRequestedPayoutAmount = sumTotalRequestedPayoutAmount(
    customDeal.items ?? [],
    customDeal.dealType,
  )
  if (customDeal.dealType === EDealType.Pawn)
    return (
      totalRequestedPayoutAmount -
      (customDeal.pawnCalculation?.dealValuesEntry.payoutAmount ??
        totalRequestedPayoutAmount)
    )
  else
    return (
      totalRequestedPayoutAmount -
      (customDeal.purchaseCalculation?.dealValuesEntry.payoutAmount ??
        totalRequestedPayoutAmount)
    )
}
