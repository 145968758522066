import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import getPagePath from '@/utils/getPagePath'
import CustomerBlacklistListPage from './CustomerBlacklist'
import CustomerBlacklistCreatePage from './create'
import CustomerBlacklistEditPage from './edit/[customerBlacklistId]'

export function CustomerBlacklistRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/create')}
        exact
        render={() => <CustomerBlacklistCreatePage />}
      />
      <SentryRoute
        exact
        path={getPagePath(props, '/edit/:customerBlacklistId')}
        render={(props) => (
          <CustomerBlacklistEditPage
            {...props}
            customerBlacklistId={
              props.match.params.customerBlacklistId as string
            }
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props)}
        exact
        render={() => <CustomerBlacklistListPage />}
      />
    </Switch>
  )
}

export default CustomerBlacklistRoutes
