import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useContext } from 'react'
import { Context } from '@/context'
import { fragments } from '@/hooks/fragments'
import {
  ELanguageCode,
  Mutation,
  MutationUpdateAuctionArgs,
} from '@/schemaTypes'
import { auctionFragments } from './auctionFragments'

const UPDATE_AUCTION = gql`
  mutation updateAuction(
    $args: AuctionUpdateArgs!
    $shouldNotifyCustomers: Boolean!
    $languageCode: ELanguageCode!
  ) {
    updateAuction(args: $args, shouldNotifyCustomers: $shouldNotifyCustomers) {
      ...AuctionDetailsFragment
    }
  }
  ${fragments.customDealFormQuestion}
  ${fragments.formAnswers}
  ${auctionFragments.auctionDetails}
  ${fragments.address}
`

type TData = { updateAuction: Mutation['updateAuction'] }
type TVariables = { languageCode?: ELanguageCode } & MutationUpdateAuctionArgs

export function useUpdateAuction(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const { language } = useContext(Context)

  const [updateAuction] = useMutation<TData, TVariables>(UPDATE_AUCTION, {
    ...opts,
    variables: { ...(opts.variables as TVariables), languageCode: language },
  })

  return updateAuction
}
