import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { companyFragments } from '@/domains/companies/hooks/companyFragments'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { Query, QueryGetDealByIdArgs } from '@/schemaTypes'
import { dealCalculationFragments, dealFragments } from './dealFragments'

const GET_DEAL_BY_ID = gql`
  query getDealById($dealId: ObjectId!) {
    getDealById(dealId: $dealId) {
      _id
      dealStartDate
      createdAt
      updatedAt
      dealType
      companyId
      businessUnit
      bookingNumber
      dealFinalValues {
        ...DealFinalValuesFragment
      }
      company {
        _id
        carPawnSettings {
          includeLicensePlate
          includeSpareTireCount
        }
        salesConfiguration {
          ...CompanySalesConfigurationFragment
        }
      }
      customer {
        ... on Customer {
          _id
          firstname
          lastname
          customerNumber
          dateOfBirth
          email
          phone
          sex
          acquiredBy
          note
          isNotePopup
        }
        ...GuestCustomerDataFragment
      }
      company {
        _id
        name
        iban
        paypalEmail
      }
      pawnData {
        ...PawnDataFragment
      }
      purchaseData {
        ...PurchaseDataFragment
      }
      events {
        ...DealEventFragment
      }
      payoutData {
        ...PaymentDataFragment
      }
      initialShopId
      pickupData {
        ...TransportDataFragment
      }
      dropoffData {
        ...TransportDataFragment
      }
      items {
        ...DealItemFragment
      }
      requestedItems {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
    }
  }
  ${dealFragments.dealItem}
  ${dealFragments.event}
  ${dealFragments.purchaseData}
  ${dealFragments.pawnData}
  ${dealFragments.transportData}
  ${dealCalculationFragments.dealValorizationCalculation}
  ${fragments.paymentData}
  ${dealFragments.dealFinalValues}
  ${companyFragments.salesConfiguration}
  ${fragments.guestCustomerData}
`

type TData = { getDealById: Query['getDealById'] }
type TVariables = QueryGetDealByIdArgs

export function useGetDealById(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useQuery<TData, TVariables>(GET_DEAL_BY_ID, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, deal: queryResult.data?.getDealById }
}
