import { useAddDealItem, useExchangeDealItem } from '../../../hooks'
import { Button } from '@material-ui/core'
import { Paper, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { ItemDraftQuestionsFormData } from '@/domains/customDeals/components/CustomDealDetail/CustomDealItemSelect'
import { useModifyDealItemsWithWarning } from '@/domains/deals/hooks'
import { useCalculateDealItem } from '@/domains/deals/hooks/calculateDealItem'
import { useGetProductLegacy, useMutationShowingErrors } from '@/hooks'
import { Deal, DealItem, ItemAnswerArgs } from '@/schemaTypes'
import { isDealClosed } from '@/utils/deal'
import ItemCalculation from './ItemCalculation'
import ItemCategoryQuestions from './ItemCategoryQuestions'
import {
  buildItemCreateArgs,
  getProductVariantMaterial,
} from './buildItemCreateArgs'
import useProductVariantForItemEvaluation from './useProductVariantForItemEvaluation'

type AddOrChangeItemProps = {
  deal: Deal
  item?: DealItem
  onSaveCustomItem?: (formData: ItemDraftQuestionsFormData) => void
} & (
  | {
      productObjectID: string
    }
  | { productVariantId: string }
)

const AddOrChangeItem: FunctionComponent<AddOrChangeItemProps> = (props) => {
  const { deal, item } = props
  const [itemAnswerArgs, setItemAnswerArgs] = useState<ItemAnswerArgs[]>()
  const { t } = useTranslation()
  const history = useHistory()

  const isLegacyProduct = 'productObjectID' in props && !!props.productObjectID
  const { productLegacy } = useGetProductLegacy({
    variables: isLegacyProduct
      ? {
          opts: {
            filter: {
              objectID: props.productObjectID,
            },
          },
        }
      : undefined,
    skip: !isLegacyProduct || !props.productObjectID,
  })

  const isNewProduct = 'productVariantId' in props && !!props.productVariantId
  const { productVariant } = useProductVariantForItemEvaluation({
    variables: isNewProduct
      ? {
          args: {
            productVariantId: props.productVariantId,
          },
        }
      : undefined,
    skip: !isNewProduct,
  })

  const modifyDealItemsWithWarning = useModifyDealItemsWithWarning(deal)
  const { calculateDealItem, queryResult: calculationQueryResult } =
    useCalculateDealItem()
  const [addDealItemMutation] = useAddDealItem()
  const [exchangeDealItemMutation] = useExchangeDealItem()

  const exchangeDealItem = useMutationShowingErrors({
    mutation: exchangeDealItemMutation,
  })
  const addDealItem = useMutationShowingErrors({
    mutation: addDealItemMutation,
  })

  const [showCalculateButton, setShowCalculateButton] = useState(false)
  const showSaveWithoutCalculationButton = isDealClosed(deal)

  useEffect(() => {
    setShowCalculateButton(true)
  }, [itemAnswerArgs])

  const onCalculateItem = () => {
    if (itemAnswerArgs) {
      calculateDealItem({
        variables: {
          companyId: deal.companyId,
          durationInDays: deal.dealFinalValues.durationInDays,
          algoliaReference: isLegacyProduct ? props.productObjectID : undefined,
          variantId: isNewProduct ? props.productVariantId : undefined,
          answers: itemAnswerArgs,
          itemCategoryId: isLegacyProduct
            ? productLegacy?.itemCategoryId
            : productVariant?.product.categoryId,
          date: dayjs().toDate(),
          material: isLegacyProduct
            ? productLegacy?.material
            : getProductVariantMaterial(productVariant),
        },
      })
    }

    setShowCalculateButton(false)
  }

  const onSaveButtonClick = async () => {
    const itemCreateArgs = buildItemCreateArgs({
      itemAnswerArgs,
      legacyProduct: isLegacyProduct,
      productLegacy,
      productObjectID: isLegacyProduct ? props.productObjectID : undefined,
      newProduct: isNewProduct,
      productVariant,
      productVariantId: isNewProduct ? props.productVariantId : undefined,
    })

    if (item && itemAnswerArgs) {
      modifyDealItemsWithWarning(async () => {
        await exchangeDealItem({
          variables: {
            dealId: deal._id,
            oldItemId: item?._id,
            newItemArgs: itemCreateArgs,
          },
        })

        history.push(`/inApp/deals/${deal._id}`)
      })
    } else {
      modifyDealItemsWithWarning(async () => {
        if (itemAnswerArgs) {
          await addDealItem({
            variables: {
              dealId: deal._id,
              args: itemCreateArgs,
            },
          })

          history.push(`/inApp/deals/${deal._id}`)
        }
      })
    }
  }

  return (
    <Fragment>
      <Paper className="u-p-sm u-12/12 u-mb-sm">
        <Typography
          variant="h5"
          gutterBottom
          style={{
            textAlign: 'left',
          }}
        >
          {isLegacyProduct ? productLegacy?.title : productVariant?.name}
        </Typography>
        <ItemCategoryQuestions
          itemCategoryId={
            isLegacyProduct
              ? productLegacy?.itemCategoryId
              : productVariant?.product.categoryId
          }
          itemAnswerArgs={itemAnswerArgs ?? []}
          setItemAnswerArgs={setItemAnswerArgs}
          productLegacy={productLegacy}
          productVariant={productVariant}
          isReadOnly={false}
          omitDeactivatedQuestionsForNewProducts={isNewProduct}
        />
        {!showSaveWithoutCalculationButton &&
        Boolean(
          calculationQueryResult.data?.itemCalculation?.itemValuesEntry,
        ) ? (
          <ItemCalculation
            calculatedItemValuesEntry={
              !showCalculateButton
                ? calculationQueryResult.data?.itemCalculation?.itemValuesEntry
                : undefined
            }
          />
        ) : null}

        {!showSaveWithoutCalculationButton && showCalculateButton ? (
          <Button
            onClick={onCalculateItem}
            variant="contained"
            color="primary"
            disabled={false}
            className="u-mb-sm u-mt-sm"
          >
            {t('calculate_values')}
          </Button>
        ) : null}

        {!showSaveWithoutCalculationButton &&
        !showCalculateButton &&
        Boolean(
          calculationQueryResult.data?.itemCalculation?.itemValuesEntry,
        ) ? (
          <Button
            className="u-mb-sm u-mt-sm"
            onClick={onSaveButtonClick}
            variant="contained"
            color="primary"
            disabled={false}
          >
            {t(item ? 'change_item' : 'add_item')}
          </Button>
        ) : null}
      </Paper>
    </Fragment>
  )
}

export default AddOrChangeItem
