import { mergeWith } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getTranslatedText } from '@/pages/InApp/Products/helpers'
import {
  EProductPriceAdjustmentType,
  ProductAvailableProperty,
  ProductPriceAdjustment,
  ProductVariant,
  ProductVariantPrice,
  ProductVariantPriceProperty,
  ProductVariantValue,
} from '@/schemaTypes'

export const getVariantValue = (
  variantValue: ProductVariantValue | undefined,
  [t, language],
) => {
  if (!variantValue) {
    return null
  }

  if (variantValue.__typename === 'ProductVariantPropertyTextValue') {
    return variantValue.value.translation.allTranslations.find(
      (a) => a.languageCode === language,
    )?.text
  }

  if (variantValue.__typename === 'ProductVariantPropertyNumberValue') {
    return variantValue.value.numberValue
  }

  if (variantValue.__typename === 'ProductVariantPropertyBooleanValue') {
    return variantValue.value.booleanValue ? t('yes') : t('no')
  }

  return null
}

export const getAvailablePropertiesHeaders = (
  availableProperties: ProductAvailableProperty[],
  language: string,
) => {
  const properties = availableProperties.map(
    (property) =>
      getTranslatedText(
        property.propertyDefinition.name.allTranslations,
        language,
      ) ?? property.propertyDefinition.key,
  )

  return properties
}

export const usePropertiesMapByVariantId = (variants: ProductVariant[]) => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  const propertiesMapByVariantId = useMemo(
    () =>
      variants.reduce((acc: Record<any, string[]>, variant) => {
        acc[variant.id] = variant.propertiesValues.map((propertyValue) =>
          getVariantValue(propertyValue, [t, language]),
        )
        return acc
      }, {}),
    [language, variants, t],
  )

  return propertiesMapByVariantId
}

export const useProductPriceMapByVariantId = (
  variants: ProductVariantPrice[],
) => {
  const productPriceMapByVariantId = useMemo(
    () =>
      variants.reduce((acc: Record<string, ProductVariantPrice>, variant) => {
        acc[variant.variantId] = variant
        return acc
      }, {}),
    [variants],
  )

  return productPriceMapByVariantId
}

export const useProductPricePropertyValueMapById = (
  properties: ProductVariantPriceProperty[],
) => {
  const productPricePropertyValueMapById = useMemo(
    () =>
      properties.reduce(
        (acc: Record<string, Record<string, string>>, property) => {
          const propertyValues = acc[property.propertyDefinitionId] || {}
          propertyValues[property.propertyValue] = parsePriceAdjustmentToText(
            property.adjustment,
          )
          acc[property.propertyDefinitionId] = propertyValues
          return acc
        },
        {},
      ),
    [properties],
  )

  return productPricePropertyValueMapById
}

export const parsePriceAdjustmentToText = (
  adjustment?: ProductPriceAdjustment | null,
) => {
  if (!adjustment) return ''

  const { type, value } = adjustment

  switch (type) {
    case EProductPriceAdjustmentType.PercentageMarkup:
      return value >= 0 ? `+${value}%` : `${value}%`
    case EProductPriceAdjustmentType.AbsoluteMarkup:
      return value >= 0 ? `+${value}€` : `${value}€`
    case EProductPriceAdjustmentType.FixedPrice:
      return `${value}€`
    default:
      throw new Error('Price adjustment type is invalid.')
  }
}

export const parseTextToPriceAdjustment = (adjustmentText: string) => {
  const sanitizedAdjustmentText = adjustmentText
    .replace('€', '')
    .replace('%', '')
  if (sanitizedAdjustmentText === '') {
    return null
  }
  const adjustmentValue = Number(sanitizedAdjustmentText)
  if (isNaN(adjustmentValue)) {
    return null
  }

  if (adjustmentText.includes('%')) {
    if (
      !/%$/.test(adjustmentText) ||
      (adjustmentText.match(/%/g) || []).length > 1
    ) {
      return null
    }

    return {
      type: EProductPriceAdjustmentType.PercentageMarkup,
      value: adjustmentValue,
    }
  }

  if (adjustmentText.includes('+') || adjustmentText.includes('-')) {
    if (
      (adjustmentText.includes('€') && !/€$/.test(adjustmentText)) ||
      (adjustmentText.match(/€/g) || []).length > 1
    ) {
      return null
    }

    return {
      type: EProductPriceAdjustmentType.AbsoluteMarkup,
      value: adjustmentValue,
    }
  }

  if (
    (adjustmentText.includes('€') && !/€$/.test(adjustmentText)) ||
    (adjustmentText.match(/€/g) || []).length > 1
  ) {
    return null
  }

  return {
    type: EProductPriceAdjustmentType.FixedPrice,
    value: adjustmentValue,
  }
}

export const computeAdjustedPrice = (
  price: number,
  adjustment: string | null,
): number | null => {
  let finalPrice = price
  const priceAdjustment = parseTextToPriceAdjustment(adjustment)

  if (!priceAdjustment) return finalPrice

  switch (priceAdjustment.type) {
    case EProductPriceAdjustmentType.PercentageMarkup:
      finalPrice = price + (price * priceAdjustment.value) / 100
      break
    case EProductPriceAdjustmentType.AbsoluteMarkup:
      finalPrice = price + priceAdjustment.value
      break
    case EProductPriceAdjustmentType.FixedPrice:
      finalPrice = priceAdjustment.value
      break
    default:
      throw new Error('Price adjustment type is invalid.')
  }

  return finalPrice >= 0 ? finalPrice : null
}

export function computeAdjustmentPrice(
  price: number,
  priceAdjustment: ProductPriceAdjustment,
): number {
  if (priceAdjustment.type === EProductPriceAdjustmentType.PercentageMarkup) {
    return (price * priceAdjustment.value) / 100
  }

  return priceAdjustment.value
}

export const validateAdjustment = (adjustment: string) => {
  if (!adjustment) return null

  const priceAdjustment = parseTextToPriceAdjustment(adjustment)

  return priceAdjustment ? null : 'amount.invalid_amount'
}

export const validateMaterialMetric = (value: string) => {
  return value === '' || Number.isNaN(Number(value))
    ? 'error.error_validation_is_required'
    : null
}

export const calculateVariantPrice = (
  basePrice: number,
  propertiesValues: ProductVariantValue[],
  propertyValuesMapById: Record<string, Record<string, string>>,
) => {
  return propertiesValues.reduce((total, propertyValue) => {
    const propertyValuePrice =
      propertyValuesMapById[propertyValue.propertyDefinition?._id]

    if (!propertyValuePrice) return total

    let adjustment = ''

    if (propertyValue.__typename === 'ProductVariantPropertyTextValue') {
      adjustment = propertyValuePrice[propertyValue.value.id] ?? ''
    } else if (
      propertyValue.__typename === 'ProductVariantPropertyNumberValue'
    ) {
      adjustment = propertyValuePrice[propertyValue.value.numberValue] ?? ''
    } else if (
      propertyValue.__typename === 'ProductVariantPropertyBooleanValue'
    ) {
      adjustment =
        propertyValuePrice[propertyValue.value.booleanValue ? 'yes' : 'no'] ??
        ''
    }

    const adjustmentValue = adjustment
      ? parseTextToPriceAdjustment(adjustment)
      : null

    return adjustmentValue
      ? total + computeAdjustmentPrice(basePrice, adjustmentValue)
      : total
  }, basePrice)
}

export function computePreciousMetalPrice(args: {
  value: number
  weight: number
  alloy: number
}) {
  return args.weight * ((args.alloy * 0.999) / 24.0) * args.value
}

export const mergedProperties = (
  sourceValue: Record<string, Record<string, string>>,
  objectValue: Record<string, Record<string, string>>,
) => {
  const newValue = JSON.parse(JSON.stringify(sourceValue))

  for (const key in objectValue) {
    if (newValue[key]) {
      newValue[key] = mergeWith(
        newValue[key],
        objectValue[key],
        (target, src) => {
          return target !== '' ? target : src
        },
      )
    } else {
      newValue[key] = objectValue[key]
    }
  }

  return newValue
}
