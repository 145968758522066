import { Paper, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import ShopsDetails from '@/domains/shops/components/ShopsDetails'
import { useCreateShop, useGetShop } from '@/domains/shops/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { useGetActivePartners } from '@/hooks/getActivePartners'
import { MutationCreateShopArgs, Shop } from '@/schemaTypes'

export interface ShopsCreateProps {
  shopId: string | null
  isDialog?: boolean
  requireCompanyId?: boolean
  onSave?: (companyLocation: MutationCreateShopArgs) => void
}

export function ShopsCreate(props: ShopsCreateProps) {
  const { shopId, isDialog = false, onSave, requireCompanyId = true } = props
  const { t } = useTranslation()
  const history = useHistory()

  const [createIsInProgress, setCreateIsInProgress] = useState(false)

  const { shop, queryResult } = useGetShop({
    variables: {
      shopId: shopId ?? '',
    },
  })

  const partnersResult = useGetActivePartners()
  const partners = partnersResult.partners ?? []

  const createShop = useMutationShowingErrors({
    mutation: useCreateShop(),
    successMessage: t('company.company_location_created'),
    closeDialogWhenMutationStarts: isDialog,
    hideSuccessMessage: isDialog,
  })

  const onSaveShop = (shop: Shop) => {
    setCreateIsInProgress(true)
    createShop({
      variables: {
        ...shop,
      },
      onCompleted: (data) => {
        setCreateIsInProgress(false)
        history.replace(
          `/inApp/configurations/shops/edit/${data.createShop._id}`,
        )
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      {!isDialog && (
        <ConfigurationBreadcrumbs
          modelId={shopId ?? ''}
          modelName={'shop.plural'}
          mode={'create'}
        />
      )}
      <Typography variant="h5" gutterBottom>
        {t('shop.shop_create')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        <ShopsDetails
          shop={shop}
          partners={partners}
          requireCompanyId={requireCompanyId}
          addOrUpdateIsInProgress={createIsInProgress}
          onSave={onSave ?? onSaveShop}
        />
      )}
    </Paper>
  )
}

export default ShopsCreate
