import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeleteMaterialPriceArgs } from '@/schemaTypes'

const DELETE_MATERIAL_PRICE = gql`
  mutation deleteMaterialPrice($materialPriceId: ObjectId!) {
    deleteMaterialPrice(materialPriceId: $materialPriceId)
  }
`

type TData = { deleteMaterialPrice: Mutation['deleteMaterialPrice'] }
type TVariables = MutationDeleteMaterialPriceArgs

export function useDeleteMaterialPrice(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deleteMaterialPrice] = useMutation<TData, TVariables>(
    DELETE_MATERIAL_PRICE,
    opts,
  )

  return deleteMaterialPrice
}
