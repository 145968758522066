import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Deal, EksvRequestType } from '@/schemaTypes'

export interface KSVQueryHistoryProps {
  deal: Deal
}

export function KSVQueryHistory(props: KSVQueryHistoryProps) {
  const { deal } = props
  const { t } = useTranslation()

  const ksvAddAssetQuery = findKSVQuery(EksvRequestType.AddAsset, deal)
  const ksvChangeAssetQuery = findKSVQuery(EksvRequestType.ChangeAsset, deal)
  const ksvRemoveAssetQuery = findKSVQuery(EksvRequestType.RemoveAsset, deal)

  if (ksvRemoveAssetQuery) {
    return (
      <TextStyled color="orange">
        {t('ksv_asset_entry_removed', {
          VIN: ksvRemoveAssetQuery.vin,
          queryAt: dayjs(ksvRemoveAssetQuery.queryAt).format('DD.MM.YYYY'),
        })}
      </TextStyled>
    )
  }

  if (ksvChangeAssetQuery) {
    return (
      <TextStyled color="green">
        {t('ksv_asset_entry_updated', {
          VIN: ksvChangeAssetQuery.vin,
          queryAt: dayjs(ksvChangeAssetQuery.queryAt).format('DD.MM.YYYY'),
        })}
      </TextStyled>
    )
  }

  if (ksvAddAssetQuery) {
    return (
      <TextStyled color="green">
        {t('ksv_asset_entry_created', {
          VIN: ksvAddAssetQuery.vin,
          queryAt: dayjs(ksvAddAssetQuery.queryAt).format('DD.MM.YYYY'),
        })}
      </TextStyled>
    )
  }

  return null
}

function findKSVQuery(type: EksvRequestType, deal: Deal) {
  return deal.items[0].vehicleData?.ksvQueryHistories?.find(
    (history) =>
      history.type === type &&
      history.vin === deal.items[0]?.vehicleData?.vin &&
      history.meta?.[getTypeKey(type)] === deal._id,
  )
}

function getTypeKey(type: EksvRequestType): string {
  switch (type) {
    case EksvRequestType.AddAsset:
      return 'verifiedDealId'
    case EksvRequestType.ChangeAsset:
      return 'extensionDealId'
    case EksvRequestType.RemoveAsset:
      return 'paybackConfirmedDealId'
    default:
      return ''
  }
}

const TextStyled = styled.p<{ color?: string }>`
  color: ${(props) =>
    props.color ? props.color : 'var(--global-text-color, #6c757d)'};
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.05rem; /* 120% */
  margin-bottom: 0.875rem;
`
