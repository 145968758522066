import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ShopSelect from '@/components/ShopSelect'
import { Context } from '@/context'
import {
  useUpdatePaybackData,
  useUpdatePayoutData,
  useUpdateSecondaryPayoutData,
} from '@/domains/deals/hooks'
import { useUpdateDealInsuredAmount } from '@/domains/deals/hooks/updateDealInsuredAmount'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import {
  EManualPaymentType,
  PaymentDataArgs,
  SecondaryPaymentDataArgs,
} from '@/schemaTypes'
import { isDealVerified } from '@/utils/deal'
import { isShopLevelPaymentType, paymentTypeUnion } from '@/utils/misc'
import { PaymentDetails, PaymentDetailsProps } from './PaymentDetails'
import styles from './PaymentDetails.module.scss'

type PaymentDetailsContainerProps = Pick<PaymentDetailsProps, 'deal'>

export default function PaymentDetailsContainer(
  props: PaymentDetailsContainerProps,
) {
  const { deal } = props
  const { showInfo, closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()
  const showConfirmPaymentTypePopup = useShowConfirmPopup({
    actionText: t('confirm'),
  })

  const updatePayoutType = useMutationShowingErrors({
    mutation: useUpdatePayoutData(),
    successMessage: `${t('update_successful')}!`,
  })

  const updateSecondaryPayoutData = useMutationShowingErrors({
    mutation: useUpdateSecondaryPayoutData(),
    successMessage: `${t('update_successful')}!`,
  })

  const onUpdateSecondaryPayoutData = (
    args: SecondaryPaymentDataArgs | null,
  ) => {
    if (
      isDealVerified(deal) &&
      args &&
      args.paymentType !==
        paymentTypeUnion(deal.secondaryPayoutData?.paymentType) &&
      isShopLevelPaymentType(args.paymentType) &&
      !args.shopId
    ) {
      showInfo(t('select_cashbook_for_booking'))
    } else if (args && args.amount <= 0) {
      showInfo(t('amount_is_not_valid'))
    } else {
      updateSecondaryPayoutData({
        variables: {
          dealId: deal._id,
          args,
        },
      })
    }
  }

  const updatePaybackType = useMutationShowingErrors({
    mutation: useUpdatePaybackData(),
    successMessage: `${t('update_successful')}!`,
  })

  const onUpdatePrimaryPayoutData = (payoutArgs: PaymentDataArgs) => {
    showConfirmPaymentTypePopup({
      title: t('confirm_change_payout_type'),
      action: () => {
        if (
          isDealVerified(deal) &&
          isShopLevelPaymentType(payoutArgs.paymentType) &&
          !payoutArgs.shopId
        ) {
          return showInfo(t('select_cashbook_for_booking'))
        }
        closeConfirmModal()
        updatePayoutType({
          variables: {
            dealId: deal._id,
            args: payoutArgs,
          },
        })
      },
    })
  }

  const updateInsuredAmount = useMutationShowingErrors({
    mutation: useUpdateDealInsuredAmount(),
    successMessage: `${t('update_successful')}!`,
  })

  const onSaveInsuredAmount = (insuredAmount: number) => {
    updateInsuredAmount({ variables: { dealId: deal._id, insuredAmount } })
  }

  const onChangePaybackType = (paymentType: EManualPaymentType) => {
    if (
      deal.pawnData?.paybackData?.paymentType &&
      paymentType !== paymentTypeUnion(deal.pawnData?.paybackData?.paymentType)
    ) {
      showConfirmPaymentTypePopup({
        title: t('confirm_change_payback_type'),
        component: (shopId, setShopId) => {
          return (
            <>
              {isShopLevelPaymentType(paymentType) && (
                <div className={styles.flexCenterWrapper}>
                  <p style={{ marginBottom: '1rem' }}>
                    {t('select_cashbook_for_booking')}:
                  </p>
                  <ShopSelect
                    companyId={deal.companyId}
                    shopId={shopId}
                    onChange={(value) => {
                      setShopId(value)
                    }}
                    includeDisabled
                  />
                </div>
              )}
            </>
          )
        },
        action: async (shopId) => {
          if (isShopLevelPaymentType(paymentType) && !shopId) {
            return showInfo(t('select_cashbook_for_booking'))
          }
          closeConfirmModal()

          updatePaybackType({
            variables: { dealId: deal._id, args: { paymentType, shopId } },
          })
        },
      })
    }
  }

  return (
    <PaymentDetails
      {...props}
      onUpdatePrimaryPayoutData={onUpdatePrimaryPayoutData}
      onChangePaybackType={onChangePaybackType}
      onSaveInsuredAmount={onSaveInsuredAmount}
      onUpdateSecondaryPayoutData={onUpdateSecondaryPayoutData}
    />
  )
}
