import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import {
  EQuestionType,
  EQuestionValueMode,
  ItemQuestion,
  Query,
  QueryGetItemQuestionArgs,
} from '@/schemaTypes'

const GET_ITEM_QUESTION = gql`
  query getItemQuestion($itemQuestionId: ObjectId!) {
    getItemQuestion(itemQuestionId: $itemQuestionId) {
      _id
      validFrom
      validTo
      _oldItemQuestionIds
      order
      questionType
      valueMode
      titleKey
      infoKey
      isInternal
      singleChoiceOptions {
        labelKey
        infoKey
        categoryValues
        conditionValue
        isDefaultValue
      }
      rangeData {
        minValue
        maxValue
        defaultValue
        unitPrice
        unitKey
      }
      predictionTag
      itemCategoryIds
      isDeactivatedForNewProducts
    }
  }
`

type TData = { getItemQuestion: Query['getItemQuestion'] }
type TVariables = QueryGetItemQuestionArgs

const defaultItemQuestion: ItemQuestion = {
  _id: '',
  validFrom: null,
  validTo: null,
  order: 0,
  questionType: EQuestionType.SingleChoice,
  valueMode: EQuestionValueMode.Percentage,
  titleKey: '',
  itemCategoryIds: [],
}

export function useGetItemQuestion(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_ITEM_QUESTION, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.itemQuestionId,
  })

  useShowErrorsPopup(queryResult.error)

  const itemQuestion = opts.variables?.itemQuestionId
    ? queryResult.data?.getItemQuestion
    : defaultItemQuestion

  return { queryResult, itemQuestion }
}
