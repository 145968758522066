import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateCompanyArgs } from '@/schemaTypes'
import { companyFragments } from './companyFragments'

const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $name: String!
    $uid: String!
    $fibuNumber: String!
    $iban: String
    $bic: String
    $accountOwner: String
    $paypalEmail: String
    $_id: ObjectId!
    $headquarter: IAddress
    $email: String
    $supportEmail: String
    $saleSupportEmail: String
    $phoneNumber: String
    $whatsappNumber: String
    $website: String
    $jurisdiction: String
    $configuration: CompanyConfigurationArgs
    $primaryShopLocationId: ObjectId!
    $successionCompanyId: ObjectId
    $salesConfiguration: CompanySalesConfigurationArgs
    $unzerConfiguration: CompanyUnzerConfigurationArgs
    $dpdConfiguration: DPDConfigurationArgs
  ) {
    updateCompany(
      name: $name
      uid: $uid
      fibuNumber: $fibuNumber
      iban: $iban
      bic: $bic
      accountOwner: $accountOwner
      paypalEmail: $paypalEmail
      _id: $_id
      headquarter: $headquarter
      email: $email
      supportEmail: $supportEmail
      saleSupportEmail: $saleSupportEmail
      phoneNumber: $phoneNumber
      whatsappNumber: $whatsappNumber
      website: $website
      jurisdiction: $jurisdiction
      configuration: $configuration
      primaryShopLocationId: $primaryShopLocationId
      successionCompanyId: $successionCompanyId
      salesConfiguration: $salesConfiguration
      unzerConfiguration: $unzerConfiguration
      dpdConfiguration: $dpdConfiguration
    ) {
      _id
      name
      uid
      fibuNumber
      iban
      bic
      accountOwner
      paypalEmail
      email
      supportEmail
      saleSupportEmail
      phoneNumber
      whatsappNumber
      website
      jurisdiction
      configuration {
        ...CompanyConfigurationFragment
      }
      headquarter {
        coordinates {
          lat
          lng
        }
        houseNumber
        stairway
        door
        floor
        street
        city
        state
        countryCode
        country
        zipCode
        queryString
        address1
        address2
      }
      primaryShopLocationId
      salesConfiguration {
        ...CompanySalesConfigurationFragment
      }
      unzerConfiguration {
        ...CompanyUnzerConfigurationFragment
      }
      dpdAccountInfo {
        ...DPDAccountInfoFragment
      }
    }
  }
  ${companyFragments.configuration}
  ${companyFragments.salesConfiguration}
  ${companyFragments.unzerConfiguration}
  ${companyFragments.dpdAccountInfo}
`

type TData = { updateCompany: Mutation['updateCompany'] }
type TVariables = MutationUpdateCompanyArgs

export function useUpdateCompany(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateCompany] = useMutation<TData, TVariables>(UPDATE_COMPANY, opts)

  return updateCompany
}
