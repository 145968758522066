import { Button, Typography } from '@material-ui/core'
import EuroOutlinedIcon from '@material-ui/icons/EuroOutlined'
import dayjs from 'dayjs'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, RouteComponentProps, Switch, useParams } from 'react-router-dom'
import FullScreenLoading from '@/components/FullScreenLoading'
import SentryRoute from '@/components/SentryRoute'
import { Context } from '@/context'
import AuctionBlock from '@/domains/auctions/components/Auction'
import styles from '@/domains/auctions/components/AuctionDetails.module.scss'
import { useGetAuctionById } from '@/domains/auctions/hooks'
import { Auction } from '@/schemaTypes'

export type ItemDetailsContainerProps = RouteComponentProps

export function AuctionDetailsPage(props: ItemDetailsContainerProps) {
  const { language } = useContext(Context)
  const { auctionId = '' } = useParams() as any

  const getAuctionVariables = useMemo(
    () => ({ auctionId, languageCode: language }),
    [auctionId, language],
  )

  const { auction, queryResult } = useGetAuctionById({
    variables: getAuctionVariables,
    skip: !auctionId || !language,
  })

  if (!auctionId) {
    return null
  }

  return (
    <div className={`${styles.root} u-12/12`}>
      {(queryResult.loading || !auction) && <FullScreenLoading />}
      {!queryResult.loading && auction && (
        <Switch>
          <SentryRoute
            path={`${props.match.path}`}
            render={() => {
              return <AuctionDetails auction={auction} />
            }}
          />
        </Switch>
      )}
    </div>
  )
}

export interface AuctionDetailsProps {
  auction: Auction
}

export function AuctionDetails({ auction }: AuctionDetailsProps) {
  const { t } = useTranslation()

  if (!auction) {
    return null
  }
  return (
    <div className={`u-p-sm u-12/12 u-mb-sm ${styles.pathTree}`}>
      {auction && (
        <>
          <Typography variant="h5" gutterBottom className={'u-flex u-jc-sb'}>
            <div className={'u-inline-block'} style={{ marginRight: 'auto' }}>
              {`${t('auction.auction_date')}: `}
              {dayjs(auction.auctionDate).format('DD.MM.YYYY')}
            </div>

            <Link
              to={`/inApp/auctions/`}
              className={`${styles.pathTreeLinkButton}`}
            >
              <Button
                className="u-mr-mini"
                variant="contained"
                classes={{ root: styles.button }}
              >
                <EuroOutlinedIcon classes={{ root: styles.dealIcon }} />{' '}
                {t('to_auctions')}
              </Button>
            </Link>
          </Typography>

          <AuctionBlock auctionFromDB={auction} />
        </>
      )}
    </div>
  )
}
