import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { EOrderBy, Query, QueryGetExportEntriesArgs } from '@/schemaTypes'

const GET_EXPORT_ENTRIES = gql`
  query getExportEntries($opts: ExportEntryOpts) {
    getExportEntries(opts: $opts) {
      records {
        _id
        createdAt
        exportStatus
        items {
          viewName
          status
          currentChunkInProgress
        }
        employeeId
        fileUrl
      }
      totalCount
      totalPages
    }
  }
`

type TData = { getExportEntries: Query['getExportEntries'] }
type TVariables = QueryGetExportEntriesArgs

export function useGetExportEntries(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_EXPORT_ENTRIES, {
    variables: {
      opts: {
        order: { createdAt: EOrderBy.Desc },
      },
    },
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    exportEntries: queryResult.data?.getExportEntries.records ?? [],
    totalCount: queryResult.data?.getExportEntries?.totalCount,
    totalPages: queryResult.data?.getExportEntries?.totalPages,
  }
}
