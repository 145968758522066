import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryGetExistingDealCalculationArgs } from '@/schemaTypes'
import { dealCalculationFragments } from './dealFragments'

const GET_EXISTING_DEAL_CALCULATIONL = gql`
  query getExistingDealCalculation(
    $dealId: ObjectId!
    $args: GetExistingDealCalculationArgs!
  ) {
    getExistingDealCalculation(dealId: $dealId, args: $args) {
      ...DealCalculationFragment
    }
  }
  ${dealCalculationFragments.dealCalculation}
`

type TData = { getExistingDealCalculation: Query['getExistingDealCalculation'] }
type TVariables = QueryGetExistingDealCalculationArgs

export function useGetExistingDealCalculation(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_EXISTING_DEAL_CALCULATIONL,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    dealCalculation: queryResult.data?.getExistingDealCalculation,
  }
}
