import { QueryHookOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import useDebouncedQuery from '@/hooks/useDebouncedQuery'
import { Query, QuerySearchCustomDealsArgs } from '@/schemaTypes'
import { customDeal, customDealItem } from './customDealFragments'

const SEARCH_CUSTOM_DEALS = gql`
  query searchCustomDeals($args: CustomDealsFiltersArgs!) {
    searchCustomDeals(args: $args) {
      nodes {
        ...CustomDealFragment
        items {
          ...CustomDealItemFragment
        }
      }
      pageInfo {
        skip
        limit
        hasPrevPage
        hasNextPage
        total
      }
    }
  }
  ${customDeal}
  ${customDealItem}
`

type TData = { searchCustomDeals: Query['searchCustomDeals'] }
type TVariables = QuerySearchCustomDealsArgs

export function useSearchCustomDeals(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useDebouncedQuery<TData, TVariables>(
    SEARCH_CUSTOM_DEALS,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
    200,
  )

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
