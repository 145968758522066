import { useQuery } from '@apollo/client'
import { graphql } from '@/gql'
import CrawlerConfig from '@/redesign/domains/products/crawlerConfig/components/CrawlerConfig'

const ProductConfigurator_Query = graphql(`
  query ProductConfigurator_Query($opts: ItemCategoryOpts) {
    getItemCategories(opts: $opts) {
      ...CrawlerConfig_ItemCategories
    }
  }
`)

export function CrawlerConfigPage() {
  const getItemCategoriesRes = useQuery(ProductConfigurator_Query, {
    variables: { opts: { filter: { includeHided: true } } },
  })

  return (
    <CrawlerConfig
      itemCategories={getItemCategoriesRes.data?.getItemCategories ?? []}
    />
  )
}
