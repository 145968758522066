import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import {
  defaultCompanyConfiguration,
  defaultSalesConfiguration,
} from '@/domains/deals/components/constants/company'
import { useShowErrorsPopup } from '@/hooks'
import { ERoles, Employee, Query, QueryGetEmployeeArgs } from '@/schemaTypes'

const GET_EMPLOYEE = gql`
  query getEmployee($employeeId: ObjectId!) {
    getEmployee(employeeId: $employeeId) {
      _id
      email
      firstname
      lastname
      dateOfBirth
      phone
      sex
      note
      createdAt
      updatedAt
      userPermissionId
      payoutConfigurations {
        payoutLimit
        noLimit
        carPayoutLimit
      }
      roles
      employeeNumber
      companyId
      company {
        _id
        name
      }
      shopIds
      locations {
        _id
        name
      }
      token
      scheduledDeactivationAt
    }
  }
`

type TData = { getEmployee: Query['getEmployee'] }
type TVariables = QueryGetEmployeeArgs

const defaultEmployee: Employee = {
  _id: '',
  email: '',
  firstname: '',
  lastname: '',
  dateOfBirth: null,
  phone: '',
  sex: undefined,
  createdAt: null,
  updatedAt: null,
  userPermissionId: '',
  roles: [ERoles.Locationstaff],
  companyId: '',
  company: {
    _id: '',
    name: '',
    fibuNumber: '',
    uid: '',
    iban: '',
    paypalEmail: '',
    configuration: defaultCompanyConfiguration,
    primaryShopLocationId: '',
    salesConfiguration: defaultSalesConfiguration,
  },
  shopIds: [],
  locations: [],
  token: '',
  userPermission: {
    _id: '',
    userPermissions: [],
  },
  employeeNumber: 0,
}

export function useGetEmployee(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useQuery<TData, TVariables>(GET_EMPLOYEE, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.employeeId,
  })

  useShowErrorsPopup(queryResult.error)

  const employee = opts.variables?.employeeId
    ? queryResult.data?.getEmployee
    : defaultEmployee
  return { queryResult, employee }
}
