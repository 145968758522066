import PrivateSaleTransactionInputDetails from '../Common/PrivateSaleTransactionInputDetails'
import {
  Button,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import * as React from 'react'
import { Fragment, useContext, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import {
  ETransactionType,
  Item,
  PrivateSaleTransactionArgs,
} from '@/schemaTypes'
import { printLocalAmount } from '@/utils/misc'
import { printLocalDate } from '@/utils/print'
import { Timezone } from '@/utils/time'
import styles from './PrivateSaleDealsList.module.scss'

interface PrivateSaleTransactionDetailsProps {
  inputTransactionArgs: PrivateSaleTransactionArgs
  transactionsArgs: PrivateSaleTransactionArgs[]
  setTransactionsArgs: (listArgs: PrivateSaleTransactionArgs[]) => void
  timezone: Timezone
  item: Item
}

const PrivateSaleTransactionDetails: FunctionComponent<
  PrivateSaleTransactionDetailsProps
> = (props) => {
  const {
    inputTransactionArgs,
    transactionsArgs,
    setTransactionsArgs,
    timezone,
    item,
  } = props
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const [newTransactionArgs, setNewTransactionArgs] =
    useState<PrivateSaleTransactionArgs>(inputTransactionArgs)
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [restoreDisabled, setRestoreDisabled] = useState(true)
  const [deleteDisabled] = useState(false)

  useEffect(() => {
    if (newTransactionArgs !== inputTransactionArgs) {
      setSaveDisabled(false)
      setRestoreDisabled(false)
    } else {
      setSaveDisabled(true)
      setRestoreDisabled(true)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTransactionArgs])

  const onUpdateButtonClick = () => {
    if (
      inputTransactionArgs.transactionType === ETransactionType.SellingCar &&
      (!inputTransactionArgs.buyerName ||
        inputTransactionArgs.buyerName.length <= 0)
    ) {
      return showInfo(t('buyer_name_is_not_set'))
    } else {
      const index = transactionsArgs.indexOf(inputTransactionArgs)
      const temp = transactionsArgs.filter(
        (c) => c.order != inputTransactionArgs.order,
      )
      temp.splice(index, 0, newTransactionArgs)
      setTransactionsArgs(temp)
      setOpen(!open)
    }
  }

  const onRestoreButtonClick = () => {
    setNewTransactionArgs(inputTransactionArgs)
    setOpen(!open)
  }

  const onDeleteButtonClick = () => {
    let temp = transactionsArgs.filter(
      (c) => c.order != inputTransactionArgs.order,
    )

    // Handle Liquidation_Fee
    if (inputTransactionArgs.transactionType === ETransactionType.SellingCar) {
      temp = temp.filter(
        (c) => c.transactionType !== ETransactionType.LiquidationFee,
      )
    }

    setTransactionsArgs(temp)
    setOpen(!open)
  }

  return (
    <Fragment>
      <TableRow>
        <TableCell component="th" scope="row">
          {newTransactionArgs.order}
        </TableCell>
        <TableCell align="left">
          {printLocalDate(newTransactionArgs.date, { timezone: timezone })}
        </TableCell>
        <TableCell align="left">{newTransactionArgs.paymentType}</TableCell>
        <TableCell align="left">{newTransactionArgs.transactionType}</TableCell>
        <TableCell align="left">
          {newTransactionArgs.grossAmount ? (
            <div>
              <p>{printLocalAmount(newTransactionArgs.grossAmount)}</p>
              <p
                style={{ color: 'gray', fontSize: 'small' }}
              >{`(${printLocalAmount(newTransactionArgs.amount)})`}</p>
            </div>
          ) : (
            printLocalAmount(newTransactionArgs.amount)
          )}
        </TableCell>
        <TableCell align="left">
          {newTransactionArgs.costName ? (
            <div>
              <p> {newTransactionArgs.costName} </p>
            </div>
          ) : (
            ''
          )}
          {<p> {newTransactionArgs.description}</p>}
        </TableCell>

        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className={`${styles.editTable}`}>
              <PrivateSaleTransactionInputDetails
                privateSaleTransactionArgs={newTransactionArgs}
                setPrivateSaleTransactionArgs={setNewTransactionArgs}
                isPrivateSaleCreated={false}
                isEditMode={false}
                item={item}
              />
              <table>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>
                    <Button
                      className="u-mh-mini u-mt-20 u-mb-20"
                      variant="contained"
                      onClick={onUpdateButtonClick}
                      disabled={saveDisabled}
                    >
                      {t('update')}
                    </Button>
                    <Button
                      className="u-mh-mini u-mt-20 u-mb-20"
                      variant="contained"
                      onClick={onRestoreButtonClick}
                      disabled={restoreDisabled}
                    >
                      {t('restore')}
                    </Button>
                    <Button
                      className="u-mh-mini u-mt-20 u-mb-20"
                      variant="contained"
                      onClick={onDeleteButtonClick}
                      disabled={deleteDisabled}
                    >
                      {t('delete')}
                    </Button>
                  </td>
                </tr>
              </table>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default PrivateSaleTransactionDetails
