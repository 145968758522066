import { TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopy from '@material-ui/icons/FileCopy'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import { Link } from 'react-router-dom'
import { MaterialPrice } from '@/schemaTypes'
import globalStyles from '@/styles/global.module.scss'

dayjs.extend(utc)

export interface MaterialPricesRowProps {
  materialPrice: MaterialPrice
  deleteMaterialPrice: () => void
}

const MaterialPricesRow = (props: MaterialPricesRowProps) => {
  const { materialPrice } = props
  const id = materialPrice._id ?? ''

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell align="right">
        {dayjs(materialPrice.date).format('DD.MM.YYYY')}
      </TableCell>
      <TableCell align="right">{materialPrice.material}</TableCell>
      <TableCell align="right">{materialPrice.price}</TableCell>
      <TableCell align="right">{materialPrice.mu}</TableCell>
      <TableCell align="right">{materialPrice.sigma}</TableCell>
      <TableCell>
        <Link to={`/inApp/configurations/materialPrices/create/${id}`}>
          <FileCopy />
        </Link>
        <Link to={`/inApp/configurations/materialPrices/edit/${id}`}>
          <EditIcon />
        </Link>
        <DeleteIcon
          className={globalStyles.clickable}
          onClick={props.deleteMaterialPrice}
        />
      </TableCell>
    </TableRow>
  )
}

export default MaterialPricesRow
