import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateExportEntryArgs } from '@/schemaTypes'

const TRIGGER_CSV_EXPORT = gql`
  mutation createExportEntry($employeeId: ObjectId!) {
    createExportEntry(employeeId: $employeeId)
  }
`

type TData = { createExportEntry: Mutation['createExportEntry'] }
type TVariables = MutationCreateExportEntryArgs

export function useCreateExportEntry() {
  const [createExportEntry] = useMutation<TData, TVariables>(TRIGGER_CSV_EXPORT)

  return createExportEntry
}
