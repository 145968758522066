import { IsNotEmpty } from 'class-validator'
import { StorageFacility } from '@/schemaTypes'

export class StorageFacilityValidation {
  @IsNotEmpty()
  name: string

  constructor(storageFacility: StorageFacility) {
    this.name = storageFacility.name
  }
}
