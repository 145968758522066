import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateEmployeeArgs } from '@/schemaTypes'

const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee(
    $email: String
    $firstname: String
    $lastname: String
    $dateOfBirth: DateTime
    $phone: String
    $sex: ESex
    $note: String
    $roles: [ERoles!]
    $_id: ObjectId!
    $scheduledDeactivationAt: DateTime
    $preferences: EmployeePreferencesInput
    $payoutConfigurations: PayoutConfigurationsInput
  ) {
    updateEmployee(
      email: $email
      firstname: $firstname
      lastname: $lastname
      dateOfBirth: $dateOfBirth
      phone: $phone
      sex: $sex
      note: $note
      roles: $roles
      _id: $_id
      scheduledDeactivationAt: $scheduledDeactivationAt
      preferences: $preferences
      payoutConfigurations: $payoutConfigurations
    ) {
      _id
      email
      firstname
      lastname
      dateOfBirth
      phone
      note
      sex
      createdAt
      updatedAt
      employeeNumber
      userPermissionId
      payoutConfigurations {
        payoutLimit
        noLimit
        carPayoutLimit
      }
      scheduledDeactivationAt
      preferences {
        defaultShopId
      }
      roles
      companyId
      company {
        _id
        name
      }
      shopIds
      locations {
        _id
        name
      }
      token
    }
  }
`

type TData = { updateEmployee: Mutation['updateEmployee'] }
type TVariables = MutationUpdateEmployeeArgs

export function useUpdateEmployee(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateEmployee] = useMutation<TData, TVariables>(UPDATE_EMPLOYEE, opts)

  return updateEmployee
}
