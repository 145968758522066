import LanguageDropdown from '../LanguageDropdown/LanguageDropdown'
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { Badge } from 'primereact/badge'
import { Image } from 'primereact/image'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { graphql } from '@/gql'
import LogoImg from '@/images/monologo_black.png'
import CompanyDropdown from '@/redesign/components/CompanyDropdown/CompanyDropdown'
import { Employee } from '@/schemaTypes'
import { useAppDispatch, useAppSelector } from '@/store'
import { switchCompany } from '@/store/actions/ui'

export const ALL_COMPANIES = 'ALL'

const Menubar_Query = graphql(`
  query Menubar_Query($opts: CompanyOpts) {
    getActiveCompanies(opts: $opts) {
      ...CompanyDropdown_Companies
    }
  }
`)

interface MenubarProps {
  currentUser: Employee | null
  onLogout: () => void
  onSideMenuOpen: () => void
  onShowedEmployeeChangeShopPopup: () => void
}

const Menubar: React.FC<MenubarProps> = React.memo(
  ({
    currentUser,
    onLogout,
    onSideMenuOpen,
    onShowedEmployeeChangeShopPopup,
  }) => {
    const [isScrolled, setIsScrolled] = useState(false)
    const { t } = useTranslation()
    const { data } = useQuery(Menubar_Query)
    const companyId = useAppSelector((state) => state.ui.companyId)
    const dispatch = useAppDispatch()

    useEffect(() => {
      const handleScroll = () => {
        const currentScrollY = window.scrollY
        if (currentScrollY > 20 && !isScrolled) {
          setIsScrolled(true)
        } else if (currentScrollY === 0 && isScrolled) {
          setIsScrolled(false)
        }
      }

      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [isScrolled])

    const handleChangeCompany = useCallback(
      (companyId: string) => {
        dispatch(switchCompany(companyId))
      },
      [dispatch],
    )

    const defaultCompany = useMemo(
      () => ({ name: t('all'), _id: ALL_COMPANIES }),
      [t],
    )

    return (
      <MenuContainer
        className={classNames('sticky z-10 mb-4', { scrolled: isScrolled })}
      >
        <div className="hidden lg:flex w-full">
          <DesktopView />
        </div>

        <div className="flex lg:hidden">
          <MobileView />
        </div>
      </MenuContainer>
    )

    function DesktopView() {
      return (
        <Menu
          className={classNames(
            'flex flex-col lg:flex-row justify-between pl-2 rounded-md relative w-full',
            { 'rounded-none': isScrolled },
          )}
        >
          <div className="flex flex-1 justify-between lg:justify-start items-center">
            <MenuIconContainer
              className="cursor-pointer flex justify-center items-center px-4"
              onClick={onSideMenuOpen}
            >
              <MenuIcon className="pi pi-bars p-toolbar-separator " />
            </MenuIconContainer>
          </div>
          <Link to="/inApp" className="flex mb-4 lg:mb-0">
            <div className="flex flex-1 justify-center items-center">
              <ImageStyled src={LogoImg} alt="Cashy" className="mr-4 xl:mr-0" />
              <MenuTitle className="pl-3 pr-4 hidden xl:block">
                CASHY - ADMIN
              </MenuTitle>
              <BadgeStyled
                value={process.env.REACT_APP_ENV.toUpperCase()}
                background={
                  process.env.REACT_APP_ENV !== 'production' ? '#f50057' : ''
                }
              />
            </div>
          </Link>
          <div className="flex flex-1 justify-end items-center flex-col md:flex-row mb-4 md:mb-0">
            <div className="mb-2 md:mb-0 w-full flex justify-end">
              <CompanyDropdown
                companies={data?.getActiveCompanies}
                defaultValue={defaultCompany}
                companyId={companyId}
                onChangeCompany={handleChangeCompany}
                className="lg:w-60"
              />
            </div>
            <div className="mb-2 md:mb-0 w-full lg:w-36 ml-2">
              <LanguageDropdown />
            </div>
            <div className="mb-2 md:mb-0 ml-2">
              <ProfileDropdown
                currentUser={currentUser}
                onEmployeeChangeShop={onShowedEmployeeChangeShopPopup}
                onLogout={onLogout}
              />
            </div>
          </div>
        </Menu>
      )
    }

    function MobileView() {
      return (
        <Menu
          className={classNames(
            'flex flex-col justify-between pl-2 rounded-md relative w-full',
            { 'rounded-none': isScrolled },
          )}
          isMobile
        >
          <div className="flex flex-1 justify-between items-center mb-2">
            <MenuIconContainer
              className="cursor-pointer flex justify-center items-center px-4"
              onClick={onSideMenuOpen}
              isMobile
            >
              <MenuIcon className="pi pi-bars p-toolbar-separator " />
            </MenuIconContainer>
            <Link to="/inApp" className="flex">
              <div className="flex justify-center items-center">
                <ImageStyled src={LogoImg} alt="Cashy" className="mr-4" />
                <BadgeStyled
                  value={process.env.REACT_APP_ENV.toUpperCase()}
                  background={
                    process.env.REACT_APP_ENV !== 'production' ? '#f50057' : ''
                  }
                />
              </div>
            </Link>
            <div className="flex justify-end">
              <ProfileDropdown
                currentUser={currentUser}
                onEmployeeChangeShop={onShowedEmployeeChangeShopPopup}
                onLogout={onLogout}
              />
            </div>
          </div>
          <div className="flex items-center flex-row">
            <div className="w-full">
              <CompanyDropdown
                companies={data?.getActiveCompanies}
                defaultValue={defaultCompany}
                companyId={companyId}
                onChangeCompany={handleChangeCompany}
                // className="lg:w-60"
              />
            </div>
            <div className="w-full ml-2">
              <LanguageDropdown />
            </div>
          </div>
        </Menu>
      )
    }
  },
)

const MenuContainer = styled.div`
  transition: all 0.3s ease;

  &.scrolled {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    top: -0.1rem;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
`

const Menu = styled.div<{ isMobile?: boolean }>`
  flex-shrink: 0;
  border: 1px solid #dee2e6;
  background: var(--horizontal-menu-background, #f8f9fa);
  padding: ${(props) => (props.isMobile ? '0.5rem 1rem' : '1rem 0.5rem')};
  @media (min-width: 992px) {
    padding: 0.5rem;
  }
`
const MenuIconContainer = styled.div<{ isMobile?: boolean }>`
  position: absolute;
  left: -0.125rem;
  left: ${(props) => (props.isMobile ? '-0.7rem' : '-0.125rem')};
  top: -0.063rem;
  bottom: 0;
  height: 100%;
  @media (max-width: 1024px) {
    position: relative;
  }
`
const MenuIcon = styled.i`
  font-size: 1.5rem;
`

const MenuTitle = styled.h5`
  color: #000;
  font-family: 'Inter';
  font-size: 1.09375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.09375rem;

  @media (max-width: 1024px) {
    display: block;
  }
  @media (max-width: 600px) {
    display: none;
  }
`

const ImageStyled = styled(Image)`
  &&& {
    width: 2.5rem;
    height: 2.5rem;
  }
`

const BadgeStyled = styled(Badge)<{ background: string }>`
  background-color: ${(props) => props.background};
`

export default Menubar
