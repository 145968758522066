import * as at from '../actionTypes'
import { createAction } from '@reduxjs/toolkit'

interface Alert {
  type: string
  message?: any
  errors?: any
  closeCallback?: () => void
}

export interface EmployeeActiveShop {
  id: string
  name: string
}

export interface UIState {
  alerts: Alert[]
  companyId: string | null
  employeeActiveShop: EmployeeActiveShop | null
  defaultRefurbedShippingProfile: string
}

export const closeModal = createAction<undefined>(at.CLOSE_MODAL)

export const showModal = createAction<Alert>(at.SHOW_MODAL)

export const switchCompany = createAction<string>(at.SWITCH_COMPANY)

export const employeeActiveShop = createAction<EmployeeActiveShop | null>(
  at.EMPLOYEE_ACTIVE_SHOP,
)

export const switchDefaultRefurbedShippingProfile = createAction<string>(
  at.SWITCH_DEFAULT_REFURBED_SHIPPING_PROFILE,
)
