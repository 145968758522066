import { MultipleLanguageText } from '@/components/MultipleLanguageInput'
import { CUSTOM_DEAL_FORM_LANGUAGES } from '@/constants'
import { CustomDealItem } from '@/domains/customDeals/helpers'
import {
  CustomDealFormQuestion,
  CustomDealFormQuestionCreateArgs,
  CustomDealFormQuestionUpdateArgs,
  CustomDealItemCreateArgs,
  CustomDealQuestionNumberRestrictionArgs,
  ECustomDealFormQuestionTitleKey,
  ECustomDealFormQuestionType,
  ELanguageCode,
  ENumberFilterConditions,
  MultipleLanguageTextArgs,
  TranslatedString,
  TranslationItem,
} from '@/schemaTypes'

export enum QUESTION_TYPE {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  // FILE_UPLOAD = 'FILE_UPLOAD',
  BOOLEAN = 'BOOLEAN',
}

export enum QUESTION_INPUT_TYPE {
  DEFAULT = 'DEFAULT',
  MULTILINE = 'MULTILINE',
  OPTIONS = 'OPTIONS',
}

export interface NumberRestriction {
  conditionOperator: ENumberFilterConditions
  conditionValue: number
  errorMessage: TranslationItem[]
}

export interface CustomDealFormOption {
  label: TranslationItem[]
  description?: TranslationItem[] | null
  default: boolean
}

export const getQuestionInputType = (type: QUESTION_TYPE) => {
  switch (type) {
    case QUESTION_TYPE.TEXT:
      return [
        QUESTION_INPUT_TYPE.DEFAULT,
        QUESTION_INPUT_TYPE.MULTILINE,
        QUESTION_INPUT_TYPE.OPTIONS,
      ]
    case QUESTION_TYPE.NUMBER:
      return [QUESTION_INPUT_TYPE.DEFAULT, QUESTION_INPUT_TYPE.OPTIONS]
    // case QUESTION_TYPE.FILE_UPLOAD:
    case QUESTION_TYPE.BOOLEAN:
    default:
      return [QUESTION_INPUT_TYPE.DEFAULT]
  }
}

export const getDisplayQuestionType = (
  type?: ECustomDealFormQuestionType,
): { type?: QUESTION_TYPE; inputType?: QUESTION_INPUT_TYPE } => {
  const result = { type: undefined, inputType: undefined }

  if (!type) return result

  switch (type) {
    case ECustomDealFormQuestionType.TextDefault:
      return {
        type: QUESTION_TYPE.TEXT,
        inputType: QUESTION_INPUT_TYPE.DEFAULT,
      }
    case ECustomDealFormQuestionType.TextMultiline:
      return {
        type: QUESTION_TYPE.TEXT,
        inputType: QUESTION_INPUT_TYPE.MULTILINE,
      }
    case ECustomDealFormQuestionType.TextOptions:
      return {
        type: QUESTION_TYPE.TEXT,
        inputType: QUESTION_INPUT_TYPE.OPTIONS,
      }
    case ECustomDealFormQuestionType.NumberDefault:
      return {
        type: QUESTION_TYPE.NUMBER,
        inputType: QUESTION_INPUT_TYPE.DEFAULT,
      }
    case ECustomDealFormQuestionType.NumberOptions:
      return {
        type: QUESTION_TYPE.NUMBER,
        inputType: QUESTION_INPUT_TYPE.OPTIONS,
      }
    // case ECustomDealFormQuestionType.FileUpload:
    //   return { type: QUESTION_TYPE.FILE_UPLOAD, inputType: QUESTION_INPUT_TYPE.DEFAULT }
    case ECustomDealFormQuestionType.Boolean:
      return {
        type: QUESTION_TYPE.BOOLEAN,
        inputType: QUESTION_INPUT_TYPE.DEFAULT,
      }
    default:
      return result
  }
}

export const createInitialMultipleLanguage = (): MultipleLanguageTextArgs[] => {
  return CUSTOM_DEAL_FORM_LANGUAGES.map((language) => ({
    languageCode: language,
    text: '',
  }))
}

// this is helpful when new languages are being added, as existing custom deal forms wont have
// entries for the new languages, but they still should be editable
export const backfillNewLanguageOptions = (
  input: MultipleLanguageText[],
): MultipleLanguageText[] => {
  const missingLanguages = CUSTOM_DEAL_FORM_LANGUAGES.filter(
    (lang) => !input.some((entry) => entry.languageCode === lang),
  )

  return [
    ...input,
    ...missingLanguages.map((language) => ({
      languageCode: language,
      text: '',
    })),
  ]
}

export const createInitialNumberRestriction = (
  restriction?: CustomDealQuestionNumberRestrictionArgs,
) => {
  if (restriction)
    return {
      conditionOperator: restriction.conditionOperator,
      conditionValue: restriction.conditionValue,
      errorMessage: backfillNewLanguageOptions(restriction.errorMessage),
    }

  return {
    conditionOperator: ENumberFilterConditions.LessThanOrEqual,
    conditionValue: 0,
    errorMessage: createInitialMultipleLanguage(),
  }
}

const QUESTION_TYPE_MAP = {
  [`${QUESTION_TYPE.TEXT}_${QUESTION_INPUT_TYPE.DEFAULT}`]:
    ECustomDealFormQuestionType.TextDefault,
  [`${QUESTION_TYPE.TEXT}_${QUESTION_INPUT_TYPE.MULTILINE}`]:
    ECustomDealFormQuestionType.TextMultiline,
  [`${QUESTION_TYPE.TEXT}_${QUESTION_INPUT_TYPE.OPTIONS}`]:
    ECustomDealFormQuestionType.TextOptions,
  [`${QUESTION_TYPE.NUMBER}_${QUESTION_INPUT_TYPE.DEFAULT}`]:
    ECustomDealFormQuestionType.NumberDefault,
  [`${QUESTION_TYPE.NUMBER}_${QUESTION_INPUT_TYPE.OPTIONS}`]:
    ECustomDealFormQuestionType.NumberOptions,
  // [`${QUESTION_TYPE.FILE_UPLOAD}_${QUESTION_INPUT_TYPE.DEFAULT}`]: ECustomDealFormQuestionType.FileUpload,
  [`${QUESTION_TYPE.BOOLEAN}_${QUESTION_INPUT_TYPE.DEFAULT}`]:
    ECustomDealFormQuestionType.Boolean,
}

export const getQuestionType = (
  type: QUESTION_TYPE,
  inputType: QUESTION_INPUT_TYPE,
): ECustomDealFormQuestionType => {
  return QUESTION_TYPE_MAP[`${type}_${inputType}`]
}

export const getConditionOperatorLabel = (
  conditionOperator: ENumberFilterConditions,
) => {
  const base = 'value '

  switch (conditionOperator) {
    case ENumberFilterConditions.Equals:
      return base + '='
    case ENumberFilterConditions.GreaterThan:
      return base + '>'
    case ENumberFilterConditions.GreaterThanOrEqual:
      return base + '>='
    case ENumberFilterConditions.LessThan:
      return base + '<'
    case ENumberFilterConditions.LessThanOrEqual:
      return base + '<='
    case ENumberFilterConditions.NotEquals:
      return base + '≠'
  }
}

export const getDefaultQuestions = (): CustomDealFormQuestionCreateArgs[] => [
  {
    type: ECustomDealFormQuestionType.TextDefault,
    label: [
      {
        languageCode: ELanguageCode.De,
        text: 'Produktname',
      },
      {
        languageCode: ELanguageCode.En,
        text: 'Product name',
      },
      {
        languageCode: ELanguageCode.Tr,
        text: 'Ürün adı',
      },
      {
        languageCode: ELanguageCode.Sr,
        text: 'Назив производа',
      },
      {
        languageCode: ELanguageCode.Ro,
        text: 'Denumirea produsului',
      },
    ],
    info: createInitialMultipleLanguage(),
    titleKey: ECustomDealFormQuestionTitleKey.ProductName,
    initialAnswerArgs: {
      textAnswer: '',
    },
    optional: false,
    showOnIssuanceCertificate: false,
    showOnSalesBill: false,
  },
  {
    type: ECustomDealFormQuestionType.TextOptions,
    label: [
      {
        languageCode: ELanguageCode.De,
        text: 'Zustand',
      },
      {
        languageCode: ELanguageCode.En,
        text: 'Condition',
      },
      {
        languageCode: ELanguageCode.Tr,
        text: 'Durum',
      },
      {
        languageCode: ELanguageCode.Sr,
        text: 'Стање',
      },
      {
        languageCode: ELanguageCode.Ro,
        text: 'Stare',
      },
    ],
    info: createInitialMultipleLanguage(),
    titleKey: ECustomDealFormQuestionTitleKey.Condition,
    initialAnswerArgs: {
      optionTextAnswer: null,
    },
    optional: false,
    options: [
      {
        label: [
          {
            languageCode: ELanguageCode.De,
            text: 'Neu',
          },
          {
            languageCode: ELanguageCode.En,
            text: 'New',
          },
          {
            languageCode: ELanguageCode.Tr,
            text: 'Yeni',
          },
          {
            languageCode: ELanguageCode.Sr,
            text: 'Ново',
          },
          {
            languageCode: ELanguageCode.Ro,
            text: 'Nou',
          },
        ],
        default: false,
      },
      {
        label: [
          {
            languageCode: ELanguageCode.De,
            text: 'Neuwertig',
          },
          {
            languageCode: ELanguageCode.En,
            text: 'Like new',
          },
          {
            languageCode: ELanguageCode.Tr,
            text: 'Yeni gibi',
          },
          {
            languageCode: ELanguageCode.Sr,
            text: 'Као нов',
          },
          {
            languageCode: ELanguageCode.Ro,
            text: 'Ca nou',
          },
        ],
        default: false,
      },
      {
        label: [
          {
            languageCode: ELanguageCode.De,
            text: 'Gut',
          },
          {
            languageCode: ELanguageCode.En,
            text: 'Good',
          },
          {
            languageCode: ELanguageCode.Tr,
            text: 'İyi',
          },
          {
            languageCode: ELanguageCode.Sr,
            text: 'Добро',
          },
          {
            languageCode: ELanguageCode.Ro,
            text: 'Bun',
          },
        ],
        default: false,
      },
      {
        label: [
          {
            languageCode: ELanguageCode.De,
            text: 'Gebraucht',
          },
          {
            languageCode: ELanguageCode.En,
            text: 'Used',
          },
          {
            languageCode: ELanguageCode.Tr,
            text: 'Kullanılmış',
          },
          {
            languageCode: ELanguageCode.Sr,
            text: 'Користи се',
          },
          {
            languageCode: ELanguageCode.Ro,
            text: 'Folosit',
          },
        ],
        default: false,
      },
    ],
    allowEnterAnswer: false,
    showOnIssuanceCertificate: false,
    showOnSalesBill: false,
  },
  {
    type: ECustomDealFormQuestionType.NumberOptions,
    label: [
      {
        languageCode: ELanguageCode.De,
        text: 'Gewünschter Betrag in €',
      },
      {
        languageCode: ELanguageCode.En,
        text: 'Desired Amount in €',
      },
      {
        languageCode: ELanguageCode.Tr,
        text: '€ cinsinden istenen miktar',
      },
      {
        languageCode: ELanguageCode.Sr,
        text: 'Жељени износ у €',
      },
      {
        languageCode: ELanguageCode.Ro,
        text: 'Suma dorită în €',
      },
    ],
    info: createInitialMultipleLanguage(),
    titleKey: ECustomDealFormQuestionTitleKey.Price,
    initialAnswerArgs: {
      optionNumberAnswer: null,
    },
    optional: false,
    options: [],
    allowEnterAnswer: true,
    restrictions: [
      {
        conditionOperator: ENumberFilterConditions.GreaterThanOrEqual,
        conditionValue: 50,
        errorMessage: [
          {
            languageCode: ELanguageCode.De,
            text: 'Leider können wir keine Gegenstände mit einem Wert unter 50€ annehmen.',
          },
          {
            languageCode: ELanguageCode.En,
            text: 'Unfortunately, we cannot accept items with a value of less than 50€.',
          },
          {
            languageCode: ELanguageCode.Tr,
            text: "Maalesef değeri 50 €'nun altında olan ürünleri kabul edemiyoruz.",
          },
          {
            languageCode: ELanguageCode.Sr,
            text: 'Нажалост, не можемо прихватити артикле чија је вредност мања од 50€.',
          },
          {
            languageCode: ELanguageCode.Ro,
            text: 'Din păcate, nu putem accepta articole cu o valoare mai mică de 50 €.',
          },
        ],
      },
    ],
    showOnIssuanceCertificate: false,
    showOnSalesBill: false,
  },
  {
    type: ECustomDealFormQuestionType.TextMultiline,
    label: [
      {
        languageCode: ELanguageCode.De,
        text: 'Zusätzliche Infos',
      },
      {
        languageCode: ELanguageCode.En,
        text: 'Additional info',
      },
      {
        languageCode: ELanguageCode.Tr,
        text: 'Ek bilgi',
      },
      {
        languageCode: ELanguageCode.Sr,
        text: 'Додатне информације',
      },
      {
        languageCode: ELanguageCode.Ro,
        text: 'Informații suplimentare',
      },
    ],
    info: createInitialMultipleLanguage(),
    titleKey: ECustomDealFormQuestionTitleKey.AdditionalInfo,
    initialAnswerArgs: {
      textAnswer: null,
    },
    optional: true,
    showOnIssuanceCertificate: false,
    showOnSalesBill: false,
  },
]

export const getAllTranslations = (
  translatedString: TranslatedString,
): MultipleLanguageTextArgs[] => {
  return translatedString.allTranslations.map((allTranslation) => ({
    text: allTranslation.text,
    languageCode: allTranslation.languageCode,
  }))
}
export const getCustomDealFormQuestionsUpdateArgs = (
  questions: CustomDealFormQuestion[],
): CustomDealFormQuestionUpdateArgs[] => {
  return questions.map((question) => ({
    ...question,
    label: getAllTranslations(question.label),
    info: getAllTranslations(question.info),
    options:
      question.__typename === 'NumberOptionsCustomDealFormQuestion'
        ? question.options?.map((option) => ({
            default: option.default,
            label: getAllTranslations(option.label),
          }))
        : question.__typename === 'TextOptionsCustomDealFormQuestion'
          ? question.options?.map((option) => ({
              default: option.default,
              description: option.description
                ? getAllTranslations(option.description)
                : null,
              label: getAllTranslations(option.label),
            }))
          : undefined,
    restrictions:
      question.__typename === 'NumberOptionsCustomDealFormQuestion'
        ? question.restrictions?.map((restriction) => ({
            conditionOperator: restriction.conditionOperator,
            conditionValue: restriction.conditionValue,
            errorMessage: getAllTranslations(restriction.errorMessage),
          }))
        : undefined,
  }))
}

export const getTranslatedString = (
  translations: MultipleLanguageTextArgs[],
  languageCode: ELanguageCode,
): TranslatedString => {
  const allTranslations = translations.map((t) => ({
    text: t.text,
    languageCode: t.languageCode,
  }))

  return {
    text:
      allTranslations.find(
        (translation) => translation.languageCode === languageCode,
      )?.text ?? '',
    allTranslations,
  }
}

export const getCustomDealItemArgs = (
  item: CustomDealItem,
): CustomDealItemCreateArgs => {
  return {
    ...item,
    answers: item.answers?.map(({ __typename, ...answer }) => answer) ?? [],
    formAnswers: item.formAnswers?.map((formAnswer) => {
      const options =
        formAnswer.question.__typename === 'NumberOptionsCustomDealFormQuestion'
          ? formAnswer.question.options?.map((option) => ({
              ...option,
              label: getAllTranslations(option.label),
            }))
          : formAnswer.question.__typename ===
              'TextOptionsCustomDealFormQuestion'
            ? formAnswer.question.options?.map((option) => ({
                ...option,
                description: option.description
                  ? getAllTranslations(option.description)
                  : null,
                label: getAllTranslations(option.label),
              }))
            : undefined

      const restrictions =
        formAnswer.question.__typename === 'NumberOptionsCustomDealFormQuestion'
          ? formAnswer.question.restrictions?.map((restriction) => ({
              ...restriction,
              errorMessage: getAllTranslations(restriction.errorMessage),
            }))
          : null

      return {
        ...formAnswer,
        question: {
          ...formAnswer.question,
          label: getAllTranslations(formAnswer.question.label),
          info: getAllTranslations(formAnswer.question.info),
          options,
          restrictions,
        },
      }
    }),
  }
}
