export const verifyToken = (token: string | null): Boolean => {
  if (!token) return false
  const splitted = token.split('.')
  if (splitted.length !== 3) return false
  let isValid = false
  try {
    const text = atob(splitted[1])
    const j = JSON.parse(text)
    if (!j.exp) return false
    if (j.exp * 1000 < new Date().getTime()) return false
    isValid = true
    return true
  } catch (e) {
    console.log(e)
  }
  return isValid
}
