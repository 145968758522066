import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import styles from '@/domains/storageFacilities/components/StorageFacilitiesConfiguration.module.scss'
import StorageFacilitiesRow from '@/domains/storageFacilities/components/StorageFacilitiesRow'
import {
  useDeactivateStorageFacility,
  useGetActiveStorageFacilities,
} from '@/domains/storageFacilities/hooks'
import { useConfirmDeleteMutationShowingErrors } from '@/hooks'

export function StorageFacilitiesTable() {
  const { t } = useTranslation()

  const { storageFacilities, queryResult } = useGetActiveStorageFacilities()

  const deactivateStorageFacility = useConfirmDeleteMutationShowingErrors({
    successMessage: t('storage.storage_facility_deleted'),
    mutation: useDeactivateStorageFacility(),
  })

  const _handleDeleteClick = async (id: string) => {
    deactivateStorageFacility({
      variables: {
        storageFacilityId: id,
      },
      onCompleted: () => {
        queryResult.refetch()
      },
    })
  }

  return (
    <Paper className={`${styles.root} u-mb`}>
      <ConfigurationBreadcrumbs modelName={'storage.storage_facilities'} />
      <Typography variant="h5" gutterBottom>
        {t('storage.storage_facilities_configurations')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Table className="u-mb-sm" size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('id')}</TableCell>
                <TableCell align="right">{t('name')}</TableCell>
                <TableCell align="right">{t('external')}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(storageFacilities).map((sfKey) => (
                <StorageFacilitiesRow
                  key={sfKey}
                  storageFacility={storageFacilities[sfKey]}
                  deleteStorageFacility={() =>
                    _handleDeleteClick(storageFacilities[sfKey]._id)
                  }
                />
              ))}
            </TableBody>
          </Table>
          <Link to="/inApp/configurations/storageFacilities/create">
            <Button className="u-mr-mini" variant="contained">
              {t('storage.storage_facility_create')}
            </Button>
          </Link>
        </Fragment>
      )}
    </Paper>
  )
}

export default StorageFacilitiesTable
