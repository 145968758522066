import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { itemFragments } from '@/domains/deals/hooks'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationUpdateItemEventArgs } from '@/schemaTypes'

const UPDATE_ITEM_EVENT = gql`
  mutation updateItemEvent(
    $itemId: ObjectId!
    $itemEventUpdateArgs: ItemEventUpdateArgs!
  ) {
    updateItemEvent(
      itemId: $itemId
      itemEventUpdateArgs: $itemEventUpdateArgs
    ) {
      _id
      serialNumber
      imei
      itemSpecification
      note
      policeNumber
      isDamageCase
      isForPersonalUse
      damageCasePeriods {
        from
        to
      }
      personalUsePeriods {
        from
        to
      }
      isInvestmentGold
      entrupyCertificateUrl
      checkedBy
      checkedAt
      storageFacilityId
      events {
        ...ItemEventFragment
      }
      vehicleData {
        vehicleProperties {
          make
          model
          regdate
          body
          facelift
          seats
          engine
          transmission
          odometer
          otherVehicleProperties {
            value
            name
          }
        }
        vin
        indicataId
        carPriceLastFetched
        vinLastFetched
        hasTypeCertificate
        hasApprovalCertificate
        has57aGutachten
        has57aGutachtenDue
        hasSecondKey
        hasSaleContract
      }
      checkedAfterAuction
    }
  }
  ${fragments.fileMetadata}
  ${itemFragments.itemEvent}
`

type TData = { updateItemEvent: Mutation['updateItemEvent'] }
type TVariables = MutationUpdateItemEventArgs

export function useUpdateItemEvent(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateItemEvent] = useMutation<TData, TVariables>(
    UPDATE_ITEM_EVENT,
    opts,
  )

  return updateItemEvent
}
