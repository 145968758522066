import { GetParentCategories } from '../CategorySelector/GetParentCategories'
import { Chip, TextField } from '@material-ui/core'
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete'
import { AutocompleteRenderGetTagProps } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EVisibleOnDomain, ItemCategory } from '@/schemaTypes'

export interface CategorySelectorProps {
  onChange?: (itemCategoryIds: string[]) => void
  categories: ItemCategory[]
  currentCategories: ItemCategory[]
  disableClear?: boolean
  initialCategorieIds?: string[]
}

export function CategorySelectorWithMultiple(props: CategorySelectorProps) {
  const {
    categories,
    onChange,
    currentCategories,
    disableClear = false,
    initialCategorieIds,
  } = props

  const options = useMemo(
    (): Option[] => categories.map(buildOption),
    [categories],
  )

  const selectedOptions = useMemo((): Option[] => {
    if (!currentCategories || currentCategories.length === 0) {
      return []
    }

    const selectedOptionIds = new Set(currentCategories.map((c) => c._id))
    return options.filter((o) => selectedOptionIds.has(o.id))
  }, [options, currentCategories])

  const { t } = useTranslation()
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField {...params} label={t('category')} variant="outlined" />
    ),
    [t],
  )

  const renderTags = useCallback(
    (options: Option[], getTagProps: AutocompleteRenderGetTagProps) => {
      return options.map((option, index) => {
        const isDisabled =
          disableClear && initialCategorieIds?.includes(option.id)
        return (
          <Chip
            key={option.id}
            label={getCategoryLabel(option.id, categories)}
            {...getTagProps({ index })}
            onDelete={isDisabled ? undefined : getTagProps({ index }).onDelete}
            disabled={isDisabled}
          />
        )
      })
    },
    [categories, disableClear, initialCategorieIds],
  )

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => getCategoryLabel(option.id, categories)}
      renderOption={(props) => (
        <li
          style={{
            color:
              props.visibleOnDomain === EVisibleOnDomain.None
                ? 'var(--darkGrey)'
                : 'inherit',
          }}
        >
          {getCategoryLabel(props.id, categories)}
        </li>
      )}
      style={style}
      onChange={(_, value, reason, details) => {
        if (reason === 'remove-option' && disableClear && details?.option) {
          const isInitialCategory = initialCategorieIds.includes(
            details.option.id,
          )
          if (isInitialCategory) {
            return
          }
        }
        onChange?.(((value as Option[]) ?? []).map((v) => v.id))
      }}
      renderInput={renderInput}
      renderTags={renderTags}
      value={selectedOptions}
      disableClearable={disableClear}
    />
  )
}

export default CategorySelectorWithMultiple

interface Option {
  id: string
  label: string
  visibleOnDomain?: EVisibleOnDomain | null
}

const getCategoryLabel = (optionId, categories) => {
  return GetParentCategories(optionId, categories)
    .map((category) => category.name)
    .reverse()
    .join(' -> ')
}

const buildOption = (category: ItemCategory) => ({
  id: category._id,
  label: category.name,
  visibleOnDomain: category.visibleOnDomain,
})

const style = { width: 600 }
