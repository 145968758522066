import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import {
  Mutation,
  MutationUpdatePrivateSaleTransactionArgs,
} from '@/schemaTypes'

const UPDATE_PRIVATE_SALE_TRANSACTION = gql`
  mutation updatePrivateSaleTransaction(
    $privateSaleId: ObjectId!
    $args: PrivateSaleTransactionArgs!
    $transactionOrder: Float!
  ) {
    updatePrivateSaleTransaction(
      privateSaleId: $privateSaleId
      args: $args
      transactionOrder: $transactionOrder
    ) {
      _id
      createdAt
      creationType
      privateSaleDate
      dealId
      itemId
      bookingNumber
      carTitle
      customerFullName
      status
      dealFinalBalance
      privateSaleTransactions {
        ...PrivateSaleTransactionFragment
      }
    }
  }
  ${fragments.privateSaleTransaction}
`

type TData = {
  updatePrivateSaleTransaction: Mutation['updatePrivateSaleTransaction']
}
type TVariables = MutationUpdatePrivateSaleTransactionArgs

export function useUpdatePrivateSaleTransaction(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updatePrivateSaleTransaction] = useMutation<TData, TVariables>(
    UPDATE_PRIVATE_SALE_TRANSACTION,
    opts,
  )
  return updatePrivateSaleTransaction
}
