import { useUpdateItemEvent } from '../../hooks/itemDetails/updateItemEvent'
import { calculateMargin } from '../ItemElement'
import styles from '../ItemsList.module.scss'
import styled from '@emotion/styled'
import Tooltip from '@material-ui/core/Tooltip'
import ErrorIcon from '@material-ui/icons/Error'
import dayjs from 'dayjs'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { Context } from '@/context'
import BuyBoxNotWonIcon from '@/images/buyboxNotWon.svg'
import BuyBoxWonIcon from '@/images/buyboxWon.svg'
import {
  EItemStatusType,
  ERefurbedBuyBoxState,
  Item,
  ItemEvent,
  ItemEventMetaArgs,
} from '@/schemaTypes'
import { REFURBED_SOLD_OVER_TIME } from '@/utils/pawnCalculation'
import { getNow } from '@/utils/time'
import { EditableItemOfferedPrice } from './ItemOfferedPrice'

type EditableItemRefurbedOfferPriceProps = {
  item: Item
  itemEvent: ItemEvent
}

export const EditableItemRefurbedOfferPrice = ({
  item,
  itemEvent,
}: EditableItemRefurbedOfferPriceProps) => {
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const updateItemEvent = useUpdateItemEvent()

  const updateRefurbedItemPrice =
    (fieldName: string) => async (price: number) => {
      const meta: ItemEventMetaArgs = {
        refurbedOffer: {
          ...(fieldName === 'offeredPrice' && { offeredPrice: price }),
          ...(fieldName === 'lowestPrice' && { lowestPrice: price }),
        },
      }
      try {
        await updateItemEvent({
          variables: {
            itemId: item._id,
            itemEventUpdateArgs: {
              event: EItemStatusType.OfferedToSell,
              meta,
            },
          },
        })
        showInfo(
          t('refurbed_offer_price_updated', {
            storageLabel: item.storageLabel,
          }),
        )
      } catch (err) {
        showInfo(
          t('refurbed_offer_price_update_failed', {
            storageLabel: item.storageLabel,
          }),
        )
      }
    }

  const offeredAt =
    itemEvent.meta?.refurbedOffer?.updatedAt || itemEvent.createdAt
  const adsDuration = dayjs(getNow()).diff(offeredAt, 'days')
  const isOverTime = adsDuration > REFURBED_SOLD_OVER_TIME
  const isWonableBuybox = [
    ERefurbedBuyBoxState.Won,
    ERefurbedBuyBoxState.ShouldWin,
  ].includes(
    itemEvent.meta?.refurbedOffer?.bestBuyBox?.state as ERefurbedBuyBoxState,
  )

  return (
    <div className={styles.salePlatforms}>
      <AdsTitleWrapper>
        <ItemRefurbedOfferPriceText>REFURBED</ItemRefurbedOfferPriceText>
        <IconWrapper>
          <Tooltip
            title={`Buybox: ${itemEvent.meta?.refurbedOffer?.bestBuyBox?.state}`}
            placement="right"
          >
            <ReactSVG
              src={isWonableBuybox ? BuyBoxWonIcon : BuyBoxNotWonIcon}
            />
          </Tooltip>
        </IconWrapper>
      </AdsTitleWrapper>
      <div className={styles.flexCenterWrapper}>
        <div className={styles.offerDate}>
          Offered at: {dayjs(offeredAt).format('DD.MM.YYYY')}
        </div>
        {!isWonableBuybox && isOverTime && (
          <Tooltip
            title={t('over_expected_turnover_time', { numDays: adsDuration })}
          >
            <ErrorIcon className={styles.warningIcon} />
          </Tooltip>
        )}
      </div>
      <div className={styles.flexCenterWrapper}>
        <EditableItemOfferedPrice
          item={item}
          itemOfferedPrice={itemEvent?.meta?.refurbedOffer?.offeredPrice || 0}
          onSave={updateRefurbedItemPrice('offeredPrice')}
        />
        {item.internalSaleData?.price && (
          <div className={styles.margin}>
            {calculateMargin(
              itemEvent?.meta?.refurbedOffer?.offeredPrice || 0,
              item.internalSaleData.price,
            )}
          </div>
        )}
      </div>
      <div className={[styles.flexCenterWrapper, styles.grayColor].join(' ')}>
        <EditableItemOfferedPrice
          item={item}
          itemOfferedPrice={itemEvent?.meta?.refurbedOffer?.lowestPrice || 0}
          onSave={updateRefurbedItemPrice('lowestPrice')}
        />
        {item.internalSaleData?.price && (
          <div className={styles.margin}>
            {calculateMargin(
              itemEvent?.meta?.refurbedOffer?.lowestPrice || 0,
              item.internalSaleData.price,
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const ItemRefurbedOfferPriceText = styled.p`
  color: #673aba;
`

const IconWrapper = styled.div`
  margin-top: 2px;
  svg {
    width: 16px;
    height: 16px;
  }
`

const AdsTitleWrapper = styled.div`
  display: flex;
  gap: 5px;
`
