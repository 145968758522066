import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationSetMainStorageFacilityIdArgs } from '@/schemaTypes'

const SET_MAIN_STORAGE_FACILITY_ID = gql`
  mutation setMainStorageFacilityId(
    $shopId: ObjectId!
    $storageFacilityId: ObjectId
  ) {
    setMainStorageFacilityId(
      shopId: $shopId
      storageFacilityId: $storageFacilityId
    ) {
      _id
      name
    }
  }
`

type TData = { setMainStorageFacilityId: Mutation['setMainStorageFacilityId'] }
type TVariables = MutationSetMainStorageFacilityIdArgs

export function useSetMainStorageFacilityId(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [setMainStorageFacilityId] = useMutation<TData, TVariables>(
    SET_MAIN_STORAGE_FACILITY_ID,
    opts,
  )

  return setMainStorageFacilityId
}
