import { Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import CompaniesDetails from '@/domains/companies/components/CompaniesDetails'
import { useCreateCompany, useGetCompany } from '@/domains/companies/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { CompanyCreateArgs, PrimaryShopLocationArgs } from '@/schemaTypes'

export interface CompaniesCreateProps {
  companyId: string | null
}

export function CompaniesCreate(props: CompaniesCreateProps) {
  const { companyId } = props
  const { t } = useTranslation()
  const history = useHistory()

  const { company, queryResult } = useGetCompany({
    variables: {
      companyId: companyId ?? '',
    },
  })

  const createCompany = useMutationShowingErrors({
    mutation: useCreateCompany(),
    successMessage: t('company.company_created'),
  })

  const onSave = (
    company: CompanyCreateArgs,
    primaryShopLocation: PrimaryShopLocationArgs,
  ) => {
    createCompany({
      variables: {
        company: {
          ...company,
          primaryShopLocation,
        },
      },
      onCompleted: (data: any) => {
        history.replace(
          `/inApp/configurations/companies/edit/${data.createCompany._id}`,
        )
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={companyId ?? ''}
        modelName={'company.plural'}
        mode={'create'}
      />
      <Typography variant="h5" gutterBottom>
        {t('company.company_create')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        <CompaniesDetails company={company} onSave={onSave} />
      )}
    </Paper>
  )
}

export default CompaniesCreate
