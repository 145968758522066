import styled from '@emotion/styled'
import { InputAdornment, TextField, Typography } from '@material-ui/core'
import React from 'react'
import deFlag from '@/images/flags/de.svg'
import enFlag from '@/images/flags/en.svg'
import roFlag from '@/images/flags/ro.svg'
import srFlag from '@/images/flags/sr.svg'
import trFlag from '@/images/flags/tr.svg'
import { ELanguageCode } from '@/schemaTypes'
import { useTranslationContext } from './LanguagePickerContext'

export interface MultipleLanguageText {
  languageCode: ELanguageCode
  text: string
}
interface MultipleLanguageInputProps {
  type?: string
  values: MultipleLanguageText[]
  onChange?: (e: MultipleLanguageText[]) => void
  className?: string
}

// flags taken from: https://github.com/lipis/flag-icons
const FLAG_MAP = {
  [ELanguageCode.De]: <img src={deFlag} alt="" />,
  [ELanguageCode.En]: <img src={enFlag} alt="" />,
  [ELanguageCode.Tr]: <img src={trFlag} alt="" />,
  [ELanguageCode.Sr]: <img src={srFlag} alt="" />,
  [ELanguageCode.Ro]: <img src={roFlag} alt="" />,
}

export const MultipleLanguageInput = (props: MultipleLanguageInputProps) => {
  const { values, type = 'text', onChange, className } = props
  const { languages } = useTranslationContext()

  const handleChange = (text: string, index: number) => {
    onChange?.(
      values.map((value, i) => {
        if (i !== index) return value
        return { ...value, text }
      }),
    )
  }

  return (
    <MultipleLanguageInputWapper className={className}>
      {values.map((value, i) => {
        if (!languages.includes(value.languageCode)) return
        return (
          <Input
            key={`MultipleLanguageInput${value.languageCode} `}
            type={type}
            value={value.text}
            onChange={(e) => handleChange(e.target.value, i)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {FLAG_MAP[value.languageCode]}
                </InputAdornment>
              ),
            }}
          />
        )
      })}
    </MultipleLanguageInputWapper>
  )
}

interface MultipleLanguageInputWithDescriptionProps {
  type?: string
  values: MultipleLanguageText[]
  onChange?: (e: MultipleLanguageText[]) => void
  className?: string
  descriptions: MultipleLanguageText[] | null
  onChangeDescription: (e: MultipleLanguageText[]) => void
}

export const MultipleLanguageInputWithDescription = (
  props: MultipleLanguageInputWithDescriptionProps,
) => {
  const {
    values,
    type = 'text',
    onChange,
    className,
    descriptions,
    onChangeDescription,
  } = props
  const { languages } = useTranslationContext()

  const handleChangeLabel = (text: string, index: number) => {
    onChange?.(
      values.map((value, i) => {
        if (i !== index) return value
        return { ...value, text }
      }),
    )
  }

  const handleChangeDescription = (text: string, index: number) => {
    if (!descriptions) return

    onChangeDescription(
      descriptions.map((value, i) => {
        if (i !== index) return value
        return { ...value, text }
      }),
    )
  }

  return (
    <MultipleLanguageInputWithDescriptionWapper className={className}>
      {values.map((value, i) => {
        if (!languages.includes(value.languageCode)) return
        const description = descriptions?.find(
          (d) => d.languageCode === value.languageCode,
        )
        return (
          <div
            key={`${i}`}
            style={{
              borderBottom:
                i !== values.length - 1
                  ? 'solid 1px rgba(224, 224, 224, 1)'
                  : 'none',
            }}
          >
            <Input
              type={type}
              value={value.text}
              onChange={(e) => handleChangeLabel(e.target.value, i)}
              fullWidth
              style={{ height: 30, marginTop: -5, marginLeft: 10 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {FLAG_MAP[value.languageCode]}
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
            />
            <Input
              type={type}
              style={{ height: 30, marginTop: -10, marginLeft: 10 }}
              value={description?.text}
              onChange={(e) => handleChangeDescription(e.target.value, i)}
              fullWidth
              InputProps={{
                startAdornment: (
                  <Typography style={{ fontSize: 12, width: 80 }}>
                    Description:
                  </Typography>
                ),
                disableUnderline: true,
              }}
            />
          </div>
        )
      })}
    </MultipleLanguageInputWithDescriptionWapper>
  )
}

const MultipleLanguageInputWapper = styled.div`
  margin-bottom: 1rem;
`

const MultipleLanguageInputWithDescriptionWapper = styled.div`
  border: solid 1px rgba(224, 224, 224, 1);
  border-radius: 5px;
`

const Input = styled(TextField)`
  > div {
    padding: 0.5rem;
  }
`
