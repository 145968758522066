import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationCancelCashBookCashFlowArgs } from '@/schemaTypes'

const CANCEL_CASH_BOOK_CASH_FLOW = gql`
  mutation cancelCashBookCashFlow($cashBookCashFlowId: ObjectId!) {
    cancelCashBookCashFlow(cashBookCashFlowId: $cashBookCashFlowId) {
      cancels {
        ...CashBookCashFlowFragment
      }
      canceled {
        ...CashBookCashFlowFragment
      }
    }
  }
  ${fragments.cashBookCashFlow}
  ${fragments.fileMetadata}
`

type TData = { cancelCashBookCashFlow: Mutation['cancelCashBookCashFlow'] }
type TVariables = MutationCancelCashBookCashFlowArgs

export function useCancelCashBookCashFlow(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [cancelCashBookCashFlow] = useMutation<TData, TVariables>(
    CANCEL_CASH_BOOK_CASH_FLOW,
    opts,
  )

  return cancelCashBookCashFlow
}
