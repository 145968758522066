import LoadingSpinner from '../LoadingSpinner'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  TableCell,
  TableRow,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import SyncIcon from '@material-ui/icons/Sync'
import WarningIcon from '@material-ui/icons/Warning'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'
import { isEqual, pick } from 'lodash'
import omit from 'lodash/omit'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import {
  BuyerBillingAddress,
  ECountry,
  ELogisticsPartnerType,
  EReturnReasons,
  ESalesPartnerType,
  IAddress,
  Item,
  OLogisticsPartnerData,
  OSalesPartnerData,
  PaymentData,
  ValorizationHistoryEntry as ValorizationHistoryEntryModel,
} from '@/schemaTypes'
import { OAddress } from '@/schemaTypes'
import {
  isCreditNote,
  paymentTypeUnion,
  salesPartnerNotConnectedToCashbooks,
  shouldPaymentTypeConnectToCashBook,
} from '@/utils/misc'
import { addDays } from '@/utils/time'
import styles from './ValorizationHistoryEntry.module.scss'
import ValorizationHistoryEntryBuyerAddressData from './ValorizationHistoryEntryBuyerAddressData'
import ValorizationHistoryEntryGeneralData from './ValorizationHistoryEntryGeneralData'
import ValorizationHistoryLogisticsPartnerData from './ValorizationHistoryEntryLogisticsPartnerData'
import ValorizationHistoryEntryPaymentData from './ValorizationHistoryEntryPaymentData'
import ValorizationHistorySalesPartnerData from './ValorizationHistoryEntrySalesPartnerData'

const columnWidths = { label: 230, value: 280 }

const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)

enum EValorizationCashbookWarning {
  ALREADY_CONNECTED_TO_CASHBOOK = 'already_connected_to_cashbook',
  CURRENT_CASHBOOK_RECORD_WILL_BE_CANCELLED = 'current_cashbook_record_will_be_cancelled',
  NEW_CASHBOOK_RECORD_WILL_BE_CREATED = 'new_cashbook_record_will_be_created',
  NEW_CASHBOOK_RECORD_WILL_NOT_BE_CREATED = 'new_cashbook_record_will_not_be_created',
}

export interface ValorizationHistoryEntryProps {
  item: Item
  entry: ValorizationHistoryEntryModel
  saveEntry: (updateData: Partial<ValorizationHistoryEntryModel>) => void
  deleteEntry: () => void
  removeValorizationEntry: (valorizationHistoryId) => void
  saved: boolean
  disabled?: boolean
  loading?: boolean
  deleteSavedValorizationInProgress
  valorizationIndex
  itemValorizationHistoryEntries
  onUpdateSavedValorizationHistoryEntry: (
    updateData: Partial<ValorizationHistoryEntryModel>,
  ) => void
  onReGenerateFinalReceipt: (valorizationId) => void
}

function ValorizationHistoryEntry(props: ValorizationHistoryEntryProps) {
  const {
    item,
    entry: persistedEntry,
    disabled,
    saved,
    saveEntry,
    deleteEntry,
    removeValorizationEntry,
    loading,
    deleteSavedValorizationInProgress,
    valorizationIndex,
    itemValorizationHistoryEntries,
    onUpdateSavedValorizationHistoryEntry,
    onReGenerateFinalReceipt,
  } = props
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const [open, setOpen] = useState(!saved ? true : false)
  const [entry, setEntry] = useState(persistedEntry)

  const defaultBuyerAddress = {
    ...(persistedEntry.buyerShippingAddress ?? {}),
    uid: persistedEntry.buyerBillingAddress?.uid,
  }

  const getDefaultSalesPartnerdata = useCallback(() => {
    return {
      ...persistedEntry.salesPartnerData,
      refurbed:
        persistedEntry.salesPartnerData.type === ESalesPartnerType.Refurbed
          ? pick(persistedEntry.salesPartnerData.refurbed, [
              'ID',
              'quantity',
              'shippingCosts',
              'warranty',
            ])
          : null,
    }
  }, [persistedEntry.salesPartnerData])

  const [buyerAddress, setBuyerAddress] = useState<
    OAddress & { uid?: string | null }
  >(defaultBuyerAddress)

  const [buyerBillingAddress, setBuyerBillingAddress] = useState(
    persistedEntry.buyerBillingAddress ?? {},
  )
  const [paymentData, setPaymentData] = useState(
    persistedEntry.paymentData ?? null,
  )
  const [salesPartnerData, setSalesPartnerData] = useState(
    getDefaultSalesPartnerdata(),
  )
  const [logisticsPartnerData, setLogisticsPartnerData] = useState(
    persistedEntry.logisticsPartnerData,
  )
  const [billingAddressDiffers, setBillingAddressDiffers] = useState(
    !isEqual(entry.buyerShippingAddress, entry.buyerBillingAddress),
  )
  const salesPartnersList = Object.values(ESalesPartnerType).map((partner) =>
    partner.replace('_', '').toLowerCase(),
  )
  const onSaveEntry = () => {
    if (isCreditNote(entry.cashflow) && !entry.isItemReturned) {
      return showInfo(
        t(
          'valorization_entry.valorization_entry_is_item_returned_should_be_set',
        ),
      )
    } else if (entry.sendBill && !entry.buyerEmail) {
      return showInfo(t('valorization_entry_buyer_email_is_not_set'))
    } else if (entry.buyerEmail && !emailRegex.test(entry.buyerEmail)) {
      return showInfo(t('valorization_entry_buyer_email_is_invalid'))
    } else if (isCreditNote(entry.cashflow) && !entry.relatedBillId) {
      return showInfo(
        t('valorization_entry.valorization_entry_bill_number_should_be_set'),
      )
    } else if (typeof entry.cashflow !== 'number' || !entry.date) {
      return showInfo(t('valorization_entry_missing_cashflow_or_date_warn'))
    } else if (
      !isCreditNote(entry.cashflow) &&
      (entry.valorizationBillNumber ?? '') !==
        (persistedEntry.valorizationBillNumber ?? '')
    ) {
      return showInfo(t('valorization_bill_number_cannot_be_changed_manually'))
    } else if (
      entry.cashflow &&
      entry.cashflow < 0 &&
      entry.returnReason === EReturnReasons.Other &&
      !entry.returnReasonDetails
    ) {
      return showInfo(
        t('valorization_return_reason_while_other_cannot_be_empty'),
      )
    } else if (
      salesPartnerData &&
      !salesPartnerNotConnectedToCashbooks.includes(salesPartnerData.type) &&
      !paymentData
    ) {
      return showInfo(t('valorization_payment_type_cannot_be_empty'))
    }

    saveEntry({
      ...entry,
      buyerShippingAddress: omit(buyerAddress, 'uid'),
      buyerBillingAddress: billingAddressDiffers
        ? buyerBillingAddress
        : (buyerAddress as unknown as BuyerBillingAddress),
      paymentData,
      salesPartnerData,
      logisticsPartnerData,
    })
  }

  const onResetEntry = useCallback(() => {
    setEntry(persistedEntry)
    setBuyerAddress(defaultBuyerAddress)
    setBuyerBillingAddress(persistedEntry.buyerBillingAddress ?? {})
    setPaymentData(persistedEntry.paymentData ?? null)
    setSalesPartnerData(getDefaultSalesPartnerdata())
    setLogisticsPartnerData(persistedEntry.logisticsPartnerData)
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedEntry])

  useEffect(() => {
    onResetEntry()
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedEntry])

  const getCountries = useMemo(() => {
    const countries = t('COUNTRY_CODES', {
      returnObjects: true,
    })
    return Object.keys(countries).map((countryKey: ECountry) => ({
      label: countries[countryKey],
      value: countryKey,
    }))
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t('COUNTRY_CODES')])

  const onChangeBillingAddressDiffers = useCallback((value: boolean) => {
    setBillingAddressDiffers(value)
  }, [])

  const onChangeEntry = useCallback(
    (updateEntry: Partial<ValorizationHistoryEntryModel>) => {
      setEntry({
        ...entry,
        ...updateEntry,
        buyerEmail: updateEntry.buyerEmail?.trim(),
      })
    },
    [entry],
  )

  const onChangePaymentData = useCallback(
    (updateData: Partial<PaymentData> | null) => {
      if (updateData && typeof updateData.paymentType !== 'undefined') {
        setPaymentData({
          ...updateData,
          paymentType: updateData.paymentType,
        })
      } else {
        setPaymentData(null)
      }
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentData],
  )

  const onChangeShippingAddressData = useCallback(
    (updateData: IAddress & { uid?: string }) => {
      setBuyerAddress(updateData)
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buyerAddress],
  )

  const onChangeBillingAddressData = useCallback(
    (updateData: BuyerBillingAddress) => {
      setBuyerBillingAddress(updateData)
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buyerBillingAddress],
  )

  // create an obj that return nulled values except selected sale partner
  const convertUnwantedOptions = (currentSelected: string) => {
    return salesPartnersList
      .filter(
        (partner) =>
          currentSelected.replace('_', '').toLocaleLowerCase() !== partner,
      )
      .reduce((acc, curr) => ((acc[curr] = null), acc), {})
  }

  const onChangeSalesPartnerData = useCallback(
    (updateData: OSalesPartnerData) => {
      const unwantedOptions: any = convertUnwantedOptions(updateData.type)

      // filter updateData, remove empty, not necessary values
      const filteredData = Object.fromEntries(
        Object.entries(updateData).filter(
          ([_, v]) => v != null && Object.keys(v).length !== 0,
        ),
      )

      // merge 2 objs to make final data
      setSalesPartnerData({ ...filteredData, ...unwantedOptions })
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [salesPartnerData],
  )

  useEffect(() => {
    handleCashbookWarning()
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesPartnerData?.type, paymentData?.paymentType])

  const onChangeLogisticsPartnerData = useCallback(
    (updateData: OLogisticsPartnerData) => {
      if (updateData.type === ELogisticsPartnerType.Cashy)
        updateData.logsta = null
      else if (updateData.type === ELogisticsPartnerType.Logsta)
        updateData.cashy = null
      setLogisticsPartnerData(updateData)
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logisticsPartnerData],
  )

  const onUpdateSavedValorizationEntry = () => {
    if (entry.cashflow !== persistedEntry.cashflow) {
      return showInfo(
        t('valorization_entry.valorization_entry_cash_flow_cannot_be_changed'),
      )
    } else if (entry.isItemReturned !== persistedEntry.isItemReturned) {
      return showInfo(
        t(
          'valorization_entry.valorization_entry_is_item_returned_cannot_be_changed',
        ),
      )
    } else if (isCreditNote(entry.cashflow) && !entry.valorizationBillNumber) {
      return showInfo(t('valorization_entry_bill_number_should_be_set'))
    } else if (typeof entry.cashflow !== 'number' || !entry.date) {
      return showInfo(t('VALORIZATION_ENTRY_MISSING_CASHFLOW_OR_DATE_WARN'))
    } else if (
      !isCreditNote(entry.cashflow) &&
      isCreditNote(persistedEntry.cashflow)
    ) {
      return showInfo(t('valorization_credit_note_cannot_be_changed_to_bill'))
    } else if (
      isCreditNote(entry.cashflow) &&
      !isCreditNote(persistedEntry.cashflow)
    ) {
      return showInfo(t('valorization_bill_cannot_be_changed_to_credit_note'))
    } else if (
      !isCreditNote(entry.cashflow) &&
      (entry.valorizationBillNumber ?? '') !==
        (persistedEntry.valorizationBillNumber ?? '')
    ) {
      return showInfo(t('VALORIZATION_BILL_NUMBER_CANNOT_BE_CHANGED_MANUALLY'))
    } else if (
      entry.cashflow &&
      entry.cashflow < 0 &&
      entry.returnReason === EReturnReasons.Other &&
      !entry.returnReasonDetails
    ) {
      return showInfo(
        t('VALORIZATION_RETURN_REASON_WHILE_OTHER_CANNOT_BE_EMPTY'),
      )
    } else if (entry.isItemReturned && entry.isTaxFreeTransaction) {
      return showInfo(
        t('partial_refund_and_tax_free_cannot_be_set_to_true_at_the_same_time'),
      )
    }

    onUpdateSavedValorizationHistoryEntry({
      ...entry,
      buyerShippingAddress: omit(buyerAddress, 'uid'),
      buyerBillingAddress: billingAddressDiffers
        ? buyerBillingAddress
        : (buyerAddress as unknown as BuyerBillingAddress),
      paymentData,
      salesPartnerData,
      logisticsPartnerData,
    })
    setOpen(false)
  }

  const onReGenerateFile = (valorizationEntryId) => {
    onReGenerateFinalReceipt(valorizationEntryId)
  }

  const isReadonly =
    (saved &&
      ((entry.valorizationBillLink &&
        entry.valorizationBillLink.url.length > 0) ||
        (entry.valorizationCreditNoteLink &&
          entry.valorizationCreditNoteLink.url.length > 0))) ??
    false

  const isPaymentTypeReadonly =
    isReadonly && addDays(dayjs(entry.createdAt).toDate(), 1) < dayjs().toDate()

  const getCashbookWarnings = () => {
    const warnings: string[] = []

    const newSalePartner = salesPartnerData?.type
    const newPaymentType = paymentData?.paymentType

    if (entry.cashFlowId) {
      warnings.push(
        t(EValorizationCashbookWarning.ALREADY_CONNECTED_TO_CASHBOOK),
      )
    }

    if (
      salesPartnerNotConnectedToCashbooks.includes(newSalePartner) ||
      !shouldPaymentTypeConnectToCashBook(paymentTypeUnion(newPaymentType))
    ) {
      if (entry.cashFlowId) {
        warnings.push(
          t(
            EValorizationCashbookWarning.CURRENT_CASHBOOK_RECORD_WILL_BE_CANCELLED,
          ),
        )
      }
      warnings.push(
        t(EValorizationCashbookWarning.NEW_CASHBOOK_RECORD_WILL_NOT_BE_CREATED),
      )
    } else if (
      !salesPartnerNotConnectedToCashbooks.includes(newSalePartner) &&
      shouldPaymentTypeConnectToCashBook(paymentTypeUnion(newPaymentType))
    ) {
      if (newPaymentType !== persistedEntry.paymentData?.paymentType) {
        if (entry.cashFlowId) {
          warnings.push(
            t(
              EValorizationCashbookWarning.CURRENT_CASHBOOK_RECORD_WILL_BE_CANCELLED,
            ),
          )
        }
        warnings.push(
          t(EValorizationCashbookWarning.NEW_CASHBOOK_RECORD_WILL_BE_CREATED),
        )
      } else if (
        !entry.cashFlowId &&
        newSalePartner !== persistedEntry.salesPartnerData?.type
      ) {
        warnings.push(
          t(EValorizationCashbookWarning.NEW_CASHBOOK_RECORD_WILL_BE_CREATED),
        )
      }
    }
    return warnings
  }

  const [cashbookWarnings, setCashbookWarnings] = useState<string[]>(
    getCashbookWarnings(),
  )

  const handleCashbookWarning = () => {
    const warnings = getCashbookWarnings()
    setCashbookWarnings(warnings)
  }

  return (
    <React.Fragment>
      <TableRow style={{ backgroundColor: '#ede7f8' }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {entry &&
          entry.itemsIds &&
          entry.itemsIds.length > 0 &&
          entry.itemsIds[0].toString() !== item._id.toString() ? (
            <Tooltip title={t('mismatched_ids_found')}>
              <WarningIcon style={{ color: 'orange' }} />
            </Tooltip>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {dayjs(entry.date).format('DD.MM.YYYY')}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {entry.salesPartnerData ? entry.salesPartnerData.type : '-'}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {entry.cashflow}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {entry.cumulatedCashflow}
        </TableCell>

        <TableCell component="th" scope="row" align="center">
          {entry.valorizationBillLink ? (
            <div className={`${styles.refreshIconDiv}`}>
              <a target="blank" href={`${entry.valorizationBillLink.url}`}>
                {entry.valorizationBillNumber}
              </a>
              <div onClick={() => onReGenerateFile(entry._id)}>
                <SyncIcon className={`${styles.refreshIcon}`} />
              </div>
            </div>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {entry.valorizationCreditNoteLink ? (
            <div className={`${styles.refreshIconDiv}`}>
              <a
                target="blank"
                href={`${entry.valorizationCreditNoteLink.url}`}
              >
                {entry.valorizationCreditNoteNumber}
              </a>
              <div onClick={() => onReGenerateFile(entry._id)}>
                <SyncIcon className={`${styles.refreshIcon}`} />
              </div>
            </div>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          <div className="u-inline-block">
            {!saved ? (
              <div
                className={`o-media__fixed u-inline-block ${styles.clearIcon}`}
                onClick={deleteEntry}
              >
                <ClearIcon />
              </div>
            ) : deleteSavedValorizationInProgress === entry._id ? (
              <div
                className="u-ml-sm u-inline-block"
                style={{ width: '100px' }}
              >
                <LoadingSpinner />
              </div>
            ) : valorizationIndex === 0 && !disabled ? (
              <Box
                className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
                onClick={() => removeValorizationEntry(entry._id)}
              >
                <DeleteForeverIcon />
              </Box>
            ) : (
              '-'
            )}
          </div>
        </TableCell>
      </TableRow>

      <TableRow style={{ backgroundColor: '#ede7f8' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Paper className="u-p-sm u-12/12 u-mb-sm">
                <ValorizationHistoryEntryGeneralData
                  entry={entry}
                  columnWidths={columnWidths}
                  isPersisted={saved}
                  onChangeEntry={onChangeEntry}
                  itemValorizationHistoryEntries={
                    itemValorizationHistoryEntries
                  }
                  isReadonly={isReadonly}
                />
              </Paper>

              <>
                <Paper className="u-p-sm u-12/12 u-mb-sm">
                  <ValorizationHistoryEntryPaymentData
                    className={`u-mt-sm`}
                    paymentData={paymentData}
                    columnWidths={columnWidths}
                    onChangePaymentData={onChangePaymentData}
                    isReadonly={isPaymentTypeReadonly}
                  />
                </Paper>
                <Paper className="u-p-sm u-12/12 u-mb-sm">
                  <ValorizationHistoryEntryBuyerAddressData
                    className={`u-mt-sm`}
                    label={
                      billingAddressDiffers
                        ? t('buyer_shipping_address')
                        : t('buyer_shipping_and_billing_address')
                    }
                    address={buyerAddress}
                    columnWidths={columnWidths}
                    onChangeAddressData={onChangeShippingAddressData}
                    isReadonly={isReadonly}
                    countries={getCountries}
                    billingAddressDiffers={billingAddressDiffers}
                  />
                </Paper>

                <Paper className="u-p-sm u-12/12 u-mb-sm">
                  <div className={styles.child}>
                    <Checkbox
                      disabled={isReadonly}
                      checked={billingAddressDiffers}
                      onChange={(e) =>
                        onChangeBillingAddressDiffers(e.target.checked)
                      }
                    />
                    {t('use_differing_billing_address')}
                  </div>

                  {billingAddressDiffers && (
                    <ValorizationHistoryEntryBuyerAddressData
                      className={`u-mt-sm`}
                      label={t('buyer_billing_address')}
                      address={buyerBillingAddress}
                      columnWidths={columnWidths}
                      onChangeAddressData={onChangeBillingAddressData}
                      isReadonly={isReadonly}
                      countries={getCountries}
                    />
                  )}
                </Paper>

                {salesPartnerData && (
                  <Paper className="u-p-sm u-12/12 u-mb-sm">
                    <ValorizationHistorySalesPartnerData
                      className={`u-mt-sm`}
                      salesPartnerData={salesPartnerData}
                      columnWidths={columnWidths}
                      onChangeSalesPartnerData={onChangeSalesPartnerData}
                      isReadonly={isReadonly}
                    />
                  </Paper>
                )}

                {logisticsPartnerData && (
                  <Paper className="u-p-sm u-12/12 u-mb-sm">
                    <ValorizationHistoryLogisticsPartnerData
                      className={`u-mt-sm`}
                      logisticsPartnerData={logisticsPartnerData}
                      columnWidths={columnWidths}
                      onChangeLogisticsPartnerData={
                        onChangeLogisticsPartnerData
                      }
                      isReadonly={isReadonly}
                    />
                  </Paper>
                )}

                {(entry._id || cashbookWarnings.length > 0) && (
                  <Paper className="u-p-sm u-12/12 u-mb-sm">
                    {cashbookWarnings.length > 0 ? (
                      <div className={styles.cashbookWarningText}>
                        {cashbookWarnings.map((cbw) => (
                          <p>{cbw}</p>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}

                    {entry._id && (
                      <div className={styles.cashbookWarningText}>
                        {t('valorization_entry_created_by')}
                        <span>
                          {entry.createdBy.__typename === 'Employee'
                            ? `${entry.createdBy.firstname} ${entry.createdBy.lastname}`
                            : entry.createdBy.name}
                        </span>
                      </div>
                    )}
                  </Paper>
                )}
              </>

              <div className={`u-flex ${styles.buttonContainer}`} />

              <div className={`u-flex ${styles.buttonContainer}`}>
                {!saved ||
                (!entry.valorizationBillLink &&
                  !entry.valorizationCreditNoteLink) ||
                !isEqual(paymentData, persistedEntry.paymentData) ? (
                  <div className="u-inline-block">
                    <div className="u-inline-block">
                      <Button
                        className="u-mh-mini u-mt-20 u-mb-20"
                        variant="contained"
                        onClick={onResetEntry}
                        disabled={disabled}
                      >
                        {t('reset')}
                      </Button>
                      {!saved ? (
                        <>
                          <Button
                            className="u-mh-mini u-mt-20 u-mb-20"
                            variant="contained"
                            onClick={deleteEntry}
                            disabled={disabled}
                          >
                            {t('abort')}
                          </Button>
                          <Button
                            className="u-mh-mini u-mt-20 u-mb-20"
                            variant="contained"
                            onClick={onSaveEntry}
                            disabled={disabled}
                          >
                            {t('save')}
                          </Button>
                        </>
                      ) : (
                        <Button
                          className="u-mh-mini u-mt-20 u-mb-20"
                          variant="contained"
                          onClick={() => onUpdateSavedValorizationEntry()}
                          disabled={disabled}
                        >
                          {t('update')}
                        </Button>
                      )}
                    </div>
                    {loading && (
                      <div
                        className={`u-inline-block u-ml-20 ${styles.loadingContainer}`}
                      >
                        <CircularProgress className="" size={30} />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default ValorizationHistoryEntry
