import gql from 'graphql-tag'

export const noteFragments = {
  notes: gql`
    fragment NoteFragment on Note {
      _id
      content
      isPopup
      createdBy
      createdAt
      updatedAt
      employee {
        _id
        firstname
        lastname
        roles
      }
    }
  `,
}
