import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationAddStorageFacilityIdArgs } from '@/schemaTypes'

const ADD_STORAGE_FACILITY_ID = gql`
  mutation addStorageFacilityId(
    $shopId: ObjectId!
    $storageFacilityId: ObjectId!
  ) {
    addStorageFacilityId(
      shopId: $shopId
      storageFacilityId: $storageFacilityId
    ) {
      _id
      name
    }
  }
`

type TData = { addStorageFacilityId: Mutation['addStorageFacilityId'] }
type TVariables = MutationAddStorageFacilityIdArgs

export function useAddStorageFacilityId(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [addStorageFacilityId] = useMutation<TData, TVariables>(
    ADD_STORAGE_FACILITY_ID,
    opts,
  )

  return addStorageFacilityId
}
