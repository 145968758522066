import Countries from '../Countries'
import { MenuItem, Select } from '@material-ui/core'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  EShippingLabelDirection,
  IAddress,
  ShippingLabelCreateArgs,
  Shop,
} from '@/schemaTypes'
import { createIAddress } from '@/utils/address'
import styles from './ShippingLabel.module.scss'

interface PostAddressProps {
  label: string
  createAddressArgs: IAddress
  setCreateAddressArgs
  createArgs: ShippingLabelCreateArgs
  setCreateArgs
  addressIsValid
  setAddressIsValid
  shops?: Shop[] | null // only for Cashy part
  primaryShop?: Shop | null // only for Cashy part
  betweenShops?: boolean
  onChangeDestinationShopId?: (shopId: string | undefined) => void
}

const PostAddress: FunctionComponent<PostAddressProps> = (props) => {
  const {
    label,
    createAddressArgs,
    setCreateAddressArgs,
    createArgs,
    setCreateArgs,
    addressIsValid,
    setAddressIsValid,
    shops,
    primaryShop,
    betweenShops,
    onChangeDestinationShopId,
  } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [scroll] = useState<DialogProps['scroll']>('paper')
  const [newCreateAddressArgs, setNewCreateAddressArgs] = useState<IAddress>({
    ...createAddressArgs,
  })

  const senderOrReceiverShopId =
    createArgs.direction === EShippingLabelDirection.Receive
      ? { receiverShopId: primaryShop?._id }
      : { senderShopId: primaryShop?._id }

  const [newCreateArgs, setNewCreateArgs] = useState<ShippingLabelCreateArgs>({
    ...createArgs,
    ...senderOrReceiverShopId,
  })

  const [selectedShop, setSelectedShop] = useState<Shop | null | undefined>(
    primaryShop,
  )

  useEffect(() => {
    setNewCreateAddressArgs(createAddressArgs)
    setNewCreateArgs(createArgs)
  }, [createAddressArgs, createArgs])

  useEffect(() => {
    setSelectedShop(primaryShop)
  }, [primaryShop])

  const conConfirmDialog = () => {
    setCreateArgs(newCreateArgs)

    setCreateAddressArgs(newCreateAddressArgs)
    setOpen(false)
  }

  const onAbortDialog = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (createAddressArgs) {
      if (
        !createAddressArgs.street ||
        createAddressArgs.street.length === 0 ||
        !createAddressArgs.houseNumber ||
        createAddressArgs.houseNumber.length === 0 ||
        createAddressArgs.houseNumber.length > 7 ||
        !createAddressArgs.city ||
        createAddressArgs.city.length === 0 ||
        !createAddressArgs.zipCode ||
        createAddressArgs.zipCode.length === 0 ||
        !createAddressArgs.countryCode
      ) {
        setAddressIsValid(false)
      } else {
        setAddressIsValid(true)
      }
    } else {
      setAddressIsValid(false)
    }
    if (label === 'SENDER') {
      if (!createArgs.senderName || createArgs.senderName.length === 0)
        setAddressIsValid(false)
    } else {
      if (!createArgs.receiverName || createArgs.receiverName.length === 0)
        setAddressIsValid(false)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAddressArgs, createArgs])

  const onChangeShop = useCallback(
    (shopId: string) => {
      if (!shops) {
        return
      }
      const newShop = shops.find((item) => item._id === shopId)
      if (!newShop) {
        // it should never happen
        return
      }
      setSelectedShop(newShop)
      if (label === 'RECEIVER') {
        setNewCreateArgs({
          ...newCreateArgs,
          receiverName: newShop.name,
          receiverAddress: createIAddress(newShop.address),
          receiverShopId: !betweenShops ? shopId : newCreateArgs.receiverShopId,
        })
        setNewCreateAddressArgs(createIAddress(newShop.address))

        // Get destination shop id in case betweenShops
        if (betweenShops && onChangeDestinationShopId) {
          onChangeDestinationShopId(shopId)
        }
      } else if (label === 'SENDER') {
        setNewCreateArgs({
          ...newCreateArgs,
          senderName: newShop.name,
          senderAddress: createIAddress(newShop.address),
          senderShopId: shopId,
        })
        setNewCreateAddressArgs(createIAddress(newShop.address))
      }
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shops, newCreateArgs, label],
  )

  return (
    <Fragment>
      <div className={styles.innerAddressDiv} onClick={() => setOpen(true)}>
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <h4>
            {t(label)}
            {addressIsValid ? (
              <span style={{ color: 'green', marginLeft: '1rem' }}>
                {t('valid')}
              </span>
            ) : (
              <span style={{ color: 'red', marginLeft: '1rem' }}>
                {t('not_valid')}
              </span>
            )}
          </h4>

          <p>
            {label === 'SENDER'
              ? createArgs.senderCompanyName ?? '-'
              : createArgs.receiverCompanyName ?? '-'}
          </p>

          <p>
            {label === 'SENDER'
              ? createArgs.senderName?.length > 0
                ? createArgs.senderName
                : '-'
              : createArgs.receiverName?.length > 0
                ? createArgs.receiverName
                : '-'}
          </p>
          <p>{createAddressArgs.street ?? '-'}</p>
          <p>{`${createAddressArgs.houseNumber ?? '-'}${createAddressArgs.stairway ? '/' + createAddressArgs.stairway : ''}${
            createAddressArgs.floor ? '/' + createAddressArgs.floor : ''
          }${createAddressArgs.door ? '/' + createAddressArgs.door : ''}`}</p>
          <p>{`${createAddressArgs.zipCode ?? '-'}, ${createAddressArgs.city ?? '-'}, ${
            createAddressArgs.country ?? ''
          }, ${createAddressArgs.countryCode ?? ''}`}</p>
        </Paper>
      </div>

      <Dialog
        maxWidth={'sm'}
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <Typography variant="h6" gutterBottom>
          <DialogTitle>{t(label)}</DialogTitle>
        </Typography>
        <DialogContent dividers={scroll === 'paper'}>
          {shops && (
            <Typography>
              {t('shop.plural')}:&nbsp;&nbsp;
              <Select
                value={selectedShop?._id}
                onChange={(e) => onChangeShop(e.target.value as string)}
              >
                {shops.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </Typography>
          )}
          <TextField
            label={t('company.singular')}
            sx={{ width: '510px' }}
            value={
              label === 'SENDER'
                ? newCreateArgs.senderCompanyName
                : newCreateArgs.receiverCompanyName
            }
            onChange={(value) => {
              label === 'SENDER'
                ? setNewCreateArgs({
                    ...newCreateArgs,
                    senderCompanyName:
                      value.target.value.length > 0 ? value.target.value : '',
                  })
                : setNewCreateArgs({
                    ...newCreateArgs,
                    receiverCompanyName:
                      value.target.value.length > 0 ? value.target.value : '',
                  })
            }}
            className="u-mr-25"
            variant="standard"
            disabled={!!primaryShop}
          />
          <TextField
            disabled={!!primaryShop}
            error={
              label === 'SENDER'
                ? !newCreateArgs.senderName ||
                  newCreateArgs.senderName.length === 0
                : !newCreateArgs.receiverName ||
                  newCreateArgs.receiverName.length === 0
            }
            label={t('name')}
            sx={{ width: '510px' }}
            value={
              label === 'SENDER'
                ? newCreateArgs.senderName
                : newCreateArgs.receiverName
            }
            onChange={(value) => {
              label === 'SENDER'
                ? setNewCreateArgs({
                    ...newCreateArgs,
                    senderName:
                      value.target.value.length > 0 ? value.target.value : '',
                  })
                : setNewCreateArgs({
                    ...newCreateArgs,
                    receiverName:
                      value.target.value.length > 0 ? value.target.value : '',
                  })
            }}
            className="u-mr-25"
            variant="standard"
          />
          <div className="u-flex" style={{ marginBottom: '10px' }}>
            <TextField
              disabled={!!primaryShop}
              error={
                !newCreateAddressArgs.street ||
                newCreateAddressArgs.street.length === 0
              }
              sx={{ width: '510px' }}
              value={newCreateAddressArgs.street ?? ''}
              onChange={(value) => {
                setNewCreateAddressArgs({
                  ...newCreateAddressArgs,
                  street:
                    value.target.value.length > 0
                      ? value.target.value
                      : undefined,
                })
              }}
              label={t('address.street')}
              className="u-mr-25"
              variant="standard"
            />
          </div>
          <div className="u-flex" style={{ marginBottom: '10px' }}>
            <TextField
              disabled={!!primaryShop}
              error={
                !newCreateAddressArgs.houseNumber ||
                newCreateAddressArgs.houseNumber.length === 0
              }
              variant="standard"
              sx={{ width: '110px' }}
              className="u-mr-25"
              value={newCreateAddressArgs.houseNumber ?? ''}
              onChange={(value) =>
                setNewCreateAddressArgs({
                  ...newCreateAddressArgs,
                  houseNumber:
                    value.target.value.length > 0
                      ? value.target.value
                      : undefined,
                })
              }
              label={t('address.house_number')}
            />
            <TextField
              disabled={!!primaryShop}
              variant="standard"
              sx={{ width: '110px' }}
              className="u-mr-25"
              value={newCreateAddressArgs.stairway ?? ''}
              onChange={(value) =>
                setNewCreateAddressArgs({
                  ...newCreateAddressArgs,
                  stairway:
                    value.target.value.length > 0
                      ? value.target.value
                      : undefined,
                })
              }
              label={t('address.stairway')}
            />
            <TextField
              disabled={!!primaryShop}
              variant="standard"
              sx={{ width: '110px' }}
              className="u-mr-25"
              value={newCreateAddressArgs.floor ?? ''}
              onChange={(value) =>
                setNewCreateAddressArgs({
                  ...newCreateAddressArgs,
                  floor:
                    value.target.value.length > 0
                      ? value.target.value
                      : undefined,
                })
              }
              label={t('address.floor')}
            />
            <TextField
              disabled={!!primaryShop}
              variant="standard"
              sx={{ width: '110px' }}
              className="u-mr-25"
              value={newCreateAddressArgs.door ?? ''}
              onChange={(value) =>
                setNewCreateAddressArgs({
                  ...newCreateAddressArgs,
                  door:
                    value.target.value.length > 0
                      ? value.target.value
                      : undefined,
                })
              }
              label={t('address.door')}
            />
          </div>
          <div className="u-flex" style={{ marginBottom: '10px' }}>
            <TextField
              disabled={!!primaryShop}
              error={
                !newCreateAddressArgs.zipCode ||
                newCreateAddressArgs.zipCode.length === 0
              }
              className="u-mr-25"
              sx={{ width: '110px' }}
              value={newCreateAddressArgs.zipCode ?? ''}
              onChange={(value) =>
                setNewCreateAddressArgs({
                  ...newCreateAddressArgs,
                  zipCode:
                    value.target.value.length > 0
                      ? value.target.value
                      : undefined,
                })
              }
              label={t('address.zip_code')}
              variant="standard"
            />
            <TextField
              disabled={!!primaryShop}
              error={
                !newCreateAddressArgs.city ||
                newCreateAddressArgs.city.length === 0
              }
              variant="standard"
              className="u-mr-25"
              sx={{ width: '110px' }}
              value={newCreateAddressArgs.city ?? ''}
              onChange={(value) =>
                setNewCreateAddressArgs({
                  ...newCreateAddressArgs,
                  city:
                    value.target.value.length > 0
                      ? value.target.value
                      : undefined,
                })
              }
              label={t('address.city')}
            />
            <Countries
              disabled={!!primaryShop}
              error={!newCreateAddressArgs.countryCode ? '*' : undefined}
              value={newCreateAddressArgs.countryCode}
              onChange={(value) =>
                setNewCreateAddressArgs({
                  ...newCreateAddressArgs,
                  countryCode: value,
                })
              }
              label={t('address.country')}
              marginTop="1rem"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onAbortDialog} color="primary">
            {t('abort')}
          </Button>
          <Button color="primary" onClick={conConfirmDialog}>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default PostAddress
