import { useFormikContext } from 'formik'
import { TFunction } from 'i18next'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { CheckIdentityZone } from './CheckIdentityZone'
import { CustomerArgs } from './CustomerConfiguration'

type Props = {
  t: TFunction
  maxDate?: Date
  identityTypeOptions: { label: string; value: string }[]
  identityFileUrl: string
  visibleCheckIdentity: boolean
  setVisibleCheckIdentity: (visible: boolean) => void
  openDeletingDialog: () => void
}

export const IdentityVerifyingDialog = ({
  visibleCheckIdentity,
  setVisibleCheckIdentity,
  t,
  maxDate,
  identityTypeOptions,
  identityFileUrl,
  openDeletingDialog,
}: Props) => {
  const { handleSubmit } = useFormikContext<CustomerArgs>()

  return (
    <Dialog
      draggable={false}
      header={t('identify_verification.check_identity')}
      visible={visibleCheckIdentity}
      onHide={() => setVisibleCheckIdentity(false)}
      footer={
        <>
          <Button
            severity="danger"
            size="small"
            label={t('identify_verification.not_a_match')}
            icon="pi pi-times"
            onClick={openDeletingDialog}
          />
          <Button
            severity="success"
            size="small"
            label={t('identify_verification.full_match')}
            icon="pi pi-check"
            onClick={() => {
              handleSubmit()
            }}
          />
        </>
      }
    >
      <CheckIdentityZone
        t={t}
        url={identityFileUrl}
        maxDate={maxDate}
        identityTypeOptions={identityTypeOptions}
      />
    </Dialog>
  )
}
