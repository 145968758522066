import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateShippingLabelArgs } from '@/schemaTypes'

const CREATE_SHIPPING_LABEL = gql`
  mutation createShippingLabel($createArgs: ShippingLabelCreateArgs!) {
    createShippingLabel(createArgs: $createArgs) {
      direction
      shippingPartner
      labelCreateDate
      senderAddress {
        houseNumber
        stairway
        door
        floor
        street
        city
        state
        countryCode
        country
        zipCode
      }
      senderName
      receiverAddress {
        houseNumber
        stairway
        door
        floor
        street
        city
        state
        countryCode
        country
        zipCode
      }
      receiverName
      labelFile {
        url
        contentType
        filename
      }
      trackingCode
      trackingLink
      isCODPayment
    }
  }
`

type TData = { createShippingLabel: Mutation['createShippingLabel'] }
type TVariables = MutationCreateShippingLabelArgs

export function useCreateShippingLabel(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createShippingLabel] = useMutation<TData, TVariables>(
    CREATE_SHIPPING_LABEL,
    opts,
  )
  return createShippingLabel
}
