import { euroDenominations } from '../helpers'
import styled from '@emotion/styled'
import { sortBy, sumBy } from 'lodash'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputNumber } from 'primereact/inputnumber'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { moneyPieceValues } from '@/domains/deals/components/constants/moneyPieces'
import { CashBookCountCashBoxEntry, EEuroDenomination } from '@/schemaTypes'
import { calculateCashBoxAmounts } from '@/utils/calculation'
import { printMoneyAmount } from '@/utils/misc'

export interface CashBookCountTableRowProps {
  readOnly?: boolean
  cashBoxEntries: CashBookCountCashBoxEntry[]
  latestBalance?: number
  onChange: (data: {
    count: number
    cashBoxName: string
    moneyPiece: EEuroDenomination
  }) => void
}

export function CashBookCountTableRow(props: {
  cashBoxName: string
  cashBoxCount: number
  readOnly?: boolean
  onChange: (count: number, cashBoxName: string) => void
}) {
  const { cashBoxName, cashBoxCount, readOnly, onChange } = props

  return (
    <>
      {readOnly ? (
        <div className="py-2">{cashBoxCount}</div>
      ) : (
        <InputNumber
          className="w-[150px] h-[37px]"
          inputClassName="w-full text-center"
          value={cashBoxCount}
          onValueChange={(e) => onChange(e.value, cashBoxName)}
          showButtons
          buttonLayout="horizontal"
          min={0}
          decrementButtonClassName="p-button-secondary"
          incrementButtonClassName="p-button-secondary"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
        />
      )}
    </>
  )
}

const moneyPieces = sortBy(
  Object.values(EEuroDenomination),
  (value) => euroDenominations[value],
).reverse()

export default function CashBookHistoryTable(
  props: CashBookCountTableRowProps,
) {
  const { cashBoxEntries, onChange, latestBalance, readOnly } = props
  const { t } = useTranslation()

  const total = useMemo(() => {
    return calculateCashBoxAmounts(cashBoxEntries)
  }, [cashBoxEntries])

  const data = useMemo(
    () =>
      moneyPieces.map((moneyPiece) => {
        const total = sumBy(
          cashBoxEntries,
          (entry) =>
            entry.counts[moneyPiece.toUpperCase()] *
            moneyPieceValues[moneyPiece],
        )

        return {
          moneyPiece,
          ...cashBoxEntries.reduce((acc: Record<string, number>, entry) => {
            return {
              ...acc,
              [entry.cashBoxName]: entry.counts[moneyPiece.toUpperCase()],
            }
          }, {}),
          total,
        }
      }),
    [cashBoxEntries],
  )

  return (
    <div>
      <DataTable value={data} cellClassName={() => '!py-2'}>
        <Column
          className="min-w-[120px]"
          header={t('cash_book_count.coin_and_banknote')}
          body={({ moneyPiece }) => <div>{euroDenominations[moneyPiece]}</div>}
          headerClassName="text-sm"
        />
        {cashBoxEntries.map((entry) => (
          <Column
            key={entry.cashBoxName}
            header={entry.cashBoxName}
            headerClassName="text-sm"
            className="text-left min-w-[180px]"
            body={({ moneyPiece }) => (
              <CashBookCountTableRow
                cashBoxName={entry.cashBoxName}
                cashBoxCount={entry.counts[moneyPiece.toUpperCase()]}
                readOnly={readOnly}
                onChange={(count, cashBoxName) => {
                  onChange({ count, cashBoxName, moneyPiece })
                }}
              />
            )}
          />
        ))}
        <Column
          headerClassName="text-sm"
          className="min-w-[120px]"
          alignHeader="right"
          header={t('total')}
          body={({ total }) => (
            <div className="text-right">{printMoneyAmount(total)}</div>
          )}
        />
      </DataTable>

      <SummaryRow className="flex justify-between row py-4 px-4 font-bold text-black">
        <div className="text-sm">{t('cash_book_count.total_counted')}</div>
        <div className="text-sm">{printMoneyAmount(total.totalAmount)}</div>
      </SummaryRow>
      <SummaryRow className="flex justify-between row py-4 px-4 font-bold text-black">
        <div className="text-sm">{t('cash_book_count.expected_total')}</div>
        <div className="text-sm">
          {latestBalance ? printMoneyAmount(latestBalance) : '-'}
        </div>
      </SummaryRow>
      <SummaryRow className="flex justify-between row py-4 px-4 font-bold text-black">
        <div className="text-sm">{t('cash_book_count.difference')}</div>
        <div className="text-sm">
          {latestBalance
            ? printMoneyAmount(total.totalAmount - latestBalance)
            : '-'}
        </div>
      </SummaryRow>
    </div>
  )
}

const SummaryRow = styled.div`
  border-bottom: 1px solid #dee2e6;
  background: #f8f9fa;
`
