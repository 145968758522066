import {
  includeHidedCategories,
  useGetItemCategories,
} from '../../itemCategories/hooks'
import { Button, Checkbox, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import * as _ from 'lodash'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AmountInput from '@/components/AmountInput'
import CategorySelector from '@/components/CategorySelector'
import ItemQuestionsFeatureIdsTable from '@/components/ItemQuestionsFeatureIdsTable'
import SelectOptionInput from '@/components/SelectOptionInput'
import TextInput from '@/components/TextInput'
import ValidFromToDateHourPicker from '@/components/ValidFromToDateHourPicker'
import {
  useValidFromToDateChange,
  useValidFromToHourChange,
  useValidationErrorMap,
} from '@/hooks'
import {
  EQuestionPredictionTag,
  EQuestionType,
  EQuestionValueMode,
  ItemQuestion,
  ItemQuestionOption,
} from '@/schemaTypes'
import { ValidFromToFieldName } from '@/types'
import { ItemQuestionValidation } from '@/validation'
import RangeData from './RangeData'
import SingleChoiceOptionsTable from './SingleChoiceOptionsTable'

export type ItemQuestionValidationErrorObject = Partial<
  Pick<
    ItemQuestion,
    | 'validFrom'
    | 'validTo'
    | 'order'
    | 'questionType'
    | 'valueMode'
    | 'titleKey'
    | 'predictionTag'
  >
>
export interface ItemQuestionValidationError {
  fieldName: keyof ItemQuestionValidationErrorObject
  message: string
}
export interface ItemQuestionsDetailsProps {
  itemQuestion: ItemQuestion
  onSave: (itemQuestion: ItemQuestion) => void
}

dayjs.extend(utc)

const questionTypes = [
  { value: EQuestionType.SingleChoice, label: 'SINGLE_CHOICE' },
  { value: EQuestionType.Range, label: 'RANGE' },
]
const valueModes = [
  { value: EQuestionValueMode.Percentage, label: 'PERCENTAGE' },
  { value: EQuestionValueMode.Constant, label: 'CONSTANT' },
  {
    value: EQuestionValueMode.UnitPriceMultiplier,
    label: 'UNIT_PRICE_MULTIPLIER',
  },
]
const predictionTags = [
  { value: null, label: 'none' },
  { value: EQuestionPredictionTag.Condition, label: 'condition' },
  { value: EQuestionPredictionTag.Weight, label: 'weight' },
  { value: EQuestionPredictionTag.Alloy, label: 'alloy' },
]

export function ItemQuestionsDetails(props: ItemQuestionsDetailsProps) {
  const { itemQuestion: persistedItemQuestion } = props
  const { t } = useTranslation()

  const [itemQuestion, setItemQuestion] = useState<ItemQuestion>({
    ...persistedItemQuestion,
  })
  const { validationErrorsMap, isValid } = useValidationErrorMap(
    itemQuestion,
    ItemQuestionValidation,
  )

  const setValidFromTo = (newDate: Date, fieldName: ValidFromToFieldName) =>
    setItemQuestion({ ...itemQuestion, [fieldName]: newDate })

  const onDateChange = useValidFromToDateChange(setValidFromTo)
  const onHourChange = useValidFromToHourChange(setValidFromTo)

  const { itemCategories } = useGetItemCategories(includeHidedCategories)

  const currentCategories = itemCategories.filter((category) =>
    itemQuestion.itemCategoryIds.includes(category._id),
  )

  const onReset = () => {
    setItemQuestion(persistedItemQuestion)
  }

  const onSave = () => {
    if (isValid) {
      if (itemQuestion.questionType === EQuestionType.Range) {
        itemQuestion.singleChoiceOptions = null

        if (itemQuestion.rangeData && !itemQuestion.rangeData?.unitKey) {
          itemQuestion.rangeData.unitKey = null as any
        }
      } else if (itemQuestion.questionType === EQuestionType.SingleChoice) {
        itemQuestion.rangeData = null

        const cleanedSingleChoiceOptions = (
          itemQuestion.singleChoiceOptions ?? []
        ).map((option) => {
          const cleanedOption = { ...option }

          if (!cleanedOption.isDefaultValue) {
            delete cleanedOption.isDefaultValue
          }

          if (!cleanedOption.infoKey) {
            cleanedOption.infoKey = null
          }

          if (!cleanedOption.isInternal) {
            cleanedOption.isInternal = false
          }

          if (!cleanedOption.labelKey) {
            cleanedOption.labelKey = null as any
          }

          return cleanedOption
        })

        itemQuestion.singleChoiceOptions = cleanedSingleChoiceOptions
      }

      props.onSave(itemQuestion)
    }
  }

  const handleAddOldItemQuestionId = (oldItemQuestionId: string) => {
    const newOldItemQuestionIds = (
      itemQuestion._oldItemQuestionIds ?? []
    ).slice()
    newOldItemQuestionIds.push(oldItemQuestionId)
    setItemQuestion({
      ...itemQuestion,
      _oldItemQuestionIds: newOldItemQuestionIds,
    })
  }

  const handleDeleteOldItemQuestionId = (oldItemQuestionIds: string[]) => {
    const persistedOldItemQuestionIds = itemQuestion._oldItemQuestionIds
    const newOldItemQuestionIds = _.difference(
      persistedOldItemQuestionIds,
      oldItemQuestionIds,
    )
    setItemQuestion({
      ...itemQuestion,
      _oldItemQuestionIds: newOldItemQuestionIds,
    })
  }

  const handleAddOption = (option: ItemQuestionOption) => {
    const newOptions: ItemQuestionOption[] = itemQuestion.singleChoiceOptions
      ? itemQuestion.singleChoiceOptions.slice()
      : []
    newOptions.push(_.cloneDeep(option))
    setItemQuestion({ ...itemQuestion, singleChoiceOptions: newOptions })
  }

  const handleDeleteOptions = (options: ItemQuestionOption[]) => {
    const persistedOptions = itemQuestion.singleChoiceOptions
    const newOptions = _.difference(persistedOptions, options)
    setItemQuestion({ ...itemQuestion, singleChoiceOptions: newOptions })
  }

  return (
    <Fragment>
      <Fragment>
        {t('loan.hide_this_question')}
        <Checkbox
          value={itemQuestion.isInternal}
          checked={itemQuestion.isInternal ?? false}
          onChange={(e) =>
            setItemQuestion({ ...itemQuestion, isInternal: e.target.checked })
          }
        />
      </Fragment>
      <ValidFromToDateHourPicker
        dateLabel={t('loan.valid_from')}
        timeLabel={t('utc_time')}
        error={validationErrorsMap.validFrom}
        minDate={!itemQuestion._id ? new Date() : undefined}
        value={itemQuestion.validFrom}
        onDateChange={(date) => onDateChange(date, itemQuestion, 'validFrom')}
        onHourChange={(hour) => onHourChange(hour, itemQuestion, 'validFrom')}
      />
      <ValidFromToDateHourPicker
        dateLabel={t('loan.valid_to')}
        timeLabel={t('utc_time')}
        error={validationErrorsMap.validTo}
        minDate={itemQuestion.validFrom ? itemQuestion.validFrom : new Date()}
        value={itemQuestion.validTo}
        onDateChange={(date) => onDateChange(date, itemQuestion, 'validTo')}
        onHourChange={(hour) => onHourChange(hour, itemQuestion, 'validTo')}
      />
      <AmountInput
        error={validationErrorsMap.order}
        value={itemQuestion.order}
        onChange={(value) => setItemQuestion({ ...itemQuestion, order: value })}
        label={t('order')}
      />
      <SelectOptionInput
        error={validationErrorsMap.questionType}
        value={itemQuestion.questionType}
        onChange={(value) =>
          setItemQuestion({ ...itemQuestion, questionType: value })
        }
        label={t('question_type')}
        menuItems={questionTypes}
      />
      <SelectOptionInput
        error={validationErrorsMap.valueMode}
        value={itemQuestion.valueMode}
        onChange={(value) =>
          setItemQuestion({ ...itemQuestion, valueMode: value })
        }
        label={t('value_mode')}
        menuItems={valueModes}
      />
      <TextInput
        error={validationErrorsMap.titleKey}
        value={itemQuestion.titleKey}
        onChange={(value) =>
          setItemQuestion({ ...itemQuestion, titleKey: value })
        }
        label={t('title_key')}
      />
      <TextInput
        error={validationErrorsMap.infoKey}
        value={itemQuestion.infoKey ?? ''}
        onChange={(value) =>
          setItemQuestion({ ...itemQuestion, infoKey: value || null })
        }
        label={t('info_key')}
      />
      <SelectOptionInput
        value={itemQuestion.predictionTag}
        onChangeNullable={(value) =>
          setItemQuestion({ ...itemQuestion, predictionTag: value })
        }
        label={t('prediction_tag')}
        menuItems={predictionTags}
        defaultValue={predictionTags[0].value}
      />
      <CategorySelector
        multiple
        categories={itemCategories}
        onChange={(itemCategoryIds) =>
          setItemQuestion({ ...itemQuestion, itemCategoryIds })
        }
        currentCategories={currentCategories}
      />
      <ItemQuestionsFeatureIdsTable
        featureIds={itemQuestion._oldItemQuestionIds ?? []}
        onAdd={handleAddOldItemQuestionId}
        onDelete={handleDeleteOldItemQuestionId}
      />

      <div className="mt-4">
        {t('item_question_is_deactivated_for_new_products')}
        <Checkbox
          value={itemQuestion.isDeactivatedForNewProducts}
          checked={Boolean(itemQuestion.isDeactivatedForNewProducts)}
          onChange={(e) =>
            setItemQuestion((itemQuestion) => ({
              ...itemQuestion,
              isDeactivatedForNewProducts: e.target.checked,
            }))
          }
        />
      </div>

      <div className="u-mt-20">
        {itemQuestion.questionType === 'SINGLE_CHOICE' && (
          <div>
            <Typography variant="h5" gutterBottom>
              {t('single_choice_options')}
            </Typography>
            <SingleChoiceOptionsTable
              options={itemQuestion.singleChoiceOptions ?? []}
              onAdd={handleAddOption}
              onDelete={handleDeleteOptions}
              onChange={(value) =>
                setItemQuestion({ ...itemQuestion, singleChoiceOptions: value })
              }
            />
          </div>
        )}
        {itemQuestion.questionType === 'RANGE' && (
          <div>
            <Typography variant="h5" gutterBottom>
              {t('range_data')}
            </Typography>
            <RangeData
              rangeData={
                itemQuestion.rangeData ?? {
                  maxValue: 0,
                  minValue: 0,
                  unitKey: '',
                  unitPrice: 0,
                  defaultValue: 0,
                }
              }
              onChangeRangeData={(value) =>
                setItemQuestion({ ...itemQuestion, rangeData: value })
              }
            />
          </div>
        )}
      </div>
      <div className="u-mt-20">
        <Button onClick={onSave} variant="contained" disabled={!isValid}>
          {t('save')}
        </Button>
        <Button onClick={onReset} variant="contained">
          {t('reset')}
        </Button>
      </div>
    </Fragment>
  )
}

export default ItemQuestionsDetails
