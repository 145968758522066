import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCallViviVinEndpointArgs } from '@/schemaTypes'

const mutation = gql`
  mutation callViviVinEndpoint($args: ViviVinRequestArgs!) {
    callViviVinEndpoint(args: $args) {
      nextStep {
        summary
        name
        description {
          value
          label
        }
        href
        type
        rel
        templated
      }
      lastStepHref
    }
  }
`

type TData = { callViviVinEndpoint: Mutation['callViviVinEndpoint'] }
type TVariables = MutationCallViviVinEndpointArgs

export function useCallViviVinEndpointMutation(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const queryResult = useMutation<TData, TVariables>(mutation, {
    ...opts,
  })

  return queryResult
}
