import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import {
  Employee,
  ItemStorageHistoryEntry,
  StorageFacility,
  StorageUnit,
} from '@/schemaTypes'
import { printLocalDateTime } from '@/utils/print'
import { Timezone } from '@/utils/time'

export interface StorageHistoryProps {
  storageHistory: ItemStorageHistoryEntry[]
  employees: Employee[]
  storageFacilities: StorageFacility[]
  timezone: Timezone
  storageUnits: StorageUnit[]
  storageLabel: string
}

export function StorageHistory(props: StorageHistoryProps) {
  const {
    storageHistory,
    employees,
    timezone,
    storageLabel,
    storageFacilities,
    storageUnits,
  } = props
  const { t } = useTranslation()

  const getEmployeeName = (employeeId: string) => {
    const employee = employees.find((employee) => employee._id === employeeId)
    if (employee) {
      return employee.firstname.concat(' ').concat(employee.lastname)
    }

    return ''
  }

  const getStorageUnitName = (storageUnitId: string) => {
    const storageUnit = storageUnits.find(
      (storageUnit) => storageUnit._id === storageUnitId,
    )
    if (storageUnit) {
      return storageUnit.name
    }

    return ''
  }

  const getStorageFacilityName = (storageUnitId: string) => {
    const storageFacility = storageFacilities.find((storageFacility) =>
      storageFacility.storageUnitIds.includes(storageUnitId),
    )
    if (storageFacility) {
      return storageFacility.name
    }

    return ''
  }

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Table className="u-mb-sm" size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('storage.label')}</TableCell>
            <TableCell>{t('status')}</TableCell>
            <TableCell>{t('date')}</TableCell>
            <TableCell>{t('employee.label')}</TableCell>
            <TableCell>{t('storage.storage_facilities')}</TableCell>
            <TableCell>{t('level') + ' 1'}</TableCell>
            <TableCell>{t('level') + ' 2'}</TableCell>
            <TableCell>{t('level') + ' 3'}</TableCell>
            <TableCell>{t('level') + ' 4'}</TableCell>
            <TableCell>{t('note')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {storageHistory.map((entry, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {storageLabel}
              </TableCell>
              <TableCell>{t(entry.storageStatus)}</TableCell>
              <TableCell>
                <span>{printLocalDateTime(entry.timestamp, { timezone })}</span>
              </TableCell>
              <TableCell>{getEmployeeName(entry.employeeId)}</TableCell>
              <TableCell>
                {getStorageFacilityName(entry.storageUnits.lvl1)}
              </TableCell>
              <TableCell>
                {getStorageUnitName(entry.storageUnits.lvl1)}
              </TableCell>
              <TableCell>
                {getStorageUnitName(entry.storageUnits.lvl2)}
              </TableCell>
              <TableCell>
                {getStorageUnitName(entry.storageUnits.lvl3)}
              </TableCell>
              <TableCell>
                {getStorageUnitName(entry.storageUnits.lvl4)}
              </TableCell>
              <TableCell>
                <ReactMarkdown>{entry.note ?? ''}</ReactMarkdown>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default StorageHistory
