import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { Messages } from 'primereact/messages'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useGetCashBookCount,
  useUpdateCashBookCount,
} from '@/domains/cashbooks/hooks'
import { useGetShopsNames, useMutationShowingErrors } from '@/hooks'
import {
  CashBookCount,
  EBooleanFilterConditions,
  EManualPaymentType,
  EOrderBy,
  Employee,
} from '@/schemaTypes'
import { addDays, defaultTimezone } from '@/utils/time'
import CashBookHistoryTable from './CashBookHistoryTable'

export default function BankNoteAndCoinHistory(props: {
  currentUser: Employee
}) {
  const { t } = useTranslation()

  const [date, setDate] = useState<Date>(new Date())
  const [shopId, setShopId] = useState<string>('')
  const [cashBookCount, setCashBookCount] = useState<
    CashBookCount | undefined
  >()
  const [latestBalance, setLatestBalance] = useState<number | undefined>()
  const [isEditing, setIsEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const msgs = useRef(null)

  const { shops } = useGetShopsNames({
    variables: {
      opts: {
        filter: {
          disabled: {
            value: true,
            condition: EBooleanFilterConditions.NotEquals,
          },
        },
      },
    },
  })

  const result = useGetCashBookCount({
    variables: {
      opts: {
        filter: {
          date: {
            betweenValues: {
              from: dayjs(date).startOf('day').toDate(),
              to: dayjs(date).endOf('day').toDate(),
            },
          },
          shopId: { value: shopId },
          paymentType: EManualPaymentType.Cash,
          temporary: {
            value: true,
            condition: EBooleanFilterConditions.NotEquals,
          },
        },
        order: {
          date: EOrderBy.Desc,
        },
      },
      filterArgs: {
        shopId: { value: shopId },
        date: {
          betweenValues: {
            from: date,
            to: addDays(date, 1, defaultTimezone),
          },
        },
        paymentType: EManualPaymentType.Cash,
      },
    },
    fetchPolicy: 'network-only',
    skip: !date || !shopId,
    onCompleted: (data) => {
      msgs.current.clear()
      if (data.getCashBookCount) {
        setCashBookCount(data.getCashBookCount)
        setLatestBalance(data.getLatestCashFlowBalanceForDate)
        msgs.current.clear()
      } else {
        msgs.current.show({
          id: 1,
          sticky: true,
          severity: 'warn',
          summary: t('cash_book_count.warning'),
          detail: t('cash_book_count.empty_history_entry', {
            date: dayjs(date).format('DD.MM.YYYY'),
          }),
          closable: false,
        })
        setCashBookCount(undefined)
        setLatestBalance(undefined)
      }
    },
  })

  const updateCashBookCount = useMutationShowingErrors({
    mutation: useUpdateCashBookCount(),
    successMessage: t('cash_book_count.cash_book_count_saved'),
  })

  const onSave = async () => {
    if (!cashBookCount?._id) return

    const { _id, cashBoxEntries } = cashBookCount

    try {
      setIsSaving(true)
      await updateCashBookCount({
        variables: {
          cashBookCountUpdateArgs: {
            _id,
            employeeId: props.currentUser._id,
            cashBoxEntries,
          },
        },
        refetchQueries: ['getCashBookCount'],
      })
    } finally {
      setIsSaving(false)
      setIsEditing(false)
    }
  }

  const onReset = () => {
    if (result.queryResult.data.getCashBookCount) {
      setCashBookCount(result.queryResult.data.getCashBookCount)
      setLatestBalance(result.queryResult.data.getLatestCashFlowBalanceForDate)
    }
  }

  const handleDateChange = (e) => {
    if (e.value) setDate(new Date(e.value))
    else setDate(null)
  }

  return (
    <div className="pb-16 max-w-[720px]">
      <div className="mb-4">
        <div className="flex items-center mb-2">
          <div className="w-60 font-bold">
            {t('cash_book_count.select_cashbook')}:
          </div>
          <Dropdown
            value={shopId}
            onChange={(e) => {
              setShopId(e.value)
            }}
            options={shops.map((option) => ({
              name: option.name,
              value: option._id,
            }))}
            optionLabel="name"
            className="mr-2 w-[250px]"
          />
        </div>

        <div className="flex items-center">
          <div className="w-60 font-bold">
            {t('cash_book_count.show_counted_from')}:
          </div>
          <div className="flex-1 p-input-icon-left">
            <i className="pi pi-calendar z-10 !left-[150px]" />
            <Calendar
              value={date}
              className="width-[150px]"
              onChange={handleDateChange}
              maxDate={new Date()}
              dateFormat="dd.mm.yy"
              placeholder="DD.MM.YYYY"
            />
          </div>
        </div>
      </div>

      <Messages ref={msgs} />

      {cashBookCount && shopId && (
        <>
          <CashBookHistoryTable
            readOnly={!isEditing}
            cashBoxEntries={cashBookCount.cashBoxEntries}
            onChange={(data) => {
              const { cashBoxName, count, moneyPiece } = data

              const cashBoxEntries = cloneDeep(cashBookCount.cashBoxEntries)
              const cashBoxEntry = cashBoxEntries.find(
                (entry) => entry.cashBoxName === cashBoxName,
              )

              if (cashBoxEntry) {
                cashBoxEntry.counts[moneyPiece.toUpperCase()] = count
              }

              setCashBookCount({ ...cashBookCount, cashBoxEntries })
            }}
            latestBalance={latestBalance}
          />

          <div
            className="mt-4 p-2 rounded-md border border-gray-200 flex"
            style={{ background: '#f8f9fa' }}
          >
            {isEditing ? (
              <>
                <Button
                  onClick={onSave}
                  icon="pi pi-save"
                  label={t('save')}
                  severity="info"
                  size="small"
                  disabled={isSaving}
                />
                <Button
                  onClick={onReset}
                  icon="pi pi-replay"
                  className="u-ml-10"
                  label={t('reset')}
                  text
                  severity="secondary"
                  size="small"
                />
                <Button
                  onClick={() => setIsEditing(false)}
                  style={{ marginLeft: 'auto' }}
                  label={t('cancel')}
                  severity="secondary"
                  size="small"
                />
              </>
            ) : (
              <Button
                onClick={() => setIsEditing(true)}
                icon="pi pi-pencil"
                label={t('cash_book_count.edit_history_entry')}
                severity="info"
                size="small"
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}
