import { useExchangeDealItem } from '../../../hooks'
import { Box, Table, TableCell, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Deal, Item } from '@/schemaTypes'
import AddVehicle, { ItemDraftVehicleFormData } from './AddVehicle'

export interface EditVehiclePropertiesProps {
  item: Item
  deal: Deal
}

const EditVehicleProperties: React.FC<EditVehiclePropertiesProps> = ({
  item,
  deal,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [exchangeDealItem] = useExchangeDealItem()

  const onSubmit = async (formData: ItemDraftVehicleFormData) => {
    await exchangeDealItem({
      variables: {
        dealId: deal._id,
        newItemArgs: {
          answers: formData.answers,
          itemCategoryId: formData.itemCategoryId,
          title: formData.title,
          customPayoutAmount: formData.customPayoutAmount,
          vehicleData: formData.vehicleData,
        },
        oldItemId: item._id,
      },
    })

    history.push(`/inApp/items/${item._id}`)
  }

  const fetchedVehicleProperties = item.vehicleData
    ? [
        ...Object.keys(item.vehicleData.vehicleProperties)
          .filter(
            (k) =>
              k !== 'otherVehicleProperties' &&
              k !== '__typename' &&
              item.vehicleData?.vehicleProperties[k] != undefined,
          )
          .map((k) => ({
            name: k,
            value: item.vehicleData?.vehicleProperties[k],
          })),
        ...(item.vehicleData?.vehicleProperties.otherVehicleProperties ?? []),
      ]
    : []

  return (
    <Box>
      <Box>
        <Typography className="u-pt-sm" variant="h6" gutterBottom>
          {t('car_current_configuration')}
        </Typography>
        <Table>
          {fetchedVehicleProperties.map((prop) => {
            return (
              <TableRow>
                <TableCell>{t('car_' + prop.name)}</TableCell>
                <TableCell>{prop.value}</TableCell>
              </TableRow>
            )
          })}
        </Table>
      </Box>

      <Box mt="2rem">
        <Typography className="u-pt-sm" variant="h6" gutterBottom>
          {t('car_new_configuration')}
        </Typography>
        <AddVehicle
          companyId={deal.companyId}
          durationInDays={deal.dealFinalValues.durationInDays}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  )
}

export default EditVehicleProperties
