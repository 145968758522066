import {
  IsArray,
  IsEmail,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  Max,
  MaxLength,
  Min,
  MinLength,
  ValidateIf,
  ValidateNested,
} from 'class-validator'
import { CompanyArgs } from '@/domains/companies/components/CompaniesDetails'
import {
  CompanyConfiguration,
  CompanySalesConfiguration,
  CompanyUnzerConfiguration,
  RefurbedStaggeredMargin,
} from '@/schemaTypes'

class CompanyConfigurationValidation {
  @IsNumber({ maxDecimalPlaces: 0 })
  @IsPositive()
  minimumPawnDuration: number

  @IsNumber({ maxDecimalPlaces: 0 })
  @IsPositive()
  gracePeriod: number

  @IsNumber()
  @Min(1)
  @Max(4)
  insuranceFactor: number

  @IsNumber()
  @Min(0)
  @Max(1)
  liquidationFee: number

  @IsNumber()
  @Min(0)
  @Max(1)
  valueAddedTaxRate: number

  @IsNumber()
  @Min(0)
  @Max(1)
  differenceTaxRate: number

  @IsNumber()
  @Min(0)
  @Max(1)
  valorizationFeeRate: number

  @IsNumber()
  @IsOptional()
  @Min(0)
  payoutLimit?: number

  @IsNumber()
  @IsOptional()
  @Min(0)
  carPayoutLimit?: number

  constructor(configuration: CompanyConfiguration) {
    this.minimumPawnDuration = configuration.minimumPawnDuration
    this.gracePeriod = configuration.gracePeriod
    this.insuranceFactor = configuration.insuranceFactor
    this.liquidationFee = configuration.liquidationFee
    this.valueAddedTaxRate = configuration.valueAddedTaxRate
    this.differenceTaxRate = configuration.differenceTaxRate
    this.valorizationFeeRate = configuration.valorizationFeeRate
    this.payoutLimit = configuration.payoutConfigurations?.payoutLimit
    this.carPayoutLimit = configuration.payoutConfigurations?.carPayoutLimit
  }
}

export class CompanySalesConfigurationValidation {
  @IsNumber()
  @Min(0)
  @Max(1)
  refurbedCommission: number

  @IsArray()
  @ValidateNested({ each: true })
  refurbedStaggeredMargins: CompanyRefurbedStaggeredMarginValidation[]

  constructor(configuration: CompanySalesConfiguration) {
    this.refurbedCommission = configuration.refurbedCommission
    this.refurbedStaggeredMargins = configuration.refurbedStaggeredMargins.map(
      (item) => new CompanyRefurbedStaggeredMarginValidation(item),
    )
  }
}

class CompanyRefurbedStaggeredMarginValidation {
  @IsNumber()
  @Min(0)
  from: number

  @IsNumber()
  @Min(0)
  @Max(1)
  expectedMargin: number

  @IsNumber()
  @Min(0)
  @Max(1)
  minimumMargin: number

  constructor(configuration: RefurbedStaggeredMargin) {
    this.from = configuration.from
    this.expectedMargin = configuration.expectedMargin
    this.minimumMargin = configuration.minimumMargin
  }
}

export class CompanyUnzerConfigurationValidation {
  @IsString()
  @IsNotEmpty()
  @MaxLength(4)
  companyCode?: string | undefined

  constructor(configuration: CompanyUnzerConfiguration) {
    this.companyCode = configuration.companyCode ?? undefined
  }
}

export class DPDSubAccountArgsValidation {
  @IsString()
  @MinLength(1)
  userId: string

  @IsString()
  @IsOptional()
  @MinLength(1)
  userToken?: string

  @IsNotEmpty()
  isTokenStored: any

  constructor(
    configuration: Pick<CompanyArgs, 'dpdAccountInfo' | 'dpdConfiguration'>,
    index: number,
  ) {
    this.userId = configuration.dpdConfiguration?.shopSubAccounts[index]?.userId
    this.userToken =
      configuration.dpdConfiguration?.shopSubAccounts[index]?.userToken
    this.isTokenStored =
      Boolean(
        configuration.dpdConfiguration?.shopSubAccounts[index]?.userToken,
      ) ||
      (configuration.dpdAccountInfo?.subAccountsInfo?.[index]
        ?.isPasswordStored &&
        configuration.dpdAccountInfo?.subAccountsInfo[index]?.username ===
          configuration.dpdConfiguration?.shopSubAccounts[index]?.userId)
  }
}

export class CompanyDpdConfigurationValidation {
  @IsString()
  @MinLength(1)
  accountName: string

  @IsString()
  @IsOptional()
  @MinLength(1)
  accountToken?: string

  @IsNotEmpty()
  isTokenStored: any

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  shopSubAccounts?: DPDSubAccountArgsValidation[]

  constructor(
    configuration: Pick<CompanyArgs, 'dpdAccountInfo' | 'dpdConfiguration'>,
  ) {
    this.accountName = configuration.dpdConfiguration?.accountName
    this.accountToken = configuration.dpdConfiguration?.accountToken
    this.isTokenStored =
      Boolean(configuration.dpdConfiguration?.accountToken) ||
      (configuration.dpdConfiguration?.accountName ===
        configuration.dpdAccountInfo?.username &&
      configuration.dpdAccountInfo?.isPasswordStored
        ? true
        : null)
    this.shopSubAccounts = configuration.dpdConfiguration?.shopSubAccounts
      ? configuration.dpdConfiguration?.shopSubAccounts?.map(
          (c, index) => new DPDSubAccountArgsValidation(configuration, index),
        )
      : undefined
  }
}

export class CompanyValidation {
  @IsNotEmpty()
  name: string

  @IsNotEmpty()
  uid: string

  @IsNotEmpty()
  fibuNumber: string

  @IsNotEmpty()
  primaryShopLocationId: string

  @ValidateIf((_, val) => val !== '')
  @IsEmail()
  paypalEmail: string

  @ValidateIf((_, val) => val !== '')
  @IsEmail()
  email: string

  @ValidateIf((_, val) => val !== '')
  @IsEmail()
  supportEmail: string

  @ValidateIf((_, val) => val !== '')
  @IsEmail()
  saleSupportEmail: string

  @ValidateIf((_, val) => val !== '')
  phoneNumber: string

  @ValidateIf((_, val) => val !== '')
  whatsappNumber: string

  @ValidateNested()
  configuration: CompanyConfigurationValidation

  @IsOptional()
  @ValidateNested()
  unzerconfiguration?: CompanyUnzerConfigurationValidation

  @ValidateNested()
  salesConfiguration: CompanySalesConfigurationValidation

  @IsOptional()
  @ValidateNested()
  dpdConfiguration?: CompanyDpdConfigurationValidation

  constructor(company: CompanyArgs) {
    this.name = company.name
    this.uid = company.uid
    this.fibuNumber = company.fibuNumber
    this.paypalEmail = company.paypalEmail ?? ''
    this.email = company.email ?? ''
    this.supportEmail = company.supportEmail ?? ''
    this.saleSupportEmail = company.saleSupportEmail ?? ''
    this.phoneNumber = company.phoneNumber ?? ''
    this.whatsappNumber = company.whatsappNumber ?? ''
    this.primaryShopLocationId = company.primaryShopLocationId
    this.unzerconfiguration = company.unzerConfiguration
      ? new CompanyUnzerConfigurationValidation(company.unzerConfiguration)
      : undefined
    this.configuration = new CompanyConfigurationValidation(
      company.configuration,
    )
    this.salesConfiguration = new CompanySalesConfigurationValidation(
      company.salesConfiguration,
    )
    this.dpdConfiguration = company.dpdConfiguration
      ? new CompanyDpdConfigurationValidation(company)
      : undefined
  }
}
