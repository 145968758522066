import { CashBooks } from '.'
import { connect } from 'react-redux'
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import FullScreenLoading from '@/components/FullScreenLoading'
import SentryRoute from '@/components/SentryRoute'
import { CashBookCounts } from '@/redesign/pages/CashBook/CashBookCounts'
import getPagePath from '@/utils/getPagePath'
import CashBookCashFlows from './cashFlows'

function CashBookRoutes(props: RouteComponentProps & { currentUser: any }) {
  const { currentUser } = props

  return (
    <div>
      {!props.currentUser && <FullScreenLoading />}
      {!!props.currentUser && (
        <Switch>
          <SentryRoute
            path={getPagePath(props, '/cashFlows')}
            component={CashBookCashFlows}
          />
          <SentryRoute
            path={getPagePath(props, '/counts')}
            render={() => <CashBookCounts currentUser={currentUser} />}
          />
          <SentryRoute path={getPagePath(props)} component={CashBooks} />
        </Switch>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentUser: state.user.current,
})

export default connect(mapStateToProps)(withRouter(CashBookRoutes))
