import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationAddItemCategoryIdArgs } from '@/schemaTypes'

const ADD_ITEM_CATEGORY_ID = gql`
  mutation addItemCategoryId($shopId: ObjectId!, $itemCategoryId: ObjectId!) {
    addItemCategoryId(shopId: $shopId, itemCategoryId: $itemCategoryId) {
      _id
      name
      phone
      email
      googleMapsLink
      address {
        ...AddressFragment
      }
      partnerId
      hideLocationOnWebsite
      disabled
      cashBoxNames
      itemCategoryIds
      itemCategories {
        _id
        name
        parentId
      }
      openingHours
    }
  }
  ${fragments.address}
`

type TData = { addItemCategoryId: Mutation['addItemCategoryId'] }
type TVariables = MutationAddItemCategoryIdArgs

export function useAddItemCategoryId(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [addItemCategoryId] = useMutation<TData, TVariables>(
    ADD_ITEM_CATEGORY_ID,
    opts,
  )

  return addItemCategoryId
}
