import { EEuroDenomination } from '@/schemaTypes'

export const moneyPieceValues: Record<EEuroDenomination, number> = {
  EURO_500: 500,
  EURO_200: 200,
  EURO_100: 100,
  EURO_50: 50,
  EURO_20: 20,
  EURO_10: 10,
  EURO_5: 5,
  EURO_2: 2,
  EURO_1: 1,
  EURO_CENT_50: 0.5,
  EURO_CENT_20: 0.2,
  EURO_CENT_10: 0.1,
  EURO_CENT_5: 0.05,
  EURO_CENT_2: 0.02,
  EURO_CENT_1: 0.01,
}
