import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateStorageUnitArgs } from '@/schemaTypes'

const CREATE_STORAGE_UNIT = gql`
  mutation createStorageUnit(
    $name: String!
    $storageFacilityId: ObjectId
    $parentId: ObjectId
    $capacity: Float
  ) {
    createStorageUnit(
      name: $name
      storageFacilityId: $storageFacilityId
      parentId: $parentId
      capacity: $capacity
    ) {
      _id
      name
      storageFacilityId
      childIds
      childUnits {
        _id
        name
        storageFacilityId
        childIds
        childUnits {
          _id
          name
          storageFacilityId
          childIds
          capacity
          isDeleted
        }
        capacity
        isDeleted
      }
      capacity
      isDeleted
    }
  }
`

type TData = { createStorageUnit: Mutation['createStorageUnit'] }
type TVariables = MutationCreateStorageUnitArgs

export function useCreateStorageUnit(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createStorageUnit] = useMutation<TData, TVariables>(
    CREATE_STORAGE_UNIT,
    opts,
  )

  return createStorageUnit
}
