import InputTextarea from '../FormikFields/FormikTextarea/FormikTextarea'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { Button } from 'primereact/button'
import { ToggleButton } from 'primereact/togglebutton'
import { useMemo, useState } from 'react'
import * as Yup from 'yup'
import { Note, NoteUpdateArgs } from '@/schemaTypes'

interface NoteElementProps {
  note: Partial<Note>
  onUpdateNote?: (
    noteId: string,
    note: NoteUpdateArgs,
    chainDealId?: string,
  ) => void
  readOnly?: boolean
  isOwnerNote?: boolean
  hideUserInfo?: boolean
  isToggleNotePopupAllowed?: boolean
}

const NoteElement = ({
  note,
  onUpdateNote,
  readOnly = false,
  isOwnerNote = false,
  hideUserInfo = false,
  isToggleNotePopupAllowed = false,
}: NoteElementProps) => {
  const [editModeActive, setEditModeActive] = useState(false)

  const employeeName = useMemo(
    () =>
      note.employee
        ? `${note.employee?.firstname} ${note.employee?.lastname}`
        : 'Unknown',
    [note.employee],
  )
  const modifiedDate = useMemo(
    () =>
      note.updatedAt
        ? dayjs(note.updatedAt).format('DD.MM.YYYY - HH:mm')
        : 'Unknown Date',
    [note.updatedAt],
  )

  return (
    <Formik
      key={note._id}
      initialValues={{ ...note }}
      enableReinitialize
      validationSchema={NoteValidationSchema}
      onSubmit={(values) => {
        if (!readOnly) {
          onUpdateNote?.(
            values._id,
            { content: values.content },
            values?.dealId,
          )
          setEditModeActive(false)
        }
      }}
    >
      {(props) => {
        return (
          <Form>
            <div className="mb-2.5 w-full">
              <div className="flex items-start mb-1">
                <InputTextareaStyled
                  name="content"
                  className="mr-2.5 w-full"
                  autoResize
                  disabled={!!note.content && !editModeActive}
                  isPopup={!!props.values.isPopup && !readOnly}
                />
                {!readOnly && (
                  <PopupToggleButton
                    onLabel=""
                    offLabel=""
                    onIcon="pi pi-bell"
                    offIcon="pi pi-bell"
                    name="isPopup"
                    checked={!!props.values.isPopup}
                    selected={!!props.values.isPopup}
                    disabled={!isToggleNotePopupAllowed}
                    onChange={(e) => {
                      props.setFieldValue('isPopup', e.value)
                      onUpdateNote?.(
                        note._id,
                        { isPopup: e.value },
                        note?.dealId,
                      )
                    }}
                    className="w-9rem"
                  />
                )}
                {!readOnly && !editModeActive && isOwnerNote && (
                  <Button
                    icon="pi pi-pencil"
                    text
                    severity="secondary"
                    onClick={() => setEditModeActive(true)}
                  />
                )}
                {!readOnly && editModeActive && isOwnerNote && (
                  <>
                    <Button
                      icon="pi pi-check"
                      text
                      onClick={() => props.handleSubmit()}
                    />
                    <Button
                      icon="pi pi-times"
                      text
                      severity="danger"
                      onClick={() => {
                        setEditModeActive(false)
                        props.handleReset()
                      }}
                    />
                  </>
                )}
              </div>

              {!hideUserInfo && (
                <NoteAuthor>
                  {employeeName} - {modifiedDate}
                </NoteAuthor>
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

const NoteValidationSchema = Yup.object().shape({
  content: Yup.string().min(2).required('Required'),
})

const InputTextareaStyled = styled(InputTextarea)<{ isPopup?: boolean }>`
  ${({ isPopup }) =>
    isPopup &&
    `border: 3px solid var(--password-medium-background, #FF9F42); border-radius: 0.375rem;`}
`

const PopupToggleButton = styled(ToggleButton)<{ selected?: boolean }>`
  &&& {
    border: 0;
    background: transparent;
    border-color: transparent;
    color: #ffffff;

    &:not(.p-highlight):hover {
      background: rgba(100, 116, 139, 0.04);
      border-color: transparent;
      color: #64748b;
    }
    &:not(.p-highlight):focus {
      box-shadow: unset;
    }

    &:hover {
      background: #f59e0b;
      border-color: #f59e0b;
      color: #ffffff;
    }

    &:active {
      background: #f59e0b;
      border-color: #f59e0b;
      color: #ffffff;
    }

    &:focus {
      box-shadow: unset;
    }

    .p-button-icon {
      color:;
      color: ${(props) => (props.selected ? '#f59e0b' : '#64748B')};
    }
  }
`

const NoteAuthor = styled.p`
  color: var(--global-text-color, #495057);
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
`

export default NoteElement
