import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdatePiceaUploadLinkArgs } from '@/schemaTypes'

const PARSE_PICEA_TEST = gql`
  mutation parsePiceaTest($itemId: ObjectId!) {
    parsePiceaTest(itemId: $itemId) {
      _id
      piceaTest {
        disable
        error
        data {
          label
          value
        }
        createdAt
      }
    }
  }
`

type TData = { parsePiceaTest: Mutation['parsePiceaTest'] }
type TVariables = MutationUpdatePiceaUploadLinkArgs

export function useParsePiceaTest(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [parsePiceaTest] = useMutation<TData, TVariables>(
    PARSE_PICEA_TEST,
    opts,
  )

  return parsePiceaTest
}
