import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryGetCategoryPagesArgs } from '@/schemaTypes'

const GET_CATEGORY_PAGES = gql`
  query getCategoryPages($opts: CategoryPageOpts) {
    getCategoryPages(opts: $opts) {
      _id
      lvl0ContentCategory
      lvl1ContentCategory
      lvl2ContentCategory
      lvl3ContentCategory
    }
  }
`

type TData = { getCategoryPages: Query['getCategoryPages'] }
type TVariables = QueryGetCategoryPagesArgs

export function useGetCategoryPages(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_CATEGORY_PAGES, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    categoryPages: queryResult.data?.getCategoryPages || [],
  }
}
