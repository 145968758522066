import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'

export const itemValorizationFragments = {
  valorizationHistory: gql`
    fragment ValorizationHistoryEntryFragment on ValorizationHistoryEntry {
      _id
      date
      cashflow
      createdAt
      updatedAt
      itemsIds
      buyerName
      valorizationBillNumber
      buyerPhone
      buyerEmail

      buyerBillingAddress {
        ...BuyerBillingAddressFragment
      }
      buyerShippingAddress {
        ...AddressFragment
      }
      valorizationBillLink {
        url
        contentType
      }
      valorizationCreditNoteLink {
        url
        contentType
      }
      valorizationCreditNoteNumber
      paymentData {
        paymentType {
          ... on ManualPaymentWrapper {
            manual: value
          }
        }
        iban
        paypalAddress
        paypalTransactionId
      }
      order
      customLabel
      logisticsPartnerData {
        type
        trackingLink
        cashy {
          _placeHolder
        }
        logsta {
          logstaNumber
        }
      }
      salesPartnerData {
        type
        cashy {
          _placeHolder
        }
        ebay {
          ebayArticleNumber
          articleDescription
          shippingDate
          shippingCosts
          transactionId
          purchaseProcessingDate
          paidDate
          shippingService
          saleProtocolNumber
          numberOfItems
        }
        refurbed {
          ID
          warranty
          quantity
          shippingCosts
        }
        willhaben {
          willhabenArticleNumber
          articleDescription
          shippingDate
          shippingCosts
          transactionId
          purchaseProcessingDate
          paidDate
          shippingService
          saleProtocolNumber
          numberOfItems
        }
      }
      cumulatedCashflow
      differenceGross
      differenceNet
      differenceTax
      deltaDifferenceTax
      externalSalePriceGross
      externalSalePriceNet
      deviation
      valorizationResult
      sendBill
      isItemReturned
    }
    ${fragments.address}
    ${fragments.buyerBillingAddress}
  `,
  itemValorizationValues: gql`
    fragment ItemValorizationValuesFragment on ItemValorizationValues {
      salePrice
      valorizationDifferenceGross
      valorizationDifferenceTax
      valorizationDifferenceNet
      valorizationDeviation
    }
  `,
}

export const itemFragments = {
  itemValuesEntry: gql`
    fragment ItemValuesEntryFragment on ItemValuesEntry {
      conditionMaxMarketValues {
        excellent
        good
        poor
      }
      maxMarketValue
      currentMarketValue
      predictedMarketValue
      adjustedMarketValue
      loanToValue
      durationInDays
      date
    }
  `,
  itemValorizationCalculation: gql`
    fragment ItemValorizationCalculationFragment on ItemValorizationCalculation {
      valorizationHistory {
        ...ValorizationHistoryEntryFragment
      }
      itemValorizationValues {
        ...ItemValorizationValuesFragment
      }
    }
    ${itemValorizationFragments.valorizationHistory}
    ${itemValorizationFragments.itemValorizationValues}
  `,
  itemQuestionFragment: gql`
    fragment ItemQuestionFragment on ItemQuestion {
      _id
      order
      questionType
      valueMode
      titleKey
      infoKey
      isInternal
      predictionTag
      singleChoiceOptions {
        labelKey
        infoKey
        categoryValues
        conditionValue
        isDefaultValue
      }
      rangeData {
        minValue
        maxValue
        defaultValue
        unitPrice
        unitKey
      }
      itemCategoryIds
    }
  `,
  itemEvent: gql`
    fragment ItemEventFragment on ItemEvent {
      event
      createdAt
      timestamp
      meta {
        notSellableType
        permanentlyNotSellableReason
        temporaryNotSellableReason
        validFrom
        validUntil
        statusReason
        description
        salesPartnerName
        salesPartnerId
        salesPartnerTrackingLink
        logisticPartnerName
        cashyEmployeeId
        cashyEmployee {
          firstname
          lastname
        }
        logisticPartnerTrackingLink
        logisticPartnerTrackingCode
        logisticPartnerLabelFileLink {
          ...FileMetadataFragment
        }
        advertiserPlatformName
        advertisementLink
        isPrerequisites
        valorizationEntryId
        sendNotification
        emailAddress
        offeredPrice
        offeredPriceAt
        creatorId
        opticalCondition
        voluntarySelfDelivery {
          appointment
        }
        derecognizedReason
        destinationShopId
        returnType
        refurbedOffer {
          sku
          lowestPrice
          offeredPrice
          shippingProfileName
          bestBuyBox {
            state
          }
          updatedAt
        }
      }
    }
  `,
}
