import { moneyPieceValues } from '@/domains/deals/components/constants/moneyPieces'
import { CashBookCountCashBoxEntry, EEuroDenomination } from '@/schemaTypes'
import { CashBoxAmounts } from '@/types'

export function calculateCashBoxAmounts(
  cashBoxEntries: CashBookCountCashBoxEntry[],
): CashBoxAmounts {
  const cashBoxAmounts: CashBoxAmounts = {
    totalAmount: 0,
    moneyPiecesAmounts: {
      EURO_500: 0,
      EURO_200: 0,
      EURO_100: 0,
      EURO_50: 0,
      EURO_20: 0,
      EURO_10: 0,
      EURO_5: 0,
      EURO_2: 0,
      EURO_1: 0,
      EURO_CENT_50: 0,
      EURO_CENT_20: 0,
      EURO_CENT_10: 0,
      EURO_CENT_5: 0,
      EURO_CENT_2: 0,
      EURO_CENT_1: 0,
    },
  }

  for (const cashBoxEntry of cashBoxEntries) {
    for (const _moneyPiece of Object.keys(cashBoxEntry.counts).filter(
      (key) => key !== '__typename',
    )) {
      const moneyPiece = _moneyPiece as EEuroDenomination
      const product =
        cashBoxEntry.counts[moneyPiece] * moneyPieceValues[moneyPiece]

      cashBoxAmounts.totalAmount += product
      cashBoxAmounts.moneyPiecesAmounts[moneyPiece] += product
    }
  }

  return cashBoxAmounts
}
