import styled from '@emotion/styled'
import { Button, Checkbox, InputAdornment, Typography } from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextInput from '@/components/TextInput'
import { useCreateMaintenanceWindow } from '@/domains/maintenance/hooks/useCreateMaintenanceWindow'
import { useGetMaintenanceWindow } from '@/domains/maintenance/hooks/useGetMaintenanceWindow'
import { useUpdateMaintenanceWindow } from '@/domains/maintenance/hooks/useUpdateMaintenanceWindow'
import { useMutationShowingErrors } from '@/hooks'

const defaultDuration = 20

export function Mainternance() {
  const { t } = useTranslation()

  const { maintenanceWindow: persistedMaintenanceWindow } =
    useGetMaintenanceWindow()
  const [maintenanceWindow, setMaintenanceWindow] = useState<{
    scheduledStartTime?: Date | null
    isFinished?: boolean
    duration?: number
  } | null>(null)

  const createMaintenanceWindow = useMutationShowingErrors({
    mutation: useCreateMaintenanceWindow(),
    successMessage: t('maintenance_created'),
  })

  const updateMaintenanceWindow = useMutationShowingErrors({
    mutation: useUpdateMaintenanceWindow(),
    successMessage: t('maintenance_updated'),
  })

  useEffect(() => {
    setMaintenanceWindow(
      persistedMaintenanceWindow
        ? {
            ...persistedMaintenanceWindow,
            duration: persistedMaintenanceWindow.duration ?? defaultDuration,
            scheduledStartTime: dayjs(
              persistedMaintenanceWindow.scheduledStartTime,
            ).toDate(),
          }
        : null,
    )
  }, [persistedMaintenanceWindow])
  const handleUpdateMaintenanceWindow = () => {
    if (!persistedMaintenanceWindow?._id) return

    updateMaintenanceWindow({
      variables: {
        id: persistedMaintenanceWindow?._id,
        scheduledStartTime: maintenanceWindow?.scheduledStartTime,
        duration: maintenanceWindow?.duration,
        isFinished: maintenanceWindow?.isFinished,
      },
      refetchQueries: ['getMaintenanceWindow'],
    })
  }

  const handleCreateMaintenanceWindow = () => {
    if (!maintenanceWindow?.scheduledStartTime) return
    createMaintenanceWindow({
      variables: {
        scheduledStartTime: maintenanceWindow.scheduledStartTime,
        duration: maintenanceWindow.duration ?? defaultDuration,
      },
      refetchQueries: ['getMaintenanceWindow'],
    })
  }

  return (
    <div>
      <div>
        <ElementWrapper>
          <Label style={{ width: '210px', marginTop: 4 }}>
            {t('scheduled_start_time')}:
          </Label>

          <KeyboardDateTimePicker
            clearable
            disablePast
            ampm={false}
            value={
              maintenanceWindow?.scheduledStartTime
                ? dayjs(maintenanceWindow.scheduledStartTime)
                : null
            }
            onChange={(date) =>
              setMaintenanceWindow({
                ...(maintenanceWindow ?? {}),
                scheduledStartTime: date?.toDate(),
              })
            }
            error={!maintenanceWindow?.scheduledStartTime ?? false}
          />
        </ElementWrapper>
        <ElementWrapper>
          <TextInput
            type="number"
            value={maintenanceWindow?.duration ?? 20}
            onChange={(value) => {
              setMaintenanceWindow({
                ...(maintenanceWindow ?? {}),
                duration:
                  parseInt(value) > 0
                    ? parseInt(value)
                    : maintenanceWindow?.duration,
              })
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end"> mins</InputAdornment>
              ),
            }}
            label={
              <Label style={{ width: '210px', marginTop: 4 }}>
                {t('duration')}:
              </Label>
            }
          />
        </ElementWrapper>
        {persistedMaintenanceWindow && (
          <ElementWrapper>
            <Label style={{ width: '210px', marginTop: 9 }}>
              {t('maintenance_is_finished')}:
            </Label>
            <Checkbox
              defaultChecked={false}
              value={maintenanceWindow?.isFinished ?? false}
              style={{ marginLeft: -13 }}
              checked={maintenanceWindow?.isFinished ?? false}
              onChange={(e) =>
                setMaintenanceWindow({
                  ...(maintenanceWindow ?? {}),
                  isFinished: e.target.checked,
                })
              }
            />
          </ElementWrapper>
        )}

        {persistedMaintenanceWindow && (
          <Button
            style={{ margin: 10, marginLeft: 0 }}
            onClick={handleUpdateMaintenanceWindow}
            variant="contained"
          >
            {maintenanceWindow?.isFinished ? t('save_and_delete') : t('save')}
          </Button>
        )}
      </div>

      <div style={{ marginTop: 10 }}>
        <Button
          style={{ margin: 10, marginLeft: 0 }}
          onClick={handleCreateMaintenanceWindow}
          variant="contained"
          disabled={
            !!persistedMaintenanceWindow ||
            !maintenanceWindow?.scheduledStartTime
          }
        >
          {t('create_maintenance_window')}
        </Button>
        {persistedMaintenanceWindow && (
          <Typography color="error">
            {t('maintenance_create_warning')}
          </Typography>
        )}
      </div>
    </div>
  )
}

const ElementWrapper = styled(Typography)`
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;

  > div:first-child {
    flex-shrink: 0;
  }
`

const Label = styled.div`
  width: 150px;
  font-weight: 500;
`
