import { useSendReminderUploadingID } from '../hooks/remindUploadingID'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Button } from 'primereact/button'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { Message } from 'primereact/message'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Customer } from '@/gql/graphql'
import { useMutationShowingErrors } from '@/hooks'
import { ERemindUploadIdType } from '@/schemaTypes'

export type IdentityStatus = 'no_id' | 'under_review' | 'verified'

type Props = {
  customer: Pick<
    Customer,
    | '_id'
    | 'identityUploadLinks'
    | 'identityVerifiedAt'
    | 'identityVerifiedBy'
    | 'lastReminderIdentitySentAt'
    | 'identityVerifiedByEmployee'
  >
}

export const IdentityStatusMessage = ({ customer }: Props) => {
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation('common')
  const [last, setLast] = useState(customer.lastReminderIdentitySentAt)
  const sendEmail = useMutationShowingErrors({
    mutation: useSendReminderUploadingID(),
  })

  useEffect(() => {
    setLast(customer.lastReminderIdentitySentAt)
  }, [customer.lastReminderIdentitySentAt])

  const openConfirmingSendEmail = (e) => {
    e.preventDefault()
    setVisible(true)
  }

  const handleSendEmail = async () => {
    const res = await sendEmail({
      variables: {
        customerId: customer._id,
        type: ERemindUploadIdType.MissingId,
      },
    })

    setLast(
      res.data?.sendReminderEmailForUploadingID?.lastReminderIdentitySentAt,
    )
  }

  const status = useMemo<IdentityStatus>(() => {
    if ((customer.identityUploadLinks || []).length === 0) {
      return 'no_id'
    }

    if (!customer.identityVerifiedAt) {
      return 'under_review'
    }

    return 'verified'
  }, [customer])

  if (status === 'no_id') {
    return (
      <>
        <NoIDWrapper className="u-mt-sm">
          <Message
            severity="error"
            text={t('identify_verification.no_id_uploaded')}
          />

          <Button
            type="button"
            label={t('identify_verification.send_reminder_uploadind_id_email')}
            onClick={openConfirmingSendEmail}
            icon="pi pi-send"
            severity="secondary"
            outlined
          />

          <Text className="u-ml-sm">
            {last &&
              t('identify_verification.identity_last_reminded_at', {
                time: dayjs(last).format('YYYY-MM-DD HH:mm'),
              })}
          </Text>
        </NoIDWrapper>
        <ConfirmDialog
          visible={visible}
          style={{
            width: '500px',
            maxWidth: '100vw',
          }}
          onHide={() => setVisible(false)}
          message={
            <div style={{ marginLeft: '-1rem' }}>
              {t('identify_verification.confirm_sending_email_description')}
            </div>
          }
          header={`${t('identify_verification.send_reminder_uploadind_id_email')}?`}
          acceptLabel={t('send_email')}
          rejectLabel={t('abort')}
          rejectIcon="pi pi-times"
          rejectClassName="p-button p-component p-button-text p-button-secondary p-button-sm"
          acceptClassName="p-button p-component p-button-info p-button-sm"
          accept={handleSendEmail}
          acceptIcon="pi pi-send"
        />
      </>
    )
  }

  if (status === 'under_review') {
    return (
      <div className="u-mt-sm">
        <Message
          severity="warn"
          text={t('identify_verification.id_under_review')}
        />
      </div>
    )
  }

  const { firstname, lastname } = customer?.identityVerifiedByEmployee || {}

  return (
    <VerifiedWrapper className="u-mt-sm">
      <Message
        severity="success"
        text={t('identify_verification.id_verified')}
      />
      {t('identify_verification.identity_verified_by', {
        name: `${firstname} ${lastname}`,
        time: dayjs(customer.identityVerifiedAt).format('YYYY-MM-DD HH:mm'),
      })}
    </VerifiedWrapper>
  )
}

const NoIDWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`

const Text = styled.p`
  text-align: left;
  display: inline-block;
  height: 100%;
  color: black;
  font-weight: 400;
  line-height: 14px;
  size: 14px;
`

const VerifiedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`
