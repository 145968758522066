import { useGetShippingProfileList } from '../items/hooks/itemDetails/getAllShippingProfileList'
import styled from '@emotion/styled'
import { Box, Button, MenuItem, Select, Typography } from '@material-ui/core'
import { CloudDownload, Refresh, Save } from '@material-ui/icons'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ShippingProfileOption } from '@/components/ItemStatus/ItemRefurbedOfferedToSellStatus'
import LoadingSpinner from '@/components/LoadingSpinner'
import { Context } from '@/context'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import { ERefurbedProductsImportStatus } from '@/schemaTypes'
import { useAppDispatch, useAppSelector } from '@/store'
import { switchDefaultRefurbedShippingProfile } from '@/store/actions/ui'
import { useGetRefurbedProductsImportStatus } from './hooks/useGetRefurbedProductsImportStatus'
import { useImportNewRefurbedProducts } from './hooks/useImportNewRefurbedProducts'
import { useRefreshShippingProfileList } from './hooks/useRefreshShippingProfileList'

function RefurbedOffers() {
  const { closeConfirmModal, showInfo } = useContext(Context)
  const { t } = useTranslation()
  const defaultRefurbedShippingProfile = useAppSelector(
    (state) => state.ui.defaultRefurbedShippingProfile,
  )
  const dispatch = useAppDispatch()

  const [refreshingShippingProfiles, setRefreshingShippingProfiles] =
    useState(false)
  const [defaultShippingProfile, setDefaultShippingProfile] = useState(
    defaultRefurbedShippingProfile,
  )

  const { getRefurbedProductsImportStatusResult } =
    useGetRefurbedProductsImportStatus()

  const handleShippingProfileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDefaultShippingProfile(e.target.value)
  }

  const saveDefaultShippingProfile = () => {
    dispatch(switchDefaultRefurbedShippingProfile(defaultShippingProfile))
    showInfo(t('update_successful'))
  }

  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })

  const { getShippingProfileList } = useGetShippingProfileList({
    fetchPolicy: 'cache-first',
  })

  const refreshShippingProfileList = useMutationShowingErrors({
    mutation: useRefreshShippingProfileList(),
    successMessage: t('shipping_profile_list_refreshed'),
  })

  const handleRefreshShippingProfiles = useCallback(() => {
    showConfirmPopup({
      title: t('confirm_refresh_shipping_profiles'),
      action: async () => {
        closeConfirmModal()
        setRefreshingShippingProfiles(true)
        await refreshShippingProfileList({})
        setRefreshingShippingProfiles(false)
      },
    })
  }, [closeConfirmModal, refreshShippingProfileList, showConfirmPopup, t])

  const shippingProfileOptions: ShippingProfileOption[] = useMemo(() => {
    return getShippingProfileList.map((profile) => ({
      key: profile.profileId,
      value: `${profile.name} - ${profile.sourceCountryMarketCode.toUpperCase()} (Destinations: ${profile.destinations
        .map((d) => d.marketCode.toUpperCase())
        .join(' - ')})`,
    }))
  }, [getShippingProfileList])

  const importNewProducts = useMutationShowingErrors({
    mutation: useImportNewRefurbedProducts({
      refetchQueries: ['getRefurbedProductsImportStatus'],
    }),
    successMessage: () => t('refurbed_products_import_is_running'),
  })

  const handleImportNewProducts = useCallback(async () => {
    await importNewProducts({})
  }, [importNewProducts])

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h5">{t('refurbed_offers')}</Typography>

        <Button
          color="primary"
          variant="contained"
          startIcon={<Refresh />}
          disabled={refreshingShippingProfiles}
          onClick={handleRefreshShippingProfiles}
        >
          {t('refresh_shipping_profiles')}
          {refreshingShippingProfiles && <LoadingSpinner />}
        </Button>
      </Box>

      <Section>
        <Typography variant="subtitle1">
          {t('available_shipping_profiles')}
        </Typography>

        <Select
          fullWidth
          className="select"
          value={defaultShippingProfile}
          onChange={handleShippingProfileChange}
          name="shippingProfile"
        >
          {shippingProfileOptions.map((g) => (
            <MenuItem key={g.key} value={g.key}>
              {g.value}
            </MenuItem>
          ))}
        </Select>

        <Button
          color="primary"
          variant="contained"
          startIcon={<Save />}
          onClick={saveDefaultShippingProfile}
          disabled={defaultRefurbedShippingProfile === defaultShippingProfile}
        >
          {t('save_default_profile')}
        </Button>
      </Section>

      <Section>
        <Typography variant="subtitle1">{t('refurbed_products')}</Typography>

        <Button
          color="primary"
          variant="contained"
          startIcon={<CloudDownload />}
          onClick={handleImportNewProducts}
          disabled={
            getRefurbedProductsImportStatusResult &&
            getRefurbedProductsImportStatusResult.status ===
              ERefurbedProductsImportStatus.Running
          }
        >
          {t(
            getRefurbedProductsImportStatusResult?.status ===
              ERefurbedProductsImportStatus.Running
              ? 'refurbed_importing'
              : 'import_new_refurbed_products',
          )}
        </Button>
        {getRefurbedProductsImportStatusResult?.status ===
          ERefurbedProductsImportStatus.Running && (
          <Typography>
            {t('import_new_refurbed_products_count', {
              addedProductsCount:
                getRefurbedProductsImportStatusResult.addedProductsCount,
            })}
          </Typography>
        )}
      </Section>
    </div>
  )
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  row-gap: 20px;
  margin-bottom: 48px;
`

export default RefurbedOffers
