import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationRemoveItemFromStorageUnitArgs } from '@/schemaTypes'

const REMOVE_ITEM_FROM_STORAGE_UNIT = gql`
  mutation removeItemFromStorageUnit(
    $itemId: ObjectId!
    $storageUnits: ItemStorageUnitsArgs!
    $storageDate: DateTime
    $note: String
  ) {
    removeItemFromStorageUnit(
      itemId: $itemId
      storageUnits: $storageUnits
      storageDate: $storageDate
      note: $note
    ) {
      _id
      storageHistory {
        storageUnits {
          lvl1
          lvl2
          lvl3
          lvl4
        }
        createdAt
        timestamp
        storageStatus
        employeeId
        note
      }
      storageLabel
    }
  }
`

type TData = {
  removeItemFromStorageUnit: Mutation['removeItemFromStorageUnit']
}
type TVariables = MutationRemoveItemFromStorageUnitArgs

export function useRemoveItemFromStorageUnit(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [removeItemFromStorageUnit] = useMutation<TData, TVariables>(
    REMOVE_ITEM_FROM_STORAGE_UNIT,
    opts,
  )

  return removeItemFromStorageUnit
}
