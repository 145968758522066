import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '@/redesign/components/Input/Input'
import { DpdSubAccountArgs, Scalars, Shop } from '@/schemaTypes'
import { CompanyDpdConfigurationValidation } from '@/validation/CompanyValidation'
import { CompanyArgs } from './CompaniesDetails'

interface CompanyDPDConfigurationDetailsProps {
  shops: Shop[]
  company?: CompanyArgs | undefined
  onCompanyChange: (value: CompanyArgs | undefined) => void
  validationErrorsMap?: Partial<
    Record<keyof CompanyDpdConfigurationValidation, any>
  >
}

const CompanyDPDConfigurationDetails: FunctionComponent<
  CompanyDPDConfigurationDetailsProps
> = (props: CompanyDPDConfigurationDetailsProps) => {
  const { t } = useTranslation()
  const { company, onCompanyChange, shops, validationErrorsMap } = props

  const countryCode = company?.headquarter?.countryCode

  const onSubAccountChange = (
    shopId: Scalars['ObjectId'],
    userId: string,
    userToken: string,
  ) => {
    const newShopSubAccount: DpdSubAccountArgs[] = [
      ...(company?.dpdConfiguration?.shopSubAccounts ?? []),
    ]
    const index = company?.dpdConfiguration?.shopSubAccounts?.findIndex(
      (c) => c.shopId === shopId,
    )

    if (index >= 0) {
      newShopSubAccount[index] = {
        shopId,
        userId,
        userToken,
      }
    } else {
      newShopSubAccount.push({
        shopId,
        userId,
        userToken,
      })
    }

    onCompanyChange({
      ...company,
      dpdConfiguration: {
        ...company?.dpdConfiguration,
        shopSubAccounts: newShopSubAccount,
      },
    })
  }

  const isInvalidAccount =
    !!validationErrorsMap?.accountToken || !!validationErrorsMap?.isTokenStored
  return (
    <>
      <div className="flex flex-row">
        <StyledTitle>{`DPD ${countryCode}`}</StyledTitle>
      </div>

      <div className="flex flex-row mb-2">
        <Input
          // TODO: Dejan - Please check this in redesign. I could not set the width using className
          style={{ width: '43.5rem' }}
          label={t('company.configuration.api_keys.webservice_api')}
          className="flex flex-col md:flex-row items-start md:items-center "
          labelClassName="text-sm font-semibold w-12.5"
          value={company.dpdAccountInfo?.apiEndpoint}
          readOnly
        />
      </div>

      <div className="flex flex-row mb-6">
        <div className="flex flex-col mb-1  mr-20">
          <Input
            label={t(
              countryCode === 'AT'
                ? 'company.configuration.api_keys.username'
                : 'company.configuration.api_keys.partner_credential_name',
            )}
            className="flex flex-col md:flex-row items-start md:items-center"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
            value={company?.dpdConfiguration?.accountName}
            onChange={(e) =>
              onCompanyChange({
                ...company,
                dpdConfiguration: {
                  ...company?.dpdConfiguration,
                  accountName: e.target.value,
                  accountToken: undefined,
                },
              })
            }
            isInvalid={!!validationErrorsMap?.accountName}
          />
        </div>
        <div className="flex flex-col mb-1">
          <Input
            label={t(
              countryCode === 'AT'
                ? 'company.configuration.api_keys.password'
                : 'company.configuration.api_keys.partner_credential_token',
            )}
            className="flex flex-col md:flex-row items-start md:items-center"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
            placeholder={t(
              company.dpdAccountInfo?.isPasswordStored && !isInvalidAccount
                ? 'company.configuration.api_keys.already_stored'
                : 'company.configuration.api_keys.not_stored',
            )}
            value={company?.dpdConfiguration?.accountToken}
            onChange={(e) =>
              onCompanyChange({
                ...company,
                dpdConfiguration: {
                  ...company?.dpdConfiguration,
                  accountToken: e.target.value,
                },
              })
            }
            isInvalid={isInvalidAccount}
          />
        </div>
      </div>
      {countryCode === 'DE' &&
        shops
          .filter((c) => c.companyId === company?._id)
          ?.map((shop, index) => {
            const subAccount = company?.dpdConfiguration?.shopSubAccounts?.find(
              (c) => c.shopId === shop._id,
            )
            const subAccountInfo =
              company.dpdAccountInfo?.subAccountsInfo?.find(
                (c) => c.shopId === shop._id,
              )

            const isInvalidSubAccount =
              validationErrorsMap?.shopSubAccounts &&
              (!!validationErrorsMap?.shopSubAccounts[`${index}`]?.userToken ||
                !!validationErrorsMap?.shopSubAccounts[`${index}`]
                  ?.isTokenStored)

            return (
              <div key={shop._id}>
                <div className="flex flex-row ">
                  <StyledTitle>{shop.name}</StyledTitle>
                </div>
                <div className="flex flex-row mb-1">
                  <div className="flex flex-col mb-1  mr-20">
                    <Input
                      label={t('company.configuration.api_keys.cloud_user_id')}
                      className="flex flex-col md:flex-row items-start md:items-center"
                      labelClassName="text-sm font-semibold w-12.5"
                      inputContainerClassName="w-52"
                      value={subAccount?.userId}
                      onChange={(e) =>
                        onSubAccountChange(shop._id, e.target.value, '')
                      }
                      isInvalid={
                        validationErrorsMap?.shopSubAccounts &&
                        !!validationErrorsMap?.shopSubAccounts[`${index}`]
                          ?.userId
                      }
                    />
                  </div>
                  <div className="flex flex-col mb-1">
                    <Input
                      label={t(
                        'company.configuration.api_keys.cloud_user_token',
                      )}
                      className="flex flex-col md:flex-row items-start md:items-center"
                      labelClassName="text-sm font-semibold w-12.5"
                      inputContainerClassName="w-52"
                      value={subAccount?.userToken}
                      placeholder={t(
                        subAccountInfo?.isPasswordStored && !isInvalidSubAccount
                          ? 'company.configuration.api_keys.already_stored'
                          : 'company.configuration.api_keys.not_stored',
                      )}
                      onChange={(e) =>
                        onSubAccountChange(
                          shop._id,
                          subAccount?.userId,
                          e.target.value,
                        )
                      }
                      isInvalid={isInvalidSubAccount}
                    />
                  </div>
                </div>
              </div>
            )
          })}
    </>
  )
}

export default CompanyDPDConfigurationDetails

const StyledTitle = styled.p`
  font-weight: 500;
  font-sze: 17.5px;
`
