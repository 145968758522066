import { useGetActiveCompanies } from '../../companies/hooks'
import { TData, TVariables, useGetShops } from '../hooks'
import { ApolloQueryResult } from '@apollo/client'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from '@material-ui/core'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandableAddress from '@/components/ExpandableAddress/ExpandableAddress'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectOptionInput, {
  MenuItemOption,
} from '@/components/SelectOptionInput'
import TextInput from '@/components/TextInput'
import TextInputList from '@/components/TextInputList'
import { Context } from '@/context'
import { useValidationErrorMap } from '@/hooks'
import { Ready2OrderPanel } from '@/redesign/domains/shops/components/Ready2OrderPanel'
import {
  ECashBookPaymentReference,
  Partner,
  R2OProductMap,
  Shop,
} from '@/schemaTypes'
import { ShopValidation } from '@/validation'
import ShopStorageConfiguration from './ShopStorageConfiguration'

export type ShopValidationErrorObject = Partial<
  Pick<Shop, 'name' | 'phone' | 'email' | 'openingHours'>
>
export interface ShopValidationError {
  fieldName: keyof ShopValidationErrorObject
  message: string
}

export interface ShopsDetailsProps {
  shop?: Shop
  partners: Partner[]
  onSave: (shop: Shop) => void
  requireCompanyId?: boolean
  shopRefetch?: (
    variables?: Partial<TVariables> | undefined,
  ) => Promise<ApolloQueryResult<TData>>
  addOrUpdateIsInProgress?: boolean
}

export function ShopsDetails(props: ShopsDetailsProps) {
  const {
    shop: persistedShop,
    partners,
    requireCompanyId = true,
    shopRefetch,
    addOrUpdateIsInProgress,
  } = props
  const { showConfirmModal, closeConfirmModal } = useContext(Context)

  const { t } = useTranslation()

  const { shops } = useGetShops()

  const [shop, setShop] = useState<Partial<Shop> | undefined>(() =>
    shopToData(persistedShop),
  )
  const { validationErrorsMap, isValid } = useValidationErrorMap(
    shop ?? {},
    ShopValidation,
    !requireCompanyId ? ['companyId'] : undefined,
  )

  const { companies } = useGetActiveCompanies()

  useEffect(() => {
    setShop(shopToData(persistedShop))
  }, [persistedShop])

  const onReset = () => {
    setShop(shopToData(persistedShop))
  }

  const onSave = () => {
    if (isValid) {
      if (shop?.disabled && !persistedShop?.disabled) {
        showConfirmModal({
          title: t('shop.shop_disabled_warning'),
          leftButtonText: t('abort'),
          rightButtonText: t('continue'),
          leftButtonAction: () => {
            closeConfirmModal()
            setShop({ ...shop, disabled: false, successionShopId: null })
          },
          rightButtonAction: () => {
            closeConfirmModal()
            props.onSave(shop as Shop)
          },
        })
      } else props.onSave(shop as Shop)
    }
  }

  const filteredShops = useMemo(() => {
    if (!shop) {
      return []
    }
    const locations: MenuItemOption<string>[] = shops
      .filter((s) => s._id !== shop._id)
      .map((shop) => ({ label: shop.name, value: shop._id }))
    if (shop.successionShop?.disabled) {
      locations.push({
        label: shop.successionShop.name,
        value: shop.successionShop._id,
        disabled: true,
      })
    }
    return locations
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shops])

  return (
    <Fragment>
      <TextInput
        error={validationErrorsMap.name}
        value={shop?.name}
        onChange={(value) => setShop({ ...(shop ?? {}), name: value })}
        label={t('name')}
        inputWidth="50%"
      />
      <TextInput
        error={validationErrorsMap.phone}
        value={shop?.phone}
        onChange={(value) => setShop({ ...(shop ?? {}), phone: value })}
        label={t('customer.phone')}
        inputWidth="50%"
      />
      <TextInput
        error={validationErrorsMap.email}
        value={shop?.email}
        onChange={(value) => setShop({ ...(shop ?? {}), email: value })}
        label={t('customer.email')}
        inputWidth="50%"
      />
      {requireCompanyId && (
        <SelectOptionInput
          error={validationErrorsMap.companyId}
          value={shop?.companyId}
          onChange={(value) => setShop({ ...(shop ?? {}), companyId: value })}
          label={t('company.singular')}
          menuItems={companies.map((company) => ({
            label: company.name,
            value: company._id,
          }))}
          inputWidth="50%"
        />
      )}
      <TextInput
        error={validationErrorsMap.googleMapsLink}
        value={shop?.googleMapsLink}
        onChange={(value) =>
          setShop({ ...(shop ?? {}), googleMapsLink: value })
        }
        label={t('google_maps_link')}
        multiline={true}
        inputWidth="50%"
      />
      <SelectOptionInput
        value={shop?.partnerId}
        onChange={(value) => setShop({ ...(shop ?? {}), partnerId: value })}
        label={t('partner')}
        menuItems={[
          { name: '<Kein Partner>', _id: undefined },
          ...partners,
        ].map((partner) => ({ label: partner.name, value: partner._id }))}
        inputWidth="50%"
      />
      <Typography variant="h5" gutterBottom>
        <TextInput
          multiline={true}
          rows={5}
          rowsMax={10}
          error={validationErrorsMap.openingHours}
          value={shop?.openingHours}
          onChange={(value) =>
            setShop({ ...(shop ?? {}), openingHours: value })
          }
          label={t('opening_hours')}
          inputWidth="50%"
        />
      </Typography>
      <FormGroup>
        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Checkbox
                checked={!shop?.hideLocationOnWebsite}
                onChange={(e) => {
                  setShop({
                    ...(shop ?? {}),
                    hideLocationOnWebsite: !e.target.checked,
                  })
                }}
              />
            }
            label={t('display_on_website')}
          />
          <FormHelperText>{t('display_on_website_info')}</FormHelperText>
        </FormControl>
      </FormGroup>
      <FormGroup>
        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Checkbox
                checked={!!shop?.disabled}
                onChange={(e) => {
                  setShop({ ...(shop ?? {}), disabled: e.target.checked })
                }}
              />
            }
            label={t('shop.shop_disabled')}
          />
        </FormControl>
      </FormGroup>
      <>
        <SelectOptionInput
          error={validationErrorsMap.successionShopId}
          value={shop?.successionShopId}
          onChange={(value) => setShop({ ...shop, successionShopId: value })}
          label={t('shop.SUCCESSION_SHOP')}
          menuItems={filteredShops}
          hasNone
          inputWidth="50%"
        />
        {shop?.successionShop?._id === shop?.successionShopId &&
          shop?.successionShop?.disabled && (
            <FormHelperText error>
              {t('succession_shop_disabled_info')}
            </FormHelperText>
          )}
      </>
      <Typography variant="body1" gutterBottom className="u-pv">
        <TextInputList
          values={shop?.cashBoxNames ?? []}
          label={t('cash_boxes')}
          onAdd={(cashBoxName) =>
            setShop({
              ...(shop ?? {}),
              cashBoxNames: [...(shop?.cashBoxNames ?? []), cashBoxName],
            })
          }
          onDelete={(index) => {
            const newCashBoxNames = [...(shop?.cashBoxNames ?? [])]

            newCashBoxNames.splice(index, 1)

            setShop({ ...(shop ?? {}), cashBoxNames: newCashBoxNames })
          }}
          unique
          inputWidth="50%"
        />
      </Typography>
      <ShopStorageConfiguration
        shop={persistedShop}
        shopRefetch={shopRefetch}
        inputWidth="50%"
      />
      <div className="u-pv">
        <ExpandableAddress
          title={t('address.label')}
          address={shop?.address}
          onChangeAddress={(value) =>
            setShop({ ...(shop ?? {}), address: value })
          }
        />
      </div>

      <Ready2OrderPanel
        shop={shop}
        setShop={setShop}
        onR2OApiKeySaved={() => {
          shopRefetch()
        }}
      />

      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          onClick={onSave}
          variant="contained"
          disabled={!isValid || addOrUpdateIsInProgress}
        >
          {t('save')}
        </Button>
        <Button onClick={onReset} variant="contained" className="u-ml-10">
          {t('reset')}
        </Button>
        {addOrUpdateIsInProgress && (
          <div style={{ marginLeft: 20 }}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default ShopsDetails

const defaultR2OProductMaps: R2OProductMap[] = [
  {
    paymentReference: 'FEES_AND_INTEREST',
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.ValorizationSale,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.Payout,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.Payback,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.Extension,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.BankWithdrawal,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.ExtensionPayout,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.Bill,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.SurplusPayout,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.Tipico,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.MiscellaneousBooking,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.PrivateSale,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.AuctionProceeds,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.OtherRevenue,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.MarketingCreditNote,
    productName: null,
  },
  {
    paymentReference: ECashBookPaymentReference.ValorizationCreditNote,
    productName: null,
  },
]

const shopToData = (shop?: Shop): Partial<Shop> => ({
  ...(shop ?? {}),
  companyId: shop?.company._id,
  hideLocationOnWebsite: shop?.hideLocationOnWebsite ?? false,
  r2oProductMaps: shop?.r2oProductMaps ?? defaultR2OProductMaps,
})
