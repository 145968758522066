import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateItemNoteArgs } from '@/schemaTypes'

const UPDATE_ITEM_NOTE = gql`
  mutation updateItemNote(
    $itemId: ObjectId!
    $noteId: ObjectId!
    $noteUpdateArgs: NoteUpdateArgs!
  ) {
    updateItemNote(
      itemId: $itemId
      noteId: $noteId
      noteUpdateArgs: $noteUpdateArgs
    ) {
      _id
    }
  }
`

type TData = { updateNote: Mutation['updateItemNote'] }
type TVariables = MutationUpdateItemNoteArgs

export function useUpdateItemNote(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateNote] = useMutation<TData, TVariables>(UPDATE_ITEM_NOTE, opts)

  return updateNote
}
