import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationRefreshItemValorizationsArgs } from '@/schemaTypes'
import { dealCalculationFragments, dealFragments } from './dealFragments'

const REFRESH_ITEM_VALORIZATIONS = gql`
  mutation refreshItemValorizations($itemId: ObjectId!) {
    refreshItemValorizations(itemId: $itemId) {
      _id
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
    }
  }
  ${dealFragments.dealItem}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = { refreshItemValorizations: Mutation['refreshItemValorizations'] }
type TVariables = MutationRefreshItemValorizationsArgs

export function useRefreshItemValorizations(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [refreshItemValorizations] = useMutation<TData, TVariables>(
    REFRESH_ITEM_VALORIZATIONS,
    opts,
  )
  return refreshItemValorizations
}
