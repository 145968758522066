import { KeyboardDatePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'
import SaveIcon from '@/images/save.svg'
import styles from './Item.module.scss'

export interface DateRowProps {
  label: string
  value?: Date
  onChange: (value?: Date | null) => void
  hasAsterisk?: boolean
}

const DateRow: React.FC<DateRowProps> = ({
  label,
  value,
  onChange,
  hasAsterisk,
}) => {
  const [date, setDate] = useState(value)

  return (
    <tr style={{ width: '100%' }}>
      <td style={{ width: '40%' }}>
        {label}
        {hasAsterisk && <span className={styles.alert}>*</span>}:
      </td>
      <td style={{ width: '60%' }}>
        <KeyboardDatePicker
          className={`${styles.datePicker} u-ml-5`}
          clearable
          format="DD.MM.YYYY"
          value={date ? dayjs(date) : null}
          onChange={(v) => setDate(v?.toDate())}
        />
        <div
          onClick={() => onChange(date ?? null)}
          className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
        >
          <ReactSVG className={`${styles.infoIcon} u-pl-15`} src={SaveIcon} />
        </div>
      </td>
    </tr>
  )
}

export default DateRow
