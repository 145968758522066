import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Mutation,
  MutationAddValorizationHistoryEntryToDealItemArgs,
} from '@/schemaTypes'
import { dealCalculationFragments, dealFragments } from './dealFragments'

const ADD_VALORIZATION_HISTORY_ENTRY_TO_DEAL_ITEM = gql`
  mutation addValorizationHistoryEntryToDealItem(
    $dealId: ObjectId!
    $itemId: ObjectId!
    $shopId: ObjectId!
    $args: ValorizationEntryCreateArgs!
  ) {
    addValorizationHistoryEntryToDealItem(
      dealId: $dealId
      itemId: $itemId
      shopId: $shopId
      args: $args
    ) {
      _id
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
    }
  }
  ${dealFragments.dealItem}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = {
  addValorizationHistoryEntryToDealItem: Mutation['addValorizationHistoryEntryToDealItem']
}
type TVariables = MutationAddValorizationHistoryEntryToDealItemArgs

export function useAddValorizationHistoryEntryToDealItem(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  return useMutation<TData, TVariables>(
    ADD_VALORIZATION_HISTORY_ENTRY_TO_DEAL_ITEM,
    opts,
  )
}
