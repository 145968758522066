import { IconButton, Tooltip, Typography } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import DeleteIcon from '@material-ui/icons/Delete'
import React from 'react'

interface EnhancedToolbarProps {
  selected: any[]
  onDelete: (selected: any[]) => void
}

const EnhancedToolbar = (props: EnhancedToolbarProps) => {
  const { selected, onDelete } = props

  const numSelected = selected.length

  return (
    <Toolbar>
      {numSelected > 0 && (
        <Typography color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon onClick={() => onDelete(selected)} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

export default EnhancedToolbar
