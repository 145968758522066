import { PageInfoSkipLimit } from '@/schemaTypes'

export enum SubscriptionEvent {
  Add = 'ADD',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

interface IPageginated<T> {
  nodes: T[]
  pageInfo: PageInfoSkipLimit
}

export const handlePaginatedSubscription = <T>(
  data: IPageginated<T>,
  element: T,
  event: SubscriptionEvent,
) => {
  switch (event) {
    case SubscriptionEvent.Add:
      data.nodes.unshift(element)
      return data

    default:
      return data
  }
}
