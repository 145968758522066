import DateHourPicker, { DateHourPickerProps } from '../DateHourPicker'
import { Typography } from '@material-ui/core'

export interface ValidFromToDateHourPickerProps {
  error?: string
  minDate?: Date
  value: Date
  onDateChange: DateHourPickerProps['onDateChange']
  onHourChange: DateHourPickerProps['onHourChange']
  dateLabel: string
  timeLabel: string
}

export default function ValidFromToDateHourPicker(
  props: ValidFromToDateHourPickerProps,
) {
  const {
    error,
    minDate,
    value,
    onDateChange,
    onHourChange,
    dateLabel,
    timeLabel,
  } = props

  return (
    <Typography variant="body1" gutterBottom className="u-flex u-ai-center">
      <DateHourPicker
        dateLabel={dateLabel}
        timeLabel={timeLabel}
        minDate={minDate}
        value={value}
        onDateChange={onDateChange}
        onHourChange={onHourChange}
        hasError={!!error ?? false}
      />
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </Typography>
  )
}
