import ShippingLabelComponent from '../ShippingLabelComponent'
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
} from '@material-ui/core'
import dayjs from 'dayjs'
import { Fragment, useContext, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { Context } from '@/context'
import SaveIcon from '@/images/save.svg'
import {
  EItemReturnType,
  EItemStatusType,
  ELogisticsPartnerType,
  EReturnReasons,
  EShippingLabelDirection,
  EShippingLabelEntity,
  EStandardShipmentType,
  Item,
  ItemEvent,
  ShippingLabelCreateArgs,
} from '@/schemaTypes'
import { EHintType, findRelatedEmailForItemEvent } from './ItemStatus'
import styles from './ItemStatus.module.scss'

export interface ItemReturnRequestedStatusProps {
  item: Item
  onChangeItemStatus: (
    newEvent: ItemEvent,
    hint: EHintType,
    shippingLabelCreateArgs?: ShippingLabelCreateArgs | undefined,
  ) => void
}

const ItemReturnRequestedStatus: FunctionComponent<
  ItemReturnRequestedStatusProps
> = (props) => {
  const { onChangeItemStatus, item } = props
  const { showInfo } = useContext(Context)
  const { t } = useTranslation()
  const logisticPartnersList = Object.values(ELogisticsPartnerType)
  const standardShipmentTyped = Object.values(EStandardShipmentType)
  const finalList: string[] = []
  logisticPartnersList.map((c) => finalList.push(c as string))
  standardShipmentTyped.map((c) => finalList.push(c as string))
  finalList.push('OTHER')

  const returnReasonsList = [
    { label: '', value: '' },
    { label: t('technical_error'), value: EReturnReasons.TechnicalError },
    { label: t('account_lock'), value: EReturnReasons.AccountLock },
    { label: t('optical_error'), value: EReturnReasons.OpticalError },
    { label: t('wrong_product'), value: EReturnReasons.WrongProduct },
    {
      label: t('return_for_no_reason'),
      value: EReturnReasons.ReturnForNoReason,
    },
    { label: t('fake'), value: EReturnReasons.Fake },
    { label: t('other'), value: EReturnReasons.Other },
  ]

  // const [logisticPartner, setLogisticPartner] = useState('')
  // const [logisticPartnerTrackingCode, setLogisticPartnerTrackingCode] = useState('')
  // const [logisticPartnerTrackingLink, setLogisticPartnerTrackingLink] = useState('')
  const [sendNotification, setSendNotification] = useState(true)
  const [returnReason, setReturnReason] = useState('')
  const [description, setDescription] = useState('')
  const [email, setEmail] = useState(findRelatedEmailForItemEvent(item))
  const [shippingLabelCreateArgs, setShippingLabelCreateArgs] =
    useState<ShippingLabelCreateArgs>()
  const [shippingLabelCreateArgsIsValid, setShippingLabelCreateArgsIsValid] =
    useState(false)
  const [itemReturnType, setItemReturnType] = useState(
    EItemReturnType.ReturnedViaShipment,
  )

  const saveNewStatus = () => {
    // if (logisticPartner === '') {
    //   return showInfo(t('logistic_partner_is_not_set'))
    // }
    if (
      itemReturnType === EItemReturnType.ReturnedViaShipment &&
      !shippingLabelCreateArgsIsValid
    ) {
      return showInfo(t('shipping_label_is_not_provided_correctly'))
    } else if (returnReason === '') {
      return showInfo(t('return_reason_is_not_set'))
    } else if (email === '' && sendNotification) {
      return showInfo(t('email_address_is_not_set'))
    }

    const now = dayjs().toDate()
    onChangeItemStatus(
      {
        createdAt: now,
        timestamp: now,
        event: EItemStatusType.ReturnRequested,
        meta: {
          logisticPartnerName: shippingLabelCreateArgs?.shippingPartner,
          logisticPartnerTrackingLink:
            shippingLabelCreateArgs?.trackingLink as string,
          logisticPartnerTrackingCode:
            shippingLabelCreateArgs?.trackingCode as string,
          logisticPartnerLabelFile: shippingLabelCreateArgs?.labelFile,
          sendNotification: sendNotification,
          emailAddress: email,
          statusReason: returnReason as string,
          description: description,
          returnType: itemReturnType,
        },
      },
      EHintType.NONE,
      shippingLabelCreateArgs,
    )
  }

  return (
    <Fragment>
      <div
        onClick={saveNewStatus}
        className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
      >
        <ReactSVG className={`${styles.saveIcon} u-pl-15`} src={SaveIcon} />
      </div>
      <table>
        <tr className={`${styles.notSellableTr}`}>
          <td>
            <br />
            {t('status_reason')}:
            <Select
              className="u-ml-sm"
              value={returnReason}
              onChange={(e) => {
                setReturnReason(e.target.value as string)
              }}
            >
              {returnReasonsList.map((name) => (
                <MenuItem key={name.label} value={name.value}>
                  {name.label}
                </MenuItem>
              ))}
            </Select>
          </td>
        </tr>

        <tr className={`${styles.notSellableTr}`}>
          <RadioGroup
            value={itemReturnType}
            onChange={(event) => {
              setItemReturnType(event.target.value as EItemReturnType)
              setShippingLabelCreateArgs(undefined)
            }}
          >
            <FormControlLabel
              value={EItemReturnType.ReturnedInShop}
              control={<Radio />}
              label={t('returned_in_shop')}
            />
            <FormControlLabel
              value={EItemReturnType.ReturnedViaShipment}
              control={<Radio />}
              label={t('returned_via_shipment')}
            />
          </RadioGroup>
        </tr>

        {itemReturnType === EItemReturnType.ReturnedViaShipment && (
          <tr className={`${styles.notSellableTr}`}>
            <ShippingLabelComponent
              dealId={item.dealsHistory[item.dealsHistory.length - 1].dealId}
              item={item}
              entityType={EShippingLabelEntity.Item}
              directionType={EShippingLabelDirection.Receive}
              shippingLabelCreateArgs={shippingLabelCreateArgs}
              setShippingLabelCreateArgs={setShippingLabelCreateArgs}
              logisticServiceProviders={finalList}
              shippingLabelCreateArgsIsValid={shippingLabelCreateArgsIsValid}
              setShippingLabelCreateArgsIsValid={
                setShippingLabelCreateArgsIsValid
              }
              customerEmailAddress={sendNotification ? email : null}
              betweenShops={false}
            />
          </tr>
        )}

        <tr className={`${styles.notSellableTr}`}>
          <td>
            {sendNotification ? <br /> : ''}
            {t('notify_customer')}:
            <Checkbox
              checked={sendNotification}
              onChange={(e) => setSendNotification(e.target.checked)}
            />
            <div hidden={!sendNotification}>
              {t('customer.email')}:
              <TextField
                style={{
                  marginLeft: '0.5rem',
                  marginTop: '-0.5rem',
                  width: '70%',
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </td>
        </tr>

        <tr className={`${styles.notSellableTr}`}>
          <td>
            {sendNotification ? <br /> : ''}
            {t('description')}:
            <TextareaAutosize
              rowsMin={4}
              placeholder={t('any_description_want_to_add')}
              style={{ width: '100%' }}
              onChange={(e) => setDescription(e.target.value)}
            />
          </td>
        </tr>
      </table>
    </Fragment>
  )
}

export default ItemReturnRequestedStatus
