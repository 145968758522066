import styled from '@emotion/styled'
import { ProgressSpinner } from 'primereact/progressspinner'

interface ScreenLoaderProps {
  isLoading: boolean
}

const ScreenLoader = ({ isLoading }: ScreenLoaderProps) => {
  if (!isLoading) return null

  return (
    <ScreenLoaderWrapper>
      <ProgressSpinnerStyled />
    </ScreenLoaderWrapper>
  )
}

const ScreenLoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`

const ProgressSpinnerStyled = styled(ProgressSpinner)`
  width: 5rem;
  height: 5rem;
`
export default ScreenLoader
