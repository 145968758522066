import { Button, Paper, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import styles from '@/domains/itemCategories/components/ItemCategoriesConfiguration.module.scss'
import ItemCategoriesTreeView from '@/domains/itemCategories/components/ItemCategoriesTreeview'
import {
  includeHidedCategories,
  useGetItemCategories,
} from '@/domains/itemCategories/hooks'

export function ItemCategoriesTable() {
  const { t } = useTranslation()

  const { itemCategories, queryResult } = useGetItemCategories(
    includeHidedCategories,
  )
  const [filter, setFilter] = useState('')

  const itemCategoryOptions = useMemo(() => {
    return itemCategories.map((c) => ({
      label: c.algoliaCategorySlug || '',
      value: c._id,
    }))
  }, [itemCategories])

  return (
    <Paper className={`${styles.root} u-mb u-mt`}>
      <ConfigurationBreadcrumbs modelName={'item_categories.label'} />
      <Typography variant="h5" gutterBottom>
        {t('item_categories.item_category_configurations')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Link to="/inApp/configurations/itemCategories/create">
            <Button className="u-mr-mini" variant="contained">
              {t('item_categories.item_category_create')}
            </Button>
          </Link>
          <Paper className={`${styles.root} u-p-sm u-mb u-mt`}>
            <Typography
              variant="body1"
              gutterBottom
              className={`u-flex u-ai-center`}
            >
              {t('filters')}
              &nbsp;&nbsp;{' '}
              <Autocomplete
                options={itemCategoryOptions}
                getOptionLabel={(option) => option.label}
                style={{ width: 500, marginBottom: 10 }}
                onChange={(event, data: any) => {
                  setFilter(data?.value || '')
                }}
                renderInput={(params) => (
                  <TextField {...params} margin="normal" />
                )}
              />
            </Typography>
            <ItemCategoriesTreeView
              itemCategories={itemCategories}
              filterCategoryId={filter}
            />
          </Paper>
        </Fragment>
      )}
    </Paper>
  )
}

export default ItemCategoriesTable
