import {
  IsDate,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  Min,
  ValidateIf,
} from 'class-validator'
import { CashBookCashFlowArgs, ECashBookPaymentReference } from '@/schemaTypes'

export class CashBookCashFlowValidation {
  @IsNotEmpty()
  paymentReference: ECashBookPaymentReference

  @IsOptional()
  note?: string

  @ValidateIf((self) =>
    [
      ECashBookPaymentReference.ValorizationSale,
      ECashBookPaymentReference.ValorizationCreditNote,
    ].includes(self.paymentReference),
  )
  @IsNotEmpty()
  storageLabel?: string | null

  @IsOptional()
  bookingNumber?: number | null

  @IsOptional()
  customerNumber?: number | null

  @IsOptional()
  billNumber?: string | null

  @ValidateIf(
    (self) =>
      ECashBookPaymentReference.ValorizationSale === self.paymentReference,
  )
  @IsNumber()
  @Min(0.01)
  inflow?: number | null

  @ValidateIf(
    (self) =>
      ECashBookPaymentReference.ValorizationCreditNote ===
      self.paymentReference,
  )
  @IsNumber()
  @Min(0.01)
  outflow?: number | null

  @ValidateIf(
    (self) => ECashBookPaymentReference.Bill === self.paymentReference,
  )
  @IsNotEmpty()
  attachmentArgs?: object | null

  @IsDate()
  date: Date

  // TODO: add rule: if paymentReference equals verwertung, itemId needs to be filled in
  // if paymentReference is valorizationBill, inflow > 0
  // if paymentReference is valorizationCreditNote, outflow > 0
  // either inflow or outflow need to be > 0, but not both and neither none

  constructor(cashBookCashFlow: CashBookCashFlowArgs) {
    this.paymentReference = cashBookCashFlow.paymentReference
    this.storageLabel = cashBookCashFlow.storageLabel
    this.bookingNumber = cashBookCashFlow.bookingNumber
    this.inflow = cashBookCashFlow.inflow
    this.outflow = cashBookCashFlow.outflow
    this.attachmentArgs = cashBookCashFlow.attachmentArgs
    this.date = cashBookCashFlow.date
  }
}
