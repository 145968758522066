import Paper from '@material-ui/core/Paper'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import ItemValuesTable from '@/components/ItemValuesTable'
import { ItemValuesEntry } from '@/schemaTypes'

interface ItemCalculationProps {
  verifiedItemValuesEntry?: ItemValuesEntry | null | undefined
  calculatedItemValuesEntry?: ItemValuesEntry | null | undefined
}

const ItemCalculation: FunctionComponent<ItemCalculationProps> = (props) => {
  const { verifiedItemValuesEntry, calculatedItemValuesEntry } = props
  const { t } = useTranslation()
  const itemValuesRows = [
    {
      label: t('maximum_market_value'),
      values: {
        verified: verifiedItemValuesEntry?.maxMarketValue,
        calculated: calculatedItemValuesEntry?.maxMarketValue,
      },
    },
    {
      label: t('current_market_value'),
      values: {
        verified: verifiedItemValuesEntry?.currentMarketValue,
        calculated: calculatedItemValuesEntry?.currentMarketValue,
      },
    },
    {
      label: t('predicted_market_value'),
      values: {
        verified: verifiedItemValuesEntry?.predictedMarketValue,
        calculated: calculatedItemValuesEntry?.predictedMarketValue,
      },
    },
    {
      label: t('adjusted_market_value'),
      values: {
        verified: verifiedItemValuesEntry?.adjustedMarketValue,
        calculated: calculatedItemValuesEntry?.adjustedMarketValue,
      },
    },
    {
      label: t('loan_to_value'),
      values: {
        verified: verifiedItemValuesEntry?.loanToValue,
        calculated: calculatedItemValuesEntry?.loanToValue,
      },
    },
  ]

  return (
    <>
      {itemValuesRows && (
        <Paper className="u-p-sm">
          <ItemValuesTable rows={itemValuesRows} />
        </Paper>
      )}
    </>
  )
}

export default ItemCalculation
