import { IsDate, IsEmail, IsNotEmpty, IsPhoneNumber } from 'class-validator'
import { Customer, ERoles } from '@/schemaTypes'

export class CustomerValidation {
  @IsEmail()
  email: string

  @IsNotEmpty()
  firstname: string

  @IsNotEmpty()
  lastname: string

  @IsDate()
  dateOfBirth: Date

  @IsPhoneNumber('AT')
  phone: string

  @IsNotEmpty()
  roles: ERoles[]

  constructor(customer: Customer) {
    this.email = customer.email
    this.firstname = customer.firstname
    this.lastname = customer.lastname
    this.dateOfBirth = customer.dateOfBirth
    this.phone = customer.phone
    this.roles = customer.roles
  }
}
