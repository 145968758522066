import styled from '@emotion/styled'
import { TextField } from '@material-ui/core'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import Modal from '@/components/Modal'
import ClearIcon from '@/images/clear.svg'
import EditIcon from '@/images/edit.svg'
import SaveIcon from '@/images/save.svg'
import { Item } from '@/schemaTypes'
import { printLocalAmount } from '@/utils/misc'

type ItemOfferedPriceProps = {
  itemOfferedPrice: number
}

export const ItemOfferedPrice = ({
  itemOfferedPrice,
}: ItemOfferedPriceProps) => {
  return (
    <ItemOfferedPriceText>
      {printLocalAmount(itemOfferedPrice)}
    </ItemOfferedPriceText>
  )
}

type EditableItemOfferedPriceProps = {
  item: Item
  itemOfferedPrice: number
  onSave: (price: number) => void
}

export const EditableItemOfferedPrice = ({
  item,
  itemOfferedPrice,
  onSave,
}: EditableItemOfferedPriceProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [editedItemOfferedPrice, setEditedItemOfferedPrice] = useState<
    number | undefined
  >(itemOfferedPrice)

  const editedItemOfferedPriceHasValue = !!editedItemOfferedPrice

  const handleSave = () => {
    onSave(editedItemOfferedPrice!)
    setEditMode(false)
  }

  const onBlurOfferedPrice = () => {
    if (
      item.internalSaleData?.price &&
      editedItemOfferedPrice &&
      item.internalSaleData.price > editedItemOfferedPrice
    ) {
      setOpen(true)
    }
  }

  useEffect(() => {
    if (editMode === false) {
      setEditedItemOfferedPrice(itemOfferedPrice)
    }
  }, [editMode, itemOfferedPrice])

  return (
    <div>
      {editMode ? (
        <EditBox display="flex" alignItems="center">
          <ToolWrapper>
            {editedItemOfferedPriceHasValue && (
              <ReactSVG
                className="infoIcon"
                src={SaveIcon}
                onClick={handleSave}
              />
            )}
            <ReactSVG
              className="infoIcon"
              src={ClearIcon}
              onClick={() => setEditMode(false)}
            />
          </ToolWrapper>
          <ItemOfferedPriceInput
            error={!editedItemOfferedPriceHasValue}
            helperText={
              !editedItemOfferedPriceHasValue
                ? t('validation.validation_is_not_empty')
                : undefined
            }
            value={editedItemOfferedPrice}
            onChange={(e) =>
              setEditedItemOfferedPrice(parseInt(e.target.value) || undefined)
            }
            onBlur={onBlurOfferedPrice}
          />
        </EditBox>
      ) : (
        <EditBox display="flex" alignItems="center">
          <ReactSVG
            className="editIcon"
            src={EditIcon}
            onClick={() => setEditMode(true)}
          />
          <ItemOfferedPrice itemOfferedPrice={itemOfferedPrice} />
        </EditBox>
      )}
      <Modal open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        {t('ad_price_lower_than_internal_price')}
      </Modal>
    </div>
  )
}

const ItemOfferedPriceText = styled(Typography)`
  font-size: 0.875rem;
`

const EditBox = styled(Box)`
  .infoIcon {
    margin-left: -20px;
    & > div {
      height: 24px;
      width: 24px;
      position: relative;
      cursor: pointer;
    }
    svg {
      height: 20px;
      fill: #683ab7c0 !important;
    }
    svg:hover {
      fill: #673aba !important;
    }
  }

  .editIcon {
    margin-right: 5px;
    margin-left: -1.5rem;
    border-radius: 80%;
    height: 22px !important;
    width: 24px !important;
    background-color: #683ab7c0;
    padding-top: 0.175rem;
    padding-left: 0.03rem;
    color: #ffffff !important;
    :hover {
      background-color: #673aba;
      color: #ffffff !important;
    }
    & > div {
      height: 20px;
      width: 20px;
      position: relative;
      cursor: pointer;
    }
    svg {
      height: 16px;
      fill: #ffffff !important;
    }
  }
`

const ItemOfferedPriceInput = styled(TextField)`
  .MuiInputBase-root {
    height: 1.1rem;
    font-size: 0.95rem;
  }
`

const ToolWrapper = styled.div`
  display: flex;
  gap: 15px;
`
