import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationRegenerateBillForDealArgs } from '@/schemaTypes'
import { dealCalculationFragments, dealFragments } from './dealFragments'

const REGENERATE_BILL_FOR_DEAL = gql`
  mutation regenerateBillForDeal($dealId: ObjectId!) {
    regenerateBillForDeal(dealId: $dealId) {
      _id
      dealStartDate
      createdAt
      updatedAt
      dealType
      businessUnit
      bookingNumber
      itemsIds
      secondaryCustomers {
        _id
        firstname
        lastname
        customerNumber
        dateOfBirth
        email
        phone
        note
        isNotePopup
        sex
        acquiredBy
        identityUploadLinks {
          url
          contentType
        }
      }
      company {
        _id
        name
        iban
        paypalEmail
      }
      pawnData {
        ...PawnDataFragment
      }
      purchaseData {
        ...PurchaseDataFragment
      }
      events {
        ...DealEventFragment
      }
      payoutData {
        ...PaymentDataFragment
      }
      secondaryPayoutData {
        ...SecondaryPaymentDataFragment
      }
      initialShopId
      pickupData {
        ...TransportDataFragment
      }
      dropoffData {
        ...TransportDataFragment
      }
      items {
        ...DealItemFragment
      }
      requestedItems {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
      shop {
        _id
        name
      }
      verifiedBy
      extendedBy
      dealPartnerData {
        type
        knp {
          fileReferenceNumber
          debtAmount
        }
      }
      uploadedFiles {
        ...FileMetadataFragment
      }
      extensionChain {
        extensionBookingNumbers
        extensionDealIds
      }
      dealChain {
        bookingNumber
        dealId
        chainType
      }
    }
  }
  ${dealFragments.dealItem}
  ${dealFragments.event}
  ${dealFragments.purchaseData}
  ${dealFragments.pawnData}
  ${dealFragments.transportData}
  ${dealCalculationFragments.dealValorizationCalculation}
  ${fragments.paymentData}
  ${fragments.secondaryPaymentData}
  ${fragments.fileMetadata}
`

type TData = { regenerateBill: Mutation['regenerateBillForDeal'] }
type TVariables = MutationRegenerateBillForDealArgs

export function useRegenerateBillForDeal(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [regenerateBillForDeal] = useMutation<TData, TVariables>(
    REGENERATE_BILL_FOR_DEAL,
    opts,
  )

  return regenerateBillForDeal
}
