import { ETransactionType } from '@/schemaTypes'

export const transactionsWithUStAffect = [
  ETransactionType.LiquidationFee,
  ETransactionType.OtherFees,
  ETransactionType.ThirdPartyCosts,
]
export const transactionsWithSpecificLabelOnBill = [
  ETransactionType.CashExpenditures,
  ETransactionType.ThirdPartyCosts,
]

// This is also the rule in Backend!
export const transactionsNotConnectedToCashbookAnyway = [
  ETransactionType.Derecognization,
  ETransactionType.LiquidationFee,
  ETransactionType.PaybackFees,
  ETransactionType.Payout,
]

export const allowedTransactionsIfPrivateSaleAlreadyCreated = [
  ETransactionType.CustomerPaymentAfterSale,
  ETransactionType.Derecognization,
  ETransactionType.PayoutExcessAmount,
]
