import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetResponseTemplatesArgs } from '@/schemaTypes'

const GET_RESPONSE_TEMPLATES = gql`
  query getResponseTemplates {
    getResponseTemplates {
      _id
      name
      subject
      openingText
      editableText
      closingText
    }
  }
`

type TData = { getResponseTemplates: Query['getResponseTemplates'] }
type TVariables = QueryGetResponseTemplatesArgs

export function useGetResponseTemplates() {
  const queryResult = useQuery<TData, TVariables>(GET_RESPONSE_TEMPLATES, {
    fetchPolicy: 'network-only',
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    responseTemplates: queryResult.data?.getResponseTemplates || [],
  }
}
