import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton'
import { SelectItemOptionsType } from 'primereact/selectitem'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ELanguageCode } from '@/schemaTypes'

export const LanguagePicker: FC<{
  className?: string
  languages: ELanguageCode[]
  setLanguages: (input: ELanguageCode[]) => void
}> = ({ languages, setLanguages, className }) => {
  const { t } = useTranslation()

  const baseLanguage = ELanguageCode.De

  const translatedLanguages = Object.values(ELanguageCode).filter(
    (language) => language != baseLanguage,
  )

  const languageOptions: SelectItemOptionsType = translatedLanguages.map(
    (language) => ({
      label: t(`language_label.${language}`),
      value: language,
    }),
  )

  const handleLanguageChange = (event: SelectButtonChangeEvent) => {
    if (!event.value) return
    setLanguages([ELanguageCode.De, event.value as ELanguageCode])
  }

  return (
    <div className={`flex flex-row gap-2 items-center ${className}`}>
      <span className="mr-2 font-bold text-sm text-gray-700">
        {t('translation')}
      </span>

      <SelectButton
        value={baseLanguage}
        className="text-sm"
        options={[
          { label: t(`language_label.${baseLanguage}`), value: baseLanguage },
        ]}
        disabled
      />

      <i className="pi pi-arrow-right mr-1 -mt-0.5" />

      <SelectButton
        value={languages[1]}
        onChange={handleLanguageChange}
        options={languageOptions}
        className="text-sm"
      />
    </div>
  )
}
