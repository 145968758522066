import {
  includeHidedCategories,
  useGetItemCategories,
} from '../../hooks/itemList/getItemCategories'
import { Box, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectOptionInput from '@/components/SelectOptionInput'

export type ItemCategoriesFilterInputDetails = {
  itemCategoryIdLv0?: string | null
  itemCategoryIdLv1?: string | null
  itemCategoryIdLv2?: string | null
}

export type ItemCategoriesFilterInputProps = {
  onItemCategoryIdChanged: (data: ItemCategoriesFilterInputDetails) => void
} & ItemCategoriesFilterInputDetails

export default function ItemCategoriesFilterInput({
  itemCategoryIdLv0: initialItemCategoryIdLv0,
  itemCategoryIdLv1: initialItemCategoryIdLv1,
  itemCategoryIdLv2: initialItemCategoryIdLv2,
  onItemCategoryIdChanged,
}: ItemCategoriesFilterInputProps) {
  const { t } = useTranslation()

  const [itemCategoryIdLv0, setItemCategoryIdLv0] = useState<string | null>(
    initialItemCategoryIdLv0,
  )

  const [itemCategoryIdLv1, setItemCategoryIdLv1] = useState<string | null>(
    initialItemCategoryIdLv1,
  )

  const [itemCategoryIdLv2, setItemCategoryIdLv2] = useState<string | null>(
    initialItemCategoryIdLv2,
  )

  const { itemCategories } = useGetItemCategories(includeHidedCategories)

  useEffect(() => {
    if (!initialItemCategoryIdLv0) {
      setItemCategoryIdLv0(null)
    }
    if (!initialItemCategoryIdLv1) {
      setItemCategoryIdLv1(null)
    }
    if (!initialItemCategoryIdLv2) {
      setItemCategoryIdLv2(null)
    }
  }, [
    initialItemCategoryIdLv0,
    initialItemCategoryIdLv1,
    initialItemCategoryIdLv2,
  ])

  useEffect(() => {
    onItemCategoryIdChanged({
      itemCategoryIdLv0,
      itemCategoryIdLv1,
      itemCategoryIdLv2,
    })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCategoryIdLv0, itemCategoryIdLv1, itemCategoryIdLv2])

  return (
    <Box display="flex" justifyContent="start">
      <SelectOptionInput
        selectProps={{ style: { minWidth: 100 } }}
        value={itemCategoryIdLv0}
        onChange={(value) => {
          setItemCategoryIdLv0(value)
          setItemCategoryIdLv1(null)
          setItemCategoryIdLv2(null)
        }}
        label={
          <Typography variant="body1" style={{ minWidth: 107 }}>
            {t('category')}
          </Typography>
        }
        showResetButton
        onReset={() => {
          setItemCategoryIdLv0(null)
          setItemCategoryIdLv1(null)
          setItemCategoryIdLv2(null)
        }}
        className="u-flex u-ai-center"
        menuItems={itemCategories
          .filter((c) => !c.parentId)
          .map((itemCategory) => ({
            label: itemCategory.name,
            value: itemCategory._id,
          }))}
      />
      {itemCategoryIdLv0 &&
        itemCategories.filter((c) => c.parentId === itemCategoryIdLv0).length >
          0 && (
          <SelectOptionInput
            selectProps={{ style: { minWidth: 100 } }}
            value={itemCategoryIdLv1}
            onChange={(value) => {
              setItemCategoryIdLv1(value)
              setItemCategoryIdLv2(null)
            }}
            showResetButton
            onReset={() => {
              setItemCategoryIdLv1(null)
              setItemCategoryIdLv2(null)
            }}
            menuItems={itemCategories
              .filter((c) => c.parentId === itemCategoryIdLv0)
              .map((itemCategory) => ({
                label: itemCategory.name,
                value: itemCategory._id,
              }))}
          />
        )}
      {itemCategoryIdLv1 &&
        itemCategories.filter((c) => c.parentId === itemCategoryIdLv1).length >
          0 && (
          <SelectOptionInput
            selectProps={{ style: { minWidth: 100 } }}
            value={itemCategoryIdLv2}
            onChange={(value) => {
              setItemCategoryIdLv2(value)
            }}
            showResetButton
            onReset={() => {
              setItemCategoryIdLv2(null)
            }}
            menuItems={itemCategories
              .filter((c) => c.parentId === itemCategoryIdLv1)
              .map((itemCategory) => ({
                label: itemCategory.name,
                value: itemCategory._id,
              }))}
          />
        )}
    </Box>
  )
}
