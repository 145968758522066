import styled from '@emotion/styled'
import { Divider } from 'primereact/divider'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Note, NoteCreateArgs, NoteUpdateArgs } from '@/schemaTypes'
import CreateNote from './CreateNote'
import NoteList from './NoteList'

interface INoteContainerProps {
  notes: Note[]
  chainNotes: Note[] | undefined
  onAddNote: (note: NoteCreateArgs) => void
  onUpdateNote: (
    noteId: string | number,
    note: NoteUpdateArgs,
    chainDealId?: string,
  ) => void
  conversationListHeight?: number
}

const NoteContainer = ({
  notes,
  onAddNote,
  onUpdateNote,
  conversationListHeight,
  chainNotes,
}: INoteContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const scrollToBottom = () => {
    const scroll = containerRef.current
    if (scroll) {
      scroll.scrollTop = scroll.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [])

  const groupedNotes = useMemo(() => {
    if (!chainNotes || chainNotes.length === 0) {
      return {}
    }

    return chainNotes.reduce((acc, note) => {
      if (!acc[note.bookingNumber]) {
        acc[note.bookingNumber] = []
      }
      acc[note.bookingNumber].push(note)
      return acc
    }, {})
  }, [chainNotes])

  return (
    <div className="relative" style={{ height: conversationListHeight }}>
      <NoteListContainer
        className="overflow-auto"
        ref={containerRef}
        style={{
          height:
            typeof conversationListHeight === 'number'
              ? `${conversationListHeight - 74}px`
              : '0px',
        }}
      >
        {groupedNotes &&
          Object.keys(groupedNotes).map((bookingNumber) => (
            <div key={bookingNumber}>
              <Divider align="center">
                <div className="inline-flex align-items-center">
                  <b>#{bookingNumber}</b>
                </div>
              </Divider>
              <NoteList
                notes={groupedNotes[bookingNumber]}
                onUpdateNote={onUpdateNote}
              />
            </div>
          ))}
        {notes.length > 0 && groupedNotes && (
          <Divider align="center">
            <div className="inline-flex align-items-center">
              <b>{t('this_deal')}</b>
            </div>
          </Divider>
        )}
        <NoteList notes={notes} onUpdateNote={onUpdateNote} />
      </NoteListContainer>
      <CreateNoteContainer className="absolute">
        <CreateNote onAddNote={onAddNote} />
      </CreateNoteContainer>
    </div>
  )
}

const NoteListContainer = styled.div`
  gap: 1.3125rem;
  margin: 0 -0.875rem 0 -0.875rem;
  padding: 1.09375rem;
`
const CreateNoteContainer = styled.div`
  background: var(--surface-50, #fafafa);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 1.09375rem;
  height: 6rem;
  bottom: -0.875rem;
  left: -0.875rem;
  right: -0.875rem;
  border-radius: 0rem 0rem 0.375rem 0.375rem;
`

export default NoteContainer
