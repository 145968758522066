import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import { Button } from 'primereact/button'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useMutationShowingErrors } from '@/hooks'
import LogoImg from '@/images/logo_01_purple.png'
import FormikInput from '@/redesign/components/FormikFields/FormikInput/FormikInput'
import {
  LoginEmployeeDocument,
  MutationLoginEmployeeArgs,
  Mutation as Mutations,
} from '@/schemaTypes'
import { useAppDispatch } from '@/store'
import { setCurrentUser } from '@/store/actions/user'

const Login = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [loginMutation] = useMutation<
    { loginEmployee: Mutations['loginEmployee'] },
    MutationLoginEmployeeArgs
  >(LoginEmployeeDocument)

  const login = useMutationShowingErrors({
    mutation: loginMutation,
  })

  const handleSignIn = useCallback(
    async ({ email, password }: SignInValidationSchemaType) => {
      const response = await login({
        variables: {
          username: email,
          password: password,
        },
      })

      const employee = response?.data?.loginEmployee
      if (!employee || !employee.token) return

      dispatch(setCurrentUser(employee))
      history.replace('/inApp')
    },
    [dispatch, history, login],
  )

  return (
    <Main className="flex justify-center items-center w-full">
      <div className="flex flex-col items-center w-full">
        <ImageStyled src={LogoImg} alt="Cashy" className="relative bottom-16" />
        <Card className="flex flex-col items-center">
          <Title>Welcome!</Title>
          <Subtitle>Sign in to continue</Subtitle>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={signInValidationSchema}
            onSubmit={(values: SignInValidationSchemaType) => {
              handleSignIn(values)
            }}
          >
            {() => {
              return (
                <Form className="flex flex-col w-full mt-6">
                  <FormikInput
                    name="email"
                    type="email"
                    label="Email"
                    autoComplete="email"
                    placeholder="Email address"
                    className="mb-5 w-full flex flex-col"
                  />
                  <FormikInput
                    name="password"
                    type="password"
                    label="Password"
                    autoComplete="current-password"
                    placeholder="Password"
                    className="mb-5 w-full flex flex-col"
                  />
                  <Button type="submit" label="Sign In" />
                </Form>
              )
            }}
          </Formik>
        </Card>
      </div>
    </Main>
  )
}

type SignInValidationSchemaType = Yup.InferType<typeof signInValidationSchema>
const signInValidationSchema = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

const Main = styled.div`
  height: 100vh;
  background: var(--surface-200, #eee);
`

const ImageStyled = styled.img`
  width: 4.8125rem;
  height: 6.4375rem;
`

const Card = styled.div`
  border-radius: 1.875rem;
  background: var(--root-surface-card, #fff);
  padding: 3.125rem;
  width: 28rem;
  @media (max-width: 28rem) {
    width: 100%;
    padding: 3.125rem 2.125rem;
  }
`

const Title = styled.h4`
  color: var(--global-text-color, #495057);
  font-family: 'Inter';
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.96875rem;
`

const Subtitle = styled.p`
  color: var(--global-text-secondary-color, #6c757d);
  font-family: 'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3125rem; /* 150% */
`

export default Login
