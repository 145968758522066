import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ProductSelector from '@/components/ProductSelector'
import { Deal, DealItem } from '@/gql/graphql'
import styles from './AddItem.module.scss'

interface AddItemPageProps {
  currentItem: Pick<DealItem, '_id' | 'dealsHistory'>
  deal: Deal
}

export default function AddItemPage(props: AddItemPageProps) {
  const { currentItem, deal } = props
  const { t } = useTranslation()

  const history = useHistory()

  return (
    <div className={`${styles.root} u-12/12 u-p-sm u-pl@sm`}>
      <div className="o-grid">
        {currentItem && (
          <div className="u-p-sm mx-auto text-center">
            <Paper className="u-p o-grid__col u-12/12">
              <Typography variant="body1" gutterBottom>
                <span className="font-medium">{t('current_item')}</span>:{' '}
                {
                  currentItem.dealsHistory?.[
                    currentItem.dealsHistory?.length - 1
                  ].title
                }
              </Typography>
            </Paper>
          </div>
        )}
        <div className="u-p-sm w-full  flex justify-center">
          <ProductSelector
            className="flex-auto max-w-screen-lg"
            allowProductsWithoutValidPrice={false}
            onSelectItem={(product) => {
              const link = `/inApp/deals/${deal._id}/addItem/${'productObjectID' in product ? product.productObjectID : product.productVariant.id}/answers${currentItem ? '?currentItemId=' + currentItem._id : ''}`
              history.push(link)
            }}
          />
        </div>
      </div>
    </div>
  )
}
