import styled from '@emotion/styled'
import React from 'react'

interface TitleBarProps {
  title?: string
}

const TitleBar: React.FC<TitleBarProps> = ({ title }) => (
  <div className="h-10 flex items-center mb-4">
    <StyledTitle>{title}</StyledTitle>
  </div>
)
const StyledTitle = styled.h2`
  color: #000;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
`

export default TitleBar
