import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationAddDealItemsToStorageUnitArgs } from '@/schemaTypes'

const ADD_DEAL_ITEMS_TO_STORAGE_UNIT = gql`
  mutation addDealItemsToStorageUnit($args: AddDealItemsToStorageUnitArgs!) {
    addDealItemsToStorageUnit(args: $args) {
      _id
    }
  }
`

type TData = {
  addDealItemsToStorageUnit: Mutation['addDealItemsToStorageUnit']
}
type TVariables = MutationAddDealItemsToStorageUnitArgs

export function useAddDealItemsToStorageUnit(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [addDealItemsToStorageUnit] = useMutation<TData, TVariables>(
    ADD_DEAL_ITEMS_TO_STORAGE_UNIT,
    opts,
  )

  return addDealItemsToStorageUnit
}
