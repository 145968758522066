export const SHOW_MODAL = 'SHOW_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export const USER_SET_CURRENT = 'USER_SET_CURRENT'
export const USER_LOGOUT = 'USER_LOGOUT'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export const SWITCH_COMPANY = 'SWITCH_COMPANY'

export const EMPLOYEE_ACTIVE_SHOP = 'EMPLOYEE_ACTIVE_SHOP'

export const SWITCH_DEFAULT_REFURBED_SHIPPING_PROFILE =
  'SWITCH_DEFAULT_REFURBED_SHIPPING_PROFILE'
