import { Panel } from 'primereact/panel'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FormikDatePicker from '@/redesign/components/FormikFields/FormikDatePicker/FormikDatePicker'
import FormikDropdown from '@/redesign/components/FormikFields/FormikDropdown/FormikDropdown'

interface CustomerInfoPanelProps {
  yesNoOptions: { label: string; value: boolean }[]
  countryOptions: { label: string; value: string }[]
  children: React.ReactNode
}

const CustomerInfoPanel = ({
  yesNoOptions,
  countryOptions,
  children,
}: CustomerInfoPanelProps) => {
  const { t } = useTranslation()

  return (
    <Panel header={t('customer_information')} toggleable className="mb-6">
      <FormikDropdown
        name="additionalInformationForCarPawn.citizenship"
        label={t('citizenship')}
        options={countryOptions}
        placeholder={t('citizenship')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <FormikDatePicker
        name="additionalInformationForCarPawn.registeredInAustriaSince"
        dateFormat="dd.mm.yy"
        label={t('registered_in_austria_since')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        showIcon
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-32"
      />
      <FormikDatePicker
        name="additionalInformationForCarPawn.lastResidentialAddressSince"
        dateFormat="dd.mm.yy"
        label={t('last_residential_address_since')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        showIcon
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-32"
      />

      <FormikDropdown
        name="additionalInformationForCarPawn.employed"
        label={t('employed')}
        options={yesNoOptions}
        placeholder={t('employed')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <FormikDropdown
        name="additionalInformationForCarPawn.paySlipsPresented"
        label={t('payslips_presented')}
        options={yesNoOptions}
        placeholder={t('payslips_presented')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <FormikDropdown
        name="additionalInformationForCarPawn.inInsolvencyDatabaseIn3Years"
        label={t('seizure_evident')}
        placeholder={t('seizure_evident')}
        options={yesNoOptions}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <FormikDropdown
        name="additionalInformationForCarPawn.hasSeizureEvident"
        label={t('customer_in_insolvency_database_in_3_year')}
        options={yesNoOptions}
        placeholder={t('customer_in_insolvency_database_in_3_year')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <FormikDropdown
        name="additionalInformationForCarPawn.appearSerious"
        label={t('customer_appears_serious')}
        options={yesNoOptions}
        placeholder={t('customer_appears_serious')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <FormikDropdown
        name="additionalInformationForCarPawn.hasCarPawnRelationshipWithCashy"
        label={t('customer_car_pawn_relationship_with_cashy')}
        options={yesNoOptions}
        placeholder={t('customer_car_pawn_relationship_with_cashy')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />
      {/* Deals History Table*/}
      {children}
    </Panel>
  )
}

export default CustomerInfoPanel
