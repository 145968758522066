import { CustomDealFormList } from '.'
import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import NotFound from '@/components/NotFound'
import getPagePath from '@/utils/getPagePath'
import { CustomDealFormPage } from './create'

function CustomDealFormsRoutes(props: RouteComponentProps) {
  return (
    <Switch>
      <Route
        path={getPagePath(props, '/create')}
        exact
        render={() => <CustomDealFormPage />}
      />
      <Route
        path={getPagePath(props, '/edit/:id')}
        exact
        render={(props) => <CustomDealFormPage id={props.match.params.id} />}
      />
      <Route path={getPagePath(props)} render={() => <CustomDealFormList />} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default CustomDealFormsRoutes
