import { dealFragments } from '../dealFragments'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { useMutationShowingErrors } from '@/hooks'
import { Deal, Mutation, MutationDeclineDealArgs } from '@/schemaTypes'

const DECLINE_DEAL = gql`
  mutation declineDeal($dealId: ObjectId!) {
    declineDeal(dealId: $dealId) {
      _id
      events {
        ...DealEventFragment
      }
      pickupData {
        ...TransportDataFragment
      }
      dropoffData {
        ...TransportDataFragment
      }
    }
  }
  ${dealFragments.event}
  ${dealFragments.transportData}
`

type TData = { declineDeal: Mutation['declineDeal'] }
type TVariables = MutationDeclineDealArgs

export function useOnDealStatusDeclined(deal: Deal) {
  const { t } = useTranslation()

  const [declineDeal] = useMutation<TData, TVariables>(DECLINE_DEAL, {
    variables: { dealId: deal._id },
  })

  return useMutationShowingErrors({
    mutation: declineDeal,
    successMessage: t('update_successful'),
  })
}
