import { useMutation } from '@apollo/client'
import { graphql } from '@/gql'

export const AddSecondaryCustomerToDealMutation = graphql(`
  mutation addSecondaryCustomerToDeal(
    $dealId: ObjectId!
    $selectedCustomerId: ObjectId!
  ) {
    addSecondaryCustomerToDeal(
      dealId: $dealId
      selectedCustomerId: $selectedCustomerId
    ) {
      _id
      secondaryCustomers {
        _id
        firstname
        lastname
        email
      }
    }
  }
`)

export const useAddSecondaryCustomerToDealMutation = () =>
  useMutation(AddSecondaryCustomerToDealMutation)
