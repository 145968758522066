import {
  CreateCustomerBlacklist_Mutation,
  CustomerBlacklistFragment,
  GetActiveCustomers_Query,
  GetExistingCustomerBlacklists_Query,
  UpdateCustomerBlacklist_Mutation,
} from '../helpers/customerBlacklists.graphql'
import { useLazyQuery, useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Form, Formik, FormikProps } from 'formik'
import { Button } from 'primereact/button'
import { Panel } from 'primereact/panel'
import { Tooltip } from 'primereact/tooltip'
import { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { Context } from '@/context'
import { FragmentType, useFragment } from '@/gql'
import { useMutationShowingErrors, useShowErrorsPopup } from '@/hooks'
import BreadCrumbBar from '@/redesign/components/BreadCrumbBar/BreadCrumbBar'
import Taskbar from '@/redesign/components/Taskbar/Taskbar'
import TitleBar from '@/redesign/components/TitleBar/TitleBar'
import {
  CustomerBlacklistCreateArgs,
  CustomerBlacklistFiltersArgs,
  CustomerFilterArgs,
} from '@/schemaTypes'
import { CheckMatchingCustomersDialog } from './CheckMatchingCustomersDialog'
import { CustomerBlacklistForm } from './CustomerBlacklistForm'
import { RemovePersonFromBlackListDialog } from './RemovePersonFromBlackListDialog'

interface CustomerBlacklistDetailPageProps {
  customerBlacklist?: FragmentType<typeof CustomerBlacklistFragment> | null
}

const CustomerBlacklistDetailPage = (
  props: CustomerBlacklistDetailPageProps,
) => {
  const { t } = useTranslation()
  const { getCurrentUser } = useContext(Context)
  const currentUser = getCurrentUser()

  const [
    showCheckMatchingCustomersDialog,
    setShowCheckMatchingCustomersDialog,
  ] = useState<boolean>(false)
  const [
    showDeleteCustomerBlacklistDialog,
    setShowDeleteCustomerBlacklistDialog,
  ] = useState<boolean>(false)
  const [foundExistingEntryId, setFoundExistingEntryId] = useState<
    string | null
  >(null)
  const formRef = useRef<FormikProps<CustomerBlacklistCreateArgs>>(null)
  const history = useHistory()

  const customerBlacklist = useFragment(
    CustomerBlacklistFragment,
    props.customerBlacklist,
  )

  const [createCustomerBlacklistMutation] = useMutation(
    CreateCustomerBlacklist_Mutation,
  )

  const createCustomerBlacklist = useMutationShowingErrors({
    mutation: createCustomerBlacklistMutation,
    successMessage: t('customer_blacklist_created'),
  })

  const [updateCustomerBlacklistMutation] = useMutation(
    UpdateCustomerBlacklist_Mutation,
  )

  const updateCustomerBlacklist = useMutationShowingErrors({
    mutation: updateCustomerBlacklistMutation,
    successMessage: t('customer_blacklist_updated'),
  })

  const [getActiveCustomers, queryResult] = useLazyQuery(
    GetActiveCustomers_Query,
  )
  const [getExistingCustomerBlacklists] = useLazyQuery(
    GetExistingCustomerBlacklists_Query,
  )

  const matchingCustomers = queryResult.data?.getActiveCustomers ?? []

  useShowErrorsPopup(queryResult.error)

  const checkExistingEntry = async ({
    firstname,
    lastname,
    email,
    dateOfBirth,
  }: CustomerBlacklistCreateArgs) => {
    const filter: CustomerBlacklistFiltersArgs = {
      firstname: firstname.trim(),
      lastname: lastname.trim(),
    }

    if (email) {
      filter.email = email.trim()
    }

    if (dateOfBirth) {
      filter.dateOfBirth = getDateOfBirthFilter(
        dateOfBirth,
        customerBlacklist?.dateOfBirth,
      )
    }

    const customerBlacklistRes = await getExistingCustomerBlacklists({
      variables: { args: filter },
      fetchPolicy: 'network-only',
    })

    return customerBlacklistRes.data?.getExistingCustomerBlacklists ?? []
  }

  const checkMatchingCustomers = async ({
    firstname,
    lastname,
    email,
    dateOfBirth,
  }: CustomerBlacklistCreateArgs) => {
    const filter: CustomerFilterArgs = {
      firstname: { value: firstname.trim() },
      lastname: { value: lastname.trim() },
    }

    if (email) {
      filter.email = { value: email.trim() }
    }

    if (dateOfBirth) {
      filter.dateOfBirth = getDateOfBirthFilter(
        dateOfBirth,
        customerBlacklist?.dateOfBirth,
      )
    }

    const customersRes = await getActiveCustomers({
      variables: { opts: { filter } },
      fetchPolicy: 'network-only',
    })

    if (customersRes?.data?.getActiveCustomers.length) {
      setShowCheckMatchingCustomersDialog(true)
    } else if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const onSave = async (values: CustomerBlacklistCreateArgs) => {
    const existingCustomerBlacklists = await checkExistingEntry(values)

    if (!customerBlacklist?._id) {
      if (existingCustomerBlacklists.length) {
        setFoundExistingEntryId(existingCustomerBlacklists[0]._id)
        return
      }
    } else {
      const foundCustomerBlacklist = existingCustomerBlacklists.filter(
        (c) => c._id !== customerBlacklist._id,
      )[0]
      if (foundCustomerBlacklist) {
        setFoundExistingEntryId(foundCustomerBlacklist._id)
        return
      }
    }

    await checkMatchingCustomers(values)
  }

  const initialValues = {
    firstname: customerBlacklist?.firstname ?? '',
    lastname: customerBlacklist?.lastname ?? '',
    email: customerBlacklist?.email ?? '',
    dateOfBirth: customerBlacklist?.dateOfBirth,
    customerIds: customerBlacklist?.customers?.map((c) => c._id) ?? [],
    employeeId: customerBlacklist?.employee?._id ?? '',
    reason: customerBlacklist?.reason ?? '',
  }

  return (
    <Wrapper>
      {customerBlacklist ? (
        <BreadCrumbBar
          customer={{
            firstname: customerBlacklist?.firstname,
            lastname: customerBlacklist?.lastname,
          }}
        />
      ) : (
        <BreadCrumbBar />
      )}

      <TitleBar
        title={t(customerBlacklist ? 'blocked_customer' : 'block_customer')}
      />

      <Formik<CustomerBlacklistCreateArgs>
        initialValues={initialValues}
        innerRef={formRef}
        validationSchema={customerBlacklistValidationSchema}
        enableReinitialize
        onSubmit={async ({
          firstname,
          lastname,
          email,
          dateOfBirth,
          reason,
          customerIds,
        }) => {
          const _dateOfBirth =
            dateOfBirth &&
            !noUpdateOnDateOfBirth(dateOfBirth, customerBlacklist?.dateOfBirth)
              ? dayjs(dateOfBirth).clone().utc(true).toDate()
              : undefined

          const args = {
            firstname: firstname.trim(),
            lastname: lastname.trim(),
            email: email?.trim(),
            dateOfBirth: _dateOfBirth,
            reason: reason.trim(),
            customerIds,
            employeeId: currentUser?._id,
          }

          if (!customerBlacklist?._id) {
            createCustomerBlacklist({
              variables: { args },
              onCompleted: (data) => {
                setShowCheckMatchingCustomersDialog(false)
                history.push(
                  '/inApp/customerBlacklists/edit/' +
                    data.createCustomerBlacklist._id,
                )
              },
            })
          } else {
            updateCustomerBlacklist({
              variables: {
                args: { id: customerBlacklist._id, ...args },
              },
              onCompleted: () => {
                setShowCheckMatchingCustomersDialog(false)
              },
            })
          }
        }}
      >
        {(props) => {
          const customerIdsFieldHelpers = props.getFieldHelpers('customerIds')

          return (
            <Form className="flex flex-col justify-between	h-full" noValidate>
              <Tooltip target=".custom-target-icon" />
              <Panel header={t('customer_data')} className="mb-6">
                <CustomerBlacklistForm
                  customerBlacklist={customerBlacklist}
                  employeeId={props.values.employeeId}
                  foundExistingEntryId={foundExistingEntryId}
                />
              </Panel>
              {formRef.current && (
                <CheckMatchingCustomersDialog
                  showCheckMatchingCustomersDialog={
                    showCheckMatchingCustomersDialog
                  }
                  setShowCheckMatchingCustomersDialog={
                    setShowCheckMatchingCustomersDialog
                  }
                  matchingCustomers={matchingCustomers}
                  customerIds={props.values.customerIds ?? []}
                  customerIdsFieldHelpers={customerIdsFieldHelpers}
                  handleSubmit={formRef.current.handleSubmit}
                />
              )}

              <Taskbar>
                <div className="flex flex-col md:flex-row justify-between w-full">
                  <div className="flex flex-row">
                    <Button
                      label={t('save')}
                      severity="info"
                      type="button"
                      onClick={() => onSave(props.values)}
                      icon="pi pi-save"
                      disabled={!props.isValid}
                      className="!mr-3.5"
                    />

                    <Button
                      label={t('reset')}
                      type="button"
                      onClick={() => props.resetForm()}
                      severity="secondary"
                      text
                      icon="pi pi-replay"
                    />
                  </div>
                  {customerBlacklist?._id && (
                    <div>
                      <Button
                        type="button"
                        severity="danger"
                        className="mt-3"
                        onClick={() =>
                          setShowDeleteCustomerBlacklistDialog(true)
                        }
                        label={t('remove_person_from_blacklist')}
                      />
                    </div>
                  )}
                </div>
              </Taskbar>
            </Form>
          )
        }}
      </Formik>

      {customerBlacklist?._id && (
        <RemovePersonFromBlackListDialog
          customerBlacklistId={customerBlacklist?._id}
          showDeleteCustomerBlacklistDialog={showDeleteCustomerBlacklistDialog}
          setShowDeleteCustomerBlacklistDialog={
            setShowDeleteCustomerBlacklistDialog
          }
        />
      )}
    </Wrapper>
  )
}

const customerBlacklistValidationSchema = Yup.object().shape({
  firstname: Yup.string().min(2).required('Required'),
  lastname: Yup.string().min(2).required('Required'),
  dateOfBirth: Yup.date()
    .max(
      dayjs().subtract(18, 'year').hour(23).minute(23).toDate(),
      'Under minimum age',
    )
    .nullable(),
  email: Yup.string().email().nullable(),
  reason: Yup.string().required('Required'),
})

const getDateOfBirthFilter = (
  dateOfBirth: Date,
  existingCustomerDateOfBirth?: Date,
) => {
  if (noUpdateOnDateOfBirth(dateOfBirth, existingCustomerDateOfBirth)) {
    const dob = dayjs(existingCustomerDateOfBirth).clone()
    return {
      betweenValues: {
        from: dob.toDate(),
        to: dob.endOf('day').toDate(),
      },
    }
  }

  const dob = dayjs(dateOfBirth).clone().utc(true)

  return {
    betweenValues: {
      from: dob.toDate(),
      to: dob.endOf('day').toDate(),
    },
  }
}

const noUpdateOnDateOfBirth = (
  dateOfBirth: Date,
  existingCustomerDateOfBirth?: Date,
) =>
  existingCustomerDateOfBirth &&
  dayjs(existingCustomerDateOfBirth).isSame(dayjs(dateOfBirth))

const Wrapper = styled.div`
  height: calc(100vh - 95px);
  min-height: calc(100vh - 95px);
  display: flex;
  flex-direction: column;
`

export default CustomerBlacklistDetailPage
