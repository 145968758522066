import { dealCalculationFragments, dealFragments } from '../dealFragments'
import { useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { useMutationShowingErrors } from '@/hooks'
import {
  ConfirmPaybackArgs,
  Mutation,
  MutationConfirmPaybackArgs,
} from '@/schemaTypes'

dayjs.extend(utc)

const CONFIRM_PAYBACK = gql`
  mutation confirmPayback($args: ConfirmPaybackArgs!) {
    confirmPayback(args: $args) {
      _id
      events {
        ...DealEventFragment
      }
      pawnData {
        ...PawnDataFragment
      }
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
    }
  }
  ${dealFragments.event}
  ${dealFragments.pawnData}
  ${dealFragments.dealItem}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = { confirmPayback: Mutation['confirmPayback'] }
type TVariables = MutationConfirmPaybackArgs

export function useOnDealStatusPaybackConfirmed(
  confirmPaybackArgs: ConfirmPaybackArgs,
) {
  const { t } = useTranslation()

  const [confirmPayback] = useMutation<TData, TVariables>(CONFIRM_PAYBACK, {
    variables: {
      args: confirmPaybackArgs,
    },
  })

  return useMutationShowingErrors({
    mutation: confirmPayback,
    successMessage: t('update_successful'),
  })
}
