import { Fragment, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ShippingLabel } from '@/schemaTypes'
import { getFilenameFromURL } from '@/utils/file'
import styles from './ShippingLabel.module.scss'

interface ShpwShippingLabelProps {
  shippingLabel: ShippingLabel
}

const ShpwShippingLabel: FunctionComponent<ShpwShippingLabelProps> = (
  props,
) => {
  const { shippingLabel } = props
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className={styles.showLabelDivAddress}>
        <h4>{t('sender')}</h4>
        <p>{shippingLabel.senderName}</p>
        <p>{shippingLabel.senderAddress.street}</p>
        <p>{`${shippingLabel.senderAddress.houseNumber ?? '-'}${
          shippingLabel.senderAddress.stairway
            ? '/' + shippingLabel.senderAddress.stairway
            : ''
        }${shippingLabel.senderAddress.floor ? '/' + shippingLabel.senderAddress.floor : ''}${
          shippingLabel.senderAddress.door
            ? '/' + shippingLabel.senderAddress.door
            : ''
        }`}</p>
        <p>{`${shippingLabel.senderAddress.zipCode ?? '-'}, ${shippingLabel.senderAddress.city ?? '-'}, ${
          shippingLabel.senderAddress.countryCode ?? '-'
        }`}</p>
      </div>

      <div className={styles.showLabelDivAddress}>
        <h4>{t('receiver')}</h4>
        <p>{shippingLabel.receiverName}</p>
        <p>{shippingLabel.receiverAddress.street}</p>
        <p>{`${shippingLabel.receiverAddress.houseNumber ?? '-'}${
          shippingLabel.receiverAddress.stairway
            ? '/' + shippingLabel.receiverAddress.stairway
            : ''
        }${shippingLabel.receiverAddress.floor ? '/' + shippingLabel.receiverAddress.floor : ''}${
          shippingLabel.receiverAddress.door
            ? '/' + shippingLabel.receiverAddress.door
            : ''
        }`}</p>
        <p>{`${shippingLabel.receiverAddress.zipCode ?? '-'}, ${shippingLabel.receiverAddress.city ?? '-'}, ${
          shippingLabel.receiverAddress.countryCode ?? '-'
        }`}</p>
      </div>

      <div className={styles.showLabelDiv}>
        <h4>{t('tracking_code')}</h4>
        <p>{shippingLabel.trackingCode}</p>
      </div>

      <div className={styles.showLabelDiv}>
        <h4>{t('tracking_link')}</h4>
        <a
          target="_blank"
          href={`${shippingLabel.trackingLink}`}
          rel="noreferrer"
        >
          {shippingLabel.trackingLink}
        </a>
      </div>

      <div className={styles.showLabelDiv}>
        <h4>{t('file')}</h4>
        <a
          target="_blank"
          href={`${shippingLabel.labelFile.url}`}
          rel="noreferrer"
        >
          {getFilenameFromURL(shippingLabel.labelFile.url).filename +
            getFilenameFromURL(shippingLabel.labelFile.url).fileExtension}
        </a>
      </div>
    </Fragment>
  )
}

export default ShpwShippingLabel
