import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationAddDealNoteArgs } from '@/schemaTypes'

const ADD_DEAL_NOTE = gql`
  mutation addDealNote($dealId: ObjectId!, $noteCreateArgs: NoteCreateArgs!) {
    addDealNote(dealId: $dealId, noteCreateArgs: $noteCreateArgs) {
      _id
    }
  }
`

type TData = { addNote: Mutation['addDealNote'] }
type TVariables = MutationAddDealNoteArgs

export function useAddDealNote(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [addNote] = useMutation<TData, TVariables>(ADD_DEAL_NOTE, opts)

  return addNote
}
