import ItemValuesTable from '../../../../../components/ItemValuesTable/ItemValuesTable'
import { Tooltip, Typography } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import WarningIcon from '@material-ui/icons/Warning'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import TableValue from '@/components/TableValue'
import { useUpdateDealItemInternalSaleData } from '@/domains/items/hooks'
import { useMutationShowingErrors } from '@/hooks'
import ClearIcon from '@/images/clear.svg'
import SaveIcon from '@/images/save.svg'
import { Deal, DealItem } from '@/schemaTypes'
import { getLatestEventForStatus } from '@/utils/deal'
import { formatFuncForPrices } from '@/utils/misc'
import styles from './AuctionElementComponent.module.scss'
import { IMinCashyPrice } from './Element'

export interface AuctionElementDealItemInfoProps {
  deal: Deal
  item: DealItem
  minCashyPrice: IMinCashyPrice
}

const itemValuesColumns = {
  verified: { displayed: true, showLoadingAnimationForNullValues: false },
  closed: { displayed: true, showLoadingAnimationForNullValues: false },
}

const AuctionElementDealItemInfo: React.FC<AuctionElementDealItemInfoProps> = ({
  deal,
  item,
  minCashyPrice,
}) => {
  const { t } = useTranslation()
  const [cashyPrice, setCashyPrice] = useState(
    item.internalSaleData?.price ?? 0,
  )
  const [error, setError] = useState('')

  const updateDealItemInternalSaleData = useMutationShowingErrors({
    mutation: useUpdateDealItemInternalSaleData(),
    successMessage: t('update_successful'),
  })

  const onReset = () => {
    setCashyPrice(item.internalSaleData?.price ?? 0)
  }

  const updateCashyPrice = (value: number) => {
    if (error) setError('')
    setCashyPrice(value)
  }

  const onUpdateCashyPriceInternalSaleData = () => {
    if (minCashyPrice && cashyPrice < Number(minCashyPrice.minPrice)) {
      return setError(
        t('auction.auction_lower_than_min_cashy_price', {
          price: minCashyPrice.minPrice,
        }),
      )
    }
    updateDealItemInternalSaleData({
      variables: {
        dealId: deal._id,
        itemId: item._id,
        args: {
          date: new Date(),
          price: cashyPrice,
          purchasedDirectly: false,
          isCashyPrice: true,
        },
      },
    })
  }

  const closedEvent = getLatestEventForStatus(deal, 'DealClosedEvent')

  const internalDisabled =
    !!getLatestEventForStatus(deal, 'DealSoldInternEvent') ||
    !!closedEvent?.isClosedByAuctionId ||
    !!getLatestEventForStatus(deal, 'DealSoldExternEvent') ||
    !!getLatestEventForStatus(deal, 'DealPaybackConfirmedEvent') ||
    !!getLatestEventForStatus(deal, 'DealExtensionConfirmedEvent')

  const itemValues = item?.values || null
  const itemValuesRows = useMemo(
    () =>
      itemValues
        ? itemValues.verified
          ? [
              {
                label: 'Maximaler Marktwert',
                values: {
                  verified: itemValues.verified.maxMarketValue,
                  closed: itemValues.closed && itemValues.closed.maxMarketValue,
                },
              },
              {
                label: 'Jetziger Marktwert',
                values: {
                  verified: itemValues.verified.currentMarketValue,
                  closed:
                    itemValues.closed && itemValues.closed.currentMarketValue,
                },
              },
              {
                label: 'Zukünftiger Marktwert',
                values: {
                  verified: itemValues.verified.predictedMarketValue,
                  closed:
                    itemValues.closed && itemValues.closed.predictedMarketValue,
                },
              },
              {
                label: 'Adjustierter Marktwert',
                values: {
                  verified: itemValues.verified.adjustedMarketValue,
                  closed:
                    itemValues.closed && itemValues.closed.adjustedMarketValue,
                },
              },
              {
                label: 'Loan To Value',
                values: {
                  verified: itemValues.verified.loanToValue,
                  closed: itemValues.closed && itemValues.closed.loanToValue,
                },
              },
            ]
          : []
        : [],
    [itemValues],
  )

  const hasCashyPrice =
    !item.internalSaleData || item.internalSaleData.isCashyPrice

  return (
    <TableRow key={item._id} className={`${styles.auctionDealItemValuesTable}`}>
      <TableCell>
        <Link to={`/inApp/items/${item._id}`} target="blank">
          {item._id}
        </Link>
      </TableCell>
      <TableCell> {item.title} </TableCell>
      <TableCell
        align="center"
        component="th"
        scope="row"
        className={`${styles.auctionDealItemValuesTable}`}
      >
        <ItemValuesTable
          rows={itemValuesRows}
          columns={itemValuesColumns}
          className={styles.itemValuesTable}
        />
      </TableCell>
      <TableCell align="center">
        {hasCashyPrice ? (
          <>
            <TableValue
              value={cashyPrice}
              type="float"
              onChange={updateCashyPrice}
              formatFunc={formatFuncForPrices}
              minValue={0}
              addPaddingRight={internalDisabled}
              disabled={internalDisabled}
            />
            {error && (
              <div>
                <Typography variant="caption" color="error" className="u-ml-5">
                  {error}
                </Typography>
              </div>
            )}
          </>
        ) : (
          <Tooltip title={t('auction.auction_not_cashy_price_warning')}>
            <WarningIcon
              className={`${styles.warning} ${styles.warning_o} u-ml-sm`}
            />
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="center">
        {item.internalSaleData
          ? item.internalSaleData.purchasedDirectly
            ? t('direct_purchase')
            : item.internalSaleData.price
              ? `${t('price')}: ${item.internalSaleData.price} €`
              : '-'
          : '-'}
      </TableCell>
      <TableCell>
        {!internalDisabled && hasCashyPrice && (
          <div className={styles.buttonWrapper}>
            <div onClick={onReset} className={`o-media__fixed u-inline-block`}>
              <ReactSVG
                className={`${styles.infoIcon} u-mr-8`}
                src={ClearIcon}
              />
            </div>
            <div
              onClick={onUpdateCashyPriceInternalSaleData}
              className={`o-media__fixed u-inline-block`}
            >
              <ReactSVG
                className={`${styles.infoIcon} u-ml-8`}
                src={SaveIcon}
              />
            </div>
          </div>
        )}
      </TableCell>
    </TableRow>
  )
}

export default AuctionElementDealItemInfo
