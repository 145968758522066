import { ProductProperyDefinitionList } from '.'
import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import { ProductPropertyDefinitionDetails } from '@/domains/productPropertyDefinitions/components/ProductPropertyDefinitionDetails'
import getPagePath from '@/utils/getPagePath'

export function ProductPropertyDefinitionsRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/edit/:id')}
        render={(props) => (
          <ProductPropertyDefinitionDetails id={props.match.params.id} />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/create')}
        component={ProductPropertyDefinitionDetails}
      />
      <SentryRoute
        path={getPagePath(props)}
        component={ProductProperyDefinitionList}
      />
    </Switch>
  )
}

export default ProductPropertyDefinitionsRoutes
