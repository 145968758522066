import { useGetItemQuestions } from '../../itemQuestions/hooks'
import styled from '@emotion/styled'
import { MenuItem, Paper, Select } from '@material-ui/core'
import { Box, Checkbox, Input } from '@material-ui/core'
import {
  Dispatch,
  Fragment,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectOptionInput from '@/components/SelectOptionInput'
import TextInput from '@/components/TextInput'
import InputNum from '@/redesign/components/InputNumber/InputNumber'
import {
  Company,
  CompanyFeesEvaluation,
  EBusinessUnit,
  EQuestionValueMode,
  FeeCreateArgs,
  FeeUpdateArgs,
  ItemQuestion,
  Scalars,
} from '@/schemaTypes'
import {
  EBillingPeriodType,
  EDealType,
  EFeeLevel,
  EFeeMode,
  EFeeType,
  ETransportMode,
} from '@/schemaTypes'
import { oneOf } from '@/utils/misc'
import CompanyFeeEvaluator from './CompanyFeeEvaluator'
import FeeAmountForCategories from './FeeAmountForCategories'
import FeeDefinitionInterval from './FeeDefinitionInterval'
import styles from './FeesConfiguration.module.scss'
import WithdrawalFeeDefinition from './WithdrawalFeeDefinition'

export interface FeeDefinitionProps {
  company: Company
  businessUnit: EBusinessUnit
  isEditMode: boolean
  newFeeDefinitionArgs: FeeCreateArgs | FeeUpdateArgs
  setNewFeeDefinitionArgs: Dispatch<
    SetStateAction<FeeCreateArgs | FeeUpdateArgs>
  >
  companyFeesEvaluation?: CompanyFeesEvaluation
  onEvaluateFees
  evaluationQueryResult
}

const FeeDefinitionComponent: FunctionComponent<FeeDefinitionProps> = (
  props,
) => {
  const {
    company,
    isEditMode,
    newFeeDefinitionArgs,
    setNewFeeDefinitionArgs,
    companyFeesEvaluation,
    onEvaluateFees,
    evaluationQueryResult,
  } = props
  const { t } = useTranslation()

  const { itemQuestions } = useGetItemQuestions({
    variables: {
      opts: {
        filter: {
          valueMode: EQuestionValueMode.Constant,
        },
      },
    },
    skip: !newFeeDefinitionArgs,
  })

  useEffect(() => {
    const newQuestions = getProperItemQuestions(itemQuestions)
    // Whenever we try to change an itemCategory in the list, we should check if the restrictToItemQuestion exists in the new itemQuestion list,
    // we keep it. Otherise we clear the restrictToItemQuestion.
    if (
      !evaluationQueryResult.loading &&
      !newQuestions
        .map((c) => c._id)
        .includes(newFeeDefinitionArgs.restrictToItemQuestion)
    ) {
      setNewFeeDefinitionArgs({
        ...newFeeDefinitionArgs,
        restrictToItemQuestion: undefined,
      })
    }

    setFilteredItemQuestions(newQuestions)
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFeeDefinitionArgs.restrictToCategories])

  const getProperItemQuestions = (inputFilter: ItemQuestion[]) => {
    if (inputFilter && inputFilter.length > 0) {
      if (
        newFeeDefinitionArgs.restrictToCategories &&
        newFeeDefinitionArgs.restrictToCategories.length > 0
      ) {
        for (const itemCategoryId of newFeeDefinitionArgs.restrictToCategories) {
          inputFilter = inputFilter.filter((c) =>
            c.itemCategoryIds
              ?.map((l) => l.toString())
              .includes(itemCategoryId.toString()),
          )
        }
        return inputFilter
      } else {
        return itemQuestions.filter(
          (c) =>
            !c.itemCategoryIds ||
            (c.itemCategoryIds && c.itemCategoryIds.length === 0),
        )
      }
    } else return []
  }

  const [filteredItemQuestions, setFilteredItemQuestions] = useState(
    getProperItemQuestions(itemQuestions ?? []),
  )

  useEffect(() => {
    setFilteredItemQuestions(getProperItemQuestions(itemQuestions))
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemQuestions])

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Fragment>
        <TextInput value={company.name} label={t('company.singular')} />
        <TextInput
          value={newFeeDefinitionArgs.businessUnit}
          label={t('deal.business_unit')}
        />

        <SelectOptionInput
          value={newFeeDefinitionArgs.feeType}
          onChange={(value) =>
            setNewFeeDefinitionArgs({
              ...newFeeDefinitionArgs,
              companyId: company._id,

              feeType: value,
            })
          }
          label={t('fee_type.label')}
          menuItems={feeTypes}
        />

        <SelectOptionInput
          value={newFeeDefinitionArgs.billingPeriodType ?? undefined}
          onChange={(value) =>
            setNewFeeDefinitionArgs({
              ...newFeeDefinitionArgs,
              companyId: company._id,

              billingPeriodType: value,
            })
          }
          label={t('billing_period_type')}
          menuItems={billingPeriodTypes}
        />
        <SelectOptionInput
          value={newFeeDefinitionArgs.level}
          onChange={(value) =>
            setNewFeeDefinitionArgs({
              ...newFeeDefinitionArgs,
              companyId: company._id,

              level: value,
            })
          }
          label={t('level')}
          menuItems={feeLevels}
        />

        <SelectOptionInput
          value={newFeeDefinitionArgs.mode}
          onChange={(value) =>
            setNewFeeDefinitionArgs({
              ...newFeeDefinitionArgs,
              companyId: company._id,

              mode: value,
            })
          }
          label={t('mode')}
          menuItems={feeModes}
        />

        <SelectOptionInput
          value={newFeeDefinitionArgs.dealTypes}
          onChangeMultiple={(values) =>
            setNewFeeDefinitionArgs({
              ...newFeeDefinitionArgs,
              companyId: company._id,

              dealTypes: values,
            })
          }
          label={t('deal.deal_types')}
          menuItems={possibleDealTypes}
          multiple
          input={<Input />}
        />

        <Box display="flex">
          <div className={'u-inline-block'}>
            <label className="mb-4">{t('included_on_extensions')}</label>
            <Checkbox
              className="ml-4 mt-4"
              checked={!!newFeeDefinitionArgs.includedOnExtensions}
              onChange={(e) => {
                setNewFeeDefinitionArgs({
                  ...newFeeDefinitionArgs,
                  includedOnExtensions: e.target.checked,
                })
              }}
            />
          </div>
        </Box>

        <Box display="flex">
          <div className={'u-inline-block'}>
            <label className="mb-4">{t('active')}</label>
            <Checkbox
              className="ml-4 mt-4"
              checked={!!newFeeDefinitionArgs.isActive}
              onChange={(e) => {
                setNewFeeDefinitionArgs({
                  ...newFeeDefinitionArgs,
                  isActive: e.target.checked,
                })
              }}
            />
          </div>
        </Box>

        <div className="flex flex-row mb-6 mt-6">
          <Title>{t('fees.fee_settings')}</Title>
        </div>

        <InputNum
          locale="de-DE"
          label={
            newFeeDefinitionArgs.feeType === EFeeType.Withdrawal
              ? t('fees.minimum_charged_amount')
              : t('fees.fees_amount')
          }
          {...(newFeeDefinitionArgs.mode === EFeeMode.Constant && {
            mode: 'currency',
          })}
          {...(newFeeDefinitionArgs.mode === EFeeMode.MultiplierPayout && {
            mode: 'decimal',
            suffix: '%',
          })}
          className="flex flex-col md:flex-row items-start md:items-center mb-4"
          labelClassName="text-sm font-semibold w-12.5"
          inputContainerClassName="w-52"
          value={newFeeDefinitionArgs.amount ?? 0}
          onChange={(e) =>
            setNewFeeDefinitionArgs({
              ...newFeeDefinitionArgs,
              companyId: company._id,

              amount: e.value,
            })
          }
        />

        {newFeeDefinitionArgs.feeType === EFeeType.InitialStaggered && (
          <FeeDefinitionInterval
            newFeeDefinitionArgs={newFeeDefinitionArgs}
            setNewFeeDefinitionArgs={setNewFeeDefinitionArgs}
          />
        )}

        {oneOf(newFeeDefinitionArgs.feeType, [
          EFeeType.PickupTransport,
          EFeeType.DropoffTransport,
        ]) && (
          <>
            <Box display="flex">
              <SelectOptionInput
                value={newFeeDefinitionArgs.transportMode}
                onChange={(value) =>
                  setNewFeeDefinitionArgs({
                    ...newFeeDefinitionArgs,
                    transportMode: value as any,
                  })
                }
                label={t('transport_type')}
                menuItems={transportModes}
                input={<Input />}
              />
            </Box>
          </>
        )}

        {oneOf(newFeeDefinitionArgs.feeType, [EFeeType.Interest]) &&
          oneOf(newFeeDefinitionArgs.level, [EFeeLevel.Deal]) && (
            <>
              <FeeAmountForCategories
                newFeeDefinitionArgs={newFeeDefinitionArgs}
                setNewFeeDefinitionArgs={setNewFeeDefinitionArgs}
              />
            </>
          )}

        {newFeeDefinitionArgs.feeType === EFeeType.Withdrawal &&
          newFeeDefinitionArgs.level === EFeeLevel.Deal && (
            <>
              <WithdrawalFeeDefinition
                feeDefinition={newFeeDefinitionArgs}
                setFeeDefinition={setNewFeeDefinitionArgs}
              />
            </>
          )}

        {oneOf(newFeeDefinitionArgs.level, [EFeeLevel.Item]) && (
          <>
            <Box>
              <div style={{ marginTop: '1rem', display: 'inline-block' }}>
                {t('restrict_to_categories')}
              </div>
              <div
                style={{
                  marginTop: '0.75rem',
                  marginBottom: '1rem',
                  display: 'inline-block',
                }}
              >
                {evaluationQueryResult.loading && (
                  <div
                    onClick={onEvaluateFees}
                    className={`${styles.AddIntervalIcon}`}
                  >
                    <LoadingSpinner />
                  </div>
                )}
              </div>
              <div style={{ marginTop: '0.75rem', marginBottom: '1rem' }}>
                <CompanyFeeEvaluator
                  newFeeDefinitionArgs={newFeeDefinitionArgs}
                  setNewFeeDefinitionArgs={setNewFeeDefinitionArgs}
                  companyFeesEvaluation={companyFeesEvaluation}
                  onEvaluateFees={onEvaluateFees}
                  evaluationQueryResult={evaluationQueryResult}
                  isEditMode={isEditMode}
                />
              </div>
            </Box>
          </>
        )}

        {oneOf(newFeeDefinitionArgs.level, [EFeeLevel.Item]) && (
          <>
            <Box display="flex">
              <div className={'u-inline-block'}>
                {t('restrict_to_item_question')}
                <Select
                  style={{ marginLeft: '1rem' }}
                  value={newFeeDefinitionArgs.restrictToItemQuestion}
                  onChange={(e) => {
                    const selectedQuestion = filteredItemQuestions.filter(
                      (c) =>
                        c._id === e.target.value
                          ? (e.target.value as Scalars['ObjectId'])
                          : undefined,
                    )
                    if (selectedQuestion && selectedQuestion.length > 0) {
                      setNewFeeDefinitionArgs({
                        ...newFeeDefinitionArgs,
                        restrictToItemQuestion: selectedQuestion[0]._id,
                        itemQuestionTitle: selectedQuestion[0].titleKey,
                      })
                    } else {
                      setNewFeeDefinitionArgs({
                        ...newFeeDefinitionArgs,
                        restrictToItemQuestion: undefined,
                        itemQuestionTitle: undefined,
                        itemQuestionValidAnswer: undefined,
                      })
                    }
                  }}
                >
                  <MenuItem value="" />
                  {filteredItemQuestions.map((question) => (
                    <MenuItem key={question._id} value={question._id}>
                      {question.titleKey}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Box>

            {itemQuestions.length > 0 &&
            newFeeDefinitionArgs.restrictToItemQuestion ? (
              <TextInput
                value={
                  itemQuestions.filter(
                    (c) =>
                      c._id === newFeeDefinitionArgs.restrictToItemQuestion,
                  ) &&
                  itemQuestions
                    .filter(
                      (c) =>
                        c._id === newFeeDefinitionArgs.restrictToItemQuestion,
                    )[0]
                    .singleChoiceOptions?.filter((c) => c.isDefaultValue)[0]
                    .labelKey
                }
                label={t('question_default_answer')}
              />
            ) : null}

            {newFeeDefinitionArgs.restrictToItemQuestion &&
            filteredItemQuestions?.filter(
              (c) => c._id === newFeeDefinitionArgs.restrictToItemQuestion,
            )[0] ? (
              <Box display="flex">
                <div className={'u-inline-block'}>
                  {t('fees.fee_item_question_valid_answer')}
                  <Select
                    style={{ marginLeft: '1rem' }}
                    value={newFeeDefinitionArgs.itemQuestionValidAnswer}
                    onChange={(e) => {
                      setNewFeeDefinitionArgs({
                        ...newFeeDefinitionArgs,
                        itemQuestionValidAnswer: e.target.value as string,
                      })
                    }}
                  >
                    {filteredItemQuestions
                      ?.filter(
                        (c) =>
                          c._id === newFeeDefinitionArgs.restrictToItemQuestion,
                      )[0]
                      .singleChoiceOptions?.map((labelKey) => (
                        <MenuItem
                          key={labelKey.labelKey}
                          value={labelKey.labelKey}
                        >
                          {labelKey.labelKey}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </Box>
            ) : null}
          </>
        )}
      </Fragment>
    </Paper>
  )
}

export default FeeDefinitionComponent

const billingPeriodTypes = [
  EBillingPeriodType.OneTime,
  EBillingPeriodType.Day,
  EBillingPeriodType.HalfMonth,
  EBillingPeriodType.Month,
].map((str) => ({
  label: str,
  value: str,
}))

const feeLevels = [EFeeLevel.Item, EFeeLevel.Deal].map((str) => ({
  label: str,
  value: str,
}))

const feeModes = [
  EFeeMode.Constant,
  EFeeMode.MultiplierPayout,
  EFeeMode.Custome,
].map((str) => ({
  label: str,
  value: str,
}))

const feeTypes = [
  EFeeType.Storage,
  EFeeType.Verification,
  EFeeType.Interest,
  EFeeType.Initial,
  EFeeType.InitialStaggered,
  EFeeType.PickupTransport,
  EFeeType.DropoffTransport,
  EFeeType.Cleaning,
  EFeeType.Manipulation,
  EFeeType.Withdrawal,
].map((str) => ({
  label: str,
  value: str,
}))

const possibleDealTypes = [EDealType.Pawn, EDealType.Purchase].map((str) => ({
  label: str,
  value: str,
}))

const transportModes = [
  ETransportMode.StandardShipment,
  ETransportMode.Courier,
  ETransportMode.Storebox,
].map((str) => ({
  label: str,
  value: str,
}))

const Title = styled.p`
  font-weight: 600;
  font-size: 20px;
`
