import PrivateSaleTransactionInputDetails from '../Common/PrivateSaleTransactionInputDetails'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import dayjs from 'dayjs'
import * as React from 'react'
import { FunctionComponent, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import {
  EManualPaymentType,
  ETransactionType,
  Item,
  PrivateSale,
  PrivateSaleTransactionArgs,
} from '@/schemaTypes'

interface AddPrivateSaleTransactionProps {
  open
  scroll
  handleClose
  onConfirm
  isPrivateSaleCreated: boolean
  privateSale: PrivateSale | null
  isEditMode: boolean
  item?: Item
}

export const defaultTransactionArgs: PrivateSaleTransactionArgs = {
  amount: 0,
  date: dayjs().toDate(),
  paymentType: EManualPaymentType.Dummy,
  transactionType: ETransactionType.SellingCar,
  buyerName: undefined,
  description: undefined,
  order: 0,
  costName: undefined,
  grossAmount: undefined,
  ust: 20,
  regenerateBill: false,
  shopId: undefined,
}

const AddPrivateSaleTransaction: FunctionComponent<
  AddPrivateSaleTransactionProps
> = (props) => {
  const {
    open,
    scroll,
    handleClose,
    onConfirm,
    isPrivateSaleCreated,
    privateSale,
    isEditMode,
    item,
  } = props
  const { t } = useTranslation()
  const { showInfo } = useContext(Context)

  const [newTransactionArgs, setNewTransactionArgs] =
    useState<PrivateSaleTransactionArgs>({ ...defaultTransactionArgs })

  const onConfirmDialog = () => {
    if (newTransactionArgs.amount < 0) {
      showInfo(t('amount_is_not_valid'))
    } else if (
      newTransactionArgs.transactionType ===
        ETransactionType.CashExpenditures &&
      !newTransactionArgs.costName
    ) {
      showInfo(t('invoice_text_is_not_valid'))
    } else {
      onConfirm(newTransactionArgs)
      setNewTransactionArgs(defaultTransactionArgs)
    }
  }

  const onAbortDialog = () => {
    handleClose()
    setNewTransactionArgs(defaultTransactionArgs)
  }

  return (
    <div>
      <Dialog
        disableBackdropClick
        maxWidth={'sm'}
        open={open}
        onClose={onAbortDialog}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <Typography variant="h6" gutterBottom>
          <DialogTitle>{t('add_transaction')}</DialogTitle>
        </Typography>
        <DialogContent dividers={scroll === 'paper'}>
          <PrivateSaleTransactionInputDetails
            privateSaleTransactionArgs={newTransactionArgs}
            setPrivateSaleTransactionArgs={setNewTransactionArgs}
            isPrivateSaleCreated={isPrivateSaleCreated}
            privateSale={privateSale}
            isEditMode={isEditMode}
            item={item}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onAbortDialog} color="primary">
            {t('abort')}
          </Button>
          <Button onClick={onConfirmDialog} color="primary">
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddPrivateSaleTransaction
