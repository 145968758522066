import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationRemoveItemCategoryIdArgs } from '@/schemaTypes'

const REMOVE_ITEM_CATEGORY_ID = gql`
  mutation removeItemCategoryId(
    $shopId: ObjectId!
    $itemCategoryId: ObjectId!
  ) {
    removeItemCategoryId(shopId: $shopId, itemCategoryId: $itemCategoryId) {
      _id
      name
      phone
      email
      googleMapsLink
      address {
        ...AddressFragment
      }
      partnerId
      hideLocationOnWebsite
      disabled
      cashBoxNames
      itemCategoryIds
      itemCategories {
        _id
        name
        parentId
      }
      openingHours
    }
  }
  ${fragments.address}
`

type TData = { removeItemCategoryId: Mutation['removeItemCategoryId'] }
type TVariables = MutationRemoveItemCategoryIdArgs

export function useRemoveItemCategoryId(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [removeItemCategoryId] = useMutation<TData, TVariables>(
    REMOVE_ITEM_CATEGORY_ID,
    opts,
  )

  return removeItemCategoryId
}
