import { Dialog, DialogProps } from '@material-ui/core'
import React, { useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import CloseIcon from '@/images/05-cross.svg'
import InfoIcon from '@/images/07-info.svg'
import { oneOf } from '@/utils/misc'
import styles from './Modal.module.scss'

type Props = {
  children: React.ReactNode
  onClose?: () => unknown
  // TODO: Make sure this does not take any string, make sure to consolidate the types
  type?: 'info' | 'CONFIRM' | 'ERROR' | 'INFO' | string
  autoFocusCloseButton?: boolean
  preventAutoCloseByKey?: boolean
  hideCloseIcon?: boolean
  open?: boolean
} & Omit<DialogProps, 'open'>

const Modal = ({
  children,
  onClose,
  type,
  autoFocusCloseButton,
  preventAutoCloseByKey,
  hideCloseIcon = false,
  open = true,
  ...rest
}: Props) => {
  const closeButtonRef: any = React.createRef()

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClose()
    }
  }

  useEffect(() => {
    if (preventAutoCloseByKey) return

    const close = (e) => {
      if (oneOf(e.keyCode, [13, 27])) {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preventAutoCloseByKey])

  if (autoFocusCloseButton) {
    setTimeout(() => {
      if (closeButtonRef && closeButtonRef.current) {
        closeButtonRef.current.focus()
      }
    }, 1)
  }

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      {...rest}
      onClose={(_event, reason) => {
        if (reason === 'backdropClick') {
          onClose()
        }
      }}
    >
      <div className={styles.modal}>
        <div className={`${styles.header} o-media`}>
          <div className="o-media__fluid">
            {type === 'info' && (
              <ReactSVG
                style={{ width: 14, height: 14, marginTop: 3 }}
                src={InfoIcon}
              />
            )}
          </div>
          {!hideCloseIcon && (
            <div
              onClick={(e) => onClose() || e.preventDefault()}
              className={`o-media__fixed u-pr-z ${styles.closeButton}`}
              tabIndex={0}
              onKeyPress={handleKeyPress}
              ref={closeButtonRef}
            >
              <ReactSVG style={{ width: 20, height: 20 }} src={CloseIcon} />
            </div>
          )}
        </div>
        <div className={`${styles.content} u-pt-10`}>{children}</div>
      </div>
    </Dialog>
  )
}

export default Modal
