import { auctionFragments } from '../auctionDetails/auctionFragments'
import { QueryHookOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import useDebouncedQuery from '@/hooks/useDebouncedQuery'
import { ELanguageCode, Query, QuerySearchAuctionsArgs } from '@/schemaTypes'

const SEARCH_AUCTIONS = gql`
  query searchAuctions(
    $args: AuctionsFiltersArgs!
    $languageCode: ELanguageCode!
  ) {
    searchAuctions(args: $args) {
      nodes {
        ...AuctionDetailsFragment
      }
      pageInfo {
        skip
        limit
        hasPrevPage
        hasNextPage
        total
      }
    }
  }
  ${fragments.customDealFormQuestion}
  ${fragments.formAnswers}
  ${auctionFragments.auctionDetails}
  ${fragments.address}
`

type TData = { searchAuctions: Query['searchAuctions'] }
type TVariables = { languageCode?: ELanguageCode } & QuerySearchAuctionsArgs

export function useSearchAuctions(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useDebouncedQuery<TData, TVariables>(
    SEARCH_AUCTIONS,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
    200,
  )

  useShowErrorsPopup(queryResult.error)

  return { queryResult }
}
