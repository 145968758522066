import styled from '@emotion/styled'
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import { uniq } from 'lodash'
import MiniSearch from 'minisearch'
import React, { useEffect, useMemo, useState } from 'react'
import { useContext } from 'react'
import TextInput from '@/components/TextInput'
import { Context } from '@/context'
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback'
import AuctionItemRejectIcon from '@/images/auctionItemReject.png'
import { AuctionItem, EAuctionItemStatus } from '@/schemaTypes'

export interface AuctionItemsTableProps {
  auctionItems: Array<AuctionItem>
  setAuctionItems: React.Dispatch<React.SetStateAction<AuctionItem[]>>
  onClickItemView: () => void
  onClickSetBuyerInformation: () => void
  setSelectedItemIds: React.Dispatch<React.SetStateAction<string[]>>
  selectedItemIds: Array<string>
}

const miniSearch = new MiniSearch({
  fields: ['title', 'bookingNumber', 'storageLabel', 'buyerName'],
  searchOptions: {
    boost: { title: 2 },
    fuzzy: 0.2,
  },
})

const isLackOfBuyerInformationAndSurchargeCertificate = (
  auctionItem: Pick<
    AuctionItem,
    'status' | 'surchargeConfirmationLink' | 'buyer' | 'buyerName'
  >,
) => {
  return (
    auctionItem.status === EAuctionItemStatus.Sold &&
    auctionItem.buyerName &&
    !auctionItem.buyer?.type &&
    !auctionItem.surchargeConfirmationLink
  )
}

export function AuctionItemsTable(props: AuctionItemsTableProps) {
  const { t } = useContext(Context)
  const {
    auctionItems,
    setAuctionItems,
    onClickItemView,
    onClickSetBuyerInformation,
    setSelectedItemIds,
    selectedItemIds,
  } = props

  const [itemsFilters, setItemsFilters] = useState<{
    text?: string
    buyerNames?: Array<string>
  } | null>(null)

  const soldItems = auctionItems.filter(
    (item) => item.status === EAuctionItemStatus.Sold,
  )

  useEffect(() => {
    miniSearch.removeAll()

    const normalizedItems = soldItems?.map((item) => {
      const dealHistory = item.item?.dealsHistory?.find(
        (h) => h.dealId === item.dealId,
      )
      return {
        id: item.itemId,
        title: dealHistory?.title,
        bookingNumber: dealHistory?.deal?.bookingNumber,
        storageLabel: item.item?.storageLabel,
        buyerName: item.buyerName,
      }
    })

    miniSearch.addAll(normalizedItems ?? [])
  }, [soldItems])

  const filteredItems = useMemo(() => {
    let auctionItems: AuctionItem[] = soldItems ?? []

    if (itemsFilters?.text) {
      auctionItems = miniSearch
        .search(itemsFilters.text)
        .map((res) => soldItems?.find((item) => item.itemId === res.id))
        .filter((item) => item)
    }

    if (itemsFilters?.buyerNames) {
      auctionItems = auctionItems.filter(
        (item) =>
          item.buyerName && itemsFilters.buyerNames?.includes(item.buyerName),
      )
    }

    return auctionItems
  }, [itemsFilters?.text, itemsFilters?.buyerNames, soldItems])

  const soldFilteredItems = useMemo(
    () =>
      filteredItems.filter((item) =>
        isLackOfBuyerInformationAndSurchargeCertificate(item),
      ),
    [filteredItems],
  )

  const [onChangeTextSearch] = useDebouncedCallback(
    (value) =>
      setItemsFilters((prev) => ({
        ...prev,
        text: value,
      })),
    300,
  )

  const getBuyerName = (item: AuctionItem) => {
    if (item.buyerName) {
      return item.buyerName
    }
    if (item.buyer?.__typename === 'AuctionItemExistingCustomer') {
      return item.buyer.customer?.firstname
    }

    if (item.buyer?.__typename === 'AuctionItemNewCustomer') {
      return item.buyer.firstname
    }

    return ''
  }

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Box
        style={{ padding: 5, display: 'flex', justifyContent: 'flex-start' }}
      >
        <TextInput
          onChange={onChangeTextSearch}
          label={<Typography>{t('search')}</Typography>}
        />
        <Box style={{ marginLeft: 30 }}>
          {uniq(soldItems?.map((item) => item.buyerName)).map((buyerName) =>
            buyerName ? (
              <span style={{ padding: 5 }}>
                <Chip
                  onClick={() =>
                    setItemsFilters((prev) => ({
                      ...prev,
                      buyerNames:
                        buyerName && prev?.buyerNames?.includes(buyerName)
                          ? undefined
                          : [...(prev?.buyerNames ?? []), buyerName],
                    }))
                  }
                  style={{
                    backgroundColor: '#D0BCFF',
                    fontWeight: 500,
                    cursor: 'pointer',
                    opacity:
                      buyerName && itemsFilters?.buyerNames?.includes(buyerName)
                        ? 1
                        : 0.5,
                  }}
                  label={buyerName}
                />
              </span>
            ) : null,
          )}
        </Box>
      </Box>

      <Table className="u-mb-sm" size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              {' '}
              <Checkbox
                checked={
                  soldFilteredItems.length > 0 &&
                  selectedItemIds.length === soldFilteredItems.length
                }
                onClick={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedItemIds(
                      soldFilteredItems.map((item) => item.itemId),
                    )
                  } else {
                    setSelectedItemIds([])
                  }
                }}
              />{' '}
            </TableCell>
            <TableCell align="left">{t('deal.booking_number')}</TableCell>
            <TableCell align="left">{t('storage.label')}</TableCell>
            <TableCell align="left">{t('item_name')}</TableCell>
            <TableCell align="left">{t('start_price')}</TableCell>
            <TableCell align="left">{t('sold_for')}</TableCell>
            <TableCell align="left">{t('buyer')}</TableCell>
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredItems.map((item) => {
            const dealHistory = item.item?.dealsHistory?.find(
              (h) => h.dealId === item.dealId,
            )
            const editableItem =
              isLackOfBuyerInformationAndSurchargeCertificate(item)
            return (
              <TableRow style={{ opacity: editableItem ? 1 : 0.6 }}>
                <TableCell component="th" scope="row">
                  {editableItem ? (
                    <Checkbox
                      checked={selectedItemIds.includes(item.itemId)}
                      onClick={(e) => e.stopPropagation()}
                      onFocus={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedItemIds((prev) => [...prev, item.itemId])
                        } else {
                          setSelectedItemIds((prev) =>
                            prev.filter((itemId) => itemId !== item.itemId),
                          )
                        }
                      }}
                    />
                  ) : (
                    <img
                      style={{ marginLeft: 10 }}
                      src={AuctionItemRejectIcon}
                      alt="file-review"
                    />
                  )}
                </TableCell>
                <TableCell align="left">
                  {dealHistory?.deal?.bookingNumber}
                </TableCell>
                <TableCell align="left">{item.item?.storageLabel}</TableCell>
                <TableCell align="left">{dealHistory?.title}</TableCell>
                <TableCell align="left">{item.startPrice} €</TableCell>
                <TableCell align="left">
                  <TextInput
                    type="number"
                    disabled={!editableItem}
                    inputProps={{ style: { width: 100 } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                    value={item.finalPrice}
                    onChange={(value) => {
                      setAuctionItems((prev) =>
                        prev.map((soldItem) => {
                          const newItem = { ...soldItem }

                          if (value && newItem.itemId === item.itemId) {
                            newItem.finalPrice = parseFloat(value)
                          }

                          return newItem
                        }),
                      )
                    }}
                  />
                </TableCell>
                <TableCell align="left">{getBuyerName(item)}</TableCell>
                <TableCell align="left">
                  {' '}
                  {item.surchargeConfirmationLink?.url && (
                    <a
                      target="_blank"
                      href={item.surchargeConfirmationLink.url}
                      rel="noreferrer"
                    >
                      <u>{t('surcharge_certificate')}</u>
                    </a>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledButton
          disabled={selectedItemIds.length === 0}
          style={{
            width: 280,
            opacity: selectedItemIds.length === 0 ? 0.6 : 1,
          }}
          onClick={() => onClickSetBuyerInformation()}
        >
          {t('set_buyer_data_and_generate_document')}
        </StyledButton>
        <ItemViewButton onClick={() => onClickItemView()}>
          Item View
        </ItemViewButton>
      </Box>
    </Paper>
  )
}

const ItemViewButton = styled(Button)`
  &&& {
    height: 30px;
    width: 150px;
    text-transform: none;
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    font-size: 14px !important;
  }
`

const StyledButton = styled(Button)`
  &&& {
    background: #6750a4;
    height: 30px;
    text-transform: none;
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    font-size: 14px !important;
    color: #ffffff !important;
  }
`
