import { IbanPaypalInformation } from '../IbanPaypalInformation'
import ManualFeeDefinition from '../ManualFeeDefintion/ManualFeeDefinition'
import { TermsAndConditions } from '../TermsAndConditions/TermsAndConditions'
import UsedFeeDefinitionViewer from '../UsedFeeDefinitionViewer'
import InfoIcon from '@material-ui/icons/Info'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ToggleButton,
  tableCellClasses,
} from '@mui/material'
import dayjs from 'dayjs'
import { Fragment, FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '@/components/LoadingSpinner'
import PaymentTypePicker from '@/components/PaymentTypePicker'
import { useDealPaybackCalculation } from '@/domains/deals/components/hooks/dealPaybackCalculation'
import {
  Deal,
  DealCalculation,
  DealPaybackCalculationArgs,
  EManualPaymentType,
  ETransportMode,
} from '@/schemaTypes'
import {
  getLatestDealManualFeeDefinitionArgs,
  getLatestEventForStatus,
  getManualFeeDefinitionsFromCalculation,
  getTotalGrossFeeAmountOfType,
} from '@/utils/deal'
import { displayLocalAmount } from '@/utils/misc'
import { addDays, getDurationInDays } from '@/utils/time'
import styles from './PaybackBox.module.scss'

interface PaybackBoxProps {
  deal: Deal
  newPaymentType?: EManualPaymentType
  onChangeDealPaybackCalculationId?: (
    dealPaybackCalculationId: string,
  ) => unknown
  onChangePaymentType?: (paymentType: EManualPaymentType) => unknown
  withdrawalFeeTermsAccepted?: boolean
  setWithdrawalFeeTermsAccepted?: (value: boolean) => void
}

const PaybackBox: FunctionComponent<PaybackBoxProps> = (props) => {
  const { t } = useTranslation()

  const {
    deal,
    onChangeDealPaybackCalculationId,
    onChangePaymentType,
    newPaymentType,
    withdrawalFeeTermsAccepted,
    setWithdrawalFeeTermsAccepted,
  } = props
  const today = dayjs().toDate()
  const isPreviewMode = Boolean(onChangeDealPaybackCalculationId)
  const confirmedPayback = getLatestEventForStatus(
    deal,
    'DealPaybackConfirmedEvent',
  )
  const isPayBackConfirmed = Boolean(confirmedPayback)
  const [dealPaybackCalculation, setDealPaybackCalculation] =
    useState<DealCalculation>()

  const [showFeeDetails, setShowFeeDetails] = useState(false)
  const [initialCalculation, setInitialCalculation] = useState<
    DealCalculation | undefined
  >(confirmedPayback?.dealCalculation)
  const [showManualFeeDetails, setShowManualFeeDetails] = useState(false)

  const iban = deal.company.iban
  const paypalEmail = deal.company.paypalEmail

  const [paybackDealCalculationArgs, setPaybackDealCalculationArgs] =
    useState<DealPaybackCalculationArgs>(() =>
      getDefaultDealPaybackArgs({ deal, today }),
    )

  const { queryResult: paybackCalculationQueryResult } =
    useDealPaybackCalculation({
      variables: {
        dealId: deal._id,
        args: paybackDealCalculationArgs,
      },
      skip: !isPreviewMode,
      onCompleted: ({ dealPaybackCalculation }) => {
        if (onChangeDealPaybackCalculationId && dealPaybackCalculation) {
          onChangeDealPaybackCalculationId(
            dealPaybackCalculation.dealPaybackCalculationId,
          )
        }
      },
    })

  if (
    paybackCalculationQueryResult.data?.dealPaybackCalculation &&
    dealPaybackCalculation !==
      paybackCalculationQueryResult.data?.dealPaybackCalculation.dealCalculation
  ) {
    setDealPaybackCalculation(
      paybackCalculationQueryResult.data.dealPaybackCalculation.dealCalculation,
    )
  }

  if (
    !initialCalculation &&
    paybackCalculationQueryResult.data?.dealPaybackCalculation
  ) {
    setInitialCalculation(
      paybackCalculationQueryResult.data.dealPaybackCalculation.dealCalculation,
    )
  }

  if (
    !isPreviewMode &&
    (!confirmedPayback || !confirmedPayback.dealCalculation)
  ) {
    return (
      <div>
        <span
          style={{
            color: 'red',
          }}
        >
          {t('deal:deal_payback_confirmed_event_calculation_cannot_be_fetched')}
        </span>
      </div>
    )
  }

  return (
    <Fragment>
      <Paper className="u-p-sm u-12/12 u-mb-sm">
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
            minWidth: 400,
          }}
          size="small"
          aria-label="a dense table"
        >
          <TableBody>
            <TableRow>
              <TableCell width={150}>{`${t('date_of_request')}:`}</TableCell>
              <TableCell
                sx={{
                  paddingLeft: '25px',
                }}
              >
                <KeyboardDatePicker
                  className={`${styles.datePicker} u-ml-5`}
                  clearable
                  format="DD.MM.YYYY"
                  value={addDays(
                    deal.dealStartDate,
                    paybackDealCalculationArgs.durationInDays ?? 0,
                  )}
                  minDate={deal.dealStartDate}
                  onChange={(date) => {
                    setPaybackDealCalculationArgs((args) => ({
                      ...args,
                      durationInDays: getDurationInDays(
                        date ? date?.toDate() : today,
                        deal.dealStartDate,
                      ),
                    }))
                  }}
                  disabled={isPayBackConfirmed}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('manual_mode')}</TableCell>

              <TableCell>
                <span>
                  <Switch
                    color="secondary"
                    checked={showManualFeeDetails}
                    onChange={(event) => {
                      setShowManualFeeDetails(event.target.checked)
                      setPaybackDealCalculationArgs((args) => ({
                        ...args,
                        manualFeeDefinitionsArgs: confirmedPayback
                          ? args.manualFeeDefinitionsArgs
                          : getManualFeeDefinitionsFromCalculation(
                              dealPaybackCalculation,
                              deal.dealType,
                            ),
                        shouldOverwriteManualFees: event.target.checked,
                      }))
                    }}
                    disabled={!dealPaybackCalculation && !confirmedPayback}
                  />
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{`${t('payback_type')}:`}</TableCell>
              <TableCell>
                <PaymentTypePicker
                  onChangePaymentType={onChangePaymentType}
                  disabled={isPayBackConfirmed}
                  businessUnit={deal.businessUnit}
                  removePadding={true}
                  label=""
                />
              </TableCell>
            </TableRow>
            {newPaymentType === EManualPaymentType.Bank && iban && (
              <IbanPaypalInformation
                deal={deal}
                ibanOrPaypalEmail={iban}
                austriaLabel={'cashy_austria_iban'}
                germanLabel={'cashy_germany_iban'}
              />
            )}
            {newPaymentType === EManualPaymentType.Paypal && paypalEmail && (
              <IbanPaypalInformation
                deal={deal}
                ibanOrPaypalEmail={paypalEmail}
                austriaLabel={'cashy_austria_paypal_email'}
                germanLabel={'cashy_germany_paypal_email'}
              />
            )}
            <TableRow>
              <TableCell colSpan={2}>
                <div className={`${styles.boxDiv}`}>
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            paddingLeft: '5px',
                          }}
                        >{`${t('payout.payout_amount')}:`}</TableCell>
                        <TableCell>
                          {displayLocalAmount(
                            confirmedPayback && confirmedPayback.dealCalculation
                              ? confirmedPayback.dealCalculation.dealValuesEntry
                                  .payoutAmount
                              : dealPaybackCalculation?.dealValuesEntry
                                  .payoutAmount,
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <span
                            style={{
                              marginRight: '0.5rem',
                              marginLeft: '-0.7rem',
                            }}
                          >
                            <ToggleButton
                              style={{
                                paddingTop: '5px',
                                width: '1px',
                                height: '1px',
                              }}
                              value={showFeeDetails}
                              size="small"
                              selected={showFeeDetails}
                              onChange={() => {
                                setShowFeeDetails(!showFeeDetails)
                              }}
                            >
                              <InfoIcon />
                            </ToggleButton>
                          </span>
                          {`${t('fees.label')}:`}
                        </TableCell>
                        <TableCell>
                          {confirmedPayback && confirmedPayback.dealCalculation
                            ? displayLocalAmount(
                                getTotalGrossFeeAmountOfType(
                                  confirmedPayback.dealCalculation
                                    .appliedUsedFeeDefinitions,
                                ),
                              )
                            : dealPaybackCalculation
                              ? displayLocalAmount(
                                  getTotalGrossFeeAmountOfType(
                                    dealPaybackCalculation.appliedUsedFeeDefinitions,
                                  ),
                                )
                              : ''}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            borderTop: '1px solid black',
                            paddingLeft: '5px',
                          }}
                        >{`${t('payout.payback_amount')}:`}</TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            borderTop: '1px solid black',
                          }}
                        >
                          {paybackCalculationQueryResult?.loading ? (
                            <LoadingSpinner />
                          ) : (
                            displayLocalAmount(
                              confirmedPayback &&
                                confirmedPayback.dealCalculation
                                ? confirmedPayback.dealCalculation
                                    .dealValuesEntry.paybackAmount
                                : dealPaybackCalculation?.dealValuesEntry
                                    .paybackAmount,
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </TableCell>
            </TableRow>
            {(dealPaybackCalculation || confirmedPayback) && (
              <>
                <TableRow>
                  <TableCell colSpan={2}>
                    {showFeeDetails && (
                      <div style={{ marginTop: '2rem' }}>
                        <UsedFeeDefinitionViewer
                          deal={deal}
                          dealCalculation={
                            confirmedPayback && confirmedPayback.dealCalculation
                              ? confirmedPayback.dealCalculation
                              : dealPaybackCalculation
                          }
                          items={deal.items}
                          isLoading={paybackCalculationQueryResult?.loading}
                          showLayoutButton={true}
                          defaultLayout={'module'}
                          showItemDetailsButton={true}
                          itemRecordsButtonDefaultValue={true}
                          showChargTableText={true}
                        />
                      </div>
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2}>
                    {showManualFeeDetails && (
                      <div style={{ marginTop: '2rem' }}>
                        <ManualFeeDefinition
                          deal={deal}
                          initialCalculation={initialCalculation}
                          args={
                            paybackDealCalculationArgs.manualFeeDefinitionsArgs
                          }
                          setArgs={(value) => {
                            setPaybackDealCalculationArgs((args) => ({
                              ...args,
                              manualFeeDefinitionsArgs: value,
                              shouldOverwriteManualFees: showManualFeeDetails,
                            }))
                          }}
                          dealCalculation={
                            dealPaybackCalculation ??
                            confirmedPayback.dealCalculation
                          }
                          calculationQueryResult={paybackCalculationQueryResult}
                          showPayoutAmount={false}
                          showResetButton={true}
                          isReadonly={isPayBackConfirmed}
                        />
                      </div>
                    )}
                  </TableCell>
                </TableRow>

                {deal.acceptingWithdrawalFeeTermsRequired &&
                  !isPayBackConfirmed && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <TermsAndConditions
                          withdrawalFeeTermsAccepted={Boolean(
                            withdrawalFeeTermsAccepted,
                          )}
                          setWithdrawalFeeTermsAccepted={
                            setWithdrawalFeeTermsAccepted
                          }
                        />
                      </TableCell>
                    </TableRow>
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  )
}

export default PaybackBox

export function getDefaultDealPaybackArgs({
  deal,
  today,
}: {
  deal: Deal
  today: Date
}): DealPaybackCalculationArgs {
  const confirmedPayback = getLatestEventForStatus(
    deal,
    'DealPaybackConfirmedEvent',
  )

  return {
    durationInDays:
      confirmedPayback?.dealCalculation?.dealValuesEntry.durationInDays ??
      getDurationInDays(today, deal.dealStartDate),
    shouldOverwriteManualFees: false,
    manualFeeDefinitionsArgs: getLatestDealManualFeeDefinitionArgs(deal),
    dropoffData: {
      transportMode: deal.dropoffData?.transportMode ?? ETransportMode.Shop,
    },
  }
}
