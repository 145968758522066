import { QueryHookOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import useDebouncedQuery from '@/hooks/useDebouncedQuery'
import { Query, QueryGetSimilarRefurbedProductsArgs } from '@/schemaTypes'

const GET_SIMILAR_REFURBED_PRODUCTS = gql`
  query getSimilarRefurbedProducts($opts: RefurbedProductSearchArgs!) {
    getSimilarRefurbedProducts(opts: $opts) {
      instanceId
      nameDe
      searchTags
    }
  }
`

type TData = { getSimilarRefurbedProducts: Query['getSimilarRefurbedProducts'] }
type TVariables = QueryGetSimilarRefurbedProductsArgs

export function useGetSimilarRefurbedProducts(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useDebouncedQuery<TData, TVariables>(
    GET_SIMILAR_REFURBED_PRODUCTS,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
    400,
    true,
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    similarRefurbedProducts: queryResult.data?.getSimilarRefurbedProducts || [],
  }
}
