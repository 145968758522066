import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationChangeKsvAssetArgs } from '@/schemaTypes'

const mutation = gql`
  mutation changeKSVAsset($itemId: ObjectId!) {
    changeKSVAsset(itemId: $itemId)
  }
`

type TData = { changeKSVAsset: Mutation['changeKSVAsset'] }
type TVariables = MutationChangeKsvAssetArgs

export function useChangeKSVAssetMutation(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [changeKSVAsset] = useMutation<TData, TVariables>(mutation, {
    ...opts,
  })
  return changeKSVAsset
}
