import TextInput from '../../../../../components/TextInput'
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NavigateBeforeSharpIcon from '@material-ui/icons/NavigateBeforeSharp'
import NavigateNextSharpIcon from '@material-ui/icons/NavigateNextSharp'
import dayjs from 'dayjs'
import queryString from 'query-string'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import DefaultActionWrapper from '@/components/DefaultActionWrapper'
import Loading from '@/components/Loading'
import { useGetDeals } from '@/domains/deals/hooks/getDeals'
import { useGetDealsCount } from '@/domains/deals/hooks/getDealsCount'
import {
  AuctionElement,
  Deal,
  EAuctionCreationType,
  EDealType,
  EIdFilterConditions,
  ENumberFilterConditions,
  EOrderBy,
  IdFilter,
  NumberFilter,
} from '@/schemaTypes'
import styles from './AuctionDealsList.module.scss'

const TAKE: number = 20

interface AuctionDealsListProps {
  suggestedDeals
  auctionDate
  finalAuctionElements
  setLocalFinalElements
  companyId: string
}

interface DealsFilterArgs {
  bookingNumber?: NumberFilter
  _id?: IdFilter
  ids?: IdFilter[]
  companyId: IdFilter
}

export function AuctionDealsList(props: AuctionDealsListProps) {
  const {
    suggestedDeals,
    finalAuctionElements,
    setLocalFinalElements,
    auctionDate,
    companyId,
  } = props
  const { t } = useTranslation()
  const location = useLocation()

  const params = queryString.parse(location.search)
  const [skip, setSkip] = useState(
    !Number.isNaN(parseInt(params.skip as string)) ||
      parseInt(params.skip as string) > 0
      ? parseInt(params.skip as string)
      : 0,
  )
  const [currentCount, setCurrentCount] = useState<number | undefined>(
    skip ? skip : 1,
  )
  const [bookingNumber, setBookingNumber] = useState<string | undefined>(
    undefined,
  )
  const [dealId, setDealId] = useState<string | undefined>(undefined)
  const [filterArgs, setFilterArgs] = useState<DealsFilterArgs>({
    bookingNumber: undefined,
    _id: undefined,
    ids: suggestedDeals.map((c) => c._id),
    companyId: {
      value: companyId,
      condition: EIdFilterConditions.Equals,
    },
  })
  const [takeCounter, setTakeCounter] = useState<number | undefined>(1)
  const [, setNewItemsCounter] = useState<number>(0)
  const [dealsListType, setDealsListType] = useState('2') // 1 means All . 2 means Suggested

  const { deals, queryResult } = useGetDeals({
    variables: {
      opts: {
        filter: {
          dealType: EDealType.Pawn,
          bookingNumber: filterArgs.bookingNumber,
          _id: filterArgs._id,
          ids: filterArgs.ids,
          companyId: filterArgs.companyId,
        },
        order: { _id: EOrderBy.Desc },
        take: TAKE,
        skip: skip,
      },
    },
    skip: !companyId,
  })

  // An addition to know the total of the Deals
  const { dealsTotal } = useGetDealsCount({
    variables: {
      opts: {
        filter: {
          dealType: EDealType.Pawn,
          bookingNumber: filterArgs.bookingNumber,
          _id: filterArgs._id,
          ids: filterArgs.ids,
        },
      },
    },
  })

  const _handleSearch = async () => {
    setSkip(0)
    setCurrentCount(1)

    setFilterArgs({
      bookingNumber: bookingNumber
        ? {
            value: parseInt(bookingNumber),
            condition: ENumberFilterConditions.Equals,
          }
        : undefined,
      _id: dealId
        ? {
            value: dealId.trim(),
            condition: EIdFilterConditions.Equals,
          }
        : undefined,
      ids: dealsListType === '2' ? suggestedDeals.map((c) => c._id) : undefined,
      companyId: {
        value: companyId,
        condition: EIdFilterConditions.Equals,
      },
    })
  }

  const _resetSearch = async () => {
    setDealsListType('2')
    setSkip(0)
    setBookingNumber(undefined)
    setDealId(undefined)
    setTakeCounter(1)
    setFilterArgs({
      bookingNumber: undefined,
      _id: undefined,
      ids: suggestedDeals.map((c) => c._id),
      companyId: {
        value: companyId,
        condition: EIdFilterConditions.Equals,
      },
    })
  }

  const _onNext = () => {
    if (deals && deals.length > 0) {
      setSkip((old) => old + TAKE)
      currentCount && setCurrentCount(skip + 1)
    }
  }

  const _onPrev = () => {
    if (deals && deals.length > 0) {
      setSkip((old) => (old - TAKE < 0 ? 0 : old - TAKE))
      currentCount && setCurrentCount(skip >= 2 ? skip - 1 : 1)
    }
  }

  const takeCounterChecker = takeCounter ? takeCounter - TAKE <= 0 : false

  const previousDisabled =
    (Number.isNaN(skip) || skip - 1 <= 0) && takeCounterChecker
  const nextDisabled = dealsTotal ? skip + TAKE >= dealsTotal : false

  const onAddDeal = (bookingNumber) => {
    const temp = deals.filter((c) => c.bookingNumber === bookingNumber)
    const newElement = CreateAuctionElementFromDeal(temp[0])
    finalAuctionElements.push(newElement)
    setLocalFinalElements(finalAuctionElements)

    setNewItemsCounter((prev) => prev + 1)
    // this is used just for re-rendering
  }

  const onRemoveDeal = (bookingNumber) => {
    const index = finalAuctionElements.findIndex(
      (c) => c.bookingNumber === bookingNumber,
    )
    finalAuctionElements.splice(index, 1)
    setLocalFinalElements(
      finalAuctionElements.filter((c) => c.bookingNumber !== bookingNumber),
    )

    setNewItemsCounter((prev) => prev - 1) // this is used just for re-rendering
  }

  const onChangeDealsListData = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newState = (event.target as HTMLInputElement).value
    const companyIdFilter = {
      value: companyId,
      condition: EIdFilterConditions.Equals,
    }
    if (newState !== dealsListType) {
      if ((event.target as HTMLInputElement).value === '1') {
        setSkip(0)
        setFilterArgs({
          ids: undefined,
          _id: undefined,
          bookingNumber: undefined,
          companyId: companyIdFilter,
        })
      } else {
        setFilterArgs({
          ids: suggestedDeals.map((c) => c._id),
          companyId: companyIdFilter,
        })
      }

      // 1 means all Deals
      // 2 means suggested Deals
      setDealsListType(newState)
    }
  }

  const CreateAuctionElementFromDeal = (deal: Deal) => {
    const newElement: AuctionElement = {
      addedToAuctionAt: new Date(),
      addedToAuctionType: EAuctionCreationType.Manual,
      dealId: deal._id,
      bookingNumber: deal.bookingNumber,
    }
    return newElement
  }

  const selectAllNotAlreadyIncludedDeals = () => {
    const alreadyIncludedBookingNumbers = (finalAuctionElements ?? []).map(
      (b) => b.bookingNumber,
    )

    for (const deal of deals) {
      if (alreadyIncludedBookingNumbers.includes(deal.bookingNumber)) {
        continue
      }

      onAddDeal(deal)
    }

    if (!nextDisabled) {
      _onNext()
    }
  }

  return (
    <div className={`${styles.root} u-p`}>
      <div className={`${styles.inlineDivs}`} style={{ width: '65%' }}>
        <div>
          <Typography
            variant="h5"
            gutterBottom
            classes={{ root: styles.headTitle }}
          >
            {t('deal.plural')}
          </Typography>
          <Paper>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ root: styles.expansionPanelSummary }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  {t('filters')}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <DefaultActionWrapper onEnterKeyUp={_handleSearch}>
                  <div className={`${styles.filterDivs}`}>
                    <div className={`${styles.filterInlineDivs}`}>
                      <TextInput
                        value={bookingNumber ?? ''}
                        onChange={setBookingNumber}
                        label={t('deal.booking_number')}
                      />
                      <TextInput
                        value={dealId ?? ''}
                        onChange={setDealId}
                        label={t('auction.auction_deal_id')}
                      />
                    </div>
                    <div className={`${styles.filterInlineDivs}`}>
                      <RadioGroup
                        aria-label="itemsList"
                        name="list"
                        value={dealsListType}
                        onChange={onChangeDealsListData}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={`${t('all_deals')}`}
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label={`${t('suggested_deals')} - (${suggestedDeals.length})`}
                        />
                      </RadioGroup>
                      {dealsListType === '2' &&
                        `${t('auction.auction_date')}: ${dayjs(auctionDate).format('DD.MM.YYYY')}`}
                    </div>
                    <div className={`${styles.filterDivs}`}>
                      <Button
                        className={'u-mv-sm u-ml-sm'}
                        onClick={() => _handleSearch()}
                        variant="contained"
                        disabled={!bookingNumber && !dealId}
                      >
                        {t('search_deals')}
                      </Button>
                      <Button
                        className={'u-mv-sm u-ml-sm'}
                        onClick={_resetSearch}
                        variant="contained"
                      >
                        {t('reset_filter')}
                      </Button>
                    </div>
                  </div>
                </DefaultActionWrapper>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            {queryResult.loading ? (
              <Loading />
            ) : (
              <div style={{ overflowY: 'scroll', height: '64.5rem' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        classes={{ root: styles.headTitleTable }}
                        align="left"
                      >
                        #
                      </TableCell>
                      <TableCell
                        classes={{ root: styles.headTitleTable }}
                        align="left"
                      >
                        {t('deal.deal_types')}
                      </TableCell>
                      <TableCell
                        classes={{ root: styles.headTitleTable }}
                        align="left"
                      >
                        {t('status')}
                      </TableCell>
                      <TableCell
                        classes={{ root: styles.headTitleTable }}
                        align="left"
                      >
                        {t('shop.label')}
                      </TableCell>
                      <TableCell
                        classes={{ root: styles.headTitleTable }}
                        align="left"
                      >
                        {t('company.singular')}
                      </TableCell>
                      <TableCell
                        classes={{ root: styles.headTitleTable }}
                        align="center"
                      >
                        <div
                          className={`${styles.copyAllDiv}`}
                          onClick={selectAllNotAlreadyIncludedDeals}
                        >
                          {`${dealsTotal - finalAuctionElements.length >= 0 ? dealsTotal - finalAuctionElements.length : 0}`}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deals
                      .filter(
                        (c) =>
                          !(finalAuctionElements ?? [])
                            .map((b) => b.bookingNumber)
                            .includes(c.bookingNumber),
                      )
                      .map((deal) => {
                        return (
                          <TableRow key={deal._id}>
                            <TableCell align="left">
                              {deal.bookingNumber}
                            </TableCell>
                            <TableCell align="left">
                              {deal ? deal.dealType : ''}
                            </TableCell>
                            <TableCell align="left">
                              {deal
                                ? deal.events
                                  ? deal.events[deal.events.length - 1]
                                      .displayName
                                  : '-'
                                : ''}
                            </TableCell>
                            <TableCell align="left">
                              {deal.shop?.name}
                            </TableCell>
                            <TableCell align="left">
                              {deal.company.name}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                className={`${styles.addButton}`}
                                onClick={() => onAddDeal(deal.bookingNumber)}
                              >
                                <AddIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </div>
            )}
          </Paper>
        </div>
      </div>
      <div className={`${styles.inlineDivs}`} style={{ width: '32%' }}>
        <div>
          <Typography
            variant="h5"
            gutterBottom
            classes={{ root: styles.headTitle }}
          >
            {t('auction.auction_selected_deals')}
          </Typography>
          <ExpansionPanel>
            <div className={`${styles.expansionPanelSummary}`}>
              <Typography style={{ fontWeight: 'bold' }} />
            </div>
          </ExpansionPanel>
          <Paper>
            <div style={{ overflowY: 'scroll', height: '64.5rem' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      classes={{ root: styles.headTitleTable }}
                      align="left"
                    >
                      #
                    </TableCell>
                    <TableCell
                      classes={{ root: styles.headTitleTable }}
                      align="left"
                    >
                      {t('auction.auction_deal_id')}
                    </TableCell>
                    <TableCell
                      classes={{ root: styles.headTitleTable }}
                      align="center"
                    >
                      {`${finalAuctionElements.length}`}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(finalAuctionElements ?? []).map((element) => {
                    return (
                      <TableRow key={element.dealId}>
                        <TableCell align="left">
                          {element.bookingNumber}
                        </TableCell>
                        <TableCell align="left">{element.dealId}</TableCell>

                        <TableCell align="center">
                          <Button
                            className={`${styles.addButton}`}
                            onClick={() => onRemoveDeal(element.bookingNumber)}
                          >
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </Paper>
        </div>
      </div>

      <div className="u-12/12 u-flex u-jc-sb">
        <Button
          classes={{ root: styles.buttonPrev }}
          onClick={_onPrev}
          variant="contained"
          disabled={previousDisabled}
        >
          <NavigateBeforeSharpIcon classes={{ root: styles.navIcon }} />{' '}
          {t('previous')}
        </Button>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            paddingBottom: '0.375rem',
          }}
        >
          <span
            className={`${styles.paginationText}`}
          >{`${!!dealsTotal ? skip / TAKE + 1 + ' / ' + Math.ceil(dealsTotal / TAKE) : ' '} `}</span>
        </div>
        <Button
          classes={{ root: styles.buttonNext }}
          onClick={_onNext}
          variant="contained"
          disabled={nextDisabled}
        >
          {t('next')}{' '}
          <NavigateNextSharpIcon classes={{ root: styles.navIcon }} />
        </Button>
      </div>
    </div>
  )
}

export default AuctionDealsList
