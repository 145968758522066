import { Grid, MenuItem, Select, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'
import WarningIcon from '@material-ui/icons/Warning'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import { Fragment } from 'react'
import { FunctionComponent } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useGetActiveCompanies } from '@/domains/companies/hooks'
import FeeDefinitionDialog from '@/domains/fees/components/FeeDefinitionDialog'
import styles from '@/domains/fees/components/FeesConfiguration.module.scss'
import { useDeleteFee, useGetFees } from '@/domains/fees/hooks'
import { useMutationShowingErrors } from '@/hooks'
import {
  EBusinessUnit,
  EFeeType,
  FeeDefinition,
  FeeOpts,
  Scalars,
} from '@/schemaTypes'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const FeeDefinitionsPage: FunctionComponent = () => {
  const { t } = useTranslation()
  const { companies } = useGetActiveCompanies()
  const [feeOpts, setFeeOpts] = useState<FeeOpts>({
    filter: {
      businessUnit: { value: EBusinessUnit.General },
    },
  })
  const [openDialog, setOpenDialog] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedFeeDefinition, setSelectedFeeDefinition] =
    useState<FeeDefinition | null>(null)
  const history = useHistory()
  const editFeeId =
    history.location.pathname.includes('edit') &&
    history.location.pathname.split('/').pop()

  const { fees: feeDefinitions, queryResult } = useGetFees({
    variables: {
      opts: feeOpts,
    },
    skip: !feeOpts.filter?.companyId || !feeOpts.filter?.businessUnit,
  })

  useEffect(() => {
    if (companies && companies.length > 0) {
      const tempId = companies[0]._id
      if (!feeOpts.filter?.companyId) {
        setFeeOpts({
          filter: {
            companyId: { value: tempId },
            businessUnit: { value: EBusinessUnit.General },
          },
        })
      }
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies])

  useEffect(() => {
    if (editFeeId) {
      setIsEditMode(true)
      setOpenDialog(true)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editButtonClicked = (feeDefinitionId: Scalars['ObjectId']) => {
    editFeeId && history.push('/inApp/configurations/fees/')

    if (
      feeDefinitions &&
      feeDefinitions.map((c) => c._id).includes(feeDefinitionId)
    ) {
      setSelectedFeeDefinition(
        feeDefinitions.filter((c) => c._id === feeDefinitionId)[0],
      )
      setIsEditMode(true)
      setOpenDialog(true)
    }
  }

  const addButtonClicked = () => {
    setIsEditMode(false)
    setOpenDialog(true)
  }

  const deleteFee = useMutationShowingErrors({
    mutation: useDeleteFee(),
    successMessage: t('fees.fee_deleted'),
  })

  const deleteButtonClicked = (feeDefinitionId: Scalars['ObjectId']) => {
    deleteFee({
      variables: {
        feeId: feeDefinitionId,
      },
      onCompleted: () => {
        queryResult.refetch()
      },
    })
  }

  const getFeeTypeString = (feeType: EFeeType) => {
    if (feeType === EFeeType.InitialStaggered) {
      const company = companies.find(
        (c) => c._id === feeOpts.filter?.companyId?.value,
      )
      // add postfex region at or de
      return t(
        `fee_type.fee_type_${feeType}_${company.headquarter.countryCode}`.toLowerCase(),
      )
    }
    return t(`fee_type.fee_type_${feeType}`.toLowerCase())
  }

  const originalFeeDefinition = isEditMode
    ? editFeeId
      ? feeDefinitions.find((c) => c._id === editFeeId)
      : selectedFeeDefinition
    : null

  if (!feeOpts.filter?.companyId || !feeOpts.filter?.businessUnit) return null

  return (
    <Fragment>
      <Paper className={`${styles.root} u-mb`}>
        <Grid item xs={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                {' '}
                {t('company.singular')}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                value={feeOpts.filter?.companyId.value}
                onChange={(e) => {
                  setFeeOpts({
                    filter: {
                      ...feeOpts.filter,
                      companyId: {
                        value: e.target.value as Scalars['ObjectId'],
                      },
                    },
                  })
                }}
              >
                {companies.map((company) => (
                  <MenuItem key={company._id} value={company._id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                {' '}
                {t('deal.business_unit')}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                value={feeOpts.filter?.businessUnit.value}
                onChange={(e) => {
                  setFeeOpts({
                    filter: {
                      ...feeOpts.filter,
                      businessUnit: {
                        value: e.target.value as EBusinessUnit,
                      },
                    },
                  })
                }}
              >
                {Object.values(EBusinessUnit).map((bUnit) => (
                  <MenuItem key={bUnit} value={bUnit}>
                    {bUnit}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>

        <Table
          sx={{ minWidth: 700, marginTop: '1rem' }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">{t('priority')}</StyledTableCell>
              <StyledTableCell align="left">{t('id')}</StyledTableCell>
              <StyledTableCell align="left">
                {t('company.singular')}
              </StyledTableCell>
              <StyledTableCell align="left">
                {t('deal.business_unit')}
              </StyledTableCell>
              <StyledTableCell align="left">
                {t('fees.fees_amount')}
              </StyledTableCell>
              <StyledTableCell align="left">
                {t('billing_period_type')}
              </StyledTableCell>
              <StyledTableCell align="left">{t('level')}</StyledTableCell>
              <StyledTableCell align="left">{t('mode')}</StyledTableCell>
              <StyledTableCell align="left">
                {t('fee_type.label')}
              </StyledTableCell>
              <StyledTableCell align="left">
                {t('deal.deal_types')}
              </StyledTableCell>
              <StyledTableCell align="left" width={120}>
                {t('included_on_extensions')}
              </StyledTableCell>
              <StyledTableCell align="left">{t('active')}</StyledTableCell>
              <StyledTableCell align="left" />
              <StyledTableCell align="left">
                <div className={`${styles.AddIcon}`} onClick={addButtonClicked}>
                  <AddIcon />
                </div>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feeDefinitions?.map((feeDef) => (
              <StyledTableRow key={feeDef._id}>
                <StyledTableCell component="th" scope="row">
                  {feeDef.priority}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {feeDef._id}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {feeDef.company?.name ?? '-'}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {feeDef.businessUnit ?? '-'}
                </StyledTableCell>
                <StyledTableCell align="left">{feeDef.amount}</StyledTableCell>
                <StyledTableCell align="left">
                  {t(feeDef.billingPeriodType?.toLowerCase())}
                </StyledTableCell>
                <StyledTableCell align="left">{feeDef.level}</StyledTableCell>
                <StyledTableCell align="left">{feeDef.mode}</StyledTableCell>
                <StyledTableCell align="left">
                  {getFeeTypeString(feeDef.feeType)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {feeDef.dealTypes.length === 1
                    ? feeDef.dealTypes[0]
                    : feeDef.dealTypes.length === 2
                      ? `${feeDef.dealTypes[0]} / ${feeDef.dealTypes[1]}`
                      : '-'}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {feeDef.includedOnExtensions ? (
                    <div>
                      <DoneIcon className={`${styles.check} u-ml-sm`} />
                    </div>
                  ) : (
                    <div>
                      <WarningIcon className={`${styles.warning} u-ml-sm`} />
                    </div>
                  )}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {feeDef.isActive ? (
                    <div>
                      <DoneIcon className={`${styles.check} u-ml-sm`} />
                    </div>
                  ) : (
                    <div>
                      <WarningIcon className={`${styles.warning} u-ml-sm`} />
                    </div>
                  )}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <div
                    onClick={() => editButtonClicked(feeDef._id)}
                    className={`${styles.EditIcon}`}
                  >
                    <EditIcon />
                  </div>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <div
                    onClick={() => deleteButtonClicked(feeDef._id)}
                    className={`${styles.RemoveIcon}`}
                  >
                    <RemoveIcon />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {openDialog && isEditMode && originalFeeDefinition ? (
        <>
          <FeeDefinitionDialog
            queryResult={queryResult}
            company={
              companies.filter(
                (c) => c._id === feeOpts.filter?.companyId?.value,
              )[0]
            }
            businessUnit={feeOpts.filter?.businessUnit?.value as EBusinessUnit}
            isEditMode={isEditMode}
            originalFeeDefinition={originalFeeDefinition}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
          />
        </>
      ) : (
        !isEditMode && (
          <FeeDefinitionDialog
            queryResult={queryResult}
            company={
              companies.filter(
                (c) => c._id === feeOpts.filter?.companyId?.value,
              )[0]
            }
            businessUnit={feeOpts.filter?.businessUnit?.value as EBusinessUnit}
            isEditMode={isEditMode}
            originalFeeDefinition={null}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
          />
        )
      )}
    </Fragment>
  )
}

export default FeeDefinitionsPage
