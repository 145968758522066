import { JsonTree } from 'react-editable-json-tree'

export interface LoanToValuesJsonTreeProps {
  categoryValues: any
  onUpdate: (e: any) => void
  categories: string[]
}

export function LoanToValuesJsonTree(props: LoanToValuesJsonTreeProps) {
  const { categoryValues, onUpdate, categories } = props

  const beforeRemoveAction = (keyName: string) => {
    return new Promise<void>((resolve, reject) => {
      if (keyName === 'default' || categories.includes(keyName)) {
        reject()
      } else resolve()
    })
  }

  return (
    <JsonTree
      data={categoryValues}
      rootName="categoryValues"
      beforeRemoveAction={beforeRemoveAction}
      onFullyUpdate={onUpdate}
      readOnly={false}
    />
  )
}

export default LoanToValuesJsonTree
