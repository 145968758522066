import { Query, QueryGetProductLegacyArgs } from '../schemaTypes'
import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from './useShowErrorsPopup'

const GET_PRODUCT_LEGACY = gql`
  query getProductLegacy($opts: ProductLegacyOpts!) {
    getProductLegacy(opts: $opts) {
      _id
      title
      slug
      objectID
      releaseDate
      maxValue
      maxLoanToValue
      itemCategoryId
      isInactive
      conditionMaxValues {
        excellent
        good
        poor
      }
      contentCategories {
        lvl0
        lvl1
        lvl2
        lvl3
      }
      pictureFilename
      gewicht
      karat
      material
      ean
      class
      isTaxFree
    }
  }
`

type TData = { getProductLegacy: Query['getProductLegacy'] }
type TVariables = QueryGetProductLegacyArgs

export function useGetProductLegacy(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_PRODUCT_LEGACY, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, productLegacy: queryResult.data?.getProductLegacy }
}
