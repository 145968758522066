import { connect } from 'react-redux'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { NotFound } from '@/domains/customDeals/components/notFound'
import { CustomDealsList } from '@/redesign/pages/InApp/CustomDeals'
import { Employee } from '@/schemaTypes'
import getPagePath from '@/utils/getPagePath'
import { CustomDealCreatePage } from './create'
import { CustomDealEditPage } from './edit/[customDealId]'

interface Props {
  currentUser: Employee
}

function CustomDealsRoutes(props: RouteComponentProps & Props) {
  return (
    <Switch>
      <Route
        path={getPagePath(props, '/edit/:customDealId')}
        render={(props) => (
          <CustomDealEditPage
            customDealId={props.match.params.customDealId as string}
          />
        )}
      />
      <Route
        path={getPagePath(props, '/create')}
        render={() => <CustomDealCreatePage currentUser={props.currentUser} />}
      />
      <Route path={getPagePath(props)} render={() => <CustomDealsList />} />
      <Route component={NotFound} />
    </Switch>
  )
}

const mapStateToProps = (state) => ({
  currentUser: state.user.current,
})

export default connect(mapStateToProps)(CustomDealsRoutes)
