import styled from '@emotion/styled'
import { InputText, InputTextProps } from 'primereact/inputtext'
import React from 'react'

interface SearchInputProps extends InputTextProps {
  onClear: () => void
}

const SearchInput: React.FC<SearchInputProps> = ({
  onClear,
  ...inputProps
}) => {
  return (
    <div className="p-input-icon-left  p-input-icon-right">
      <StyledIcon className="right-0 pi pi-search" />
      <InputText {...inputProps} className="w-full" />
      {(inputProps.value?.length ?? 0) > 0 && (
        <i className="pi pi-times cursor-pointer" onClick={onClear} />
      )}
    </div>
  )
}

const StyledIcon = styled.i`
  right: unset !important;
`

export default SearchInput
