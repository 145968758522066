import styled from '@emotion/styled'
import { Button, MenuItem, Select, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useEffect, useState } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EVisibleOnDomain, ItemCategory } from '@/schemaTypes'

const DEFAULT_VALUE = 'n/a'

interface CategorySelectorProps {
  allCategories: ItemCategory[]
  parentCategories: ItemCategory[]
  categories: ItemCategory[] | undefined
  onChange?(itemCategoryIDs: string[]): void
  manualUpdate?: boolean
  hideLabel?: boolean
  label?: string | React.ReactNode
  disabled?: boolean
}

interface CategorySelectorState {
  categories: ItemCategory[]
}

export function MultiLevelCategorySelector(props: CategorySelectorProps) {
  const restricted =
    props.allCategories.length == 1 && props.categories?.length == 1
  const { t } = useTranslation()
  const [itemCategories, setCategories] = useState<CategorySelectorState>({
    categories: props.parentCategories,
  })

  useEffect(() => {
    if (!itemCategories.categories.length) {
      setCategories({
        categories: props.parentCategories,
      })
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentCategories])

  const changeHandler = props.manualUpdate
    ? (v) => {
        //If we let the UpdateButton handle the change itself:
        setCategories({ categories: SetCategoryChain(props.allCategories, v) })
      }
    : (v) => {
        //If we auto-refresh and call the supplied onChangeFunction instead:
        setCategories({ categories: SetCategoryChain(props.allCategories, v) })
        const lastCategoryId = SetCategoryChain(props.allCategories, v).pop()
          ?._id
        props.onChange?.(lastCategoryId)
      }

  const getSelectors = () => {
    const selectors: {
      itemCategory: ItemCategory
      options: ItemCategory[]
      enabled: boolean
    }[] = []

    for (let i = 0; i < itemCategories.categories.length + 1; i++) {
      const options = (function () {
        if (restricted) {
          return itemCategories.categories[i]
            ? [itemCategories.categories[i]]
            : []
        } else {
          return FindAvailableCategories(
            props.allCategories,
            i === 0
              ? null
              : itemCategories.categories[i - 1]
                ? itemCategories.categories[i - 1]._id
                : null,
          )
        }
      })()

      selectors.push({
        options,
        itemCategory: itemCategories.categories[i],
        enabled: i === 0 || itemCategories.categories.length > i - 1,
      })
    }

    return selectors
  }

  return (
    <div>
      {!props.hideLabel && (
        <Typography
          variant="body1"
          style={{ display: 'inline-block', marginRight: '1rem' }}
        >
          {props.label ?? t('select_category')}
        </Typography>
      )}
      <SelectorList>
        {getSelectors()
          .filter((selector) => selector.enabled && selector.options.length > 0)
          .map((selector, i) => (
            <React.Fragment key={i}>
              <CategorySelector
                value={selector.itemCategory}
                options={selector.options}
                restricted={restricted}
                allCategories={props.allCategories}
                changeHandler={changeHandler}
                disabled={props.disabled}
              />
              <NavigateNextIcon className="arrow" />
            </React.Fragment>
          ))}
      </SelectorList>
      {props.manualUpdate ? (
        <ChangeConfirmButton
          onChange={props.onChange}
          categories={itemCategories.categories}
        />
      ) : null}
    </div>
  )
}

function ChangeConfirmButton(props: {
  onChange?: (itemCategoryIDs: string[] | undefined) => void
  categories: ItemCategory[]
}) {
  const { categories, onChange } = props
  const { t } = useTranslation()

  if (categories.length > 0) {
    const lastCategoryId = categories[categories.length - 1]._id
    return (
      <Button
        style={{ marginLeft: '1rem' }}
        onClick={() => onChange?.(lastCategoryId)}
      >
        {t('update')}
      </Button>
    )
  }
  return null
}

function CategorySelector(props: {
  restricted: boolean
  value?: ItemCategory
  changeHandler: (v: ItemCategory | undefined) => void
  options: ItemCategory[]
  allCategories: ItemCategory[]
  disabled?: boolean
}) {
  const { restricted, value, changeHandler, options, allCategories, disabled } =
    props

  if (options.length == 0) {
    return null
  }

  const defaultValue = restricted ? options[0]?._id : DEFAULT_VALUE
  const initialValue = value?._id ?? defaultValue

  return (
    <Select
      defaultValue={initialValue}
      value={initialValue}
      disabled={disabled}
      style={{ minWidth: '5rem' }}
      onChange={(e) => {
        if (e.target.value != DEFAULT_VALUE) {
          const item = options.find((item) => item._id == e.target.value)
          if (item) {
            changeHandler(item)
          }
        } else {
          const current = options.find((item) => item._id == value?._id)

          if (current) {
            const parent = allCategories.find(
              (item) => item._id == current.parentId,
            )
            if (parent) {
              //Default value click reverts the state back to parent category selected, depending on how deep we are
              changeHandler(parent)
            } else {
              // if it's top level category, it can be set as undefined if we want to remove
              changeHandler(undefined)
            }
          }
        }
      }}
    >
      {restricted ? null : (
        <MenuItem value={DEFAULT_VALUE}>{DEFAULT_VALUE}</MenuItem>
      )}
      {options.map((option) => (
        <MenuItem
          key={option.name}
          value={`${option._id}`}
          style={{
            color:
              option.visibleOnDomain === EVisibleOnDomain.None
                ? 'var(--darkGrey)'
                : 'inherit',
          }}
        >
          {option.name}
        </MenuItem>
      ))}
    </Select>
  )
}

function GetParentCategories(
  categories: ItemCategory[],
  currentItem: ItemCategory | undefined,
  accumulator: ItemCategory[],
) {
  if (currentItem) {
    accumulator.push(currentItem)
    if (currentItem.parentId) {
      const category = categories.find((cat) => cat._id == currentItem.parentId)
      if (category) {
        GetParentCategories(categories, category, accumulator)
      }
    }
  }
}

function SetCategoryChain(
  allItems: ItemCategory[],
  lastItem: ItemCategory,
): ItemCategory[] {
  const results = []
  GetParentCategories(allItems, lastItem, results)
  return results.reverse()
}

function FindAvailableCategories(
  allCategories: ItemCategory[],
  parentId: string | null,
): ItemCategory[] {
  return allCategories.filter(
    (category) => category.parentId == (parentId ?? undefined),
  )
}

const SelectorList = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;

  .arrow {
    margin: 0 1rem;
    color: #736d7d;
  }

  .arrow:last-child {
    display: none;
  }
`
