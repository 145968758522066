import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'

const GET_REQUESTED_FEE_CALCULATION_FOR_DEAL = gql`
  query getRequestedFeeCalculationForDeal(
    $dealId: ObjectId!
    $feesCalculationArgs: GetFeeCalculationForDealArgs!
  ) {
    getRequestedFeeCalculationForDeal(
      dealId: $dealId
      feesCalculationArgs: $feesCalculationArgs
    ) {
      totalAmounts {
        itemsFeesIdAndTotalAmounts {
          itemId
          itemFeesTotalAmount
        }
        dealFeesTotalAmount
        feesTotalAmount
      }
      feesAggregations {
        itemsFeesAggregations {
          feeType
          amount
          itemsPositions {
            itemId
            amount
          }
        }
        dealFeesAggregations {
          feeType
          amount
        }
      }
    }
  }
`

type TData = {
  getRequestedFeeCalculationForDeal: any
  // Query['getRequestedFeeCalculationForDeal']
}
type TVariables = Object // QueryGetRequestedFeeCalculationForDealArgs

export function useGetRequestedFeeCalculationForDeal(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_REQUESTED_FEE_CALCULATION_FOR_DEAL,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    feeCalculation: queryResult.data?.getRequestedFeeCalculationForDeal,
  }
}
