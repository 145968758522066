import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationDeleteFeeArgs } from '@/schemaTypes'

const DELETE_FEE = gql`
  mutation deleteFee($feeId: ObjectId!) {
    deleteFee(feeId: $feeId) {
      ...FeeDefinitionFragment
    }
  }
  ${fragments.feeDefinition}
`

type TData = { deleteFee: Mutation['deleteFee'] }
type TVariables = MutationDeleteFeeArgs

export function useDeleteFee(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deleteFee] = useMutation<TData, TVariables>(DELETE_FEE, opts)

  return deleteFee
}
