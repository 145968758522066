import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateInsuredAmountArgs } from '@/schemaTypes'

const UPDATE_INSURED_AMOUNT = gql`
  mutation updateInsuredAmount($dealId: ObjectId!, $insuredAmount: Float!) {
    updateInsuredAmount(dealId: $dealId, insuredAmount: $insuredAmount) {
      _id
      insuredAmount
    }
  }
`

type TData = { updateInsuredAmount: Mutation['updateInsuredAmount'] }
type TVariables = MutationUpdateInsuredAmountArgs

export function useUpdateDealInsuredAmount(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateDealInsuredAmount] = useMutation<TData, TVariables>(
    UPDATE_INSURED_AMOUNT,
    opts,
  )

  return updateDealInsuredAmount
}
