import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { MaterialPrice, Query, QueryGetMaterialPriceArgs } from '@/schemaTypes'

const GET_MATERIAL_PRICE = gql`
  query getMaterialPrice($materialPriceId: ObjectId!) {
    getMaterialPrice(materialPriceId: $materialPriceId) {
      _id
      date
      material
      price
      mu
      sigma
    }
  }
`

type TData = { getMaterialPrice: Query['getMaterialPrice'] }
type TVariables = QueryGetMaterialPriceArgs

const defaultMaterialPrice: MaterialPrice = {
  _id: '',
  date: null,
  material: '',
  price: 0,
  mu: 0,
  sigma: 0,
}

export function useGetMaterialPrice(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_MATERIAL_PRICE, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.materialPriceId,
  })

  useShowErrorsPopup(queryResult.error)

  const materialPrice = opts.variables?.materialPriceId
    ? queryResult.data?.getMaterialPrice
    : defaultMaterialPrice
  return { queryResult, materialPrice }
}
