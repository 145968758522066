import styled from '@emotion/styled'
import { Box } from '@material-ui/core'
import { ArrowRightAlt } from '@material-ui/icons'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CUSTOM_DEAL_FORM_LANGUAGES } from '@/constants'
import { ELanguageCode } from '@/schemaTypes'
import { useTranslationContext } from './LanguagePickerContext'

export const LanguagePicker = () => {
  const { t } = useTranslation()
  const { languages, setLanguages } = useTranslationContext()

  const handleLanguageChange = (
    _: React.MouseEvent<HTMLElement>,
    nextLanguage: string,
  ) => {
    if (!nextLanguage) return
    setLanguages([ELanguageCode.De, nextLanguage as ELanguageCode])
  }

  const baseLanguage = ELanguageCode.De

  const translatedLanguages = CUSTOM_DEAL_FORM_LANGUAGES.filter(
    (language) => language != baseLanguage,
  )

  return (
    <Box mb="1rem">
      <span className="mr-2">{`${t('translation')}:`}</span>

      <BaseLanguageStyled>
        {t(`language_label.${baseLanguage}`)}
      </BaseLanguageStyled>

      <ArrowRightAlt className="mr-1 -mt-0.5" />

      <ToggleButtonGroupStyled
        color="primary"
        value={languages}
        exclusive
        onChange={handleLanguageChange}
        aria-label="Platform"
      >
        {translatedLanguages.map((language) => (
          <ToggleButtonStyled
            size="medium"
            value={language}
            key={'languagePicker' + language}
          >
            {t(`language_label.${language}`)}
          </ToggleButtonStyled>
        ))}
      </ToggleButtonGroupStyled>
    </Box>
  )
}

const ToggleButtonStyled = styled(ToggleButton)`
  border-radius: 2rem;
  height: 2.375rem;
  text-transform: none;
  width: 5rem;
`

const BaseLanguageStyled = styled.span`
  font-family: Inter;
  font-size: 0.88rem;
  font-style: normal;
  font-weight: 500;
  padding: 0.5rem;
  color: #37404c;
`

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)`
  border-radius: 2px;
  border-width: 1px;
`
