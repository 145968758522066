import InputDropdown from '../InputDropdown/InputDropdown'
import { useContext, useMemo } from 'react'
import { Context } from '@/context'
import {
  ECustomDealFormQuestionType,
  ELanguageCode,
  FormBooleanAnswer,
} from '@/schemaTypes'
import { NewFormAnswer, getQuestionLabel } from './ItemFormAnswers'

interface ItemFormBooleanAnswerProps {
  formAnswer: FormBooleanAnswer
  disabled?: boolean
  onChange?: (newFormAnswer: NewFormAnswer, allowEnterAnswer?: boolean) => void
  label: string
}

export const ItemFormBooleanAnswer = ({
  formAnswer,
  disabled,
  onChange,
  label,
}: ItemFormBooleanAnswerProps) => {
  const { language } = useContext(Context)

  const dropdownOptions = useMemo(() => {
    return options.map((option, index) => ({
      label: getQuestionLabel(option.allTranslations, language),
      value: index,
    }))
  }, [language])

  const selectedValue = getBooleanValue(formAnswer.booleanAnswer)

  return (
    <InputDropdown
      value={selectedValue}
      label={label}
      options={dropdownOptions}
      className="flex flex-col md:flex-row items-start md:items-center mb-2"
      labelClassName="text-sm font-semibold w-12.5"
      inputContainerClassName="w-52"
      disabled={disabled}
      onChange={(e) =>
        onChange?.({
          type: ECustomDealFormQuestionType.Boolean,
          value: e.target.value === 1 ? true : false,
          question: formAnswer.question,
        })
      }
    />
  )
}

const options = [
  {
    allTranslations: [
      { languageCode: ELanguageCode.En, text: 'No' },
      { languageCode: ELanguageCode.De, text: 'Nein' },
    ],
  },
  {
    allTranslations: [
      { languageCode: ELanguageCode.En, text: 'Yes' },
      { languageCode: ELanguageCode.De, text: 'Ja' },
    ],
  },
]

const getBooleanValue = (booleanAnswer: boolean | null | undefined) => {
  if (booleanAnswer === null || booleanAnswer === undefined) {
    return null
  }

  return booleanAnswer ? 1 : 0
}
