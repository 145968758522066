import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import {
  Mutation,
  MutationDeletePrivateSaleTransactionArgs,
} from '@/schemaTypes'

const DELETE_PRIVATE_SALE_TRANSACTION = gql`
  mutation deletePrivateSaleTransaction(
    $privateSaleId: ObjectId!
    $transactionOrder: Float!
  ) {
    deletePrivateSaleTransaction(
      privateSaleId: $privateSaleId
      transactionOrder: $transactionOrder
    ) {
      _id
      createdAt
      creationType
      privateSaleDate
      dealId
      itemId
      bookingNumber
      carTitle
      customerFullName
      status
      dealFinalBalance
      privateSaleTransactions {
        ...PrivateSaleTransactionFragment
      }
    }
  }
  ${fragments.privateSaleTransaction}
`

type TData = {
  deletePrivateSaleTransaction: Mutation['deletePrivateSaleTransaction']
}
type TVariables = MutationDeletePrivateSaleTransactionArgs

export function useDeletePrivateSaleTransaction(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deletePrivateSaleTransaction] = useMutation<TData, TVariables>(
    DELETE_PRIVATE_SALE_TRANSACTION,
    opts,
  )
  return deletePrivateSaleTransaction
}
