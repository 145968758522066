import CheckIcon from '@material-ui/icons/Check'
import ListIcon from '@material-ui/icons/List'
import WarningIcon from '@material-ui/icons/Warning'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableRow,
  Tooltip,
} from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import { Fragment, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetShippingLabels } from '@/hooks/getShippingLabels'
import {
  EShippingLabelDirection,
  EShippingLabelEntity,
  EShippingLabelFileSource,
  EStandardShipmentType,
  Scalars,
  ShippingLabelCreateArgs,
} from '@/schemaTypes'
import { getFilenameFromURL } from '@/utils/file'
import { printLocalDateTime } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'
import styles from './ShippingLabel.module.scss'
import { convertShippingLabelModelToCreteArgs } from './ShippingLabelComponent'
import ShowShippingLabel from './ShowShippingLabel'

interface ShippingLabelHistoryProps {
  dealId?: Scalars['ObjectId']
  itemId?: Scalars['ObjectId']
  shippingEntity: EShippingLabelEntity
  directionType: EShippingLabelDirection
  shippingPartner: EStandardShipmentType
  shippingLabelCreateArgsFromHistory: ShippingLabelCreateArgs | undefined
  setShippingLabelCreateArgsFromHistory
}

const ShippingLabelHistory: FunctionComponent<ShippingLabelHistoryProps> = (
  props,
) => {
  const {
    dealId,
    itemId,
    shippingEntity,
    directionType,
    shippingPartner,
    setShippingLabelCreateArgsFromHistory,
  } = props
  const { t } = useTranslation()
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [open, setOpen] = useState(false)

  const { shippingLabels } = useGetShippingLabels({
    variables: {
      args: {
        dealId: shippingEntity === EShippingLabelEntity.Deal ? dealId : null,
        itemId: shippingEntity === EShippingLabelEntity.Item ? itemId : null,
      },
    },
    skip: !itemId && !dealId,
  })

  const filteredShippingLabel = shippingLabels
    ? shippingLabels.filter(
        (c) =>
          c.direction === directionType &&
          c.shippingPartner === shippingPartner,
      )
    : []

  const handleHistoryChange = (index: number) => {
    setSelectedIndex(index)
    setShippingLabelCreateArgsFromHistory(
      convertShippingLabelModelToCreteArgs(
        filteredShippingLabel[index],
        dealId,
        itemId,
        shippingEntity,
        EShippingLabelFileSource.History,
      ),
    )
  }

  // if (!itemId && !dealId) return null

  return (
    <Fragment>
      {filteredShippingLabel && filteredShippingLabel.length > 0 ? (
        <Table sx={{ minWidth: 500 }} size="small" aria-label="a dense table">
          <TableBody>
            <RadioGroup>
              {filteredShippingLabel.map((label) => (
                <TableRow key={filteredShippingLabel?.indexOf(label)}>
                  <TableCell sx={{ minWidth: '15px', maxWidth: '15px' }}>
                    <FormControlLabel
                      value={filteredShippingLabel?.indexOf(label)}
                      control={
                        <Radio
                          checked={
                            filteredShippingLabel?.indexOf(label) ===
                            selectedIndex
                          }
                          onChange={() =>
                            handleHistoryChange(
                              filteredShippingLabel?.indexOf(label),
                            )
                          }
                        />
                      }
                      label={''}
                    />
                  </TableCell>
                  <TableCell sx={{ minWidth: '260px', maxWidth: '260px' }}>
                    {getFilenameFromURL(label.labelFile.url).filename +
                      getFilenameFromURL(label.labelFile.url).fileExtension}
                  </TableCell>
                  <TableCell sx={{ minWidth: '170px', maxWidth: '170px' }}>
                    {label.labelCreateDate
                      ? printLocalDateTime(label.labelCreateDate, {
                          timezone: defaultTimezone,
                        })
                      : ''}
                  </TableCell>
                  <TableCell sx={{ minWidth: '15px', maxWidth: '15px' }}>
                    {label.trackingLink ? (
                      <Tooltip title={t('link_is_available')}>
                        <span
                          hidden={
                            selectedIndex !==
                            filteredShippingLabel?.indexOf(label)
                          }
                        >
                          <CheckIcon style={{ color: 'green' }} />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('link_is_not_available')}>
                        <span
                          hidden={
                            selectedIndex !==
                            filteredShippingLabel?.indexOf(label)
                          }
                        >
                          <WarningIcon style={{ color: 'orange' }} />
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell sx={{ minWidth: '15px', maxWidth: '15px' }}>
                    <Tooltip title={t('show_shipping_label')}>
                      <span
                        hidden={
                          selectedIndex !==
                          filteredShippingLabel?.indexOf(label)
                        }
                        onClick={() => {
                          setOpen(true)
                        }}
                        className={styles.visibleIcon}
                      >
                        <ListIcon style={{ color: '#5f41af' }} />
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </RadioGroup>
          </TableBody>
        </Table>
      ) : (
        <span style={{ color: 'red' }}>{t('no_file_in_shipping_history')}</span>
      )}

      {selectedIndex >= 0 && (
        <Dialog
          maxWidth={'sm'}
          open={open}
          scroll={'paper'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth
        >
          <DialogContent dividers={true}>
            <ShowShippingLabel
              shippingLabel={filteredShippingLabel[selectedIndex]}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpen(false)}>
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  )
}

export default ShippingLabelHistory
