import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function ConfigurationOverview() {
  const { t } = useTranslation()

  return (
    <Box>
      <Link to="/inApp/configurations/loanToValues">
        {t('loan.loan_to_values')}
      </Link>
      <br />
      <Link to="/inApp/configurations/fees">{t('fees.label')}</Link>
      <br />
      <Link to="/inApp/configurations/materialPrices">
        {t('material_prices')}
      </Link>
      <br />
      <Link to="/inApp/configurations/shops">{t('shop.plural')}</Link>
      <br />
      <Link to="/inApp/configurations/companies">{t('company.plural')}</Link>
      <br />
      <Link to="/inApp/configurations/storageFacilities">
        {t('storage.storage_facilities')}
      </Link>
      <br />
      <Link to="/inApp/configurations/employees">{t('employees')}</Link>
      <br />
      <Link to="/inApp/configurations/itemQuestions">
        {t('item_questions')}
      </Link>
      <br />
      <Link to="/inApp/configurations/itemCategories">
        {t('item_categories.label')}
      </Link>
      <br />
      <Link to="/inApp/configurations/responseTemplates">
        {t('response_templates')}
      </Link>
      <br />
      <Link to="/inApp/configurations/customDealForms">
        {t('custom_deal_form.plural')}
      </Link>
      <br />
      {process.env.REACT_APP_ENV !== 'production' && (
        <>
          <Link to="/inApp/configurations/product-property-definitions">
            {t('product_property_definition')}
          </Link>
          <br />
        </>
      )}
      <Link to="/inApp/configurations/refurbedOffers">
        {t('refurbed_offers')}
      </Link>
      <br />
      <Link to="/inApp/configurations/maintenance">{t('maintenance')}</Link>
    </Box>
  )
}

export default ConfigurationOverview
