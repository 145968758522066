import SelectOptionInput, { MenuItemOption } from '../SelectOptionInput'
import { FormHelperText } from '@material-ui/core'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetShops } from '@/domains/shops/hooks'
import { useAppSelector } from '@/store'

export interface ShopSelectProps {
  shopId: string
  companyId?: string | null
  includeDisabled?: boolean
  showDisabledWarning?: boolean
  showCurrentDisabled?: boolean
  className?: string
  hideLabel?: boolean
  error?: string
  multiple?: boolean
  defaultValue?: string
  disabled?: boolean
  style?: React.CSSProperties
  hasNone?: boolean
  hasAsterisk?: boolean
  readOnly?: boolean
  useDefaultShopId?: boolean
  idPrefix?: string
  onChange?: (value: string | null) => void
}

export default function ShopSelect(props: ShopSelectProps) {
  const {
    shopId,
    companyId,
    showCurrentDisabled = false,
    includeDisabled = false,
    showDisabledWarning = false,
    hideLabel = false,
    useDefaultShopId = false,
    onChange,
    idPrefix,
    ...selectOptionInputProps
  } = props

  const { t } = useTranslation()
  const defaultShopId = useAppSelector(
    (state) => state.ui.employeeActiveShop,
  )?.id

  const { shops } = useGetShops({
    variables: {
      opts: {
        filter: {
          includeDisabled: true,
          ...(companyId && { companyId: { value: companyId } }),
        },
      },
    },
    onCompleted: (data) => {
      if (onChange && shopId) {
        const filteredShops =
          includeDisabled || showCurrentDisabled
            ? data.getShops
            : data.getShops.filter((s) => !s.disabled)
        !filteredShops.find((s) => s._id === shopId) && onChange(null)
      }
    },
  })

  const currentShop = shops.find((s) => s._id === shopId)

  const shopMenuItems = useMemo(() => {
    const filteredShops = includeDisabled
      ? shops
      : shops.filter((s) => !s.disabled)
    const locations: MenuItemOption<string>[] = filteredShops.map((shop) => ({
      label: shop.name,
      value: shop._id,
    }))

    if (!includeDisabled && currentShop?.disabled) {
      locations.push({
        label: currentShop.name,
        value: currentShop._id,
        disabled: true,
      })
    }
    return locations
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shops, shopId])

  useEffect(() => {
    if (onChange && useDefaultShopId && defaultShopId && !shopId) {
      onChange(defaultShopId)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SelectOptionInput
        value={shopId}
        idPrefix={idPrefix}
        menuItems={shopMenuItems}
        label={!hideLabel ? `${t('shop.label')}:` : undefined}
        onChange={onChange}
        {...selectOptionInputProps}
      />
      {showDisabledWarning && currentShop?.disabled ? (
        currentShop.successionShop?.name ? (
          <FormHelperText error>
            {t('shop.shop_disabled_with_succession_shop_info', {
              name: currentShop.successionShop.name,
            })}
          </FormHelperText>
        ) : (
          <FormHelperText error>
            {t('shop.shop_was_disabled_info')}
          </FormHelperText>
        )
      ) : null}
    </div>
  )
}
