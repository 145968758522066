import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query } from '@/schemaTypes'

const GET_OPEN_AUCTIONS = gql`
  query getOpenAuction($dealId: ObjectId!) {
    getOpenAuction(dealId: $dealId) {
      isDealExistsInAnOpenAuctions
      auctionDate
    }
  }
`

type TData = { getOpenAuction: Query['getOpenAuction'] }

export function useGetOpenAuction(opts: QueryHookOptions<TData> = {}) {
  const queryResult = useQuery<TData>(GET_OPEN_AUCTIONS, {
    variables: {},
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    openAuctionData: queryResult.data
      ? queryResult.data.getOpenAuction
      : undefined,
  }
}
