import styled from '@emotion/styled'
import { Timeline } from 'primereact/timeline'
import { useCallback, useMemo } from 'react'
import { Deal, EDealType } from '@/schemaTypes'
import { getLatestEventForStatus } from '@/utils/deal'
import { printLocalDate } from '@/utils/print'
import { addDays, defaultTimezone } from '@/utils/time'

export interface DateOverviewProps {
  deal: Deal
  warnBookedDate: boolean
}

const DateOverview = (props: DateOverviewProps) => {
  const { deal, warnBookedDate } = props
  const statusEvents = useMemo(() => {
    const latestBookedEvent = getLatestEventForStatus(deal, 'DealBookedEvent')
    const latestVerifiedEvent = getLatestEventForStatus(
      deal,
      'DealVerifiedEvent',
    )
    const extensionConfirmedEvent = getLatestEventForStatus(
      deal,
      'DealExtensionConfirmedEvent',
    )
    const closedEvent = getLatestEventForStatus(deal, 'DealClosedEvent')
    const paybackConfirmedEvent = getLatestEventForStatus(
      deal,
      'DealPaybackConfirmedEvent',
    )

    return {
      latestBookedEvent,
      latestVerifiedEvent,
      extensionConfirmedEvent,
      closedEvent,
      paybackConfirmedEvent,
    }
  }, [deal])

  const closedOrPaybackConfirmedDate = (
    statusEvents.paybackConfirmedEvent ?? statusEvents.closedEvent
  )?.timestamp
  const verifiedDurationInDays = statusEvents.latestVerifiedEvent
    ? statusEvents.latestVerifiedEvent.dealCalculation.dealValuesEntry
        .durationInDays
    : statusEvents.latestBookedEvent?.dealCalculation.dealValuesEntry
        .durationInDays || 0

  const bookedAt = getEventDate(statusEvents.latestBookedEvent, 'timestamp')
  const createdAt = getEventDate(deal, 'createdAt')

  const closedExpectedAt = addDays(
    deal.dealStartDate,
    verifiedDurationInDays,
    defaultTimezone,
  )

  const valorizationAt =
    deal.dealType === EDealType.Pawn
      ? addDays(
          closedExpectedAt,
          deal.company.configuration.gracePeriod,
          defaultTimezone,
        )
      : closedExpectedAt

  const dateRows = [
    {
      stage: 'Booked',
      icon: 'pi pi-shopping-cart',
      date: bookedAt,
      warn: warnBookedDate,
    },
    { stage: 'Created', icon: 'pi pi-sun', date: createdAt },
  ]

  const addDateRow = (stage, icon, date) => {
    dateRows.push({ stage, icon, date: formatDate(date) })
  }

  if (statusEvents.latestVerifiedEvent) {
    addDateRow(
      'Verified',
      'pi pi-verified',
      statusEvents.latestVerifiedEvent.timestamp,
    )
  }

  if (statusEvents.extensionConfirmedEvent) {
    addDateRow(
      'Extension Confirmed',
      'pi pi-check-circle',
      statusEvents.extensionConfirmedEvent.timestamp,
    )
  }

  if (
    typeof verifiedDurationInDays === 'number' &&
    deal.dealType === EDealType.Pawn
  ) {
    addDateRow('Closed (Expected)', 'pi pi-flag', closedExpectedAt)
  }

  if (closedOrPaybackConfirmedDate) {
    addDateRow(
      'Closed (Actual)',
      'pi pi-flag-fill',
      closedOrPaybackConfirmedDate,
    )
  }

  if (
    typeof verifiedDurationInDays === 'number' &&
    !statusEvents.extensionConfirmedEvent
  ) {
    addDateRow('Valorization Start', 'pi pi-euro', valorizationAt)
  }

  const customizedMarker = useCallback(
    (item) => (
      <IconContainer
        className="flex w-7 h-7 items-center justify-center text-white"
        warn={item?.warn}
      >
        <i className={item.icon} />
      </IconContainer>
    ),
    [],
  )

  return (
    <div className="flex justify-start w-96 flex-col relative">
      <Timeline
        value={dateRows}
        opposite={(item) => <TimelineText>{item.stage}</TimelineText>}
        marker={customizedMarker}
        content={(item) => <TimelineText bold>{item.date}</TimelineText>}
      />
    </div>
  )
}

const formatDate = (date) => printLocalDate(date, { timezone: defaultTimezone })

const getEventDate = (event, dateProperty) =>
  event ? formatDate(event[dateProperty]) : ''

const IconContainer = styled.div<{ warn?: boolean }>`
  border-radius: 0.875rem;
  background: ${(props) =>
    props.warn ? 'var(--red-400, #FF6259)' : 'var(--purple-500, #A855F7)'};
`

const TimelineText = styled.p<{ bold?: boolean }>`
  color: var(--global-text-color, #495057);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: ${(props) => (props.bold ? '600' : '400')};
  line-height: normal;
`

export default DateOverview
