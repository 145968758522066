import styled from '@emotion/styled'
import { Box, MenuItem, Paper, Select, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import React, { ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpansionBox from '@/components/ExpansionBox/ExpansionBox'
import Info from '@/components/Info'
import { auctionPaymentMethods } from '@/domains/auctions/components/Auction/AuctionBuyerInformation'
import { useUpdateAuctionItem } from '@/domains/auctions/hooks/auctionDetails/updateAuctionItem'
import { useMutationShowingErrors } from '@/hooks'
import {
  Auction,
  AuctionItemNewCustomer,
  DealItem,
  EAuctionItemStatus,
  EItemStatusType,
  EManualPaymentType,
} from '@/schemaTypes'
import styles from './Item.module.scss'

interface AuctionItemProps {
  dealItem: DealItem
  auction: Auction
}

export function AuctionItem(props: AuctionItemProps) {
  const { dealItem, auction } = props

  const [openBox, setOpenBox] = useState(true)
  const lastItemStatus = dealItem.events
    ? dealItem.events[dealItem.events.length - 1].event
    : null

  const { t } = useTranslation()

  const getBuyerDetails = (buyer: AuctionItemNewCustomer) => {
    const info = [
      {
        label: t('customer.firstname'),
        value: buyer.firstname,
      },
      {
        label: t('customer.lastname'),
        value: buyer.lastname,
      },
      {
        label: t('customer.email'),
        value: buyer.email,
      },
      {
        label: t('customer.phone'),
        value: buyer.phoneNumber,
      },
      {
        label: t('address.street'),
        value: buyer.address.street,
      },
      {
        label: t('address.house_number'),
        value: buyer.address.houseNumber,
      },
      {
        label: t('stairs'),
        value: buyer.address.stairway,
      },
      {
        label: t('address.door'),
        value: buyer.address.door,
      },
      {
        label: t('address.zip_code'),
        value: buyer.address.zipCode,
      },
      {
        label: t('address.city'),
        value: buyer.address.city,
      },
    ]
    return `<div>
      ${info
        .map((data) =>
          data.value
            ? `<div style="display: flex; justify-content: flex-start; margin-bottom: 10px">
              <div style="width: 140px; font-weight: 500">${data.label}:</div>
              ${data.value}
          </div>`
            : '',
        )
        .join('\n')}
    </div>`
  }

  const updateAuctionItem = useMutationShowingErrors({
    mutation: useUpdateAuctionItem(),
    successMessage: t('update_auction_item_successfully'),
  })

  const auctionItemDetails = useMemo(() => {
    if (!auction) return null

    const auctionedItem = auction.items?.find(
      (item) =>
        item.status === EAuctionItemStatus.Sold &&
        item.itemId === dealItem?._id,
    )

    if (!auctionedItem?.buyer) {
      return null
    }

    let buyerName: string | ReactNode | null = null
    let isCustomer = false
    let buyerDetails: AuctionItemNewCustomer | null = null

    if (auctionedItem.buyer?.__typename === 'AuctionItemExistingCustomer') {
      buyerName = (
        <a
          target="_blank"
          href={'/inApp/customers/edit/' + auctionedItem.buyer.customerId}
          rel="noreferrer"
        >
          <u>{`${auctionedItem.buyer.customer?.firstname} ${auctionedItem.buyer.customer?.lastname}`}</u>
        </a>
      )
      isCustomer = true
    } else if (auctionedItem.buyer?.__typename === 'AuctionItemNewCustomer') {
      if (auctionedItem.buyer?.newCustomer) {
        buyerName = (
          <a
            target="_blank"
            href={'/inApp/customers/edit/' + auctionedItem.buyer.newCustomerId}
            rel="noreferrer"
          >
            <u>{`${auctionedItem.buyer.newCustomer?.firstname} ${auctionedItem.buyer.newCustomer?.lastname}`}</u>
          </a>
        )
        isCustomer = true
      } else {
        buyerName = `${auctionedItem.buyer?.firstname} ${auctionedItem.buyer?.lastname}`
        buyerDetails = auctionedItem.buyer
      }
    } else if (auctionedItem.buyer?.__typename === 'AuctionItemAnonyBuyer') {
      buyerName = t('anonym')
    }

    return {
      auctionId: auction._id,
      itemId: auctionedItem.itemId,
      auctionDate: dayjs(auction.auctionDate).format('DD.MM.YYYY'),
      finalPrice: auctionedItem.finalPrice,
      surchargeConfirmationLink: auctionedItem.surchargeConfirmationLink?.url,
      buyerName,
      paymentMethod: auctionedItem.paymentMethod,
      buyerDetails,
      isCustomer,
    }
  }, [auction, dealItem, t])

  if (!auctionItemDetails) {
    return null
  }

  return (
    <ExpansionBox
      title={t('verwertung')}
      openAtLoad={openBox ?? false}
      onClickBox={() => setOpenBox((prev) => !prev)}
    >
      <Paper className="u-p-sm u-12/12 u-mb-sm">
        <Typography style={{ fontWeight: 500, fontSize: 16 }}>
          {t('item_auctioned_on', {
            auctionDate: auctionItemDetails.auctionDate,
          })}
        </Typography>
        {auctionItemDetails.buyerName && (
          <ElementWrapper style={{ marginTop: 20 }}>
            <Label>{t('buyer')}:</Label> {auctionItemDetails.buyerName}
            {!auctionItemDetails.isCustomer &&
              auctionItemDetails.buyerDetails && (
                <Info
                  className={'o-media__fixed u-inline-block'}
                  svgClassName={`${styles.infoUsedGradeIcon} u-pl-5 u-pr-5`}
                  svgStyles={{ marginTop: -7 }}
                  infoText={getBuyerDetails(auctionItemDetails.buyerDetails)}
                />
              )}
          </ElementWrapper>
        )}
        <ElementWrapper>
          <Label>{t('price')}:</Label> {auctionItemDetails.finalPrice} €
        </ElementWrapper>
        <ElementWrapper style={{ marginTop: 5 }}>
          <Label>{t('payment_method')}:</Label>
          <StyledSelect
            className="u-ml-sm"
            style={{ width: 150 }}
            disabled={lastItemStatus !== EItemStatusType.Auctioned}
            value={auctionItemDetails.paymentMethod}
            onChange={(e) => {
              const newPaymentMethod = e.target.value as EManualPaymentType

              updateAuctionItem({
                variables: {
                  auctionItemArgs: {
                    auctionId: auctionItemDetails.auctionId,
                    itemId: auctionItemDetails.itemId,
                    paymentMethod: newPaymentMethod,
                  },
                },
                refetchQueries: ['getAuctionByItemId'],
              })
            }}
          >
            {auctionPaymentMethods.map((name) => (
              <MenuItem key={name} value={name}>
                {t(name)}
              </MenuItem>
            ))}
          </StyledSelect>
        </ElementWrapper>
        <ElementWrapper>
          <Label>{t('bid_confirmation')}:</Label>{' '}
          <Box>
            {auctionItemDetails.surchargeConfirmationLink && (
              <a
                target="_blank"
                href={auctionItemDetails.surchargeConfirmationLink}
                rel="noreferrer"
              >
                <u>{t('surcharge_certificate')}</u>
              </a>
            )}
          </Box>
        </ElementWrapper>
      </Paper>
    </ExpansionBox>
  )
}

const ElementWrapper = styled(Typography)`
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;

  > div:first-child {
    flex-shrink: 0;
  }
`

const Label = styled.div`
  width: 150px;
  font-weight: 500;
`

const StyledSelect = styled(Select)`
  margin-left: 0 !important;
`
