import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetAuctionSuggestedDeals } from '@/domains/auctions/hooks/auctionDetails/getAuctionSuggestedDeals'
import { AuctionElement } from '@/schemaTypes'
import styles from './AddAuctionElement.module.scss'
import AuctionDealsList from './AuctionDealsList'

const AddAuctionElement = (props) => {
  const {
    open,
    scroll,
    handleClose,
    auctionElements,
    auctionDate,
    companyId,
    setIsSuggestedDealsLoadingFinished,
    setSuggestedDealsCount,
  } = props
  const { t } = useTranslation()

  // Here we create a copy of our original list, so that we can behave correctly if user chose Cancel
  const [localFinalElements, setLocalFinalElements] = useState<
    AuctionElement[]
  >([])

  const { suggestedDeals, querySuggestedResult } = useGetAuctionSuggestedDeals({
    variables: {
      companyId,
    },
    skip: !companyId,
  })

  React.useEffect(() => {
    setSuggestedDealsCount(suggestedDeals.length)
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedDeals.length])

  setIsSuggestedDealsLoadingFinished(querySuggestedResult.loading)

  const descriptionElementRef = React.useRef<HTMLElement>(null)

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const onclose = () => {
    setLocalFinalElements([])
    handleClose(undefined)
  }

  return (
    <div>
      <Dialog
        disableBackdropClick
        maxWidth={'xl'}
        open={open}
        onClose={onclose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <Typography variant="h6" gutterBottom classes={{ root: styles.title }}>
          <DialogTitle>{t('auction.auction_add_element_title')}</DialogTitle>
        </Typography>
        <DialogContent dividers={scroll === 'paper'}>
          {/* We pass the original reference inside but the setter for our local array */}
          <AuctionDealsList
            suggestedDeals={suggestedDeals}
            finalAuctionElements={auctionElements ?? []}
            setLocalFinalElements={setLocalFinalElements}
            auctionDate={auctionDate}
            companyId={companyId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onclose} color="primary">
            {t('abort')}
          </Button>
          <Button
            onClick={() =>
              handleClose(
                localFinalElements.length === 0
                  ? auctionElements
                  : localFinalElements,
              )
            }
            color="primary"
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddAuctionElement
