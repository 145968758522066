import { Box, Checkbox, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EVisibleOnDomain } from '@/schemaTypes'

interface VisibleOnDomainOptions {
  showOnAllDomains: boolean
  at: boolean
  de: boolean
  hideOnAllDomains: boolean
}

export interface ItemCategoriesVisibleOnDomainProps {
  defaultVisibleOnDomainOptions: VisibleOnDomainOptions
  onChangeVisibleOnDomain: (visibleOnDomain: EVisibleOnDomain | null) => void
}

export function ItemCategoriesVisibleOnDomain(
  props: ItemCategoriesVisibleOnDomainProps,
) {
  const { defaultVisibleOnDomainOptions, onChangeVisibleOnDomain } = props
  const { t } = useTranslation()

  const [visibleOnDomainOptions, setVisibleOnDomainOptions] =
    useState<VisibleOnDomainOptions>(defaultVisibleOnDomainOptions)

  useEffect(() => {
    if (visibleOnDomainOptions.showOnAllDomains) {
      return onChangeVisibleOnDomain(EVisibleOnDomain.All)
    }

    if (visibleOnDomainOptions.hideOnAllDomains) {
      return onChangeVisibleOnDomain(EVisibleOnDomain.None)
    }

    if (visibleOnDomainOptions.at) {
      return onChangeVisibleOnDomain(EVisibleOnDomain.At)
    }

    if (visibleOnDomainOptions.de) {
      return onChangeVisibleOnDomain(EVisibleOnDomain.De)
    }

    return onChangeVisibleOnDomain(null)
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleOnDomainOptions])

  return (
    <Box mt="8px">
      <Typography variant="h5" gutterBottom>
        {t('category_visible_on_company')}
      </Typography>
      <Box>
        <Checkbox
          value={visibleOnDomainOptions.showOnAllDomains}
          checked={visibleOnDomainOptions.showOnAllDomains}
          onChange={(e) =>
            setVisibleOnDomainOptions(() => ({
              showOnAllDomains: e.target.checked,
              at: e.target.checked,
              de: e.target.checked,
              hideOnAllDomains: false,
            }))
          }
        />
        {t('all')}
      </Box>
      <Box>
        <Checkbox
          value={visibleOnDomainOptions.de}
          checked={visibleOnDomainOptions.de}
          onChange={(e) =>
            setVisibleOnDomainOptions((prev) => ({
              ...prev,
              de: e.target.checked,
              showOnAllDomains: !e.target.checked ? false : prev.at,
              hideOnAllDomains: false,
            }))
          }
        />
        {t('cashy_germany_gmbh')}
      </Box>
      <Box>
        <Checkbox
          value={visibleOnDomainOptions.at}
          checked={visibleOnDomainOptions.at}
          onChange={(e) =>
            setVisibleOnDomainOptions((prev) => ({
              ...prev,
              at: e.target.checked,
              showOnAllDomains: !e.target.checked ? false : prev.de,
              hideOnAllDomains: false,
            }))
          }
        />
        {t('cashy_automotive_gmbh')}
      </Box>
      <Box>
        <Checkbox
          value={visibleOnDomainOptions.hideOnAllDomains}
          checked={visibleOnDomainOptions.hideOnAllDomains}
          onChange={(e) =>
            setVisibleOnDomainOptions((prev) => ({
              hideOnAllDomains: e.target.checked,
              showOnAllDomains: e.target.checked
                ? false
                : prev.showOnAllDomains,
              at: e.target.checked ? false : prev.at,
              de: e.target.checked ? false : prev.de,
            }))
          }
        />
        {t('hide_on_all_domains')}
      </Box>
    </Box>
  )
}

export default ItemCategoriesVisibleOnDomain
