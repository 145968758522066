import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ELogisticsPartnerType,
  OLogisticsPartnerData,
  OLogisticsPartnerDataLogsta,
} from '@/schemaTypes'
import styles from './ValorizationHistoryEntry.module.scss'
import ValorizationHistoryEntryTable, {
  ValorizationHistoryEntryTableRow,
} from './ValorizationHistoryEntryTable'

interface ValorizationHistoryEntryLogisticsPartnerDataRow
  extends Pick<
    ValorizationHistoryEntryTableRow<
      Exclude<keyof OLogisticsPartnerData, '__typename'>
    >,
    'label' | 'type' | 'key' | 'hidden' | 'options' | 'disabled'
  > {
  partnerKey?: keyof OLogisticsPartnerDataLogsta
}

export interface ValorizationHistoryEntryLogisticsPartnerDataProps {
  className?: string
  logisticsPartnerData: OLogisticsPartnerData
  columnWidths: { label: number; value: number }
  onChangeLogisticsPartnerData: (updateData: OLogisticsPartnerData) => void
  isReadonly: boolean
}

export function ValorizationHistoryLogisticsPartnerData(
  props: ValorizationHistoryEntryLogisticsPartnerDataProps,
) {
  const {
    className,
    logisticsPartnerData,
    columnWidths,
    onChangeLogisticsPartnerData,
    isReadonly,
  } = props
  const { t } = useTranslation()

  const LOGISTICS_PARTNER_TYPES_OPTIONS = [
    { label: t('cashy'), value: ELogisticsPartnerType.Cashy },
    { label: t('logsta'), value: ELogisticsPartnerType.Logsta },
  ]

  const buildRow = (
    params: ValorizationHistoryEntryLogisticsPartnerDataRow,
  ): ValorizationHistoryEntryTableRow<keyof OLogisticsPartnerData> => {
    const { key, partnerKey } = params

    let value: any = null

    if (logisticsPartnerData) {
      value = logisticsPartnerData[key] ?? null

      if (partnerKey && value) {
        value = value[partnerKey] ?? null
      }
    }

    return {
      ...params,
      renderKey: key,
      value,
      onChange: params.disabled
        ? undefined
        : (value) => {
            if (partnerKey && key !== 'type' && key !== 'trackingLink') {
              onChangeLogisticsPartnerData({
                ...(logisticsPartnerData ?? {}),
                [key]: {
                  ...(logisticsPartnerData[key] ?? {}),
                  [partnerKey]: value,
                },
              } as OLogisticsPartnerData)
            } else {
              onChangeLogisticsPartnerData({
                ...(logisticsPartnerData ?? {}),
                [key]: value,
              } as OLogisticsPartnerData)
            }
          },
    }
  }

  const rowsData: ValorizationHistoryEntryLogisticsPartnerDataRow[] = [
    {
      label: t('logistics_partner'),
      key: 'type',
      type: 'string',
      options: LOGISTICS_PARTNER_TYPES_OPTIONS,
      disabled: isReadonly,
    },
    {
      label: t('logsta_number'),
      key: 'logsta',
      partnerKey: 'logstaNumber',
      type: 'string',
      hidden: logisticsPartnerData.type !== ELogisticsPartnerType.Logsta,
      disabled: isReadonly,
    },
    {
      label: t('logistics_tracking_link'),
      key: 'trackingLink',
      type: 'string',
      disabled: isReadonly,
    },
  ]
  const rows = rowsData.map((rowData) => buildRow(rowData))

  return (
    <section className={className ?? ''}>
      <Typography
        className={`${styles.middleBold}`}
        variant="body1"
        gutterBottom
      >
        {t('logistics_partner_data')}
      </Typography>
      <ValorizationHistoryEntryTable rows={rows} columnWidths={columnWidths} />
    </section>
  )
}

// Prevent rerender if parent rerendered and shallow child props comparison did not find differences.
export default React.memo(ValorizationHistoryLogisticsPartnerData)
