import styled from '@emotion/styled'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import FileCopy from '@material-ui/icons/FileCopy'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import React from 'react'
import { Link } from 'react-router-dom'
import { EVisibleOnDomain, ItemCategory } from '@/schemaTypes'

export interface ItemCategoriesRowProps {
  itemCategory: ItemCategory
}

const ItemCategoriesRow = (props: ItemCategoriesRowProps) => {
  const { itemCategory } = props
  const id = itemCategory._id ?? ''

  return (
    <Table>
      <TableBody>
        <FlexWrapper>
          <CellWrapper>{itemCategory.name}</CellWrapper>
          <CellWrapper>
            <Link to={`/inApp/configurations/itemCategories/create/${id}`}>
              <FileCopy />
            </Link>
            <Link to={`/inApp/configurations/itemCategories/edit/${id}`}>
              <EditIcon />
            </Link>
            {itemCategory.visibleOnDomain === EVisibleOnDomain.None && (
              <VisibilityOff />
            )}
          </CellWrapper>
        </FlexWrapper>
      </TableBody>
    </Table>
  )
}

export default ItemCategoriesRow

const FlexWrapper = styled(TableRow)`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`

const CellWrapper = styled(TableCell)`
  border: none;
`
