import { createContext } from 'react'
import { ELanguageCode, Employee } from '@/schemaTypes'
import { UIError } from './types'

export interface ContextType {
  t: Function
  showConfirmModal: Function
  closeConfirmModal: Function
  setLanguage: Function
  language: ELanguageCode.De | ELanguageCode.En
  showErrors: (errors: UIError[], closeCallback?: Function) => void
  showInfo: (message: string, closeCallback?: Function) => void
  history: any
  getCurrentUser: () => Employee | null
  isGuestUser: () => boolean
}

export const Context = createContext<ContextType>(Object.create(null))
