import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import {
  Mutation,
  MutationUpdateStorageLabelForCashBookCashFlowArgs,
} from '@/schemaTypes'

const UPDATE_STORAGE_LABEL_FOR_CASH_BOOK_CASH_FLOW = gql`
  mutation updateStorageLabelForCashBookCashFlow(
    $cashBookCashFlowId: ObjectId!
    $storageLabel: String!
  ) {
    updateStorageLabelForCashBookCashFlow(
      cashBookCashFlowId: $cashBookCashFlowId
      storageLabel: $storageLabel
    ) {
      ...CashBookCashFlowFragment
    }
  }
  ${fragments.cashBookCashFlow}
  ${fragments.fileMetadata}
`

type TData = {
  updateStorageLabelForCashBookCashFlow: Mutation['updateStorageLabelForCashBookCashFlow']
}
type TVariables = MutationUpdateStorageLabelForCashBookCashFlowArgs

export function useUpdateStorageLabelForCashBookCashFlow(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateStorageLabelForCashBookCashFlow] = useMutation<
    TData,
    TVariables
  >(UPDATE_STORAGE_LABEL_FOR_CASH_BOOK_CASH_FLOW, opts)

  return updateStorageLabelForCashBookCashFlow
}
