import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import thunk from 'redux-thunk'
import uiReducer from './reducer/ui'
import userReducer from './reducer/user'

const store = configureStore({
  reducer: {
    ui: uiReducer,
    user: userReducer,
  },
  middleware: [thunk],
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
