import { Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import ModelsPicker from '@/components/ModelsPicker'
import StorageFacilitiesDetails from '@/domains/storageFacilities/components/StorageFacilitiesDetails'
import {
  useAddStorageFacilityId,
  useGetStorageFacility,
  useRemoveStorageFacilityId,
  useUpdateStorageFacility,
} from '@/domains/storageFacilities/hooks'
import StorageUnitsConfiguration from '@/domains/storageUnits/components'
import {
  useConfirmDeleteMutationShowingErrors,
  useGetShopsNames,
  useMutationShowingErrors,
} from '@/hooks'
import { EOrderBy, StorageFacility } from '@/schemaTypes'

export interface StorageFacilitiesEditProps {
  storageFacilityId: string
}

export function StorageFacilitiesEdit(props: StorageFacilitiesEditProps) {
  const { storageFacilityId } = props
  const { t } = useTranslation()
  const history = useHistory()

  const { storageFacility, queryResult: getStorageFacilityQueryResult } =
    useGetStorageFacility({
      variables: {
        storageFacilityId,
        shopOpts: {
          order: { name: EOrderBy.Asc },
        },
      },
    })

  const { shops } = useGetShopsNames()

  const { shops: assignedShops, queryResult: assignedShopsQueryResult } =
    useGetShopsNames({
      variables: {
        opts: {
          filter: { storageFacilityIds: [storageFacilityId] },
          order: { name: EOrderBy.Asc },
        },
      },
      fetchPolicy: 'network-only',
    })

  const updateStorageFacility = useMutationShowingErrors({
    mutation: useUpdateStorageFacility(),
    successMessage: t('storage.storage_facility_updated'),
  })

  const removeStorageFacilityId = useConfirmDeleteMutationShowingErrors({
    mutation: useRemoveStorageFacilityId(),
  })

  const addStorageFacilityId = useMutationShowingErrors({
    mutation: useAddStorageFacilityId(),
  })

  const onSave = (storageFacility: StorageFacility) => {
    updateStorageFacility({
      variables: {
        ...storageFacility,
      },
    })
  }

  const onAddShop = (shopId: string) => {
    addStorageFacilityId({
      variables: {
        shopId,
        storageFacilityId,
      },
      onCompleted: () => assignedShopsQueryResult.refetch(),
    })
  }

  const onRemoveShop = (shopId: string) => {
    removeStorageFacilityId({
      variables: {
        shopId,
        storageFacilityId,
      },
      onCompleted: () => assignedShopsQueryResult.refetch(),
    })
  }

  const onClickShop = (shopId: string) => {
    history.push(`/inApp/configurations/shops/edit/${shopId}`)
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={storageFacilityId}
        modelName={'storage.storage_facilities'}
        mode={'edit'}
      />
      <Typography variant="h5" gutterBottom>
        {t('storage.storage_facility_edit')}
      </Typography>
      {getStorageFacilityQueryResult.loading ? (
        <Loading />
      ) : (
        storageFacility && (
          <StorageFacilitiesDetails
            storageFacility={storageFacility}
            onSave={onSave}
          />
        )
      )}
      <br />
      <br />
      <ModelsPicker
        label={t('shop.plural')}
        options={shops.map((cl) => ({ id: cl._id, label: cl.name }))}
        values={assignedShops.map((cl) => ({ id: cl._id, label: cl.name }))}
        onAdd={(id) => onAddShop(id)}
        onDelete={(id) => onRemoveShop(id)}
        onClick={(id) => onClickShop(id)}
      />

      <br />
      <StorageUnitsConfiguration
        storageFacilityId={storageFacility?._id}
        controlBarStatus={true}
      />
    </Paper>
  )
}

export default StorageFacilitiesEdit
