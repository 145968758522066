import StorageUnitsSelectInput from '../StorageUnitsSelectInput/StorageUnitsSelectInput'
import { TableCell, TableRow } from '@material-ui/core'
import LoopIcon from '@material-ui/icons/Loop'
import React from 'react'
import { EStorageStatus, ItemStorageHistoryEntry } from '@/schemaTypes'
import globalStyles from '@/styles/global.module.scss'

interface StorageUnitMenuItem {
  label: string
  value: string
}

export interface StorageUnitsSelectProps {
  firstLevelUnit: {
    _id: string
    onChange: (_id: string) => void
    menuItems: StorageUnitMenuItem[]
  }
  secondLevelUnit: {
    _id: string
    onChange: (_id: string) => void
    menuItems: StorageUnitMenuItem[]
  }
  thirdLevelUnit: {
    _id: string
    onChange: (_id: string) => void
    menuItems: StorageUnitMenuItem[]
  }
  lastLevelUnit: {
    _id: string
    onChange: (_id: string) => void
    menuItems: StorageUnitMenuItem[]
  }
  _handleResetForSelectors: (selector?: string | undefined) => Promise<void>
  latestStorageHistoryEntry?: ItemStorageHistoryEntry | null
}

export function StorageUnitsSelect(props: StorageUnitsSelectProps) {
  const {
    firstLevelUnit,
    secondLevelUnit,
    thirdLevelUnit,
    lastLevelUnit,
    _handleResetForSelectors,
    latestStorageHistoryEntry,
  } = props

  return (
    <TableRow>
      <TableCell align="center">
        <StorageUnitsSelectInput
          value={firstLevelUnit._id}
          onChange={(value) => firstLevelUnit.onChange(value)}
          menuItems={firstLevelUnit.menuItems}
          iconOnClick={() => _handleResetForSelectors('first')}
          latestStorageHistoryEntry={latestStorageHistoryEntry}
        />
      </TableCell>
      <TableCell align="center">
        <StorageUnitsSelectInput
          value={secondLevelUnit._id}
          onChange={(value) => secondLevelUnit.onChange(value)}
          menuItems={secondLevelUnit.menuItems}
          iconOnClick={() => _handleResetForSelectors('second')}
          latestStorageHistoryEntry={latestStorageHistoryEntry}
        />
      </TableCell>
      <TableCell align="center">
        <StorageUnitsSelectInput
          value={thirdLevelUnit._id}
          onChange={(value) => thirdLevelUnit.onChange(value)}
          menuItems={thirdLevelUnit.menuItems}
          iconOnClick={() => _handleResetForSelectors('third')}
          latestStorageHistoryEntry={latestStorageHistoryEntry}
        />
      </TableCell>
      <TableCell align="center">
        <StorageUnitsSelectInput
          value={lastLevelUnit._id}
          onChange={(value) => lastLevelUnit.onChange(value)}
          menuItems={lastLevelUnit.menuItems}
          iconOnClick={() => _handleResetForSelectors('last')}
          latestStorageHistoryEntry={latestStorageHistoryEntry}
        />
      </TableCell>
      <TableCell align="center">
        {(!latestStorageHistoryEntry ||
          latestStorageHistoryEntry.storageStatus !==
            EStorageStatus.AddedToStorage) && (
          <LoopIcon
            className={globalStyles.clickable}
            onClick={() => _handleResetForSelectors()}
          />
        )}
      </TableCell>
    </TableRow>
  )
}

export default StorageUnitsSelect
