import { EHintType } from '.'
import { TextareaAutosize } from '@material-ui/core'
import { Fragment, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import SaveIcon from '@/images/save.svg'
import { EItemStatusType, ItemEvent } from '@/schemaTypes'
import styles from './ItemStatus.module.scss'

export interface Props {
  onChangeItemStatus: (newEvent: ItemEvent, hint: EHintType) => void
}

const ItemCollectCarStatus: FunctionComponent<Props> = (props) => {
  const { onChangeItemStatus } = props
  const { t } = useTranslation()

  const [description, setDescription] = useState('')

  const saveNewStatus = () => {
    const now = new Date()
    onChangeItemStatus(
      {
        createdAt: now,
        timestamp: now,
        event: EItemStatusType.CollectCar,
        meta: {
          description: description,
        },
      },
      EHintType.NONE,
    )
  }

  return (
    <Fragment>
      <div
        onClick={saveNewStatus}
        className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
      >
        <ReactSVG className={`${styles.saveIcon} u-pl-15`} src={SaveIcon} />
      </div>
      <table>
        <tr className={`${styles.notSellableTr}`}>
          <td>
            <br />
            {t('description')}:
            <TextareaAutosize
              rowsMin={4}
              placeholder={t('any_description_want_to_add')}
              style={{ width: '100%' }}
              onChange={(e) => setDescription(e.target.value)}
            />
          </td>
        </tr>
      </table>
    </Fragment>
  )
}

export default ItemCollectCarStatus
