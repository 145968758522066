import styles from '../styles/CashBook.module.scss'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  Tooltip,
  createTheme,
} from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CancelIcon from '@material-ui/icons/Cancel'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'
import CloseIcon from '@material-ui/icons/Close'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import ExposureIcon from '@material-ui/icons/Exposure'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import LaunchIcon from '@material-ui/icons/Launch'
import PersonIcon from '@material-ui/icons/Person'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ColumnWithLooseAccessor,
  ColumnWithStrictAccessor,
  useTable,
} from 'react-table'
import {
  CashBookCashFlow,
  CashBookCashFlowReady2OrderInvoice,
  Customer,
  ECashBookPaymentReference,
  FileMetadata,
} from '@/schemaTypes'
import { assertDealConnectedToRegisteredCustomer } from '@/utils/deal'
import { printLocalAmount } from '@/utils/misc'
import { printLocalDate } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'
import CashBookTableStorageLabel from './CashBookTableStorageLabel'

export interface CashBookCashFlowsTableProps {
  data: CashBookCashFlow[]
  onUpdateItemId: (itemId: string, cashBookCashFlowId: string) => void
  onCancel: (cashBookCashFlowId: string) => void
}

export interface Row {
  _id: string
  cashFlowNumber: number
  date: Date
  dealId?: string
  bookingNumber?: number
  itemId?: string | null
  storageLabel?: string | null
  customerId?: string | null
  customerNumber?: number
  customerEmail?: string
  paymentReference: ECashBookPaymentReference
  note?: string | null
  outflow?: number | null
  inflow?: number | null
  closedFeesTotalAmount?: number | null
  ready2OrderInvoices?: CashBookCashFlowReady2OrderInvoice[]
  cancels?: CashBookCashFlow | null
  canceledBy?: CashBookCashFlow | null
  accumulatedBalance: number
  attachment?: FileMetadata | null
}

const AlignRight = (props: { children: ReactNode }) => (
  <div style={{ textAlign: 'right' }}>{props.children}</div>
)

const AlignCenter = (props: { children: ReactNode }) => (
  <div className={`u-flex u-jc-center`}>{props.children}</div>
)
const VerticalAlignMiddle = (props: { children: ReactNode }) => (
  <div className={styles.verticalAlignMiddle}>{props.children}</div>
)

export function CashBookCashFlowsTable(props: CashBookCashFlowsTableProps) {
  const { data } = props
  const { t } = useTranslation()

  const columns = React.useMemo<
    Array<ColumnWithStrictAccessor<Row> | ColumnWithLooseAccessor<Row>>
  >(
    () => [
      {
        Header: () => (
          <Tooltip title={t('cash_book_number')}>
            <span>#</span>
          </Tooltip>
        ),
        accessor: 'cashFlowNumber',
        width: 50,
        minWidth: 30,
      },
      {
        Header: () => (
          <AlignCenter>
            <VerticalAlignMiddle>
              <Tooltip title={t('deal.booking_number')}>
                <RecentActorsIcon />
              </Tooltip>
            </VerticalAlignMiddle>
          </AlignCenter>
        ),
        accessor: 'bookingNumber',
        width: 50,
        minWidth: 30,
        Cell: (data) => {
          return data.row.original.bookingNumber ? (
            <a
              href={`/inApp/deals/${data.row.original.dealId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.row.original.bookingNumber}
            </a>
          ) : null
        },
      },
      {
        Header: t('date'),
        accessor: 'date',
        width: 50,
        minWidth: 30,
        Cell: (data) => {
          return printLocalDate(data.row.values.date, {
            timezone: defaultTimezone,
          })
        },
      },
      {
        Header: t('storage.label'),
        accessor: 'storageLabel',
        width: 152,
        minWidth: 152,
        Cell: (data) => {
          if (data.value) {
            return data.row.original.storageLabel ? (
              <CashBookTableStorageLabel
                value={data.value}
                itemId={data.row.original.itemId}
                paymentReferance={data.row.original.paymentReference}
                onSave={(value) => {
                  if (value) {
                    props.onUpdateItemId(value, data.row.original._id)
                  }
                }}
              />
            ) : null
          } else {
            return data.value
          }
        },
      },
      {
        Header: () => (
          <AlignCenter>
            <VerticalAlignMiddle>
              <Tooltip title={t('customer.customer_number')}>
                <PersonIcon />
              </Tooltip>
            </VerticalAlignMiddle>
          </AlignCenter>
        ),
        accessor: 'customerNumber',
        width: 50,
        minWidth: 30,
        Cell: (data) => {
          return data.row.original.customerNumber ? (
            <a
              href={`/inApp/customers/edit/${data.row.original.customerId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.row.original.customerNumber}
            </a>
          ) : null
        },
      },
      {
        Header: t('payment_reference'),
        accessor: 'paymentReference',
        width: 156,
        minWidth: 136,
        Cell: (data) => {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
              <span>
                {t(
                  `cash_book.payment_reference_${data.row.original.paymentReference.toLowerCase()}`,
                )}
              </span>
              {data.row.original.attachment && (
                <a
                  href={data.row.original.attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LaunchIcon className={`${styles.linkIcon} u-pl-5`} />
                </a>
              )}
            </div>
          )
        },
      },
      {
        Header: t('note'),
        accessor: 'note',
        width: 500,
        minWidth: 30,
      },
      {
        Header: t('ready_2_order'),
        accessor: 'ready2OrderInvoices',
        width: 300,
        maxWidth: 300,
        minWidth: 136,
        Cell: (data) => {
          const ready2OrderInvoices = data.row.original.ready2OrderInvoices
          if (!ready2OrderInvoices) {
            return null
          }

          return (
            <p>
              {ready2OrderInvoices.length === 1
                ? `Ready2Order ID: ${ready2OrderInvoices[0].numberFull}`
                : `Ready2Order IDs: ${ready2OrderInvoices.map((invoice) => invoice.numberFull).join(', ')}`}
            </p>
          )
        },
      },
      {
        Header: () => (
          <AlignCenter>
            <VerticalAlignMiddle>
              <Tooltip title={t('outflow')}>
                <IndeterminateCheckBoxIcon />
              </Tooltip>
            </VerticalAlignMiddle>
          </AlignCenter>
        ),
        accessor: 'outflow',
        width: 50,
        minWidth: 30,
        Cell: (data) => {
          return (
            <AlignRight>
              {typeof data.row.original.outflow === 'number'
                ? printLocalAmount(data.row.original.outflow)
                : ''}
            </AlignRight>
          )
        },
      },
      {
        Header: () => (
          <AlignCenter>
            <VerticalAlignMiddle>
              <Tooltip title={t('inflow')}>
                <AddBoxIcon />
              </Tooltip>
            </VerticalAlignMiddle>
          </AlignCenter>
        ),
        accessor: 'inflow',
        width: 50,
        minWidth: 30,
        Cell: (data) => {
          return (
            <AlignRight>
              {typeof data.row.original.inflow === 'number'
                ? printLocalAmount(data.row.original.inflow)
                : ''}
            </AlignRight>
          )
        },
      },
      {
        Header: () => (
          <AlignCenter>
            <VerticalAlignMiddle>
              <Tooltip title={t('closedfeestotalamount')}>
                <DataUsageIcon />
              </Tooltip>
            </VerticalAlignMiddle>
          </AlignCenter>
        ),
        accessor: 'closedFeesTotalAmount',
        width: 50,
        minWidth: 30,
        Cell: (data) => {
          return (
            [
              ECashBookPaymentReference.Payback,
              ECashBookPaymentReference.Extension,
            ].includes(data.row.original.paymentReference) && (
              <AlignRight>
                {typeof data.row.original.closedFeesTotalAmount === 'number'
                  ? printLocalAmount(data.row.original.closedFeesTotalAmount)
                  : ''}
              </AlignRight>
            )
          )
        },
      },
      {
        Header: () => (
          <AlignCenter>
            <VerticalAlignMiddle>
              <Tooltip title={t('balance')}>
                <ExposureIcon />
              </Tooltip>
            </VerticalAlignMiddle>
          </AlignCenter>
        ),
        accessor: 'accumulatedBalance',
        width: 50,
        minWidth: 30,
        Cell: (data) => {
          return (
            <AlignRight>
              {typeof data.row.original.accumulatedBalance === 'number'
                ? printLocalAmount(data.row.original.accumulatedBalance)
                : ''}
            </AlignRight>
          )
        },
      },
      {
        Header: () => (
          <AlignCenter>
            <VerticalAlignMiddle>
              <Tooltip title={t('cash_book.canceled_by')}>
                <CancelPresentationIcon />
              </Tooltip>
            </VerticalAlignMiddle>
          </AlignCenter>
        ),
        accessor: 'canceledBy',
        width: 50,
        minWidth: 30,
        Cell: (data) => {
          return data.row.values.canceledBy?.cashFlowNumber ?? ''
        },
      },
      {
        Header: () => (
          <AlignCenter>
            <VerticalAlignMiddle>
              <Tooltip title={t('cash_book.cancels_cash_book')}>
                <CancelIcon />
              </Tooltip>
            </VerticalAlignMiddle>
          </AlignCenter>
        ),
        accessor: 'cancels',
        width: 50,
        minWidth: 30,
        Cell: (data) => {
          return data.row.values.cancels?.cashFlowNumber ?? ''
        },
      },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'canceler', // It needs an ID
        width: 24,
        minWidth: 24,
        Cell: (data) => {
          console.log('data.row.original.note', data.row.original.note)

          if (
            !data.row.original.customerNumber && // We don't allow canceling automatic generated entries.
            !data.row.original.canceledBy &&
            !data.row.original.cancels &&
            !data.row.original.note?.includes(
              'Storniert Excel Kassabucheintrag für Deal #',
            ) &&
            !data.row.original.valorizationEntryId
          ) {
            return (
              <span onClick={() => props.onCancel(data.row.original._id)}>
                <CloseIcon className={styles.closeIcon} />
              </span>
            )
          } else {
            return null
          }
        },
      },
    ],
    [props, t],
  )

  const tableInstance = useTable<Row>({
    columns,
    data: data.map((entry) => {
      let customer: Customer
      if (entry.deal) {
        assertDealConnectedToRegisteredCustomer(entry.deal)
        customer = entry.deal.customer
      }

      const row: Row = {
        _id: entry._id,
        bookingNumber: entry.deal?.bookingNumber,
        dealId: entry.deal?._id,
        date: entry.date,
        cashFlowNumber: entry.cashFlowNumber,
        customerId: customer?._id,
        customerEmail: customer?.email,
        customerNumber: customer?.customerNumber,
        paymentReference: entry.paymentReference,
        outflow: entry.outflow,
        inflow: entry.inflow,
        closedFeesTotalAmount: entry.closedFeesTotalAmount,
        accumulatedBalance: entry.accumulatedBalance,
        itemId: entry.itemId,
        storageLabel: entry.storageLabel,
        ready2OrderInvoices: entry.ready2OrderInvoices,
        canceledBy: entry.canceledBy,
        cancels: entry.cancels,
        attachment: entry.attachment,
        note: entry.note,
      }

      return row
    }),
    // getRowId: originalRow => originalRow._id,
  })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        sizeSmall: {
          padding: '3px 9px 3px 9px',
          '&:last-child': {
            paddingRight: '9px',
          },
        },
      },
    },
  })

  return (
    <Paper className={`${styles.cashbookCashFlowPaper} u-p-sm u-mb`}>
      <ThemeProvider theme={theme}>
        <Table
          {...getTableProps()}
          className={`u-mb-sm ${styles.cashbookCashFlowTable}`}
          size="small"
        >
          <TableHead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {
                      return (
                        // Apply the header cell props
                        <TableCell
                          style={{
                            maxWidth: column.maxWidth,
                            width: column.width,
                            minWidth: column.minWidth,
                          }}
                          {...column.getHeaderProps()}
                        >
                          {
                            // Render the header
                            column.render('Header')
                          }
                        </TableCell>
                      )
                    })
                  }
                </TableRow>
              ))
            }
          </TableHead>
          {/* Apply the table body props */}
          <TableBody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <TableRow {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <TableCell {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </ThemeProvider>
    </Paper>
  )
}

export default CashBookCashFlowsTable
