import { TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopy from '@material-ui/icons/FileCopy'
import React from 'react'
import { Link } from 'react-router-dom'
import { StorageFacility } from '@/schemaTypes'
import globalStyles from '@/styles/global.module.scss'

export interface StorageFacilitiesRowProps {
  storageFacility: StorageFacility
  deleteStorageFacility: () => void
}

const StorageFacilitiesRow = (props: StorageFacilitiesRowProps) => {
  const { storageFacility } = props
  const id = storageFacility._id ?? ''

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell align="right">{storageFacility.name}</TableCell>
      <TableCell align="right">{storageFacility.external}</TableCell>
      <TableCell>
        <Link to={`/inApp/configurations/storageFacilities/create/${id}`}>
          <FileCopy />
        </Link>
        <Link to={`/inApp/configurations/storageFacilities/edit/${id}`}>
          <EditIcon />
        </Link>
        <DeleteIcon
          className={globalStyles.clickable}
          onClick={props.deleteStorageFacility}
        />
      </TableCell>
    </TableRow>
  )
}

export default StorageFacilitiesRow
