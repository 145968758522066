import { QuestionFormat } from '../format/QuestionFormat'
import {
  CustomDealFormOption,
  NumberRestriction,
  QUESTION_INPUT_TYPE,
  QUESTION_TYPE,
  backfillNewLanguageOptions,
  createInitialMultipleLanguage,
  createInitialNumberRestriction,
  getDisplayQuestionType,
  getQuestionInputType,
  getQuestionType,
} from '../helpers'
import styled from '@emotion/styled'
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import Modal from '@/components/Modal'
import {
  MultipleLanguageInput,
  MultipleLanguageText,
} from '@/components/MultipleLanguageInput'
import { LanguagePicker } from '@/components/MultipleLanguageInput/LanguagePicker'
import { TranslationContextProvider } from '@/components/MultipleLanguageInput/LanguagePickerContext'
import {
  CustomDealFormQuestionCreateArgs,
  CustomDealFormQuestionUpdateArgs,
  ECustomDealFormQuestionTitleKey,
} from '@/schemaTypes'

interface Props {
  question?: CustomDealFormQuestionCreateArgs | CustomDealFormQuestionUpdateArgs
  hasConditionQuestion?: boolean
  onSave: (
    args: CustomDealFormQuestionCreateArgs | CustomDealFormQuestionUpdateArgs,
  ) => void
  onClose: () => void
}

interface CreateFormQuestion {
  label: MultipleLanguageText[]
  info: MultipleLanguageText[]
  type: QUESTION_TYPE
  inputType: QUESTION_INPUT_TYPE
  options?: CustomDealFormOption[]
  allowEnterAnswer?: boolean
  restrictions?: NumberRestriction[]
  initialAnswerArgs?: InitialAnswerArgs | null
  optional: number
  titleKey?: ECustomDealFormQuestionTitleKey
  showOnIssuanceCertificate: boolean
  showOnSalesBill: boolean
}

interface InitialAnswerArgs {
  numberAnswer?: number | null
  booleanAnswer?: boolean | null
  textAnswer?: string | null
}

export const CustomDealFormQuestionCreate = (props: Props) => {
  const { onClose, question, hasConditionQuestion, onSave } = props
  const { t } = useTranslation()

  const formik = useFormik<CreateFormQuestion>({
    initialValues: {
      label:
        question?.label != null
          ? backfillNewLanguageOptions(question?.label)
          : createInitialMultipleLanguage(),
      info:
        question?.info != null
          ? backfillNewLanguageOptions(question?.info)
          : createInitialMultipleLanguage(),
      type: getDisplayQuestionType(question?.type)?.type ?? QUESTION_TYPE.TEXT,
      inputType:
        getDisplayQuestionType(question?.type)?.inputType ??
        QUESTION_INPUT_TYPE.DEFAULT,
      options: question?.options?.map((option) => ({
        default: option.default,
        description:
          option.description != null
            ? backfillNewLanguageOptions(option.description)
            : createInitialMultipleLanguage(),
        label: backfillNewLanguageOptions(option.label),
      })),
      allowEnterAnswer: question?.allowEnterAnswer ?? undefined,
      restrictions:
        question?.restrictions?.map((restriction) =>
          createInitialNumberRestriction(restriction),
        ) ?? undefined,
      initialAnswerArgs: question?.initialAnswerArgs,
      optional: question?.optional ? 1 : 0,
      showOnIssuanceCertificate: question?.showOnIssuanceCertificate ?? false,
      showOnSalesBill: question?.showOnSalesBill ?? false,
      titleKey: question?.titleKey || undefined,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const args:
        | CustomDealFormQuestionCreateArgs
        | CustomDealFormQuestionUpdateArgs = {
        _id: question && '_id' in question ? question._id : undefined,
        label: values.label,
        info: values.info,
        type: getQuestionType(values.type, values.inputType),
        options: values.options?.map((option) => ({
          ...option,
          description: option.description?.every((value) => !value.text)
            ? null
            : option.description,
        })),
        allowEnterAnswer: values.allowEnterAnswer,
        restrictions: values.restrictions?.map((restriction) => ({
          ...restriction,
          conditionValue: Number(restriction.conditionValue),
        })),
        initialAnswerArgs: null,
        optional: !!values.optional,
        titleKey: values.titleKey || undefined,
        showOnIssuanceCertificate: values.showOnIssuanceCertificate,
        showOnSalesBill: values.showOnSalesBill,
      }

      if (values.type === QUESTION_TYPE.BOOLEAN) {
        args.initialAnswerArgs = {
          booleanAnswer: values.initialAnswerArgs?.booleanAnswer,
        }
      }

      onSave(args)
    },
  })

  const { values, handleChange, setFieldValue } = formik

  return (
    <Modal open onClose={onClose} fullWidth maxWidth="xl">
      <Content>
        <TranslationContextProvider>
          <Box mb="2rem">
            <Typography variant="h5" gutterBottom>
              {t(
                (props.question as CustomDealFormQuestionUpdateArgs)?._id
                  ? 'update_question'
                  : 'create_question',
              )}
            </Typography>
          </Box>

          <LanguagePicker />

          <Typography variant="h6">{t('label')}</Typography>

          <MultipleLanguageInput
            key="Label"
            values={values.label}
            onChange={(value) => setFieldValue('label', value)}
          />

          <Box display="flex" marginBottom="1rem">
            <TypeBox>
              <Typography variant="h6" gutterBottom>
                {t('type')}
              </Typography>
              <Select
                className="select"
                value={values.type}
                onChange={handleChange}
                name="type"
              >
                {Object.values(QUESTION_TYPE).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </TypeBox>

            <TypeBox>
              <Typography variant="h6" gutterBottom>
                {t('input_type')}
              </Typography>
              <Select
                className="select"
                value={values.inputType}
                onChange={handleChange}
                name="inputType"
              >
                {getQuestionInputType(values.type).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </TypeBox>

            {values.type === QUESTION_TYPE.BOOLEAN && (
              <TypeBox>
                <Typography variant="h6" gutterBottom>
                  {t('default_value')}
                </Typography>
                <Select
                  className="select"
                  value={values.initialAnswerArgs?.booleanAnswer ? '1' : '0'}
                  onChange={(e) =>
                    setFieldValue(
                      'initialAnswerArgs.booleanAnswer',
                      e.target.value === '0' ? false : true,
                    )
                  }
                  name="booleanAnswer"
                >
                  <MenuItem key="none" value="">
                    <em>{t('none')}</em>
                  </MenuItem>
                  <MenuItem value="0">{t('no')}</MenuItem>
                  <MenuItem value="1">{t('yes')}</MenuItem>
                </Select>
              </TypeBox>
            )}

            <TypeBox>
              <Typography variant="h6" gutterBottom>
                {t('optional')}
              </Typography>
              <Select
                className="select"
                value={values.optional}
                onChange={handleChange}
                name="optional"
              >
                <MenuItem value={0}>{t('no')}</MenuItem>
                <MenuItem value={1}>{t('yes')}</MenuItem>
              </Select>
            </TypeBox>

            <TypeBox>
              <Typography variant="h6" gutterBottom>
                {t('label_key')}
              </Typography>
              <Select
                className="select"
                value={values.titleKey ?? ''}
                onChange={handleChange}
                name="titleKey"
                disabled={
                  values.titleKey &&
                  values.titleKey !== ECustomDealFormQuestionTitleKey.Condition
                }
              >
                <MenuItem key="none" value="">
                  <em>{t('none')}</em>
                </MenuItem>
                {Object.values(ECustomDealFormQuestionTitleKey).map((key) => (
                  <MenuItem
                    value={key}
                    key={key}
                    disabled={
                      key !== ECustomDealFormQuestionTitleKey.Condition ||
                      hasConditionQuestion
                    }
                  >
                    {t(key)}
                  </MenuItem>
                ))}
              </Select>
            </TypeBox>
          </Box>

          <QuestionFormat
            type={getQuestionType(values.type, values.inputType)}
            options={values.options}
            allowEnterAnswer={values.allowEnterAnswer}
            restrictions={values.restrictions}
            setFieldValue={setFieldValue}
          />

          <Typography variant="h6" gutterBottom>
            {t('info')}
          </Typography>

          <MultipleLanguageInput
            values={values.info}
            onChange={(value) => setFieldValue('info', value)}
          />

          <Typography variant="h6" gutterBottom>
            {t('additional_options')}
          </Typography>

          <Typography>
            <Checkbox
              style={{ marginTop: -5 }}
              checked={values.showOnIssuanceCertificate ?? false}
              onChange={(e) =>
                setFieldValue('showOnIssuanceCertificate', e.target.checked)
              }
            />
            {t('show_on_issuance_certificate_description')}
          </Typography>

          <Typography>
            <Checkbox
              style={{ marginTop: -5 }}
              checked={values.showOnSalesBill ?? false}
              onChange={(e) =>
                setFieldValue('showOnSalesBill', e.target.checked)
              }
            />
            {t('show_on_sales_bill_description')}
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb="2rem"
            mt="4rem"
          >
            <Button color="primary" variant="outlined" onClick={onClose}>
              {t('cancel')}
            </Button>
            <div>
              <Button
                color="primary"
                variant="contained"
                startIcon={<CheckIcon />}
                onClick={formik.submitForm}
                disabled={formik.isSubmitting}
              >
                {t('confirm')}
              </Button>
            </div>
          </Box>
        </TranslationContextProvider>
      </Content>
    </Modal>
  )
}

const TypeBox = styled.div`
  flex: 1;

  .select {
    width: 200px;
  }
`

const Content = styled.div`
  margin: 1rem;
`
