import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetAuctionSuggestedDealsArgs } from '@/schemaTypes'

const GET_AUCTION_SUGGESTED_DEALS = gql`
  query getAuctionSuggestedDeals($companyId: ObjectId!) {
    getAuctionSuggestedDeals(companyId: $companyId) {
      _id
    }
  }
`

type TData = { getAuctionSuggestedDeals: Query['getAuctionSuggestedDeals'] }
type TVariables = QueryGetAuctionSuggestedDealsArgs

export function useGetAuctionSuggestedDeals(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const querySuggestedResult = useQuery<TData, TVariables>(
    GET_AUCTION_SUGGESTED_DEALS,
    {
      variables: {
        companyId: opts.variables?.companyId,
      },
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(querySuggestedResult.error)

  return {
    querySuggestedResult,
    suggestedDeals: querySuggestedResult.data?.getAuctionSuggestedDeals || [],
  }
}
