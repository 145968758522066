import ShopsTable from '.'
import React from 'react'
import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import getPagePath from '@/utils/getPagePath'
import ShopsCreate from './create'
import ShopsEdit from './edit/[shopId]'

export function ShopsRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/create/:shopId')}
        render={(props) => (
          <ShopsCreate
            {...props}
            shopId={props.match.params.shopId as string}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/edit/:shopId')}
        render={(props) => (
          <ShopsEdit {...props} shopId={props.match.params.shopId as string} />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/create')}
        render={(props) => <ShopsCreate {...props} shopId={null} />}
      />
      <SentryRoute path={getPagePath(props)} component={ShopsTable} />
    </Switch>
  )
}

export default ShopsRoutes
