import { useShowErrorsPopup } from '../../../hooks/useShowErrorsPopup'
import { QueryHookOptions, useLazyQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Query, QueryItemCalculationArgs } from '@/schemaTypes'
import { itemFragments } from './itemFragments'

const CALCULATE_DEAL_ITEM = gql`
  query itemCalculation(
    $durationInDays: Int!
    $date: DateTime
    $material: String
    $algoliaReference: String
    $variantId: ObjectId
    $customPayoutAmount: Float
    $itemCategoryId: ObjectId!
    $answers: [ItemAnswerArgs!]!
    $useOldValuesOfPrediction: Boolean
    $existingCurrentMarketValue: Float
    $existingPredictedMarketValue: Float
    $companyId: ObjectId!
    $vehicleData: VehicleDataArgs
  ) {
    itemCalculation(
      durationInDays: $durationInDays
      date: $date
      material: $material
      algoliaReference: $algoliaReference
      variantId: $variantId
      customPayoutAmount: $customPayoutAmount
      itemCategoryId: $itemCategoryId
      answers: $answers
      useOldValuesOfPrediction: $useOldValuesOfPrediction
      existingCurrentMarketValue: $existingCurrentMarketValue
      existingPredictedMarketValue: $existingPredictedMarketValue
      companyId: $companyId
      vehicleData: $vehicleData
    ) {
      itemValuesEntry {
        ...ItemValuesEntryFragment
      }
    }
  }
  ${itemFragments.itemValuesEntry}
`

type TData = { itemCalculation: Query['itemCalculation'] }
type TVariables = QueryItemCalculationArgs

export function useCalculateDealItem(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const [calculateDealItem, queryResult] = useLazyQuery<TData, TVariables>(
    CALCULATE_DEAL_ITEM,
    {
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return { queryResult, calculateDealItem }
}
