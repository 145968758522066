import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetDealsCountArgs } from '@/schemaTypes'

const GET_DEALS_COUNT = gql`
  query getDealsCount($opts: DealOpts) {
    getDealsCount(opts: $opts)
  }
`

type TData = { getDealsCount: Query['getDealsCount'] }
type TVariables = QueryGetDealsCountArgs

export function useGetDealsCount(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_DEALS_COUNT, {
    variables: {},
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { dealsTotal: queryResult.data?.getDealsCount || 0 }
}
