import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'

export const itemValorizationFragments = {
  valorizationHistory: gql`
    fragment ValorizationHistoryEntryFragment on ValorizationHistoryEntry {
      _id
      date
      cashflow
      returnReason
      returnReasonDetails
      isItemReturned
      isTaxFreeTransaction
      sendBill
      createdAt
      createdBy {
        __typename
        ... on Employee {
          firstname
          lastname
        }
        ... on SystemOperation {
          name
        }
      }
      updatedAt
      itemsIds
      buyerName
      valorizationBillNumber
      buyerPhone
      buyerEmail
      buyerBillingAddress {
        ...BuyerBillingAddressFragment
      }
      buyerShippingAddress {
        ...AddressFragment
      }
      valorizationBillLink {
        url
        contentType
      }
      valorizationCreditNoteLink {
        url
        contentType
      }
      valorizationCreditNoteNumber
      paymentData {
        ...PaymentDataFragment
      }
      order
      customLabel
      logisticsPartnerData {
        type
        trackingLink
        cashy {
          _placeHolder
        }
        logsta {
          logstaNumber
        }
      }
      salesPartnerData {
        type
        cashy {
          _placeHolder
        }
        ebay {
          ebayArticleNumber
          articleDescription
          shippingDate
          shippingCosts
          transactionId
          purchaseProcessingDate
          paidDate
          shippingService
          saleProtocolNumber
          numberOfItems
        }
        refurbed {
          ID
          warranty
          quantity
          shippingCosts
          settlementTotalRefunded
          settlementTotalCommission
          settlementBaseCommission
          settlementPayoutCommission
          settlementDynamicCommission
          settlementPaymentCommission
        }
        willhaben {
          willhabenArticleNumber
          articleDescription
          shippingDate
          shippingCosts
          transactionId
          purchaseProcessingDate
          paidDate
          shippingService
          saleProtocolNumber
          numberOfItems
        }
        chrono24 {
          transactionId
          saleId
        }
        rebelle {
          transactionId
          saleId
        }
        blitzmobile {
          transactionId
          saleId
        }
        ebaykleinanzeigen {
          kleinanzeigenArticleNumber
          shippingDate
        }
        baggyluxe {
          transactionId
          saleId
        }
        vestiairecollective {
          refId
        }
        others {
          transactionId
          saleId
        }
      }
      cumulatedCashflow
      differenceGross
      differenceNet
      differenceTax
      deltaDifferenceTax
      externalSalePriceGross
      externalSalePriceNet
      deviation
      valorizationResult
      cashFlowId
      externalSalePriceGrossDeduction
      externalSalePriceNetDeduction
      costOfGoodsSold
      taxableAmount
      grossProfit
    }
    ${fragments.address}
    ${fragments.buyerBillingAddress}
    ${fragments.paymentData}
  `,
  itemValorizationValues: gql`
    fragment ItemValorizationValuesFragment on ItemValorizationValues {
      salePrice
      valorizationDifferenceGross
      valorizationDifferenceTax
      valorizationDifferenceNet
      valorizationDeviation
    }
  `,
}

export const itemFragments = {
  itemValuesEntry: gql`
    fragment ItemValuesEntryFragment on ItemValuesEntry {
      conditionMaxMarketValues {
        excellent
        good
        poor
      }
      maxMarketValue
      currentMarketValue
      predictedMarketValue
      adjustedMarketValue
      loanToValue
      durationInDays
      date
      payoutAmount
    }
  `,
  itemValorizationCalculation: gql`
    fragment ItemValorizationCalculationFragment on ItemValorizationCalculation {
      valorizationHistory {
        ...ValorizationHistoryEntryFragment
      }
      itemValorizationValues {
        ...ItemValorizationValuesFragment
      }
    }
    ${itemValorizationFragments.valorizationHistory}
    ${itemValorizationFragments.itemValorizationValues}
  `,
  itemEvent: gql`
    fragment ItemEventFragment on ItemEvent {
      event
      createdAt
      timestamp
      meta {
        notSellableType
        permanentlyNotSellableReason
        temporaryNotSellableReason
        validFrom
        validUntil
        statusReason
        description
        salesPartnerName
        salesPartnerId
        salesPartnerTrackingLink
        logisticPartnerName
        cashyEmployeeId
        cashyEmployee {
          firstname
          lastname
        }
        logisticPartnerTrackingLink
        logisticPartnerTrackingCode
        logisticPartnerLabelFileLink {
          ...FileMetadataFragment
        }
        advertiserPlatformName
        advertisementLink
        isPrerequisites
        valorizationEntryId
        sendNotification
        emailAddress
      }
    }
  `,
}
