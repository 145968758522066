import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Paper, Table, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import ManualFeeDefinition from '@/domains/deals/components/ManualFeeDefintion/ManualFeeDefinition'
import UsedFeeDefinitionViewer from '@/domains/deals/components/UsedFeeDefinitionViewer'
import {
  Deal,
  DealCalculation,
  ETransactionType,
  GetExistingDealCalculationArgs,
  PrivateSaleTransactionArgs,
} from '@/schemaTypes'
import {
  getTotalFeeAmountOfType,
  getTotalGrossFeeAmountOfType,
} from '@/utils/deal'
import { printDate, printLocalAmount } from '@/utils/misc'

interface PrivateSaleCalculationViewerProps {
  deal: Deal
  dealCloseCalculation: DealCalculation
  transactionsArgs: PrivateSaleTransactionArgs[]
  calculationQueryResult
  existingCalculationArgs: GetExistingDealCalculationArgs
  setExistingCalculationArgs: (value: GetExistingDealCalculationArgs) => void
}

const PrivateSaleCalculationViewer: FunctionComponent<
  PrivateSaleCalculationViewerProps
> = (props) => {
  const {
    deal,
    transactionsArgs,
    dealCloseCalculation,
    calculationQueryResult,
    existingCalculationArgs,
    setExistingCalculationArgs,
  } = props
  const { t } = useTranslation()

  let sellingCarPrice = 0
  if (
    transactionsArgs
      .map((c) => c.transactionType)
      .includes(ETransactionType.SellingCar)
  ) {
    sellingCarPrice = transactionsArgs.filter(
      (c) => c.transactionType === ETransactionType.SellingCar,
    )[0].amount
  }

  const totalNetFees = getTotalFeeAmountOfType(
    dealCloseCalculation.appliedUsedFeeDefinitions,
  )
  const totalGrossFees = getTotalGrossFeeAmountOfType(
    dealCloseCalculation.appliedUsedFeeDefinitions,
  )
  const finalBalance =
    sellingCarPrice - deal.dealFinalValues.payoutAmount - totalGrossFees

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Typography variant="h5" gutterBottom className={'u-flex u-jc-sb'}>
        <div className={'u-inline-block'} style={{ marginRight: 'auto' }}>
          {`${t('final_bill_overview')} `}
        </div>
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('description')}</TableCell>
            <TableCell>{t('date')}</TableCell>
            <TableCell>{t('deposit_value')}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {deal.items.map((item) => {
            return (
              <TableRow>
                <TableCell>{item.title}</TableCell>
                <TableCell>{printDate(deal.dealStartDate)}</TableCell>
                <TableCell>
                  {printLocalAmount(deal.dealFinalValues.payoutAmount)}
                </TableCell>
              </TableRow>
            )
          })}

          <TableRow>
            <TableCell>{t('fees_excluding_ust')}</TableCell>
            <TableCell>{printDate(dealCloseCalculation.date)}</TableCell>
            <TableCell>{printLocalAmount(totalNetFees)}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t('value_added_tax')}</TableCell>
            <TableCell>{printDate(dealCloseCalculation.date)}</TableCell>
            <TableCell>
              {printLocalAmount(totalGrossFees - totalNetFees)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t('fees_including_ust')}</TableCell>
            <TableCell>{printDate(dealCloseCalculation.date)}</TableCell>
            <TableCell>{printLocalAmount(totalGrossFees)}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t('total_liabilities')}</TableCell>
            <TableCell>{printDate(dealCloseCalculation.date)}</TableCell>
            <TableCell>
              {printLocalAmount(
                totalGrossFees + deal.dealFinalValues.payoutAmount,
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t('selling_price')}</TableCell>
            <TableCell>{printDate(dealCloseCalculation.date)}</TableCell>
            <TableCell>{printLocalAmount(sellingCarPrice)}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell
              style={{
                fontWeight: 'bold',
                color: finalBalance >= 0 ? 'green' : 'red',
              }}
            >
              {finalBalance >= 0 ? t('exceeded') : t('shortaged')}
            </TableCell>
            <TableCell />
            <TableCell
              style={{
                fontWeight: 'bold',
                color: finalBalance >= 0 ? 'green' : 'red',
              }}
            >
              {printLocalAmount(finalBalance)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <div style={{ marginTop: '2rem' }}>
        <ManualFeeDefinition
          deal={deal}
          dealCalculation={dealCloseCalculation}
          args={existingCalculationArgs.manualFeeDefinitionsArgs}
          setArgs={(value) => {
            setExistingCalculationArgs({
              ...existingCalculationArgs,
              manualFeeDefinitionsArgs: value,
              shouldOverwriteManualFees: true,
            })
          }}
          calculationQueryResult={calculationQueryResult}
          showPayoutAmount={false}
          showResetButton={false}
          isReadonly={false}
        />
      </div>

      <UsedFeeDefinitionViewer
        deal={deal}
        dealCalculation={dealCloseCalculation}
        isLoading={calculationQueryResult.isLoading}
        items={deal.items}
        showLayoutButton={false}
        defaultLayout={'list'}
        showItemDetailsButton={false}
        itemRecordsButtonDefaultValue={true}
        showChargTableText={false}
      />
    </Paper>
  )
}

export default PrivateSaleCalculationViewer
