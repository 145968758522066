import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateItemCategoryArgs } from '@/schemaTypes'

const CREATE_ITEM_CATEGORY = gql`
  mutation createItemCategory(
    $name: String!
    $parentId: ObjectId
    $algoliaCategorySlug: String
    $categoryPageIds: [ObjectId!]
    $storageFeeIds: [ObjectId!]!
    $visibleOnDomain: EVisibleOnDomain
    $expectedTurnOverTime: Float
    $helpLink: String
    $maximumPawnDuration: EMaximumPawnDuration
  ) {
    createItemCategory(
      name: $name
      parentId: $parentId
      algoliaCategorySlug: $algoliaCategorySlug
      categoryPageIds: $categoryPageIds
      storageFeeIds: $storageFeeIds
      visibleOnDomain: $visibleOnDomain
      expectedTurnOverTime: $expectedTurnOverTime
      helpLink: $helpLink
      maximumPawnDuration: $maximumPawnDuration
    ) {
      _id
      name
      slug
      algoliaCategorySlug
      categoryPageIds
      categoryPages {
        _id
        lvl0ContentCategory
        lvl1ContentCategory
      }
      parentId
      visibleOnDomain
      expectedTurnOverTime
      helpLink
      maximumPawnDuration
    }
  }
`

type TData = { createItemCategory: Mutation['createItemCategory'] }
type TVariables = MutationCreateItemCategoryArgs

export function useCreateItemCategory(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createItemCategory] = useMutation<TData, TVariables>(
    CREATE_ITEM_CATEGORY,
    opts,
  )

  return createItemCategory
}
