import { useDeleteCustomer } from '../../../../domains/customers/hooks'
import { useSearchCustomers } from '../../../../domains/customers/hooks/searchCustomers'
import dayjs from 'dayjs'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Loading from '@/components/Loading'
import { useConfirmDeleteMutationShowingErrors } from '@/hooks'
import { HistoryFilters, useHistoryState } from '@/hooks/useHistoryState'
import { usePrepareMergingCustomer } from '@/hooks/usePrepareMergingCustomer'
import InfiniteScrollTable from '@/redesign/components/InfiniteScrollTable/InfiniteScrollTable'
import SearchInput from '@/redesign/components/SearchInput/SearchInput'
import TableFilterHeader from '@/redesign/components/TableFilterHeade/TableFilterHeader'
import Taskbar from '@/redesign/components/Taskbar/Taskbar'
import { CustomersFiltersArgs } from '@/schemaTypes'

const LIMIT = 10
const defaultTableFilterArgs = { paginationArgs: { skip: 0, limit: LIMIT } }

const CustomersListPage = () => {
  const { t } = useTranslation()
  const { historyState } =
    useHistoryState<HistoryFilters<CustomersFiltersArgs>>()
  const [customerFilter, setCustomerFilter] = useState<CustomersFiltersArgs>({
    ...historyState?.filters,
    ...defaultTableFilterArgs,
  })
  const {
    selecting,
    toggleSelecting,
    selectedCustomerIds,
    toggleCustomer,
    disableSelection,
    disableLinkGotoMergePage,
  } = usePrepareMergingCustomer()
  const history = useHistory()
  const [date, setDate] = useState(new Date())

  const customerFilterArgs = useMemo(() => {
    return {
      ...customerFilter,
      createdBefore: date,
      paginationArgs: { skip: 0, limit: LIMIT },
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerFilter])

  const { queryResult } = useSearchCustomers({
    variables: {
      args: customerFilterArgs,
    },
    nextFetchPolicy: 'cache-first',
  })

  const customers = useMemo(
    () =>
      queryResult?.data?.searchCustomers?.nodes.map((node) => ({
        ...node,
        dateOfBirth: dayjs(node.dateOfBirth).format('DD.MM.YYYY'),
        sex: node.sex ? node.sex.charAt(0) : '-',
      })),
    [queryResult?.data],
  )

  const loadNextPage = useCallback(async () => {
    const pageInfo = queryResult?.data?.searchCustomers?.pageInfo
    const paginationLimit = pageInfo?.limit || LIMIT
    const paginationSkip = (pageInfo?.skip || 0) + paginationLimit

    await queryResult.fetchMore({
      variables: {
        args: {
          ...customerFilterArgs,
          paginationArgs: {
            skip: paginationSkip,
            limit: paginationLimit,
          },
        },
      },
    })
  }, [queryResult, customerFilterArgs])

  const handleSearch = useCallback(
    (event) => {
      const value = event?.target?.value
      setDate(new Date())
      setCustomerFilter((prev) => ({
        ...prev,
        search: value,
        createdBefore: date,
        ...defaultTableFilterArgs,
      }))
    },
    [date],
  )

  const deleteCustomer = useConfirmDeleteMutationShowingErrors({
    successMessage: t('customer.deleted'),
    mutation: useDeleteCustomer(),
  })

  const handleDeleteClick = useCallback(
    (id: string) => {
      deleteCustomer({
        variables: {
          customerId: id,
        },
        onCompleted: () => {
          if (queryResult.refetch) {
            queryResult.refetch()
          }
        },
      })
    },
    [deleteCustomer, queryResult],
  )

  const handleClickCustomer = useCallback(
    ({ value }) => {
      if (selecting) {
        toggleCustomer(value._id)
      } else {
        history.push(`/inApp/customers/edit/${value._id}`)
      }
    },
    [selecting, history, toggleCustomer],
  )

  const tableCheckboxTemplate = ({
    userPermissionId,
    _id,
  }: {
    userPermissionId: string
    _id: string
  }) => {
    return (
      <Checkbox
        inputId={userPermissionId}
        name={userPermissionId}
        onChange={() => toggleCustomer(_id)}
        checked={selectedCustomerIds.includes(_id)}
        disabled={disableSelection && !selectedCustomerIds.includes(_id)}
      />
    )
  }

  const tableActionsTemplate = ({ _id }: { _id: string }) => {
    return (
      <div className="flex justify-end">
        <Link
          className="mr-2"
          to={`/inApp/customers/edit/${_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon="pi pi-external-link" severity="secondary" text />
        </Link>
        <Button
          icon="pi pi-clone"
          className="me-1 !mr-2"
          outlined
          severity="secondary"
          onClick={() => history.push(`/inApp/customers/create/${_id}`)}
          disabled={selecting}
          text
          tooltip={t('duplicate_customer')}
          tooltipOptions={{ position: 'bottom' }}
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          onClick={() => handleDeleteClick(_id)}
          disabled={selecting}
          text
          tooltip={t('delete_customer')}
          tooltipOptions={{ position: 'bottom' }}
        />
      </div>
    )
  }

  return (
    <div className="h-full flex flex-col">
      <TableFilterHeader
        title={t('customers')}
        resultsCount={queryResult.data?.searchCustomers.pageInfo.total || 0}
        filterOptions={
          <SearchInput
            placeholder={t('search')}
            value={customerFilter.search ?? ''}
            onChange={handleSearch}
            onClear={() => handleSearch(null)}
          />
        }
      />

      {queryResult.loading ? (
        <Loading />
      ) : (
        <>
          <InfiniteScrollTable
            handleLoadNextPage={loadNextPage}
            dataKey="_id"
            value={customers ?? []}
            onClickRow={handleClickCustomer}
            limit={queryResult.data?.searchCustomers.pageInfo.limit ?? LIMIT}
            hasNextPage={
              queryResult.data?.searchCustomers.pageInfo.hasNextPage ?? true
            }
          >
            {selecting ? (
              <Column style={{ width: '50px' }} body={tableCheckboxTemplate} />
            ) : null}
            <Column
              field="firstname"
              style={{ width: '80px' }}
              header={t('customer.firstname')}
            />
            <Column
              field="lastname"
              style={{ width: '80px' }}
              header={t('customer.lastname')}
            />
            <Column
              field="email"
              style={{ width: '150px' }}
              header={t('customer.email')}
            />
            <Column
              field="dateOfBirth"
              style={{ width: '90px' }}
              header={t('customer.date_of_birth')}
            />
            <Column
              field="phone"
              style={{ width: '120px' }}
              header={t('customer.phone')}
            />
            <Column
              field="sex"
              style={{ width: '40px' }}
              header={t('customer.sex')}
            />
            <Column
              field="customerNumber"
              style={{ width: '40px' }}
              header={t('nr.')}
            />
            <Column style={{ width: '80px' }} body={tableActionsTemplate} />
          </InfiniteScrollTable>
          <Taskbar>
            <div className="flex flex-col md:flex-row justify-between w-full">
              <div className="flex flex-col sm:flex-row mb-2 md:mb-0">
                <Link
                  style={{ pointerEvents: selecting ? 'none' : 'auto' }}
                  to="/inApp/customers/create"
                >
                  <Button
                    label={t('add_new_customer')}
                    icon="pi pi-user-plus"
                    severity="secondary"
                    disabled={selecting}
                  />
                </Link>

                <div className="ml-3">
                  <Button
                    label={t('merge_customer')}
                    icon="pi pi-arrow-right-arrow-left"
                    severity="secondary"
                    disabled={selecting}
                    onClick={() => toggleSelecting()}
                  />
                </div>
              </div>

              {selecting && (
                <div className="flex flex-col sm:flex-row mb-2 md:mb-0">
                  <div className="ml-0 sm:ml-3 mt-2 sm:mt-0 mr-3">
                    <Button
                      label={t('cancel_merge')}
                      severity="secondary"
                      icon="pi pi-times"
                      text
                      onClick={() => toggleSelecting()}
                    />
                  </div>
                  <Link
                    style={{
                      pointerEvents: !disableLinkGotoMergePage
                        ? 'none'
                        : 'auto',
                    }}
                    to={`/inApp/customers/merge/${selectedCustomerIds.join(',')}`}
                  >
                    <Button
                      label={t('merge_selected_customers')}
                      onClick={() => toggleSelecting()}
                      disabled={!disableLinkGotoMergePage}
                    />
                  </Link>
                </div>
              )}
            </div>
          </Taskbar>
        </>
      )}
    </div>
  )
}

export default CustomersListPage
