import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeleteStorageUnitArgs } from '@/schemaTypes'

const DELETE_STORAGE_UNIT = gql`
  mutation deleteStorageUnit($storageUnitId: ObjectId!) {
    deleteStorageUnit(storageUnitId: $storageUnitId) {
      _id
      isDeleted
    }
  }
`

type TData = { deleteStorageUnit: Mutation['deleteStorageUnit'] }
type TVariables = MutationDeleteStorageUnitArgs

export function useDeleteStorageUnit(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deleteStorageUnit] = useMutation<TData, TVariables>(
    DELETE_STORAGE_UNIT,
    opts,
  )
  return deleteStorageUnit
}
