import { Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import ItemQuestionsDetails from '@/domains/itemQuestions/components/ItemQuestionsDetails'
import {
  useCreateItemQuestion,
  useGetItemQuestion,
} from '@/domains/itemQuestions/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { ItemQuestion } from '@/schemaTypes'

export interface ItemQuestionsCreateProps {
  itemQuestionId: string | null
}

export function ItemQuestionsCreate(props: ItemQuestionsCreateProps) {
  const { itemQuestionId } = props
  const { t } = useTranslation()
  const history = useHistory()

  const { itemQuestion, queryResult } = useGetItemQuestion({
    variables: {
      itemQuestionId: itemQuestionId ?? '',
    },
  })

  const createItemQuestion = useMutationShowingErrors({
    mutation: useCreateItemQuestion(),
    successMessage: t('item_question_created'),
  })

  const onSave = (itemQuestion: ItemQuestion) => {
    createItemQuestion({
      variables: {
        ...itemQuestion,
      },
      onCompleted: (data) => {
        history.replace(
          `/inApp/configurations/itemQuestions/edit/${data.createItemQuestion._id}`,
        )
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={itemQuestionId ?? ''}
        modelName={'itemQuestions'}
        mode={'create'}
      />
      <Typography variant="h5" gutterBottom>
        {t('item_question_create')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        itemQuestion && (
          <ItemQuestionsDetails itemQuestion={itemQuestion} onSave={onSave} />
        )
      )}
    </Paper>
  )
}

export default ItemQuestionsCreate
