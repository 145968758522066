import Spinner from 'react-spinkit'
import styles from './FullScreenLoading.module.scss'

const FullScreenLoading = () => (
  <div id="FullScreenLoading" className={styles.root}>
    <Spinner name="line-scale" color="#5017f4" />
  </div>
)

export default FullScreenLoading
