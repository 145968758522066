import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import { FunctionComponent, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'

export interface OnlineExtensionNoteProps {
  open: boolean
  setOpen: (value: boolean) => void
  onUpdateIsOnlineExtensionPrevented: (flag: boolean, note: string) => void
}

const OnlineExtensionNoteDialog: FunctionComponent<OnlineExtensionNoteProps> = (
  props,
) => {
  const { t } = useTranslation()
  const { isGuestUser } = useContext(Context)

  const { open, setOpen, onUpdateIsOnlineExtensionPrevented } = props
  const [dealExtensionBandReason, setDealExtensionBandReason] =
    useState<string>()

  const handleConfirmModal = () => {
    setOpen(false)
    onUpdateIsOnlineExtensionPrevented(true, dealExtensionBandReason)
  }

  return (
    <Dialog
      maxWidth={'sm'}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          {t('in_house_reason_for_extension_ban')}
        </Typography>

        <Typography variant="body1" gutterBottom>
          {t('in_house_reason_for_extension_ban_desc')}
        </Typography>
        <TextareaAutosize
          rowsMin={5}
          placeholder={t('note_placeholder')}
          style={{ width: 550, marginTop: '1rem' }}
          disabled={isGuestUser()}
          value={dealExtensionBandReason}
          onChange={(e) => setDealExtensionBandReason(e.target.value)}
          name="content"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          {t('cancel')}
        </Button>
        <Button
          onClick={handleConfirmModal}
          color="primary"
          disabled={!Boolean(dealExtensionBandReason?.trim())}
        >
          {t('block_extension')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OnlineExtensionNoteDialog
