import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { some, takeWhile } from 'lodash'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import ValorizationHistoryEntry from '@/components/ValorizationHistoryEntry'
import {
  Deal,
  EItemStatusType,
  Item,
  ValorizationHistoryEntry as ValorizationHistoryEntryModel,
} from '@/schemaTypes'
import { getLatestEventForStatus } from '@/utils/deal'
import { compareValorizationEntries } from '@/utils/valorization'
import styles from './ValorizationHistory.module.scss'

export interface ValorizationHistoryProps {
  deal: Deal
  itemWithEvents: Item
  valorizationHistory: ValorizationHistoryEntryModel[]
  onAddNewValorizationHistoryEntry: (fillWithPreviousData?: boolean) => void
  onSaveValorizationHistoryEntry: (
    data: Partial<ValorizationHistoryEntryModel>,
  ) => void
  onDeleteUnsavedValorizationHistoryEntry: () => void
  onDeleteSavedValorizationHistoryEntry: (valorizationId) => void
  showUnsavedEntry: boolean
  loadingId?: string
  deleteSavedValorizationInProgress
  onUpdateSavedValorizationHistoryEntry: (
    data: Partial<ValorizationHistoryEntryModel>,
  ) => void
  onReGenerateFinalReceipt: (valorizationId) => void
}

export function ValorizationHistory(props: ValorizationHistoryProps) {
  const {
    deal,
    itemWithEvents,
    valorizationHistory,
    onAddNewValorizationHistoryEntry,
    showUnsavedEntry,
    onSaveValorizationHistoryEntry,
    onDeleteUnsavedValorizationHistoryEntry,
    onDeleteSavedValorizationHistoryEntry,
    loadingId,
    deleteSavedValorizationInProgress,
    onUpdateSavedValorizationHistoryEntry,
    onReGenerateFinalReceipt,
  } = props
  const { t } = useTranslation()

  const paybackConfirmedEvent = getLatestEventForStatus(
    deal,
    'DealPaybackConfirmedEvent',
  )
  const extensionConfirmedEvent = getLatestEventForStatus(
    deal,
    'DealExtensionConfirmedEvent',
  )
  const disabled =
    !!extensionConfirmedEvent ||
    !!paybackConfirmedEvent ||
    (deal.pawnData && deal.pawnData.buyBackChildId)

  const sortedValorizationHistory = [...valorizationHistory].sort(
    compareValorizationEntries,
  )
  const itemLastEvent = itemWithEvents.events
    ? itemWithEvents.events[itemWithEvents.events.length - 1].event
    : undefined
  // only sort if createdAt is already set - otherwise always in first position

  const shouldEnabled =
    itemLastEvent === EItemStatusType.OfferedToSell ||
    itemLastEvent === EItemStatusType.Sold ||
    itemLastEvent === EItemStatusType.ReturnRequested

  const isAlreadyReturnItem =
    !valorizationHistory.length ||
    some(
      takeWhile(
        valorizationHistory,
        (item: ValorizationHistoryEntryModel) => item.cashflow < 0,
      ),
      (item: ValorizationHistoryEntryModel) => item.isItemReturned,
    )

  return (
    <div>
      {!showUnsavedEntry && (
        <Fragment>
          <Button
            className={`u-mh-mini u-mt-20 u-mb-20 ${shouldEnabled ? styles.buttonEnabled : styles.buttonDisabled}`}
            variant="contained"
            onClick={() => onAddNewValorizationHistoryEntry()}
            disabled={disabled || !shouldEnabled || !isAlreadyReturnItem}
          >
            {t('new_entry')}
          </Button>
          <Button
            className={`u-mh-mini u-mt-20 u-mb-20 ${shouldEnabled && valorizationHistory.length ? styles.buttonEnabled : styles.buttonDisabled}`}
            variant="contained"
            onClick={() => onAddNewValorizationHistoryEntry(true)}
            disabled={disabled || !valorizationHistory.length}
          >
            {t('new_entry_with_data')}
          </Button>
          {!shouldEnabled ? (
            <span style={{ color: 'red', fontSize: '0.7rem' }}>
              {deal.pawnData && deal.pawnData.buyBackChildId
                ? t('deal_is_buyback_parent')
                : `${t('valorization_entry_cannot_be_added_reason')} ${itemLastEvent}`}
            </span>
          ) : (
            ''
          )}
        </Fragment>
      )}

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ backgroundColor: '#e9e2f8' }}>
              <TableCell />
              <TableCell />
              <TableCell align="center">{t('date')}</TableCell>
              <TableCell align="center">{t('sales_partner')}</TableCell>
              <TableCell align="center">{t('payment')}</TableCell>
              <TableCell align="center">{t('payments_cumulated')}</TableCell>
              <TableCell align="center">{t('deal.pawn.bill_number')}</TableCell>
              <TableCell align="center">{t('credit_note_number')}</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedValorizationHistory.map((entry) => {
              const saved = !showUnsavedEntry || entry._id !== ''
              return (
                <ValorizationHistoryEntry
                  item={itemWithEvents}
                  key={entry._id}
                  entry={entry}
                  saveEntry={onSaveValorizationHistoryEntry}
                  deleteEntry={() => onDeleteUnsavedValorizationHistoryEntry()}
                  removeValorizationEntry={
                    onDeleteSavedValorizationHistoryEntry
                  }
                  saved={saved}
                  disabled={disabled}
                  loading={entry._id === loadingId}
                  deleteSavedValorizationInProgress={
                    deleteSavedValorizationInProgress
                  }
                  valorizationIndex={sortedValorizationHistory.indexOf(entry)}
                  itemValorizationHistoryEntries={sortedValorizationHistory}
                  onUpdateSavedValorizationHistoryEntry={
                    onUpdateSavedValorizationHistoryEntry
                  }
                  onReGenerateFinalReceipt={onReGenerateFinalReceipt}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ValorizationHistory
