import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import {
  Mutation,
  MutationCreatePrivateSaleTransactionArgs,
} from '@/schemaTypes'

const CREATE_PRIVATE_SALE_TRANSACTION = gql`
  mutation createPrivateSaleTransaction(
    $privateSaleId: ObjectId!
    $args: PrivateSaleTransactionArgs!
    $needToHandleCashbook: Boolean!
  ) {
    createPrivateSaleTransaction(
      args: $args
      privateSaleId: $privateSaleId
      needToHandleCashbook: $needToHandleCashbook
    ) {
      _id
      createdAt
      creationType
      privateSaleDate
      dealId
      itemId
      bookingNumber
      carTitle
      customerFullName
      status
      dealFinalBalance
      privateSaleTransactions {
        ...PrivateSaleTransactionFragment
      }
    }
  }
  ${fragments.privateSaleTransaction}
`

type TData = {
  createPrivateSaleTransaction: Mutation['createPrivateSaleTransaction']
}
type TVariables = MutationCreatePrivateSaleTransactionArgs

export function useCreatePrivateSaleTransaction(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createPrivateSaleTransaction] = useMutation<TData, TVariables>(
    CREATE_PRIVATE_SALE_TRANSACTION,
    opts,
  )
  return createPrivateSaleTransaction
}
