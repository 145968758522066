import {
  DealItemCreateArgs,
  ItemAnswerArgs,
  ProductLegacy,
  ProductVariant,
} from '@/gql/graphql'

interface BuildItemCreateArgsData {
  itemAnswerArgs: ItemAnswerArgs[]
  legacyProduct: boolean
  productObjectID?: string
  newProduct: boolean
  productVariantId?: string
  productLegacy: ProductLegacy
  productVariant: ProductVariant
}

export function buildItemCreateArgs({
  itemAnswerArgs,
  legacyProduct,
  productObjectID,
  newProduct,
  productVariantId,
  productLegacy,
  productVariant,
}: BuildItemCreateArgsData): DealItemCreateArgs {
  return {
    answers: itemAnswerArgs,
    algoliaReference: legacyProduct ? productObjectID : undefined,
    variantId: newProduct ? productVariantId : undefined,
    itemCategoryId: legacyProduct
      ? productLegacy?.itemCategoryId
      : productVariant?.product.categoryId,
    title: (legacyProduct ? productLegacy?.title : productVariant.name) ?? '',
    material: legacyProduct
      ? productLegacy?.material
      : getProductVariantMaterial(productVariant),
    ean: productLegacy?.ean ?? undefined,
    // TODO: support in new product database
    isInvestmentGold: productLegacy?.isTaxFree,
  }
}

export function getProductVariantMaterial(
  productVariant: ProductVariant | undefined,
) {
  return productVariant?.product.price?.__typename ===
    'ProductPriceManualPreciousMetalMode'
    ? productVariant.product.price.material
    : undefined
}
