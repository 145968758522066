import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationConvertToDealArgs } from '@/schemaTypes'

const CONVERT_TO_DEAL = gql`
  mutation ConvertToDeal(
    $customDealId: ObjectId!
    $customDealUpdateArgs: CustomDealUpdateArgs
  ) {
    convertToDeal(
      customDealId: $customDealId
      customDealUpdateArgs: $customDealUpdateArgs
    )
  }
`

type TData = { changeCustomDealStatus: Mutation['convertToDeal'] }
type TVariables = MutationConvertToDealArgs

export function useConvertToDeal() {
  const [changeCustomDealStatus] = useMutation<TData, TVariables>(
    CONVERT_TO_DEAL,
    {
      refetchQueries: ['getCustomDealById'],
    },
  )
  return changeCustomDealStatus
}
