import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationUpdateItemMediaArgs } from '@/schemaTypes'

const UPDATE_ITEM_MEDIA = gql`
  mutation updateItemMedia(
    $itemId: ObjectId!
    $mediaArgs: [FileTusMetadataArgs!]!
  ) {
    updateItemMedia(itemId: $itemId, mediaArgs: $mediaArgs) {
      _id
      media {
        ...FileMetadataFragment
      }
    }
  }
  ${fragments.fileMetadata}
`

type TData = { updateItemMedia: Mutation['updateItemMedia'] }
type TVariables = MutationUpdateItemMediaArgs

export function useUpdateItemMedia(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateItemMedia] = useMutation<TData, TVariables>(
    UPDATE_ITEM_MEDIA,
    opts,
  )

  return updateItemMedia
}
