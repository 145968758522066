import gql from 'graphql-tag'

export const companyFragments = {
  companyCounter: gql`
    fragment CompanyCountersFragment on CompanyCounter {
      bookingNumber
      pawnNumber
      billNumbers {
        year
        counter
      }
      receiptNumbers {
        year
        counter
      }
      valorizationBillNumbers {
        year
        counter
      }
      valorizationCreditNoteNumbers {
        year
        counter
      }
    }
  `,
  configuration: gql`
    fragment CompanyConfigurationFragment on CompanyConfiguration {
      minimumPawnDuration
      gracePeriod
      insuranceFactor
      liquidationFee
      valueAddedTaxRate
      differenceTaxRate
      valorizationFeeRate
      payoutConfigurations {
        payoutLimit
        carPayoutLimit
      }
    }
  `,
  salesConfiguration: gql`
    fragment CompanySalesConfigurationFragment on CompanySalesConfiguration {
      refurbedCommission
      refurbedStaggeredMargins {
        from
        expectedMargin
        minimumMargin
      }
    }
  `,
  unzerConfiguration: gql`
    fragment CompanyUnzerConfigurationFragment on CompanyUnzerConfiguration {
      companyCode
      webhookId
      publicKey
      privateKeyStored
    }
  `,
  dpdAccountInfo: gql`
    fragment DPDAccountInfoFragment on DPDAccountInfo {
      apiEndpoint
      username
      isPasswordStored
      subAccountsInfo {
        username
        shopId
        isPasswordStored
      }
    }
  `,
}
