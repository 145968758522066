import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryGetSearchedCustomersArgs } from '@/schemaTypes'

const GET_SEARCHED_CUSTOMERS = gql`
  query getSearchedCustomers($opts: CustomerSearchOpts!) {
    getSearchedCustomers(opts: $opts) {
      _id
      firstname
      lastname
      email
      customerNumber
    }
  }
`

type TData = { getSearchedCustomers: Query['getSearchedCustomers'] }
type TVariables = QueryGetSearchedCustomersArgs

export function useGetSearchedCustomers(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_SEARCHED_CUSTOMERS, {
    variables: {
      opts: {},
    },
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    customers: queryResult.data?.getSearchedCustomers || [],
  }
}
