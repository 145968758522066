import ItemsListPage from '.'
import React from 'react'
import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import getPagePath from '@/utils/getPagePath'
import ItemDetailsPage from './[itemId]'

function ItemsRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/:itemId')}
        component={ItemDetailsPage}
      />
      <SentryRoute
        path={getPagePath(props, '?bookingNum=:bookingNum')}
        component={ItemsListPage}
      />
      <SentryRoute path={getPagePath(props)} component={ItemsListPage} />
    </Switch>
  )
}

export default ItemsRoutes
