import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationCreateStorageFacilityArgs } from '@/schemaTypes'

const CREATE_STORAGE_FACILITY = gql`
  mutation createStorageFacility(
    $name: String!
    $external: Boolean!
    $address: IAddress!
  ) {
    createStorageFacility(name: $name, external: $external, address: $address) {
      _id
      name
      external
      address {
        ...AddressFragment
      }
    }
  }
  ${fragments.address}
`

type TData = { createStorageFacility: Mutation['createStorageFacility'] }
type TVariables = MutationCreateStorageFacilityArgs

export function useCreateStorageFacility(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createStorageFacility] = useMutation<TData, TVariables>(
    CREATE_STORAGE_FACILITY,
    opts,
  )

  return createStorageFacility
}
