import CompaniesTable from '.'
import React from 'react'
import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import getPagePath from '@/utils/getPagePath'
import CompaniesCreate from './create'
import CompaniesEdit from './edit/[companyId]'

function CompaniesRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/create/:companyId')}
        render={(props) => (
          <CompaniesCreate
            {...props}
            companyId={props.match.params.companyId ?? null}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/edit/:companyId')}
        render={(props) => (
          <CompaniesEdit
            {...props}
            companyId={props.match.params.companyId as string}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/create')}
        render={(props) => <CompaniesCreate {...props} companyId={null} />}
      />
      <SentryRoute path={getPagePath(props)} component={CompaniesTable} />
    </Switch>
  )
}

export default CompaniesRoutes
