import { useAddSecondaryCustomerToDealMutation } from '../../mutations/useAddSecondaryCustomerToDeal'
import { useChangeMainCustomerOfDealMutation } from '../../mutations/useChangeMainCustomerOfDeal'
import { useConnectCustomerToDealMutation } from '../../mutations/useConnectCustomerToDeal'
import { useRemoveSecondaryCustomerFromDealMutation } from '../../mutations/useRemoveSecondaryCustomerFromDeal'
import CustomerSelection from '../CustomerSelection'
import styled from '@emotion/styled'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import { Deal, EBusinessUnit } from '@/schemaTypes'
import { getRegisteredCustomer, isDealVerified } from '@/utils/deal'
import CarDocuments from './CarDocuments'
import CustomerInfoCard from './CustomerInfoCard'
import GuestCustomerInfoCard from './GuestCustomerInfoCard'

enum ECustomerDialogType {
  CHANGE_MAIN_CUSTOMER = 'CHANGE_MAIN_CUSTOMER',
  ADD_SECONDARY_CUSTOMER = 'ADD_SECONDARY_CUSTOMER',
  CONNECT_CUSTOMER_TO_DEAL = 'CONNECT_CUSTOMER_TO_DEAL',
}

interface DealCustomerProps {
  deal: Deal
}

export const CustomerPanel = ({ deal }: DealCustomerProps) => {
  const { t } = useTranslation()
  const { isGuestUser } = useContext(Context)
  const dealVerified = isDealVerified(deal)

  const registeredCustomer = getRegisteredCustomer(deal)

  const [dialogType, setDialogType] = useState(
    ECustomerDialogType.CHANGE_MAIN_CUSTOMER,
  )
  const [visible, setVisible] = useState(false)
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null,
  )

  const [changeMainCustomerOfDeal] = useChangeMainCustomerOfDealMutation()
  const [addSecondaryCustomerToDeal] = useAddSecondaryCustomerToDealMutation()
  const [connectCustomerToDeal] = useConnectCustomerToDealMutation()
  const [removeSecondaryCustomerFromDeal] =
    useRemoveSecondaryCustomerFromDealMutation()

  const handleRemoveSecondaryCustomerButtonClick = (customerId: string) => {
    removeSecondaryCustomerFromDeal({
      variables: {
        dealId: deal?._id,
        selectedCustomerId: customerId,
      },
    })
  }

  const onConfirmCustomerSelection = useCallback(
    (dialogType: string) => {
      if (selectedCustomerId) {
        const onAddSecondaryCustomerToDeal = (customerId: string) => {
          addSecondaryCustomerToDeal({
            variables: {
              dealId: deal?._id,
              selectedCustomerId: customerId,
            },
          })
        }

        const onChangeMainCustomerOfDeal = (customerId: string) => {
          changeMainCustomerOfDeal({
            variables: {
              dealId: deal?._id,
              selectedCustomerId: customerId,
            },
          })
        }

        const onConnectCustomerToDeal = (customerId: string) => {
          connectCustomerToDeal({
            variables: {
              dealId: deal?._id,
              customerId,
            },
          })
        }

        switch (dialogType) {
          case ECustomerDialogType.ADD_SECONDARY_CUSTOMER:
            onAddSecondaryCustomerToDeal(selectedCustomerId)
            break
          case ECustomerDialogType.CHANGE_MAIN_CUSTOMER:
            onChangeMainCustomerOfDeal(selectedCustomerId)
            break
          case ECustomerDialogType.CONNECT_CUSTOMER_TO_DEAL:
            onConnectCustomerToDeal(selectedCustomerId)
            break
          default:
            throw new Error('dialogType is not valid.')
        }
      }
    },
    [
      deal?._id,
      selectedCustomerId,
      addSecondaryCustomerToDeal,
      changeMainCustomerOfDeal,
      connectCustomerToDeal,
    ],
  )

  const footerContent = (
    <div>
      <Button
        label={t('abort')}
        icon="pi pi-times"
        severity="secondary"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button
        label={t('save')}
        icon="pi pi-check"
        onClick={() => {
          setVisible(false)
          onConfirmCustomerSelection(dialogType)
        }}
        autoFocus
      />
    </div>
  )

  return (
    <>
      <div className="flex flex-wrap">
        <div className="flex flex-col mb-6">
          {registeredCustomer ? (
            <>
              <CustomerInfoCard
                customer={registeredCustomer}
                isMainCustomer={true}
                canEditCustomer={true}
                isGuestUser={isGuestUser()}
                isDealVerified={dealVerified}
                onChangeCustomer={() => {
                  setDialogType(ECustomerDialogType.CHANGE_MAIN_CUSTOMER)
                  setVisible(true)
                }}
              />
              <CarDocuments
                isDealVerified={dealVerified}
                deal={deal}
                customer={registeredCustomer}
              />
            </>
          ) : (
            <GuestCustomerInfoCard
              customerEmail={deal.customer.email}
              onConnectCustomerToDealClick={() => {
                setDialogType(ECustomerDialogType.CONNECT_CUSTOMER_TO_DEAL)
                setVisible(true)
              }}
            />
          )}
        </div>

        {deal.businessUnit === EBusinessUnit.Car &&
          (deal.secondaryCustomers?.length ?? 0) > 0 &&
          deal.secondaryCustomers?.map((secondaryCustomer) => {
            return (
              <div className="flex flex-col mb-6">
                <CustomerInfoCard
                  key={secondaryCustomer._id}
                  customer={secondaryCustomer}
                  canEditCustomer={true}
                  isGuestUser={isGuestUser()}
                  isDealVerified={dealVerified}
                  onRemoveSecondaryCustomerFromDeal={
                    handleRemoveSecondaryCustomerButtonClick
                  }
                />
                <CarDocuments
                  isDealVerified={dealVerified}
                  deal={deal}
                  customer={secondaryCustomer}
                />
              </div>
            )
          })}

        {deal.businessUnit === EBusinessUnit.Car && !dealVerified && (
          <AddSecondaryCustomerCard
            onClick={() => {
              setVisible(true)
              setDialogType(ECustomerDialogType.ADD_SECONDARY_CUSTOMER)
            }}
            className="flex justify-center items-center cursor-pointer"
          >
            <div className="flex flex-row">
              <i className="pi pi-plus" />
              <AddAddressText>
                {t('deal.add_secondary_customer')}
              </AddAddressText>
            </div>
          </AddSecondaryCustomerCard>
        )}
      </div>

      <Dialog
        header={t(
          dialogType === 'CHANGE_MAIN_CUSTOMER'
            ? 'deal.change_main_customer'
            : 'deal.add_secondary_customer',
        )}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        draggable={false}
        footer={footerContent}
      >
        <CustomerSelection
          selectedCustomerId={selectedCustomerId}
          setSelectedCustomerId={setSelectedCustomerId}
        />
      </Dialog>
    </>
  )
}

const AddSecondaryCustomerCard = styled.div`
  width: 25rem;
  height: 17.1875rem;
  padding: 0.65625rem 1.09375rem;
  gap: 0.4375rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 3px dashed var(--surface-200, #eee);
  background: var(--surface-0, #fff);
  &:hover,
  &:focus {
    background-color: #fafafa;
  }
  &:active {
    background-color: #fafafa;
  }
`

const AddAddressText = styled.p`
  color: var(--button-secondary-background, #64748b);
  font-family: 'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.05rem;
`
