import styled from '@emotion/styled'
import { Timeline } from 'primereact/timeline'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Deal, EDealChainType } from '@/schemaTypes'

export interface DealsChainProps {
  deal: Deal
}

const DealsChain: FunctionComponent<DealsChainProps> = (props) => {
  const { deal } = props

  const sortedFinalElements = useMemo(
    () =>
      deal.dealChain
        ? deal.dealChain
            .slice()
            .sort((a, b) => b.bookingNumber - a.bookingNumber)
        : [],
    [deal.dealChain],
  )

  const dealEvents = useMemo(() => {
    let array: {
      status: number
      icon?: string
      color?: string
      dealId?: string
    }[] = []

    sortedFinalElements.map((element) => {
      if (element.chainType === EDealChainType.Buyback) {
        return (array = [
          ...array,
          {
            status: element.bookingNumber,
            icon: 'pi pi-sync',
            color: '#439a4f',
          },
        ])
      } else if (element.bookingNumber === deal.bookingNumber) {
        return (array = [
          ...array,
          {
            status: element.bookingNumber,
            icon: 'pi pi-eye',
            color: '#673AB7',
          },
        ])
      } else {
        return (array = [
          ...array,
          { status: element.bookingNumber, dealId: element.dealId },
        ])
      }
    })

    return array
  }, [sortedFinalElements, deal])

  const customizedMarker = useCallback(
    (item) => (
      <>
        {item?.icon ? (
          <IconContainer
            className="flex p-1 items-center justify-center text-white"
            style={{ backgroundColor: item.color }}
          >
            <IconStyled className={item.icon} />
          </IconContainer>
        ) : (
          <div
            className="p-2"
            style={{
              border: '2px solid #6366F1',
              borderRadius: '50%',
              backgroundColor: '#ffffff',
            }}
          />
        )}
      </>
    ),
    [],
  )

  const customizedContent = useCallback(
    (item) => (
      <>
        {item.dealId ? (
          <Link
            style={{ color: 'black' }}
            target={'_blank'}
            to={`/inApp/deals/${item.dealId}`}
          >
            {item.status}
          </Link>
        ) : (
          <p>{item.status}</p>
        )}
      </>
    ),
    [],
  )

  return (
    <DealChainContainer className="overflow-x-auto">
      <TimelineContainer>
        <TimelineStyled
          value={dealEvents}
          layout="horizontal"
          align="top"
          content={customizedContent}
          marker={customizedMarker}
        />
      </TimelineContainer>
    </DealChainContainer>
  )
}

const IconContainer = styled.div`
  border-radius: 0.875rem;
`

const IconStyled = styled.i`
  font-size: 0.8rem;
  padding: 0.2rem;
`
const DealChainContainer = styled.div`
  contain: inline-size;
`
const TimelineContainer = styled.div`
  min-width: 96rem; // 1536px
`

const TimelineStyled = styled(Timeline)`
  &&& {
    .p-timeline-event-separator {
      width: 110px;
    }
    .p-timeline-event {
      width: 110px;
      flex: none;
    }
  }
`

export default DealsChain
