import {
  AnyPaymentType,
  EAutomaticPaymentType,
  ECashBookType,
  EManualPaymentType,
  EQuestionPredictionTag,
  EQuestionType,
  ERoles,
  ESalesPartnerType,
  ETransportMode,
  ItemAnswer,
  ItemAnswerArgs,
  ItemCategory,
  ItemQuestion,
  ProductLegacy,
  ProductVariantPrice,
} from '../schemaTypes'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { isEqual, round } from 'lodash'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Context } from '@/context'
import {
  ANSWER_CUSTOMER_CONTINUE_USING_ITEM_YES,
  EVehicleCategory,
  QUESTION_CUSTOMER_CONTINUE_USING_ITEM_ID,
  QUESTION_OPTICAL_CONDITION,
  USED_OPTICAL_CONDITION_ANSWER,
} from '@/domains/deals/components/constants/enums'
import { EProductPriceMode, Product, ProductVariant } from '@/gql/graphql'

/**
 * Ensures that a date is returned from either a date or a firestore timestamp
 * @param value
 */
export const ensureDate = (value) => {
  if (!value) return value // undefined or null
  if (value.toDate) return value.toDate() // dayjs
  if (value.seconds) return new Date(value.seconds * 1000) // firebase timestamp
  if (!value.getTime) return new Date(value) // iso time string or milliseconds as number
  return value // JS date
}

export function padZeros(value: number, length: number) {
  let padStr = ''

  for (let i = 0; i < length; i++) {
    padStr += '0'
  }

  return value?.toString().padStart(length, padStr)
}

export function timeToHour(time) {
  return `${time.getHours().toString().padStart(2, '0')}:${time
    .getMinutes()
    .toString()
    .padStart(2, '0')}`
}

export function timeToHourTimeSpan(startTime, durationInHours = 1) {
  const endTime = new Date(
    startTime.getTime() + 1000 * 60 * 60 * durationInHours,
  )

  return `${timeToHour(startTime)} - ${timeToHour(endTime)}`
}

export function numberToString(value) {
  if (typeof value === 'number') {
    return value.toString()
  } else {
    return ''
  }
}

export const dateFormat = 'DD.MM.YYYY'

export function printPercentage(percentage) {
  return `${printLocalFloat(percentage * 100)}%`
}

export function printAbsoluteAndTotal(absoluteAmount, totalAmount) {
  return `(${printLocalFloat(absoluteAmount)}/${printLocalFloat(totalAmount)})`
}

export function printDateTimestamp(timestamp) {
  return dayjs(timestamp.seconds * 1000).format('DD.MM.YYYY')
}

export function printLocalFloat(
  number,
  fractionDigits = 2,
  isAmount?: boolean,
) {
  if (typeof number !== 'number') {
    return '-'
  }

  if (isAmount) return round(number, fractionDigits).toFixed(2).toString()
  else return round(number, fractionDigits).toString()
}

/**
 * @deprecated Use displayLocalAmount instead.
 */

export function printLocalAmount(number: number, fractionDigits = 2) {
  return `${printLocalFloat(number, fractionDigits, true)} €`
}

export function printMoneyAmount(number: number, fractionDigits = 2) {
  if (typeof number !== 'number') {
    return '-'
  }

  //  Fix issue display -0.00
  const formattedNumber = Number(
    round(number, fractionDigits).toFixed(2).toString(),
  ).toLocaleString('de-DE', {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  })

  return `${formattedNumber} €`
}

export function displayLocalAmount(number: number, fractionDigits = 2) {
  const options = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }

  try {
    return new Intl.NumberFormat('de-DE', options).format(number)
  } catch (error) {
    console.error('Error formatting number:', error)
    // Fallback if Intl fails (very unlikely in modern browsers)
    return `${number.toFixed(fractionDigits)} €`
  }
}

export function printDateAndTime(date: Date) {
  if (date) {
    return dayjs(date).format('DD.MM.YYYY HH:mm')
  } else {
    return '-'
  }
}

export function printDate(date: Date) {
  if (date) {
    return dayjs(date).format('DD.MM.YYYY')
  } else {
    return '-'
  }
}

export function printInt(number) {
  return typeof number === 'number' ? number.toString() : ''
}

export function formatFuncForPrices(value?: number | null) {
  if (value === null || typeof value === 'undefined') {
    return null
  }

  if (typeof value !== 'number') {
    return null
  }

  return printLocalFloat(value)
}

export function formatFuncForDates(value) {
  if (value) {
    return dayjs(value).format('DD.MM.YYYY')
  } else {
    return '-'
  }
}

export function formatIban(value: string) {
  return value
    .replace(/[^\dA-Z]/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim()
}

export function isCreditNote(value: number) {
  return value <= 0
}

export function valorizationHistoryCompare(a, b) {
  const timeA = a.date ? ensureDate(a.date).getTime() : Number.MAX_VALUE
  const timeB = b.date ? ensureDate(b.date).getTime() : Number.MAX_VALUE
  const billNumberA = a.valorizationBillNumber
  const billNumberB = b.valorizationBillNumber
  const cashflowA = a.cashflow
  const cashflowB = b.cashflow

  if (timeA > timeB) {
    return 1 // timeA is after timeB
  } else if (timeA < timeB) {
    return -1
  }

  // two bills on same day, sort by bill number
  if (cashflowA >= 0 && cashflowB >= 0) {
    const billCountA = billNumberA
      ? parseInt(billNumberA.split('-')[2])
      : Number.MAX_VALUE
    const billCountB = billNumberB
      ? parseInt(billNumberB.split('-')[2])
      : Number.MAX_VALUE

    if (billCountA > billCountB) {
      return 1
    } else {
      return -1
    }
  }

  // two credit notes on same day, sort by credit not number (saved in valorizationBillNumber variable)
  if (cashflowA < 0 && cashflowB < 0) {
    const billCountA = billNumberA
      ? parseInt(billNumberA.split('-')[2])
      : Number.MAX_VALUE
    const billCountB = billNumberB
      ? parseInt(billNumberB.split('-')[2])
      : Number.MAX_VALUE

    if (billCountA > billCountB) {
      return 1
    } else {
      return -1
    }
  }

  if (cashflowA >= 0 && cashflowB < 0) {
    return -1
  }

  return 1
}

export function snakeToCamel(str: string) {
  return str.replace(/(_\w)/g, function (m) {
    return m[1].toUpperCase()
  })
}

export function camelToSnake(str: string) {
  return str
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + '_' + m[1]
    })
    .toLowerCase()
}

export function camelToUpperSnake(str: string) {
  return str
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + '_' + m[1]
    })
    .toUpperCase()
}

export function capitalizeFirstLetter(str) {
  if (!str) return str

  return str.replace(/\b\w/g, (match) => match.toUpperCase())
}

export function isShipmentPickup(pickupType: string) {
  return (
    pickupType === ETransportMode.StandardShipment ||
    pickupType === ETransportMode.PremiumShipment
  )
}

export function transformEmptyStringToNullDeep<T extends Object>(obj: T) {
  const result = obj

  if (obj === null || typeof obj === 'undefined') {
    return null
  }

  Object.keys(result).forEach((key) => {
    if (result[key] === '') {
      result[key] = null
    }
    if (
      typeof result[key] === 'object' &&
      Object.prototype.hasOwnProperty.call(result, key)
    ) {
      result[key] = transformEmptyStringToNullDeep(result[key])
    }
  })

  return result
}

export function stripTypenameDeep<T extends Object>(obj: T | null) {
  if (obj === null || typeof obj === 'undefined') {
    return null
  }

  const result = obj

  Object.keys(result).forEach((key) => {
    if (key === '__typename' && typeof result[key] === 'string') {
      delete result[key]
    }
    if (
      typeof result[key] === 'object' &&
      Object.prototype.hasOwnProperty.call(result, key)
    ) {
      result[key] = stripTypenameDeep(result[key])
    }
  })

  return result
}

export type NestedStateOpts = {
  persistent?: string
  persistOnChange?: boolean
}

/**
 * @deprecated
 */
export type NestedState<T> = {
  values: T
  setValues: Dispatch<SetStateAction<T>>
  setProperty: <K extends keyof T>(key: K, value: T[K]) => void
  getSetProperty: <K extends keyof T>(key: K) => (value: T[K]) => void
  persist: () => void
  hasChanged: boolean
}

/**
 * @deprecated
 */
export function useNestedState<T>(
  initialValue: T,
  opts: NestedStateOpts = {},
): NestedState<T> {
  const { persistent, persistOnChange: persistOnCange } = opts

  const [values, setValues] = useState<T>(
    persistent
      ? () => {
          try {
            const item = window.localStorage.getItem(persistent)
            return item
              ? JSON.parse(item, (key, value) => {
                  if (
                    typeof value === 'string' &&
                    dayjs(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid()
                  ) {
                    return new Date(value)
                  }

                  return value
                })
              : initialValue
          } catch (error) {
            console.log(error)
            return initialValue
          }
        }
      : initialValue,
  )

  // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hasChanged = useMemo(() => !isEqual(initialValue, values), [values])

  const persist = useCallback(() => {
    try {
      if (persistent) {
        setValues((now) => {
          window.localStorage.setItem(persistent, JSON.stringify(now))
          return now
        })
      }
    } catch (error) {
      console.log(error)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (persistOnCange) persist()
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const setProperty = useCallback(<K extends keyof T>(key: K, value: T[K]) => {
    return setValues((old) => ({
      ...old,
      [key]: typeof value === 'function' ? value(old[key] ?? {}) : value,
    }))
  }, [])

  const getSetProperty = useCallback(<K extends keyof T>(key: K) => {
    return (value: T[K]) => {
      setProperty(key, value)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { values, setValues, setProperty, getSetProperty, persist, hasChanged }
}

export function oneOf<T, L extends T[]>(value: T, list: L) {
  return list.includes(value)
}

export const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((v, f) => f(v), x)

export function getStringBetween(value: string, start: string, end: string) {
  return value.substring(
    value.indexOf(start) + start.length,
    value.indexOf(end),
  )
}

export function isVehicle(itemCategoryId: string) {
  return oneOf(itemCategoryId, Object.values(EVehicleCategory))
}

export const isContinueUsingVehicle = (itemCategoryId: string) => {
  return oneOf(itemCategoryId, [
    EVehicleCategory.CAR,
    EVehicleCategory.MOTORCYCLE,
  ])
}

export const isStoredVehicle = (itemCategoryId: string) => {
  return oneOf(itemCategoryId, [
    EVehicleCategory.CAR_STORED,
    EVehicleCategory.MOTORCYCLE_STORED,
  ])
}

export const getMainCategory = (itemCategory: ItemCategory) => {
  const categories = itemCategory.algoliaCategorySlug
    ? itemCategory.algoliaCategorySlug.split(' > ')
    : [itemCategory.name]
  const mainCategory = categories.map((category) => category.trim())[0]
  return mainCategory
}

export const isRequiredSerialNoOrImeiCategory = (
  itemCategory: ItemCategory,
) => {
  const mainCategory = getMainCategory(itemCategory)
  return (
    mainCategory !== 'Uhren' &&
    mainCategory !== 'Schmuck' &&
    mainCategory !== 'Münzen & Barren' &&
    !isVehicle(itemCategory._id)
  )
}

export const isElectronic = (itemCategory: ItemCategory) => {
  const mainCategory = getMainCategory(itemCategory)
  return mainCategory === 'Elektronik'
}

export const isUsedCondition = (
  questionsAndAsnwers: {
    answer: ItemAnswer | undefined
    question: ItemQuestion | undefined
  }[],
) => {
  const opticalConditionQuestion = questionsAndAsnwers.find((c) =>
    isEqual(c.question?.titleKey, QUESTION_OPTICAL_CONDITION),
  )
  return Boolean(
    isEqual(
      opticalConditionQuestion?.answer?.selectedOptionIndex,
      USED_OPTICAL_CONDITION_ANSWER,
    ),
  )
}

export const isShownImei = (itemCategory: ItemCategory): boolean => {
  const categories = itemCategory.algoliaCategorySlug
    ? itemCategory.algoliaCategorySlug.split(' > ')
    : [itemCategory.name]
  const [mainCategory, subCategory] = categories.map((category) =>
    category.trim(),
  )
  return (
    mainCategory === 'Elektronik' &&
    [
      'Smartphones',
      'Tablets',
      'Smartwatch',
      'eBookReader',
      'Computer',
    ].includes(subCategory)
  )
}

export function lowerCaseFirstLetter(value: string) {
  return value[0].toLowerCase() + value.slice(1)
}

export const buildPopupNote = (header: string, note?: string | null) => {
  return `<b>${header}<br/>Note: </b><br/>${note ?? ''}<br/><br/>`
}

export function buildQuestionsDefaultAnswer(args: {
  questions: ItemQuestion[] | undefined | null
  t: Function
  productLegacy?: ProductLegacy
  productVariant?: Pick<ProductVariant, 'id'> & {
    product: {
      price?: Pick<Product['price'], 'mode'> & {
        variants: Pick<ProductVariantPrice, 'variantId' | 'materialMetric'>[]
      }
    }
  }
  hideConditionQuestion?: boolean
}): ItemAnswer[] {
  return (args.questions ?? [])
    .filter(
      (question) =>
        (question.titleKey === QUESTION_OPTICAL_CONDITION &&
          !args.hideConditionQuestion) ||
        question.titleKey !== QUESTION_OPTICAL_CONDITION,
    )
    .map((question) => {
      if (!question)
        throw new Error(
          `${args.t('an_unexpected_error_occured')}. (QUESTION_NOT_FOUND)`,
        )

      const answer: ItemAnswer = {
        questionId: question._id,
      }

      const {
        productLegacy,
        productVariant: { product, id: productVariantId } = {},
      } = args
      if (
        productLegacy?.gewicht &&
        question.predictionTag === EQuestionPredictionTag.Weight
      ) {
        answer.rangeValue = productLegacy.gewicht
      } else if (
        productLegacy?.karat &&
        question.predictionTag === EQuestionPredictionTag.Alloy
      ) {
        answer.rangeValue = productLegacy.karat
      } else if (
        product?.price?.mode === EProductPriceMode.ManualPreciousMetal
      ) {
        const variantPrice = product.price.variants.find(
          (vp) => vp.variantId === productVariantId,
        )

        if (
          variantPrice?.materialMetric &&
          question.predictionTag === EQuestionPredictionTag.Weight
        ) {
          answer.rangeValue = variantPrice.materialMetric.weight
        } else if (
          variantPrice?.materialMetric &&
          question.predictionTag === EQuestionPredictionTag.Alloy
        ) {
          answer.rangeValue = variantPrice.materialMetric.alloy
        }
      }

      if (question.questionType === EQuestionType.SingleChoice) {
        answer.selectedOptionIndex = question.singleChoiceOptions?.findIndex(
          (c) => c.isDefaultValue,
        )
      } else {
        answer.rangeValue =
          answer.rangeValue !== undefined && answer.rangeValue !== null
            ? answer.rangeValue
            : question.rangeData?.defaultValue
      }

      return answer
    })
}

export const getVehicleCategorieId = (
  vehicleCategoryId: EVehicleCategory,
  continueUsing: boolean,
) => {
  switch (vehicleCategoryId) {
    case EVehicleCategory.CAR:
    case EVehicleCategory.CAR_STORED:
      return continueUsing ? EVehicleCategory.CAR : EVehicleCategory.CAR_STORED
    case EVehicleCategory.MOTORCYCLE:
    case EVehicleCategory.MOTORCYCLE_STORED:
      return continueUsing
        ? EVehicleCategory.MOTORCYCLE
        : EVehicleCategory.MOTORCYCLE_STORED
  }
}

export const isCustomerContinueUsingItem = (
  itemAnswers: ItemAnswerArgs[] | ItemAnswer[],
) => {
  if (itemAnswers) {
    const continuUsingAnswer = itemAnswers.find(
      (c) => c.questionId === QUESTION_CUSTOMER_CONTINUE_USING_ITEM_ID,
    )

    if (
      continuUsingAnswer?.selectedOptionIndex ===
      ANSWER_CUSTOMER_CONTINUE_USING_ITEM_YES
    )
      return true
  }

  return false
}

export const manualPaymentTypesConnectedToCashbook = [
  undefined,
  EManualPaymentType.Bank,
  EManualPaymentType.Card,
  EManualPaymentType.Cash,
  EManualPaymentType.Paypal,
  EManualPaymentType.CashOnDelivery,
]

export const salesPartnerNotConnectedToCashbooks = [
  undefined,
  ESalesPartnerType.Refurbed,
]

export const shouldPaymentTypeConnectToCashBook = (
  value: EManualPaymentType | EAutomaticPaymentType | undefined,
) => {
  return (
    manualPaymentTypesConnectedToCashbook as (
      | (typeof manualPaymentTypesConnectedToCashbook)[number]
      | EAutomaticPaymentType
    )[]
  ).includes(value)
}

const shopLevelPaymentTypes = [
  EManualPaymentType.Card,
  EManualPaymentType.Cash,
  EManualPaymentType.Dummy,
]
export function isShopLevelPaymentType(
  paymentType: EManualPaymentType | EAutomaticPaymentType,
) {
  return (
    shopLevelPaymentTypes as (
      | (typeof shopLevelPaymentTypes)[number]
      | EAutomaticPaymentType
    )[]
  ).includes(paymentType)
}

export function isShopLevelCashbookType(cashBookType?: ECashBookType) {
  return (
    cashBookType &&
    [ECashBookType.Card, ECashBookType.Cash].includes(cashBookType)
  )
}

const manualPaymentTypes = Object.values(EManualPaymentType)

export function isManualPaymentType(
  paymentType: EManualPaymentType | EAutomaticPaymentType,
): paymentType is EManualPaymentType {
  return (
    manualPaymentTypes as (
      | (typeof manualPaymentTypes)[number]
      | EAutomaticPaymentType
    )[]
  ).includes(paymentType)
}

export function getDefaultManualPaymentType(
  paymentType: AnyPaymentType | undefined,
): EManualPaymentType {
  const paymentTypeValue = paymentTypeUnion(paymentType)
  if (paymentTypeValue) {
    if (isManualPaymentType(paymentTypeValue)) return paymentTypeValue
    else if (paymentTypeValue === EAutomaticPaymentType.PaypalAutomatic)
      return EManualPaymentType.Paypal
    else return EManualPaymentType.Cash
  } else return EManualPaymentType.Cash
}

// GraphQL setup is messed up, we're not receiving the actual typings from our queries and mutations
type GqlAnyPaymentType =
  | { manual: EManualPaymentType }
  | { automatic: EAutomaticPaymentType }

export function paymentTypeUnion(
  paymentType: AnyPaymentType,
): EManualPaymentType | EAutomaticPaymentType
export function paymentTypeUnion(
  paymentType: AnyPaymentType | undefined | null,
): EManualPaymentType | EAutomaticPaymentType | undefined
export function paymentTypeUnion(
  paymentType: AnyPaymentType | undefined | null,
): EManualPaymentType | EAutomaticPaymentType | undefined {
  if (!paymentType) {
    return undefined
  }

  const actualValue = paymentType as any as GqlAnyPaymentType
  if ('manual' in actualValue) {
    return actualValue.manual
  } else if ('automatic' in actualValue) {
    return actualValue.automatic
  }

  throw new Error(
    'Received AnyPaymentType object missing both manual and automatic key.',
  )
}

export const getCombinations = <T>(args: T[][]) => {
  const results: T[][] = []
  const max = args.length - 1

  const getCombinationsRecursive = (arr, i) => {
    for (let j = 0, l = args[i].length; j < l; j++) {
      const copy = arr.slice(0)

      copy.push(args[i][j])

      if (i === max) {
        results.push(copy)
      } else {
        getCombinationsRecursive(copy, i + 1)
      }
    }
  }

  getCombinationsRecursive([], 0)
  return results
}

export const useIsUserSuperAdmin = () => {
  const { getCurrentUser } = useContext(Context)
  return getCurrentUser()?.roles.includes(ERoles.Superadmin)
}

export interface DownloadFolderZip {
  downloadEntries: { url: string; filename: string; fileExtension: string }[]
  folderName: string
}

export function downloadFilesInZip(
  downloadFolders: DownloadFolderZip[],
  mainFolderName: string,
) {
  const zip = new JSZip()
  const allFolders = downloadFolders.map(async (downloadFolder) => {
    const folder = zip.folder(downloadFolder.folderName)

    const allFiles = downloadFolder.downloadEntries.map(
      (entry) =>
        new Promise<void>((resolve, reject) => {
          const xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = function () {
            const blob = xhr.response
            folder.file(`${entry.filename}.${entry.fileExtension}`, blob)
            resolve()
          }
          xhr.onerror = function (error) {
            reject(error)
          }
          xhr.open('GET', entry.url)
          xhr.send()
        }),
    )

    await Promise.all(allFiles)
      .then(() => {})
      .catch((e) => {
        throw new Error(
          `Error downloading ${downloadFolder.folderName}.zip: ${e}`,
        )
      })
  })

  Promise.all(allFolders).then(() => {
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${mainFolderName}.zip`)
    })
  })
}
