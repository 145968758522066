import dayjs from 'dayjs'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Loading from '@/components/Loading'
import { useSearchPrivateSales } from '@/domains/privateSales/hooks/searchPrivateSales'
import InfiniteScrollTable from '@/redesign/components/InfiniteScrollTable/InfiniteScrollTable'
import SearchInput from '@/redesign/components/SearchInput/SearchInput'
import TableFilterHeader from '@/redesign/components/TableFilterHeade/TableFilterHeader'
import Taskbar from '@/redesign/components/Taskbar/Taskbar'
import { ETransactionType, PrivateSalesFiltersArgs } from '@/schemaTypes'
import { displayLocalAmount } from '@/utils/misc'

const LIMIT = 10
const defaultTableFilterArgs = { paginationArgs: { skip: 0, limit: LIMIT } }

export function PrivateSalesList() {
  const { t } = useTranslation()
  const history = useHistory()

  const [privateSalesFilter, setPrivateSalesFilter] =
    useState<PrivateSalesFiltersArgs>(defaultTableFilterArgs)

  const privateSalesFilterArgs = useMemo(() => {
    return {
      ...privateSalesFilter,
      paginationArgs: { skip: 0, limit: LIMIT },
    }
  }, [privateSalesFilter])

  const { queryResult } = useSearchPrivateSales({
    variables: {
      args: privateSalesFilterArgs,
    },
    nextFetchPolicy: 'cache-first',
  })

  const privateSale = useMemo(
    () =>
      queryResult?.data?.searchPrivateSales?.nodes.map((node) => ({
        ...node,
        privateSaleDate: dayjs(node.privateSaleDate).format('DD.MM.YYYY'),
      })),
    [queryResult?.data],
  )

  const handleSearch = useCallback((event) => {
    const value = event?.target?.value
    setPrivateSalesFilter((prev) => ({
      ...prev,
      search: value,
      ...defaultTableFilterArgs,
    }))
  }, [])

  const tableActionsTemplate = (rowData) => {
    return (
      <Link
        to={`/inApp/privateSales/${rowData._id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button icon="pi pi-external-link" severity="secondary" text />
      </Link>
    )
  }

  const handleClickSale = useCallback(
    ({ value }) => {
      value?._id && history.push(`/inApp/privateSales/${value._id}`)
    },
    [history],
  )

  const loadNextPage = useCallback(async () => {
    const pageInfo = queryResult?.data?.searchPrivateSales?.pageInfo
    const paginationLimit = pageInfo?.limit || LIMIT
    const paginationSkip = (pageInfo?.skip || 0) + paginationLimit

    await queryResult.fetchMore({
      variables: {
        args: {
          ...privateSalesFilter,
          paginationArgs: {
            skip: paginationSkip,
            limit: paginationLimit,
          },
        },
      },
    })
  }, [queryResult, privateSalesFilter])

  return (
    <div className="h-full flex flex-col">
      <TableFilterHeader
        title={t('private_sales')}
        resultsCount={queryResult?.data?.searchPrivateSales.pageInfo.total || 0}
        filterOptions={
          <SearchInput
            placeholder={t('deal.booking_number')}
            value={`${privateSalesFilter?.search || ''}`}
            onChange={handleSearch}
            onClear={() => handleSearch(null)}
          />
        }
      />
      {queryResult.loading ? (
        <Loading />
      ) : (
        <>
          <InfiniteScrollTable
            handleLoadNextPage={loadNextPage}
            dataKey="_id"
            value={privateSale}
            onClickRow={handleClickSale}
            limit={queryResult.data?.searchPrivateSales.pageInfo.limit ?? LIMIT}
            hasNextPage={
              queryResult.data?.searchPrivateSales.pageInfo.hasNextPage ?? true
            }
          >
            <Column
              field="bookingNumber"
              style={{ width: '30px' }}
              header={'#'}
            />
            <Column
              field="status"
              style={{ width: '70px' }}
              header={t('status')}
            />
            <Column
              field="customerFullName"
              style={{ width: '50px' }}
              header={t('customer.label')}
            />
            <Column
              field="carTitle"
              style={{ width: '150px' }}
              header={t('car')}
            />
            <Column
              field="privateSaleDate"
              style={{ width: '35px' }}
              header={t('date')}
            />
            <Column
              style={{ width: '35px' }}
              header={t('private_sale_amount')}
              body={privateSaleAmountTemplate}
            />
            <Column
              style={{ width: '40px', textAlign: 'right' }}
              header={`${t('exceeded')}/${t('shortaged')}`}
              body={dealFinalBalanceTemplate}
            />
            <Column
              style={{ width: '30px', textAlign: 'right' }}
              body={tableActionsTemplate}
            />
          </InfiniteScrollTable>

          <Taskbar>
            <Link to={`/inApp/privatesales/create`}>
              <Button label={t('create_private_sale')} icon="pi pi-plus" />
            </Link>
          </Taskbar>
        </>
      )}
    </div>
  )
}

const privateSaleAmountTemplate = (rowData) => {
  return rowData.privateSaleTransactions.length &&
    rowData.privateSaleTransactions
      .map((c) => c.transactionType)
      .includes(ETransactionType.SellingCar)
    ? displayLocalAmount(
        rowData.privateSaleTransactions.find(
          (c) => c.transactionType === ETransactionType.SellingCar,
        ).amount,
      )
    : '-'
}

const dealFinalBalanceTemplate = (rowData) => {
  const balance = rowData?.dealFinalBalance || 0
  const tagStyle =
    balance > 0
      ? { background: '#22C55E' }
      : balance < 0
        ? { background: '#EF4444' }
        : { background: '#64748B' }

  return <Tag style={tagStyle} value={displayLocalAmount(balance)} />
}

export default PrivateSalesList
