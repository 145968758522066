import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryCheckDealItemsPossiblyStolenArgs } from '@/schemaTypes'

const CHECK_DEAL_ITEMS_POSSIBLY_STOLEN = gql`
  query checkDealItemsPossiblyStolen($dealId: ObjectId!) {
    checkDealItemsPossiblyStolen(dealId: $dealId)
  }
`

type TData = {
  checkDealItemsPossiblyStolen: Query['checkDealItemsPossiblyStolen']
}
type TVariables = QueryCheckDealItemsPossiblyStolenArgs

export function useCheckDealItemsPossiblyStolen(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    CHECK_DEAL_ITEMS_POSSIBLY_STOLEN,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    dealItemsPossiblyStolen: queryResult.data?.checkDealItemsPossiblyStolen,
  }
}
