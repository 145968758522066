import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryGetStorageUnitsArgs } from '@/schemaTypes'

const GET_STORAGE_UNITS = gql`
  query getStorageUnits($opts: StorageUnitOpts) {
    getStorageUnits(opts: $opts) {
      _id
      storageFacilityId
      childIds
      name
      capacity
      isDeleted
      isSecureLocation
    }
  }
`

type TData = { getStorageUnits: Query['getStorageUnits'] }
type TVariables = QueryGetStorageUnitsArgs

export function useGetStorageUnits(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_STORAGE_UNITS, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    storageUnits: queryResult.data?.getStorageUnits || [],
    refreshStorageUnits: queryResult.refetch,
  }
}
