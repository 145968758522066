import Address from '../Address'
import CustomerAddress from '../CustomerAddress'
import {
  Button,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  makeStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { IAddress, ICustomerAddress } from '@/schemaTypes'
import styles from './ExpandableAddress.module.scss'

export interface ExpandableAddressProps {
  title: string
  address?: IAddress | ICustomerAddress
  onChangeAddress(address: IAddress | ICustomerAddress): void
  deleteAddress?: () => void
  isCustomerAddress?: boolean
}

const useStyles = makeStyles((theme) => ({
  previewText: ({ isInvalid }: { isInvalid: boolean }) => ({
    color: isInvalid ? theme.palette.error.main : undefined,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }),
}))

const getAddressPreviewText = (
  t: Function,
  address: ICustomerAddress | IAddress,
) => {
  if (!address.street && !address.zipCode && !address.city)
    return t('address.address_not_set')
  return address.street
    ? `${address.street} ${address.houseNumber}, ${address.zipCode} ${address.city} (${address.countryCode})`
    : t('address.address_is_missing_street')
}

export const ExpandableAddress = (props: ExpandableAddressProps) => {
  const { t } = useTranslation()
  const {
    address = {},
    onChangeAddress,
    title,
    isCustomerAddress,
    deleteAddress,
  } = props
  const classes = useStyles({ isInvalid: !address.street })

  const previewText = getAddressPreviewText(t, address)
  const customerAddress: ICustomerAddress | undefined = isCustomerAddress
    ? (address as ICustomerAddress)
    : undefined

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        className={styles.expansionFlexDirectionColumn}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="additional-actions1-content"
        aria-label="Expand"
      >
        <div className={`${styles.child} o-type-title`}>{title}</div>
        <div className={`${styles.child} o-type-alert ${classes.previewText}`}>
          {previewText}
        </div>
        {customerAddress && deleteAddress && (
          <Fragment>
            <div className={styles.child}>
              <div>
                {t('address.is_billing_address')}
                <Checkbox
                  checked={customerAddress?.isBillingAddress ?? false}
                  onClick={(e) => e.stopPropagation()}
                  onFocus={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    onChangeAddress({
                      ...customerAddress,
                      isBillingAddress: e.target.checked,
                    })
                  }
                />
              </div>
              <div>
                {t('address.is_delivery_address')}
                <Checkbox
                  checked={customerAddress?.isDeliveryAddress ?? false}
                  onClick={(e) => e.stopPropagation()}
                  onFocus={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    onChangeAddress({
                      ...customerAddress,
                      isDeliveryAddress: e.target.checked,
                    })
                  }
                />
              </div>
              <div>
                {t('address.is_registration_address')}
                <Checkbox
                  checked={customerAddress?.isRegistrationAddress ?? false}
                  onClick={(e) => e.stopPropagation()}
                  onFocus={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    onChangeAddress({
                      ...customerAddress,
                      isRegistrationAddress: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div className={styles.child}>
              <Button
                className="u-mr-mini"
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation()
                  deleteAddress()
                }}
                onFocus={(e) => e.stopPropagation()}
              >
                {t('address.delete_address')}
              </Button>
            </div>
          </Fragment>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {customerAddress && deleteAddress ? (
          <CustomerAddress
            address={address as IAddress}
            onChangeAddress={(address) => {
              onChangeAddress(address)
            }}
          />
        ) : (
          <Address
            address={address as IAddress}
            onChangeAddress={(address) => {
              onChangeAddress(address)
            }}
          />
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default ExpandableAddress
