import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import LogoImg from '@/images/logo_01_purple.png'
import EmployeeCompletion from '@/redesign/components/EmployeeCompletion/EmployeeCompletion'

const EmployeeActivation = () => {
  const { t } = useTranslation()

  return (
    <Main className="flex justify-center items-center w-full">
      <div className="flex flex-col items-center w-full">
        <ImageStyled src={LogoImg} alt="Cashy" className="relative mb-12" />
        <Card className="rounded-lg bg-white mx-auto" style={{ maxWidth: 639 }}>
          <h1
            className="text-5xl font-bold text-center mb-2 text-gray-600"
            style={{ fontSize: 35 }}
          >
            {t('welcome')}!
          </h1>
          <h5 className="font-semibold text-md text-center text-gray-600 mb-4">
            {t('activate_employee_desc')}
          </h5>
          <div className="flex items-center justify-center">
            <EmployeeCompletion />
          </div>
        </Card>
      </div>
    </Main>
  )
}

const Main = styled.div`
  height: 100vh;
  background: var(--surface-200, #eee);
`

const ImageStyled = styled.img`
  width: 4.8125rem;
  height: 6.4375rem;
`

const Card = styled.div`
  border-radius: 1.875rem;
  background: var(--root-surface-card, #fff);
  width: 48rem;
  padding: 2rem;
  @media (max-width: 28rem) {
    width: 100%;
    padding: 2rem;
  }
`

export default EmployeeActivation
