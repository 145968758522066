import styled from '@emotion/styled'
import classNames from 'classnames'
import { isEqual } from 'lodash'
import { Button } from 'primereact/button'
import { TreeNode } from 'primereact/treenode'
import {
  TreeSelect,
  TreeSelectCheckboxSelectionKeyType,
} from 'primereact/treeselect'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FilterDropdown from '@/redesign/components/FilterDropdown/FilterDropdown'
import SearchInput from '@/redesign/components/SearchInput/SearchInput'
import { ProductsFiltersArgs } from '@/schemaTypes'

export type ProductsFilters = Omit<
  ProductsFiltersArgs,
  'paginationArgs' | 'isCrawled' | 'categoryIds'
> & {
  isPublished: boolean | undefined
  isCrawled: boolean | undefined
  categoryIds?: { [key: string]: TreeSelectCheckboxSelectionKeyType }
}

interface ProductsFilterProps {
  productFilter: ProductsFilters
  setProductsFilter: React.Dispatch<React.SetStateAction<ProductsFilters>>
  defaultProductsFilter: Pick<ProductsFilters, 'isPublished' | 'isCrawled'>
  itemCategoryOptions: TreeNode[]
}

const ProductsFilter = (props: ProductsFilterProps) => {
  const {
    productFilter,
    setProductsFilter,
    defaultProductsFilter,
    itemCategoryOptions,
  } = props
  const { t } = useTranslation()

  const filterChanged = useMemo(
    () => !isEqual(productFilter, defaultProductsFilter),
    [productFilter, defaultProductsFilter],
  )

  const productStatusOptions = useMemo(
    () =>
      statusOptions.map((option) => ({
        ...option,
        label: t(`products_filter.${option.label}`),
      })),
    [t],
  )

  const productTypeOptions = useMemo(
    () =>
      typeOptions.map((option) => ({
        ...option,
        label: t(`products_filter.${option.label}`),
      })),
    [t],
  )

  const handleProductFilterChange = <T extends keyof ProductsFilters>(
    key: T,
    value: ProductsFilters[T],
  ) => {
    setProductsFilter((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const valueTemplate = useCallback(
    (selectedNodes: TreeNode[]) => {
      if (!selectedNodes || selectedNodes.length === 0) {
        return t('category')
      }

      return selectedNodes.length > 1
        ? `${selectedNodes.length} selected`
        : selectedNodes[0].label
    },
    [t],
  )

  const renderItem = useCallback((node) => <div>{node.label}</div>, [])

  return (
    <div className="flex flex-wrap items-center">
      <div className="w-52 mr-4">
        <SearchInput
          placeholder={t('search')}
          value={productFilter.search ?? ''}
          onChange={({ target }) => {
            handleProductFilterChange('search', target?.value)
          }}
          onClear={() => handleProductFilterChange('search', null)}
        />
      </div>
      <div className="mr-4">
        <FilterDropdown
          showClear
          placeholder={t('status')}
          value={productFilter.isPublished}
          options={productStatusOptions}
          onChange={({ value }) =>
            handleProductFilterChange('isPublished', value)
          }
          className={classNames({
            selected: productFilter.isPublished !== undefined,
          })}
        />
      </div>

      <div className="mr-4">
        <FilterDropdown
          showClear
          placeholder={t('type')}
          value={productFilter.isCrawled}
          options={productTypeOptions}
          onChange={({ value }) =>
            handleProductFilterChange('isCrawled', value)
          }
          className={classNames({
            selected: productFilter.isCrawled !== undefined,
          })}
        />
      </div>
      <div className=" relative">
        <StyledTreeSelect
          placeholder={t('category')}
          value={productFilter.categoryIds}
          options={itemCategoryOptions}
          selectionMode="checkbox"
          display="chip"
          onChange={({ value }) => {
            if (!isCategoryIds(value)) {
              throw new Error(
                'Expected value to be a [key: string]: TreeSelectCheckboxSelectionKeyType',
              )
            }
            handleProductFilterChange('categoryIds', value)
          }}
          nodeTemplate={renderItem}
          valueTemplate={valueTemplate}
          filter
        />
        {Object.keys(productFilter.categoryIds ?? {}).length > 0 && (
          <button
            type="button"
            aria-label="Close"
            data-pc-section="closebutton"
            onClick={() => handleProductFilterChange('categoryIds', {})}
          >
            <i className="pi pi-times absolute cursor-pointer text-[#6c757d] right-[1.4rem] top-[1rem]" />
          </button>
        )}
      </div>
      <StyledButton
        disabled={!filterChanged}
        label={t('clear_filter')}
        icon="pi pi-replay"
        severity="secondary"
        text
        onClick={() => {
          setProductsFilter({
            ...defaultProductsFilter,
            createdBefore: new Date(),
          })
        }}
      />
    </div>
  )
}

const isCategoryIds = (
  value: any,
): value is { [key: string]: TreeSelectCheckboxSelectionKeyType } => {
  return value !== null && typeof value === 'object' && !Array.isArray(value)
}

export enum ProductPublishStatus {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
}

export enum ProductIsCrawled {
  CRAWLED = 'crawled',
  MANUAL = 'manual',
}

const statusOptions = [
  {
    value: true,
    label: ProductPublishStatus.PUBLISHED,
  },
  {
    value: false,
    label: ProductPublishStatus.UNPUBLISHED,
  },
]

const typeOptions = [
  {
    value: true,
    label: ProductIsCrawled.CRAWLED,
  },
  {
    value: false,
    label: ProductIsCrawled.MANUAL,
  },
]

const StyledButton = styled(Button)`
  &&& {
    height: 2.375rem;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.05rem;
    padding: 0.66rem 1.09rem;
  }
`

const StyledTreeSelect = styled(TreeSelect)`
  border: none !important;
  color: #495057;

  &:hover {
    background: #e9ecef;
    color: #495057;
  }

  &.p-inputwrapper-filled {
    border: 1px solid #6366f1 !important;
  }

  .p-treeselect-label {
    padding: 0.75rem !important;
    padding-right: 1.25rem !important;
  }

  .p-treeselect-trigger {
    width: 1.6rem;
  }
`

export default ProductsFilter
