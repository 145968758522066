import { useGetActiveCompanies } from '../../companies/hooks'
import {
  includeHidedCategories,
  useGetItemCategories,
} from '../../itemCategories/hooks'
import { cloneDeep } from '@apollo/client/utilities'
import { Button, Typography } from '@material-ui/core'
import { MenuItem, Select } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Fragment, useState } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ValidFromToDateHourPicker from '@/components/ValidFromToDateHourPicker'
import { useValidationErrorMap } from '@/hooks'
import {
  LoanToValuesEntry,
  MutationCreateLoanToValuesEntryArgs,
} from '@/schemaTypes'
import { LoanToValuesEntryValidation } from '@/validation'
import LoanToValuesJsonTree from './LoanToValuesJsonTree'

export type LoanToValuesEntryValidationErrorObject = Partial<
  Pick<LoanToValuesEntry, 'validFrom'>
>
export interface LoanToValuesEntryValidationError {
  fieldName: keyof LoanToValuesEntryValidationErrorObject
  message: string
}
export interface LoanToValuesDetailsProps {
  loanToValuesEntry: LoanToValuesEntry
  onSave: (loanToValuesEntry: MutationCreateLoanToValuesEntryArgs) => void
}

const setTime = (date: Date, hour: number) => {
  date.setHours(hour)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
}
dayjs.extend(utc)

export function LoanToValuesDetails(props: LoanToValuesDetailsProps) {
  const { t } = useTranslation()

  const [loanToValuesEntry, setLoanToValuesEntry] =
    useState<MutationCreateLoanToValuesEntryArgs>(
      createArgsFromPersisted(props.loanToValuesEntry),
    )
  useEffect(() => {
    setLoanToValuesEntry(createArgsFromPersisted(props.loanToValuesEntry))
  }, [props.loanToValuesEntry])

  const { itemCategories } = useGetItemCategories(includeHidedCategories)

  const { validationErrorsMap, isValid } = useValidationErrorMap(
    loanToValuesEntry,
    LoanToValuesEntryValidation,
  )

  const onDateChange = useCallback(
    (date: Dayjs) => {
      if (!date)
        return setLoanToValuesEntry((prev) => ({ ...prev, validFrom: null }))
      const jsDate = date.toDate()
      setTime(jsDate, 0)
      setLoanToValuesEntry((prev) => ({ ...prev, validFrom: jsDate }))
    },
    [setLoanToValuesEntry],
  )

  const onHourChange = useCallback(
    (hour: number) => {
      setLoanToValuesEntry((prev) => {
        const jsDate = dayjs(prev.validFrom).toDate()
        setTime(jsDate, hour)
        return { ...prev, validFrom: jsDate }
      })
    },
    [setLoanToValuesEntry],
  )

  const onReset = () => {
    setLoanToValuesEntry(createArgsFromPersisted(props.loanToValuesEntry))
  }

  const onSave = () => {
    if (isValid) {
      props.onSave(loanToValuesEntry)
    }
  }

  const { companies } = useGetActiveCompanies()

  return (
    <Fragment>
      <ValidFromToDateHourPicker
        dateLabel={t('loan.valid_from_now_on')}
        timeLabel={t('time')}
        error={validationErrorsMap.validFrom}
        minDate={new Date()}
        value={loanToValuesEntry.validFrom}
        onDateChange={onDateChange}
        onHourChange={onHourChange}
      />
      <Typography variant="h5" gutterBottom>
        {t('loan.enter_loan_to_values_data')}
      </Typography>
      <LoanToValuesJsonTree
        categoryValues={loanToValuesEntry.categoryValues}
        onUpdate={(event) =>
          setLoanToValuesEntry((e) => ({
            ...e,
            categoryValues: event.target.value,
          }))
        }
        categories={itemCategories.map((category) => category.name)}
      />
      <Select
        label={t('company.singular')}
        value={loanToValuesEntry.companyId}
        onChange={(event) =>
          setLoanToValuesEntry((e) => ({ ...e, companyId: event.target.value }))
        }
      >
        {companies.map((c) => (
          <MenuItem value={c._id}>{c.name}</MenuItem>
        ))}
      </Select>
      <Button onClick={onSave} variant="contained" disabled={!isValid}>
        {t('save')}
      </Button>
      <Button onClick={onReset} variant="contained">
        {t('reset')}
      </Button>
    </Fragment>
  )
}

export default LoanToValuesDetails

const createArgsFromPersisted = (
  loanToValuesEntry: LoanToValuesEntry,
): MutationCreateLoanToValuesEntryArgs => ({
  companyId: loanToValuesEntry.company._id,
  validFrom: loanToValuesEntry.validFrom,
  categoryValues: cloneDeep(loanToValuesEntry.categoryValues),
})
