import { Box, Collapse } from '@material-ui/core'
import * as React from 'react'
import { FunctionComponent, useState } from 'react'
import { Scalars } from '@/schemaTypes'
import styles from './ExpansionBox.module.scss'

export interface ExpansionBoxProps {
  title: string
  style?: React.HTMLAttributes<HTMLDivElement>['style']
  name?: string
  openAtLoad: Scalars['Boolean']
  onClickBox: (value: boolean, name: string) => void
}

// For keeping the state you should use nestedUseState exactly like DealDetails/ItemBlock
const ExpansionBox: FunctionComponent<ExpansionBoxProps> = (props) => {
  const { title, name, openAtLoad, onClickBox, style } = props
  const [open, setOpen] = useState(openAtLoad)

  const handleClick = () => {
    // This is for when we want to keep the state on refresh
    if (name) onClickBox(!openAtLoad, name)
    // This is for when we use the expansion box normally and we do not want to keep the state
    else setOpen(!open)
  }

  return (
    <div className={styles.mainDiv} style={style}>
      <div className={styles.title} onClick={() => handleClick()}>
        {title}
      </div>
      <div className={styles.content}>
        <Collapse in={name ? openAtLoad : open} timeout="auto" unmountOnExit>
          <Box margin={1}>{props.children}</Box>
        </Collapse>
      </div>
    </div>
  )
}

export default ExpansionBox
