import { FunctionComponent } from 'react'

export interface DefaultActionWrapperProps {
  onEnterKeyUp?
  onEscapeKeyUp?
}

const DefaultActionWrapper: FunctionComponent<DefaultActionWrapperProps> = (
  props,
) => {
  const { onEnterKeyUp, onEscapeKeyUp } = props

  const onKeyUp = (event) => {
    if (event.key === 'Enter') {
      if (onEnterKeyUp) onEnterKeyUp()
    } else if (event.key === 'Escape') {
      if (onEscapeKeyUp) onEscapeKeyUp()
    }
  }

  return <div onKeyUp={onKeyUp}>{props.children}</div>
}

export default DefaultActionWrapper
