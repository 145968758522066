import Loading from '../Loading'
import { QueryResult } from '@apollo/client'
import styled from '@emotion/styled'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { ListOnScrollProps, VariableSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { HistoryFilters, useHistoryState } from '@/hooks/useHistoryState'
import { PageInfoSkipLimit } from '@/schemaTypes'

interface Paginated<T> {
  nodes: T[]
  pageInfo: PageInfoSkipLimit
}

interface ChildrenProps<T> {
  index: number
  data: T
  rowRef: React.RefObject<unknown>
  isScrolling?: boolean
  style: object
}

export interface InfiniteScrollSkipLimitProps<
  TDataKey extends string,
  TData extends { [key in TDataKey]: Paginated<unknown> },
> {
  height: number // height of list
  width: number | string // width of list
  itemSize: number // itemSize of children
  gutterSize?: number // padding between children
  threshold?: number // after x childs, we will call load next page again
  customChildrenStyle?: Record<string, any>
  queryResult: QueryResult<TData>
  dataKey: TDataKey
  isBackWardPagination?: boolean
  children: (props: ChildrenProps<TData[TDataKey]['nodes']>) => React.ReactNode
}

const InfiniteScrollSkipLimit = <
  TDataKey extends string,
  TData extends { [key in TDataKey]: Paginated<unknown> },
>({
  children,
  height,
  width,
  itemSize,
  gutterSize = 10,
  queryResult,
  dataKey,
  threshold = 5,
  customChildrenStyle,
  isBackWardPagination = false,
}: InfiniteScrollSkipLimitProps<TDataKey, TData>) => {
  const { historyState, setHistory } = useHistoryState<HistoryFilters>()
  const listRef = useRef<VariableSizeList>(null)
  const rowHeights = useRef(historyState?.scroll?.rowHeights || {})
  const loadingRef = useRef(false)
  const destroyStateRef = useRef(false)
  const items = useMemo(
    () => (queryResult.data ? queryResult.data[dataKey].nodes : []),
    [dataKey, queryResult.data],
  )
  const pageInfo = queryResult.data
    ? queryResult.data[dataKey].pageInfo
    : undefined
  const hasNextPage = pageInfo?.hasNextPage

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length
  const onScroll = useMemo(
    () =>
      debounce((props: ListOnScrollProps) => {
        if (destroyStateRef.current) return
        setHistory({
          scroll: {
            offset: props.scrollOffset,
            items: items.length,
            rowHeights: { ...rowHeights?.current },
          },
        })
      }, 1000),
    [setHistory, items.length],
  )

  const loadNextPage = useCallback(() => {
    const paginationSkip = (pageInfo?.skip || 0) + (pageInfo?.limit || 10)
    const paginationLimit = pageInfo?.limit || 10

    queryResult.fetchMore({
      variables: {
        args: {
          ...queryResult.variables?.args,
          paginationArgs: {
            skip: paginationSkip,
            limit: paginationLimit,
          },
        },
      },
    })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult, pageInfo, isBackWardPagination])

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = useCallback(async () => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true
    try {
      await loadNextPage()
    } finally {
      loadingRef.current = false
    }
  }, [loadNextPage])

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < items.length,
    [hasNextPage, items],
  )

  const getRowHeight = (index) => {
    return rowHeights.current[index] + 10 || itemSize
  }

  const setRowHeight = (index, size) => {
    listRef.current?.resetAfterIndex(0)
    rowHeights.current = { ...rowHeights.current, [index]: size }
  }

  // Render an item or a loading indicator.
  const Item = (props) => {
    const rowRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(props.index, rowRef.current.clientHeight + gutterSize)
      }
      // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!isItemLoaded(props.index)) {
      return <Loading />
    } else {
      return (
        <div
          key={props.index}
          style={{
            ...props.style,
            padding: gutterSize,
            ...(customChildrenStyle ?? {}),
          }}
        >
          {children({ ...props, rowRef })}
        </div>
      )
    }
  }

  useEffect(() => {
    return () => {
      destroyStateRef.current = true
    }
  }, [])

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={threshold}
    >
      {({ onItemsRendered }) => (
        <CustomVariableSizeList
          initialScrollOffset={historyState?.scroll?.offset}
          onScroll={onScroll}
          height={height}
          itemCount={itemCount}
          itemSize={getRowHeight}
          itemData={items}
          onItemsRendered={onItemsRendered}
          ref={listRef}
          width={width}
        >
          {(props) => Item(props)}
        </CustomVariableSizeList>
      )}
    </InfiniteLoader>
  )
}

const CustomVariableSizeList = styled(VariableSizeList)`
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export default InfiniteScrollSkipLimit
