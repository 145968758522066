import { graphql } from '@/gql'

export const CreateCustomerBlacklist_Mutation = graphql(`
  mutation createCustomerBlacklist($args: CustomerBlacklistCreateArgs!) {
    createCustomerBlacklist(args: $args) {
      _id
    }
  }
`)

export const UpdateCustomerBlacklist_Mutation = graphql(`
  mutation updateCustomerBlacklist($args: CustomerBlacklistUpdateArgs!) {
    updateCustomerBlacklist(args: $args) {
      _id
    }
  }
`)

export const DeleteCustomerBlacklist_Mutation = graphql(`
  mutation deleteCustomerBlacklist($args: CustomerBlacklistDeleteArgs!) {
    deleteCustomerBlacklist(args: $args)
  }
`)

export const GetActiveCustomers_Query = graphql(`
  query getActiveCustomers($opts: CustomerOpts) {
    getActiveCustomers(opts: $opts) {
      _id
      email
      firstname
      lastname
      dateOfBirth
    }
  }
`)

export const GetExistingCustomerBlacklists_Query = graphql(`
  query getExistingCustomerBlacklists($args: CustomerBlacklistFiltersArgs!) {
    getExistingCustomerBlacklists(args: $args) {
      _id
    }
  }
`)

export const SearchCustomerBlacklists_Query = graphql(`
  query searchCustomerBlacklists($args: CustomerBlacklistsFiltersArgs!) {
    searchCustomerBlacklists(args: $args) {
      nodes {
        ...CustomerBlacklist
      }

      pageInfo {
        limit
        skip
        hasPrevPage
        hasNextPage
        total
      }
    }
  }
`)

export const CustomerBlacklistFragment = graphql(`
  fragment CustomerBlacklist on CustomerBlacklist {
    _id
    email
    firstname
    lastname
    dateOfBirth
    reason
    createdAt
    customers {
      _id
      firstname
      lastname
    }
    employee {
      _id
      firstname
      lastname
    }
  }
`)

export const GetCustomerBlacklistById_Query = graphql(`
  query getCustomerBlacklistById($id: ObjectId!) {
    getCustomerBlacklistById(id: $id) {
      ...CustomerBlacklist
    }
  }
`)
