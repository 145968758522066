import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateItemQuestionArgs } from '@/schemaTypes'

const UDPATE_ITEM_QUESTION = gql`
  mutation updateItemQuestion(
    $validTo: DateTime!
    $validFrom: DateTime!
    $_oldItemQuestionIds: [String!]
    $order: Float!
    $questionType: EQuestionType!
    $valueMode: EQuestionValueMode
    $titleKey: String!
    $infoKey: String
    $isInternal: Boolean
    $predictionTag: EQuestionPredictionTag
    $itemCategoryIds: [ObjectId!]!
    $_id: ObjectId!
    $singleChoiceOptions: [ItemQuestionOptionArgs!]
    $rangeData: ItemQuestionRangeDataArgs
    $isDeactivatedForNewProducts: Boolean
  ) {
    updateItemQuestion(
      validTo: $validTo
      validFrom: $validFrom
      _oldItemQuestionIds: $_oldItemQuestionIds
      order: $order
      questionType: $questionType
      valueMode: $valueMode
      titleKey: $titleKey
      infoKey: $infoKey
      isInternal: $isInternal
      predictionTag: $predictionTag
      itemCategoryIds: $itemCategoryIds
      singleChoiceOptions: $singleChoiceOptions
      rangeData: $rangeData
      _id: $_id
      isDeactivatedForNewProducts: $isDeactivatedForNewProducts
    ) {
      _id
      validFrom
      validTo
      _oldItemQuestionIds
      order
      questionType
      valueMode
      titleKey
      infoKey
      itemCategoryIds
      predictionTag
      isDeactivatedForNewProducts
      singleChoiceOptions {
        labelKey
        infoKey
        categoryValues
        conditionValue
        isDefaultValue
      }
      rangeData {
        minValue
        maxValue
        defaultValue
        unitPrice
        unitKey
      }
    }
  }
`

type TData = { updateItemQuestion: Mutation['updateItemQuestion'] }
type TVariables = MutationUpdateItemQuestionArgs

export function useUpdateItemQuestion(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateItemQuestion] = useMutation<TData, TVariables>(
    UDPATE_ITEM_QUESTION,
    opts,
  )

  return updateItemQuestion
}
