import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationEnablePostalCodesArgs } from '@/schemaTypes'

const ENABLE_POSTAL_CODES = gql`
  mutation enablePostalCodes($postalCodes: [String!]!, $companyId: ObjectId!) {
    enablePostalCodes(postalCodes: $postalCodes, companyId: $companyId) {
      _id
      postalName
      postalCode
      country
    }
  }
`

type TData = { enablePostalCodes: Mutation['enablePostalCodes'] }
type TVariables = MutationEnablePostalCodesArgs

export function useEnablePostalCodes(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [enablePostalCodes] = useMutation<TData, TVariables>(
    ENABLE_POSTAL_CODES,
    opts,
  )

  return enablePostalCodes
}
