import FormikInput from '../FormikFields/FormikInput/FormikInput'
import FormikTextarea from '../FormikFields/FormikTextarea/FormikTextarea'
import { find } from 'lodash'
import React from 'react'
import {
  BooleanCustomDealFormQuestion,
  CustomDealFormQuestion,
  CustomDealFormQuestionCreateArgs,
  ECustomDealFormQuestionType,
  ELanguageCode,
  FormAnswer,
  MultipleLanguageTextArgs,
  NumberDefaultCustomDealFormQuestion,
  NumberOptionsCustomDealFormQuestion,
  TextDefaultCustomDealFormQuestion,
  TextOptionsCustomDealFormQuestion,
} from '@/schemaTypes'
import { ItemFormBooleanAnswer } from './ItemFormBooleanAnswer'
import {
  ItemFormSelectNumberAnswer,
  ItemFormSelectTextAnswer,
} from './ItemFormSelectAnswer'

export type NewFormAnswer =
  | {
      type:
        | ECustomDealFormQuestionType.NumberDefault
        | ECustomDealFormQuestionType.NumberOptions
      value?: number
      customValue?: number | string
      question:
        | NumberDefaultCustomDealFormQuestion
        | NumberOptionsCustomDealFormQuestion
    }
  | {
      type:
        | ECustomDealFormQuestionType.TextDefault
        | ECustomDealFormQuestionType.TextOptions
      value: string
      question:
        | TextDefaultCustomDealFormQuestion
        | TextOptionsCustomDealFormQuestion
    }
  | {
      type: ECustomDealFormQuestionType.Boolean
      value: boolean
      question: BooleanCustomDealFormQuestion
    }

interface ItemFormAnswersProps {
  formAnswers: FormAnswer[]
  isEditable?: boolean
  onChangeAnswer?: (args: {
    index: number
    newFormAnswer: NewFormAnswer
    allowEnterAnswer?: boolean
  }) => void
}

const ItemFormAnswer = ({
  formAnswer,
  index,
  isEditable,
  onChangeAnswer,
}: {
  formAnswer: FormAnswer
  isEditable?: boolean
  index: number
  onChangeAnswer?: (args: {
    index: number
    newFormAnswer: NewFormAnswer
    allowEnterAnswer?: boolean
  }) => void
}) => {
  const { question } = formAnswer

  return (
    <div className="flex">
      {formAnswer.__typename === 'FormOptionNumberAnswer' ? (
        <ItemFormSelectNumberAnswer
          formAnswer={formAnswer}
          disabled={!isEditable}
          onChange={(newFormAnswer, allowEnterAnswer) =>
            onChangeAnswer?.({ index, newFormAnswer, allowEnterAnswer })
          }
          label={question.label.text}
        />
      ) : formAnswer.__typename === 'FormOptionTextAnswer' ? (
        <ItemFormSelectTextAnswer
          formAnswer={formAnswer}
          disabled={!isEditable}
          onChange={(newFormAnswer, allowEnterAnswer) =>
            onChangeAnswer?.({ index, newFormAnswer, allowEnterAnswer })
          }
          label={question.label.text}
        />
      ) : formAnswer.__typename === 'FormBooleanAnswer' ? (
        <ItemFormBooleanAnswer
          formAnswer={formAnswer}
          disabled={!isEditable}
          onChange={(newFormAnswer) =>
            onChangeAnswer?.({ index, newFormAnswer })
          }
          label={question.label.text}
        />
      ) : formAnswer.__typename === 'FormMultilineTextAnswer' ? (
        <FormikTextarea
          name={`formAnswers[${index}].textMultilineAnswer`}
          label={question.label.text}
          className="flex flex-col md:flex-row items-start mb-2 mr-2"
          labelClassName="text-sm font-semibold w-12.5 pt-1.5"
          inputContainerClassName="w-full md:w-96"
          rows={4}
          cols={20}
        />
      ) : formAnswer.__typename === 'FormTextAnswer' ||
        formAnswer.__typename === 'FormNumberAnswer' ? (
        <FormikInput
          name={
            formAnswer.__typename === 'FormTextAnswer'
              ? `formAnswers[${index}].textAnswer`
              : `formAnswers[${index}].numberAnswer`
          }
          type={
            question.type === ECustomDealFormQuestionType.NumberDefault
              ? 'number'
              : 'text'
          }
          label={question.label.text}
          className="flex flex-col md:flex-row items-start md:items-center mb-2"
          labelClassName="text-sm font-semibold w-12.5"
          inputContainerClassName="w-52"
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            switch (question.__typename) {
              case 'NumberDefaultCustomDealFormQuestion':
                onChangeAnswer?.({
                  index,
                  newFormAnswer: {
                    type: ECustomDealFormQuestionType.NumberDefault,
                    value: +e.target.value,
                    question,
                  },
                })
                break
              case 'TextDefaultCustomDealFormQuestion':
                onChangeAnswer?.({
                  index,
                  newFormAnswer: {
                    type: ECustomDealFormQuestionType.TextDefault,
                    value: e.target.value,
                    question,
                  },
                })
                break
            }
          }}
        />
      ) : null}
    </div>
  )
}

export const ItemFormAnswers = ({
  formAnswers,
  isEditable = true,
  onChangeAnswer,
}: ItemFormAnswersProps) => {
  return (
    <>
      {formAnswers.map((formAnswer, i) => (
        <ItemFormAnswer
          key={i}
          formAnswer={formAnswer}
          index={i}
          isEditable={isEditable}
          onChangeAnswer={onChangeAnswer}
        />
      ))}
    </>
  )
}

export const checkIsCustomDealFormQuestion = (
  question: CustomDealFormQuestion | CustomDealFormQuestionCreateArgs,
): question is CustomDealFormQuestion => {
  return 'text' in question.label
}

export const getQuestionLabel = (
  label: MultipleLanguageTextArgs[],
  language: ELanguageCode,
) => find(label, { languageCode: language })?.text ?? ''
