import ItemCategoriesTable from '.'
import React from 'react'
import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import getPagePath from '@/utils/getPagePath'
import ItemCategoriesCreate from './create'
import ItemCategoriesEdit from './edit/[itemCategoryId]'

export function ItemCategoriesRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/create/:itemCategoryId')}
        render={(props) => (
          <ItemCategoriesCreate
            {...props}
            itemCategoryId={props.match.params.itemCategoryId as string}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/edit/:itemCategoryId')}
        render={(props) => (
          <ItemCategoriesEdit
            {...props}
            itemCategoryId={props.match.params.itemCategoryId as string}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/create')}
        render={(props) => (
          <ItemCategoriesCreate {...props} itemCategoryId={null} />
        )}
      />
      <SentryRoute path={getPagePath(props)} component={ItemCategoriesTable} />
    </Switch>
  )
}

export default ItemCategoriesRoutes
