import { TableCell, TableRow, makeStyles } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopy from '@material-ui/icons/FileCopy'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { LoanToValuesEntry } from '@/schemaTypes'
import globalStyles from '@/styles/global.module.scss'

export interface LoanToValuesRowProps {
  company: { name: string }
  loanToValuesEntry: LoanToValuesEntry
  deleteLoanToValuesEntry: () => void
}

const useStyles = makeStyles({
  validFromCell: {
    minWidth: '180px',
  },
  chipCell: {
    minWidth: '250px',
  },
  chip: {
    borderRadius: '2px',
  },
  json: {
    fontSize: '0.75rem',
    whiteSpace: 'normal',
  },
})

const LoanToValuesRow = (props: LoanToValuesRowProps) => {
  const { loanToValuesEntry } = props
  const id = loanToValuesEntry._id ?? ''
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.validFromCell}>
        {dayjs(loanToValuesEntry.validFrom).format('DD.MM.YYYY HH:mm')}
      </TableCell>
      <TableCell className={classes.chipCell}>{props.company.name}</TableCell>
      <TableCell align="right">
        <pre className={classes.json}>
          {JSON.stringify(loanToValuesEntry.categoryValues, null, 1)}
        </pre>
      </TableCell>
      <TableCell>
        <Link to={`/inApp/configurations/loanToValues/create/${id}`}>
          <FileCopy />
        </Link>
        <DeleteIcon
          className={globalStyles.clickable}
          onClick={props.deleteLoanToValuesEntry}
        />
      </TableCell>
    </TableRow>
  )
}

export default LoanToValuesRow
