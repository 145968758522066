import { Button, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import NavigateBeforeSharpIcon from '@material-ui/icons/NavigateBeforeSharp'
import NavigateNextSharpIcon from '@material-ui/icons/NavigateNextSharp'
import queryString from 'query-string'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Loading from '@/components/Loading'
import { useGetDeals } from '@/domains/deals/hooks/getDeals'
import { useGetDealsCount } from '@/domains/deals/hooks/getDealsCount'
import { Deal, EDealType, EOrderBy } from '@/schemaTypes'
import styles from './PrivateSaleDealsList.module.scss'

const TAKE: number = 15

interface PrivateDealsListProps {
  suggestedDeals: Deal[]
  selectedDealId
  setSelectedDealId
}

const takeCounter = 1
export function PrivateSaleDealsList(props: PrivateDealsListProps) {
  const { suggestedDeals, selectedDealId, setSelectedDealId } = props
  const { t } = useTranslation()
  const location = useLocation()

  const params = queryString.parse(location.search)
  const [skip, setSkip] = useState(
    !Number.isNaN(parseInt(params.skip as string)) ||
      parseInt(params.skip as string) > 0
      ? parseInt(params.skip as string)
      : 0,
  )
  const [currentCount, setCurrentCount] = useState<number | undefined>(
    skip ? skip : 1,
  )

  const { deals, queryResult } = useGetDeals({
    variables: {
      opts: {
        filter: {
          dealType: EDealType.Pawn,
          ids: suggestedDeals.map((c) => c._id),
        },
        order: { _id: EOrderBy.Desc },
        take: TAKE,
        skip: skip,
      },
    },
  })

  // An addition to know the total of the Deals
  const { dealsTotal } = useGetDealsCount({
    variables: {
      opts: {
        filter: {
          dealType: EDealType.Pawn,
          ids: suggestedDeals.map((c) => c._id),
        },
      },
    },
  })

  const _onNext = () => {
    if (deals && deals.length > 0) {
      setSkip((old) => old + TAKE)
      currentCount && setCurrentCount(skip + 1)
    }
  }

  const _onPrev = () => {
    if (deals && deals.length > 0) {
      setSkip((old) => (old - TAKE < 0 ? 0 : old - TAKE))
      currentCount && setCurrentCount(skip >= 2 ? skip - 1 : 1)
    }
  }

  const takeCounterChecker = takeCounter ? takeCounter - TAKE <= 0 : false

  const previousDisabled =
    (Number.isNaN(skip) || skip - 1 <= 0) && takeCounterChecker
  const nextDisabled = dealsTotal ? skip + TAKE >= dealsTotal : false

  const onAddDeal = (bookingNumber) => {
    const tempDeal = deals.filter((c) => c.bookingNumber === bookingNumber)
    setSelectedDealId(tempDeal[0]._id)

    // this is used just for re-rendering
  }

  const onRemoveDeal = () => {
    setSelectedDealId(undefined)
  }

  return (
    <div className={`${styles.root} u-p`}>
      <div className={`${styles.inlineDivs}`} style={{ width: '65%' }}>
        <div>
          <Typography
            variant="h5"
            gutterBottom
            classes={{ root: styles.headTitle }}
          >
            {t('deal.plural')}
          </Typography>
          <Paper>
            {queryResult.loading ? (
              <Loading />
            ) : (
              <div style={{ overflowY: 'scroll', height: '35.5rem' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        classes={{ root: styles.headTitleTable }}
                        align="left"
                      >
                        #
                      </TableCell>
                      <TableCell
                        classes={{ root: styles.headTitleTable }}
                        align="left"
                      >
                        {t('deal.deal_types')}
                      </TableCell>
                      <TableCell
                        classes={{ root: styles.headTitleTable }}
                        align="left"
                      >
                        {t('status')}
                      </TableCell>
                      <TableCell
                        classes={{ root: styles.headTitleTable }}
                        align="left"
                      >
                        {t('shop.label')}
                      </TableCell>
                      <TableCell
                        classes={{ root: styles.headTitleTable }}
                        align="left"
                      >
                        {t('company.singular')}
                      </TableCell>
                      <TableCell
                        classes={{ root: styles.headTitleTable }}
                        align="center"
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deals
                      .filter((c) => c._id !== selectedDealId)
                      .map((deal) => {
                        return (
                          <TableRow key={deal._id}>
                            <TableCell align="left">
                              {deal.bookingNumber}
                            </TableCell>
                            <TableCell align="left">
                              {deal ? deal.dealType : ''}
                            </TableCell>
                            <TableCell align="left">
                              {deal
                                ? deal.events
                                  ? deal.events[deal.events.length - 1]
                                      .displayName
                                  : '-'
                                : ''}
                            </TableCell>
                            <TableCell align="left">
                              {deal.shop?.name}
                            </TableCell>
                            <TableCell align="left">
                              {deal.company.name}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                className={`${styles.addButton}`}
                                onClick={() => onAddDeal(deal.bookingNumber)}
                              >
                                <DoubleArrowIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </div>
            )}
          </Paper>
        </div>
      </div>
      <div className={`${styles.inlineDivs}`} style={{ width: '32%' }}>
        <div>
          <Typography
            variant="h5"
            gutterBottom
            classes={{ root: styles.headTitle }}
          >
            {t('auction.auction_selected_deals')}
          </Typography>
          <Paper>
            <div style={{ overflowY: 'scroll', height: '35.5rem' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      classes={{ root: styles.headTitleTable }}
                      align="left"
                    >
                      #
                    </TableCell>
                    <TableCell
                      classes={{ root: styles.headTitleTable }}
                      align="left"
                    >
                      {t('auction.auction_deal_id')}
                    </TableCell>
                    <TableCell
                      classes={{ root: styles.headTitleTable }}
                      align="center"
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deals
                    .filter((c) => c._id === selectedDealId)
                    .map((deal) => {
                      return (
                        <TableRow key={deal._id}>
                          <TableCell align="left">
                            {deal.bookingNumber}
                          </TableCell>
                          <TableCell align="left">{deal._id}</TableCell>

                          <TableCell align="center">
                            <Button
                              className={`${styles.addButton}`}
                              onClick={onRemoveDeal}
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </div>
          </Paper>
        </div>
      </div>

      <div className="u-12/12 u-flex u-jc-sb">
        <Button
          classes={{ root: styles.buttonPrev }}
          onClick={_onPrev}
          variant="contained"
          disabled={previousDisabled}
        >
          <NavigateBeforeSharpIcon classes={{ root: styles.navIcon }} />{' '}
          {t('previous')}
        </Button>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            paddingBottom: '0.375rem',
          }}
        >
          <span
            className={`${styles.paginationText}`}
          >{`${!!dealsTotal ? skip / TAKE + 1 + ' / ' + Math.ceil(dealsTotal / TAKE) : ' '} `}</span>
        </div>
        <Button
          classes={{ root: styles.buttonNext }}
          onClick={_onNext}
          variant="contained"
          disabled={nextDisabled}
        >
          {t('next')}{' '}
          <NavigateNextSharpIcon classes={{ root: styles.navIcon }} />
        </Button>
      </div>
    </div>
  )
}

export default PrivateSaleDealsList
