import EmployeesTable from '.'
import React from 'react'
import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import getPagePath from '@/utils/getPagePath'
import EmployeesChangePassword from './changeEmployeePassword/[employeeId]'
import EmployeesEdit from './edit/[employeeId]'

export function EmployeesRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/changeEmployeePassword/:employeeId')}
        render={(props) => (
          <EmployeesChangePassword
            {...props}
            employeeId={props.match.params.employeeId as string}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/edit/:employeeId')}
        render={(props) => (
          <EmployeesEdit
            {...props}
            employeeId={props.match.params.employeeId as string}
          />
        )}
      />
      <SentryRoute path={getPagePath(props)} component={EmployeesTable} />
    </Switch>
  )
}

export default EmployeesRoutes
