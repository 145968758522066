import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeleteAuctionArgs } from '@/schemaTypes'

const DELETE_AUCTION = gql`
  mutation deleteAuction(
    $args: AuctionDeleteArgs!
    $shouldNotifyCustomers: Boolean!
  ) {
    deleteAuction(args: $args, shouldNotifyCustomers: $shouldNotifyCustomers) {
      _id
    }
  }
`

type TData = { deleteAuction: Mutation['deleteAuction'] }
type TVariables = MutationDeleteAuctionArgs

export function useDeleteAuction(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deleteAuction] = useMutation<TData, TVariables>(DELETE_AUCTION, opts)
  return deleteAuction
}
