import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { paymentFragments } from '@/hooks/fragments'
import { Mutation, MutationUpdatePayoutDataArgs } from '@/schemaTypes'

const UPDATE_PAYOUT_DATA = gql`
  mutation updatePayoutData($dealId: ObjectId!, $args: PaymentDataArgs!) {
    updatePayoutData(dealId: $dealId, args: $args) {
      _id
      payoutData {
        paymentType {
          ...AnyPaymentTypeFragment
        }
        iban
        paypalAddress
      }
    }
  }
  ${paymentFragments.anyPaymentType}
`

type TData = { updatePayoutData: Mutation['updatePayoutData'] }
type TVariables = MutationUpdatePayoutDataArgs

export function useUpdatePayoutData(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updatePayoutData] = useMutation<TData, TVariables>(
    UPDATE_PAYOUT_DATA,
    opts,
  )

  return updatePayoutData
}
