import { FieldHelperProps } from 'formik'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { GetActiveCustomersQuery } from '@/schemaTypes'

interface CheckMatchingCustomersDialogProps {
  showCheckMatchingCustomersDialog: boolean
  setShowCheckMatchingCustomersDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >
  customerIds: Array<string>
  matchingCustomers: GetActiveCustomersQuery['getActiveCustomers']
  customerIdsFieldHelpers: FieldHelperProps<unknown>
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
}

export const CheckMatchingCustomersDialog = ({
  showCheckMatchingCustomersDialog,
  setShowCheckMatchingCustomersDialog,
  matchingCustomers,
  customerIds,
  customerIdsFieldHelpers,
  handleSubmit,
}: CheckMatchingCustomersDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog
      header={t('matching_customers')}
      visible={showCheckMatchingCustomersDialog}
      style={{ width: '50vw' }}
      onHide={() => {
        setShowCheckMatchingCustomersDialog(false)
      }}
    >
      <p className="m-0">{t('select_customer_accounts')}</p>

      <div className="mt-3">
        {matchingCustomers.map((customer, index) => (
          <div className="flex flex-row">
            <div className="flex align-items-center mt-3 mr-2">
              <Checkbox
                key={index}
                inputId={customer._id}
                name={customer._id}
                value={customer._id}
                onChange={(e) => {
                  if (e.checked) {
                    if (!customerIds?.includes(customer._id)) {
                      customerIdsFieldHelpers.setValue([
                        ...customerIds,
                        customer._id,
                      ])
                    }
                  } else {
                    customerIdsFieldHelpers.setValue(
                      customerIds.filter(
                        (customerId) => customerId !== customer._id,
                      ),
                    )
                  }
                }}
                checked={customerIds.includes(customer._id)}
              />
              <label htmlFor={customer._id} className="ml-2">
                {customer.firstname} {customer.lastname}
              </label>
            </div>
            <Link target="_blank" to={`/inApp/customers/edit/${customer._id}`}>
              <Button text>{t('open_profile')}</Button>
            </Link>
          </div>
        ))}
      </div>

      <div className="flex justify-end mt-3">
        <div className="flex">
          <div className="mr-3.5">
            <Button
              label={t('cancel')}
              onClick={() => setShowCheckMatchingCustomersDialog(false)}
              text
              severity="secondary"
              icon="pi pi-times"
            />
          </div>
          <div>
            <Button
              label={
                customerIds?.length
                  ? t('connect_customers_and_create_entry')
                  : t('save_entry')
              }
              severity="info"
              type="submit"
              onClick={() => handleSubmit && handleSubmit()}
              icon="pi pi-check"
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}
