import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateCustomerCategoryArgs } from '@/schemaTypes'

const UPDATE_CUSTOMER_CATEGORY = gql`
  mutation updateCustomerCategory(
    $customerCategoryArgs: CustomerCategoryArgs!
  ) {
    updateCustomerCategory(customerCategoryArgs: $customerCategoryArgs) {
      _id
      customerCategory
    }
  }
`

type TData = { updateCustomerCategory: Mutation['updateCustomerCategory'] }
type TVariables = MutationUpdateCustomerCategoryArgs

export function useUpdateCustomerCategory(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateCustomerCategory] = useMutation<TData, TVariables>(
    UPDATE_CUSTOMER_CATEGORY,
    opts,
  )

  return updateCustomerCategory
}
