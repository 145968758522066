import {
  Box,
  Button,
  DialogContent,
  Paper,
  Typography,
} from '@material-ui/core'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import apollo from '@/apollo'
import AmountInput from '@/components/AmountInput'
import Loading from '@/components/Loading'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectOptionInput from '@/components/SelectOptionInput'
import {
  useCallViviValuationQuery,
  useCarDealCalculation,
  useCustomDealPawnPurchaseCalculations,
  useGetItemQuestions,
  useGetViviEntryPointQuery,
} from '@/domains/customDeals/hooks'
import {
  EVehicleCategory,
  QUESTION_CUSTOMER_CONTINUE_USING_ITEM_TITLE,
} from '@/domains/deals/components/constants/enums'
import { CallViviEndpointQuery } from '@/domains/items/hooks/itemDetails/callViviEndpoint.graphql'
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback'
import {
  ItemAnswer,
  ItemAnswerArgs,
  ItemQuestion,
  Query,
  QueryCallViviEndpointArgs,
  Scalars,
  VehicleData,
} from '@/schemaTypes'
import { getStringBetween, printLocalAmount } from '@/utils/misc'
import ItemCalculation from './ItemCalculation'

export interface ItemDraftVehicleFormData {
  itemCategoryId: string
  valuationAmount?: number
  customPayoutAmount?: number
  vehicleData: VehicleData
  title: string
  isNoValuation: boolean
  answers: ItemAnswer[]
}

interface Props {
  companyId: Scalars['ObjectId']
  durationInDays: number
  onSubmit: (formData: ItemDraftVehicleFormData) => void
}

interface CarProperty {
  name: string
  selected?: CarOption
  options: CarOption[]
}

interface CarOption {
  name: string
  href: string
}

const definedCarPropertyKeys = [
  'make',
  'model',
  'regdate',
  'body',
  'engine',
  'facelift',
  'seats',
  'transmission',
  'odometer',
  'admin',
]

const continueUsingOptions = [
  {
    label: 'YES',
    value: true,
  },
  {
    label: 'NO',
    value: false,
  },
]

const isContinueUsingQuestion = (
  question: Pick<ItemQuestion, 'titleKey'>,
): boolean => question.titleKey === QUESTION_CUSTOMER_CONTINUE_USING_ITEM_TITLE

export function AddVehicle(props: Props) {
  const { t } = useTranslation()

  const { onSubmit, durationInDays, companyId } = props
  const [vehicleProperties, setVehicleProperties] = useState<CarProperty[]>([])
  const [lastStepHref, setLastStepHref] = useState<string>('')
  const [questionsLoading, setQuestionsLoading] = useState(true)
  const [pawnContinueUsing, setPawnContinueUsing] = useState(true)
  const [odometer, setOdometer] = useState<number>()
  const [customPayoutAmount, setCustomPayoutAmount] = useState<number>()
  const { current: date } = useRef(new Date())

  const itemCategoryId = useMemo(
    () =>
      pawnContinueUsing ? EVehicleCategory.CAR : EVehicleCategory.CAR_STORED,
    [pawnContinueUsing],
  )

  const { itemQuestions: normalItemQuestions } = useGetItemQuestions({
    variables: {
      itemCategoryId,
      date,
      isCustomItem: false,
      omitDeactivatedQuestionsForNewProducts: false,
    },
  })

  const { itemQuestions: customItemQuestions } = useGetItemQuestions({
    variables: {
      itemCategoryId,
      date,
      isCustomItem: true,
      omitDeactivatedQuestionsForNewProducts: false,
    },
  })

  const itemQuestions: ItemQuestion[] = useMemo(
    () => [...(normalItemQuestions ?? []), ...(customItemQuestions ?? [])],
    [normalItemQuestions, customItemQuestions],
  )

  const answers: ItemAnswerArgs[] = useMemo(
    () =>
      itemQuestions.map((question) => ({
        questionId: question._id,
        selectedOptionIndex: isContinueUsingQuestion(question)
          ? pawnContinueUsing
            ? 0
            : 1
          : question.singleChoiceOptions?.findIndex(
              (choice) => choice.isDefaultValue,
            ),
      })),
    [itemQuestions, pawnContinueUsing],
  )

  useGetViviEntryPointQuery({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setQuestionsLoading(false)
      if (!data) return
      const nextStep = data.getViviEntryPoint.nextStep
      if (nextStep && nextStep.length > 0) {
        const defaultOption = nextStep.find(
          (option) => option.summary === 'Personenkraftwagen',
        )
        setVehicleProperties([
          {
            name: nextStep[0].rel,
            options: nextStep.map((option) => ({
              name: option.summary,
              href: option.href,
            })),
            selected: defaultOption
              ? { href: defaultOption.href, name: defaultOption.summary }
              : undefined,
          },
        ])

        if (defaultOption) {
          handleOptionSelected(defaultOption.href, 0)
        }
      }
    },
  })

  const handleOptionSelected = async (value: string, i: number) => {
    setLastStepHref('')

    setVehicleProperties((old) => {
      const newVehicleProperties = [...old]
      const carProperty = newVehicleProperties[i]
      carProperty.selected = carProperty.options.find((o) => o.href === value)
      return newVehicleProperties
    })

    if (!value) {
      setVehicleProperties((old) => {
        const newVehicleProperties = [...old]
        newVehicleProperties.splice(i + 1)
        return newVehicleProperties
      })
      return
    }

    setQuestionsLoading(true)

    const res = await apollo.query<
      { callViviEndpoint: Query['callViviEndpoint'] },
      QueryCallViviEndpointArgs
    >({
      query: CallViviEndpointQuery,
      variables: {
        args: {
          href: value,
        },
      },
    })

    setQuestionsLoading(false)
    const viviRes = res?.data?.callViviEndpoint
    const nextStep = viviRes.nextStep

    if (!nextStep) return

    if (nextStep.length < 1 && viviRes.lastStepHref) {
      setLastStepHref(viviRes.lastStepHref)
      return
    }

    setVehicleProperties((old) => {
      const newVehicleProperties = [...old]
      newVehicleProperties.splice(i + 1)
      return [
        ...newVehicleProperties,
        {
          name: nextStep[0].rel,
          options: nextStep.map((option) => ({
            name: option.summary,
            href: option.href,
          })),
        },
      ]
    })
  }

  const viviValuationRes = useCallViviValuationQuery({
    fetchPolicy: 'network-only',
    skip: !lastStepHref || !odometer,
    variables: {
      args: {
        href: lastStepHref as string,
        odometer,
      },
    },
  })

  const valuation = viviValuationRes.data?.callViviValuation

  const { queryResult: carDealCalculationResult } = useCarDealCalculation({
    variables: {
      args: {
        companyId,
        durationInDays,
        href: lastStepHref,
        answers,
        odometer,
        itemCategoryId,
      },
    },
    skip: !valuation || !lastStepHref || !odometer || !itemCategoryId,
  })

  const isNoValuation =
    !!carDealCalculationResult.data?.carDealCalculation?.noValuation

  const { queryResult: customDealCalculationResult } =
    useCustomDealPawnPurchaseCalculations({
      variables: {
        customDealCalculationArgs: {
          isReversedFeeCalculation: false,
          companyId,
          calculationItems: [
            {
              payoutAmount: customPayoutAmount,
              itemCategoryId,
              answers,
            },
          ],
          durationInDays,
        },
      },
      skip: !customPayoutAmount || !isNoValuation,
    })

  const [onChangeOdometer] = useDebouncedCallback(
    (e) => setOdometer(Number(e)),
    1000,
  )

  const [onChangeCustomPayoutAmount] = useDebouncedCallback(
    (e) => setCustomPayoutAmount(Number(e)),
    1000,
  )

  const itemValuesEntry = isNoValuation
    ? customDealCalculationResult.data?.customDealPawnPurchaseCalculations
        ?.pawnCalculation.itemsValuesEntries?.[0]
    : carDealCalculationResult.data?.carDealCalculation?.itemsValuesEntries?.[0]

  const calculatedCustomPayoutAmount =
    (isNoValuation
      ? itemValuesEntry?.payoutAmount
      : itemValuesEntry?.adjustedMarketValue) ?? undefined

  const invalidPayoutAmount =
    !calculatedCustomPayoutAmount || calculatedCustomPayoutAmount <= 0

  const isLoadingCalculation =
    carDealCalculationResult.loading || customDealCalculationResult.loading

  const handleSubmit = async () => {
    if (!valuation || !lastStepHref || invalidPayoutAmount) return

    const definedVehicleProperties: any = { odometer }

    vehicleProperties.forEach((c) => {
      if (definedCarPropertyKeys.includes(c.name)) {
        definedVehicleProperties[c.name] =
          c.name === 'odometer' ? Number(c.selected?.name) : c.selected?.name
      }
    })

    const indicataId = getStringBetween(lastStepHref, '/AT/', '/valuation')

    const otherVehicleProperties = vehicleProperties.filter(
      (c) => !definedCarPropertyKeys.includes(c.name),
    )

    onSubmit({
      itemCategoryId,
      valuationAmount: valuation.amount,
      isNoValuation,
      customPayoutAmount: calculatedCustomPayoutAmount,
      vehicleData: {
        indicataId: isNoValuation ? undefined : indicataId,
        vehicleProperties: {
          ...definedVehicleProperties,
          otherVehicleProperties:
            otherVehicleProperties.length > 0
              ? otherVehicleProperties
                  .filter((p) => p.selected)
                  .map((p) => ({
                    name: p.name,
                    value: p.selected?.name as string,
                  }))
              : undefined,
        },
      },
      answers,
      title: vehicleProperties
        .slice(0, 4)
        .map((p) => p.selected?.name)
        .join(' '),
    })
  }

  return (
    <DialogContent>
      <Box>
        {vehicleProperties.map((carOption, i) => {
          return (
            <Box marginBottom="1rem" key={carOption.name}>
              <SelectOptionInput
                hasNone
                value={carOption.selected?.href ?? ''}
                label={t(`car_${carOption.name}`)}
                menuItems={carOption.options.map((option) => ({
                  label: option.name,
                  value: option.href,
                }))}
                onChange={(value) => {
                  handleOptionSelected(value, i)
                }}
              />
            </Box>
          )
        })}
        {questionsLoading && (
          <Box textAlign="left">
            <LoadingSpinner />
          </Box>
        )}
        {lastStepHref && (
          <Box>
            <AmountInput
              className="u-mt-sm"
              type="int"
              onChange={onChangeOdometer}
              label={t('car_odometer')}
            />
            <SelectOptionInput
              className="u-mt-sm"
              value={pawnContinueUsing}
              label={t('deal.pawn.continue_using')}
              menuItems={continueUsingOptions}
              onChange={(value) => {
                setPawnContinueUsing(value)
              }}
            />

            {carDealCalculationResult.data?.carDealCalculation?.noValuation && (
              <AmountInput
                className="u-mt-sm"
                type="int"
                onChange={onChangeCustomPayoutAmount}
                label={t('payout.payout_amount')}
              />
            )}
          </Box>
        )}
        {valuation && lastStepHref && (
          <Box>
            {isLoadingCalculation && (
              <Paper className="u-p-sm">
                <Loading />
              </Paper>
            )}

            {valuation?.amount > 0 && (
              <Typography className="u-mt-sm u-mb-sm">
                Indicata Value: {printLocalAmount(valuation?.amount)}
              </Typography>
            )}

            {!isLoadingCalculation && (
              <ItemCalculation calculatedItemValuesEntry={itemValuesEntry} />
            )}

            {invalidPayoutAmount && (
              <Typography style={{ marginTop: 20 }} color="error">
                {t('error.error_validation_payout_amount_greater_than_0')}
              </Typography>
            )}

            <Button
              className="u-mb-sm u-mt-sm"
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={invalidPayoutAmount}
            >
              {t('create_car_data')}
            </Button>
          </Box>
        )}
      </Box>
    </DialogContent>
  )
}

export default AddVehicle
