import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Loading from '@/components/Loading'
import { useFragment } from '@/gql'
import { HistoryFilters, useHistoryState } from '@/hooks/useHistoryState'
import InfiniteScrollTable from '@/redesign/components/InfiniteScrollTable/InfiniteScrollTable'
import SearchInput from '@/redesign/components/SearchInput/SearchInput'
import TableFilterHeader from '@/redesign/components/TableFilterHeade/TableFilterHeader'
import Taskbar from '@/redesign/components/Taskbar/Taskbar'
import {
  CustomerBlacklistFragment,
  SearchCustomerBlacklists_Query,
} from '@/redesign/domains/customerBlacklists/helpers/customerBlacklists.graphql'
import { CustomerBlacklistsFiltersArgs } from '@/schemaTypes'

const LIMIT = 10
const defaultTableFilterArgs = { paginationArgs: { skip: 0, limit: LIMIT } }

const CustomerBlacklistListPage = () => {
  const { t } = useTranslation()
  const { historyState } =
    useHistoryState<HistoryFilters<CustomerBlacklistsFiltersArgs>>()
  const [customerFilter, setCustomerFilter] =
    useState<CustomerBlacklistsFiltersArgs>({
      ...historyState?.filters,
      ...defaultTableFilterArgs,
    })
  const history = useHistory()
  const [date, setDate] = useState(new Date())

  const customerFilterArgs = useMemo(() => {
    return {
      ...customerFilter,
      createdBefore: date,
      paginationArgs: { skip: 0, limit: LIMIT },
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerFilter])

  const queryResult = useQuery(SearchCustomerBlacklists_Query, {
    variables: {
      args: customerFilterArgs,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const customerBlacklists = useFragment(
    CustomerBlacklistFragment,
    queryResult.data?.searchCustomerBlacklists.nodes ?? [],
  )

  const customers = useMemo(
    () =>
      customerBlacklists.map((node) => ({
        ...node,
        email: node.email ? node.email : '-',
        dateOfBirth: node.dateOfBirth
          ? dayjs(node.dateOfBirth).format('DD.MM.YYYY')
          : '-',
        createdAt: dayjs(node.createdAt).format('DD.MM.YYYY'),
      })),
    [customerBlacklists],
  )

  const loadNextPage = useCallback(async () => {
    const pageInfo = queryResult?.data?.searchCustomerBlacklists?.pageInfo
    const paginationLimit = pageInfo?.limit || LIMIT
    const paginationSkip = (pageInfo?.skip || 0) + paginationLimit

    await queryResult.fetchMore({
      variables: {
        args: {
          ...customerFilterArgs,
          paginationArgs: {
            skip: paginationSkip,
            limit: paginationLimit,
          },
        },
      },
    })
  }, [queryResult, customerFilterArgs])

  const handleSearch = useCallback(
    (event) => {
      const value = event?.target?.value
      setDate(new Date())
      setCustomerFilter((prev) => ({
        ...prev,
        search: value,
        createdBefore: date,
        ...defaultTableFilterArgs,
      }))
    },
    [date],
  )

  const handleClickCustomer = useCallback(
    ({ value }) => {
      history.push(`/inApp/customerBlacklists/edit/${value._id}`)
    },
    [history],
  )

  const accountTemplate = (
    customerBlacklist: (typeof customerBlacklists)[number],
  ) => {
    const { customers } = customerBlacklist

    if (!customers?.length) {
      return '-'
    }

    return (
      <Tag
        className="mr-2"
        severity={customers.length === 1 ? 'info' : 'warning'}
        icon={customers.length === 1 ? 'pi pi-user' : 'pi pi-users'}
        value={
          customers.length === 1
            ? `${customers[0]?.firstname} ${customers[0]?.lastname}`
            : `${customers.length} ${t('customer_accounts')}`
        }
      />
    )
  }

  const noteTemplate = (
    customerBlacklist: (typeof customerBlacklists)[number],
  ) => (
    <p className="whitespace-nowrap overflow-hidden text-ellipsis">
      {customerBlacklist.reason ? customerBlacklist.reason : '-'}
    </p>
  )

  return (
    <div className="h-full flex flex-col">
      <TableFilterHeader
        title={t('customer_blacklists')}
        resultsCount={
          queryResult.data?.searchCustomerBlacklists.pageInfo.total || 0
        }
        filterOptions={
          <SearchInput
            placeholder={t('search')}
            value={customerFilter.search ?? ''}
            onChange={handleSearch}
            onClear={() => handleSearch(null)}
          />
        }
      />

      {queryResult.loading ? (
        <Loading />
      ) : (
        <>
          <InfiniteScrollTable
            handleLoadNextPage={loadNextPage}
            dataKey="_id"
            value={customers}
            onClickRow={handleClickCustomer}
            limit={
              queryResult.data?.searchCustomerBlacklists.pageInfo.limit ?? LIMIT
            }
            hasNextPage={
              queryResult.data?.searchCustomerBlacklists.pageInfo.hasNextPage ??
              true
            }
          >
            <Column
              field="firstname"
              style={{ width: '80px' }}
              header={t('customer.firstname')}
            />
            <Column
              field="lastname"
              style={{ width: '80px' }}
              header={t('customer.lastname')}
            />
            <Column
              field="email"
              style={{ width: '100px' }}
              header={t('customer.email')}
            />
            <Column
              field="dateOfBirth"
              style={{ width: '50px' }}
              header={t('customer.date_of_birth')}
            />
            <Column
              field="customers"
              style={{ width: '80px' }}
              header={t('cashy_account')}
              body={accountTemplate}
            />
            <Column
              field="reason"
              style={{ width: '160px' }}
              header={t('note')}
              body={noteTemplate}
            />
            <Column
              field="createdAt"
              style={{ width: '50px' }}
              header={t('blocked_at')}
            />
          </InfiniteScrollTable>
          <Taskbar>
            <Link to="/inApp/customerBlacklists/create">
              <Button
                label={t('add_person')}
                icon="pi pi-user-plus"
                severity="info"
              />
            </Link>
          </Taskbar>
        </>
      )}
    </div>
  )
}

export default CustomerBlacklistListPage
