import { useGetLookVariants } from '../hooks/useGetLookVariants'
import {
  ProductFormData,
  ProductImage,
  ProductLookVariant,
  ProductVariant,
  UppyFileObject,
} from '../interfaces'
import styled from '@emotion/styled'
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import Uppy from '@uppy/core'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import Tus from '@uppy/tus'
import { FormikErrors } from 'formik'
import { compact, uniqueId } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { ALLOWED_FILE_TYPES, MAX_IMAGE_SIZE_MB, UppyError } from '@/constants'
import DefaultUploaderIcon from '@/images/defaultUploader.svg'
import DeleteIcon from '@/images/trashIcon.svg'
import { EProductImageMode, EProductPropertyType } from '@/schemaTypes'
import { cloneProductVariants } from './ProductVariants'

export const uppy = new Uppy({
  id: 'uppy-' + uniqueId(),
  autoProceed: true,
  restrictions: {
    maxFileSize: MAX_IMAGE_SIZE_MB * 10000000,
    allowedFileTypes: ALLOWED_FILE_TYPES,
  },
  locale: {
    strings: {
      noDuplicates: UppyError.FILE_DUPLICATION,
      exceedsSize: UppyError.EXCEED_MAX_FILE_SIZE,
      youCanOnlyUploadFileTypes: UppyError.FILE_TYPE_UNALLOWED,
    },
  },
})
  .use(Tus, {
    endpoint: process.env.REACT_APP_TUS_SERVER_URL,
    retryDelays: [0, 1000, 3000, 5000],
  })
  .use(ThumbnailGenerator, {
    id: 'ThumbnailGenerator',
  })

export const ProductImages = (props: Props) => {
  const { t } = useTranslation()
  const { variants, setFieldValue, productImage, imageMode, overviewImage } =
    props

  const [uppyRestrictionError, setUppyRestrictionError] = useState<
    string | null
  >(null)
  const inputRefs = useRef<Array<HTMLInputElement | null>>([])

  const onUppyFileChanges = useCallback(
    (uppyFile: UppyFileObject) => {
      const file = uppy.getFile(uppyFile.id)
      if (imageMode === EProductImageMode.OneImageForAllProducts) {
        setFieldValue('image', {
          ephemeralId: getImageId(file),
          uppyFileObject: file,
        })
      } else {
        if ('isOverviewImage' in uppyFile.meta) {
          setFieldValue('overviewImage', {
            ephemeralId: getImageId(file),
            uppyFileObject: file,
          })
        } else {
          setFieldValue(
            'variants',
            cloneProductVariants(variants).map((variant) => {
              const lookVariantPropertyValues = uppyFile.meta
                .variantPropertyValueIds as (string | boolean | null)[][]
              if (
                isVariantFromLookVariant(variant, lookVariantPropertyValues)
              ) {
                const file = uppy.getFile(uppyFile.id)
                variant.ephemeralImageId = getImageId(file)
                variant.uppyFileObject = file
              }

              return variant
            }),
          )
        }
      }
    },
    [variants, imageMode, setFieldValue],
  )

  useEffect(() => {
    const fileIds = uppy.getFiles().map((file) => file.id)
    const variantUppyFileIds = compact(
      variants.map((variant) => variant.uppyFileObject?.id),
    )

    for (const fileId of fileIds) {
      if (!variantUppyFileIds.includes(fileId)) {
        uppy.removeFile(fileId)
      }
    }
  }, [variants])

  useEffect(() => {
    uppy.on('thumbnail:generated', onUppyFileChanges)
    uppy.on('upload-success', onUppyFileChanges)
    uppy.on('upload-progress', onUppyFileChanges)
    uppy.on('file-removed', onUppyFileChanges)

    return () => {
      uppy.off('thumbnail:generated', onUppyFileChanges)
      uppy.off('upload-success', onUppyFileChanges)
      uppy.off('upload-progress', onUppyFileChanges)
      uppy.off('file-removed', onUppyFileChanges)
    }
  }, [onUppyFileChanges])

  const handleUppyError = useCallback(
    (filename: string, error: any) => {
      if (!(error instanceof Error)) {
        setUppyRestrictionError(t(`AN_UNEXPECTED_ERROR_OCCURED`))
        return
      }

      switch (error.message) {
        case UppyError.FILE_DUPLICATION: {
          setUppyRestrictionError(t(`FILE_DUPLICATION`, { filename: filename }))
          return
        }

        case UppyError.EXCEED_MAX_FILE_SIZE: {
          setUppyRestrictionError(
            t(`EXCEED_MAX_FILE_SIZE`, { size: MAX_IMAGE_SIZE_MB }),
          )
          return
        }

        case UppyError.FILE_TYPE_UNALLOWED: {
          setUppyRestrictionError(
            t(`FILE_TYPE_UNALLOWED`, {
              allowedFileTypes: ALLOWED_FILE_TYPES.join(', '),
            }),
          )
          return
        }

        default: {
          setUppyRestrictionError(t(`AN_UNEXPECTED_ERROR_OCCURED`))
          return
        }
      }
    },
    [t],
  )

  const { lookVariants, error: lookVariantsError } =
    useGetLookVariants(variants)

  const handleRemoveFile = (
    lookVariant: ProductLookVariant,
    lookVariantIndex: number,
  ) => {
    if (lookVariant.uppyFileObject) {
      // If it has uppyFileObject, means it's just uploaded by Uppy
      uppy.removeFile(lookVariant.uppyFileObject.id)
    } else {
      // Else, it's not from Uppy and the removed file doesn't exist in Uppy state
      setFieldValue(
        'variants',
        cloneProductVariants(variants).map((variant) => {
          if (
            isVariantFromLookVariant(
              variant,
              lookVariant.variantPropertyValueIds,
            )
          ) {
            variant.imageId = null
            variant.imageUrl = null
          }

          return variant
        }),
      )
    }
    inputRefs.current = inputRefs.current.filter(
      (ref, index) => index !== lookVariantIndex,
    )
  }

  const handleAddFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    fileMeta?: Record<string, any>,
  ) => {
    const file = e.target.files?.[0]
    if (file) {
      try {
        uppy.addFile({
          source: 'file input',
          name: getUppyFilename(file),
          type: file.type,
          data: file,
          meta: fileMeta,
        })
      } catch (error) {
        handleUppyError(file.name, error as Error)
      }
    }

    ;(e.target.value as any) = null
  }

  const disableImageModeSelection = useMemo(() => {
    if (imageMode === EProductImageMode.ImagePerVariant) {
      return (
        (lookVariants.length > 0 &&
          lookVariants.some(
            (v) =>
              v.uppyFileObject?.progress &&
              !v.uppyFileObject.progress.uploadComplete,
          )) ||
        (overviewImage?.uppyFileObject?.progress &&
          !overviewImage.uppyFileObject.progress.uploadComplete)
      )
    }

    if (imageMode === EProductImageMode.OneImageForAllProducts) {
      return (
        productImage?.uppyFileObject?.progress &&
        !productImage.uppyFileObject.progress.uploadComplete
      )
    }
  }, [lookVariants, imageMode, productImage, overviewImage])

  return (
    <Box style={{ marginTop: 20 }}>
      <Typography variant="h5" style={{ width: 200 }}>
        {t('pictures')}
      </Typography>

      <ProductImageModeSelection
        disabled={disableImageModeSelection}
        imageMode={imageMode}
        onChange={(value) => setFieldValue('imageMode', value)}
      />

      <Box style={{ width: '100%', marginTop: 20 }}>
        {imageMode === EProductImageMode.ImagePerVariant ? (
          <>
            {lookVariants.map((lookVariant, lookVariantIndex) => {
              const isUploaded =
                !!lookVariant.imageId ||
                !!lookVariant.uppyFileObject?.response?.uploadURL

              return (
                <ProductImageContainer
                  previewUrl={
                    lookVariant.uppyFileObject?.preview ?? lookVariant.imageUrl
                  }
                  onClose={() =>
                    handleRemoveFile(lookVariant, lookVariantIndex)
                  }
                  onOpenFileSelection={() => {
                    inputRefs?.current?.[lookVariantIndex]?.click()
                  }}
                  index={lookVariantIndex}
                  isUploaded={
                    !!lookVariant.imageId ||
                    !!lookVariant.uppyFileObject?.response?.uploadURL
                  }
                  onFileChanged={(e) => {
                    if (isUploaded) {
                      handleRemoveFile(lookVariant, lookVariantIndex)
                    }

                    handleAddFile(e, {
                      variantPropertyValueIds:
                        lookVariant.variantPropertyValueIds, // Used as look variant Ids to identify images after being uploaded
                    })
                  }}
                  imageName={
                    <div>
                      {lookVariant.name.map((name) => (
                        <div>{name}</div>
                      ))}
                    </div>
                  }
                  inputRefs={inputRefs}
                  percentage={
                    lookVariant.uppyFileObject?.progress?.percentage ?? 0
                  }
                />
              )
            })}

            <ProductImageContainer
              previewUrl={
                overviewImage?.uppyFileObject?.preview ?? overviewImage?.url
              }
              onClose={() => {
                setFieldValue('overviewImage', null)
              }}
              onOpenFileSelection={() => {
                inputRefs?.current?.[variants.length]?.click()
              }}
              index={variants.length}
              isUploaded={
                !!overviewImage?.id ||
                !!overviewImage?.uppyFileObject?.response?.uploadURL
              }
              onFileChanged={(e) => {
                if (
                  !!overviewImage?.id ||
                  !!overviewImage?.uppyFileObject?.response?.uploadURL
                ) {
                  setFieldValue('overviewImage', null)
                }

                handleAddFile(e, {
                  isOverviewImage: true,
                })
              }}
              imageName={<div>{t('overview_image')}</div>}
              inputRefs={inputRefs}
              percentage={
                overviewImage?.uppyFileObject?.progress?.percentage ?? 0
              }
            />
          </>
        ) : (
          <ProductImageContainer
            previewUrl={
              productImage?.uppyFileObject?.preview ?? productImage?.url
            }
            onClose={() => {
              setFieldValue('image', null)
            }}
            onOpenFileSelection={() => {
              inputRefs?.current?.[0]?.click()
            }}
            index={0}
            isUploaded={
              !!productImage?.id ||
              !!productImage?.uppyFileObject?.response?.uploadURL
            }
            onFileChanged={(e) => {
              if (
                !!productImage?.id ||
                !!productImage?.uppyFileObject?.response?.uploadURL
              ) {
                setFieldValue('image', null)
              }

              handleAddFile(e)
            }}
            inputRefs={inputRefs}
            percentage={productImage?.uppyFileObject?.progress?.percentage ?? 0}
          />
        )}
      </Box>
      {uppyRestrictionError && (
        <Typography style={{ marginTop: 10 }} color="error">
          {uppyRestrictionError}
        </Typography>
      )}
      {lookVariantsError && (
        <Typography style={{ marginTop: 10 }} color="error">
          {t(lookVariantsError)}
        </Typography>
      )}
    </Box>
  )
}

interface ProductImageContainerProps {
  previewUrl?: string | null
  onClose: () => void
  onOpenFileSelection: () => void
  index: number
  isUploaded: boolean
  onFileChanged: (e: React.ChangeEvent<HTMLInputElement>) => void
  imageName?: React.ReactNode
  inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>
  percentage: number
}

export const ProductImageContainer = ({
  previewUrl,
  onClose,
  onOpenFileSelection,
  index,
  isUploaded,
  onFileChanged,
  imageName,
  inputRefs,
  percentage,
}: ProductImageContainerProps) => {
  return (
    <LookVariantContainer>
      <LookVariantWrapper>
        <>
          {previewUrl && (
            <Box
              style={{ position: 'relative', width: '100%', height: '100%' }}
            >
              <CloseButton onClick={() => onClose()}>
                <ReactSVG src={DeleteIcon} />{' '}
              </CloseButton>
              <ImageWrapper
                onClick={() => onOpenFileSelection()}
                key={index}
                url={previewUrl}
                isUploaded={isUploaded}
              >
                {!isUploaded && (
                  <ProgressWrapper>
                    <Typography
                      style={{ fontWeight: 600, color: 'white', fontSize: 23 }}
                    >
                      {percentage} %
                    </Typography>
                  </ProgressWrapper>
                )}
              </ImageWrapper>
            </Box>
          )}
          <UploadIconWrapper
            style={{ display: previewUrl ? 'none' : 'block' }}
            onClick={() => onOpenFileSelection()}
          >
            <ReactSVG
              src={DefaultUploaderIcon}
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            />{' '}
            <StyledInput
              onChange={onFileChanged}
              ref={(ref) => {
                inputRefs.current[index] = ref
              }}
              type="file"
              accept="image/png, image/jpeg, image/gif"
            />
          </UploadIconWrapper>
        </>
      </LookVariantWrapper>
      {imageName && (
        <div
          style={{
            marginTop: 190,
            fontWeight: 500,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {imageName}
        </div>
      )}
    </LookVariantContainer>
  )
}

export const getImageId = (uppyFile: UppyFileObject) => {
  return (
    uppyFile?.response?.uploadURL?.replace(
      process.env.REACT_APP_TUS_SERVER_URL + '/uploads/',
      '',
    ) ?? null
  )
}

interface Props {
  variants: ProductVariant[]
  productImage?: ProductImage | null
  overviewImage?: ProductImage | null
  imageMode: EProductImageMode
  setFieldValue: <T extends keyof ProductFormData>(
    field: T,
    value: ProductFormData[T],
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<ProductFormData>>
}

const isVariantFromLookVariant = (
  variant: ProductVariant,
  variantPropertyValueIds: (string | boolean | null)[][],
) => {
  return variantPropertyValueIds
    .map((ids) => ids.join())
    .includes(
      variant.propertiesValues
        .map((v) => {
          if (
            v.propertyDefinitionType === EProductPropertyType.Translation ||
            v.propertyDefinitionType === EProductPropertyType.Number
          ) {
            return v.valueId
          }

          if (v.propertyDefinitionType === EProductPropertyType.Boolean) {
            return v.value
          }

          throw new Error('Invalid property type')
        })
        .join(),
    )
}

const getUppyFilename = (file: File | undefined) => {
  if (!file?.name) return undefined
  const [filename, extentions] = file.name.split('.')
  return [filename + uniqueId(), extentions].join('.')
}

export const ProductImageModeSelection = ({
  imageMode,
  viewOnly,
  styles,
  disabled,
  onChange,
}: {
  imageMode: EProductImageMode
  viewOnly?: boolean
  disabled?: boolean
  styles?: Record<string, any>
  onChange?: (value: EProductImageMode) => void
}) => {
  const { t } = useTranslation()

  return (
    <RadioGroup
      value={imageMode}
      row
      style={styles ?? {}}
      onChange={(e) => onChange?.(e.target.value as EProductImageMode)}
    >
      <FormControlLabel
        disabled={disabled ?? viewOnly}
        value={EProductImageMode.OneImageForAllProducts}
        control={<Radio />}
        label={
          <Typography style={{ fontSize: 13, fontWeight: 500 }}>
            {t('one_picture_for_product')}
          </Typography>
        }
      />
      <FormControlLabel
        disabled={disabled ?? viewOnly}
        value={EProductImageMode.ImagePerVariant}
        control={<Radio />}
        label={
          <Typography style={{ fontSize: 13, fontWeight: 500 }}>
            {t('picture_per_variant')}
          </Typography>
        }
      />
    </RadioGroup>
  )
}

const UploadIconWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
  border: 1px solid #797979;
  border-radius: 10px;
  width: 100%;
  height: 100%;
`

const StyledInput = styled.input`
  display: none;
`

export const ImageWrapper = styled.li<{ url: string; isUploaded?: boolean }>`
  position: relative;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  filter: ${(props) =>
    props.isUploaded ? 'brightness(100%)' : 'brightness(50%)'};
  width: 100%;
  height: 100%;
  list-style: none;
  border-radius: 5px;
  cursor: pointer;
`

const CloseButton = styled.span`
  top: -18px;
  right: -23px;
  position: absolute;
  cursor: pointer;
  z-index: 100;
`

const ProgressWrapper = styled.div`
  top: 0px;
  right: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LookVariantWrapper = styled.div`
  width: 160px;
  height: 180px;
  float: left;
`

export const LookVariantContainer = styled.div`
  width: 160px;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0.5rem;
`
