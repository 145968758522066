import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateLoanToValuesEntryArgs } from '@/schemaTypes'

const CREATE_LOAN_TO_VALUES_ENTRY = gql`
  mutation createLoanToValuesEntry(
    $validFrom: DateTime!
    $companyId: ObjectId!
    $categoryValues: JSON!
  ) {
    createLoanToValuesEntry(
      validFrom: $validFrom
      companyId: $companyId
      categoryValues: $categoryValues
    ) {
      _id
      validFrom
      categoryValues
      company {
        _id
        name
      }
    }
  }
`

type TData = { createLoanToValuesEntry: Mutation['createLoanToValuesEntry'] }
type TVariables = MutationCreateLoanToValuesEntryArgs

export function useCreateLoanToValuesEntry(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createLoanToValuesEntry] = useMutation<TData, TVariables>(
    CREATE_LOAN_TO_VALUES_ENTRY,
    opts,
  )

  return createLoanToValuesEntry
}
