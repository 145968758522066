import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationAddItemNoteArgs } from '@/schemaTypes'

const ADD_ITEM_NOTE = gql`
  mutation addItemNote($itemId: ObjectId!, $noteCreateArgs: NoteCreateArgs!) {
    addItemNote(itemId: $itemId, noteCreateArgs: $noteCreateArgs) {
      _id
    }
  }
`

type TData = { addNote: Mutation['addItemNote'] }
type TVariables = MutationAddItemNoteArgs

export function useAddItemNote(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [addNote] = useMutation<TData, TVariables>(ADD_ITEM_NOTE, opts)

  return addNote
}
