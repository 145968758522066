import { EHintType } from '.'
import SelectOptionInput from '../SelectOptionInput'
import { TextareaAutosize, Typography } from '@material-ui/core'
import { last } from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { useGetActiveStorageFacilities } from '@/domains/items/hooks'
import { useGetShop } from '@/domains/shops/hooks'
import StorageUnitsConfiguration from '@/domains/storageUnits/components'
import SaveIcon from '@/images/save.svg'
import {
  AddItemToStorageUnitArgs,
  Deal,
  EItemStatusType,
  EStorageStatus,
  Item,
  ItemEvent,
  ShippingLabelCreateArgs,
} from '@/schemaTypes'
import { getNow } from '@/utils/time'
import styles from './ItemStatus.module.scss'

export interface ItemStatusWithStorageSelectionProps {
  item: Item
  deal: Deal
  newStatus: EItemStatusType
  onChangeItemStatus: (
    newEvent: ItemEvent,
    hint: EHintType,
    shippingLabelCreateArgs?: ShippingLabelCreateArgs | undefined,
    relocateItem?: boolean,
    relocateNote?: string,
    addItemToStorageUnitArgs?: AddItemToStorageUnitArgs,
  ) => void
}

const ItemStatusWithStorageSelection: FunctionComponent<
  ItemStatusWithStorageSelectionProps
> = (props) => {
  const { onChangeItemStatus, item, deal, newStatus } = props
  const { t } = useTranslation()

  const lastEvent: ItemEvent | undefined = last(item.events)

  const { storageFacilities } = useGetActiveStorageFacilities()

  const { shop: destinationShop } = useGetShop({
    variables: {
      shopId:
        lastEvent?.event === EItemStatusType.ShipBetweenShops
          ? lastEvent?.meta?.destinationShopId
          : undefined,
    },
  })

  const [description, setDescription] = useState('')
  const [storageFacilityId, setStorageFacilityId] = useState<string | null>(
    null,
  )

  const [storageUnitIdLv1, setStorageUnitIdLv1] = useState<string | null>(null)
  const [storageUnitIdLv2, setStorageUnitIdLv2] = useState<string | null>(null)
  const [storageUnitIdLv3, setStorageUnitIdLv3] = useState<string | null>(null)
  const [storageUnitIdLv4, setStorageUnitIdLv4] = useState<string | null>(null)

  useEffect(() => {
    if (destinationShop) {
      setStorageFacilityId(destinationShop.storageFacilityIds?.[0])
    }
  }, [destinationShop])

  const isAddedToNewStorage = item.storageHistory
    ? item?.storageHistory[0].storageStatus === EStorageStatus.AddedToStorage
    : false

  const saveNewStatus = () => {
    const now = getNow()

    onChangeItemStatus(
      {
        createdAt: now,
        timestamp: now,
        event: newStatus,
        meta: {
          description: description,
        },
      },
      EHintType.NONE,
      undefined,
      undefined,
      undefined,
      storageUnitIdLv1 && storageFacilityId
        ? {
            storageFacilityId,
            itemId: item?._id,
            storageUnits: {
              lvl1: storageUnitIdLv1,
              lvl2: storageUnitIdLv2,
              lvl3: storageUnitIdLv3,
              lvl4: storageUnitIdLv4,
            },
            storageDate: now,
            note: '',
          }
        : undefined,
    )
  }

  const onChangeStorageUnits = (data: {
    lvl1?: string
    lvl2?: string
    lvl3?: string
    lvl4?: string
  }) => {
    setStorageUnitIdLv1(data.lvl1 ?? null)
    setStorageUnitIdLv2(data.lvl2 ?? null)
    setStorageUnitIdLv3(data.lvl3 ?? null)
    setStorageUnitIdLv4(data.lvl4 ?? null)
  }

  return (
    <Fragment>
      <div
        onClick={saveNewStatus}
        className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
      >
        <ReactSVG className={`${styles.saveIcon} u-pl-15`} src={SaveIcon} />
      </div>
      {lastEvent?.event === EItemStatusType.ShipBetweenShops &&
        !isAddedToNewStorage &&
        destinationShop && (
          <div style={{ marginTop: 20 }}>
            <Typography style={{ color: 'red' }}>
              {t('item_needs_to_be_stored_again')}
            </Typography>
            <SelectOptionInput
              value={
                storageFacilityId ?? destinationShop.storageFacilityIds?.[0]
              }
              onChange={(value) => setStorageFacilityId(value)}
              label={t('storage.storage_facilities')}
              menuItems={storageFacilities.map((sf) => ({
                label: sf.name,
                value: sf._id,
              }))}
            />

            {storageFacilityId && (
              <StorageUnitsConfiguration
                storageFacilityId={storageFacilityId}
                item={item}
                controlBarStatus={false}
                storageHistory={item.storageHistory}
                items={deal.items}
                deal={deal}
                showOnItemStatus
                onChangeStorageUnits={onChangeStorageUnits}
              />
            )}
          </div>
        )}
      <table>
        <tr className={`${styles.notSellableTr}`}>
          <td>
            <br />
            {t('description')}:
            <TextareaAutosize
              rowsMin={4}
              placeholder={t('any_description_want_to_add')}
              style={{ width: '100%' }}
              onChange={(e) => setDescription(e.target.value)}
            />
          </td>
        </tr>
      </table>
    </Fragment>
  )
}

export default ItemStatusWithStorageSelection
