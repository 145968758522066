import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetProductPropertyDefinitionsArgs } from '@/schemaTypes'

const GET_PRODUCT_PROPERTY_DEFINITION_PRIORITIES = gql`
  query getProductPropertyDefinitions($opts: ProductPropertyDefinitionOpts) {
    getProductPropertyDefinitions(opts: $opts) {
      _id
      sortPriority
    }
  }
`

type TData = {
  getProductPropertyDefinitions: Query['getProductPropertyDefinitions']
}
type TVariables = QueryGetProductPropertyDefinitionsArgs

export function useGetPropertyDefinitionsSortPriorities(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_PRODUCT_PROPERTY_DEFINITION_PRIORITIES,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    propertyDefinitionsSortPriorities:
      queryResult.data?.getProductPropertyDefinitions?.map(
        (c) => c.sortPriority,
      ) ?? [],
  }
}
