import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { companyFragments } from '@/domains/companies/hooks/companyFragments'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryGetCompaniesArgs } from '@/schemaTypes'

const GET_COMPANIES = gql`
  query getCompanies($opts: CompanyOpts) {
    getCompanies(opts: $opts) {
      _id
      name
      uid
      fibuNumber
      successionCompanyId
      companyCounterId
      companyCounter {
        ...CompanyCountersFragment
      }
    }
  }
  ${companyFragments.companyCounter}
`

const GET_COMPANIES_NAMES = gql`
  query getCompanies($opts: CompanyOpts) {
    getCompanies(opts: $opts) {
      _id
      name
    }
  }
`

type TData = { getCompanies: Query['getCompanies'] }
type TVariables = QueryGetCompaniesArgs

export function useGetCompanies(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_COMPANIES, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, companies: queryResult.data?.getCompanies || [] }
}

export function useGetCompaniesNames(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_COMPANIES_NAMES, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, companies: queryResult.data?.getCompanies || [] }
}
