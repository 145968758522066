import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationMergeCustomerArgs } from '@/schemaTypes'

const MERGE_CUSTOMERS = gql`
  mutation mergeCustomer($input: MergeCustomersInput!) {
    mergeCustomer(input: $input) {
      _id
    }
  }
`

type TData = { mergeCustomers: Mutation['mergeCustomer'] }
type TVariables = MutationMergeCustomerArgs

export function useMergeCustomers(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [mergeCustomers] = useMutation<TData, TVariables>(MERGE_CUSTOMERS, opts)

  return mergeCustomers
}
