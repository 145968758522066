import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query } from '@/schemaTypes'

const GET_SHIPPING_LABELS = gql`
  query getShippingLabels($args: ShippingLabelFilterArgs!) {
    getShippingLabels(args: $args) {
      direction
      shippingPartner
      labelCreateDate
      senderAddress {
        houseNumber
        stairway
        door
        floor
        street
        city
        state
        countryCode
        country
        zipCode
      }
      senderName
      receiverAddress {
        houseNumber
        stairway
        door
        floor
        street
        city
        state
        countryCode
        country
        zipCode
      }
      receiverName
      labelFile {
        url
        contentType
        filename
      }
      trackingCode
      trackingLink
    }
  }
`

type TData = { getShippingLabels: Query['getShippingLabels'] }
type TVariables = {}

export function useGetShippingLabels(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_SHIPPING_LABELS, {
    variables: {},
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    shippingLabels: queryResult.data?.getShippingLabels || [],
  }
}
