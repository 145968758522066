import { ASSESSMENT_57_TOLERANCE_DURATION_MONTH } from '../VerificationChecklist/VerificationChecklist'
import dayjs from 'dayjs'
import { Message } from 'primereact/message'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Deal } from '@/schemaTypes'

export interface CarExtensionWarningProps {
  deal: Deal
  className?: string
}

export const CarExtensionWarning: FunctionComponent<
  CarExtensionWarningProps
> = ({ deal, className }) => {
  const { t } = useTranslation()
  const today = dayjs()

  const isFinalMaxPawnDateExceeded = dayjs(
    deal.pawnData?.finalMaxPawnDate,
  ).isBefore(
    today.add(deal.company.configuration.minimumPawnDuration, 'days'),
    'date',
  )

  const isPawnDurationExceedingAssessment57 = dayjs(
    deal.items[0].vehicleData?.has57aGutachtenDue,
  )
    .add(ASSESSMENT_57_TOLERANCE_DURATION_MONTH, 'month')
    .isBefore(
      today.add(deal.company.configuration.minimumPawnDuration, 'days'),
      'date',
    )

  return (
    <div className={className}>
      {isFinalMaxPawnDateExceeded ? (
        <Message
          severity="warn"
          text={t('car_inspection.max_pawn_duration_exceeded_message')}
        />
      ) : isPawnDurationExceedingAssessment57 ? (
        <Message
          severity="warn"
          text={t('car_inspection.assessment_57_exceeded_message')}
        />
      ) : (
        deal.dealFinalValues.isOnlineExtensionPrevented && (
          <Message severity="warn" text={t('allow_online_extension_warning')} />
        )
      )}
    </div>
  )
}
