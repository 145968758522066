import { useMutation } from '@apollo/client'
import { graphql } from '@/gql'

const ConnectCustomerToDealMutation = graphql(`
  mutation connectCustomerToDeal($dealId: ObjectId!, $customerId: ObjectId!) {
    connectCustomerToDeal(dealId: $dealId, customerId: $customerId) {
      _id
      customer {
        ... on Customer {
          _id
          firstname
          lastname
          email
        }
      }
    }
  }
`)

export const useConnectCustomerToDealMutation = () =>
  useMutation(ConnectCustomerToDealMutation)
