import { TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopy from '@material-ui/icons/FileCopy'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import { Link } from 'react-router-dom'
import { ItemQuestion } from '@/schemaTypes'
import globalStyles from '@/styles/global.module.scss'

dayjs.extend(utc)

export interface ItemQuestionsRowProps {
  itemQuestion: ItemQuestion
  deleteItemQuestion: () => void
}

const ItemQuestionsRow = (props: ItemQuestionsRowProps) => {
  const { itemQuestion } = props
  const id = itemQuestion._id ?? ''

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell align="right">{itemQuestion.order}</TableCell>
      <TableCell align="left">{itemQuestion.titleKey}</TableCell>
      <TableCell align="right">{itemQuestion.questionType}</TableCell>
      <TableCell align="right">{itemQuestion.valueMode}</TableCell>
      <TableCell align="right">{itemQuestion.predictionTag}</TableCell>
      <TableCell>
        <Link to={`/inApp/configurations/itemQuestions/create/${id}`}>
          <FileCopy />
        </Link>
        <Link to={`/inApp/configurations/itemQuestions/edit/${id}`}>
          <EditIcon />
        </Link>
        <DeleteIcon
          className={globalStyles.clickable}
          onClick={props.deleteItemQuestion}
        />
      </TableCell>
    </TableRow>
  )
}

export default ItemQuestionsRow
