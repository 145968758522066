import { CustomDealFormOption, createInitialMultipleLanguage } from '../helpers'
import styled from '@emotion/styled'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { MultipleLanguageInputWithDescription } from '@/components/MultipleLanguageInput'

interface Props {
  options?: CustomDealFormOption[]
  allowEnterAnswer?: boolean
  setFieldValue: (field: string, value: unknown) => void
}

export const TextOptions = ({
  setFieldValue,
  options,
  allowEnterAnswer,
}: Props) => {
  const { t } = useTranslation()

  const onAddOption = () => {
    setFieldValue('options', [
      ...(options ?? []),
      { default: false, label: createInitialMultipleLanguage() },
    ])
  }

  const onRemoveOption = (index: number) => {
    setFieldValue(
      'options',
      (options ?? []).filter((_, i) => i !== index),
    )
  }

  return (
    <Box mb="1rem">
      <Typography variant="h6" gutterBottom>
        {t('options')}
      </Typography>

      {options?.map((option, i) => (
        <Option key={i}>
          <MultipleLanguageInputWithDescription
            descriptions={option.description ?? []}
            onChangeDescription={(value) => {
              setFieldValue(`options.[${i}].description`, value)
            }}
            className="text-input"
            values={option.label}
            onChange={(value) => setFieldValue(`options.[${i}].label`, value)}
            key={i}
          />
          <DefaultCheckBox
            control={
              <Checkbox
                checked={option.default ?? false}
                onChange={() =>
                  setFieldValue(`options.[${i}].default`, !option.default)
                }
              />
            }
            label={t('default')}
          />
          <IconButton
            style={{ padding: '0.5rem' }}
            onClick={() => onRemoveOption(i)}
          >
            <CloseIcon />
          </IconButton>
        </Option>
      ))}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              checked={allowEnterAnswer ?? false}
              onChange={() =>
                setFieldValue('allowEnterAnswer', !allowEnterAnswer)
              }
            />
          }
          label={t('allow_enter_answer')}
        />
        <Button
          color="primary"
          size="small"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={onAddOption}
        >
          {t('add_option')}
        </Button>
      </Box>
    </Box>
  )
}

const Option = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;

  .text-input {
    flex: 1;
    margin-right: 1rem;
  }
`

const DefaultCheckBox = styled(FormControlLabel)`
  position: absolute;
  bottom: 1rem;
  right: 3rem;
`
