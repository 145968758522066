import React, { ComponentProps, useState } from 'react'
import { ReactSVG } from 'react-svg'
import TextInput from '@/components/TextInput'
import SaveIcon from '@/images/save.svg'
import styles from './Item.module.scss'

export interface TextRowProps {
  label: string | React.ReactNode
  defaultValue: string
  onChange: (value: string) => void
  textInputProps?: ComponentProps<typeof TextInput>
  addionalColumns?: JSX.Element
}

const TextRow: React.FC<TextRowProps> = ({
  label,
  defaultValue,
  onChange,
  textInputProps,
  addionalColumns,
}) => {
  const [value, setValue] = useState(defaultValue)

  return (
    <tr style={{ width: '100%' }}>
      <td style={{}}>
        <p>{label}:</p>
      </td>
      <td style={{ display: 'flex', alignItems: 'center' }}>
        <TextInput
          style={{}}
          value={value}
          onChange={(e) => setValue(e)}
          {...(textInputProps ?? {})}
        />
        <ReactSVG
          className={`${styles.saveIcon} u-pl-15`}
          src={SaveIcon}
          onClick={() => onChange(value)}
        />
      </td>
      {addionalColumns}
    </tr>
  )
}

export default TextRow
