const patternFileExtension = /\.([0-9a-z]+)(?:[?#]|$)/i

export function getFilenameAndExtension(fullFilename: string) {
  const fileExtensionMatch = fullFilename.match(patternFileExtension)
  const fileExtension = fileExtensionMatch ? fileExtensionMatch[0] ?? '' : ''
  const extensionLength = fileExtension?.length ?? 0

  const filename = fullFilename.substr(0, fullFilename.length - extensionLength)

  return {
    fileExtension,
    filename,
  }
}

export function getFilenameFromURL(url: string) {
  const fullPath = url.split('?')[0]
  const splits = fullPath.split('/')
  const fullFilename =
    splits.length > 0 ? decodeURIComponent(splits[splits.length - 1]) : ''

  return getFilenameAndExtension(fullFilename)
}
