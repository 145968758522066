import { ApolloProvider } from '@apollo/client'
import DayJsUtils from '@date-io/dayjs'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import deAt from 'dayjs/locale/de-at'
import { createBrowserHistory } from 'history'
import { PrimeReactProvider } from 'primereact/api'
import ReactDOM from 'react-dom'
import client from './apollo'
import './i18n'
import './index.css'
import Root from './pages'
import * as serviceWorker from './serviceWorker'
import './styles/index.scss'

const history = createBrowserHistory()

function notUndefined<T>(value: T): value is Exclude<T, undefined> {
  return Boolean(value)
}

Sentry.init({
  dsn: 'https://edd68f5f9c3742a3ae3a9a7499eee3d7@o1221812.ingest.sentry.io/6365202',
  release: process.env.SENTRY_RELEASE,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [process.env.REACT_APP_GRAPHQL_HTTP_URI].filter(
        notUndefined,
      ),
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1,
  environment: process.env.REACT_APP_ENV,
  enabled: !process.env.REACT_APP_GRAPHQL_HTTP_URI?.includes('localhost'),
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <PrimeReactProvider>
      <MuiPickersUtilsProvider utils={DayJsUtils} locale={deAt}>
        <Root history={history} />
      </MuiPickersUtilsProvider>
    </PrimeReactProvider>
  </ApolloProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
