import { useCallback, useEffect, useMemo, useState } from 'react'

export const usePrepareMergingCustomer = () => {
  const [selecting, setSelecting] = useState(false)
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>([])

  const disableSelection = useMemo(() => {
    return selectedCustomerIds.length >= 2
  }, [selectedCustomerIds])

  const disableLinkGotoMergePage = useMemo(() => {
    return selecting && selectedCustomerIds.length === 2
  }, [selecting, selectedCustomerIds])

  const toggleCustomer = useCallback(
    (id: string) => {
      setSelectedCustomerIds((selectedCustomerIds) =>
        !disableSelection && !selectedCustomerIds.includes(id)
          ? [...selectedCustomerIds, id]
          : selectedCustomerIds.filter((id) => id !== id),
      )
    },
    [disableSelection, setSelectedCustomerIds],
  )

  const toggleSelecting = useCallback(() => {
    setSelecting((prev) => !prev)
  }, [])

  useEffect(() => {
    if (!selecting) {
      setSelectedCustomerIds([])
    }
  }, [selecting])

  return {
    selectedCustomerIds,
    selecting,
    toggleSelecting,
    toggleCustomer,
    disableSelection,
    disableLinkGotoMergePage,
  }
}
