import { css } from '@emotion/react'
import styled from '@emotion/styled'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { isNil, omitBy, round } from 'lodash'
import { Button } from 'primereact/button'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import PaymentTypePicker from '@/components/PaymentTypePicker'
import { Context } from '@/context'
import { useGetPrivateSaleByDealId } from '@/domains/privateSales/hooks/getPrivateSaleByDealId'
import { ShowConfirmPopupParams, useShowConfirmPopup } from '@/hooks'
import Input from '@/redesign/components/Input/Input'
import InputNum from '@/redesign/components/InputNumber/InputNumber'
import SectionTitle from '@/redesign/components/SectionTitle/SectionTitle'
import {
  Deal,
  DealExtensionConfirmedEvent,
  DealPaybackConfirmedEvent,
  EManualPaymentType,
  PaymentData,
  PaymentDataArgs,
  SecondaryPaymentData,
  SecondaryPaymentDataArgs,
} from '@/schemaTypes'
import {
  assertDealConnectedToRegisteredCustomer,
  getDealValueEntry,
  getLatestEventForStatus,
  isDealConnectedToRegisteredCustomer,
  isDealVerified,
} from '@/utils/deal'
import {
  getDefaultManualPaymentType,
  isManualPaymentType,
  paymentTypeUnion,
  printLocalAmount,
} from '@/utils/misc'
import PayoutData from './PayoutData'

export interface PaymentDetailsProps {
  deal: Deal
  onUpdatePrimaryPayoutData: (args: PaymentDataArgs) => void
  onChangePaybackType: (paymentType: EManualPaymentType) => void
  onSaveInsuredAmount: (insuredAmount: number) => void
  onUpdateSecondaryPayoutData: (args: SecondaryPaymentDataArgs | null) => void
}

export function PaymentDetails(props: PaymentDetailsProps) {
  const {
    deal,
    onUpdatePrimaryPayoutData,
    onChangePaybackType,
    onSaveInsuredAmount,
    onUpdateSecondaryPayoutData,
  } = props
  const { closeConfirmModal } = useContext(Context)

  const { t } = useTranslation()

  const [primaryPayoutDataArgs, setPrimaryPayoutDataArgs] = useState<
    PaymentDataArgs | null | undefined
  >(convertPrimaryPayoutDataToArgs(deal.payoutData))
  const [secondaryPayoutDataArgs, setSecondaryPayoutDataArgs] = useState<
    SecondaryPaymentDataArgs | null | undefined
  >(convertSecondaryPayoutDataToArgs(deal.secondaryPayoutData))

  const event = useMemo(() => {
    const closedEvent = getLatestEventForStatus(deal, 'DealClosedEvent')
    const soldInternEvent = getLatestEventForStatus(deal, 'DealSoldInternEvent')
    const paybackConfirmedEvent = getLatestEventForStatus(
      deal,
      'DealPaybackConfirmedEvent',
    )
    const extensionConfirmedEvent = getLatestEventForStatus(
      deal,
      'DealExtensionConfirmedEvent',
    )
    const verifiedEvent = getLatestEventForStatus(deal, 'DealVerifiedEvent')

    return {
      closedEvent,
      soldInternEvent,
      paybackConfirmedEvent,
      extensionConfirmedEvent,
      verifiedEvent,
    }
  }, [deal])

  let payBackAmountIsItemsSales = false

  const payoutAmount = getDealValueEntry(deal, 'DealVerifiedEvent').payoutAmount

  let paybackAmount: number | undefined

  if (event.extensionConfirmedEvent) {
    paybackAmount =
      getDealValueEntry(deal, 'DealExtensionConfirmedEvent').paybackAmount ?? 0
  } else if (event.paybackConfirmedEvent) {
    paybackAmount =
      getDealValueEntry(deal, 'DealPaybackConfirmedEvent').paybackAmount ?? 0
  } else if (
    (event.soldInternEvent || event.closedEvent?.isClosedByAuctionId) &&
    deal.pawnData &&
    deal.pawnData.buyBackChildId
  ) {
    paybackAmount = deal.dealValorizationCalculation
      ? deal.dealValorizationCalculation.auctionRevenues
          .map((c) => c.finalPrice)
          .reduce((a, b) => a + b) ?? 0
      : 0
    payBackAmountIsItemsSales = true
  }

  const showConfirmUseCustomerSavedPaymentDataPopup = useShowConfirmPopup({
    actionText: t('take'),
    abortActionText: t('do_not_take'),
  })

  const { privateSale } = useGetPrivateSaleByDealId({
    variables: {
      dealId: deal._id,
    },
  })

  const onAddSecondaryPayoutData = useCallback(
    (deal: Deal, paymentType: EManualPaymentType) => {
      assertDealConnectedToRegisteredCustomer(deal)

      const defaultSecondaryPayoutDataArgs: SecondaryPaymentDataArgs = {
        amount: 0,
        paymentType: paymentType,
      }

      loadCustomerSavedData({
        customerPaymentData: {
          iban: deal.customer.iban,
          paypalAddress: deal.customer.paypalAddress,
        },
        paymentType: paymentType,
        dataArgs: defaultSecondaryPayoutDataArgs,
        setDataArgs: setSecondaryPayoutDataArgs,
        t,
        popup: showConfirmUseCustomerSavedPaymentDataPopup,
        close: closeConfirmModal,
      })
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, deal.customer],
  )

  const disablePaybackTypeChange =
    isMoreThan24HoursAfterEventCreated(event.paybackConfirmedEvent) ||
    isMoreThan24HoursAfterEventCreated(event.extensionConfirmedEvent)
  const primaryPayoutAmount = !secondaryPayoutDataArgs
    ? payoutAmount
    : (payoutAmount ?? 0) - secondaryPayoutDataArgs.amount

  const [insuredAmount, setInsuredAmount] = useState<number | undefined>(
    deal.insuredAmount,
  )
  const [insuredAmountValidationError, setInsuredAmountValidationError] =
    useState<string>()

  const isVerifiedDeal = isDealVerified(deal)

  const dealPayoutAmount = deal.dealFinalValues.payoutAmount

  const companyConfigurationInsuranceFactor =
    deal.company.configuration.insuranceFactor

  const insuredAmountDiffers = insuredAmount !== deal.insuredAmount

  useEffect(() => {
    if (!dealPayoutAmount || isVerifiedDeal) return
    if (isNil(insuredAmount)) {
      setInsuredAmountValidationError(t('validation.validation_is_number'))
    } else {
      if (
        insuredAmount! / dealPayoutAmount <
        companyConfigurationInsuranceFactor
      ) {
        setInsuredAmountValidationError(
          t('insured_amount_is_not_valid', {
            insuranceFactor: companyConfigurationInsuranceFactor,
          }),
        )
      } else {
        setInsuredAmountValidationError(undefined)
      }
    }
  }, [
    t,
    isVerifiedDeal,
    insuredAmount,
    dealPayoutAmount,
    companyConfigurationInsuranceFactor,
  ])

  const onChangeInsuredAmount = (value) => {
    const inputInsuredAmount = round(parseFloat(value), 2)
    setInsuredAmount(isNaN(inputInsuredAmount) ? undefined : inputInsuredAmount)
  }

  useEffect(() => {
    setInsuredAmount(deal.insuredAmount)
  }, [deal.insuredAmount])

  return (
    <div>
      <SectionTitle title={t('payout.label')} />

      <InputNum
        value={payoutAmount}
        label={t('total_payout_amount')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        disabled
      />

      <div className="flex flex-wrap a mb-3.5 items-start">
        <InputNum
          value={insuredAmount}
          label={t('insured_value')}
          disabled={
            isVerifiedDeal || !dealPayoutAmount || isNil(deal.insuredAmount)
          }
          className="flex flex-col md:flex-row items-start md:items-center mr-4"
          labelClassName="text-sm font-semibold w-12.5"
          inputContainerClassName="w-52"
          onChange={(e) => onChangeInsuredAmount(e.value)}
          isInvalid={!!insuredAmountValidationError}
          errorText={insuredAmountValidationError}
        />
        {/* Reset insuredAmount value*/}
        <Button
          icon="pi pi-replay"
          severity="secondary"
          className={classNames('!mr-0.5', {
            '!hidden': !insuredAmountDiffers,
          })}
          onClick={() => setInsuredAmount(deal.insuredAmount)}
          text
        />
        {/* onSaveInsuredAmount value*/}
        <Button
          icon="pi pi-save"
          severity="secondary"
          className={classNames({
            '!hidden': !(insuredAmountDiffers && !insuredAmountValidationError),
          })}
          onClick={() =>
            onSaveInsuredAmount && onSaveInsuredAmount(insuredAmount!)
          }
          text
        />
      </div>

      <div className="flex flex-wrap">
        {/* Primary Payout */}
        <div>
          <PayoutData
            deal={deal}
            amount={primaryPayoutAmount}
            payoutDataArgs={primaryPayoutDataArgs}
            setPayoutDataArgs={setPrimaryPayoutDataArgs}
            onUpdatePayoutData={onUpdatePrimaryPayoutData}
          />
        </div>

        {/* Secondary Payout */}
        <div>
          {secondaryPayoutDataArgs && (
            <PayoutData
              deal={deal}
              amount={secondaryPayoutDataArgs.amount}
              payoutDataArgs={secondaryPayoutDataArgs}
              setPayoutDataArgs={setSecondaryPayoutDataArgs}
              onUpdatePayoutData={onUpdateSecondaryPayoutData}
              isSecondary={true}
            />
          )}
          {!secondaryPayoutDataArgs && (
            <>
              <AddSecondaryPayoutCard
                className="flex justify-center items-center cursor-pointer"
                onClick={() =>
                  onAddSecondaryPayoutData(deal, EManualPaymentType.Bank)
                }
                disabled={
                  deal.pawnData?.extensionParentId ||
                  deal.pawnData?.buyBackParentId ||
                  !isDealConnectedToRegisteredCustomer(deal) ||
                  (event.verifiedEvent &&
                    !dayjs().isSame(
                      dayjs(event.verifiedEvent.createdAt),
                      'date',
                    ))
                }
              >
                <div className="flex flex-row">
                  <i className="pi pi-plus mr-2" />
                  <AddPayoutText>{t('add_secondary_payout')}</AddPayoutText>
                </div>
              </AddSecondaryPayoutCard>
            </>
          )}
        </div>
      </div>

      {privateSale && (
        <div className="flex flex-row">
          <span style={{ marginLeft: '0.6rem', marginRight: '0.6rem' }}>
            {t('loan_exposure')}:
          </span>
          <Link
            to={`/inApp/privateSales/${privateSale._id}`}
            target="_blank"
            rel="noreferrer"
          >
            {privateSale.dealFinalBalance &&
            privateSale?.dealFinalBalance > 0 ? (
              <span style={{ color: 'green', fontWeight: 'bold' }}>
                {' '}
                {printLocalAmount(privateSale?.dealFinalBalance)}
              </span>
            ) : privateSale &&
              privateSale.dealFinalBalance &&
              privateSale.dealFinalBalance < 0 ? (
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                {printLocalAmount(privateSale.dealFinalBalance)}
              </span>
            ) : (
              <span style={{ color: 'black', fontWeight: 'bold' }}>
                {printLocalAmount(privateSale.dealFinalBalance)}
              </span>
            )}
          </Link>
        </div>
      )}

      {(deal.pawnData?.paybackData || typeof paybackAmount === 'number') && (
        <div className="mt-4">
          <SectionTitle title={t('payback_type')} />
          {deal.pawnData?.paybackData && (
            <div>
              <div className="mb-2">
                <PaymentTypePicker
                  disabled={
                    !isManualPaymentType(
                      paymentTypeUnion(deal.pawnData?.paybackData?.paymentType),
                    ) || disablePaybackTypeChange
                  }
                  paymentType={paymentTypeUnion(
                    deal.pawnData.paybackData.paymentType,
                  )}
                  onChangePaymentType={onChangePaybackType}
                  businessUnit={deal.businessUnit}
                  label={t('payback_type')}
                />
              </div>
              {typeof paybackAmount === 'number' && (
                <Input
                  value={
                    printLocalAmount(paybackAmount) +
                    (payBackAmountIsItemsSales
                      ? '  (' + t('sum_of_items_buy_back') + ' )'
                      : '')
                  }
                  label={t('payout.payback_amount')}
                  disabled={true}
                  className="flex flex-col md:flex-row items-start md:items-center mb-2"
                  labelClassName="text-sm font-semibold w-12.5"
                  inputContainerClassName="w-52"
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const isMoreThan24HoursAfterEventCreated = (
  event: DealPaybackConfirmedEvent | DealExtensionConfirmedEvent | undefined,
) => event && dayjs().diff(dayjs(event.createdAt), 'minutes') > 24 * 60

export function convertSecondaryPayoutDataToArgs(
  secondaryPamentData: SecondaryPaymentData | null | undefined,
): SecondaryPaymentDataArgs | undefined {
  if (secondaryPamentData) {
    const paymentType = paymentTypeUnion(secondaryPamentData.paymentType)
    if (isManualPaymentType(paymentType)) {
      const args: SecondaryPaymentDataArgs = {
        ...omitBy(
          secondaryPamentData,
          (f) => f === undefined || f === null || f === 'SecondaryPaymentData',
        ),
        paymentType: paymentType,
        amount: secondaryPamentData.amount,
      }
      return args
    }
  }
}

export function convertPrimaryPayoutDataToArgs(
  payoutData: PaymentData,
): PaymentDataArgs | undefined {
  if (isManualPaymentType(paymentTypeUnion(payoutData.paymentType))) {
    const args: PaymentDataArgs = {
      ...omitBy(
        payoutData,
        (f) => f === undefined || f === null || f === 'PaymentData',
      ),
      paymentType: getDefaultManualPaymentType(payoutData.paymentType),
    }
    return args
  }
}

export const loadCustomerSavedData = (params: {
  customerPaymentData: {
    iban?: string
    paypalAddress?: string
  }
  paymentType: EManualPaymentType
  dataArgs: PaymentDataArgs | SecondaryPaymentDataArgs
  setDataArgs
  t
  popup: (params: ShowConfirmPopupParams) => any
  close: any
}) => {
  const {
    customerPaymentData,
    paymentType,
    dataArgs,
    setDataArgs,
    t,
    popup,
    close,
  } = params

  let savedPaymentData
  if (paymentType === EManualPaymentType.Bank) {
    savedPaymentData = customerPaymentData.iban
  } else if (paymentType === EManualPaymentType.Paypal) {
    savedPaymentData = customerPaymentData.paypalAddress
  }

  if (savedPaymentData) {
    popup({
      title: t('account_data_available'),
      component: () => {
        return (
          <div>
            <AvailableAcountData>
              {t('use_customer_data_for_payment_1')}
            </AvailableAcountData>
            <AvailableAcountDataMain>
              {savedPaymentData}
            </AvailableAcountDataMain>
            <AvailableAcountData>
              {t('use_customer_data_for_payment_2')}
            </AvailableAcountData>
          </div>
        )
      },

      action: () => {
        if (dataArgs && setDataArgs) {
          paymentType === EManualPaymentType.Bank
            ? setDataArgs({
                ...dataArgs,
                paymentType: paymentType,
                iban: savedPaymentData,
              })
            : setDataArgs({
                ...dataArgs,
                paymentType: paymentType,
                paypalAddress: savedPaymentData,
              })
        }
        close()
      },
      leftButtonAction: () => {
        close()
      },
      preventAutoCloseByKey: true,
    })
  }

  if (dataArgs && setDataArgs) {
    setDataArgs({ ...dataArgs, paymentType: paymentType })
  }
}

const AvailableAcountData = styled.p`
  margin: 1.3rem;
  font-weight: 550;
`
const AvailableAcountDataMain = styled.p`
  font-weight: 750;
`

const AddSecondaryPayoutCard = styled.div<{ disabled?: boolean }>`
  width: 28.8125rem;
  height: 11.9375rem;
  padding: 0.65625rem 1.09375rem;
  gap: 0.4375rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 3px dashed var(--surface-200, #eee);
  background: var(--surface-0, #fff);
  &:hover,
  &:focus {
    background-color: #fafafa;
  }
  &:active {
    background-color: #fafafa;
  }
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `};
`

const AddPayoutText = styled.p`
  color: var(--button-secondary-background, #64748b);
  font-family: 'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.05rem;
`
