import { TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@mui/material'
import React from 'react'
import { countryCodes } from '@/domains/deals/components/constants/countryCodes'
import { ECountry } from '@/schemaTypes'
import styles from './Countries.module.scss'

export interface CountrySelectProps {
  error?: string
  value: any
  onChange: (countryCode: ECountry | null) => void
  label: string
  marginTop?: string
  disabled?: boolean
}

const options = Object.keys(countryCodes).map((countryKey: ECountry) => ({
  label: countryCodes[countryKey],
  id: countryKey,
}))

export default function CountrySelect(props: CountrySelectProps) {
  const { value, onChange, label, error, marginTop, disabled } = props

  const selectedOption = options.find((option) => option.id === value)

  return (
    <Typography variant="body1" gutterBottom style={{ marginTop: marginTop }}>
      {label}
      <Autocomplete
        disabled={disabled}
        renderInput={(params) => <TextField {...params} />}
        className={styles.input}
        sx={{ width: 400 }}
        value={selectedOption || null}
        onChange={(e, option) => onChange((option as any)?.id ?? null)}
        options={options}
      />
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </Typography>
  )
}
