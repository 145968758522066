import { useAddValorizationHistoryEntryToDealItem } from '../../../hooks'
import { useRegenerateValorizationReceipts } from '../../../hooks/itemDetails/regenerateValorizationReceipts'
import { useRemoveValorizationEntry } from '../../../hooks/itemDetails/removeValorizationEntry'
import { useUpdateSavedValorizationHistoryEntry } from '../../../hooks/itemDetails/updateSavedValorizationEntry'
import { useRefreshItem } from '../../../hooks/itemDetails/useRefreshItem'
import { useRefreshItemValorizations } from '../../../hooks/itemDetails/useRefreshItemValorizations'
import { cloneDeep } from '@apollo/client/utilities'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ShopSelect from '@/components/ShopSelect'
import { Context } from '@/context'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import {
  Deal,
  DealItem,
  ELogisticsPartnerType,
  ESalesPartnerType,
  Item,
  ValorizationEntryCreateArgs,
  ValorizationEntryUpdateArgs,
  ValorizationHistoryEntry,
} from '@/schemaTypes'
import {
  getDefaultManualPaymentType,
  isCreditNote,
  isShopLevelPaymentType,
  paymentTypeUnion,
  stripTypenameDeep,
  transformEmptyStringToNullDeep,
} from '@/utils/misc'
import { createInitialValorizationHistoryEntry } from '@/utils/valorization'
import ValorizationHistory from './ValorizationHistory'
import styles from './ValorizationHistory.module.scss'

export interface ValorizationHistoryContainerProps {
  item: DealItem
  deal: Deal
  itemWithEvents: Item
}

export function ValorizationHistoryContainer(
  props: ValorizationHistoryContainerProps,
) {
  const { item, deal, itemWithEvents } = props
  const { itemValorizationCalculation } = item
  const { closeConfirmModal, showInfo } = useContext(Context)
  const { t } = useTranslation()
  const { getCurrentUser } = useContext(Context)
  const currentUser = getCurrentUser()
  const showConfirmPopup = useShowConfirmPopup({ actionText: t('save') })

  const [
    updatedOrDeleteSavedValorizationInProgress,
    setUpdatedOrDeleteSavedValorizationInProgress,
  ] = useState(0)
  const [valorizationHistory, setValorizationHistory] = useState<
    ValorizationHistoryEntry[]
  >([...(itemValorizationCalculation?.valorizationHistory ?? [])])
  const [showUnsavedEntry, setShowUnsavedEntry] = useState(false)
  const [loadingId, setLoadingId] = useState<string | undefined>(undefined)

  const [addValorizationHistoryEntryToDealItemMutation] =
    useAddValorizationHistoryEntryToDealItem()

  const addValorizationHistoryEntryToDealItem = useMutationShowingErrors({
    mutation: addValorizationHistoryEntryToDealItemMutation,
    successMessage: t('update_successful'),
  })

  useEffect(() => {
    setValorizationHistory([
      ...(itemValorizationCalculation?.valorizationHistory ?? []),
    ])
  }, [itemValorizationCalculation?.valorizationHistory])

  const onAddNewValorizationHistoryEntry = (fillWithPreviousData = false) => {
    setShowUnsavedEntry(true)

    const lastEntry =
      valorizationHistory.length && fillWithPreviousData
        ? valorizationHistory[0]
        : undefined

    const newValorizationHistoryEntry = createInitialValorizationHistoryEntry(
      {
        logisticsPartnerType: ELogisticsPartnerType.Cashy,
        salesPartnerType: ESalesPartnerType.Cashy,
        ...(fillWithPreviousData && { isItemReturned: true }),
        isTaxFreeTransaction: item.isInvestmentGold,
        currentUser,
      },
      lastEntry,
    )
    const newValorizationHistory = [
      newValorizationHistoryEntry,
      ...valorizationHistory,
    ]

    setValorizationHistory(newValorizationHistory)
  }

  const onDeleteUnsavedValorizationHistoryEntry = () => {
    const newValorizationHistory = [...valorizationHistory]

    newValorizationHistory.shift()

    setValorizationHistory(newValorizationHistory)
    setShowUnsavedEntry(false)
  }

  const refreshItem = useMutationShowingErrors({
    mutation: useRefreshItem(),
  })

  const refreshValorizationCalculations = useMutationShowingErrors({
    mutation: useRefreshItemValorizations(),
  })

  const removeValorizationHistoryEntry = useMutationShowingErrors({
    mutation: useRemoveValorizationEntry(),
    successMessage: `${t('valorization_entry.valorization_history_entry_removed_successfully')}!`,
  })

  const updateSavedValorizationHistoryEntry = useMutationShowingErrors({
    mutation: useUpdateSavedValorizationHistoryEntry(),
    successMessage: t('update_successful'),
  })

  const regenerateValorizationHistoryEntryReceipt = useMutationShowingErrors({
    mutation: useRegenerateValorizationReceipts(),
    successMessage: `${t('final_receipt_regenerated_successfully')}!`,
  })

  const onDeleteSavedValorizationHistoryEntry = (valorizationId) => {
    showConfirmPopup({
      title: t('valorization_entry.remove_valorization_entry_confirmation'),
      action: async () => {
        closeConfirmModal()
        setUpdatedOrDeleteSavedValorizationInProgress(valorizationId)
        const res = await removeValorizationHistoryEntry({
          variables: {
            valorizationHistoryId: valorizationId,
          },
        })
        setUpdatedOrDeleteSavedValorizationInProgress(0)
        if (!res.error) {
          refreshItem({
            variables: { itemId: item._id },
          })
          await refreshValorizationCalculations({
            variables: {
              itemId: item._id,
            },
          })
        }
      },
    })
  }

  const onUpdateSavedValorizationHistoryEntry = async (
    data: Partial<ValorizationHistoryEntry>,
  ) => {
    showConfirmPopup({
      title: t('updating_valorization_entry_confirmation'),
      state: {
        shopId: undefined,
      },
      component: (state, setState) => {
        return (
          <>
            {data.paymentData?.paymentType &&
            isShopLevelPaymentType(
              paymentTypeUnion(data.paymentData?.paymentType),
            ) ? (
              <div className={styles.flexCenterWrapper}>
                <p>{t('select_cashbook_for_booking')}:</p>
                <ShopSelect
                  companyId={itemWithEvents.companyId}
                  shopId={state.shopId}
                  onChange={(value) => setState({ ...state, shopId: value })}
                  useDefaultShopId
                  includeDisabled
                />
              </div>
            ) : null}
          </>
        )
      },
      action: async (state) => {
        closeConfirmModal()
        setUpdatedOrDeleteSavedValorizationInProgress(data._id)

        const cleanedData = stripTypenameDeep(
          transformEmptyStringToNullDeep(
            cloneDeep({
              paymentData: data.paymentData,
            }),
          ),
        )

        const args: ValorizationEntryUpdateArgs = {
          ...cleanedData,
          paymentData: {
            ...cleanedData?.paymentData,
            paymentType: getDefaultManualPaymentType(
              cleanedData?.paymentData?.paymentType,
            ),
          },
          _id: data._id,
        }

        await updateSavedValorizationHistoryEntry({
          variables: {
            shopId: state.shopId,
            args: args,
          },
        })

        setUpdatedOrDeleteSavedValorizationInProgress(0)
      },
    })
  }

  const onReGenerateFinalReceipt = (valorizationEntryId) => {
    showConfirmPopup({
      title: t('regenrate_valorization_entry_receipt_confirmation'),
      action: async () => {
        closeConfirmModal()
        setUpdatedOrDeleteSavedValorizationInProgress(valorizationEntryId)
        const res = await regenerateValorizationHistoryEntryReceipt({
          variables: {
            valorizationEntryId: valorizationEntryId,
          },
        })
        setUpdatedOrDeleteSavedValorizationInProgress(0)
        if (!res.error) {
          refreshItem({
            variables: { itemId: item._id },
          })
          await refreshValorizationCalculations({
            variables: {
              itemId: item._id,
            },
          })
        }
      },
    })
  }

  const onSaveValorizationHistoryEntry = async (
    data: Partial<Omit<ValorizationHistoryEntry, 'valorizationBillNumber'>>,
  ) => {
    showConfirmPopup({
      title: t('valorization_entry.create_valorization_entry_confirmation'),
      state: {
        shopId: '',
      },
      component: (state, setState) => {
        return (
          <div className={styles.flexCenterWrapper}>
            <p>{t('select_cashbook_for_booking')}:</p>
            <ShopSelect
              companyId={itemWithEvents.companyId}
              shopId={state.shopId}
              onChange={(value) => setState({ ...state, shopId: value })}
              useDefaultShopId
              includeDisabled
            />
          </div>
        )
      },
      action: async (state) => {
        closeConfirmModal()

        const isItemReturned =
          data.cashflow && data.cashflow < 0
            ? (data.isItemReturned ?? '').toString() === 'true'
              ? true
              : false
            : undefined
        const isTaxFreeTransaction =
          data.cashflow && data.cashflow < 0
            ? (data.isTaxFreeTransaction ?? '').toString() === 'true'
              ? true
              : false
            : undefined

        if (isItemReturned && isTaxFreeTransaction) {
          return showInfo(
            t(
              'partial_refund_and_tax_free_cannot_be_set_to_true_at_the_same_time',
            ),
          )
        }

        setLoadingId(data._id)
        const cleanedDataPartnerArgs = {
          logisticsPartnerData: data.logisticsPartnerData,
          salesPartnerData: data.salesPartnerData,
        }

        const cleanedData = stripTypenameDeep(
          transformEmptyStringToNullDeep(
            cloneDeep({
              ...cleanedDataPartnerArgs,
              cashflow: data.cashflow,
              returnReason:
                data.cashflow && data.cashflow < 0
                  ? data.returnReason
                  : undefined,
              returnReasonDetails:
                data.cashflow && data.cashflow < 0
                  ? data.returnReasonDetails
                  : '',
              isItemReturned: isItemReturned,
              isTaxFreeTransaction: isTaxFreeTransaction,
              date: data.date,
              buyerEmail: data.buyerEmail,
              buyerName: data.buyerName,
              buyerPhone: data.buyerPhone,
              customLabel: `${item.storageLabel}|${deal.bookingNumber}`,
              paymentData: {
                ...data.paymentData,
                paymentType: getDefaultManualPaymentType(
                  data.paymentData?.paymentType,
                ),
              },
              relatedBillId:
                data.cashflow && isCreditNote(data.cashflow)
                  ? data.relatedBillId
                  : undefined,
              buyerBillingAddress: data.buyerBillingAddress,
              buyerShippingAddress: data.buyerShippingAddress,
              sendBill: data.sendBill,
            }),
          ),
        )

        delete data._id

        const args: ValorizationEntryCreateArgs = {
          ...cleanedData,
          itemsIds: [item._id],
        } as ValorizationEntryCreateArgs

        const { error } = await addValorizationHistoryEntryToDealItem({
          variables: {
            args,
            dealId: deal._id,
            itemId: item._id,
            shopId: state.shopId,
          },
        })

        if (!error) {
          setShowUnsavedEntry(false)
          refreshItem({
            variables: { itemId: item._id },
          })
        }

        setLoadingId(undefined)
      },
    })
  }

  return (
    <ValorizationHistory
      deal={deal}
      itemWithEvents={itemWithEvents}
      valorizationHistory={valorizationHistory}
      onAddNewValorizationHistoryEntry={onAddNewValorizationHistoryEntry}
      showUnsavedEntry={showUnsavedEntry}
      onDeleteUnsavedValorizationHistoryEntry={
        onDeleteUnsavedValorizationHistoryEntry
      }
      onDeleteSavedValorizationHistoryEntry={
        onDeleteSavedValorizationHistoryEntry
      }
      onSaveValorizationHistoryEntry={onSaveValorizationHistoryEntry}
      onUpdateSavedValorizationHistoryEntry={
        onUpdateSavedValorizationHistoryEntry
      }
      loadingId={loadingId}
      deleteSavedValorizationInProgress={
        updatedOrDeleteSavedValorizationInProgress
      }
      onReGenerateFinalReceipt={onReGenerateFinalReceipt}
    />
  )
}

export default ValorizationHistoryContainer
