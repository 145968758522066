import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import { Query, QueryGetItemCategoriesArgs } from '@/schemaTypes'

const GET_ITEM_CATEGORIES = gql`
  query getItemCategories($opts: ItemCategoryOpts) {
    getItemCategories(opts: $opts) {
      _id
      name
      parentId
      algoliaCategorySlug
      visibleOnDomain
    }
  }
`

type TData = { getItemCategories: Query['getItemCategories'] }
type TVariables = QueryGetItemCategoriesArgs

export function useGetItemCategories(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_ITEM_CATEGORIES, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    itemCategories: queryResult.data?.getItemCategories || [],
  }
}

export const includeHidedCategories: QueryHookOptions<TData, TVariables> = {
  variables: { opts: { filter: { includeHided: true } } },
}
