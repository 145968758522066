import CustomerSelection from '../../../deals/components/CustomerSelection'
import CustomDealUserData from '../CustomDealUserData'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { FormikErrors } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  CustomDealContactData,
  CustomDealCreateArgs,
  Customer,
  ECustomDealStatusType,
} from '@/schemaTypes'

interface Props {
  contactData: CustomDealContactData
  customerId: string
  onChangeField: (field: string, value: string | null) => void
  status?: ECustomDealStatusType
  customer?: Customer
  errors?: FormikErrors<CustomDealCreateArgs>['contactData']
}

enum DIALOG {
  CHANGE_MAIN_CUSTOMER = 'CHANGE_MAIN_CUSTOMER',
}

export function CustomDealCustomer(props: Props) {
  const { contactData, customerId, onChangeField, customer, errors, status } =
    props
  const { t } = useTranslation()
  const [dialogMode, setDialogMode] = useState<DIALOG>()
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null,
  )
  const isReadonly = [
    ECustomDealStatusType.Canceled,
    ECustomDealStatusType.Closed,
    ECustomDealStatusType.Declined,
    ECustomDealStatusType.Expired,
  ].includes(status)

  const handleOpenDialog = (mode) => {
    setDialogMode(mode)
  }

  const handleCloseDialog = () => {
    setDialogMode(undefined)
  }

  const onConfirmCustomerSelection = () => {
    if (selectedCustomerId) {
      onChangeField('customerId', selectedCustomerId)
      setSelectedCustomerId(null)
      onChangeField('contactData.email', '')
      onChangeField('contactData.phone', '')
      handleCloseDialog()
    }
  }

  return (
    <div className="u-p-sm u-mb-sm">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box alignItems="center" display="flex">
            <Typography variant="h6" gutterBottom className="u-mb-sm">
              {t('customer.label')}
            </Typography>
            {customerId && (
              <Typography variant="subtitle1" gutterBottom className="u-mb-sm">
                <Link to={`/inApp/customers/edit/${customerId}`}>
                  <IconButton aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Link>
              </Typography>
            )}
          </Box>
        </Grid>
        {!isReadonly && (
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              className="u-mr-mini"
              size="small"
              color="secondary"
              variant="outlined"
              disabled={!customerId}
              onClick={() => onChangeField('customerId', null)}
              type="button"
            >
              {t('remove_customer')}
            </Button>
            <Button
              className="u-mr-mini"
              size="small"
              variant="outlined"
              onClick={() => handleOpenDialog(DIALOG.CHANGE_MAIN_CUSTOMER)}
              type="button"
            >
              {t('add_customer')}
            </Button>
          </Grid>
        )}
      </Grid>

      {!customerId && !isReadonly && (
        <Grid container spacing={2} className="u-mb-sm">
          <Grid item xs={6}>
            <TextField
              label={t('customer.email')}
              variant="outlined"
              value={contactData.email}
              fullWidth
              disabled={!!customerId}
              onChange={(e) =>
                onChangeField('contactData.email', e.target.value)
              }
              error={typeof errors === 'string' || !!errors?.email}
              helperText={errors?.email && t(errors?.email)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t('customer.phone')}
              variant="outlined"
              value={contactData.phone}
              fullWidth
              disabled={!!customerId}
              onChange={(e) =>
                onChangeField('contactData.phone', e.target.value)
              }
              error={!!errors?.phone}
              helperText={errors?.phone && t(errors?.phone)}
            />
          </Grid>
        </Grid>
      )}

      {(customer || isReadonly) && (
        <CustomDealUserData customer={customer} contactData={contactData} />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={!!dialogMode}
        onClose={() => handleCloseDialog()}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          {t('add_customer')}
        </DialogTitle>
        <DialogContent>
          <CustomerSelection
            selectedCustomerId={selectedCustomerId}
            setSelectedCustomerId={setSelectedCustomerId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog()} color="primary">
            {t('abort')}
          </Button>

          <Button onClick={onConfirmCustomerSelection} color="primary">
            {t('add')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
