import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import ResponseTemplateRow from '@/domains/responseTemplates/components/ResponseTemplateRow'
import {
  useDeleteResponseTemplate,
  useGetResponseTemplates,
} from '@/domains/responseTemplates/hooks'
import { useConfirmDeleteMutationShowingErrors } from '@/hooks'

export function ResponseTemplatesTable() {
  const { t } = useTranslation()

  const { responseTemplates, queryResult } = useGetResponseTemplates()

  const deleteResponseTemplate = useConfirmDeleteMutationShowingErrors({
    successMessage: t('response_template_deleted'),
    mutation: useDeleteResponseTemplate(),
  })

  const handleDelete = (id: string) => {
    deleteResponseTemplate({
      variables: {
        responseTemplateId: id,
      },
      onCompleted: () => {
        queryResult.refetch()
      },
    })
  }

  return (
    <Paper className={`u-mb`}>
      <ConfigurationBreadcrumbs modelName={'responseTemplates'} />
      <Typography variant="h5" gutterBottom>
        {t('response_templates_configurations')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Table className="u-mb-sm" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('name')}</TableCell>
                <TableCell align="left">{t('subject')}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {responseTemplates.map((responseTemplate) => (
                <ResponseTemplateRow
                  key={responseTemplate._id}
                  responseTemplate={responseTemplate}
                  deleteResponseTemplate={handleDelete}
                />
              ))}
            </TableBody>
          </Table>
          <Link to="/inApp/configurations/responseTemplates/create">
            <Button className="u-mr-mini" variant="contained">
              {t('response_template_create')}
            </Button>
          </Link>
        </Fragment>
      )}
    </Paper>
  )
}

export default ResponseTemplatesTable
