import { Button } from '@material-ui/core'
import { Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemDraftQuestionsFormData } from '@/domains/customDeals/components/CustomDealDetail/CustomDealItemSelect'
import { useCalculateDealItem } from '@/domains/deals/hooks/calculateDealItem'
import { QueryItemCalculationArgs } from '@/gql/graphql'
import { useGetProductLegacy } from '@/hooks'
import { ItemAnswerArgs, Scalars } from '@/schemaTypes'
import ItemCalculation from './ItemCalculation'
import ItemCategoryQuestions from './ItemCategoryQuestions'
import { buildItemCreateArgs } from './buildItemCreateArgs'
import useProductVariantForItemEvaluation from './useProductVariantForItemEvaluation'

type AddOrChangeItemProps = {
  durationInDays: number
  minimumPawnDuration: number
  companyId: Scalars['ObjectId']
  onSaveItemDraft?: (formData: ItemDraftQuestionsFormData) => void
} & (
  | {
      productObjectID: string
    }
  | { productVariantId: string }
)

const AddItemToCustomDeal: FunctionComponent<AddOrChangeItemProps> = (
  props,
) => {
  const { durationInDays, companyId, onSaveItemDraft } = props
  const [itemAnswerArgs, setItemAnswerArgs] = useState<ItemAnswerArgs[]>([])
  const { t } = useTranslation()

  const isLegacyProduct = 'productObjectID' in props && !!props.productObjectID
  const { productLegacy } = useGetProductLegacy({
    variables: isLegacyProduct
      ? {
          opts: {
            filter: {
              objectID: props.productObjectID,
            },
          },
        }
      : undefined,
    skip: !isLegacyProduct,
  })

  const isNewProduct = 'productVariantId' in props && !!props.productVariantId
  const { productVariant } = useProductVariantForItemEvaluation({
    variables: isNewProduct
      ? {
          args: {
            productVariantId: props.productVariantId,
          },
        }
      : undefined,
    skip: !isNewProduct,
  })

  const {
    calculateDealItem: calculatePurchaseDealItem,
    queryResult: calculateDealItemPurchaseData,
  } = useCalculateDealItem()
  const {
    calculateDealItem: calculatePawnDealItem,
    queryResult: calculateDealItemPawnData,
  } = useCalculateDealItem()

  const [showCalculateButton, setShowCalculateButton] = useState(false)

  useEffect(() => {
    setShowCalculateButton(true)
  }, [itemAnswerArgs])

  if (!productLegacy && !productVariant) {
    return null
  }

  const itemCreateArgs = buildItemCreateArgs({
    itemAnswerArgs,
    productLegacy,
    productObjectID: isLegacyProduct ? props.productObjectID : undefined,
    legacyProduct: isLegacyProduct,
    newProduct: isNewProduct,
    productVariantId: isNewProduct ? props.productVariantId : undefined,
    productVariant,
  })

  const onCalculateItem = () => {
    const variables: QueryItemCalculationArgs = {
      ...itemCreateArgs,
      companyId,
      date: new Date(),
      durationInDays: 0,
    }

    calculatePurchaseDealItem({
      variables,
    })

    calculatePawnDealItem({
      variables,
    })

    setShowCalculateButton(false)
  }

  const onSaveButtonClick = async () => {
    onSaveItemDraft &&
      onSaveItemDraft({
        ...itemCreateArgs,
        ean: productLegacy?.ean ?? undefined,
        payoutAmountPawn:
          calculateDealItemPawnData.data?.itemCalculation?.itemValuesEntry
            ?.loanToValue,
        payoutAmountPurchase:
          calculateDealItemPurchaseData.data?.itemCalculation?.itemValuesEntry
            ?.loanToValue,
      })
  }

  const showingCalculation =
    durationInDays > 0
      ? calculateDealItemPawnData.data?.itemCalculation?.itemValuesEntry
      : calculateDealItemPurchaseData.data?.itemCalculation?.itemValuesEntry

  return (
    <Fragment>
      <Paper className="u-p-sm u-12/12 u-mb-sm">
        <Typography
          variant="h5"
          gutterBottom
          style={{
            textAlign: 'left',
          }}
        >
          {isLegacyProduct ? productLegacy?.title : productVariant.name}
        </Typography>
        <ItemCategoryQuestions
          itemCategoryId={
            isLegacyProduct
              ? productLegacy.itemCategoryId
              : productVariant.product.categoryId
          }
          itemAnswerArgs={itemAnswerArgs}
          setItemAnswerArgs={setItemAnswerArgs}
          productLegacy={productLegacy}
          productVariant={productVariant}
          isReadOnly={false}
          omitDeactivatedQuestionsForNewProducts={isNewProduct}
        />
        {Boolean(showingCalculation) && (
          <ItemCalculation
            calculatedItemValuesEntry={
              !showCalculateButton ? showingCalculation : undefined
            }
          />
        )}

        {showCalculateButton && (
          <Button
            onClick={onCalculateItem}
            variant="contained"
            color="primary"
            disabled={false}
            className="u-mb-sm u-mt-sm"
          >
            {t('calculate_values')}
          </Button>
        )}

        {!showCalculateButton && Boolean(showingCalculation) ? (
          <Button
            className="u-mb-sm u-mt-sm"
            onClick={onSaveButtonClick}
            variant="contained"
            color="primary"
            disabled={false}
          >
            {t('item.add_item')}
          </Button>
        ) : null}
      </Paper>
    </Fragment>
  )
}

export default AddItemToCustomDeal
