import withFieldWrapper from '../withFieldWrapper/withFieldWrapper'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { Calendar, CalendarProps } from 'primereact/calendar'

interface DatePickerProps extends CalendarProps {
  isInvalid?: boolean
}

const DatePicker = withFieldWrapper((props: DatePickerProps) => {
  return (
    <CalendarStyled
      className={classNames({ 'p-invalid': props.isInvalid })}
      appendTo={props.appendTo || 'self'}
      {...props}
    />
  )
})

const CalendarStyled = styled(Calendar)`
  height: 2.375rem;
  &&& {
    .p-datepicker {
      width: 408px;
    }
    .p-inputtext {
      padding: 0.56rem;
      font-size: 0.875rem;
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem;
    }
    .p-button {
      color: #6c757d;
      background: transparent;
      border: unset;
      position: absolute;
      right: 0.938rem;
      padding: 0;
      top: 0.375rem;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      width: auto;
    }
    .p-button:focus {
      box-shadow: unset;
    }
  }
`

export default DatePicker
