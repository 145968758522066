import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationUpdateFeeArgs } from '@/schemaTypes'

const UPDATE_FEE = gql`
  mutation updateFee($feeUpdateArgs: FeeUpdateArgs!) {
    updateFee(feeUpdateArgs: $feeUpdateArgs) {
      ...FeeDefinitionFragment
    }
  }
  ${fragments.feeDefinition}
`

type TData = { updateFee: Mutation['updateFee'] }
type TVariables = MutationUpdateFeeArgs

export function useUpdateFee(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateFee] = useMutation<TData, TVariables>(UPDATE_FEE, opts)

  return updateFee
}
