import { FunctionComponent } from 'react'

interface SectionTitleProps {
  title?: string
}

const SectionTitle: FunctionComponent<SectionTitleProps> = ({ title }) => (
  <h2 className="text-black font-semibold text-xl mt-9 mb-3 leading-4">
    {title}
  </h2>
)

export default SectionTitle
