import styled from '@emotion/styled'
import { Box, Button, IconButton, Typography } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { last } from 'lodash'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { GetParentCategories } from '@/components/CategorySelector/GetParentCategories'
import Loading from '@/components/Loading'
import { Context } from '@/context'
import {
  useDeleteCustomDealForm,
  useGetCustomDealForms,
} from '@/domains/customDealForms/hooks'
import {
  includeHidedCategories,
  useGetItemCategories,
} from '@/domains/itemCategories/hooks'
import { useMutationShowingErrors, useShowConfirmDeletePopup } from '@/hooks'

export const CustomDealFormList = () => {
  const { closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()
  const { queryResult } = useGetCustomDealForms()
  const { itemCategories } = useGetItemCategories(includeHidedCategories)
  const showConfirmDeletePopup = useShowConfirmDeletePopup({
    actionText: t('yes'),
    abortActionText: t('no'),
  })

  const customDealForms = useMemo(() => {
    return (
      queryResult.data?.getCustomDealForms?.map((form) => ({
        ...form,
        parentCategories: GetParentCategories(
          form.categoryId,
          itemCategories,
        ).reverse(),
      })) ?? []
    )
  }, [queryResult, itemCategories])

  const deleteCustomDealFormQuestion = useMutationShowingErrors({
    mutation: useDeleteCustomDealForm(),
    successMessage: t(
      'custom_deal_form.custom_deal_form_question_entry_deleted',
    ),
  })

  const onDelete = async (customDealFormId: string) => {
    await showConfirmDeletePopup({
      title: t('custom_deal_form.delete_custom_deal_form'),
      action: () => {
        closeConfirmModal()
        deleteCustomDealFormQuestion({
          variables: {
            customDealFormId,
          },
        })
      },
    })
  }

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h5">{t('custom_deal_form.plural')}</Typography>

        <Button
          color="primary"
          variant="contained"
          component={Link}
          to={'/inApp/configurations/customDealForms/create/'}
          startIcon={<Add />}
        >
          {t('create_form')}
        </Button>
      </Box>

      {queryResult.loading && <Loading />}

      {customDealForms.map((form) => (
        <FormItem key={form._id}>
          <div>
            <Typography variant="body1">
              {last(form.parentCategories)?.name}
            </Typography>
            <Typography variant="body2" style={{ opacity: 0.6 }}>
              {t('category')}:{' '}
              {form.parentCategories
                .map((category) => category.name)
                .join(' > ')}
            </Typography>
          </div>
          <div>
            <Link to={`/inApp/configurations/customDealForms/edit/${form._id}`}>
              <IconButton style={{ padding: '0.5rem' }}>
                <EditIcon color="primary" />
              </IconButton>
            </Link>
            <IconButton
              style={{ padding: '0.5rem' }}
              onClick={() => onDelete(form._id)}
            >
              <DeleteIcon color="secondary" />
            </IconButton>
          </div>
        </FormItem>
      ))}
    </div>
  )
}

const FormItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #21212114;
`
