import { throttle } from 'lodash'
import { useMemo, useState } from 'react'
import useResizeObserver from 'use-resize-observer'

const useThrottledResizeObserver = (wait: number) => {
  const [size, setSize] = useState<{ height?: number }>()

  const onResize = useMemo(() => throttle(setSize, wait), [wait])

  const { ref } = useResizeObserver({ onResize })

  return { ref, ...size }
}

export default useThrottledResizeObserver
