import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationRemoveStorageFacilityIdArgs } from '@/schemaTypes'

const REMOVE_STORAGE_FACILITY_ID = gql`
  mutation removeStorageFacilityId(
    $storageFacilityId: ObjectId!
    $shopId: ObjectId!
  ) {
    removeStorageFacilityId(
      storageFacilityId: $storageFacilityId
      shopId: $shopId
    ) {
      _id
      name
      storageFacilityIds
    }
  }
`

type TData = { removeStorageFacilityId: Mutation['removeStorageFacilityId'] }
type TVariables = MutationRemoveStorageFacilityIdArgs

export function useRemoveStorageFacilityId(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [removeStorageFacilityId] = useMutation<TData, TVariables>(
    REMOVE_STORAGE_FACILITY_ID,
    opts,
  )

  return removeStorageFacilityId
}
