import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import {
  EMaximumPawnDuration,
  EVisibleOnDomain,
  ItemCategory,
  Query,
  QueryGetItemCategoryArgs,
} from '@/schemaTypes'

const GET_ITEM_CATEGORY = gql`
  query getItemCategory($itemCategoryId: ObjectId!) {
    getItemCategory(itemCategoryId: $itemCategoryId) {
      _id
      name
      slug
      algoliaCategorySlug
      categoryPageIds
      categoryPages {
        _id
        lvl0ContentCategory
        lvl1ContentCategory
      }
      parentId
      visibleOnDomain
      expectedTurnOverTime
      helpLink
      maximumPawnDuration
      minimumItemValue
      isValuable
    }
  }
`

type TData = { getItemCategory: Query['getItemCategory'] }
type TVariables = QueryGetItemCategoryArgs

const defaultItemCategory: ItemCategory = {
  _id: '',
  name: '',
  slug: '',
  createdAt: null,
  updatedAt: null,
  visibleOnDomain: EVisibleOnDomain.All,
  availableTransportModes: [],
  maximumPawnDuration: EMaximumPawnDuration.Inherited,
  parentCategories: [],
}

export function useGetItemCategory(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_ITEM_CATEGORY, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.itemCategoryId,
  })

  useShowErrorsPopup(queryResult.error)

  const itemCategory = opts.variables?.itemCategoryId
    ? queryResult.data?.getItemCategory
    : defaultItemCategory
  return { queryResult, itemCategory }
}
