import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateMaterialPriceArgs } from '@/schemaTypes'

const UPDATE_MATERIAL_PRICE = gql`
  mutation updateMaterialPrice(
    $date: DateTime!
    $material: String!
    $price: Float!
    $mu: Float!
    $sigma: Float!
    $_id: ObjectId!
  ) {
    updateMaterialPrice(
      date: $date
      material: $material
      price: $price
      mu: $mu
      sigma: $sigma
      _id: $_id
    ) {
      _id
      date
      material
      price
      mu
      sigma
    }
  }
`

type TData = { updateMaterialPrice: Mutation['updateMaterialPrice'] }
type TVariables = MutationUpdateMaterialPriceArgs

export function useUpdateMaterialPrice(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateMaterialPrice] = useMutation<TData, TVariables>(
    UPDATE_MATERIAL_PRICE,
    opts,
  )

  return updateMaterialPrice
}
