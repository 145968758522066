import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationCreateShopArgs } from '@/schemaTypes'

const CREATE_SHOP = gql`
  mutation createShop(
    $name: String!
    $phone: String!
    $email: String!
    $googleMapsLink: String!
    $address: IAddress!
    $openingHours: String!
    $partnerId: ObjectId
    $hideLocationOnWebsite: Boolean
    $disabled: Boolean
    $successionShopId: ObjectId
    $cashBoxNames: [String!]
    $itemCategoryIds: [ObjectId!]
    $companyId: ObjectId!
    $ready2OrderApiKey: String
  ) {
    createShop(
      name: $name
      phone: $phone
      email: $email
      address: $address
      openingHours: $openingHours
      googleMapsLink: $googleMapsLink
      partnerId: $partnerId
      hideLocationOnWebsite: $hideLocationOnWebsite
      disabled: $disabled
      successionShopId: $successionShopId
      cashBoxNames: $cashBoxNames
      itemCategoryIds: $itemCategoryIds
      companyId: $companyId
      ready2OrderApiKey: $ready2OrderApiKey
    ) {
      _id
      name
      phone
      email
      googleMapsLink
      company {
        _id
        name
      }
      address {
        ...AddressFragment
      }
      partnerId
      hideLocationOnWebsite
      disabled
      cashBoxNames
      itemCategoryIds
      itemCategories {
        _id
        name
        parentId
      }
      openingHours
    }
  }
  ${fragments.address}
`

type TData = { createShop: Mutation['createShop'] }
type TVariables = MutationCreateShopArgs

export function useCreateShop(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createShop] = useMutation<TData, TVariables>(CREATE_SHOP, opts)

  return createShop
}
