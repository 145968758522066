import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
} from '@material-ui/core'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import SelectOptionInput from '@/components/SelectOptionInput'
import StorageUnitsConfiguration from '@/domains/storageUnits/components'
import { Item, StorageFacility } from '@/schemaTypes'
import { GroupItemsUpdateArgs } from './ItemOverviewTable'

interface AddDealItemsStorageUnitProps {
  item?: Item
  state: GroupItemsUpdateArgs
  setState: (value: GroupItemsUpdateArgs) => void
  storageFacilities: StorageFacility[]
  eventPayedAndStoredPossible: boolean
}
const AddDealItemsStorageUnit: FunctionComponent<
  AddDealItemsStorageUnitProps
> = (props) => {
  const { t } = useTranslation()
  const {
    state,
    setState,
    storageFacilities,
    eventPayedAndStoredPossible,
    item,
  } = props

  return (
    <Paper style={{ textAlign: 'left' }} className="u-p-sm u-12/12 u-mb-sm">
      <SelectOptionInput
        value={state.storageFacilityId}
        onChange={(value) => {
          setState({ ...state, storageFacilityId: value })
        }}
        label={t('storage.storage_facilities')}
        menuItems={storageFacilities.map((sf) => ({
          label: sf.name,
          value: sf._id,
        }))}
        hasAsterisk={true}
      />
      <Box style={{ marginTop: '2rem' }}>
        <StorageUnitsConfiguration
          storageFacilityId={state.storageFacilityId}
          controlBarStatus={false}
          hideActionButtons={true}
          item={item}
          onChangeStorageUnits={(data) => {
            setState({
              ...state,
              storageUnits: {
                lvl1: data.lvl1,
                lvl2: data.lvl2,
                lvl3: data.lvl3,
                lvl4: data.lvl4,
              },
            })
          }}
        />
      </Box>

      <Box style={{ marginTop: '2rem' }}>
        <TextField
          style={{
            width: '60%',
          }}
          id="note"
          label={t('note') + ' :'}
          value={state.note}
          onChange={(e) => {
            setState({ ...state, note: e.target.value })
          }}
          variant="standard"
        />
      </Box>

      {eventPayedAndStoredPossible && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(state.setDealToPayedAndStored)}
              onChange={(e) => {
                setState({
                  ...state,
                  setDealToPayedAndStored: e.target.checked,
                })
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('storage.last_item_stored')}
          style={{ marginTop: '30px' }}
        />
      )}
    </Paper>
  )
}

export default AddDealItemsStorageUnit
