import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationAddItemToStorageUnitArgs } from '@/schemaTypes'

const ADD_ITEM_TO_STORAGE_UNIT = gql`
  mutation addItemToStorageUnit(
    $itemId: ObjectId!
    $storageUnits: ItemStorageUnitsArgs!
    $storageDate: DateTime
    $note: String
    $setDealToPayedAndStored: Boolean
  ) {
    addItemToStorageUnit(
      itemId: $itemId
      storageUnits: $storageUnits
      storageDate: $storageDate
      note: $note
      setDealToPayedAndStored: $setDealToPayedAndStored
    ) {
      _id
      storageHistory {
        storageUnits {
          lvl1
          lvl2
          lvl3
          lvl4
        }
        createdAt
        timestamp
        storageStatus
        employeeId
        note
      }
      storageLabel
    }
  }
`

type TData = { addItemToStorageUnit: Mutation['addItemToStorageUnit'] }
type TVariables = MutationAddItemToStorageUnitArgs

export function useAddItemToStorageUnit(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [addItemToStorageUnit] = useMutation<TData, TVariables>(
    ADD_ITEM_TO_STORAGE_UNIT,
    opts,
  )

  return addItemToStorageUnit
}
