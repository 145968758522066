import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import EmployeeCreationDialog from '@/domains/employees/components/EmployeeCreationDialog'
import styles from '@/domains/employees/components/EmployeesConfiguration.module.scss'
import EmployeesRow from '@/domains/employees/components/EmployeesRow'
import {
  useDeactivateEmployee,
  useGetActiveEmployees,
} from '@/domains/employees/hooks'
import { useConfirmDeleteMutationShowingErrors } from '@/hooks'

export function EmployeesTable() {
  const { t } = useTranslation()
  const [showCreationModal, setShowCreationModal] = useState(false)

  const { employees, queryResult } = useGetActiveEmployees()

  const deactivateEmployee = useConfirmDeleteMutationShowingErrors({
    successMessage: t('employee.employee_deleted'),
    mutation: useDeactivateEmployee(),
  })

  const _handleDeleteClick = async (id: string) => {
    deactivateEmployee({
      variables: {
        employeeId: id,
      },
      onCompleted: () => {
        queryResult.refetch()
      },
    })
  }

  return (
    <Paper className={`${styles.root} u-mb`}>
      <ConfigurationBreadcrumbs modelName={'employees'} />
      <Typography variant="h5" gutterBottom>
        {t('employee.employees_configurations')}
      </Typography>
      {queryResult.loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Table className="u-mb-sm" size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('id')}</TableCell>
                <TableCell align="right">{t('customer.email')}</TableCell>
                <TableCell align="right">{t('customer.firstname')}</TableCell>
                <TableCell align="right">{t('customer.lastname')}</TableCell>
                <TableCell align="right">
                  {t('customer.date_of_birth')}
                </TableCell>
                <TableCell align="right">{t('customer.phone')}</TableCell>
                <TableCell align="right">{t('customer.sex')}</TableCell>
                <TableCell align="right">{t('customer.role')}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(employees).map((eKey) => (
                <EmployeesRow
                  key={eKey}
                  employee={employees[eKey]}
                  deleteEmployee={() => _handleDeleteClick(employees[eKey]._id)}
                />
              ))}
            </TableBody>
          </Table>
          <Button
            className="u-mr-mini"
            variant="contained"
            onClick={() => setShowCreationModal(true)}
          >
            {t('employee.employee_create')}
          </Button>
          <EmployeeCreationDialog
            visible={showCreationModal}
            setVisible={setShowCreationModal}
          />
        </Fragment>
      )}
    </Paper>
  )
}

export default EmployeesTable
