import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import TextInput from '@/components/TextInput'
import {
  CompanySalesConfiguration,
  RefurbedStaggeredMargin,
} from '@/schemaTypes'
import { CompanySalesConfigurationValidation } from '@/validation'

const useStyles = makeStyles((theme) => ({
  configurationContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  configurationUnitContainer: {
    padding: theme.spacing(1),
  },
  internalValorizationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
}))

export interface CompanySalesConfigurationDetailsProps {
  salesConfiguration?: CompanySalesConfiguration
  setSalesConfiguration: (value: CompanySalesConfiguration) => void
  validationErrorsMap?: Partial<
    Record<keyof CompanySalesConfigurationValidation, any>
  >
}

export function CompanySalesConfigurationDetails(
  props: CompanySalesConfigurationDetailsProps,
) {
  const { salesConfiguration, setSalesConfiguration, validationErrorsMap } =
    props
  const { t } = useTranslation()
  const classes = useStyles()

  const onUpdateRefurbedStaggeredMargins = (
    value: string,
    index: number,
    fieldName: string,
  ) => {
    const cloneRefurbedStaggeredMargins = cloneDeep(
      salesConfiguration!.refurbedStaggeredMargins,
    )
    const currentRefurbedStaggeredMargins = cloneRefurbedStaggeredMargins[index]
    currentRefurbedStaggeredMargins[fieldName] = parseFloat(value)

    setSalesConfiguration({
      ...salesConfiguration!,
      refurbedStaggeredMargins: cloneRefurbedStaggeredMargins,
    })
  }

  const onAddNewMarginRow = () => {
    const cloneRefurbedStaggeredMargins: RefurbedStaggeredMargin[] = cloneDeep(
      salesConfiguration!.refurbedStaggeredMargins,
    )
    cloneRefurbedStaggeredMargins.push({
      from: cloneRefurbedStaggeredMargins[
        cloneRefurbedStaggeredMargins.length - 1
      ].from,
      expectedMargin: 0.1,
      minimumMargin: 0.05,
    })
    setSalesConfiguration({
      ...salesConfiguration!,
      refurbedStaggeredMargins: cloneRefurbedStaggeredMargins,
    })
  }

  const onRemoveMarginRow = (index: number) => {
    const cloneRefurbedStaggeredMargins: RefurbedStaggeredMargin[] = cloneDeep(
      salesConfiguration!.refurbedStaggeredMargins,
    )
    cloneRefurbedStaggeredMargins.splice(index, 1)
    setSalesConfiguration({
      ...salesConfiguration!,
      refurbedStaggeredMargins: cloneRefurbedStaggeredMargins,
    })
  }

  const refurbedStaggeredMarginsLength =
    salesConfiguration?.refurbedStaggeredMargins?.length || 0
  const refurbedStaggeredMarginsRendering =
    salesConfiguration?.refurbedStaggeredMargins.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell className={classes.internalValorizationContainer}>
            <Box className={classes.configurationContainer}>
              <TextInput
                disabled={index === 0}
                type="number"
                error={
                  validationErrorsMap?.refurbedStaggeredMargins &&
                  validationErrorsMap.refurbedStaggeredMargins[index]?.from
                }
                value={item.from.toString()}
                onChange={(value) =>
                  onUpdateRefurbedStaggeredMargins(value, index, 'from')
                }
              />
              <Typography className={classes.configurationUnitContainer}>
                €
              </Typography>
            </Box>

            <Box className={classes.configurationContainer}>
              <TextInput
                disabled
                type="number"
                value={
                  index + 1 < refurbedStaggeredMarginsLength
                    ? salesConfiguration?.refurbedStaggeredMargins[
                        index + 1
                      ].from.toString()
                    : ''
                }
              />
              <Typography className={classes.configurationUnitContainer}>
                €
              </Typography>
            </Box>
          </TableCell>
          <TableCell>
            <TextInput
              type="number"
              error={
                validationErrorsMap?.refurbedStaggeredMargins &&
                validationErrorsMap.refurbedStaggeredMargins[index]
                  ?.expectedMargin
              }
              value={item.expectedMargin.toString()}
              onChange={(value) =>
                onUpdateRefurbedStaggeredMargins(value, index, 'expectedMargin')
              }
            />
          </TableCell>
          <TableCell>
            <TextInput
              type="number"
              error={
                validationErrorsMap?.refurbedStaggeredMargins &&
                validationErrorsMap.refurbedStaggeredMargins[index]
                  ?.minimumMargin
              }
              value={item.minimumMargin.toString()}
              onChange={(value) =>
                onUpdateRefurbedStaggeredMargins(value, index, 'minimumMargin')
              }
            />
          </TableCell>
          {index !== 0 && (
            <TableCell>
              <RemoveCircleOutlineIcon
                onClick={() => onRemoveMarginRow(index)}
                fontSize="small"
                style={{ cursor: 'pointer', marginLeft: '1rem' }}
              />
            </TableCell>
          )}
        </TableRow>
      )
    })

  return (
    <>
      <Table className="u-mb-sm" size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('internal_valorization')}</TableCell>
            <TableCell>{t('expected_margin')}</TableCell>
            <TableCell>{t('min_margin')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.internalValorizationContainer}>
              <Typography className={classes.configurationUnitContainer}>
                {t('from')}
              </Typography>
              <Typography className={classes.configurationUnitContainer}>
                {t('to_exclusive')}
              </Typography>
            </TableCell>
          </TableRow>
          {refurbedStaggeredMarginsRendering}
        </TableBody>
      </Table>
      <Box
        className={classes.configurationContainer}
        style={{ alignItems: 'center', marginBottom: '0.5rem' }}
      >
        <AddCircleOutlineIcon
          onClick={onAddNewMarginRow}
          fontSize="small"
          style={{ cursor: 'pointer', marginLeft: '1rem' }}
        />
        <Typography style={{ marginLeft: '0.5rem' }}>
          {t('add_new_margin')}
        </Typography>
      </Box>
      <Box className={classes.configurationContainer}>
        <TextInput
          type="number"
          error={validationErrorsMap?.refurbedCommission}
          value={salesConfiguration?.refurbedCommission.toString()}
          onChange={(value) =>
            setSalesConfiguration({
              ...salesConfiguration!,
              refurbedCommission: parseFloat(value),
            })
          }
          label={t('refurbed_commission')}
        />
      </Box>
    </>
  )
}

export default CompanySalesConfigurationDetails
