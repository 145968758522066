import * as React from 'react'
import { FunctionComponent } from 'react'
import { useState } from 'react'
import UsedFeeDefinitionViewerWrapper from '@/components/UsedFeeDefinitionViewerWrapper'
import UsedFeeDefinitionListView from '@/components/UsedFeeDefinitionViewerWrapper/UsedFeeDefinitionListView'
import UsedFeeDefinitionModularView from '@/components/UsedFeeDefinitionViewerWrapper/UsedFeeDefinitionModularView'
import { cashyDocumentLinks } from '@/constants'
import { CustomDeal, CustomDealCalculation } from '@/schemaTypes'
import { isVehicle } from '@/utils/misc'

interface CustomDealUsedFeeDefinitionViewerProps {
  customDeal: CustomDeal
  customDealCalculation: CustomDealCalculation
  showLayoutButton: boolean
  defaultLayout: 'list' | 'module'
  showItemDetailsButton: boolean // This is just for list view
  showChargTableText: boolean // This is just for modular View
  itemRecordsButtonDefaultValue: boolean // This is just for list view
}

const CustomDealUsedFeeDefinitionViewer: FunctionComponent<
  CustomDealUsedFeeDefinitionViewerProps
> = (props) => {
  const {
    defaultLayout,
    showChargTableText,
    customDealCalculation,
    customDeal,
    itemRecordsButtonDefaultValue,
  } = props

  const [view, setView] = useState(defaultLayout)
  const [itemRecordsView, setItemRecordsView] = useState(
    itemRecordsButtonDefaultValue,
  )

  const isCarPawn = React.useMemo(
    () => !!customDeal.items?.some((item) => isVehicle(item.itemCategoryId)),
    [customDeal],
  )

  return (
    <UsedFeeDefinitionViewerWrapper
      {...props}
      onChangeView={(view) => setView(view)}
      onChangeItemRecordsView={(itemRecordsView) =>
        setItemRecordsView(itemRecordsView)
      }
    >
      {view === 'module' ? (
        <UsedFeeDefinitionModularView
          items={customDeal.items}
          appliedUsedFeeDefinitions={
            customDealCalculation.appliedUsedFeeDefinitions
          }
          durationInDays={customDealCalculation.dealValuesEntry.durationInDays}
          feeScheduleLink={
            !isCarPawn
              ? cashyDocumentLinks.feesCashyLink
              : cashyDocumentLinks.feesCashyAutomotiveLink
          }
          companyCountryCode={customDeal.company.headquarter?.countryCode}
          itemRecordsView={itemRecordsView}
          showChargTableText={showChargTableText}
        />
      ) : (
        <UsedFeeDefinitionListView
          appliedUsedFeeDefinitions={
            customDealCalculation.appliedUsedFeeDefinitions
          }
          itemRecordsView={itemRecordsView}
          items={customDeal.items}
          companyCountryCode={customDeal.company.headquarter?.countryCode}
          calculationDate={customDealCalculation.date}
        />
      )}
    </UsedFeeDefinitionViewerWrapper>
  )
}

export default CustomDealUsedFeeDefinitionViewer
