import EnhancedToolbar from '../EnhancedToolbar'
import TextInput from '../TextInput'
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface ItemQuestionsFeatureIdsTableProps {
  featureIds: string[]
  onAdd: (id: string) => void
  onDelete: (ids: string[]) => void
}

export default function ItemQuestionsFeatureIdsTable(
  props: ItemQuestionsFeatureIdsTableProps,
) {
  const { featureIds, onAdd, onDelete } = props
  const { t } = useTranslation()

  const [selected, setSelected] = useState<string[]>([])
  const [featureId, setFeatureId] = useState<string>('')

  const handleClick = (name: string) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  const handleDeleteClick = (featureIds: string[]) => {
    onDelete(featureIds)
    setSelected([])
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  return (
    <Fragment>
      <TextInput
        value={featureId}
        onChange={(value) => setFeatureId(value)}
        label={t('old_item_question_ids')}
      />
      <Button
        onClick={() => onAdd(featureId)}
        variant="contained"
        disabled={!featureId}
      >
        {t('add_new_old_item_question_id')}
      </Button>
      {featureIds.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('old_item_question_id')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(featureIds).map((key) => {
                const isItemSelected = isSelected(featureIds[key])
                return (
                  <TableRow
                    hover
                    onClick={() => handleClick(featureIds[key])}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={key}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected} />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      {featureIds[key]}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <EnhancedToolbar
            selected={selected}
            onDelete={() => handleDeleteClick(selected)}
          />
        </TableContainer>
      )}
    </Fragment>
  )
}
