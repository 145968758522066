import {
  CustomDealFormOption,
  NumberRestriction,
  createInitialMultipleLanguage,
  createInitialNumberRestriction,
  getConditionOperatorLabel,
} from '../helpers'
import styled from '@emotion/styled'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Input,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { MultipleLanguageInput } from '@/components/MultipleLanguageInput'
import { ENumberFilterConditions } from '@/schemaTypes'

interface Props {
  options?: CustomDealFormOption[]
  allowEnterAnswer?: boolean
  restrictions?: NumberRestriction[]
  setFieldValue: (field: string, value: unknown) => void
}

export const NumberOptions = ({
  options,
  allowEnterAnswer,
  setFieldValue,
  restrictions,
}: Props) => {
  const { t } = useTranslation()

  const onAddOption = () => {
    setFieldValue('options', [
      ...(options ?? []),
      { default: false, label: createInitialMultipleLanguage() },
    ])
  }

  const onRemoveOption = (index: number) => {
    setFieldValue(
      'options',
      (options ?? []).filter((_, i) => i !== index),
    )
  }

  const onAddRestrictionOption = () => {
    setFieldValue('restrictions', [
      ...(restrictions ?? []),
      createInitialNumberRestriction(),
    ])
  }

  const onRemoveRestrictionOption = (index: number) => {
    setFieldValue(
      'restrictions',
      (restrictions ?? []).filter((_, i) => i !== index),
    )
  }

  return (
    <Box mb="1rem">
      <Typography variant="h6" gutterBottom>
        {t('options')}
      </Typography>

      {options?.map((option, i) => (
        <Option key={i}>
          <MultipleLanguageInput
            className="text-input"
            type="number"
            values={option.label}
            onChange={(value) => setFieldValue(`options.[${i}].label`, value)}
          />
          <DefaultCheckBox
            control={
              <Checkbox
                checked={option.default ?? false}
                onChange={() =>
                  setFieldValue(`options.[${i}].default`, !option.default)
                }
              />
            }
            label={t('default')}
          />
          <IconButton
            style={{ padding: '0.5rem' }}
            onClick={() => onRemoveOption(i)}
          >
            <CloseIcon />
          </IconButton>
        </Option>
      ))}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="1rem"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={allowEnterAnswer ?? false}
              onChange={() =>
                setFieldValue('allowEnterAnswer', !allowEnterAnswer)
              }
            />
          }
          label={t('allow_enter_answer')}
        />
        <Button
          color="primary"
          size="small"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={onAddOption}
        >
          {t('add_option')}
        </Button>
      </Box>

      <Typography variant="h6" gutterBottom>
        {t('restrictions')}
      </Typography>

      {restrictions?.map((restriction, i) => (
        <RestrictionOption key={i}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography variant="body1" gutterBottom>
              {t('condition')}
            </Typography>

            <IconButton
              style={{ padding: '0.5rem' }}
              onClick={() => onRemoveRestrictionOption(i)}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box display="flex" marginBottom="1rem">
            <Select
              className="select"
              value={restriction.conditionOperator}
              onChange={(e) =>
                setFieldValue(
                  `restrictions.[${i}].conditionOperator`,
                  e.target.value,
                )
              }
            >
              {Object.values(ENumberFilterConditions).map((condition) => (
                <MenuItem key={condition} value={condition}>
                  {getConditionOperatorLabel(condition)}
                </MenuItem>
              ))}
            </Select>

            <Input
              type="number"
              value={restriction.conditionValue}
              onChange={(e) =>
                setFieldValue(
                  `restrictions.[${i}].conditionValue`,
                  e.target.value,
                )
              }
            />
          </Box>

          <Typography variant="body1" gutterBottom>
            {t('error.error_message')}
          </Typography>

          <MultipleLanguageInput
            className="text-input"
            values={restriction.errorMessage}
            onChange={(value) =>
              setFieldValue(`restrictions.[${i}].errorMessage`, value)
            }
          />
        </RestrictionOption>
      ))}

      <AddRestriction>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={onAddRestrictionOption}
        >
          {t('add_restriction')}
        </Button>
      </AddRestriction>
    </Box>
  )
}

const AddRestriction = styled.div`
  margin-top: 1rem;
  text-align: right;
`

const Option = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;

  .text-input {
    flex: 1;
    margin-right: 1rem;
  }
`

const RestrictionOption = styled.div`
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 1rem;

  .select {
    margin-right: 1rem;
    width: 6rem;
  }
`

const DefaultCheckBox = styled(FormControlLabel)`
  position: absolute;
  bottom: 1rem;
  right: 3rem;
`
