import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Mutation,
  MutationUpdateDealItemInternalSaleDataArgs,
} from '@/schemaTypes'
import { dealCalculationFragments, dealFragments } from './dealFragments'

const UPDATE_DEAL_ITEM_INTERNAL_SALE_DATA = gql`
  mutation updateDealItemInternalSaleData(
    $dealId: ObjectId!
    $itemId: ObjectId!
    $args: InternalSaleDataArgs!
  ) {
    updateDealItemInternalSaleData(
      dealId: $dealId
      itemId: $itemId
      args: $args
    ) {
      _id
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
    }
  }
  ${dealFragments.dealItem}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = {
  updateDealItemInternalSaleData: Mutation['updateDealItemInternalSaleData']
}
type TVariables = MutationUpdateDealItemInternalSaleDataArgs

export function useUpdateDealItemInternalSaleData(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateDealItemInternalSaleData] = useMutation<TData, TVariables>(
    UPDATE_DEAL_ITEM_INTERNAL_SALE_DATA,
    opts,
  )

  return updateDealItemInternalSaleData
}
