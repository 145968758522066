import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useContext } from 'react'
import { Context } from '@/context'
import { fragments } from '@/hooks/fragments'
import {
  ELanguageCode,
  Mutation,
  MutationRefreshAuctionArgs,
} from '@/schemaTypes'
import { auctionFragments } from './auctionFragments'

const REFRESH_AUCTION = gql`
  mutation refreshAuction(
    $auctionId: ObjectId!
    $languageCode: ELanguageCode!
  ) {
    refreshAuction(auctionId: $auctionId) {
      ...AuctionDetailsFragment
    }
  }
  ${fragments.customDealFormQuestion}
  ${fragments.formAnswers}
  ${auctionFragments.auctionDetails}
  ${fragments.address}
`

type TData = { refreshAuction: Mutation['refreshAuction'] }
type TVariables = { languageCode?: ELanguageCode } & MutationRefreshAuctionArgs

export function useRefreshAuction(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const { language } = useContext(Context)

  const [refreshAuction] = useMutation<TData, TVariables>(REFRESH_AUCTION, {
    ...opts,
    variables: { ...(opts.variables as TVariables), languageCode: language },
  })
  return refreshAuction
}
