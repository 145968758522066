import { QueryHookOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import useDebouncedQuery from '@/hooks/useDebouncedQuery'
import { Query, QueryGetEnabledPostalCodesArgs } from '@/schemaTypes'

const GET_ENABLED_POSTAL_CODES = gql`
  query getEnabledPostalCodes($companyId: ObjectId!, $text: String) {
    getEnabledPostalCodes(companyId: $companyId, text: $text) {
      _id
      postalCode
      postalName
      country
    }
  }
`

type TData = { getEnabledPostalCodes: Query['getEnabledPostalCodes'] }
type TVariables = QueryGetEnabledPostalCodesArgs

export function useGetEnabledPostalCodes(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useDebouncedQuery<TData, TVariables>(
    GET_ENABLED_POSTAL_CODES,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
    200,
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    enablePostalCodes: queryResult.data?.getEnabledPostalCodes,
  }
}
