import {
  Chip,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import React, { ReactElement } from 'react'
import styles from './SelectOptionInput.module.scss'

export interface MenuItemOption<T> {
  label: string
  value: T
  disabled?: boolean
}
export interface SelectOptionInputProps<
  T extends string | number | string[] | boolean | null,
> {
  className?: string
  error?: string
  value: any
  inputWidth?: string
  onChange?: (value: T) => void
  onChangeNullable?: (value: T | null) => void
  onChangeMultiple?: (value: T[]) => void
  label?: string | JSX.Element
  menuItems: MenuItemOption<T>[]
  multiple?: boolean
  input?: ReactElement<any, any> | undefined
  defaultValue?: string | null
  disabled?: boolean
  renderSaveButton?: () => JSX.Element
  style?: React.CSSProperties
  selectProps?: SelectProps
  hasNone?: boolean
  hasAsterisk?: boolean
  readOnly?: boolean
  showResetButton?: boolean
  onReset?: () => void
  isStriked?: boolean
  idPrefix?: string
}

/**
 * If a menu item value is null, the onChange event will also receive null as a value.
 *
 * @param props
 */
export default function SelectOptionInput<
  T extends string | number | string[] | boolean | null,
>(props: SelectOptionInputProps<T>) {
  const {
    error,
    value,
    inputWidth,
    onChange,
    onChangeNullable,
    onChangeMultiple,
    label,
    menuItems,
    multiple,
    input,
    defaultValue,
    disabled,
    className,
    renderSaveButton,
    style,
    selectProps,
    hasNone,
    hasAsterisk,
    readOnly,
    showResetButton,
    onReset,
    isStriked,
    idPrefix,
  } = props

  return (
    <Typography
      component="div"
      style={style}
      variant="body1"
      gutterBottom
      className={`${className ?? ''}`}
    >
      <span style={{ whiteSpace: 'nowrap' }}>{label ?? ''}</span>
      {hasAsterisk && <span className={styles.alert}>*</span>}
      <Select
        {...selectProps}
        id={`${idPrefix ? idPrefix : ''}SELECT`}
        className="u-ml-sm"
        value={value ?? ''}
        readOnly={readOnly}
        style={{
          width: inputWidth,
          textDecoration: isStriked ? 'line-through' : 'inherit',
        }}
        onChange={(e) => {
          if (typeof onChange === 'function') {
            onChange(e.target.value as T)
          }

          if (typeof onChangeNullable === 'function') {
            onChangeNullable((e.target.value as T) ?? null)
          }

          if (typeof onChangeMultiple === 'function') {
            onChangeMultiple(e.target.value as T[])
          }
        }}
        disabled={disabled}
        error={!!error ?? false}
        multiple={multiple}
        input={input}
        defaultValue={defaultValue}
        renderValue={
          multiple
            ? (selected: any) => (
                <div>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </div>
              )
            : undefined
        }
      >
        {hasNone && (
          <MenuItem key={'none'} value={undefined}>
            <em>None</em>
          </MenuItem>
        )}
        {menuItems.map((menuItem) => (
          <MenuItem
            id={`${idPrefix ? idPrefix : ''}${menuItem.value?.toString()}`}
            key={menuItem.label}
            value={(menuItem.value as any) ?? undefined}
            disabled={menuItem.disabled}
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </Select>
      {showResetButton && value && (
        <DeleteForeverIcon
          style={{ cursor: 'pointer', marginBottom: -8 }}
          onClick={() => onReset?.()}
        />
      )}

      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
      {renderSaveButton && renderSaveButton()}
    </Typography>
  )
}
