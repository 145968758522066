import { Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ExpansionBox from '@/components/ExpansionBox/ExpansionBox'
import styles from '@/domains/privateSales/components/PrivateSaleDetails/PrivateSaleDetails.module.scss'
import PrivateSaleDetailsCustomerOverView from '@/domains/privateSales/components/PrivateSaleDetails/PrivateSaleDetailsCustomerOverView'
import PrivateSaleDetailsDealOverView from '@/domains/privateSales/components/PrivateSaleDetails/PrivateSaleDetailsDealOverView'
import PrivateSaleDetailsItemOverView from '@/domains/privateSales/components/PrivateSaleDetails/PrivateSaleDetailsItemOverView'
import PrivateSaleTransactionsList from '@/domains/privateSales/components/PrivateSaleDetails/PrivateSaleTransactionsList'
import { useGetPrivateSaleById } from '@/domains/privateSales/hooks/getPrivateSaleById'
import { useNestedState } from '@/utils/misc'
import { defaultTimezone } from '@/utils/time'

interface expansionBoxFilterInput {
  expandedCustomer?: boolean
  expandedItem?: boolean
  expandedGeneral?: boolean
  expandedDeal?: boolean
  expandedTrasnactions?: boolean
}

export function PrivateSaleDetails() {
  const { privateSaleId = '' } = useParams() as any
  const { t } = useTranslation()

  const { privateSale } = useGetPrivateSaleById({
    variables: { privateSaleId },
    skip: !privateSaleId,
  })

  const expansionBoxFilter = useNestedState<expansionBoxFilterInput>(
    {},
    {
      persistent: 'expansionBoxFilter',
    },
  )

  const handleExpansionBoxes = (value, name) => {
    if (name === 'expandedCustomer') {
      expansionBoxFilter.setProperty('expandedCustomer', value)
    } else if (name === 'expandedDeal') {
      expansionBoxFilter.setProperty('expandedDeal', value)
    } else if (name === 'expandedItem') {
      expansionBoxFilter.setProperty('expandedItem', value)
    } else if (name === 'expandedTrasnactions') {
      expansionBoxFilter.setProperty('expandedTrasnactions', value)
    }

    expansionBoxFilter.persist()
  }

  if (!privateSale) {
    return null
  }
  return (
    <div className={`u-12/12 u-mb-sm ${styles.pathTree}`}>
      {privateSale && (
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <ExpansionBox
            title={t('deal_information')}
            name={'expandedDeal'}
            openAtLoad={expansionBoxFilter.values.expandedDeal ?? false}
            onClickBox={(value, name) => handleExpansionBoxes(value, name)}
          >
            <PrivateSaleDetailsDealOverView
              privateSaleId={privateSaleId}
              dealId={privateSale.dealId}
            />
          </ExpansionBox>

          <ExpansionBox
            title={t('item_information')}
            name={'expandedItem'}
            openAtLoad={expansionBoxFilter.values.expandedItem ?? false}
            onClickBox={(value, name) => handleExpansionBoxes(value, name)}
          >
            <PrivateSaleDetailsItemOverView itemId={privateSale.itemId} />
          </ExpansionBox>

          <ExpansionBox
            title={t('customer_information')}
            name={'expandedCustomer'}
            openAtLoad={expansionBoxFilter.values.expandedCustomer ?? false}
            onClickBox={(value, name) => handleExpansionBoxes(value, name)}
          >
            <PrivateSaleDetailsCustomerOverView dealId={privateSale.dealId} />
          </ExpansionBox>

          <ExpansionBox
            title={t('private_sale_transactions')}
            name={'expandedTrasnactions'}
            openAtLoad={expansionBoxFilter.values.expandedTrasnactions ?? false}
            onClickBox={(value, name) => handleExpansionBoxes(value, name)}
          >
            <PrivateSaleTransactionsList
              privateSale={privateSale}
              timezone={defaultTimezone}
              itemId={privateSale.itemId}
            />
          </ExpansionBox>
        </Paper>
      )}
    </div>
  )
}

export default PrivateSaleDetails
