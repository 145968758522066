import { ALL_COMPANIES } from '../../../components/CompanySwitch/CompanySwitch'
import styled from '@emotion/styled'
import { Box, Button, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { omit } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScrollSkipLimit from '@/components/InfiniteScrollSkipLimit/InfiniteScrollSkipLimit'
import Info from '@/components/Info'
import Loading from '@/components/Loading'
import { SearchWrapper } from '@/components/Search'
import {
  includeHidedCategories,
  useGetItemCategories,
} from '@/domains/itemCategories/hooks'
import ItemElement from '@/domains/items/components/ItemElement'
import ItemsSearch, {
  ItemsFilters,
} from '@/domains/items/components/ItemSearch'
import styles from '@/domains/items/components/ItemsList.module.scss'
import { useGetActiveStorageFacilities } from '@/domains/items/hooks'
import { useUpdateItemEvent } from '@/domains/items/hooks/itemDetails/updateItemEvent'
import { useGetStorageUnits } from '@/domains/items/hooks/itemList/getStorageUnits'
import { useSearchItems } from '@/domains/items/hooks/itemList/searchItems'
import { useMutationShowingErrors } from '@/hooks'
import {
  HistoryFilters,
  useHistoryFilter,
  useHistoryState,
} from '@/hooks/useHistoryState'
import useThrottledResizeObserver from '@/hooks/useThrottledResizeObserver'
import {
  EItemStatusType,
  EStorageStatus,
  ItemEventMetaArgs,
  ItemStorageHistoryEntry,
} from '@/schemaTypes'
import { useAppSelector } from '@/store'
import { getNow } from '@/utils/time'

const TAKE = 10

export function ItemsListPage() {
  const { t } = useTranslation()

  const companyId = useAppSelector((state) => state.ui.companyId)

  const { ref, height: listHeight } = useThrottledResizeObserver(500)

  const defaultItemsFilterArgs = useMemo(
    () => ({ companyId: companyId === ALL_COMPANIES ? undefined : companyId }),
    [companyId],
  )

  const { historyState, setHistory } =
    useHistoryState<HistoryFilters<ItemsFilters>>()

  const [itemFilter, setItemFilter] = useState<ItemsFilters>({
    ...historyState?.filters,
    ...defaultItemsFilterArgs,
  })
  const { edited } = useHistoryFilter<ItemsFilters>(
    historyState?.filters,
    itemFilter,
    setHistory,
  )

  const [isBasicMode, setIsBasicMode] = useState<boolean>(
    !itemFilter.storageFacilityId &&
      !itemFilter.salePartnerType &&
      !itemFilter.opticalCondition,
  )

  const itemsFiltersArgs = useMemo(() => {
    const salePartnerType = itemFilter.salePartnerType
      ? {
          ...itemFilter.salePartnerType,
          names: [...itemFilter.salePartnerType.names],
        }
      : undefined

    const status = itemFilter.status
      ? {
          ...itemFilter.status,
          names: [...itemFilter.status.names], // create new string array instance so Apollo can detect the change in it
        }
      : undefined

    return {
      ...omit(itemFilter, [
        'itemCategoryIdLv0',
        'itemCategoryIdLv1',
        'itemCategoryIdLv2',
      ]),
      itemCategoryId:
        itemFilter.itemCategoryIdLv2 ??
        itemFilter.itemCategoryIdLv1 ??
        itemFilter.itemCategoryIdLv0,
      salePartnerType,
      createdBefore: new Date(),
      status,
      paginationArgs: {
        limit: edited ? TAKE : historyState?.scroll?.items || TAKE,
      },
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemFilter, edited])

  useEffect(() => {
    setItemFilter((prev) => ({
      ...prev,
      ...defaultItemsFilterArgs,
    }))
  }, [companyId, defaultItemsFilterArgs])

  const { queryResult } = useSearchItems({
    variables: {
      args: itemsFiltersArgs,
    },
    nextFetchPolicy: 'cache-first',
  })

  const { storageFacilities } = useGetActiveStorageFacilities()

  const { storageUnits: allStorageUnits } = useGetStorageUnits()

  const updateItemEvent = useMutationShowingErrors({
    mutation: useUpdateItemEvent(),
    successMessage: `${t('item.item_event_updated_successfully')}!`,
  })

  const updateItemOfferedPrice = (id: any) => (price: number) => {
    const meta: ItemEventMetaArgs = {
      offeredPrice: price,
      offeredPriceAt: getNow(),
    }
    updateItemEvent({
      variables: {
        itemId: id,
        itemEventUpdateArgs: {
          event: EItemStatusType.OfferedToSell,
          meta,
        },
      },
    })
  }

  const getStorageHistoryString = (
    storageHistory: ItemStorageHistoryEntry[] | null | undefined,
    storageFacilityId: string,
  ) => {
    let storageHistoryString = ''
    if (storageHistory && storageHistory.length > 0 && storageFacilityId) {
      if (storageHistory[0].storageStatus === EStorageStatus.AddedToStorage) {
        const storageFacility = storageFacilities.find(
          (storageFacility) => storageFacility._id === storageFacilityId,
        )?.name
        const lvl1 = allStorageUnits.find(
          (storageUnit) =>
            storageUnit._id === storageHistory[0].storageUnits.lvl1,
        )?.name

        if (storageFacility) {
          storageHistoryString = storageHistoryString.concat(storageFacility)
        }

        if (lvl1) {
          storageHistoryString = storageHistoryString
            .concat(' -> ')
            .concat(lvl1)
        }

        const lvl2 = allStorageUnits.find(
          (storageUnit) =>
            storageUnit._id === storageHistory[0].storageUnits.lvl2,
        )?.name

        if (lvl2) {
          storageHistoryString = storageHistoryString
            .concat(' -> ')
            .concat(lvl2)
        }

        const lvl3 = allStorageUnits.find(
          (storageUnit) =>
            storageUnit._id === storageHistory[0].storageUnits.lvl3,
        )?.name

        if (lvl3) {
          storageHistoryString = storageHistoryString
            .concat(' -> ')
            .concat(lvl3)
        }

        const lvl4 = allStorageUnits.find(
          (storageUnit) =>
            storageUnit._id === storageHistory[0].storageUnits.lvl4,
        )?.name

        if (lvl4) {
          storageHistoryString = storageHistoryString
            .concat(' -> ')
            .concat(lvl4)
        }
      }
    }
    return storageHistoryString
  }

  const { itemCategories } = useGetItemCategories(includeHidedCategories)

  return (
    <Wrapper className={`${styles.root}`}>
      <Typography
        variant="h5"
        gutterBottom
        classes={{ root: styles.headTitle }}
      >
        {t('item.plural')}
      </Typography>
      <ContentWrapper>
        <SearchWrapper
          onResetFilter={() => setItemFilter(defaultItemsFilterArgs)}
          additionalComponent={
            <ChangeModeButton
              variant="contained"
              onClick={() => setIsBasicMode(!isBasicMode)}
              className="u-mt-sm u-mr-sm u-ml-sm u-mb-sm"
            >
              {isBasicMode ? t('advanced') : t('basic')}
            </ChangeModeButton>
          }
          name={t('item.plural')}
          numberOfFound={queryResult.data?.searchItems.pageInfo.total}
        >
          <ItemsSearch
            isBasicMode={isBasicMode}
            defaultItemFilter={defaultItemsFilterArgs}
            itemFilter={itemFilter}
            setItemFilter={setItemFilter}
          />
        </SearchWrapper>

        {queryResult.loading ? (
          <Loading />
        ) : (
          <>
            <HeaderContainer>
              <ItemHeaderBox>{t('storage.label')}</ItemHeaderBox>
              <ItemHeaderBox flex={3}>{t('item.title')}</ItemHeaderBox>
              <ItemHeaderBox>
                <div className={styles.flexCenterWrapper}>
                  {t('sales_platform')}
                  <Info
                    className={'o-media__fixed u-inline-block'}
                    svgClassName={`${styles.infoIcon} u-pl-5 u-pr-5`}
                    infoText={t('item.item_sale_platform_hint')}
                  />
                </div>
              </ItemHeaderBox>
              <ItemHeaderBox>{t('internal_valorization')}</ItemHeaderBox>
              <ItemHeaderBox>{t('status')}</ItemHeaderBox>
              <ItemHeaderBox flex={0.5}>{t('stored')}</ItemHeaderBox>
              <ItemHeaderBox flex={2}>
                {t('storage.storage_history')}
              </ItemHeaderBox>
              <ItemHeaderBox flex={0.5} />
            </HeaderContainer>
            <ListWrapper ref={ref}>
              {listHeight !== undefined && (
                <InfiniteScrollSkipLimit
                  height={listHeight}
                  width={'100%'}
                  itemSize={110}
                  customChildrenStyle={{
                    zIndex: 10,
                    borderBottom: 'solid 1px #e8e8e8',
                  }}
                  gutterSize={15}
                  queryResult={queryResult}
                  dataKey="searchItems"
                >
                  {(childrenProp) => {
                    const item = childrenProp.data[childrenProp.index]
                    return (
                      <ItemElement
                        item={item}
                        updateItemOfferedPrice={updateItemOfferedPrice}
                        itemCategories={itemCategories}
                        storageHistoryDescription={getStorageHistoryString(
                          item.storageHistory,
                          item.storageFacilityId,
                        )}
                      />
                    )
                  }}
                </InfiniteScrollSkipLimit>
              )}
            </ListWrapper>
          </>
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

const HeaderContainer = styled(Box)`
  display: flex;
  align-content: center;
  height: 60px;
  padding: 10px;
  border-bottom: solid 1px #e8e8e8;
`

const ItemHeaderBox = styled(Box)<{ flex?: number }>`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  color: #683ab7c0 !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
`

const ChangeModeButton = styled(Button)`
  &&& {
    background-color: #683ab7c0 !important;
    color: white !important;
    height: 40px;
    margin: 10px 0px 10px 0px !important;
  }
`

const ListWrapper = styled.div`
  flex: 1 0 0;
  overflow: hidden;
`

const Wrapper = styled.div`
  height: calc(100vh - 64px);
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled(Paper)`
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
`

export default ItemsListPage
