import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDeleteResponseTemplateArgs } from '@/schemaTypes'

const DELETE_RESPONSE_TEMPLATE = gql`
  mutation deleteResponseTemplate($responseTemplateId: ObjectId!) {
    deleteResponseTemplate(responseTemplateId: $responseTemplateId)
  }
`

type TData = { deleteResponseTemplate: Mutation['deleteResponseTemplate'] }
type TVariables = MutationDeleteResponseTemplateArgs

export function useDeleteResponseTemplate(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [deleteResponseTemplate] = useMutation<TData, TVariables>(
    DELETE_RESPONSE_TEMPLATE,
    opts,
  )

  return deleteResponseTemplate
}
