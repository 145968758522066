import {
  DocumentNode,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { useDebouncedCallback } from './useDebouncedCallback'

export default function useDebouncedQuery<TData, TVariables>(
  document: DocumentNode | TypedDocumentNode<TData, TVariables>,
  {
    variables: inputVariables,
    ...opts
  }: QueryHookOptions<TData, TVariables> = {},
  delay = 1000,
  isDeepEqual = false,
): QueryResult<TData, TVariables> {
  const [debouncedVariables, setDebouncedVariables] = useState(inputVariables)
  const [setVariablesDebounced] = useDebouncedCallback(
    setDebouncedVariables,
    delay,
  )

  useEffect(() => {
    setVariablesDebounced((current) => {
      // prevent unneeded rerenders
      if (isDeepEqual && isEqual(current, inputVariables)) {
        return current
      } else if (shallowEqual(current, inputVariables)) {
        return current
      }

      return inputVariables
    })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputVariables, setVariablesDebounced])

  const { loading, ...queryResult } = useQuery<TData, TVariables>(document, {
    variables: debouncedVariables,
    ...opts,
  })

  return {
    ...queryResult,
    loading: loading || !shallowEqual(debouncedVariables, inputVariables),
  }
}
