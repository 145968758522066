import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import {
  defaultCompanyConfiguration,
  defaultSalesConfiguration,
} from '@/domains/deals/components/constants/company'
import { useShowErrorsPopup } from '@/hooks'
import { Company, Query, QueryGetCompanyArgs } from '@/schemaTypes'
import { companyFragments } from './companyFragments'

const GET_COMPANY = gql`
  query getCompany($companyId: ObjectId!) {
    getCompany(companyId: $companyId) {
      _id
      name
      uid
      fibuNumber
      iban
      bic
      accountOwner
      paypalEmail
      email
      supportEmail
      saleSupportEmail
      phoneNumber
      whatsappNumber
      website
      jurisdiction
      companyCounterId
      primaryShopLocationId

      successionCompanyId
      successionCompany {
        name
      }
      predecessionCompany {
        _id
        name
      }
      companyCounter {
        ...CompanyCountersFragment
      }
      configuration {
        ...CompanyConfigurationFragment
      }
      headquarter {
        coordinates {
          lat
          lng
        }
        houseNumber
        stairway
        door
        floor
        street
        city
        state
        countryCode
        country
        zipCode
        queryString
        address1
        address2
      }
      salesConfiguration {
        ...CompanySalesConfigurationFragment
      }
      unzerConfiguration {
        ...CompanyUnzerConfigurationFragment
      }
      dpdAccountInfo {
        ...DPDAccountInfoFragment
      }
    }
  }
  ${companyFragments.companyCounter}
  ${companyFragments.configuration}
  ${companyFragments.salesConfiguration}
  ${companyFragments.unzerConfiguration}
  ${companyFragments.dpdAccountInfo}
`

type TData = { getCompany: Query['getCompany'] }
type TVariables = QueryGetCompanyArgs

export function useGetCompany(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useQuery<TData, TVariables>(GET_COMPANY, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.companyId,
  })

  useShowErrorsPopup(queryResult.error)

  const company = opts.variables?.companyId
    ? queryResult.data?.getCompany
    : ({
        configuration: defaultCompanyConfiguration,
        salesConfiguration: defaultSalesConfiguration,
      } as Partial<Company>)
  return { queryResult, company }
}
