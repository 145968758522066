import LoanToValuesTable from '.'
import React from 'react'
import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import getPagePath from '@/utils/getPagePath'
import LoanToValuesCreate from './create'

export function LoanToValuesRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/create')}
        component={LoanToValuesCreate}
      />
      <SentryRoute
        path={getPagePath(props, '/create/:loanToValuesEntryId')}
        render={(props) => (
          <LoanToValuesCreate
            {...props}
            loanToValuesEntryId={
              props.match.params.loanToValuesEntryId as string
            }
          />
        )}
      />
      <SentryRoute path={getPagePath(props)} component={LoanToValuesTable} />
    </Switch>
  )
}

export default LoanToValuesRoutes
