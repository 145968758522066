import ImportExport from '.'
import React from 'react'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import FullScreenLoading from '@/components/FullScreenLoading'
import SentryRoute from '@/components/SentryRoute'
import getPagePath from '@/utils/getPagePath'
import ImportExportProducts from './products'

function ImportExportsRoutes(props) {
  return (
    <div>
      {!props.currentUser && <FullScreenLoading />}
      {!!props.currentUser && (
        <Switch>
          <SentryRoute
            path={getPagePath(props, '/products')}
            component={ImportExportProducts}
          />
          <SentryRoute path={getPagePath(props)} component={ImportExport} />
        </Switch>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentUser: state.user.current,
})

export default connect(mapStateToProps)(withRouter(ImportExportsRoutes))
