import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdateProductPublicationArgs } from '@/schemaTypes'

const UPDATE_PRODUCT_PUBLICATION = gql`
  mutation updateProductPublication(
    $productId: ObjectId!
    $isPublished: Boolean!
  ) {
    updateProductPublication(productId: $productId, isPublished: $isPublished) {
      _id
    }
  }
`

type TData = { updateProductPublication: Mutation['updateProductPublication'] }
type TVariables = MutationUpdateProductPublicationArgs

export function useUpdateProductPublication(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [mutation] = useMutation<TData, TVariables>(
    UPDATE_PRODUCT_PUBLICATION,
    opts,
  )
  return mutation
}
