import styled from '@emotion/styled'
import { BreadCrumb } from 'primereact/breadcrumb'
import { MenuItem } from 'primereact/menuitem'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { capitalizeFirstLetter } from '@/utils/misc'

interface BreadCrumbBarProps {
  customer?: { firstname: string; lastname: string }
  customBreadCrumb?: string
}

const BreadCrumbBar = ({ customer, customBreadCrumb }: BreadCrumbBarProps) => {
  const { t } = useTranslation()

  const location = useLocation()

  const items = useMemo((): MenuItem[] => {
    const [, basePath, ...nestedSegments] = location.pathname
      .split('/')
      .filter((x) => x)

    const newArray = customBreadCrumb
      ? [[customBreadCrumb]]
      : customer
        ? [[basePath], [`${customer?.firstname} ${customer?.lastname}`]]
        : [[basePath], nestedSegments]

    return newArray.map((labelParts, index, array) => {
      const isLast = index === array.length - 1
      const translatedLabelParts = labelParts.map((part, i) =>
        i === 0 ? t(capitalizeFirstLetter(part)) : part,
      )
      const translatedLabel = translatedLabelParts.join(' ')
      const url = `/inApp/${newArray
        .slice(0, index + 1)
        .flat()
        .join('/')}`

      return {
        label: translatedLabel,
        url,
        key: index,
        template: (item, options) => {
          return (
            <Link
              to={item.url}
              className={options.className}
              style={{ pointerEvents: isLast ? 'none' : 'auto' }}
            >
              {item.icon}
              <span className={options.labelClassName}>
                {(item.label ?? '').replace(/([A-Z])/g, ' $1').trim()}
              </span>
            </Link>
          )
        },
      }
    })
  }, [location, t, customer, customBreadCrumb])

  return <BreadCrumbStyled home={home} model={items} />
}

const home: MenuItem = {
  icon: 'pi pi-home',
  url: '/inApp/deals',
  template: (item, options) => {
    return (
      <Link to={item.url} className={options.className}>
        <i className={item.icon} />
      </Link>
    )
  },
}

const BreadCrumbStyled = styled(BreadCrumb)`
  &&& {
    border: unset;
    color: var(--breadcrumb-item-color, #495057);
    font-family: 'Inter';
    font-size: 1.09375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
    padding: 0 0 0.38rem 0;
  }
`

export default BreadCrumbBar
