import {
  UseMutationShowingErrorsOpts,
  UseMutationShowingErrorsParams,
  useMutationShowingErrors,
} from './useMutationShowingErrors'
import {
  ShowConfirmPopupOpts,
  useShowConfirmDeletePopup,
  useShowConfirmPopup,
} from './useShowConfirmPopup'

export interface UseConfirmMutationShowingErrorsOpts<TData, TVariables>
  extends ShowConfirmPopupOpts,
    UseMutationShowingErrorsOpts<TData, TVariables> {}

export interface UseConfirmDeleteMutationShowingErrorsOpts<TData, TVariables>
  extends UseMutationShowingErrorsOpts<TData, TVariables> {
  closeDialogWhenMutationStarts?: boolean
}

export interface UseConfirmMutationShowingErrorsParams<TData, TVariables>
  extends UseMutationShowingErrorsParams<TData, TVariables> {}

export function useConfirmMutationShowingErrors<TData, TVariables>(
  opts: UseConfirmMutationShowingErrorsOpts<TData, TVariables>,
) {
  const { actionText, title, mutation: mutation, successMessage } = opts

  const showConfirmPopup = useShowConfirmPopup({ actionText, title })
  const performMutationAndShowErrors = useMutationShowingErrors({
    mutation: mutation,
    successMessage,
  })

  return (params: UseConfirmMutationShowingErrorsParams<TData, TVariables>) => {
    showConfirmPopup({ action: () => performMutationAndShowErrors(params) })
  }
}

export function useConfirmDeleteMutationShowingErrors<TData, TVariables>(
  opts: UseConfirmDeleteMutationShowingErrorsOpts<TData, TVariables>,
) {
  const {
    mutation: mutation,
    successMessage,
    closeDialogWhenMutationStarts,
  } = opts

  const showConfirmPopup = useShowConfirmDeletePopup()
  const performMutationAndShowErrors = useMutationShowingErrors({
    mutation: mutation,
    successMessage,
    closeDialogWhenMutationStarts,
  })

  return (params: UseConfirmMutationShowingErrorsParams<TData, TVariables>) => {
    showConfirmPopup({
      action: () => {
        performMutationAndShowErrors(params)
      },
    })
  }
}
