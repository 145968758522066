import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationAddDealItemArgs } from '@/schemaTypes'
import { dealCalculationFragments, dealFragments } from './dealFragments'

const ADD_DEAL_ITEM = gql`
  mutation addDealItem($dealId: ObjectId!, $args: DealItemCreateArgs!) {
    addDealItem(dealId: $dealId, args: $args) {
      _id
      items {
        ...DealItemFragment
      }
      dealValorizationCalculation {
        ...DealValorizationCalculationFragment
      }
      pawnData {
        ...PawnDataFragment
      }
      purchaseData {
        ...PurchaseDataFragment
      }
    }
  }
  ${dealFragments.pawnData}
  ${dealFragments.dealItem}
  ${dealFragments.purchaseData}
  ${dealCalculationFragments.dealValorizationCalculation}
`

type TData = { addDealItem: Mutation['addDealItem'] }
type TVariables = MutationAddDealItemArgs

export function useAddDealItem(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  return useMutation<TData, TVariables>(ADD_DEAL_ITEM, opts)
}
