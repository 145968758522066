import classnames from 'classnames'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '@/redesign/components/Input/Input'
import { Deal } from '@/schemaTypes'
import { displayLocalAmount } from '@/utils/misc'

export interface MinPartialRedemptionInputProps {
  deal: Deal
  className?: string
}

export const RemainingPartialRedemptionDisplay: FunctionComponent<
  MinPartialRedemptionInputProps
> = ({ deal, className }) => {
  const { t } = useTranslation()

  const { partialRedemptionSetUpBy } = deal.dealFinalValues

  let authorNotice: string
  if (partialRedemptionSetUpBy.__typename === 'SystemOperation') {
    authorNotice = `System (${partialRedemptionSetUpBy.name})`
  } else if (partialRedemptionSetUpBy.__typename === 'Employee') {
    authorNotice = `${partialRedemptionSetUpBy.firstname} ${partialRedemptionSetUpBy.lastname}`
  }

  return (
    <div className={classnames('flex flex-col md:flex-row', className)}>
      <Input
        value={
          deal?.dealFinalValues?.remainingPartialRedemptionAmount != null &&
          displayLocalAmount(
            deal.dealFinalValues.remainingPartialRedemptionAmount,
          )
        }
        label={t('partial_redemption')}
        tooltipText={
          authorNotice &&
          t('car_inspection.partial_redemption_configuration_author_notice', {
            authorNotice,
          })
        }
        tooltipIcon={'pi pi-question-circle'}
        tooltipOptions={{ showOnDisabled: true }}
        disabled={true}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />
      {deal?.dealFinalValues?.partialRedemptionIntervalsCount && (
        <div className="flex flex-col md:flex-row md:mt-1.5 ml-1">
          {t('partial_redemption_interval_notice', {
            count: deal.dealFinalValues.partialRedemptionIntervalsCount,
          })}
        </div>
      )}
    </div>
  )
}
