import { MenuItem, Select } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetActiveCompaniesNames } from '@/hooks'
import { useAppDispatch, useAppSelector } from '@/store'
import { switchCompany } from '@/store/actions/ui'
import styles from './CompanySwitch.module.scss'

export const ALL_COMPANIES = 'ALL'
const CompanySwitch = () => {
  const { t } = useTranslation()
  const { companies } = useGetActiveCompaniesNames()
  const companyId = useAppSelector((state) => state.ui.companyId)
  const dispatch = useAppDispatch()

  const defaultCompanyId = ALL_COMPANIES

  const onChangeCompany = (companyId) => dispatch(switchCompany(companyId))

  useEffect(() => {
    if (!companyId && defaultCompanyId) {
      onChangeCompany(defaultCompanyId)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, defaultCompanyId])

  return (
    <Select
      className={styles.select}
      variant="filled"
      value={companyId || ''}
      onChange={(e) => onChangeCompany(e.target.value)}
    >
      <MenuItem key={ALL_COMPANIES} value={ALL_COMPANIES}>
        {t('all')}
      </MenuItem>
      {companies.map((company) => (
        <MenuItem key={company._id} value={company._id}>
          {company.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CompanySwitch
