import qs from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ObjectSchema } from 'yup'

const getSearchParams = <T extends object>(
  search: string,
  schema: ObjectSchema<T>,
) => {
  const params = qs.parse(search)

  return schema.cast(params) as T
}

export const useSyncSearchParams = <T extends Record<string, unknown>>(
  schema: ObjectSchema<T>,
) => {
  const { search } = useLocation()
  const [state, setState] = useState<T>(
    getSearchParams(search, schema) || ({} as T),
  )
  const history = useHistory()

  const syncSearchParams = useCallback(
    (values: object) => {
      const params = qs.stringify(values)
      history.replace({ search: params })
    },
    [history],
  )

  useEffect(() => {
    syncSearchParams(state)
  }, [syncSearchParams, state])

  return [state, setState] as const
}
