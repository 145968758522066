import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetCompanyArgs } from '@/schemaTypes'

const GET_COMPANY = gql`
  query getCompany($companyId: ObjectId!) {
    getCompany(companyId: $companyId) {
      _id
      name
    }
  }
`

type TData = { getCompany: Query['getCompany'] }
type TVariables = QueryGetCompanyArgs

export function useGetCompany(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useQuery<TData, TVariables>(GET_COMPANY, {
    fetchPolicy: 'network-only',
    ...opts,
    skip: !opts.variables?.companyId,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, company: queryResult.data?.getCompany }
}
