import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationCreateCompanyArgs } from '@/schemaTypes'
import { companyFragments } from './companyFragments'

const CREATE_COMPANY = gql`
  mutation createCompany($company: CompanyCreateArgs!) {
    createCompany(company: $company) {
      _id
      name
      uid
      fibuNumber
      iban
      bic
      accountOwner
      paypalEmail
      email
      supportEmail
      saleSupportEmail
      phoneNumber
      website
      jurisdiction
      companyCounterId
      companyCounter {
        ...CompanyCountersFragment
      }
      configuration {
        ...CompanyConfigurationFragment
      }
      headquarter {
        coordinates {
          lat
          lng
        }
        houseNumber
        stairway
        door
        floor
        street
        city
        state
        countryCode
        country
        zipCode
        queryString
        address1
        address2
      }
      primaryShopLocationId
      salesConfiguration {
        ...CompanySalesConfigurationFragment
      }
    }
  }
  ${companyFragments.companyCounter}
  ${companyFragments.configuration}
  ${companyFragments.salesConfiguration}
`

type TData = { createCompany: Mutation['createCompany'] }
type TVariables = MutationCreateCompanyArgs

export function useCreateCompany(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [createCompany] = useMutation<TData, TVariables>(CREATE_COMPANY, opts)

  return createCompany
}
