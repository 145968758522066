import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetItemQuestionsByCategoryArgs } from '@/schemaTypes'

const GET_ITEM_QUESTIONS_BY_CATEGORY = gql`
  query getItemQuestionsByCategory(
    $itemCategoryId: ObjectId!
    $date: DateTime!
    $isCustomItem: Boolean!
    $omitDeactivatedQuestionsForNewProducts: Boolean!
  ) {
    getItemQuestionsByCategory(
      itemCategoryId: $itemCategoryId
      date: $date
      isCustomItem: $isCustomItem
      omitDeactivatedQuestionsForNewProducts: $omitDeactivatedQuestionsForNewProducts
    ) {
      _id
      validFrom
      validTo
      order
      questionType
      valueMode
      titleKey
      infoKey
      isInternal
      predictionTag
      singleChoiceOptions {
        labelKey
        infoKey
        categoryValues
        conditionValue
        isDefaultValue
      }
      rangeData {
        minValue
        maxValue
        defaultValue
        unitPrice
        unitKey
      }
      itemCategoryIds
    }
  }
`

type TData = { getItemQuestionsByCategory: Query['getItemQuestionsByCategory'] }
type TVariables = QueryGetItemQuestionsByCategoryArgs

export function useGetItemQuestions(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_ITEM_QUESTIONS_BY_CATEGORY,
    {
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    itemQuestions: queryResult.data?.getItemQuestionsByCategory,
  }
}
