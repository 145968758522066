import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationUpdatePreviewCalculationArgs } from '@/schemaTypes'

const UPDATE_PREVIEW_CALCULATION = gql`
  mutation updatePreviewCalculation(
    $dealId: ObjectId!
    $dealFinalValuesArgs: DealFinalValuesArgs!
  ) {
    updatePreviewCalculation(
      dealId: $dealId
      dealFinalValuesArgs: $dealFinalValuesArgs
    ) {
      _id
    }
  }
`

type TData = { overwriteDealPayoutAmount: Mutation['updatePreviewCalculation'] }
type TVariables = MutationUpdatePreviewCalculationArgs

export function useUpdatePreviewCalculation(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updatePreviewCalculation] = useMutation<TData, TVariables>(
    UPDATE_PREVIEW_CALCULATION,
    opts,
  )

  return updatePreviewCalculation
}
