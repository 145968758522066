import UsedFeeDefinitionViewer from '../UsedFeeDefinitionViewer'
import classNames from 'classnames'
import { addDays } from 'date-fns'
import dayjs from 'dayjs'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback'
import DatePicker from '@/redesign/components/DatePicker/DatePicker'
import InputNum from '@/redesign/components/InputNumber/InputNumber'
import { EDealType, GetExistingDealCalculationArgs } from '@/schemaTypes'
import {
  getTotalGrossFeeAmountOfType,
  manipulateFeesForCustomer,
} from '@/utils/deal'
import { displayLocalAmount, printInt } from '@/utils/misc'
import { getDurationInDays } from '@/utils/time'

const DealCalculationTable = (props) => {
  const {
    deal,
    bookedDealCalculation,
    verifiedDealCalculation,
    existingCaluclation,
    persistedDealCalculation,
    isDealClosed,
    existingDealCalculationArgs,
    setExistingDealCalculationArgs,
    resetPreviewCalculation,
    updatePreviewCalculation,
    closedEvent,
    paybackOrExtensionConfirmed,
    isVerifiedDeal,
    dealFinalCalculation,
    updatePreviewCompleted,
  } = props
  const { t } = useTranslation()
  const [feeDialogData, setFeeDialogData] = useState(null)
  const [editingRowKey, setEditingRowKey] = useState(null)
  const [durationDaysPerRow, setDurationDaysPerRow] = useState({})
  const [payoutPerRow, setPayoutPerRow] = useState({})
  const [paybackDatePerRow, setPaybackDatePerRow] = useState({})
  const [manualDurationInDays, setManualDurationInDays] = useState(
    deal.dealFinalValues.durationInDays,
  )

  const [setDebouncedExtensionCalculationArgs] = useDebouncedCallback(
    (newDealExtensionCalculationArgs: GetExistingDealCalculationArgs) => {
      setExistingDealCalculationArgs(newDealExtensionCalculationArgs)
    },
    1000,
  )

  const onChangeDurationInDays = (value: string, dealCalculation) => {
    if (
      parseInt(value || '0') < deal.company.configuration.minimumPawnDuration &&
      !isVerifiedDeal
    ) {
      setManualDurationInDays(parseInt(value || '0'))
    } else {
      setManualDurationInDays(
        dealCalculation?.dealValuesEntry.durationInDays ?? 0,
      )
      setDebouncedExtensionCalculationArgs({
        ...existingDealCalculationArgs,
        durationInDays: parseInt(value || '0'),
      })
    }
  }

  const generateData = useCallback(
    (
      label,
      dealCalculation,
      closedEvent,
      isVerifiedDeal,
      isPreview,
      isEditable,
      manualDurationInDays,
      paybackOrExtensionConfirmed,
      existingDealType,
      isPurchase,
    ) => {
      const getDealData = (calculation, isPurchase) => {
        if (!calculation) {
          return []
        }

        const dealCalculation = calculation
        const durationInDays =
          dealCalculation?.dealValuesEntry?.durationInDays ?? 0
        const paybackDate = addDays(
          isEditable && !isVerifiedDeal ? dayjs().toDate() : deal.dealStartDate,
          durationInDays,
        )

        const isActionTemplateVisible =
          updatePreviewCompleted &&
          !isVerifiedDeal &&
          isPreview &&
          isEditable &&
          (existingDealCalculationArgs.durationInDays >=
            deal.company.configuration.minimumPawnDuration ||
            existingDealCalculationArgs.dealType === EDealType.Purchase) &&
          (deal.dealFinalValues.payoutAmount !==
            dealCalculation?.dealValuesEntry.payoutAmount ||
            deal.dealFinalValues.shouldOverwritePayoutAmount !==
              existingDealCalculationArgs.shouldOverwritePayoutAmount ||
            deal.dealFinalValues.durationInDays !==
              existingDealCalculationArgs.durationInDays ||
            deal.dealType !== existingDealCalculationArgs.dealType)

        return [
          {
            process_step: label,
            durationInDays: isPurchase
              ? '-'
              : manualDurationInDays <
                    deal.company.configuration.minimumPawnDuration &&
                  !isVerifiedDeal
                ? manualDurationInDays
                : printInt(dealCalculation?.dealValuesEntry?.durationInDays),
            purchase: isPurchase,
            paybackDate: paybackDate,
            payout: dealCalculation?.dealValuesEntry.payoutAmount,
            fee: displayLocalAmount(
              manualDurationInDays <
                deal.company.configuration.minimumPawnDuration &&
                !isVerifiedDeal
                ? getTotalGrossFeeAmountOfType(
                    manipulateFeesForCustomer(
                      dealCalculation,
                      manualDurationInDays,
                    ).appliedUsedFeeDefinitions,
                  )
                : getTotalGrossFeeAmountOfType(
                    dealCalculation.appliedUsedFeeDefinitions || [],
                  ),
            ),
            payback: isPurchase
              ? '-'
              : displayLocalAmount(
                  dealCalculation?.dealValuesEntry?.paybackAmount,
                ),
            isEditable,
            isVerifiedDeal,
            dealCalculation: calculation,
            isPurchase,
            isActionTemplateVisible,
            existingDealType,
          },
        ]
      }
      let data = []

      if (label === 'BOOKED' || label === 'VERIFIED' || label === 'CLOSED') {
        data = [...data, ...getDealData(dealCalculation, isPurchase)]
      }

      if (
        (label === 'P-CLOSED' || label === 'P-VERIFIED') &&
        !paybackOrExtensionConfirmed &&
        !closedEvent
      ) {
        data = [...data, ...getDealData(dealCalculation, isPurchase)]
      }
      return data
    },
    [
      updatePreviewCompleted,
      deal.company.configuration.minimumPawnDuration,
      deal.dealFinalValues.durationInDays,
      deal.dealFinalValues.payoutAmount,
      deal.dealFinalValues.shouldOverwritePayoutAmount,
      deal.dealStartDate,
      deal.dealType,
      existingDealCalculationArgs.dealType,
      existingDealCalculationArgs.durationInDays,
      existingDealCalculationArgs.shouldOverwritePayoutAmount,
    ],
  )

  const rows = useMemo(() => {
    let data = []

    data = [
      ...data,
      ...generateData(
        'BOOKED',
        bookedDealCalculation,
        closedEvent,
        isVerifiedDeal,
        false, //isPreview
        false, //isEditable
        manualDurationInDays,
        paybackOrExtensionConfirmed,
        null,
        bookedDealCalculation.dealValuesEntry?.durationInDays === 0,
      ),
      ...generateData(
        'VERIFIED',
        verifiedDealCalculation,
        closedEvent,
        isVerifiedDeal,
        false, //isPreview
        false, //isEditable
        manualDurationInDays,
        paybackOrExtensionConfirmed,
        null,
        deal.dealFinalValues.dealType === EDealType.Purchase,
      ),
      ...generateData(
        'CLOSED',
        dealFinalCalculation,
        closedEvent,
        isVerifiedDeal,
        false, //isPreview
        false, //isEditable
        manualDurationInDays,
        paybackOrExtensionConfirmed,
        null,
        deal.dealFinalValues.dealType === EDealType.Purchase,
      ),
      ...generateData(
        verifiedDealCalculation ? 'P-CLOSED' : 'P-VERIFIED',
        existingCaluclation,
        closedEvent,
        isVerifiedDeal,
        true, //isPreview
        !isDealClosed, //isEditable
        manualDurationInDays,
        paybackOrExtensionConfirmed,
        existingDealCalculationArgs.dealType, // existingDealType
        (verifiedDealCalculation &&
          deal.dealFinalValues.dealType === EDealType.Purchase) ||
          existingDealCalculationArgs.dealType === EDealType.Purchase,
      ),
    ]

    return data
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closedEvent,
    existingCaluclation,
    existingDealCalculationArgs.dealType,
    generateData,
    isDealClosed,
    isVerifiedDeal,
    manualDurationInDays,
    paybackOrExtensionConfirmed,
    verifiedDealCalculation,
    bookedDealCalculation,
    dealFinalCalculation,
  ])

  const onRowEditToggle = (key) => {
    setEditingRowKey((prev) => (prev === key ? null : key))
  }

  const handleDurationChange = (process_step, newDuration) => {
    setDurationDaysPerRow((prev) => ({
      ...prev,
      [process_step]: newDuration,
    }))
  }

  const durationInDaysTemplate = (rowData, key) => {
    const {
      isEditable,
      durationInDays,
      existingDealType,
      process_step,
      dealCalculation,
    } = rowData

    const isEditing = editingRowKey === `${key}_${process_step}`
    const durationDaysValue =
      durationDaysPerRow[process_step] !== undefined
        ? durationDaysPerRow[process_step]
        : durationInDays

    return (
      <EditableField
        isEditing={isEditing}
        value={durationDaysValue}
        isDisabled={!isEditable || existingDealType === EDealType.Purchase}
        onChange={(e) => handleDurationChange(process_step, e.value)}
        onSave={() => {
          if (isEditing) {
            onChangeDurationInDays(durationDaysValue, dealCalculation)
            setDurationDaysPerRow({})
            onRowEditToggle(`${key}_${process_step}`)
          } else {
            setDurationDaysPerRow({})
            onRowEditToggle(`${key}_${process_step}`)
          }
        }}
        onClear={() => {
          setDurationDaysPerRow({})
          onRowEditToggle(`${key}_${process_step}`)
        }}
      >
        <InputNum mode="decimal" />
      </EditableField>
    )
  }

  const purchaseTemplate = (rowData) => {
    const { purchase, isVerifiedDeal, isEditable } = rowData
    return (
      <Checkbox
        disabled={!isEditable || isVerifiedDeal}
        onChange={(e) => {
          if (e.checked) {
            setExistingDealCalculationArgs({
              ...existingDealCalculationArgs,
              durationInDays: 0,
              dealType: EDealType.Purchase,
            })
          } else {
            setExistingDealCalculationArgs({
              ...existingDealCalculationArgs,
              durationInDays:
                persistedDealCalculation?.dealValuesEntry.durationInDays ?? 0,
              dealType: EDealType.Pawn,
            })
          }
        }}
        checked={purchase}
      />
    )
  }

  const handlePaybackDateChange = (process_step, newDate) => {
    setPaybackDatePerRow((prev) => ({
      ...prev,
      [process_step]: newDate,
    }))
  }

  const paybackDateTemplate = (rowData, key) => {
    const {
      paybackDate,
      isEditable,
      isPurchase,
      process_step,
      isVerifiedDeal,
    } = rowData

    if (isPurchase) {
      return '-'
    }

    const isEditing = editingRowKey === `${key}_${process_step}`
    const paybackDateValue =
      paybackDatePerRow[process_step] !== undefined
        ? paybackDatePerRow[process_step]
        : paybackDate

    return (
      <EditableField
        isEditing={isEditing}
        value={paybackDateValue}
        isDisabled={!isEditable}
        onChange={(e) => handlePaybackDateChange(process_step, e.value)}
        onSave={() => {
          if (isEditing) {
            setDebouncedExtensionCalculationArgs({
              ...existingDealCalculationArgs,
              durationInDays: getDurationInDays(
                paybackDateValue ? paybackDateValue : dayjs().toDate(),
                isVerifiedDeal ? deal.dealStartDate : dayjs().toDate(),
              ),
            })
            setPaybackDatePerRow({})
            onRowEditToggle(`${key}_${process_step}`)
          } else {
            setPaybackDatePerRow({})
            onRowEditToggle(`${key}_${process_step}`)
          }
        }}
        onClear={() => {
          setPaybackDatePerRow({})
          onRowEditToggle(`${key}_${process_step}`)
        }}
      >
        <DatePicker
          dateFormat="dd.mm.yy"
          disabled={!isEditable}
          appendTo={document.body}
          showIcon={true}
        />
      </EditableField>
    )
  }

  const handlePayoutChange = (process_step, newPayout) => {
    setPayoutPerRow((prev) => ({
      ...prev,
      [process_step]: newPayout,
    }))
  }

  const payoutTemplate = (rowData, key) => {
    const { payout, isEditable, isVerifiedDeal, process_step } = rowData

    const isEditing = editingRowKey === `${key}_${process_step}`
    const payoutValue =
      payoutPerRow[process_step] !== undefined
        ? payoutPerRow[process_step]
        : payout

    return (
      <EditableField
        isEditing={isEditing}
        value={isEditing ? payoutValue : displayLocalAmount(payoutValue)}
        isDisabled={!isEditable || isVerifiedDeal}
        onChange={(e) => handlePayoutChange(process_step, e.value)}
        onSave={() => {
          if (isEditing) {
            setDebouncedExtensionCalculationArgs({
              ...existingDealCalculationArgs,
              overwrittenPayoutAmount: parseInt(payoutValue ?? 0),
              shouldOverwritePayoutAmount: true,
            })
            setPayoutPerRow({})
            onRowEditToggle(`${key}_${process_step}`)
          } else {
            setPayoutPerRow({})
            onRowEditToggle(`${key}_${process_step}`)
          }
        }}
        onClear={() => {
          setPayoutPerRow({})
          onRowEditToggle(`${key}_${process_step}`)
        }}
      >
        <InputNum />
      </EditableField>
    )
  }

  const feeTemplate = (rowData) => {
    return (
      <div className="flex flex-row items-center">
        {rowData.fee}
        <i
          className="pi pi-question-circle ml-2 cursor-pointer"
          style={{ fontSize: '1.3rem' }}
          onClick={() => {
            setFeeDialogData(rowData)
          }}
        />
      </div>
    )
  }

  const tableActionsTemplate = (rowData) => {
    const { isActionTemplateVisible } = rowData
    if (!isActionTemplateVisible) return
    return (
      <div className="flex">
        <div className="mr-2">
          <Button
            icon="pi pi-replay"
            className="me-1"
            outlined
            severity="secondary"
            onClick={resetPreviewCalculation}
            text
          />
        </div>
        <div>
          <Button
            icon="pi pi-save"
            severity="secondary"
            onClick={updatePreviewCalculation}
            text
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <DataTable value={rows} tableStyle={{ minWidth: '50rem' }}>
        <Column field="process_step" header={t('process_step')} />
        <Column
          header={t('duration_in_days')}
          body={(rowData) =>
            durationInDaysTemplate(rowData, 'duration_in_days')
          }
        />
        <Column header={t('purchase')} body={purchaseTemplate} />
        <Column
          header={t('payback_date')}
          body={(rowData) => paybackDateTemplate(rowData, 'payback_date')}
        />
        <Column
          header={t('payout.label')}
          body={(rowData) => payoutTemplate(rowData, 'payout')}
        />
        <Column header={t('fees.label')} body={feeTemplate} />
        <Column field="payback" header={t('payback')} />

        <Column
          style={{ width: '30px', textAlign: 'right' }}
          body={tableActionsTemplate}
        />
      </DataTable>

      <Dialog
        header="Header"
        visible={!!feeDialogData}
        style={{ width: '50vw' }}
        onHide={() => setFeeDialogData(null)}
      >
        {feeDialogData?.dealCalculation && (
          <UsedFeeDefinitionViewer
            deal={deal}
            dealCalculation={
              manualDurationInDays <
                deal.company.configuration.minimumPawnDuration &&
              !feeDialogData.isVerifiedDeal
                ? manipulateFeesForCustomer(
                    feeDialogData.dealCalculation,
                    manualDurationInDays,
                  )
                : feeDialogData.dealCalculation
            }
            items={deal.items}
            isLoading={false}
            showLayoutButton={true}
            defaultLayout={'module'}
            showItemDetailsButton={true}
            itemRecordsButtonDefaultValue={true}
            showChargTableText={true}
          />
        )}
      </Dialog>
    </>
  )
}

const EditableField = ({
  isEditing,
  value,
  onChange,
  onSave,
  onClear,
  children,
  className = '',
  isDisabled,
}) => (
  <div className={classNames(className, { 'opacity-60': isDisabled })}>
    {isEditing ? (
      <div className="flex flex-row items-center">
        {React.cloneElement(children, { value, onChange })}
        <i
          className="pi pi-check cursor-pointer ml-2 p-1"
          style={{ fontSize: '.8rem' }}
          onClick={onSave}
        />
        <i
          className="pi pi-times cursor-pointer ml-2 p-1 text-[#ef4444]"
          style={{ fontSize: '.8rem' }}
          onClick={onClear}
        />
      </div>
    ) : (
      <div className="flex flex-row items-center">
        {value instanceof Date ? dayjs(value).format('DD.MM.YYYY') : value}
        {!isDisabled && (
          <i
            className="pi pi-pencil cursor-pointer ml-2 p-1"
            style={{ fontSize: '.8rem' }}
            onClick={onSave}
          />
        )}
      </div>
    )}
  </div>
)

export default DealCalculationTable
