import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryDealPaybackCalculationArgs } from '@/schemaTypes'
import { paybackFragments } from './paybackFragments'

const DEAL_PAYBACK_CALCULATION = gql`
  query dealPaybackCalculation(
    $dealId: ObjectId!
    $args: DealPaybackCalculationArgs!
  ) {
    dealPaybackCalculation(dealId: $dealId, args: $args) {
      ...PersistedDealPaybackCalculationFragment
    }
  }
  ${paybackFragments.dealPaybackCalculation}
`

type TData = { dealPaybackCalculation: Query['dealPaybackCalculation'] }
type TVariables = QueryDealPaybackCalculationArgs

export function useDealPaybackCalculation(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(DEAL_PAYBACK_CALCULATION, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    dealPaybackCalculation: queryResult.data?.dealPaybackCalculation,
  }
}
