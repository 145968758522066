import FieldWrapper from '../FieldWrapper/FieldWrapper'
import withFieldWrapper from '../withFieldWrapper/withFieldWrapper'
import { InputSwitch, InputSwitchProps } from 'primereact/inputswitch'

interface InnerSwitchProps extends InputSwitchProps {
  checked: boolean
}

const Switch = withFieldWrapper(({ checked, ...props }: InnerSwitchProps) => {
  return (
    <FieldWrapper {...props}>
      <InputSwitch checked={checked || false} {...props} />
    </FieldWrapper>
  )
})

export default Switch
