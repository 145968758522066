import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { fragments } from '@/hooks/fragments'
import { Mutation, MutationRegisterCustomerArgs } from '@/schemaTypes'

const REGISTER_CUSTOMER = gql`
  mutation registerCustomer(
    $email: String!
    $password: String!
    $firstname: String!
    $lastname: String!
    $dateOfBirth: DateTime!
    $phone: String!
    $sex: ESex
    $note: String
    $isNotePopup: Boolean
    $isTestUser: Boolean
    $iban: String
    $paypalAddress: String
    $guestEmail: String
    $acquiredBy: String
    $address: ICustomerAddress
    $deactivated: Boolean
    $additionalInformationForCarPawn: AdditionalInformationForCarPawnArgs
  ) {
    registerCustomer(
      email: $email
      password: $password
      firstname: $firstname
      lastname: $lastname
      dateOfBirth: $dateOfBirth
      phone: $phone
      note: $note
      isNotePopup: $isNotePopup
      isTestUser: $isTestUser
      sex: $sex
      iban: $iban
      paypalAddress: $paypalAddress
      guestEmail: $guestEmail
      acquiredBy: $acquiredBy
      address: $address
      deactivated: $deactivated
      additionalInformationForCarPawn: $additionalInformationForCarPawn
    ) {
      _id
      email
      firstname
      lastname
      dateOfBirth
      phone
      note
      isNotePopup
      isTestUser
      sex
      iban
      paypalAddress
      createdAt
      updatedAt
      customerNumber
      guestEmail
      acquiredBy
      userPermissionId
      roles
      deactivated
      deactivatedAt
      identityUploadLinks {
        ...FileMetadataFragment
      }
      registrationCertificate {
        ...FileMetadataFragment
      }
      uploadedFiles {
        ...FileMetadataFragment
      }
      addresses {
        ...CustomerAddressFragment
      }
      additionalInformationForCarPawn {
        ...AdditionalInformationForCarPawnFragment
      }
    }
  }
  ${fragments.customerAddress}
  ${fragments.fileMetadata}
  ${fragments.additionalInformationForCarPawn}
`

type TData = { registerCustomer: Mutation['registerCustomer'] }
type TVariables = MutationRegisterCustomerArgs

export function useRegisterCustomer(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [registerCustomer] = useMutation<TData, TVariables>(
    REGISTER_CUSTOMER,
    opts,
  )

  return registerCustomer
}
