import { getStoragePlace } from '../../helpers'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { compact, flatten, isEqual } from 'lodash'
import React from 'react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Context } from '@/context'
import { useGetStorageUnits } from '@/domains/storageUnits/hooks'
import { AuctionItem, Deal, EStorageStatus } from '@/schemaTypes'

export interface AuctionItemsTableProps {
  auctionItems: Array<AuctionItem>
  deals: Deal[]
}

export function AuctionCloseTable(props: AuctionItemsTableProps) {
  const { t } = useContext(Context)
  const { auctionItems, deals } = props

  const { storageUnits } = useGetStorageUnits({
    variables: {
      opts: {
        filter: {
          ids: compact(
            flatten(
              auctionItems.map((auctionItem) => {
                const storageHistory = auctionItem.item?.storageHistory?.[0]
                if (
                  storageHistory?.storageStatus !==
                  EStorageStatus.AddedToStorage
                ) {
                  return []
                }

                return [
                  storageHistory?.storageUnits.lvl1,
                  storageHistory?.storageUnits.lvl2,
                  storageHistory?.storageUnits.lvl3,
                  storageHistory?.storageUnits.lvl4,
                ]
              }),
            ),
          ),
        },
      },
    },
    skip: !auctionItems.length,
  })

  const getBuyerName = (item: AuctionItem) => {
    if (item.buyerName) {
      return item.buyerName
    }
    if (item.buyer?.__typename === 'AuctionItemExistingCustomer') {
      return item.buyer.customer?.firstname
    }

    if (item.buyer?.__typename === 'AuctionItemNewCustomer') {
      return item.buyer.firstname
    }

    return ''
  }

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <Table className="u-mb-sm" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('deal.label')}</TableCell>
            <TableCell align="left">{t('storage.label')}</TableCell>
            <TableCell align="left">{t('item_name')}</TableCell>
            <TableCell align="left">{t('shop.label')}</TableCell>
            <TableCell align="left">{t('auctioned_for')}</TableCell>
            <TableCell align="left">{t('payment_method')}</TableCell>
            <TableCell align="left">{t('auctioned_to')}</TableCell>
            <TableCell align="left">{t('item_state')}</TableCell>
            <TableCell align="left">{t('storage_place')}</TableCell>
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {auctionItems.map((item) => {
            const dealHistory = item.item?.dealsHistory?.find(
              (h) => h.dealId === item.dealId,
            )
            const deal = deals.find(
              (c) => dealHistory && isEqual(c._id, dealHistory?.dealId),
            )
            return (
              <TableRow>
                <TableCell align="left">
                  <Link to={`/inApp/deals/${dealHistory?.dealId}`}>
                    {' '}
                    {dealHistory?.deal?.bookingNumber}
                  </Link>
                </TableCell>
                <TableCell align="left">
                  <Link to={`/inApp/items/${item.itemId}`}>
                    {' '}
                    {item.item?.storageLabel}
                  </Link>
                </TableCell>
                <TableCell align="left">{dealHistory?.title}</TableCell>
                <TableCell align="left">{deal?.shop?.name}</TableCell>
                <TableCell align="left">{item.finalPrice} €</TableCell>
                <TableCell align="left">{t(item.paymentMethod)}</TableCell>
                <TableCell align="left">{getBuyerName(item)}</TableCell>
                <TableCell align="left">{item.item?.status}</TableCell>
                <TableCell align="left">
                  {getStoragePlace(item, storageUnits) ?? t('not_available')}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}
