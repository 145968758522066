import { ApolloError } from '@apollo/client'
import { useContext, useEffect } from 'react'
import { Context } from '@/context'
import { useParseErrors } from './useParseErrors'

export function useShowErrorsPopup(error?: ApolloError) {
  const { showErrors } = useContext(Context)
  const parseErrors = useParseErrors()

  useEffect(() => {
    if (error) {
      const errors = parseErrors(error)

      showErrors(errors)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
}
