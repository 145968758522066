import styled from '@emotion/styled'
import {
  Button,
  Checkbox,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import { NoteCreateArgs } from '@/schemaTypes'

interface CreateNoteProps {
  onAddNote: (note: NoteCreateArgs) => void
  horizontalMode?: boolean
  isCreateMode?: boolean
}

const initialNote = {
  content: '',
  isPopup: false,
}

const CreateNote = ({
  onAddNote,
  horizontalMode,
  isCreateMode,
}: CreateNoteProps) => {
  const { isGuestUser } = useContext(Context)
  const { t } = useTranslation()
  const [note, setNote] = useState<NoteCreateArgs>(initialNote)

  const onSave = () => {
    onAddNote(note)
    setNote(initialNote)
  }

  useEffect(() => {
    if (isCreateMode && note.content) {
      onAddNote(note)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note])

  return (
    <div className={`u-block u-mt-20 o-type-bold`}>
      <Typography style={{ marginBottom: 10, fontWeight: 600 }}>
        {t('add_new_note')}:
      </Typography>
      <TextareaAutosize
        rowsMin={5}
        placeholder={t('note_placeholder')}
        style={{
          width: horizontalMode ? '80%' : 500,
          padding: 10,
          border: '1px solid #616161',
          borderRadius: 5,
        }}
        disabled={isGuestUser()}
        value={note.content}
        onChange={(e) => setNote({ ...note, [e.target.name]: e.target.value })}
        name="content"
      />
      <div
        style={
          horizontalMode
            ? { display: 'flex', justifyContent: 'space-between', width: '80%' }
            : { width: 500 }
        }
      >
        <Typography variant="body1" gutterBottom>
          {t('popup_note')}:
          <Checkbox
            name="isPopup"
            checked={note.isPopup ? note.isPopup : false}
            onChange={(e) =>
              setNote({ ...note, [e.target.name]: e.target.checked })
            }
          />
        </Typography>
        {!isGuestUser() && !isCreateMode && (
          <NoteButton
            variant="contained"
            onClick={onSave}
            disabled={!note.content}
          >
            {t('save_note')}
          </NoteButton>
        )}
      </div>
    </div>
  )
}

const NoteButton = styled(Button)`
  &&& {
    background-color: #683ab7c0;
    color: white;
    &:hover {
      background-color: #673aba;
    }
  }
`

export default CreateNote
