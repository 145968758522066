import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from '@/components/Loading'
import {
  GET_CUSTOMER,
  useGetCustomer,
  useUpdateCustomer,
} from '@/domains/customers/hooks'
import { useMutationShowingErrors } from '@/hooks'
import BreadCrumbBar from '@/redesign/components/BreadCrumbBar/BreadCrumbBar'
import TitleBar from '@/redesign/components/TitleBar/TitleBar'
import CustomersConfiguration, {
  CustomerArgs,
} from '@/redesign/domains/customer/components/CustomerConfiguration'

export interface CustomersEditProps {
  customerId: string
}

export function CustomersEdit(props: CustomersEditProps) {
  const { customerId } = props
  const { t } = useTranslation()

  const { customer, queryResult: getCustomerQueryResult } = useGetCustomer({
    variables: {
      customerId,
    },
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const updateCustomer = useMutationShowingErrors({
    mutation: useUpdateCustomer({
      refetchQueries: [GET_CUSTOMER],
    }),
    successMessage: t('customer.updated'),
  })

  const onSave = async (customer: CustomerArgs) => {
    const { ...customerDiff } = customer

    if (customerDiff.additionalInformationForCarPawn) {
      delete customerDiff.additionalInformationForCarPawn.__typename
    }

    setIsLoading(true)

    await updateCustomer({
      variables: customerDiff,
    })

    setIsLoading(false)
  }

  return (
    <div className="w-full">
      {customer && (
        <BreadCrumbBar
          customer={{
            firstname: customer?.firstname,
            lastname: customer?.lastname,
          }}
        />
      )}
      {customer && (
        <TitleBar title={`${customer?.firstname} ${customer?.lastname}`} />
      )}
      {getCustomerQueryResult.loading ? (
        <Loading />
      ) : (
        customer && (
          <CustomersConfiguration
            customer={customer}
            onSave={onSave}
            isLoading={isLoading}
          />
        )
      )}
    </div>
  )
}

export default CustomersEdit
