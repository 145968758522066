import {
  IsBoolean,
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsPhoneNumber,
  IsString,
  IsUrl,
} from 'class-validator'
import { MutationCreateShopArgs, OAddress } from '@/schemaTypes'

export class ShopValidation {
  @IsNotEmpty()
  name: string

  @IsPhoneNumber('AT')
  phone: string

  @IsEmail()
  email: string

  @IsUrl()
  googleMapsLink: string

  @IsNotEmpty()
  @IsString()
  companyId: string

  @IsOptional()
  @IsBoolean()
  disabled?: boolean | null

  @IsOptional()
  @IsString()
  successionShopId?: string

  @IsNotEmpty()
  @IsString()
  openingHours: string

  @IsNotEmpty()
  address: OAddress

  constructor(shop: MutationCreateShopArgs) {
    this.name = shop.name
    this.phone = shop.phone
    this.email = shop.email
    this.googleMapsLink = shop.googleMapsLink
    this.companyId = shop.companyId
    this.disabled = shop.disabled
    this.successionShopId = shop.successionShopId
    this.openingHours = shop.openingHours
    this.address = shop.address
  }
}
