import dayjs from 'dayjs'
import parse from 'html-react-parser'
import { Message } from 'primereact/message'
import { Tag } from 'primereact/tag'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import FormikDatePicker from '@/redesign/components/FormikFields/FormikDatePicker/FormikDatePicker'
import FormikInput from '@/redesign/components/FormikFields/FormikInput/FormikInput'
import FormikTextarea from '@/redesign/components/FormikFields/FormikTextarea/FormikTextarea'
import { CustomerBlacklistFragment } from '@/schemaTypes'

interface CustomerBlacklistFormProps {
  customerBlacklist: CustomerBlacklistFragment | null | undefined
  employeeId: string | null
  foundExistingEntryId: string | null
}

export const CustomerBlacklistForm = (props: CustomerBlacklistFormProps) => {
  const { t } = useTranslation()
  const { employeeId, foundExistingEntryId, customerBlacklist } = props

  return (
    <>
      {employeeId && (
        <Message
          className="!mb-2"
          text={t('customer_blocked_message', {
            date: dayjs(customerBlacklist?.createdAt).format('DD.MM.YYYY'),
            employeeName:
              customerBlacklist?.employee?.firstname +
              ' ' +
              customerBlacklist?.employee?.lastname,
          })}
        />
      )}
      <FormikInput
        name="firstname"
        type="text"
        label={t('customer.firstname')}
        placeholder={t('customer.firstname')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        required
      />

      <FormikInput
        name="lastname"
        label={t('customer.lastname')}
        placeholder={t('customer.lastname')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        required
      />

      <FormikInput
        name="email"
        type="text"
        label={t('customer.email')}
        placeholder={t('customer.email')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      <FormikDatePicker
        name="dateOfBirth"
        label={t('customer.date_of_birth')}
        placeholder={t('customer.date_of_birth')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        showIcon
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-32"
        dateFormat="dd.mm.yy"
      />

      <FormikTextarea
        name="reason"
        label={t('reason')}
        placeholder={t('reason')}
        className="flex flex-col md:flex-row items-start mb-2"
        labelClassName="text-sm font-semibold w-12.5 pt-1.5"
        inputContainerClassName="w-full md:w-96"
        rows={4}
        cols={20}
        tooltipText={'Tooltip text'}
        required
      />
      {!!customerBlacklist?.customers?.length && (
        <div className="flex flex-row">
          <p className="text-sm font-semibold w-48">{t('connected_account')}</p>
          <div>
            {customerBlacklist.customers.map((customer) => (
              <Link to={`/inApp/customers/edit/${customer._id}`}>
                <Tag
                  className="mr-2"
                  icon="pi pi-user"
                  severity="info"
                  value={customer.firstname + ' ' + customer.lastname}
                />
              </Link>
            ))}
          </div>
        </div>
      )}
      {foundExistingEntryId && (
        <p className="m-0 text-red-500 mt-2">
          {parse(
            t('found_existing_customer_blacklist_warning', {
              id: foundExistingEntryId,
            }),
          )}
        </p>
      )}
    </>
  )
}
