import { IconButton, Modal } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { Maybe } from '@/schemaTypes'
import styles from './styles.module.scss'

interface Props {
  onClose: () => void
  viewedPositionIndex: number
  media: IMedia[]
}

export interface IMedia {
  url: string
  type?: Maybe<string>
}

export const ImageGallery = ({
  media,
  viewedPositionIndex,
  onClose,
}: Props) => {
  const [index, setIndex] = useState(viewedPositionIndex)

  const onNavigateMedia = useCallback(
    (num: -1 | 1) => {
      setIndex((index) => {
        let newIndex = index + num

        if (num > 0 && newIndex >= media.length) newIndex -= media.length
        if (num < 0 && newIndex < 0) newIndex += media.length

        return newIndex
      })
    },
    [media.length],
  )

  useEffect(() => {
    const onNavigateByKey = (e) => {
      switch (e.key) {
        case 'ArrowLeft':
          onNavigateMedia(-1)
          return
        case 'ArrowRight':
          onNavigateMedia(1)
          return
      }
    }

    document.addEventListener('keydown', onNavigateByKey)

    return () => document.removeEventListener('keydown', onNavigateByKey)
  }, [onNavigateMedia])

  return (
    <Modal open onClose={onClose}>
      <div className={styles.mediaBox}>
        <IconButton
          aria-label="back"
          className={styles.icon}
          onClick={() => onNavigateMedia(-1)}
        >
          <ArrowBackIcon htmlColor="white" />
        </IconButton>
        <div className={styles.mediaCard}>
          {media[index].type === 'application/pdf' ? (
            <object
              className={styles.pdfMedia}
              data={media[index].url}
              type="application/pdf"
            >
              {' '}
            </object>
          ) : (
            <img
              className={styles.media}
              src={media[index].url}
              alt="item-media"
            />
          )}
        </div>
        <IconButton
          aria-label="next"
          className={`${styles.icon} ${styles.nextIcon}`}
          onClick={() => onNavigateMedia(1)}
        >
          <ArrowBackIcon htmlColor="white" />
        </IconButton>
        <div className={styles.actions}>
          <a target="_blank" rel="noopener noreferrer" href={media[index].url}>
            <IconButton
              aria-label="open-image"
              className={styles.openImageIcon}
            >
              <OpenInNewIcon htmlColor="white" />
            </IconButton>
          </a>
          <IconButton
            aria-label="close-image"
            className={styles.closeIcon}
            onClick={onClose}
          >
            <CloseIcon htmlColor="white" />
          </IconButton>
        </div>
      </div>
    </Modal>
  )
}
