import { TextField, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import React from 'react'
import styles from './AmountInput.module.scss'

export interface AmountInputProps {
  className?: string
  error?: string
  value?: number
  onChange: (value: number) => void
  label?: string
  type?: 'float' | 'int'
  inputWidth?: string
  variant?: 'outlined' | 'filled' | 'standard'
  size?: 'small' | 'medium'
  disabled?: boolean
  onBlur?: () => void
  showRemoveIcon?: boolean
  isStriked?: boolean
  labelRightMargin?: number
}

export default function AmountInput(props: AmountInputProps) {
  const {
    className,
    error,
    value,
    onChange,
    label = '',
    type = 'float',
    inputWidth,
    variant = 'standard',
    size = 'medium',
    disabled = false,
    onBlur,
    showRemoveIcon,
    isStriked,
    labelRightMargin,
  } = props

  return (
    <Typography
      component="div"
      variant="body1"
      gutterBottom
      className={`${styles.AmountInput} ${className} u-flex u-ai-center`}
    >
      <span
        style={{ whiteSpace: 'nowrap', marginRight: `${labelRightMargin}rem` }}
      >
        {label}
      </span>
      <TextField
        type="number"
        value={value}
        onChange={(e) =>
          onChange(
            type === 'float'
              ? parseFloat(e.target.value)
              : parseInt(e.target.value),
          )
        }
        error={!!error ?? false}
        inputProps={{
          step: type === 'float' ? 0.01 : 1,
        }}
        style={{
          width: inputWidth,
          textDecoration: isStriked ? 'line-through' : 'inherit',
        }}
        variant={variant}
        size={size}
        disabled={disabled}
        onBlur={onBlur}
      />
      {showRemoveIcon ? (
        <ClearIcon
          style={{ cursor: 'pointer', color: 'red' }}
          onClick={() => onChange(undefined)}
        />
      ) : (
        ''
      )}
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </Typography>
  )
}
