import { dealFragments } from '.'
import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { Query, QueryGetDealsArgs } from '@/schemaTypes'

const GET_DEALS = gql`
  query getDeals($opts: DealOpts) {
    getDeals(opts: $opts) {
      _id
      bookingNumber
      dealType
      businessUnit
      createdAt
      dealFinalValues {
        payoutAmount
      }
      events {
        ...DealEventFragment
      }
      customer {
        ... on Customer {
          _id
          firstname
          lastname
          email
          isNotePopup
          note
        }
        ...GuestCustomerDataFragment
      }
      pickupData {
        transportMode
        standardShippingData {
          sentShippingLabels {
            shippingPartner
          }
        }
      }
      dropoffData {
        transportMode
      }
      initialShopId
      shop {
        _id
        name
      }
      company {
        name
      }
    }
  }
  ${dealFragments.event}
  ${fragments.guestCustomerData}
`

type TData = { getDeals: Query['getDeals'] }
type TVariables = QueryGetDealsArgs

export function useGetDeals(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useQuery<TData, TVariables>(GET_DEALS, {
    variables: {},
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, deals: queryResult.data?.getDeals || [] }
}
