import { useGetCustomer } from '../../../customers/hooks'
import { useGetSearchedCustomers } from '../../hooks/getSearchedCustomers'
import { List, ListItem, ListItemText } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import dayjs from 'dayjs'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import LoadingSpinner from '@/components/LoadingSpinner'
import { Scalars } from '@/schemaTypes'
import styles from './CustomerSelection.module.scss'

export interface CustomerSelectionProps {
  selectedCustomerId: string | null
  setSelectedCustomerId: (customerId: string | null) => void
}

export function CustomerSelection(props: CustomerSelectionProps) {
  const { selectedCustomerId, setSelectedCustomerId } = props
  const { t } = useTranslation()

  const { customer, queryResult: queryResultCustomer } = useGetCustomer({
    variables: {
      customerId: selectedCustomerId,
    },
  })

  const [searchedFirstName, setSearchedFirstName] = useState('')
  const [searchedLastName, setSearchedLastName] = useState('')
  const [searchedEmail, setSearchedEmail] = useState('')
  const [searchedCustomerNumber, setSearchedCustomerNumber] = useState('')
  const { customers, queryResult: queryResultSearchedCustomer } =
    useGetSearchedCustomers({
      variables: {
        opts: {
          firstname: searchedFirstName,
          lastname: searchedLastName,
          email: searchedEmail,
          customerNumber: searchedCustomerNumber,
        },
      },
    })

  useEffect(() => {
    setSelectedCustomerId(null)
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchedFirstName,
    searchedLastName,
    searchedEmail,
    searchedCustomerNumber,
  ])

  const onSearchResult = (selectedId: Scalars['ObjectId']) => {
    if (selectedId) {
      setSelectedCustomerId(selectedId)
    }
  }

  return (
    <Fragment>
      <div style={{ width: 500, display: 'inline-block' }}>
        <TextField
          autoComplete="off"
          autoFocus
          style={{ margin: '0.5rem' }}
          value={searchedFirstName}
          label={t('customer.firstname')}
          variant="outlined"
          onChange={(value) => setSearchedFirstName(value.target.value)}
        />
        <TextField
          autoComplete="off"
          style={{ margin: '0.5rem' }}
          value={searchedLastName}
          label={t('customer.lastname')}
          variant="outlined"
          onChange={(value) => setSearchedLastName(value.target.value)}
        />
        <TextField
          autoComplete="off"
          style={{ margin: '0.5rem' }}
          value={searchedEmail}
          label={t('customer.email')}
          variant="outlined"
          onChange={(value) => setSearchedEmail(value.target.value)}
        />
        <TextField
          autoComplete="off"
          style={{ margin: '0.5rem' }}
          value={searchedCustomerNumber}
          type={'number'}
          label={t('customer.customer_number')}
          variant="outlined"
          // error={!!error ?? false}
          onChange={(e) => {
            setSearchedCustomerNumber(e.target.value)
          }}
        />
      </div>

      <div className={`${styles.list}`}>
        {queryResultSearchedCustomer.loading ? (
          <div className={`${styles.loadSpinnerCustomers}`}>
            <LoadingSpinner />
          </div>
        ) : customer && selectedCustomerId ? (
          <Paper className="u-p-sm u-12/12 u-mb-sm">
            <Typography variant="body1" gutterBottom>
              {t('customer.firstname')}: {customer.firstname}
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('customer.lastname')}: {customer.lastname}
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('customer.customer_number')}: {customer.customerNumber}
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('customer.date_of_birth')}:{' '}
              {dayjs(customer.dateOfBirth).format('DD.MM.YYYY')}
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('customer.email')}: {customer.email}
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('customer.phone')}: {customer.phone}
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('customer.sex')}: {customer.sex ?? '-'}
              <br />
            </Typography>
          </Paper>
        ) : queryResultCustomer.loading ? (
          <div className={`${styles.loadSpinnerCustomer}`}>
            <LoadingSpinner />
          </div>
        ) : (
          <List dense={true}>
            {customers.map((customer) => {
              return customer.customerNumber.toString() === '1' ? (
                <Link
                  target="_blank"
                  to="/inApp/customers/create"
                  style={{ textDecoration: 'none' }}
                  key={customer._id}
                >
                  <div className={`${styles.notFoundRow}`}>
                    {t('customer.create')}
                  </div>
                </Link>
              ) : (
                <div
                  className={`${styles.itemList}`}
                  onClick={() => onSearchResult(customer._id)}
                  key={customer._id}
                >
                  <ListItem>
                    <ListItemText
                      primary={customer.firstname + ' ' + customer.lastname}
                      secondary={`Email: ${customer.email} | ${t('customer_no')}: ${customer.customerNumber}`}
                    />
                  </ListItem>
                </div>
              )
            })}
          </List>
        )}
      </div>
    </Fragment>
  )
}

export default CustomerSelection
