import PriceDetermination from '../PriceDetermination/PriceDetermination'
import styled from '@emotion/styled'
import { Form, FormikProvider, useFormik } from 'formik'
import { pick, pickBy } from 'lodash'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { Tooltip } from 'primereact/tooltip'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useFormatVariantProperties } from '@/components/ProductSelector/helper'
import { Context } from '@/context'
import { useGetCustomDealFormByCategory } from '@/domains/customDealForms/hooks'
import { TDataCustomDealFormQuestion } from '@/domains/customDealForms/hooks/useGetCustomDealFormByCategory'
import {
  getItemValuationFactor,
  onlyOverwriteIsPossible,
} from '@/domains/customDeals/components/CustomDealDetail/CustomDealItems'
import { CustomDealItem } from '@/domains/customDeals/helpers'
import { useCustomDealPawnPurchaseCalculations } from '@/domains/customDeals/hooks'
import {
  ANSWER_CUSTOMER_CONTINUE_USING_ITEM_NO,
  ANSWER_CUSTOMER_CONTINUE_USING_ITEM_YES,
  EItemCategory,
  PRICE_QUESTION_TITLE_KEY,
  QUESTION_CUSTOMER_CONTINUE_USING_ITEM_ID,
} from '@/domains/deals/components/constants/enums'
import {
  includeHidedCategories,
  useGetItemCategories,
} from '@/domains/itemCategories/hooks'
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback'
import { MINIMUM_CUSTOM_PAYOUT_AMOUNT } from '@/pages/InApp/CustomDeals/edit/[customDealId]'
import CategoryDropdown from '@/redesign/components/CategoryDropdown/CategoryDropdown'
import FormikInput from '@/redesign/components/FormikFields/FormikInput/FormikInput'
import Input from '@/redesign/components/Input/Input'
import InputDropdown from '@/redesign/components/InputDropdown/InputDropdown'
import { ItemFormAnswers } from '@/redesign/components/ItemFormAnswers'
import {
  UppyUploader,
  UppyUploaderFile,
} from '@/redesign/components/UppyUploader/UppyUploader'
import ItemCategoryQuestions from '@/redesign/domains/items/components/Item/ItemEvaluation/ItemCategoryQuestions'
import {
  BooleanCustomDealFormQuestion,
  CustomDealCalculationArgs,
  CustomDealCalculationItem,
  CustomDealFormQuestion,
  CustomDealItemDataEntry,
  ECustomDealFormQuestionTitleKey,
  ECustomDealFormQuestionType,
  ECustomItemFoundComparableOffers,
  EDealType,
  ETransportMode,
  FileMetadata,
  FileTusMetadataArgs,
  FormAnswer,
  ItemAnswer,
  NumberDefaultCustomDealFormQuestion,
  NumberOptionsCustomDealFormQuestion,
  PriceFinding,
  PriceFindingArgs,
  ProductVariant,
  TextDefaultCustomDealFormQuestion,
  TextMultilineCustomDealFormQuestion,
  TextOptionsCustomDealFormQuestion,
  VehicleProperties,
} from '@/schemaTypes'
import {
  isContinueUsingVehicle,
  isStoredVehicle,
  isVehicle,
} from '@/utils/misc'

export interface CustomItemDraftFormData {
  title: string
  itemCategoryId: string
  answers: ItemAnswer[]
  payoutAmount: number
  counterofferPayoutAmount?: number | null
  media?: FileMetadata[]
  mediaUploads: FileTusMetadataArgs[]
  vehicleProperties?: VehicleProperties
  formAnswers?: FormAnswer[]
  policeNumber?: string | undefined | null
  foundComparableOffers?: ECustomItemFoundComparableOffers
  priceFindings?: PriceFindingArgs[]
  overwrittenItemValue?: number
  foundOffersEvaluationFactor?: number
  variantId?: string
  unconfirmedPropertyIds?: string[]
}

const vehiclePropertyKeys = [
  'make',
  'regdate',
  'model',
  'body',
  'facelift',
  'seats',
  'engine',
  'transmission',
  'fuel',
  'horsePower',
  'trim',
  'odometer',
] as const

const definedCarPropertyKeys = [
  'make',
  'model',
  'regdate',
  'body',
  'engine',
  'facelift',
  'seats',
  'transmission',
  'odometer',
  'trim',
]

export interface CustomItemDraftProps {
  companyId: string
  items: CustomDealItem[]
  durationInDays: number
  dealType: EDealType
  onChangeDealType: (dealType: EDealType) => void
  onSave: (formData: CustomItemDraftFormData) => void
  editedIndex: number | undefined
  item:
    | CustomDealItem
    | {
        productVariant: ProductVariant
        itemCategoryId: string
      }
    | undefined

  productVariant?: ProductVariant
}

const CustomItemDraft = (props: CustomItemDraftProps) => {
  const {
    companyId,
    durationInDays,
    items,
    onSave,
    onChangeDealType,
    dealType,
    editedIndex,
    item,
    productVariant,
  } = props
  const { language, showInfo } = useContext(Context)
  const { t } = useTranslation()
  const [priceFindingLengthError, setPriceFindingLengthError] = useState<
    string | undefined
  >()
  const [overwrittenItemValueError, setOverwrittenItemValueError] = useState<
    string | undefined
  >()
  const handleSave = (values: CustomItemDraftFormData) => {
    const priceFindingErr = checkPricefindingsErrors(
      customPawnCalculationArgs.calculationItems[itemIdex],
      foundComparableOffers,
    )
    const OverwrittenItemValueErr = checkOverwrittenItemValueErrors(
      customPawnCalculationArgs.calculationItems[itemIdex],
      foundComparableOffers,
      overwriteItemValue,
    )
    const isPayoutError =
      (dealType === EDealType.Pawn &&
        itemPawnValuesEntry?.payoutAmount <= MINIMUM_CUSTOM_PAYOUT_AMOUNT) ||
      (dealType === EDealType.Purchase &&
        itemPurchaseValuesEntry?.payoutAmount <= MINIMUM_CUSTOM_PAYOUT_AMOUNT)

    if (priceFindingErr) {
      showInfo(t(priceFindingErr))
    } else if (OverwrittenItemValueErr) {
      showInfo(t(OverwrittenItemValueErr))
    } else if (isPayoutError) {
      showInfo(
        t('payout_amount_is_not_valid', {
          amount: MINIMUM_CUSTOM_PAYOUT_AMOUNT,
        }),
      )
    } else {
      onSave({
        title: values.title,
        itemCategoryId: values.itemCategoryId,
        answers,
        payoutAmount: values.payoutAmount,
        counterofferPayoutAmount: values.counterofferPayoutAmount,
        mediaUploads: values.mediaUploads.map(
          ({ id, ...file }: UppyUploaderFile) => file,
        ),
        vehicleProperties: values.vehicleProperties,
        formAnswers: values.formAnswers,
        policeNumber:
          values.itemCategoryId === EItemCategory.LIFE_INSURANCE
            ? values.policeNumber
            : '',
        priceFindings: values.priceFindings,
        foundComparableOffers: values.foundComparableOffers,
        overwrittenItemValue: values.overwrittenItemValue,
        foundOffersEvaluationFactor: values.foundOffersEvaluationFactor,
        variantId: values.variantId,
        unconfirmedPropertyIds: values.unconfirmedPropertyIds,
      })
    }
  }
  // TRANSLATION - TODO
  const customDealItemValidationSchema = yup.object().shape({
    title: yup
      .string()
      .trim()
      .required(t('error.error_validation_is_required')),
    itemCategoryId: yup
      .string()
      .trim()
      .required(t('error.error_validation_is_required')),
    variantId: yup.string().nullable(),
    payoutAmount: yup.number().when('variantId', {
      is: (value) => !value,
      then: () =>
        yup
          .number()
          .positive(t('error.error_validation_payout_amount_greater_than_0'))
          .required(t('error.error_validation_payout_amount_greater_than_0')),
    }),
    mediaUploads: yup.array(),
    priceFindings: yup.array(
      yup.object().shape({
        url: yup.string().url('Must be a valid URL').nullable(),
        price: yup.number().nullable(),
      }),
    ),
    foundOffersEvaluationFactor: yup
      .number()
      .min(0.4, 'Must be at least 0.4')
      .max(1, 'Must be at most 1')
      .required('Required'),
  })

  const initialValues = getInitialFormValues(item, dealType)

  const formik = useFormik<CustomItemDraftFormData>({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSave,
    validationSchema: customDealItemValidationSchema,
  })

  const { values, setFieldValue, errors, isValid, dirty, isSubmitting } = formik

  const {
    itemCategoryId,
    title,
    answers,
    vehicleProperties,
    formAnswers,
    policeNumber,
    priceFindings,
    foundComparableOffers,
    overwrittenItemValue,
    foundOffersEvaluationFactor,
    counterofferPayoutAmount,
    variantId,
  } = values

  const [overwriteItemValue, setOverWriteItemValue] = useState<boolean>(
    Boolean(overwrittenItemValue) ||
      foundComparableOffers === ECustomItemFoundComparableOffers.OneOrTwo ||
      foundComparableOffers === ECustomItemFoundComparableOffers.NoOffersFound,
  )

  const defaultCustomDealCalculation = getDefaultCustomDealCalculation(
    companyId,
    items,
    durationInDays,
    vehiclePropertyKeys,
  )
  const [customPawnCalculationArgs, setCustomPawnCalculationArgs] =
    useState<CustomDealCalculationArgs>({ ...defaultCustomDealCalculation })

  const [setDebouncedCalculationArgs] = useDebouncedCallback(
    (newCustomDealCalculationArgs: CustomDealCalculationArgs) => {
      setCustomPawnCalculationArgs(newCustomDealCalculationArgs)
    },
    1000,
  )

  const { itemCategories } = useGetItemCategories(includeHidedCategories)
  const currentCategories = itemCategories.filter(
    (category) => category._id === itemCategoryId,
  )
  const isCarPawn = !!item?.itemCategoryId && isVehicle(item?.itemCategoryId)
  const itemIdex = !item ? items.length : editedIndex
  const dealTypeOptions = useMemo(() => {
    return Object.entries(EDealType).map(([label, value]) => ({ label, value }))
  }, [])

  const { queryResult: customPawnPurchaseCalculationsResult } =
    useCustomDealPawnPurchaseCalculations({
      variables: {
        customDealCalculationArgs: customPawnCalculationArgs,
      },
      skip:
        Boolean(
          checkPricefindingsErrors(
            customPawnCalculationArgs.calculationItems[itemIdex],
            foundComparableOffers,
          ),
        ) ||
        Boolean(
          checkOverwrittenItemValueErrors(
            customPawnCalculationArgs.calculationItems[itemIdex],
            foundComparableOffers,
            overwriteItemValue,
          ),
        ) ||
        !foundComparableOffers,
    })

  const itemPawnValuesEntry =
    customPawnPurchaseCalculationsResult?.data
      ?.customDealPawnPurchaseCalculations?.pawnCalculation.itemsValuesEntries[
      itemIdex
    ]
  const itemPurchaseValuesEntry =
    customPawnPurchaseCalculationsResult?.data
      ?.customDealPawnPurchaseCalculations?.purchaseCalculation
      ?.itemsValuesEntries[itemIdex]

  const { queryResult } = useGetCustomDealFormByCategory({
    variables: {
      categoryId: itemCategoryId,
      languageCode: language,
    },
    skip: !itemCategoryId || !!item,
  })

  const onHandleChangeAnswers = (answers: ItemAnswer[]) => {
    setFieldValue('answers', answers)
  }

  useEffect(() => {
    const questions = queryResult.data?.getCustomDealFormByCategory?.questions

    if (!questions) return

    const initialFormAnswers = getInitialFormAnswers(
      questions,
      formAnswers ?? [],
    )

    setFieldValue('formAnswers', initialFormAnswers)
    // only allow to run init one time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult])

  const getProductTitleFromFormAnswers = (
    question: CustomDealFormQuestion,
    value: string,
  ) => {
    if (
      question.__typename !== 'TextOptionsCustomDealFormQuestion' &&
      question.__typename !== 'NumberOptionsCustomDealFormQuestion'
    ) {
      return value
    }

    if (
      question.__typename === 'TextOptionsCustomDealFormQuestion' ||
      question.__typename === 'NumberOptionsCustomDealFormQuestion'
    ) {
      const label = question.options?.[parseInt(value)]?.label
      return label.text ?? label?.[0]?.text
    }

    return value
  }

  const onChangeAnswer = ({
    index,
    newFormAnswer,
    allowEnterAnswer,
  }: {
    index: number
    newFormAnswer:
      | {
          value?: number
          type:
            | ECustomDealFormQuestionType.NumberDefault
            | ECustomDealFormQuestionType.NumberOptions
          question:
            | NumberDefaultCustomDealFormQuestion
            | NumberOptionsCustomDealFormQuestion
        }
      | {
          value: string
          type:
            | ECustomDealFormQuestionType.TextDefault
            | ECustomDealFormQuestionType.TextMultiline
            | ECustomDealFormQuestionType.TextOptions
          question:
            | TextDefaultCustomDealFormQuestion
            | TextMultilineCustomDealFormQuestion
            | TextOptionsCustomDealFormQuestion
        }
      | {
          value: boolean
          type: ECustomDealFormQuestionType.Boolean
          question: BooleanCustomDealFormQuestion
        }
    allowEnterAnswer?: boolean
  }) => {
    const fieldMap = {
      [ECustomDealFormQuestionType.TextDefault]: 'textAnswer',
      [ECustomDealFormQuestionType.TextMultiline]: 'textMultilineAnswer',
      [ECustomDealFormQuestionType.Boolean]: 'booleanAnswer',
      [ECustomDealFormQuestionType.NumberDefault]: 'numberAnswer',
      [ECustomDealFormQuestionType.TextOptions]: 'optionTextAnswer',
      [ECustomDealFormQuestionType.NumberOptions]: 'optionNumberAnswer',
    }

    const fieldKey = `formAnswers[${index}].${fieldMap[newFormAnswer.type]}`

    setFieldValue(fieldKey, newFormAnswer.value)

    if (
      newFormAnswer.question.titleKey ===
        ECustomDealFormQuestionTitleKey.ProductName &&
      newFormAnswer.type === ECustomDealFormQuestionType.TextDefault
    ) {
      const titleValue =
        allowEnterAnswer || newFormAnswer.value === ''
          ? newFormAnswer.value
          : getProductTitleFromFormAnswers(
              newFormAnswer.question,
              newFormAnswer.value,
            )

      setFieldValue('title', titleValue)
    }
  }

  const onChangeProductTitle = (value: string) => {
    setFieldValue('title', value)

    if (!Array.isArray(formAnswers)) {
      throw new Error('Expected formAnswers to be a Array')
    }

    const productTitleQuestion = formAnswers.find((answer) => {
      const { question } = answer
      return question.titleKey === ECustomDealFormQuestionTitleKey.ProductName
    })
    // ProductName in questions must be text type
    if (productTitleQuestion?.__typename === 'FormTextAnswer') {
      const titleFormAnswerIndex = formAnswers.indexOf(productTitleQuestion)
      setFieldValue(`formAnswers.${titleFormAnswerIndex}.textAnswer`, value)
    }
  }

  useEffect(() => {
    let newEvaluationFactor = foundOffersEvaluationFactor
    if (!onlyOverwriteIsPossible(foundComparableOffers)) {
      newEvaluationFactor = getItemValuationFactor(values.foundComparableOffers)
    }
    setFieldValue('foundOffersEvaluationFactor', newEvaluationFactor)

    if (
      (!errors || Object.keys(errors).length === 0) &&
      values.itemCategoryId?.length > 0
    ) {
      const calculationItems: CustomDealCalculationItem[] =
        customPawnCalculationArgs.calculationItems

      if (!item || 'productVariant' in item) {
        if (calculationItems.length === items.length) {
          calculationItems.push(undefined)
        }
      }

      const newCustomDealCalculationArgs = {
        ...customPawnCalculationArgs,
        isReversedFeeCalculation: false,
        calculationItems: calculationItems?.map((itemArgs, index) => {
          if (index !== itemIdex) {
            return {
              ...itemArgs,
            }
          } else {
            return {
              ...itemArgs,
              answers: values.answers,
              itemCategoryId: values.itemCategoryId,
              payoutAmount: values.overwrittenItemValue
                ? undefined
                : values.payoutAmount,
              foundComparableOffersPrices: values.priceFindings?.length
                ? values.priceFindings
                    ?.filter((c) => c.price)
                    .map((c) => c.price)
                : undefined,
              overwrittenItemValue: values.overwrittenItemValue,
              foundOffersEvaluationFactor: newEvaluationFactor,
            }
          }
        }),
      }

      setPriceFindingLengthError(
        checkPricefindingsErrors(
          newCustomDealCalculationArgs.calculationItems[itemIdex],
          foundComparableOffers,
        ),
      )

      setOverwrittenItemValueError(
        checkOverwrittenItemValueErrors(
          newCustomDealCalculationArgs.calculationItems[itemIdex],
          foundComparableOffers,
          overwriteItemValue,
        ),
      )

      setDebouncedCalculationArgs(newCustomDealCalculationArgs)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.answers,
    values.counterofferPayoutAmount,
    values.foundComparableOffers,
    values.priceFindings,
    values.overwrittenItemValue,
    values.foundOffersEvaluationFactor,
    values.foundComparableOffers,
    values.itemCategoryId,
  ])

  const syncItemQuestionsAnswersWithCategoryId = (itemCategoryId: string) => {
    if (isVehicle(itemCategoryId)) {
      if (!Array.isArray(answers)) {
        throw new Error('Expected answers to be a Array')
      }

      const question = answers.find(
        (c) => c.questionId === QUESTION_CUSTOMER_CONTINUE_USING_ITEM_ID,
      )

      if (!question) {
        throw new Error('Question not found')
      }

      if (isStoredVehicle(itemCategoryId)) {
        question.selectedOptionIndex = ANSWER_CUSTOMER_CONTINUE_USING_ITEM_NO
      } else if (isContinueUsingVehicle(itemCategoryId)) {
        question.selectedOptionIndex = ANSWER_CUSTOMER_CONTINUE_USING_ITEM_YES
      }

      onHandleChangeAnswers(answers)
    }
  }

  const formattedVariantProperties = useFormatVariantProperties(
    item
      ? 'productVariant' in item
        ? item.productVariant
        : productVariant
      : undefined,
  )

  return (
    <div>
      <Tooltip target=".custom-target-icon" />
      <FormikProvider value={formik}>
        <Form>
          <div>
            <Input
              value={title}
              label={t('product_name')}
              className="flex flex-col md:flex-row items-start md:items-center mb-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-52"
              onChange={(e) => onChangeProductTitle(e.target.value)}
              isInvalid={!!errors.title}
              errorText={t(errors.title ?? '')}
              disabled={Boolean(variantId)}
            />
            <CategoryDropdown
              label={t('category')}
              placeholder={t('Select Item')}
              className="flex flex-col md:flex-row items-start md:items-center mb-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="min-w-52"
              filter
              onChangeCategory={(categoryId) => {
                setFieldValue('itemCategoryId', categoryId)
                syncItemQuestionsAnswersWithCategoryId(categoryId.toString())
              }}
              categories={itemCategories}
              currentCategories={currentCategories}
              disabled={Boolean(variantId)}
            />
            <InputDropdown
              value={dealType}
              label={t('type')}
              options={dealTypeOptions}
              placeholder={t('type')}
              className="flex flex-col md:flex-row items-start md:items-center mb-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-52"
              onChange={(e) => onChangeDealType(e.target.value as EDealType)}
            />
          </div>
          <Divider />

          <div className="flex flex-col lg:flex-row">
            <div className="flex-1">
              <StyledTitle>{t('item_specification')}</StyledTitle>
              {!!formAnswers?.length && (
                <ItemFormAnswers
                  formAnswers={formAnswers}
                  onChangeAnswer={onChangeAnswer}
                />
              )}
              {policeNumber && (
                <FormikInput
                  name="policeNumber"
                  label={t('police_number')}
                  className="flex flex-col md:flex-row items-start md:items-center mb-2"
                  labelClassName="text-sm font-semibold w-12.5"
                  inputContainerClassName="w-52"
                />
              )}
              {isCarPawn &&
                vehicleProperties &&
                definedCarPropertyKeys.map((vehiclePropertyKey) => (
                  <FormikInput
                    key={vehiclePropertyKey}
                    name={`vehicleProperties.${vehiclePropertyKey}`}
                    label={t(`car_${vehiclePropertyKey}`)}
                    className="flex flex-col md:flex-row items-start md:items-center mb-2"
                    labelClassName="text-sm font-semibold w-12.5"
                    inputContainerClassName="w-52"
                  />
                ))}
              {formattedVariantProperties
                ?.filter(
                  (property) =>
                    !(
                      'unconfirmedPropertyIds' in item &&
                      item.unconfirmedPropertyIds?.includes(property.id)
                    ),
                )
                .map((property) => {
                  return (
                    <Input
                      value={property.value}
                      label={property.title}
                      disabled={true}
                      className="flex flex-col md:flex-row items-start md:items-center mb-2"
                      labelClassName="text-sm font-semibold w-12.5"
                      inputContainerClassName="w-52"
                    />
                  )
                })}
            </div>
            <Divider layout="vertical" className="!hidden lg:!block" />
            <div className="flex-1">
              {itemCategoryId && (
                <>
                  <StyledTitle>{t('additional_fee')}</StyledTitle>

                  <ItemCategoryQuestions
                    itemCategoryId={itemCategoryId}
                    isReadOnly={false}
                    loadCurrentAnswers={Boolean(answers.length)}
                    itemAnswerArgs={answers}
                    setItemAnswerArgs={onHandleChangeAnswers}
                    hideConditionQuestion={!!formAnswers?.length}
                    disabledQuestionsIds={[
                      QUESTION_CUSTOMER_CONTINUE_USING_ITEM_ID,
                    ]}
                    omitDeactivatedQuestionsForNewProducts={false}
                  />

                  {errors.itemCategoryId && (
                    <small className="p-error">
                      {t(errors.itemCategoryId)}
                    </small>
                  )}
                </>
              )}
            </div>
          </div>
          <Divider />

          <PriceDetermination
            isVehicle={isCarPawn}
            itemPawnValuesEntry={itemPawnValuesEntry}
            itemPurchaseValuesEntry={itemPurchaseValuesEntry}
            calculationRunning={customPawnPurchaseCalculationsResult.loading}
            dealType={dealType}
            foundComparableOffers={foundComparableOffers}
            values={priceFindings ?? []}
            setFieldValue={setFieldValue}
            foundOffersEvaluationFactor={foundOffersEvaluationFactor}
            formikErrors={errors}
            overwrittenItemValue={overwrittenItemValue}
            overwriteItemValue={overwriteItemValue}
            setOverWriteItemValue={setOverWriteItemValue}
            priceFindingLengthError={priceFindingLengthError}
            overwrittenItemValueError={overwrittenItemValueError}
            counterofferPayoutAmount={counterofferPayoutAmount}
            variantId={variantId}
          />
          <Divider />

          <UppyUploader
            confirmPopupBeforeRemove
            multiple
            label={t('product_pictures')}
            name="mediaUploads"
          />
        </Form>
      </FormikProvider>

      <div className="sticky bottom-0 left-0 w-full">
        <div className="flex justify-end p-5 bg-white relative top-10">
          <Button
            label={t('reset')}
            severity="secondary"
            text
            icon="pi pi-replay"
            className="!mr-3.5"
            disabled={!dirty}
            onClick={() => formik.resetForm()}
          />

          <Button
            label={t('save')}
            type="submit"
            icon="pi pi-save"
            onClick={formik.submitForm}
            disabled={
              !isValid ||
              !dirty ||
              isSubmitting ||
              customPawnPurchaseCalculationsResult.loading
            }
          />
        </div>
      </div>
    </div>
  )
}

const StyledTitle = styled.h5`
  color: #000;

  /* h5/semibold-600/line-height-1 */
  font-family: Inter;
  font-size: 1.09375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.09375rem; /* 100% */
  margin-bottom: 0.66rem;
`

export default CustomItemDraft

const getDefaultCustomDealCalculation = (
  companyId,
  items,
  durationInDays,
  vehiclePropertyKeys,
) => {
  const customDealCalcArgs = {
    companyId: companyId,
    calculationItems: items.map((item) => ({
      ...pick(item, [
        'ean',
        'algoliaReference',
        'itemCategoryId',
        'material',
        'variantId',
      ]),
      vehicleData: item.vehicleData
        ? {
            indicataId: item.vehicleData.indicataId,
            vehicleProperties: pick(
              item.vehicleData?.vehicleProperties,
              vehiclePropertyKeys,
            ),
          }
        : undefined,
      answers: item.answers ?? [],
      payoutAmount: item.pawnPayoutAmount,
      foundComparableOffersPrices:
        item.priceFindings?.filter((c) => c.price)?.map((c) => c.price) ?? [],
      overwrittenItemValue: item.overwrittenItemValue,
      foundOffersEvaluationFactor: item.foundOffersEvaluationFactor,
    })),

    durationInDays: durationInDays,
    pickupTransportMode: ETransportMode.Shop,
    isReversedFeeCalculation: false,
  }

  return customDealCalcArgs
}

function checkPricefindingsErrors(
  itemArgs: CustomDealCalculationItem,
  foundComaparableOffers: ECustomItemFoundComparableOffers,
): string | undefined {
  switch (foundComaparableOffers) {
    case ECustomItemFoundComparableOffers.OneOrTwo:
      if ((itemArgs?.foundComparableOffersPrices?.length ?? 0) < 1)
        return 'custom_item_valuation_price_finding_should_be_at_least_one'
      break

    case ECustomItemFoundComparableOffers.LessThanTen:
      if ((itemArgs?.foundComparableOffersPrices?.length ?? 0) < 2)
        return 'custom_item_valuation_price_finding_should_be_at_least_two'
      break

    case ECustomItemFoundComparableOffers.MoreThanTen:
      if ((itemArgs?.foundComparableOffersPrices?.length ?? 0) < 3)
        return 'custom_item_valuation_price_finding_should_be_at_least_three'
      break
  }
}

function checkOverwrittenItemValueErrors(
  itemArgs: CustomDealCalculationItem,
  foundComaparableOffers: ECustomItemFoundComparableOffers,
  overwriteItemValue: boolean,
): string | undefined {
  switch (foundComaparableOffers) {
    case ECustomItemFoundComparableOffers.NoOffersFound:
    case ECustomItemFoundComparableOffers.OneOrTwo:
      if (!itemArgs?.overwrittenItemValue) {
        return 'custom_item_valuation_overwritten_item_value_is_missing'
      }
      break

    case ECustomItemFoundComparableOffers.LessThanTen:
    case ECustomItemFoundComparableOffers.MoreThanTen:
      if (overwriteItemValue && !itemArgs?.overwrittenItemValue) {
        return 'custom_item_valuation_overwritten_item_value_is_missing'
      }
      break
  }
}

const getInitialFormAnswers = (
  questions: TDataCustomDealFormQuestion[],
  formAnswers: FormAnswer[],
): FormAnswer[] => {
  return (
    questions
      // Because already set it with outer field
      .filter((question) => question.titleKey !== PRICE_QUESTION_TITLE_KEY)
      .map((question) => {
        const formAnswer = formAnswers?.find((formAnswer) => {
          return formAnswer.question._id === question._id
        })
        if (question.__typename === 'TextDefaultCustomDealFormQuestion') {
          if (formAnswer?.__typename === 'FormTextAnswer') {
            return {
              __typename: 'FormTextAnswer',
              textAnswer: formAnswer.textAnswer,
              question,
            }
          } else {
            return {
              __typename: 'FormTextAnswer',
              question,
            }
          }
        }

        if (question.__typename === 'TextMultilineCustomDealFormQuestion') {
          if (formAnswer?.__typename === 'FormMultilineTextAnswer') {
            return {
              __typename: 'FormMultilineTextAnswer',
              textMultilineAnswer: formAnswer.textMultilineAnswer,
              question,
            }
          } else {
            return {
              __typename: 'FormMultilineTextAnswer',
              question,
            }
          }
        }

        if (question.__typename === 'NumberOptionsCustomDealFormQuestion') {
          if (formAnswer?.__typename === 'FormOptionNumberAnswer') {
            return {
              __typename: 'FormOptionNumberAnswer',
              optionNumberAnswer: formAnswer.optionNumberAnswer,
              question,
            }
          } else {
            return {
              __typename: 'FormOptionNumberAnswer',
              question,
            }
          }
        }

        if (question.__typename === 'TextOptionsCustomDealFormQuestion') {
          if (formAnswer?.__typename === 'FormOptionTextAnswer') {
            return {
              __typename: 'FormOptionTextAnswer',
              optionTextAnswer: formAnswer.optionTextAnswer,
              question,
            }
          } else {
            return {
              __typename: 'FormOptionTextAnswer',
              question,
            }
          }
        }

        if (question.__typename === 'BooleanCustomDealFormQuestion') {
          if (formAnswer?.__typename === 'FormBooleanAnswer') {
            return {
              __typename: 'FormBooleanAnswer',
              booleanAnswer: formAnswer.booleanAnswer,
              question,
            }
          } else {
            return {
              __typename: 'FormBooleanAnswer',
              question,
            }
          }
        }

        if (question.__typename === 'NumberDefaultCustomDealFormQuestion') {
          if (formAnswer?.__typename === 'FormNumberAnswer') {
            return {
              __typename: 'FormNumberAnswer',
              numberAnswer: formAnswer.numberAnswer,
              question,
            }
          } else {
            return {
              __typename: 'FormNumberAnswer',
              question,
            }
          }
        }

        if (question.__typename === 'FileUploadCustomDealFormQuestion') {
          return {
            __typename: 'FormFileUploadAnswer',
            question: question,
          }
        }

        throw new Error('Invalid question type')
      })
  )
}

const getInitialFormValues = (
  item:
    | CustomDealItem
    | {
        productVariant: ProductVariant
        itemCategoryId: string
      }
    | undefined,
  dealType: EDealType,
): CustomItemDraftFormData => {
  if (!item) {
    return {
      title: '',
      itemCategoryId: '',
      answers: [],
      payoutAmount: 0,
      mediaUploads: [],
    }
  }

  return 'productVariant' in item
    ? {
        title: item.productVariant.name || '',
        itemCategoryId: item.itemCategoryId ?? '',
        answers: [],
        payoutAmount: 0,
        mediaUploads: item.productVariant.image
          ? [
              {
                url: item.productVariant.image?.url,
              },
            ]
          : [],
        variantId: item.productVariant.id,
        unconfirmedPropertyIds:
          item.productVariant.unconfirmedPropertyIds ?? [],
      }
    : {
        policeNumber: item.policeNumber,
        title: item.title || '',
        itemCategoryId: item.itemCategoryId ?? '',
        answers: item.answers ?? [],
        payoutAmount:
          dealType === EDealType.Pawn
            ? item.pawnPayoutAmount || 0
            : item.purchasePayoutAmount || 0,
        counterofferPayoutAmount:
          (item as CustomDealItemDataEntry).counterofferPayoutAmount ?? 0,
        mediaUploads: item.media ?? [],
        vehicleProperties:
          item.vehicleData?.vehicleProperties &&
          (pickBy(
            item.vehicleData.vehicleProperties,
            (vehiclePropertyValue, vehiclePropertyKey) =>
              typeof vehiclePropertyValue !== 'object' &&
              vehiclePropertyKey !== '__typename',
          ) as VehicleProperties),
        formAnswers: item.formAnswers ?? [],
        foundComparableOffers: item.foundComparableOffers,
        priceFindings:
          (item.priceFindings as PriceFinding[])
            ?.filter((c) => c.price && c.url)
            .map(({ __typename, ...args }) => args) ?? [],
        overwrittenItemValue: item.overwrittenItemValue ?? undefined,
        foundOffersEvaluationFactor:
          item.foundOffersEvaluationFactor === 1
            ? 0.6
            : item.foundOffersEvaluationFactor,
        variantId: item.variantId,
        unconfirmedPropertyIds: item.unconfirmedPropertyIds ?? [],
      }
}
