import { IconButton } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Box } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Info from '@/components/Info'
import TextInput from '@/components/TextInput'
import { useMutationShowingErrors } from '@/hooks'
import styles from './EmployeeChangePassword.module.scss'
import { useEmployeeChangePassword } from './hooks'

export function EmployeeChangePassword(props) {
  const { history, showAlert } = props
  const { t } = useTranslation()

  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [repeatPassword, setRepeatPassword] = useState<string>('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [showNewPassword, setShowNewPassword] = React.useState(false)
  const [showRepeatPassword, setRepeatShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show)
  const handleClickShowRepeatPassword = () =>
    setRepeatShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  const handleValidatePassword = useCallback((value: string) => {
    // New Password must have 16 characters
    // Password must contain at least one number
    // Password must contain at least one special characters
    const regexPassword = /^(?=.*\d)(?=.*[-_.?!@#$%^&*])(.{16,})$/
    return !regexPassword.test(value)
  }, [])

  const employeeChangePassword = useMutationShowingErrors({
    mutation: useEmployeeChangePassword(),
    successMessage: t('employee_changed_password'),
  })

  const inValidNewPassword = useMemo(() => {
    if (!newPassword) return ''
    if (newPassword.length <= 0) return ''
    const isValid = handleValidatePassword(newPassword)
    if (isValid) {
      return t('invalid_password')
    }
    return ''
  }, [t, newPassword, handleValidatePassword])

  const inValidRepeatPassword = useMemo(() => {
    if (!newPassword || !repeatPassword) return ''
    if (newPassword.length <= 0 || repeatPassword.length <= 0) return ''

    if (newPassword !== repeatPassword) return t('repeat_password_not_compare')
    return ''
  }, [t, newPassword, repeatPassword])

  const _changePassword = async (e) => {
    e.preventDefault()
    if (!oldPassword) {
      return showAlert(t('missing_field_old_password'))
    }

    if (inValidNewPassword !== '') return showAlert(t('INVALID_PASSWORD'))
    if (newPassword !== repeatPassword)
      return showAlert(t('REPEAT_PASSWORD_NOT_COMPARE'))

    const res = await employeeChangePassword({
      variables: {
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
    })
    if (!res || !res.data) return showAlert(t('employee_change_password_fail'))

    setOldPassword('')
    setNewPassword('')
    setRepeatPassword('')
    history.goBack()
  }

  const _cancel = (e) => {
    e.preventDefault()
    history.goBack()
  }

  return (
    <main>
      <CssBaseline />
      <Paper className={styles.wrapper}>
        <Typography component="h1" variant="h5" align="center">
          {t('change_password')}
        </Typography>
        <form onSubmit={_changePassword} className={styles.form}>
          <FormControl margin="dense" required fullWidth>
            <div>
              <div className={styles.inlineBlockDiv}>
                <TextInput
                  value={oldPassword}
                  name="old-password"
                  autoComplete="old-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(value) => setOldPassword(value)}
                  style={{ marginBottom: 10 }}
                  fullWidth
                  label={
                    <div className={styles.inputLabel}>
                      {' '}
                      {t('old_password')}
                    </div>
                  }
                  icon={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  }
                />
              </div>
              <div className={styles.inlineBlockDiv}>
                <TextInput
                  error={inValidNewPassword}
                  value={newPassword}
                  name="new-password"
                  autoComplete="new-password"
                  type={showNewPassword ? 'text' : 'password'}
                  onChange={(value) => setNewPassword(value)}
                  style={{ marginBottom: 10 }}
                  fullWidth
                  label={
                    <div className={styles.inputLabel}>{t('new_password')}</div>
                  }
                  errorMessagePosition="bottom"
                  icon={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  }
                />
              </div>
              <div className={styles.inlineBlockDiv}>
                <Info
                  className={'o-media__fixed u-inline-block'}
                  svgClassName={`${styles.infoIcon} u-pl-5 u-pr-5`}
                  infoText={`${t('criteria_new_password_must_16_char')}<br>${t('criteria_password_contain_least_one_number')}<br>${t(
                    'CRITERIA_PASSWORD_CONTAIN_LEAST_ONE_SPECIAL',
                  )}`}
                />
              </div>
              <div className={styles.inlineBlockDiv}>
                <TextInput
                  error={inValidRepeatPassword}
                  value={repeatPassword}
                  name="repeat-password"
                  autoComplete="repeat-password"
                  type={showRepeatPassword ? 'text' : 'password'}
                  onChange={(value) => setRepeatPassword(value)}
                  style={{ marginBottom: 10 }}
                  fullWidth
                  label={
                    <div className={styles.inputLabel}>
                      {t('repeat_password')}
                    </div>
                  }
                  errorMessagePosition="bottom"
                  icon={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowRepeatPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  }
                />
              </div>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '45px',
                }}
              >
                <Button
                  onClick={_cancel}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {t('cancel')}
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {t('save')}
                </Button>
              </Box>
            </div>
          </FormControl>
        </form>
      </Paper>
    </main>
  )
}

export default EmployeeChangePassword
