import { dealFragments } from '.'
import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationGenerateUserAgreementArgs } from '@/schemaTypes'

const GENERATE_USER_AGREEMENT = gql`
  mutation generateUserAgreement(
    $generateUserAgreementArgs: GenerateUserAgreementArgs!
  ) {
    generateUserAgreement(
      generateUserAgreementArgs: $generateUserAgreementArgs
    ) {
      _id
      pawnData {
        ...PawnDataFragment
      }
    }
  }
  ${dealFragments.pawnData}
`

type TData = { generateUserAgreement: Mutation['generateUserAgreement'] }
type TVariables = MutationGenerateUserAgreementArgs

export function useGenerateUserAgreement(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [generateUserAgreement] = useMutation<TData, TVariables>(
    GENERATE_USER_AGREEMENT,
    opts,
  )

  return generateUserAgreement
}
