import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'
import {
  Query,
  QueryCheckCustomDealItemsPossiblyStolenArgs,
} from '@/schemaTypes'

const CHECK_CUSTOM_DEAL_ITEMS_POSSIBLY_STOLEN = gql`
  query checkCustomDealItemsPossiblyStolen($customDealId: ObjectId!) {
    checkCustomDealItemsPossiblyStolen(customDealId: $customDealId)
  }
`

type TData = {
  checkCustomDealItemsPossiblyStolen: Query['checkCustomDealItemsPossiblyStolen']
}
type TVariables = QueryCheckCustomDealItemsPossiblyStolenArgs

export function useCheckCustomDealItemsPossiblyStolen(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    CHECK_CUSTOM_DEAL_ITEMS_POSSIBLY_STOLEN,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    customDealItemsPossiblyStolen:
      queryResult.data?.checkCustomDealItemsPossiblyStolen,
  }
}
