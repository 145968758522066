import styled from '@emotion/styled'
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  TableContainer,
  Typography,
} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { GetApp, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import Add from '@material-ui/icons/Add'
import { Pagination, Stack } from '@mui/material'
import dayjs from 'dayjs'
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ObjectSchema, number, object, string } from 'yup'
import Loading from '@/components/Loading'
import { Context } from '@/context'
import { useCreateExportEntry } from '@/domains/csvExports/hooks/createExportEntry'
import { useGetExportEntries } from '@/domains/csvExports/hooks/getExportEntries'
import { useGetActiveEmployees } from '@/domains/employees/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { useSyncSearchParams } from '@/hooks/useSyncSearchParams'
import { EExportStatusType, EOrderBy, ExportEntry } from '@/schemaTypes'

const TAKE = 30

export type FilterObject = {
  status?: string
  page: number
}

export const filterSchema: ObjectSchema<FilterObject> = object({
  status: string().optional(),
  page: number().optional().default(1),
}).required()

const ExportEntryListContainer = styled.div`
  display: block;
`

interface ExportEntryRowProps {
  exportEntry: ExportEntry
  getEmployeeName: (employeeId: string) => string
}

function ExportEntryRow(props: ExportEntryRowProps) {
  const { exportEntry, getEmployeeName } = props
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {exportEntry._id}
        </TableCell>
        <TableCell align="left">{exportEntry.exportStatus}</TableCell>
        <TableCell align="left">
          {dayjs(exportEntry.createdAt).format('DD.MM.YYYY HH:mm')}
        </TableCell>
        <TableCell align="left">
          {getEmployeeName(exportEntry.employeeId)}
        </TableCell>
        <TableCell align="left">{exportEntry.error}</TableCell>
        <TableCell align="right">
          {exportEntry.exportStatus === EExportStatusType.Finished && (
            <a
              href={exportEntry.fileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GetApp />
            </a>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={3}>
              <Typography variant="h6" gutterBottom component="div">
                {t('item.plural')}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('file')}</TableCell>
                    <TableCell>{t('number_of_processed_rows')}</TableCell>
                    <TableCell align="right">{t('status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exportEntry.items.map((item) => (
                    <TableRow key={item.viewName}>
                      <TableCell component="th" scope="row">
                        {item.viewName}
                      </TableCell>
                      <TableCell>{item.currentChunkInProgress}</TableCell>
                      <TableCell align="right">{item.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>{' '}
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

function ExportEntryList() {
  const { getCurrentUser } = useContext(Context)
  const { t } = useTranslation()
  const { employees } = useGetActiveEmployees()

  const getEmployeeName = useCallback(
    (employeeId: string) => {
      const employee = employees.find((employee) => employee._id === employeeId)

      return employee ? `${employee.firstname} ${employee.lastname}` : ''
    },
    [employees],
  )

  const createExportEntry = useMutationShowingErrors({
    mutation: useCreateExportEntry(),
    successMessage: t('create_successful'),
  })

  const [filterValues, setFilterValues] =
    useSyncSearchParams<FilterObject>(filterSchema)

  const [exportEntries, setExportEntries] = useState<ExportEntry[]>([])

  const {
    queryResult,
    exportEntries: exportEntriesQueryResult,
    totalPages,
  } = useGetExportEntries({
    variables: {
      opts: {
        order: { createdAt: EOrderBy.Desc },
        take: TAKE,
        skip: Math.max(filterValues.page - 1, 0) * TAKE, // Define min skip = 0
      },
    },
  })

  const onChangePage = (e, page: number) => {
    setFilterValues((state) => ({ ...state, page }))
  }

  const onClickTriggerExport = () => {
    createExportEntry({
      variables: {
        employeeId: getCurrentUser()?._id,
      },
      refetchQueries: ['getExportEntries'],
    })
  }

  useEffect(() => {
    setExportEntries(exportEntriesQueryResult)
  }, [exportEntriesQueryResult])

  return (
    <ExportEntryListContainer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h5" gutterBottom>
          {t('csv_exports')}
        </Typography>

        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={onClickTriggerExport}
        >
          {t('export')}
        </Button>
      </Stack>
      <Paper>
        {queryResult.loading ? (
          <Loading />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="left">{t('id')}</TableCell>
                  <TableCell align="left">{t('status')}</TableCell>
                  <TableCell align="left">{t('created_at')}</TableCell>
                  <TableCell align="left">
                    {t('item_categories.created_by')}
                  </TableCell>
                  <TableCell align="left">{t('error.error_message')}</TableCell>
                  <TableCell align="right">{t('download_zip')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exportEntries.map((exportEntry) => (
                  <ExportEntryRow
                    exportEntry={exportEntry}
                    getEmployeeName={getEmployeeName}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <Box marginTop="1rem" textAlign="center">
        <Pagination
          style={{ display: 'inline-block' }}
          count={totalPages}
          page={filterValues.page}
          onChange={onChangePage}
        />
      </Box>
    </ExportEntryListContainer>
  )
}

export default ExportEntryList
