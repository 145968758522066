import TableValue from '../TableValue'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { printLocalFloat } from '@/utils/misc'

function formatFloatValue(value) {
  return !value ? '-' : printLocalFloat(value)
}

export const ItemValuesTable = ({
  rows,
  columns = {
    selected: { displayed: false, showLoadingAnimationForNullValues: false },
    verified: { displayed: true, showLoadingAnimationForNullValues: false },
    closed: { displayed: false, showLoadingAnimationForNullValues: false },
    calculated: { displayed: true, showLoadingAnimationForNullValues: false },
  },
  className = '',
}: any) => {
  const { t } = useTranslation()

  return (
    <Table className={`u-mb-sm ${className}`} size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('cost_name')}</TableCell>
          {columns.verified && columns.verified.displayed && (
            <TableCell align="right">{t('value_verified')}</TableCell>
          )}
          {columns.selected && columns.selected.displayed && (
            <TableCell align="right">{t('value_selected')}</TableCell>
          )}
          {columns.closed && columns.closed.displayed && (
            <TableCell align="right">{t('value_closed')}</TableCell>
          )}
          {columns.calculated && columns.calculated.displayed && (
            <TableCell align="right">{t('value_calculated')}</TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.label}>
            <TableCell component="th" scope="row">
              <TableValue value={row.label} />
            </TableCell>
            {columns.verified && columns.verified.displayed && (
              <TableCell align="right">
                <TableValue
                  value={row.values.verified}
                  formatFunc={formatFloatValue}
                  onChange={row.onChange ? row.onChange.verified : undefined}
                  showLoadingAnimationForNullValues={
                    columns.verified.showLoadingAnimationForNullValues
                  }
                  type={'float'}
                />
              </TableCell>
            )}
            {columns.selected && columns.selected.displayed && (
              <TableCell align="right">
                <TableValue
                  value={row.values.selected}
                  formatFunc={formatFloatValue}
                  onChange={row.onChange ? row.onChange.selected : undefined}
                  showLoadingAnimationForNullValues={
                    columns.selected.showLoadingAnimationForNullValues
                  }
                  type={'float'}
                />
              </TableCell>
            )}
            {columns.closed && columns.closed.displayed && (
              <TableCell align="right">
                <TableValue
                  value={row.values.closed}
                  formatFunc={formatFloatValue}
                  onChange={row.onChange ? row.onChange.closed : undefined}
                  showLoadingAnimationForNullValues={
                    columns.closed.showLoadingAnimationForNullValues
                  }
                  type={'float'}
                />
              </TableCell>
            )}
            {columns.calculated && columns.calculated.displayed && (
              <TableCell align="right">
                <TableValue
                  value={row.values.calculated}
                  formatFunc={formatFloatValue}
                  onChange={row.onChange ? row.onChange.calculated : undefined}
                  showLoadingAnimationForNullValues={
                    columns.calculated.showLoadingAnimationForNullValues
                  }
                  type={'float'}
                />
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ItemValuesTable
