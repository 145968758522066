import ItemQuestionsTable from '.'
import React from 'react'
import { Switch } from 'react-router-dom'
import SentryRoute from '@/components/SentryRoute'
import getPagePath from '@/utils/getPagePath'
import ItemQuestionsCreate from './create'
import ItemQuestionsEdit from './edit/[id]'

export function ItemQuestionsRoutes(props) {
  return (
    <Switch>
      <SentryRoute
        path={getPagePath(props, '/create/:itemQuestionId')}
        render={(props) => (
          <ItemQuestionsCreate
            {...props}
            itemQuestionId={props.match.params.itemQuestionId as string}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/edit/:itemQuestionId')}
        render={(props) => (
          <ItemQuestionsEdit
            {...props}
            itemQuestionId={props.match.params.itemQuestionId as string}
          />
        )}
      />
      <SentryRoute
        path={getPagePath(props, '/create')}
        render={(props) => (
          <ItemQuestionsCreate {...props} itemQuestionId={null} />
        )}
      />
      <SentryRoute path={getPagePath(props)} component={ItemQuestionsTable} />
    </Switch>
  )
}

export default ItemQuestionsRoutes
