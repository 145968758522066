import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { dealEventFragments } from '@/domains/deals/hooks'
import { useShowErrorsPopup } from '@/hooks'
import { Query } from '@/schemaTypes'

const GET_PRIVATE_SALE_SUGGESTED_DEALS = gql`
  query getPrivateSaleSuggestedDeals {
    getPrivateSaleSuggestedDeals {
      _id
      bookingNumber
      events {
        ...BaseEventFragment
      }
    }
  }
  ${dealEventFragments.baseEvent}
`

type TData = {
  getPrivateSaleSuggestedDeals: Query['getPrivateSaleSuggestedDeals']
}
type TVariables = {}

export function useGetPrivateSaleSuggestedDeals(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const querySuggestedResult = useQuery<TData, TVariables>(
    GET_PRIVATE_SALE_SUGGESTED_DEALS,
    {
      variables: {},
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(querySuggestedResult.error)

  return {
    querySuggestedResult,
    suggestedDeals:
      querySuggestedResult.data?.getPrivateSaleSuggestedDeals || [],
  }
}
