import { CustomDealFormOption, NumberRestriction } from '../helpers'
import { ECustomDealFormQuestionType } from '@/schemaTypes'
import { NumberOptions } from './NumberOptions'
import { TextOptions } from './TextOptions'

interface Props {
  type: ECustomDealFormQuestionType
  setFieldValue: (field: string, value: unknown) => void
  options?: CustomDealFormOption[]
  allowEnterAnswer?: boolean
  restrictions?: NumberRestriction[]
}

const questionComponentMap = {
  [ECustomDealFormQuestionType.TextDefault]: null,
  [ECustomDealFormQuestionType.TextMultiline]: null,
  [ECustomDealFormQuestionType.TextOptions]: TextOptions,
  [ECustomDealFormQuestionType.NumberDefault]: null,
  [ECustomDealFormQuestionType.NumberOptions]: NumberOptions,
  [ECustomDealFormQuestionType.FileUpload]: null,
  [ECustomDealFormQuestionType.Boolean]: null,
}

export const QuestionFormat = ({ type, ...rest }: Props) => {
  const Component = questionComponentMap[type]

  if (!Component) return null

  return <Component {...rest} />
}
