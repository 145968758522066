import { isEqual, last } from 'lodash'
import {
  Deal,
  EItemStatusType,
  ELogisticsPartnerType,
  EManualPaymentType,
  ESalesPartnerType,
  Employee,
  IAddress,
  Item,
  Maybe,
  OLogisticsPartnerData,
  OSalesPartnerData,
  ValorizationHistoryEntry,
} from '@/schemaTypes'
import { getDealValueEntry } from './deal'
import { paymentTypeUnion } from './misc'

export function createInitialValorizationHistoryEntry(
  params: {
    logisticsPartnerType?: ELogisticsPartnerType
    salesPartnerType?: ESalesPartnerType
    isItemReturned?: boolean
    isTaxFreeTransaction?: Maybe<boolean>
    currentUser: Employee
  },
  previousEntry?: ValorizationHistoryEntry,
) {
  const {
    logisticsPartnerType,
    salesPartnerType,
    isItemReturned,
    isTaxFreeTransaction,
    currentUser,
  } = params
  const now = new Date()

  const defaults: ValorizationHistoryEntry = {
    _id: '',
    companyId: '',
    date: null,
    cashflow: 0,
    createdAt: now,
    updatedAt: now,
    itemsIds: [],
    valorizationBillNumber: '',
    cumulatedCashflow: 0,
    differenceGross: 0,
    differenceNet: 0,
    differenceTax: 0,
    deltaDifferenceTax: 0,
    externalSalePriceGross: 0,
    externalSalePriceNet: 0,
    deviation: 0,
    valorizationResult: 0,
    externalSalePriceGrossDeduction: 0,
    externalSalePriceNetDeduction: 0,
    costOfGoodsSold: 0,
    taxableAmount: 0,
    grossProfit: 0,
    ...(isItemReturned && { isItemReturned }),
    ...(isTaxFreeTransaction && { isTaxFreeTransaction }),
    sendBill: true,
    createdBy: {
      __typename: 'SystemOperation',
      name: currentUser.firstname + ' ' + currentUser.lastname,
    },
  }

  if (previousEntry) {
    const { __typename, _id, ...rest } = previousEntry
    const entry: ValorizationHistoryEntry = {
      ...rest,
      // We override entries with the following ones.
      ...defaults,
    }

    // We do not need to copy any data regarding the bill/creditnote
    entry.valorizationBillLink = undefined
    entry.valorizationCreditNoteNumber = undefined

    return entry
  }

  const entry: ValorizationHistoryEntry = {
    ...defaults,
    buyerName: '',
    logisticsPartnerData: logisticsPartnerType
      ? createLogisticsPartnerInitialData(logisticsPartnerType)
      : undefined,
    salesPartnerData: salesPartnerType
      ? createSalesPartnerInitialData(salesPartnerType)
      : undefined,
  }

  // We do not need to copy any data regarding the bill/creditnote
  entry.valorizationBillLink = undefined
  entry.valorizationCreditNoteNumber = undefined

  return entry
}

export function createLogisticsPartnerInitialData(
  logisticsPartnerType: ELogisticsPartnerType,
) {
  const logisticsPartnerData: OLogisticsPartnerData = {
    type: logisticsPartnerType,
    cashy:
      logisticsPartnerType === ELogisticsPartnerType.Cashy ? {} : undefined,
    logsta:
      logisticsPartnerType === ELogisticsPartnerType.Logsta ? {} : undefined,
  }

  return logisticsPartnerData
}

export function createSalesPartnerInitialData(
  salesPartnerType: ESalesPartnerType,
) {
  const salesPartnerData: OSalesPartnerData = {
    type: salesPartnerType,
    cashy: salesPartnerType === ESalesPartnerType.Cashy ? {} : undefined,
    ebay: salesPartnerType === ESalesPartnerType.Ebay ? {} : undefined,
  }

  return salesPartnerData
}

export function createBuyerAddressInitialData() {
  const address: IAddress = {}

  return address
}

export function isValorizationCreditNote(cashflow: number) {
  return cashflow < 0
}

export function calcDirectPurchaseFactor(deal: Deal) {
  const itemsClosedLoanToValues = deal.items.map(
    (item) => item.values.closed?.loanToValue ?? 0,
  )
  const closedPaybackAmount =
    getDealValueEntry(deal, 'DealClosedEvent').paybackAmount ?? 0

  const closedTotalLoanToValue = itemsClosedLoanToValues.reduce(
    (cur, prev) => cur + prev,
    0,
  )
  const directPurchaseFactor =
    closedTotalLoanToValue > 0
      ? closedPaybackAmount / closedTotalLoanToValue
      : 0

  return directPurchaseFactor
}

function parseValorizationNumber(str: string) {
  const parts = str.split('-')

  return {
    year: parseInt(parts[1]),
    counter: parseInt(parts[2]),
  }
}

export function compareValorizationEntries(
  a: ValorizationHistoryEntry,
  b: ValorizationHistoryEntry,
) {
  const billNumberComponentsA = parseValorizationNumber(
    a.valorizationBillNumber ?? '',
  )
  const billNumberComponentsB = parseValorizationNumber(
    b.valorizationBillNumber ?? '',
  )

  if (billNumberComponentsA.year === billNumberComponentsB.year) {
    if (billNumberComponentsA.counter === billNumberComponentsB.counter) {
      if (a.valorizationCreditNoteNumber && b.valorizationCreditNoteNumber) {
        const creditNoteNumberComponentsA = parseValorizationNumber(
          a.valorizationCreditNoteNumber,
        )
        const creditNoteNumberComponentsB = parseValorizationNumber(
          b.valorizationCreditNoteNumber,
        )

        if (
          creditNoteNumberComponentsA.year === creditNoteNumberComponentsB.year
        ) {
          return (
            creditNoteNumberComponentsB.counter -
            creditNoteNumberComponentsA.counter
          )
        } else {
          return (
            creditNoteNumberComponentsB.year - creditNoteNumberComponentsA.year
          )
        }
      } else {
        if (a.valorizationCreditNoteNumber) {
          // a is credit note but b is not - a must come first
          return -1
        } else {
          // b is credit note but a is not - b must come first
          return 1
        }
      }
    } else {
      return billNumberComponentsB.counter - billNumberComponentsA.counter
    }
  } else {
    return billNumberComponentsB.year - billNumberComponentsA.year
  }
}

export function isItemSoldByLastnamePaymentType(item: Item) {
  const latestSoldEvent = last(
    item.events?.filter((c) => c.event === EItemStatusType.Sold),
  )
  const latestSoldValorizationEntry = last(
    item.itemValorizationCalculation?.valorizationHistory?.filter((c) =>
      isEqual(c._id, latestSoldEvent?.meta?.valorizationEntryId),
    ),
  )

  return (
    latestSoldValorizationEntry?.paymentData?.paymentType &&
    paymentTypeUnion(latestSoldValorizationEntry?.paymentData?.paymentType) ===
      EManualPaymentType.CashOnDelivery
  )
}
