import { DeleteCustomerBlacklist_Mutation } from '../helpers/customerBlacklists.graphql'
import { useMutation } from '@apollo/client'
import { Form, Formik } from 'formik'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import * as Yup from 'yup'
import { useMutationShowingErrors } from '@/hooks'
import FormikTextarea from '@/redesign/components/FormikFields/FormikTextarea/FormikTextarea'

interface RemovePersonFromBlackListDialogProps {
  customerBlacklistId: string
  showDeleteCustomerBlacklistDialog: boolean
  setShowDeleteCustomerBlacklistDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >
}

export const RemovePersonFromBlackListDialog = ({
  customerBlacklistId,
  showDeleteCustomerBlacklistDialog,
  setShowDeleteCustomerBlacklistDialog,
}: RemovePersonFromBlackListDialogProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [deleteCustomerBlacklistMutation] = useMutation(
    DeleteCustomerBlacklist_Mutation,
  )

  const deleteCustomerBlacklist = useMutationShowingErrors({
    mutation: deleteCustomerBlacklistMutation,
    successMessage: t('customer_blacklist_deleted'),
  })

  return (
    <Dialog
      header={t('remove_person_from_blacklist')}
      visible={showDeleteCustomerBlacklistDialog}
      style={{ width: '50vw' }}
      onHide={() => {
        setShowDeleteCustomerBlacklistDialog(false)
      }}
    >
      <Formik<{ reason: string | null }>
        initialValues={{
          reason: '',
        }}
        validationSchema={Yup.object().shape({
          reason: Yup.string().trim().required('Required'),
        })}
        onSubmit={(values) => {
          if (!customerBlacklistId || !values.reason) return

          deleteCustomerBlacklist({
            variables: {
              args: { id: customerBlacklistId, reason: values.reason },
            },
            update: (cache) => {
              const normalizedId = cache.identify({
                __typename: 'CustomerBlacklist',
                id: customerBlacklistId,
              })
              cache.evict({ id: normalizedId })
            },
            onCompleted: () => {
              setShowDeleteCustomerBlacklistDialog(false)
              history.push('/inApp/customerBlacklists')
            },
          })
        }}
      >
        {(props) => {
          return (
            <Form noValidate>
              <FormikTextarea
                name="reason"
                label={t('reason_for_unblocking')}
                className="flex flex-col md:flex-row items-start gap-8"
                labelClassName="text-sm font-semibold mr-5"
                inputContainerClassName="w-full h-28"
                wrapperInputContainerClassName="w-full"
                autoResize
                required
                rows={4}
                cols={20}
              />
              <div className="flex justify-end mt-12">
                <div className="flex">
                  <div className="mr-3.5">
                    <Button
                      label={t('cancel')}
                      onClick={() =>
                        setShowDeleteCustomerBlacklistDialog(false)
                      }
                      text
                      severity="secondary"
                      icon="pi pi-times"
                    />
                  </div>
                  <div>
                    <Button
                      label={t('unblock')}
                      severity="danger"
                      type="button"
                      onClick={() => props.submitForm()}
                      icon="pi pi-check"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
