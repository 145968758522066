import withFieldWrapper from '../withFieldWrapper/withFieldWrapper'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea'

interface TextareaProps extends InputTextareaProps {
  isInvalid?: boolean
}

const Textarea = withFieldWrapper(
  ({ isInvalid, value, ...props }: TextareaProps) => {
    return (
      <InputTextareaStyled
        value={value || ''}
        className={classNames({ 'p-invalid': isInvalid })}
        {...props}
      />
    )
  },
)

const InputTextareaStyled = styled(InputTextarea)`
  font-size: 0.875rem;
  padding: 0.66rem;
  border-radius: 0.375rem;
`

export default Textarea
