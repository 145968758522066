import { Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConfigurationBreadcrumbs from '@/components/ConfigurationBreadcrumbsBar/ConfigurationBreadcrumbsBar'
import Loading from '@/components/Loading'
import MaterialPricesDetails from '@/domains/materialPrices/components/MaterialPricesDetails'
import {
  useGetMaterialPrice,
  useUpdateMaterialPrice,
} from '@/domains/materialPrices/hooks'
import { useMutationShowingErrors } from '@/hooks'
import { MaterialPrice } from '@/schemaTypes'

export interface MaterialPricesEditProps {
  materialPriceId: string
}

export function MaterialPricesEdit(props: MaterialPricesEditProps) {
  const { materialPriceId } = props
  const { t } = useTranslation()

  const { materialPrice, queryResult: getMaterialPriceQueryResult } =
    useGetMaterialPrice({
      variables: {
        materialPriceId,
      },
    })

  const updateMaterialPrice = useMutationShowingErrors({
    mutation: useUpdateMaterialPrice(),
    successMessage: t('material.material_price_updated'),
  })

  const onSave = (materialPrice: MaterialPrice) => {
    updateMaterialPrice({
      variables: {
        ...materialPrice,
      },
    })
  }

  return (
    <Paper className="u-12/12 u-mb-sm">
      <ConfigurationBreadcrumbs
        modelId={materialPriceId}
        modelName={'material.material_prices'}
        mode={'edit'}
      />
      <Typography variant="h5" gutterBottom>
        {t('material.material_price_edit')}
      </Typography>
      {getMaterialPriceQueryResult.loading ? (
        <Loading />
      ) : (
        materialPrice && (
          <MaterialPricesDetails
            materialPrice={materialPrice}
            onSave={onSave}
          />
        )
      )}
    </Paper>
  )
}

export default MaterialPricesEdit
