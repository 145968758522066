import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationDisablePostalCodeArgs } from '@/schemaTypes'

const DELETE_ENABLED_POSTAL_CODE = gql`
  mutation disablePostalCode($id: ObjectId!) {
    disablePostalCode(id: $id)
  }
`

type TData = { disablePostalCode: Mutation['disablePostalCode'] }
type TVariables = MutationDisablePostalCodeArgs

export function useDisablePostalCode(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [disablePostalCode] = useMutation<TData, TVariables>(
    DELETE_ENABLED_POSTAL_CODE,
    opts,
  )

  return disablePostalCode
}
