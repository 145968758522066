import { QueryHookOptions, useLazyQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetItemQuestionsByCategoryV2Args } from '@/schemaTypes'

const GET_ITEM_QUESTIONS_BY_CATEGORY = gql`
  query getItemQuestionsByCategoryV2(
    $itemCategoryId: ObjectId!
    $date: DateTime!
    $omitDeactivatedQuestionsForNewProducts: Boolean!
    $omitInternalQuestions: Boolean
  ) {
    getItemQuestionsByCategoryV2(
      itemCategoryId: $itemCategoryId
      date: $date
      omitDeactivatedQuestionsForNewProducts: $omitDeactivatedQuestionsForNewProducts
      omitInternalQuestions: $omitInternalQuestions
    ) {
      _id
      validFrom
      validTo
      order
      questionType
      valueMode
      titleKey
      infoKey
      isInternal
      predictionTag
      singleChoiceOptions {
        labelKey
        infoKey
        categoryValues
        conditionValue
        isDefaultValue
      }
      rangeData {
        minValue
        maxValue
        unitPrice
        unitKey
        defaultValue
      }
      itemCategoryIds
    }
  }
`

export type TData = {
  getItemQuestionsByCategoryV2: Query['getItemQuestionsByCategoryV2']
}
type TVariables = QueryGetItemQuestionsByCategoryV2Args

export function useGetItemQuestionsByCategoryV2(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const [getItemQuestionsByCategoryV2, queryResult] = useLazyQuery<
    TData,
    TVariables
  >(GET_ITEM_QUESTIONS_BY_CATEGORY, {
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return {
    getItemQuestionsByCategoryV2,
    itemQuestions: queryResult.data?.getItemQuestionsByCategoryV2,
  }
}
