import dayjs from 'dayjs'
import { ValidFromToFieldName } from '@/types'

const hourChangeConfig: Record<
  ValidFromToFieldName,
  { minute: number; second: number; millisecond: number }
> = {
  validFrom: {
    minute: 0,
    second: 0,
    millisecond: 0,
  },
  validTo: {
    minute: 59,
    second: 59,
    millisecond: 999,
  },
  date: {
    minute: 0,
    second: 0,
    millisecond: 0,
  },
  dateOfBirth: {
    minute: 0,
    second: 0,
    millisecond: 0,
  },
  checkedAt: {
    minute: 0,
    second: 0,
    millisecond: 0,
  },
}

export function useValidFromToHourChange(
  changeFunc: (newDate: Date | null, fieldName: ValidFromToFieldName) => void,
) {
  return (
    hour: number,
    model: Partial<Record<ValidFromToFieldName, Date>>,
    fieldName: ValidFromToFieldName,
  ) => {
    const newDate = dayjs(model[fieldName])
      .set('hour', hour)
      .set('minute', hourChangeConfig[fieldName].minute)
      .set('second', hourChangeConfig[fieldName].second)
      .set('millisecond', hourChangeConfig[fieldName].millisecond)
      .toDate()

    changeFunc(newDate, fieldName)
  }
}
