import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Mutation,
  MutationUpdateDealOnlineExtensionPreventionArgs,
} from '@/schemaTypes'
import { noteFragments } from './noteFragments'

const UPDATE_DEAL_ONLINE_EXTENSION_PREVENTION = gql`
  mutation updateDealOnlineExtensionPrevention(
    $dealId: ObjectId!
    $isOnlineExtensionPrevented: Boolean!
    $note: String
  ) {
    updateDealOnlineExtensionPrevention(
      dealId: $dealId
      isOnlineExtensionPrevented: $isOnlineExtensionPrevented
      note: $note
    ) {
      _id
      notes {
        ...NoteFragment
      }
      dealFinalValues {
        isOnlineExtensionPrevented
      }
    }
  }
  ${noteFragments.notes}
`

type TData = {
  updateDealOnlineExtensionPrevention: Mutation['updateDealOnlineExtensionPrevention']
}
type TVariables = MutationUpdateDealOnlineExtensionPreventionArgs

export function useUpdateDealOnlineExtensionPrevention(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateDealOnlineExtensionPrevention] = useMutation<TData, TVariables>(
    UPDATE_DEAL_ONLINE_EXTENSION_PREVENTION,
    opts,
  )

  return updateDealOnlineExtensionPrevention
}
