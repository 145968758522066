import FeeDefinitionsPage from '.'
import { Route, Switch } from 'react-router-dom'
import getPagePath from '@/utils/getPagePath'

export function FeesConfiguration(props) {
  return (
    <Switch>
      <Route path={getPagePath(props)} component={FeeDefinitionsPage} />
    </Switch>
  )
}

export default FeesConfiguration
