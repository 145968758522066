import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EBillingPeriodType,
  EDealType,
  FeeCreateArgs,
  FeeEvaluationItemCategory,
  FeeUpdateArgs,
} from '@/schemaTypes'
import FeeEvaluationDemonstrator from './FeeEvaluationDemonstrator'
import styles from './FeesConfiguration.module.scss'

interface FeeEvaluatorWithItemCategoryProps {
  itemCategoryEvaluation: FeeEvaluationItemCategory
  newFeeDefinitionArgs: FeeCreateArgs | FeeUpdateArgs
  isEditMode: boolean
}

const FeeEvaluatorWithItemCategory: FunctionComponent<
  FeeEvaluatorWithItemCategoryProps
> = (props) => {
  const { itemCategoryEvaluation, newFeeDefinitionArgs, isEditMode } = props
  const { t } = useTranslation()

  if (!itemCategoryEvaluation) return null

  return (
    <table>
      <tr>
        <td className={`${styles.evaluationColumnDealType}`} />
        <td className={`${styles.evaluationColumn}`}>{`${t('daily')}`}</td>
        <td className={`${styles.evaluationColumn}`}>{`${t('one_time')}`}</td>
        <td className={`${styles.evaluationColumn}`}>{`${t('half_month')}`}</td>
        <td className={`${styles.evaluationColumn}`}>{`${t('month')}`}</td>
      </tr>

      <tr>
        <td
          className={`${styles.evaluationColumnDealType}`}
        >{`${t('purchase')}`}</td>
        <td className={`${styles.evaluationColumn}`}>
          <FeeEvaluationDemonstrator
            inputFeeDefinitions={
              itemCategoryEvaluation.feeDefinitionEvaluations
                .dailyPurchaseFeeEvaluation
            }
            newFeeDefinitionArgs={newFeeDefinitionArgs}
            isEditMode={isEditMode}
            billingPeriod={EBillingPeriodType.Day}
            dealType={EDealType.Purchase}
          />
        </td>
        <td className={`${styles.evaluationColumn}`}>
          <FeeEvaluationDemonstrator
            inputFeeDefinitions={
              itemCategoryEvaluation.feeDefinitionEvaluations
                .oneTimePurchaseFeeEvaluation
            }
            newFeeDefinitionArgs={newFeeDefinitionArgs}
            isEditMode={isEditMode}
            billingPeriod={EBillingPeriodType.OneTime}
            dealType={EDealType.Purchase}
          />
        </td>
        <td className={`${styles.evaluationColumn}`}>
          <FeeEvaluationDemonstrator
            inputFeeDefinitions={
              itemCategoryEvaluation.feeDefinitionEvaluations
                .halfMonthPurchaseFeeEvaluation
            }
            newFeeDefinitionArgs={newFeeDefinitionArgs}
            isEditMode={isEditMode}
            billingPeriod={EBillingPeriodType.HalfMonth}
            dealType={EDealType.Purchase}
          />
        </td>
        <td className={`${styles.evaluationColumn}`}>
          <FeeEvaluationDemonstrator
            inputFeeDefinitions={
              itemCategoryEvaluation.feeDefinitionEvaluations
                .fullMonthPurchaseFeeEvaluation
            }
            newFeeDefinitionArgs={newFeeDefinitionArgs}
            isEditMode={isEditMode}
            billingPeriod={EBillingPeriodType.Month}
            dealType={EDealType.Purchase}
          />
        </td>
      </tr>

      <tr>
        <td
          className={`${styles.evaluationColumnDealType}`}
        >{`${t('pawn')}`}</td>

        <td className={`${styles.evaluationColumn}`}>
          <FeeEvaluationDemonstrator
            inputFeeDefinitions={
              itemCategoryEvaluation.feeDefinitionEvaluations
                .dailyPawnFeeEvaluation
            }
            newFeeDefinitionArgs={newFeeDefinitionArgs}
            isEditMode={isEditMode}
            billingPeriod={EBillingPeriodType.Day}
            dealType={EDealType.Pawn}
          />
        </td>
        <td className={`${styles.evaluationColumn}`}>
          <FeeEvaluationDemonstrator
            inputFeeDefinitions={
              itemCategoryEvaluation.feeDefinitionEvaluations
                .oneTimePawnFeeEvaluation
            }
            newFeeDefinitionArgs={newFeeDefinitionArgs}
            isEditMode={isEditMode}
            billingPeriod={EBillingPeriodType.OneTime}
            dealType={EDealType.Pawn}
          />
        </td>
        <td className={`${styles.evaluationColumn}`}>
          <FeeEvaluationDemonstrator
            inputFeeDefinitions={
              itemCategoryEvaluation.feeDefinitionEvaluations
                .halfMonthPawnFeeEvaluation
            }
            newFeeDefinitionArgs={newFeeDefinitionArgs}
            isEditMode={isEditMode}
            billingPeriod={EBillingPeriodType.HalfMonth}
            dealType={EDealType.Pawn}
          />
        </td>
        <td className={`${styles.evaluationColumn}`}>
          <FeeEvaluationDemonstrator
            inputFeeDefinitions={
              itemCategoryEvaluation.feeDefinitionEvaluations
                .fullMonthPawnFeeEvaluation
            }
            newFeeDefinitionArgs={newFeeDefinitionArgs}
            isEditMode={isEditMode}
            billingPeriod={EBillingPeriodType.Month}
            dealType={EDealType.Pawn}
          />
        </td>
      </tr>
    </table>
  )
}

export default FeeEvaluatorWithItemCategory
