import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { Button } from 'primereact/button'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PaymentTypePicker from '@/components/PaymentTypePicker'
import ShopSelect from '@/components/ShopSelect'
import { Context } from '@/context'
import { useShowConfirmPopup } from '@/hooks'
import InputCopyClipboard from '@/redesign/components/InputCopyClipboard/InputCopyClipboard'
import InputNum from '@/redesign/components/InputNumber/InputNumber'
import {
  Deal,
  EManualPaymentType,
  PaymentDataArgs,
  SecondaryPaymentDataArgs,
} from '@/schemaTypes'
import {
  getLatestEventForStatus,
  isDealConnectedToRegisteredCustomer,
  isDealVerified,
} from '@/utils/deal'
import { isShopLevelPaymentType, paymentTypeUnion } from '@/utils/misc'
import {
  convertPrimaryPayoutDataToArgs,
  convertSecondaryPayoutDataToArgs,
  loadCustomerSavedData,
} from './PaymentDetails'

export interface PayoutDataProps {
  deal: Deal
  payoutDataArgs: PaymentDataArgs | SecondaryPaymentDataArgs
  amount: number
  setPayoutDataArgs
  onUpdatePayoutData: (
    args: PaymentDataArgs | SecondaryPaymentDataArgs | null,
  ) => void
  isSecondary?: boolean
}

export default function PayoutData(props: PayoutDataProps) {
  const {
    deal,
    amount,
    payoutDataArgs,
    setPayoutDataArgs,
    onUpdatePayoutData,
    isSecondary = false,
  } = props
  const { closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()

  const showConfirmUseCustomerSavedPaymentDataPopup = useShowConfirmPopup({
    actionText: t('take'),
    abortActionText: t('do_not_take'),
  })

  const isVerifiedDeal = isDealVerified(deal)

  const persistedPrimaryPayoutData = useMemo(
    () => convertPrimaryPayoutDataToArgs(deal.payoutData),
    [deal.payoutData],
  )
  const persistedSecondaryPayoutData = useMemo(
    () => convertSecondaryPayoutDataToArgs(deal.secondaryPayoutData),
    [deal.secondaryPayoutData],
  )

  const showSaveButton =
    (isSecondary && !isEqual(persistedSecondaryPayoutData, payoutDataArgs)) ||
    (!isSecondary && !isEqual(persistedPrimaryPayoutData, payoutDataArgs))

  const verifiedEvent = getLatestEventForStatus(deal, 'DealVerifiedEvent')

  const isValid = useMemo(() => {
    return payoutDataArgs?.paymentType === EManualPaymentType.Bank
      ? !payoutDataArgs?.iban
      : payoutDataArgs?.paymentType === EManualPaymentType.Paypal
        ? !payoutDataArgs?.paypalAddress
        : false
  }, [payoutDataArgs])

  const isDisabled = !isDealConnectedToRegisteredCustomer(deal)

  return (
    <PayoutCard className="flex flex-col pb-3.5">
      <div className="flex items-center justify-between h-10 pl-3.5">
        <CardTitle>
          {!isSecondary ? t('primary_payout') : t('secondary_payout')}
        </CardTitle>

        <div>
          {showSaveButton && (
            <>
              <Button
                className="h-10"
                icon="pi pi-refresh"
                text
                severity="secondary"
                onClick={() =>
                  setPayoutDataArgs(
                    !isSecondary
                      ? persistedPrimaryPayoutData
                      : persistedSecondaryPayoutData,
                  )
                }
              />
              <Button
                className="h-10"
                icon="pi pi-save"
                text
                disabled={isValid || (isSecondary && !amount)}
                onClick={() => onUpdatePayoutData(payoutDataArgs)}
              />
            </>
          )}

          {isSecondary &&
            isEqual(persistedSecondaryPayoutData, payoutDataArgs) && (
              <Button
                className="h-10"
                icon="pi pi-trash"
                text
                severity="danger"
                onClick={() => {
                  onUpdatePayoutData(null)
                  setPayoutDataArgs(null)
                }}
              />
            )}
        </div>
      </div>
      {isSecondary || payoutDataArgs ? (
        <div className="px-3.5 h-32">
          <InputNum
            value={amount}
            label={t('payout.payout_amount')}
            disabled={!isSecondary}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-60"
            onValueChange={(e) => {
              setPayoutDataArgs({
                ...payoutDataArgs,
                amount: e.target.value || 0,
              })
            }}
            required={isSecondary && true}
            isInvalid={isSecondary ? !amount : false}
            errorText=""
            max={deal.dealFinalValues.payoutAmount}
          />

          <div className="mb-2">
            <PaymentTypePicker
              disabled={
                isDisabled ||
                (verifiedEvent &&
                  !dayjs().isSame(verifiedEvent.createdAt, 'date'))
              }
              paymentType={payoutDataArgs?.paymentType}
              onChangePaymentType={(value) =>
                loadCustomerSavedData({
                  customerPaymentData: {
                    iban: deal.customer.iban,
                    paypalAddress: deal.customer.paypalAddress,
                  },
                  paymentType: value,
                  dataArgs: payoutDataArgs,
                  setDataArgs: setPayoutDataArgs,
                  t,
                  popup: showConfirmUseCustomerSavedPaymentDataPopup,
                  close: closeConfirmModal,
                })
              }
              businessUnit={deal.businessUnit}
              isOutFlow={true}
              inputContainerClassName="w-60"
            />
          </div>

          {payoutDataArgs.paymentType === EManualPaymentType.Bank && (
            <InputCopyClipboard
              value={payoutDataArgs.iban}
              disabled={isDisabled}
              label={t('customer.iban')}
              className="flex flex-col md:flex-row items-start md:items-center mb-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-60"
              onChange={(e) => {
                setPayoutDataArgs({ ...payoutDataArgs, iban: e.target.value })
              }}
              required={isSecondary && true}
              isInvalid={isSecondary ? !payoutDataArgs.iban : false}
              errorText=""
            />
          )}
          {payoutDataArgs.paymentType === EManualPaymentType.Paypal && (
            <InputCopyClipboard
              value={payoutDataArgs.paypalAddress}
              disabled={isDisabled}
              label={t('paypal_address')}
              className="flex flex-col md:flex-row items-start md:items-center mb-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-60"
              onChange={(e) => {
                setPayoutDataArgs({
                  ...payoutDataArgs,
                  paypalAddress: e.target.value,
                })
              }}
              required={isSecondary && true}
              isInvalid={!payoutDataArgs.paypalAddress}
              errorText=""
            />
          )}

          {isVerifiedDeal &&
          showSaveButton &&
          isShopLevelPaymentType(payoutDataArgs.paymentType) ? (
            <ShopSelect
              companyId={deal.companyId}
              shopId={payoutDataArgs.shopId ?? ''}
              onChange={(value) => {
                setPayoutDataArgs({ ...payoutDataArgs, shopId: value })
              }}
              includeDisabled
            />
          ) : null}
        </div>
      ) : (
        <div className="px-3.5 h-32">
          <PaymentTypePicker
            disabled={true}
            paymentType={paymentTypeUnion(deal.payoutData.paymentType)}
            businessUnit={deal.businessUnit}
            inputContainerClassName="w-60"
          />
        </div>
      )}
    </PayoutCard>
  )
}

const CardTitle = styled.h6`
  color: #000;
  font-family: Inter;
  font-size: 1.09375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.09375rem; /* 100% */
`
const PayoutCard = styled.div`
  width: 28.8125rem;
  // height: 11.9375rem;
  // padding: 0.65625rem 1.09375rem;
  gap: 0.4375rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  background: var(--surface-50, #fafafa);
  margin-right: 0.66rem;
`
