import { cloneDeep } from '@apollo/client/utilities'
import dayjs from 'dayjs'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import { TreeNode } from 'primereact/treenode'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { ALL_COMPANIES } from '@/components/EmployeeShopPopup/EmployeeShopSelect'
import Loading from '@/components/Loading'
import { Context } from '@/context'
import { checkIsCarPawn } from '@/domains/customDeals/helpers'
import { useDeleteCustomDeal } from '@/domains/customDeals/hooks'
import {
  CUSTOM_DEAL_ADDED,
  TCustomDealAddedData,
} from '@/domains/customDeals/hooks/useCustomDealAdded'
import { useSearchCustomDeals } from '@/domains/customDeals/hooks/useSearchCustomDeals'
import { useGetItemCategories } from '@/domains/itemCategories/hooks'
import { useShowConfirmDeletePopup } from '@/hooks'
import { useGetActiveEmployees } from '@/hooks/useGetActiveEmployees'
import {
  HistoryFilters,
  useHistoryFilter,
  useHistoryState,
} from '@/hooks/useHistoryState'
import InfiniteScrollTable from '@/redesign/components/InfiniteScrollTable/InfiniteScrollTable'
import TableFilterHeader from '@/redesign/components/TableFilterHeade/TableFilterHeader'
import Taskbar from '@/redesign/components/Taskbar/Taskbar'
import CustomDealsFilter, {
  CustomDealsFilters,
} from '@/redesign/domains/customDeals/components/CustomDealsFilter'
import { CustomDeal, ECustomDealStatusType, ItemCategory } from '@/schemaTypes'
import { useAppSelector } from '@/store'
import { displayLocalAmount } from '@/utils/misc'
import {
  SubscriptionEvent,
  handlePaginatedSubscription,
} from '@/utils/paginatedUtils'

const LIMIT = 10

export function CustomDealsList() {
  const { closeConfirmModal } = useContext(Context)
  const { t } = useTranslation()
  const history = useHistory()
  const companyId = useAppSelector((state) => state.ui.companyId)

  const showConfirmDeletePopup = useShowConfirmDeletePopup({
    actionText: t('yes'),
    abortActionText: t('no'),
  })

  const defaultCustomDealsFilterArgs = useMemo(
    () => ({ companyId: companyId === ALL_COMPANIES ? undefined : companyId }),
    [companyId],
  )

  const { historyState, setHistory } =
    useHistoryState<HistoryFilters<CustomDealsFilters>>()
  const [customDealFilter, setCustomDealFilter] = useState<CustomDealsFilters>({
    ...historyState?.filters,
    ...defaultCustomDealsFilterArgs,
  })
  const { edited } = useHistoryFilter<CustomDealsFilters>(
    historyState?.filters,
    customDealFilter,
    setHistory,
  )

  useEffect(() => {
    setCustomDealFilter((prev) => ({
      ...prev,
      ...defaultCustomDealsFilterArgs,
    }))
  }, [companyId, defaultCustomDealsFilterArgs])

  const customDealsFiltersArgs = useMemo(() => {
    const status = customDealFilter.status
      ? {
          ...customDealFilter.status,
          names: [...customDealFilter.status.names], // create new string array instance so Apollo can detect the change in it
        }
      : undefined

    const categoryIds = Object.keys(customDealFilter.categoryIds ?? {})
      .map((key) => (customDealFilter.categoryIds[key].checked ? key : null))
      .filter(Boolean)

    return {
      ...customDealFilter,
      categoryIds,
      status,
      createdBefore: new Date(),
      paginationArgs: { limit: LIMIT },
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customDealFilter, edited])

  const { queryResult } = useSearchCustomDeals({
    variables: {
      args: customDealsFiltersArgs,
    },
    nextFetchPolicy: 'cache-first',
  })

  const { employees } = useGetActiveEmployees()

  const { itemCategories } = useGetItemCategories()

  const deleteCustomDeal = useDeleteCustomDeal({
    onCompleted: (data) => {
      if (queryResult) {
        queryResult.updateQuery((prev) => {
          const updatedResult = prev.searchCustomDeals.nodes.filter(
            (node) => node._id !== data.deleteCustomDeal._id,
          )
          return {
            searchCustomDeals: {
              ...prev.searchCustomDeals,
              edges: updatedResult,
            },
          }
        })
      }
    },
  })

  useEffect(() => {
    const unsubscribe = queryResult.subscribeToMore({
      document: CUSTOM_DEAL_ADDED,
      updateQuery: (
        prev,
        {
          subscriptionData,
        }: { subscriptionData: { data: TCustomDealAddedData } },
      ) => {
        const clonedData = cloneDeep(prev)
        const addedElement = subscriptionData.data.customDealAdded
        if (
          addedElement &&
          !clonedData.searchCustomDeals.nodes.find(
            (deal) => deal._id === addedElement._id,
          )
        ) {
          const updatedSearchCustomDeals =
            handlePaginatedSubscription<CustomDeal>(
              clonedData.searchCustomDeals,
              addedElement,
              SubscriptionEvent.Add,
            )
          return { searchCustomDeals: updatedSearchCustomDeals }
        }
        return prev
      },
    })
    return () => unsubscribe()
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const employeeIdNameMap = useMemo(
    () =>
      employees?.reduce((result, employee) => {
        result[employee._id] = `${employee.firstname} ${employee.lastname}`
        return result
      }, {}),
    [employees],
  )

  const itemCategoryOptions = useMemo(() => {
    const processArray = (
      array: ItemCategory[],
      parentId: string = null,
    ): TreeNode[] => {
      const result = []
      array.forEach((item) => {
        if (item.parentId === parentId) {
          const newItem = {
            key: item._id,
            label: item.name,
            value: item._id,
            children: processArray(array, item._id),
          }
          result.push(newItem)
        }
      })
      return result
    }

    return processArray(itemCategories)
  }, [itemCategories])

  const handleDeleteCustomDeal = async (customDealId: string) => {
    await showConfirmDeletePopup({
      title: t('delete_custom_deal'),
      action: () => {
        closeConfirmModal()
        deleteCustomDeal({
          variables: { customDealId },
        })
      },
    })
  }

  const tableActionsTemplate = (rowData) => {
    return (
      <div className="flex justify-end">
        <Link
          className="mr-2"
          to={`/inApp/customDeals/edit/${rowData._id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon="pi pi-external-link" severity="secondary" text />
        </Link>
        <Button
          icon="pi pi-trash"
          severity="danger"
          onClick={() => handleDeleteCustomDeal(rowData?._id)}
          text
          tooltip={t('custom_deal.delete_customer_deal')}
          tooltipOptions={{ position: 'bottom' }}
        />
      </div>
    )
  }

  const handleClickCustomDeal = useCallback(
    ({ value }) => {
      value?._id && history.push(`/inApp/customDeals/edit/${value._id}`)
    },
    [history],
  )

  const customDeals = useMemo(
    () =>
      queryResult?.data?.searchCustomDeals?.nodes.map((node) => ({
        ...node,
        createdAt: dayjs(node.createdAt).format('DD.MM.YYYY'),
      })),
    [queryResult?.data],
  )

  const employeeOptions = useMemo(
    () =>
      employees?.map((employee) => ({
        name: `${employee.firstname} ${employee.lastname}`,
        value: employee._id,
      })) ?? [],
    [employees],
  )

  const loadNextPage = useCallback(async () => {
    const pageInfo = queryResult?.data?.searchCustomDeals?.pageInfo
    const paginationLimit = pageInfo?.limit || LIMIT
    const paginationSkip = (pageInfo?.skip || 0) + paginationLimit

    await queryResult.fetchMore({
      variables: {
        args: {
          ...customDealsFiltersArgs,
          paginationArgs: {
            skip: paginationSkip,
            limit: paginationLimit,
          },
        },
      },
    })
  }, [queryResult, customDealsFiltersArgs])

  return (
    <div className="h-full flex flex-col">
      <TableFilterHeader
        title={t('custom_deal.plural')}
        resultsCount={queryResult.data?.searchCustomDeals.pageInfo.total || 0}
        filterOptions={
          <CustomDealsFilter
            defaultCustomDealsFilter={defaultCustomDealsFilterArgs}
            customDealsFilter={customDealFilter}
            setCustomDealsFilter={setCustomDealFilter}
            employeeOptions={employeeOptions}
            itemCategoryOptions={itemCategoryOptions}
          />
        }
      />
      {queryResult.loading ? (
        <Loading />
      ) : (
        <>
          <InfiniteScrollTable
            handleLoadNextPage={loadNextPage}
            dataKey="_id"
            value={customDeals}
            onClickRow={handleClickCustomDeal}
            limit={queryResult.data?.searchCustomDeals.pageInfo.limit ?? LIMIT}
            hasNextPage={
              queryResult.data?.searchCustomDeals.pageInfo.hasNextPage ?? true
            }
          >
            <Column
              field="customDealNumber"
              style={{ width: '40px' }}
              header={'#'}
            />
            <Column
              style={{ width: '140px' }}
              header={t('customer.customer_email')}
              body={(rowData) => {
                const email =
                  rowData.customer?.email || rowData.contactData.email

                return rowData.customerId ? (
                  <Link
                    to={`/inApp/customers/edit/${rowData.customerId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {email}
                  </Link>
                ) : (
                  email
                )
              }}
            />
            <Column
              style={{ width: '90px' }}
              header={t('customer.phone')}
              body={(rowData) =>
                rowData.customer?.phone || rowData.contactData.phone
              }
            />
            <Column
              style={{ width: '50px' }}
              header={t('value')}
              body={(rowData) =>
                displayLocalAmount(rowData.overridePayoutAmount)
              }
            />
            <Column
              style={{ width: '150px' }}
              header={t('item.plural')}
              body={(rowData) => {
                return rowData.items.map((item) => (
                  <div key={item._id}>
                    <Link to={`/inApp/customDeals/edit/${rowData._id}`}>
                      {item.title}
                    </Link>
                  </div>
                ))
              }}
            />
            <Column
              style={{ width: '60px' }}
              header={t('type')}
              body={(rowData) =>
                t(checkIsCarPawn(rowData.items) ? 'car_pawn' : rowData.dealType)
              }
            />
            <Column
              style={{ width: '70px' }}
              header={t('status')}
              body={statusTempalte}
            />
            <Column
              style={{ width: '80px' }}
              header={t('employee.label')}
              body={(rowData) =>
                employeeIdNameMap[rowData.employeeId] ?? t('unassigned')
              }
            />
            <Column
              field="createdAt"
              style={{ width: '60px' }}
              header={t('created_at')}
            />
            <Column style={{ width: '60px' }} body={tableActionsTemplate} />
          </InfiniteScrollTable>

          <Taskbar>
            <Link to="/inApp/CustomDeals/create/">
              <Button
                label={t('custom_deal.create_customer_deal')}
                icon="pi pi-plus"
              />
            </Link>
          </Taskbar>
        </>
      )}
    </div>
  )
}

const statusTempalte = ({ status }: { status?: ECustomDealStatusType }) => {
  const statusColors: Record<ECustomDealStatusType, string> = {
    [ECustomDealStatusType.Accepted]: '#22C55E',
    [ECustomDealStatusType.Declined]: '#EF4444',
    [ECustomDealStatusType.Canceled]: '#FF7400',
    [ECustomDealStatusType.Created]: '#A855F7',
    [ECustomDealStatusType.Counteroffer]: '#3B82F6',
    [ECustomDealStatusType.InProgress]: '#F59E0B',
    [ECustomDealStatusType.Reviewing]: '#F59E0B',
    [ECustomDealStatusType.Closed]: '#DEE2E6',
    [ECustomDealStatusType.Expired]: '',
  }

  const background = status ? statusColors[status] : ''

  return <Tag style={{ background: background }} value={status} />
}
