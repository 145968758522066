import ItemValorizationSalesTable from '../ItemValorizationSalesTable'
import React, { Fragment } from 'react'
import { Deal, DealItem, EBusinessUnit } from '@/schemaTypes'
import { Timezone } from '@/utils/time'

export interface ItemValorizationDetailsProps {
  item: DealItem
  directPurchaseFactor: number
  deal: Deal
  timezone: Timezone
  businessUnit: EBusinessUnit
}

export function ItemValorizationDetails({
  item,
  directPurchaseFactor,
  deal,
  timezone,
  businessUnit,
}: ItemValorizationDetailsProps) {
  return (
    <Fragment>
      <ItemValorizationSalesTable
        deal={deal}
        timezone={timezone}
        directPurchaseFactor={directPurchaseFactor}
        item={item}
        businessUnit={businessUnit}
      />
    </Fragment>
  )
}

export default ItemValorizationDetails
