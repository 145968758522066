import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import {
  dealCalculationFragments,
  dealFragments,
  itemFragments,
} from '@/domains/items/hooks'
import { useShowErrorsPopup } from '@/hooks'
import { fragments } from '@/hooks/fragments'
import { Query, QueryGetDealsArgs } from '@/schemaTypes'

const GET_AUCTION_DEALS = gql`
  query getAuctionDeals($opts: DealOpts, $auctionDate: DateTime!) {
    getDeals(opts: $opts) {
      _id
      dealStartDate
      createdAt
      updatedAt
      dealType
      bookingNumber
      shop {
        _id
        name
      }
      dealFinalValues {
        payoutAmount
        shouldOverwritePayoutAmount
        durationInDays
      }
      customer {
        ... on Customer {
          _id
          firstname
          lastname
          dateOfBirth
          email
          phone
        }
      }
      company {
        _id
        configuration {
          liquidationFee
        }
      }
      events {
        ...DealEventFragment
      }
      items {
        _id
        title
        storageLabel
        internalSaleData {
          price
          date
          purchasedDirectly
          isCashyPrice
          buyerData {
            partnerId
            name
          }
        }
        itemValorizationCalculation {
          ...ItemValorizationCalculationFragment
        }
        dealsHistory {
          values {
            verified {
              loanToValue
              payoutAmount
            }
          }
        }
        events {
          ...ItemEventFragment
        }
      }
      auctionDealCalculation(auctionDate: $auctionDate) {
        ...DealCalculationFragment
      }
      pawnData {
        buyBackData {
          repurchaseFee
          partialRepayment
          grossProfit
          isPawnAgain
          totalRepurchaseFee
        }
        paybackData {
          ...PaymentDataFragment
        }
      }
    }
  }

  ${itemFragments.itemEvent}
  ${fragments.fileMetadata}
  ${itemFragments.itemValorizationCalculation}
  ${fragments.paymentData}

  ${dealFragments.event}
  ${dealCalculationFragments.dealCalculation}
`

type TData = { getDeals: Query['getDeals'] }
type TVariables = QueryGetDealsArgs & { auctionDate: Date }

export function useGetAuctionDeals(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(GET_AUCTION_DEALS, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, deals: queryResult.data?.getDeals || [] }
}
