import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Formik, FormikProps } from 'formik'
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { Message } from 'primereact/message'
import { Panel } from 'primereact/panel'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { IdentityStatusMessage } from '@/domains/customers/components/IdentityStatusMessage'
import FormikDropdown from '@/redesign/components/FormikFields/FormikDropdown/FormikDropdown'
import FormikText from '@/redesign/components/FormikFields/FormikInput/FormikInput'
import { UppyUploader } from '@/redesign/components/UppyUploader/UppyUploader'
import { Customer, ECustomerIdentityType } from '@/schemaTypes'
import { useAppSelector } from '@/store'
import { CustomerArgs } from './CustomerConfiguration'
import { IdentityVerifyingDialog } from './IdentityVerifyingDialog'

type CustomerIdentityForm = Pick<
  CustomerArgs,
  'firstname' | 'lastname' | 'identityType' | 'identityNumber' | 'dateOfBirth'
>

type CustomerDocumentsPanelProps = {
  identityTypeOptions: { label: string; value: string }[]
  identityTypeSelected: boolean
  allowedFileTypes: Array<string>
  customer: Customer
  maxDate?: Date
  formikProps: FormikProps<any>
  onChangeCheckboxSendEmailReminder: (data: boolean) => void
}

const CustomerDocumentsPanel = ({
  identityTypeOptions,
  identityTypeSelected,
  allowedFileTypes,
  customer,
  maxDate,
  formikProps,
  onChangeCheckboxSendEmailReminder,
}: CustomerDocumentsPanelProps) => {
  const { t } = useTranslation()
  const currentUser = useAppSelector((state) => state.user.current)
  const [visible, setVisible] = useState(false)
  const [shouldSendEmail, setShouldSendEmail] = useState(false)
  const [visibleCheckIdentity, setVisibleCheckIdentity] = useState(false)
  const identityFileUrl = useRef('')
  const shouldCloseIdentityDialog = useRef(false)

  const { setFieldValue } = formikProps

  const onChangeCheckboxSendEmail = (e: CheckboxChangeEvent) => {
    const { checked } = e.target
    setShouldSendEmail(!!checked)
    onChangeCheckboxSendEmailReminder(!!checked)
  }

  const confirmFullMatch = (values: CustomerIdentityForm) => {
    setFieldValue('identityVerifiedAt', new Date())
    setFieldValue('identityVerifiedBy', currentUser?._id)
    setFieldValue('identityNumber', values.identityNumber)
    setFieldValue('identityType', values.identityType)
    setFieldValue('firstname', values.firstname)
    setFieldValue('lastname', values.lastname)
    setFieldValue('dateOfBirth', values.dateOfBirth)
    setVisibleCheckIdentity(false)
  }

  const handleOpenGallery = (files: { url: string; type: string }[]) => {
    identityFileUrl.current = files[0].url
    setVisibleCheckIdentity(true)
  }

  return (
    <Panel header={t('documents')} toggleable className="mb-6">
      <Title>{t('customer.identity_files')}</Title>

      <div className="mb-3">
        <IdentityStatusMessage customer={customer} />
      </div>

      <FormikDropdown
        name="identityType"
        label={t('type')}
        options={identityTypeOptions}
        placeholder={t('type')}
        className="flex flex-col md:flex-row items-start md:items-center mb-2"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
      />

      {identityTypeSelected && (
        <FormikText
          name="identityNumber"
          type="text"
          label={t('id_number')}
          placeholder={t('id_number')}
          className="flex flex-col md:flex-row items-start md:items-center mb-2"
          labelClassName="text-sm font-semibold w-12.5"
          inputContainerClassName="w-52"
        />
      )}

      <UppyUploader
        idPrefix={'identity-file-upload-'}
        allowedFileTypes={allowedFileTypes}
        compress={false}
        name="identityUploadLinks"
        multiple={false}
        confirmBeforeRemove
        onConfirmRemovingFile={() => {
          setShouldSendEmail(false)
          onChangeCheckboxSendEmailReminder(false)
          setVisible(true)
        }}
        onOpenGalery={
          !(customer.identityVerifiedAt && customer.identityVerifiedBy) &&
          handleOpenGallery
        }
      />

      <UppyUploader
        confirmPopupBeforeRemove
        allowedFileTypes={allowedFileTypes}
        label={t('registration_certificate')}
        name="registrationCertificate"
      />

      <UppyUploader
        confirmPopupBeforeRemove
        allowedFileTypes={allowedFileTypes}
        multiple
        label={t('customer.files')}
        name="uploadedFiles"
      />

      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message={
          <MessageWrapper>
            <Message
              severity="warn"
              text={t('identify_verification.remove_id_warn')}
              style={{ width: '100%' }}
            />
            <div className="flex align-items-center mt-5">
              <Checkbox
                inputId="send_email_checkbox"
                checked={shouldSendEmail}
                onChange={onChangeCheckboxSendEmail}
              />
              <label htmlFor="send_email_checkbox" className="ml-2">
                {t('identify_verification.send_email_checkbox')}
              </label>
            </div>
          </MessageWrapper>
        }
        header={`${t('identify_verification.delete_id')}?`}
        acceptLabel={t('delete')}
        acceptClassName="p-button p-component p-button-danger p-button-sm"
        acceptIcon="pi pi-trash"
        rejectLabel={t('abort')}
        rejectIcon="pi pi-times"
        rejectClassName="p-button p-component p-button-text p-button-secondary p-button-sm"
        accept={() => {
          setVisible(false)
          setFieldValue('identityUploadLinks', [])
          if (
            !customer.identityVerifiedAt &&
            !customer.identityVerifiedBy &&
            visibleCheckIdentity
          ) {
            setVisibleCheckIdentity(false)
          }
        }}
      />

      <Formik<CustomerIdentityForm>
        initialValues={{
          //  This "dateOfBirth: maxDate" resolves the issue with the dateOfBirth DatePicker maxDate.
          //  When a maxDate is specified on DatePicker, it should also be used as the default value for the dateOfBirth field in this case.
          firstname: customer.firstname || '',
          lastname: customer.lastname || '',
          identityNumber: customer.identityNumber || '',
          identityType:
            customer.identityType || ECustomerIdentityType.IdentityCard,
          dateOfBirth: customer.dateOfBirth || maxDate,
        }}
        validationSchema={customerValidationSchema}
        onSubmit={confirmFullMatch}
      >
        <IdentityVerifyingDialog
          identityFileUrl={identityFileUrl.current}
          identityTypeOptions={identityTypeOptions}
          openDeletingDialog={() => {
            shouldCloseIdentityDialog.current = true
            setVisible(true)
          }}
          setVisibleCheckIdentity={setVisibleCheckIdentity}
          t={t}
          visibleCheckIdentity={visibleCheckIdentity}
          maxDate={maxDate}
        />
      </Formik>
    </Panel>
  )
}

const Title = styled.h5`
  color: var(--global-text-color, #495057);
  font-family: 'Inter';
  font-size: 1.09375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.09375rem;
`

const MessageWrapper = styled.div`
  margin-left: -1rem;
`

const customerValidationSchema = Yup.object().shape({
  firstname: Yup.string().min(2).required('Required'),
  lastname: Yup.string().min(2).required('Required'),
  dateOfBirth: Yup.date()
    .max(
      dayjs().subtract(18, 'year').hour(23).minute(23).toDate(),
      'Under minimum age',
    )
    .required('Required'),
  identityNumber: Yup.string(),
  identityType: Yup.string().min(2).required('Required'),
})

export default CustomerDocumentsPanel
