import styled from '@emotion/styled'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ExpansionBox from '@/components/ExpansionBox/ExpansionBox'
import SelectOptionInput from '@/components/SelectOptionInput'
import TextInput from '@/components/TextInput'
import { useValidFromToDateChange, useValidationErrorMap } from '@/hooks'
import { Company, ERoles, ESex, Employee } from '@/schemaTypes'
import { ValidFromToFieldName } from '@/types'
import { defaultTimezone } from '@/utils/time'
import { EmployeeValidation } from '@/validation/EmployeeValidation'
import styles from './EmployeesConfiguration.module.scss'

const sexes = [
  { label: 'NONE', value: null },
  { label: 'MALE', value: ESex.Male },
  { label: 'DIVERSE', value: ESex.Diverse },
  { label: 'FEMALE', value: ESex.Female },
]
const roles = [
  { label: 'SUPERADMIN', value: ERoles.Superadmin },
  { label: 'ADMIN', value: ERoles.Admin },
  { label: 'LOCATIONADMIN', value: ERoles.Locationadmin },
  { label: 'LOCATIONSTAFF', value: ERoles.Locationstaff },
  { label: 'CUSTOMER', value: ERoles.Customer },
  { label: 'GUEST', value: ERoles.Guest },
]

export type EmployeeValidationErrorObject = Partial<
  Pick<
    Employee,
    'email' | 'firstname' | 'lastname' | 'dateOfBirth' | 'phone' | 'roles'
  >
>
export interface EmployeeValidationError {
  fieldName: keyof EmployeeValidationErrorObject
  message: string
}
export interface EmployeesDetailsProps {
  employee: Employee
  companies: Company[]
  disableCompanySelect?: boolean
  onSave: (employee: Employee) => void
}

dayjs.extend(utc)
dayjs.extend(timezone)

export function EmployeesDetails(props: EmployeesDetailsProps) {
  const { employee: persistedEmployee, companies, disableCompanySelect } = props
  const { t } = useTranslation()
  const history = useHistory()

  const [employee, setEmployee] = useState<Employee>(persistedEmployee)
  const { validationErrorsMap, isValid } = useValidationErrorMap(
    employee,
    EmployeeValidation,
  )
  const setDateOfBirth = (newDate: Date, fieldName: ValidFromToFieldName) =>
    setEmployee({ ...employee, [fieldName]: newDate })
  const onDateChange = useValidFromToDateChange(setDateOfBirth)
  const [expansionBoxStatus, setExpansionBoxStatus] = useState({
    expandedEmployeeInformation: true,
    expandedEmployeeSettings: true,
  })

  useEffect(() => {
    if (employee.companyId === undefined || employee.companyId === '')
      if (companies && companies.length > 0)
        setEmployee({ ...employee, companyId: companies[0]._id })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee])

  const onReset = () => {
    setEmployee(persistedEmployee)
  }

  const onSave = () => {
    if (isValid) {
      props.onSave(employee)
    }
  }

  const onClickChangePassword = (employeeId: string) => {
    history.push(
      `/inApp/configurations/employees/changeEmployeePassword/${employeeId}`,
    )
  }

  return (
    <Box style={{ marginTop: 30 }}>
      <ExpansionBox
        title={t('employee_information')}
        openAtLoad={expansionBoxStatus.expandedEmployeeInformation}
        onClickBox={() =>
          setExpansionBoxStatus((prev) => ({
            ...prev,
            expandedEmployeeInformation:
              !expansionBoxStatus.expandedEmployeeInformation,
          }))
        }
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <ElementWrapper>
            <TextInput
              inputWidth="250px"
              value={employee.employeeNumber.toString()}
              label={<Label>{t('employee_number')}</Label>}
              disabled={true}
            />
          </ElementWrapper>
          <ElementWrapper>
            <TextInput
              error={validationErrorsMap.email}
              inputWidth="250px"
              value={employee.email}
              onChange={(value) => setEmployee({ ...employee, email: value })}
              label={<Label>{t('customer.email')}</Label>}
            />
          </ElementWrapper>
          <ElementWrapper>
            <TextInput
              inputWidth="250px"
              error={validationErrorsMap.firstname}
              value={employee.firstname}
              onChange={(value) =>
                setEmployee({ ...employee, firstname: value })
              }
              label={<Label>{t('customer.firstname')}</Label>}
            />
          </ElementWrapper>
          <ElementWrapper>
            <TextInput
              inputWidth="250px"
              error={validationErrorsMap.lastname}
              value={employee.lastname}
              onChange={(value) =>
                setEmployee({ ...employee, lastname: value })
              }
              label={<Label>{t('customer.lastname')}</Label>}
            />{' '}
          </ElementWrapper>
          <ElementWrapper>
            <Label>{t('customer.date_of_birth')}:</Label>
            <KeyboardDatePicker
              className={`${styles.datePicker} u-ml-5`}
              clearable
              format="DD.MM.YYYY"
              value={employee.dateOfBirth ?? null}
              onChange={(date) => onDateChange(date, employee, 'dateOfBirth')}
              error={!!validationErrorsMap.dateOfBirth ?? false}
            />
          </ElementWrapper>
          <ElementWrapper>
            <TextInput
              inputWidth="250px"
              error={validationErrorsMap.phone}
              value={employee.phone ?? ''}
              onChange={(value) => setEmployee({ ...employee, phone: value })}
              label={<Label>{t('customer.phone')}</Label>}
            />
          </ElementWrapper>
          <ElementWrapper>
            <SelectOptionInput
              value={employee.sex}
              selectProps={{ style: { width: 250 } }}
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              onChangeNullable={(value) =>
                setEmployee({ ...employee, sex: value })
              }
              label={<Label style={{ width: 142 }}>{t('customer.sex')}</Label>}
              menuItems={sexes}
            />
          </ElementWrapper>
          <ElementWrapper>
            <TextInput
              multiline
              inputWidth="250px"
              value={employee.note ?? ''}
              onChange={(value) => setEmployee({ ...employee, note: value })}
              label={<Label>{t('note')}</Label>}
            />
          </ElementWrapper>
        </Paper>
      </ExpansionBox>
      <ExpansionBox
        title={t('settings')}
        style={{ marginTop: 30 }}
        openAtLoad={expansionBoxStatus.expandedEmployeeSettings}
        onClickBox={() =>
          setExpansionBoxStatus((prev) => ({
            ...prev,
            expandedEmployeeSettings:
              !expansionBoxStatus.expandedEmployeeSettings,
          }))
        }
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <ElementWrapper>
            <SelectOptionInput
              error={validationErrorsMap.companyId}
              value={employee.companyId}
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              selectProps={{ style: { width: 230 } }}
              onChange={(value) =>
                setEmployee({ ...employee, companyId: value })
              }
              label={
                <Label style={{ width: '200px' }}>
                  {t('company.singular')}:
                </Label>
              }
              menuItems={companies.map((company) => ({
                label: company.name,
                value: company._id,
              }))}
              disabled={disableCompanySelect}
            />
          </ElementWrapper>
          <ElementWrapper>
            <SelectOptionInput
              multiple
              selectProps={{ style: { width: 230 } }}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
              error={validationErrorsMap.roles}
              value={employee.roles}
              onChangeMultiple={(value) =>
                setEmployee({ ...employee, roles: value })
              }
              label={
                <Label style={{ width: '200px' }}>{t('customer.role')}:</Label>
              }
              menuItems={roles}
            />
          </ElementWrapper>
          <ElementWrapper>
            <Label style={{ width: '210px' }}>
              {t('scheduled_date_deactivation')}:
            </Label>

            <KeyboardDatePicker
              className={`${styles.datePicker} u-ml-5`}
              clearable
              disablePast
              format="DD.MM.YYYY"
              value={
                employee.scheduledDeactivationAt
                  ? dayjs(employee.scheduledDeactivationAt)
                  : null
              }
              onChange={(date) =>
                setEmployee({
                  ...employee,
                  scheduledDeactivationAt: date
                    ? date.tz(defaultTimezone).startOf('date').toDate()
                    : null,
                })
              }
              error={!!validationErrorsMap.scheduledDeactivationAt ?? false}
            />
          </ElementWrapper>
          <ElementWrapper>
            <TextInput
              inputWidth="250px"
              type="number"
              error={validationErrorsMap.payoutLimit}
              value={
                employee.payoutConfigurations?.payoutLimit?.toString() ?? ''
              }
              onChange={(value) => {
                const newPayoutLimit = value ? parseFloat(value) : null

                setEmployee({
                  ...employee,
                  payoutConfigurations: {
                    payoutLimit: newPayoutLimit,
                    carPayoutLimit:
                      employee.payoutConfigurations?.carPayoutLimit,
                  },
                })
              }}
              label={
                <Label style={{ width: '208px' }}>
                  {t('payout.payout_limit')}:
                </Label>
              }
            />
            <Typography style={{ marginTop: 8, marginLeft: 5 }}>€</Typography>
          </ElementWrapper>
          <ElementWrapper>
            <TextInput
              inputWidth="250px"
              type="number"
              error={validationErrorsMap.carPayoutLimit}
              value={
                employee.payoutConfigurations?.carPayoutLimit?.toString() ?? ''
              }
              onChange={(value) => {
                const newPayoutLimit = value ? parseFloat(value) : null

                setEmployee({
                  ...employee,
                  payoutConfigurations: {
                    carPayoutLimit: newPayoutLimit,
                    payoutLimit: employee.payoutConfigurations?.payoutLimit,
                  },
                })
              }}
              label={
                <Label style={{ width: '208px' }}>
                  {t('car_payout_limit')}:
                </Label>
              }
            />
            <Typography style={{ marginTop: 8, marginLeft: 5 }}>€</Typography>
          </ElementWrapper>
          <ElementWrapper>
            <FormGroup style={{ marginLeft: 210 }}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={employee.payoutConfigurations?.noLimit}
                      onChange={(e) => {
                        setEmployee({
                          ...employee,
                          payoutConfigurations: {
                            payoutLimit: e.target.checked
                              ? null
                              : employee.payoutConfigurations?.payoutLimit,
                            carPayoutLimit: e.target.checked
                              ? null
                              : employee.payoutConfigurations?.carPayoutLimit,
                            noLimit: e.target.checked,
                          },
                        })
                      }}
                    />
                  }
                  label={t('payout.no_limit')}
                />
              </FormControl>
            </FormGroup>
          </ElementWrapper>
          <Button
            style={{ marginTop: 80 }}
            onClick={() => onClickChangePassword(employee._id)}
            variant="contained"
          >
            {t('change_password')}
          </Button>
        </Paper>
      </ExpansionBox>

      <div style={{ marginTop: 10 }}>
        <Button
          style={{ margin: 10, marginLeft: 0 }}
          onClick={onSave}
          variant="contained"
          disabled={!isValid}
        >
          {t('save')}
        </Button>
        <Button style={{ margin: 10 }} onClick={onReset} variant="contained">
          {t('reset')}
        </Button>
      </div>
    </Box>
  )
}

const ElementWrapper = styled(Typography)`
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
`

const Label = styled.div`
  width: 150px;
  font-weight: 500;
`

export default EmployeesDetails
