import withFieldWrapper from '../withFieldWrapper/withFieldWrapper'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { TreeSelect, TreeSelectProps } from 'primereact/treeselect'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { EVisibleOnDomain, ItemCategory } from '@/schemaTypes'

type CategoryItem = {
  key: string
  label: string
  value: string
  // visibleOnDomain: string
  children: CategoryItem[]
}

interface CategoryDropdownProps extends TreeSelectProps {
  isInvalid?: boolean
  categories: ItemCategory[]
  currentCategories: ItemCategory[]
  onChangeCategory?: (itemCategoryId) => void
  restrictions?: string[]
}

// Comment by CAS-1531 Can not select CAR as category on item questions
// const defaultRestrictions = ['car', 'motorrad', 'CarStored', 'MotorradStored']
const defaultRestrictions = []

const CategoryDropdown = withFieldWrapper(
  ({ isInvalid, onChangeCategory, ...props }: CategoryDropdownProps) => {
    const restrictions = props.restrictions ?? defaultRestrictions
    const { categories, currentCategories } = restrictCategoriesForAll(
      restrictions,
      props.categories,
      props.currentCategories,
    )

    const initialNodeKey = useMemo(
      () =>
        currentCategories.length
          ? currentCategories[currentCategories.length - 1]._id
          : '',
      [currentCategories],
    )

    const [selectedNodeKey, setSelectedNodeKey] = useState('')
    const [selectedPath, setSelectedPath] = useState('')
    const [processedCurrentCategories, setProcessedCurrentCategories] =
      useState<CategoryItem[]>([])

    useEffect(() => {
      const processArray = (array, parentId = null) => {
        const result = []
        for (const item of array) {
          if (item.parentId === parentId) {
            const newItem = {
              key: item._id,
              label: item.name,
              value: item._id,
              visibleOnDomain: item.visibleOnDomain,
              children: processArray(array, item._id),
            }
            result.push(newItem)
          }
        }

        return result.sort((a, b) => a.label.localeCompare(b.label))
      }

      const newArray = processArray(categories)

      setProcessedCurrentCategories(newArray)
      setSelectedNodeKey(initialNodeKey)
    }, [categories, initialNodeKey])

    useEffect(() => {
      if (currentCategories[0]?._id && processedCurrentCategories) {
        const path = findNodePath(
          currentCategories[0]._id,
          processedCurrentCategories,
          '',
        )
        setSelectedPath(path)
      }
    }, [currentCategories, processedCurrentCategories])

    const onNodeSelect = useCallback(
      (e) => {
        setSelectedNodeKey(e.value)
        if (onChangeCategory) onChangeCategory(e.value)
        const path = findNodePath(e.value, processedCurrentCategories, '')
        setSelectedPath(path)
      },
      [processedCurrentCategories, onChangeCategory],
    )

    const renderItem = useCallback(
      (node) => (
        <div
          style={{
            color: node.visibleOnDomain === EVisibleOnDomain.None && '#9b9b9b',
          }}
        >
          {node.label}
        </div>
      ),
      [],
    )

    const dropdownValue = useMemo(
      () => selectedPath || props.placeholder,
      [selectedPath, props.placeholder],
    )

    return (
      <TreeSelectStyled
        className={classNames({ 'p-invalid': isInvalid })}
        value={selectedNodeKey}
        options={processedCurrentCategories}
        valueTemplate={() => dropdownValue}
        nodeTemplate={renderItem}
        onChange={onNodeSelect}
        {...props}
      />
    )
  },
)

function restrictCategoriesForAll(
  categoryRestrictions: string[],
  categories: ItemCategory[],
  currentCategories: ItemCategory[],
) {
  //We are running the restricategories for every restriction type, and these are mutually exclusive, so should work as expected.
  //For example, if category is car OR bike, it will be car or bike, and nothing else es available option.
  //Otherwise, the items themselves will get reduced by each item.
  let filteredCategories: ItemCategory[] = categories
  let filteredCurrentCategories: ItemCategory[] = currentCategories

  for (const restriction of categoryRestrictions) {
    const { categories, currentCategories } = restrictCategoriesFor(
      restriction,
      filteredCategories,
      filteredCurrentCategories,
    )
    filteredCategories = categories
    filteredCurrentCategories = currentCategories
  }

  return {
    categories: filteredCategories,
    currentCategories: filteredCurrentCategories,
  }
}

function restrictCategoriesFor(
  categoryRestriction: string,
  categories: ItemCategory[],
  currentCategories: ItemCategory[],
): { categories: ItemCategory[]; currentCategories: ItemCategory[] } {
  let restrictedCategories: ItemCategory[] = []
  let restrictedCurrentCategories: ItemCategory[] = []

  const restrictionTriggered = currentCategories.some(
    (category) => category.name === categoryRestriction,
  )

  if (restrictionTriggered) {
    //If the restricted type is in the current categories, no other parent category is selectable.
    restrictedCategories = [...currentCategories]
    restrictedCurrentCategories = currentCategories
  } else {
    //If the restricted type is NOT in the current categories, it is not even selectable.
    restrictedCurrentCategories = currentCategories
    restrictedCategories = categories.filter(
      (category) => category.name !== categoryRestriction,
    )
  }

  return {
    categories: restrictedCategories,
    currentCategories: restrictedCurrentCategories,
  }
}

const findNodePath = (nodeKey, treeData, path = '') => {
  for (const node of treeData) {
    if (node.key === nodeKey) {
      return path + node.label
    } else if (node.children) {
      const foundPath = findNodePath(
        nodeKey,
        node.children,
        path + node.label + ' > ',
      )
      if (foundPath) {
        return foundPath
      }
    }
  }
  return null
}

const TreeSelectStyled = styled(TreeSelect)`
  &&& {
    .p-treeselect-label {
      padding: 0.66rem;
      font-size: 0.875rem;
      height: 2.375rem;
    }
    .p-dropdown-label.p-placeholder {
      opacity: 1;
      color: #9ca3af;
    }
  }
`

export default CategoryDropdown
