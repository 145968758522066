import ManualFeeDefinition from '../ManualFeeDefintion/ManualFeeDefinition'
import UsedFeeDefinitionViewer from '../UsedFeeDefinitionViewer'
import InfoIcon from '@material-ui/icons/Info'
import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ToggleButton,
} from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { Fragment } from 'react'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '@/components/LoadingSpinner'
import {
  Deal,
  DealCalculation,
  DealExtensionCalculationArgs,
} from '@/schemaTypes'
import {
  getManualFeeDefinitionsFromCalculation,
  getTotalGrossFeeAmountOfType,
} from '@/utils/deal'
import { displayLocalAmount } from '@/utils/misc'
import styles from './ExtensionBox.module.scss'

interface ExtensionChildDetailsProps {
  deal: Deal
  dealExtensionCalculationArgs: DealExtensionCalculationArgs
  setDealExtensionCalculationArgs: Dispatch<
    SetStateAction<DealExtensionCalculationArgs>
  >
  dealCalculation?: DealCalculation
  isExtensionConfirmed: boolean
  isLoading: boolean
  extensionCalculationQueryResult
  onChangeChildOverwriteManualFees?: (
    childShouldOverwriteManualFees: boolean,
  ) => void
  initialCalculation: DealCalculation
}

const ExtensionChildDetails: FunctionComponent<ExtensionChildDetailsProps> = (
  props,
) => {
  const {
    deal,
    dealExtensionCalculationArgs,
    setDealExtensionCalculationArgs,
    dealCalculation,
    isExtensionConfirmed,
    isLoading,
    extensionCalculationQueryResult,
    onChangeChildOverwriteManualFees,
    initialCalculation,
  } = props
  const { t } = useTranslation()

  const [showChildManualFeeDetails, setShowChildManualFeeDetails] =
    useState(false)
  const [showChildFeeDetails, setShowChildFeeDetails] = useState(false)

  return (
    <Fragment>
      <div className={`${styles.boxDiv}`}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  paddingLeft: '7px',
                }}
              >{`${t('payout.new_payout_amount')}:`}</TableCell>
              <TableCell
                sx={{
                  paddingLeft: '70px',
                }}
              >
                {displayLocalAmount(
                  dealCalculation?.dealValuesEntry.payoutAmount,
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <span style={{ marginRight: '0.5rem', marginLeft: '-0.7rem' }}>
                  <ToggleButton
                    style={{
                      paddingTop: '5px',
                      width: '1px',
                      height: '1px',
                    }}
                    value={showChildFeeDetails}
                    size="small"
                    selected={showChildFeeDetails}
                    onChange={() => {
                      setShowChildFeeDetails(!showChildFeeDetails)
                    }}
                  >
                    <InfoIcon />
                  </ToggleButton>
                </span>
                {t('new_fees') + ':'}
              </TableCell>

              <TableCell
                sx={{
                  paddingLeft: '70px',
                }}
              >
                {dealCalculation
                  ? displayLocalAmount(
                      getTotalGrossFeeAmountOfType(
                        dealCalculation?.appliedUsedFeeDefinitions,
                      ),
                    )
                  : '-'}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                fontWeight: 'bold',
                borderTop: '1px solid black',
              }}
            >
              <TableCell
                sx={{
                  paddingLeft: '5px',
                }}
              >{`${t('payout.new_payback_amount')}:`}</TableCell>
              <TableCell
                sx={{
                  paddingLeft: '70px',
                  fontWeight: 'bold',
                  borderTop: '1px solid black',
                }}
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : dealCalculation ? (
                  displayLocalAmount(
                    dealCalculation?.dealValuesEntry.paybackAmount,
                  )
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  width: '250px',
                  paddingLeft: '5px',
                }}
              >
                {t('add_additional_fees_child')}
              </TableCell>

              <TableCell>
                <span style={{ marginRight: '1rem', paddingLeft: '45px' }}>
                  <Switch
                    color="secondary"
                    checked={showChildManualFeeDetails}
                    onChange={(event) => {
                      setShowChildManualFeeDetails(event.target.checked)
                      setDealExtensionCalculationArgs((args) => ({
                        ...args,
                        childShouldOverwriteManualFees: event.target.checked,
                        childManualFeeDefinitionsArgs:
                          getManualFeeDefinitionsFromCalculation(
                            dealCalculation,
                            deal.dealType,
                          ),
                      }))

                      if (!isExtensionConfirmed)
                        onChangeChildOverwriteManualFees(event.target.checked)
                    }}
                    disabled={!dealCalculation && !isExtensionConfirmed}
                  />
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      {(dealCalculation || isExtensionConfirmed) && (
        <>
          {showChildFeeDetails && (
            <div style={{ marginTop: '1rem' }}>
              <UsedFeeDefinitionViewer
                deal={deal}
                dealCalculation={dealCalculation}
                items={deal.items}
                isLoading={
                  !isExtensionConfirmed &&
                  extensionCalculationQueryResult?.loading
                }
                showLayoutButton
                defaultLayout={'module'}
                showItemDetailsButton
                itemRecordsButtonDefaultValue
                showChargTableText
              />
            </div>
          )}

          {showChildManualFeeDetails && (
            <>
              <div style={{ marginTop: '1rem' }}>
                <ManualFeeDefinition
                  deal={deal}
                  args={
                    dealExtensionCalculationArgs.childManualFeeDefinitionsArgs
                  }
                  setArgs={(value) => {
                    setDealExtensionCalculationArgs({
                      ...dealExtensionCalculationArgs,
                      childManualFeeDefinitionsArgs: value,
                      childShouldOverwriteManualFees: showChildManualFeeDetails,
                    })
                  }}
                  dealCalculation={dealCalculation}
                  calculationQueryResult={extensionCalculationQueryResult}
                  showPayoutAmount={false}
                  showResetButton={false}
                  isReadonly={isExtensionConfirmed}
                  initialCalculation={initialCalculation}
                />
              </div>
            </>
          )}
        </>
      )}

      {/* This may be uncommented in future. Please do not remove
          <span style={{ marginLeft: '1rem', marginRight: '1rem' }}>
            <Switch color="secondary" checked={showChildItemQuestions} onChange={event => setShowChildItemQuestions(event.target.checked)} />
          </span>
          {showChildItemQuestions && (
            <div style={{ marginTop: '2rem' }}>
              <ItemQuestions
                deal={deal}
                args={dealExtensionCalculationArgs}
                setArgs={setDealExtensionCalculationArgs}
                isEditable={!isExtensionConfirmed}
              />
            </div>
          )} */}
    </Fragment>
  )
}

export default ExtensionChildDetails
