import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks/useShowErrorsPopup'

const GET_FEE_CALCULATION_FOR_DEAL = gql`
  query getFeeCalculationForDeal(
    $dealId: ObjectId!
    $feesCalculationArgs: GetFeeCalculationForDealArgs!
  ) {
    getFeeCalculationForDeal(
      dealId: $dealId
      feesCalculationArgs: $feesCalculationArgs
    )
  }
`

type TData = {
  getFeeCalculationForDeal: any
  //  Query['getFeeCalculationForDeal']
}
type TVariables = Object //QueryGetFeeCalculationForDealArgs

export function useGetFeeCalculationForDeal(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_FEE_CALCULATION_FOR_DEAL,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    feeCalculation: queryResult.data?.getFeeCalculationForDeal,
  }
}
