import styled from '@emotion/styled'
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Field, Form, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetMaterialPriceForDate } from '@/domains/materialPrices/hooks/getMaterialPriceForDate'
import { useMutationShowingErrors } from '@/hooks'
import {
  EProductMaterial,
  EProductPriceMode,
  Product,
  ProductPriceManualPreciousMetalMode,
  ProductVariant,
} from '@/schemaTypes'
import { printMoneyAmount } from '@/utils/misc'
import { printLocalDate } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'
import { TableFooter } from './TableFooter'
import { TableHeader } from './TableHeader'
import {
  computePreciousMetalPrice,
  getAvailablePropertiesHeaders,
  useProductPriceMapByVariantId,
  usePropertiesMapByVariantId,
  validateMaterialMetric,
} from './helpers'
import { useUpdateProductPrice } from './hooks/useUpdateProductPrice'

interface Props {
  product: Product
}

type ProductPriceFormData = {
  material: EProductMaterial | ''
  isInvestmentGold: boolean
  materialMetricByVariant: Record<
    string,
    { alloy: number | string; weight: number | string }
  >
}

export const MetalBase = ({ product }: Props) => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  const price = product.price as ProductPriceManualPreciousMetalMode | undefined

  const propertiesMapByVariantId = usePropertiesMapByVariantId(product.variants)
  const productPriceMapByVariantId = useProductPriceMapByVariantId(
    price?.variants ?? [],
  )

  const updateProductPrice = useMutationShowingErrors({
    mutation: useUpdateProductPrice({
      refetchQueries: ['getProduct'],
    }),
    successMessage: t('product_updated_successfully'),
  })

  const formik = useFormik<ProductPriceFormData>({
    initialValues: {
      material: price?.material ?? '',
      isInvestmentGold: price?.isInvestmentGold ?? false,
      materialMetricByVariant: product.variants.reduce(
        (
          acc: Record<
            string,
            { alloy: number | string; weight: number | string }
          >,
          variant,
        ) => {
          const materialMetric =
            productPriceMapByVariantId[variant.id]?.materialMetric
          acc[variant.id] = {
            alloy: materialMetric?.alloy ?? '',
            weight: materialMetric?.weight ?? '',
          }
          return acc
        },
        {},
      ),
    },
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true)

      updateProductPrice({
        variables: {
          args: {
            _id: product._id,
            price: {
              isInvestmentGold: values.isInvestmentGold,
              mode: EProductPriceMode.ManualPreciousMetal,
              material: values.material as EProductMaterial,
              variants: product.variants.map((variant) => ({
                variantId: variant.id,
                materialMetric: {
                  alloy: Number(
                    values.materialMetricByVariant[variant.id]?.alloy,
                  ),
                  weight: Number(
                    values.materialMetricByVariant[variant.id]?.weight,
                  ),
                },
              })),
            },
          },
        },
        onError: () => {
          actions.setSubmitting(false)
        },
        onCompleted: () => {
          actions.setSubmitting(false)
        },
      })
    },
  })

  const material = formik.values.material
  const [date] = useState(new Date())

  const { queryResult } = useGetMaterialPriceForDate({
    variables: {
      material: material,
      date,
    },
    skip: !material,
  })

  const handleComputePrice = (variant: ProductVariant) => {
    const price = queryResult.data?.getMaterialPriceForDate.price
      ? computePreciousMetalPrice({
          value: queryResult.data?.getMaterialPriceForDate.price,
          alloy: Number(
            formik.values.materialMetricByVariant[variant.id]?.alloy,
          ),
          weight: Number(
            formik.values.materialMetricByVariant[variant.id]?.weight,
          ),
        })
      : null

    return price ? printMoneyAmount(price) : '-'
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field name="material">
          {({ field }) => (
            <TextField
              select
              label={t('metal')}
              style={{ width: 250, marginTop: 20 }}
              {...field}
            >
              {Object.values(EProductMaterial).map((option) => (
                <MenuItem key={option} value={option}>
                  {t(option)}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Field>

        <TableContainer>
          <TableHeader
            headers={[
              ...getAvailablePropertiesHeaders(
                product.availableProperties,
                language,
              ),
              'release_date',
              'weight',
              'karat',
              'price',
            ]}
          />
          <TableBody>
            {product.variants.map((variant) => {
              const variantPrice = productPriceMapByVariantId[variant.id]

              return (
                <TableRow key={variant.id}>
                  {propertiesMapByVariantId[variant.id]?.map((child) => (
                    <ItemRowBox key={child}>{child}</ItemRowBox>
                  ))}
                  <ItemRowBox>
                    {printLocalDate(variant.releasedAt, {
                      timezone: defaultTimezone,
                    })}
                  </ItemRowBox>
                  <ItemRowBox>
                    <Field
                      name={`materialMetricByVariant.${variant.id}.weight`}
                      validate={validateMaterialMetric}
                    >
                      {({ field, meta }) => (
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                g
                              </InputAdornment>
                            ),
                          }}
                          {...field}
                          type="number"
                          variant="standard"
                          size="small"
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && t(meta.error)}
                        />
                      )}
                    </Field>
                  </ItemRowBox>
                  <ItemRowBox>
                    <Field
                      name={`materialMetricByVariant.${variant.id}.alloy`}
                      validate={validateMaterialMetric}
                    >
                      {({ field, meta }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                karat
                              </InputAdornment>
                            ),
                          }}
                          type="number"
                          variant="standard"
                          size="small"
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && t(meta.error)}
                        />
                      )}
                    </Field>
                  </ItemRowBox>
                  <ItemRowBox>
                    {!formik.dirty
                      ? variantPrice?.price
                        ? printMoneyAmount(variantPrice.price)
                        : '-'
                      : handleComputePrice(variant)}
                  </ItemRowBox>
                </TableRow>
              )
            })}
          </TableBody>
        </TableContainer>
        <Field name="isInvestmentGold" type="checkbox">
          {({ field }) => (
            <FormControlLabel
              label={t('investment_gold')}
              style={{ width: 250, marginTop: 20 }}
              control={<Checkbox {...field} />}
            />
          )}
        </Field>
        <TableFooter
          isValid={formik.isValid && formik.dirty}
          handleReset={formik.resetForm}
        />
      </Form>
    </FormikProvider>
  )
}

const TableBody = styled.div``

const TableContainer = styled.div`
  margin-top: 16px;
  border: solid 1px #e8e8e8;
`

const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 24px;

  &:nth-of-type(odd) {
    background-color: #f8f8f8;
  }
`

const ItemRowBox = styled.div<{ flex?: number; bold?: boolean }>`
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`
