import styled from '@emotion/styled'
import { TextField } from '@material-ui/core'
import dayjs from 'dayjs'
import { Field, Form, FormikProvider, useFormik } from 'formik'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useMutationShowingErrors } from '@/hooks'
import {
  EProductPriceMode,
  Product,
  ProductPriceCrawledMode,
  ProductVariantPrice,
} from '@/schemaTypes'
import { printMoneyAmount } from '@/utils/misc'
import { printLocalDate } from '@/utils/print'
import { defaultTimezone } from '@/utils/time'
import { TableFooter } from './TableFooter'
import { TableHeader } from './TableHeader'
import {
  computeAdjustedPrice,
  getAvailablePropertiesHeaders,
  parsePriceAdjustmentToText,
  parseTextToPriceAdjustment,
  useProductPriceMapByVariantId,
  usePropertiesMapByVariantId,
  validateAdjustment,
} from './helpers'
import { useUpdateProductPrice } from './hooks/useUpdateProductPrice'

interface Props {
  product: Product
}

type ProductPriceFormData = {
  adjustmentByVariant: Record<string, string>
}

export const CrawledBase = ({ product }: Props) => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  const updateProductPrice = useMutationShowingErrors({
    mutation: useUpdateProductPrice({
      refetchQueries: ['getProduct'],
    }),
    successMessage: t('product_updated_successfully'),
  })

  const price = product.price as ProductPriceCrawledMode | undefined

  const propertiesMapByVariantId = usePropertiesMapByVariantId(product.variants)
  const productPriceMapByVariantId = useProductPriceMapByVariantId(
    price?.variants ?? [],
  )

  const formik = useFormik<ProductPriceFormData>({
    initialValues: {
      adjustmentByVariant: product.variants.reduce(
        (acc: Record<string, string>, variant) => {
          const adjustment = productPriceMapByVariantId[variant.id]?.adjustment
          acc[variant.id as string] = parsePriceAdjustmentToText(adjustment)
          return acc
        },
        {},
      ),
    },
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true)

      const variants = map(
        values.adjustmentByVariant,
        (adjustmentText, variantId) => {
          const priceAdjustment = adjustmentText
            ? parseTextToPriceAdjustment(adjustmentText)
            : null

          return priceAdjustment
            ? {
                adjustment: {
                  type: priceAdjustment.type,
                  value: priceAdjustment.value,
                },
                variantId,
              }
            : { variantId }
        },
      )

      updateProductPrice({
        variables: {
          args: {
            _id: product._id,
            price: {
              mode: EProductPriceMode.Crawled,
              variants,
            },
          },
        },
        onError: () => {
          actions.setSubmitting(false)
        },
        onCompleted: () => {
          actions.setSubmitting(false)
        },
      })
    },
  })

  const handleComputeAdjustedPrice = (
    variantPrice: ProductVariantPrice | undefined,
    adjustment: string | null,
  ) => {
    const value = computeAdjustedPrice(variantPrice?.price ?? 0, adjustment)
    return value ? printMoneyAmount(value) : '-'
  }

  const { values } = formik

  return (
    <FormikProvider value={formik}>
      <Form>
        <TableContainer>
          <TableHeader
            headers={[
              ...getAvailablePropertiesHeaders(
                product.availableProperties,
                language,
              ),
              'release_date',
              'last_crawled_date',
              'last_crawled_price',
              'price_adjusted_at',
              'manual_adjustment',
              'adjusted_price',
            ]}
          />
          <TableBody>
            {product.variants.map((variant) => {
              const variantPrice = productPriceMapByVariantId[variant.id]
              const adjustment = values.adjustmentByVariant[variant.id]

              return (
                <TableRow key={variant.id}>
                  {propertiesMapByVariantId[variant.id]?.map((child) => (
                    <ItemRowBox key={child}>{child}</ItemRowBox>
                  ))}
                  <ItemRowBox>
                    {printLocalDate(variant.releasedAt, {
                      timezone: defaultTimezone,
                    })}
                  </ItemRowBox>

                  <ItemRowBox>
                    {variantPrice?.createdAt
                      ? dayjs(variantPrice.createdAt).format('DD.MM.YYYY HH:mm')
                      : '-'}
                  </ItemRowBox>
                  <ItemRowBox>
                    {printMoneyAmount(variantPrice?.price)}
                  </ItemRowBox>
                  <ItemRowBox>
                    {variantPrice?.adjustment?.updatedAt
                      ? dayjs(variantPrice.adjustment.updatedAt).format(
                          'DD.MM.YYYY HH:mm',
                        )
                      : '-'}
                  </ItemRowBox>
                  <ItemRowBox>
                    <Field
                      name={`adjustmentByVariant.${variant.id}`}
                      validate={validateAdjustment}
                    >
                      {({ field, meta }) => (
                        <TextField
                          {...field}
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && t(meta.error)}
                        />
                      )}
                    </Field>
                  </ItemRowBox>
                  <ItemRowBox>
                    {handleComputeAdjustedPrice(variantPrice, adjustment)}
                  </ItemRowBox>
                </TableRow>
              )
            })}
          </TableBody>
        </TableContainer>

        <TableFooter
          isValid={formik.isValid && formik.dirty}
          handleReset={formik.resetForm}
        />
      </Form>
    </FormikProvider>
  )
}

const TableBody = styled.div``

const TableContainer = styled.div`
  margin-top: 16px;
  border: solid 1px #e8e8e8;
`

const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 24px;

  &:nth-of-type(odd) {
    background-color: #f8f8f8;
  }
`

const ItemRowBox = styled.div<{ flex?: number; bold?: boolean }>`
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`
